import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import UploadProfile from "../../../../components/image_crop/image_crop_popup";
import ResumeHolder from "../../../../components/resume_holder/resume_holder";
import { LoadingType, ProfilePictureType, THEME } from "../../../../enums";
import { CallBlueIcon, EmailBlueIcon, LockOpenIcon } from "../../../../icons";
import { OnlineApplicationReceived } from "../../../../interfaces/online_application_received";
import { useAppDispatch, useAppSelector, getPreSignedURLForDownload, selectSingleProfilePictureStateById, getResumeFileUrlForDownload } from "../../../../redux/store";
import { handleDownload } from "../../../../utils";

export interface OnlineApplicationReceivedDetailsSectionProps {
  appInfo: OnlineApplicationReceived;
  onResumeClick: (path: string) => void
}

const AppHeader = (props) => {
  const { name, objectId } = props;

  const dispatch = useAppDispatch();
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const profilePictureState = useAppSelector((state) =>
    selectSingleProfilePictureStateById(
      ProfilePictureType.onlineApplicant + "-" + objectId
    )(state)
  );

  useEffect(() => {
    updateProfile();
  }, [props.objectId]);

  function updateProfile() {
    if(props.objectId) {
      setSentRequest(true);
      dispatch(getPreSignedURLForDownload({ object_id: objectId, object_type: ProfilePictureType.onlineApplicant }));
    }
    
  }

  useEffect(() => {
    if (
      sentRequest &&
      profilePictureState.download.loading === LoadingType.succeeded
    ) {
      setSentRequest(false);
      setImageURL(profilePictureState.download.response ?? '');
    }
  }, [profilePictureState.download.loading]);

  return (
    <div className="cont-ds-user-info">
      <div
        className="cont-ds-avatar-holder"
        onClick={() => {
          //setShowImageUpload(true) /// disabled
          
        }}
      >
        <div
          className="cont-ds-avatar background-img"
          style={imageURL ? { backgroundImage: `url(${imageURL})` } : {}}
        ></div>
      </div>
      <div className="cont-ds-user-name">
        <span>{name}</span>
      </div>
      {showImageUpload && (
        <Portal>
          <UploadProfile
            onClose={() => setShowImageUpload(false)}
            onDismiss={() => setShowImageUpload(false)}
            visible={showImageUpload}
            onApprove={updateProfile}
            objectId={props.objectId}
            objectType={ProfilePictureType.onlineApplicant}
          />
        </Portal>
      )}
    </div>
  );
};

export const OnlineApplicationReceivedDetailsSection: FC<
  OnlineApplicationReceivedDetailsSectionProps
> = (props: OnlineApplicationReceivedDetailsSectionProps) => {
  const { appInfo } = props;
  const { t } = useTranslation();

  function getSSNInfo(value: string) {
    return (
      <div className="ssn-holder">
        <div className="ssn-number">
          <span>{value}</span>
        </div>
        {/* <button className="loc-open-icon">
          <LockOpenIcon
            width={"100%"}
            height={"100%"}
            style={{ color: "#00A4DC" }}
          />
        </button> */}
      </div>
    );
  }

  function getResumeIcon(resume_file_name: string, resume_file_path: string) {
    if (resume_file_name && resume_file_path && resume_file_path != "") {
      return (
        <ResumeHolder
          path={resume_file_name}
          onClick={() => (props.onResumeClick(resume_file_path))}
        />
      )
    }
    return '';
  }
  return (
    <div className="cds-container">
      <AppHeader
        name={
          appInfo.contact_information?.first_name +
          " " +
          appInfo.contact_information?.last_name
        }
        objectId={appInfo.id}
      />
      <div className="cds-branch-selector">
        <div className="cds-status" style={{ margin: "auto" }}>
          <span>
            {`${t("status")}:`}
            <span className="status-txt">
              <span style={{ color: THEME.buttonColor17 }}>
                {appInfo.status}
              </span>
            </span>
          </span>
        </div>
        <div className="cont-ds-info-container">
          <div className="cont-ds-info-content">
            <div className="cont-ds-info-holder">
              <div className="cont-ds-into-title">
                <span>{t("resume").toUpperCase()}</span>
              </div>
              <div className="cont-ds-info-value">
                <span>{getResumeIcon(appInfo.contact_information?.resume_file_name, appInfo.contact_information?.resume_path)}</span>
              </div>
            </div>
          </div>
        </div>
        {appInfo.contact_information?.last_4_digits_of_ssn && (
          <div className="cont-ds-info-container">
            <div className="cont-ds-info-content">
              <div className="cont-ds-info-holder">
                <div className="cont-ds-into-title">
                  <span>{t("ss_number").toUpperCase()}</span>
                </div>
                <div className="cont-ds-info-value">
                  {getSSNInfo(
                    appInfo.contact_information?.last_4_digits_of_ssn
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {appInfo.contact_information?.phone && (
          <div className="cont-ds-info-container">
            <div className="cont-ds-info-icon">
              <CallBlueIcon width={"100%"} height={"100%"} />
            </div>
            <div className="cont-ds-info-content">
              <div className="cont-ds-info-holder">
                <div className="cont-ds-info-value">
                  {appInfo.contact_information?.phone}
                </div>
              </div>
            </div>
          </div>
        )}
        {appInfo.contact_information?.email && (
          <div className="cont-ds-info-container">
            <div className="cont-ds-info-icon">
              <EmailBlueIcon width={"100%"} height={"100%"} />
            </div>
            <div className="cont-ds-info-content">
              <div className="cont-ds-info-holder">
                <div className="cont-ds-info-value">
                  {appInfo.contact_information?.email}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
