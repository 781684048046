import { combineReducers } from "@reduxjs/toolkit";
import { contactsAssignmentsReducers } from "./assignments"
import { contactDashboardReducers } from './dashboard';
import { contactJobOrdersReducers } from './job_orders';
import { contactSalesProfileReducers } from './sales_profile';
import { contactServiceProfileReducers } from './service_profile';

export const contactsDetailsReducers = combineReducers({
    'assignments': contactsAssignmentsReducers,
    'dashboard': contactDashboardReducers,
    // ['job_orders']: contactJobOrdersReducers,
    // ['sales_profile']: contactSalesProfileReducers,
    // ['service_profile']: contactServiceProfileReducers,
});

export * from './assignments';
export * from './dashboard';