import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, IWeeklyTransactions } from "../../../../interfaces";
import * as thunk from './timecards_dashboard_thunk'


export interface TimeCardsDashboardState {
    error?: AppError | null;
    loading: LoadingType;
    response: IWeeklyTransactions[] | null;
    create: {
        error?: AppError | null;
        loading: LoadingType;
        createResponse: string | null;
    },

}

const initialState: TimeCardsDashboardState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    create: {
        error: null,
        loading: LoadingType.idle,
        createResponse: null,
    },

}

export const timecardsDashboardSlice = createSlice({
    name: "timcardsDashboardSlice",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearErrorState(state) {
            return {
                ...state,
                error:null,
                create: {
                    ...initialState.create,
                    error: null,
                },

            };
        },
        clearCreateState(state) {
            return {
                ...state,
                create: {
                    ...initialState.create,

                },

            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getWeeklyTransactionsThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getWeeklyTransactionsThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getWeeklyTransactionsThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))

            .addCase(thunk.createWeeklyTransactionThunk.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createWeeklyTransactionThunk.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createWeeklyTransactionThunk.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, createResponse: action.payload } }))

            // .addCase(thunk.getWeeklyAccountingCloseThunk.pending, (state, action) => ({ ...state, close: { ...state.close, loading: LoadingType.pending, error: null, } }))
            // .addCase(thunk.getWeeklyAccountingCloseThunk.rejected, (state, action) => ({ ...state, close: { ...state.close, loading: LoadingType.failed, error: action.payload, } }))
            // .addCase(thunk.getWeeklyAccountingCloseThunk.fulfilled, (state, action) => ({ ...state, close: { ...state.close, loading: LoadingType.succeeded, error: null, closeResponse: action.payload } }))

            .addDefaultCase((state) => ({ ...state }));
    }
});
export const timecardsDashboardSliceActions = timecardsDashboardSlice.actions;
export const selectState = (state: TimeCardsDashboardState) => state;