import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./rc_wc_code_thunk";
import { IRCWCcode } from "../../../../interfaces/report_center";


export interface RCWCcodeState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCWCcode | null
}

const initialState: RCWCcodeState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}

export const rcWorkCompSlice = createSlice({
    name: 'rcWCCode',
    initialState: initialState,
    reducers: {
        clearRCWCErrorState(state) {
            return { ...state, error: null };
        },
        clearRCWCState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getRCWCcodeThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getRCWCcodeThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getRCWCcodeThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const rcWorkCompSliceActions = rcWorkCompSlice.actions;
export const selectState = (state: RCWCcodeState) => state;
export const selectLoading = (state: RCWCcodeState) => state.loading;
export const selectError = (state: RCWCcodeState) => state.error;




