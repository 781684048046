import {createAsyncThunk} from "@reduxjs/toolkit";
import {AgencyBranch, AppError} from "../../../../interfaces";
import {AdminCenterAPI} from "../../../../apis";
import {catchRequestError} from "../../../../utils";

export const getAgencyBranches = createAsyncThunk<
    AgencyBranch[],
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >('@admin_center/system_configuration/branches/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getBranches();
            return res.data as AgencyBranch[];
        }
        catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    })


export const createAgencyBranch = createAsyncThunk<
    string,
    AgencyBranch,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >('@admin_center/system_configuration/branches/create',
    async (agencyBranch, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createBranch(agencyBranch);
            return res.data as string;
        }
        catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    })

export const updateAgencyBranch = createAsyncThunk<
    string,
    AgencyBranch,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >('@admin_center/system_configuration/branches/update',
    async (agencyBranch, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateBranch(agencyBranch);
            return res.data as string;
        }
        catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    })