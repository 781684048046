import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './departments_reducer';

const select = (state: IRootState) => state.companies.details.dashboard.departments;
export const selectCompanyDepartmentsState = createSelector(select, reducer.selectCompanyDepartmentsState);
export const selectSingleCompanyDepartmentsStateById = (id: string | undefined | null) => createSelector(
    selectCompanyDepartmentsState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleCompanyDepartmentsState(state, id);
        return reducer.selectInitialSingleCompanyDepartmentsState();
    }
);