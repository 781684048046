//const axios = require('axios');
import axios, { AxiosInstance } from 'axios';
import config from '../config';
import preferences from '../preferences';

const fileInterceptor: AxiosInstance = axios.create({
    baseURL: config.BASE_API_URL,
    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': '' }
});

// request fileInterceptor 
fileInterceptor.interceptors.request.use((config) => {
    // Do something before request is sent 
    //If the header does not contain the token and the url not public, redirect to login  
    const accessToken = preferences.getAuthToken();
    if (accessToken && config.method !== 'OPTIONS') {
        config.headers!.Authorization = 'Bearer ' + accessToken;
        return config;
    };
    return config;

}, (error) => {
    // AppLogger.error("request interceptor error\n", error);
    // Do something with request error 
    return error;
});
// axiosInstance.interceptors.response.use((response)=> {
//     // Do something with response data
//     console.dir(response, response.status);

//     return response;
// }, (error) => {
//     // Do something with response error
//     console.dir(error);
//     console.dir(error.response);
//     return error;
// });
export default fileInterceptor;