import { combineReducers } from "@reduxjs/toolkit";
import { weeklyAccountingSlice } from "./weekly_accounting/weekly_accounting_dashboard/weekly_accounting_dashboard_reducer";
import { backOfficePayRollReducers } from "./payroll";
import { backOfficeBillingReducers } from "./billing_invoices";
import { paycheckInvoiceSlice } from "./view_paycheck_invoice/view_paycheck_invoice_reducer";
import { backOfficeTimeCardsReducers } from "./timecards";
import { backOfficeImportExportReducers } from "./import_export";
import { thirdPartyDeductionReducers } from "./third_party_deductions";
import { weeklyAErrorReportListSlice } from "./weekly_accounting/error_report/error_report_reducer";
import { mailInvoicePaycheckSlice } from "./mail_paycheck_invoices/mail_paycheck_invoices_reducer";
import { recordPaymentSlice } from "./accounts_receivable/record_payment/record_payment_reducer";


export const backOfficeReducers = combineReducers({
  [weeklyAccountingSlice.name]: weeklyAccountingSlice.reducer,
  [weeklyAErrorReportListSlice.name]: weeklyAErrorReportListSlice.reducer,

  [paycheckInvoiceSlice.name]:paycheckInvoiceSlice.reducer,
  'payRoll': backOfficePayRollReducers,
  'billingInvoice': backOfficeBillingReducers,
  'timeCards':backOfficeTimeCardsReducers,
  'importExport':backOfficeImportExportReducers,
  'thirdPartyDeduction': thirdPartyDeductionReducers,
  [mailInvoicePaycheckSlice.name]:mailInvoicePaycheckSlice.reducer,
  [recordPaymentSlice.name]:recordPaymentSlice.reducer,
});

export * from "./weekly_accounting/weekly_accounting_dashboard/weekly_accounting_dashboard_thunk";
export * from "./weekly_accounting/weekly_accounting_dashboard/weekly_accounting_dashboard_selector";

export * from "./weekly_accounting/error_report/error_report_thunk";
export * from "./weekly_accounting/error_report/error_report_selector";

export * from './view_paycheck_invoice/view_paycheck_invoice_thunk';
export * from './view_paycheck_invoice/view_paycheck_invoice_selector';

export * from './mail_paycheck_invoices/mail_paycheck_invoices_thunk';
export * from './mail_paycheck_invoices/mail_paycheck_invoices_selector';

export * from "./accounts_receivable/record_payment/record_payment_thunk";
export * from "./accounts_receivable/record_payment/record_payment_selector";



export * from "./payroll";

export * from './timecards';

export * from './billing_invoices';

export * from './import_export';

export * from './third_party_deductions';


