import { useRef } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { useTranslation } from "react-i18next";
import { BODeleteBatchIcon, BOOpenCloseIcon } from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton, SpinnerScreen } from "../../../../utils";
import './delete_batch.scss';
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { AppError, ITimeCardsData } from "../../../../interfaces";
import ApiError from "../../../../components/api_error";
import { selectManageBatchState, useAppDispatch, useAppSelector } from "../../../../redux/store";
interface Props {
    visible: boolean,
    title: string,
    onClose: () => void,
    onDissmiss?: () => void,
    batchNum: string | undefined;
    deleteState: {
        error?: AppError | null | undefined;
        loading: LoadingType;
        response: string | null;
    },
    handleDeleteBatch: () => void,
    onSuccessClose: () => void;

}

const DeleteBatchPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        onDissmiss,
        batchNum, deleteState, handleDeleteBatch, onSuccessClose
    } = props;
    const { t } = useTranslation();
    // const dispatch = useAppDispatch();

    const actionRef = useRef<ActionDialogHolderType>(null);
    // const manageBatchState = useAppSelector((state) => selectManageBatchState(state));

    return (<ActionDialogHolder
        ref={actionRef}
        visible={visible}
        wrapperWidth={"40%"}
        maxWrapperWidth={"40%"}
        onClose={onClose}
        onDissmiss={onDissmiss}
    >
        <DialogWrapper onClose={onClose}>
            <div className="delete-batch-container">
                <div className="delete-batch-header">
                    <FormDialogTilteHeader
                        title={title}
                        titleIcon={<BODeleteBatchIcon width={"100%"} height={"100%"} />}
                    />
                </div>
                <div className="delete-batch-content">
                    {deleteState && deleteState.loading === LoadingType.idle &&
                        (
                            <div>
                                <span className="aknw-name">
                                    {t("are_you_sure_to_delete_batch", { number: batchNum })}
                                </span>
                            </div>

                        )
                    }
                    {deleteState && deleteState.loading === LoadingType.pending &&
                        <SpinnerScreen />
                    }
                    {deleteState && deleteState.loading === LoadingType.succeeded &&
                        (
                            <div>
                                <span className="aknw-name">
                                    {t("batch_deleted", { number: batchNum })}
                                </span>
                            </div>

                        )
                    }
                    {deleteState.error &&
                        <div className=" error-holder">
                            <div className="error-title">{t("Warning")}</div>
                            <div>
                                <span className="aknw-name">
                                    {deleteState.error.message}
                                </span>
                            </div>
                        </div>
                    }
                </div>

                <div className="delete-batch-actions">
                    {deleteState.loading === LoadingType.succeeded ?
                        <div className="btn-save">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("ok")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => { onSuccessClose(); onClose() }}
                            />
                        </div>
                        :
                        <div className="delete-batch-actions-row">
                            <div className="btn-cancel" style={{ marginRight: "1em" }}>
                                <CustomButton
                                    loading={false}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("cancel")}
                                    enable={true}
                                    backgroundColor={THEME.toggleDisableColor}
                                    onClick={onClose}
                                />
                            </div>
                            <div className="btn-save">
                                <CustomButton
                                    loading={false}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("delete")}
                                    enable={true}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={handleDeleteBatch}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </DialogWrapper>

    </ActionDialogHolder>);
}

export default DeleteBatchPopup;