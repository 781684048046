import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './backoffice_payroll_setup_reducer';
import { IRootState } from "../../../../store";

const select = (state: IRootState) => state.talent.details.backOfficePayrollSetUp.talentPRsetup;
export const selectTalentPRSStateState = createSelector(select, reducer.selectState);
export const selectSingleTalentPRSStateById = (id: string | undefined | null) => createSelector(
    selectTalentPRSStateState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleTalentPRSState(state, id);
        return reducer.selectInitialSingleTalentPRSState();
    }
);