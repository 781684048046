import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../apis";
import { AppError, ICreateEmploymentHistory, IEmploymentHistory } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
// import { getTalentList } from "../talent_list/talent_list_thunk";

/// Create user
export const createTalentEmploymentHistory = createAsyncThunk<
    string,
    { forceAdd: boolean, value: ICreateEmploymentHistory },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent-details/talent/manage-talent-employment-history/create',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.createTalentEmploymentHistory(payload);

            if (typeof res.data === 'string') return res.data as string;
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
/// Update user
export const updateTalentEmploymentHistory = createAsyncThunk<
    string,
    ICreateEmploymentHistory,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent-details/talent/manage-talent-employment-history/update',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.updateTalentEmploymentHistory(payload);

            if (typeof res.data === 'string') return res.data as string;
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);





