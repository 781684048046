import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState } from "./invoice_correction_reducer";

const select = (state: IRootState) => state.backOffice.billingInvoice.invoiceCorrection;
export const selectInvoiceCorrectionState = createSelector(
    select,
    selectState
);

