import { createSelector } from "@reduxjs/toolkit";
import { ROLE } from "../../../../enums";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./role_users_list_reducer";

const select = (state: IRootState) => state.adminCenter.roleManagement.rmroleUsersList;
export const selectRMRoleUsersListState = createSelector(select, selectState);
export const selectRMRoleUsersList = createSelector(
    select,
    selectAll
);
export const selectRMRoleUsersListEntities = createSelector(
    select,
    selectEntities
);

export const selectAccountManagerRoleUsersList = createSelector(
    selectRMRoleUsersList,
    (docs) => docs.filter((doc,index) => doc.roles.filter((user, index)=> user.role === ROLE.accountManager).length != 0)
);

export const selectRecruiterRoleUsersList = createSelector(
    selectRMRoleUsersList,
    (docs) => docs.filter((doc,index) => doc.roles.filter((user, index)=> user.role === ROLE.recruiter).length != 0)
);

export const selectAccountManagerAndRecruiterRoleUsersList = createSelector(
    selectRMRoleUsersList,
    (docs) => docs.filter((doc,index) => doc.roles.filter((user, index)=> (user.role === ROLE.accountManager || user.role === ROLE.recruiter)).length != 0)
);