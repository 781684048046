import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./record_payment_thunk";
import { AppError, IBillingBatch, IBillingInvoicesReportData } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

export interface RecPaymentState {
    error?: AppError | null;
    loading: LoadingType;
    response: IBillingInvoicesReportData[] | null;
    post: {
        error?: AppError | null;
        loading: LoadingType;
        response: { object_id: string, status: string } | null;
    },
    documentUpload: {
        error?: AppError | null,
        loading: LoadingType,
        response: any
    },

}

const initialState: RecPaymentState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    post: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    documentUpload: {
        error: null,
        loading: LoadingType.idle,
        response: []
    },
};

export const recordPaymentSlice = createSlice({
    name: "recordPayment",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearErrorState(state) {
            return { ...state, error: null };
        },
        clearPostState(state) {
            return {
                ...state,
                post: { ...initialState.post }
            }

        },
        clearPostErrorState(state) {
            return {
                ...state,
                post: { ...initialState.post, error: null }
            }
        },
        clearARDocState(state) {
            return {
                ...state, documentUpload: {
                    ...initialState.documentUpload
                }
            }
        },
        cleaARDocsErrorState(state) {
            return {
                ...state,
                documentUpload: { ...initialState.documentUpload, error: null }
            }

        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPendingInvoicesThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getPendingInvoicesThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getPendingInvoicesThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))
            .addCase(thunk.createRecordPaymentThunk.pending, (state, action) => ({ ...state, post: { ...state.post, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createRecordPaymentThunk.rejected, (state, action) => ({ ...state, post: { ...state.post, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createRecordPaymentThunk.fulfilled, (state, action) => ({ ...state, post: { ...state.post, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getARUploadDocsURLsThunk.pending, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getARUploadDocsURLsThunk.rejected, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getARUploadDocsURLsThunk.fulfilled, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase((state) => ({ ...state }));
    },
});
export const recordPaymentSliceActions = recordPaymentSlice.actions;
export const selectState = (state: RecPaymentState) => state;
export const selectAll = (state: RecPaymentState) => state.response;
export const selectARDocUpload = (state: RecPaymentState) => state.documentUpload;

