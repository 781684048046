import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../../../components/dialog_wrapper/dialog_wrapper';
import { CustomMultiFormSelect } from '../../../../../components/form_selector/form_select';
import MultiUserSection from '../../../../../components/multi_users_section/multi_users_section';
import {
    useAppSelector, selectRoleUsersListState,
    selectAccountManagerRoleUsersList, selectRecruiterRoleUsersList, selectTalentDetailsByIdState, 
    
} from '../../../../../redux/store';
import { getUserNameFromProfileDropdown, SpinnerScreen } from '../../../../../utils';
import { LoadingType, ROLE, TALENT_SKILLS_TYPE } from '../../../../../enums';
import { Spinner } from 'reactstrap';


interface Props {
    onAddOption: (type: string) => void,
    onRemoveOption: (type: string, value: {id: string, value: string}) => void,
    id:string,
    customStyle?: React.CSSProperties,
}
const TalentStaffDetails: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const talentState = useAppSelector((state) => selectTalentDetailsByIdState(props.id)(state));

    const isLoading = () => {
        return talentState.loading === LoadingType.pending
    }
    return (
        <div className="cd-aosr-container" style={props.customStyle}>
            {isLoading() ? <Spinner /> :
                <>  <MultiUserSection
                    headerTitle={t('account_manager').toUpperCase()}
                    actionName={t('add_account_manager')}
                    onClick={() => { props.onAddOption(TALENT_SKILLS_TYPE.account_managers) }}
                    list={(talentState.account_managers.data ?? []).map((doc, index) => ({ id: doc.id, value: getUserNameFromProfileDropdown(doc) }))}
                    onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.account_managers, doc)}
                />
                    <MultiUserSection
                        headerTitle={t('recruiter').toUpperCase()}
                        actionName={t('add_recruiter')}
                        onClick={() => { props.onAddOption(TALENT_SKILLS_TYPE.recruiters) }}
                        list={(talentState.recruiters.data ?? []).map((doc, index) => ({ id: doc.id, value: getUserNameFromProfileDropdown(doc) }))}
                        onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.recruiters, doc)}
                    />
                </>
            }
        </div>
    );
}

export default TalentStaffDetails;