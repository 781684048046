
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { IRCPaymentRegister } from "../../../../interfaces/report_center_payroll_tax";
import * as thunk from "./payment_register_thunk";
import { createSlice } from "@reduxjs/toolkit";

export interface PaymentRegisterListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCPaymentRegister | null,
}

const initialState: PaymentRegisterListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const paymentRegisterListSlice = createSlice({
    name: 'paymentRegisterList',
    initialState: initialState,
    reducers: {
        clearPaymentRegisterListError(state) {
            return { ...state, error: null };
        },
        clearPaymentRegisterListState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPaymentRegisterList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getPaymentRegisterList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getPaymentRegisterList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const paymentRegisterListSliceActions = paymentRegisterListSlice.actions;
export const selectState = (state: PaymentRegisterListState) => state;



