import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import { ICompany, IDepartment } from "../../../interfaces";
import { getMyCallDeckList } from "./my_call_deck_thunk";


export interface MyCallDeckListState extends EntityState<ICompany | IDepartment> {
    error?: AppError | null,
    loading: LoadingType,
}

const myCallDeckListAdapter: EntityAdapter<ICompany | IDepartment> = createEntityAdapter<ICompany | IDepartment>({
    selectId: (doc) => doc.id,
})

const initialState: MyCallDeckListState = myCallDeckListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const myCallDeckListSlice = createSlice({
    name: 'myCallDeckList',
    initialState: initialState,
    reducers: {
        clearMyCallDeckListError(state){
            return {...state, error: null};
        },
        clearMyCallDeckListState(state){
            return myCallDeckListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMyCallDeckList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(getMyCallDeckList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(getMyCallDeckList.fulfilled, (state, action) => myCallDeckListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearMyCallDeckListError, clearMyCallDeckListState } = myCallDeckListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = myCallDeckListAdapter.getSelectors();
export const selectState = (state: MyCallDeckListState) => state;
export const selectLoading = (state: MyCallDeckListState) => state.loading;
export const selectError = (state: MyCallDeckListState) => state.error;


