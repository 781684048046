import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolderType, ActionDialogHolder } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, ProfilePictureType, ROLE } from '../../../../enums';
import { ICompany, ICreateCompany, ICreateResponse } from '../../../../interfaces';
import {
    postSnackbarMessage,
    useAppDispatch,
    useAppSelector,
    createCompany,
    getAccountManagers,
    getBusinessSectors,
    getRecruiters,
    selectAccountManagersList,
    selectAccountManagersListState,
    selectBusinessSectorList,
    selectBusinessSectorListState,
    selectCompetitorsList,
    selectCompetitorsListState,
    selectManageCompanyCreateState,
    selectManageCompanyUpdateState,
    selectRecruitersList,
    selectRecruitersListState,
    updateCompany,
    getCompetitorsList,
    selectCertificationsList,
    selectCredentialsList,
    selectEducationList,
    selectRequiredEquipmentList,
    selectSkillSetList,
    getSkillSetList,
    getCertificationsList,
    getCredentialsList,
    getEducationList,
    getRequiredEquipmentList,
    selectSkillSetListState,
    selectCredentialsListState,
    selectCertificationsListState,
    selectEducationListState,
    selectRequiredEquipmentListState,
    selectProfileState,
    selectManageCompanyUploadState,
    getCompanyLogoUploadURLThunk,
    uploadCompanyLogoThunk,
    selectManageCompanyFileURLState,
    getCompaniesList
} from '../../../../redux/store';
import { manageCompanyActions } from '../../../../redux/companies/universal_directory/manage_company/manage_company_reducer';
import { SpinnerScreen } from '../../../../utils';
import CompanyForm from '../../forms/company_form/company_form';

import './create_company.scss';
import { getCompanyStatus, selectCompanyStatusList, selectCompanyStatusState } from '../../../../redux/admin_center';

interface Props {
    visible: boolean,
    title: string,
    successTitle: string,
    company?: ICompany,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,

    onGotoAgencyDashboard: () => void,
    onGotoCreated: (id: string, name: string) => void,
}

const CreateCompanyPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        company,
        onClose,
        onDissmiss,
        onSuccessClose,
        onGotoAgencyDashboard,
        onGotoCreated,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const createState = useAppSelector((state) => selectManageCompanyCreateState(state));
    const updateState = useAppSelector((state) => selectManageCompanyUpdateState(state));
    const uploadState = useAppSelector((state) => selectManageCompanyUploadState(state));
    const fileURLState = useAppSelector((state) => selectManageCompanyFileURLState(state));
    const accountManagersListState = useAppSelector((state) => selectAccountManagersListState(state));
    const recruitersListState = useAppSelector((state) => selectRecruitersListState(state));
    const businessSectorListState = useAppSelector((state) => selectBusinessSectorListState(state));
    const competitorsListState = useAppSelector((state) => selectCompetitorsListState(state));

    const skillSetListState = useAppSelector((state) => selectSkillSetListState(state));
    const credentialsListState = useAppSelector((state) => selectCredentialsListState(state));
    const certificationsListState = useAppSelector((state) => selectCertificationsListState(state));
    const educationListState = useAppSelector((state) => selectEducationListState(state));
    const requiredEquipmentListState = useAppSelector((state) => selectRequiredEquipmentListState(state));

    /// list
    const accountManagersList = useAppSelector((state) => selectAccountManagersList(state));
    const recruitersList = useAppSelector((state) => selectRecruitersList(state));
    const businessSectorList = useAppSelector((state) => selectBusinessSectorList(state));
    const competitorsList = useAppSelector((state) => selectCompetitorsList(state));
    const actionRef = useRef<ActionDialogHolderType>(null);

    /// skills
    const skillSetList = useAppSelector((state) => selectSkillSetList(state));
    const credentialsList = useAppSelector((state) => selectCredentialsList(state));
    const certificationsList = useAppSelector((state) => selectCertificationsList(state));
    const educationList = useAppSelector((state) => selectEducationList(state));
    const requiredEquipmentList = useAppSelector((state) => selectRequiredEquipmentList(state));

    ///Files
    const [companyLogo, setCompanyLogo] = useState<File | null>(null);

    ///Company Status
    const companyStatusState = useAppSelector((state) => selectCompanyStatusState(state));
    const companyStatusList = useAppSelector((state) => selectCompanyStatusList(state));

    useEffect(() => {
        getData();
        dispatch(getSkillSetList());
        dispatch(getCertificationsList());
        dispatch(getCredentialsList());
        dispatch(getEducationList());
        dispatch(getRequiredEquipmentList());
        dispatch(getCompanyStatus());
    }, [])

    useEffect(() => {
        if (createState.loading == LoadingType.succeeded) {
            if (createState.response) handleNextStep(createState.response);
        }
        if (updateState.loading == LoadingType.succeeded) {
            if (updateState.response) handleNextStep(updateState.response);
        }
        return () => { }
    }, [createState.loading, updateState.loading])

    useEffect(() => {
        if (fileURLState.loading == LoadingType.succeeded) {
            if(fileURLState.response) dispatch(uploadCompanyLogoThunk({data: fileURLState.response, photo: companyLogo }));
        }
        return () => { }
    }, [fileURLState.loading])

    useEffect(() => {
        if (_isFileUploaded() == LoadingType.succeeded) {
            if(props.company && props.company.id) closePopup(onSuccessClose);
        }
        return () => { }
    }, [_isFileUploaded()])

    function _isFileUploaded(): LoadingType {
        return uploadState.loading ;
    }
    
    function handleNextStep(response: ICreateResponse) {
        if (companyLogo != null) {
            dispatch(getCompanyLogoUploadURLThunk({ object_id: response.object_id, object_type: ProfilePictureType.companies, file_name: companyLogo.name }));
        } else if (props.company && props.company.id) {
            dispatch(postSnackbarMessage(response.status ?? null));
            closePopup(onSuccessClose);
        } else {
            // ignore
        }
        // dispatch(postSnackbarMessage(response.status ?? null));
        // closePopup(onSuccessClose);
    }

    function getData() {
        dispatch(getAccountManagers({ role: ROLE.accountManager, branch_id: '' }));
        dispatch(getRecruiters({ role: ROLE.recruiter, branch_id: '' }));
        dispatch(getBusinessSectors());
        dispatch(getCompetitorsList());
    }

    function isInitializing() {
        return accountManagersListState.loading === LoadingType.pending ||
            recruitersListState.loading === LoadingType.pending ||
            businessSectorListState.loading === LoadingType.pending ||
            competitorsListState.loading === LoadingType.pending ||
            skillSetListState.loading === LoadingType.pending ||
            credentialsListState.loading === LoadingType.pending ||
            certificationsListState.loading === LoadingType.pending ||
            educationListState.loading === LoadingType.pending ||
            requiredEquipmentListState.loading === LoadingType.pending ||
            companyStatusState.loading === LoadingType.pending;
    }

    function isLoading() {
        return createState.loading ||
            updateState.loading ||
            fileURLState.loading ||
            uploadState.loading;
    }

    function isError() {
        return createState.error ||
            updateState.error ||
            fileURLState.error ||
            uploadState.error;
    }

    const handleClosePopup = () => {
        if (createState?.loading == LoadingType.succeeded || updateState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(createState.response?.status ?? null));
            return closePopup(onSuccessClose)
        }
        return closePopup(onClose)
    }


    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClosePopup}>
                {isInitializing() ? <div className="loading-spinner"><SpinnerScreen /></div> : <CompanyForm
                    title={props.title}
                    successTitle={props.successTitle}
                    currentLoginUserName={`${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`}
                    company={props.company}
                    accountManagers={accountManagersList}
                    recruiters={recruitersList}
                    businessSectors={businessSectorList}
                    competitors={competitorsList}
                    loading={isLoading()}
                    error={isError()}
                    onClearError={handleClearError}
                    onClose={() => closePopup(onClose)}
                    onSubmit={handleSubmit}
                    skillSetList={skillSetList}
                    credentialsList={credentialsList}
                    certificationsList={certificationsList}
                    educationList={educationList}
                    requiredEquipmentList={requiredEquipmentList}
                    selectedPhoto={companyLogo}
                    onPhotoChange={setCompanyLogo}
                    onRemovePhoto={() => setCompanyLogo(null)}

                    onGotoAgencyDashboard={handleGotoAgencyDashboard}
                    onCreateNew={handleCreateNew}
                    onGotoCreated={handleGotoCreated}
                    CompanyStatus={companyStatusList}
                />}
            </DialogWrapper>
        </ActionDialogHolder>

    );

    ///Handle clear error
    function handleClearError() {
        dispatch(manageCompanyActions.clearManageCompanyStateError());
    }

    /// handle submit
    function handleSubmit(doc: { forceAdd: boolean, value: ICreateCompany }) {
        if (props.company && props.company.id) {
            dispatch(updateCompany({ ...props.company, ...doc.value }));
        }
        else {
            dispatch(createCompany(doc));
        }
    }

    function handleGotoAgencyDashboard(){
        closePopup(onGotoAgencyDashboard);
    }


    function handleCreateNew() {
        setCompanyLogo(null);
        dispatch(getCompaniesList());
        dispatch(manageCompanyActions.clearManageCompanyState());
    }


    function handleGotoCreated(name: string) {
        if (props.company && props.company.id) {
            closePopup(onSuccessClose);
        }else if(createState.response && createState.response.object_id) {
            closePopup(() => props.onGotoCreated(createState?.response?.object_id!, name));
        }
    }


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default CreateCompanyPopup;