import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { MarketingCampaign } from "../../../../interfaces/marketing_campaign";
import { IReportCenterUploadUrlResponse } from "../../../../interfaces/report_center";

export const getMarketingSummaryReportList = createAsyncThunk<
    Array<MarketingCampaign>,
    { is_completed: boolean, start_date: number, end_date: number },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/marketing_summary_report_list/post",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getMarketingSummaryReportGetByUsers(payload.is_completed, payload.start_date, payload.end_date);
            if (typeof res.data === "string") return [];
            const arr = res.data;
            return arr as MarketingCampaign[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getMarketingSummaryReportDownloadUrl = createAsyncThunk<
    IReportCenterUploadUrlResponse,
    { is_completed: boolean, start_date: number, end_date: number, download: boolean, users?: Array<string> },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@report_center/marketing_summary_report_list/download/post',
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getMarketingSummaryReportDownloadUrl(payload.is_completed, payload.start_date, payload.end_date, payload.download, payload.users);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);