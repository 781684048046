import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import CustomFormSelect, { CustomMultiFormSelect } from '../../../../components/form_selector/form_select';
import { LoadingType, THEME } from '../../../../enums';
import { ICompanySalesProfile, ICompanyUpdateSalesProfile } from '../../../../interfaces';
import { getAdminCenterBusinessSector, selectBusinessSectorState } from '../../../../redux/admin_center';
import { getCompanySalesProfile, getCompetitors, _saveCompanySalesProfile } from '../../../../redux/store';
import { manageCompanyProfileActions } from '../../../../redux/companies/universal_directory/company_profile/company_profile_reducer';
import { selectCompanySalesProfile, selectCompetitorsData, selectSave_state } from '../../../../redux/companies/universal_directory/company_profile/company_profile_selector';
import { postSnackbarMessage, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { convertNumberToMoney, CustomButton, formatToMoney, FormInput, SpinnerScreen } from '../../../../utils';
import './company_sales_profile_page.scss';
import { cloneDeep } from 'lodash';
import ToggleSwitch from '../../../../components/toggle_switch/toggle_switch';

interface Props extends RouteComponentProps<any> { }

const PageLoading = (props) => {
    return (
        <div className="table-loading">
            <SpinnerScreen />
        </div>
    );
}

const CompanySalesProfilePage: React.FunctionComponent<Props> = (props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { history, location, match } = props;
    const { params } = match;
    const { loading, error, salesProfile } = useAppSelector(state => selectCompanySalesProfile(state));
    const saveResponse = useAppSelector((state) => selectSave_state(state));
    const businessSector = useAppSelector(state => selectBusinessSectorState(state));
    const competitors = useAppSelector((state) => selectCompetitorsData(state));
    const [profileData, setProfileData] = useState({} as ICompanyUpdateSalesProfile);
    const [businessSectors, setBusinessSectors]: any = useState([]);
    // const [competitorsData, setCompetitorsData]: any = useState([]);
    const [selectedList, setSelectedList]: any = useState([]);
    const [saveInitiated, setSaveInitiated] = useState(false);
    const [usesStaffingAgency, setUsesStaffingAgency] = useState(false);

    useEffect(() => {
        dispatch(getCompetitors());
        dispatch(getAdminCenterBusinessSector());
        if (params && params.id) {
            dispatch(manageCompanyProfileActions.clearSalesProfileState());
            dispatch(getCompanySalesProfile({ companyId: params.id, departmentId: params.departmentId }));
        }
    }, [params.id, params.departmentId]);


    useEffect(() => {
        if (businessSector && businessSector.dataList?.length > 0) {
            setBusinessSectors(businessSector.dataList);
        }
    }, [businessSector]);

    useEffect(() => {
        if (params && params.id === salesProfile.company_id && salesProfile && salesProfile.company_id) {
            setUsesStaffingAgency(salesProfile && salesProfile.uses_staffing_agency ? true : false);
            updateForm(salesProfile);
            const data: any = [];
            if (salesProfile && salesProfile.staffing_agency_competitors) {
                salesProfile?.staffing_agency_competitors.map((h: any) => {
                    data.push(h);
                });
            }
            setSelectedList(data);
        }
    }, [salesProfile]);


    useEffect(() => {
        if (saveResponse.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(saveResponse.response ?? null));
            dispatch(manageCompanyProfileActions.clearSaveState());
        }
        return () => { }
    }, [saveResponse.loading]);

    const handleSaveClick = () => {
        setSaveInitiated(true);
        const payload: ICompanySalesProfile = {
            ...profileData,
            uses_staffing_agency: usesStaffingAgency,
            staffing_agency_competitors: usesStaffingAgency ? profileData.staffing_agency_competitors : [],
            company_annual_revenue: convertNumberToMoney(profileData.company_annual_revenue, 'number') as number,
            annual_revenue_spend_for_staffing_service: convertNumberToMoney(profileData.annual_revenue_spend_for_staffing_service, 'number') as number,
            annual_gross_profit_for_staffing_service: convertNumberToMoney(profileData.annual_gross_profit_for_staffing_service, 'number') as number,
        }
        dispatch(_saveCompanySalesProfile(payload));
    }


    function updateForm(value: ICompanySalesProfile) {
        setProfileData({
            ...value,
            company_annual_revenue: convertNumberToMoney(value.company_annual_revenue, 'string') as string,
            annual_revenue_spend_for_staffing_service: convertNumberToMoney(value.annual_revenue_spend_for_staffing_service, 'string') as string,
            annual_gross_profit_for_staffing_service: convertNumberToMoney(value.annual_gross_profit_for_staffing_service, 'string') as string
        });
    }



    function isLoading() {
        return loading === LoadingType.pending || competitors.loading === LoadingType.pending || businessSector.loading === LoadingType.pending;
    }

    return (
        <>
            {
                isLoading() ? <PageLoading /> :
                    <div className="ac-sales-profile-container">
                        {/* <div className="ac-sales-profile-header">
                    <div className="ac-sales-profile-headerText">{profileData.company_name}</div>
                </div>
                <div className="Line-68"></div> */}
                        <div className="ac-sales-profile-content">
                            {/* <div className="ac-sp-field-row row1"> */}
                            <div className='ac-sc-row1'>
                                <div className="ac-sc-fields">
                                    <FormInput
                                        id={'company_annual_revenue'}
                                        onChange={(field, value) => {
                                            if (value !== null) {
                                                setProfileData((prevState) => ({
                                                    ...prevState,
                                                    company_annual_revenue: value,
                                                }));
                                            }
                                        }}
                                        required={false}
                                        placeholder={""}
                                        type={"text"}
                                        value={convertNumberToMoney(profileData.company_annual_revenue, "string") as string}
                                        label={"Company Annual Revenue"}
                                        prefix={<span>{'$'}</span>}
                                        onBlur={formatToMoney}
                                        onKeyDown={formatToMoney}
                                        onKeyUp={formatToMoney}
                                    />
                                </div>
                                <div className="ac-sc-fields">
                                    <FormInput
                                        id={'number_of_full_time_staff'}
                                        onChange={(field: any, value: any) => {
                                            if (value !== null) {
                                                setProfileData((prevState: any) => ({
                                                    ...prevState,
                                                    number_of_full_time_staff: value,
                                                }));
                                            }
                                        }}
                                        required={false}
                                        placeholder={""}
                                        type={"number"}
                                        value={profileData.number_of_full_time_staff}
                                        label={"How many full time employees does this Company have?"}
                                    />
                                </div>
                                <div className="ac-sc-fields">
                                    <CustomFormSelect
                                        customStyle={{ width: '-webkit-fill-available' }}
                                        name={"industry"}
                                        label={"Industry\n Type of placements your agency will make with this Company"}
                                        placeholder={"Select"}
                                        value={profileData.industry}
                                        list={businessSectors.map(doc => ({ label: doc.business_sector, value: doc.id }))}
                                        onChange={(value) => {
                                            setProfileData((prevState: any) => ({
                                                ...prevState,
                                                industry: value,
                                            }));
                                        }
                                        }
                                        menuPlacement= {"top"}
                                    />
                                </div>
                                <div className="ac-sc-fields">
                                    <FormInput
                                        id={'annual_revenue_spend_for_staffing_service'}
                                        onChange={(field, value) => {
                                            if (value !== null) {
                                                setProfileData((prevState) => ({
                                                    ...prevState,
                                                    annual_revenue_spend_for_staffing_service: value,
                                                }));
                                            }
                                        }}
                                        required={false}
                                        placeholder={""}
                                        type={"text"}
                                        value={convertNumberToMoney(profileData.annual_revenue_spend_for_staffing_service, "string") as string}
                                        label={t("estimated_annual_revenue_spend_for_staffing_services")}
                                        prefix={<span>{'$'}</span>}
                                        onBlur={formatToMoney}
                                        onKeyDown={formatToMoney}
                                        onKeyUp={formatToMoney}
                                    />
                                </div>
                                <div className="ac-sc-fields">
                                    <FormInput
                                        id={'annual_gross_profit_for_staffing_service'}
                                        onChange={(field, value) => {
                                            if (value !== null) {
                                                setProfileData((prevState) => ({
                                                    ...prevState,
                                                    annual_gross_profit_for_staffing_service: value,
                                                }));
                                            }
                                        }}
                                        required={false}
                                        placeholder={""}
                                        type={"text"}
                                        value={convertNumberToMoney(profileData.annual_gross_profit_for_staffing_service, "string") as string}
                                        label={t("estimated_annual_gross_profit_for_staffing_services")}
                                        prefix={<span>{'$'}</span>}
                                        onBlur={formatToMoney}
                                        onKeyDown={formatToMoney}
                                        onKeyUp={formatToMoney}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="ac-sc-fields-row2">
                                    <div className='ac-sc-field-one'>
                                        <FormInput
                                            id={'industry_averages'}
                                            onChange={(field: any, value: any) => {
                                                if (value !== null) {
                                                    setProfileData((prevState: any) => ({
                                                        ...prevState,
                                                        industry_averages: value,
                                                    }));
                                                }
                                            }}
                                            required={false}
                                            placeholder={""}
                                            type={"text"}
                                            multiline={true}
                                            value={profileData.industry_averages}
                                            label={"Based on Industry averages here are some assumptions"}
                                        />
                                    </div>
                                    <div className='ac-sc-field-two'>
                                        <ToggleSwitch
                                            label={t('does_this_company_or_department_use_staffing_agencies')}
                                            labelPosition={'top'}
                                            checked={usesStaffingAgency ? true : false}
                                            onChange={(value) => setUsesStaffingAgency(value)}
                                        />

                                        <div style={{ display: usesStaffingAgency ? "inline" : "none" }}>
                                            <CustomMultiFormSelect
                                                customStyle={{ width: '-webkit-fill-available' }}
                                                name={"staffing_agency_competitors"}
                                                label={"If yes,staffing Agencies does this Company works with?"}
                                                placeholder={"Select"}
                                                value={profileData.staffing_agency_competitors}
                                                list={competitors.data.map(doc => ({ label: doc.competitor_name, value: doc.id }))}
                                                onChange={(value) => {
                                                    const selectedListClone = cloneDeep(selectedList);
                                                    selectedListClone.push(value);
                                                    setSelectedList(selectedListClone);
                                                    setProfileData((prevState: any) => ({
                                                        ...prevState,
                                                        staffing_agency_competitors: value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='ac-sc-fields-row2'>
                                    <div className='ac-sc-field-one'>
                                        <FormInput
                                            id={'profile_mystique'}
                                            onChange={(field: any, value: any) => {
                                                if (value !== null) {
                                                    setProfileData((prevState: any) => ({
                                                        ...prevState,
                                                        profile_mystique: value,
                                                    }));
                                                }
                                            }}
                                            required={false}
                                            placeholder={""}
                                            type={"text"}
                                            multiline={true}
                                            value={profileData.profile_mystique}
                                            label={t("profile_mystique")}
                                            customInputStyle={{ width: "18vw" }}
                                        />
                                    </div>
                                    <div className='ac-sc-field-two'>
                                        <ToggleSwitch
                                            label={t('have_we_presented_our_services_to_this_company_or_contact')}
                                            labelPosition={'top'}
                                            checked={profileData.offered_our_services}
                                            onChange={(value) => {
                                                setProfileData({
                                                    ...profileData,
                                                    offered_our_services: value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='ac-sc-fields-row2'>
                                    <div className='ac-sc-field-one'>
                                        <FormInput
                                            id={'summary'}
                                            onChange={(field: any, value: any) => {
                                                if (value !== null) {
                                                    setProfileData((prevState: any) => ({
                                                        ...prevState,
                                                        summary: value,
                                                    }));
                                                }
                                            }}
                                            required={false}
                                            placeholder={""}
                                            type={"text"}
                                            multiline={true}
                                            value={profileData.summary}
                                            label={t("how_did_we_hear_about_this_company")}
                                        />
                                    </div>
                                    <div className='ac-sc-field-two'>
                                        <FormInput
                                            id={'additional_notes'}
                                            onChange={(field: any, value: any) => {
                                                if (value !== null) {
                                                    setProfileData((prevState: any) => ({
                                                        ...prevState,
                                                        additional_notes: value,
                                                    }));
                                                }
                                            }}
                                            required={false}
                                            placeholder={""}
                                            type={"text"}
                                            multiline={true}
                                            value={profileData.additional_notes}
                                            label={"Additional Notes "}
                                            customInputStyle={{ width: "18vw" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="add-role-action-holder">
                            <div className="add-role-action">
                                <div className="btn-no">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={t('cancel')}
                                        enable={true}
                                        backgroundColor={THEME.defaultActionColor}
                                        onClick={() => {
                                            updateForm(salesProfile);
                                            setUsesStaffingAgency(salesProfile && salesProfile.uses_staffing_agency ? true : false);
                                        }}
                                    />
                                </div>
                                <div className="btn-yes">
                                    <CustomButton
                                        loading={saveResponse.loading === LoadingType.pending}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={'Save'}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={handleSaveClick}
                                        enable={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}


export default CompanySalesProfilePage;
