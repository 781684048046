import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccountAPI, TalentAPI,  } from "../../../../apis";
import { AppError, ITalent, } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getTalentList = createAsyncThunk<
    Array<ITalent>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/universal-directory/talent/get',
    async (_, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalents();
            if(typeof res.data === 'string') return [];
            return res.data as ITalent[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);