import { t } from "i18next";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import SearchBar from "../../../../../components/search_bar";
import SortableTable, {
  TableData,
} from "../../../../../components/sortable_table/sortable_table";
import { LoadingType, THEME } from "../../../../../enums";
import {
  SpinnerScreen,
  CustomButton,
  getPlusBtnIcon,
} from "../../../../../utils";
import { useEffect, useState } from "react";
import { postSnackbarMessage, selectManageDeductionCreateState, selectManageDeductionState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { getThirdPartyDeductionType } from "../../../../../redux/back_office/third_party_deductions/deduction_type/deduction_type_thunk";
import {
  selectThirdPartyDeductionTypeList,
  selectThirdPartyDeductionTypeListState,
} from "../../../../../redux/back_office/third_party_deductions/deduction_type/deduction_type_selector";
import "./deduction_authority.scss";
import CreateDeductionPopup from "../../../popups/create_deduction/create_deduction";
import { Portal } from "react-portal";
import { AppRoutes } from "../../../../../routes";
import { ThirdPartyDeductionType } from "../../../../../interfaces/third_party_deduction_type";
import { createDeduction } from "../../../../../redux/back_office/third_party_deductions/manage_deductions/manage_deduction_type_thunk";
import { manageDeductionActions } from "../../../../../redux/back_office/third_party_deductions/manage_deductions/manage_deduction_type_reducer";
import { useTranslation } from "react-i18next";

export const DeductionAuthorityDashboard = (props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const thirdPartyDeductionTypeListState = useAppSelector(
    selectThirdPartyDeductionTypeListState
  );
  const thirdPartyDeductionTypeList = useAppSelector(
    selectThirdPartyDeductionTypeList
  );

  const createDeductionState = useAppSelector((state) => selectManageDeductionCreateState(state));
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [addNewDeduction, setAddNewDeduction] = useState(false);

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (createDeductionState && createDeductionState.loading === LoadingType.succeeded) {
      setAddNewDeduction(false);
      dispatch(postSnackbarMessage(createDeductionState.response ?? null));
      dispatch(manageDeductionActions.clearManageDeductionState());
      dispatch(getThirdPartyDeductionType());

    }
  }, [createDeductionState, createDeductionState.loading])
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    dispatch(getThirdPartyDeductionType());
  }, []);

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  const tableHeader = [
    { title: "deduction_entity_name", code: "value" },
    { title: "type_of_deduction", code: "type_of_deduction" },
    { title: "status", code: "status" },
    { title: "website", code: "website" },
    { title: "city", code: "city" },
    { title: "state", code: "state" },
    { title: "zipcode", code: "zipcode" },
    { title: "notes", code: "notes" },
  ];

  function getFlexNumber(value: number) {
    if (value === 0) return 1;
    if (value === 1) return 1;
    if (value === 2) return 0.5;
    if (value === 3) return 0.8;
    if (value === 4) return 0.6;
    if (value === 5) return 0.4;
    if (value === 6) return 0.5;
    if (value === 7) return 0.5;
    return 1;
  }

  function getFilteredList() {
    let list = thirdPartyDeductionTypeList;
    if (sortedField != null && thirdPartyDeductionTypeList) {
      list = [...thirdPartyDeductionTypeList].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] !== undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }
    if (selectedStatus.trim().length !== 0 && selectedStatus !== "All")
      list = list?.filter((doc) => doc.status === selectedStatus);
    return list?.filter((doc) => {
      const str = search.trim().toLowerCase();
      const statusFilter = doc.status
        ? doc.status.toString().trim().toLowerCase().includes(str)
        : false;
      const valueFilter = doc.value
        ? doc.value.toString().trim().toLowerCase().includes(str)
        : false;
      const deductionTypeFilter = doc.type_of_deduction
        ? doc.type_of_deduction.toString().trim().toLowerCase().includes(str)
        : false;
      const websiteFilter = doc.website
        ? doc.website.toString().trim().toLowerCase().includes(str)
        : false;
      const cityFilter = doc.city
        ? doc.city.toString().trim().toLowerCase().includes(str)
        : false;
      const stateFilter = doc.state
        ? doc.state.toString().trim().toLowerCase().includes(str)
        : false;
      const zipcodeFilter = doc.zipcode
        ? doc.zipcode.toString().trim().toLowerCase().includes(str)
        : false;
      return (
        statusFilter ||
        valueFilter ||
        deductionTypeFilter ||
        websiteFilter ||
        cityFilter ||
        stateFilter ||
        zipcodeFilter
      );
    });
  }

  const selectStatusList = [
    { value: "All", label: "All" },
    { value: "ACTIVE", label: "Active", color: "green" },
    { value: "INACTIVE", label: "Inactive", color: "red" },
  ];

  const handleAddNewDeductionSuccess = (data: ThirdPartyDeductionType) => {
    dispatch(createDeduction(data));
  };

  const handleSelectStatus = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    setSearch("");
  };

  const handleDeductionSelect = (value) => {
    props.history.push({
      pathname: `${AppRoutes.deductionAuthoritiyDetailedPage}/${value.id}`,
      state: {
        id: value.id,
        name: value.value,
      },
    });
    return;
  };

  const getStatusStyle = (deductionStatus) => {
    if (deductionStatus) {
      return deductionStatus.toUpperCase() === "ACTIVE"
        ? { color: "green" }
        : { color: "red" };
    }
    return;
  };

  const getStatusText = (deductionStatus) => {
    if (deductionStatus) {
      return deductionStatus.toUpperCase() === "ACTIVE"
        ? "Active"
        : "Inactive";
    }
    return "";
  };

  return (
    <div className="deduction-authority-dashboard-container">
      <>
        <div className="deduction-authority-dashboard-header">
          <div>
            <CustomFormSelect
              name={t("status")}
              list={selectStatusList}
              onChange={(val) => handleSelectStatus(val)}
              value={selectedStatus}
              placeholder={t("Select")}
              label={t("status")}
              customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}
            />
          </div>
          <div className="right-col">
            <div>
              <CustomButton
                leftIcon={getPlusBtnIcon()}
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={"Add " + t("third_party_deduction_authority")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => setAddNewDeduction(true)}
              />
            </div>
            <div className="ml search-bar">
              <SearchBar
                value={search}
                onChange={handleSearch}
                onSearch={() => { }}
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <div className="deduction-authority-dashboard-content">
          <div className="deduction-authority-dashboard-table">
            <SortableTable
              headerList={tableHeader}
              sortedField={sortedField}
              onSortChange={handleSortFieldChange}
              flexNumber={getFlexNumber}
              isAsc={sortDirection}
            >
              {thirdPartyDeductionTypeListState.loading === LoadingType.pending
                ?

                <SpinnerScreen />
                :
                <>
                  <>
                    {getFilteredList() && getFilteredList()?.length! > 0 ? (
                      <>
                        {getFilteredList()?.map((doc, index) => {
                          return (
                            <tr key={doc.id}>
                              <TableData
                                onClick={() => handleDeductionSelect(doc)}
                                isButton={true}
                                customStyle={{ flex: getFlexNumber(0) }}
                              >
                                <span>{doc.value}</span>
                              </TableData>
                              <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                <span>{doc.type_of_deduction}</span>
                              </TableData>
                              <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                <span style={getStatusStyle(doc.status)}>
                                  {getStatusText(doc.status)}
                                </span>
                              </TableData>
                              <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                <span>{doc.website}</span>
                              </TableData>
                              <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                {doc.city}
                              </TableData>
                              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                <span>{doc.state}</span>
                              </TableData>
                              <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                <span>{doc.zipcode}</span>
                              </TableData>
                              <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                <span>{doc.notes}</span>
                              </TableData>
                            </tr>
                          );
                        })}
                      </>
                    )
                      :
                      <>
                        <tr>
                          <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                            <div className="na_div" style={{ width: "100%", fontSize: "1vw", textAlign: "center" }}>
                              {t("no_data_available")}
                            </div>
                          </TableData>
                        </tr>
                      </>
                    }

                  </>
                </>
              }
            </SortableTable>
          </div>
        </div>
        {addNewDeduction && (
          <Portal>
            <CreateDeductionPopup
              visible={addNewDeduction}
              title={"New " + t("third_party_deduction_authority")}
              onClose={() => {
                setAddNewDeduction(false);
                dispatch(manageDeductionActions.clearManageDeductionState());
              }}
              onSubmit={handleAddNewDeductionSuccess}
              state={createDeductionState}
            />
          </Portal>
        )}
      </>
    </div>
  );
};
