import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IProfileDropdown } from "../../../interfaces";
import * as thunk from "./role_users_list_thunk";

export interface RoleUsersListState extends EntityState<IProfileDropdown> {
    error?: AppError | null,
    loading: LoadingType,
}

const roleUsersListAdapter: EntityAdapter<IProfileDropdown> = createEntityAdapter<IProfileDropdown>({
    selectId: (doc) => doc.id,
})

const initialState: RoleUsersListState = roleUsersListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const roleUsersListSlice = createSlice({
    name: 'roleUsersList',
    initialState: initialState,
    reducers: {
        clearRoleUserListError(state){
            return {...state, error: null};
        },
        clearRoleUserListState(state){
            return roleUsersListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getRoleUsersList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getRoleUsersList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getRoleUsersList.fulfilled, (state, action) => roleUsersListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearRoleUserListError, clearRoleUserListState } = roleUsersListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = roleUsersListAdapter.getSelectors();
export const selectState = (state: RoleUsersListState) => state;
export const selectLoading = (state: RoleUsersListState) => state.loading;
export const selectError = (state: RoleUsersListState) => state.error;


