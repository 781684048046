export const yesNo = [
    { id: "Y", value: "Y - Yes" },
    { id: "N", value: "N - No" }
];

export const cashServiceLevel = [
    { id: "F", value: "F - Full" },
    { id: "V", value: "V - Vacancies Only" }
];

export const employerKind = [
    { id: "F", value: "F - Federal Government" },
    { id: "S", value: "S - State & Local Government" },
    { id: "T", value: "T - Tax Exempt" },
    { id: "Y", value: "Y - State & Local Tax Exempt" },
    { id: "N", value: "N - None of the above" }
];

export const feinType = [
    { id: "Y", value: "Y - Applied For" },
    { id: "N", value: "N - Registered" },
    { id: "P", value: "P - Common Pay Parent" },
    { id: "C", value: "C - Common Pay Child without a FEIN" }
];

export const activeInactive = [
    { id: "A", value: "A - Active" },
    { id: "I", value: "I - Inactive" }
];

export const serviceLevel = [
    { id: "F", value: "F - Full service; payments and tax returns will be produced" },
    { id: "R", value: "R - Return only; tax returns will be produced" },
    { id: "B", value: "B - Balance only; no payments or tax returns will be produced" }
];

export const einType = [
    { id: "Y", value: "Y - Applied For" },
    { id: "N", value: "N - Registered" },
    { id: "I", value: "I - Reimbursable" },
    { id: "E", value: "E - Exempt" },
    { id: "P", value: "P - Common Pay Parent" },
    { id: "C", value: "C - Common Pay Child" },
    { id: "5", value: "5 - Michigan 501 Alliance" }
];

export const companyTaxServiceLevel = [
    { id: "R", value: "R - Return only; tax returns will be produced" },
    { id: "B", value: "B - Balance only; no payments or tax returns will be produced" }
];

export const companyPaymentMethod = [
    {id: 1, value: "01 - Check"},
    {id: 21, value: "21 - EFT Debit"},
    {id: 22, value: "22 - EFT Debit Touchtone"},
    {id: 23, value: "23 - EFT Debit Online Hand Key"},
    {id: 24, value: "24 - EFT Debit in a Standalone Payment File"},
    {id: 25, value: "25 - EFT Debit within the Return e-File"}
];

export const accountType = [
    { id: "C", value: "C - Checking" },
    { id: "S", value: "S - Savings" }
];