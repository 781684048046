import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../apis/back_office_api";
import { AppError } from "../../../interfaces";

import { CompaniesAPI } from "../../../apis";
import { catchRequestError } from "../../../utils";


export const mailBOPaycheckThunk = createAsyncThunk<
    string,
    string[],
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/paycheck_directory/mail_paycheck/post",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.mailPaycheck(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


export const mailBOInvoicesThunk = createAsyncThunk<
    string,
    string[],
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing_invoice/billing_invoice_report/mail_invoices/post",
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.mailInvoices(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });
