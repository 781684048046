import { useTranslation } from 'react-i18next';
import CustomPab from '../../../../components/custom_pab/custom_pab';
import { EmptyList, ErrorHandler, LoadingHandler } from '../../../../components/error_handler/error_handler';
import { PREFERED_CONTACT } from '../../../../enums';
import { EmailBlueIcon, CallIcon, ChatIcon, FacebookIcon, IndeedIcon, LinkedInIcon, ZipRecruiterChairIcon, IndeedLogoIcon, InPerson, Teams, Zoom, GoogleMeet } from '../../../../icons';
import { AppError, ISalesCall } from '../../../../interfaces';
import { formatAMPM, getDateFromTimeStamp, getDatesBetween, getDateString, WEEK_DAYS } from '../../../../variables';
import './sales_calls_list.scss';
import { Dispatch, SetStateAction } from 'react';

/// [Sales board] list
interface SalesCallsListProps {
    list: ISalesCall[],
    loading: boolean,
    error?: AppError | null,
    onClick: (value: ISalesCall) => void,
    onRefresh: () => void,
    salesUsers: string[];
    salesFormState: { available_from: number, available_to: number };
    salesCallLength:Dispatch<SetStateAction<number>>;
}

const SalesCallsList: React.FunctionComponent<SalesCallsListProps> = (props) => {
    const { t } = useTranslation();
    const { list, salesUsers, salesFormState, salesCallLength } = props

    if (props.loading) {
        return (
            <LoadingHandler />
        )
    }

    if (props.error) {
        return (
            <ErrorHandler
                error={props.error}
                onRefresh={props.onRefresh}
            />
        )
    }

    if (props.list.length === 0) {
        return (
            <EmptyList
                title={t('no_sales_calls')}
                onClick={props.onRefresh}
            />
        )
    }

    function getFilteredList() {
        let resultList: ISalesCall[] | undefined;

        if (salesUsers?.length !== 0 && salesFormState.available_from && salesFormState.available_to) {
            resultList = (resultList ?? list).filter((doc) => {
           
                const isScheduleValid =
                    doc.schedule_date_time !== null &&
                    salesFormState.available_from <= doc.schedule_date_time &&
                    doc.schedule_date_time < salesFormState.available_to;
              
                return isScheduleValid && (doc.assigned_to_id !== null ? salesUsers.includes(doc.assigned_to_id!) : (doc.planned_by_id !== null ? salesUsers.includes(doc.planned_by_id!) : (doc.modified_by !== null ? salesUsers.includes(doc?.modified_by!) : salesUsers.includes(doc?.created_by!))));
            });
            salesCallLength(resultList.length)
            if (salesUsers) {
                return resultList
            }         
        }
        if (salesUsers?.length !== 0) {
            resultList = (resultList ?? list).filter((doc) => {
                const userIncluded = (doc.assigned_to_id !== null ? salesUsers.includes(doc.assigned_to_id!) : (doc.modified_by !== null ? salesUsers.includes(doc?.modified_by!) : salesUsers.includes(doc?.created_by!)));
                return userIncluded;
            });
            salesCallLength(resultList.length)
            if (salesUsers) {
                return resultList
            } 
        }
        if (salesFormState.available_from && salesFormState.available_to) {
            resultList = (resultList ?? list).filter((doc) => {
                const isScheduleValid = doc.schedule_date_time !== null &&
                        salesFormState.available_from <= doc.schedule_date_time &&
                        doc.schedule_date_time < salesFormState.available_to;

                return isScheduleValid;
            });
            salesCallLength(resultList.length)
            if (salesFormState.available_from && salesFormState.available_to) {
                return resultList
            }
        }
        salesCallLength(list.length)      
        return list;
    }

    return (
        <div className="sales-calls-list-holder">
            {getFilteredList()?.map((doc, index) => {
                const startDate = getDateFromTimeStamp(doc.schedule_date_time);
                // const endDate = getDate(doc.end_date);
                const dateStr = getDateString(doc.schedule_date_time);
                const startTime = formatAMPM(startDate);
                // const endTime = formatAMPM(endDate);
                return (
                    <CustomPab
                        key={index}
                        onClick={() => props.onClick(doc)}
                        customStyle={{
                            backgroundColor: '#F5FAFC',
                            borderLeft: `4px solid ${'#1196A9'}`,
                            paddingLeft: '1em',
                            marginTop: (index === 0) ? 0 : '1em',
                        }}
                    >
                        <div className="holder">
                            <div className="info" style={{ color: '#1198AB' }}>
                                <div className="date-info" style={{ borderRight: '1px solid #1198AB' }}>
                                    <span style={{ fontWeight: 'bold' }}>{WEEK_DAYS[startDate.getDay()]}</span>
                                    <span style={{ fontWeight: 'bold' }}>{dateStr}</span>
                                    <span style={{ fontSize: '0.625vw' }}>{startTime}</span>
                                </div>
                                <div className="user-info">
                                    <span style={{ fontSize: '0.78125vw', fontWeight: 'bold' }}>{`${doc.contact_first_name ?? ""} ${doc.contact_last_name ?? ""}`}</span>
                                    <span style={{ fontSize: '0.625vw' }}>{getCompanyName(doc)}</span>
                                    <span style={{ fontSize: '0.625vw', textTransform: 'none' }}>{t('assigned_to')}&#x3a;&nbsp;{doc.assigned_to_name ? doc.assigned_to_name : doc.planned_by_name}</span>
                                </div>
                            </div>
                            <div className="actions">
                                {doc.contact_method && getPrefferedContact(doc.contact_method.toUpperCase())}
                            </div>
                        </div>
                    </CustomPab>
                )
            })}
        </div>

    )

    function getCompanyName(doc: ISalesCall) {
        if (doc.company_name && doc.department_name) {
            return `${doc.company_name} - ${doc.department_name}`;
        }
        if (doc.company_name && doc.department_name === null) {
            return doc.company_name;
        }
        if (doc.company_name === null && doc.department_name) {
            return doc.department_name;
        }
        return '--'
    }

    function getPrefferedContact(value: string) {
        if (value === PREFERED_CONTACT.email) {
            return (
                <div className="action-contact-icon">
                    <EmailBlueIcon width={"100%"} height={"100%"} style={{ color: '#1196A9' }} />
                </div>
            );
        }
        if (value === PREFERED_CONTACT.phone) {
            return (
                <div className="action-contact-icon">
                    <CallIcon width={"100%"} height={"100%"} style={{ color: '#1196A9' }} />
                </div>
            );
        }
        if (value === PREFERED_CONTACT.text) {
            return (
                <div className="action-contact-icon">
                    <ChatIcon width={"100%"} height={"100%"} style={{ color: '#1196A9' }} />
                </div>
            );
        }
        if (value === PREFERED_CONTACT.facebook) {
            return (
                <div className="action-contact-icon">
                    <FacebookIcon width={"100%"} height={"100%"} style={{ color: '#1196A9' }} />
                </div>
            );
        }
        if (value === PREFERED_CONTACT.indeed) {
            return (
                <div className="action-contact-icon">
                    <IndeedLogoIcon width={"100%"} height={"100%"} style={{ color: '#1196A9' }} />
                </div>
            );
        }
        if (value === PREFERED_CONTACT.linkedIn) {
            return (
                <div className="action-contact-icon">
                    <LinkedInIcon width={"100%"} height={"100%"} style={{ color: '#1196A9' }} />
                </div>
            );
        }
        if (value === PREFERED_CONTACT.zipRecruiter) {
            return (
                <div className="action-contact-icon">
                    <ZipRecruiterChairIcon width={"100%"} height={"100%"} style={{ color: '#1196A9' }} />
                </div>
            );
        }
        if (value === PREFERED_CONTACT.inperson) {
            return (
                <div className="action-contact-icon">
                    <InPerson width={"100%"} height={"100%"} style={{ color: '#1196A9' }} />
                </div>
            );
        }
        if (value === PREFERED_CONTACT.zoom) {
            return (
                <div className="action-contact-icon">
                    <Zoom width={"100%"} height={"100%"} style={{ color: '#1196A9' }} />
                </div>
            );
        }
        if (value === PREFERED_CONTACT.teams) {
            return (
                <div className="action-contact-icon">
                    <Teams width={"100%"} height={"100%"} style={{ color: '#1196A9' }} />
                </div>
            );
        }
        if (value === PREFERED_CONTACT.googleMeet) {
            return (
                <div className="action-contact-icon gmeet-icon">
                    <GoogleMeet width={"100%"} height={"100%"} style={{ color: '#1196A9' }} className='gmeet'/>
                </div>
            );
        }
        return (<span>{''}</span>);
    }
}

export default SalesCallsList;