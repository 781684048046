import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./agency_toggle_status_thunk";

export interface AgencyToggleStatusState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialState: AgencyToggleStatusState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const agencyToggleStatusSlice = createSlice({
    name: 'agencyStatus',
    initialState: initialState,
    reducers: {
        clearAgencyToggleStatusStateError(state){
            return { ...state, error: null };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.updateAgencyToggleStatus.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null }))
            .addCase(thunk.updateAgencyToggleStatus.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.updateAgencyToggleStatus.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearAgencyToggleStatusStateError } = agencyToggleStatusSlice.actions;
export const selectState = (state: AgencyToggleStatusState) => state;
export const selectLoading = (state: AgencyToggleStatusState) => state.loading;
export const selectError = (state: AgencyToggleStatusState) => state.error;


