import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import CustomCalender from '../../../../../components/custom_calender/custom_calender';
import { ITalent } from '../../../../../interfaces';
import { getDateFromTimeStamp } from '../../../../../variables';
import './availability.scss';

interface Props {
    talent: ITalent,
    onAddAvailability: (value: number) => void,
    onMarkedAlert: () => void,
    customStyle?: React.CSSProperties,
}

const AvailabilityTab: React.FunctionComponent<Props> = (props) => {
    const { params } = useRouteMatch();
    const { t } = useTranslation();

    return (
        <div className="tlnt-dsh-av-container" style={props.customStyle}>
            <CustomCalender
                dates_available={props.talent.dates_available.map((doc) => getDateFromTimeStamp(doc)).reduce((a, v) => ({ ...a, [`${v.getDate()}${v.getMonth()}${v.getFullYear()}`]: v }), {})}
                onClick={props.onAddAvailability}
                onMarkedAlert={props.onMarkedAlert}
            />
        </div>

    );

}

export default AvailabilityTab;