import { useTranslation } from 'react-i18next';
import './interview_question_card.scss';

interface Props {
    doc: {
        talent_interview_question_id: string;
        interview_question: string;
        candidate_answer: string;
        recruiter_notes: string;
    },
    index: number,
}

const InterviewQuestionCard: React.FunctionComponent<Props> = (props) => {
    const {t} = useTranslation();
    const { doc, index } = props;
    return (
        <div className="interview-question-card">
            <div className="question-title">
                <span>{`${index + 1}. ${t(doc.interview_question)}`}</span>
            </div>
            <div className="interview-question-content">
                <div className="candidate-answer qestion-answ">
                    <div className="title-txt">
                        <span>{`${t('candidate_answer').toUpperCase()}:`}</span>
                    </div>
                    <div className="answ-txt">
                        <span>{doc.candidate_answer}</span>
                    </div>
                </div>
                <div className="recruiter-notes qestion-answ">
                    <div className="title-txt">
                        <span>{`${t('recruiter_notes').toUpperCase()}:`}</span>
                    </div>
                    <div className="answ-txt">
                        <span>{doc.recruiter_notes}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InterviewQuestionCard;