
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./mark_up_reducer";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.markUpList;
export const selectMarkUpListState = createSelector(select, selectState);
export const selectMarkUpList = createSelector(
    select,
    selectAll
);
export const selectMarkUpListEntities = createSelector(
    select,
    selectEntities
);