import './custom_calender.scss';
import * as dateFns from "date-fns";
import React, { useState } from 'react';
import StatusBanner from '../status_banner/status_banner';
import { useTranslation } from 'react-i18next';
import { convertDateToTimeStamp } from '../../variables';

interface Props {
    dates_available: { [key: string]: Date },
    onClick: (value: number) => void,
    onMarkedAlert: () => void,
}

const CustomCalender: React.FunctionComponent<Props> = (props) => {
    const { dates_available, onClick } = props;
    const [state, setState] = useState<{ currentMonth: Date, selectedDate: Date, today: Date }>({ currentMonth: new Date(), selectedDate: new Date(), today: new Date() });
    const { t } = useTranslation();
    const onDateClick = (day: Date, isMarked: boolean) => {
        setState({
            ...state,
            selectedDate: day
        });
        if (isMarked) props.onMarkedAlert();
        else props.onClick(convertDateToTimeStamp(day, true));
    };

    const nextMonth = () => {
        setState({
            ...state,
            currentMonth: dateFns.addMonths(state.currentMonth, 1)
        });
    };

    const prevMonth = () => {
        setState({
            ...state,
            currentMonth: dateFns.subMonths(state.currentMonth, 1)
        });
    };

    function renderHeader() {
        const dateFormat = "MMMM yyyy";

        return (
            <div className="cclndr-month-header">
                <div className="cclndr-month-year-txt">
                    <span>{dateFns.format(state.currentMonth, dateFormat).toLowerCase()}</span>
                </div>
            </div>
        );
    }

    function renderDays() {
        const dateFormat = "iiii";
        const days: Array<React.ReactNode> = [];

        let startDate = dateFns.startOfWeek(state.currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="cclndr-col cclndr-col-center" key={i}>
                    {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
                </div>
            );
        }

        return <div className="cclndr-days cclndr-row">{days}</div>;
    }

    function renderCells() {
        const { currentMonth, selectedDate, today } = state;
        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart);
        const endDate = dateFns.endOfWeek(monthEnd);

        const dateFormat = "d";
        const rows: Array<React.ReactNode> = [];

        let days: Array<React.ReactNode> = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);
                const cloneDay = day;
                const sameMonth = dateFns.isSameMonth(day, monthStart);
                const thisMonth = new Date().getMonth();
                const todayDate = new Date().getDate();
                const month = day.getMonth();
                const currentDate = day.getDate();
                const year = day.getFullYear();
                const thisYear = new Date().getFullYear();
                const sameDay = dateFns.isSameDay(day, today);
                const selected = dateFns.isSameDay(day, selectedDate);
                const markedAsAvailable = isAvailabel(day);
                days.push(
                    <button
                        className={`cclndr-col cclndr-cell ${((month < thisMonth) &&  (month != thisMonth) && (thisYear === year) || (thisMonth === month? (currentDate < todayDate) : false))
                            ? "cclndr-disabled"
                            : sameDay ? "cclndr-today" : selected ? "cclndr-selected" : ""
                            }`}
                        key={day.valueOf()}
                        onClick={() => onDateClick(dateFns.parseISO(cloneDay.toISOString()), markedAsAvailable)}
                    >
                        <span className="cclndr-number">{formattedDate}</span>
                        <span className="cclndr-bg">{formattedDate}</span>
                        {markedAsAvailable && <div className="cclndr-available-banner">
                            <StatusBanner
                                title={t('available')}
                                textClassName={'cclndr-status-txt'}
                                containerClassName={'cclndr-status-banner-container'}
                            />
                        </div>}
                    </button>
                );
                day = dateFns.addDays(day, 1);
            }
            rows.push(
                <div className="cclndr-row" key={day.valueOf()}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="cclndr-body">{rows}</div>;
    }


    function isAvailabel(day: Date): boolean {
        if (dates_available[`${day.getDate()}${day.getMonth()}${day.getFullYear()}`]) return true;
        return false;
    }




    return (
        <div className="cclndr-calendar">
            <div className="cclndr-col cclndr-col-left">
                <button className="cclndr-icon" onClick={prevMonth}>chevron_left</button>
            </div>
            <div className="cclndr-calendar-content">
                {renderHeader()}
                {renderDays()}
                {renderCells()}
            </div>
            <div className="cclndr-col cclndr-col-right" onClick={nextMonth}>
                <button className="cclndr-icon">chevron_right</button>
            </div>
        </div>
    )
}

export default CustomCalender;