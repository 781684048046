import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ISalesCall } from "../../../../interfaces";
import * as thunk from "./sales_calls_thunk";

export interface SalesCallsListState extends EntityState<ISalesCall> {
    error?: AppError | null,
    loading: LoadingType,
    openOrders:{
        error?: AppError | null,
        loading: LoadingType,
        response: number | null
    }
}

const salesCallsListAdapter: EntityAdapter<ISalesCall> = createEntityAdapter<ISalesCall>({
    selectId: (doc) => doc.id,
})

const initialState: SalesCallsListState = salesCallsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    openOrders:{
        error:  null,
        loading: LoadingType.idle,
        response: null
    }
})


export const salesCallsListSlice = createSlice({
    name: 'salesCallsList',
    initialState: initialState,
    reducers: {
        clearSalesCallsListError(state) {
            return { ...state, error: null };
        },
        clearSalesCallsListState(state) {
            return salesCallsListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getDashboardSalesCallsList.pending,
                (state, action) => ({
                    ...state,
                    loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
                    error: null,
                })
            )
            .addCase(
                thunk.getDashboardSalesCallsList.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getDashboardSalesCallsList.fulfilled,
                (state, action) => salesCallsListAdapter.setAll({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null
                },
                    action.payload
                )
            )
            ///[total open orders count]
            .addCase(
                thunk.getDashboardTotalOpenOrdersCount.pending,
                (state, action) => ({
                    ...state,
                    openOrders: {
                        ...state.openOrders,
                        loading: LoadingType.pending,
                        error: null,
                    }     
                })
            )
            .addCase(
                thunk.getDashboardTotalOpenOrdersCount.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getDashboardTotalOpenOrdersCount.fulfilled,
                (state, action) => ({
                    ...state,
                openOrders:{
                    ...state.openOrders,
                    loading: LoadingType.succeeded,
                    error: null,
                    response: action.payload
                }

                })
                
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearSalesCallsListError, clearSalesCallsListState } = salesCallsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = salesCallsListAdapter.getSelectors();
export const selectState = (state: SalesCallsListState) => state;
export const selectLoading = (state: SalesCallsListState) => state.loading;
export const selectError = (state: SalesCallsListState) => state.error;
export const selectOpenOrdersState = (state: SalesCallsListState) => state.openOrders;