import { createAsyncThunk } from "@reduxjs/toolkit";
import { getContactSalesProfile, getContactServiceProfile, updateContactSalesProfile, updateContactServiceProfile } from "../../../apis/contacts_api";
import { IContactSalesProfile, IContactServiceProfile } from "../../../entities/ContactInfo";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";

export const getContactSalesProfileThunk = createAsyncThunk<
    IContactSalesProfile,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/universal-directory/contacts/contact-sales-profile/get',
    async (id, thunkAPI) => {
        try {
            const res = await getContactSalesProfile(id);
            if (typeof res.data === 'string') return {};
            return res.data as IContactSalesProfile;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const saveContactSalesProfileThunk = createAsyncThunk<
    string,
    IContactSalesProfile,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/universal-directory/contacts/contact-sales-profile/save',
    async (payload, thunkAPI) => {
        try {
            const res = await updateContactSalesProfile(payload);
            thunkAPI.dispatch(getContactSalesProfileThunk(payload.contact_id));
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getContactServiceProfileThunk = createAsyncThunk<
    IContactServiceProfile,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/universal-directory/contacts/contact-service-profile/get',
    async (id, thunkAPI) => {
        try {
            const res = await getContactServiceProfile(id);
            if (typeof res.data === 'string') return {};
            return res.data as IContactServiceProfile;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const saveContactServiceProfileThunk = createAsyncThunk<
    string,
    IContactServiceProfile,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/universal-directory/contacts/contact-service-profile/save',
    async (payload, thunkAPI) => {
        try {
            const res = await updateContactServiceProfile(payload);
            thunkAPI.dispatch(getContactServiceProfileThunk(payload.contact_id));
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
