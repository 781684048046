import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectArchiveState, selectCompletedCount, selectData, selectRemainingCount, selectSaveState, selectState, selectUpdateState } from "./send_interview_calendar_redux";

const select = (state: IRootState) => state.dashboard.metrics.sendInterviewCalendarInvite;
export const selectDashboardInterviewCalendarInviteState = createSelector(select, selectState);
export const  selectInterviewCalendarInviteList=createSelector(select,selectData);
export const selectInterviewCalendarInviteSaveState=createSelector(select,selectSaveState);
export const selectInterviewCalendarInviteUpdateState=createSelector(select,selectUpdateState);
export const selectInterviewCalendarRemainingCount=createSelector(select,selectRemainingCount);
export const selectInterviewCalendarCompletedCount=createSelector(select,selectCompletedCount);
export const selectArchiveInterviewState=createSelector(select,selectArchiveState);
