import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IOnlineApplicationResponse, IUploadUrlsResponse, Profile } from "../../../interfaces";
import * as thunk from "./submit_application_thunk";

export interface SubmitOnlineApplicationState {
    error?: AppError | null,
    loading: LoadingType,
    response?: IOnlineApplicationResponse | null,
    uploadUrls: {
        error?: AppError | null,
        loading: LoadingType,
        response: IUploadUrlsResponse | null,
    },
    uploadFiles: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }
}

const initialState: SubmitOnlineApplicationState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    uploadUrls: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    uploadFiles: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}


export const submitOnlineApplicationSlice = createSlice({
    name: 'submitOnlineApplication',
    initialState: initialState,
    reducers: {
        clearSubmitApplicationStateError(state) {
            return { ...state, error: null };
        },
        clearUploadUrlsStateError(state) {
            return { ...state, uploadUrls: { ...state.uploadUrls, error: null } };
        },
        clearUploadFilesStateError(state) {
            return { ...state, uploadFiles: { ...state.uploadFiles, error: null } };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.submitOnlineApplicationThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.submitOnlineApplicationThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, response: null, }))
            .addCase(thunk.submitOnlineApplicationThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))

            .addCase(thunk.getFileUploadUrlsThunk.pending, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getFileUploadUrlsThunk.rejected, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.getFileUploadUrlsThunk.fulfilled, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.uploadFilesThunk.pending, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadFilesThunk.rejected, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.uploadFilesThunk.fulfilled, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const submitOnlineApplicationStateActions = submitOnlineApplicationSlice.actions;
export const selectState = (state: SubmitOnlineApplicationState) => state;
export const selectLoading = (state: SubmitOnlineApplicationState) => state.loading;
export const selectError = (state: SubmitOnlineApplicationState) => state.error;

export const selectUploadURLState = (state: SubmitOnlineApplicationState) => state.uploadUrls;
export const selectUploadFileState = (state: SubmitOnlineApplicationState) => state.uploadFiles;

