import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./company_edocs_thunk";
import { AppError, ICompanyEdocs } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

export interface CompanyEDocsListState {
  error?: AppError | null;
  loading: LoadingType;
  response: ICompanyEdocs[] | null;
  add: {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
  },
  mailEdocs: {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
  }
}

const initialState: CompanyEDocsListState = {
  error: null,
  loading: LoadingType.idle,
  response: null,
  add: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  },
  mailEdocs: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  }
};

export const companyEDocsSlice = createSlice({
  name: "companyEdocs",
  initialState: initialState,
  reducers: {
    clearState(state) {
      return { ...initialState };
    },
    clearAddState(state) {
      return { ...state, add: { ...initialState.add } };
    },
    clearAddErrorState(state) {
      return { ...state, add: { ...initialState.add, error: null } };
    },
    clearMailState(state) {
      return { ...state, mailEdocs: { ...initialState.mailEdocs } };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getCompanyEdDocsThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getCompanyEdDocsThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.getCompanyEdDocsThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        response: action.payload,
      }))

      .addCase(thunk.addNewCompanyEdDocsThunk.pending, (state, action) => ({ ...state, add: { ...state.add, loading: LoadingType.pending, error: null } }))
      .addCase(thunk.addNewCompanyEdDocsThunk.rejected, (state, action) => ({ ...state, add: { ...state.add, loading: LoadingType.failed, error: action.error } }))
      .addCase(thunk.addNewCompanyEdDocsThunk.fulfilled, (state, action) => ({ ...state, add: { ...state.add, loading: LoadingType.succeeded, error: null, response: action.payload } }))
      
      .addCase(thunk.mailCompanyEdocsThunk.pending, (state, action) => ({ ...state, mailEdocs: { ...state.mailEdocs, loading: LoadingType.pending, error: null } }))
      .addCase(thunk.mailCompanyEdocsThunk.rejected, (state, action) => ({ ...state, mailEdocs: { ...state.mailEdocs, loading: LoadingType.failed, error: action.error } }))
      .addCase(thunk.mailCompanyEdocsThunk.fulfilled, (state, action) => ({ ...state, mailEdocs: { ...state.mailEdocs, loading: LoadingType.succeeded, error: null, response: action.payload } }))
      
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const companyEdocsListActions = companyEDocsSlice.actions;
export const selectState = (state: CompanyEDocsListState) => state;
export const selectAll = (state: CompanyEDocsListState) => state.response;
