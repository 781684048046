import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectAll, selectEntities, selectState } from "./agencies_list_reducer";

const select = (state: IRootState) => state.adminCenter.agenciesList;
export const selectAgenciesListState = createSelector(select, selectState);
export const selectAgenciesList = createSelector(
    select,
    selectAll
);
export const selectAgenciesListEntities = createSelector(
    select,
    selectEntities
);