export class RegxPattern {
    static email = /^[_aA-zZ0-9-]+(\.[_aA-zZ0-9-]+)*(\+[aA-zZ0-9-]+)?@[_aA-zZ0-9-]+(\.[_aA-zZ0-9-]+)*(\+[aA-zZ0-9-]+)?\.([aA-zZ0-9-]+)+$/;
    static phone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[\s\.]?[-][\s\.]?[0-9]{4}$/;
    static usPhone = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3} - [0-9]{4}$/;
    static usPhoneWithoutCode = /^[0-9]{3}-[0-9]{4}$/;
    static code = /^[0-9]{3}$/;
    static url = /^((http|https):\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z0-9]{2,5}[a-zA-Z0-9]*(:[0-9]{1,5})?(\/.*)?$/;
    static ssn = /^[0-9]{3}[-\s\.]?[0-9]{2}[-\s\.]?[0-9]{4}$/;
    static username = /^[A-Za-z0-9]+.*/;
    static zipCode = /(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)/;
    static numbers = /^[0-9]+.*/;
    static ein = /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/;
    static einWithoutDash = /^(?:\d{9}|\d{10})$/;
}
