import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import MultiUserSection from '../../../../components/multi_users_section/multi_users_section';
import { TALENT_SKILLS_TYPE } from '../../../../enums';
import { useAppSelector, selectRoleUsersListState, selectJobDetailsByIdState, selectAccountManagerRoleUsersList, selectRecruiterRoleUsersList } from '../../../../redux/store';
import { getUserNameFromProfileDropdown, SpinnerScreen } from '../../../../utils';

interface Props {
    job_id: string,
    onAddOption: (type: string) => void,
    onRemoveOption: (type: string, value: {id: string, value: string}) => void,
    customStyle?: React.CSSProperties,
}
const JobOrderStaffDetails: React.FunctionComponent<Props> = (props) => {
    const jobState = useAppSelector((state) => selectJobDetailsByIdState(props.job_id)(state));
    const { t } = useTranslation();

 
    return (
        <div className="cd-aosr-container" style={props.customStyle}>
            <MultiUserSection
                headerTitle={t('account_manager').toUpperCase()}
                actionName={t('add_account_manager')}
                onClick={() => { props.onAddOption(TALENT_SKILLS_TYPE.account_managers) }}
                list={(jobState.account_managers.data ?? []).map((doc, index) => ({ id: doc.id, value: getUserNameFromProfileDropdown(doc) }))}
                onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.account_managers, doc)}
            />
            <MultiUserSection
                headerTitle={t('recruiter').toUpperCase()}
                actionName={t('add_recruiter')}
                onClick={() => { props.onAddOption(TALENT_SKILLS_TYPE.recruiters) }}
                list={(jobState.recruiters.data ?? []).map((doc, index) => ({ id: doc.id, value: getUserNameFromProfileDropdown(doc) }))}
                onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.recruiters, doc)}
            />
        </div>
    );
}

export default JobOrderStaffDetails;