import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import {AppError} from "../../../../../interfaces/app_error";
import { IJobOrderContact  } from "../../../../../entities/ContactInfo";
import * as thunk from './job_order_contact_thunk';


export interface SingleJobOrderContactCreateState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null
}

const initialCreateState: SingleJobOrderContactCreateState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export interface JobOrderContactCreateState {
    [key: string]: SingleJobOrderContactCreateState,
}


const initialState: JobOrderContactCreateState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
        response: ''
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
        response: ''
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}

export const jobOrderContactCreateSlice = createSlice({
    name: 'contactCreate',
    initialState: initialState,
    reducers: {
        clearJobOrderContactCreateError(state, action: PayloadAction<string>){
            return {
                ...state,
                [action.payload]: {
                   ...state[action.payload], 
                   ...initialCreateState,
                }
            }
        },
        clearJobOrderContactCreateState(state, action: PayloadAction<string>){
            return {
                ...state,
                [action.payload]: {
                   ...state[action.payload], 
                   ...initialCreateState,
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.createJobOrderContact.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...getLoadingState(state[action.meta.arg.job_id])
                    }
                })
            )
            .addCase(
                thunk.createJobOrderContact.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...getErrorState(state[action.meta.arg.job_id], action.payload),
                    }
                })
            )
            .addCase(
                thunk.createJobOrderContact.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...getSuccessState(state[action.meta.arg.job_id], action.payload),
                    }
                })
            )
            
            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const jobOrderContactCreateActions = jobOrderContactCreateSlice.actions;
export const selectJobOrderContactCreateState = (state: JobOrderContactCreateState) => state;
export const selectSingleJobOrderContactCreateState = (state: JobOrderContactCreateState, id: string): SingleJobOrderContactCreateState => state[id];

export const selectInitialSingleJobOrderContactCreateState = (): SingleJobOrderContactCreateState => ({
    error: null,
    loading: LoadingType.idle,
    response: ''
});