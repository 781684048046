import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./payroll_cash_req_thunk";
import { IPayrollCashReq } from "../../../../interfaces/report_center";


export interface PayrollCashState {
    error?: AppError | null,
    loading: LoadingType,
    response: IPayrollCashReq | null
}

const initialState: PayrollCashState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}

export const payrollCashSlice = createSlice({
    name: 'payrollCashRequired',
    initialState: initialState,
    reducers: {
        clearPRCErrorState(state) {
            return { ...state, error: null };
        },
        clearPRCState(state) {
            return { ...state, ...initialState };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPayrollCashThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getPayrollCashThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getPayrollCashThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const payrollCashSliceActions = payrollCashSlice.actions;
export const selectState = (state: PayrollCashState) => state;
export const selectLoading = (state: PayrollCashState) => state.loading;
export const selectError = (state: PayrollCashState) => state.error;



