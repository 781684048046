import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./billing_invoices_report_list_thunk";
import { AppError, IBillingInvoicesReportData } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

export interface BillingInvoiceReportState {
  error?: AppError | null;
  loading: LoadingType;
  response: IBillingInvoicesReportData[] | null;
}

const initialState: BillingInvoiceReportState = {
  error: null,
  loading: LoadingType.idle,
  response: null,
};

export const BillingInvoiceReportSlice = createSlice({
  name: "billingInvoiceReport",
  initialState: initialState,
  reducers: {
    clearState(state) {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getBillingInvoiceReportThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getBillingInvoiceReportThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.getBillingInvoiceReportThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        response: action.payload,
      }))
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const BillingInvoiceReportSliceActions = BillingInvoiceReportSlice.actions;
export const selectState = (state: BillingInvoiceReportState) => state;
export const selectAll = (state: BillingInvoiceReportState) => state.response;
