import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IBackOfficeUploadUrlResponse, ITCErrorReports } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { S3API } from "../../../../apis/s3_api";


export const getBIERListThunk = createAsyncThunk<
    ITCErrorReports[],
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing_invoices/bi_errors_report_list/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getBillingInvoicesErrorReports(payload);
            return res.data as ITCErrorReports[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


export const approveBIErrorThunk = createAsyncThunk<
    string,
    string[],
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/bi_timecards/bi_timecards_errors_report_list/approve/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.billingInvoicesReportsApprove(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const resolveBIErrorThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/bi_timecards/bi_timecards_errors_report_list/resolve/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.billingInvoicesReportsResolve(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getBERDownloadUrl = createAsyncThunk<
    IBackOfficeUploadUrlResponse,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@back_office/billing/billing_error_report/download/get',
    async (weekId, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getBillingErrorReportDownloadUrl(weekId);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);