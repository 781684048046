import { createSelector } from "@reduxjs/toolkit";
import {
    selectState,
    selectApolloCompanyDetailsState,
    selectInitialApolloCompanyDetailState,   
} from "./apollo_company_details_reducer";
import { IRootState } from "../../store";

const select = (state: IRootState) => state.apolloImport.apolloCompanyDetailsSlice;
export const selectACompanyDetailsState = createSelector(select, selectState);
export const selectApolloCompanyDetailsByIdState = (id: string | undefined | null) => createSelector(
    selectACompanyDetailsState,
    (state) => {
        if (id && state[id]) return selectApolloCompanyDetailsState(state, id);
        return selectInitialApolloCompanyDetailState();
    }
);