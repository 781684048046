import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectARDocUpload } from "./record_payment_reducer";

const select = (state: IRootState) => state.backOffice.recordPayment;
export const selectRecordPaymentState = createSelector(select, selectState);
export const selectOpeniInvoicesList = createSelector(
    select,
    selectAll
);

export const selectARDocUploadState = createSelector(select, selectARDocUpload);