import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const ImportDataFileAPI = {
    getImportDataFileUploadUrl(baseUrl: string, file_name: string ) {
        let url = `${baseUrl}?file_name=${file_name}`;
        return interceptor.get(url);
    },
    updateImportDataFileUploadUrl(baseUrl: string, import_id: string ) {
        let url = `${baseUrl}?import_id=${import_id}`;
        return interceptor.patch(url);
    },
    getUploadedFileStatus(import_type: string) {
        return interceptor.get(`${UrlBuilder.getUploadedFileStatus}?import_type=${import_type}`);
    },
    getUploadedFileDownloadURL(file_path: string) {
        return interceptor.get(`${UrlBuilder.fileDownLoad}?file_path=${file_path}`);
    },
    getSampleFileDownloadURL(import_type: string) {
        return interceptor.get(`${UrlBuilder.getSampleFileUrl}?import_type=${import_type}`);
    },

}