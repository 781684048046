import { ITalent } from "./talent"

export interface IUnEmploymentClaim {
    id: string,
    sequence_number: number,
    employee_name: string,
    claim_status: string,
    potential_charges: number,
    cost_to_date: number,
    claim_number: number,
    claim_date: number,
    date_closed: number,
    branch_id: string,
    branch_name: string,
    next_steps: string,
    social_security_number: string,
    assignment: string,
    company: string,
    notes: string,
    claim_docs: any | null,
    agency_id: string,
    is_deleted: boolean,
    talent_id: string,
    on_assignment_company: boolean,
    on_assignment: boolean,

}


export interface ICreateUnEmploymentClaim {
    id: string | null,
    sequence_number: number | null,
    employee_name: string,
    claim_status: string | null,
    potential_charges: number | null,
    cost_to_date: number | null,
    claim_number: number | null,
    claim_date: number | null,
    date_closed: number | null,
    branch_id: string | null,
    branch_name: string | null,
    next_steps: string | null,
    social_security_number: string | null | undefined,
    assignment_id: string | null,
    assignment: string | null,
    on_assignment: boolean,
    company_id: string | null,
    company: string | null,
    notes: string | null,
    claim_docs: Array<string>,
    agency_id: string | null,
    is_deleted: boolean,
    talent_id: string | null
}
export class UpdateICreateUnEmploymentClaim {
    id: string | null;
    sequence_number: number | null;
    employee_name: string;
    claim_status: string | null;
    potential_charges: string | null;
    cost_to_date: string | null;
    claim_number: number | null;
    claim_date: number | null;
    date_closed: number | null;
    branch_id: string | null;
    branch_name: string | null;
    next_steps: string | null;
    social_security_number: string | null | undefined;
    assignment_id: string | null;
    assignment: string | null;
    on_assignment: boolean;
    company_id: string | null;
    company: string | null;
    notes: string | null;
    claim_docs: Array<string>;
    agency_id: string | null;
    is_deleted: boolean;
    talent_id: string | null;
}

export interface IUnEmploymentClaimTalentData {
    talent: ITalent,
    unemployment_claims: IUnEmploymentClaimData[]
}

export interface IUnEmploymentClaimData {
    id: string | null,
    sequence_number: number | null,
    employee_name: string,
    claim_status: string | null,
    potential_charges: number | null,
    cost_to_date: number | null,
    claim_number: number | null,
    claim_date: number | null,
    date_closed: number | null,
    branch_id: string | null,
    branch_name: string | null,
    next_steps: string | null,
    social_security_number: string | null | undefined,
    assignment_id: string | null,
    assignment: string | null,
    on_assignment: boolean,
    company_id: string | null,
    company: string | null,
    notes: string | null,
    claim_docs: Array<string>,
    agency_id: string | null,
    is_deleted: boolean,
    talent_id: string | null
}

export interface ITalentUnEmploymentClaimTalentData {
    talent: ITalent,
    unemployment_claims: IUnEmploymentClaim[]
}