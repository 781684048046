import { createSelector } from "@reduxjs/toolkit";
import * as reducer from "./file_view_download_reducer";
import { IRootState } from "../../store";


const select = (state: IRootState) => state.files.fileDownloadSlice;
export const selectFileViewDownloadState = createSelector(
    select,
    reducer.selectState
);
