
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import ToggleSwitch from "../../../../../components/toggle_switch/toggle_switch";
import { THEME } from "../../../../../enums";
import { EditIcon } from "../../../../../icons";
import { ITalentBOPRDirectDeposit, ITalentDirectDeposit } from "../../../../../interfaces";
import { CustomButton, getPlusBtnIcon } from "../../../../../utils";

const tableHeader = [
  { title: 'bank_name', code: 'bank_name' },
  { title: 'account_type', code: 'account_type' },
  { title: 'routing_number', code: 'routing_number' },
  { title: 'account_number', code: 'account_number' },
  { title: 'amount', code: 'amount_per_check' },
  { title: 'amount_type', code: 'amount_type' },
  { title: "actions", code: "actions" },
];

interface stepTwoProps {
  talent_direct_deposit_data: ITalentBOPRDirectDeposit | null;
  addAccount: () => void;
  handleEditAccount: (index: number | undefined) => void;
}
// interface Props extends RouteComponentProps<any> {}
const TalBODirectDepositStepTwoForm: React.FunctionComponent<stepTwoProps> = (
  props
) => {
  const { talent_direct_deposit_data, addAccount, handleEditAccount } =
    props;

  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const { t } = useTranslation();

  function handleSortFieldChange(value: string) {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 2;
    if (value === 1) return 2;
    if (value === 2) return 2;
    if (value === 3) return 2;
    if (value === 4) return 3;
    if (value === 5) return 2;
    return 1;
  }
  const getAmountType = (type) => {
    if (type === "FIXED$AMOUNT") {
      return "Fixed";
    } else if (type === "PERCENTAGE") {
      return "Percentage";
    } else if (type === "REMAININGAMOUNT") {
      return "Remaining";
    } else {
      return type;
    }
  };
  const getAmountPrefix = (type, val) => {
    if (type === "FIXED$AMOUNT") {
      return "$ " + val.toFixed(2);
    } else if (type === "PERCENTAGE") {
      return val.toFixed(2) + " %";
    } else {
      return val;
    }
  };
  return (
    <>
      <div className="drd-title-txt">
        <span>{t("financial_accounts")}</span>

        <div style={{ marginTop: "1rem" }}>
          <CustomButton
            leftIcon={getPlusBtnIcon()}
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("add_account")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={addAccount}
          />
        </div>
      </div>
      <div className="direct-deposit-sortable-table ">
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {talent_direct_deposit_data?.bank_accounts?.map((doc, index) => {
            return (
              <tr key={"branch_list" + index}>
                {/* <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleUserSelect(doc))}</TableData> */}
                <TableData
                  customStyle={{
                    flex: getFlexNumber(0),
                    wordWrap: "break-word",
                  }}
                >
                  <span>{doc.bank_name}</span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(1),
                    wordWrap: "break-word",
                  }}
                >
                  <span>{doc.account_type}</span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(2),
                    wordWrap: "break-word",
                  }}
                >
                  <span>{doc.routing_number}</span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(3),
                    wordWrap: "break-word",
                  }}
                >
                  <span>{doc.account_number}</span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(4),
                    wordWrap: "break-word",
                  }}
                >
                  <span>
                    {getAmountPrefix(doc.amount_type, doc.amount_per_check)}
                  </span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(5),
                    wordWrap: "break-word",
                  }}
                >
                  <span>{getAmountType(doc.amount_type)}</span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(6),
                    wordWrap: "break-word",
                  }}
                >
                  <ToggleSwitch
                    label={""}
                    labelPosition={"top"}
                    checked={doc.is_active ? true : false}
                    onChange={() => { }}
                  />
                  <div style={{ marginLeft: '1rem' }}>
                    <button className="icon-btn ac-ob-edit-icon" onClick={() => { }}>
                      <EditIcon
                        width={"100%"}
                        height={"100%"}
                        style={{ color: "#fff" }}
                        onClick={(value) => handleEditAccount(index)}
                      />
                    </button>
                  </div>
                </TableData>
              </tr>
            );
          })}
        </SortableTable>
      </div>

    </>
  );
};

export default TalBODirectDepositStepTwoForm;
