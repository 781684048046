import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType, TALENT_SKILLS_TYPE } from "../../../../../enums";
import { AppError, ISkillOption, ICompany, IDepartment, Profile, IJobOrderDetail, IJobTalentMatches, IJobCompanyMatches } from "../../../../../interfaces";
import * as thunk from "./job_details_thunk";


interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

interface RemoveState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialRemoveState: RemoveState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


/// [Job Matches]
export interface SingleJobTalentMatchesState {
    data: Array<IJobTalentMatches> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}


const singleJobTalentMatchesInitialState: SingleJobTalentMatchesState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}

// /// [Company Matches]
export interface SingleJobCompanyMatchesState {
    data: Array<IJobCompanyMatches> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}


const singleJobCompanyMatchesInitialState: SingleJobCompanyMatchesState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}

/// [Skills]
export interface SingleJobSkillsState {
    data: Array<ISkillOption> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}



const singleJobSkillsInitialState: SingleJobSkillsState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}

export interface SingleJobRoleUsersState {
    data: Array<Profile> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}

const singleJobRoleUsersInitialState: SingleJobRoleUsersState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}



export interface SingleJobState {
    error?: AppError | null,
    loading: LoadingType,
    data: IJobOrderDetail | null,
    talentMatches: SingleJobTalentMatchesState,
    companyMatches: SingleJobCompanyMatchesState,
    [TALENT_SKILLS_TYPE.professional_skills]: SingleJobSkillsState,
    [TALENT_SKILLS_TYPE.credentials]: SingleJobSkillsState,
    [TALENT_SKILLS_TYPE.certifications]: SingleJobSkillsState,
    [TALENT_SKILLS_TYPE.education]: SingleJobSkillsState,
    [TALENT_SKILLS_TYPE.safety_equipment]: SingleJobSkillsState,
    [TALENT_SKILLS_TYPE.account_managers]: SingleJobRoleUsersState,
    [TALENT_SKILLS_TYPE.recruiters]: SingleJobRoleUsersState,
}


export interface JobDetailsState {
    [key: string]: SingleJobState,
}


const initialState: JobDetailsState = {}


function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

function getResponseSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}

export const jobDetailsSlice = createSlice({
    name: 'jobDetails',
    initialState: initialState,
    reducers: {
        clearJobDetailsAddOptionError(state, action: PayloadAction<{ id: string, type: string }>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        add: {
                            ...state[action.payload.id][action.payload.type].add,
                            ...initialAddState,
                        },

                    },
                }
            };
        },

        clearJobDetailsRemoveOptionError(state, action: PayloadAction<{ id: string, type: string }>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        remove: {
                            ...state[action.payload.id][action.payload.type].remove,
                            ...initialRemoveState,
                        },

                    },
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getJobById.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...getLoadingState(state[action.meta.arg.job_id]),
                        talentMatches: singleJobTalentMatchesInitialState,
                        companyMatches: singleJobCompanyMatchesInitialState,
                        [TALENT_SKILLS_TYPE.professional_skills]: singleJobSkillsInitialState,
                        [TALENT_SKILLS_TYPE.credentials]: singleJobSkillsInitialState,
                        [TALENT_SKILLS_TYPE.certifications]: singleJobSkillsInitialState,
                        [TALENT_SKILLS_TYPE.education]: singleJobSkillsInitialState,
                        [TALENT_SKILLS_TYPE.safety_equipment]: singleJobSkillsInitialState,
                        [TALENT_SKILLS_TYPE.account_managers]: singleJobRoleUsersInitialState,
                        [TALENT_SKILLS_TYPE.recruiters]: singleJobRoleUsersInitialState,
                    }
                })
            )
            .addCase(
                thunk.getJobById.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...getErrorState(state[action.meta.arg.job_id], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getJobById.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...getSuccessState(state[action.meta.arg.job_id], action.payload),
                    }
                })
            )


            /// Get all skillls
            .addCase(
                thunk.getJobAllSkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getLoadingState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.professional_skills]),

                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getLoadingState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.credentials]),

                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getLoadingState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.certifications]),

                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getLoadingState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.education]),

                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getLoadingState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.safety_equipment]),
                        },
                    }
                })
            )
            .addCase(
                thunk.getJobAllSkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getErrorState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.professional_skills], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getErrorState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.credentials], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getErrorState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.certifications], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getErrorState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.education], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getErrorState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.safety_equipment], action.payload),
                        },
                    }
                })
            )
            .addCase(
                thunk.getJobAllSkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getSuccessState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.professional_skills], action.payload[TALENT_SKILLS_TYPE.professional_skills]),
                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getSuccessState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.credentials], action.payload[TALENT_SKILLS_TYPE.credentials]),
                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getSuccessState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.certifications], action.payload[TALENT_SKILLS_TYPE.certifications]),
                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getSuccessState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.education], action.payload[TALENT_SKILLS_TYPE.education]),
                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getSuccessState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.safety_equipment], action.payload[TALENT_SKILLS_TYPE.safety_equipment]),
                        },
                    }
                })
            )

            /// Skills list [DELETE]
            .addCase(
                thunk.removeJobSkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.job_id][action.meta.arg.value.skill_type],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.job_id][action.meta.arg.value.skill_type].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeJobSkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.job_id][action.meta.arg.value.skill_type],
                            remove: {
                                ...getErrorState(state[action.meta.arg.job_id][action.meta.arg.value.skill_type].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeJobSkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.job_id][action.meta.arg.value.skill_type],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.job_id][action.meta.arg.value.skill_type].remove, action.payload),
                            },
                        },

                    }
                })
            )

            /// Skills list [POST]
            .addCase(
                thunk.addJobSkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.job_id][action.meta.arg.value.skill_type],
                            add: {
                                ...getLoadingState(state[action.meta.arg.job_id][action.meta.arg.value.skill_type].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addJobSkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.job_id][action.meta.arg.value.skill_type],
                            add: {
                                ...getErrorState(state[action.meta.arg.job_id][action.meta.arg.value.skill_type].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addJobSkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.job_id][action.meta.arg.value.skill_type],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.job_id][action.meta.arg.value.skill_type].add, action.payload),
                            },
                        },
                    }
                })
            )


            /// Get all account managers
            .addCase(
                thunk.getJobAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        account_managers: {
                            ...getLoadingState(state[action.meta.arg.job_id].account_managers),

                        },
                    }
                })
            )
            .addCase(
                thunk.getJobAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        account_managers: {
                            ...getErrorState(state[action.meta.arg.job_id].account_managers, action.payload),

                        },
                    }
                })
            )
            .addCase(
                thunk.getJobAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        account_managers: {
                            ...getSuccessState(state[action.meta.arg.job_id].account_managers, action.payload),
                        },
                    }
                })
            )


            // /// AccountManagers list [DELETE]
            .addCase(
                thunk.removeJobAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeJobAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getErrorState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeJobAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers].remove, action.payload),
                            },
                        },

                    }
                })
            )

            // /// Account managers list [POST]
            .addCase(
                thunk.addJobAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getLoadingState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addJobAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getErrorState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addJobAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.account_managers].add, action.payload),
                            },
                        },
                    }
                })
            )


            // /// Get all account managers
            .addCase(
                thunk.getJobRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getLoadingState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters]),

                        },
                    }
                })
            )
            .addCase(
                thunk.getJobRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getErrorState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters], action.payload),

                        },
                    }
                })
            )
            .addCase(
                thunk.getJobRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getSuccessState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters], action.payload),
                        },
                    }
                })
            )


            // /// Recruiters list [DELETE]
            .addCase(
                thunk.removeJobRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeJobRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getErrorState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeJobRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters].remove, action.payload),
                            },
                        },

                    }
                })
            )

            // /// Account managers list [POST]
            .addCase(
                thunk.addJobRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getLoadingState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addJobRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getErrorState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addJobRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.job_id][TALENT_SKILLS_TYPE.recruiters].add, action.payload),
                            },
                        },
                    }
                })
            )

            /// Job matches list
            .addCase(
                thunk.getJobTalentMatches.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        talentMatches: {
                            ...getLoadingState(state[action.meta.arg.job_id].talentMatches),
                        },
                    }
                })
            )
            .addCase(
                thunk.getJobTalentMatches.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        talentMatches: {
                            ...getErrorState(state[action.meta.arg.job_id].talentMatches, action.payload),
                        },
                    }
                })
            )
            .addCase(
                thunk.getJobTalentMatches.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        talentMatches: {
                            ...getSuccessState(state[action.meta.arg.job_id].talentMatches, action.payload),
                        },
                    }
                })
            )

            /// Company matches list
            .addCase(
                thunk.getJobCompanyMatches.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        companyMatches: {
                            ...getLoadingState(state[action.meta.arg.job_id].companyMatches),
                        },
                    }
                })
            )
            .addCase(
                thunk.getJobCompanyMatches.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        companyMatches: {
                            ...getErrorState(state[action.meta.arg.job_id].companyMatches, action.payload),
                        },
                    }
                })
            )
            .addCase(
                thunk.getJobCompanyMatches.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...state[action.meta.arg.job_id],
                        companyMatches: {
                            ...getSuccessState(state[action.meta.arg.job_id].companyMatches, action.payload),
                        },
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const jobDetailsSliceActions = jobDetailsSlice.actions;
export const selectState = (state: JobDetailsState) => state;
export const selectJobState = (state: JobDetailsState, id: string): SingleJobState => state[id];
export const selectJobJobMatchesState = (state: SingleJobState): SingleJobTalentMatchesState => state.talentMatches;
export const selectJobCompanyMatchesState = (state: SingleJobState): SingleJobCompanyMatchesState => state.companyMatches;
export const selectJobSkillsState = (state: SingleJobState): SingleJobSkillsState => state.professional_skills;
export const selectJobCredentialsState = (state: SingleJobState): SingleJobSkillsState => state.credentials;
export const selectJobCertificationsState = (state: SingleJobState): SingleJobSkillsState => state.certifications;
export const selectJobEducationState = (state: SingleJobState): SingleJobSkillsState => state.education;
export const selectJobEquipmentState = (state: SingleJobState): SingleJobSkillsState => state.safety_equipment;

export const selectInitialSingleJobState = (): SingleJobState => ({
    data: null,
    loading: LoadingType.pending,
    error: null,
    talentMatches: singleJobTalentMatchesInitialState,
    companyMatches: singleJobCompanyMatchesInitialState,
    [TALENT_SKILLS_TYPE.professional_skills]: singleJobSkillsInitialState,
    [TALENT_SKILLS_TYPE.credentials]: singleJobSkillsInitialState,
    [TALENT_SKILLS_TYPE.certifications]: singleJobSkillsInitialState,
    [TALENT_SKILLS_TYPE.education]: singleJobSkillsInitialState,
    [TALENT_SKILLS_TYPE.safety_equipment]: singleJobSkillsInitialState,
    [TALENT_SKILLS_TYPE.account_managers]: singleJobRoleUsersInitialState,
    [TALENT_SKILLS_TYPE.recruiters]: singleJobRoleUsersInitialState,
});


