export enum ACCOUNT_TYPE {
    checking = "CHECKING",
    savings = "SAVINGS",
    paycard = "PAYCARD"
}

export enum PAYCHECK_TYPE {
    fixed$amount = "FIXED$AMOUNT",
    percentage = "PERCENTAGE",
    remainingamount = "REMAININGAMOUNT"
}