
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll } from "./status_code_reducer";

const select = (state: IRootState) => state.adminCenter.statusCode;
export const selectStatusCodeState = createSelector(select, selectState);
export const selectStatusCodeList = createSelector(
    select,
    selectAll
);