import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { AppError } from "../../../../interfaces";
import { IHolidayReport, IHolidayReportParam, IVacationReport } from "../../../../interfaces/report_center";
import { catchRequestError } from "../../../../utils";

export const getHolidayReportThunk = createAsyncThunk<
    IHolidayReport[],
    IHolidayReportParam,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/payroll_tax/holiday_report/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getHolidayReport(payload);
            return res.data as IHolidayReport[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


export const getVacationReportThunk = createAsyncThunk<
    IVacationReport[],
    void,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/payroll_tax/vacation_report/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getVacationReport();
            return res.data as IVacationReport[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });