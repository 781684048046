import { useTranslation } from "react-i18next";
import { LoadingType, THEME } from "../../../../enums";
import { AppError, IWeekEndingDatesDropdown, IWeeklyTransactionAssignment } from "../../../../interfaces";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { useEffect, useRef, useState } from "react";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { BOOpenCloseIcon } from "../../../../icons";
import './create_transaction_assignment.scss';
import { CustomButton, CustomCheckBox, FormInput, SpinnerScreen, currencyConversion } from "../../../../utils";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
// import { getWeeklyTransactionAssignmentsThunk } from "../../../../redux/back_office";
import { getWeeklyTransactionAssignmentsThunk, selectAssignmentTransactionsState, selectProfileState, useAppDispatch, useAppSelector } from "../../../../redux/store";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { TableFilterEmpty } from "../../../../components/table_empty/table_empty";
import SearchBar from "../../../../components/search_bar";
import ApiError from "../../../../components/api_error";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { getDateString } from "../../../../variables";


interface Props {
    visible: boolean;
    title: string;
    onClose: () => void;
    handleCreateTimecard: (doc: string[], weekId: string) => void;
    handleSuccessClose: () => void;
    currentLoginUserName: string;
    handleClearError: () => void;
    weekEndingDatesList: IWeekEndingDatesDropdown[];
}
const CreateTransactionAssignmentPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        handleCreateTimecard,
        handleSuccessClose,
        currentLoginUserName,
        handleClearError,
        weekEndingDatesList
    } = props;
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const dispatch = useAppDispatch();
    const assignmentsState = useAppSelector((state) => selectAssignmentTransactionsState(state));
    const profileState = useAppSelector((state) => selectProfileState(state));

    const [assignmentsList, setAssignmentsList] = useState<IWeeklyTransactionAssignment[] | null>([]);
    const [selectedWeekDate, setSelectedWeekDate] = useState<{ id: string, date: string }>((weekEndingDatesList.length > 0) ? { id: weekEndingDatesList[0].id, date: getDateString(weekEndingDatesList[0].week_ending_date, "mm/dd/yyyy") } : { id: "", date: "" });
    const [existedEmp, setExistedEmp] = useState<{ talent_name: string, company_name: string, department_name: string, job_title: string }[]>([])
    const [isExistEmp, setIsExistEmp] = useState<boolean>(false);

    useEffect(() => {
        if (weekEndingDatesList.length > 0)
            dispatch(getWeeklyTransactionAssignmentsThunk(selectedWeekDate.id));
    }, []);
    const handleWeekEndChange = (val) => {
        const selectedWeekObject = weekEndingDatesList.find(obj => obj.id === val);
        setSelectedWeekDate({ id: val, date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy") });
        dispatch(getWeeklyTransactionAssignmentsThunk(val));
    }
    const handleExistEmpClear = () => {
        setExistedEmp([]);
        setIsExistEmp(false)
    }
    useEffect(() => {
        if (assignmentsState && assignmentsState.loading === LoadingType.succeeded) {
            setAssignmentsList(assignmentsState.response);
        }
        if (assignmentsState && assignmentsState.create.loading === LoadingType.succeeded) {
            handleExistEmpClear();
        }
    }, [assignmentsState, assignmentsState.loading, assignmentsState.response])
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState('');
    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === assignmentsList?.length}
                onClick={handleMarkAllItem}
            />
        )
    }
    const tableHeader = [
        { title: '', code: '', sort: false, children: <SelectAllCheckBox /> },
        { title: 'talent', code: 'talent_name' },
        { title: 'company', code: 'company_name' },
        { title: 'department', code: 'department_name' },
        { title: 'job_title', code: 'job_title' },
        { title: 'wc_code', code: 'wc_code' },
        { title: 'pay_rate', code: 'pay_rate' },
        { title: 'bill_rate', code: 'bill_rate' },
    ];
    function getFilteredList(): (IWeeklyTransactionAssignment[] | null) {
        let sortedList;
        if (sortedField != null && assignmentsList !== null) {
            sortedList = [...assignmentsList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== null
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (sortedList ?? assignmentsList).filter(doc => {
            const str = search.trim().toLowerCase();
            const nameFilter = doc.talent_name ? doc.talent_name.trim().toLowerCase().includes(str) : false;
            const companyFilter = doc.company_name ? doc.company_name.trim().toLowerCase().includes(str) : false;
            const departmentFilter = doc.department_name ? doc.department_name.trim().toLowerCase().includes(str) : false;
            const jobTitleFilter = doc.job_title ? doc.job_title.trim().toLowerCase().includes(str) : false;
            const wcCodeFilter = doc.wc_code ? doc.wc_code.trim().toLowerCase().includes(str) : false;
            const payRateFilter = doc.pay_rate ? doc.pay_rate.trim().toLowerCase().includes(str) : false;
            const billRateFilter = doc.bill_rate ? doc.bill_rate.trim().toLowerCase().includes(str) : false;
            return nameFilter || companyFilter || departmentFilter || jobTitleFilter || wcCodeFilter || payRateFilter || billRateFilter;
        });
    };
    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    }
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
    const handleMarkAssignment = (doc: IWeeklyTransactionAssignment) => {
        const newSet = new Set(selectedItems);
        if (selectedItems.has(doc.id)) {
            newSet.delete(doc.id);
        } else {
            newSet.add(doc.id);
        }
        setSelectedItems(newSet);
    };
    const handleMarkAllItem = () => {
        if (selectedItems.size === getFilteredList()?.length) {
            setSelectedItems(new Set<string>());
        } else {
            const newSet = new Set<string>(selectedItems);
            getFilteredList()?.forEach((assignment) => {
                newSet.add(assignment.id);
            });
            setSelectedItems(newSet);
        }
    }
    function getFlexNumber(value: number) {
        if (value === 0) return 0.2;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 0.8;
        if (value === 6) return 1.2;
        if (value === 7) return 1.2;
        if (value === 8) return 0.5;
        if (value === 9) return 1;

        return 1;
    }

    const handleClose = () => {
        handleExistEmpClear();
        onClose();
        handleClearError();
    }
    const handleSubmit = () => {
        const checkExistedEmployees = (assignmentsList ?? [])
            .filter(({ id, time_card_exists }) => selectedItems.has(id) && time_card_exists)
            .map(({ talent_name, company_name, job_title, department_name }) => ({ talent_name, company_name, job_title, department_name }));

        setExistedEmp(checkExistedEmployees);
        if (checkExistedEmployees.length === 0 || isExistEmp) {
            handleCreateTimecard(Array.from(selectedItems.values()), selectedWeekDate.id);
            setSelectedItems(new Set());

        }
        else {
            setIsExistEmp(true);
        }

    }
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            maxWrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={() => {
                onClose();
                handleClearError();
            }}>
                <div className="create-ta-popup-container">
                    <div className="create-ta-popup-header">
                        <FormDialogTilteHeader
                            title={title}
                            titleIcon={<BOOpenCloseIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="create-ta-popup-content">
                        {assignmentsState.create.loading === LoadingType.succeeded ?
                            (<div className="saved-doc-holder">
                                <DocSavedText>
                                    <div><span className="aknw-name">
                                        {assignmentsState.create.createResponse}
                                    </span></div>
                                </DocSavedText>
                            </div>)
                            :
                            (
                                <>
                                    {isExistEmp ?
                                        <div className="exist-tab">
                                            <span className="et-title">{t("below_emp_exist", { name: profileState?.profile?.first_name ?? "" })}</span>
                                            <div className="table-content">
                                                <table>
                                                    <thead>
                                                        <th>{t('talent_name')}</th>
                                                        <th>{t('company_name')}</th>
                                                        <th>{t('department_name')}</th>
                                                        <th>{t('job_title')}</th>
                                                    </thead>
                                                    <tbody>
                                                        {existedEmp.map((emp) => (
                                                            <tr>
                                                                <td>{emp.talent_name}</td>
                                                                <td>{emp.company_name}</td>
                                                                <td>{emp.department_name}</td>
                                                                <td>{emp.job_title}</td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <div className="create-ta-content-table">

                                            <div className="create-ta-search-row">
                                                <div className="srch-tlnt-ud-table-search-bar">
                                                    <div className="search-header">{t("search_talent")}</div>
                                                    <SearchBar value={search} onChange={(value) => setSearch(value)} onSearch={() => { }} />
                                                </div>
                                                <div>
                                                    <CustomFormSelect
                                                        list={
                                                            weekEndingDatesList.map((doc) => ({
                                                                label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                                                value: doc.id,
                                                            }))
                                                        }
                                                        customStyle={{ width: "100%" }}
                                                        name={"week_ending_date"}
                                                        value={selectedWeekDate.id}
                                                        label={t("week_ending_date")}
                                                        placeholder={"Select"}
                                                        onChange={handleWeekEndChange}
                                                        isClearable={false}
                                                    />
                                                </div>
                                                <div className="total-count">
                                                    <span>
                                                        {`${t("total_count")}: `}
                                                        <span className="total-count-number">{getFilteredList()?.length}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="create-ta-table">
                                                {assignmentsState.loading === LoadingType.pending ?
                                                    (
                                                        <SpinnerScreen />
                                                    )
                                                    :
                                                    (<> {getFilteredList()?.length !== 0 ?
                                                        <SortableTable
                                                            headerList={tableHeader}
                                                            sortedField={sortedField}
                                                            onSortChange={handleSortFieldChange}
                                                            flexNumber={getFlexNumber}
                                                            isAsc={sortDirection}
                                                        >
                                                            {getFilteredList()?.map((doc, index) => {
                                                                return (
                                                                    <tr key={doc.id}>
                                                                        <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                                            <CustomCheckBox
                                                                                name={""}
                                                                                title={""}
                                                                                checked={selectedItems.has(doc.id)}
                                                                                onClick={() => handleMarkAssignment(doc)}
                                                                            />
                                                                        </TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.talent_name}</span></TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.company_name}</span></TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{doc.department_name ? doc.department_name : "Corporate"}</span></TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc.job_title}</span></TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(5) }}><span>{doc.wc_code}</span></TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                                                            <span>
                                                                                {doc.regular_pay_rate
                                                                                    ? currencyConversion(doc.regular_pay_rate)
                                                                                    : "$0.00"}</span>


                                                                        </TableData>
                                                                        <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                                            <span>
                                                                                {doc.regular_bill_rate
                                                                                    ? currencyConversion(doc.regular_bill_rate)
                                                                                    : "$0.00"}</span>
                                                                        </TableData>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </SortableTable>
                                                        :
                                                        <div className="srch-talent-holder">
                                                            <TableFilterEmpty title={t('no_assignments_found')} />
                                                        </div>
                                                    }</>)}
                                            </div>
                                        </div>
                                    }
                                </>

                            )
                        }
                        {assignmentsState.create && assignmentsState.create.error && <div className="error-holder">
                            <ApiError
                                message={assignmentsState?.create.error.message}
                                onClose={handleClearError}
                            />
                        </div>}
                    </div>
                    <div className="create-ta-popup-actions">
                        {assignmentsState.create.loading === LoadingType.succeeded
                            ?
                            (<>
                                <div className="btn-save">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("ok")}
                                        enable={true}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={() => { handleExistEmpClear(); handleSuccessClose() }}
                                    />
                                </div></>)
                            :
                            (
                                <>
                                    <div className="btn-cancel" style={{ marginRight: "1em" }}>
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={isExistEmp ? t("back") : t("cancel")}
                                            enable={true}
                                            backgroundColor={THEME.toggleDisableColor}
                                            onClick={() => {
                                                isExistEmp ? setIsExistEmp(false) :
                                                    handleClose();
                                            }}
                                        />
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={assignmentsState.create.loading === LoadingType.pending}
                                            textStyle={{ textTransform: "none" }}
                                            name={t("create_timecard")}
                                            enable={selectedItems.size > 0}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={handleSubmit}
                                        />
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>);
}

export default CreateTransactionAssignmentPopup;