import { useTranslation } from "react-i18next";
import {
  IEmpAccountInfo,
  IEmpDirectDeposit,
  IEmpOnboardingDocsReviewStatus,
  IUpdateEmpOnboardingDocsReviewStatus,
} from "../../../interfaces";

import { useEffect, useState } from "react";
import DirectDepositStepOneForm from "./step_one/step_one";
import DirectDepositStepTwoForm from "./step_two/step_two";
import DirectDepositStepThreeForm from "./step_three/step_three";
import {
  useAppDispatch,
  useAppSelector,
  addEmpDirectDepositThunk,
  selectEmpDirectDepositeState,
  getEmpODRSThunk,
  validateEmpDirectDepositAcntThunk,
} from "../../../redux/store";
import { ONBOARDING_STATUS } from "../../../enums/onboarding_status";
import { LoadingType, THEME } from "../../../enums";
import { FormDialogTilteHeader } from "../../../components/dialog_wrapper/dialog_wrapper";
import { OnBoardingIcon } from "../../../icons";
import CustomStepIndicator from "../../../components/custom_step_indicator/custom_step_indicator";
import { CustomButton } from "../../../utils";
import { PAYCHECK_TYPE } from "../../../enums/onboarding_direct_deposit";

export const initialForm = {
  talent_id: "",
  modified_by: "",
  created_by: "",
  modified_date: 0,
  handbook_document: {
    talent_completed_review: false,
    talent_signature: "",
    talent_signature_date: "",
    recruiter_completed_review: false,
    recruiter_signature: "",
    recruiter_signature_date: "",
    reviewed_on_timestamp: 0,
    reviewed_by: "",
    url: "",
    modified_by: "",
    created_by: "",
    modified_date: 0,
  },
  notice_of_healthcare_exchange_document: {
    talent_completed_review: false,
    recruiter_completed_review: false,
    reviewed_on_timestamp: 0,
    reviewed_by: "",
    url: "",
    modified_by: "",
    created_by: "",
    modified_date: 0,
  },

  direct_deposit_document: {
    talent_completed_review: false,
    recruiter_completed_review: false,
    bank_accounts: {
      0: {
        account_type: "",
        bank_name: "",
        routing_number: 0,
        account_number: 0,
        amount_type: "",
        amount_per_check: 0,
        is_active: true,
        modified_by: "",
        created_by: "",
        modified_date: 0,
      },
    },
    modified_by: "",
    created_by: "",
    modified_date: 0,
    reviewed_on_timestamp: "",
    reviewed_by: "",
    url: "",
    name: "",
    ssn: "",
    bank_accounts_encrypted: "",
  },
  w4_document: {
    talent_completed_review: false,
    recruiter_completed_review: false,
    reviewed_on_timestamp: 0,
    reviewed_by: "",
    url: "",
    modified_by: "",
    created_by: "",
    modified_date: 0,
  },
  i9_document: {
    talent_completed_review: false,
    recruiter_completed_review: false,
    reviewed_on_timestamp: 0,
    reviewed_by: "",
    url: "",
    modified_by: "",
    created_by: "",
    modified_date: 0,
  },
  terms_and_conditions: {
    modified_by: "",
    created_by: "",
    modified_date: 0,
    talent_read_tc: false,
    talent_signature: "",
    employee_signature: "",
    talent_signed_date: "",
    employee_signed_date: "",
    terms_and_conditions: "",
    url: "",
  },
  status: ONBOARDING_STATUS.onboardingLinkSent,
};
interface Props {
  data: IEmpDirectDeposit | undefined;
  lastStep: number;
  onClose: () => void;
  title: string;
  currentLoginUserName: string;
  token: string | undefined;
  onSuccessClose: (type: string, value?: any) => void;
  type: string;
  state: IEmpOnboardingDocsReviewStatus | null;
  talentName: string;
  handleClearError: () => void;
}
const DirectDepositForm: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    title,
    token,
    onSuccessClose,
    type,
    state,
    talentName,
    handleClearError
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const directDepositState = useAppSelector((state) =>
    selectEmpDirectDepositeState(state)
  );

  const [directDepositData, setDirectDepositData] = useState(
    {} as IEmpOnboardingDocsReviewStatus
  );
  useEffect(() => {
    if (state !== null) {
      setDirectDepositData(state);
    }
  });
  const [currentBankAcnt, setCurrentBankAcnt] = useState<IEmpAccountInfo>();

  const getFormData = (
    value: IEmpAccountInfo
  ): IUpdateEmpOnboardingDocsReviewStatus => {
    return {
      talent_id: "",
      modified_by: "",
      created_by: "",
      modified_date: 0,
      handbook_document: {
        talent_completed_review: false,
        talent_signature: "",
        talent_signature_date: "",
        recruiter_completed_review: false,
        recruiter_signature: "",
        recruiter_signature_date: "",
        reviewed_on_timestamp: 0,
        reviewed_by: "",
        url: "",
        modified_by: "",
        created_by: "",
        modified_date: 0,
      },
      notice_of_healthcare_exchange_document: {
        talent_completed_review: false,
        recruiter_completed_review: false,
        reviewed_on_timestamp: 0,
        reviewed_by: "",
        url: "",
        modified_by: "",
        created_by: "",
        modified_date: 0,
      },

      direct_deposit_document: {
        talent_completed_review: false,
        recruiter_completed_review: false,
        bank_accounts: {
          0: {
            account_type: value.account_type ?? "",
            bank_name: value.bank_name ?? "",
            routing_number: value.routing_number ?? 0,
            account_number: value.account_number ?? 0,
            amount_type: value.amount_type ?? "",
            amount_per_check: value.amount_per_check ?? 0,
            is_active: value.is_active ?? true,
            modified_by: value.modified_by ?? "",
            created_by: value.created_by ?? "",
            modified_date: value.modified_date ?? 0,
          },
        },
        modified_by: "",
        created_by: "",
        modified_date: 0,
        reviewed_on_timestamp: "",
        reviewed_by: "",
        url: "",
        name: "",
        ssn: "",
        bank_accounts_encrypted: "",
      },
      w4_document: {
        talent_completed_review: false,
        recruiter_completed_review: false,
        reviewed_on_timestamp: 0,
        reviewed_by: "",
        url: "",
        modified_by: "",
        created_by: "",
        modified_date: 0,
      },
      i9_document: {
        talent_completed_review: false,
        recruiter_completed_review: false,
        reviewed_on_timestamp: 0,
        reviewed_by: "",
        url: "",
        modified_by: "",
        created_by: "",
        modified_date: 0,
      },
      terms_and_conditions: {
        modified_by: "",
        created_by: "",
        modified_date: 0,
        talent_read_tc: false,
        talent_signature: "",
        employee_signature: "",
        talent_signed_date: "",
        employee_signed_date: "",
        terms_and_conditions: "",
        url: "",
      },
      status: ONBOARDING_STATUS.onboardingLinkSent,
    };
  };
  const [formState, setFormState] =
    useState<IUpdateEmpOnboardingDocsReviewStatus>(
      currentBankAcnt !== undefined ? getFormData(currentBankAcnt) : initialForm
    );
  const [edit, setEdit] = useState<boolean>(false);
  useEffect(() => {
    if (currentBankAcnt !== undefined)
      setFormState(getFormData(currentBankAcnt));
  }, [currentBankAcnt]);

  const [currentStep, setCurrentStep] = useState<{
    label: string;
    value: number;
  }>({ label: t("step_option", { value: 1 }), value: 1 });

  const stepsList: Array<{ label: string; value: number }> = [
    { label: t("step_option", { value: 1 }), value: 0 },
    ...Array.from(Array(props.lastStep).keys()).map((doc, index) => ({
      label: t("step_option", { value: index + 2 }),
      value: index + 1,
    })),
    {
      label: t("step_option", { value: props.lastStep + 2 }),
      value: props.lastStep + 1,
    },
    {
      label: t("step_option", { value: props.lastStep + 3 }),
      value: props.lastStep + 2,
    },
  ];

  function getSubTitleByStep(): string {
    if (currentStep.value === 2)
      return t("review_and_verify_info_below", {
        name: talentName,
      });
    if (currentStep.value === 1)
      return t("check_bank_acnts_make_changes_go_ahead_next", {
        name: talentName,
      });

    if (currentStep.value === 0)
      return t("direct_deposit_setup_msg", {
        name: talentName,
      });
    return t("direct_deposit_setup_msg", {
      name: talentName,
    });
  }

  async function handleNext() {
    if (currentStep.value === 0) {
      setCurrentStep(stepsList[currentStep.value + 1]);
    }
    if (currentStep.value === 1 && directDepositData) {
      const ddState = await dispatch(
        validateEmpDirectDepositAcntThunk({
          ...directDepositData,
        })
      );
      if (ddState.meta.requestStatus === "fulfilled") {
        setCurrentStep(stepsList[currentStep.value + 1]);
      }
    }
    if (currentStep.value === props.lastStep + 2) {
      props.onClose();
      handleClearError();
    }
    setCurrentBankAcnt(undefined);
    setEdit(false);
    setFormState(initialForm);
  }
  function handleBack() {
    if (currentStep.value !== 0 && currentStep.value <= props.lastStep + 2) {
      setCurrentStep(stepsList[currentStep.value - 1]);
    }
    handleClearError();
  }
  function _isCurrentStepValid() {
    if (currentStep.value === 0) return true;
    return true;
  }

  function handleBankAccntFieldChange(
    fieldId: string,
    value: any,
    index: number
  ) {
    let amountPayCheckVal =
      fieldId === "amount_per_check"
        ? value
        : formState.direct_deposit_document.bank_accounts[index][
        "amount_per_check"
        ];
    if (value === PAYCHECK_TYPE.remainingamount) {
      amountPayCheckVal = 0;
    }

    setFormState({
      ...formState,
      direct_deposit_document: {
        ...formState.direct_deposit_document,
        bank_accounts: {
          ...formState.direct_deposit_document.bank_accounts,
          [index]: {
            ...formState.direct_deposit_document.bank_accounts[index],
            [fieldId]: value,
            amount_per_check: amountPayCheckVal,
          },
        },
      },
    });
  }
  const handleAddAcnt = (index) => {
    // if (onboardingData?.talent_onboarding_data) {
    const formStateBankAccnts = Object.keys(
      formState.direct_deposit_document.bank_accounts
    ).map((key) => formState.direct_deposit_document.bank_accounts[key]);

    const updatedBankAccnts = [
      ...formStateBankAccnts,
      ...directDepositData.direct_deposit_document.bank_accounts,
    ];

    dispatch(
      addEmpDirectDepositThunk({
        ...directDepositData,
        direct_deposit_document: {
          ...directDepositData?.direct_deposit_document,
          bank_accounts: updatedBankAccnts,
        },
      })
    );
    handleNext();
    // }
  };
  const [currentIndexKey, setCurrentIndexKey] = useState<number>(0);
  const handleEditAccount = (indexKey) => {
    const bankData = data?.bank_accounts[indexKey];
    setCurrentBankAcnt(bankData);
    setEdit(true);
    setCurrentStep(stepsList[currentStep.value - 1]);
    setCurrentIndexKey(indexKey);
    handleClearError();

  };

  const handleUpdateAccount = () => {
    if (directDepositData) {
      const formStateBankAccnts = Object.keys(
        formState.direct_deposit_document.bank_accounts
      ).map((key) => formState.direct_deposit_document.bank_accounts[key]);
      const updatedBankAccnts = [
        ...directDepositData.direct_deposit_document.bank_accounts.slice(
          0,
          currentIndexKey
        ),
        ...formStateBankAccnts,
        ...directDepositData.direct_deposit_document?.bank_accounts.slice(
          currentIndexKey + 1
        ),
      ];
      dispatch(
        addEmpDirectDepositThunk({
          ...directDepositData,
          direct_deposit_document: {
            ...directDepositData?.direct_deposit_document,
            bank_accounts: updatedBankAccnts,
          },
        })
      );

      handleNext();
    }
  };

  useEffect(() => {
    if (
      directDepositState.create.loading === LoadingType.succeeded ||
      directDepositState.update.loading === LoadingType.succeeded
    ) {
      dispatch(getEmpODRSThunk({ talent_id: directDepositData.talent_id }));
    }
  }, [directDepositState.create.loading, directDepositState.update.loading]);

  function getActiveStep() {
    if (currentStep.value === props.lastStep + 2) {
      return (
        <div>
          <DirectDepositStepThreeForm name={talentName} />
        </div>
      );
    }
    if (currentStep.value === props.lastStep + 1) {
      return (
        <div>
          <DirectDepositStepTwoForm
            talent_direct_deposit_data={
              directDepositData?.direct_deposit_document
            }
            addAccount={handleBack}
            token={token}
            handleEditAccount={(index) => handleEditAccount(index)}
          />
        </div>
      );
    }

    return (
      <div>
        {Object.keys(formState.direct_deposit_document.bank_accounts).map(
          (key, index) => {
            return (
              <div key={key + index + "form"} className="child-form-holder">
                <DirectDepositStepOneForm
                  formState={
                    formState.direct_deposit_document.bank_accounts[key]
                  }
                  onChange={(id, val) =>
                    handleBankAccntFieldChange(id, val, index)
                  }
                  amount_option={
                    formState.direct_deposit_document.bank_accounts[key]
                      .amount_type
                  }
                  edit={edit}
                />
              </div>
            );
          }
        )}
      </div>
    );
  }

  return (
    <div>
      <div>
        <div className="cnt-f-container">
          <div className="drd-header">
            <FormDialogTilteHeader
              title={title}
              subTitle={getSubTitleByStep()}
              subTitleStyle={{ fontWeight: "normal" }}
              titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <div className="cnt-f-steps-indicator">
            <CustomStepIndicator
              selected={currentStep}
              stepsList={stepsList}
              onClick={() => { }}
            />
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "2rem" }}>{getActiveStep()}</div>
      <div className="drd-actions">
        {directDepositState?.create?.error !== null && (
          <div className="error-msg">
            {directDepositState?.create?.error?.message}
          </div>
        )}
        <div className="btn-cancel">
          {currentStep.value !== 0 &&
            currentStep.value <= props.lastStep + 2 ? (
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("back")}
              enable={_isCurrentStepValid()}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleBack}
            />
          ) : (
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("cancel")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={props.onClose}
            />
          )}
        </div>
        <div className="btn-new">
          {currentStep.value === 0 && (
            <CustomButton
              loading={false}
              textStyle={{
                textTransform: "capitalize",
              }}
              name={t("save_and_next")}
              enable={isFormValid()}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() =>
                currentBankAcnt === undefined
                  ? handleAddAcnt(
                    directDepositData &&
                      directDepositData?.direct_deposit_document
                        ?.bank_accounts
                      ? directDepositData?.direct_deposit_document
                        ?.bank_accounts?.length
                      : 0
                  )
                  : handleUpdateAccount()
              }
            />
          )}
        </div>
        <div className="btn-new">
          {currentStep.value === 2 ? (
            <CustomButton
              loading={false}
              textStyle={{
                textTransform: "capitalize",
              }}
              name={t("confirm_complete")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => onSuccessClose(type)}
            />
          ) : (
            <CustomButton
              loading={false}
              textStyle={{
                textTransform: "capitalize",
              }}
              name={currentStep.value === 0 ? t("skip") : t("next")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleNext}
            />
          )}
        </div>
      </div>
    </div>
  );
  function isFormValid() {
    const valuesCheck = Object.keys(
      formState.direct_deposit_document.bank_accounts
    ).map((key) => {
      if (
        formState.direct_deposit_document.bank_accounts[key].account_number ===
        "" ||
        formState.direct_deposit_document.bank_accounts[key].routing_number ===
        "" ||
        formState.direct_deposit_document.bank_accounts[key].account_number ===
        0 ||
        formState.direct_deposit_document.bank_accounts[key].routing_number ===
        0 ||
        formState.direct_deposit_document.bank_accounts[key].routing_number.length < 9
        ||
        formState.direct_deposit_document.bank_accounts[key].bank_name === ""||
        formState.direct_deposit_document.bank_accounts[key].amount_type === ""
      ) {
        return false;
      } else {
        return true;
      }
    });

    return valuesCheck[0];
  }
};

export default DirectDepositForm;
