import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingType, THEME } from '../../../../enums';
import { ApplicationReceivedIcon } from '../../../../icons';
import { sendInterviewCalendarInviteStateActions } from '../../../../redux/dashboard/metrics/send_interview_calendar_invite/send_interview_calendar_redux';
import { useAppDispatch, useAppSelector, postSnackbarMessage, getInterviewCalendarInviteThunk, selectArchiveInterviewState, archiveInterviewCalendarInviteThunk } from '../../../../redux/store';
import './archive_interview.scss';
import { CustomButton, FormInput } from '../../../../utils';

interface Props {
    setShowArchive: any,
    data: any,
    closePopup: any,

}

const SendInterviewCalendarArchivePopup: React.FunctionComponent<Props> = (props) => {
    const {
        setShowArchive,
        data,
        closePopup,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const sendInterviewCalendarInviteArchiveState = useAppSelector((state) => selectArchiveInterviewState(state))
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (sendInterviewCalendarInviteArchiveState.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(sendInterviewCalendarInviteArchiveState.response || null));
            dispatch(getInterviewCalendarInviteThunk({ this_week: true, get_count: false, completed: false }));
            dispatch(sendInterviewCalendarInviteStateActions.clearArchiveResponse());
            setShowArchive(false);
            closePopup();
        }

    }, [sendInterviewCalendarInviteArchiveState.loading])

    const getError = () => {
        return sendInterviewCalendarInviteArchiveState.error;
    }
    return (
        <div className="interview-dialog-container">
            <div className="interview-dialog-header">
                <div className="left-fields">
                    <ApplicationReceivedIcon />
                    <span><h4>{t("archive_interview")}</h4></span>
                </div>
            </div>
            <div className="interview-dialog-content">
                <p className="ac-header-text"> {t("archive_reason")}</p>
                <div className='ac-eng-field-row'>
                    <div className='ac-sc-desc-fields'>
                        <FormInput
                            id={'message'}
                            onChange={(field: any, value: any) => {
                                setMessage(value);
                            }}
                            multiline={true}
                            required={false}
                            placeholder={""}
                            type={"text"}
                            value={message}
                        />
                    </div>

                </div>
            </div>
            <div className={"interview-dialog-footer"}>
                <div className="cmpny-op-f-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('cancel')}
                            enable={true}
                            backgroundColor={THEME.secondaryColor4}
                            onClick={() => {
                                if (getError()) handleClearError();
                                setShowArchive(false);
                                closePopup();
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={sendInterviewCalendarInviteArchiveState.loading === LoadingType.pending}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('save')}
                            enable={message !== ""}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleOnSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    function handleOnSubmit() {
        const payload: any = {
            ids: data,
            reason: message,
        }
        dispatch(archiveInterviewCalendarInviteThunk(payload));
    }

    function handleClearError() {
        dispatch(sendInterviewCalendarInviteStateActions.clearSaveResponse());
    }
}

export default SendInterviewCalendarArchivePopup;