import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../../enums";
import { OnBoardingIcon } from "../../../../icons";
import {
  IOnboardingDocsReviewStatus,
  IProfileDropdown,
  ITalent,
  ITalentOBW4I9Data,
} from "../../../../interfaces";
import {
  getUploadI9UrlThunk,
  selectI9State,
  updateTalentW4I9Details,
  uploadI9Thunk,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { CustomButton } from "../../../../utils";
import { WebViewerInstance } from "@pdftron/webviewer";
import "./i9.scss";
import PDFReader from "../../../../components/pdf_reader/pdf_reader";
import { i9SliceActions } from "../../../../redux/onboarding_portal/i9/i9_reducer";
import ApiError from "../../../../components/api_error";
import { convertDateToTimeStamp, convertStringTimeToTimestamp, dateStringToTimestamp, dateToTimestamp, getDateString } from "../../../../variables";
import OnboardingReviewForm, {
  IReviewForm,
} from "../../forms/onboarding_review_form/onboarding_review_form";
import { I9FormFields } from "../../../../onboarding/pdf_fields/i9";

interface Props {
  visible: boolean;
  type: string;
  title: string;
  successTitle: string;
  talentId: string;
  state: IOnboardingDocsReviewStatus;
  onClose: () => void;
  onDissmiss?: () => void;
  onSuccessClose: (type: string, value?: any) => void;
  talentData: ITalent | null;
  today: number;
  currentLoginUserName: string;
  recruitersList: IProfileDropdown[];
}

const I9Popup: React.FunctionComponent<Props> = (props) => {
  const {
    visible,
    type,
    title,
    state,
    talentId,
    onClose,
    onDissmiss,
    onSuccessClose,
    talentData,
    today,
    currentLoginUserName,
    recruitersList,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { uploadFile, uploadURL } = useAppSelector((state) =>
    selectI9State(state)
  );
  const actionRef = useRef<ActionDialogHolderType>(null);
  const instance = useRef<WebViewerInstance>();
  const reviewedByData = recruitersList.filter(
    (recruiter) => state && recruiter.id === state?.i9_document?.reviewed_by
  );
  const reviewedBy = reviewedByData
    .map((recruiter) => `${recruiter.first_name} ${recruiter.last_name}`)
    .join(", ");
  const [formState, setFormState] = useState<IReviewForm>({
    name:
      state && talentData
        ? talentData?.first_name + " " + talentData?.last_name
        : "",
    recruiter_completed_review:
      state && state?.i9_document?.recruiter_completed_review
        ? "Reviewed"
        : "Not Reviewed",
    reviewed_on_timestamp:
      state && state?.i9_document?.reviewed_on_timestamp !== null
        ? getDateString(state?.i9_document?.reviewed_on_timestamp, "mm/dd/yyyy")
        : getDateString(today, "mm/dd/yyyy"),
    reviewed_by:
      state && state?.i9_document?.reviewed_by !== null
        ? reviewedBy ?? ""
        : currentLoginUserName,
  });
  const [talentI9Data, setTalentI9Data] = useState<ITalentOBW4I9Data>({
    talent_id: talentId,
    w4_data: null,
    i9_data: {
      dob: 0
    }
  });
  useEffect(() => {
    if (uploadURL.loading === LoadingType.succeeded && uploadURL.response) {
      handleUploadFile(uploadURL.response.url);
    }
  }, [uploadURL.loading]);

  useEffect(() => {
    if (uploadFile.loading === LoadingType.succeeded) {
      onSuccessClose(type);
    }

    return () => {
      if (instance.current) {
        cleanPDFState(instance.current);
      }
      dispatch(i9SliceActions.clearState());
    };
  }, [uploadFile.loading]);

  useEffect(() => {
    if (instance.current && state.i9_document.url) {
      loadPDFDocument(instance.current, state.i9_document.url);
    }
  }, [state.i9_document.url]);

  function handleOnInstance(value: WebViewerInstance) {
    instance.current = value;
    instance.current.UI.disableElements(['toolsHeader']);
    loadPDFDocument(value, state.i9_document.url);
  }
  const fieldsToDisableForRecruiter = [
    "Last Name (Family Name)",
    "First Name Given Name",
    "Address Street Number and Name",
    "Apt Number (if any)",
    "City or Town",
    "State",
    "ZIP Code",
    "Date of Birth mmddyyyy",
    "US Social Security Number",
    "Employees E-mail Address",
    "Telephone Number",
    "Signature of Employee",
    "Today's Date mmddyyy",
    "Employee Middle Initial (if any)",
    "Employee Other Last Names Used (if any)",
    "CB_1",
    "CB_2",  // Checkbox field name in the PDF document
    "CB_3",
    "CB_4",
    "Foreign Passport Number and Country of IssuanceRow1",
    "Form I94 Admission Number",
    "USCIS ANumber",
    "Last Name Family Name from Section 1",
    "First Name Given Name from Section 1",
    "Middle initial if any from Section 1",
    "Signature of Preparer or Translator 0",
    "Signature of Preparer or Translator 1",
    "Signature of Preparer or Translator 2",
    "Signature of Preparer or Translator 3",
    "Sig Date mmddyyyy 0",
    "Sig Date mmddyyyy 1",
    "Sig Date mmddyyyy 2",
    "Sig Date mmddyyyy 3",
    "Preparer or Translator Last Name (Family Name) 0",
    "Preparer or Translator Last Name (Family Name) 1",
    "Preparer or Translator Last Name (Family Name) 2",
    "Preparer or Translator Last Name (Family Name) 3",
    "Preparer or Translator Address (Street Number and Name) 0",
    "Preparer or Translator Address (Street Number and Name) 1",
    "Preparer or Translator Address (Street Number and Name) 2",
    "Preparer or Translator Address (Street Number and Name) 3",
    "Preparer or Translator First Name (Given Name) 0",
    "Preparer or Translator First Name (Given Name) 1",
    "Preparer or Translator First Name (Given Name) 2",
    "Preparer or Translator First Name (Given Name) 3",
    "PT Middle Initial 0",
    "PT Middle Initial 1",
    "PT Middle Initial 2",
    "PT Middle Initial 3",
    "Preparer or Translator City or Town 0",
    "Preparer or Translator City or Town 1",
    "Preparer or Translator City or Town 2",
    "Preparer or Translator City or Town 3",
    "Preparer State 0",
    "Preparer State 1",
    "Preparer State 2",
    "Preparer State 3",
    "Zip Code 0",
    "Zip Code 1",
    "Zip Code 2",
    "Zip Code 3",
    "Document Number 0 (if any)",
    "Issuing Authority_0",
  ];
  const fieldsToEnableForRecruiter = [
    //supplement B (Page 4)
    "Document Title 0",
    "Document Title 1",
    "Document Title 2",
    "Document Number 0",
    "Document Number 1",
    "Document Number 2",
    "Expiration Date 0",
    "Expiration Date 1",
    "Expiration Date 2",
    "Date of Rehire 0",
    "Date of Rehire 1",
    "Date of Rehire 2",
    "Todays Date 0",
    "Todays Date 1",
    "Todays Date 2",
    "Name of Emp or Auth Rep 0",
    "Name of Emp or Auth Rep 1",
    "Name of Emp or Auth Rep 2",
    "Signature of Emp Rep 0",
    "Signature of Emp Rep 1",
    "Signature of Emp Rep 2",
    "Addtl Info 0",
    "Addtl Info 1",
    "Addtl Info 2",
    "CB_Alt_0",
    "CB_Alt_1",
    "CB_Alt_2",
    "Last Name 0",
    "Last Name 1",
    "Last Name 2",
    "First Name 0",
    "First Name 1",
    "First Name 2",
    "Middle Initial 0",
    "Middle Initial 1",
    "Middle Initial 2",
    "Last Name Family Name from Section 1-2",
    "First Name Given Name from Section 1-2",
    "Middle initial if any from Section 1-2",
    //Page 1 Section 2
    "CB_Alt",
    "Expiration Date if any",
    "Issuing Authority 1",
    "Issuing Authority_2",
    "Issuing Authority_0",
    "List A.  Document 2. Expiration Date (if any)",
    "List A.  Document 2",
    "List A.  Document 3",
    "List A.  Document 3 Number.  If any",
    "List A. Document 3",
    "List A. Document 3.  Enter Issuing Authority",
    "List A.   Document Title 3",
    "List A.   Document Title 3.  If any",
    "List B Document 1 Title",
    "List B Issuing Authority 1",
    "List B Document Number 1",
    "List B Expiration Date 1",
    "List C Document Title 1",
    "List C Issuing Authority 1",
    "List C Document Number 1",
    "List C Expiration Date 1",
    "Document Number 0 (if any)",
    "Document Number If any_2",
    "Document Number if any_3", //expiration date (if any) [3] field value is pointed to this value
    "Document Title 2 If any",

    "Additional Information",
    "Last Name First Name and Title of Employer or Authorized Representative",
    "Signature of Employer or AR",
    "Employers Business or Org Name",
    "Employers Business or Org Address",
    "FirstDayEmployed mmddyyyy",
    "S2 Todays Date mmddyyyy",
  ];
  const dateFields = [
    "S2 Todays Date mmddyyyy",
  ];
  function loadPDFDocument(value: WebViewerInstance, url: string) {
    const { UI, Core } = value;
    const { documentViewer, annotationManager, Annotations } = Core;

    // to disable individual input field using name
    // <<<<<<<<<----- DO NOT REMOVE THIS CODE AS IT MAY BE USED IN FUTURE REFERENCE TO DISABLE INDIVIDUAL FIELD ------>>>>>>>>>>>
    annotationManager.addEventListener(
      "annotationChanged",
      (annotations, action, { imported }) => {
        // if the annotation change occurs because of an import then
        // these are fields from inside the document
        if (imported && action === "add") {
          annotations.forEach(function (annot) {
            if (
              annot instanceof Annotations.WidgetAnnotation &&
              fieldsToDisableForRecruiter.includes(annot.fieldName)
            ) {
              annot.fieldFlags.set("ReadOnly", true); // this is to enable the field
            }
            if (
              annot instanceof Annotations.WidgetAnnotation &&
              fieldsToEnableForRecruiter.includes(annot.fieldName)
            ) {
              annot.fieldFlags.set("ReadOnly", false); // this is to enable the field
            }
          });
        }
      }
    );
    documentViewer.addEventListener("documentLoaded", () => {
      documentViewer.getAnnotationsLoadedPromise().then(() => {
        // iterate over fields
        const fieldManager = annotationManager.getFieldManager();

        /// TO print the field names in the PDF
        // const arr: any[] = [];
        // fieldManager.forEachField((field) => {
        //   arr.push(field.name)

        // });
        // End
        // Iterate over each field to get the field name and value
        fieldManager.forEachField(field => {
          const fieldName = field.name; // Get the field name
          const fieldValue = field.getValue().toString(); // Get the field value
          setTalentI9Data(prevState => {
            const newi9Data = {
              ...prevState.i9_data // Spread current w4_data
            };
            if (fieldName === "Date of Birth mmddyyyy") {
          
              newi9Data.dob = dateToTimestamp(fieldValue) // Default to 0 if NaN
            }
            return {
              ...prevState,
              i9_data: newi9Data
            };
          });

        });

        Object.keys(I9FormFields).forEach((key) => {
          const doc = I9FormFields[key];
          if (dateFields.includes(doc.key)) {
            const field = fieldManager.getField(doc.key);
            field.setValue(field.getValue() !== "" ? field.getValue() : getDateString(new Date(), "mm/dd/yyyy"));
          }
        });
      });
    });
    UI.loadDocument(url);
    UI.setFitMode(UI.FitMode.FitWidth);
  }

  function cleanPDFState(value: WebViewerInstance) {
    const { documentViewer } = value.Core;
    documentViewer.removeEventListener("documentLoaded");
    // documentViewer.removeEventListener('fieldChanged');
  }

  function handleClose() {
    closePopup(onClose);
  }
  const requiredFieldsArray = [
    "Signature of Employer or AR",
    "S2 Todays Date mmddyyyy",
    "FirstDayEmployed mmddyyyy",

  ];
  const requiredFieldsListA = [
    "Document Title 1",
    "Issuing Authority 1",

  ]
  const requiredFieldsListBC = [
    "List B Document 1 Title",
    "List B Issuing Authority 1",
    "List C Document Title 1",
    "List C Issuing Authority 1",
  ]
  const [requiredFieldMsg, setRequiredFieldMsg] = useState<boolean>(true);
  async function handleUploadFile(url: string) {
    if (instance.current) {
      const { documentViewer, annotationManager } = instance.current.Core;

      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations();
      const data = await doc.getFileData({
        // saves the document with annotations in it
        xfdfString,
      });
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: "application/pdf" });

      // TO check whether the required fields are empty or not
      const formFields = documentViewer
        .getAnnotationManager()
        .getFieldManager();

      const formFieldsData = formFields.getFields();

      const reqFieldsSection1 = formFieldsData.filter((field) =>
        requiredFieldsArray.includes(field.name)
      );
      const isRequiredFieldsEmptySec1 = reqFieldsSection1.some(
        (field) => (field.getValue() === "" || field.getValue() === " ")
      );

      const reqFieldsListA = formFieldsData.filter((field) =>
        requiredFieldsListA.includes(field.name)
      );
      const isRequiredFieldsEmptyListA = reqFieldsListA.some(
        (field) => (field.getValue() === "" || field.getValue() === " ")
      );
      const reqFieldsListBC = formFieldsData.filter((field) =>
        requiredFieldsListBC.includes(field.name)
      );
      const isRequiredFieldsEmptyListBC = reqFieldsListBC.some(
        (field) => (field.getValue() === "" || field.getValue() === " ")
      );

      if (!isRequiredFieldsEmptySec1 && (!isRequiredFieldsEmptyListA || !isRequiredFieldsEmptyListBC)) {
        setRequiredFieldMsg(false);
        dispatch(uploadI9Thunk({ url: url, file: blob }));
      }

    }
  }

  const loading =
    uploadURL.loading === LoadingType.pending ||
    uploadFile.loading === LoadingType.pending;
  const error = uploadURL.error || uploadFile.error;

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={"70%"}
      onClose={onClose}
      onDissmiss={onDissmiss}
    >
      <DialogWrapper onClose={handleClose}>
        <div className="i9-container">
          <div className="i9-header">
            <FormDialogTilteHeader
              title={t("type_form", { name: title })}
              subTitle={t("please_scroll_down_and_fill_out_the_form_below")}
              subTitleStyle={{ fontWeight: "normal" }}
              titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <div className="talent-i9-content">
            <div className="ob-review-div">
              <OnboardingReviewForm
                handleFieldChange={() => { }}
                formState={formState}
              />
            </div>
            <PDFReader onInstanceChange={handleOnInstance} />
            {error && (
              <div className={"error-section"}>
                <ApiError message={error.message} onClose={handleClearError} />
              </div>
            )}
          </div>
          <div className="i9-actions">
            {/* {requiredFieldMsg && ( */}
            <div className="errorMsg" style={{ visibility: requiredFieldMsg && state && state?.i9_document?.recruiter_completed_review ? 'hidden' : 'visible' }}>
              <span style={{ color: "Red" }}>
                {t("fill_required_fields_details_i9_recruiter")}
              </span>
            </div>
            {/* )} */}
            <div className="i9-actions-row">
              <div className="btn-cancel">
                <CustomButton
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("cancel")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => closePopup(onClose)}
                />
              </div>
              <div className="btn-new">
                <CustomButton
                  loading={loading}
                  textStyle={{
                    textTransform: "capitalize",
                  }}
                  name={t("save")}
                  enable={
                    state && state?.i9_document?.recruiter_completed_review
                      ? false
                      : true
                  }
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => (loading ? undefined : handleSave())}
                />
              </div>
            </div>

          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );

  ///Handle clear error
  function handleClearError() {
    dispatch(i9SliceActions.clearState());
  }

  /// handle submit
  function handleSave() {
    dispatch(getUploadI9UrlThunk({ talent_id: talentId }));
    dispatch(updateTalentW4I9Details(talentI9Data));
  }

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }
};

export default I9Popup;
