import { useEffect, useRef } from "react";
import { AppError } from "../../../../../interfaces";
import {
  getTEAConversation,
  selectTextEmAllState,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import { SpinnerScreen } from "../../../../../utils";
import { LoadingType } from "../../../../../enums";
import "./text_em_all.scss";
interface Props {
  //   responseURL: string;
  //   loading?: boolean;
  //   error?: AppError | null | undefined;
  //   teaConversationData: () => void;
  talent_phone: string | undefined;
  talent_id: string | undefined;
}
const TextEmAllTab: React.FunctionComponent<Props> = (props) => {
  const { talent_id, talent_phone } = props;
  const dispatch = useAppDispatch();

  const textEmAllState = useAppSelector((state) => selectTextEmAllState(state));

  useEffect(() => {
    if (talent_id && talent_id != null && talent_phone !== null) {
      dispatch(getTEAConversation({phone:talent_phone,talent_id:talent_id}));
    }
  }, [talent_id, talent_phone]);

  return (
    <div className="tdc_textemall_container">
      <div className="tdc_textemall_content">
        {textEmAllState?.loading === LoadingType.pending && <SpinnerScreen />}
        {textEmAllState?.error && textEmAllState?.error !== null ? (
          <div>
            <span className="error-span">
              {JSON.stringify(textEmAllState?.error?.message)}
            </span>
          </div>
        ) : (
          <iframe
            src={textEmAllState?.response}
            title="talent dashboard text-em-all conversation"
            className="broadcast_iframe"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default TextEmAllTab;
