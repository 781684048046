import { createAsyncThunk } from "@reduxjs/toolkit";
import { RiskManagementAPI  } from "../../../../apis";
import { AppError} from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { IUpdateReport, IWorkCompAuditAndComplianceReporting } from "../../../../interfaces/work_comp_audit_and_compliance_reporting";

export const getAllWorkCompAuditAndComplianceReportingList = createAsyncThunk<
    Array<IWorkCompAuditAndComplianceReporting>,
    string | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/work-comp-and-compliance-report/get',
    async (thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getAllWorkCompAuditAndComplianceReports();
            if(typeof res.data === 'string') return [];
            return res.data as IWorkCompAuditAndComplianceReporting[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getAllWorkCompAuditAndComplianceReportById = createAsyncThunk<
    IWorkCompAuditAndComplianceReporting,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/work-comp-and-compliance-report/get-by-id/get',
    async (record_id, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getAllWorkCompAuditAndComplianceReports(record_id);
            return res.data as IWorkCompAuditAndComplianceReporting;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const workCompAuditAndComplianceReportCreate = createAsyncThunk<
    any,
    IUpdateReport,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/work-comp-and-compliance-report/create',
    async (data, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.createWorkCompAuditAndComplianceReports(data);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const workCompAuditAndComplianceReportUpdate = createAsyncThunk<
    any,
    IUpdateReport,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/work-comp-and-compliance-report/update',
    async (data, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.updateWorkCompAuditAndComplianceReports(data)
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const workCompAuditAndComplianceReportUpload = createAsyncThunk<
    Array<IWorkCompAuditAndComplianceReporting>,
    {id: string, attachedDocs : Array<File>},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/work-comp-and-compliance-report/upload',
    async (data, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getWorkCompAndComplianceReportDocs(
                data.id,
                data.attachedDocs.map((doc) => doc.name)
            ); 
            if(typeof res.data === 'string') return [];
            return res.data as IWorkCompAuditAndComplianceReporting[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const workCompAuditAndComplianceReportDelete = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@risk-management/universal-directory/work-comp-and-compliance-report/delete',
        async (rm_wc_audits_compliance_report_id, thunkAPI) => {
            try {
                const res = await RiskManagementAPI.deleteWorkCompAuditAndComplianceReports(rm_wc_audits_compliance_report_id);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
);