import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { OnlineApplicationSent } from "../../../../interfaces/online_application_sent";
import { catchRequestError } from "../../../../utils";

export const getOnlineApplicationsSentList = createAsyncThunk<
    Array<OnlineApplicationSent>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/online_applications_sent',
    async (_, thunkAPI) => {
        try {
            const res = await DashboardAPI.getOnlineApplicationsSent();
            if(typeof res.data === 'string') return [];
            return res.data as OnlineApplicationSent[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getOnlineApplicationsSentCount = createAsyncThunk<
number,
void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/online_applications_sent/count',
    async (_, thunkAPI) => {
        try {
            const res = await DashboardAPI.getOnlineApplicationsSent(true);
            if(typeof res.data === 'string') return 0;
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);