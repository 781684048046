import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../../components/action_dialog_holder/action_dialog_holder";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { LoadingType, THEME } from "../../../../../enums";
import { CloseIcon, CompaniesIcon } from "../../../../../icons";
import { getAgencyBranches, createAgencyBranch, updateAgencyBranch } from "../../../../../redux/admin_center";
import { postSnackbarMessage, useAppDispatch } from "../../../../../redux/store";
import { FormInput, formatToPhone, enforceFormat, CustomButton } from "../../../../../utils";
import { US_states } from "../../../../../utils/States_US";
import { AgencyBranch, AppError } from "../../../../../interfaces";
import { AgencyBranchDetails } from "../../../../../redux/admin_center/agency_setup/agency_branch/agency_branch_reducer";
import { FormDialogTilteHeader } from "../../../../../components/dialog_wrapper/dialog_wrapper";
import ApiError from "../../../../../components/api_error";

interface Props {
    visible: boolean;
    data?: any;
    agencyBDState: AgencyBranchDetails;
    errorState?: AppError | null | undefined;
    onClose: () => void;
    onDismiss: () => void;
    onClearError: () => void;
    formValidators: (value: { text: string; field: string; }) => string | null;
}

const BranchForm: React.FunctionComponent<Props> = (props) => {
    const { data, visible, agencyBDState, errorState, onClose, onDismiss, onClearError, formValidators } = props
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);

    const getFormData = (value) => {
        if (value) {
            return value;
        }
        return {
            name: '', street_address: '', city: '', country: '',
            zip_code: '', phone: '', fax: '', web_url: ''
        }
    }

    const [branchDetails, setBranchDetails] = useState<AgencyBranch>(getFormData(data))
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>(false);
    const [error, setError] = useState('');

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        if ((agencyBDState.create.loading === LoadingType.succeeded || agencyBDState.update.loading === LoadingType.succeeded)) {
            dispatch(postSnackbarMessage(agencyBDState.create.response || agencyBDState.update.response));
            setTimeout(() => (onClose()), 1000);
            onClearError();
            dispatch(getAgencyBranches());
        }
    }, [agencyBDState.create.loading, agencyBDState.update.loading])

    function handleOnChange(id, value) {
        setBranchDetails({ ...branchDetails, [id]: value });
    }

    const isStateSelected = () => {
        if (branchDetails.country === '' || branchDetails.country === null) {
            return setError(t("validators.required"))
        }
        return setError("");
    }

    function _isFormValid() {
        const { name, street_address, city, country, zip_code, phone, web_url } = branchDetails;
        if (name === '' || street_address === '' || city === '' || country === '' || zip_code === '' || phone === '' || web_url === '' || zip_code?.length! < 5) return false;
        return true;
    }

    function handleSave() {
        setCheckValidationOnSubmit(true);
        isStateSelected();
    
        if (_isFormValid()) {
            const finalData = {
                name: branchDetails?.name?.trim() || '',
                street_address: branchDetails?.street_address?.trim() || '',
                city: branchDetails?.city?.trim() || '',
                country: branchDetails?.country?.trim() || '',
                zip_code: branchDetails?.zip_code?.trim() || '',
                phone: branchDetails?.phone?.trim() || '',
                fax: branchDetails?.fax?.trim() || '',
                web_url: branchDetails?.web_url?.trim() || '',
                id: branchDetails?.id || ''
            };
    
            if (data && branchDetails) {
                dispatch(updateAgencyBranch(finalData));
            } else {
                dispatch(createAgencyBranch(finalData));
            }
    
            setCheckValidationOnSubmit(false);
        }
    }
    

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"31.75vw"}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="ac-as-dialog-container">
                <div className="ac-as-dialog-header">
                    <FormDialogTilteHeader
                        title={data ? t('update_branch') : t('add_new_branch')}
                        titleIcon={<CompaniesIcon width={"100%"} height={"100%"} />}
                    />
                    <CloseIcon width={"1.25vw"} height={"1.25vw"} onClick={() => { closePopup(onClose); onClearError(); }} />
                </div>
                <div className="ac-as-dialog-content">
                    <div className="form-row">
                        <div className="form-column">
                            <FormInput
                                id={"name"}
                                onChange={(id, val) => handleOnChange(id, val)}
                                type={"text"}
                                value={branchDetails.name}
                                label={t("branch_name")}
                                placeholder=""
                                required={true}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-column">
                            <FormInput
                                id={"street_address"}
                                onChange={(id, val) => handleOnChange(id, val)}
                                type={"text"}
                                value={branchDetails.street_address}
                                label={t("address")}
                                placeholder=""
                                required={true}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                            />
                        </div>
                        <div className="form-column">
                            <FormInput
                                id={"city"}
                                onChange={(id, val) => handleOnChange(id, val)}
                                type={"text"}
                                value={branchDetails.city}
                                label={t("city")}
                                placeholder=""
                                required={true}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                            />
                        </div>
                        <div className="form-column small">
                            <CustomFormSelect
                                list={US_states.map((doc) => ({
                                    label: doc.name,
                                    value: doc.code,
                                }))}
                                customStyle={{ width: "-webkit-fill-available" }}
                                name={"country"}
                                value={branchDetails.country}
                                required={true}
                                label={t("state")}
                                placeholder={"Select"}
                                onChange={(val) => handleOnChange('country', val)}
                                customError={error && (branchDetails.country === '' || branchDetails.country === null) ? true : false}
                            />
                            {error && (branchDetails.country === '' || branchDetails.country === null) && <div className="error">{error}</div>}
                        </div>
                        <div className="form-column small">
                            <FormInput
                                id={"zip_code"}
                                onChange={(id, val) => handleOnChange(id, val)}
                                type={"text"}
                                value={branchDetails.zip_code}
                                label={t("zip_code")}
                                placeholder=""
                                required={true}
                                maxLength={5}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                            />
                        </div>
                    </div>
                    <div className="form-row third-row">
                        <div className="form-column">
                            <FormInput
                                id={"phone"}
                                onChange={(id, val) => handleOnChange(id, val)}
                                type={"tel"}
                                value={branchDetails.phone}
                                label={t("phone")}
                                placeholder=""
                                required={true}
                                maxLength={16}
                                onKeyUp={formatToPhone}
                                onKeyDown={enforceFormat}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                            />
                        </div>
                        <div className="form-column">
                            <FormInput
                                id={"fax"}
                                onChange={(id, val) => handleOnChange(id, val)}
                                type={"text"}
                                value={branchDetails.fax}
                                label={t("fax")}
                                placeholder=""
                                required={false}
                            />
                        </div>
                        <div className="form-column">
                            <FormInput
                                id={"web_url"}
                                onChange={(id, val) => handleOnChange(id, val)}
                                type={"text"}
                                value={branchDetails.web_url}
                                label={t("website")}
                                placeholder=""
                                required={true}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                            />
                        </div>
                    </div>
                    {errorState && <div className="error-holder">
                        <ApiError message={errorState?.message} onClose={onClearError} />
                    </div>}
                </div>
                <div className="ac-as-dialog-footer">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("close")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => { closePopup(onClose); onClearError(); }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            name={data ? t('update') : t('add')}
                            enable={true}
                            loading={agencyBDState.create.loading === LoadingType.pending}
                            backgroundColor={THEME.buttonColor1}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </ActionDialogHolder>
    )
}

export default BranchForm;