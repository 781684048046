import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./text_em_all_thunk";
import { TextEmAllToken } from "../../../../interfaces/admin_center_textemall";

export interface TextEmAllAuthTokenState {
  error?: AppError | null;
  loading: LoadingType;
  data: TextEmAllToken;
  update: {
    error?: AppError | null;
    loading: LoadingType;
    response: string;
  };
}

const initialState: TextEmAllAuthTokenState = {
  error: null,
  loading: LoadingType.idle,
  data: {} as TextEmAllToken,
  update: {
    error: null,
    loading: LoadingType.idle,
    response: "",
  },
};

export const textEmAllAuthTokenSlice = createSlice({
  name: "textEmAllAuthToken",
  initialState: initialState,
  reducers: {
    clearTokenError(state) {
      return { ...state, error: null };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getTextEmAllAuthToken.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getTextEmAllAuthToken.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.getTextEmAllAuthToken.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        data: action.payload,
      }))

      .addCase(thunk.updateTextEmAllAuthToken.pending, (state, action) => ({
        ...state,
        update: { ...state.update, loading: LoadingType.pending, error: null },
      }))
      .addCase(thunk.updateTextEmAllAuthToken.rejected, (state, action) => ({
        ...state,
        update: {
          ...state.update,
          loading: LoadingType.failed,
          error: action.payload,
        },
      }))
      .addCase(thunk.updateTextEmAllAuthToken.fulfilled, (state, action) => ({
        ...state,
        update: {
          ...state.update,
          loading: LoadingType.succeeded,
          data: action.payload,
        },
      }))

      .addCase(thunk.getSSOTokenThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getSSOTokenThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
        token: null,
      }))
      .addCase(thunk.getSSOTokenThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        error: null,
        token: action.payload,
      }))
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const textEmAllAuthTokenSliceActions = textEmAllAuthTokenSlice.actions;
export const selectState = (state: TextEmAllAuthTokenState) => state;
export const selectLoading = (state: TextEmAllAuthTokenState) => state.loading;
export const selectError = (state: TextEmAllAuthTokenState) => state.error;
