import {createAsyncThunk} from "@reduxjs/toolkit";
import {IJobOrderContact} from "../../../../../entities/ContactInfo";
import {AppError} from "../../../../../interfaces";
import {catchRequestError} from "../../../../../utils";
import {createJobContact} from "../../../../../apis/contacts_api";

export const createJobOrderContact = createAsyncThunk<
    Array<IJobOrderContact>,
    {job_id: string, contact_id: string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@jobs/details/contacts/contact-create/post',
    async (data, thunkAPI) => {
        try {
            const res = await createJobContact(data);
            return res.data as IJobOrderContact[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
