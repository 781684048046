import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./holiday_vacation_report_thunk";
import { IHolidayReport, IVacationReport } from "../../../../interfaces/report_center";

export interface HolidayReportState {
    error?: AppError | null,
    loading: LoadingType,
    response: IHolidayReport[] | null,
    vacation: {
        error?: AppError | null,
        loading: LoadingType,
        response: IVacationReport[] | null,
    }
}

const initialState: HolidayReportState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    vacation: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}

export const holidayReportSlice = createSlice({
    name: 'holidayVacationReport',
    initialState: initialState,
    reducers: {
        clearHRErrorState(state) {
            return { ...state, error: null };
        },
        clearHRState(state) {
            return { ...initialState };
        },
        clearVacationState(state) {
            return {
                ...state, vacation: {
                    ...initialState.vacation
                }
            };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getHolidayReportThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getHolidayReportThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getHolidayReportThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addCase(thunk.getVacationReportThunk.pending, (state, action) => ({ ...state, vacation: { ...state.vacation, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getVacationReportThunk.rejected, (state, action) => ({ ...state, vacation: { ...state.vacation, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.getVacationReportThunk.fulfilled, (state, action) => ({ ...state, vacation: { ...state.vacation, loading: LoadingType.succeeded, error: null, response: action.payload } }))


            .addDefaultCase(state => ({ ...state }));
    }
});

export const holidayVacationReportSliceActions = holidayReportSlice.actions;
export const selectState = (state: HolidayReportState) => state;
export const selectLoading = (state: HolidayReportState) => state.loading;
export const selectError = (state: HolidayReportState) => state.error;
export const selectVacationState = (state: HolidayReportState) => state.vacation;




