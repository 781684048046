import { useTranslation } from "react-i18next";
import { LoadingType, THEME } from "../../../../enums";
import { DeleteIcon, PaperPlaneIcon } from "../../../../icons";
import { deleteUploadDocument, selectProfileState, selectSalesDocumentSendByEmailState, selectSalesDocumentState, sendVideoByEmail, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CustomButton } from "../../../../utils";
import { useEffect, useState } from "react";
import { Portal } from "react-portal";
import SendByEmailPopup from "../../popups/send_by_email/send_by_email";
import { salesDocumentSendByEmailActions } from "../../../../redux/sales_marketing/marketing_docs/marketing_doc_send_by_email/send_by_email_reducer";
import { SendByEmail } from "../../../../interfaces/marketing_campaign";
import { salesDocumentActions } from "../../../../redux/sales_marketing/marketing_docs/marketing_docs_list/marketing_docs_reducer";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";

const VidePlayer = ({ url, title, id, onRetry, state }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const sendByemailState = useAppSelector((state) => selectSalesDocumentSendByEmailState(state))
    const [showSendByEmail, setShowSendByEmail] = useState<boolean>(false);
    const [showDeleteVideoDialog, setShowDeleteVideoDialog] = useState<{ visible: boolean, id: string, documentName: string | undefined  } | null>(null);

    useEffect(() => {
        if (state?.documentDelete && state.documentDelete.loading === LoadingType.succeeded) {
            setShowDeleteVideoDialog(null)
            dispatch(salesDocumentActions.clearDeleteState());
        }
    }, [state?.documentDelete])

    let videoId = '';
    if (url.toLowerCase().includes('v='))
        videoId = url.split('v=')[1].split('&')[0];
    else {
        videoId = url.split('/');
        videoId = videoId[videoId.length - 1];
    }

    function handleDelete(id: string) {
        onRetry()
        dispatch(deleteUploadDocument(id))
    }

    function handleClearError() {
        dispatch(salesDocumentActions.clearDeleteState());
    }

    return (
        <div className="job-order-docs-container">
            <iframe style={{ 'borderRadius': "5px" }} width="250" height="250" src={`https://www.youtube.com/embed/${videoId}?controls=1`} allowFullScreen={true} frameBorder={0}></iframe>
            <div className="job-order-docs-container-header">
                <span className="jod-font-style">{title}</span>
                <button onClick={() => setShowDeleteVideoDialog({visible: true, id: id, documentName: title})}>
                    <div className="icon-btn tdp-edit-icon">
                        <DeleteIcon width={"100%"} height={"100%"} />
                    </div>
                </button>
            </div>
            <div>
                <CustomButton
                    textStyle={{ textTransform: 'capitalize' }}
                    enable={true}
                    leftIcon={
                        <div>
                            <PaperPlaneIcon
                                height= {"1vw"}
                                width= {"1vw"}
                                style={{ color: THEME.white }}
                            />
                        </div>
                    }
                    name={t("send_by_email")}
                    backgroundColor={THEME.buttonBackgroundColor4}
                    onClick={() => setShowSendByEmail(true)}
                />
            </div>
            {showSendByEmail && (
                <Portal>
                    <SendByEmailPopup
                        visible={showSendByEmail}
                        DocumentId={id}
                        onClose={() => setShowSendByEmail(false)}
                        onDissmiss={() => setShowSendByEmail(false)}
                        onSuccessClose={handleOnSendByEmailSuccess}
                        onSubmit={handleOnSubmit}
                        profileState={profileState}
                        sendByemailState={sendByemailState}
                        loading={sendByemailState.sendVideoLinkToEmail.loading}
                        error={sendByemailState.sendVideoLinkToEmail.error}
                    />
                </Portal>
            )}
            {showDeleteVideoDialog && showDeleteVideoDialog.visible && (
                <DeleteConfirmationDialog
                    message={t("are_you_sure_you_want_to_delete_this_document", {
                        documentName: showDeleteVideoDialog.documentName,
                    })}
                    onCancel={() => setShowDeleteVideoDialog(null)}
                    onConfirm={() => handleDelete(showDeleteVideoDialog.id)}
                    deleting={state.documentDelete.loading === LoadingType.pending}
                    deleteType={t("Document")}
                    state={state?.documentDelete}
                    onClearError={handleClearError}
                />
            )}
        </div>
    )

    function handleOnSubmit(data: SendByEmail) {
        dispatch(sendVideoByEmail(data));
    }

    function handleOnSendByEmailSuccess() {
        setShowSendByEmail(false);
        dispatch(salesDocumentSendByEmailActions.clearsendVideoLinkToEmailState());
    }
}

export default VidePlayer;