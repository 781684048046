import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { SalesCall } from "../../../../interfaces/sales_call";
import * as thunk from "./sales_call_summary_report_thunk";
import { IReportCenterUploadUrlResponse } from "../../../../interfaces/report_center";


export interface SalesCallSummaryReportListState extends EntityState<SalesCall> {
    error?: AppError | null,
    loading: LoadingType,
    downloadURL: {
        error?: AppError | null;
        loading: LoadingType;
        response: IReportCenterUploadUrlResponse | null;
    };
}

const salesCallSummaryReportListAdapter: EntityAdapter<SalesCall> = createEntityAdapter<SalesCall>({
    selectId: (doc) => doc.id,
})

const initialState: SalesCallSummaryReportListState = salesCallSummaryReportListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    downloadURL: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
})


export const salesCallSummaryReportListSlice = createSlice({
    name: 'salesCallSummaryReportList',
    initialState: initialState,
    reducers: {
        clearSalesCallSummaryReportListError(state) {
            return { ...state, error: null };
        },
        clearSalesCallSummaryReportListState(state) {
            return salesCallSummaryReportListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
        clearSCSRDownloadState(state) {
            return {
                ...state, downloadURL: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getSalesCallSummaryReportFilteredList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getSalesCallSummaryReportFilteredList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getSalesCallSummaryReportFilteredList.fulfilled, (state, action) => salesCallSummaryReportListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            
            .addCase(thunk.getSalesCallSummaryReportDownloadUrl.pending, (state, action) => ({ ...state, downloadURL: { ...state.downloadURL, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getSalesCallSummaryReportDownloadUrl.rejected, (state, action) => ({ ...state, downloadURL: { ...state.downloadURL, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.getSalesCallSummaryReportDownloadUrl.fulfilled, (state, action) => ({ ...state, downloadURL: { ...state.downloadURL, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearSalesCallSummaryReportListError, clearSalesCallSummaryReportListState, clearSCSRDownloadState } = salesCallSummaryReportListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = salesCallSummaryReportListAdapter.getSelectors();
export const selectState = (state: SalesCallSummaryReportListState) => state;
export const selectLoading = (state: SalesCallSummaryReportListState) => state.loading;
export const selectError = (state: SalesCallSummaryReportListState) => state.error;
export const selectSalesCallSummaryReportDownloadUrl = (state: SalesCallSummaryReportListState) => state.downloadURL;


