import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ActionDialogHolder,
    ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, {
    FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import { BOBillingInvoiceIcon } from "../../../../icons";
import "./invoice_correction_popup.scss";
import {
    correctTimecards,
    getCorrectionTimecards,
    selectInvoiceCorrectionState,
    useAppDispatch,
    useAppSelector,
} from "../../../../redux/store";
import { LoadingType, THEME } from "../../../../enums";
import { ICorrectTimeCards } from "../../../../interfaces";
import SortableTable, {
    TableData,
} from "../../../../components/sortable_table/sortable_table";
import { getDateString } from "../../../../variables";
import {
    allowNumbersWithDotAndFourDecimals,
    convertNumberToMoney,
    currencyConversion,
    CustomButton,
    FormInput,
    removeLastDecimal,
    SpinnerScreen,
} from "../../../../utils";
import SearchBar from "../../../../components/search_bar";
import { DateInput } from "../../../../components/date_input/date_input";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { InvoiceCorrectionSliceActions } from "../../../../redux/back_office/billing_invoices/invoice_correction/invoice_correction_reducer";
import ApiError from "../../../../components/api_error";

const tableHeader = [
    { title: "talent", code: "talent_name" },
    { title: "company", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "job_title", code: "job_title" },
    { title: "week_ending", code: "week_ending_date" },
    { title: "pay_type", code: "pay_type" },
    { title: "bill_units", code: "bill_units" },
    { title: "bill_rate", code: "bill_rate" },
    { title: "item_bill", code: "item_bill" },
];

interface Props {
    visible: boolean;
    title: string;
    onClose: () => void;
    currentLoginUserName: string;
    onSuccessClose: () => void;
    openedInvoice: {
        visible: boolean;
        invoice_id: string;
        invoice_date: number;
        company_name: string;
    }
}
const InvoceCorrectionPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        currentLoginUserName, onSuccessClose, openedInvoice
    } = props;

    const actionRef = useRef<ActionDialogHolderType>(null);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { company_name, invoice_id, invoice_date } = openedInvoice
    const invoiceCrctState = useAppSelector((state) =>
        selectInvoiceCorrectionState(state)
    );
    const updateState = invoiceCrctState.update;

    const [timecards, setTimecards] = useState<ICorrectTimeCards[]>([]);
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [next, setNext] = useState<{ step1: boolean, step2: boolean, step3: boolean }>({ step1: true, step2: false, step3: false });
    const [formState, setFormState] = useState<ICorrectTimeCards[] | null>(null);
    const [queryParam, setQueryParam] = useState<{ same_invoice_number: boolean, invoice_date: number }>({ same_invoice_number: false, invoice_date: invoice_date })
    useEffect(() => {
        dispatch(getCorrectionTimecards(invoice_id));
    }, []);

    useEffect(() => {
        if (
            invoiceCrctState.loading === LoadingType.succeeded &&
            invoiceCrctState.response
        ) {
            setTimecards(invoiceCrctState.response);
            setFormState(invoiceCrctState.response);
        }
    }, [invoiceCrctState.loading, invoiceCrctState.response]);

    useEffect(() => {
        if (updateState.loading === LoadingType.succeeded && updateState.response) {
            setNext({ step1: false, step2: false, step3: true });
        }
    }, [updateState.loading, updateState.response])

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };
    const getFilteredList = () => {
        let list: ICorrectTimeCards[] | null = timecards;
        if (sortedField != null && list) {
            list = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return list.filter((doc) => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(
                (val) => val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    };
    function getFlexNumber(value: number) {
        if (value === 0) return 1.2;
        if (value === 1) return 1.2;
        if (value === 4) return 1.1;
        return 1;
    }
    const handleClose = () => {
        onClose();
        dispatch(InvoiceCorrectionSliceActions.clearUpdateState());
        dispatch(InvoiceCorrectionSliceActions.clearState());
    }
    const successClose = () => {
        handleClose();
        onSuccessClose();

    }
    const handleCancel = () => {
        if (next.step1) {
            handleClose();
        }
        else if (next.step2) {
            setNext({ ...next, step1: true, step2: false })
            handleClearError()
        }
        else if (next.step3) {

        }
    }

    const handleSave = () => {
        if (next.step1) {
            setNext({ ...next, step1: false, step2: true });
        } else if (next.step2 && formState) {

            const updatedFormstate = formState.map((doc) => {
                return {
                    ...doc,
                    payments: doc.payments.map((payment) => {
                        return {
                            ...payment,
                            bill_units: payment.bill_units ? convertNumberToMoney(payment.bill_units, "number") as number : 0,
                            bill_rate: payment.bill_rate ? convertNumberToMoney(payment.bill_rate, "number") as number : 0,
                            item_bill: payment.item_bill ? convertNumberToMoney(payment.item_bill, "number") as number : 0,
                        };
                    })
                }

            })
            dispatch(correctTimecards({
                invoice_id: invoice_id,
                timeCards: updatedFormstate,
                same_invoice_number: !queryParam.same_invoice_number,
                invoice_date: queryParam.invoice_date
            }))


        } else if (next.step3) {
            setNext({ step1: false, step2: false, step3: false });
            successClose();
        }
    };
    const handleQueryParam = (id: string, val: any) => {
        setQueryParam({ ...queryParam, [id]: val })
    }

    const handleTimecardChange = (id: string, val: string, timeCardId: string, paytypeid: string) => {
        if (id === "bill_rate") {
            val = removeLastDecimal(val);
        }
        if (formState) {
            const updatedFormState = formState.map((item) => {
                if (item.id === timeCardId) {
                    const updatedPayments = item.payments.map((payment, index) => {
                        if (payment.pay_type_id === paytypeid) {
                            let billUnitVal, billRateVal;
                            if (id === "bill_units") {
                                billUnitVal = val;
                                billRateVal = payment.bill_rate;
                            }
                            else if (id === "bill_rate") {
                                billRateVal = val;
                                billUnitVal = payment.bill_units;
                            }
                            return {
                                ...payment,
                                bill_units: billUnitVal,
                                bill_rate: billRateVal,
                                item_bill: billUnitVal.toString().replace(/,/g, '') !== "" ? convertNumberToMoney(billRateVal.toString().replace(/,/g, '') * parseFloat(billUnitVal.toString().replace(/,/g, '')), "number") as number : 0
                            };
                        }
                        return payment;
                    });
                    return {
                        ...item,
                        payments: updatedPayments,
                        updated: true
                    };
                };
                return item;
            });
            setFormState(updatedFormState);
        }
    };
    const handleClearError = () => {
        dispatch(InvoiceCorrectionSliceActions.clearErrorState());
        dispatch(InvoiceCorrectionSliceActions.clearUpdateErrorState());
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"55%"}
            maxWrapperWidth={"60%"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={handleClose}>
                <div className="ic-container">
                    <div className="ic-header">
                        <FormDialogTilteHeader
                            title={company_name + " - " + title}
                            titleIcon={
                                <BOBillingInvoiceIcon width={"100%"} height={"100%"} />
                            }
                        />
                    </div>
                    <div className="ic-content">
                        {next.step1 && (
                            <div className="step1">
                                <div className="ic-c-h">
                                    <div className="total-count">
                                        <span>
                                            {`${t("total_count")}: `}
                                            <span className="total-count-number">
                                                <b>{getFilteredList()?.length}</b>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="search-bar">
                                        <SearchBar
                                            value={search}
                                            onChange={setSearch}
                                            onSearch={() => { }}
                                            placeholder="Search..."
                                        />
                                    </div>
                                </div>
                                {invoiceCrctState.loading === LoadingType.pending ?
                                    <SpinnerScreen />
                                    :
                                    <div className="ic-t-table">
                                        <SortableTable
                                            headerList={tableHeader}
                                            sortedField={sortedField}
                                            onSortChange={handleSortFieldChange}
                                            flexNumber={getFlexNumber}
                                            isAsc={sortDirection}
                                        >
                                            {getFilteredList().map((doc, docIndex) =>
                                                doc.payments.map((payment, payIndex) => (
                                                    <tr key={doc.id + docIndex + payIndex} className={docIndex % 2 === 0 ? 'tr-even' : 'tr-odd'}>
                                                        {payIndex === 0 ? (
                                                            <>
                                                                <TableData
                                                                    customStyle={{ flex: getFlexNumber(0) }}
                                                                >
                                                                    <span>{doc.talent_name}</span>
                                                                </TableData>
                                                                <TableData
                                                                    customStyle={{ flex: getFlexNumber(1) }}
                                                                >
                                                                    <span>{doc.company_name}</span>
                                                                </TableData>
                                                                <TableData
                                                                    customStyle={{ flex: getFlexNumber(2) }}
                                                                >
                                                                    <span>{doc.department_name}</span>
                                                                </TableData>
                                                                <TableData
                                                                    customStyle={{ flex: getFlexNumber(3) }}
                                                                >
                                                                    <span>{doc.job_title}</span>
                                                                </TableData>
                                                                <TableData
                                                                    customStyle={{ flex: getFlexNumber(4) }}
                                                                >
                                                                    <span>
                                                                        {getDateString(doc.week_ending_date)}
                                                                    </span>
                                                                </TableData>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TableData
                                                                    customStyle={{ flex: getFlexNumber(0) }}
                                                                >
                                                                    <></>
                                                                </TableData>
                                                                <TableData
                                                                    customStyle={{ flex: getFlexNumber(1) }}
                                                                >
                                                                    <></>
                                                                </TableData>
                                                                <TableData
                                                                    customStyle={{ flex: getFlexNumber(2) }}
                                                                >
                                                                    <></>
                                                                </TableData>
                                                                <TableData
                                                                    customStyle={{ flex: getFlexNumber(3) }}
                                                                >
                                                                    <></>
                                                                </TableData>
                                                                <TableData
                                                                    customStyle={{ flex: getFlexNumber(4) }}
                                                                >
                                                                    <></>
                                                                </TableData>
                                                            </>
                                                        )}
                                                        <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                            <span>{payment.pay_type}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                                            <div className="form-input-holder">
                                                                <FormInput
                                                                    id={"bill_units"}
                                                                    onChange={(id, val) => handleTimecardChange(id, val, doc.id, payment.pay_type_id)}
                                                                    type={"text"}
                                                                    step={"0.01"}
                                                                    min={"0.00"}
                                                                    value={formState?.find((formDoc) => formDoc.id === doc.id)?.payments.find(pay => pay.pay_type_id === payment.pay_type_id)?.bill_units}
                                                                    customStyle={{ width: "3vw" }}
                                                                    placeholder="0"
                                                                    onKeyUp={(e) =>
                                                                        allowNumbersWithDotAndFourDecimals(e)
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                        allowNumbersWithDotAndFourDecimals(e)
                                                                    }
                                                                />
                                                            </div>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                            <div className="form-input-holder">
                                                                <FormInput
                                                                    id={"bill_rate"}
                                                                    onChange={(id, val) => handleTimecardChange(id, val, doc.id, payment.pay_type_id)}
                                                                    type={"text"}
                                                                    step={"0.01"}
                                                                    min={"0.00"}
                                                                    value={formState?.find((formDoc) => formDoc.id === doc.id)?.payments.find(pay => pay.pay_type_id === payment.pay_type_id)?.bill_rate}
                                                                    customStyle={{ width: "3vw" }}
                                                                    placeholder="0"
                                                                    onKeyUp={(e) =>
                                                                        allowNumbersWithDotAndFourDecimals(e)
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                        allowNumbersWithDotAndFourDecimals(e)
                                                                    }
                                                                />
                                                            </div>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                            <span>
                                                                {formState ?
                                                                    currencyConversion(formState?.find((formDoc) => formDoc.id === doc.id)?.payments.find(pay => pay.pay_type_id === payment.pay_type_id)?.item_bill ?? 0, 4)
                                                                    // ? (!isNaN(payment.bill_units) && !isNaN(parseInt(payment.bill_rate.toString().replace(/,/g, '')))) ? currencyConversion(payment.bill_units * parseFloat(payment.bill_rate.toString().replace(/,/g, '')), 4) : currencyConversion(payment.item_bill, 4)
                                                                    : currencyConversion(payment.item_bill, 4)}
                                                            </span>
                                                        </TableData>
                                                    </tr>
                                                ))
                                            )}
                                        </SortableTable>
                                    </div>
                                }

                            </div>
                        )}
                        {next.step2 && (
                            <div className="step2">
                                <ToggleSwitch
                                    label={t("keep_same_invoice_num")}
                                    checked={(queryParam.same_invoice_number)}
                                    onChange={(value) => handleQueryParam("same_invoice_number", value)}
                                    labelPosition="top"
                                />
                                <DateInput
                                    id={"invoice_date"}
                                    onChange={(id, val) => handleQueryParam(id, val)}
                                    placeholder={"mm/dd/yyyy"}
                                    value={queryParam.invoice_date}
                                    label={t("update_invoice_date")}
                                />
                            </div>
                        )}
                        {next.step3 && (
                            <DocSavedText>
                                <div>
                                    <div className="aknw-name">
                                        <span>{t("hi_username", { userName: currentLoginUserName })}</span>
                                    </div>
                                    <div className="aknw-name">
                                        <span> {updateState.response}</span>
                                    </div>
                                </div>

                            </DocSavedText>
                        )}

                    </div>
                    <div className="ic-actions">
                        <div className="er-span">
                            {updateState.error && (
                                <div className={"error-section"}>
                                    <ApiError message={updateState.error.message} onClose={handleClearError} />
                                </div>
                            )}
                        </div>
                        <div className="ic-actions-row">
                            {updateState.loading === LoadingType.succeeded ? (
                                <>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("ok")}
                                            enable={true}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={successClose}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="btn-cancel" style={{ marginRight: "1em" }}>
                                        {!next.step3 &&
                                            <CustomButton
                                                loading={false}
                                                textStyle={{ textTransform: "capitalize" }}
                                                name={next.step2 ? t("back") : t("cancel")}
                                                enable={true}
                                                backgroundColor={THEME.toggleDisableColor}
                                                onClick={handleCancel}
                                            />}
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={
                                                updateState.loading === LoadingType.pending
                                            }
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={next.step1 ? t("next") : (next.step2 ? t("update") : t("ok"))}
                                            enable={true}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={handleSave}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    );
};

export default InvoceCorrectionPopup;
