import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, IInterviewCalendarInvite, ITrafficLightEngagementDetails } from "../../../../interfaces";
import * as thunk from "./send_interview_calendar_thunk";

export interface SendInterviewCalendarInviteState {
    error?: AppError | null,
    loading: LoadingType,
    data: IInterviewCalendarInvite[],
    remainingCount:number,
    completedCount:number,
    save: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null,
    },
    archive: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null,
    }
}



const initialState: SendInterviewCalendarInviteState = {
    error: null,
    loading: LoadingType.idle,
    data: [],
    remainingCount:0,
    completedCount:0,
    save: {
        error: null,
        loading: LoadingType.idle,
        response: null
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: null
    },
    archive: {
        error: null,
        loading: LoadingType.idle,
        response: null
    }
}


export const sendInterviewCalendarInviteSlice = createSlice({
    name: 'sendInterviewCalendarInvite',
    initialState: initialState,
    reducers: {
        clearSaveResponse(state) {
            return { ...state, save: initialState.save };
        },
        clearUpdateResponse(state) {
            return ({ ...state, update: initialState.update });
        },
        clearArchiveResponse(state) {
            return ({ ...state, archive: initialState.archive });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getInterviewCalendarInviteThunk.pending,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.pending,
                    error: null,
                })
            )
            .addCase(
                thunk.getInterviewCalendarInviteThunk.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getInterviewCalendarInviteThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null,
                    data: action.payload,
                })
            )

            .addCase(
                thunk.saveInterviewCalendarInviteThunk.pending,
                (state, action) => ({
                    ...state, save: {
                        ...state.save,
                        loading: LoadingType.pending,
                        error: null,
                    }
                })
            )
            .addCase(
                thunk.saveInterviewCalendarInviteThunk.rejected,
                (state, action) => ({
                    ...state, save: {
                        ...state.save,
                        loading: LoadingType.failed,
                        error: null,
                    }
                })
            )
            .addCase(
                thunk.saveInterviewCalendarInviteThunk.fulfilled,
                (state, action) => ({
                    ...state, save: {
                        ...state.save,
                        loading: LoadingType.succeeded,
                        error: null,
                        response:action.payload
                    }
                })
            )

            .addCase(
                thunk.updateInterviewCalendarInviteThunk.pending,
                (state, action) => ({
                    ...state, update: {
                        ...state.update,
                        loading: LoadingType.pending,
                        error: null,
                    }
                })
            )
            .addCase(
                thunk.updateInterviewCalendarInviteThunk.rejected,
                (state, action) => ({
                    ...state, update: {
                        ...state.update,
                        loading: LoadingType.failed,
                        error: null,
                    }
                })
            )
            .addCase(
                thunk.updateInterviewCalendarInviteThunk.fulfilled,
                (state, action) => ({
                    ...state, update: {
                        ...state.update,
                        loading: LoadingType.succeeded,
                        error: null,
                        response:action.payload
                    }
                })
            )

            .addCase(
                thunk.getRemainingInterviewInviteCount.pending,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.pending,
                    error: null,
                })
            )
            .addCase(
                thunk.getRemainingInterviewInviteCount.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getRemainingInterviewInviteCount.fulfilled,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null,
                    remainingCount: action.payload,
                })
            )

            .addCase(
                thunk.getCompletedInterviewInviteCount.pending,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.pending,
                    error: null,
                })
            )
            .addCase(
                thunk.getCompletedInterviewInviteCount.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getCompletedInterviewInviteCount.fulfilled,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null,
                    completedCount: action.payload,
                })
            )
            
            .addCase(
                thunk.archiveInterviewCalendarInviteThunk.pending,
                (state, action) => ({
                    ...state, archive: {
                        ...state.archive,
                        loading: LoadingType.pending,
                        error: null,
                    }
                })
            )
            .addCase(
                thunk.archiveInterviewCalendarInviteThunk.rejected,
                (state, action) => ({
                    ...state, archive: {
                        ...state.archive,
                        loading: LoadingType.failed,
                        error: null,
                    }
                })
            )
            .addCase(
                thunk.archiveInterviewCalendarInviteThunk.fulfilled,
                (state, action) => ({
                    ...state, archive: {
                        ...state.archive,
                        loading: LoadingType.succeeded,
                        error: null,
                        response:action.payload
                    }
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const sendInterviewCalendarInviteStateActions = sendInterviewCalendarInviteSlice.actions;
export const selectState = (state: SendInterviewCalendarInviteState) => state;
export const selectSaveState = (state: SendInterviewCalendarInviteState) => state.save;
export const selectUpdateState = (state: SendInterviewCalendarInviteState) => state.update;
export const selectData = (state: SendInterviewCalendarInviteState) => state.data;
export const selectRemainingCount=(state: SendInterviewCalendarInviteState) => state.remainingCount;
export const selectCompletedCount=(state: SendInterviewCalendarInviteState) => state.completedCount;
export const selectArchiveState=(state:SendInterviewCalendarInviteState)=>state.archive;


