import { useTranslation } from "react-i18next";
import {
    getFileViewURLThunk,
    getW2UploadURLThunk,
    selectFileViewDownloadState,
    uploadW2FileThunk,
    useAppDispatch,
    useAppSelector,
} from "../../../../redux/store";
import { WebViewerInstance } from "@pdftron/webviewer";
import { useEffect, useRef, useState } from "react";
import {
    ActionDialogHolderType,
    ActionDialogHolder,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, {
    FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import {
    AppError,
    IFileUplodState,
    ITalentW2,
    IURLState,
} from "../../../../interfaces";
import { EmplportalIcon } from "../../../../icons";
import PDFReader from "../../../../components/pdf_reader/pdf_reader";
import {
    CustomButton,
    getValueFromKey,
    SpinnerScreen,
} from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import { W2FormFields } from "../../pdf_fields/w2";
import { talentW2SliceActions } from "../../../../redux/employee_portal/edocs_certification/talent_w2/talent_w2_reducer";
import "./w2.scss";
import ApiError from "../../../../components/api_error";
import { fileViewDownloadSliceActions } from "../../../../redux/files/file_view_download/file_view_download_reducer";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
interface Props {
    visible: boolean;
    title: string;
    talentId: string;
    data: ITalentW2 | null;
    onClose: () => void;
    onDissmiss?: () => void;
    onSuccessClose: () => void;
    currentLoginUserName: string;
    generate: boolean;
    uploadURLState: IURLState;
    uploadFileState: IFileUplodState;
}

const W2Popup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        data,
        talentId,
        title,
        onClose,
        onSuccessClose,
        currentLoginUserName,
        uploadURLState,
        uploadFileState,
        generate,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const actionRef = useRef<ActionDialogHolderType>(null);
    const instance = useRef<WebViewerInstance>();
    const fileViewState = useAppSelector((state) =>
        selectFileViewDownloadState(state)
    );
    const [viewURLResponse, setViewURLResponse] = useState<string>("");
    const [successMsg, setSuccessMsg] = useState<string>("");

    /// Reload document on component re-render
    useEffect(() => {
        if (generate && instance.current && data && data.w2_template_download_url) {
            loadPDFDocument(instance.current, data.w2_template_download_url);
        }
    }, [data, data?.w2_template_download_url, generate]);

    useEffect(() => {
        if (!generate && data) {
            dispatch(getFileViewURLThunk(data.w2_path));
        }
    }, [generate, data]);
    useEffect(() => {
        if (
            fileViewState.viewURL.loading === LoadingType.succeeded &&
            fileViewState.viewURL.response
        ) {
            setViewURLResponse(fileViewState.viewURL.response);
        }
    }, [fileViewState.viewURL.response, fileViewState.viewURL.loading]);

    useEffect(() => {
        if (
            uploadURLState.loading === LoadingType.succeeded &&
            uploadURLState.response
        ) {
            handleUploadFile(uploadURLState.response.url);
            setSuccessMsg("Generating your W2 file....");
            setTimeout(() => setSuccessMsg(""), 5000);
        }
    }, [uploadURLState.loading]);

    useEffect(() => {
        if (uploadFileState.loading === LoadingType.succeeded) {
            setSuccessMsg("File Generated Successfully!");
            setTimeout(() => setSuccessMsg(""), 5000);
            dispatch(fileViewDownloadSliceActions.clearViewState());
            onSuccessClose();
        }
        return () => {
            if (instance.current) {
                cleanPDFState(instance.current);
            }
            dispatch(talentW2SliceActions.clearUploadFile());
        };
    }, [uploadFileState.loading]);

    function cleanPDFState(value: WebViewerInstance) {
        const { documentViewer } = value.Core;
        documentViewer.removeEventListener("documentLoaded");
        // documentViewer.removeEventListener('fieldChanged');
    }

    function handleOnInstance(value: WebViewerInstance) {
        instance.current = value;
        instance.current.UI.disableElements(["toolsHeader"]);
        if(data){
            loadPDFDocument(value,data.w2_template_download_url);
        }
        instance.current.UI.disableElements(["toolsHeader"]);
    }
    function loadPDFDocument(value: WebViewerInstance, url: string) {
        const { UI, Core } = value;
        const { documentViewer, annotationManager, Annotations } = Core;
        annotationManager.addEventListener(
            "annotationChanged",
            (annotations, action, { imported }) => {
                if (imported && action === "add") {
                    annotations.forEach(function (annot) {
                        if (annot instanceof Annotations.WidgetAnnotation) {
                            annot.fieldFlags.set("ReadOnly", true); // this is to DISABLE the field
                        }
                    });
                }
            }
        );
        documentViewer.addEventListener("documentLoaded", () => {
            documentViewer.getAnnotationsLoadedPromise().then(() => {
                // iterate over fields
                const fieldManager = annotationManager.getFieldManager();
                
                if (data) {
                    const updatedData = {
                        ...data,
                        employee_full_address: `${data.employee_name}\n${data.employee_street_address}\n${data.employee_city}, ${data.employee_state} ${data.employee_zipcode}`,
                        employer_full_address: `${data.employer_name}\n${data.employer_street_address}\n${data.employer_city}, ${data.employer_state} ${data.employer_zipcode}`,
                    };
                    Object.keys(W2FormFields).forEach((key) => {
                        const doc = W2FormFields[key];
                        if (doc.mapTo) {
                            const field = fieldManager.getField(doc.key);
                            if(field){
                                field.setValue(getValueFromKey(doc.mapTo, { ...updatedData }));
                            }
                        }
                    });
                }
            });
        });

        // Add download button
        // UI.setHeaderItems(header => {
        //     header.push({
        //         type: 'actionButton',
        //         img: '<svg>...</svg>', // You can add your own SVG icon here for the download button
        //         onClick: async () => {
        //             const doc = documentViewer.getDocument();
        //             const data = await doc.getFileData({}); // Fetch the document's file data

        //             // Create a Blob from the file data
        //             const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });

        //             // Create a download link and trigger it
        //             const link = document.createElement('a');
        //             link.href = window.URL.createObjectURL(blob);
        //             link.download = 'downloadedDocument.pdf'; // Set the file name for the download
        //             link.click();
        //             window.URL.revokeObjectURL(link.href); // Clean up after download
        //         },
        //         title: 'Download PDF' // Tooltip text when hovering over the button
        //     });
        // });
        UI.setFitMode(UI.FitMode.FitWidth);
        UI.loadDocument(url);
    }

    function handleClose() {
        closePopup(onClose);

        setViewURLResponse("");
    }
    function handleSave() {
        if (data) {
            dispatch(getW2UploadURLThunk({ talent_id: talentId, year: data.year }));
        }
    }
    async function handleUploadFile(url: string) {
        if (instance.current) {
            const { documentViewer, annotationManager } = instance.current.Core;
            const doc = documentViewer.getDocument();
            const xfdfString = await annotationManager.exportAnnotations();
            const data = await doc.getFileData({
                // saves the document with annotations in it
                xfdfString,
            });
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: "application/pdf" });
            dispatch(uploadW2FileThunk({ url: url, file: blob }));
        }
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"70%"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={handleClose}>
                <div className="w2-container">
                    <div className="w2-header">
                        <FormDialogTilteHeader
                            title={t("type_form", { name: title })}
                            titleIcon={<EmplportalIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="w2-content">
                        {generate ? (
                            <PDFReader onInstanceChange={handleOnInstance} />
                        ) : (
                            <div>
                                {fileViewState.viewURL.loading !== LoadingType.succeeded ? (
                                    <SpinnerScreen />
                                ) : (
                                    <iframe src={viewURLResponse} title={title} className="resp-iframe"></iframe>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="w2-actions">

                        <div className={"error-section"}>
                            {uploadURLState.error && (
                                <ApiError
                                    message={uploadURLState.error.message}
                                    onClose={() =>
                                        dispatch(talentW2SliceActions.clearUploadUrlError())
                                    }
                                />
                            )}
                            {uploadFileState.error && (
                                <ApiError
                                    message={uploadFileState.error.message}
                                    onClose={() =>
                                        dispatch(talentW2SliceActions.clearUploadFileError())
                                    }
                                />
                            )}
                        </div>
                        {generate && (


                            <div className="w2-actions-row">

                                {successMsg !== "" &&
                                    <div className="generate-span">
                                        <span><SpinnerScreen /></span>
                                        <span style={{ color: "green" }}>{"Generating your w2 File"}</span>
                                    </div>
                                }
                                <div className="btn-cancel">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("cancel")}
                                        enable={true}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={() => closePopup(onClose)}
                                    />
                                </div>
                                <div className="btn-new">
                                    <CustomButton
                                        loading={
                                            uploadURLState.loading === LoadingType.pending ||
                                            uploadFileState.loading === LoadingType.pending
                                        }
                                        textStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        name={t("save")}
                                        enable={true}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={handleSave}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    );
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
};

export default W2Popup;
