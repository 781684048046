import { useHistory } from "react-router-dom";
import { THEME, TrafficLightMetricsType } from "../../../../../enums";
import {
  LocationIcon,
  SectorIcon,
  WebsiteIcon,
} from "../../../../../icons";
import "./deduction_entity_dashboard.scss";
import { useTranslation } from "react-i18next";
import { addressParser } from "../../../../../utils";
import SimpleUserAvatar from "../../../../../components/user_avatar/simple_user_avatar";

const DeductionHeader = (props) => {
  return (
    <div className="deduct-ds-user-info">
      <SimpleUserAvatar
        onClick={() => {}}
        url={""}
      />
      <div className="deduct-ds-user-name">
        <span>{props.name}</span>
      </div>
    </div>
  );
};

export const DeductionDetailsSection = (props) => {
  const { location } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const deductionInfo = props.deductionInfo;

  const getStatusStyle = (status: string) => {
    if(status)
      return deductionInfo.status.toUpperCase() === "ACTIVE"
        ? { color: "green" }
        : { color: "red" };
    return ;
  };

  const getStatusText = (status: string) => {
    if(status)
      return deductionInfo.status.toUpperCase() === "ACTIVE"
        ? "Active"
        : "Inactive";
    return "";
  };

  return (
    <div className="deduct-ds-container">
      <DeductionHeader
        name={deductionInfo?.value ?? ""}
        objectId={deductionInfo?.id}
        rating={0}
        level={TrafficLightMetricsType.medium}
      />
      <div className="deduct-ds-info-container">
        <div className="deduct-ds-status">
          <span>
            {`${t("status")}: `}
            <span className="status-txt" style={getStatusStyle(deductionInfo?.status)}>
              {getStatusText(deductionInfo?.status)}
            </span>
          </span>
        </div>
      </div>
      <div className="deduct-ds-info-container">
        <div className="deduct-ds-info-icon">
          <SectorIcon width={"100%"} height={"100%"} />
        </div>
        <div className="deduct-ds-info-content">
          <div className="deduct-ds-info-holder">
            <div className="deduct-ds-into-title"></div>

            <div className="deduct-ds-info-value">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "normal" }}>
                  {t("type_of_deduction").toUpperCase()}
                </span>
                {deductionInfo?.type_of_deduction ? (
                  <span>{deductionInfo?.type_of_deduction}</span>
                ) : (
                  <span className="na-span">NA</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="deduct-ds-info-container">
        <div className="deduct-ds-info-icon">
          <LocationIcon width={"100%"} height={"100%"} />
        </div>
        <div className="deduct-ds-info-content">
          <div className="deduct-ds-info-holder">
            <div className="deduct-ds-into-title"></div>
            <div className="deduct-ds-info-value">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "normal" }}>
                  {t("location").toUpperCase()}
                </span>
                <span>
                  {addressParser({
                    street_address: deductionInfo
                      ? deductionInfo["street_address"]
                      : null,
                    city: deductionInfo ? deductionInfo["city"] : null,
                    state: deductionInfo ? deductionInfo["state"] : null,
                    zip_code: deductionInfo ? deductionInfo["zipcode"] : null,
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="deduct-ds-info-container">
        <div className="deduct-ds-info-icon">
          <WebsiteIcon
            width={"100%"}
            height={"100%"}
            style={{ color: THEME.primaryColor }}
          />
        </div>
        <div className="deduct-ds-info-content">
          <div className="deduct-ds-info-holder">
            <div className="deduct-ds-into-title"></div>
            <div className="deduct-ds-info-value">
              {deductionInfo?.website && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: "normal" }}>
                    {t("website").toUpperCase()}
                  </span>
                  <span>{deductionInfo?.website}</span>
                </div>
              )}
              {deductionInfo?.website == null && (
                <span className="na-span">NA</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
