import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./billing_invoices_list_thunk";
import { AppError, IBillingBatch } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

export interface BillingInvoiceListState {
  error?: AppError | null;
  loading: LoadingType;
  response: IBillingBatch[] | null;
}

const initialState: BillingInvoiceListState = {
  error: null,
  loading: LoadingType.idle,
  response: null,
};

export const billingBatchListSlice = createSlice({
  name: "billingBatchListSlice",
  initialState: initialState,
  reducers: {
    clearState(state) {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getBillingInvoiceListThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getBillingInvoiceListThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.getBillingInvoiceListThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        response: action.payload,
      }))
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const billingInvoiceListActions = billingBatchListSlice.actions;
export const selectState = (state: BillingInvoiceListState) => state;
export const selectAll = (state: BillingInvoiceListState) => state.response;
