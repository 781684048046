import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

/// I18n
import './app/i18n';
import config from './app/config';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const StrictMode: React.FunctionComponent<{ children: React.ReactNode }> = (props) => {
  if (config.ENV !== 'production') {
    return (
      <>
        {props.children}
      </>
    )
  }

  return (
    <React.StrictMode>
      {props.children}
    </React.StrictMode>
  )
}
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    alert('New version available!  Ready to update?');
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
