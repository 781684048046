import { useTranslation } from "react-i18next";
import { getWeekEndingDatesDropdownThunk, selectWeekEndingDatesDDList, selectWeekEndingDatesState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { SpinnerScreen, convertNumberToMoney, currencyConversion } from "../../../../../utils";
import { useEffect, useState } from "react";
import { LoadingType } from "../../../../../enums";
import SearchBar from "../../../../../components/search_bar";
import { getBillingInvoiceReportThunk, selectBillingInvoiceReportList, selectBillingInvoiceReportState } from "../../../../../redux/back_office/billing_invoices";

const tableHeader = [
    { title: "customer", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "invoice_number", code: "invoice_number" },
    { title: "invoice_amount", code: "invoice_amount" },
    { title: "balance_amount", code: "balance_amount" },
    { title: "sales_tax_amount", code: "sales_tax_amount" },
];

interface Props {
    batch_id: string;
    week_accounting_id: string;
}

const CNBBBillingAndInvoicesReportPage = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState('');

    const billingInvoiceReportState = useAppSelector((state) =>
        selectBillingInvoiceReportState(state)
    );
    const billingInvoiceReportList = useAppSelector((state) =>
        selectBillingInvoiceReportList(state)
    );

    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);

    useEffect(() => {
        dispatch(getBillingInvoiceReportThunk({ week_accounting_id: props.week_accounting_id, batch_id: props.batch_id }));
    }, []);

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function getFilteredList() {
        let list;
        if (sortedField != null && billingInvoiceReportList) {
            list = [...billingInvoiceReportList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (list ?? billingInvoiceReportList)?.filter(doc => {
            const str = search.trim().toLowerCase();
            const companyNameFilter = doc.company_name ? doc.company_name.trim().toLowerCase().includes(str) : false;
            const departmentNameFilter = doc.department_name ? doc.department_name.toString().trim().toLowerCase().includes(str) : false;
            const invoiceNumberFilter = doc.invoice_number ? doc.invoice_number.toString().trim().toLowerCase().includes(str) : false;
            const invoiceAmountFilter = doc.invoice_amount ? convertNumberToMoney(doc.invoice_amount).toString().trim().toLowerCase().includes(str) : false;
            const balanceAmountFilter = doc.balance_amount ? convertNumberToMoney(doc.balance_amount).toString().trim().toLowerCase().includes(str) : false;
            const salesTaxAmountFilter = doc.sales_tax_amount ? convertNumberToMoney(doc.sales_tax_amount).toString().trim().toLowerCase().includes(str) : false;
            return companyNameFilter || departmentNameFilter || invoiceNumberFilter || invoiceAmountFilter || balanceAmountFilter || salesTaxAmountFilter;
        });
    }

    return (
        <div className="billing-invoices-container" style={{ boxShadow: "none" }}>
            <div className="billing-invoices-header">
                <div className="select-row">
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                </div>
                <div>
                    <SearchBar value={search} onChange={setSearch} onSearch={() => { }} />
                </div>
            </div>
            <div className="billing-invoices-content">
                <div className="billing-invoices-table">
                    {billingInvoiceReportList &&
                        billingInvoiceReportState.loading === LoadingType.pending ? (
                        <>
                            <SpinnerScreen></SpinnerScreen>
                        </>
                    ) : (
                        <SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList() && getFilteredList()!.length > 0 ?
                                getFilteredList()?.map((doc) => {
                                    return (
                                        <tr key={doc.id}>
                                            <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                <span>{doc.company_name ?? "N/A"}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                                <span>{doc.department_name ?? "Corporate"}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                <span>{doc.invoice_number ?? "N/A"}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                <span>{currencyConversion(doc.invoice_amount, 2)}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                <span>{currencyConversion(doc.balance_amount, 2)}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                <span>{doc.sales_tax_amount !== null ? currencyConversion(doc.sales_tax_amount, 2) : "N/A"}</span>
                                            </TableData>
                                        </tr>
                                    );
                                })
                                :
                                <tr>
                                    <TableData customStyle={{ flex: getFlexNumber(10) }}><div style={{ width: '100%', textAlign: "center", fontSize: '0.9vw' }}>{t("no_data_available")}</div></TableData>
                                </tr>
                            }
                        </SortableTable>
                    )}
                </div>
            </div>
        </div>
    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 0.5;
        if (value === 9) return 1.2;
        if (value === 10) return 1;
        if (value === 11) return 1;
        if (value === 12) return 1;
        return 1;
    }
}

export default CNBBBillingAndInvoicesReportPage;