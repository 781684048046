import { createSlice } from "@reduxjs/toolkit";
import { Agency, AppError } from "../../../interfaces";
import { LoadingType } from "../../../enums";
import * as thunk from './apollo_credits_thunk'
import { IApolloCredits } from "../../../interfaces/apollo_search_import";

export interface ApolloCreditsState {
    error?: AppError | null,
    loading: LoadingType,
    response:IApolloCredits | null,
    addCredits: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    }
}

const initialState: ApolloCreditsState = {
    error: null,
    loading: LoadingType.idle,
    response:null,
    addCredits: {
        error: null,
        loading: LoadingType.idle,
        response: null
    }
}

export const apolloCreditsSlice = createSlice({
    name: "apolloCredits",
    initialState: initialState,
    reducers: {
        clearGetCreditsStateError(state) {
            return { ...state, error: null };
        },
        clearApolloAddCreditsState(state) {
            return {
                ...state, addCredits: {
                    ...state.addCredits, error: null,
                    loading: LoadingType.idle,
                    response: null
                }
            };
        },
        clearApolloCreditsState(state) {
            return {
                ...state, error: null, loading: LoadingType.idle, addCredits: {
                    ...state.addCredits, error: null,
                    loading: LoadingType.idle,
                    response: null
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.apolloGetCreditsThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null }))
            .addCase(thunk.apolloGetCreditsThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload }))
            .addCase(thunk.apolloGetCreditsThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, response: action.payload }))

            .addCase(thunk.apolloAddCreditsThunk.pending, (state, action) => ({ ...state, addCredits: { ...state.addCredits, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.apolloAddCreditsThunk.rejected, (state, action) => ({ ...state, addCredits: { ...state.addCredits, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.apolloAddCreditsThunk.fulfilled, (state, action) => ({ ...state, addCredits: { ...state.addCredits, loading: LoadingType.succeeded, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const apolloCreditsActions = apolloCreditsSlice.actions;
export const selectState = (state: ApolloCreditsState) => state;