import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll } from "./company_edocs_reducer";

const select = (state: IRootState) => state.companies.details.companyEdocs;
export const selectCompanyEDocsState = createSelector(
    select,
    selectState
);
export const selectCompanyEDocsList = createSelector(select, selectAll)
