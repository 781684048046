import { useRef } from "react";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../action_dialog_holder/action_dialog_holder";
import DialogWrapper from "../dialog_wrapper/dialog_wrapper";
import "./broadcast_talent.scss";
import { AppError } from "../../interfaces";
import { SpinnerScreen } from "../../utils";
interface Props {
  visible: boolean;
  title: string;
  successTitle: string;
  onClose: () => void;
  onSuccessClose: () => void;
  responseURL: string;
  loading?: boolean;
}
const BroadcastToTalentPopup: React.FunctionComponent<Props> = (props) => {
  const {
    visible,
    onClose,
    responseURL,
    loading,
  } = props;

  const actionRef = useRef<ActionDialogHolderType>(null);
  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={"80%"}
      onClose={onClose}
      onDissmiss={onClose}
    >
      <DialogWrapper onClose={onClose}>
        <div className="broadcast_talent_container">
          <div className="bc_talent_header"></div>
          <div className="bc_talent_content">
            {loading && <SpinnerScreen />}

            <iframe
              src={responseURL}
              title="text-em-all broadcast talent"
              className="broadcast_iframe"
            ></iframe>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );
};

export default BroadcastToTalentPopup;
