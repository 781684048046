import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import * as thunk from "./manage_marketing_campaign_thunk";

export interface ManageMarketingCampaignState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    },
}

const initialState: ManageMarketingCampaignState = {
    create: {
        error: null,
        loading: LoadingType.idle,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
    },
}


export const manageMarketingCampaignSlice = createSlice({
    name: 'manageMarketingCampaign',
    initialState: initialState,
    reducers: {
        clearCreateMarketingCampaignState(state) {
            state.create = { ...initialState.create };
        },
        clearUpdateMarketingCampaignState(state) {
            state.update = { ...initialState.update };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.createMarketingCampaign.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createMarketingCampaign.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createMarketingCampaign.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateMarketingCampaign.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateMarketingCampaign.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateMarketingCampaign.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageMarketingCampaignActions = manageMarketingCampaignSlice.actions;
export const selectState = (state: ManageMarketingCampaignState) => state;
export const selectCreateState = (state: ManageMarketingCampaignState) => state.create;
export const selectUpdateState = (state: ManageMarketingCampaignState) => state.update;


