import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType, TALENT_SKILLS_TYPE } from "../../../../../enums";
import { AppError, ISkillOption, ICompany, IDepartment, Profile, IContact, Assignment } from "../../../../../interfaces";
import * as thunk from "./assignment_details_thunk";


interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

interface RemoveState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialRemoveState: RemoveState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


/// [Job Matches]
// export interface SingleCompanyJobMatchesState {
//     data: Array<ITalentJobMatch> | null,
//     error?: AppError | null,
//     loading: LoadingType,
//     add: AddState,
//     remove: RemoveState,
// }


// const singleCompanyJobMatchesInitialState: SingleCompanyJobMatchesState = {
//     data: null,
//     error: null,
//     loading: LoadingType.idle,
//     add: initialAddState,
//     remove: initialRemoveState,
// }

// /// [Company Matches]
// export interface SingleCompanyMatchesState {
//     data: Array<ITalentCompanyMatch> | null,
//     error?: AppError | null,
//     loading: LoadingType,
//     add: AddState,
//     remove: RemoveState,
// }


// const singleTalentCompanyMatchesInitialState: SingleCompanyMatchesState = {
//     data: null,
//     error: null,
//     loading: LoadingType.idle,
//     add: initialAddState,
//     remove: initialRemoveState,
// }

/// [Skills]
export interface SingleAssignmentSkillsState {
    data: Array<ISkillOption> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}



const singleAssignmentSkillsInitialState: SingleAssignmentSkillsState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}

export interface SingleAssignmentRoleUsersState {
    data: Array<Profile> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}

const singleAssignmentRoleUsersInitialState: SingleAssignmentRoleUsersState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}



export interface SingleAssignmentState {
    error?: AppError | null,
    loading: LoadingType,
    data: Assignment | null,
    // jobMatches: SingleCompanyJobMatchesState,
    // companyMatches: SingleCompanyMatchesState,
    // [TALENT_SKILLS_TYPE.professional_skills]: SingleAssignmentSkillsState,
    // [TALENT_SKILLS_TYPE.credentials]: SingleAssignmentSkillsState,
    // [TALENT_SKILLS_TYPE.certifications]: SingleAssignmentSkillsState,
    // [TALENT_SKILLS_TYPE.education]: SingleAssignmentSkillsState,
    // [TALENT_SKILLS_TYPE.safety_equipment]: SingleAssignmentSkillsState,
    [TALENT_SKILLS_TYPE.account_managers]: SingleAssignmentRoleUsersState,
    [TALENT_SKILLS_TYPE.recruiters]: SingleAssignmentRoleUsersState,
}


export interface AssignmentDetailsState {
    [key: string]: SingleAssignmentState,
}


const initialState: AssignmentDetailsState = {}


function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

function getResponseSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}

export const assignmentDetailsSlice = createSlice({
    name: 'assignmentDetails',
    initialState: initialState,
    reducers: {
        clearAssignmentDetailsAddOptionError(state, action: PayloadAction<{id: string, type: string}>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        add: {
                            ...state[action.payload.id][action.payload.type].add,
                            ...initialAddState,
                        },

                    },
                }
            };
        },

        clearAssignmentDetailsRemoveOptionError(state, action: PayloadAction<{id: string, type: string}>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        remove: {
                            ...state[action.payload.id][action.payload.type].remove,
                            ...initialRemoveState,
                        },

                    },
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getAssignmentById.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...getLoadingState(state[action.meta.arg.assignment_id]),
                        // jobMatches: singleCompanyJobMatchesInitialState,
                        // companyMatches: singleTalentCompanyMatchesInitialState,
                        // [TALENT_SKILLS_TYPE.professional_skills]: singleAssignmentSkillsInitialState,
                        // [TALENT_SKILLS_TYPE.credentials]: singleAssignmentSkillsInitialState,
                        // [TALENT_SKILLS_TYPE.certifications]: singleAssignmentSkillsInitialState,
                        // [TALENT_SKILLS_TYPE.education]: singleAssignmentSkillsInitialState,
                        // [TALENT_SKILLS_TYPE.safety_equipment]: singleAssignmentSkillsInitialState,
                        [TALENT_SKILLS_TYPE.account_managers]: singleAssignmentRoleUsersInitialState,
                        [TALENT_SKILLS_TYPE.recruiters]: singleAssignmentRoleUsersInitialState,
                    }
                })
            )
            .addCase(
                thunk.getAssignmentById.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...getErrorState(state[action.meta.arg.assignment_id], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getAssignmentById.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...getSuccessState(state[action.meta.arg.assignment_id], action.payload),
                    }
                })
            )


            // /// Get all skillls
            // .addCase(
            //     thunk.getAssignmentAllSkills.pending,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.assignment_id]: {
            //             ...state[action.meta.arg.assignment_id],
            //             [TALENT_SKILLS_TYPE.professional_skills]: {
            //                 ...getLoadingState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.professional_skills]),

            //             },
            //             [TALENT_SKILLS_TYPE.credentials]: {
            //                 ...getLoadingState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.credentials]),

            //             },
            //             [TALENT_SKILLS_TYPE.certifications]: {
            //                 ...getLoadingState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.certifications]),

            //             },
            //             [TALENT_SKILLS_TYPE.education]: {
            //                 ...getLoadingState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.education]),

            //             },
            //             [TALENT_SKILLS_TYPE.safety_equipment]: {
            //                 ...getLoadingState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.safety_equipment]),
            //             },
            //         }
            //     })
            // )
            // .addCase(
            //     thunk.getAssignmentAllSkills.rejected,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.assignment_id]: {
            //             ...state[action.meta.arg.assignment_id],
            //             [TALENT_SKILLS_TYPE.professional_skills]: {
            //                 ...getErrorState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.professional_skills], action.payload),

            //             },
            //             [TALENT_SKILLS_TYPE.credentials]: {
            //                 ...getErrorState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.credentials], action.payload),

            //             },
            //             [TALENT_SKILLS_TYPE.certifications]: {
            //                 ...getErrorState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.certifications], action.payload),

            //             },
            //             [TALENT_SKILLS_TYPE.education]: {
            //                 ...getErrorState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.education], action.payload),

            //             },
            //             [TALENT_SKILLS_TYPE.safety_equipment]: {
            //                 ...getErrorState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.safety_equipment], action.payload),
            //             },
            //         }
            //     })
            // )
            // .addCase(
            //     thunk.getAssignmentAllSkills.fulfilled,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.assignment_id]: {
            //             ...state[action.meta.arg.assignment_id],
            //             [TALENT_SKILLS_TYPE.professional_skills]: {
            //                 ...getSuccessState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.professional_skills], action.payload[TALENT_SKILLS_TYPE.professional_skills]),
            //             },
            //             [TALENT_SKILLS_TYPE.credentials]: {
            //                 ...getSuccessState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.credentials], action.payload[TALENT_SKILLS_TYPE.credentials]),
            //             },
            //             [TALENT_SKILLS_TYPE.certifications]: {
            //                 ...getSuccessState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.certifications], action.payload[TALENT_SKILLS_TYPE.certifications]),
            //             },
            //             [TALENT_SKILLS_TYPE.education]: {
            //                 ...getSuccessState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.education], action.payload[TALENT_SKILLS_TYPE.education]),
            //             },
            //             [TALENT_SKILLS_TYPE.safety_equipment]: {
            //                 ...getSuccessState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.safety_equipment], action.payload[TALENT_SKILLS_TYPE.safety_equipment]),
            //             },
            //         }
            //     })
            // )

            // /// Skills list [DELETE]
            // .addCase(
            //     thunk.removeAssignmentSkills.pending,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.assignment_id]: {
            //             ...state[action.meta.arg.assignment_id],
            //             [action.meta.arg.value.skill_type]: {
            //                 ...state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type],
            //                 remove: {
            //                     ...getLoadingState(state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type].remove),
            //                 },
            //             },
            //         }
            //     })
            // )
            // .addCase(
            //     thunk.removeAssignmentSkills.rejected,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.assignment_id]: {
            //             ...state[action.meta.arg.assignment_id],
            //             [action.meta.arg.value.skill_type]: {
            //                 ...state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type],
            //                 remove: {
            //                     ...getErrorState(state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type].remove, action.payload),
            //                 },
            //             },
            //         }
            //     })
            // )
            // .addCase(
            //     thunk.removeAssignmentSkills.fulfilled,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.assignment_id]: {
            //             ...state[action.meta.arg.assignment_id],
            //             [action.meta.arg.value.skill_type]: {
            //                 ...state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type],
            //                 remove: {
            //                     ...getResponseSuccessState(state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type].remove, action.payload),
            //                 },
            //             },
                        
            //         }
            //     })
            // )

            // /// Skills list [POST]
            // .addCase(
            //     thunk.addAssignmentSkills.pending,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.assignment_id]: {
            //             ...state[action.meta.arg.assignment_id],
            //             [action.meta.arg.value.skill_type]: {
            //                 ...state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type],
            //                 add: {
            //                     ...getLoadingState(state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type].add),
            //                 },
            //             },
            //         }
            //     })
            // )
            // .addCase(
            //     thunk.addAssignmentSkills.rejected,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.assignment_id]: {
            //             ...state[action.meta.arg.assignment_id],
            //             [action.meta.arg.value.skill_type]: {
            //                 ...state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type],
            //                 add: {
            //                     ...getErrorState(state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type].add, action.payload),
            //                 },
            //             },
            //         }
            //     })
            // )
            // .addCase(
            //     thunk.addAssignmentSkills.fulfilled,
            //     (state, action) => ({
            //         ...state,
            //         [action.meta.arg.assignment_id]: {
            //             ...state[action.meta.arg.assignment_id],
            //             [action.meta.arg.value.skill_type]: {
            //                 ...state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type],
            //                 add: {
            //                     ...getResponseSuccessState(state[action.meta.arg.assignment_id][action.meta.arg.value.skill_type].add, action.payload),
            //                 },
            //             },
            //         }
            //     })
            // )


            /// Get all account managers
            .addCase(
                thunk.getAssignmentAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...getLoadingState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers]),

                        },
                    }
                })
            )
            .addCase(
                thunk.getAssignmentAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...getErrorState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers], action.payload),

                        },
                    }
                })
            )
            .addCase(
                thunk.getAssignmentAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...getSuccessState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers], action.payload),
                        },
                    }
                })
            )


            /// AccountManagers list [DELETE]
            .addCase(
                thunk.removeAssignmentAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeAssignmentAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getErrorState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeAssignmentAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers].remove, action.payload),
                            },
                        },
                        
                    }
                })
            )

            /// Account managers list [POST]
            .addCase(
                thunk.addAssignmentAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getLoadingState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addAssignmentAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getErrorState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addAssignmentAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.account_managers].add, action.payload),
                            },
                        },
                    }
                })
            )


            /// Get all account managers
            .addCase(
                thunk.getAssignmentRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getLoadingState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters]),

                        },
                    }
                })
            )
            .addCase(
                thunk.getAssignmentRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getErrorState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters], action.payload),

                        },
                    }
                })
            )
            .addCase(
                thunk.getAssignmentRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getSuccessState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters], action.payload),
                        },
                    }
                })
            )


            /// Recruiters list [DELETE]
            .addCase(
                thunk.removeAssignmentRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeAssignmentRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getErrorState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeAssignmentRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters].remove, action.payload),
                            },
                        },
                        
                    }
                })
            )

            /// Account managers list [POST]
            .addCase(
                thunk.addAssignmentRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getLoadingState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addAssignmentRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getErrorState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addAssignmentRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.assignment_id]: {
                        ...state[action.meta.arg.assignment_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.assignment_id][TALENT_SKILLS_TYPE.recruiters].add, action.payload),
                            },
                        },
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const assignmentDetailsSliceActions = assignmentDetailsSlice.actions;
export const selectState = (state: AssignmentDetailsState) => state;
export const selectAssignmentState = (state: AssignmentDetailsState, id: string): SingleAssignmentState => state[id];
// export const selectAssignmentJobMatchesState = (state: SingleAssignmentState): SingleCompanyJobMatchesState => state.jobMatches;
// export const selectAssignmentCompanyMatchesState = (state: SingleAssignmentState): SingleCompanyMatchesState => state.companyMatches;
// export const selectAssignmentSkillsState = (state: SingleAssignmentState): SingleAssignmentSkillsState => state.professional_skills;
// export const selectAssignmentCredentialsState = (state: SingleAssignmentState): SingleAssignmentSkillsState => state.credentials;
// export const selectAssignmentCertificationsState = (state: SingleAssignmentState): SingleAssignmentSkillsState => state.certifications;
// export const selectAssignmentEducationState = (state: SingleAssignmentState): SingleAssignmentSkillsState => state.education;
// export const selectAssignmentEquipmentState = (state: SingleAssignmentState): SingleAssignmentSkillsState => state.safety_equipment;

export const selectInitialTalentState = (): SingleAssignmentState => ({
    data: null,
    loading: LoadingType.pending,
    error: null,
    // jobMatches: singleCompanyJobMatchesInitialState,
    // companyMatches: singleTalentCompanyMatchesInitialState,
    // [TALENT_SKILLS_TYPE.professional_skills]: singleAssignmentSkillsInitialState,
    // [TALENT_SKILLS_TYPE.credentials]: singleAssignmentSkillsInitialState,
    // [TALENT_SKILLS_TYPE.certifications]: singleAssignmentSkillsInitialState,
    // [TALENT_SKILLS_TYPE.education]: singleAssignmentSkillsInitialState,
    // [TALENT_SKILLS_TYPE.safety_equipment]: singleAssignmentSkillsInitialState,
    [TALENT_SKILLS_TYPE.account_managers]: singleAssignmentRoleUsersInitialState,
    [TALENT_SKILLS_TYPE.recruiters]: singleAssignmentRoleUsersInitialState,
});


