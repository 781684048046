import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../../apis";
import { ROLE, TALENT_SKILLS_TYPE } from "../../../../../enums";
import { AppError, IAddSkill, IAddTalentStaff, IRemoveTalentStaff, ITalent, ITalentJobMatch, ITalentMatchesRequestBody, ISkillsInfo, Profile, IRemoveSkill, ITalentCompanyMatch } from "../../../../../interfaces";
import { catchRequestError, getTalentMatchQuery } from "../../../../../utils";


export const getTalentById = createAsyncThunk<
    ITalent,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/get',
    async (talentId, thunkAPI) => {
        try {
            const res = await TalentAPI.talentById(talentId);
            // if(typeof res.data === 'string') return [];
            thunkAPI.dispatch(getTalentJobMatches({ talentId, query: getTalentMatchQuery(res.data) }));
            thunkAPI.dispatch(getTalentCompanyMatches({ talentId, query: getTalentMatchQuery(res.data) }));
            thunkAPI.dispatch(getTalentAllSkills(talentId));
            thunkAPI.dispatch(getTalentAccountManagers({ talent_id: talentId }));
            thunkAPI.dispatch(getTalentRecruiters({ talent_id: talentId }));
            return res.data as ITalent;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);




/// [Talent] ---> JOB Matches
export const getTalentJobMatches = createAsyncThunk<
    Array<ITalentJobMatch>,
    { talentId: string, query: ITalentMatchesRequestBody },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/job_matches/get',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.getJobMatches(payload);
            if (typeof res.data === 'string') return [];
            return res.data as ITalentJobMatch[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// [Talent] ---> Company Matches
export const getTalentCompanyMatches = createAsyncThunk<
    Array<ITalentCompanyMatch>,
    { talentId: string, query: ITalentMatchesRequestBody },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/company_matches/get',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.getCompanyMatches(payload);
            if (typeof res.data === 'string') return [];
            return res.data as ITalentCompanyMatch[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// [Talent] ---> Skills
export const getTalentAllSkills = createAsyncThunk<
    ISkillsInfo,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/skills/get',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalentSkills(payload);
            if (typeof res.data === 'string') return {
                [TALENT_SKILLS_TYPE.professional_skills]: [],
                [TALENT_SKILLS_TYPE.certifications]: [],
                [TALENT_SKILLS_TYPE.education]: [],
                [TALENT_SKILLS_TYPE.credentials]: [],
                [TALENT_SKILLS_TYPE.safety_equipment]: []
            };
            return res.data as ISkillsInfo;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const addTalentSkills = createAsyncThunk<
    string,
    { talentId: string, value: IAddSkill },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/company/skills/post',
    async (data, thunkAPI) => {
        try {
            const res = await TalentAPI.addTalentSkill(data);
            thunkAPI.dispatch(getTalentAllSkills(data.talentId));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeTalentSkills = createAsyncThunk<
    string,
    { talentId: string, value: IRemoveSkill },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/skills/delete',
    async (data, thunkAPI) => {
        try {
            const res = await TalentAPI.deleteTalentSkill(data);
            thunkAPI.dispatch(getTalentAllSkills(data.talentId));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);



/// [Talent]--> [Alpha omega staffing] --> staff

export const getTalentAccountManagers = createAsyncThunk<
    Array<Profile>,
    { talent_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/account_managers/get',
    async (query, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalentStaff({ ...query, role: ROLE.accountManager, });

            if (typeof res.data === 'string') return [];
            return res.data as Profile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addTalentAccountManagers = createAsyncThunk<
    string,
    IAddTalentStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/account_managers/post',
    async (data, thunkAPI) => {
        try {
            const res = await TalentAPI.addTalentStaff(data);
            thunkAPI.dispatch(getTalentAccountManagers({ talent_id: data.talent_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeTalentAccountManagers = createAsyncThunk<
    string,
    IRemoveTalentStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/account_managers/delete',
    async (data, thunkAPI) => {
        try {
            const res = await TalentAPI.deleteTalentStaff(data);
            thunkAPI.dispatch(getTalentAccountManagers({ talent_id: data.talent_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const getTalentRecruiters = createAsyncThunk<
    Array<Profile>,
    { talent_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/recruiters/get',
    async (query, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalentStaff({ ...query, role: ROLE.recruiter, });
            if (typeof res.data === 'string') return [];
            return res.data as Profile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addTalentRecruiters = createAsyncThunk<
    string,
    IAddTalentStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/recruiters/post',
    async (data, thunkAPI) => {
        try {
            const res = await TalentAPI.addTalentStaff(data);
            thunkAPI.dispatch(getTalentRecruiters({ talent_id: data.talent_id, }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeTalentRecruiters = createAsyncThunk<
    string,
    IRemoveTalentStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/recruiters/delete',
    async (data, thunkAPI) => {
        try {
            const res = await TalentAPI.deleteTalentStaff(data);
            thunkAPI.dispatch(getTalentRecruiters({ talent_id: data.talent_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

