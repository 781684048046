import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState,
} from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./work_comp_audit_and_compliance_reporting_thunk";
import { IWorkCompAuditAndComplianceReporting } from "../../../../interfaces/work_comp_audit_and_compliance_reporting";

export interface WorkCompAuditAndComplianceReportingListState
  extends EntityState<IWorkCompAuditAndComplianceReporting> {
  error?: AppError | null;
  loading: LoadingType;
  getById: {
    response: any;
    error?: AppError | null;
    loading: LoadingType;
  };
  create: {
    response: any;
    error?: AppError | null;
    loading: LoadingType;
  };
  update: {
    response: any;
    error?: AppError | null;
    loading: LoadingType;
  };
  upload: {
    response: any;
    error?: AppError | null;
    loading: LoadingType;
  };
  delete: {
    error?: AppError | null,
    loading: LoadingType,
    response: string,
  }

}

const workCompAuditAndComplianceReportingListAdapter: EntityAdapter<IWorkCompAuditAndComplianceReporting> =
  createEntityAdapter<IWorkCompAuditAndComplianceReporting>({
    selectId: (doc) => doc.id,
  });

const initialState: WorkCompAuditAndComplianceReportingListState =
  workCompAuditAndComplianceReportingListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    getById: {
      response: "",
      error: null,
      loading: LoadingType.idle,
    },
    create: {
      response: "",
      error: null,
      loading: LoadingType.idle,
    },
    update: {
      response: "",
      error: null,
      loading: LoadingType.idle,
    },
    upload: {
      response: "",
      error: null,
      loading: LoadingType.idle,
    },
    delete: {
      error: null,
      loading: LoadingType.idle,
      response: "",
    }

  });

export const workCompAuditAndComplianceReportingListSlice = createSlice({
  name: "workCompAuditAndComplianceReportingList",
  initialState: initialState,
  reducers: {
    clearWorkCompAuditAndComplianceReportingListError(state) {
      return { ...state, error: null };
    },
    clearWorkCompAuditAndComplianceReportingListState(state) {
      return workCompAuditAndComplianceReportingListAdapter.removeAll({
        ...state,
        loading: LoadingType.idle,
        error: null,
      });
    },
    clearWCCRGetByIdState(state) {
      return {
        ...state,
        getById: {
          response: "",
          error: null,
          loading: LoadingType.idle,
        }
      };
    },
    clearWCCRCreateState(state) {
      return {
        ...state,
        create: {
          response: "",
          error: null,
          loading: LoadingType.idle,
        }
      };
    },
    clearWCCRUpdateState(state) {
      return {
        ...state,
        update: {
          response: "",
          error: null,
          loading: LoadingType.idle,
        }
      };
    },
    clearWCCRUploadState(state) {
      return {
        ...state,
        upload: {
          response: "",
          error: null,
          loading: LoadingType.idle,
        }
      };
    },
    clearWCCRDeleteState(state) {
      return {
        ...state,
        delete: {
          response: "",
          error: null,
          loading: LoadingType.idle,
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        thunk.getAllWorkCompAuditAndComplianceReportingList.pending,
        (state, action) => ({
          ...state,
          loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
          error: null,
        })
      )
      .addCase(
        thunk.getAllWorkCompAuditAndComplianceReportingList.rejected,
        (state, action) => ({
          ...state,
          loading: LoadingType.failed,
          error: action.payload,
        })
      )
      .addCase(
        thunk.getAllWorkCompAuditAndComplianceReportingList.fulfilled,
        (state, action) =>
          workCompAuditAndComplianceReportingListAdapter.setAll(
            { ...state, loading: LoadingType.succeeded, error: null },
            action.payload
          )
      )
      .addCase(
        thunk.getAllWorkCompAuditAndComplianceReportById.pending,
        (state, action) => ({
          ...state,
          getById: {
            loading: LoadingType.pending,
            error: null,
            response: "",
          },
        })
      )
      .addCase(
        thunk.getAllWorkCompAuditAndComplianceReportById.rejected,
        (state, action) => ({
          ...state,
          getById: {
            loading: LoadingType.failed,
            error: action.payload,
            response: "",
          },
        })
      )
      .addCase(
        thunk.getAllWorkCompAuditAndComplianceReportById.fulfilled,
        (state, action) => ({
          ...state,
          getById: {
            loading: LoadingType.succeeded,
            error: null,
            response: action.payload,
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportCreate.pending,
        (state, action) => ({
          ...state,
          create: {
            loading: LoadingType.pending,
            error: null,
            response: "",
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportCreate.rejected,
        (state, action) => ({
          ...state,
          create: {
            loading: LoadingType.failed,
            error: action.payload,
            response: "",
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportCreate.fulfilled,
        (state, action) => ({
          ...state,
          create: {
            loading: LoadingType.succeeded,
            error: null,
            response: action.payload,
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportUpdate.pending,
        (state, action) => ({
          ...state,
          update: {
            loading: LoadingType.pending,
            error: null,
            response: "",
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportUpdate.rejected,
        (state, action) => ({
          ...state,
          update: {
            loading: LoadingType.failed,
            error: action.payload,
            response: "",
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportUpdate.fulfilled,
        (state, action) => ({
          ...state,
          update: {
            loading: LoadingType.succeeded,
            error: null,
            response: action.payload,
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportUpload.pending,
        (state, action) => ({
          ...state,
          upload: {
            loading: LoadingType.pending,
            error: null,
            response: "",
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportUpload.rejected,
        (state, action) => ({
          ...state,
          upload: {
            loading: LoadingType.failed,
            error: action.payload,
            response: "",
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportUpload.fulfilled,
        (state, action) => ({
          ...state,
          upload: {
            loading: LoadingType.succeeded,
            error: null,
            response: action.payload,
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportDelete.pending,
        (state, action) => ({
          ...state,
          delete: {
            loading: LoadingType.pending,
            error: null,
            response: "",
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportDelete.rejected,
        (state, action) => ({
          ...state,
          delete: {
            loading: LoadingType.failed,
            error: action.payload,
            response: "",
          },
        })
      )
      .addCase(
        thunk.workCompAuditAndComplianceReportDelete.fulfilled,
        (state, action) => ({
          ...state,
          delete: {
            loading: LoadingType.succeeded,
            error: null,
            response: action.payload,
          },
        })
      )

      .addDefaultCase((state) => ({ ...state }));
  },
});

export const workCompAuditAndComplianceReportingListActions =
  workCompAuditAndComplianceReportingListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } =
  workCompAuditAndComplianceReportingListAdapter.getSelectors();
export const selectState = (
  state: WorkCompAuditAndComplianceReportingListState
) => state;


export const selectCreateReportState = (
  state: WorkCompAuditAndComplianceReportingListState
) => state.create;
export const selectUpdateReportState = (
  state: WorkCompAuditAndComplianceReportingListState
) => state.update;
export const selectUploadReportState = (
  state: WorkCompAuditAndComplianceReportingListState
) => state.upload;
export const selectDeleteReportState = (
  state: WorkCompAuditAndComplianceReportingListState
) => state.delete;
