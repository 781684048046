import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, ServiceCall } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getServiceCalls = createAsyncThunk<
    Array<ServiceCall>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/service_call/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getServiceCalls();
            return res.data as ServiceCall[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createServiceCall = createAsyncThunk<
    string,
    {'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/service_call/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createServiceCall(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteServiceCall = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/service_call/delete',
    async (call_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteServiceCall(call_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateServiceCall = createAsyncThunk<
    string,
    {'id': string, 'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/service_call/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateServiceCall(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);