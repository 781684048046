import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IAgencyProfilePictureRequestQuery, IAgencyProfilePictureUploadUrlResponse } from "../../../../interfaces";
import { S3API } from "../../../../apis/s3_api";
import { catchRequestError } from "../../../../utils";


export const getAgencyLogoURLForUpload = createAsyncThunk<
    string,
    IAgencyProfilePictureRequestQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@files/profile-picture/agency-logo-url-for-upload/get',
    async (query, thunkAPI) => {
        try {
            const res = await S3API.getAgencyProfilePictureUrlForUpload(query)
            if (typeof res.data === 'string') return '';
            return (res.data as IAgencyProfilePictureUploadUrlResponse).url
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
