import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as thunk from "./kiniso_job_order_details_thunk";
import { LoadingType } from "../../../enums";
import { IKinisoJobOrderResponse } from "../../../interfaces/kiniso_job_orders";
import { AppError } from "../../../interfaces";


interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

interface RemoveState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialRemoveState: RemoveState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

export interface SingleJobOrderState {
    error?: AppError | null,
    loading: LoadingType,
    data: IKinisoJobOrderResponse | null,

}
export interface JobOrderDetailsState {
    [key: string]: SingleJobOrderState,
}


const initialState: JobOrderDetailsState = {}


function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}



export const kinisoJobOrderDetailsSlice = createSlice({
    name: 'kinisoJobOrderDetails',
    initialState: initialState,
    reducers: {
        clearJobDetailsAddOptionError(state, action: PayloadAction<{ id: string, type: string }>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        add: {
                            ...state[action.payload.id][action.payload.type].add,
                            ...initialAddState,
                        },

                    },
                }
            };
        },

        clearJobDetailsRemoveOptionError(state, action: PayloadAction<{ id: string, type: string }>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        remove: {
                            ...state[action.payload.id][action.payload.type].remove,
                            ...initialRemoveState,
                        },

                    },
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getAllKinisoJobOrderByIdThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...getLoadingState(state[action.meta.arg.job_id]),
                     
                    }
                })
            )
            .addCase(
                thunk.getAllKinisoJobOrderByIdThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...getErrorState(state[action.meta.arg.job_id], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getAllKinisoJobOrderByIdThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_id]: {
                        ...getSuccessState(state[action.meta.arg.job_id], action.payload),
                    }
                })
            )


            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const kinisoJobOrderSliceActions = kinisoJobOrderDetailsSlice.actions;
export const selectState = (state: JobOrderDetailsState) => state;
export const selectJobOrderState = (state: JobOrderDetailsState, id: string): SingleJobOrderState => state[id];
export const selectInitialSingleJobOrderState = (): SingleJobOrderState => ({
    data: null,
    loading: LoadingType.pending,
    error: null,
});


