import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { THEME, LoadingType } from "../../../../enums";
import { INFO_TEXT_CATEGORY } from "../../../../enums/info_text_category";
import { AgencySetup, BulbIcon } from "../../../../icons";
import { selectInfoTextState, getAdminCenterInfoText } from "../../../../redux/admin_center";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CustomButton } from "../../../../utils";
import { clearAdminCenterInfoTextError } from "../../../../redux/admin_center/info_text/info_text_reducer";


interface Props {
    visible: boolean;
    onClose: () => void;
    onDismiss: () => void;
}

const PayrollTaxInfoTextPopUp: React.FunctionComponent<Props> = (props) => {
    const { onClose, onDismiss, visible } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const infoTextState = useAppSelector((state) => selectInfoTextState(state));

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        dispatch(getAdminCenterInfoText(INFO_TEXT_CATEGORY.PAYROLL_TAX_CONFIGURATION));
    }, [])

    const handleClearError = () => {
        dispatch(clearAdminCenterInfoTextError())
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'30%'}
            maxWrapperWidth={'60%'}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="ac-info-text-popup-container">
                    <div className="ac-info-text-popup-header">
                        <FormDialogTilteHeader
                            title={t("payroll_tax_configuration_overview")}
                            titleIcon={<BulbIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="ac-info-text-popup-content">
                        <div className="info-text">
                            <span className="list-arrow">
                                <p>Welcome to KinISO! As a Super user, we'll explain the Payroll Tax Configuration section, vital for precise and compliant payroll processing. </p>
                                <b>Payroll Tax Configuration Overview</b>
                                <ul>
                                    <li><b>Payroll Taxes:</b> Crucial for financial management, these taxes involve wage deduction, including income tax, Social Security, and Medicare. Accurate management ensures compliance.</li>
                                </ul>
                                <span><b>Key Components</b></span>
                                <ul>
                                    <li><b>Tax Rates:</b> Define federal, state, local rates, and Social Security/Medicare rates for precision.</li>
                                    <li><b>Deductions:</b> Specify employee deductions (e.g., retirement, insurance) for correct calculations.</li>
                                    <li><b>Tax Reporting:</b> Generate tax reports (e.g., W-2 forms) in compliance with tax authorities.</li>
                                    <li><b>Compliance Updates:</b> Stay informed about tax law changes and updates.</li>
                                    <li><b>Employee Profiles:</b> Maintain accurate tax profiles, including allowances.</li>
                                </ul>
                                <b>Benefits:</b>
                                <ul>
                                    <li><b>Accuracy:</b>Reduce calculation errors, avoiding penalties.</li>
                                    <li><b>Compliance:</b> Comply with tax regulations, minimizing audit risks.</li>
                                    <li><b>Efficiency:</b> Streamline tax processes, saving time and resources.</li>
                                    <li><b>Employee Satisfaction:</b> Accurate deductions ensure predictable paychecks.</li>
                                </ul>
                                <p><b>Maintenance:</b> Regularly review and update settings to reflect current tax regulations.</p>
                                <p> In conclusion, KinISO's Payroll Tax Configuration aids Super users in precise tax management for compliance and employee satisfaction. For assistance, contact our support team. Welcome and good luck!</p>
                            </span>
                        </div>
                        {infoTextState.update?.error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={infoTextState.update?.error?.message}
                                    onClose={handleClearError}
                                />
                            </div>
                        )}
                    </div>
                    <div className="ac-info-text-popup-actions">
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('ok')}
                                loading={infoTextState.update?.loading === LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )
}

export default PayrollTaxInfoTextPopUp