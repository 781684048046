import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI, } from "../../../../apis";
import { AppError, IOnboardingLink } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const resendOnboardingLinkThunk = createAsyncThunk<
    string,
    IOnboardingLink,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/oboarding/link-update',
    async (payload, thunkAPI) => {
        try {
            const res = await DashboardAPI.reseSendOnboardingLink(payload);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
