import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import * as thunk from "./talent_onboarding_thunk";

export interface TalentOnboardingState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }
    submit: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }

}

const initialState: TalentOnboardingState = {
    create: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    submit: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}


export const talentOnboardingSlice = createSlice({
    name: 'talentOnboarding',
    initialState: initialState,
    reducers: {
        clearState(state){
            return {...state, ...initialState};
        },
        clearError(state) {
            return {
                ...state,
                create: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },
                update: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },
                submit: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },

            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.addTalentOnboardingThunk.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.addTalentOnboardingThunk.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.addTalentOnboardingThunk.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateTalentOnboardingThunk.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateTalentOnboardingThunk.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.updateTalentOnboardingThunk.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.submitTalentOnboardingThunk.pending, (state, action) => ({ ...state, submit: { ...state.submit, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.submitTalentOnboardingThunk.rejected, (state, action) => ({ ...state, submit: { ...state.submit, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.submitTalentOnboardingThunk.fulfilled, (state, action) => ({ ...state, submit: { ...state.submit, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentOnboardingSliceActions = talentOnboardingSlice.actions;
export const selectState = (state: TalentOnboardingState) => state;


