import { combineReducers } from "@reduxjs/toolkit";
import { credentialStateSlice } from "./master_tax/credentials/credentials_reducer";
import { masterTaxSetupStateSlice } from "./master_tax/master_tax_setup/master_tax_setup_reducer";

export const integrationPartnerReducers = combineReducers({
    [credentialStateSlice.name]: credentialStateSlice.reducer,
    [masterTaxSetupStateSlice.name]: masterTaxSetupStateSlice.reducer,
});


export * from './master_tax/credentials/credentials_thunk';
export * from './master_tax/credentials/credentials_selector';


export * from './master_tax/master_tax_setup/master_tax_setup_thunk';
export * from './master_tax/master_tax_setup/master_tax_setup_selector';
