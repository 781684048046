import { useTranslation } from "react-i18next";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import { AppRoutes, getAdminCenterNavHeaderProps } from "../../routes";
import AdminCenterPage from "./admin_center_page";
import AdminCenterRoleManagementPage from "./role_management/role_management_page";
import AdminCenterSystemConfigurationPage from "./system_configuration/system_configuration_page";
import AdminCenterUserManagementPage from "./user_management/user_management_page";
import UserSettingsPage from "./user_management/user_settings_page";
import SystemConfigurationProfessionalSkillsPage from "./system_configuration/system_configuration_professional_skills_page";
import AdministratorPage from "./role_management/administrator_page";
import SystemWidePage from "./system_configuration/system_wide_page";
import TextEmAllPage from "./integration_partners/text_em_all/text_em_all";
import SalesAndMarketingPage from "./system_configuration/sales_and_marketing_page";
import ContactsPage from "./system_configuration/contacts_page";
import TrafficLightEngagementMetricPage from "./system_configuration/traffic_light_engagement_metric_page";
import JobsPage from "./system_configuration/jobs_page";
import AssignmentsPage from './system_configuration/assignments_page';
import TalentPage from "./system_configuration/talent_page";

import SecurityConfigurationPage from "./security_policy/security_configuration_page";
import AgencySetupPage from "./agency_setup/agency_setup_page";
import { OnBoardingTabNav } from "./admin_center_onboarding_nav";
import TeamListPage from "./team_management/team_list/team_list_page";
import TeamDetailsListPage from "./team_management/team_details_list/team_details_page";
import { PayrollTaxTabNav } from "./payroll_tax_configuration/payroll_tax_nav";
import { SalesTaxTabNav } from "./sales_tax_configuration/sales_tax_nav";
import { WorkCompTabNav } from "./work_comp_configuration/work_comp_nav";
import AdminCenterBackOfficePage from "./back_office_onboarding/back_office_onboarding_page";
import AdminCenterAgencyManagementPage from "./agency_management/agency_management_page/agency_management_page";
import AgencyDashboardPage from "./agency_management/agency_dashboard/agency_dashboard";
import InfoTextPage from "./info_text/info_text_page";
import AdminCenterSCRiskManagementPage from "./system_configuration/pages/risk_management/risk_management";
import IntegrationPartnersPage from "./integration_partners/integration_partners_page";
import { MasterTaxTabNav } from "./master_tax_page_nav";
import { ACReportCenterTabNav } from "./admin_center_report_center_nav";


interface Props extends RouteComponentProps<any> { }

export const AdminCenterNavigation: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getAdminCenterNavHeaderProps(location, match, t);

    return (
        <PageNavHeader data={headerOptions} history={history} location={location}>
            <Switch>
                <Route path={AppRoutes.systemConfigurationOnboarding} component={OnBoardingTabNav} />
                <Route exact path={AppRoutes.adminCenterUserManagement} component={AdminCenterUserManagementPage} />
                <Route exact path={`${AppRoutes.adminCenterUserManagement}/:id`} component={UserSettingsPage} />
                <Route exact path={AppRoutes.adminCenterSecurityPolicy} component={SecurityConfigurationPage} />
                <Route exact path={AppRoutes.adminCenterAgencySetup} component={AgencySetupPage} />
                <Route exact path={AppRoutes.adminCenterInfoText} component={InfoTextPage} />
                <Route exact path={AppRoutes.adminCenterAgencyManagement} component={AdminCenterAgencyManagementPage} />
                <Route exact path={`${AppRoutes.adminCenterAgencyManagement}/:id`} component={AgencyDashboardPage} />
                <Route exact path={AppRoutes.adminCenterTeamManagement} component={TeamListPage} />
                <Route exact path={`${AppRoutes.adminCenterTeamManagement}/:id`} component={TeamDetailsListPage} />
                <Route exact path={AppRoutes.adminCenterIntegrations} component={IntegrationPartnersPage} />

                <Route exact path={AppRoutes.adminCenterSystemConfiguration} component={AdminCenterSystemConfigurationPage} />
                <Route exact path={AppRoutes.adminCenterPayrollTaxConfiguration} component={PayrollTaxTabNav} />
                <Route exact path={AppRoutes.adminCenterSalesTaxConfiguration} component={SalesTaxTabNav} />
                <Route exact path={AppRoutes.adminCenterWorkCompConfiguration} component={WorkCompTabNav} />
                <Route exact path={AppRoutes.adminCenterBackOffice} component={AdminCenterBackOfficePage} />
                <Route exact path={AppRoutes.systemConfigurationSkillSets} component={SystemConfigurationProfessionalSkillsPage} />
                <Route exact path={AppRoutes.systemConfigurationSystemWide} component={SystemWidePage} />
                <Route exact path={AppRoutes.systemConfigurationSalesAndMarketing} component={SalesAndMarketingPage} />
                <Route exact path={AppRoutes.systemConfigurationContacts} component={ContactsPage} />
                <Route exact path={AppRoutes.systemConfigurationTrafficLightEngagementLight} component={TrafficLightEngagementMetricPage} />
                <Route exact path={AppRoutes.systemConfigurationJobs} component={JobsPage} />
                <Route exact path={AppRoutes.systemConfigurationTalent} component={TalentPage} />
                <Route exact path={AppRoutes.systemConfigurationRiskManagement} component={AdminCenterSCRiskManagementPage} />
                <Route exact path={AppRoutes.systemConfigurationAssignments} component={AssignmentsPage} />
                <Route exact path={AppRoutes.adminCenterRoleManagement} component={AdminCenterRoleManagementPage} />
                <Route exact path={AppRoutes.roleManagementAdministrator} component={AdministratorPage} />
                <Route exact path={AppRoutes.roleManagementSuperUser} component={AdministratorPage} />
                <Route exact path={AppRoutes.roleManagementBranchManager} component={AdministratorPage} />
                <Route exact path={AppRoutes.roleManagementAccountManager} component={AdministratorPage} />
                <Route exact path={AppRoutes.roleManagementRecruiter} component={AdministratorPage} />
                <Route exact path={AppRoutes.roleManagementCallDeckOwner} component={AdministratorPage} />
                <Route exact path={AppRoutes.integrationPartnersTextEmAll} component={TextEmAllPage} />
                <Route path={AppRoutes.integrationPartnersMasterTax} component={MasterTaxTabNav} />
                <Route path={AppRoutes.adminCenterReportCenter} component={ACReportCenterTabNav} />


                <Route component={AdminCenterPage} />
            </Switch>
        </PageNavHeader>
    );
}