import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './application_status_reducer';

const select = (state: IRootState) => state.talent.details.onBoardingAndDocs.onboardingReviewStatus;
export const selectTalentODRSState = createSelector(select, reducer.selectTalentODRSState);
export const selectSingleTalentODRSStateById = (id: string | undefined | null) => createSelector(
    selectTalentODRSState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleTalentODRSState(state, id);
        return reducer.selectInitialSingleTalentODRSState();
    }
);