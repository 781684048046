import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";
import { IMasterTaxSetup } from "../../../../../interfaces/master_tax";
import * as thunk from './master_tax_setup_thunk';

export interface MasterTaxSetupState {
    error?: AppError | null,
    loading: LoadingType,
    data: IMasterTaxSetup | null,
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: any,
    },
    export: {
        error?: AppError | null,
        loading: LoadingType,
        response: any,
    }

}

const initialState: MasterTaxSetupState = {
    error: null,
    loading: LoadingType.idle,
    data: null,
    update: {
        error: null,
        loading: LoadingType.idle,
        response: null
    },
    export: {
        error: null,
        loading: LoadingType.idle,
        response: null
    }
}
export const masterTaxSetupStateSlice = createSlice({
    name: 'masterTaxSetup',
    initialState: initialState,
    reducers: {
        clearPostState(state) {
            return {
                ...state,
                update: {
                    ...initialState.update
                }
            };
        },
        clearExportState(state) {
            return {
                ...state,
                export: {
                    ...initialState.export
                }
            };
        },
        clearState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(thunk.getMasterTaxSetup.pending, (state, action) => ({ ...state, loading: LoadingType.pending }))
            .addCase(thunk.getMasterTaxSetup.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload }))
            .addCase(thunk.getMasterTaxSetup.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, data: action.payload }))

            .addCase(thunk.updateMasterTaxSetup.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, response: null, error: null } }))
            .addCase(thunk.updateMasterTaxSetup.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, response: null, error: action.payload } }))
            .addCase(thunk.updateMasterTaxSetup.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, response: action.payload, error: null } }))

            .addCase(thunk.exportCompanySetupThunk.pending, (state, action) => ({ ...state, export: { ...state.export, loading: LoadingType.pending, response: null, error: null } }))
            .addCase(thunk.exportCompanySetupThunk.rejected, (state, action) => ({ ...state, export: { ...state.export, loading: LoadingType.failed, response: null, error: action.payload } }))
            .addCase(thunk.exportCompanySetupThunk.fulfilled, (state, action) => ({ ...state, export: { ...state.export, loading: LoadingType.succeeded, response: action.payload, error: null } }))


            .addDefaultCase(state => ({ ...state }));
    }
});

export const masterTaxSetupStateSliceActions = masterTaxSetupStateSlice.actions;
export const selectState = (state: MasterTaxSetupState) => state;