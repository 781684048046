import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, ICreateBatchResponse, ITimeCardsBatch } from "../../../../../interfaces";
import * as thunk from './time_cards_manage_batch_thunk'


export interface ManageBatchState {
    create: {
        error?: AppError | null;
        loading: LoadingType;
        response: ICreateBatchResponse | null;
    },
    delete: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    verify: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    close: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    addTimecards: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    }

}

const initialState: ManageBatchState = {
    create: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    delete: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    verify: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    close: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    addTimecards: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },

}

export const manageBatchSlice = createSlice({
    name: "manageTimecardsBatch",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },

        clearCreateState(state) {
            return {
                ...state, create: { ...initialState.create },
            };
        },
        clearVerifyState(state) {
            return {
                ...state, verify: { ...initialState.verify },
            };
        },
        clearDeleteState(state) {
            return {
                ...state, delete: { ...initialState.delete },
            };
        },
        clearCloseState(state) {
            return {
                ...state, close: { ...initialState.close },
            };
        },
        clearaddTCBState(state) {
            return {
                ...state, addTimecards: { ...initialState.addTimecards },
            };
        },
        clearCreateErrorState(state) {
            return {
                ...state, create: { ...initialState.create, error: null, },
            };
        },
        clearVerifyErrorState(state) {
            return {
                ...state, verify: { ...initialState.verify, error: null, },
            };
        },
        clearDeleteErrorState(state) {
            return {
                ...state, delete: { ...initialState.delete, error: null, },
            };
        },
        clearCloseErrorState(state) {
            return {
                ...state, close: { ...initialState.close, error: null, },
            };
        },
        clearaddTCBErrorState(state) {
            return {
                ...state, addTimecards: { ...initialState.addTimecards, error: null, },
            };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.createBatchThunk.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createBatchThunk.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createBatchThunk.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.verifyBatchThunk.pending, (state, action) => ({ ...state, verify: { ...state.verify, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.verifyBatchThunk.rejected, (state, action) => ({ ...state, verify: { ...state.verify, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.verifyBatchThunk.fulfilled, (state, action) => ({ ...state, verify: { ...state.verify, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.closeBatchThunk.pending, (state, action) => ({ ...state, close: { ...state.close, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.closeBatchThunk.rejected, (state, action) => ({ ...state, close: { ...state.close, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.closeBatchThunk.fulfilled, (state, action) => ({ ...state, close: { ...state.close, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.deleteBatchThunk.pending, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.deleteBatchThunk.rejected, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.deleteBatchThunk.fulfilled, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.addTimecardstoBatchThunk.pending, (state, action) => ({ ...state, addTimecards: { ...state.addTimecards, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.addTimecardstoBatchThunk.rejected, (state, action) => ({ ...state, addTimecards: { ...state.addTimecards, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.addTimecardstoBatchThunk.fulfilled, (state, action) => ({ ...state, addTimecards: { ...state.addTimecards, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase((state) => ({ ...state }));
    }
});
export const manageBatchSliceActions = manageBatchSlice.actions;
export const selectState = (state: ManageBatchState) => state;