import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRCPayrollJournal } from "../../../../interfaces/report_center_payroll_tax";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { IReportCenterBOPayload } from "../../../../interfaces/report_center";

export const getPayrollJournalList = createAsyncThunk<
    IRCPayrollJournal,
    IReportCenterBOPayload,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/payroll_journal/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getPayrollJournal(payload);
            return res.data as IRCPayrollJournal;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });