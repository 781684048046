import { useTranslation } from "react-i18next";
import SectionCard from "../../../../../components/section_card/section_card";
import { useAppDispatch } from "../../../../../redux/store";
import { EditIcon } from "../../../../../icons";
import { useEffect, useState } from "react";
import { CustomButton, FormInput, RegxPattern, allowNumbers, convertNumberToMoney, formatToMoney } from "../../../../../utils";
import { LoadingType, THEME } from "../../../../../enums";
import { ICompanyBackOfficeSetup } from "../../../../../interfaces/company_back_office_setup";
import { AppError } from "../../../../../interfaces";
import './finance_collections.scss';
import ToggleSwitch from "../../../../../components/toggle_switch/toggle_switch";
import { SingleCompanyBackOfficeSetupState } from "../../../../../redux/companies/details/back_office_format_and_setup/backoffice_setup_reducer";
import ApiError from "../../../../../components/api_error";
interface Props {
    fcUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleFieldChange: (fieldId: string, value: any, parent: string) => void;
    formState: ICompanyBackOfficeSetup;
    handleSubmit: (parent: string) => void;
    onClearError: () => void;
    handleEditCancel: () => void;
    isLoading: boolean,

}
const FinanceCollections: React.FC<Props> = props => {
    const { fcUpdateState, handleFieldChange, formState, handleSubmit, onClearError, handleEditCancel, isLoading } = props

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const parentField = 'finance_collections';

    const handleSave = () => {
        handleSubmit(parentField);
    };
    const isAddLoading = () => {
        return isLoading && fcUpdateState?.loading === LoadingType.pending;
    };
    function _isFormValid() {
        if (formState.finance_collections?.email_statements_to &&
            formState.finance_collections?.email_statements_to !== "" &&
            RegxPattern.email.test(formState.finance_collections?.email_statements_to) === false) return false;
        return true;
    }
    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
          case "email_statements_to": {
            if (value.text !== "" && RegxPattern.email.test(value.text) === false)
              return t("validators.enterValidEmail");
            return null;
          }
          default: {
            return null;
          }
        }
      }
    return (
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('finance_collections')} >
            <div className="fc-container">
                <div className="fc-content">
                    <div className="fc-input-holder-row">
                        <div className={"fc-content-input-holder"}>
                            <FormInput
                                id={'finance_charge'}
                                onChange={(id, val) => handleFieldChange(id, val, parentField)}
                                required={false}
                                placeholder={""}
                                type={"text"}
                                value={formState.finance_collections?.finance_charge as number}
                                label={t("finance_charge")}
                                suffix={<span>%</span>}
                                onKeyUp={(e) => { allowNumbers(e); }}
                                onKeyDown={(e) => { allowNumbers(e); }}
                            />
                        </div>
                        <div className={"fc-content-input-holder"}>
                            <FormInput
                                id={'credit_limit'}
                                onChange={(id, val) => handleFieldChange(id, val, parentField)}
                                required={false}
                                placeholder={""}
                                type={"text"}
                                value={convertNumberToMoney(formState.finance_collections?.credit_limit, "string") as string}
                                label={t("credit_limit")}
                                prefix={<span>$</span>}
                                onBlur={formatToMoney}
                                onKeyDown={formatToMoney}
                                onKeyUp={formatToMoney}
                            />
                        </div>
                        <div className={"fc-content-input-holder"}>
                            <FormInput
                                id={'warning_for_credit_limit'}
                                onChange={(id, val) => handleFieldChange(id, val, parentField)}
                                required={false}
                                placeholder={""}
                                type={"text"}
                                value={convertNumberToMoney(formState.finance_collections?.warning_for_credit_limit, "string") as string}
                                label={t("warning_cred_limit")}
                                prefix={<span>$</span>}
                                onBlur={formatToMoney}
                                onKeyDown={formatToMoney}
                                onKeyUp={formatToMoney}
                            />
                        </div>
                    </div>
                    <div className="fc-input-holder-row">
                        <div className={"fc-content-input-holder"}>
                            <FormInput
                                id={"email_statements_to"}
                                onChange={(id, val) => handleFieldChange(id, val, parentField)}
                                type={"text"}
                                value={formState.finance_collections?.email_statements_to}
                                label={t("email_statements_to")}
                                required={false}
                                multiline={false} placeholder={undefined}
                                validations={formValidators}

                            />
                        </div>
                    </div>
                    <div className="fc-input-holder-row toggle-row">
                        <div className={"fc-content-input-holder"}>
                            <ToggleSwitch
                                onChange={(val) => handleFieldChange('include_finance_charges', val, parentField)}
                                label={t("include_finance_charges")}
                                checked={formState.finance_collections?.include_finance_charges!}
                                labelPosition={'right'}
                            />
                        </div>
                        <div className={"fc-content-input-holder"}>
                            <ToggleSwitch
                                onChange={(val) => handleFieldChange('email_monthly_statements', val, parentField)}
                                label={t("email_monthly_statements")}
                                checked={formState.finance_collections?.email_monthly_statements!}
                                labelPosition={'right'}
                            />
                        </div>
                    </div>
                </div>
                <div className="fc-actions">
                    <div className="er-div">
                        {isLoading && fcUpdateState !== undefined && fcUpdateState.error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={fcUpdateState.error.message}
                                    onClose={onClearError}
                                />
                            </div>
                        )}
                    </div>
                    <div className="fc-actions-btn">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable
                                backgroundColor={THEME.secondaryColor4}
                                onClick={handleEditCancel}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                loading={isAddLoading()}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("save")}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleSave}
                                enable={_isFormValid()}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </SectionCard>);
}

export default FinanceCollections;