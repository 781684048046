import { BillingStatus } from "../enums/back_office";
import { ITimeCardsPayType } from "./back_office_timecards";

export class IBillingBatch {
  modified_by: string;
  created_by: string;
  modified_date: number;
  id: string;
  week_accounting_id: string;
  week_ending_date: number;
  invoice_date: number;
  action: string;
  batch_number: string;
  batch_description: string;
  time_card_ids: string[];
  starting_invoice_number: number;
  ending_invoice_number: number;
  transaction_count: number;
  invoice_count: number;
  process_all_invoices: boolean;
  total_billed: number;
  date_printed: number;
  date_posted: number;
  date_processed: number;
  created_date: number;
  processed_by: string;
  status: BillingStatus;
}

export class IBillingTimeCardsData {
  time_card_id: string;
  company_id: string;
  talent_id: string;
  department_id: string;
  weekly_accounting_id: string;
  company_name: string;
  department_name: string;
  talent_name: string;
  transaction_count: number;
  invoice_amount: number;
  week_ending_date: number;

}
export class IBillingInvoicesReportData {
  modified_by: string | null;
  created_by: string | null;
  modified_date: string | null;
  week_accounting_id: string;
  billing_batch_id: string;
  company_id: string;
  company_name: string;
  department_id: string | null;
  department_name: string | null;
  invoice_date: number;
  invoice_number: string;
  invoice_amount: number;
  balance_amount: number;
  due_date: number;
  sales_tax_amount: number | null;
  branch: null;
  id: string;
  payment_amount: any;
  invoice_corrected: boolean;
  invoice_status: string;
}

export interface ICorrectTimeCards {
  id: string;
  weekly_accounting_id: string;
  created_week_ending_date: number;
  weekly_transaction_id: string;
  assignment_id: string;
  talent_id: string;
  company_id: string;
  department_id: string;
  direct_hire: boolean;
  contact_id: string;
  job_order_id: string;
  payments: ITimeCardsPayType[];
  batch_id: string;
  company_name: string;
  department_name: string;
  talent_name: string;
  job_title: string;
  batch_number: string;
  batch_status: string;
  week_ending_date: number;
  is_deleted: boolean;
  attach_timecard: boolean;
  pay_frequency: string;
  updated: boolean;
}


export interface InvoiceCorrectionQueryParam {
  invoice_id: string;
  timeCards: ICorrectTimeCards[];
  same_invoice_number: boolean;
  invoice_date: number
}
