import { BorderColor, CheckCircleOutline, KeyboardBackspace, Timer } from "@material-ui/icons";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import { DateInput } from "../../../../components/date_input/date_input";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { CONTACT_METHODS, LoadingType, THEME } from "../../../../enums";
import {
  CallBlueIcon,
  EmailIcon,
  FacebookIcon,
  IndeedIcon,
  IndeedLogoIcon,
  LinkedInIcon,
  ScheduledCallIcon,
  TextIcon,
  ZipRecruiterChairIcon,
  ZipRecruiterIcon,
  InPerson,
  Teams,
  Zoom,
  GoogleMeet,
} from "../../../../icons";
import { IDepartment, IProfileDropdown } from "../../../../interfaces";
import { SalesCall } from "../../../../interfaces/sales_call";
import { getSalesCallFilteredList } from "../../../../redux/sales_marketing/my_call_deck/sales_call_list/sales_call_list_thunk";
import {
  selectManageSalesCallCreateState,
  selectManageSalesCallUpdateState,
} from "../../../../redux/sales_marketing/plan_my_sales_calls/manage_sales_calls/manage_sales_calls_selector";
import {
  createSalesCall,
  updateSalesCall,
} from "../../../../redux/sales_marketing/plan_my_sales_calls/manage_sales_calls/manage_sales_calls_thunk";
import {
  getCompaniesList,
  postSnackbarMessage,
  selectCompaniesListEntities,
  selectCompaniesListState,
  selectDepartmentsList,
  selectDepartmentsListEntities,
  selectDepartmentsListState,
  useAppDispatch,
  useAppSelector,
  selectCompaniesDropdownList,
  getCompaniesDropdown,
  selectDepartmentsDropdownList,
  getDepartmentsDropdown,
  getContactsDropdownThunk,
  selectContactsDropdownList,
  selectContactsDropdownListEntities,
} from "../../../../redux/store";
import {
  CustomButton,
  FormInput,
  mapOptions,
  SpinnerScreen,
} from "../../../../utils";
import { convertDateToTimeStamp, dateTimeToTimestamp, extractTimeFromTimestamp, lowBtnStyle, timestampToStringDate } from "../../../../variables";
import "./plan_my_sales_calls.scss";
import { departmentsDropdownListActions } from "../../../../redux/selectors/departments_list/departments_list_reducer";
import { contactsDropdownListActions } from "../../../../redux/selectors/contacts_list/contact_list_reducer";
import TimePickerComponent from "../../../../components/time_picker/time_picker";

export interface Props {
  showModal: boolean;
  salesCallInfo: SalesCall;
  successTitle: string;
  currentLoginUserName?: string;
  onGotoAgencyDashboard: () => void;
  onCreateNew: () => void;
  onGotoCreated: (id: string, name: string) => void;
  onClose: () => void;
  onDissmiss?: () => void,
  onSuccessClose: () => void,
  usersList: IProfileDropdown[],
  currentLoginUserId: string;
  availableFrom: number;
  availableTo: number;
  showCompleted: boolean;
  clearStateSource: () => void;
  stateSource?: {
    source: string;
    contactId?: string;
    companyId?: string;
  }

}

export const PlanMySalesCalls: FC<Props> = (
  props: Props
) => {
  const { showModal, salesCallInfo, usersList, currentLoginUserName, currentLoginUserId, availableFrom, availableTo, showCompleted, clearStateSource, onClose, stateSource } = props;

  const [salesCall, setSalesCall] = useState<SalesCall>(salesCallInfo ?? new SalesCall());
  const [showSocialMediaOptions, setShowSocialMediaOptions] =
    useState<boolean>(false);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] =
  useState<boolean | undefined>();
  const actionRef = useRef<ActionDialogHolderType>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const companiesDropdown = useAppSelector((state) =>
    selectCompaniesDropdownList(state)
  );
  const departmentsDropdownList = useAppSelector((state) =>
    selectDepartmentsDropdownList(state)
  );
  const companiesEntities = useAppSelector((state) =>
    selectCompaniesListEntities(state)
  );
  const companiesState = useAppSelector((state) =>
    selectCompaniesListState(state)
  );
  const allDepartments = useAppSelector((state) =>
    selectDepartmentsList(state)
  );
  const departmentState = useAppSelector((state) =>
    selectDepartmentsListState(state)
  );
  const departmentEntities = useAppSelector((state) =>
    selectDepartmentsListEntities(state)
  );
  const contactsList = useAppSelector((state) =>
    selectContactsDropdownList(state)
  );

  const contactsEntities = useAppSelector((state) =>
    selectContactsDropdownListEntities(state)
  );
  const createState = useAppSelector((state) =>
    selectManageSalesCallCreateState(state)
  );
  const updateState = useAppSelector((state) =>
    selectManageSalesCallUpdateState(state)
  );

  const handleClearDropdownStates = () => {
    dispatch(contactsDropdownListActions.clearContactDropdownListState());
    dispatch(departmentsDropdownListActions.clearDepartmentsDropdownListState());
  }
  useEffect(() => {
    dispatch(getCompaniesList());
    dispatch(getCompaniesDropdown());
    handleClearDropdownStates();
    if (salesCallInfo && salesCallInfo.company_id !== undefined) {
      dispatch(
        getContactsDropdownThunk({
          company_id: salesCallInfo.company_id,
          department_id: salesCallInfo.department_id,
        })
      );
      dispatch(getDepartmentsDropdown(salesCallInfo.company_id));
    }
  }, []);

  useEffect(() => {
    if (salesCallInfo && (salesCallInfo.id !== undefined || salesCallInfo.company_id !== undefined)) {
      dispatch(
        getContactsDropdownThunk({
          company_id: salesCallInfo.company_id,
          department_id: salesCallInfo.department_id,
        })
      );
      setDepartments(
        allDepartments.filter(
          (dept) => dept.company_id === salesCallInfo.company_id
        )
      );
      setSalesCall(salesCallInfo);
      if (
        salesCallInfo.contact_method &&
        (salesCallInfo.contact_method === CONTACT_METHODS.linkedIn ||
          salesCallInfo.contact_method === CONTACT_METHODS.indeed ||
          salesCallInfo.contact_method === CONTACT_METHODS.facebook ||
          salesCallInfo.contact_method === CONTACT_METHODS.zipRecruiter)
      ) {
        setShowSocialMediaOptions(true);
      }
    }
  }, [salesCallInfo, salesCallInfo.company_id, salesCallInfo.id]);

  useEffect(() => {
    if (createState.loading === LoadingType.succeeded) {
      dispatch(postSnackbarMessage(createState.response?.status ?? null));
      dispatch(getSalesCallFilteredList({ my_upcoming_calls: !showCompleted, start_date: availableFrom, end_date: availableTo }))
    }
    if (updateState.loading == LoadingType.succeeded) {
      dispatch(postSnackbarMessage(updateState.response?.status ?? null));
      dispatch(getSalesCallFilteredList({ my_upcoming_calls: !showCompleted, start_date: availableFrom, end_date: availableTo }))
    }
  }, [createState.loading, updateState.loading]);

  const updateSalesCallProp = (key: string, value: string) => {
    const newsalesCall = { ...salesCall };
    if (key === "company_id") {
      newsalesCall["company_name"] = companiesEntities[value]?.name ?? "";
      newsalesCall.department_id = "";
      newsalesCall.department_name = "";
      newsalesCall.contact_id = "";
    }
    if (key === "department_id") {
      newsalesCall.department_name = departmentEntities[value]?.name ?? "";
      newsalesCall.contact_id = "";
    }
    newsalesCall[key] = value;
    setSalesCall(newsalesCall);
  };

  const handleCompanyChange = (value) => {
    updateSalesCallProp("company_id", value);


    if (value === "") {
      setDepartments([]);
      handleClearDropdownStates();

    } else {
      dispatch(getDepartmentsDropdown(value));
      dispatch(
        getContactsDropdownThunk({ company_id: value, department_id: undefined })
      );
      setDepartments(
        allDepartments.filter((dept) => dept.company_id === value)
      );

    }
  };

  const handleDepartmentChange = (value) => {
    updateSalesCallProp("department_id", value);

    if (value !== "" || salesCall.company_id !== "" || salesCall.company_id !== undefined) {
      dispatch(
        getContactsDropdownThunk({
          company_id: salesCall.company_id,
          department_id: value,
        })
      );
    }
    else if (salesCall.company_id === "") {
      dispatch(contactsDropdownListActions.clearContactDropdownListState());
    }

  };

  const showSpinner = () =>
    companiesState.loading === LoadingType.pending ||
    departmentState.loading === LoadingType.pending;

  const switchContactMethodsView = () => {
    setShowSocialMediaOptions(!showSocialMediaOptions);
    // updateSalesCallProp("contact_method", "");
  };

  const getCheckCircle = (small = false) => (
    <div className={`check-circle-outline ${small ? "small" : ""}`}>
      <CheckCircleOutline htmlColor="#00be4b" />
    </div>
  );

  const handleSave = () => {
    setCheckValidationOnSubmit(true);
    if(!isFormValid()){
      return;
    }
    let finalData = { ...salesCall };
    finalData.call_goal = finalData.accomplishment;
    const dateTimestampValue = timestampToStringDate(salesCall?.schedule_date!)
    const timeStringValue = salesCall?.schedule_time!
    const dateAndTimeTimeStampValue= dateTimeToTimestamp(dateTimestampValue, timeStringValue)!/1000
    if (salesCallInfo && salesCallInfo.id) {
      dispatch(updateSalesCall({
        data: {
          ...finalData,
          assigned_to_id: salesCall.assigned_to_id ? salesCall.assigned_to_id : currentLoginUserId,
          schedule_date_time: dateAndTimeTimeStampValue,
        }, salesCallsShowCompleted: showCompleted, salesCallsAvailableFrom: availableFrom, salesCallsAvailableTo: availableTo
      }));
      props.onSuccessClose()
    } else {
      dispatch(createSalesCall({
        ...finalData,
        planned_by_id: currentLoginUserId ?? null,
        assigned_to_id: salesCall.assigned_to_id ? salesCall.assigned_to_id : currentLoginUserId,
        schedule_date_time: dateAndTimeTimeStampValue
      }));
    }
    setCheckValidationOnSubmit(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 't' || event.key === 'T') {
      event.preventDefault();
      updateSalesCallProp('schedule_date', convertDateToTimeStamp(new Date()).toString());
    }
  };

  const handleClearTime = () => {
    setSalesCall({...salesCall, schedule_time: ""})
  }

  const handleDisableCompany = () => {
    if(stateSource?.source === "companies" && stateSource.companyId !== "") return true;
    if(stateSource?.source === "contacts-details" && stateSource.companyId !== "") return true;
    return false;
  }

  const handleGotoAgencyDashboard = () => {
    closePopup(props.onGotoAgencyDashboard);
  }

  const closePopup = (action?: () => void) => {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }

  const isFormValid = () =>
    !!salesCall.company_id &&
    !!salesCall.schedule_date &&
    !!salesCall.schedule_time &&
    !!salesCall.contact_id;

  const formValidators = (value: { text: string; field: string }) => {
    switch (value.field) {
      case "company_name":
      case "schedule_date":
      case "schedule_time":
      case "contact": {
        if (!value.text) return t("validators.required");
        return null;
      }
      default:
        return null;
    }
  };

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={showModal}
      wrapperWidth={"50%"}
      onClose={() => { handleClearDropdownStates(); onClose() }}
      onDissmiss={() => { handleClearDropdownStates(); onClose() }}
    >
      <DialogWrapper onClose={() => { handleClearDropdownStates(); onClose() }}>
        {showSpinner() ? (
          <div className="loading-spinner">
            <SpinnerScreen />
          </div>
        ) : getForm()}
      </DialogWrapper>
    </ActionDialogHolder>
  );

  function getForm() {
    if (createState.loading === LoadingType.succeeded) {
      const company = companiesEntities[salesCall.company_id];
      const contact = contactsEntities[salesCall.contact_id];
      return (
        <div className="tlnt-f-container">
          <div className="tlnt-f-header">
            <FormDialogTilteHeader
              title={props.successTitle}
              titleIcon={<ScheduledCallIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <div className="tlnt-f-content">
            <div className="saved-doc-holder">
              <DocSavedText>
                <span className="aknw-name">
                  {t("well_done_call", {
                    name: props.currentLoginUserName,
                  })}
                </span>
                <span className="name">{`${contact?.name ?? ""} - ${company?.name ?? ""
                  }`}</span>
              </DocSavedText>
            </div>
          </div>
          <div className="tlnt-f-actions">
            <div className="btn-cancel">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize", ...lowBtnStyle.text }}
                name={t("back_to_my_agency_dashboard")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={handleGotoAgencyDashboard}
                customStyle={lowBtnStyle.btn}
              />
            </div>

            <div className="btn-new">
              <CustomButton
                loading={false}
                textStyle={{
                  textTransform: "capitalize",
                }}
                name={t("plan_sales_call")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => {
                  setSalesCall(salesCallInfo);
                  props.onCreateNew();
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="plan-call">
        <div className="sales-call-header">
          <FormDialogTilteHeader
            title={"Let's plan a Sales call!"}
            titleIcon={
              <ScheduledCallIcon width={"100%"} height={"100%"} style={{ color: "#79649E" }} />}
          />
        </div>
        <div className="form-container">
          <div style={{ marginRight: "5em" }}>
            <CustomFormSelect
              customStyle={{ width: "-webkit-fill-available" }}
              name="company_name"
              value={salesCall?.company_id}
              label={"Which Company would you like to call on?"}
              placeholder={"select"}
              list={mapOptions(companiesDropdown, "name", "id")}
              onChange={handleCompanyChange}
              validations={formValidators}
              checkValidationOnSubmit={checkValidationOnSubmit}
              disabled={handleDisableCompany()}
              required
            />
            <CustomFormSelect
              customStyle={{ width: "-webkit-fill-available" }}
              name="department"
              value={salesCall?.department_id}
              label={
                "Which Department within this Company would you like to call on?"
              }
              placeholder={t("Corporate")}
              list={mapOptions(departmentsDropdownList ?? [], "name", "id")}
              onChange={handleDepartmentChange}
            />
            <CustomFormSelect
              customStyle={{ width: "-webkit-fill-available" }}
              name="contact"
              value={salesCall?.contact_id}
              label={
                "Which Contact within this Company would you like to call on? "
              }
              placeholder={t("select_placeholder")}
              list={mapOptions(contactsList ?? [], "name", "id")}
              onChange={(value) => updateSalesCallProp("contact_id", value)}
              validations={formValidators}
              checkValidationOnSubmit={checkValidationOnSubmit}
              required
            />
            <div className="call-goal">
              <FormInput
                id={"accomplishment"}
                onChange={(field: any, value: any) => {
                  if (value !== null) {
                    updateSalesCallProp("accomplishment", value);
                  }
                }}
                required={false}
                placeholder={""}
                type={"text"}
                multiline={true}
                value={salesCall.accomplishment}
                label={
                  "Specifically, what do you want to accomplish during this call?"
                }
                labelStyles={{
                  fontSize: "0.850vw",
                  fontWeight: 700,
                  marginTop: "25px",
                }}
                numberOfLines={3}
              />
            </div>
            <div className="call-plan-assign">
              <div>
                <FormInput
                  id={"planned_by_name"}
                  required={false}
                  placeholder={""}
                  type={"text"}
                  label={t("this_call_is_planned_by")}
                  labelStyles={{
                    fontSize: "0.850vw",
                    fontWeight: 700,
                    textTransform: "none"
                  }}
                  customStyle={{ width: "13vw" }}
                  value={salesCallInfo?.company_id ? salesCall?.planned_by_name : currentLoginUserName}
                  onChange={updateSalesCallProp}
                  disabled
                />
              </div>
              <div>
                <div className="psc-assign-call">
                  <CustomFormSelect
                    customStyle={{ width: "13vw" }}
                    name="assigned_to_id"
                    value={salesCall.assigned_to_id ?? currentLoginUserId}
                    label={t("this_call_is_assigned_to")}
                    placeholder={t("select_placeholder")}
                    list={usersList.map((doc) => ({
                      label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                      value: doc.id,
                    }))}
                    onChange={(value) => updateSalesCallProp("assigned_to_id", value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="label">
              On what day and what time would you like to make this call?
            </div>
            <div className="schedule-container">
              <div className="date-time">
                <div className="sub-label">Date*</div>
                <DateInput
                  id={"schedule_date"}
                  onChange={updateSalesCallProp}
                  onKeyDown={handleKeyDown}
                  placeholder={""}
                  value={salesCall.schedule_date}
                  required
                  validations={formValidators}
                  customStyle={{borderColor: "red !important"}}
                  checkValidationOnSubmit={checkValidationOnSubmit}
                />
              </div>
              <div className="date-time">
                <div className="time-sub-label">
                  <div className="sub-label">Time*</div>
                </div>
                <TimePickerComponent
                  id={"schedule_time"}
                  onChange={updateSalesCallProp}
                  placeholder={''}
                  value={salesCall.schedule_time}
                  onClearTime={handleClearTime}
                  icon={<Timer />}
                  required
                  validations={formValidators}
                  checkValidationOnSubmit={checkValidationOnSubmit}
                />
              </div>
            </div>
            <div>
              <div className="label">
                How do you want to make this call?
              </div>
              <div className="contact-methods">
                {!showSocialMediaOptions && (
                  <>
                    <div
                      className="flex-row"
                      style={{ marginBottom: "25px" }}
                    >
                      <div
                        className={`contact-icon ${salesCall.contact_method === CONTACT_METHODS.phone
                          ? "selected"
                          : ""
                          }`}
                        onClick={() =>
                          updateSalesCallProp(
                            "contact_method",
                            CONTACT_METHODS.phone
                          )
                        }
                      >
                        {salesCall.contact_method ===
                          CONTACT_METHODS.phone && getCheckCircle()}
                        <CallBlueIcon />
                        <div className="contact-label">Phone call</div>
                      </div>
                      <div
                        className={`contact-icon ${salesCall.contact_method === CONTACT_METHODS.email
                          ? "selected"
                          : ""
                          }`}
                        onClick={() =>
                          updateSalesCallProp(
                            "contact_method",
                            CONTACT_METHODS.email
                          )
                        }
                      >
                        {salesCall.contact_method ===
                          CONTACT_METHODS.email && getCheckCircle()}
                        <EmailIcon />
                        <div className="contact-label">Email</div>
                      </div>
                      <div
                        className={`contact-icon ${salesCall.contact_method === CONTACT_METHODS.inperson
                          ? "selected"
                          : ""
                          }`}
                        onClick={() =>
                          updateSalesCallProp(
                            "contact_method",
                            CONTACT_METHODS.inperson
                          )
                        }
                      >
                        {salesCall.contact_method ===
                          CONTACT_METHODS.inperson && getCheckCircle()}
                        <InPerson />
                        <div className="contact-label">In Person</div>
                      </div>
                      <div
                        className={`contact-icon ${salesCall.contact_method === CONTACT_METHODS.zoom
                          ? "selected"
                          : ""
                          }`}
                        onClick={() => {
                          updateSalesCallProp(
                            "contact_method",
                            CONTACT_METHODS.zoom
                          );
                          // window.location.href = 'zoomus://';
                          window.open("https://us04web.zoom.us/meeting/schedule", "_blank");

                        }
                        }
                      >
                        {salesCall.contact_method ===
                          CONTACT_METHODS.zoom && getCheckCircle()}
                        <Zoom />
                        <div className="contact-label">Zoom</div>
                      </div>
                    </div>
                    <div
                      className="flex-row"
                      style={{ marginBottom: "25px" }}
                    >
                      <div
                        className={`contact-icon ${salesCall.contact_method === CONTACT_METHODS.text
                          ? "selected"
                          : ""
                          }`}
                        onClick={() =>
                          updateSalesCallProp(
                            "contact_method",
                            CONTACT_METHODS.text
                          )
                        }
                      >
                        {salesCall.contact_method ===
                          CONTACT_METHODS.text && getCheckCircle()}
                        <TextIcon />
                        <div className="contact-label">Text</div>
                      </div>
                      <div
                        className="contact-icon"
                        onClick={switchContactMethodsView}
                      >
                        <div className="flex-row social-media-options">
                          <LinkedInIcon width={"16px"} height={"16px"} />
                          <FacebookIcon width={"16px"} height={"16px"} />
                          <ZipRecruiterChairIcon
                            width={"16px"}
                            height={"16px"}
                          // viewBox={"0 0 20 98"}
                          />
                          <IndeedLogoIcon
                            width={"16px"}
                            height={"16px"}
                            //viewBox={"0 0 20 98"}
                            fill={"#0B3E79"}
                          />
                        </div>
                        <div className="contact-label">Social media</div>
                      </div>
                      <div
                        className={`contact-icon ${salesCall.contact_method === CONTACT_METHODS.teams
                          ? "selected"
                          : ""
                          }`}
                        onClick={() => {
                          updateSalesCallProp(
                            "contact_method",
                            CONTACT_METHODS.teams
                          );

                          // window.location.href = 'zoomus://';
                          window.open("https://teams.microsoft.com/_?culture=en-in&country=in#/calendarv2", "_blank");
                        }
                        }
                      >
                        {salesCall.contact_method ===
                          CONTACT_METHODS.teams && getCheckCircle()}
                        <Teams />
                        <div className="contact-label">Teams</div>
                      </div>
                      <div
                        className={`contact-icon ${salesCall.contact_method === CONTACT_METHODS.googleMeet
                          ? "selected"
                          : ""
                          }`}
                        onClick={() => {
                          updateSalesCallProp(
                            "contact_method",
                            CONTACT_METHODS.googleMeet
                          );

                          window.open("https://meet.google.com/?pli=1", "_blank");
                        }
                        }
                      >
                        {salesCall.contact_method ===
                          CONTACT_METHODS.googleMeet && getCheckCircle()}
                        <div className="google-meet-icon">
                          <GoogleMeet />
                        </div>
                        <div className="contact-label">Google Meet</div>
                      </div>
                    </div>
                  </>
                )}
                {showSocialMediaOptions && (
                  <div>
                    <KeyboardBackspace onClick={switchContactMethodsView} />
                    <div className="flex-row" style={{ marginTop: "15px" }}>
                      <div
                        className={`contact-icon small ${salesCall.contact_method ===
                          CONTACT_METHODS.linkedIn
                          ? "selected"
                          : ""
                          }`}
                        onClick={() =>
                          updateSalesCallProp(
                            "contact_method",
                            CONTACT_METHODS.linkedIn
                          )
                        }
                      >
                        {salesCall.contact_method ===
                          CONTACT_METHODS.linkedIn && getCheckCircle(true)}
                        <LinkedInIcon width={"30px"} height={"30px"} />
                      </div>
                      <div
                        className={`contact-icon small ${salesCall.contact_method ===
                          CONTACT_METHODS.indeed
                          ? "selected"
                          : ""
                          }`}
                        onClick={() =>
                          updateSalesCallProp(
                            "contact_method",
                            CONTACT_METHODS.indeed
                          )
                        }
                      >
                        {salesCall.contact_method ===
                          CONTACT_METHODS.indeed && getCheckCircle(true)}
                        <IndeedIcon width={"65px"} height={"16px"} />
                      </div>
                      <div
                        className={`contact-icon small ${salesCall.contact_method ===
                          CONTACT_METHODS.zipRecruiter
                          ? "selected"
                          : ""
                          }`}
                        onClick={() =>
                          updateSalesCallProp(
                            "contact_method",
                            CONTACT_METHODS.zipRecruiter
                          )
                        }
                      >
                        {salesCall.contact_method ===
                          CONTACT_METHODS.zipRecruiter &&
                          getCheckCircle(true)}
                        <ZipRecruiterIcon width={"60px"} height={"16px"} />
                      </div>
                      <div
                        className={`contact-icon small ${salesCall.contact_method ===
                          CONTACT_METHODS.facebook
                          ? "selected"
                          : ""
                          }`}
                        onClick={() =>
                          updateSalesCallProp(
                            "contact_method",
                            CONTACT_METHODS.facebook
                          )
                        }
                      >
                        {salesCall.contact_method ===
                          CONTACT_METHODS.facebook && getCheckCircle(true)}
                        <FacebookIcon width={"30px"} height={"30px"} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="btn-cancel">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("cancel")}
              enable
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => {
                setSalesCall(new SalesCall());
                handleClearDropdownStates();
                onClose();
              }}
            />
          </div>
          <div className="btn-save">
            <CustomButton
              loading={
                createState.loading === LoadingType.pending ||
                updateState.loading === LoadingType.pending
              }
              name={t("confirm_my_sales_call")}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    )
  }
};
