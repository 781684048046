import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddPayRollTaxConfiguration, PayRollTaxConfiguration } from "../../../interfaces/payroll_tax_configuration";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { AdminCenterAPI } from "../../../apis";

export const getPayRollTaxConfigList = createAsyncThunk<
    Array<PayRollTaxConfiguration>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/payroll_tax_config/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getPayRollTaxConfigList();
            if(typeof res.data === 'string') return [];
            return res.data as  Array<PayRollTaxConfiguration>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const createPayRollTaxConfig = createAsyncThunk<
    string,
    AddPayRollTaxConfiguration,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/payroll_tax_config/create',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createPayRollTaxConfigList(data);
            if(typeof res.data === 'string') return [];
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const updatePayRollTaxConfig = createAsyncThunk<
    string,
    PayRollTaxConfiguration,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/payroll_tax_config/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updatePayRollTaxConfigList(data);
            if(typeof res.data === 'string') return [];
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deletePayrollTaxConfig = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/payroll_tax_config/delete',
        async (payroll_tax_id, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.deletePayRollTaxConfigList(payroll_tax_id);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
);