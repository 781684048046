import { useTranslation } from "react-i18next";
import PageNavHeader from "../../components/page_header/page_nav_header";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { AppRoutes, getMyProfileNavHeaderProps } from "../../routes";
import MyProfileDashboardPage from "./pages/my_profile_dashboard/my_profile_dashboard";

interface Props extends RouteComponentProps<any> { }

export const MyProfileNavigation: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getMyProfileNavHeaderProps(location, match, t);
    return (
        <PageNavHeader data={headerOptions!} history={history} location={location}>
            <Switch>
                <Route path={AppRoutes.myProfileDashboardPage} component={MyProfileDashboardPage} /> 
            </Switch>
        </PageNavHeader>
        )
}