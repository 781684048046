import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./void_reverse_check_thunk";
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";


export interface VoidRevCheckState {
    voidState: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    reverseState: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    }
}

const initialState: VoidRevCheckState = {
    voidState: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    reverseState: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
};

export const voidRevCheckSlice = createSlice({
    name: "voidRevCheck",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearErrorState(state) {
            return {
                ...state,
                voidState: {
                    ...state.voidState,
                    error: null
                },
                reverseState: {
                    ...state.reverseState,
                    error: null
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.voidPayCheckThunk.pending, (state, action) => ({ ...state, voidState: { ...state.voidState, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.voidPayCheckThunk.rejected, (state, action) => ({ ...state, voidState: { ...state.voidState, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.voidPayCheckThunk.fulfilled, (state, action) => ({ ...state, voidState: { ...state.voidState, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.reversePayCheckThunk.pending, (state, action) => ({ ...state, reverseState: { ...state.reverseState, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.reversePayCheckThunk.rejected, (state, action) => ({ ...state, reverseState: { ...state.reverseState, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.reversePayCheckThunk.fulfilled, (state, action) => ({ ...state, reverseState: { ...state.reverseState, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase((state) => ({ ...state }));
    },
});
export const voidRevCheckSliceActions = voidRevCheckSlice.actions;
export const selectState = (state: VoidRevCheckState) => state;

