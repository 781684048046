import { combineReducers } from "@reduxjs/toolkit";
import { profilePictureSlice } from "./profile_pictures/profile_picture_reducer";
import { resumeFileSlice } from "./resume_files/resume_file_reducer";
import { fileDownloadSlice } from "./file_view_download/file_view_download_reducer";


export const filesReducers = combineReducers({
    [profilePictureSlice.name]: profilePictureSlice.reducer,
    [resumeFileSlice.name]: resumeFileSlice.reducer,
    [fileDownloadSlice.name] : fileDownloadSlice.reducer
});

export * from './profile_pictures/profile_picture_selector';
export * from './profile_pictures/profile_picture_thunk';
export * from './resume_files/resume_file_selector';
export * from './resume_files/resume_file_thunk';

export * from './file_view_download/file_view_download_thunk';
export * from './file_view_download/file_view_download_selector';
