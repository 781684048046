import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, 
    selectAll, 
    selectEntities,
    selectBusinessState,
    selectSkillSetState,
    selectCertificationState,
    selectCredentialState,
    selectEducationState,
    selectEquipmentState,
    selectRemoveState, 
    selectSaveState } from "./skill_list_reducer";

const select = (state: IRootState) => state.adminCenter.skillList;
export const selectSkillListState = createSelector(select, selectState);
export const selectSkillList = createSelector(
    select,
    selectAll
);
export const selectSkillListEntities = createSelector(
    select,
    selectEntities
);
export const selectSkillSet_State=createSelector(select,selectSkillSetState);
export const selectBusinessSectorState = createSelector(select, selectBusinessState);
export const selectCertification_State=createSelector(select,selectCertificationState);
export const selectCredential_State=createSelector(select,selectCredentialState);
export const selectEducation_State=createSelector(select,selectEducationState);
export const selectEquipment_State=createSelector(select,selectEquipmentState);
export const selectSave_state =createSelector(select,selectSaveState);
export const selectDelete_State = createSelector(select,selectRemoveState);


