import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, AssignmentType } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getAssignmentTypes = createAsyncThunk<
    Array<AssignmentType>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/assignment_type/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getAssignmentTypes();
            return res.data as AssignmentType[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createAssignmentType = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/assignment_type/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createAssignmentType(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteAssignmentType = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/assignment_type/delete',
    async (status_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteAssignmentType(status_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateAssignmentType = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': number},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/assignment_type/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateAssignmentType(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);