import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppError, JobDocument} from "../../../../../interfaces";
import {catchRequestError} from "../../../../../utils";
import {JobOrderAPI} from "../../../../../apis/job_order_api";

export const getJobDocuments = createAsyncThunk<
    Array<JobDocument>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@jobs/details/documents/documents/get',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobDocumentsAPI(data);
            return res.data as JobDocument[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const createJobDocumentUploadVideo = createAsyncThunk<
    string,
    {job_order_id: string, file_name: string, file_path: string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@jobs/details/documents/video-upload/post',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.createJobDocumentVideo(data);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const createJobDocumentUploadDocument = createAsyncThunk<
    string,
    {job_order_id: string, file_name: string, notes: string | null},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@jobs/details/documents/document-upload/post',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.createJobDocument(data);
            return res.data.url;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateJobDocumentUploadDocument = createAsyncThunk<
    string,
    JobDocument,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/documents/document-upload/patch',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.updateJobDocumentUploadDocument(data);
            return res.data.url;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteJobDocumentUploadDocument = createAsyncThunk<
    string,
    {job_order_id: string, file_object_id: string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/documents/document-upload/delete',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.deleteJobDocumentUploadDocument(data['file_object_id']);
            return res
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
