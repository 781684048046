import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IFileUplodState, IURLState, Profile } from "../../../interfaces";
import * as thunk from "./essential_staffcare_thunk";

export interface EssentialStaffcareState {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
}

const initialState: EssentialStaffcareState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const essentialStaffcareSlice = createSlice({
    name: 'essentialStaffcare',
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...state, ...initialState };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getESCEnrollmentURLThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getESCEnrollmentURLThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
                response: null,
            }))
            .addCase(thunk.getESCEnrollmentURLThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                error: null,
                response: action.payload
            }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const essentialStaffcareSliceActions = essentialStaffcareSlice.actions;
export const selectState = (state: EssentialStaffcareState) => state;

