
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { LoadingType, THEME } from "../../../../enums";
import { ICreateTalentBOPRDirectDeposit, IBOTalentAccountInfo, ITalentBOPRDirectDeposit } from "../../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { getTalentBOPayrollDirectDepositThunk, selecTalentDirectDepositUpdateState, selectSingleTalentPRDDSStateById, updateTalentBOPayrollDirectDepositThunk, validateTalentDirectDepositAcntThunk } from "../../../../redux/talent";
import { title } from "process";
import CustomStepIndicator from "../../../../components/custom_step_indicator/custom_step_indicator";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { OnBoardingIcon } from "../../../../icons";
import TalBODirectDepositStepOneForm from "./step_one/step_one";
import TalBODirectDepositStepThreeForm from "./step_three/step_three";
import { PAYCHECK_TYPE } from "../../../../enums/onboarding_direct_deposit";
import { CustomButton } from "../../../../utils";
import TalBODirectDepositStepTwoForm from "./step_two/step_two";
interface Props {
    lastStep: number;
    onClose: () => void;
    title: string;
    currentLoginUserName: string;
    talentName: string;
    talentId: string;
    onSuccessClose: () => void;
    handleClearError: () => void;
}

const DDInitialForm = {
    talent_completed_review: false,
    recruiter_completed_review: false,
    reviewed_on_timestamp: 0,
    reviewed_by: "",
    url: "",
    name: "",
    ssn: "",
    bank_accounts_encrypted: "",
    bank_accounts: [
        {
            account_type: "",
            bank_name: "",
            routing_number: 0,
            account_number: 0,
            amount_type: "",
            amount_per_check: 0,
            is_active: true,
        },
    ],
}
const TalentBODirectDepositForm: React.FC<Props> = props => {
    const {
        title,
        currentLoginUserName,
        onSuccessClose,
        handleClearError,
        lastStep, talentName, talentId
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const talentDirectDepositState = useAppSelector((state) => selectSingleTalentPRDDSStateById(talentId)(state));

    const directDepositState = useAppSelector((state) =>
        selecTalentDirectDepositUpdateState(state)
    );
    const getTalentDDInitialForm = (
        value: IBOTalentAccountInfo
    ): ICreateTalentBOPRDirectDeposit => {
        return {
            bank_accounts: {
                0: {
                    account_type: value.account_type ?? "",
                    bank_name: value.bank_name ?? "",
                    routing_number: value.routing_number ?? 0,
                    account_number: value.account_number ?? 0,
                    amount_type: value.amount_type ?? "",
                    amount_per_check: value.amount_per_check ?? 0,
                    is_active: value.is_active ?? true,
                }
            },
        }
    };
    const [currentBankAcnt, setCurrentBankAcnt] = useState<IBOTalentAccountInfo>();
    const [directDepositData, setDirectDepositData] = useState({} as ITalentBOPRDirectDeposit | null);
    const [formState, setFormState] = useState<ICreateTalentBOPRDirectDeposit>(
        currentBankAcnt !== undefined ? getTalentDDInitialForm(currentBankAcnt) : DDInitialForm
    );
    const [edit, setEdit] = useState<boolean>(false);

    useEffect(() => {
        if (currentBankAcnt !== undefined)
            setFormState(getTalentDDInitialForm(currentBankAcnt));
    }, [currentBankAcnt]);
    useEffect(() => {
        if (talentDirectDepositState.loading === LoadingType.succeeded) {
            setDirectDepositData(talentDirectDepositState.data);
        }
    }, [talentDirectDepositState.loading, talentDirectDepositState.data]);
    useEffect(() => {
        if (
            directDepositState.create.loading === LoadingType.succeeded ||
            directDepositState.update.loading === LoadingType.succeeded
        ) {
            dispatch(getTalentBOPayrollDirectDepositThunk(talentId));
        }
    }, [directDepositState.create.loading, directDepositState.update.loading]);
    const [currentStep, setCurrentStep] = useState<{
        label: string;
        value: number;
    }>({ label: t("step_option", { value: 1 }), value: 0 });

    const stepsList: Array<{ label: string; value: number }> = [
        { label: t("step_option", { value: 1 }), value: 0 },
        ...Array.from(Array(lastStep).keys()).map((doc, index) => ({
            label: t("step_option", { value: index + 2 }),
            value: index + 1,
        })),
        {
            label: t("step_option", { value: lastStep + 2 }),
            value: lastStep + 1,
        },
        {
            label: t("step_option", { value: lastStep + 3 }),
            value: lastStep + 2,
        },
    ];

    function getSubTitleByStep(): string {
        if (currentStep.value === 2)
            return t("review_and_verify_info_below", {
                name: currentLoginUserName,
            });
        if (currentStep.value === 1)
            return t("check_bank_acnts_make_changes_go_ahead_next", {
                name: currentLoginUserName,
            });

        if (currentStep.value === 0)
            return t("direct_deposit_setup_msg", {
                name: currentLoginUserName,
            });
        return t("direct_deposit_setup_msg", {
            name: currentLoginUserName,
        });
    }


    const getAmountType = (type) => {
        if (type === "FIXED$AMOUNT") {
            return "Fixed";
        } else if (type === "PERCENTAGE") {
            return "Percentage";
        } else if (type === "REMAININGAMOUNT") {
            return "Remaining";
        } else {
            return type;
        }
    };
    const getAmountPrefix = (type, val) => {
        if (type === "FIXED$AMOUNT") {
            return "$ " + val.toFixed(2);
        } else if (type === "PERCENTAGE") {
            return val.toFixed(2) + " %";
        } else {
            return val;
        }
    };
    async function handleNext() {
        if (currentStep.value === 0) {
            setCurrentStep(stepsList[currentStep.value + 1]);
        }
        if (currentStep.value === 1 && directDepositData) {
            const ddState = await dispatch(
                validateTalentDirectDepositAcntThunk({
                    ...directDepositData,
                })
            );
            if (ddState.meta.requestStatus === "fulfilled") {
                setCurrentStep(stepsList[currentStep.value + 1]);
            }
        }

        if (currentStep.value === props.lastStep + 2) {
            props.onClose();
            handleClearError();
        }

        setCurrentBankAcnt(undefined);
        setEdit(false);
        setFormState(DDInitialForm);
    }
    function handleBack() {
        if (currentStep.value !== 0 && currentStep.value <= props.lastStep + 2) {
            setCurrentStep(stepsList[currentStep.value - 1]);
        }
        handleClearError();
    }
    function _isCurrentStepValid() {
        if (currentStep.value === 0) return true;
        return true;
    }
    function handleBankAccntFieldChange(
        fieldId: string,
        value: any,
        index: number
    ) {
        let amountPayCheckVal =
            fieldId === "amount_per_check"
                ? value.replaceAll(",", "")
                : formState.bank_accounts[index][
                "amount_per_check"
                ];
        if (value === PAYCHECK_TYPE.remainingamount) {
            amountPayCheckVal = 0;
        }

        setFormState({
            ...formState,
            bank_accounts: {
                ...formState.bank_accounts,
                [index]: {
                    ...formState.bank_accounts[index],
                    [fieldId]: value,
                    amount_per_check: amountPayCheckVal,
                },
            },
        });
    }
    const handleAddAcnt = (index) => {
        if (directDepositData) {
            const formStateBankAccnts = Object.keys(
                formState.bank_accounts
            ).map((key) => formState.bank_accounts[key]);
            const ddBanksData = directDepositData?.bank_accounts ? directDepositData?.bank_accounts : []
            const updatedBankAccnts = [
                ...formStateBankAccnts,
                ...ddBanksData,
            ];

            dispatch(
                updateTalentBOPayrollDirectDepositThunk({
                    data: {
                        ...directDepositData,
                        bank_accounts: updatedBankAccnts,
                    }, talent_id: talentId
                })
            );
            handleNext();
        }
    };
    const [currentIndexKey, setCurrentIndexKey] = useState<number>(0);
    const handleEditAccount = (indexKey) => {
        if (directDepositData && directDepositData?.bank_accounts) {
            const bankData = directDepositData?.bank_accounts[indexKey];
            setCurrentBankAcnt(bankData);
            setEdit(true);
            setCurrentStep(stepsList[currentStep.value - 1]);
            setCurrentIndexKey(indexKey);
            handleClearError();
        }
    };
    const handleUpdateAccount = () => {
        if (directDepositData) {
            const formStateBankAccnts = Object.keys(
                formState.bank_accounts
            ).map((key) => formState.bank_accounts[key]);
            const ddBanksData = directDepositData?.bank_accounts ? directDepositData?.bank_accounts : []

            const updatedBankAccnts = [
                ...ddBanksData.slice(
                    0,
                    currentIndexKey
                ),
                ...formStateBankAccnts,
                ...ddBanksData.slice(
                    currentIndexKey + 1
                ),
            ];

            dispatch(updateTalentBOPayrollDirectDepositThunk({
                data: {
                    ...directDepositData,
                    bank_accounts: updatedBankAccnts,
                }, talent_id: talentId
            }));

            handleNext();
        }
    };
    function getActiveStep() {
        if (currentStep.value === props.lastStep + 2) {
            return (
                <div>
                    <TalBODirectDepositStepThreeForm name={currentLoginUserName} />
                </div>
            );
        }
        if (currentStep.value === props.lastStep + 1) {
            return (
                <div className="ob-dd-f-container">
                    <TalBODirectDepositStepTwoForm
                        talent_direct_deposit_data={directDepositData}
                        addAccount={handleBack}
                        handleEditAccount={(index) => handleEditAccount(index)}
                    />
                </div>
            );
        }
        return (
            <div>
                {Object.keys(formState.bank_accounts).map(
                    (key, index) => {
                        return (
                            <div key={key + index + "form"} className="child-form-holder">
                                <TalBODirectDepositStepOneForm
                                    formState={
                                        formState.bank_accounts[key]
                                    }
                                    onChange={(id, val) =>
                                        handleBankAccntFieldChange(id, val, index)
                                    }
                                    amount_option={
                                        formState.bank_accounts[key]
                                            .amount_type
                                    }
                                    edit={edit}
                                />
                            </div>
                        );
                    }
                )}
            </div>
        );
    }

    return (
        <div className="add-tal-bo-dd-container">
            <div className="cnt-f-container">
                <div className="drd-header">
                    <FormDialogTilteHeader
                        title={title}
                        subTitle={getSubTitleByStep()}
                        subTitleStyle={{ fontWeight: "normal" }}
                        titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
                    />
                </div>
                <div className="cnt-f-steps-indicator">
                    <CustomStepIndicator
                        selected={currentStep}
                        stepsList={stepsList}
                        onClick={() => { }}
                    />
                </div>
            </div>
            <div style={{ marginBottom: "2rem" }}>{getActiveStep()}</div>
            <div className="drd-actions">
                {directDepositState?.create?.error !== null && (
                    <div className="error-msg">
                        {directDepositState?.create?.error?.message}
                    </div>
                )}

                <div className="btn-cancel">
                    {currentStep.value !== 0 &&
                        currentStep.value <= props.lastStep + 2 ? (
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("back")}
                            enable={_isCurrentStepValid()}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleBack}
                        />
                    ) : (
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("cancel")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={props.onClose}
                        />
                    )}
                </div>
                <div className="btn-new">
                    {currentStep.value === 0 && (
                        <CustomButton
                            loading={false}
                            textStyle={{
                                textTransform: "capitalize",
                            }}
                            name={t("save_and_next")}
                            enable={isFormValid()}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() =>
                                currentBankAcnt === undefined
                                    ? handleAddAcnt(
                                        directDepositData &&
                                            directDepositData?.bank_accounts
                                            ? directDepositData?.bank_accounts?.length
                                            : 0
                                    )
                                    : handleUpdateAccount()
                            }
                        />
                    )}
                </div>
                <div className="btn-new">
                    {currentStep.value === 2 ? (
                        <CustomButton
                            loading={false}
                            textStyle={{
                                textTransform: "capitalize",
                            }}
                            name={t("confirm_complete")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => onSuccessClose()}
                        />
                    ) : (
                        <CustomButton
                            loading={false}
                            textStyle={{
                                textTransform: "capitalize",
                            }}
                            name={currentStep.value === 0 ? t("skip") : t("next")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleNext}
                        />
                    )}
                </div>
            </div>
        </div>
    )
    function isFormValid() {
        const valuesCheck = Object.keys(
            formState.bank_accounts
        ).map((key) => {
            if (
                formState.bank_accounts[key].account_number ===
                "" ||
                formState.bank_accounts[key].routing_number ===
                "" ||
                formState.bank_accounts[key].account_number ===
                0 ||
                formState.bank_accounts[key].routing_number ===
                0 ||
                formState.bank_accounts[key].routing_number.length < 9
                ||
                formState.bank_accounts[key].bank_name === "" ||
                formState.bank_accounts[key].amount_type === ""
            ) {
                return false;
            } else {
                return true;
            }
        });

        return valuesCheck[0];
    }
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 0.7;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 0.5;


        return 1;
    }
}

export default TalentBODirectDepositForm;