
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import {selectState, selectVacationState } from "./holiday_vacation_report_reducer";

const select = (state: IRootState) => state.reportCenter.reportCenterPayrollTax.holidayVacationReport;
export const selectHolidayReportState = createSelector(select, selectState);
export const selectVacationReportState = createSelector(select, selectVacationState);


