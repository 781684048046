import { createSlice } from "@reduxjs/toolkit";
import { AppError, IPCRevHistory } from "../../../../interfaces";
import * as thunk from "./check_rev_history_thunk";
import { LoadingType } from "../../../../enums";

export interface CheckReversalState {
    error?: AppError | null,
    loading: LoadingType,
    response?: IPCRevHistory | null,
}

const initialState: CheckReversalState = {
    error: null,
    loading: LoadingType.idle,
    response: null
}


export const paycheckRevSlice = createSlice({
    name: 'paycheckReverseSlice',
    initialState: initialState,
    reducers: {
    
    },
    extraReducers: (builder) => {
        builder
        .addCase(thunk.getPaycheckRevHistTHunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
        .addCase(thunk.getPaycheckRevHistTHunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
        .addCase(thunk.getPaycheckRevHistTHunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))

        .addDefaultCase(state => ({ ...state }));
    }
});

export const paycheckRevSliceActions = paycheckRevSlice.actions;
export const selectState = (state: CheckReversalState) => state;



