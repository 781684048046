import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, Profile } from "../../../../interfaces";
import * as thunk from "./account_managers_list_thunk";

export interface AccountManagersListState extends EntityState<Profile> {
    error?: AppError | null,
    loading: LoadingType,
}

const accountManagersListAdapter: EntityAdapter<Profile> = createEntityAdapter<Profile>({
    selectId: (doc) => doc.id,
})

const initialState: AccountManagersListState = accountManagersListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const accountManagersListSlice = createSlice({
    name: 'accountManagersList',
    initialState: initialState,
    reducers: {
        clearAccountManagersListError(state){
            return {...state, error: null};
        },
        clearAccountManagersListState(state){
            return accountManagersListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAccountManagers.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getAccountManagers.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAccountManagers.fulfilled, (state, action) => accountManagersListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearAccountManagersListError, clearAccountManagersListState } = accountManagersListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = accountManagersListAdapter.getSelectors();
export const selectState = (state: AccountManagersListState) => state;
export const selectLoading = (state: AccountManagersListState) => state.loading;
export const selectError = (state: AccountManagersListState) => state.error;


