import { createSelector } from "@reduxjs/toolkit";
import * as reducer from "./resume_file_reducer";
import { IRootState } from "../../store";


const select = (state: IRootState) => state.files.resumeFile;
export const selectResumeFileState = createSelector(select, reducer.selectresumeFileState);
export const selectSingleResumeFileStateById = (id: string | undefined) => createSelector(selectResumeFileState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleResumeFileState(state, id);
        return reducer.selectInitialSingleResumeFileState();
    })