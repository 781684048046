import { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper from "../../../components/dialog_wrapper/dialog_wrapper";
import {
  IEmpOnboardingDocsReviewStatus,
} from "../../../interfaces";
import { useAppDispatch } from "../../../redux/store";
import "./direct_deposit.scss";
import DirectDepositForm from "../../forms/direct_deposit/direct_deposit";
import { empDirectDepositSliceActions } from "../../../redux/employee_dashboard/direct_deposit/direct_deposit_reducer";
interface Props {
  visible: boolean;
  type: string;
  title: string;
  token: string | undefined;
  state: IEmpOnboardingDocsReviewStatus | null;
  successTitle: string;
  onClose: () => void;
  onDissmiss?: () => void;
  onSuccessClose: (type: string, value?: any) => void;
  lastStep: number;
  currentLoginUserName: string;
  talentName: string;
}

const DirectDepositPopup: React.FunctionComponent<Props> = (props) => {
  const {
    visible,
    onSuccessClose,
    state,
    title,
    token,
    onClose,
    onDissmiss,
    type,
    lastStep,
    currentLoginUserName,
    talentName,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const actionRef = useRef<ActionDialogHolderType>(null);

  function handleClose() {
    closePopup(onClose);
    handleClearError();
  }

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={"70%"}
      onClose={onClose}
      onDissmiss={onDissmiss}
    >
      <DialogWrapper onClose={handleClose}>
        <div className="drd-container">
          <div>
            {/* <PDFReader onInstanceChange={handleOnInstance} /> */}
            <div className="drd-content">
              <DirectDepositForm
                data={state?.direct_deposit_document}
                state={state}
                lastStep={lastStep}
                onClose={onClose}
                title={title}
                currentLoginUserName={currentLoginUserName}
                talentName={talentName}
                token={token}
                onSuccessClose={onSuccessClose}
                type={type}
                handleClearError={handleClearError}
              />
            </div>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );

  ///Handle clear error
  function handleClearError() {
    dispatch(empDirectDepositSliceActions.clearState());
  }

  /// handle submit
  function handleSave() {
    // dispatch(
    //   getUploadDirectDepositUrlThunk({ token, talentId: state.profile.id })
    // );
  }

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }
};

export default DirectDepositPopup;
