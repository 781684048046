import './notification_circle.scss';

interface Props {
    value: number,
    onClick: () => void,
    customStyle?: React.CSSProperties,
    textStyle?: React.CSSProperties,
}

export const NotificationCircle: React.FunctionComponent<Props> = (props) => {

    return (
        <button 
        className="notification-circle-holder" 
        onClick={props.onClick}
        style={{...props.customStyle}}
        >
            <div className="notification-txt">
                <span style={{...props.textStyle}}>{props.value}</span>
            </div>
        </button>
    )
}


export const NumberBadge: React.FunctionComponent<Props> = (props) => {

    return (
        <button 
        className="number-badge-holder" 
        onClick={props.onClick}
        style={{...props.customStyle}}
        >
            <div className="number-badge-txt">
                <span style={{...props.textStyle}}>{props.value}</span>
            </div>
        </button>
    )
}
