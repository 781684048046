// import { t } from "i18next";
// import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import { Portal } from "react-portal";
// import {
//   ActionDialogHolder,
//   ActionDialogHolderType,
// } from "../../../../../../components/action_dialog_holder/action_dialog_holder";
// import ApiError from "../../../../../../components/api_error";
// import DialogWrapper, {
//   FormDialogTilteHeader,
// } from "../../../../../../components/dialog_wrapper/dialog_wrapper";
// import { CustomMultiFormSelect } from "../../../../../../components/form_selector/form_select";
import MultiOptionSection from "../../../../../../components/multi_option_section/multi_option_section";
// import {
//   IAddContactCertificate,
//   IAddContactCredentials,
//   IAddContactEducation,
//   IAddContactSkill,
//   IRemoveContactCertificate,
//   IRemoveContactCredentials,
//   IRemoveContactEducation,
//   IRemoveContactSkill,
// } from "../../../../../../entities/ContactInfo";
import { LoadingType, TALENT_SKILLS_TYPE, THEME } from "../../../../../../enums";
// import { ContactsIcon } from "../../../../../../icons";
import { AppError, ISkillOption } from "../../../../../../interfaces";
// import { contactDetailsSlice } from "../../../../../../redux/contacts/contact_details/contact_details_reducer";
// import { selectContactDetailsState } from "../../../../../../redux/contacts/contact_details/contact_details_selector";
// import {
//   addContactCertificationThunk,
//   addContactCredentialsThunk,
//   addContactEducationThunk,
//   addContactSkillsThunk,
//   removeContactCertificationThunk,
//   removeContactCredentialsThunk,
//   removeContactEducationThunk,
//   removeContactSkillsThunk,
// } from "../../../../../../redux/contacts/contact_details/contact_details_thunk";
import {
  selectContactDetailsByIdState,
  useAppSelector,
} from "../../../../../../redux/store";
// import { CustomButton } from "../../../../../../utils";
import './skills_summary.scss';

interface Props {
  contactId: string,
  onAddOption: (type: string) => void,
  onRemoveOption: (type: string, value: ISkillOption) => void,
}

const ContactSkillSummary: React.FunctionComponent<Props> = (props) => {
  const companyState = useAppSelector((state) => selectContactDetailsByIdState(props.contactId)(state));
  const { t } = useTranslation();
  return (
    <div className="cd-cs-covv-container">
      <MultiOptionSection
        headerTitle={t('professional_skills').toUpperCase()}
        actionName={t('add_skills')}
        onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.professional_skills)}
        list={companyState.professional_skills.data ?? []}
        onRemove={(value) => props.onRemoveOption(TALENT_SKILLS_TYPE.professional_skills, value)}
      />
      <MultiOptionSection
        headerTitle={t('credentials').toUpperCase()}
        actionName={t('add_credentials')}
        onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.credentials)}
        list={companyState.credentials.data ?? []}
        onRemove={(value) => props.onRemoveOption(TALENT_SKILLS_TYPE.credentials, value)}
      />
      <MultiOptionSection
        headerTitle={t('certifications').toUpperCase()}
        actionName={t('add_certifications')}
        onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.certifications)}
        list={companyState.certifications.data ?? []}
        onRemove={(value) => props.onRemoveOption(TALENT_SKILLS_TYPE.certifications, value)}
      />
      <MultiOptionSection
        headerTitle={t('education').toUpperCase()}
        actionName={t('add_education')}
        onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.education)}
        list={companyState.education.data ?? []}
        onRemove={(value) => props.onRemoveOption(TALENT_SKILLS_TYPE.education, value)}
      />
      <MultiOptionSection
        headerTitle={t('required_equipment').toUpperCase()}
        actionName={t('add_equipment')}
        onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.safety_equipment)}
        list={companyState.safety_equipment.data ?? []}
        onRemove={(value) => props.onRemoveOption(TALENT_SKILLS_TYPE.safety_equipment, value)}
      />
    </div>
  )
};

export default ContactSkillSummary;
