import { EntityAdapter, EntityState, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import * as thunk from "./kiniso_job_orders_thunk";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import { IKinisoJobOrderResponse } from "../../../interfaces/kiniso_job_orders";

export interface KinisoJobsListState extends EntityState<IKinisoJobOrderResponse> {
    error?: AppError | null,
    loading: LoadingType,
}
const kinisoJobstListAdapter: EntityAdapter<IKinisoJobOrderResponse> = createEntityAdapter<IKinisoJobOrderResponse>({
    selectId: (doc) => doc.id,
})

const initialState: KinisoJobsListState = kinisoJobstListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const KinisoJobOrdersSlice = createSlice({
    name: 'kinisoJobOrders',
    initialState: initialState,
    reducers: {
        clearJobsListError(state) {
            return { ...state, error: null };
        },
        clearJobsListState(state) {
            return kinisoJobstListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAllKinisoJobOrdersThunk.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getAllKinisoJobOrdersThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAllKinisoJobOrdersThunk.fulfilled, (state, action) => kinisoJobstListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const KinisoJobOrdersActions = KinisoJobOrdersSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = kinisoJobstListAdapter.getSelectors();
export const selectState = (state: KinisoJobsListState) => state;
export const selectLoading = (state: KinisoJobsListState) => state.loading;
export const selectError = (state: KinisoJobsListState) => state.error;

