import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import {
    selectState,
    selectAssignmentState,
    selectInitialTalentState,
    // selectAssignmentSkillsState,
    // selectAssignmentCredentialsState,
    // selectAssignmentCertificationsState,
    // selectAssignmentEducationState,
    // selectAssignmentEquipmentState,
} from "./assignment_details_reducer";

const select = (state: IRootState) => state.assignments.details.dashboard.assignmentDetails;
export const selectAssignmentDetailsState = createSelector(select, selectState);

export const selectAssignmentDetailsByIdState = (id: string | undefined | null) => createSelector(
    selectAssignmentDetailsState,
    (state) => {
        if (id && state[id]) return selectAssignmentState(state, id);
        return selectInitialTalentState();
    }
);
// export const selectAssignmentSkillsStateById = (id: string | undefined | null) => createSelector(
//     selectAssignmentDetailsByIdState(id),
//     selectAssignmentSkillsState,
// );
// export const selectAssignmentCredentialsStateById = (id: string | undefined | null) => createSelector(
//     selectAssignmentDetailsByIdState(id),
//     selectAssignmentCredentialsState,
// );
// export const selectAssignmentCertificationsStateById = (id: string | undefined | null) => createSelector(
//     selectAssignmentDetailsByIdState(id),
//     selectAssignmentCertificationsState,
// );
// export const selectAssignmentEducationStateById = (id: string | undefined | null) => createSelector(
//     selectAssignmentDetailsByIdState(id),
//     selectAssignmentEducationState,
// );
// export const selectAssignmentEquipmentStateById = (id: string | undefined | null) => createSelector(
//     selectAssignmentDetailsByIdState(id),
//     selectAssignmentEquipmentState,
// );
// export const selectAssignmentDetailsDepartmentState = createSelector(select, selectDepartmentState);
