import { TFunction } from "react-i18next";
import './menu_option.scss';

export interface MenuOptionDocInput {
    type?: string,
    title: string,
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }>,
    icon_two?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }>,
    supText?:string,
    route?: string,
    loading?: boolean,
    error?: any,
    onClick?: () => void,
}
interface Props {
    doc: MenuOptionDocInput,
    t: TFunction<"translation", undefined>,
    customStyle?: React.CSSProperties,
    className?: string,
    isCompleted?: boolean,
    onClick: () => void,
}

const MenuOption = (props: Props) => {
    return (
        <div
            style={props.customStyle}
            className={`adc-option adc-option-${props.doc.title} ${props.className}`}
        >
            <div
                onClick={props.onClick}
                className={`adc-option-icon-title`}
            >
                <div className={props.doc.title === 'text_em_all' ? "adc-option-icon-tea" : "adc-option-icon"}>
                    <props.doc.icon width={"100%"} height={"100%"} />
                </div>

                <div className="adc-option-title">
                    <div>{props.t(props.doc.title)}</div>
                </div>
            </div>
            {props.doc.icon_two && <div className={"adc-option-icon-two"} onClick={props.doc.onClick}>
                <props.doc.icon_two width={"1vw"} height={"1vw"} />
            </div>}
            {props.doc.supText && <div className={"adc-option-sup-text"} onClick={props.onClick}>
                <span>{props.doc.supText}</span>
            </div>}
        </div>
    );
}

export default MenuOption;