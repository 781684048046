import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../../../../icons';
import { ITalentInHouseInterview } from '../../../../../../interfaces';
import { getDateString } from '../../../../../../variables';
import './interview_details_header.scss';
import { TableData } from '../../../../../../components/sortable_table/sortable_table';

interface Props {
    list: Array<{ title: string, value: string, isButton?: boolean, style?: React.CSSProperties ,handleCompanyClick?: () => void,handleDeptClick?: () => void,handleContactClick?: () => void, handleTalentClick?: () => void }>
    onEditInterview: () => void
}

const InterviewDetailsHeader: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    function handleClick(doc){
        if(doc.title==="Company"){
            return doc.handleCompanyClick
        }
        if(doc.title==="Department"){
            return doc.handleDeptClick
        }
        if(doc.title==="Interview With" || doc.title==="Interviewer"){
            return doc.handleContactClick
        }
        if(doc.title==="Talent"){
            return doc.handleTalentClick
        }
       
     }
    return (
        <div className="interview-info">
            <div className="info-txt-holder">
                {props.list.map((doc, index) => {
                    return (
                        <div key={index} className="txt-str">
                            <span>{`${doc.title}:`}<TableData isButton={doc.isButton} onClick={handleClick(doc)}><span style={doc.style}>{doc.value}</span></TableData></span>
                            {/* <span>{`${doc.title}:`} <span style={{ fontWeight: 800 }}>{doc.value}</span></span> */}
                        </div>
                    )
                })}
            </div>
            <button className="icon-btn edit-icon" onClick={props.onEditInterview}>
                <EditIcon width={"100%"} height={"100%"} />
            </button>
        </div>
    )
}

export default InterviewDetailsHeader;