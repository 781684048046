
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./additional_tax_reducer";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.AditionalTaxList;
export const selectAditionalTaxListState = createSelector(select, selectState);
export const selectAditionalTaxList = createSelector(
    select,
    selectAll
);
export const selectAditionalTaxListEntities = createSelector(
    select,
    selectEntities
);