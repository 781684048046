import { useTranslation } from "react-i18next";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import InDevelopment from "../../others/in_development";
import { getClientPortalNavHeaderProps } from "../../routes";

interface Props extends RouteComponentProps<any> { }

export const ClientPortalNavigation: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getClientPortalNavHeaderProps(location, match, t);

    return (
        <PageNavHeader data={headerOptions} history={history} location={location}>
            <Switch>
                <Route path={'/'} component={InDevelopment} />
            </Switch>
        </PageNavHeader>
    );
}
