import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiError from '../../../../../components/api_error';
import InterviewsListView, { InterviewDetailsCard } from '../../../../../components/interviews_list_view/interviews_list_view';
import { TableErrorHandler } from '../../../../../components/table_empty/table_empty';
import { LoadingType, THEME } from '../../../../../enums';
import { ChatIcon, CloseIcon, EditIcon } from '../../../../../icons';
import { AppError, ITalentInHouseInterview } from '../../../../../interfaces';
import { SingleTalentInHouseInterviewsState } from '../../../../../redux/talent/details/interviews/in_house_interviews/in_house_interviews_reducer';
import { CustomButton, FormInput, SpinnerScreen } from '../../../../../utils';
import { getDateString } from '../../../../../variables';
import InterviewDetailsHeader from '../components/interview_details_header/interview_details_header';
import InterviewQuestionCard from '../components/interview_question_card/interview_question_card';
import './in_house_interviews.scss';

interface Props {
    onInterviewClick: (value: ITalentInHouseInterview) => void
    onAddNew: () => void,
    onRefresh: () => void,
    state: SingleTalentInHouseInterviewsState,
    selectedInterview: ITalentInHouseInterview | null,
    onEditInterview: (value: ITalentInHouseInterview) => void,
    onCloseInterview: () => void,
}

const InHouseInterviews: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();

    if (props.state.loading === LoadingType.pending) {
        return (
            <div className="tint-ihi-container">
                <SpinnerScreen />
            </div>
        )
    }

    if (props.state.error != null) {
        <div className="tint-ihi-container">
            <TableErrorHandler error={props.state.error} onRefresh={props.onRefresh} />
        </div>
    }

    return (
        <div className="tint-ihi-container">
            <div className="tint-ihi-left-panel">
                <InterviewsListView
                    interviews={props.state.data ? Object.keys(props.state.data).map((key) => props.state.data[key]) : []}
                    onClick={props.onInterviewClick}
                    onAddNew={props.onAddNew}
                    selectedInterview={props.selectedInterview}
                />
                <div className="tint-left-panel-shape-pyramid"></div>
            </div>
            <div className="tint-ihi-right-panel">
                {/* {props.selectedInterview && <>
                    <InterviewDetailsCard
                        title={t('internal_interview')}
                        interviewBy={t('interview_by', { value: props.selectedInterview.interview_by})}
                        interViewDate={t('interview_date', { value: getDateString(props.selectedInterview.interview_date) })}
                        applicationDate={t('application_date', { value: getDateString(props.selectedInterview.application_date) })}
                    />
                    <div className="tint-ihi-question-form">
                        <EditInHouseInterviewForm
                            interview={props.selectedInterview}
                            error={props.state.update.error}
                            loading={props.state.update.loading === LoadingType.pending}
                            onClearError={props.onClearError}
                            onSubmit={props.onSaveChanges}
                        />
                    </div>
                </>} */}
                {props.selectedInterview && <div className="tint-ihi-details-holder">
                    <div className="tint-ihi-icon">
                        <ChatIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
                    </div>
                    <div className="tint-ihi-content">
                        <div className="tint-ihi-title">
                            <span>{t('internal_interview')}</span>
                            <button className="icon-btn close-icon" onClick={props.onCloseInterview}>
                                <CloseIcon width={"100%"} height={"100%"} />
                            </button>
                        </div>
                        <div className="tint-ihi-info-header">
                            <InterviewDetailsHeader
                                list={[
                                    { title: t('interview_by'), value: props.selectedInterview.interview_by },
                                    { title: t('interview_date'), value: getDateString(props.selectedInterview.interview_date) },
                                    { title: t('application_date'), value: getDateString(props.selectedInterview.application_date) },
                                ]}
                                onEditInterview={() => props.onEditInterview(props.selectedInterview!)}
                            />
                        </div>
                        <div className="tint-ihi-info-content">
                            {(props.selectedInterview.talent_interview_questions ?? []).map((doc, index) => {
                                return (
                                    <InterviewQuestionCard
                                        key={index}
                                        doc={doc}
                                        index={index}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>}

            </div>

        </div>
    )
}

interface EditInHouseInterviewFormProps {
    interview: ITalentInHouseInterview,
    loading: boolean,
    error: AppError | null | undefined,
    onSubmit: (value: ITalentInHouseInterview) => void,
    onClearError: () => void,
}

const formOptions = {
    candidate_answer: {
        field: "candidate_answer",
        type: "text",
        label: 'candidate_answer',
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: '',
        multiline: true,
    },
    recruiter_notes: {
        field: "recruiter_notes",
        type: "text",
        label: 'recruiter_notes',
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: '',
        multiline: true,
    }
}

function getInitialState(value: ITalentInHouseInterview) {
    return {
        id: value.id,
        talent_id: value.talent_id,
        user_id: value.user_id,
        interview_date: value.interview_date,
        application_date: value.application_date,
        talent_interview_questions: { ...value.talent_interview_questions },
    }
}

const EditInHouseInterviewForm = (props: EditInHouseInterviewFormProps) => {
    const { interview } = props;
    const { t } = useTranslation();
    const [formState, setFormState] = useState<any>(getInitialState(interview))

    function handleFieldChange(fieldId, value, index) {
        setFormState({
            ...formState,
            talent_interview_questions: {
                ...formState.talent_interview_questions,
                [index]: {
                    ...formState.talent_interview_questions[index],
                    [fieldId]: value,
                }

            }
        });
    }

    return (
        <div className="tint-ihi-form-container">
            <div className="tint-ihi-header">
                <div className="tint-ihi-header-txt">
                    <span>{t('questions').toUpperCase()}</span>
                </div>
            </div>
            <div className="tint-ihi-questions-section">
                {(interview.talent_interview_questions ?? []).map((doc, index) => {
                    return (
                        <div className="tint-ihi-question-container" key={index}>
                            <div className="tint-ihi-question-title">
                                <span>{`${index + 1}. ${doc.interview_question}`}</span>
                            </div>
                            <div className="tint-ihi-input-form">
                                <div className="tlnt-f-row">
                                    <div key={formOptions.candidate_answer.field} className={"tlnt-f-input-holder"}>
                                        <FormInput
                                            id={formOptions.candidate_answer.field}
                                            onChange={(field, value) => handleFieldChange(field, value, index)}
                                            secure={formOptions.candidate_answer.secure}
                                            required={formOptions.candidate_answer.required}
                                            placeholder={formOptions.candidate_answer.placeholder}
                                            type={formOptions.candidate_answer.type}
                                            value={formState.talent_interview_questions[index][formOptions.candidate_answer.field]}
                                            label={`${t(formOptions.candidate_answer.label).toUpperCase()}:`}
                                            keyboardtype={formOptions.candidate_answer?.keyboardtype}
                                            multiline={formOptions.candidate_answer.multiline}
                                            labelStyles={{ fontWeight: 'normal' }}
                                        />
                                    </div>
                                    <div key={formOptions.recruiter_notes.field} className={"tlnt-f-input-holder"}>
                                        <FormInput
                                            id={formOptions.recruiter_notes.field}
                                            onChange={(field, value) => handleFieldChange(field, value, index)}
                                            secure={formOptions.recruiter_notes.secure}
                                            required={formOptions.recruiter_notes.required}
                                            placeholder={formOptions.recruiter_notes.placeholder}
                                            type={formOptions.recruiter_notes.type}
                                            value={formState.talent_interview_questions[index][formOptions.recruiter_notes.field]}
                                            label={`${t(formOptions.recruiter_notes.label).toUpperCase()}:`}
                                            keyboardtype={formOptions.recruiter_notes?.keyboardtype}
                                            multiline={formOptions.recruiter_notes.multiline}
                                            labelStyles={{ fontWeight: 'normal' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                {props.error && <div className={"error-section"}>
                    <ApiError message={props.error.message} onClose={props.onClearError} />
                </div>}
            </div>

            <div className="tlnt-f-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('cancel')}
                        enable={_isDirty()}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={handleCancel}
                    />
                </div>

                <div className="btn-save">
                    <CustomButton
                        loading={props.loading}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('save')}
                        enable={_isDirty()}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => props.onSubmit({ ...formState, talent_interview_questions: Object.keys(formState.talent_interview_questions).map((key, index) => formState.talent_interview_questions[key]) })}
                    />
                </div>
            </div>
        </div>
    )

    function _isDirty(): boolean {
        const initialState = getInitialState(interview);
        if (JSON.stringify(initialState) === JSON.stringify(formState)) return false;
        return true;
    }


    function handleCancel() {
        setFormState({
            ...formState,
            talent_interview_questions: { ...interview.talent_interview_questions },
        });
    }
}

export default InHouseInterviews;