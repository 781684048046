import { combineReducers } from "@reduxjs/toolkit";
import { companyAssignmentsReducers } from "./assignments";
import { companyBackOfficeFormatAndSetupReducers } from "./back_office_format_and_setup";
import { companyBackOfficeInvoicesAndDocsReducers } from "./back_office_invoices_and_docs";
import { companyContactsReducers } from "./contacts";
import { companyDashboardReducers } from "./dashboard";
import { companyInvoicesSlice } from "./company_invoices/company_invoices_reducer";
import { companyEDocsSlice } from "./company_edocs/company_edocs_reducer";

export const companyDetailsReducers = combineReducers({
    'dashboard': companyDashboardReducers,
    'assignments': companyAssignmentsReducers,
    'contacts': companyContactsReducers,
    'backOfficeFormatAndSetUp': companyBackOfficeFormatAndSetupReducers,
    ['backOfficeInvoicesAndDocs']: companyBackOfficeInvoicesAndDocsReducers,
    [companyInvoicesSlice.name]: companyInvoicesSlice.reducer,
    [companyEDocsSlice.name]: companyEDocsSlice.reducer

});

export * from './dashboard';
export * from './assignments';
export * from './contacts';
export * from './back_office_format_and_setup';
export * from './back_office_invoices_and_docs';

export * from './company_invoices/company_invoices_selector';
export * from './company_invoices/company_invoices_thunk';

export * from './company_edocs/company_edocs_selector';
export * from './company_edocs/company_edocs_thunk';
