import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../enums";
import { AppError, Profile } from "../../interfaces";
import * as profileThunk from "./profile_thunk";

export interface ProfileState {
    error?: AppError | null,
    loading: LoadingType,
    logoutLoading: LoadingType,
    token?: String | null,
    profile?: Profile | null,
}

const initialState: ProfileState = {
    error: null,
    loading: LoadingType.idle,
    logoutLoading: LoadingType.idle,
    profile: null,
    token: null,
}


export const profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(profileThunk.checkAuthToken.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(profileThunk.checkAuthToken.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, token: null, }))
            .addCase(profileThunk.checkAuthToken.fulfilled, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, token: action.payload }))

            .addCase(profileThunk.getProfile.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(profileThunk.getProfile.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.error,  }))
            .addCase(profileThunk.getProfile.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, profile: action.payload, }))

            .addCase(profileThunk.profileLogout.pending, (state, action) => ({ ...state, logoutLoading: LoadingType.pending, error: null, }))
            .addCase(profileThunk.profileLogout.rejected, (state, action) => ({ ...state, logoutLoading: LoadingType.failed, error: action.error , profile: null, token: null  }))
            .addCase(profileThunk.profileLogout.fulfilled, (state, action) => ({ ...state, logoutLoading: LoadingType.succeeded, error: null, profile: null, token: null }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = profileSlice.actions;
export const selectState = (state: ProfileState) => state;
export const selectLoading = (state: ProfileState) => state.loading;
export const selectError = (state: ProfileState) => state.error;


