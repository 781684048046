import React, { useState, useRef } from "react"
import { RegisterCredentials } from '../../../../interfaces'
import { RegxPattern } from '../../../../utils';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import CreateTalentAccountForm from '../../../talent/forms/create_login_form/create_login_form'
import { selectCreateLoginForTalentState, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { createLoginForTalentThunkUser } from "../../../../redux/store";
import { clearCreateLoginForTalentError, createLoginForTalentSlice } from '../../../../redux/talent/details/onboarding_and_docs/create_talent_login/create_talent_login_reducer';
import { LoadingType } from '../../../../enums';
import { useTranslation } from "react-i18next";
import { PanoramaSharp } from "@material-ui/icons";


export interface CreateTalentAccount {
    visible: boolean,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    talentId: string
}
const CreateTalentAccount: React.FC<CreateTalentAccount> = (props) => {
    const { visible, onClose, onDissmiss } = props
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const { loading, response, error } = useAppSelector((state) => selectCreateLoginForTalentState(state))
    const createLoginTalentState = useAppSelector((state) => selectCreateLoginForTalentState(state))
    const [createLoginForTalent, setcreateLoginForTalent] = useState<RegisterCredentials>({
        talentName: '',
        email: '',
        talentId: '',

    })


    function handleClearError() {
        dispatch(clearCreateLoginForTalentError());
    }


    function handleWrapperClose(action?: () => void) {
        if (loading == LoadingType.succeeded) {
            // dispatch(postSnackbarMessage(createState.response ?? null));
            closePopup(props.onSuccessClose);
        } else {
            if (action) closePopup(action);
            dispatch(clearCreateLoginForTalentError());
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
                {getUI()}
            </DialogWrapper>
        </ActionDialogHolder>
    );

    function getUI() {
        return (
            <div className="signup-container-bg">
                <div className="signup-page-container">
                    <div className="signup-form">
                        <CreateTalentAccountForm
                            loading={createLoginTalentState.loading}
                            error={error}
                            onClearError={handleClearError}
                            onSuccessClose={props.onSuccessClose}
                            isFormVaid={true}
                            talentId={props.talentId}
                        />
                    </div>
                </div>
            </div>
        )
    }

}

export default CreateTalentAccount