export enum PAYROLLTAX_CONFIG_TAX {
    employer = "Employer",
    employee = "Employee",
}
export enum PAYROLLTAX_CONFIG_TAX_TYPE {
    federal = "Federal",
    state = "State",
    local = "Local",
}
export enum PAYROLLTAX_CONFIG_TAX_LIMIT {
    yes = "Yes",
    no = "No",
}