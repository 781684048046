import { createAsyncThunk } from "@reduxjs/toolkit";
import { OnlineApplicationAPI } from "../../../apis";
import { AppError, IOnlineApplication, IOnlineApplicationResponse, IOnlineApplicationUploadUrlsQuery, IUploadUrlsResponse, } from "../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../utils";

export const submitOnlineApplicationThunk = createAsyncThunk<
    IOnlineApplicationResponse,
    IOnlineApplication,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@online-application/form/post',
    async (credentials, thunkAPI) => {
        try {
            const res = await OnlineApplicationAPI.postOnlineApplication(credentials);
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data as IOnlineApplicationResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const getFileUploadUrlsThunk = createAsyncThunk<
    IUploadUrlsResponse,
    IOnlineApplicationUploadUrlsQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@online-application/upload-urls/get',
    async (query, thunkAPI) => {
        try {
            const res = await OnlineApplicationAPI.getFileUploadUrls(query);
            // if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data as IUploadUrlsResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const uploadFilesThunk = createAsyncThunk<
    any,
    { data: IUploadUrlsResponse, photo?: File | null, resume?: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@online-application/upload-files/post',
    async (payload, thunkAPI) => {
        try {
            if (payload.photo && payload.resume && payload.data?.profile_pic_upload_url && payload.data?.resume_upload_url) {
                const photoRes = await OnlineApplicationAPI.uploadPhotoAndResume(payload.data?.profile_pic_upload_url?.url, payload.photo);
                const resumeRes = await OnlineApplicationAPI.uploadPhotoAndResume(payload.data?.resume_upload_url?.url, payload.resume);
                return photoRes.data || resumeRes.data;
            }
            if (payload.photo && payload.resume === null && payload.data?.profile_pic_upload_url) {
                const photoRes = await OnlineApplicationAPI.uploadPhotoAndResume(payload.data?.profile_pic_upload_url?.url, payload.photo);
                return photoRes.data;
            }
            if (payload.photo === null && payload.resume && payload.data?.resume_upload_url) {
                const resumeRes = await OnlineApplicationAPI.uploadPhotoAndResume(payload.data?.resume_upload_url?.url, payload.resume);
                return resumeRes.data;
            }
            return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);