import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { AgencyToggleStatus } from "../../../../interfaces/toggle_status";

export const updateAgencyToggleStatus = createAsyncThunk<
    string,
    AgencyToggleStatus,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/agency-management/agency_toggle-status/patch',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.agencyToggleStatus(payload);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);