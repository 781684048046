import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI } from "../../../../../apis";
import { IDepartment, AppError } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";



/// [Company] ---> Departments
export const getCompanyDepartments = createAsyncThunk<
    Array<IDepartment>,
    { companyId: string, departmentId?: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/details/dashboard/departments/get',
    async (query, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanyDepartments(query.departmentId ?? query.companyId);
            if (typeof res.data === 'string') return [];
            return res.data as IDepartment[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
