import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { AppError } from "../../../../interfaces";
import { IRCWCcode, IReportCenterBOPayload } from "../../../../interfaces/report_center";
import { catchRequestError } from "../../../../utils";

export const getRCWCcodeThunk = createAsyncThunk<
    IRCWCcode,
    IReportCenterBOPayload,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/payroll_tax/work_comp_code/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getRCWCcodeData(payload);
            return res.data as IRCWCcode;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });