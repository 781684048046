import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import { AppRoutes } from "../../routes/routes";
import { getAdminCenterNavHeaderProps } from "../../routes/route_helpers";
import { GetCloseAction } from "../../utils";
import MasterTaxCredentialsPage from "./integration_partners/master_tax/pages/credentials/credentials_page";
import MasterTaxCompanySetupPage from "./integration_partners/master_tax/pages/company_setup/company_setup_page";
import InDevelopment from "../../others/in_development";
import DisbursementBankSetupPage from "./integration_partners/master_tax/pages/disbursement_bank_setup/disbursement_bank_setup";
import PayrollCashCareBankSetupPage from "./integration_partners/master_tax/pages/payroll_cash_care/payroll_cash_care_setup";
import CompanyTaxSetupPage from "./integration_partners/master_tax/pages/company_tax/company_tax_page";
import MasterTaxExportPage from "./integration_partners/master_tax/pages/export/export_page";


export const MasterTaxTabNav = (props: RouteComponentProps<any>) => {

    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();

    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                {
                    title: "credentials",
                    route: AppRoutes.masterTaxCredentials,
                },
                {
                    title: "company_setup",
                    route: AppRoutes.masterTaxCompanySetup,
                },
                {
                    title: "company_disbursement_setup",
                    route: AppRoutes.masterTaxCompanyDisbursement,
                },
                {
                    title: "payroll_cash_care_setup",
                    route: AppRoutes.masterTaxPayrollCCSetup,
                },
                {
                    title: "company_tax_setup",
                    route: AppRoutes.masterTaxCompanyTaxSetup,
                },
                {
                    title: "export",
                    route: AppRoutes.masterTaxCompanyExport,
                },

            ]}
            onChange={(value) =>
                history.push({ pathname: value, state: location.state })
            }
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.adminCenterIntegrations)} />}
        >
            <Switch>
                <Route
                    exact
                    path={AppRoutes.masterTaxCredentials}
                    component={MasterTaxCredentialsPage}
                />
                <Route
                    exact
                    path={AppRoutes.masterTaxCompanySetup}
                    component={MasterTaxCompanySetupPage}
                />
                <Route
                    exact
                    path={AppRoutes.masterTaxCompanyDisbursement}
                    component={DisbursementBankSetupPage}
                />
                <Route
                    exact
                    path={AppRoutes.masterTaxPayrollCCSetup}
                    component={PayrollCashCareBankSetupPage}
                />
                <Route
                    exact
                    path={AppRoutes.masterTaxCompanyTaxSetup}
                    component={CompanyTaxSetupPage}
                />
                <Route
                    exact
                    path={AppRoutes.masterTaxCompanyExport}
                    component={MasterTaxExportPage}
                />
                <Route
                    path={"/"}
                    render={({ location }) => (
                        <Redirect
                            to={{
                                pathname: `${location.pathname}/credentials`,
                                state: { ...(location.state as any) },
                            }}
                        />
                    )}
                />
            </Switch>
        </TabNavigation>
    );
};

export const MasterTaxNavigation = (props: RouteComponentProps<any>) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getAdminCenterNavHeaderProps(location, match, t);

    return (
        <PageNavHeader data={headerOptions} history={history} location={location}>
            <Switch>
                <Route path={"/"} component={MasterTaxTabNav} />
            </Switch>
        </PageNavHeader>
    );
};
