import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { IGrossProfit } from "../../../../../interfaces/report_center";
import { selectPayrollCashReqState, getGrossProfitThunk, selectGrossProfitState, getReportStartEndDate, selectReportsDownloadState, selectReportStartEndDateState, getReportDownloadURL } from "../../../../../redux/report_center";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { convertDateToTimeStamp, getDateFromTimeStamp } from "../../../../../variables";
import { grossProfitSliceActions } from "../../../../../redux/report_center/finance_accounting/gross_profit/gross_profit_reducer";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import DatePicker from "react-datepicker";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import { LoadingType, THEME } from "../../../../../enums";
import { currencyConversion, CustomButton, formatNumber, SpinnerScreen } from "../../../../../utils";
import './gross_profit_report.scss';
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import ApiError from "../../../../../components/api_error";
import UrlBuilder from "../../../../../apis/url_builder";

interface Props extends RouteComponentProps<any> { }
const GrossProfitReportPage: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const grossProfitState = useAppSelector((state) => selectGrossProfitState(state));

    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>();
    const [grossProfitList, setGrossProfitList] = useState<IGrossProfit | null>(null)

    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);
    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);
    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0)) {
            getGrossProfitData();
            setDatePickerOpen(false);
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getGrossProfitData();
            setDatePickerOpen(true);
        }
    }, [formState?.available_from, formState?.available_to]);
    const getGrossProfitData = () => {
        if (formState)
            dispatch(getGrossProfitThunk({ start_date: formState.available_from, end_date: formState.available_to }));
    }
    useEffect(() => {
        if (grossProfitState?.error && grossProfitState.error?.message) {
            setGrossProfitList({} as IGrossProfit);
        }
    }, [grossProfitState?.error?.message]);
    useEffect(() => {
        if (grossProfitState.loading === LoadingType.succeeded && grossProfitState.response) {
            setGrossProfitList(grossProfitState.response);
        }
    }, [grossProfitState.loading, grossProfitState.response]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
        dispatch(grossProfitSliceActions.clearState());
    };
    const handleClearError = () => {
        dispatch(grossProfitSliceActions.clearErrorState())
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.grossProfitReport,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    download: true
                }));
        }
    }

    const sales = grossProfitList?.sales;
    const contractStaffing = grossProfitList?.contract_staffing ?? {};
    const cogs = grossProfitList?.cost_of_good_sold;
    const fees = grossProfitList?.fees;
    const contractStaffGP = grossProfitList?.contract_staffing_gross_profit ?? {};
    const totalGrossProfit = grossProfitList?.total_gross_profit ?? {};

    const contractStaffingCurrencyKeys = ['average_hourly_pay_rate', 'average_hourly_bill_rate', 'average_gross_profit_per_hour']


    return (
        <div className="gp-container">
            <div className="header-container">
                <div className="table-header">
                    <span>{t("gross_profit_report")}</span>
                </div>
                <div>
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="gp-header">
                <div className="gp-header-left">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                            />
                        </div>
                    </div>
                    <div className="download-btn">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={(Object.keys(grossProfitList?.sales.sales_types ?? {}).length > 0)
                                && (Object.keys(grossProfitList?.contract_staffing ?? {}).length > 0)}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    {grossProfitState?.error && <div className="error-holder">
                        <ApiError message={grossProfitState?.error?.message} onClose={handleClearError} />
                    </div>}
                </div>
            </div>
            <div className="gp-content">
                {grossProfitState.loading === LoadingType.pending ?
                    <SpinnerScreen />
                    :
                    <div className="gp-div-row">
                        <div className="gp-col">
                            <div className="gp-div">
                                <div className="gp-div-title">{t('Sales')}</div>
                                <div className="emp-earn">
                                    <table>
                                        <thead>
                                            <th>{t("")}</th>
                                            <th className="second-col">{t("amount_billed")}</th>
                                            <th>{t("percent_sales")}</th>

                                        </thead>
                                        <tbody>
                                            {sales?.sales_types?.map((sale) => {
                                                return (
                                                    <tr>
                                                        <td>{sale.sales_name}:</td>
                                                        <td className="second-col">{currencyConversion(sale.sales_amount)}</td>
                                                        <td>{sale.sales_percentage}%</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr className="total-row">
                                                <td>{t("total_billed")}</td>
                                                <td className="second-col">{sales?.total_billed ? currencyConversion(sales?.total_billed) : "$0.00"}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="gp-div">
                                <div className="gp-div-title">{t('contract_staffing_by_num')}</div>
                                <div className="emp-earn">
                                    <table>

                                        <tbody>
                                            {Object.keys(contractStaffing).map(key => (
                                                <tr key={key}>
                                                    <td>{t(key)}:</td>
                                                    <td>{contractStaffingCurrencyKeys.includes(key) ? currencyConversion(contractStaffing[key]) : formatNumber(contractStaffing[key])}</td>
                                                </tr>
                                            ))}
                                            <tr></tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div className="gp-col">
                            <div className="gp-div">
                                <div className="gp-div-title">{t('cogs')}</div>
                                <div className="emp-earn">
                                    <table>

                                        <tbody>
                                            {Object.keys(cogs?.cogs_types ?? {}).map(key => (
                                                <tr key={key}>
                                                    <td>{t(key)}:</td>
                                                    <td>{currencyConversion(cogs?.cogs_types[key])}</td>
                                                </tr>
                                            ))}
                                            <tr className="total-row">
                                                <td>{t("total_cogs")}</td>
                                                <td>{cogs?.total_cogs ? currencyConversion(cogs?.total_cogs) : "$0.00"}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="gp-div">
                                <div className="gp-div-title">{t('contract_staffing_gross_profit')}</div>
                                <div className="emp-earn">
                                    <table>

                                        <tbody>
                                            {Object.keys(contractStaffGP).map(key => (
                                                <tr key={key}>
                                                    <td>{t(key)}:</td>
                                                    <td>{key === "gross_profit" ? currencyConversion(contractStaffGP[key]) : formatNumber(contractStaffGP[key]).toString() + "%"}</td>
                                                </tr>
                                            ))}
                                            <tr></tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div className="gp-col">
                            <div className="gp-div">
                                <div className="gp-div-title">{t('Fees')}</div>
                                <div className="fees-div">
                                    <table>
                                        <thead>
                                            <th></th>
                                            <th>{t('amount_billed')}</th>

                                        </thead>
                                        <tbody>
                                            {Object.keys(fees?.fees_types ?? {}).length === 0 ? (
                                                <tr>
                                                    <td colSpan={2}></td>
                                                </tr>
                                            ) : (
                                                Object.keys(fees?.fees_types ?? {}).map(key => (
                                                    <tr key={key}>
                                                        <td>{t(key)}:</td>
                                                        <td>{currencyConversion(fees?.fees_types[key])}</td>
                                                    </tr>
                                                ))
                                            )}
                                            <tr className="total-row">
                                                <td>{t("total_fees")}</td>
                                                <td>{fees?.total_fees ? currencyConversion(fees?.total_fees) : "$0.00"}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="gp-div">
                                <div className="gp-div-title">{t('total_gross_profit')}</div>
                                <div className="emp-earn">
                                    <table>

                                        <tbody>
                                            {Object.keys(totalGrossProfit).map(key => (
                                                <tr key={key}>
                                                    <td>{t(key)}:</td>
                                                    <td>{key === "gross_profit" ? currencyConversion(totalGrossProfit[key]) : formatNumber(totalGrossProfit[key]).toString() + "%"}</td>
                                                </tr>
                                            ))}
                                            <tr></tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default GrossProfitReportPage;