import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, IWorkCompClaim, IWorkCompClaimTalentData } from "../../../../interfaces";
import * as thunk from "./work_comp_claims_list_thunk";

export interface WorkCompClaimsListState extends EntityState<IWorkCompClaim> {
    error?: AppError | null,
    loading: LoadingType,
    saveResponse: {
        error?: AppError | null,
        loading: LoadingType,
        response: any
    },

    documentUpload: {
        error?: AppError | null,
        loading: LoadingType,
        response: any
    },
    details: {
        error?: AppError | null,
        loading: LoadingType,
        data: IWorkCompClaimTalentData
    },
    fileDownloadResponse:{
        error?:AppError | null,
        loading: LoadingType,
        data: any
    }
}

const workCompClaimsListAdapter: EntityAdapter<IWorkCompClaim> = createEntityAdapter<IWorkCompClaim>({
    selectId: (doc) => doc.id,
})

const initialState: WorkCompClaimsListState = workCompClaimsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    saveResponse: {
        error: null,
        loading: LoadingType.idle,
        response: "",
    },
    documentUpload: {
        error: null,
        loading: LoadingType.idle,
        response: []
    },
    details: {
        error: null,
        loading: LoadingType.idle,
        data: {} as IWorkCompClaimTalentData
    },
    fileDownloadResponse:{
        error: null,
        loading: LoadingType.idle,
        data: null
    }
})


export const workCompClaimsListSlice = createSlice({
    name: 'workCompClaimsList',
    initialState: initialState,
    reducers: {
        clearWorkCompClaimsListError(state) {
            return { ...state, error: null };
        },
        clearWorkCompClaimsListState(state) {
            return workCompClaimsListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
        clearWorkCompClaimSaveState(state) {
            return {
                ...state, saveResponse: {
                    error: null,
                    loading: LoadingType.idle,
                    response: "",
                }
            }
        },
        clearWorkCompClaimDocState(state) {
            return {
                ...state, documentUpload: {
                    error: null,
                    loading: LoadingType.idle,
                    response: [],
                }
            }
        },
        clearFileDownloadState(state){
            return {
                ...state, fileDownloadResponse: {
                    error: null,
                    loading: LoadingType.idle,
                    data: null,
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAllWorkCompClaimsList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getAllWorkCompClaimsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAllWorkCompClaimsList.fulfilled, (state, action) => workCompClaimsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.addWorkCompClaimDetails.pending, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.addWorkCompClaimDetails.rejected, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.addWorkCompClaimDetails.fulfilled, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateWorkCompClaimDetails.pending, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.updateWorkCompClaimDetails.rejected, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.updateWorkCompClaimDetails.fulfilled, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getWorkCompClaimDocs.pending, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getWorkCompClaimDocs.rejected, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.getWorkCompClaimDocs.fulfilled, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getWorkCompClaimDetailsByTalentId.pending, (state, action) => ({ ...state, details: { ...state.details, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getWorkCompClaimDetailsByTalentId.rejected, (state, action) => ({ ...state, details: { ...state.details, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.getWorkCompClaimDetailsByTalentId.fulfilled, (state, action) => ({ ...state, details: { ...state.details, loading: LoadingType.succeeded, error: null, data: action.payload } }))

            .addCase(thunk.getFileDownloadURL.pending, (state, action) => ({ ...state, fileDownloadResponse: { ...state.fileDownloadResponse, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getFileDownloadURL.rejected, (state, action) => ({ ...state, fileDownloadResponse: { ...state.fileDownloadResponse, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.getFileDownloadURL.fulfilled, (state, action) => ({ ...state, fileDownloadResponse: { ...state.fileDownloadResponse, loading: LoadingType.succeeded, error: null, data: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const workCompClaimsListActions = workCompClaimsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = workCompClaimsListAdapter.getSelectors();
export const selectState = (state: WorkCompClaimsListState) => state;
export const selectLoading = (state: WorkCompClaimsListState) => state.loading;
export const selectError = (state: WorkCompClaimsListState) => state.error;
export const selectWorkCompClaimSave = (state: WorkCompClaimsListState) => state.saveResponse;
export const selectWorkCompClaimDocUpload = (state: WorkCompClaimsListState) => state.documentUpload;
export const selectDetailsState = (state: WorkCompClaimsListState) => state.details;
export const selectFileDownloadResponse=(state:WorkCompClaimsListState)=>state.fileDownloadResponse;


