import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectAll, selectEntities, selectState } from "./payroll_tax_configuration_reducer";

const select = (state: IRootState) => state.adminCenter.payRollTaxConfigList;
export const selectPayRollTaxConfigListState = createSelector(select, selectState);
export const selectPayRollTaxConfigList = createSelector(
    select,
    selectAll
);
export const selectPayRollTaxConfigListEntities = createSelector(
    select,
    selectEntities
);