
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./deduction_type_reducer";

const select = (state: IRootState) => state.backOffice.thirdPartyDeduction.ThirdPartyDeductionTypeList;
export const selectThirdPartyDeductionTypeListState = createSelector(select, selectState);
export const selectThirdPartyDeductionTypeList = createSelector(
    select,
    selectAll
);
export const selectThirdPartyDeductionTypeListEntities = createSelector(
    select,
    selectEntities
);