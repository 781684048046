import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { THEME, LoadingType } from "../../../../enums";
import { INFO_TEXT_CATEGORY } from "../../../../enums/info_text_category";
import { AgencySetup, BulbIcon } from "../../../../icons";
import { selectInfoTextState, getAdminCenterInfoText } from "../../../../redux/admin_center";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CustomButton } from "../../../../utils";
import { clearAdminCenterInfoTextError } from "../../../../redux/admin_center/info_text/info_text_reducer";


interface Props {
    visible: boolean;
    onClose: () => void;
    onDismiss: () => void;
}

const SalesTaxInfoTextPopUp: React.FunctionComponent<Props> = (props) => {
    const { onClose, onDismiss, visible } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const infoTextState = useAppSelector((state) => selectInfoTextState(state));

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        dispatch(getAdminCenterInfoText(INFO_TEXT_CATEGORY.SALES_TAX_CONFIGURATION));
    }, [])

    const handleClearError = () => {
        dispatch(clearAdminCenterInfoTextError())
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'30%'}
            maxWrapperWidth={'60%'}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="ac-info-text-popup-container">
                    <div className="ac-info-text-popup-header">
                        <FormDialogTilteHeader
                            title={t("overview_of_sales_tax_configuration")}
                            titleIcon={<BulbIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="ac-info-text-popup-content">
                        <div className="info-text">
                            <p>Sales Tax Configuration in KinISO is crucial for staffing agencies operating across diverse regions with varying tax regulations. It enables efficient management of sales tax obligations, ensuring accurate invoicing and compliance.</p>
                            <b>Key Features:</b>
                            <p><b>Tax Rate Management:</b> Input and manage region-specific tax rates to automate accurate sales tax calculations, reducing errors.</p>
                            <p><b>Geographic Jurisdiction:</b> Define tax jurisdictions by area, accommodating varying tax rates and rules seamlessly.</p>
                            <p><b>Exemptions and Rules:</b> Customize exemptions and rules to align with regulatory requirements for certain customers or services.</p>
                            <p><b>Real-Time Updates:</b> Stay compliant with dynamic tax laws through real-time rate and regulation updates.</p>
                            <p><b>User-Friendly Interface:</b> KinISO offers an intuitive interface for easy configuration, even for new super users. Adjust tax settings, add rates, and update them effortlessly.</p>
                            <p><b>Integration and Automation:</b> Seamless integration with QuickBooks Online streamlines tax calculations and financial management.</p>
                            <p><b>Compliance and Reporting:</b> Generate comprehensive reports for tax compliance audits and financial analysis. Simplify tax return preparation, reducing administrative burdens.</p>
                            <p>With KinISO, automate tax calculations, ensure compliance, and maintain financial accuracy, allowing you to focus on growing your agency efficiently and compliantly. Welcome to a smarter way of handling sales tax with KinISO!</p>
                        </div>
                        {infoTextState.update?.error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={infoTextState.update?.error?.message}
                                    onClose={handleClearError}
                                />
                            </div>
                        )}
                    </div>
                    <div className="ac-info-text-popup-actions">
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('ok')}
                                loading={infoTextState.update?.loading === LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )
}

export default SalesTaxInfoTextPopUp