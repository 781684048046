import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CompaniesIcon } from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton, CustomCheckBox, currencyConversion } from "../../../../utils";
import './add_new_wc_code_popup.scss';
import { getWorkCompConfigList, selectWorkCompConfigList, selectWorkCompConfigListState } from "../../../../redux/admin_center";
import { WorkCompConfiguration } from "../../../../interfaces/work_comp_configuration";
import TableEmpty, { TableErrorHandler, TableFilterEmpty, TableLoading } from "../../../../components/table_empty/table_empty";
import SearchBar from "../../../../components/search_bar";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";

interface Props {
    visible: boolean,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    handleSave: (doc: { wc_code_id: string; }[]) => void,
}
const tableHeader = [
    { title: "", code: "" },
    { title: "work_comp_class_code", code: "work_comp_code" },
    { title: "base_rate", code: "base_rate" },
    { title: "description", code: "description" },
];
const AddNewWCCodePopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        handleSave
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const WorkCompConfigListState = useAppSelector((state) => selectWorkCompConfigListState(state))
    const WorkCompConfigList = useAppSelector((state) => selectWorkCompConfigList(state))
    useEffect(() => {
        getWCCodes();
        return () => {
        }
    }, []);
    function getWCCodes() {
        dispatch(getWorkCompConfigList());
    }
    // const [wcCodesList, setWCCodesList] = useState<{ id: string } | null>(null);
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());

    // const handleSave = () => {
    //     const WCCodesList = Array.from(selectedItems).map(id => ({ wc_code_id: id }));;

    // }
    function getFilteredList(): WorkCompConfiguration[] {
        let sortedList: WorkCompConfiguration[] | undefined;
        if (sortedField != null) {
            sortedList = [...WorkCompConfigList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (sortedList ?? WorkCompConfigList).filter(doc => {
            const str = search.trim().toLowerCase();
            const workCompClassCode = doc.work_comp_code ? doc.work_comp_code.trim().toLowerCase().includes(str) : false;
            const baseRate = doc.base_rate ? doc.base_rate.toString().trim().toLowerCase().includes(str) : false;
            const description = doc.code_description ? doc.code_description.trim().toLowerCase().includes(str) : false;

            return workCompClassCode || baseRate || description
        });
    }

    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            // setSortedField(null);
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    }
    if (WorkCompConfigListState.loading === LoadingType.pending) {
        return (
            <div className="search-wcc-holder">
                <TableLoading />
            </div>
        );
    }
    if (WorkCompConfigListState.error != null && WorkCompConfigList.length === 0) {
        return (
            <div className="search-wcc-holder">
                <TableErrorHandler error={WorkCompConfigListState.error} onRefresh={getWCCodes} />
            </div>
        );
    }
    // if (WorkCompConfigList.length === 0) {
    //     return (
    //         <div className="search-wcc-holder">
    //             {/* <TableEmpty title={t('no_talent_found')} onClick={() => getWCCodes()} /> */}
    //         </div>
    //     );
    // }

    function handleRowClick(doc: WorkCompConfiguration) {
        const newSet = new Set(selectedItems);
        if (selectedItems.has(doc.id)) {
            newSet.delete(doc.id);
        } else {
            newSet.add(doc.id);
        }
        setSelectedItems(newSet);
    }
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="add-wcc-container">
                    <div className="add-wcc-header">
                        <FormDialogTilteHeader
                            title={t("new_work_comp_code")}
                            titleIcon={<CompaniesIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="add-wcc-content">
                        {getFilteredList().length !== 0 ?
                            <div className="search-wcc-holder">
                                <div className="srch-wcc-table-search-bar">
                                    <SearchBar value={search} onChange={(value) => setSearch(value)} onSearch={() => { }} />
                                </div>
                                <div className="search-table-holder">
                                    <SortableTable
                                        headerList={tableHeader}
                                        sortedField={sortedField}
                                        onSortChange={handleSortFieldChange}
                                        flexNumber={getFlexNumber}
                                        isAsc={sortDirection}
                                    >
                                        {getFilteredList().map((doc, index) => {
                                            return (
                                                <tr key={doc.id} onClick={() => handleRowClick(doc)}>
                                                    <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                        <CustomCheckBox
                                                            name={""}
                                                            title={""}
                                                            checked={selectedItems.has(doc.id)}
                                                            onClick={() => handleRowClick(doc)}
                                                        />
                                                    </TableData>
                                                    <TableData customStyle={{ flex: getFlexNumber(1) }}>{doc.work_comp_code}</TableData>
                                                    <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.base_rate
                                                        ? currencyConversion(doc.base_rate)
                                                        : "$0.00"}</span></TableData>
                                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>{doc.code_description}</TableData>
                                                </tr>
                                            );
                                        })}
                                    </SortableTable>
                                </div>
                            </div>
                            :
                            <div className="search-wcc-holder">
                                <TableFilterEmpty title={t('no_work_comp_codes_available')} />
                            </div>
                        }
                    </div>
                    <div className="add-wcc-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={onClose}
                            />
                        </div>

                        <div className="btn-save">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('save')}
                                enable={selectedItems.size > 0}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => handleSave(Array.from(selectedItems).map(id => ({ wc_code_id: id })))}
                            />
                        </div>
                    </div>
                </div>

            </DialogWrapper>
        </ActionDialogHolder>
    );
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
    function getFlexNumber(value: number) {
        if (value === 0) return 0.3;
        if (value === 1) return 1.5;
        if (value === 2) return 1;
        if (value === 3) return 1.5;
        if (value === 4) return 1;
        if (value === 5) return 1;
        return 1;
    }
}

export default AddNewWCCodePopup;