import { createSelector } from "@reduxjs/toolkit";
import { selectAll, selectState } from "./friday_calls_reports_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.reportCenter.reportCenterService.fridayCallsReportsList;
export const selectFridayCallsReportsListState = createSelector(select, selectState);
export const selectFridayCallsReportsList = createSelector(
    select,
    selectAll
);
