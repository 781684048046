import "./curve_tab_navigation.scss";

import React, { useEffect, useRef, useState } from "react";

import { ActiveLeft, ActiveRight, InactiveRight } from "../../icons";

interface Props {
  selected: number;
  tabs: Array<{
    title: string;
    icon?: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    content: React.ReactNode;
  }>;
  actions?: React.ReactNode;
  onChange: (value: number) => void;
  customStyle?: React.CSSProperties;
}

const CurveTabNavigation: React.FunctionComponent<Props> = (props) => {
  const ref = useRef(null);
  const [tabWidth, setTabWidth] = useState<number>(180);

  useEffect(() => {
    if (ref.current) {
      const totalAvailableWidth =
        (ref.current as HTMLElement).offsetWidth - 180;
      setTabWidth(totalAvailableWidth / props.tabs.length / 1.5);
    }
    return () => {};
  }, [ref.current]);

  return (
    <div
      ref={ref}
      className="curve-tab-navigation-container"
      style={props.customStyle}
    >
      <div className="curve-tab-header">
        <div className="curve-tab-options-container">
          {props &&
            props.tabs &&
            props.tabs.map((doc, index) => {
              const active = props.selected === index;
              return (
                <button
                  key={index}
                  className={`curve-tab-option ${active ? "tab-active" : ""}`}
                  style={{
                    width: "fit-content",
                    maxWidth: active ? "fit-content" : tabWidth,
                    ...(active === false && {
                      borderTop:
                        active === false
                          ? "1px solid #0353a0"
                          : "1px solid transparent",
                    }),
                    ...(index === 0 && {
                      borderTopLeftRadius: 4,
                      borderLeft:
                        active === false
                          ? "1px solid #0353a0"
                          : "1px solid transparent",
                    }),
                  }}
                  onClick={() => props.onChange(index)}
                >
                  {active && index !== 0 && (
                    <ActiveLeft className="tab-curve left"></ActiveLeft>
                  )}
                  <div className="tab-option">
                      {
                        doc.icon && <div className="tab-icon">
                          <doc.icon
                          width={"100%"}
                          height={"100%"}
                          style={{ color: active ? "#fff" : "#0353a0" }}
                        />
                        </div>
                      }
                    <div className="tab-title">
                      <span
                        style={{
                          fontWeight: active ? 800 : "normal",
                          width: active ? "fit-content" : (tabWidth / 4) * 2,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {doc.title}
                      </span>
                    </div>
                  </div>
                  {active && (
                    <ActiveRight
                      className="tab-curve right"
                      style={{
                        zIndex: 2,
                      }}
                    ></ActiveRight>
                  )}
                  {active === false && (
                    <InactiveRight className="tab-curve inactive"></InactiveRight>
                  )}
                </button>
              );
            })}
        </div>
        {props.actions ?? <div></div>}
      </div>
      <div className="curve-tab-content" style={{width: '100%', height: '100%'}}>
        {props.tabs[props.selected] ? props.tabs[props.selected].content : null}
      </div>
    </div>
  );
};
export default CurveTabNavigation;
