import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ITimeCardsData } from "../../../../interfaces";
import * as thunk from "./payroll_timecards_thunk";

export interface PayrollTimecardsState {
  error?: AppError | null;
  loading: LoadingType;
  response: ITimeCardsData[] | null;
}

const initialState: PayrollTimecardsState = {
  error: null,
  loading: LoadingType.idle,
  response: null,
};

export const PayrollTimecardsSlice = createSlice({
  name: "payrollTimecards",
  initialState: initialState,
  reducers: {
    clearState(_state) {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getPayrollTimecardsThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getPayrollTimecardsThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.getPayrollTimecardsThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        response: action.payload,
      }))
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const PayrollTimecardsSliceActions = PayrollTimecardsSlice.actions;
export const selectState = (state: PayrollTimecardsState) => state;
export const selectAll = (state: PayrollTimecardsState) => state.response;
