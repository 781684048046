import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType } from "../../../../enums";

import { ISendOnBoardingLink } from "../../../../interfaces/onboarding_link";
import { sendOnBoardingLinksStateActions } from "../../../../redux/dashboard/metrics/send_onboarding_link/send_onboarding_link_reducer";
import {
  useAppDispatch,
  useAppSelector,
  selectDashboardSendOnBoardingLinksState,
  postSnackbarMessage,
  selectProfileState,
  sendOnBoardingLinkThunk,
} from "../../../../redux/store";
import SendOnBoardingLinkForm from "../../forms/send_onboarding_link_form/send_onboarding_link_form";
import "./send_onboarding_link.scss";

interface Props {
  visible: boolean;
  onClose: () => void;
  onDissmiss?: () => void;
  onSuccessClose: () => void;
  talentId?: string;
  setDisable?: boolean;
}

const SendOnBoardingLinkPopup: React.FunctionComponent<Props> = (props) => {
  const { visible, onClose, onDissmiss, onSuccessClose, talentId, setDisable } =
    props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const actionRef = useRef<ActionDialogHolderType>(null);
  const profileState = useAppSelector((state) => selectProfileState(state));
  const onBoardingState = useAppSelector((state) =>
    selectDashboardSendOnBoardingLinksState(state)
  );
  function handleWrapperClose(action?: () => void) {
    if (onBoardingState.loading === LoadingType.succeeded) {
      // dispatch(postSnackbarMessage(createState.response ?? null));
      closePopup(props.onSuccessClose);
    } else {
      if (action) closePopup(action);
    }
  }
  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={"40%"}
      onClose={onClose}
      onDissmiss={onDissmiss}
    >
      <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
        {getUI()}
      </DialogWrapper>
    </ActionDialogHolder>
  );
  function getUI() {
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${
      profileState.profile?.last_name ?? ""
    }`;
    return (
      <div className="mark-tlnt-pop-holder">
        <SendOnBoardingLinkForm
          title={t("hi_lets_do_some_onboarding", {
            name: currentLoginUserName,
          })}
          currentLoginUserName={currentLoginUserName}
          error={onBoardingState.error}
          loading={onBoardingState.loading}
          onCancel={() => closePopup(onClose)}
          onClearError={handleClearError}
          onSubmit={handleOnSubmit}
          onSuccessClose={() => closePopup(props.onSuccessClose)}
          talentId={talentId}
          setDisable={setDisable}
        />
      </div>
    );
  }

  function handleOnSubmit(doc: {
    forceResend: boolean;
    value: ISendOnBoardingLink;
  }) {
    dispatch(sendOnBoardingLinkThunk(doc));
  }

  function handleClearError() {
    dispatch(sendOnBoardingLinksStateActions.clearSendOnBoardingLinksError());
  }

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    handleClearError();
  }
};

export default SendOnBoardingLinkPopup;
