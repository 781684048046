import Constants from "./constants";


const preferences = {

    //push notifications
    setFirebaseNotificationToken(token: string) {
        return localStorage.setItem(Constants.firebasePNToken, token);
    },

    getFirebaseNotificationToken() {
        return localStorage.getItem(Constants.firebasePNToken);
    },

    removeFirebaseNotificationToken() {
        return localStorage.removeItem(Constants.firebasePNToken);
    },

    //id_token
    getAuthToken() {
        return localStorage.getItem(Constants.authToken);
    },

    setAuthToken(token: string) {
        return localStorage.setItem(Constants.authToken, token);
    },

    removeAuthToken() {
        return localStorage.removeItem(Constants.authToken);
    },

    /// Server url
    getServerUrl() {
        return localStorage.getItem(Constants.serverUrl);
    },

    setServerUrl(url: string) {
        return localStorage.setItem(Constants.serverUrl, url);
    },

    removeServerUrl() {
        return localStorage.removeItem(Constants.serverUrl);
    },


    /// Left nav bar
    setNavExpandState(value: boolean){
        return localStorage.setItem(Constants.leftNavbar, JSON.stringify(value));
    },

    getNavExpandState(){
        return localStorage.getItem(Constants.leftNavbar);
    },

    removeNavExpandState(){
        return localStorage.removeItem(Constants.leftNavbar);
    }



}

export default preferences;