import { createAsyncThunk } from "@reduxjs/toolkit";
import { RiskManagementAPI } from "../../../../../apis";
import { IWorkCompClaimTalentData, AppError, ITalentWorkCompClaimTalentData } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";


export const getTalentWorkCompClaimDetailsByTalentId = createAsyncThunk<
    ITalentWorkCompClaimTalentData,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/risk-management/universal-directory/work-comp-claims/talentId',
    async (id, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getWorkCompClaimsByTalentId(id);
            if (typeof res.data === 'string') return {};
            return res.data as ITalentWorkCompClaimTalentData;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);