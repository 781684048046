import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import * as thunk from "./direct_deposit_thunk";

export interface EmpDirectDepositState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }
    submit: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }

}

const initialState: EmpDirectDepositState = {
    create: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    submit: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}


export const empDirectDepositSlice = createSlice({
    name: 'empDirectDeposit',
    initialState: initialState,
    reducers: {
        clearState(state){
            return {...state, ...initialState};
        },
        clearError(state) {
            return {
                ...state,
                create: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },
                update: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },
                submit: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },

            };
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(thunk.addEmpDirectDepositThunk.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
        .addCase(thunk.addEmpDirectDepositThunk.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, response: null, } }))
        .addCase(thunk.addEmpDirectDepositThunk.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))
        
        .addCase(thunk.validateEmpDirectDepositAcntThunk.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
        .addCase(thunk.validateEmpDirectDepositAcntThunk.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, response: null, } }))
        .addCase(thunk.validateEmpDirectDepositAcntThunk.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))
        
        .addDefaultCase(state => ({ ...state }));

    }
});

export const empDirectDepositSliceActions = empDirectDepositSlice.actions;
export const selectState = (state: EmpDirectDepositState) => state;


