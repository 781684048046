import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import {AppError} from "../../../../../interfaces/app_error";
import { ContactListItem  } from "../../../../../entities/ContactInfo";
import * as thunk from './company_contact_thunk';


export interface SingleCompanyContactState {
    data: Array<ContactListItem> | null,
    error?: AppError | null,
    loading: LoadingType
}


export interface CompanyContactsState {
    [key: string]: SingleCompanyContactState,
}


const initialState: CompanyContactsState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

export const companyContactSlice = createSlice({
    name: 'contactList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getCompanyContacts.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...getLoadingState(state[action.meta.arg.department_id ?? action.meta.arg.company_id])
                    }
                })
            )
            .addCase(
                thunk.getCompanyContacts.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...getErrorState(state[action.meta.arg.department_id ?? action.meta.arg.company_id], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getCompanyContacts.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...getSuccessState(state[action.meta.arg.department_id ?? action.meta.arg.company_id], action.payload),
                    }
                })
            )
            
            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const {} = companyContactSlice.actions;
export const selectCompanyContactsState = (state: CompanyContactsState) => state;
export const selectSingleCompanyContactssState = (state: CompanyContactsState, id: string): SingleCompanyContactState => state[id];

export const selectInitialSingleCompanyContactsState = (): SingleCompanyContactState => ({
    data: null,
    error: null,
    loading: LoadingType.idle
});