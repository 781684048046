import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, ICertiUploadUrlResponse, ITalentCertifications } from "../../../../../interfaces";
import * as thunk from "./certifications_other_docs_thunk";


interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: ICertiUploadUrlResponse | null,
    upload: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
    upload: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
}
interface UpdateState {
    error?: AppError | null,
    loading: LoadingType,
    response: ICertiUploadUrlResponse | null,
    upload: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }
}

const initialUpdateState: UpdateState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
    upload: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
}
interface RemoveState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialRemoveState: RemoveState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}
export interface SingleTalentCertificationState {
    data: ITalentCertifications[] | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    update: UpdateState,
    delete: RemoveState,
}
export const selectSingleTalentCertificationInitialState = (): SingleTalentCertificationState => {
    return {
        loading: LoadingType.pending,
        error: null,
        data: null,
        add: initialAddState,
        update: initialUpdateState,
        delete: initialRemoveState
    }
}

export interface TalentCertificationsState {
    [key: string]: SingleTalentCertificationState,
}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}
const initialState: TalentCertificationsState = {}

export const talentCertificationsSlice = createSlice({
    name: 'talentCertificationOtherDocs',
    initialState: initialState,
    reducers: {
        clearTalentCertificationError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    loading: LoadingType.idle,
                    error: null,
                    data: null,
                    // add: initialAddState,
                    // update: initialUpdateState,
                    // delete: initialRemoveState 
                }
            }
        },
        clearTalentAddCertificationError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: { ...initialAddState },
                   
                }
            }
        },
        clearTalentUpdateCertificationError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    update: { ...initialUpdateState },
                }
            }
        },
        clearTalentRemoveCertificationError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    delete: { ...initialRemoveState },
                }
            }
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getTalentCertificationsThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...getLoadingState(state[action.meta.arg.talent_id]),
                    }
                })
            )
            .addCase(
                thunk.getTalentCertificationsThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...getErrorState(state[action.meta.arg.talent_id], action.payload),

                    }
                })
            )
            .addCase(
                thunk.getTalentCertificationsThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...getSuccessState(state[action.meta.arg.talent_id], action.payload),
                    }
                })
            )
            /// Add new talent in house interview
            .addCase(
                thunk.addNewTalentCertificationsThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        add: {
                            ...state[action.meta.arg.talent_id].add,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.addNewTalentCertificationsThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        add: {
                            ...state[action.meta.arg.talent_id].add,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.addNewTalentCertificationsThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        add: {
                            ...state[action.meta.arg.talent_id].add,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )
            /// Add new talent in house interview
            .addCase(
                thunk.updateTalentCertificationsThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        update: {
                            ...state[action.meta.arg.talent_id].update,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.updateTalentCertificationsThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        update: {
                            ...state[action.meta.arg.talent_id].update,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.updateTalentCertificationsThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        update: {
                            ...state[action.meta.arg.talent_id].update,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )
            /// deleting crime history record
            .addCase(
                thunk.deleteTalentCertificationsThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        delete: {
                            ...state[action.meta.arg.talent_id].delete,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.deleteTalentCertificationsThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        delete: {
                            ...state[action.meta.arg.talent_id].delete,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.deleteTalentCertificationsThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        delete: {
                            ...state[action.meta.arg.talent_id].delete,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )

            .addCase(thunk.uploadCertificationFileThunk.pending, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadCertificationFileThunk.rejected, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.uploadCertificationFileThunk.fulfilled, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));


    }

});


export const talentCertificationsActions = talentCertificationsSlice.actions;
export const selectState = (state: TalentCertificationsState) => state;
export const selectSingleState = (state: TalentCertificationsState, id: string) => state[id];
export const selectCertFileDownloadResponse = (state: TalentCertificationsState) => state.fileDownload;

