import { createAsyncThunk } from "@reduxjs/toolkit";
import { OnboardingPortalAPI } from "../../../apis";
import { AppError, IOnboardingVerifyTokenResponse } from "../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../utils";

export const onboardingVerifyTokenThunk = createAsyncThunk<
  IOnboardingVerifyTokenResponse,
  { token: string, refNumber?: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/verify-token/post",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.onboardingVerifyToken(payload.token, payload.refNumber);
      if (typeof res.data === "string") {
        return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      }
      return res.data as IOnboardingVerifyTokenResponse;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

