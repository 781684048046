import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./manage_application_sent_thunk";

export interface ManageApplicationSentState {

    update: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    },
}

const initialState: ManageApplicationSentState = {
    update: {
        error: null,
        loading: LoadingType.idle,
    },
}


export const manageApplicationSentSlice = createSlice({
    name: 'manageApplicationSent',
    initialState: initialState,
    reducers: {
        clearUpdateApplicationSentState(state) {
            state.update = { ...initialState.update };
        },
    },
    extraReducers: (builder) => {
        builder
           .addCase(thunk.updateApplicationSent.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateApplicationSent.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateApplicationSent.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageApplicationSentActions = manageApplicationSentSlice.actions;
export const selectState = (state: ManageApplicationSentState) => state;
export const selectUpdateState = (state: ManageApplicationSentState) => state.update;


