import './signature.scss';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper, { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { OnBoardingIcon } from '../../../../icons';
import { IBasicDialog, ITalentOnBoardingData, ITalentTermsAndConditions } from '../../../../interfaces';
import { CustomButton, isFormDirty, RegxPattern } from '../../../../utils';
import { convertDateToTimeStamp, getDateString } from '../../../../variables';
import RenderInput from '../../../../components/render_input/render_input';
import { LoadingType, THEME } from '../../../../enums';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import ApiError from '../../../../components/api_error';
import { selectEmployeeSignatureState } from '../../../../redux/onboarding_portal/employee_signature/employee_signature_selector';
import { employeeSignatureSliceActions } from '../../../../redux/onboarding_portal/employee_signature/employee_signature_reducer';
import { updateEmployeeSignatureThunk } from '../../../../redux/onboarding_portal/employee_signature/employee_signature_thunk';

interface Props extends IBasicDialog {
    type: string,
    token: string,
    state: ITalentOnBoardingData,
    termsAndConditions: any,
    onSuccessClose: (type: string, value?: ITalentTermsAndConditions) => void,
}

const Signature: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        type,
        token,
        state,
        title,
        termsAndConditions,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const update = useAppSelector((state) => selectEmployeeSignatureState(state));
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [formState, setFormState] = useState<ITalentTermsAndConditions>({
        talent_read_tc: true,
        talent_signature: state.terms_and_conditions.talent_signature ?? null,
        talent_signed_date: state.terms_and_conditions.talent_signed_date ?? convertDateToTimeStamp(new Date()),
        employee_signature: state.terms_and_conditions.employee_signature ?? null,
        employee_signed_date: state.terms_and_conditions.employee_signed_date ?? null,
        terms_and_conditions: state.terms_and_conditions.terms_and_conditions ?? termsAndConditions,
        url: state.terms_and_conditions.url ?? null,
    });

    useEffect(() => {
        if (update.loading === LoadingType.succeeded) {
            onSuccessClose(type, formState);
        }

        return () => {
            dispatch(employeeSignatureSliceActions.clearState());
        }

    }, [update.loading])

    function handleFieldChange(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setFormState({
                ...formState,
                [parent]: { ...formState[parent], [fieldId]: value },
            });
        } else {
            setFormState({ ...formState, [fieldId]: value });
        }
    }
    function handleClose() {
        closePopup(onClose);
    }

    const loading = update.loading === LoadingType.pending;

    const error = update.error;

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"70%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClose}>
                <div className="hnd-signature-container">
                    <div className="hnd-signature-header">
                        <FormDialogTilteHeader
                            title={title}
                            subTitle={t('please_read_and_sign_the_document')}
                            subTitleStyle={{ fontWeight: 'normal' }}
                            titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="hnd-signature-content">
                        <div className="terms-text">
                            <p>
                                {termsAndConditions}
                            </p>
                        </div>
                        <div className="hnd-signature-user-input">
                            <div className="hnd-signature-input-row">
                                <RenderInput
                                    doc={{
                                        field: "talent_signature",
                                        type: "text",
                                        label: "employee_signature",
                                        secure: false,
                                        required: false,
                                        placeholder: "",
                                        keyboardtype: "",
                                        uiType: "pdf",
                                    }}
                                    formState={formState}
                                    handleFieldChange={handleFieldChange}
                                    formValidators={formValidators}
                                    customStyle={{ width: "-webkit-fill-available" }}
                                />
                                <div className="date-input-holder">
                                    <div className="date-input">
                                        <span>{getDateString(formState.talent_signed_date, 'mm/dd/yyyy')}</span>
                                    </div>
                                    <div className="date-label">
                                        <span>{t("date")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="hnd-signature-input-row">
                                <RenderInput
                                    doc={{
                                        field: "employee_signature",
                                        type: "text",
                                        label: "dynamic_global_staffing_representative",
                                        secure: false,
                                        required: false,
                                        placeholder: "",
                                        keyboardtype: "",
                                        disabled: true,
                                        uiType: "pdf",
                                    }}
                                    formState={formState}
                                    handleFieldChange={handleFieldChange}
                                    formValidators={formValidators}
                                    customStyle={{ width: "-webkit-fill-available" }}
                                />
                                <div className="date-input-holder">
                                    <div className="date-input">
                                        <span>{getDateString(formState.talent_signed_date, 'mm/dd/yyyy')}</span>
                                    </div>
                                    <div className="date-label">
                                        <span>{t("date")}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={error.message}
                                    onClose={handleClearError}
                                />
                            </div>
                        )}
                    </div>
                    <div className="hnd-signature-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleClose}
                            />
                        </div>
                        <div className="btn-new">
                            <CustomButton
                                loading={loading}
                                textStyle={{
                                    textTransform: "capitalize",
                                }}
                                name={t("save")}
                                enable={_isFormValid()}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => loading ? undefined : handleSave()}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>

    );

    function _isFormValid() {
        const { talent_signature } = formState;
        if (talent_signature === "") return false;
        if (RegxPattern.username.test(talent_signature ?? "") === false) return false;
        return true;
    }


    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "talent_signature": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.username.test(value.text) === false) return t("validators.enterValidName");
                return null;
            }
            default: {
                return null;
            }
        }
    }


    ///Handle clear error
    function handleClearError() {
        dispatch(employeeSignatureSliceActions.clearState());
    }

    /// handle submit
    function handleSave() {
        const isDirty = isFormDirty(formState, state.terms_and_conditions);
        if (isDirty) {
            dispatch(updateEmployeeSignatureThunk({ ...state, terms_and_conditions: formState }));
        } else {
            handleClose();
        }
    }


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}



export default Signature;