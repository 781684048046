import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import {
    selectAll,
    selectEntities,
    selectState,
} from "./active_directory_list_reducer";

const select = (state: IRootState) => state.dashboard.onboarding.completedThisWeek.activeDirectoryList;
export const selectOCTWActiveDirectoryListState = createSelector(
    select,
    selectState
);
export const selectOCTWActiveDirectoryList = createSelector(
    select,
    selectAll
);
export const selectOCTWActiveDirectoryEntities = createSelector(
    select,
    selectEntities
);
