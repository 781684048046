import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { RouteComponentProps } from 'react-router-dom';
import StateChangeHandler from '../../../../components/state_change_handler/state_change_handler';
import CompanyDetailsSection from '../../../../components/company_details_section/company_details_section';
import CurveTabNavigation from '../../../../components/curve_tab_navigation/curve_tab_navigation';
import { COMPANY_TYPE, LoadingType } from '../../../../enums';
import { EditIcon, EngagementIcon, MagnifyGlassIcon, RatingIcon, StaffIcon } from '../../../../icons';
import { ICompany, IDepartment } from '../../../../interfaces';
import {
    getCompanyById,
    selectApolloCreditsState,
    selectApolloRefreshState,
    selectCompanyDetailsByIdState,
    selectSingleCompanyDepartmentsStateById,
} from '../../../../redux/store';
import { companyDetailsSliceActions } from '../../../../redux/companies/details/dashboard/company_details/company_details_reducer';
import { companyDepartmentSliceActions } from '../../../../redux/companies/details/dashboard/departments/departments_reducer';
import { manageCompanyActions } from '../../../../redux/companies/universal_directory/manage_company/manage_company_reducer';
import { manageDepartmentActions } from '../../../../redux/companies/universal_directory/manage_department/manage_department_reducer';
import { selectProfileState, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { AppRoutes } from '../../../../routes';
import { getUserNameFromProfileDropdown } from '../../../../utils';
import CreateCompanyPopup from '../../popup/create_company/create_company';
import CreateDepartmentPopup from '../../popup/create_department/create_department';
import { CreateOption } from '../../popup/create_option/create_option';
import AlphaOmegaStaffingRepresentatives from './alpha_omega_staffing_representatives/alpha_omega_staffing_representatives';
import './company_dashboard_page.scss';
import CompanySummery from './company_summery/company_summery';
import EngagementDetails from '../../../components/engagementDetails';
import RatingsTab from '../../../components/ratingsDetails';
import { RemoveOptionPopup } from '../../../components/remove_option/remove_option';


interface Props extends RouteComponentProps<any> { }
const CompanyDashboardPage: React.FunctionComponent<Props> = (props) => {
    const { history, location, match } = props;
    const { params } = match;
    const dispatch = useAppDispatch();
    const profile = useAppSelector((state) => selectProfileState(state));
    const companyState = useAppSelector((state) => selectCompanyDetailsByIdState(params.departmentId ?? params.id)(state));
    const companyDepartments = useAppSelector((state) => selectSingleCompanyDepartmentsStateById(params.departmentId ?? params.id)(state));
    const companyRefreshState = useAppSelector((state) => selectApolloRefreshState(state));
    const apolloCreditsState = useAppSelector((state) => selectApolloCreditsState(state));

    // const departmentState = useAppSelector((state) => selectCompanyDetailsDepartmentState(state));

    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [branchId, setBranchId] = useState<string | null>(null);
    const [editCompany, setEditCompany] = useState<{ visible: boolean, data: ICompany } | null>(null);
    const [editDepartment, setEditDepartment] = useState<{ visible: boolean, data?: IDepartment } | null>(null);
    const [addOption, setAddOption] = useState<{ visible: boolean, type: string } | null>(null);
    const [removeOption, setRemoveOption] = useState<{ visible: boolean, type: string, value: { id: string, value: string } } | null>(null);

    useEffect(() => {
        getCompanyDetails();
        // dispatch(apolloGetCreditsThunk());
        return () => { }
    }, [params.departmentId])

    useEffect(() => {
        if (companyState.loading === LoadingType.succeeded) {
            updateNavigationNames();
        }
        return () => { }
    }, [companyState.loading])

    useEffect(() => {
        if (params && params.departmentId && location && location.state) {
            if (location?.state!["departmentId"] === null || location?.state!["departmentId"] === undefined) {
                history.push({
                    pathname: `${AppRoutes.companiesDetailsPage}/${params.id}/dashboard`,
                    state: {
                        ...(location.state as any),
                        departmentId: null,
                        departmentName: null,
                    }
                });
            }
        }
    }, [params.departmentId, location.state])


    function updateNavigationNames() {
        if (params) {
            if (params.departmentId != null && companyState.data?.type === COMPANY_TYPE.department) {
                const department = (companyState.data as IDepartment);

                history.replace({
                    pathname: location.pathname,
                    state: {
                        ...(location.state as any),
                        departmentId: params.departmentId,
                        departmentName: department.name,
                    }
                });
            }
            if (params.id != null && companyState.data?.type === COMPANY_TYPE.company) {
                const company = (companyState.data as ICompany);
                history.replace({
                    pathname: location.pathname,
                    state: {
                        ...(location.state as any),
                        id: params.id,
                        name: company.name,
                        departmentId: null,
                        departmentName: null,
                    }
                });
            }

        }

    }

    function getCompanyDetails() {
        if (params) {
            if (params.departmentId != null) {
                setBranchId(params.departmentId);
                dispatch(getCompanyById({ companyId: params.id, departmentId: params.departmentId }));
                return;
            }
            if (params.id != null) {
                setBranchId(null);
                dispatch(getCompanyById({ companyId: params.id }));
            }

        }
    }

    function handleDepartmentChange(id: string, name?: string) {
        if (id != '') {
            history.push({
                pathname: `${AppRoutes.companiesDetailsPage}/${params.id}/dashboard/${id}`,
                state: {
                    ...(location.state as any),
                    departmentId: id,
                    departmentName: name,
                }
            });
        } else {
            history.push({
                pathname: `${AppRoutes.companiesDetailsPage}/${params.id}/dashboard`,
                state: {
                    ...(location.state as any),
                    departmentId: null,
                    departmentName: null,
                }
            });
        }

    }
    //<------**** Refresh company code******-------------> //
    // const [availCredits, setAvailCredits] = useState<IApolloCredits | null>();

    // useEffect(() => {
    //     if (apolloCreditsState && apolloCreditsState.loading === LoadingType.succeeded && apolloCreditsState.response) {
    //         setAvailCredits(apolloCreditsState.response)
    //     }
    // }, [apolloCreditsState, apolloCreditsState.loading]);
    // const [showRefreshPopup, setShowRefreshPopup] = useState<boolean>(false);

    // useEffect(() => {
    //     if (companyRefreshState && companyRefreshState.refreshCompany.loading === LoadingType.succeeded) {
    //         setShowRefreshPopup(false);
    //         dispatch(postSnackbarMessage(companyRefreshState.refreshCompany.response.msg ?? null));
    //         handleClearError();
    //         getCompanyDetails();
    //     }
    // }, [companyRefreshState, companyRefreshState.loading]);
    // useEffect(() => {
    //     if (apolloCreditsState && apolloCreditsState.addCredits && apolloCreditsState.addCredits.loading === LoadingType.succeeded && apolloCreditsState.addCredits.response) {
    //         // setSnackBarMessage(apolloCreditsState.addCredits.response);
    //         dispatch(postSnackbarMessage(apolloCreditsState.addCredits.response ?? null));
    //         setAddCredit(false);
    //         dispatch(apolloGetCreditsThunk());
    //         dispatch(apolloCreditsActions.clearApolloAddCreditsState());
    //     }
    // }, [apolloCreditsState.addCredits.loading]);
    // function handleOnRefreshCompany(need_direct_dial: boolean) {
    //     dispatch(refreshApolloCompany({ company_id: params.id, need_direct_dial: need_direct_dial }));
    // }
    // function handleClearError() {
    //     dispatch(apolloRefreshActions.clearApolloRefreshCompanyState());
    // };
    // const [addCredit, setAddCredit] = useState<boolean>(false);
    // const handleAddCredits = (count: Number | undefined) => {
    //     dispatch(apolloAddCreditsThunk({ count: count }));
    // }

    const handleClearRemoveOptionError = () => {
        dispatch(companyDetailsSliceActions.clearCompanyDetailsRemoveOptionError({id: params.id, type: removeOption?.type!}));
    };

    return (
        <StateChangeHandler
            loading={companyState.loading}
            error={companyState.error}
            onRefresh={getCompanyDetails}
        >
            <div className="cdp-container">
                <div className="cdp-left-panel">
                    <CompanyDetailsSection
                        isDepartment={params && params.departmentId != null}
                        branchId={branchId}
                        company={companyState.data}
                        departmentsState={companyDepartments}
                        onActionClick={handleActionPress}
                        onDepartmentChange={(value) => {
                            const doc = companyDepartments.entities[value];
                            handleDepartmentChange(value, (doc ? doc.name : value));
                        }}
                    // onShowRefreshPopup={setShowRefreshPopup}
                    // onAddCredit={setAddCredit}
                    // availCredits={availCredits?.credits_available}
                    />
                </div>
                <div className="cdp-right-panel">
                    <CurveTabNavigation
                        tabs={getTabList()}
                        selected={tabIndex}
                        onChange={(value) => setTabIndex(value)}
                        actions={getEditAction()}
                    />
                </div>
            </div>
            {editCompany && <Portal>
                <CreateCompanyPopup
                    visible={editCompany.visible}
                    company={editCompany.data}
                    title={t('edit_company')}
                    successTitle={t('edit_company')}
                    onClose={() => setEditCompany(null)}
                    onSuccessClose={handleUpdateCompanySuccess}
                    onGotoAgencyDashboard={() => { }}
                    onGotoCreated={() => { }}
                />
            </Portal>}

            {editDepartment && <Portal>
                <CreateDepartmentPopup
                    visible={editDepartment.visible}
                    department={editDepartment.data}
                    company_id={params.id}
                    title={editDepartment.data ? t('edit_department') : t('add_department')}
                    successTitle={editDepartment.data ? t('edit_department') : t('new_department')}
                    onClose={() => setEditDepartment(null)}
                    onSuccessClose={editDepartment.data ? handleUpdateDepartmentSuccess : handleCreateDepartmentSuccess}
                    onGotoAgencyDashboard={handleGotoAgencyDashboard}
                    onGotoCreated={handleOnGotoDepartment}
                />
            </Portal>}

            {addOption && <Portal>
                <CreateOption
                    data={{ company_id: params.id, department_id: params.departmentId }}
                    visible={addOption.visible}
                    type={addOption.type}
                    onClose={handleCloseAddDialog}
                    onDissmiss={handleCloseAddDialog}
                    onSuccessClose={handleOnSuccessAddDialog}
                />
            </Portal>}
            {removeOption && <Portal>
                <RemoveOptionPopup
                    id={params.id}
                    departmentId={params.departmentId}
                    state={companyState}
                    data={removeOption.value}
                    type={removeOption.type}
                    sourceType={"Company"}
                    visible={removeOption.visible}
                    onClose={handleCloseRemoveDialog}
                    onDissmiss={handleCloseRemoveDialog}
                    onSuccessClose={handleOnSuccessRemoveDialog}
                    onClearError={handleClearRemoveOptionError}
                />
            </Portal>}
            {/* {showRefreshPopup && (
                <Portal>
                    <ApolloRefreshPopup
                        visible={showRefreshPopup}
                        title={t('refresh_contact')}
                        paramId={params.id}
                        onClose={() => { setShowRefreshPopup(false); handleClearError() }}
                        onDissmiss={() => { setShowRefreshPopup(false); handleClearError() }}
                        onSuccessClose={handleOnRefreshCompany}
                        importedDirectDial={companyState?.data?.direct_dial}
                        name={`${companyState.data?.company_name ?? ''}`}
                        refreshState={companyRefreshState.refreshCompany}
                        onClearError={handleClearError}

                    />
                </Portal>
            )}
            {addCredit && (
                <Portal>
                    <AddCreditsPopup
                        visible={addCredit}
                        onClose={() => setAddCredit(false)}
                        loading={apolloCreditsState.addCredits.loading}
                        handleAddCredits={handleAddCredits}
                        error={apolloCreditsState.addCredits.error?.message}
                    />
                </Portal>
            )
            } */}

        </StateChangeHandler>


    );

    function handleGotoAgencyDashboard() {
        if (editDepartment && editDepartment.data) {
            handleUpdateDepartmentSuccess();
        }
        else {
            handleCreateDepartmentSuccess();
            props.history.push(AppRoutes.dashboardPage);
        }

    }

    function handleOnGotoDepartment(id: string, name: string) {
        if (editDepartment && editDepartment.data) {
            /// ignore
            handleUpdateDepartmentSuccess();
        } else {
            handleCreateDepartmentSuccess();
            props.history.push({
                pathname: `${AppRoutes.companiesDetailsPage}/${params.id}/dashboard/${id}`,
                state: {
                    ...(location.state as any),
                    departmentId: id,
                    departmentName: name,
                }
            })
        }

    }

    function getTabList() {
        return [
            {
                title: (params && params.departmentId) ? t('department_summary').toUpperCase() : t('company_summary').toUpperCase(),
                icon: MagnifyGlassIcon,
                content: <CompanySummery
                    isDepartment={params && params.departmentId != null}
                    onAddDepartment={handleAddDepartment}
                    onRemoveDepartment={handleRemoveDepartment}
                    onAddOption={handleAddOption}
                    onRemoveOption={handleRemoveOption}
                    onDepartmentClick={(value) => handleDepartmentChange(value.department_id, value.department_name)}
                />,
            },
            {
                title: t('engagement_details').toUpperCase(),
                icon: EngagementIcon,
                content: <EngagementDetails
                    id={params.departmentId != null ? params.departmentId : params.id}
                    source={params.departmentId != null ? "DEPARTMENT" : "COMPANIES"}
                    onSuccessCreate={getCompanyDetails}
                />
            },
            {
                title: t('staffing_representatives', { value: profile.profile?.agency_name }).toUpperCase(),
                icon: StaffIcon,
                content: <AlphaOmegaStaffingRepresentatives
                    onAddOption={handleAddOption}
                    onRemoveOption={handleRemoveOption}
                    companyId={params.id}
                    departmentId={params.departmentId}
                    accountManagers={companyState.account_managers.data ?? []}
                    recruiters={companyState.recruiters.data ?? []}
                />,
            },
            {
                title: t('ratings').toUpperCase(),
                icon: RatingIcon,
                content: <RatingsTab
                    id={params.id}
                    departmentId={params.departmentId}
                    source={"company"}
                    onSuccessCallback={getCompanyDetails}
                />
            },

        ];
    }

    /// close add option dialog
    function handleCloseAddDialog() {
        setAddOption(null);
    }

    /// Add option
    function handleAddOption(type: string) {
        setAddOption({ visible: true, type: type });
    }

    /// Add on success add option
    function handleOnSuccessAddDialog() {
        if (addOption != null) {
            dispatch(companyDepartmentSliceActions.clearCompanyDepartmentAddOptionError(params.departmentId ?? params.id));
            dispatch(companyDetailsSliceActions.clearCompanyDetailsAddOptionError({ id: params.departmentId ?? params.id, type: addOption?.type }));
            setAddOption(null);
            getCompanyDetails();
        }

    }

    /// Remove Option
    function handleRemoveOption(type: string, value: { id: string, value: string }) {
        setRemoveOption({ visible: true, type: type, value: value });
    }

    /// close add option dialog
    function handleCloseRemoveDialog() {
        setRemoveOption(null);
    }

    /// Add on success remove option
    function handleOnSuccessRemoveDialog() {
        if (removeOption != null) {
            dispatch(companyDepartmentSliceActions.clearCompanyDepartmentRemoveOptionError(params.departmentId ?? params.id));
            dispatch(companyDetailsSliceActions.clearCompanyDetailsRemoveOptionError({ id: params.departmentId ?? params.id, type: removeOption?.type }));
            setRemoveOption(null);
            getCompanyDetails();
        }

    }

    /// Edit icon actions
    function getEditAction() {
        return (
            <button className="icon-btn cdp-edit-icon" onClick={handleEdit}>
                <EditIcon width={"100%"} height={"100%"} style={{ color: '#fff' }} />
            </button>
        );
    }

    function handleAddDepartment() {
        setEditDepartment({ visible: true });
        dispatch(manageDepartmentActions.clearManageDepartmentState());
    }

    function handleRemoveDepartment(value: { id: string, value: string }) {

    }

    function handleEdit() {
        if (companyState.data != null && companyState.data.type === COMPANY_TYPE.company) {
            setEditCompany({ visible: true, data: (companyState.data as ICompany) });
        }
        if (companyState.data != null && companyState.data.type === COMPANY_TYPE.department) {
            setEditDepartment({ visible: true, data: (companyState.data as IDepartment) });
        }
    }

    ///Add user action
    function handleUpdateCompanySuccess() {
        setEditCompany(null);
        getCompanyDetails();
        dispatch(manageCompanyActions.clearManageCompanyState());
    }

    function handleCreateDepartmentSuccess() {
        setEditDepartment(null);
        getCompanyDetails();
        dispatch(manageDepartmentActions.clearManageDepartmentState());
    }

    function handleUpdateDepartmentSuccess() {
        setEditDepartment(null);
        getCompanyDetails();
        dispatch(manageDepartmentActions.clearManageDepartmentState());
    }

    function handleActionPress(value: string) {
        switch (value) {
            case "company_assignments": {
                history.push({
                    pathname: `${AppRoutes.companiesDetailsPage}/${params.id}/assignments`,
                    state: {
                        ...(location.state as any),
                    }
                });
                break;
            }
            case "company_job_orders": {
                history.push({
                    pathname: `${AppRoutes.companiesDetailsPage}/${params.id}/job-orders`,
                    state: {
                        ...(location.state as any),
                    }
                });
                break;
            }
            case "schedule_a_sales_call": {
                history.push({
                    pathname: AppRoutes.salesAndMarketingSalesCallActivity,
                    state: {
                        planCall: true,
                        companyId: companyState?.data?.id ?? "",
                        planned_by_name: getUserNameFromProfileDropdown(
                            profile?.profile!
                        ),
                        source: "companies"
                    },
                });
                break;
            }
            default: {

                break;
            }
        }
    }
}


export default CompanyDashboardPage;