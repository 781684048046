import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll } from "./company_invoices_reducer";

const select = (state: IRootState) => state.companies.details.companyInvoices;
export const selectCompanyInvoicesState = createSelector(
    select,
    selectState
);
export const selectCompanyInvoiceList = createSelector(select, selectAll)
