import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssignmentsAPI } from "../../../../../apis";
import { ROLE, TALENT_SKILLS_TYPE } from "../../../../../enums";
import { AppError, IAddSkill, IContact, ISkillsInfo, Profile, IRemoveSkill, IDepartment, Assignment, ICreateAssignmentStaff, IDeleteAssignmentStaff } from "../../../../../interfaces";
import { catchRequestError, getTalentMatchQuery } from "../../../../../utils";


export const getAssignmentById = createAsyncThunk<
    Assignment,
    { assignment_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@assignments/assignment_info/assignment/get',
    async (doc, thunkAPI) => {
        try {
            const res = await AssignmentsAPI.getAssignmentById(doc.assignment_id);
            // thunkAPI.dispatch(getAssignmentAllSkills(doc));
            thunkAPI.dispatch(getAssignmentAccountManagers(doc));
            thunkAPI.dispatch(getAssignmentRecruiters(doc));
            return res.data as IContact;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


// /// [Company] ---> Skills
// export const getAssignmentAllSkills = createAsyncThunk<
//     ISkillsInfo,
//     { assignment_id: string },
//     {
//         rejectValue: AppError | null,
//         rejectedMeta: AppError | null,
//     }
// >(
//     '@assignments/assignment_info/assignment/skills/get',
//     async (payload, thunkAPI) => {
//         try {
//             const res = await AssignmentsAPI.getAssignmentSkills(payload);
//             if (typeof res.data === 'string') return {
//                 [TALENT_SKILLS_TYPE.professional_skills]: [],
//                 [TALENT_SKILLS_TYPE.certifications]: [],
//                 [TALENT_SKILLS_TYPE.education]: [],
//                 [TALENT_SKILLS_TYPE.credentials]: [],
//                 [TALENT_SKILLS_TYPE.safety_equipment]: []
//             };
//             return res.data as ISkillsInfo;
//         } catch (error) {
//             return catchRequestError(error, thunkAPI);
//         }
//     }
// );


// export const addAssignmentSkills = createAsyncThunk<
//     string,
//     { assignment_id: string, value: IAddSkill },
//     {
//         rejectValue: AppError | null,
//         rejectedMeta: AppError | null,
//     }
// >(
//     '@assignments/assignment_info/assignment/skills/post',
//     async (data, thunkAPI) => {
//         try {
//             const res = await AssignmentsAPI.addAssignmentSkill(data);
//             thunkAPI.dispatch(getAssignmentAllSkills({ assignment_id: data.assignment_id }));
//             return res.data;
//         } catch (error) {
//             return catchRequestError(error, thunkAPI);
//         }
//     }
// );

// export const removeAssignmentSkills = createAsyncThunk<
//     string,
//     { assignment_id: string, value: IRemoveSkill },
//     {
//         rejectValue: AppError | null,
//         rejectedMeta: AppError | null,
//     }
// >(
//     '@assignments/assignment_info/assignment/skills/delete',
//     async (data, thunkAPI) => {
//         try {
//             const res = await AssignmentsAPI.deleteContactSkill(data);
//             thunkAPI.dispatch(getAssignmentAllSkills({ assignment_id: data.assignment_id }));
//             return res.data;
//         } catch (error) {
//             return catchRequestError(error, thunkAPI);
//         }
//     }
// );



/// [Company]--> [Alpha omega staffing] --> staff

export const getAssignmentAccountManagers = createAsyncThunk<
    Array<Profile>,
    { assignment_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@assignments/assignment_info/assignment/account_managers/get',
    async (query, thunkAPI) => {
        try {
            const res = await AssignmentsAPI.getAssignmentStaffs({ ...query, role: ROLE.accountManager, });
            if (typeof res.data === 'string') return [];
            return res.data as Profile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addAssignmentAccountManagers = createAsyncThunk<
    string,
    ICreateAssignmentStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@assignments/assignment_info/assignment/account_managers/post',
    async (data, thunkAPI) => {
        try {
            const res = await AssignmentsAPI.postAssignmentStaff(data);
            thunkAPI.dispatch(getAssignmentAccountManagers({ assignment_id: data.assignment_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeAssignmentAccountManagers = createAsyncThunk<
    string,
    IDeleteAssignmentStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@assignments/assignment_info/assignment/account_managers/delete',
    async (data, thunkAPI) => {
        try {
            const res = await AssignmentsAPI.deleteAssignmentStaff(data);
            thunkAPI.dispatch(getAssignmentAccountManagers({ assignment_id: data.assignment_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const getAssignmentRecruiters = createAsyncThunk<
    Array<Profile>,
    { assignment_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@assignments/assignment_info/assignment/recruiters/get',
    async (query, thunkAPI) => {
        try {
            const res = await AssignmentsAPI.getAssignmentStaffs({ ...query, role: ROLE.recruiter, });
            if (typeof res.data === 'string') return [];
            return res.data as Profile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addAssignmentRecruiters = createAsyncThunk<
    string,
    ICreateAssignmentStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@assignments/assignment_info/assignment/recruiters/post',
    async (data, thunkAPI) => {
        try {
            const res = await AssignmentsAPI.postAssignmentStaff(data);
            thunkAPI.dispatch(getAssignmentRecruiters({ assignment_id: data.assignment_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeAssignmentRecruiters = createAsyncThunk<
    string,
    IDeleteAssignmentStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@assignments/assignment_info/assignment/recruiters/delete',
    async (data, thunkAPI) => {
        try {
            const res = await AssignmentsAPI.deleteAssignmentStaff(data);
            thunkAPI.dispatch(getAssignmentRecruiters({ assignment_id: data.assignment_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);