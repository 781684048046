import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, Profile } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { IRootState } from "../../../store";
import { selectUserDetailsId } from "./user_details_selector";

export const getAdminCenterUserManagementUserDetails = createAsyncThunk<
    Profile,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/user-management/user_details/get',
    async (userId, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getUserById(userId);
            return res.data as Profile;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const refreshUserDetails = createAsyncThunk<
    Profile,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/user-management/user_details/refresh',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const res = await AdminCenterAPI.getUserById(state.adminCenter.userDetails.id!);
            return res.data as Profile;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);