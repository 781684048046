import { useTranslation } from 'react-i18next';
import { AgencySetup, CallIcon, LocationIcon } from '../../icons';
import { SpinnerScreen, addressParser, getArrayStrings } from '../../utils';
import { IAgencies } from '../../interfaces/agency_management';
import ApiError from '../api_error';
import ToggleSwitch from '../toggle_switch/toggle_switch';
import { LoadingType } from '../../enums';
import { AgencyToggleStatusState } from '../../redux/admin_center/agency_management/agency_toggle_status/agency_toggle_status_reducer';

interface Props {
    agency: IAgencies | null;
    selectedAgency: string;
    agencyToggleState: AgencyToggleStatusState
    onToggleChange: (id: any, value: any, domain: any) => void;
    onToggleError: () => void;
}



const AgencyProfileSection: React.FunctionComponent<Props> = (props) => {
    const { agency, selectedAgency, agencyToggleState, onToggleChange, onToggleError } = props;
    const { t } = useTranslation();

    const agencySection = [
        {
            sectionTitle: 'agency',
            sectionIcon: AgencySetup,
            sectionContent: [
                {
                    title: 'status', valueField: ""
                },
            ]
        },
        {
            sectionTitle: 'address',
            sectionIcon: LocationIcon,
            sectionContent: [
                {
                    title: 'address', valueField: addressParser({
                        'street_address': agency ? agency['street_address'] : null,
                        'city': agency ? agency['city'] : null,
                        'state': agency ? agency['state'] : null,
                        'zip_code': agency ? agency['zip_code'] : null,
                    })
                },
            ]
        },

        {
            sectionTitle: 'contact',
            sectionIcon: CallIcon,
            sectionContent: [
                { title: 'contact', valueField: '' },
                { title: 'mobile', valueField: 'phone' },
            ]
        },
    ]

    return (
        <div className="ups-container">
            <AgencyInfo name={agency?.name} url={agency?.agency_logo_file_url} />
            {agencySection.map((doc, index) => {
                return (
                    <div key={index} className="ups-info-container">
                        <div className="ups-info-icon">
                            <doc.sectionIcon width={"100%"} height={"100%"} />
                        </div>
                        <div className="ups-info-content">
                            {doc.sectionContent.map((data, index) => {
                                const arrayValue = getValues(data.valueField);

                                return (
                                    <div key={index} className="ups-info-holder">
                                        {(data.title !== 'mobile' && data.title !== 'office') ?
                                            <div>
                                                <div className="ups-into-title">
                                                    <span>{t(data.title).toUpperCase()}</span>
                                                </div>
                                                {data.title === 'status' ?
                                                    <div style={{marginTop: "0.5em"}}>
                                                        <span>
                                                            {
                                                                <ToggleSwitch
                                                                    label={agency?.disabled ? "Disabled" : "Enabled"}
                                                                    checked={!(agency?.disabled)}
                                                                    onChange={(value) => onToggleChange(agency?.id, value, agency?.domain)}
                                                                    id={agency?.id}
                                                                />

                                                            }
                                                        </span>
                                                        <span className='toggle-spinner'>
                                                            {agency?.id === selectedAgency && agencyToggleState.loading === LoadingType.pending && <SpinnerScreen size={15} />}
                                                            {agency?.id === selectedAgency &&
                                                                agencyToggleState.error && <div className="error-holder">
                                                                    <ApiError message={agencyToggleState.error.message} onClose={onToggleError} />
                                                                </div>
                                                            }
                                                        </span>
                                                    </div>
                                                    : ""
                                                }
                                                <div className="ups-info-value"
                                                    style={{ marginBottom: '0.5em' }}
                                                >
                                                    {arrayValue}
                                                </div>
                                            </div>
                                            :
                                            <table className='contact-table'>
                                                <tr><td><span>{t(data.title)}</span></td>
                                                    <td><span>{arrayValue}</span></td>
                                                </tr>
                                            </table>
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );

    function getValues(obj: any) {
        if (agency == null) return (<span>{''}</span>);

        if (Array.isArray(obj)) {
            return getArrayStrings(obj, agency);
        }
        if (typeof obj === "object") {
            return obj;
        }
        const value = (obj !== '' && agency![obj] != null) ? agency![obj] : '';
        return (
            <span>{value}</span>
        );
    }
}

// const AgencyAvatar = (props) => {
//     const { name, url } = props;
//     return (
//         <div className="ups-user-info">
//             <div className="ups-avatar-holder">
//                 <div className="ups-avatar background-img" style={url ? { backgroundImage: `url(${url})` } : {}}></div>
//             </div>
//             {/* <div className="ups-user-name">
//                 <span>{name}</span>
//             </div> */}
//         </div>
//     );
// }
const AgencyInfo = (props) => {
    const { name, url } = props;
    return (
        <div className="ups-user-info">
            <div className="ups-avatar-holder">
                <div className="ups-avatar background-img" style={url ? { backgroundImage: `url(${url})` } : {}}></div>
            </div>
            <div className="ups-user-name">
                <span>{name}</span>
            </div>
        </div>
    );
}

// const AgencyInfo = (props) => {
//     const { name, url } = props;
//     const dispatch = useAppDispatch();
//     const [showImageUpload, setShowImageUpload] = useState(false);
//     const [sentRequest, setSentRequest] = useState(false);
//     const [imageURL, setImageURL] = useState('');

//     const agencyPictureState = useAppSelector((state) => selectSingleProfilePictureStateById(ProfilePictureType.branch + '-' + props.objectId)(state));

//     useEffect(() => {
//         setSentRequest(true);
//         dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.branch }));
//     }, [])

//     function updateProfile() {
//         dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.branch }));
//         setSentRequest(true);
//         // dispatch(getProfile())
//     }

//     useEffect(() => {
//         if (sentRequest && profilePictureState.download.loading === LoadingType.succeeded) {
//             setSentRequest(false);
//             setImageURL(profilePictureState.download.response ?? '');
//         }
//     }, [profilePictureState.download.loading])

//     return (
//         <div className="tds-user-info">
//             <AgencyAvatar
//                 onClick={() => (setShowImageUpload(true))}
//                 url={imageURL}
//             />
//             <div className="tds-user-name">
//                 <span>{name}</span>
//             </div>
//             {showImageUpload && <Portal>
//                 <UploadProfile
//                     onClose={() => setShowImageUpload(false)}
//                     onDismiss={() => setShowImageUpload(false)}
//                     visible={showImageUpload}
//                     onApprove={updateProfile}
//                     objectId={props.objectId}
//                     objectType={ProfilePictureType.talent}
//                 />
//             </Portal>}
//         </div>
//     );
// }

export default AgencyProfileSection;
