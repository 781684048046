import { useTranslation } from "react-i18next";
import RenderInput from "../../../../../components/render_input/render_input";
import { PAYCHECK_TYPE } from "../../../../../enums/onboarding_direct_deposit";
import { ITalentAccountInfo, FormInputOption } from "../../../../../interfaces";
import { allowNumbers, formatToMoney, RegxPattern } from "../../../../../utils";
import { onBoardingDirectDepositAccountTypeOptions, onBoardingDirectDepositPaycheckTypeOptions } from "../../../../../variables";
import './step_one.scss'


interface Props {
  data?: ITalentAccountInfo | undefined;
  formState: any[] | ITalentAccountInfo;
  onChange: (fieldId: string, value: any, parent?: string) => void;
  amount_option?: string;
  edit?: boolean;
}
const TalBODirectDepositStepOneForm: React.FunctionComponent<Props> = (props) => {
  const { onChange, formState, amount_option, edit } = props;
  const { t } = useTranslation();
  const amountPayCheckValidation = (event) => {
    allowNumbers(event);
    
    return amount_option === PAYCHECK_TYPE.fixed$amount
      ? formatToMoney(event)
      : () => {};
  };
  const formOptions: (FormInputOption | FormInputOption[])[] = [
    [
      {
        field: "bank_name",
        type: "text",
        label: "name_of_financial_institution",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
        disabled: edit ? true : false,
      },
    ],
    [
      {
        field: "account_type",
        type: "selector",
        label: "type_of_account",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: onBoardingDirectDepositAccountTypeOptions.map((doc) => ({
          label: doc.label,
          value: doc.value,
        })),
        multi: false,
        disabled: edit ? true : false,
      },
      {
        field: "routing_number",
        type: "text",
        label: "bank_routing_number",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
        disabled: edit ? true : false,
        onKeyUp: allowNumbers,
        maxLength: 9,
      },
      {
        field: "account_number",
        type: "text",
        label: "bank_account_number",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
        disabled: edit ? true : false,
        onKeyUp: allowNumbers,
      },
    ],
    [
      {
        field: "amount_type",
        type: "selector",
        label: "amount_of_paycheck_to_deposit_to_this_account",
        secure: false,
        required: true,
        placeholder: "select",
        keyboardtype: "",
        selectorList: onBoardingDirectDepositPaycheckTypeOptions.map((doc) => ({
          label: doc.label,
          value: doc.value,
        })),
        multi: false,
      },
      {
        field: "amount_per_check",
        type: "text",
        label: "amount_of_paycheck_to_deposit",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
        prefix: amount_option === PAYCHECK_TYPE.fixed$amount ? "$" : "",
        suffix: amount_option === PAYCHECK_TYPE.percentage ? "%" : " ",
        min: "0.00",
        step: "0.01",
        onBlur: amountPayCheckValidation,
        onKeyDown: amountPayCheckValidation,
        onKeyUp: amountPayCheckValidation,
        disabled:
          amount_option === PAYCHECK_TYPE.remainingamount ? true : false,
      },
      {
        field: "is_active",
        type: "switch",
        label: "active_account",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
        labelPosition: "top",
      },
    ],
  ];
  return (
    <div className="ob-cnt-inf-form-container talent-bo-payroll-setup">
      {formOptions.map((doc, index) => {
        if (Array.isArray(doc)) {
          return (
            <div key={index + "row"} className="ob-cnt-inf-form-row">
              {doc.map((subDoc, subIndex) => {
                return (
                  <RenderInput
                    key={index + subIndex}
                    doc={subDoc}
                    index={subIndex}
                    formState={formState}
                    handleFieldChange={onChange}
                    formValidators={formValidators}
                    className={"ob-cnt-inf-f-input-holder"}
                    // inputValue={subDoc.inputValue}
                  />
                );
              })}
            </div>
          );
        }

        return (
          <RenderInput
            key={index}
            doc={doc}
            index={index}
            formState={formState}
            handleFieldChange={onChange}
            formValidators={formValidators}
            className={"ob-cnt-inf-f-input-holder"}
            // inputValue={doc.inputValue}
          />
        );
      })}
    </div>
  );

  function formValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case "bank_name": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.username.test(value.text) === false)
          return t("validators.enterValidName");
        return null;
      }
      case "routing_number" : {
        if (value.text === "") return t("validators.required");
        if (value.text && value.text.length < 9) return t("validators.routinglimit");
        if (!value.text) return null;
        return null;
      }
      case "account_number": {
        if (value.text === "") return t("validators.required");
       
        return null;
      }

      default: {
        return null;
      }
    }
  }
};

export default TalBODirectDepositStepOneForm;
