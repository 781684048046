import { combineReducers } from "@reduxjs/toolkit";
import { apolloCreditsSlice } from "./apollo_credits/apollo_credits_reducer";
import { apolloCompanyDetailsSlice } from "./apollo_company_details/apollo_company_details_reducer";
import { apolloRefreshSlice } from "./apollo_refresh/apollo_refresh_reducer";

export const apolloImportReducers = combineReducers({
    [apolloCreditsSlice.name]: apolloCreditsSlice.reducer,
    [apolloCompanyDetailsSlice.name]: apolloCompanyDetailsSlice.reducer,
    [apolloRefreshSlice.name]: apolloRefreshSlice.reducer,


});

export * from "./apollo_credits/apollo_credits_thunk";
export * from "./apollo_credits/apollo_credits_selector";

export * from "./apollo_company_details/apollo_company_details_thunk";
export * from "./apollo_company_details/apollo_company_details_selector";

export * from "./apollo_refresh/apollo_refresh_thunk";
export * from "./apollo_refresh/apollo_refresh_selector";