
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postSnackbarMessage, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import { LoadingType } from '../../../../enums';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { createAgency, getAgencyLogoUploadURLThunk, selectManageAgencyCreateState, selectManageAgencyUpdateState, selectManageAgencyUploadFilesState, selectManageAgencyUploadURLState, updateAgency, uploadAgencyLogoThunk } from '../../../../redux/admin_center';
import { manageAgencyActions } from '../../../../redux/admin_center/agency_management/manage_agency/manage_agency_reducer';
import { IAgencies, IAgencyUploadUrlsQuery, ICreateAgency } from '../../../../interfaces/agency_management';
import AgencyForm from '../../forms/agency_form/agency_form';
import { AgencyBranch, ICreateResponse, ICreateUser, IUploadUrlsQuery } from '../../../../interfaces';

interface Props {
    visible: boolean,
    agency?: IAgencies,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    onClearError: () => void,
}

const AddAgency: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        agency,
        onClose,
        onDissmiss,
        onSuccessClose,
        onClearError
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const createState = useAppSelector((state) => selectManageAgencyCreateState(state));
    const updateState = useAppSelector((state) => selectManageAgencyUpdateState(state));
    const uploadURLState = useAppSelector((state) => selectManageAgencyUploadURLState(state));
    const uploadFileState = useAppSelector((state) => selectManageAgencyUploadFilesState(state));
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [agencyLogo, setAgencyLogo] = useState<File | null>(null);
    const [tabIndex, setTabIndex] = useState<number>(0);

    useEffect(() => {
        if (createState.loading == LoadingType.succeeded ) {
            dispatch(postSnackbarMessage(createState.response ?? null));
            closePopup(onSuccessClose);
        }
        if (updateState.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(updateState.response ?? null));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [createState.loading, updateState.loading])


    // useEffect(() => {
    //     if (createState.loading == LoadingType.succeeded) {
    //         if (createState.response) handleNextStep(createState.response);
    //     }
    //     if (updateState.loading == LoadingType.succeeded) {
    //         if (updateState.response) handleNextStep(updateState.response);
    //     }
    //     return () => { }
    // }, [createState.loading, updateState.loading])


    /// [handle get file upload urls]
    // useEffect(() => {
    //     if (uploadURLState.response && uploadURLState.loading === LoadingType.succeeded) {
    //         dispatch(uploadAgencyLogoThunk({
    //             url: uploadURLState.response,
    //             file: agencyLogo,
    //         }))
    //     }
    // }, [uploadURLState.loading, uploadURLState])

    /// [handle upload files]
    //    useEffect(() => {
    //     if (uploadFileState.loading === LoadingType.succeeded) {
    //         if (agency && agency.id) closePopup(onSuccessClose);
    //     }
    //     return () => {

    //     }
    // }, [uploadFileState.loading])


    // function handleNextStep(response: string) {
    //     if (agencyLogo != null) {
    //         dispatch(getAgencyLogoUploadURLThunk({
    //             agency_id: agency && agency?.id,
    //             logo_file_name: agencyLogo ? agencyLogo?.name : undefined,
    //         }));
    //     } else if (agency && agency.id) {
    //         dispatch(postSnackbarMessage(response ?? null));
    //         closePopup(onSuccessClose);
    //     }
    // }


    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <AgencyForm
                    agency={agency}
                    loading={(createState.loading === LoadingType.pending) || (updateState.loading === LoadingType.pending)}
                    error={createState.error || updateState.error}
                    selectedPhoto={agencyLogo}
                    onAgencyLogoChange={setAgencyLogo}
                    onRemoveAgencyLogo={() => setAgencyLogo(null)}
                    onClearError={onClearError}
                    onClose={() => closePopup(onClose)}
                    onDissmiss={onDissmiss}
                    onSubmit={handleSubmit}
                    lastStep={tabIndex}
                />
            </DialogWrapper>
        </ActionDialogHolder>

    );

    /// handle submit
    function handleSubmit(
        value: {
            agency: ICreateAgency;
            agency_branch: AgencyBranch;
            user: ICreateUser
        }) {
        if (agency && agency.id) {
            dispatch(updateAgency({data: value.agency, file: agencyLogo}));
        }
        else {
            dispatch(createAgency(value));
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default AddAgency;