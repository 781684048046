import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, Profile } from "../../../../../interfaces";
import * as createLoginForTalentThunk from "./create_talent_login_thunk";

export interface TalentLogin {
    error?: AppError | null,
    loading: LoadingType,
    response?: String | null,
}

const initialState: TalentLogin = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const createLoginForTalentSlice = createSlice({
    name: 'createLoginForTalent',
    initialState: initialState,
    reducers: {
        clearCreateLoginForTalentState(state) {
            return { ...state, loading: LoadingType.idle, error: null };
        },
        clearCreateLoginForTalentError(state) {
            return { ...state, error: null };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createLoginForTalentThunk.createLoginForTalentThunkUser.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(createLoginForTalentThunk.createLoginForTalentThunkUser.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, response: null, }))
            .addCase(createLoginForTalentThunk.createLoginForTalentThunkUser.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearCreateLoginForTalentError, clearCreateLoginForTalentState } = createLoginForTalentSlice.actions;
export const selectState = (state: TalentLogin) => state;
export const selectLoading = (state: TalentLogin) => state.loading;
export const selectError = (state: TalentLogin) => state.error;