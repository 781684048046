import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import TabNavigation from "../../../components/tab_navigation/tab_navigation";
import { AppRoutes } from "../../../routes";
import { GetCloseAction } from "../../../utils";
import InDevelopment from "../../../others/in_development";
import BillingInvoicingDashboardPage from "../pages/billing_invoicing/dashboard/bi_dashboard_page";
import BIErrorsReportsPage from "../pages/billing_invoicing/error_report/error_report";
import BillingAndInvoicesReportPage from "../pages/billing_invoicing/billing_and_invoices_report/billing_and_invoices_report";

const BillingInvoiceTabNav = (props: RouteComponentProps<any>) => {
    const { history, location, match } = props;
    const { t } = useTranslation();

    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                {
                    title: 'billing_invoice_dashboard',
                    route: `${AppRoutes.billingInvoicesDashboard}`,
                },
                {
                    title: 'billing_invoces_report',
                    route: `${AppRoutes.billingInvoicesReport}`,
                },
                {
                    title: "errors_report",
                    route: `${AppRoutes.billingInvoicesErrorsReport}`,
                },

                {
                    title: "invoice_correction",
                    route: `${AppRoutes.billingInvoiceCorrection}`,
                },

            ]}
            onChange={(value) =>
                history.push({ pathname: value, state: location.state })
            }
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.backOfficePage)} />}
        >
            <Switch>
                <Route
                    exact
                    path={`${AppRoutes.billingInvoicesDashboard}`}
                    component={BillingInvoicingDashboardPage}
                    // component={InDevelopment}

                />
                <Route
                    exact
                    path={`${AppRoutes.billingInvoicesReport}`}
                    // component={TimeCardsTabPage}
                    component={BillingAndInvoicesReportPage}

                />
                <Route
                    exact
                    path={`${AppRoutes.billingInvoicesErrorsReport}`}
                    // component={TimeCardsReportPage}
                    component={BIErrorsReportsPage}

                />
                <Route
                    exact
                    path={`${AppRoutes.billingInvoiceCorrection}`}
                    // component={TimeCardsErrorsReportPage}
                    component={InDevelopment}

                />
                <Route
                    path={"/"}
                    render={({ location }) => (
                        <Redirect
                            to={{
                                pathname: `${location.pathname}/dashboard`,
                                state: { ...(location.state as any) },
                            }}
                        />
                    )}
                />
            </Switch>
        </TabNavigation>);
}

export default BillingInvoiceTabNav;