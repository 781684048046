import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../../apis";
import { AppError } from "../../../../../interfaces";
import { IOnboardingDocsReviewStatus, IUpdateTalentOnboardingDocsReviewStatus } from "../../../../../interfaces/talent_onboard_docs";
import { catchRequestError } from "../../../../../utils";


export const getTalentODRSThunk = createAsyncThunk<
    IOnboardingDocsReviewStatus,
    { talent_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboarding-and-docs/application-status/get',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.getOnboardingReviewStatus(payload.talent_id);
            return res.data as IOnboardingDocsReviewStatus;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateTalentODRSThunk = createAsyncThunk<
  string,
  IUpdateTalentOnboardingDocsReviewStatus,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@talent/details/onboarding-and-docs/application-status/patch",
  async (payload, thunkAPI) => {
    try {
      const res = await TalentAPI.updateOnboardingReviewStatus(payload);
      thunkAPI.dispatch(getTalentODRSThunk({talent_id:payload.talent_id}))
      // if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);
