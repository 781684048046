import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import ToggleSwitch from "../../../../../components/toggle_switch/toggle_switch";
import { IContactServiceProfile, IContactUpdateServiceProfile } from "../../../../../entities/ContactInfo";
import { LoadingType, THEME } from "../../../../../enums";
import {
  getCheckDelivery,
  getOnboardingSteps,
  getServiceCalls,
  selectCheckDeliveryList,
  selectCheckDeliveryState,
  selectOnboardingStepList,
  selectOnboardingStepState,
  selectServiceCallList,
  selectServiceCallState,
} from "../../../../../redux/admin_center";
import { contactProfileSlice } from "../../../../../redux/contacts/contact_profile/contact_profile_reducer";
import {
  selectContactServiceProfile,
  selectContactSave_state,
} from "../../../../../redux/contacts/contact_profile/contact_profile_selector";
import {
  getContactServiceProfileThunk,
  saveContactServiceProfileThunk,
} from "../../../../../redux/contacts/contact_profile/contact_profile_thunk";
import {
  getContactById,
  postSnackbarMessage,
  selectContactDetailsByIdState,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import { AppRoutes } from "../../../../../routes";
import { convertNumberToMoney, CustomButton, formatToMoney, FormInput, moneyFormat, SpinnerScreen } from "../../../../../utils";

export const ContactServiceProfile: FC<RouteComponentProps<any>> = (
  props: RouteComponentProps<any>
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { history, location, match } = props;
  const { params } = match;
  const { loading, error, serviceProfile } = useAppSelector((state) =>
    selectContactServiceProfile(state)
  );
  const ContactDetails = useAppSelector((state) => selectContactDetailsByIdState(params.id)(state));
  const [loader, setIsLoader] = useState(true);
  const checkDeliveryState = useAppSelector((state) =>
    selectCheckDeliveryState(state)
  );
  const checkDeliveryList = useAppSelector((state) =>
    selectCheckDeliveryList(state)
  );
  const onboardingStepState = useAppSelector((state) =>
    selectOnboardingStepState(state)
  );
  const onboardingStepList = useAppSelector((state) =>
    selectOnboardingStepList(state)
  );
  const serviceCallState = useAppSelector((state) =>
    selectServiceCallState(state)
  );
  const serviceCallList = useAppSelector((state) =>
    selectServiceCallList(state)
  );
  const [profileData, setProfileData] = useState({} as IContactUpdateServiceProfile);
  const saveResponse = useAppSelector((state) => selectContactSave_state(state));

  useEffect(() => {
    dispatch(getOnboardingSteps());
    dispatch(getCheckDelivery());
    dispatch(getServiceCalls());

    if (params && params.id) {
      dispatch(contactProfileSlice.actions.clearState());
      dispatch(getContactServiceProfileThunk(params.id));
      dispatch(getContactById({ contact_id: params.id }));
    }
  }, []);

  useEffect(() => {
    if (saveResponse.loading == LoadingType.succeeded) {
      dispatch(postSnackbarMessage(saveResponse.response ?? null));
      dispatch(contactProfileSlice.actions.clearSaveState());
    }
  }, [saveResponse.loading]);

  useEffect(() => {
    if (serviceProfile && serviceProfile.contact_id) {
      updateForm(serviceProfile);
      setIsLoader(false);
    }
  }, [serviceProfile]);

  const isLoading = () => {
    return (
      loading === LoadingType.pending ||
      loader ||
      checkDeliveryState.loading === LoadingType.pending ||
      onboardingStepState.loading === LoadingType.pending ||
      serviceCallState.loading === LoadingType.pending
    );
  };

  const handleSave = () => {
    dispatch(saveContactServiceProfileThunk({
      ...profileData, 
      mileage_rate: convertNumberToMoney(profileData.mileage_rate, 'number') as number
    }));
  };

  function updateForm(value: IContactServiceProfile){
    setProfileData({ 
        ...value, 
        onboarding_steps: serviceProfile.onboarding_steps
          ? [...serviceProfile.onboarding_steps]
          : [],
        check_delivery: serviceProfile.check_delivery
          ? [...serviceProfile.check_delivery]
          : [],
        service_calls: serviceProfile.service_calls
          ? [...serviceProfile.service_calls]
          : [],
        mileage_rate: value.mileage_rate ? convertNumberToMoney(value.mileage_rate, 'string', 3) as string : '',
    });
  }


  return (
    <>
      {isLoading() ? (
        <div className="table-loading">
          <SpinnerScreen />
        </div>
      ) : (
        <div className="ac-service-profile-container">
          {/* <div className="ac-sales-profile-header">
            <div className="ac-sales-profile-headerText">

              {`${ContactDetails.contactInfo?.first_name ?? ''} ${ContactDetails.contactInfo?.last_name ?? ''} `} 
            </div>
          </div>
          <div className="Line-68"></div> */}
          <div className="ac-service-profile-content">
            <div className="ac-sp-field-row" style={{ marginTop: "1em" }}>
              <p className="ac-service-text">ONBOARDING STEPS</p>
            </div>

            {onboardingStepList &&
              onboardingStepList.map((data: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="ac-service-field"
                    style={{ display: "inline-flex" }}
                  >
                    <ToggleSwitch
                      label={data.onboarding}
                      checked={
                        profileData?.onboarding_steps?.filter(
                          (step: any) => step === data.id
                        ).length > 0
                      }
                      onChange={(value) => {
                        const onboarding_steps = profileData.onboarding_steps
                          ? profileData.onboarding_steps
                          : [];
                        if (value) {
                          onboarding_steps.push(data.id);
                          setProfileData({
                            ...profileData,
                            onboarding_steps: onboarding_steps,
                          });
                        } else {
                          setProfileData({
                            ...profileData,
                            onboarding_steps: onboarding_steps.filter(
                              (step: any) => step !== data.id
                            ),
                          });
                        }
                      }}
                    />
                  </div>
                );
              })}
            <hr />
            <div className="ac-sp-field-row">
              <p className="ac-service-text">CHECK DELIVERY</p>
            </div>
            {checkDeliveryList &&
              checkDeliveryList.map((data: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="ac-service-field"
                    style={{ display: "inline-flex" }}
                  >
                    <ToggleSwitch
                      label={data.check_delivery}
                      checked={
                        profileData?.check_delivery?.filter(
                          (check: any) => check === data.id
                        ).length > 0
                      }
                      onChange={(value) => {
                        const checkDelivery = profileData.check_delivery
                          ? profileData.check_delivery
                          : [];
                        if (value) {
                          checkDelivery.push(data.id);
                          setProfileData({
                            ...profileData,
                            check_delivery: checkDelivery,
                          });
                        } else {
                          setProfileData({
                            ...profileData,
                            check_delivery: checkDelivery.filter(
                              (step: any) => step !== data.id
                            ),
                          });
                        }
                      }}
                    />
                  </div>
                );
              })}
            <hr />
            <div className="ac-sp-field-row">
              <p className="ac-service-text">SERVICE CALLS</p>
            </div>
            {serviceCallList &&
              serviceCallList.map((data: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="ac-service-field"
                    style={{ display: "inline-flex" }}
                  >
                    <ToggleSwitch
                      label={data.service_call}
                      checked={
                        profileData?.service_calls?.filter(
                          (call: any) => call === data.id
                        ).length > 0
                      }
                      onChange={(value) => {
                        const service_calls = profileData.service_calls
                          ? profileData.service_calls
                          : [];
                        if (value) {
                          service_calls.push(data.id);
                          setProfileData({
                            ...profileData,
                            service_calls: service_calls,
                          });
                        } else {
                          setProfileData({
                            ...profileData,
                            service_calls: service_calls.filter(
                              (step: any) => step !== data.id
                            ),
                          });
                        }
                      }}
                    />
                  </div>
                );
              })}
            <hr />
            <div className="ac-sp-field-row">
              <div className="ac-service-field">
                <label className="field-label-text">Timeclock</label>
                <br></br>
                <ToggleSwitch
                  label={t("active")}
                  checked={profileData.time_clock}
                  onChange={(value) => {
                    setProfileData({
                      ...profileData,
                      time_clock: value,
                    });
                  }}
                />
              </div>
              {/* <div className="ac-service-field">
                <FormInput
                  id={"mileage_rate"}
                  onChange={(field: any, value: any) => {
                    if (value !== null) {
                      setProfileData((prevState: any) => ({
                        ...prevState,
                        mileage_rate: value,
                      }));
                    }
                  }}
                  required={false}
                  placeholder={""}
                  type={"text"}
                  value={profileData.mileage_rate}
                  label={"Mileage Rate"}
                  prefix={<span>{'$'}</span>}
                  onBlur={()=>formatToMoney(event,3)}
                  onKeyDown={()=>formatToMoney(event,3)}
                  onKeyUp={()=>formatToMoney(event,3)}
                />
              </div> */}
            </div>
          </div>
          <div className="add-role-action-holder">
            <div className="add-role-action">
              <div className="btn-no">
                <CustomButton
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("cancel")}
                  enable={true}
                  backgroundColor={THEME.defaultActionColor}
                  onClick={() => {
                    updateForm(serviceProfile);
                  }}
                />
              </div>
              <div className="btn-yes">
                <CustomButton
                  loading={saveResponse.loading === LoadingType.pending}
                  textStyle={{ textTransform: "capitalize" }}
                  name={"Save"}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={handleSave}
                  enable={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
