import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import {
    selectState,
    selectSingleJobSuccessProfileState,
    selectInitialSingleJobSuccessProfileState,
} from "./success_profile_info_reducer";

const select = (state: IRootState) => state.jobs.details.successProfile.successProfile;
export const selectJobSuccessProfileState = createSelector(select, selectState);

export const selectJobSuccessProfileByIdState = (id: string | undefined | null) => createSelector(
    selectJobSuccessProfileState,
    (state) => {
        if (id && state[id]) return selectSingleJobSuccessProfileState(state, id);
        return selectInitialSingleJobSuccessProfileState();
    }
);

// export const selectJobSuccessProfileDepartmentState = createSelector(select, selectDepartmentState);
