import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces/app_error";
import { SalesCall } from "../../../../interfaces/sales_call";
import * as thunk from "./sales_call_details_thunk";

export interface SalesCallState {
    error?: AppError | null,
    loading: LoadingType,
    response: SalesCall | null
}
const initialState: SalesCallState = {
    error: null,
    loading: LoadingType.idle,
    response: null,

}

export const salesCallDetailSlice = createSlice({
    name: 'salesCallDetails',
    initialState: initialState,
    reducers: {
        clearSalesCallDetailError(state) {
            return { ...state, error: null };
        },
        clearSalesCallDetailState(state) {
            return { ...state, loading: LoadingType.idle, error: null,response:null };
        },

    },
    extraReducers: (builder) => {
        builder
        .addCase(thunk.getSalesCallByIdThunk.pending, (state, action) => ({
            ...state,
            loading: LoadingType.pending,
            error: null,
        }))
        .addCase(thunk.getSalesCallByIdThunk.rejected, (state, action) => ({
            ...state,
            loading: LoadingType.failed,
            error: action.payload,
            response: null,
        }))
        .addCase(thunk.getSalesCallByIdThunk.fulfilled, (state, action) => ({
            ...state,
            loading: LoadingType.succeeded,
            error: null,
            response: action.payload
        }))
            
            .addDefaultCase(state => ({ ...state }));
    }
});


export const  salesCallDetailActions = salesCallDetailSlice.actions;

export const selectState = (state: SalesCallState) => state;