import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { TalentAPI } from "../../../../apis";

export const getTerminateTalent = createAsyncThunk<
    string,
    { talent_id: string, termination_date: number },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_dashboard/details_section/delete',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.terminateTalent(payload.talent_id, payload.termination_date);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);