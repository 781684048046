import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props } from "react-select";
import { LoadingType, THEME } from "../../../../../enums";

import { AgencyOnboarding } from "../../../../../interfaces";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import { CustomButton, FormInput } from "../../../../../utils";
import {
  getAgencyOnBoarding,
  selectAgencyOnboardingListState,
  selectOnboardingUpdatestate,
  updateAgencyOnboarding,
} from "../../../../../redux/admin_center";
import { manageAgencyOnboardingActions } from "../../../../../redux/admin_center/system_configuration/onboarding/onboarding_reducer";
import { EditIcon } from "../../../../../icons";

const AdminCenterOnBoardingCompany: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [onboardingData, setOnboardingData] = useState({} as AgencyOnboarding);
  const [inputEnable, setInputEnable] = useState<boolean>(true);

  const agencyOnboardingState = useAppSelector((state) =>
    selectAgencyOnboardingListState(state)
  );
  const saveResponse = useAppSelector((state) =>
    selectOnboardingUpdatestate(state)
  );
  const { loading, error, agencyOnboardingData } = useAppSelector((state) =>
    selectAgencyOnboardingListState(state)
  );

  useEffect(() => {
    if (agencyOnboardingState.loading === LoadingType.succeeded) {
      setOnboardingData(agencyOnboardingState.agencyOnboardingData);
      if (
        onboardingData.terms_and_conditions === "" 
        // &&
        // onboardingData.esc_api_key === "" &&
        // onboardingData.wotc_api_key === ""
      ) {
        setInputEnable(false);
      }
    }
  }, [agencyOnboardingState.loading]);

  useEffect(() => {
    dispatch(getAgencyOnBoarding());
    return () => {};
  }, []);

  const isAddLoading = () => {
    return saveResponse.loading === LoadingType.pending;
  };
  const handleFieldChange = (id, value) => {
    setOnboardingData({ ...onboardingData, [id]: value });
  };
  const handleSave = () => {
    dispatch(
      updateAgencyOnboarding({
        ...onboardingData,
        terms_and_conditions: onboardingData.terms_and_conditions,
        // esc_api_key: onboardingData.esc_api_key,
        // wotc_api_key: onboardingData.wotc_api_key,
      })
    );
    setInputEnable(true);
  };
  const handleEdit = () => {

    setInputEnable(false);
  };
  useEffect(() => {
    if (
      saveResponse.loading == LoadingType.succeeded &&
      saveResponse.response
    ) {
      dispatch(postSnackbarMessage(saveResponse.response ?? null));
      dispatch(getAgencyOnBoarding());
      dispatch(manageAgencyOnboardingActions.clearUpdateState())
      dispatch(manageAgencyOnboardingActions.clearUpdateStateError())
    }
  }, [saveResponse.loading]);
  return (
    <div className="ac-sc-ob-content-container">
      <div className="ac-ob-btn-header">
        <button className="icon-btn ac-ob-edit-icon" onClick={() => {}}>
          <EditIcon
            width={"100%"}
            height={"100%"}
            style={{ color: "#fff" }}
            onClick={handleEdit}
          />
        </button>
      </div>
      <div className="ac-sc-ob-content ac-sc-ob-company-content">
        <div>
          <FormInput
            id={"terms_and_conditions"}
            onChange={(id, val) => handleFieldChange(id, val)}
            type={"text"}
            value={onboardingData.terms_and_conditions ?? ""}
            label={t("terms_and_conditions")}
            placeholder=""
            required={false}
            customStyle={{ minWidth: "15vw" }}
            multiline={true}
            disabled={inputEnable}
          />
        </div>
        {/* <div className="flex-input-holder">
          <div className="input-holder">
            <FormInput
              id={"esc_api_key"}
              onChange={(id, val) => handleFieldChange(id, val)}
              type={"text"}
              value={onboardingData.esc_api_key ?? ""}
              label={t("esc_api_key")}
              placeholder=""
              required={false}
              // customStyle={{ minWidth: "15vw" }}
              multiline={false}
              disabled={inputEnable}
            />
          </div>
          <div className="input-holder">
            <FormInput
              id={"wotc_api_key"}
              onChange={(id, val) => handleFieldChange(id, val)}
              type={"text"}
              value={onboardingData.wotc_api_key ?? ""}
              label={t("wotc_api_key")}
              placeholder=""
              required={false}
              // customStyle={{ minWidth: "15vw" }}
              multiline={false}
              disabled={inputEnable}
            />
          </div>
        </div> */}
      </div>

      <div className="ac-sc-ob-content-actions company-actions">
        <div className="btn-cancel">
          <CustomButton
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("cancel")}
            enable
            backgroundColor={THEME.secondaryColor4}
            onClick={() => {
              setOnboardingData(agencyOnboardingData);
              setInputEnable(true);
            }}
          />
        </div>
        <div className="btn-save">
          <CustomButton
            loading={isAddLoading()}
            textStyle={{ textTransform: "capitalize" }}
            name={t("save")}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={handleSave}
            enable={!inputEnable && (onboardingData.terms_and_conditions !== "" && onboardingData.terms_and_conditions !== null)} 
          />
        </div>
      </div>
    </div>
  );
};

export default AdminCenterOnBoardingCompany;
