import { useTranslation } from 'react-i18next';
import { SpinnerScreen } from '../../../utils';
import './logout_page.scss';

const LogoutPage = () => {
    const {t} = useTranslation();
    return (
        <div className="lo-container">
            <div className="lo-logo-size">
                <SpinnerScreen />
            </div>
            <span>{`${t('logging_out')}...`}</span>
        </div>
    );
}

export default LogoutPage;