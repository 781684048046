import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props } from "react-select";
import { LoadingType, THEME } from "../../../../../enums";

import { AgencyOnboarding } from "../../../../../interfaces";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import { CustomButton, FormInput, SpinnerScreen } from "../../../../../utils";
import {
  getAgencyOnBoarding,
  selectAgencyOnboardingListState,
  selectManageUserToggleRoleState,
  selectOnboardingUpdatestate,
  updateAgencyOnboarding,
} from "../../../../../redux/admin_center";
import {
  getAgencyOnboardingDataSlice,
  manageAgencyOnboardingActions,
} from "../../../../../redux/admin_center/system_configuration/onboarding/onboarding_reducer";
import DeleteIcon from "../../../../../../assets/icons/delete-icon.svg";
import "./admin_center_onboarding.scss";
import {
  convertDateToTimeStamp,
  getDateFromTimeStamp,
  getDateString,
} from "../../../../../variables";
import { Portal } from "react-portal";
import {
  ActionDialogHolderType,
  ActionDialogHolder,
} from "../../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../../components/api_error";
import { DeleteConfirmationDialog } from "../../../../components/delete_confirmation_dialog";
const AdminCenterOnBoardingWelcomeVideo: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [onboardingData, setOnboardingData] = useState({} as AgencyOnboarding);
  const [videoUrl, setVideoUrl] = useState<{ [key: string]: string }>({});
  const [showDeleteAction, setShowDeleteAction] = useState<{
    doc: any;
    visible: boolean;
  } | null>(null);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const agencyOnboardingState = useAppSelector((state) =>
    selectAgencyOnboardingListState(state)
  );
  const saveResponse = useAppSelector((state) =>
    selectOnboardingUpdatestate(state)
  );
  const { loading, error, agencyOnboardingData } = useAppSelector((state) =>
    selectAgencyOnboardingListState(state)
  );
  useEffect(() => {
    if (agencyOnboardingState.loading === LoadingType.succeeded) {
      setOnboardingData(agencyOnboardingState.agencyOnboardingData);
    }
  }, [agencyOnboardingState.loading]);

  useEffect(() => {
    dispatch(getAgencyOnBoarding());
    return () => {};
  }, []);

  useEffect(() => {
    if (
      saveResponse.loading == LoadingType.succeeded &&
      saveResponse.response
    ) {
      if(snackBarMessage!="")
        dispatch(postSnackbarMessage(snackBarMessage ?? null));
      dispatch(getAgencyOnboardingDataSlice.actions.clearUpdateState());
      dispatch(getAgencyOnBoarding());
    }
  }, [saveResponse.loading]);

  const isAddLoading = () => {
    return saveResponse.loading === LoadingType.pending;
  };
  const handleSave = () => {
    dispatch(
      updateAgencyOnboarding({
        ...onboardingData,
        ...(videoUrl ?? {}),
        video_upload_timestamp: convertDateToTimeStamp(new Date()),
      })
    );
    setSnackBarMessage("Welcome Video Uploaded Successfully!");

  };
  const handleCancel = () => {
    setVideoUrl({ ...videoUrl, "welcome_video_path": "" });
  };
  const handleFieldChange = (id, value) => {
    setVideoUrl({ ...videoUrl, [id]: value });
  };
  const checkYouTubeURL = (url?: string) => {
    if (url) {
      var regExp =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (url.match(regExp)) {
        return true;
      }
    }
    return false;
  };

  const PageLoading = (props) => {
    return (
      <div className="table-loading">
        <SpinnerScreen />
      </div>
    );
  };
  const isLoading = () => {
    return agencyOnboardingState.loading === LoadingType.pending;
  };
  const handleDelete = (doc: any) => {
    setShowDeleteAction({ doc: doc, visible: true });
  };
  /// close delete role dialog
  function handleCloseDeleteDialog() {
    setShowDeleteAction(null);
  }

  function handleClearError() {
    dispatch(manageAgencyOnboardingActions.clearOnboardingURLState());
  }
  ///Delete user role
  function handleOnSuccessDeleteHandbookDialog() {
    dispatch(
      updateAgencyOnboarding({
        ...onboardingData,
        welcome_video_path: "",
      })
    );
    setVideoUrl({ ...videoUrl, "welcome_video_path": "" });
    handleCloseDeleteDialog();
    setSnackBarMessage("Welcome Video Deleted Successfully!");
    // dispatch(manageAgencyOnboardingActions.clearUpdateState());
  }
  const VideoPlayer = ({ url }) => {
    let videoId = "";

    if (url.toLowerCase().includes("v="))
      videoId = url.split("v=")[1].split("&")[0];
    else {
      videoId = url.split("/");
      videoId = videoId[videoId.length - 1];
    }

    const date = getDateFromTimeStamp(onboardingData.video_upload_timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const UploadDate = `${day < 10 ? "0" + day : day} ${month} ${year}`;

    return (
      <div>
        <div className="iframe-div">
          <iframe
            id="player"
            style={{ borderRadius: "5px" }}
            width="300"
            height="350"
            src={`https://www.youtube.com/embed/${videoId}?controls=2`}
            allowFullScreen={true}
            loading="lazy"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>

        <div className="ac-sc-ob-content-actions video-actions">
          <div className="upload-span">
            <span>Uploaded {UploadDate}</span>
          </div>
          <div className="btn-del">
            <img
              src={DeleteIcon}
              alt="delete_button"
              onClick={() => handleDelete(t("welcome_video"))}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="ac-sc-ob-content-container">
      <div className="ac-sc-ob-content-header">
        <span>{t("video")}</span>
      </div>
      <div className="ac-sc-ob-content">
        {onboardingData &&
        checkYouTubeURL(onboardingData.welcome_video_path) ? (
          <div>
            {isLoading() ? (
              <PageLoading />
            ) : (
              <VideoPlayer url={onboardingData.welcome_video_path} />
            )}
          </div>
        ) : (
          <>
            <div>
              <FormInput
                id={"welcome_video_path"}
                onChange={(id, val) => handleFieldChange(id, val)}
                type={"text"}
                value={videoUrl["welcome_video_path"] ?? ""}
                label={t("youtube_link")}
                placeholder=""
                required={false}
                customStyle={{ minWidth: "15vw" }}
                multiline={false}
              />
            </div>

            <div className="ac-sc-ob-content-actions">
              <div className="btn-cancel">
                <CustomButton
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("cancel")}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={handleCancel}
                  enable
                />
              </div>
              <div className="btn-save">
                <CustomButton
                  loading={isAddLoading()}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("save")}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={handleSave}
                  enable={checkYouTubeURL(videoUrl["welcome_video_path"])}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {showDeleteAction?.visible && (
        <DeleteConfirmationDialog
          message={t("are_you_sure_you_want_to_delete_doc", { value: showDeleteAction?.doc })}
          onCancel={() => setShowDeleteAction(null)}
          onConfirm={handleOnSuccessDeleteHandbookDialog}
          deleting={saveResponse?.loading === LoadingType.pending}
          deleteType={t("welcome_video")}
          state={saveResponse}
          onClearError={handleClearError}
        />
      )}
    </div>
  );
};

export default AdminCenterOnBoardingWelcomeVideo;
