import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums"
import { AppError, IDepartmentStructure } from "../../../../../interfaces"
import * as thunk from './departments_structure_thunk';


interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

interface RemoveState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialRemoveState: RemoveState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


/// [Skills]
export interface SingleCompanyDepartmentStructuresState {
    data: Array<IDepartmentStructure> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}



const singleCompanyDepartmentStructuresInitialState: SingleCompanyDepartmentStructuresState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}


export interface CompanyDepartmentStructurestate {
    [key: string]: SingleCompanyDepartmentStructuresState,
}


const initialState: CompanyDepartmentStructurestate = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

function getResponseSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}

export const companyDepartmentStructureSlice = createSlice({
    name: 'departmentsStructure',
    initialState: initialState,
    reducers: {
        clearCompanyDepartmentStructureAddOptionError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: {
                        ...state[action.payload].add,
                        ...initialAddState,
                    },
                }
            };
        },

        clearCompanyDepartmentStructureRemoveOptionError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    remove: {
                        ...state[action.payload].remove,
                        ...initialAddState,
                    },

                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getCompanyDepartmentStructures.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId]),
                        add: initialAddState,
                        remove: initialRemoveState,
                    }
                })
            )
            .addCase(
                thunk.getCompanyDepartmentStructures.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getCompanyDepartmentStructures.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...getSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId], action.payload),
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentStructureListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const companyDepartmentStructureSliceActions = companyDepartmentStructureSlice.actions;
export const selectCompanyDepartmentStructuresState = (state: CompanyDepartmentStructurestate) => state;
export const selectSingleCompanyDepartmentStructuresState = (state: CompanyDepartmentStructurestate, id: string): SingleCompanyDepartmentStructuresState => state[id];

export const selectInitialSingleCompanyDepartmentStructuresState = (): SingleCompanyDepartmentStructuresState => ({
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
});