import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import * as thunk from "./work_comp_configuration_thunk";
import { AppError } from "../../../interfaces";
import { LoadingType } from "../../../enums";
import { WorkCompConfiguration } from "../../../interfaces/work_comp_configuration";

export interface WorkCompConfigListState extends EntityState<WorkCompConfiguration> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        respose: string,  
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        respose: string,  
    },
    delete: {
        error?: AppError | null,
        loading: LoadingType,
        respose: string,  
    }
}

const WorkCompConfigListAdapter: EntityAdapter<WorkCompConfiguration> = createEntityAdapter<WorkCompConfiguration>({
    selectId: (doc) => doc.id,
})

const initialState: WorkCompConfigListState = WorkCompConfigListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        respose: "",  
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        respose: "",  
    },
    delete: {
        error: null,
        loading: LoadingType.idle,
        respose: "",  
    }
})


export const WorkCompConfigListSlice = createSlice({
    name: 'workCompConfigList',
    initialState: initialState,
    reducers: {
        clearWorkCompConfigListError(state){
            return {...state, error: null};
        },
        clearWorkCompConfigListState(state){
            return WorkCompConfigListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearWorkCompCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearWorkCompUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearWorkCompDeleteState(state) {
            return { ...state, delete : {...initialState.delete} };
        },
        clearWorkCompCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearWorkCompUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearWorkCompDeleteStateError(state) {
            return { ...state, delete : { ...initialState.delete , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getWorkCompConfigList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getWorkCompConfigList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getWorkCompConfigList.fulfilled, (state, action) => WorkCompConfigListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addCase(thunk.createWorkCompConfigList.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.createWorkCompConfigList.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.createWorkCompConfigList.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, respose: action.payload } }))
            .addCase(thunk.updateWorkCompConfigList.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.updateWorkCompConfigList.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.updateWorkCompConfigList.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, respose: action.payload } }))
            .addCase(thunk.deleteWorkCompConfigList.pending, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deleteWorkCompConfigList.rejected, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deleteWorkCompConfigList.fulfilled, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.succeeded, error: null, respose: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearWorkCompConfigListError, clearWorkCompConfigListState, clearWorkCompCreateState, clearWorkCompUpdateState, clearWorkCompCreateStateError, clearWorkCompUpdateStateError, clearWorkCompDeleteStateError, clearWorkCompDeleteState } = WorkCompConfigListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = WorkCompConfigListAdapter.getSelectors();
export const selectState = (state: WorkCompConfigListState) => state;
export const selectLoading = (state: WorkCompConfigListState) => state.loading;
export const selectError = (state: WorkCompConfigListState) => state.error;


