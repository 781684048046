import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FONT, THEME } from '../../enums';
import { FILE_ICONS } from '../../file_icons';
import { CloseIcon, FilesIcon } from '../../icons';
import CustomText from '../../utils/custom_text';
import './drag_and_drop.scss';


interface Props {
    label?: string | React.ReactNode,
    placeholder?: string,
    selectedFile?: File | null,
    selectedFiles?: Array<File> | null,
    accept: string,
    onChange?: (value: File) => void,
    onMultiChange?: (value: Array<File>) => void,
    onRemove?: () => void,
    onMultiRemove?: (index: number) => void,
    formValidators?: () => string | null,
    type?: 'small' | 'large',
    multiple?: boolean,
    disabled?: boolean
}
const DragAndDrop: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { selectedFile, selectedFiles, accept, multiple, label, placeholder, type, onRemove, onMultiRemove, onChange, onMultiChange, formValidators, disabled } = props;
    const fileRef = useRef<HTMLInputElement | null>(null);
    const [dragDetected, setDargDetected] = useState<boolean>(false);

    function handleClick() {
        if (fileRef && fileRef.current != null) {
            fileRef.current.click();
        }
    }

    const handleDragEnter = e => {
        e.preventDefault();
        setDargDetected(true);
    };

    const handleDragLeave = e => {
        e.preventDefault();
        setDargDetected(false);
    };

    const handleDragOver = e => {
        e.preventDefault();
    };

    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            if (multiple && onMultiChange) {
                onMultiChange(Array.from(e.dataTransfer.files));
            }
            else {
                if (onChange) onChange(e.dataTransfer.files[0]);
            }
        }


    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            if (onChange) onChange(e.target.files[0]);
            if (onMultiChange) onMultiChange(Array.from(e.target.files));
            e.target.value = '';
        }
    };


    return (
        <div className={type === 'small' ? "drad-input-container-small" : "drad-input-container"}>
            <input
                style={{ display: 'none' }}
                ref={(ref) => fileRef.current = ref}
                type="file"
                name="file"
                accept={accept}
                onChange={handleInputChange}
                multiple={multiple}
                disabled={disabled}
            />
            {label && <div className="drad-label-holder">
                {getLabel()}
            </div>}
            <div
                className={`drad-input-holder ${dragDetected ? 'drg-active' : ''}`}
                onDrop={e => handleDrop(e)}
                onDragOver={e => handleDragOver(e)}
                onDragEnter={e => handleDragEnter(e)}
                onDragLeave={e => handleDragLeave(e)}
            >
                {getPreview()}
            </div>
            {formValidators && <CustomText
                customStyle={{
                    color: 'red',
                    padding: 5,
                    fontSize: 10,
                    fontFamily: FONT.lato
                }}
            >
                {formValidators()}
            </CustomText>}
        </div>
    );

    function getPreview() {
        if (selectedFile) {
            const nameList = (selectedFile as File).name.split('.');
            const type = nameList[nameList?.length - 1];

            return (
                <div className={props.type === 'small' ? "file-preview-holder-small" : "file-preview-holder"}>
                    <div className="file-type-icon" >
                        <img src={FILE_ICONS[type.toLowerCase()]} width={"100%"} height={"100%"} style={{ objectFit: 'cover' }} />
                        <button className="icon-btn close-action" onClick={() => onRemove ? onRemove() : {}}>
                            <CloseIcon width={"100%"} height={"100%"} />
                        </button>
                    </div>
                    <div className="file-name">
                        <span>{(selectedFile as File).name}</span>
                    </div>
                </div>
            )
        }

        if (selectedFiles && selectedFiles.length > 0) {
            return (
                <div className="row-list-files">
                    {selectedFiles.map((doc, index) => {
                        const nameList = (doc as File).name.split('.');
                        const type = nameList[nameList?.length - 1];

                        return (
                            <div key={index} className={props.type === 'small' ? "file-preview-holder-small" : "file-preview-holder"}>
                                <div className="file-type-icon" >
                                    <img src={FILE_ICONS[type.toLowerCase()]} width={"100%"} height={"100%"} style={{ objectFit: 'cover' }} />
                                    <button className="icon-btn close-action" onClick={() => onMultiRemove ? onMultiRemove(index) : {}}>
                                        <CloseIcon width={"100%"} height={"100%"} />
                                    </button>
                                </div>
                                <div className="file-name">
                                    <span>{(doc as File).name}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            );

        }

        return (
            <button className="file-icon-info" onClick={handleClick}>
                <div className="file-icon">
                    <FilesIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />

                </div>
                <div className="drag-info-txt">
                    <span>{placeholder ?? t('select_or_drag_a_file')}</span>
                </div>
            </button>
        )
    }

    function getLabel() {
        if (typeof label === "string") {
            return (<span className='label-txt'>{label}</span>);
        }
        return label;
    }

}

export default DragAndDrop;