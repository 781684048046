import { TFunction, useTranslation } from 'react-i18next';
import { OrderStatus } from '../enums';
import { TickGreenIcon, TickRedIcon, TickGreyIcon } from '../icons';


const OpenOrderRow = (doc, field_name, t) => {
    if (doc?.requested === null || doc?.requested === 0)
        return (<span style={{ color: '#b9b9b9' }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <div style={{ width: "1.25vw" }}><TickGreyIcon style={{ width: "100%", height: "100%" }} /></div>
                <div style={{ marginLeft: 5 }}>0 {t('orders')}</div>
            </div>
        </span>);
    if (doc[field_name] === 'OPEN')
        return (<span style={{ color: '#FF0000' }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <div style={{ width: "1.25vw" }}><TickRedIcon style={{ width: "100%", height: "100%" }} /></div>
                <div style={{ marginLeft: 5 }}>{doc.open_orders} {t('open')}</div>
            </div>
        </span>);
    else if (doc[field_name] === 'FILLED')
        return (<span style={{ color: '#00c655' }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <div style={{ width: "1.25vw" }}><TickGreenIcon style={{ width: "100%", height: "100%" }} /></div>
                <div style={{ marginLeft: 5 }}>{doc?.placed ? doc.placed : doc.requested} {t('filled')}</div>
            </div>
        </span>);
    return (<span style={{ color: '#b9b9b9' }}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            <div style={{ width: "1.25vw" }}><TickGreyIcon style={{ width: "100%", height: "100%" }} /></div>
            <div style={{ marginLeft: 5 }}>{t('unfilled')}</div>
        </div>
    </span>);
}


export function getOpenOrdersInfo(doc: { order_status: string, open_orders: number, requested: number | null }, t: TFunction<"translation", undefined>,) {
    const status = getCircleTickIcon(doc.order_status, doc.open_orders, t);
    if (doc.requested === null || doc.requested === 0 ) {
        return noOrders(t);
    }
    return (
        <div className="open-orders-empty">
            <div className="ord-icon">
                {status.icon}
            </div>
            <div className="ord-text" >
                <span style={{ color: status.color }}>{status.text}</span>
            </div>
        </div>
    );
}

export const getCircleTickIcon = (order_status: string, open_orders: number, t: TFunction<"translation", undefined>,): { icon: React.ReactNode, color: string, text: string } => {
    switch (order_status) {
        case OrderStatus.CLOSED: return { icon: (<TickGreyIcon width={"100%"} height={"100%"} />), color: "#B1B1B1", text: `${t('value_open_orders', { value: open_orders, text: open_orders ? t('orders') : t('order') })}` };
        case OrderStatus.FILLED: return { icon: (<TickGreenIcon width={"100%"} height={"100%"} />), color: "#00BE4B", text: `${t('value_filled_orders', { value: open_orders, text: open_orders ? t('orders') : t('order') })}` };
        case OrderStatus.OPEN: return { icon: (<TickRedIcon width={"100%"} height={"100%"} />), color: "#FF0000", text: `${t('value_open_orders', { value: open_orders, text: open_orders ? t('orders') : t('order') })}` };
        default: return { icon: (<TickGreyIcon width={"100%"} height={"100%"} />), color: "#B1B1B1", text: `${t('orders')}` };
    }
}


export function noOrders(t: TFunction<"translation", undefined>) {
    return (
        <div className="open-orders-empty">
            <div className="ord-icon">
                <TickGreyIcon width={"100%"} height={"100%"} />
            </div>
            <div className="ord-text-empty">
                <span>{`0 ${t('orders')}`}</span>
            </div>
        </div>
    )
}

export default OpenOrderRow;