import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssignmentsAPI} from "../../../apis";
import { AppError, IAssignmentList} from "../../../interfaces";
import { catchRequestError } from "../../../utils";


export const getAssignmentDropdown = createAsyncThunk<
    Array<IAssignmentList>,
    {company_id?: string, department_id?: string, talent_id?: string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/assignments/assignments/dropdown/get',
    async (query, thunkAPI) => {
        try {
            const res = await AssignmentsAPI.getAssignmentDropdown(query);
            if(typeof res.data === 'string')  return [];
            return res.data as  Array<IAssignmentList>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);