import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IVerifyTokenResponse, Profile } from "../../../interfaces";
import * as thunk from "./submit_application_thunk";

export interface OnBoadingSubmitApplicationState {
    error?: AppError | null,
    loading: LoadingType,
    response?: IVerifyTokenResponse | null,
}

const initialState: OnBoadingSubmitApplicationState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const onBoardingSubmitApplicationSlice = createSlice({
    name: 'submitApplication',
    initialState: initialState,
    reducers: {
        clearSubmitApplicationError(state){
            return { ...state, error: null };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.onboardingSubmitApplicationThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.onboardingSubmitApplicationThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, response: null, }))
            .addCase(thunk.onboardingSubmitApplicationThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const onboardingSubmitApplicationSliceActions = onBoardingSubmitApplicationSlice.actions;
export const selectState = (state: OnBoadingSubmitApplicationState) => state;
export const selectLoading = (state: OnBoadingSubmitApplicationState) => state.loading;
export const selectError = (state: OnBoadingSubmitApplicationState) => state.error;


