import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import * as thunk from "./manage_employment_thunk";

export interface ManageTalentEmploymentHistoryState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    }
   
}

const initialState: ManageTalentEmploymentHistoryState = {
    create: {
        error: null,
        loading: LoadingType.idle,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
    }
}


export const manageTalentEMploymentHistorySlice = createSlice({
    name: 'manageTalentEmploymentHistory',
    initialState: initialState,
    reducers: {
        clearCreateTalentEMploymentHistoryState(state) {
            return { ...state, create: { ...initialState.create } };
        },
        clearUpdateTalentEMploymentHistoryState(state) {
            return { ...state, update: { ...initialState.update } };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.createTalentEmploymentHistory.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createTalentEmploymentHistory.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createTalentEmploymentHistory.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addCase(thunk.updateTalentEmploymentHistory.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateTalentEmploymentHistory.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateTalentEmploymentHistory.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageTalentEmploymentHistoryActions = manageTalentEMploymentHistorySlice.actions;
export const selectState = (state: ManageTalentEmploymentHistoryState) => state;
export const selectCreateState = (state: ManageTalentEmploymentHistoryState) => state.create;
export const selectUpdateState = (state: ManageTalentEmploymentHistoryState) => state.update;


