
import { Assignment, IAssignmentApiQuery, ICreateAssignment, ICreateAssignmentStaff, IDeleteAssignmentStaff } from "../interfaces";
import { } from "../interfaces/talent";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const AssignmentsAPI = {

    getAllAssignments(query?: IAssignmentApiQuery) {
        if (query) {
            let queryString = '';
            for (let param of Object.keys(query))
                if (query[param])
                    queryString += `&${param}=${query[param]}`;
            queryString = queryString.slice(1,);
            return interceptor.get(
                `${UrlBuilder.allAssignments}?${queryString}`
            );
        }
        return interceptor.get(UrlBuilder.allAssignments);
    },

    getAssignmentById(assignment_id: string) {
        return interceptor.get(`${UrlBuilder.assignments}?assignment_id=${assignment_id}`);
    },
    getAssignmentDropdown(query?: {company_id?: string, department_id?: string, talent_id?: string},){
        if (query && query.talent_id){
            return interceptor.get(`${UrlBuilder.getAssignmentDropdown}?talent_id=${query.talent_id}`);
        }
        if(query && query.company_id){
            if(query.department_id) return interceptor.get(`${UrlBuilder.getAssignmentDropdown}?company_id=${query.company_id}&department_id=${query.department_id}`);
            return interceptor.get(`${UrlBuilder.getAssignmentDropdown}?company_id=${query.company_id}`);
        }
        return interceptor.get(`${UrlBuilder.getAssignmentDropdown}`);
    },

    // postAssignment(doc: Assignment) {
    //     return interceptor.post(UrlBuilder.assignments, doc);
    // },

    // updateAssignment(doc: Assignment){
    //     return interceptor.patch(UrlBuilder.assignments, doc);
    // },

    // deleteAssignmentById(assignment_id: string) {
    //     return interceptor.delete(`${UrlBuilder.assignments}?assignment_id=${assignment_id}`);
    // },

    createAssignment(doc: { forceAdd: boolean, value: Assignment }) {
        if (doc.forceAdd) return interceptor.post(`${UrlBuilder.assignments}?force_add=${doc.forceAdd}`, doc.value);
        return interceptor.post(UrlBuilder.assignments, doc.value);
    },

    deleteAssignment(id: string) {
        return interceptor.delete(`${UrlBuilder.assignments}?assignment_id=${id}`);
    },

    updateAssignment(doc: Assignment) {
        return interceptor.patch(UrlBuilder.assignments, doc);
    },

    /// [stafff]
    getAssignmentStaffs(doc: { assignment_id: string, role: string }) {
        return interceptor.get(`${UrlBuilder.assignmentStaffs}?assignment_id=${doc.assignment_id}&role=${doc.role}`);
    },

    postAssignmentStaff(doc: ICreateAssignmentStaff) {
        return interceptor.post(UrlBuilder.assignmentStaffs, doc);
    },

    deleteAssignmentStaff(doc: IDeleteAssignmentStaff) {
        return interceptor.delete(UrlBuilder.assignmentStaffs, { data: doc });
    },
}