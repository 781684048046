import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, PayCycle } from "../../../../interfaces";
import * as thunk from "./pay_cycle_thunk";

export interface PayCycleState extends EntityState<PayCycle> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const payCycleAdapter: EntityAdapter<PayCycle> = createEntityAdapter<PayCycle>({
    selectId: (doc) => doc.id
})

const initialState: PayCycleState = payCycleAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const payCycleSlice = createSlice({
    name: 'payCycle',
    initialState: initialState,
    reducers: {
        clearPayCycleListError(state){
            return {...state, error: null};
        },
        clearPayCycleListState(state){
            return payCycleAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearPayCycleCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearPayCycleUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearPayCycleDeleteState(state) {
            return { ...state, remove : {...initialState.remove} };
        },
        clearPayCycleCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearPayCycleUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearPayCycleDeleteStateError(state) {
            return { ...state, remove : { ...initialState.remove , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPayCycles.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getPayCycles.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getPayCycles.fulfilled, (state, action) => payCycleAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createPayCycle.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createPayCycle.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createPayCycle.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deletePayCycle.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deletePayCycle.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deletePayCycle.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updatePayCycle.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updatePayCycle.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updatePayCycle.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const payCycleActions = payCycleSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = payCycleAdapter.getSelectors();
export const selectState = (state: PayCycleState) => state;
export const selectLoading = (state: PayCycleState) => state.loading;
export const selectError = (state: PayCycleState) => state.error;


