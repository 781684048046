
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./payment_terms_reducer";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.PaymentTermsList;
export const selectPaymentTermsListState = createSelector(select, selectState);
export const selectPaymentTermsList = createSelector(
    select,
    selectAll
);
export const selectPaymentTermsListEntities = createSelector(
    select,
    selectEntities
);