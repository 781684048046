import { createAsyncThunk } from "@reduxjs/toolkit";
import { catchRequestError } from "../../utils";
import { AppError } from "../../interfaces";
import { TextEmAllAPI } from "../../apis/text_em_all_api";
import { TEADraftBroadCast } from "../../interfaces/text_em_all";

export const getTEADraftBroadCast = createAsyncThunk<
  string,
  { forceSend: boolean | undefined, data: TEADraftBroadCast },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@text-em-all/draft-broadcast/post", async (doc, thunkAPI) => {
  try {
    const res = await TextEmAllAPI.getTextEmAllDraftBroadCast(doc);
    return res.data;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});

export const getTEAConversation = createAsyncThunk<
  string,
  { phone: string | undefined; talent_id: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@text-em-all/get-conversation/post", async (data, thunkAPI) => {
  try {
    const res = await TextEmAllAPI.getTextEmAllConversation(data);
    return res.data;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});
