import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { THEME } from "../../../../enums";
import { BulbIcon } from "../../../../icons";
import { CustomButton } from "../../../../utils";
import './info_text_popup.scss'

interface Props {
    visible: boolean;
    onClose: () => void;
    onDismiss: () => void;
    title: string;
    textContent: string;
}

const InfoTextPopup: React.FunctionComponent<Props> = (props) => {
    const { onClose, onDismiss, visible, title, textContent } = props;
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'30%'}
            maxWrapperWidth={'60%'}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="rc-info-text-popup-container">
                    <div className="rc-info-text-popup-header">
                        <FormDialogTilteHeader
                            title={title}
                            titleIcon={<BulbIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="rc-info-text-popup-content">
                        <div className="info-text" dangerouslySetInnerHTML={{ __html: textContent }}>

                        </div>
                    </div>
                    <div className="rc-info-text-popup-actions">
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('ok')}
                                backgroundColor={THEME.buttonColor1}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>

                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )

}

export default InfoTextPopup;