import "./onboarding_review_form.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import RenderInput from "../../../../components/render_input/render_input";
import { LinkIcon } from "../../../../icons";
import { FormInputOption } from "../../../../interfaces";
import { RegxPattern } from "../../../../utils";
import "./onboarding_review_form.scss";

export interface IReviewForm {
  name: string | null;
  recruiter_completed_review: boolean | string;
  reviewed_on_timestamp: string | number | null | Date;
  reviewed_by: string | null;
}

interface Props {
  formState: IReviewForm | undefined;
  //   talentData:IOnboardingTalentData | undefined;
  handleFieldChange: (id: string, value: any) => void;
  // talentName: string;
}



function getLinkIcon() {
  return (
    <div className="ob-cnt-link-icon">
      <LinkIcon width={"100%"} height={"100%"} />
    </div>
  );
}

const OnboardingReviewForm: React.FunctionComponent<Props> = (props) => {
  const { handleFieldChange, formState } = props;
  const { t } = useTranslation();
  const formOptions: (FormInputOption | FormInputOption[])[] = [
    [
      {
        field: "name",
        type: "text",
        label: "talent_name",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
        disabled: true,
        className:"",
  
      },
  
      {
        field: "reviewed_on_timestamp",
        type: "text",
        label: "form_reviewed",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
        disabled: true,
        className:"",
  
      },
      {
        field: "reviewed_by",
        type: "text",
        label: "reviewed_by",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
        disabled: true,
        className:"",
  
      },
      {
        field: "recruiter_completed_review",
        type: "text",
        label: "status",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
        className:formState?.recruiter_completed_review === "Reviewed" ? "status-span reviewed" : "status-span not-reviewed",
        
      },
    ],
  ];
  return (
    <div className="ob-cnt-inf-form-container">
      {formOptions.map((doc, index) => {
        if (Array.isArray(doc)) {
          return (
            <div key={index + "row"} className="ob-cnt-inf-form-row">
              {doc.map((subDoc, subIndex) => {
                return (
                  <RenderInput
                    key={index + subIndex}
                    doc={subDoc}
                    index={subIndex}
                    formState={formState}
                    handleFieldChange={handleFieldChange}
                    formValidators={formValidators}
                    className={"ob-cnt-inf-f-input-holder "+ subDoc.className}
                  />
                );
              })}
            </div>
          );
        }

        return (
          <>
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={formValidators}
              className={"ob-cnt-inf-f-input-holder "+ doc.className}
            />
          </>
        );
      })}
    </div>
  );

  function formValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case "first_name": {
        if (value.text === "") return t("validators.required");
        return null;
      }
      case "email": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.email.test(value.text) === false)
          return t("validators.enterValidEmail");
        return null;
      }
      case "phone": {
        if (!value.text) return null;
        if (RegxPattern.phone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      default: {
        return null;
      }
    }
  }
};

export default OnboardingReviewForm;
