import { useTranslation } from 'react-i18next';
import MultiUserSection from '../../../../../../components/multi_users_section/multi_users_section';
import {
    useAppSelector,
} from '../../../../../../redux/store';
import { LoadingType, ROLE, TALENT_SKILLS_TYPE } from '../../../../../../enums';
import { Spinner } from 'reactstrap';
import { selectContactDetailsByIdState } from '../../../../../../redux/contacts';
import { getUserNameFromProfileDropdown } from '../../../../../../utils';

interface Props {
    contactId: string,
    onAddOption: (type: string) => void,
    onRemoveOption: (type: string, value: {id: string, value: string}) => void,
}
const ContactsStaffDetails: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const contactState = useAppSelector((state) =>selectContactDetailsByIdState(props.contactId)(state));

    const isLoading = () => {
        return contactState.loading === LoadingType.pending
    }
    return (
        <div className="cd-aosr-container">
            {isLoading() ? <Spinner /> :
                <>  <MultiUserSection
                    headerTitle={t('account_manager').toUpperCase()}
                    actionName={t('add_account_manager')}
                    onClick={() => { props.onAddOption(TALENT_SKILLS_TYPE.account_managers) }}
                    list={(contactState.account_managers.data ?? []).map((doc, index) => ({ id: doc.id, value: getUserNameFromProfileDropdown(doc) }))}
                    onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.account_managers, doc)}
                />
                    <MultiUserSection
                        headerTitle={t('recruiter').toUpperCase()}
                        actionName={t('add_recruiter')}
                        onClick={() => { props.onAddOption(TALENT_SKILLS_TYPE.recruiters) }}
                        list={(contactState.recruiters.data ?? []).map((doc, index) => ({ id: doc.id, value: getUserNameFromProfileDropdown(doc) }))}
                        onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.recruiters, doc)}
                    />
                </>
            }
        </div>
    );
}

export default ContactsStaffDetails;