import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, MarketingCampaignJourneyStep } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getMarketingCampaignSteps = createAsyncThunk<
    Array<MarketingCampaignJourneyStep>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/marketing_campaign_journey_step/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getMarketingCampaignSteps();
            return res.data as MarketingCampaignJourneyStep[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createMarketingCampaignStep = createAsyncThunk<
    string,
    {'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/marketing_campaign_journey_step/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createMarketingCampaignStep(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteMarketingCampaignStep = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/marketing_campaign_journey_step/delete',
    async (step_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteMarketingCampaignStep(step_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateMarketingCampaignStep = createAsyncThunk<
    string,
    {'id': string, 'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/marketing_campaign_journey_step/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateMarketingCampaignStep(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);