import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ToggleStatus } from "../../../../interfaces/toggle_status";

export const updateToggleStatus = createAsyncThunk<
    string,
    ToggleStatus,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/user-management/toggle-status/get',
    async (toggle, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.UMtoggleStatus(toggle);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);