import { useEffect, useState } from "react";
import CustomFormSelect from "../../../components/form_selector/form_select";
import TabOptionsHeader from "../../../components/tab_options_header/tab_options_header";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { CustomButton, FormInput } from "../../../utils";
import { LoadingType, THEME } from "../../../enums";
import { RouteComponentProps } from "react-router-dom";
import { infoTextCategory } from "../../../variables";
import { getAdminCenterInfoText, selectInfoTextState, updateAdminCenterInfoText } from "../../../redux/admin_center";
import { AdminCenterInfoTextCategory } from "../../../interfaces/admin_center_info_text_category";
import { EditIcon } from "../../../icons";
import { INFO_TEXT_CATEGORY } from "../../../enums/info_text_category";
import ApiError from "../../../components/api_error";
import { clearAdminCenterInfoTextError } from "../../../redux/admin_center/info_text/info_text_reducer";

interface Props extends RouteComponentProps<any> { }

const InfoTextPage: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [infoText, setInfoText] = useState<AdminCenterInfoTextCategory>();
    const [infoTextType, setInfoTextType] = useState<string>(INFO_TEXT_CATEGORY.AGENCY_SETUP);
    const [inputEnable, setInputEnable] = useState<boolean>(true);

    const infoTextState = useAppSelector((state) => selectInfoTextState(state));

    useEffect(() => {
        dispatch(getAdminCenterInfoText(infoTextType));
    }, [infoTextType])

    useEffect(() => {
        if (infoTextState.loading === LoadingType.succeeded && infoTextState.data?.category === infoTextType) {
            setInfoText(infoTextState.data);
        }
    }, [infoTextType, infoTextState.loading])

    const handleEdit = () => {
        setInputEnable(false);
    };

    const handleFieldChange = (id, value) => {
        setInfoText({ ...infoText!, [id]: value });
    };

    const handleClearError = () => {
        dispatch(clearAdminCenterInfoTextError())
    }

    const handleSaveInfoText = () => {
        dispatch(updateAdminCenterInfoText(infoText))
    }

    return (
        <div className="ac-sc-container">
            <div className="ac-sc-header">
                <div className="ac-sytm-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="ac-sc-content">
                <div className="ac-sc-sp-content-pe">
                    <CustomFormSelect
                        name={t("info_category")}
                        list={infoTextCategory}
                        onChange={(val) => (setInfoTextType(val))}
                        value={infoTextType}
                        placeholder={"Select"}
                        label={t("info_category")}
                        customStyle={{ width: "30vw"}}
                        labelStyle={{ fontSize: "14px" }}
                    />
                </div>
                <div>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <button>
                            <EditIcon
                                width={"1vw"}
                                height={"1vw"}
                                style={{ color: "#fff" }}
                                onClick={handleEdit}
                            />
                        </button>
                    </div>
                    <div className="ac-sc-sp-content-pe">
                        <FormInput
                            id={"value"}
                            onChange={(id, val) => handleFieldChange(id, val)}
                            type={"text"}
                            value={infoText?.value ?? ""}
                            label={t("info_text")}
                            placeholder=""
                            required={false}
                            customStyle={{ minWidth: "15vw" }}
                            multiline={true}
                            disabled={inputEnable}
                        />
                    </div>
                    {infoTextState?.update?.error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={infoTextState?.update?.error?.message}
                                    onClose={handleClearError}
                                />
                            </div>
                        )}
                </div>
                <div className="ac-sc-ob-content-actions company-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("cancel")}
                            enable
                            backgroundColor={THEME.secondaryColor4}
                            onClick={() => {
                                setInfoText(infoTextState?.data!);
                                setInputEnable(true);
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t("save")}
                            loading={infoTextState.update.loading === LoadingType.pending}
                            enable={infoTextType ? true : false}
                            backgroundColor={THEME.buttonColor1}
                            onClick={handleSaveInfoText}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}


export default InfoTextPage;