import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { ManageCompetitorState } from "./manage_competitors_reducer";

export const selectState = (state: ManageCompetitorState) => state;
export const selectCreateState = (state: ManageCompetitorState) => state.create;
export const selectUpdate = (state: ManageCompetitorState) => state.update;

const select = (state: IRootState) => state.salesMarketing.competitorSheet.manageCompetitor;
export const selectManageCompetitorState = createSelector(select, selectState);
export const selectManageCompetitorCreateState = createSelector(
  selectManageCompetitorState,
  selectCreateState
);
export const selectManageCompetitorUpdateState = createSelector(
  selectManageCompetitorState,
  selectUpdate
);
