import { createSelector } from "@reduxjs/toolkit";
import { selectAll, selectState } from "./end_of_1st_days_calls_report_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.reportCenter.reportCenterService.endOFDCReportList;
export const selectEndOFDCReportListState = createSelector(select, selectState);
export const selectEndOFDCReportList = createSelector(
    select,
    selectAll
);
