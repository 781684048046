import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./contacts_list_reducer";

const select = (state: IRootState) => state.contacts.universalDirectory.contactsList;
export const selectContactsListState = createSelector(select, selectState);
export const selectContactsList = createSelector(
    select,
    selectAll
);
export const selectContactsListEntities = createSelector(
    select,
    selectEntities
);