import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./companies_list_reducer";

const select = (state: IRootState) => state.companies.universalDirectory.companiesList;
export const selectCompaniesListState = createSelector(select, selectState);
export const selectCompaniesList = createSelector(
    select,
    selectAll
);
export const selectCompaniesListEntities = createSelector(
    select,
    selectEntities
);