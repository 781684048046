import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./other_docs_thunk";
import { OtherDocs } from "../../../../interfaces/other_docs";

export interface OtherDocsState extends EntityState<OtherDocs> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const otherDocsAdapter: EntityAdapter<OtherDocs> = createEntityAdapter<OtherDocs>({
    selectId: (doc) => doc.id
})

const initialState: OtherDocsState = otherDocsAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const otherDocsSlice = createSlice({
    name: 'OtherDocs',
    initialState: initialState,
    reducers: {
        clearOtherDocsListError(state){
            return {...state, error: null};
        },
        clearOtherDocsListState(state){
            return otherDocsAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearOtherDocsCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearOtherDocsUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearOtherDocsDeleteState(state) {
            return { ...state, remove : {...initialState.remove} };
        },
        clearOtherDocsCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearOtherDocsUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearOtherDocsDeleteStateError(state) {
            return { ...state, remove : { ...initialState.remove , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getOtherDocs.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getOtherDocs.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getOtherDocs.fulfilled, (state, action) => otherDocsAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createOtherDocs.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createOtherDocs.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createOtherDocs.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteOtherDocs.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteOtherDocs.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteOtherDocs.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateOtherDocs.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateOtherDocs.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateOtherDocs.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const otherDocsActions = otherDocsSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = otherDocsAdapter.getSelectors();
export const selectState = (state: OtherDocsState) => state;
export const selectLoading = (state: OtherDocsState) => state.loading;
export const selectError = (state: OtherDocsState) => state.error;


