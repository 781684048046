import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities, selectById} from "./insurance_company_reducer";

const select = (state: IRootState) => state.riskManagement.universalDirectory.riskMgmtInsuranceCompany;
export const selectRiskMgmtInsuranceCompanyState = createSelector(select, selectState);
export const selectRiskMgmtInsuranceCompanies = createSelector(select, selectAll);
export const selectRiskMgmtInsuranceCompanyEntities = createSelector(
    select,
    selectEntities
);
export const selectRiskMgmtInsuranceCompanyByID = (id: string) => createSelector(
    select,
    (state) => selectById(state, id)
);