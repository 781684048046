import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IBusinessSector } from "../../../interfaces";
import * as thunk from "./business_sectors_thunk";

export interface BusinessSectorState extends EntityState<IBusinessSector> {
    error?: AppError | null,
    loading: LoadingType
}

const businessSectorAdapter: EntityAdapter<IBusinessSector> = createEntityAdapter<IBusinessSector>({
    selectId: (doc) => doc.id
})

const initialState: BusinessSectorState = businessSectorAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle
})


export const businessSectorSlice = createSlice({
    name: 'businessSector',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getBusinessSectorSelector.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getBusinessSectorSelector.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getBusinessSectorSelector.fulfilled, (state, action) => businessSectorAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = businessSectorSlice.actions;
export const { selectAll } = businessSectorAdapter.getSelectors();
export const selectState = (state: BusinessSectorState) => state;


