import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./company_list_reducer";

const select = (state: IRootState) => state.selectors.companiesDropdown;
export const selectCompaniesDropdownListState = createSelector(select, selectState);
export const selectCompaniesDropdownList = createSelector(
    select,
    selectAll
);

export const selectCompaniesDropdownListEntities = createSelector(
    select,
    selectEntities
);