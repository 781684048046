import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { THEME, LoadingType } from "../../../../enums";
import { INFO_TEXT_CATEGORY } from "../../../../enums/info_text_category";
import { AgencySetup, BulbIcon } from "../../../../icons";
import { selectInfoTextState, getAdminCenterInfoText } from "../../../../redux/admin_center";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CustomButton } from "../../../../utils";
import { clearAdminCenterInfoTextError } from "../../../../redux/admin_center/info_text/info_text_reducer";


interface Props {
    visible: boolean;
    onClose: () => void;
    onDismiss: () => void;
}

const WorkCompInfoTextPopUp: React.FunctionComponent<Props> = (props) => {
    const { onClose, onDismiss, visible } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const infoTextState = useAppSelector((state) => selectInfoTextState(state));

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        dispatch(getAdminCenterInfoText(INFO_TEXT_CATEGORY.WORK_COMP_CONFIGURATION));
    }, [])

    const handleClearError = () => {
        dispatch(clearAdminCenterInfoTextError())
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'70%'}
            maxWrapperWidth={'60%'}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="ac-info-text-popup-container">
                    <div className="ac-info-text-popup-header">
                        <FormDialogTilteHeader
                            title={t("overview_of_work_comp_configuration_in_kiniso_software_for_staffing_agencies")}
                            titleIcon={<BulbIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="ac-info-text-popup-content">
                        <div className="info-text">
                            <p>Welcome to KinISO Software, a powerful tool designed exclusively for staffing agencies to streamline their operations and enhance productivity. As a new super user, we are here to guide you through the various features and functionalities of KinISO. In this overview, we will focus on the "Work Comp Configuration" section, an essential component that plays a crucial role in managing workers' compensation information effectively.</p>
                            <b>What is Work Comp Configuration?</b>
                            <p>Work Comp Configuration is a specialized module within KinISO Software that allows staffing agencies to efficiently manage and configure workers' compensation settings and data. Workers' compensation is a critical aspect of staffing agency operations, and KinISO makes it easy to handle this complex area with precision and accuracy.</p>
                            <b>Key Features of Work Comp Configuration:</b>
                            <p><b>Carrier Information:</b> In this section, you can input and maintain detailed information about the workers' compensation insurance carriers your agency works with. This includes contact information, policy details, and coverage specifics for each carrier.</p>
                            <p><b>Class Codes:</b> KinISO allows you to set up and customize class codes that correspond to different types of work and job roles. You can configure specific workers' compensation rates and rules for each class code, ensuring accurate calculations and compliance with regulations.</p>
                            <p><b>Rate Tables:</b> With Rate Tables, you can define and manage workers' compensation rates for various states, regions, or classifications. KinISO provides the flexibility to set up rates based on factors such as payroll, hours worked, or per employee.</p>
                            <p><b>Experience Modification Factor (EMR):</b> The software allows you to input and track the Experience Modification Factor for your clients. This factor impacts insurance premiums and can be a vital metric in managing your clients' workers' compensation costs.</p>
                            <p><b>Claims Management:</b> KinISO provides a comprehensive platform to track and manage workers' compensation claims. You can input and monitor claims, assign responsible parties, and ensure timely resolution, reducing potential liabilities.</p>
                            <b>Benefits of Using Work Comp Configuration in KinISO:</b>
                            <p><b>Efficiency:</b> Streamline your workers' compensation management processes, reducing the time and effort required to maintain accurate records and calculations.</p>
                            <p><b>Accuracy:</b> Ensure precise calculations and compliance with state and industry regulations, minimizing errors and potential legal issues.</p>
                            <p><b>Cost Control:</b> By effectively managing workers' compensation data, you can better control costs and negotiate competitive insurance rates for your agency and clients.</p>
                            <p><b>Risk Mitigation:</b> Track claims and EMR to identify trends and address potential risks, ultimately improving workplace safety and reducing liability.</p>
                            <p>In conclusion, the Work Comp Configuration section in KinISO Software is a critical tool for staffing agencies, enabling efficient and accurate management of workers' compensation-related data and processes. As you delve into this module, you will find that it empowers your agency to better serve your clients, reduce operational complexities, and optimize your workers' compensation practices.</p>
                            <p>We encourage you to explore the features and functionalities of this section and reach out to our support team for any assistance you may require. Welcome to a more efficient and effective way of managing workers' compensation with KinISO!</p>
                        </div>
                        {infoTextState.update?.error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={infoTextState.update?.error?.message}
                                    onClose={handleClearError}
                                />
                            </div>
                        )}
                    </div>
                    <div className="ac-info-text-popup-actions">
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('ok')}
                                loading={infoTextState.update?.loading === LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )

}

export default WorkCompInfoTextPopUp