import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../enums";
import { ICompetitor, IProfileDropdown } from "../../../../interfaces";
import {
  getCompetitorsList,
  getRoleUsersList,
  postSnackbarMessage,
  selectCompetitorsList,
  selectCompetitorsListState,
  selectRoleUsersList,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  getPlusBtnIcon,
  getTableAccessIcon,
  getWebsiteInfo,
} from "../../../../utils";
import { AddCompetitor } from "./add_competitor";
import "./competitor_sheet.scss";
import SearchBar from "../../../../components/search_bar";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
  { title: "access_competitor", code: "access_competitor" },
  { title: "competitor", code: "competitor_name" },
  { title: "address", code: "street_address" },
  { title: "status", code: "status" },
  { title: "city", code: "city" },
  { title: "state", code: "state" },
  { title: "zip_code", code: "zip_code" },
  { title: "website", code: "website" },
  { title: "domain_expertise", code: "domain_expertise" },
  { title: "competitor_customers", code: "competitor_customers" },
];

const CompetitorSheet: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const [sortedField, setSortedField] = useState<string | null>("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");

  const dispatch = useAppDispatch();
  const [statusFilter, setStatusFilter] = useState<string>();
  const [modal, setModal] = useState(false);
  const usersList = useAppSelector((state) => selectRoleUsersList(state));

  const [usersListData, setUsersListData] = useState<IProfileDropdown[]>([]);

  const [selectedUser, setSelectedUser]: any = useState("");
  const [showInActive, setShowInActive] = useState(false);
 
    useEffect(() => {
    if (usersList.length !== 0) {
      // setUsersListData(usersList.filter(user => user.status === 'ACTIVE'))
      setUsersListData(usersList)
    }
  }, [usersList])

  function handleOnChange(e) {
    setShowInActive(showInActive ? false : true);
    // if(showInActive === false 
      // && usersList.filter(user => user.status === 'ACTIVE')
      // )
    // {
      // setSelectedUser("")
      // setUsersListData(usersList);
    // }
    // if(showInActive === false && usersList.filter(user => user.status === 'ACTIVE'))
    // {
    //   setSelectedUser("")
    //   setUsersListData(usersList);
    // }
    // else{
      // setUsersListData(usersList.filter(user => user.status === 'ACTIVE'))
    // }
  
  }
  const competitors: ICompetitor[] = useAppSelector((state) =>
    selectCompetitorsList(state)
  );


  const getCompetitors = () => dispatch(getCompetitorsList());
  const { loading, error } = useAppSelector((state) =>
    selectCompetitorsListState(state)
  );

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    getCompetitors();
    dispatch(getRoleUsersList());

  }, []);

  useEffect(() => {
    if (error != null && competitors.length != 0) {
      dispatch(postSnackbarMessage(error.message));
    }
  }, [error]);

  const getFilteredList = () => {
    let list = [...competitors];
    let sortedList: ICompetitor[] | undefined;
    if (showInActive === false) {
      sortedList = list.filter((doc) =>doc.status === "ACTIVE")
    }
    if (selectedUser?.length !== 0) {
      sortedList = (sortedList ?? list).filter((doc) => {
        const userIncluded = (doc.modified_by !== null ? selectedUser.includes(doc?.modified_by!) : selectedUser.includes(doc?.created_by!));
        return userIncluded;
      })
    }
    if (sortedField != null) {
      sortedList = [...(sortedList ?? list)].sort((a, b) => {
        const valueA =
          a[sortedField] != null
            ? typeof a[sortedField] != typeof 1 &&
              typeof a[sortedField] != typeof []
              ? a[sortedField].trim().toLowerCase()
              : typeof a[sortedField] === typeof []
                ? []
                : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] != typeof 1 &&
              typeof b[sortedField] != typeof []
              ? b[sortedField].trim().toLowerCase()
              : typeof b[sortedField] === typeof []
                ? []
                : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }


    // return sortedList;
    return (sortedList ?? list).filter((doc) => {
      const str = search.trim().toLowerCase();
      const nameFilter = doc.competitor_name
        ? doc.competitor_name.trim().toLowerCase().includes(str)
        : false;
      const cityFilter = doc.city
        ? doc.city.trim().toLowerCase().includes(str)
        : false;
      const addressFilter = doc.street_address
        ? doc.street_address.trim().toLowerCase().includes(str)
        : false;
      const statusFilter = doc.status
        ? doc.status.trim().toLowerCase().includes(str)
        : false;
      const stateFilter = doc.state
        ? doc.state.trim().toLowerCase().includes(str)
        : false;
      const zipcodeFilter = doc.zip_code
        ? doc.zip_code.trim().toLowerCase().includes(str)
        : false;
      const domainExpertiseFilter = doc.domain_expertise
        ? doc.domain_expertise.toString().trim().toLowerCase().includes(str)
        : false;
      const competitorCustomersFilter = doc.competitor_customers
        ? doc.competitor_customers.toString().trim().toLowerCase().includes(str)
        : false;

      return nameFilter || cityFilter || addressFilter || statusFilter || stateFilter || zipcodeFilter || domainExpertiseFilter || competitorCustomersFilter;
    });
  };

  const handleCompetitorSelect = (value): void => {
    props.history.push({
      pathname: `${AppRoutes.salesAndMarketingCompetitorSheet}/${value.id}/dashboard`,
      state: {
        id: value.id,
        name: value.competitor_name,
      },
    });
  };

  const showSpinner = () => loading === LoadingType.pending;

  const getTable = () => {
    if (showSpinner()) return <TableLoading />;

    if (error != null && competitors.length === 0) {
      return <TableErrorHandler error={error} onRefresh={getCompetitors} />;
    }

    if (competitors.length === 0 || getFilteredList()?.length === 0) {
      return <TableEmpty title={"No calls found"} onClick={getCompetitors} />;
    }

    if (getFilteredList()?.length !== 0) {
      return (
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList()?.map((doc) => {
            return (
              <tr key={doc.id}>
                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                  {getTableAccessIcon(() => handleCompetitorSelect(doc))}
                </TableData>
                <TableData
                  customStyle={{ flex: getFlexNumber(1) }}
                  isButton={true}
                  onClick={() => handleCompetitorSelect(doc)}
                >
                  <span>{doc.competitor_name}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                  <span>{doc.street_address}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                  <span>{doc.status}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                  <span>{doc.city}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                  <span>{doc.state}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                  {doc.zip_code}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                  <span style={{ height: "20px" }}>
                    {getWebsiteInfo(doc.website)}
                  </span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                  <span>{doc.domain_expertise.join(",")}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(9) }}>
                  <span>{doc.competitor_customers.join(",")}</span>
                </TableData>
              </tr>
            );
          })}
        </SortableTable>
      );
    }
  };

  function getFlexNumber(value: number) {
    if (value === 0) return 1;
    if (value === 1) return 2;
    if (value === 2) return 2;
    if (value === 3) return 1;
    if (value === 4) return 1.5;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 1;
    if (value === 8) return 3;
    if (value === 9) return 3;
    return 1;
  }

  const changeModelHandler = () => {
    setModal(!modal);
  };

 

  return (
    <div className="my-call-deck">
      <div className="filters-container">
        <div className="actions">
          <div className="table-header">
            <div>
              <CustomMultiFormSelect
                customStyle={{ width: "initial", marginLeft: "0em" }}
                // label={t("account_manager")}
                // name={"account_manager"}
                label={t("select_user")}
                name={"select_user"}
                value={selectedUser}
                placeholder={"All"}
                list={usersListData.map((doc) => ({
                  label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                  value: doc.id,
                }))}
                onChange={(value) => {
                  setSelectedUser(value);
                }}
              />
            </div>
            <div>
              <div className="active-checkbox">
                <input
                  type="checkbox"
                  checked={showInActive}
                  onChange={handleOnChange}
                />
                <span>{t("include_inactive")}</span>
              </div>
            </div>
            <div className="total-count-action">
              <span>
                {`${t("total_count")}: `}
                <span className="total-count-number">
                  {getFilteredList()?.length}
                </span>
              </span>
            </div>
          </div>
          <div className="cs-search-btn">
            <div className="cs-search">
              <SearchBar
                value={search}
                onChange={(value) => setSearch(value)}
                onSearch={() => { }}
              />
            </div>
            <div style={{ width: "auto" }}>
              <CustomButton
                leftIcon={getPlusBtnIcon()}
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("Add Competitor")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => setModal(true)}
              />
            </div>
          </div>
        </div>


      </div>
      {getTable()}
      {modal && (
        <Portal>
          <AddCompetitor
            showModal={modal}
            callbackHandler={changeModelHandler}
          />
        </Portal>
      )}
    </div>
  );
};

export default CompetitorSheet;
