import { createAsyncThunk } from "@reduxjs/toolkit";
import { OnboardingPortalAPI } from "../../../apis";
import { S3API } from "../../../apis/s3_api";
import { AppError } from "../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../utils";

/// upload file
export const uploadI9Thunk = createAsyncThunk<
  any,
  { url: string, file: File | Blob },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/i9/upload/put",
  async (payload, thunkAPI) => {
    try {
      const res = await S3API.uploadFile(payload.url, payload.file);
      // if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

/// upload url
export const getUploadI9UrlThunk = createAsyncThunk<
  { url: string },
  { token?: string, talent_id?: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/i9/upload-url/get",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.getI9UploadUrl(payload);
      if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

/// Download url
export const getDownloadI9UrlThunk = createAsyncThunk<
  { url: string },
  { token?: string, talent_id?: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/i9/download-url/get",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.getI9DownloadUrl(payload);
      if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);