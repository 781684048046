import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { THEME, LoadingType } from "../../../../enums";
import { AgencySetup } from "../../../../icons";
import { AppError } from "../../../../interfaces";
import { postSnackbarMessage, useAppDispatch } from "../../../../redux/store";
import { FormInput, CustomButton, allowNumbers, formatToMoney, convertNumberToMoney } from "../../../../utils";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { US_states } from "../../../../utils/States_US";
import "./add_sales_tax.scss"
import { createSalesTaxTaxConfigList, updateSalesTaxTaxConfigList } from "../../../../redux/admin_center";
import { payrollTaxConfigTaxLimitOptions, payrollTaxConfigTaxTypeOptions, salesTaxConfigTaxTypeOptions } from "../../../../variables";
import { AddSalesTaxConfiguration, SalesTaxConfiguration } from "../../../../interfaces/sales_tax_configuration";
import { SalesTaxConfigListState } from "../../../../redux/admin_center/sales_tax_configuration/sales_tax_configuration_reducer";
import ApiError from "../../../../components/api_error";

interface Props {
    visible: boolean;
    salesTaxState?: SalesTaxConfiguration;
    onClose: () => void;
    onDismiss: () => void;
    error: AppError | null | undefined;
    loading: LoadingType;
    onSuccessClose: () => void;
    onClearError: () => void;
    salesTaxListState: SalesTaxConfigListState;
}

const salesTaxInitialForm: AddSalesTaxConfiguration = {
    agency_id: "",
    id: "",
    sales_tax_name: "",
    customer_tax: "",
    tax_type_value: "",
    tax_rate: 0,
    tax_limit: "",
    tax_limit_cap: 0,
    state: "",
};

const getSalesTaxFormData = (value: SalesTaxConfiguration): AddSalesTaxConfiguration => {
    return {
        id: value.id ?? "",
        agency_id: value.agency_id ?? "",
        sales_tax_name: value.sales_tax_name ?? "",
        customer_tax: value.customer_tax ?? "",
        tax_type_value: value.tax_type_value ?? "",
        tax_rate: value.tax_rate ?? 0,
        tax_limit: value.tax_limit ?? "",
        tax_limit_cap: value.tax_limit_cap ?? 0,
        state: value.state ?? "",
    }
}

const SalesTaxPopUp: React.FunctionComponent<Props> = (props) => {
    const { onClose, onDismiss, visible, salesTaxState, loading, error, salesTaxListState, onClearError, onSuccessClose } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [salesTaxDetails, setSalesTaxDetails] = useState<AddSalesTaxConfiguration>(
        salesTaxState ? getSalesTaxFormData(salesTaxState) : salesTaxInitialForm
    );
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>();

    useEffect(() => {
        if (salesTaxListState.create.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage("Sales Tax Configuration added successfully!"));
            closePopup(onSuccessClose);
        }
        else if (salesTaxListState.update.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage("Sales Tax Configuration updated successfully!"));
            closePopup(onSuccessClose);
        }

    }, [salesTaxListState.create.loading, salesTaxListState.update.loading])

    function handleSave() {
        setCheckValidationOnSubmit(true);
        if (_isFormValid()) {
            if (salesTaxState?.id) {
                dispatch(updateSalesTaxTaxConfigList({
                    ...salesTaxDetails,
                    sales_tax_name: salesTaxDetails.sales_tax_name.trim(),
                    tax_limit_cap: convertNumberToMoney(salesTaxDetails.tax_limit_cap, 'number') as number,
                    tax_rate: convertNumberToMoney(salesTaxDetails.tax_rate, 'number') as number,
                }));
            }
            else {
                dispatch(createSalesTaxTaxConfigList({
                    ...salesTaxDetails,
                    sales_tax_name: salesTaxDetails.sales_tax_name.trim(),
                    tax_limit_cap: convertNumberToMoney(salesTaxDetails.tax_limit_cap, 'number') as number,
                    tax_rate: convertNumberToMoney(salesTaxDetails.tax_rate, 'number') as number,
                }));
            }
            setCheckValidationOnSubmit(false);
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function handleOnChange(id, value) {
        if (id === "tax_rate") {
            if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
                setSalesTaxDetails({ ...salesTaxDetails, tax_rate: value })
            }
        }
        else {
            setSalesTaxDetails({ ...salesTaxDetails, [id]: value });
        }
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'30%'}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="ac-stc-ast-popup-container">
                    <div className="ac-stc-ast-popup-header">
                        <FormDialogTilteHeader
                            title={salesTaxState && salesTaxState?.id ? "Edit Sales Tax" : "Add Sales Tax"}
                            titleIcon={<AgencySetup width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="ac-stc-ast-popup-content">
                        <div className={"ac-stc-ast-popup-input-holder"}>
                            <FormInput
                                id={"sales_tax_name"}
                                onChange={(id, val) => handleOnChange(id, val)}
                                placeholder={"Sales Tax Name"}
                                type={"text"}
                                value={salesTaxDetails.sales_tax_name}
                                required={true}
                                label={t("sales_tax_name")}
                                // disabled={salesTaxState?.id ? true : false}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                            />
                        </div>
                        <div className="ac-stc-ast-popup-section-divider">
                            <div className={"ac-stc-ast-popup-input-holder"}>
                                <CustomFormSelect
                                    list={payrollTaxConfigTaxLimitOptions}
                                    name={"customer_tax"}
                                    value={salesTaxDetails.customer_tax}
                                    required={false}
                                    label={t("customer_tax")}
                                    placeholder={"Select"}
                                    onChange={(val) => handleOnChange('customer_tax', val)}
                                />
                            </div>
                            <div className={"ac-stc-ast-popup-input-holder"}>
                                <CustomFormSelect
                                    list={salesTaxConfigTaxTypeOptions}
                                    name={"tax_type_value"}
                                    value={salesTaxDetails.tax_type_value}
                                    required={false}
                                    label={t("tax_type")}
                                    placeholder={"Select"}
                                    onChange={(val) => handleOnChange('tax_type_value', val)}
                                />
                            </div>
                        </div>
                        <div className="ac-stc-ast-popup-section-divider">
                            <div className={"ac-stc-ast-popup-input-holder"}>
                                <FormInput
                                    id={'tax_rate'}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    required={false}
                                    placeholder={""}
                                    type={"text"}
                                    value={salesTaxDetails.tax_rate}
                                    label={t("tax_rate")}
                                    suffix={<span>%</span>}
                                    onKeyUp={(e) => { allowNumbers(e); }}
                                    onKeyDown={(e) => { allowNumbers(e); }}

                                />
                            </div>
                            <div className={"ac-stc-ast-popup-input-holder"}>
                                <CustomFormSelect
                                    list={payrollTaxConfigTaxLimitOptions}
                                    name={"tax_limit"}
                                    value={salesTaxDetails.tax_limit}
                                    required={false}
                                    label={t("tax_limit")}
                                    placeholder={"Select"}
                                    onChange={(val) => handleOnChange('tax_limit', val)}
                                />
                            </div>
                        </div>
                        <div className="ac-stc-ast-popup-section-divider">
                            <div className={"ac-stc-ast-popup-input-holder"}>
                                <FormInput
                                    id={'tax_limit_cap'}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    required={false}
                                    placeholder={""}
                                    type={"text"}
                                    value={convertNumberToMoney(salesTaxDetails.tax_limit_cap, "string") as string}
                                    label={t("tax_limit_cap")}
                                    prefix={<span>$</span>}
                                    onBlur={formatToMoney}
                                    onKeyDown={formatToMoney}
                                    onKeyUp={formatToMoney}

                                />
                            </div>
                            <div className={"ac-stc-ast-popup-input-holder"}>
                                <CustomFormSelect
                                    list={US_states.map((doc) => ({
                                        label: doc.name,
                                        value: doc.code,
                                    }))}
                                    name={"state"}
                                    value={salesTaxDetails.state}
                                    required={false}
                                    label={t("state")}
                                    placeholder={"Select"}
                                    onChange={(val) => handleOnChange('state', val)}
                                    menuPlacement={"top"}
                                />
                            </div>
                        </div>
                        {error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={error?.message}
                                    onClose={onClearError}
                                />
                            </div>
                        )}
                    </div>
                    <div className="ac-stc-ast-popup-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={(salesTaxState && salesTaxState?.id) ? t('update') : t('add')}
                                // enable={_isFormValid()}
                                enable={true}
                                loading={loading === LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={handleSave}
                            />
                        </div>

                    </div>
                </div>
            </DialogWrapper>


        </ActionDialogHolder>
    )

    function _isFormValid() {
        const { sales_tax_name } = salesTaxDetails;
        if (sales_tax_name === '') return false;
        return true;
    }

    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "sales_tax_name": {
                if (value.text === "") return t("validators.required");
                return null;
            }
            default: {
                return null;
            }
        }
    }
}

export default SalesTaxPopUp