import { combineReducers } from '@reduxjs/toolkit';
import { ImportUrlSlice } from './import_data_file_reducer';
import { uploadedFileStatusSlice } from './upload_file_status/upload_file_status_reducer';

export const importRecordReducers = combineReducers({
    [ImportUrlSlice.name]: ImportUrlSlice.reducer,
    [uploadedFileStatusSlice.name]: uploadedFileStatusSlice.reducer,
});

export * from './import_data_file_selector';
export * from './import_data_file_thunk';

export * from './upload_file_status/upload_file_status_selector';
export * from './upload_file_status/upload_file_status_thunk';
