
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./processing_method_reducer";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.ProcessingMethodList;
export const selectProcessingMethodListState = createSelector(select, selectState);
export const selectProcessingMethodList = createSelector(
    select,
    selectAll
);
export const selectProcessingMethodListEntities = createSelector(
    select,
    selectEntities
);