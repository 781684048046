import { combineReducers } from "@reduxjs/toolkit";
import { talentODRSSlice } from "./application_status/application_status_reducer";
import { talentCertificationsSlice } from "./certifications_other_docs/certifications_other_docs_reducer";
import { talentCrimeHistorySlice } from "./crime_history/crime_history_reducer";
import { talentDocsParserSlice } from "./talent_w4_i9/talent_w4_i9_reducer";

export const talentOnBoardingAndDocsReducers = combineReducers({
    [talentODRSSlice.name]: talentODRSSlice.reducer,
    [talentCertificationsSlice.name]: talentCertificationsSlice.reducer,
    [talentCrimeHistorySlice.name]: talentCrimeHistorySlice.reducer,
    [talentDocsParserSlice.name]: talentDocsParserSlice.reducer

    
});

export * from './application_status/application_status_selector';
export * from './application_status/application_status_thunk';

export * from './certifications_other_docs/certifications_other_docs_selector';
export * from './certifications_other_docs/certifications_other_docs_thunk';

export * from './crime_history/crime_history_selector';
export * from './crime_history/crime_history_thunk';

export *  from "./talent_w4_i9/talent_w4_i9_selector";
export *  from "./talent_w4_i9/talent_w4_i9_thunk";
