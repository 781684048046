import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AppError,
  AgencyOnboarding,
  IHandbookUploadUrls,
  IHandbookUploadUrlsQuery,
} from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { AdminCenterAPI } from "../../../../apis/admin_center_api";
import { S3API } from "../../../../apis/s3_api";

export const getAgencyOnBoarding = createAsyncThunk<
  AgencyOnboarding,
  void,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@admin_center/system_configuration/onboarding/get", async (_, thunkAPI) => {
  try {
    const res = await AdminCenterAPI.getAgencyOnboarding();
    return res.data as AgencyOnboarding;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});

export const updateAgencyOnboarding = createAsyncThunk<
  string,
  AgencyOnboarding,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@admin_center/system_configuration/onboarding/update",
  async (data, thunkAPI) => {
    try {
      const res = await AdminCenterAPI.updateAgencyOnboarding(data);
      return res.data as string;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const getAgencyOnboardingHandbookUploadURL = createAsyncThunk<
  IHandbookUploadUrls,
  IHandbookUploadUrlsQuery,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@admin_center/system_configuration/onboarding/handbook-upload-url/get",
  async (query, thunkAPI) => {
    try {
      const res = await AdminCenterAPI.getPreAgencyOnboardingHandbookUploadURL(
        query
      );
      return res.data['data'] as IHandbookUploadUrls;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const uploadAgencyOnboardingHandbookURL = createAsyncThunk<
  any,
  { data: IHandbookUploadUrls, handbook?: File | null,
    //  onboarding: AgencyOnboarding 
    },

  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@admin_center/system_configuration/onboarding/handbook-upload-url/post",
  async (payload, thunkAPI) => {
    try {
      const res = await S3API.uploadAgencyOnboardingHandbookFile(payload.data.handbook_upload_url.url, payload.handbook);
      // thunkAPI.dispatch(updateAgencyOnboarding(payload.onboarding));

      return res;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);


export const getHandbookFileDownloadURL = createAsyncThunk<
    any,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/system_configuration/onboarding/handbook-download-url/get',
    async (_, thunkAPI) => {
        try {
            const res = await S3API.getAgencyOnboardingHandbookDownloadURL();
            return res.data['data']['handbook_download_url'];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);