import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper, { FormDialogTilteHeader } from '../../../components/dialog_wrapper/dialog_wrapper';
import RenderInput from '../../../components/render_input/render_input';
import { LoadingType, THEME } from '../../../enums';
import { OnBoardingIcon, RightArrowIcon } from '../../../icons';
import { FormInputOption, ITalentOnBoardingData } from '../../../interfaces';
import { getDownloadEssentialStaffcareUrlThunk, getESCEnrollmentURLThunk, selectEssentialStaffcareState, selectProfileState, useAppDispatch, useAppSelector } from '../../../redux/store';
import { CustomButton } from '../../../utils';
import './essential_staffcare.scss';
import { essentialStaffcareSliceActions } from '../../../redux/onboarding_portal/essential_staffcare/essential_staffcare_reducer';

interface ESCProps {
    employee_social_security_number: string,
    employee_first_name: string,
    employee_last_name: string,
}
interface Props {
    visible: boolean,
    type: string,
    title: string,
    token: string,
    state: ITalentOnBoardingData,
    successTitle: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: (type: string, value?: any) => void,
}

const EssentialStaffcarePopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        token,
        state,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const enrollmentURLState = useAppSelector((state) => selectEssentialStaffcareState(state));

    const [formState, setFormState] = useState<ESCProps>({
        employee_social_security_number: "",
        employee_first_name: "",
        employee_last_name: "",
    });

    const formInputs: (FormInputOption | FormInputOption[])[] = [
        {
            field: "employee_social_security_number",
            type: "text",
            label: "employee_social_security_number",
            secure: false,
            required: true,
            placeholder: "",
            keyboardtype: "",
        },
        {
            field: "employee_first_name",
            type: "text",
            label: "employee_first_name",
            secure: false,
            required: false,
            placeholder: "",
            keyboardtype: "",
        },
        {
            field: "employee_last_name",
            type: "text",
            label: "employee_last_name",
            secure: false,
            required: false,
            placeholder: "",
            keyboardtype: "",
        },

    ];

    function handleFieldChange(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setFormState({
                ...formState,
                [parent]: { ...formState[parent], [fieldId]: value },
            });
        } else {
            setFormState({ ...formState, [fieldId]: value });
        }
    }

    useEffect(() => {
        dispatch(getESCEnrollmentURLThunk({token:token, data:{}}));
        return () => { }
    }, [])

    function handleClose() {
        dispatch(essentialStaffcareSliceActions.clearState());

        closePopup(onClose);
    }

    function getArrowRightIcon() {
        return (
            <div className="btn-icon">
                <RightArrowIcon width={"100%"} height={"100%"} style={{ color: '#fff' }} />
            </div>
        )
    };
    const handleRedirect = () => {
        if (enrollmentURLState.loading === LoadingType.succeeded && enrollmentURLState.response !== null) {
            window.location.href = `${enrollmentURLState.response}`;
            // window.open(`${screeningURLState.response}`,"_blank");
        }
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClose}>
                <div className="ess-stff-container">
                    <div className="ess-stff-header">
                        <FormDialogTilteHeader
                            title={title}
                            subTitle={''}
                            subTitleStyle={{ fontWeight: 'normal' }}
                            titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="ess-stff-content">
                        <span>{t('redirect_to_esc_enrollment')}</span>

                        {/* <div className="ess-stff-form">
                            {formInputs.map((doc, index) => {
                                if (Array.isArray(doc)) {
                                    return (
                                        <div key={index + "row"} className="cmpny-f-row ">
                                            {doc.map((subDoc, subIndex) => {
                                                return (
                                                    <RenderInput
                                                        key={index + subIndex}
                                                        doc={subDoc}
                                                        index={subIndex}
                                                        formState={formState}
                                                        handleFieldChange={handleFieldChange}
                                                    // formValidators={companyFormValidators}
                                                    // className={"cmpny-f-input-holder"}
                                                    />
                                                );
                                            })}
                                        </div>
                                    );
                                }

                                return (
                                    <RenderInput
                                        key={index}
                                        doc={doc}
                                        index={index}
                                        formState={formState}
                                        handleFieldChange={handleFieldChange}
                                    // formValidators={companyFormValidators}
                                    // className={"cmpny-f-input-holder"}
                                    />
                                );
                            })}
                            <div className="ess-stff-visit">
                                <CustomButton
                                    name={t('visit_essential_staff_care')}
                                    onClick={() => { window.open("https://essentialstaffcare.com/") }}
                                    rightIcon={getArrowRightIcon()}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    customStyle={{ width: "-webkit-fill-available", justifyContent: 'space-between' }}
                                />
                            </div>

                        </div>
                        <div className="ess-stff-logo">
                            <div className="ess-staff-image-icon">
                                <img src={"https://essentialstaffcare.com/wp-content/uploads/2020/03/esc-logo-2020.gif"} alt={"essentialstaffcare"} width={"100%"} height={"100%"} />
                            </div>
                        </div> */}
                    </div>
                    <div className="ess-stff-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-new">
                            <CustomButton
                                loading={false}
                                textStyle={{
                                    textTransform: "capitalize",
                                }}
                                name={t("visit_essential_staff_care")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleRedirect}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>

    );

    ///Handle clear error
    function handleClearError() {

    }

    function closePopup(action?: () => void) {
        dispatch(essentialStaffcareSliceActions.clearState());

        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default EssentialStaffcarePopup;