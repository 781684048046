import React, { useRef } from 'react'
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder'
import DialogWrapper, { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { ApplicationReceivedIcon } from '../../../../icons';
import { t } from "i18next";
import { CustomButton } from '../../../../utils';
import { THEME } from '../../../../enums';

interface ArchiveOnboardingLink {
    currentLoginUserName: string,
    onboardingCandidate: string | undefined
    visible: boolean,
    onClose: () => void,
    handlePopUpOpen: () => void
    onDissmiss: () => void
}

const ArchiveOnboardingLink: React.FunctionComponent<ArchiveOnboardingLink> = (props) => {
    const actionRef = useRef<ActionDialogHolderType>(null);
    
    const closePopup = (action?: () => void) => {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    };

    return (
        <div>
            <ActionDialogHolder
                ref={actionRef}
                visible={props.visible}
                wrapperWidth={"50%"}
                onClose={props.onDissmiss}
                onDissmiss={props.onDissmiss}
            >
                <DialogWrapper onClose={() => closePopup(props.onDissmiss)}>
                    <div className="uf-container contact-form">
                        <div className="uf-header">
                            <FormDialogTilteHeader
                                title={"Onboarding Link"}
                                titleIcon={
                                    <ApplicationReceivedIcon width={"100%"} height={"100%"} />
                                }
                            />
                        </div>
                        <div className="ad-dw-content">
                            <span style={{ fontSize: "1.041vw", color: "#0B3E78", textTransform: "capitalize" }}>
                                {t('hey') + " " + props.currentLoginUserName + ","}
                            </span>
                            <span style={{ fontSize: "1.041vw", color: "#0B3E78", textTransform: "none" }}>
                                {t('you_have_moved') + " " + props.onboardingCandidate + " " + t('to_the_archived_directory')}
                            </span>
                        </div>
                    </div>
                    <div className="actions-btn">
                        <div className="btn-bp" style={{ marginRight: '1em' }}>
                            <CustomButton
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("ok")}
                                enable={true}
                                backgroundColor={THEME.statusActiveColor}
                                onClick={props.onClose}
                            />
                        </div>
                    </div>

                </DialogWrapper>
            </ActionDialogHolder>
        </div>
    )
}

export default ArchiveOnboardingLink