import React from 'react';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolderType, ActionDialogHolder } from '../../../../components/action_dialog_holder/action_dialog_holder';
import ApiError from '../../../../components/api_error';
import { LoadingType, ROLE, TALENT_SKILLS_TYPE, THEME } from '../../../../enums';
import { ISkillOption, IRemoveSkill, IRemoveJobStaff, IDeleteAssignmentStaff } from '../../../../interfaces';
import {
    selectAssignmentDetailsByIdState,
    removeAssignmentAccountManagers,
    removeAssignmentRecruiters,
} from '../../../../redux/store';
import { useAppDispatch, useAppSelector, postSnackbarMessage } from '../../../../redux/store';
import { CustomButton } from '../../../../utils';
import './remove_option.scss';
import { assignmentDetailsSliceActions } from '../../../../redux/assignments/details/dashboard/assignment_details/assignment_details_reducer';



interface Props {
    type: string,
    visible: boolean,
    assignment_id: string,
    data: { id: string, value: string },
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

export const RemoveAssignmentOptionPopup = (props: Props) => {
    const {
        type,
        data,
        assignment_id,
        visible,
        onClose,
        onDissmiss,
        onSuccessClose
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);

    /// Create optin states
    const assignmentState = useAppSelector((state) => selectAssignmentDetailsByIdState(assignment_id)(state));
    const accountManagerRemoveState = assignmentState.account_managers.remove;
    const recruitersRemoveState = assignmentState.recruiters.remove;

    useEffect(() => {
        if (accountManagerRemoveState.loading === LoadingType.succeeded ||
            recruitersRemoveState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(accountManagerRemoveState.response ||
                recruitersRemoveState.response));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [
        
        accountManagerRemoveState.loading,
        recruitersRemoveState.loading,
    ])

    function isAddLoading() {
        return accountManagerRemoveState.loading === LoadingType.pending ||
            recruitersRemoveState.loading === LoadingType.pending;;
    }

    function getError() {
        return accountManagerRemoveState.error ||
            recruitersRemoveState.error;;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={430}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <div className="del-role-container">
                <div className="del-role-header">
                    <div className="title-txt">
                        <span>{`${t('attention')}!!`}</span>
                    </div>
                </div>
                <div className="del-role-content">
                    <div className="message-txt">
                        <span>{t('are_you_sure_you_want_to_delete', { value: `${data.value} ${t(type)}` })}</span>
                    </div>
                    {getError() && <div className={"error-section"}>
                        <ApiError message={getError()?.message} onClose={handleClearError} />
                    </div>}


                </div>
                <div className="del-role-action-holder">
                    <div className="del-role-action">


                        <div className="btn-no">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.secondaryColor4}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-yes">
                            <CustomButton
                                loading={isAddLoading()}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('delete')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleDeleteOption}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ActionDialogHolder>

    );

    function handleDeleteOption() {
        switch (type) {
            case TALENT_SKILLS_TYPE.account_managers: {
                const payload: IDeleteAssignmentStaff = {
                    assignment_id: assignment_id,
                    user_id: data.id,
                    role: ROLE.accountManager,
                }
                dispatch(removeAssignmentAccountManagers(payload))
                break;
            }
            case TALENT_SKILLS_TYPE.recruiters: {
                const payload: IDeleteAssignmentStaff = {
                    assignment_id: assignment_id,
                    user_id: data.id,
                    role: ROLE.recruiter,
                }
                dispatch(removeAssignmentRecruiters(payload));
                break;
            }
            // case TALENT_SKILLS_TYPE.professional_skills:
            // case TALENT_SKILLS_TYPE.credentials:
            // case TALENT_SKILLS_TYPE.certifications:
            // case TALENT_SKILLS_TYPE.education:
            // case TALENT_SKILLS_TYPE.safety_equipment: {
            //     const payload: IRemoveSkill = {
            //         skill_ids: [
            //             data.id,
            //         ],
            //         skill_type: type,
            //     }
            //     dispatch(removeJobSkills({ assignment_id: assignment_id, value: payload }))
            //     break;
            // }
            default: { break; }
        }


    }

    function handleClearError() {
        dispatch(assignmentDetailsSliceActions.clearAssignmentDetailsRemoveOptionError({ id: assignment_id, type: type }));
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}