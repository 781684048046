import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../components/action_dialog_holder/action_dialog_holder";
import { THEME } from "../../enums";
import { CustomButton } from "../../utils";
import './delete_confirmation_dialog.scss';
import { CloseIcon, DeleteIcon } from "../../icons";
import { FormDialogTilteHeader } from "../../components/dialog_wrapper/dialog_wrapper";
import ApiError from "../../components/api_error";

export interface DeleteConfirmationDialogProps {
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  deleting: boolean;
  deleteType: string;
  state?: any;
  customDeleteHeaderTitle?: string;
  onClearError: () => void;
}

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = (
  props: DeleteConfirmationDialogProps
) => {
  const { message, onCancel, onConfirm, deleting, deleteType, state, onClearError, customDeleteHeaderTitle } = props;
  const { t } = useTranslation();
  const actionRef = useRef<ActionDialogHolderType>(null);

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible
      wrapperWidth={"30%"}
      maxWrapperWidth={"40%"}
      onClose={onCancel}
      onDissmiss={onCancel}
    >
      <div className="common-dialog-delete-container" style={{ height: "150px" }}>
        <div className="delete-dialog-header">
          <div className="header-content">
            <div className="del-icon">
              <DeleteIcon width={"1vw"} height={"1vw"} color="red" />
            </div>
            <div className="header-title">
              <span>
                {customDeleteHeaderTitle && customDeleteHeaderTitle !== "" ? customDeleteHeaderTitle : t("delete_field", { name: deleteType })}
              </span>
            </div>
          </div>
          <div>
            <CloseIcon width={"1vw"} height={"1vw"} onClick={() => {
              closePopup(onCancel);
              onClearError();
            }} />
          </div>
        </div>
        <div className="delete-dialog-content">
          <span></span>
          <span>{message}</span>
        </div>
        <div className="delete-dialog-footer">
          <div className="error-holder">
            {state?.error && <ApiError message={state.error.message} onClose={onClearError} />}
          </div>
          <div className="del-actions">
            <div className="btn-cancel">
              <CustomButton
                textStyle={{ textTransform: "capitalize" }}
                name={t("no")}
                enable={true}
                onClick={() => {
                  closePopup(onCancel);
                  onClearError();
                }}
              />
            </div>
            <div className="btn-save">
              <CustomButton
                textStyle={{ textTransform: "capitalize" }}
                name={t("yes")}
                enable={true}
                backgroundColor={""}
                onClick={onConfirm}
                loading={deleting}
              />
            </div>
          </div>
        </div>
      </div>
    </ActionDialogHolder>
  );
};
