import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";

export const getPayType = createAsyncThunk<
    Array<AdminCenterBackOfficeType>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/back_office/pay_type/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getPayTypeDetails();
            return res.data as AdminCenterBackOfficeType[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createPayType = createAsyncThunk<
    string,
    { 'value': string, 'description': string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/pay_type/post',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.createPayTypeDetails(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );

export const updatePayType = createAsyncThunk<
    string,
    { 'id': string, 'value': string, 'description': string, 'agency_id': string, 'created_by': string, 'is_deleted': boolean, 'modified_by': string, 'modified_date': number, },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/pay_type/update',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.updatePayTypeDetails(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );

export const deletePayType = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/pay_type/delete',
        async (option_id, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.deletePayTypeDetails(option_id);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );