import { AddCircleOutline, ArrowUpward, Delete } from '@material-ui/icons';
import { Portal } from 'react-portal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import UserProfileSection from '../../../components/user_profile_section/user_profile_section';
import { LoadingType, THEME } from '../../../enums';
import { AdminCenterIcon, DeleteIcon, EditIcon, LockIcon } from '../../../icons';
import { getAdminCenterUserManagementUserDetails, refreshUserDetails, selectManageUserToggleRoleState, selectUserDetailsState, toggleUserRole } from '../../../redux/admin_center';
import { postSnackbarMessage, useAppDispatch, useAppSelector } from '../../../redux/store';
import { CustomButton, SpinnerScreen, getUserRoleText, getPlusBtnIcon } from '../../../utils';
import { formatAMPM, getDateFromTimeStamp, getDateString } from '../../../variables';
import { AddRoleAction } from './action';
import './user_settings_page.scss';
import { ProfileRole, ToggleRoleData } from '../../../interfaces';
import { manageUserActions } from '../../../redux/admin_center/user_management/manage_user/manage_user_reducer';
import CreateUser from './create_user';
import { clearUserDetailsState } from '../../../redux/admin_center/user_management/user_details/user_details_reducer';
import { TableErrorHandler } from '../../../components/table_empty/table_empty';
import ToggleSwitch from '../../../components/toggle_switch/toggle_switch';
import CurveTabNavigation from '../../../components/curve_tab_navigation/curve_tab_navigation';
import TextEmAllPage from '../integration_partners/text_em_all/text_em_all';
import { DeleteConfirmationDialog } from '../../components/delete_confirmation_dialog';

interface Props extends RouteComponentProps<any> { }

const tabOptions = [
    {
        title: "dashboard",
    }
];

const tableHeader = [
    { title: 'role', code: 'role' },
    { title: 'member_since', code: 'member_since' },
    { title: 'actions', code: '' },
];

const UserSettingsPage: React.FunctionComponent<Props> = (props: Props) => {
    const dispatch = useAppDispatch();
    const { params } = props.match;
    const { loading, error, profile } = useAppSelector((state) => selectUserDetailsState(state));
    const userToggleState = useAppSelector((state) => selectManageUserToggleRoleState(state));
    const { t } = useTranslation();
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [showAddRoleAction, setShowAddRoleAction] = useState<boolean>(false);
    const [showDeleteAction, setShowDeleteAction] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<ProfileRole | null>();
    const [editUser, setEditUser] = useState<boolean>(false);
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [sortDirection, setSortDirection] = useState('asc');

    useEffect(() => {
        getUserDetails();
        dispatch(manageUserActions.clearToggleRoleState());
        return () => {
            dispatch(clearUserDetailsState());
        }
    }, [])

    useEffect(() => {
        if (userToggleState?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(userToggleState?.response ?? null));
            handleOnSuccessDeleteRoleDialog()
        }
        return () => { }
    }, [userToggleState?.loading])

    function getUserDetails() {
        if (params && params.id) dispatch(getAdminCenterUserManagementUserDetails(params.id));
    }

    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }

    function getFilteredList() {
        if (profile == null) return [];
        let list;
        if (sortedField != null) {
            list = [...profile?.roles].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return list ?? profile?.roles
    }

    function handleDeleteUserRole() {
        if (selectedRole?.role != null) {
            const credentials: ToggleRoleData = {
                role: selectedRole?.role,
                add: false,
                user_id: params.id,
            };
            dispatch(toggleUserRole(credentials));

        }
    }

    function handleClearError() {
        dispatch(manageUserActions.clearToggleRoleStateError());
    }


    return (

        <div className="ac-um-usp-container">
            <div className="ac-um-usp-header">
                <TabOptionsHeader list={tabOptions} onClose={() => props.history.goBack()} />
            </div>
            {handleUserDetails()}
              {showDeleteAction && (
                <Portal>
                    <DeleteConfirmationDialog
                        message={t('are_you_sure_you_want_to_delete_role', { value: selectedRole?.role })}
                        onCancel={() => setShowDeleteAction(false)}
                        onConfirm={handleDeleteUserRole}
                        deleting={userToggleState?.loading === LoadingType.pending}
                        deleteType={t("Role")}
                        customDeleteHeaderTitle={t("attention_alert")}
                        state={userToggleState}
                        onClearError={handleClearError}
                    />
                </Portal>
            )}

            {showAddRoleAction && profile != null && <Portal>
                <AddRoleAction
                    userRoles={profile?.roles.map(doc => doc.role)}
                    userId={params.id}
                    visible={showAddRoleAction}
                    onClose={handleCloseAddDialog}
                    onDissmiss={handleCloseAddDialog}
                    onSuccessClose={handleOnSuccessAddDialog}
                />
            </Portal>}

            {editUser && profile && profile.branch_id && <Portal>
                <CreateUser
                    visible={editUser}
                    title={t('edit_user')}
                    user={profile}
                    branchId={profile?.branch_id}
                    onClose={() => setEditUser(false)}
                    onSuccessClose={handleEditUserSuccess}
                />
            </Portal>}
        </div>
    );

    /// User details screen
    function handleUserDetails() {
        if (loading === LoadingType.pending) {
            return (
                <DetailsLoading />
            );
        }
        if (error != null) {
            return (
                <TableErrorHandler error={error} onRefresh={getUserDetails} />
            )
        }
        return (
            <div className="ac-um-usp-content">
                <div className="ac-um-usp-profile-container">
                    <UserProfileSection profile={profile} />
                </div>
                <div className="ac-um-usp-settings-content">
                    <CurveTabNavigation
                        tabs={getTabList()}
                        selected={tabIndex}
                        onChange={(value) => setTabIndex(value)}
                    // actions={getEditAction()}
                    />
                    {/* <div className="settings-header">
                        <div className="setting-header-tab">
                            <div className="tab-icon">
                                <AdminCenterIcon width={"100%"} height={"100%"} style={{ color: THEME.white, }} />
                            </div>
                            <div className="tab-title">
                                <span>{t('user_settings').toUpperCase()}</span>
                            </div>
                        </div>
                    </div>
                    <div className="header-strap"></div> */}

                </div>

            </div>
        )
    }

    function getTabList() {
        return [
            {
                title: t('user_settings').toUpperCase(),
                icon: AdminCenterIcon,
                content: getUserSettingsPage(),
            },
        ];
    }

    function getUserSettingsPage() {
        return (
            <div className="settings-container">
                <SectionPanel title={t('').toUpperCase()} actions={getEditUserAction()}>
                    <div className='ac-um-usp-toggle'>
                        <div>
                            <ToggleSwitch
                                onChange={(value) => { }}
                                label={t("view_ssn")}
                                checked={profile && profile.show_ssn ? profile.show_ssn : false}
                            />
                        </div>
                        <div className='ac-um-usp-ml'>
                            <ToggleSwitch
                                onChange={(value) => { }}
                                label={t("can_send_text")}
                                checked={profile && profile.texting ? profile.texting : false}
                            />
                        </div>
                        <div className='ac-um-usp-ml'>
                            <ToggleSwitch
                                onChange={(value) => { }}
                                label={t("view_onboarding_data")}
                                checked={profile && profile.view_onboarding_data ? profile.view_onboarding_data : false}
                            />
                        </div>
                        <div className='ac-um-usp-ml'>
                            <ToggleSwitch
                                onChange={(value) => { }}
                                label={t("search_and_import_data")}
                                checked={profile && profile.search_and_import_data ? profile.search_and_import_data : false}
                            />
                        </div>
                        {/* <div className="ac-um-usp-password-reset">
                                <div className="ac-um-usp-password-reset-header">
                                    <div className="header-txt">
                                        <span>{t('password')}</span>
                                    </div>
                                    <div className="last-password-change-info">
                                        <span>{`${t('last_password_change')} ${getDateString(profile?.last_password_change)}  ${formatAMPM(getDate(profile?.last_password_change!))}`}</span>
                                    </div>
                                </div>
                                <div className="ac-um-usp-password-reset-content">
                                    <div className="password-input">
                                        <PasswordInput value={'123456'} onChange={() => { }} />
                                    </div>

                                    <div className="reset-password-btn">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: 'capitalize' }}
                                            name={t('reset_password_for_user')}
                                            enable={true}
                                            backgroundColor={THEME.tableRowTextColor}
                                            onClick={() => { }}
                                        />
                                    </div>

                                </div>
                            </div> */}
                    </div>
                </SectionPanel>
                {
                    profile && profile.texting && <TextEmAllPage title={true} titleHeader={true} userLevelValue={true} />
                }
                <SectionPanel title={t('role_management').toUpperCase()} actions={getAddRoleAction()}>
                    <RoleTable headerList={tableHeader} sortedField={sortedField} onSortChange={handleSortFieldChange}>
                        {getFilteredList().map((doc, index) => {
                            return (
                                <tr key={index} onClick={() => { }}>
                                    <td><span>{t(getUserRoleText(doc.role))}</span></td>
                                    <td style={{ flex: 3 }}><span>{getDateString(doc.member_since)}</span></td>
                                    <td style={{ borderRight: 'none' }}>
                                        <button className="delete-icon" onClick={() => handleDeleteRole(doc)}>
                                            <DeleteIcon width={"100%"} height={"100%"} />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </RoleTable>
                </SectionPanel>
            </div>
        )
    }

    /// close add role dialog
    function handleCloseAddDialog() {
        setShowAddRoleAction(false);
    }

    /// handle open add role dialog
    function handleOpenRoleDialog() {
        setShowAddRoleAction(true);
    }

    /// handle on success add dialog
    function handleOnSuccessAddDialog() {
        dispatch(refreshUserDetails());
        handleCloseAddDialog();
        dispatch(manageUserActions.clearToggleRoleState());
    }

    /// close delete role dialog
    function handleCloseDeleteDialog() {
        setSelectedRole(null);
        setShowDeleteAction(false);
    }

    ///Delete Role
    function handleDeleteRole(doc: ProfileRole) {
        setShowDeleteAction(true);
        setSelectedRole(doc);
    }

    ///Delete user role
    function handleOnSuccessDeleteRoleDialog() {
        dispatch(refreshUserDetails());
        handleCloseDeleteDialog();
        dispatch(manageUserActions.clearToggleRoleState());

    }

    function handleChange(value: string) {
        // setSearch(value);
    }

    /// Edit user iocn actions
    function getEditUserAction() {
        return (
            <button className="icon-btn ac-um-usp-edit-icon" onClick={() => setEditUser(true)}>
                <EditIcon width={"100%"} height={"100%"} />
            </button>
        );
    }

    /// Add role button
    function getAddRoleAction() {
        return (
            <div className="ac-um-usp-add-role-btn">
                <CustomButton
                    leftIcon={getPlusBtnIcon()}
                    loading={false}
                    textStyle={{ textTransform: 'capitalize' }}
                    name={t('add_role')}
                    enable={true}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={handleOpenRoleDialog}
                />
            </div>
        )
    }



    /// handle edit user 
    function handleEditUserSuccess() {
        setEditUser(false);
        dispatch(manageUserActions.clearUpdateState());
    }
}

/// spinner 

const DetailsLoading = (props) => {

    return (
        <div className="ac-um-usp-loading">
            <SpinnerScreen />
        </div>
    );
}


/// Secton panel

interface SectionProps {
    title: string,
    children?: React.ReactNode,
    actions?: React.ReactNode,
}

const SectionPanel = (props: SectionProps) => {

    return (
        <div className="sp-container">
            <div className="sp-header">
                <div className="sp-header-title">
                    <span>{props.title}</span>
                </div>
                <div className="sp-header-actions">
                    {props.actions != null ? props.actions : <div></div>}
                </div>
            </div>
            <div className="sp-content">
                {props.children}
            </div>
        </div>
    );
}


/// password input
interface InputProps {
    value: string,
    onChange: (value: string) => void,
}

const PasswordInput = (props: InputProps) => {
    const [hide, setHide] = useState(true);
    const { t } = useTranslation();
    return (
        <div className="ac-um-input-holder">
            <div className="ac-um-container form-input">
                <input
                    className={""}
                    type={hide ? "password" : "text"}
                    placeholder={`${t('type_to_search')}...`}
                    onChange={event => {
                        props.onChange(event.target.value);
                    }}
                    value={props.value}
                    autoFocus={false}

                />
                <a className="ac-um-s-icon" onClick={() => toggleHide()}>
                    <LockIcon width={'100%'} height={'100%'} />
                </a>
            </div>
            <div className="ac-um-input-txt">
                <span>{"User has a strong password"}</span>
            </div>
        </div>

    );

    function toggleHide() {
        setHide(!hide);
    }
}

/// Role management table
interface RoleTableProps {
    headerList: Array<{ title: string, code: string }>,
    children: Array<JSX.Element>,
    sortedField: string | null,
    onSortChange: (value: string) => void,
}

const RoleTable = (props: RoleTableProps) => {
    const { headerList, children, onSortChange, sortedField } = props;
    const { t } = useTranslation();

    return (
        <table>
            <thead>
                <tr>
                    {headerList.map((doc, index) => {
                        return (
                            <th
                                key={index}
                                className='tb-header-cc'
                                style={{
                                    flex: index == 1 ? 3 : 1,
                                    ...(index == headerList.length - 1) && {
                                        borderRight: 'none'
                                    },
                                }}
                                onClick={() => onSortChange(doc.code)}
                            >
                                <span>{t(doc.title)}</span>
                                <div className={`table-header-sort-icon ${(doc.code == sortedField) ? 'table-header-sort-icon-rotate' : ''}`}>
                                    <ArrowUpward fontSize={'inherit'} color={"inherit"} />
                                </div>

                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    );
}


export default UserSettingsPage;