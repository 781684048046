import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI } from "../../../apis";
import { AppError, ICompaniesList, IDepartmentsList, } from "../../../interfaces";
import { catchRequestError } from "../../../utils";


export const getDepartmentsDropdown = createAsyncThunk<
    Array<IDepartmentsList>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/companies/department/dropdown/get',
    async (companyId, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getDepartmentsList(companyId);
            if(typeof res?.data === 'string')  return [];
            return res?.data as  Array<IDepartmentsList>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);