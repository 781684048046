import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../../apis";
import { AppError, ITalentClientCompanyInterview, ICreateTalentClientCompanyInterview } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";


export const getInterviewsToClientCompanies = createAsyncThunk<
    Array<ITalentClientCompanyInterview>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/interviews/talent-client-company-interview/get',
    async (talentId, thunkAPI) => {
        try {
            const res = await TalentAPI.getAllTalentClientInterviews(talentId);
            if(typeof res.data === 'string') return [];
            return res.data as ITalentClientCompanyInterview;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const addNewTalentClientCompanyInterview = createAsyncThunk<
    string,
    { talentId: string, data: ICreateTalentClientCompanyInterview },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/interviews/talent-client-company-interview/post',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.postTalentClientInterview(payload.data);
            // thunkAPI.dispatch(getTalentInHouseInterviews(payload.talentId));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const updateTalentClientCompanyInterview = createAsyncThunk<
    string,
    { talentId: string, data: ITalentClientCompanyInterview },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/interviews/talent-client-company-interview/patch',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.patchTalentClientInterview(payload.data);
            // thunkAPI.dispatch(getTalentInHouseInterviews(payload.talentId));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);