import { TrafficLightMetricsType } from '../../enums';
import { getRatingBackgrounColor } from '../../utils';
import './user_avatar.scss';

interface Props {
    onClick: () => void,
    url: string,
    rating: {value: number, level: TrafficLightMetricsType},
}

const UserAvatar : React.FunctionComponent<Props>= (props) => {

    return (
        <div className="avatar-holder" onClick={props.onClick}>
                <div className="avatar background-img" style={props.url ? { backgroundImage: `url(${props.url})` } : {}}></div>
                <button className="icon-btn close-action" style={{backgroundColor: getRatingBackgrounColor(props.rating.level)}}>
                    <span>{props.rating.value ? props.rating.value : 0}</span>
                </button>
            </div>
    )
}

export default UserAvatar;