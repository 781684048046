const popupInfoContent = {
    paycheckRegisterInfo:
        `<div className="info-text"><p>The Paycheck Register is a tool used to track and manage employee salaries and payments within your staffing agency. It records details such as employee names, wages, hours worked, and deductions. This register helps ensure accurate payroll processing and financial transparency for the company.</p></div>`,
    payrollJournalInfo:
        `<div className="info-text"><p>The Payroll Journal is a comprehensive record of all payroll transactions within your staffing agency. It logs employee wages, taxes, deductions, and any other relevant payroll information. This journal serves as a crucial tool for tracking payroll expenses, ensuring accuracy, and complying with financial regulations and reporting requirements.</p></div>`,
    paymentRegisterInfo:
        `<div className="info-text"><p>The Payment Register Report is a document listing employees' net check amounts due and the financial institutions or paycards for deposit. It includes detailed information like account type, routing number, and check number. It serves as a guide for distributing salaries and managing payroll transactions efficiently.</p></div>`,
    payrollCashRequiredInfo:
        `<div className="info-text"><p>The Payroll Cash Required Report is a financial document detailing the amount of cash needed to cover payroll expenses within a specified period. It helps ensure that your staffing firm has sufficient funds on hand to meet its payroll obligations, facilitating efficient cash management and budgeting.</p></div>`,
    newHireReportInfo:
        `<div className="info-text"><p>The New Hire Report is a concise summary detailing the newly employed individuals within a specified time frame. It is legally mandated that businesses submit this report within 20 days of the employee's start date.</p>
            <p>The New Hire Report includes essential information such as the employee's name, social security number, start date, and other pertinent details. The purpose of the New Hire Report is to facilitate compliance with government regulations, aid in tracking workforce changes, and support various administrative processes within an organization.</p>
            <p>Its timely submission ensures adherence to legal requirements and enables efficient management of personnel data.</p></div>`,
    garnishmentReportInfo:
        `<div className="info-text"><p>The Garnishment Report is a document detailing deductions from employee wages to fulfill legal obligations such as child support or debt repayments. It tracks withheld amounts and ensures compliance with court orders or creditor agreements, assisting in accurate payroll processing and legal compliance.</p></div>`,
    garnishmentsSubmitalReportInfo:
        `<div className="info-text"><p>The Garnishment Submittal Report is a document listing employees and the garnishments withheld, specifying the 3rd party (e.g. state agency) to which the funds should be submitted. It accompanies institutional payments, ensuring third-party recipients like Child Support Enforcement Agencies or Bankruptcy Trustees know the amount and account for crediting funds.</p></div>`,
    payrollDeductionsInfo:
        `<div className="info-text"><p>The Payroll Deductions Report is a document detailing any modifications made to employee payroll records within your staffing firm. It tracks changes such as salary adjustments, overtime corrections, or bonus updates. This report ensures accurate financial records and helps in auditing payroll activities.</p></div>`,
    invoiceRegisterInfo:
        `<div className="info-text"><p>The Invoice Register is a comprehensive record of invoices issued by our agency to customers for payment. It serves as a centralized repository capturing essential details of each transaction, including invoice numbers, dates, amounts, and recipient information.</p>
            <p>This register acts as a vital tool for tracking financial transactions, facilitating transparency and ensuring timely payment processing. By maintaining an organized record of invoices, we can effectively monitor outstanding payments, reconcile accounts and address any discrepancies promptly.</p>
            <p>Ultimately, the Invoice Register plays a crucial role in maintaining financial integrity, optimizing cash flow, and fostering positive relationships with our customers.</p></div>`,
    cashReceiptsInfo:
        `<div className="info-text"><p>The Cash Receipts Report details customer payments, invoices paid, and amounts paid within a specified date range. It provides a clear overview of financial transactions, aiding in tracking payments and reconciling accounts receivable.</p></div>`,
    aRAgingReportInfo:
        `<div className="info-text"><p>The Account Receivable Aging Report is an overview of companies that owe your staffing firm money, detailing all open invoices. It tracks outstanding payments, categorizing them by age to assess your firms financial health and prioritize collections efforts.</p></div>`,
    grossProfitReportInfo:
        `<div className="info-text"><p>The Gross Profit Report for KinISO displays gross revenue, cost of goods sold, and resulting gross profit. It also includes insightful data like average bill rate, average gross profit per hour, customers billed, and employees paid. This report offers a snapshot of the staffing firm's sales performance within a specific period.</p></div>`,
    terminationReport:
        `<div className="info-text"><p>The Termination Report is a brief summary that lists employees who have been terminated within a certain period. It's important to report terminations quickly to meet state deadlines. For instance, in Texas, terminations must be reported within 7 days.
        The report includes key details like the employee’s name, social security number, and termination date. Its main purpose is to help you follow government rules, keep track of workforce changes, and manage administrative tasks in your organization.
        Submitting the report on time ensures you meet legal requirements and helps you manage employee information efficiently.</p><div>`
};

export default popupInfoContent;