import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { IAgencies } from "../../../../interfaces/agency_management";
import { catchRequestError } from "../../../../utils";
import { AdminCenterAPI } from "../../../../apis";

export const getAdminCenterAgencyManagementAgencies = createAsyncThunk<
    Array<IAgencies>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/agency-management/agencies/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getAgencies();
            return res.data as IAgencies[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);