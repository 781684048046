import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { LoadingType, THEME } from "../../../../enums";
import { CompaniesIcon } from "../../../../icons";
import { AppError, ICompBOInvoiceDocsPOoptions } from "../../../../interfaces";
import { FormInput, convertNumberToMoney, formatToMoney, CustomButton } from "../../../../utils";
import './add_new_po.scss';
interface Props {
    visible: boolean,
    title: string,
    successTitle: string,
    onClose: () => void,
    onDissmiss?: () => void,
    handleFormFieldChange: (fieldId: string, value: any, parent: string) => void;
    poFormState: ICompBOInvoiceDocsPOoptions;
    handleSave: () => void;
    currentLoginUserName: string;
    onSuccessClose: () => void;
    index: number | undefined;
    markupPOUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    companyName: string;
}
const AddNewPOPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        title,
        successTitle,
        handleFormFieldChange, poFormState, handleSave, currentLoginUserName, companyName, onSuccessClose, markupPOUpdateState, index
    } = props;

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const parentField = "po_options";
    const loading = markupPOUpdateState.loading === LoadingType.succeeded;
    const isAddLoading = () => {
        return markupPOUpdateState?.loading === LoadingType.pending;
    };
    function handleWrapperClose(action?: () => void) {
        if (loading) {
            closePopup(props.onSuccessClose);
        } else {
            if (action) closePopup(action);
        }
    }
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"45%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
                <div className="add-new-po-container">
                    <div className="add-new-po-header">
                        <FormDialogTilteHeader
                            title={loading ? (index !== undefined ? t("new_markup") : successTitle) : (index === undefined ? title : t("update_markup"))}
                            titleIcon={<CompaniesIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="add-new-po-content">
                        {loading ?
                            (<div className="saved-doc-holder">
                                <DocSavedText>
                                    <span className="aknw-name">
                                        {t("well_done", {
                                            name: currentLoginUserName,
                                        })}
                                    </span>
                                    <span>{index !== undefined ? t("well_done_updated_new_po", { name: currentLoginUserName, companyName: companyName }) : t("well_done_added_new_po", { name: currentLoginUserName, companyName: companyName })}</span>
                                </DocSavedText>
                            </div>)
                            :
                            (
                                <>
                                    <div className="add-new-po-input-holder-row">
                                        <div className={"add-new-po-input-holder"}>
                                            <FormInput
                                                id={'po_number'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={poFormState.po_number}
                                                label={t("what_po_num")}

                                            />
                                        </div>
                                        <div className={"add-new-po-input-holder"}>
                                            <FormInput
                                                id={'po_limit'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(poFormState.po_limit, "string") as string}
                                                label={t("what_po_limit")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                    </div>
                                    <div className="add-new-po-input-holder-row">
                                        <div className={"add-new-po-input-holder"}>
                                            <FormInput
                                                id={'warning_value'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(poFormState.warning_value, "string") as string}
                                                label={t("what_amount_used_po_limit_warning")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                    </div>
                                    <div className="add-new-po-input-holder-row">
                                        <div className={"add-new-po-input-holder"}>
                                            <FormInput
                                                id={'amount_used'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(poFormState.amount_used, "string") as string}
                                                label={t("amount_used")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                    </div>
                                    <div className="add-new-po-input-holder-row">
                                        <div className={"add-new-po-input-holder"}>
                                            <FormInput
                                                id={'description'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={poFormState.description}
                                                label={t("please_provide_desc")}
                                                multiline={true}

                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    </div>
                    <div className="add-new-po-actions">
                        {loading ?
                            <div className="btn-save">
                                <CustomButton
                                    loading={isAddLoading()}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("ok")}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => closePopup(onSuccessClose)}
                                    enable={true}
                                />
                            </div>
                            :
                            (
                                <>
                                    <div className="btn-cancel">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("cancel")}
                                            enable
                                            backgroundColor={THEME.secondaryColor4}
                                            onClick={onClose}
                                        />
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={isAddLoading()}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("save")}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={handleSave}
                                            enable={true}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>);
}

export default AddNewPOPopup;