import { RouteComponentProps } from "react-router-dom";
import { createRecordPaymentThunk, getARUploadDocsURLsThunk, getInvoicePDFURLThunk, getPendingInvoicesThunk, selectARDocUploadState, selectePaycheckInvoiceState, selectOpeniInvoicesList, selectRecordPaymentState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { allowNumbersWithDotAndFourDecimals, convertNumberToMoney, currencyConversion, CustomCheckBox, formatNumber, formatToMoney, FormInput, SpinnerScreen } from "../../../../../utils";
import { LoadingType } from "../../../../../enums";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { convertDateToTimeStamp, getDateString } from "../../../../../variables";
import './record_payment.scss';
import RecordPaymentForm from "../../../forms/record_payment_form";
import { IRecordPayment } from "../../../../../interfaces/back_office_acnts_receivable";
import { getBankAccount, selectBankAccountList } from "../../../../../redux/admin_center/back_office";
import { recordPaymentSliceActions } from "../../../../../redux/back_office/accounts_receivable/record_payment/record_payment_reducer";
import { S3API } from "../../../../../apis/s3_api";
import { AppRoutes } from "../../../../../routes";
import { SearchIcon } from "../../../../../icons";
import { paycheckInvoiceSliceActions } from "../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";
interface Props extends RouteComponentProps<any> { }

const RecordPaymentPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { params } = props.match;
    const recordPaymentState = useAppSelector((state) => selectRecordPaymentState(state))
    const openInvoicesList = useAppSelector((state) => selectOpeniInvoicesList(state))
    const bankAccountList = useAppSelector((state) => selectBankAccountList(state));
    const recordDocsState = useAppSelector((state) => selectARDocUploadState(state));
    const invoicePDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));

    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState('');
    const [customer, setCustomer] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [selectedFiles, setSelectedFiles] = useState<Array<File> | null>(null);
    const [selectedItems, setSelectedItems] = useState<
        Map<string, { invoice_id: string; invoice_amount_paid: number; company_id: string }>
    >(new Map());
    useEffect(() => {
        dispatch(getPendingInvoicesThunk());
        dispatch(getBankAccount());
        handleClearError();
    }, []);

   
    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    }
    useEffect(() => {
        if (recordPaymentState.post.loading === LoadingType.succeeded && recordPaymentState.post.response) {
            if (selectedFiles && selectedFiles.length > 0) {
                dispatch(getARUploadDocsURLsThunk({ id: recordPaymentState.post.response?.object_id, docs: formState.attachments }))
            }
            else {
                setSuccessMessage("Payment Recorded Successfully!");
                setTimeout(() => setSuccessMessage(""), 5000);
                handleCancel();
                dispatch(getPendingInvoicesThunk());
            }
        }
    }, [recordPaymentState.post.loading])
    useEffect(() => {
        if (recordDocsState.loading == LoadingType.succeeded) {
            if (selectedFiles) {
                selectedFiles.map((file: any) => {
                    const url = recordDocsState.response[file.name].url;
                    S3API.uploadWithPreSignedURL(url, file);
                });
                setSuccessMessage("Payment Recorded Successfully!");
                setTimeout(() => setSuccessMessage(""), 5000);
                handleCancel();
                dispatch(getPendingInvoicesThunk());

            }
        }
        return () => { }
    }, [recordDocsState.loading]);
    useEffect(() => {
        if (invoicePDFURLState.invoice.loading === LoadingType.succeeded && invoicePDFURLState.invoice.response !== "") {
            window.open(invoicePDFURLState.invoice.response, "_blank");
            dispatch(paycheckInvoiceSliceActions.clearInoviceState());
        }
    }, [invoicePDFURLState.invoice.loading, invoicePDFURLState.invoice.response]);
    const initialForm = {
        id: "",
        invoice_id: "",
        amount_paid: 0,
        paid_date: convertDateToTimeStamp(new Date()),
        recorded_date: convertDateToTimeStamp(new Date()),
        reference_number: "",
        deposit_to_bank_id: "",
        deposit_to_bank_name: "",
        payment_method: null,
        notes: "",
        attachments: [],
        payments_by_invoice: [],
    }
    const [formState, setFormState] = useState<IRecordPayment>(initialForm);

    const handleFormFieldChange = (id: string, value: any, invoice_id?: string) => {

        if (id === 'invoice_id') {

            setFormState({
                ...formState,
                [id]: value,
            });
            if (value !== "" && openInvoicesList) {
                const { company_id, balance_amount } = openInvoicesList.find(doc => doc.id === value) || { company_id: "", balance_amount: 0 };
                if (company_id !== customer) {
                    handleMarkItem(value, balance_amount, company_id, false);
                }
                else {
                    handleMarkItem(value, balance_amount, company_id);

                }
                setCustomer(company_id ? company_id : "");
            }
            else {
                handleMarkItem("", 0, "");
                setCustomer("")
            }

        }
        else if (id === "invoice_amount_paid" && invoice_id) {

            setFormState(prevState => {
                const updatedPayments = prevState.payments_by_invoice.map(payment =>
                    payment.invoice_id === invoice_id ? { ...payment, invoice_amount_paid: value } : payment
                );

                return {
                    ...prevState,
                    amount_paid: updatedPayments.reduce((sum, payment) => sum + (
                        typeof payment.invoice_amount_paid === 'number'
                            ? payment.invoice_amount_paid
                            : convertNumberToMoney(payment.invoice_amount_paid, "number") as number), 0
                    ),
                    payments_by_invoice: updatedPayments
                };
            });
        }
        else {
            setFormState({ ...formState, [id]: value });
        }
    }

    const handleCancel = () => {
        setCustomer(null)
        setFormState(initialForm);
        setSelectedFiles(null);
        handleClearError();
    }
    const handleRecordPayment = () => {
        const updatedPayments = formState.payments_by_invoice.map(payment => {
            if (!payment.invoice_amount_paid) {
                payment.invoice_amount_paid = 0;
            }
            if (typeof payment.invoice_amount_paid === "string") {
                payment.invoice_amount_paid = (convertNumberToMoney(payment.invoice_amount_paid, "number") as number)
            }
            return payment;
        });

        dispatch(createRecordPaymentThunk({
            doc: {
                ...formState,
                amount_paid: convertNumberToMoney(formState.amount_paid, 'number') as number,
                payments_by_invoice: updatedPayments
            }

        }))
    }
    function handleFilesChange(value: Array<File>) {
        setSelectedFiles((prevFiles) => {
            const newFiles = prevFiles ? [...prevFiles, ...value] : value;
            setFormState((prevState) => ({
                ...prevState,
                attachments: newFiles.map((file) => file.name),
            }));
            return newFiles;
        });
    }

    function handleFileRemove(index: number) {
        setSelectedFiles((prevFiles) => {
            if (!prevFiles) return [];
            const newFiles = prevFiles.filter((_, i) => i !== index);
            setFormState((prevState) => ({
                ...prevState,
                attachments: newFiles.map((file) => file.name)
            }));
            return newFiles;
        });
    }
    const handleClearError = () => {
        dispatch(recordPaymentSliceActions.clearPostErrorState());
        dispatch(recordPaymentSliceActions.cleaARDocsErrorState());
        dispatch(paycheckInvoiceSliceActions.clearInoviceState());
    }

    const getFilteredList = () => {
        if (customer && openInvoicesList) {
            let list;
            if (sortedField != null) {
                list = [...openInvoicesList].sort((a, b) => {
                    const valueA =
                        a[sortedField] != null && a[sortedField] !== undefined
                            ? typeof a[sortedField] == typeof "1"
                                ? a[sortedField].trim().toLowerCase()
                                : a[sortedField]
                            : "";
                    const valueB =
                        b[sortedField] != null
                            ? typeof b[sortedField] == typeof "1"
                                ? b[sortedField].trim().toLowerCase()
                                : b[sortedField]
                            : "";
                    if (sortDirection === "asc") {
                        return valueA > valueB ? 1 : -1;
                    } else {
                        return valueA < valueB ? 1 : -1;
                    }
                });
            }
            if (customer && customer !== "") {
                list = openInvoicesList?.filter(doc => {
                    // if (formState.invoice_id && formState.invoice_id !== "") {
                    //     return doc.company_id === customer && doc.id === formState.invoice_id;
                    // }
                    return doc.company_id === customer;
                });
            }

            return (list ?? openInvoicesList)?.filter(doc => {
                const str = search.trim().toLowerCase();
                const companyNameFilter = doc.company_name ? doc.company_name.trim().toLowerCase().includes(str) : false;
                const invoice_date = doc.invoice_date ? doc.invoice_date.toString().trim().toLowerCase().includes(str) : false;
                const due_date = doc.due_date ? doc.due_date.toString().trim().toLowerCase().includes(str) : false;
                const invoiceNumberFilter = doc.invoice_number ? doc.invoice_number.toString().trim().toLowerCase().includes(str) : false;
                const invoiceAmountFilter = doc.invoice_amount ? convertNumberToMoney(doc.invoice_amount).toString().trim().toLowerCase().includes(str) : false;
                const balanceAmountFilter = doc.balance_amount ? convertNumberToMoney(doc.balance_amount).toString().trim().toLowerCase().includes(str) : false;
                const paymentAmountFilter = doc.payment_amount ? convertNumberToMoney(doc.payment_amount).toString().trim().toLowerCase().includes(str) : false;

                return companyNameFilter || invoice_date || due_date || invoiceNumberFilter || invoiceAmountFilter || balanceAmountFilter || paymentAmountFilter;
            });
        }
        return [];

    }
    const customerList = openInvoicesList?.reduce<{ label: string; value: string }[]>((acc, invoice) => {
        if (!acc.some(item => item.value === invoice.company_id)) {
            acc.push({ label: invoice.company_name, value: invoice.company_id });
        }
        return acc;
    }, []);
    const invoiceList = openInvoicesList?.filter((inv) => customer ? inv.company_id === customer : true).map((invoice) => ({
        label: invoice.invoice_number.toString(),
        value: invoice.id
    }))
    function handleCompanySelect(company_id, company_name) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${company_id}`,
            state: {
                id: company_id,
                name: company_name,
            }
        })
        return;
    }
    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === invoiceList?.length}
                onClick={handleMarkAllItem}
            />
        );
    };
    const tableHeader = [
        { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        { title: "company_name", code: "company_name" },
        { title: "invoice_number", code: "invoice_number" },
        { title: "invoice_date", code: "invoice_date" },
        { title: "due_date", code: "due_date" },
        { title: "original_balance", code: "invoice_amount" },
        { title: "payment_amount", code: "payment_amount" },
        { title: "outstanding_balance", code: "balance_amount" },
        { title: "", code: "" },
        { title: "", code: "", sort: false },
    ];

    const handleMarkAllItem = () => {
        const newSet = new Map<string, { invoice_id: string; invoice_amount_paid: number; company_id: string; }>();
        if (selectedItems.size === getFilteredList()?.length) {
            setSelectedItems(newSet);
        } else {
            getFilteredList()?.forEach((doc) => {
                newSet.set(doc.id, { invoice_id: doc.id, invoice_amount_paid: doc.balance_amount, company_id: doc.company_id })
            });
            setSelectedItems(newSet);
        }
        addInvoiceAmntFormstate(newSet);
    };

    const handleMarkItem = (id: string, invoice_amount_paid: number, company_id: string, isCurrentCompany?: boolean) => {
        if (id !== "") {
            const newSet = new Map(selectedItems);
            if (isCurrentCompany === false) {
                newSet.forEach((value, key) => {
                    if (value.company_id !== company_id) {
                        newSet.delete(key);
                    }
                });
                newSet.set(id, { invoice_id: id, invoice_amount_paid: invoice_amount_paid, company_id: company_id });
            } else {
                if (selectedItems.has(id)) {
                    newSet.delete(id);
                    setFormState({ ...formState, invoice_id: formState.invoice_id === id ? "" : formState.invoice_id })

                } else {
                    newSet.set(id, { invoice_id: id, invoice_amount_paid: invoice_amount_paid, company_id: company_id });
                }

            }
            setSelectedItems(newSet);
            addInvoiceAmntFormstate(newSet);
        }
        else {
            setSelectedItems(new Map());
            addInvoiceAmntFormstate(new Map());
        }
    };
    const addInvoiceAmntFormstate = (newSet: Map<string, {
        invoice_id: string;
        invoice_amount_paid: number;
        company_id: string;
    }>) => {

        const newPaymentsByInvoice = Array.from(newSet.values());
        setFormState(prevFormState => {
            // Create a copy of the current payments_by_invoice
            const updatedPaymentsByInvoice = prevFormState.payments_by_invoice.filter(payment =>
                newSet.has(payment.invoice_id)
            );
            newPaymentsByInvoice.forEach(newPayment => {
                const index = updatedPaymentsByInvoice.findIndex(payment => payment.invoice_id === newPayment.invoice_id);
                if (index >= 0) {
                    updatedPaymentsByInvoice[index] = newPayment;
                } else {
                    updatedPaymentsByInvoice.push(newPayment);
                }
            });

            return {
                ...prevFormState,
                amount_paid: updatedPaymentsByInvoice.reduce((sum, payment) => sum + payment.invoice_amount_paid, 0),
                payments_by_invoice: updatedPaymentsByInvoice
            };
        });
    }
    const getInvoiceURL = (invoice_id: string) => {
        dispatch(getInvoicePDFURLThunk(invoice_id));
    }

    return (
        <>
            {openInvoicesList &&
                recordPaymentState.loading === LoadingType.pending ? (
                <>
                    <SpinnerScreen></SpinnerScreen>
                </>
            ) : (
                <div className="bo-rec-pay-container">

                    <div className="bo-rec-pay-post">
                        <RecordPaymentForm
                            loading={recordPaymentState.post.loading}
                            error={recordPaymentState.post.error}
                            docsError={recordPaymentState.documentUpload.error}
                            customerList={customerList}
                            invoiceList={invoiceList}
                            bankAccountList={bankAccountList.map((doc) => ({
                                label: `${doc.bank_name} ${doc.purpose ? " - " + doc.purpose : ""}`,
                                value: doc.id,
                            }))}
                            onSubmit={() => handleRecordPayment()}
                            onClearError={handleClearError}
                            onCustomerChange={(val) => { handleFormFieldChange("invoice_id", ""); setCustomer(val); }}
                            customer={customer}
                            formState={formState}
                            handleFormFieldChange={handleFormFieldChange}
                            handleCancel={handleCancel}
                            successMessage={successMessage}
                            selectedFiles={selectedFiles}
                            onDocumentChange={handleFilesChange}
                            onRemoveDocument={handleFileRemove}
                        />
                    </div>
                    <div className="bo-rec-pay-invoices">
                        <div className="header">
                            <span className="open-i">{t('open_invoices')}</span>
                            <span className="total-count">
                                <span>
                                    {`${t("total_count")}: `}
                                    <span className="total-count-number">{getFilteredList()?.length}</span>
                                </span>

                            </span>
                        </div>
                        <div className="open-invoices-table">
                            <SortableTable
                                headerList={tableHeader}
                                sortedField={sortedField}
                                onSortChange={handleSortFieldChange}
                                flexNumber={getFlexNumber}
                                isAsc={sortDirection}
                            >
                                {getFilteredList() && getFilteredList()!.length > 0 ?
                                    (
                                        <>
                                            {getFilteredList()?.map((doc, index) => {
                                                const isLastRow = index === getFilteredList()?.length - 1;
                                                return (
                                                    <tr key={doc.id} className={isLastRow ? "border-bottom" : ""}>
                                                        <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                            <CustomCheckBox
                                                                name={""}
                                                                title={""}
                                                                checked={selectedItems.has(doc.id)}
                                                                onClick={() => handleMarkItem(doc.id, doc.balance_amount, doc.company_id)}
                                                            />

                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(1) }} isButton={doc.company_name ? true : false}
                                                            onClick={() => handleCompanySelect(doc.company_id, doc.company_name)}>
                                                            <span>{doc.company_name}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                            <span>{doc.invoice_number}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                            <span>{getDateString(doc.invoice_date, "mm/dd/yyyy")}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                            <span>{getDateString(doc.due_date, "mm/dd/yyyy")}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                            <span>{doc.invoice_amount ? currencyConversion(doc.invoice_amount, 2) : "$0.00"}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                                            <span>{doc.payment_amount ? currencyConversion(doc.payment_amount, 2) : "$0.00"}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                            <span>{doc.balance_amount ? currencyConversion(doc.balance_amount, 2) : "$0.00"}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                                            <div className="amount-input">
                                                                <FormInput
                                                                    id={"invoice_amount_paid"}
                                                                    onChange={(id, val) => (handleFormFieldChange(id, val, doc.id))}
                                                                    type={"text"}
                                                                    step={"0.01"}
                                                                    min={"0.00"}
                                                                    value={selectedItems.has(doc.id) ?
                                                                        convertNumberToMoney(formState.payments_by_invoice.find((formDoc) => formDoc.invoice_id === doc.id)?.invoice_amount_paid, "string",) as number
                                                                        : 0}
                                                                    placeholder="0"
                                                                    disabled={!selectedItems.has(doc.id)}
                                                                    prefix={<span>$</span>}
                                                                    onBlur={(e) => { allowNumbersWithDotAndFourDecimals(e); formatToMoney(e, 4); }}
                                                                    onKeyDown={(e) => { allowNumbersWithDotAndFourDecimals(e); formatToMoney(e, 4); }}
                                                                    onKeyUp={(e) => { allowNumbersWithDotAndFourDecimals(e); formatToMoney(e, 4); }}
                                                                />

                                                            </div>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                                            <div className="view-icon" onClick={() => getInvoiceURL(doc.id)}>
                                                                <SearchIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
                                                            </div>
                                                        </TableData>
                                                    </tr>

                                                );
                                            }
                                            )}
                                            <tr className="total-row">
                                                <TableData customStyle={{ flex: getFlexNumber(0) }} >
                                                    <span></span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(1) }} >
                                                    <span>Total</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                    <span></span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                    <span></span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                    <span></span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                    <span>
                                                        {currencyConversion(getFilteredList().reduce((total, doc) => {
                                                            return total + (doc.invoice_amount || 0);
                                                        }, 0))}
                                                    </span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                                    <span>{currencyConversion(getFilteredList().reduce((total, doc) => {
                                                        return total + (doc.payment_amount || 0);
                                                    }, 0))}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                    <span>{currencyConversion(getFilteredList().reduce((total, doc) => {
                                                        return total + (doc.balance_amount || 0);
                                                    }, 0))}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                                    <span></span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                                    <span></span>
                                                </TableData>
                                            </tr>
                                        </>
                                    )
                                    :
                                    <tr>
                                        <TableData customStyle={{ flex: getFlexNumber(8) }}><div style={{ width: '100%', textAlign: "center", fontSize: '0.9vw' }}>{t("no_data_available")}</div></TableData>
                                    </tr>
                                }
                            </SortableTable>
                        </div>
                    </div>
                </div >
            )}
        </>

    );
    function getFlexNumber(value: number) {
        if (value === 0) return 0.2;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 0.8;
        if (value === 4) return 0.8;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 0.3;

        return 1;
    }

}
export default RecordPaymentPage;