import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState,
} from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import { MarketingCampaign } from "../../../interfaces/marketing_campaign";
import { getMarketingCampaigns } from "./marketing_campaign_thunk";

export interface MarketingCampaignState extends EntityState<MarketingCampaign> {
  error?: AppError | null;
  loading: LoadingType;
}

const marketingCampaignAdapter: EntityAdapter<MarketingCampaign> =
  createEntityAdapter<MarketingCampaign>({
    selectId: (doc) => doc.id,
  });

const initialState: MarketingCampaignState =
  marketingCampaignAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
  });

export const marketingCampaignSlice = createSlice({
  name: "marketingCampaign",
  initialState: initialState,
  reducers: {
    clearMarketingCampaignListError(state) {
      return { ...state, error: null };
    },
    clearMarketingCampaignListState(state) {
      return marketingCampaignAdapter.removeAll({
        ...state,
        loading: LoadingType.idle,
        error: null,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarketingCampaigns.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(getMarketingCampaigns.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(getMarketingCampaigns.fulfilled, (state, action) =>
        marketingCampaignAdapter.setAll(
          { ...state, loading: LoadingType.succeeded, error: null },
          action.payload
        )
      )
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const {
  clearMarketingCampaignListError,
  clearMarketingCampaignListState,
} = marketingCampaignSlice.actions;
export const selectState = (state: MarketingCampaignState) => state;
export const selectLoading = (state: MarketingCampaignState) => state.loading;
export const selectError = (state: MarketingCampaignState) => state.error;
export const { selectAll, selectEntities, selectIds, selectById } =
  marketingCampaignAdapter.getSelectors();
