import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../enums";
import { OnBoardingIcon } from "../../../icons";
import {
  IAgencyOnboardingData,
  IOnboardingTeamMemberDetail,
  ITalentOnBoardingData,
} from "../../../interfaces";
import {
  getUploadI9UrlThunk,
  selectI9State,
  selectProfileState,
  uploadI9Thunk,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import { CustomButton, getValueFromKey } from "../../../utils";
import { WebViewerInstance } from "@pdftron/webviewer";
import "./i9.scss";
import PDFReader from "../../../components/pdf_reader/pdf_reader";
import { i9SliceActions } from "../../../redux/onboarding_portal/i9/i9_reducer";
import ApiError from "../../../components/api_error";
import { I9FormFields } from "../../pdf_fields/i9";
import { getDateString } from "../../../variables";

interface Props {
  visible: boolean;
  type: string;
  title: string;
  token: string;
  successTitle: string;
  state: ITalentOnBoardingData;
  recruiter: IOnboardingTeamMemberDetail;
  agency: IAgencyOnboardingData;
  onClose: () => void;
  onDissmiss?: () => void;
  onSuccessClose: (type: string, value?: any) => void;
}

const I9Popup: React.FunctionComponent<Props> = (props) => {
  const {
    visible,
    type,
    title,
    token,
    state,
    recruiter,
    agency,
    onClose,
    onDissmiss,
    onSuccessClose,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { uploadFile, uploadURL } = useAppSelector((state) =>
    selectI9State(state)
  );
  const actionRef = useRef<ActionDialogHolderType>(null);
  const instance = useRef<WebViewerInstance>();

  useEffect(() => {
    if (uploadURL.loading === LoadingType.succeeded && uploadURL.response) {
      handleUploadFile(uploadURL.response.url);
    }
  }, [uploadURL.loading]);

  useEffect(() => {
    if (uploadFile.loading === LoadingType.succeeded) {
      onSuccessClose(type);
    }

    return () => {
      if (instance.current) {
        cleanPDFState(instance.current);
      }
      dispatch(i9SliceActions.clearState());
    };
  }, [uploadFile.loading]);

  useEffect(() => {
    if (instance.current && state.i9_document.url) {
      loadPDFDocument(instance.current, state.i9_document.url);
    }
  }, [state.i9_document.url]);

  function handleOnInstance(value: WebViewerInstance) {
    instance.current = value;
    instance.current.UI.disableElements(['toolsHeader']);
    loadPDFDocument(value, state.i9_document.url);
  }
  const requiredFieldsArray = [
    "Last Name (Family Name)",
    "First Name Given Name",
    "Address Street Number and Name",
    "City or Town",
    "State",
    "ZIP Code",
    "Date of Birth mmddyyyy",
    "US Social Security Number",
    "Employees E-mail Address",
    "Telephone Number",
    "Signature of Employee",
    "Today's Date mmddyyy",
  ];
  const sec1CheckboxFieldsArray = [
    "CB_1",
    "CB_2",  // Checkbox field name in the PDF document
    "CB_3",
    "CB_4"
  ];
  const nonCitizenFieldsArray = [
    "Foreign Passport Number and Country of IssuanceRow1",
    "Form I94 Admission Number",
    "USCIS ANumber"
  ]
  const fieldsToDisableForTalent = [
    //supplement B (Page 4)
    "Document Title 0",
    "Document Title 1",
    "Document Title 2",
    "Document Number 0",
    "Document Number 1",
    "Document Number 2",
    "Expiration Date 0",
    "Expiration Date 1",
    "Expiration Date 2",
    "Date of Rehire 0",
    "Date of Rehire 1",
    "Date of Rehire 2",
    "Todays Date 0",
    "Todays Date 1",
    "Todays Date 2",
    "Name of Emp or Auth Rep 0",
    "Name of Emp or Auth Rep 1",
    "Name of Emp or Auth Rep 2",
    "Signature of Emp Rep 0",
    "Signature of Emp Rep 1",
    "Signature of Emp Rep 2",
    "Addtl Info 0",
    "Addtl Info 1",
    "Addtl Info 2",
    "CB_Alt_0",
    "CB_Alt_1",
    "CB_Alt_2",
    "Last Name 0",
    "Last Name 1",
    "Last Name 2",
    "First Name 0",
    "First Name 1",
    "First Name 2",
    "Middle Initial 0",
    "Middle Initial 1",
    "Middle Initial 2",
    "Last Name Family Name from Section 1-2",
    "First Name Given Name from Section 1-2",
    "Middle initial if any from Section 1-2",

    //Page 1 Section 2
    "CB_Alt",
    "Expiration Date if any",
    "Issuing Authority 1",
    "Issuing Authority_2",
    "Issuing Authority_0",
    "List A.  Document 2. Expiration Date (if any)",
    "List A.  Document 2",
    "List A.  Document 3",
    "List A.  Document 3 Number.  If any",
    "List A. Document 3",
    "List A. Document 3.  Enter Issuing Authority",

    "List A.   Document Title 3",
    "List A.   Document Title 3.  If any",
    "List B Document 1 Title",
    "List B Issuing Authority 1",
    "List B Document Number 1",
    "List B Expiration Date 1",
    "List C Document Title 1",
    "List C Issuing Authority 1",
    "List C Document Number 1",
    "List C Expiration Date 1",
    "Document Number 0 (if any)",
    "Document Number If any_2",
    "Document Number if any_3", //expiration date (if any) [3] field value is pointed to this value
    "Document Title 2 If any",
    "List A",
    "Additional Information",
    "Last Name First Name and Title of Employer or Authorized Representative",
    "Signature of Employer or AR",
    "Employers Business or Org Name",
    "Employers Business or Org Address",
    "FirstDayEmployed mmddyyyy",
    "S2 Todays Date mmddyyyy",

  ];
  const dateFields = [
    "Today's Date mmddyyy"
  ];

  function loadPDFDocument(value: WebViewerInstance, url: string) {
    const { UI, Core } = value;
    // annotationManager.addEventListener('fieldChanged', (field, value) => {
    //     console.log(`Field changed: ${field.name}, ${value}`);
    // });
    const { documentViewer, annotationManager, Annotations } = Core;

    //to disable individual input field using name
    // <<<<<<<<<----- DO NOT REMOVE THIS CODE AS IT MAY BE USED IN FUTURE REFERENCE TO DISABLE INDIVIDUAL FIELD ------>>>>>>>>>>>
    annotationManager.addEventListener(
      "annotationChanged",
      (annotations, action, { imported }) => {
        // if the annotation change occurs because of an import then
        // these are fields from inside the document
        if (imported && action === "add") {
          annotations.forEach(function (annot) {
            if (
              annot instanceof Annotations.WidgetAnnotation &&
              fieldsToDisableForTalent.includes(annot.fieldName)
            ) {
              // annot.fieldFlags.set("Required", true);
              annot.fieldFlags.set("ReadOnly", true); // this is to disable the field
              // annot.getField().
            }

            // if (
            //   annot instanceof Annotations.WidgetAnnotation &&
            //   requiredFieldsArray.includes(annot.fieldName)
            // ) {
            //   annot.fieldFlags.set("Required", true);
            //   // annot.fieldFlags.set("ReadOnly", true);  // this is to disable the field
            // }
          });
        }
      }
    );
    documentViewer.addEventListener("documentLoaded", () => {
      documentViewer.getAnnotationsLoadedPromise().then(() => {
        // iterate over fields
        const fieldManager = annotationManager.getFieldManager();

        /// TO print the field names in the PDF
        const arr: any[] = [];
        fieldManager.forEachField((field) => {
          arr.push(field.name)

        });
        // End

        Object.keys(I9FormFields).forEach((key) => {
          const doc = I9FormFields[key];

          if (doc.mapTo) {
            const field = fieldManager.getField(doc.key);
            field.setValue(
              getValueFromKey(doc.mapTo, { ...recruiter, ...agency })
            );
          }

          if (dateFields.includes(doc.key)) {
            const field = fieldManager.getField(doc.key);
            field.setValue(getDateString(new Date(), "mm/dd/yyyy"));
          }
        });
      });
    });
    UI.loadDocument(url);
    UI.setFitMode(UI.FitMode.FitWidth);
  }

  function cleanPDFState(value: WebViewerInstance) {
    const { documentViewer } = value.Core;
    documentViewer.removeEventListener("documentLoaded");
    // documentViewer.removeEventListener('fieldChanged');
  }

  function handleClose() {
    closePopup(onClose);
  }
  const changeDOBFormat = (dob: string | number) => {
    const dobString = dob.toString();
    if(!dobString.includes("/")){
      const trimString = dobString.replaceAll(/[-.]/g, "");
      const mm = trimString.substring(0,2);
      const dd = trimString.substring(2,4);
      const yyyy = trimString.substring(4,8);
      return `${mm}/${dd}/${yyyy}`
    }
    return dob
  }

  async function handleUploadFile(url: string) {
    if (instance.current) {
      const { documentViewer, annotationManager } = instance.current.Core;
      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations();
      const data = await doc.getFileData({
        // saves the document with annotations in it
        xfdfString,
      });
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: "application/pdf" });
      // TO check whether the required fields are empty or not
      const formFields = documentViewer
        .getAnnotationManager()
        .getFieldManager();

      const formFieldsData = formFields.getFields();
      // console.log(formFieldsData.map((field) => field.name + " : " + field.getValue()))

      const reqFieldsSection1 = formFieldsData.filter((field) =>
        requiredFieldsArray.includes(field.name)
      );
      const isRequiredFieldsEmptySec1 = reqFieldsSection1.some(
        (field) => (field.getValue() === "" || field.getValue() === " ")
      );
      const reqFieldsSection1CB = formFieldsData.filter((field) =>
        sec1CheckboxFieldsArray.includes(field.name)
      );
      const isRequiredFieldsEmptyCB = reqFieldsSection1CB.some(
        (field) => (field.getValue() === "On")
      );
      const isNonCitizenFieldsArray = formFieldsData.filter((field) =>
        nonCitizenFieldsArray.includes(field.name)
      );
      const dobField = formFields.getField("Date of Birth mmddyyyy");

      dobField.setValue(changeDOBFormat(dobField.getValue()));

      if (!isRequiredFieldsEmptySec1 && isRequiredFieldsEmptyCB) {
        // setRequiredFieldMsg(false);
        dispatch(uploadI9Thunk({ url: url, file: blob }));
      }
      // else {
      //   // setRequiredFieldMsg(true);
      // }
    }
  }

  const loading =
    uploadURL.loading === LoadingType.pending ||
    uploadFile.loading === LoadingType.pending;
  const error = uploadURL.error || uploadFile.error;

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={"70%"}
      onClose={onClose}
      onDissmiss={onDissmiss}
    >
      <DialogWrapper onClose={handleClose}>
        <div className="onboarding-pages i9-container">
          <div className="i9-header">
            <FormDialogTilteHeader
              title={t("type_form", { name: title })}
              subTitle={t("please_scroll_down_and_fill_out_the_form_below")}
              subTitleStyle={{ fontWeight: "normal" }}
              titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <div className="i9-content">
            <PDFReader onInstanceChange={handleOnInstance} />
            {error && (
              <div className={"error-section"}>
                <ApiError message={error.message} onClose={handleClearError} />
              </div>
            )}
          </div>
          <div className="i9-actions">
            {/* {requiredFieldMsg && (
              <div className="errorMsg">
                <span style={{ color: "Red" }}>
                  {t("fill_required_fields_details_i9")}
                </span>
              </div>
            )} */}

            <div className="errorMsg">
              <span style={{ color: "Red" }}>
                {t("fill_required_fields_details_i9")}
              </span>
            </div>

            <div className="i9-actions-row">
              <div className="btn-cancel">
                <CustomButton
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("cancel")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => closePopup(onClose)}
                />
              </div>
              <div className="btn-new">
                <CustomButton
                  loading={loading}
                  textStyle={{
                    textTransform: "capitalize",
                  }}
                  name={t("confirm_complete")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => (loading ? undefined : handleSave())}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );

  ///Handle clear error
  function handleClearError() {
    dispatch(i9SliceActions.clearState());
  }

  /// handle submit
  function handleSave() {
    dispatch(getUploadI9UrlThunk({ token, talent_id: state.profile.id }));
  }

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }
};

export default I9Popup;
