import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccountAPI, AdminCenterAPI, firebaseLogin } from "../../../apis";
import { AppError, IProfileDropdown, LoginCredentials, Profile, UsersQuery } from "../../../interfaces";
import preferences from "../../../preferences";
import { catchRequestError } from "../../../utils";

export const getRoleUsersList = createAsyncThunk<
    Array<IProfileDropdown>,
    UsersQuery | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/role-management/users/get',
    async (credentials, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getUsersDropdownList(credentials);
            return res.data as IProfileDropdown[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);