import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType, TALENT_SKILLS_TYPE } from "../../../../../enums";
import { AppError, ISkillOption, ICompany, IDepartment, Profile } from "../../../../../interfaces";
import * as thunk from "./company_details_thunk";


interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

interface RemoveState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialRemoveState: RemoveState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


/// [Job Matches]
// export interface SingleCompanyJobMatchesState {
//     data: Array<ITalentJobMatch> | null,
//     error?: AppError | null,
//     loading: LoadingType,
//     add: AddState,
//     remove: RemoveState,
// }


// const singleCompanyJobMatchesInitialState: SingleCompanyJobMatchesState = {
//     data: null,
//     error: null,
//     loading: LoadingType.idle,
//     add: initialAddState,
//     remove: initialRemoveState,
// }

// /// [Company Matches]
// export interface SingleCompanyMatchesState {
//     data: Array<ITalentCompanyMatch> | null,
//     error?: AppError | null,
//     loading: LoadingType,
//     add: AddState,
//     remove: RemoveState,
// }


// const singleTalentCompanyMatchesInitialState: SingleCompanyMatchesState = {
//     data: null,
//     error: null,
//     loading: LoadingType.idle,
//     add: initialAddState,
//     remove: initialRemoveState,
// }

/// [Skills]
export interface SingleCompanySkillsState {
    data: Array<ISkillOption> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}



const singleCompanySkillsInitialState: SingleCompanySkillsState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}

export interface SingleCompanyRoleUsersState {
    data: Array<Profile> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}

const singleCompanyRoleUsersInitialState: SingleCompanyRoleUsersState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}



export interface SingleCompanyState {
    error?: AppError | null,
    loading: LoadingType,
    data: ICompany | IDepartment | null,
    // jobMatches: SingleCompanyJobMatchesState,
    // companyMatches: SingleCompanyMatchesState,
    [TALENT_SKILLS_TYPE.professional_skills]: SingleCompanySkillsState,
    [TALENT_SKILLS_TYPE.credentials]: SingleCompanySkillsState,
    [TALENT_SKILLS_TYPE.certifications]: SingleCompanySkillsState,
    [TALENT_SKILLS_TYPE.education]: SingleCompanySkillsState,
    [TALENT_SKILLS_TYPE.safety_equipment]: SingleCompanySkillsState,
    [TALENT_SKILLS_TYPE.account_managers]: SingleCompanyRoleUsersState,
    [TALENT_SKILLS_TYPE.recruiters]: SingleCompanyRoleUsersState,
}


export interface CompanyDetailsState {
    [key: string]: SingleCompanyState,
}


const initialState: CompanyDetailsState = {}


function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

function getResponseSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}

export const companyDetailsSlice = createSlice({
    name: 'companyDetails',
    initialState: initialState,
    reducers: {
        clearCompanyDetailsAddOptionError(state, action: PayloadAction<{ id: string, type: string }>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        add: {
                            ...state[action.payload.id][action.payload.type].add,
                            ...initialAddState,
                        },

                    },
                }
            };
        },

        clearCompanyDetailsRemoveOptionError(state, action: PayloadAction<{ id: string, type: string }>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        remove: {
                            ...state[action.payload.id][action.payload.type].remove,
                            ...initialRemoveState,
                        },

                    },
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getCompanyById.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId]),
                        // jobMatches: singleCompanyJobMatchesInitialState,
                        // companyMatches: singleTalentCompanyMatchesInitialState,
                        [TALENT_SKILLS_TYPE.professional_skills]: singleCompanySkillsInitialState,
                        [TALENT_SKILLS_TYPE.credentials]: singleCompanySkillsInitialState,
                        [TALENT_SKILLS_TYPE.certifications]: singleCompanySkillsInitialState,
                        [TALENT_SKILLS_TYPE.education]: singleCompanySkillsInitialState,
                        [TALENT_SKILLS_TYPE.safety_equipment]: singleCompanySkillsInitialState,
                        [TALENT_SKILLS_TYPE.account_managers]: singleCompanyRoleUsersInitialState,
                        [TALENT_SKILLS_TYPE.recruiters]: singleCompanyRoleUsersInitialState,
                    }
                })
            )
            .addCase(
                thunk.getCompanyById.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getCompanyById.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...getSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId], action.payload),
                    }
                })
            )


            /// Get all skillls
            .addCase(
                thunk.getCompanyAllSkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.professional_skills]),

                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.credentials]),

                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.certifications]),

                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.education]),

                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.safety_equipment]),
                        },
                    }
                })
            )
            .addCase(
                thunk.getCompanyAllSkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.professional_skills], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.credentials], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.certifications], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.education], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.safety_equipment], action.payload),
                        },
                    }
                })
            )
            .addCase(
                thunk.getCompanyAllSkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.professional_skills], action.payload[TALENT_SKILLS_TYPE.professional_skills]),
                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.credentials], action.payload[TALENT_SKILLS_TYPE.credentials]),
                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.certifications], action.payload[TALENT_SKILLS_TYPE.certifications]),
                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.education], action.payload[TALENT_SKILLS_TYPE.education]),
                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.safety_equipment], action.payload[TALENT_SKILLS_TYPE.safety_equipment]),
                        },
                    }
                })
            )

            /// Skills list [DELETE]
            .addCase(
                thunk.removeCompanySkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeCompanySkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type],
                            remove: {
                                ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeCompanySkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type].remove, action.payload),
                            },
                        },

                    }
                })
            )

            /// Skills list [POST]
            .addCase(
                thunk.addCompanySkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type],
                            add: {
                                ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addCompanySkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type],
                            add: {
                                ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addCompanySkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][action.meta.arg.value.skill_type].add, action.payload),
                            },
                        },
                    }
                })
            )


            /// Get all account managers
            .addCase(
                thunk.getCompanyAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.account_managers]),

                        },
                    }
                })
            )
            .addCase(
                thunk.getCompanyAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.account_managers], action.payload),

                        },
                    }
                })
            )
            .addCase(
                thunk.getCompanyAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...getSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.account_managers], action.payload),
                        },
                    }
                })
            )


            /// AccountManagers list [DELETE]
            .addCase(
                thunk.removeCompanyAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeCompanyAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getErrorState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeCompanyAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers].remove, action.payload),
                            },
                        },

                    }
                })
            )

            /// Account managers list [POST]
            .addCase(
                thunk.addCompanyAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getLoadingState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addCompanyAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getErrorState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addCompanyAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.account_managers].add, action.payload),
                            },
                        },
                    }
                })
            )


            /// Get all account managers
            .addCase(
                thunk.getCompanyRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.recruiters]),

                        },
                    }
                })
            )
            .addCase(
                thunk.getCompanyRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.recruiters], action.payload),

                        },
                    }
                })
            )
            .addCase(
                thunk.getCompanyRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...state[action.meta.arg.departmentId ?? action.meta.arg.companyId],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId][TALENT_SKILLS_TYPE.recruiters], action.payload),
                        },
                    }
                })
            )


            /// Recruiters list [DELETE]
            .addCase(
                thunk.removeCompanyRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeCompanyRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getErrorState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeCompanyRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters].remove, action.payload),
                            },
                        },

                    }
                })
            )

            /// Account managers list [POST]
            .addCase(
                thunk.addCompanyRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getLoadingState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addCompanyRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getErrorState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addCompanyRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...state[action.meta.arg.department_id ?? action.meta.arg.company_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.department_id ?? action.meta.arg.company_id][TALENT_SKILLS_TYPE.recruiters].add, action.payload),
                            },
                        },
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const companyDetailsSliceActions = companyDetailsSlice.actions;
export const selectState = (state: CompanyDetailsState) => state;
export const selectCompanyState = (state: CompanyDetailsState, id: string): SingleCompanyState => state[id];
// export const selectCompanyJobMatchesState = (state: SingleCompanyState): SingleCompanyJobMatchesState => state.jobMatches;
// export const selectCompanyCompanyMatchesState = (state: SingleCompanyState): SingleCompanyMatchesState => state.companyMatches;
export const selectCompanySkillsState = (state: SingleCompanyState): SingleCompanySkillsState => state.professional_skills;
export const selectCompanyCredentialsState = (state: SingleCompanyState): SingleCompanySkillsState => state.credentials;
export const selectCompanyCertificationsState = (state: SingleCompanyState): SingleCompanySkillsState => state.certifications;
export const selectCompanyEducationState = (state: SingleCompanyState): SingleCompanySkillsState => state.education;
export const selectCompanyEquipmentState = (state: SingleCompanyState): SingleCompanySkillsState => state.safety_equipment;

export const selectInitialTalentState = (): SingleCompanyState => ({
    data: null,
    loading: LoadingType.pending,
    error: null,
    // jobMatches: singleCompanyJobMatchesInitialState,
    // companyMatches: singleTalentCompanyMatchesInitialState,
    [TALENT_SKILLS_TYPE.professional_skills]: singleCompanySkillsInitialState,
    [TALENT_SKILLS_TYPE.credentials]: singleCompanySkillsInitialState,
    [TALENT_SKILLS_TYPE.certifications]: singleCompanySkillsInitialState,
    [TALENT_SKILLS_TYPE.education]: singleCompanySkillsInitialState,
    [TALENT_SKILLS_TYPE.safety_equipment]: singleCompanySkillsInitialState,
    [TALENT_SKILLS_TYPE.account_managers]: singleCompanyRoleUsersInitialState,
    [TALENT_SKILLS_TYPE.recruiters]: singleCompanyRoleUsersInitialState,
});


