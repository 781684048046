import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./competitors_list_reducer";

const select = (state: IRootState) => state.selectors.competitorsList;
export const selectCompetitorsListState = createSelector(select, selectState);
export const selectCompetitorsList = createSelector(
    select,
    selectAll
);
export const selectCompetitorsListEntities = createSelector(
    select,
    selectEntities
);