import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IBatchQueryParams, ITimeCardsBatch, ITimeCardsData } from "../../../../../interfaces";
import { BackOfficeAPI } from "../../../../../apis/back_office_api";
import { catchRequestError } from "../../../../../utils";


export const getBatchDropdownThunk = createAsyncThunk<
    ITimeCardsBatch[],
    { week_accounting_id: string, queryParams: IBatchQueryParams },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_tab/batch/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getTimeCardsBatchDropdown(payload.week_accounting_id, payload.queryParams);
            return res.data as ITimeCardsBatch[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


