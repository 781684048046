export enum TALENT_STATUS {
    active = "ACTIVE",
    inactive = "INACTIVE",
}

export enum TALENT_DASHBOARD_STATUS {
    active = "Active",
    inactive = "Inactive",
    terminated= "Terminated"
}

export enum PREFERED_CONTACT {
    email = "EMAIL",
    phone = "PHONE",
    text = "TEXT",
    linkedIn = "LINKEDIN",
    zipRecruiter = "ZIPRECRUITER",
    indeed = "INDEED",
    facebook = "FACEBOOK",
    inperson = "IN_PERSON",
    teams = "TEAMS",
    zoom = "ZOOM",
    googleMeet = "GOOGLE_MEET"
}

export enum TALENT_SKILLS_TYPE {
    professional_skills = "professional_skills",
    certifications = "certifications",
    education = "education",
    credentials = "credentials",
    safety_equipment = "safety_equipment",
    account_managers = "account_managers",
    recruiters = "recruiters"
}