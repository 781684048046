import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { LoadingType, THEME } from '../../../enums';
import { CircleTickIcon, CloseIcon, MarkIcon, PlusIcon } from '../../../icons';
import { ICreateOnlineApplication, IOnlineApplication } from '../../../interfaces';
import { CustomButton, RegxPattern, SpinnerScreen } from '../../../utils';
import OnlineApplicationHolder from '../online_application_holder/online_application_holder';
import ContactInformationForm from '../../forms/contact_information_form/contact_information_form';
import './online_application.scss';
import WorkHistoryForm from '../../forms/work_history_form/work_history_form';
import EducationForm from '../../forms/education_form/education_form';
import RenderInput from '../../../components/render_input/render_input';
import {
  useAppDispatch,
  useAppSelector,
  selectOnlineApplicationVerifyTokenState,
  verifyTokenThunk,
  postSnackbarMessage,
  selectSubmitOnlineApplicationState,
  selectSubmitOnlineApplicationGetUploadURLState,
  getFileUploadUrlsThunk,
  submitOnlineApplicationThunk,
  selectSubmitOnlineApplicationUploadFilesState,
  uploadFilesThunk
} from '../../../redux/store';
import { AuthRoutes } from '../../../routes';
import ApiError from '../../../components/api_error';
import { submitOnlineApplicationStateActions } from '../../../redux/online_application/submit_application/submit_application_reducer';
import { Default, Mobile } from '../../../components/react_responsive_breakpoints/responsive_breakpoints';
import OnlineApplicationHolderMobile from '../../../mobile_view/online_application_mobile/pages/online_application_holder_mobile/online_application_holder_mobile';
import CustomStepIndicator from '../../../components/custom_step_indicator/custom_step_indicator';


const termsAndConditions = {
  field: "agree_with_terms_and_conditions",
  type: "checkbox",
  label: 'i_agree_with_terms_and_conditions',
  secure: false,
  required: false,
  placeholder: '',
  keyboardtype: ''
};

interface Props extends RouteComponentProps<any> { }
const OnlineApplication: React.FunctionComponent<Props> = (props) => {
  const { history, location, match } = props;
  const { params } = match;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (params.lng === "es")
      i18n.changeLanguage('es'); // Change the language to 'es' (Spanish) on page load
  }, [params, params.lng]); // Empty dependency array ensures this effect runs once on page load

  useEffect(() => {
    // Find the script element by its widget_id attribute
    const scriptElement = document.querySelector(
      '#freshworks-container'
    );
    // Remove the script element if found
    if (scriptElement) {
      scriptElement.remove();
    }
  }, []);

  const dispatch = useAppDispatch();
  const verifyTokenState = useAppSelector((state) => selectOnlineApplicationVerifyTokenState(state));
  const submitApplicationState = useAppSelector((state) => selectSubmitOnlineApplicationState(state));
  const uploadURLState = useAppSelector((state) => selectSubmitOnlineApplicationGetUploadURLState(state));
  const uploadFileState = useAppSelector((state) => selectSubmitOnlineApplicationUploadFilesState(state));
  const [userPhoto, setUserPhoto] = useState<File | null>(null);
  const [userResume, setUserResume] = useState<File | null>(null);
  const [activeForm, setActiveForm] = useState<"contact_information" | "work_history" | "education">('contact_information');
  const [formState, setFormState] = useState<ICreateOnlineApplication>({
    contact_information: {
      photo_path: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      phone: '',
      email: '',
      last_4_digits_of_ssn: '',
      resume_path: '',
    },
    work_history: {
      // 0: {
      //     job_title: '',
      //     start_date: 0,
      //     company_name: '',
      //     end_date: 0,
      //     company_department: '',
      //     is_currently_work_here: false,
      //     job_accomplishments_details_and_responsibilities: ''
      // }
    },
    education: {
      // 0: {
      //     last_school_attended: '',
      //     degree: '',
      //     year_completed: 0,
      //     is_currently_in_progress: false,
      //     awards_achievement_and_honours: ''
      // }
    },
    agree_with_terms_and_conditions: false,
    token: params.key,
    agency_domain: '',
  });
  const [tasks, setTasks] = useState({
    contact_information: {
      title: 'contact_information',
      open: true,
    },
    work_history: {
      title: 'work_history',
      open: false,
    },
    education: {
      title: 'education',
      open: false,
    }
  });
  //mobile view step indicator  ---- START
  const [currentStep, setCurrentStep] = useState<{
    label: string;
    value: number;
  }>({ label: "", value: 0 });
  const lastStep = 3;
  const stepsList: Array<{ label: string; value: number }> = [
    ...Array.from(Array(lastStep).keys()).map((doc, index) => ({
      label: "",
      value: index,
    })),
  ];
  //mobile view step indicator ---- END

  /// [Verify token]
  useEffect(() => {
    if (params && params.key) {
      dispatch(verifyTokenThunk(params.key));
    }
    return () => {

    }
  }, [])

  /// [handle verification]
  useEffect(() => {
    if (verifyTokenState.loading === LoadingType.succeeded && verifyTokenState.response && params && params.key) {
      setFormState({
        ...formState,
        token: params.key,
        agency_domain: verifyTokenState.response?.agency_domain,
        contact_information: {
          ...formState.contact_information,
          email: verifyTokenState.response.email,
        }

      })
      // dispatch(postSnackbarMessage(verifyTokenState.response.status));
    }
    return () => {

    }
  }, [verifyTokenState.loading])

  /// [handle submit application]
  useEffect(() => {
    if (submitApplicationState.loading === LoadingType.succeeded && submitApplicationState.response && (userPhoto || userResume)) {
      dispatch(getFileUploadUrlsThunk({
        object_id: submitApplicationState.response.object_id,
        domain: formState.agency_domain,
        profile_pic_file_name: userPhoto ? userPhoto?.name : undefined,
        resume_file_name: userResume ? userResume?.name : undefined
      }));
    }
    return () => {

    }
  }, [submitApplicationState.loading])

  /// [handle get file upload urls]
  useEffect(() => {
    if (uploadURLState.loading === LoadingType.succeeded && uploadURLState.response) {
      dispatch(uploadFilesThunk({
        data: uploadURLState.response,
        photo: userPhoto,
        resume: userResume,
      }))
    }
    return () => {

    }
  }, [uploadURLState.loading])

  /// [handle upload files]
  useEffect(() => {
    if (uploadFileState.loading === LoadingType.succeeded && uploadFileState.response) {
      dispatch(postSnackbarMessage(t('application_submitted_successfully')));
    }
    return () => {

    }
  }, [uploadFileState.loading])


  function getSubmitLoading() {
    if (userPhoto || userResume) {
      return submitApplicationState.loading === LoadingType.pending ||
        uploadURLState.loading === LoadingType.pending ||
        uploadFileState.loading === LoadingType.pending;
    }
    return submitApplicationState.loading === LoadingType.pending;
  }

  function isApplicationSubmitted() {
    if (userPhoto || userResume) {
      return submitApplicationState.loading === LoadingType.succeeded &&
        uploadURLState.loading === LoadingType.succeeded &&
        uploadFileState.loading === LoadingType.succeeded;
    }
    return submitApplicationState.loading === LoadingType.succeeded;
  }

  function handleFieldChange(fieldId, value) {
    setFormState({ ...formState, [fieldId]: value });
  }

  function handleContactInfoFieldChange(fieldId, value) {
    setFormState({
      ...formState,
      contact_information: {
        ...formState.contact_information,
        [fieldId]: value
      }
    });
  }

  function handleWorkHistoryFieldChange(fieldId: string, value: any, index: number) {
    setFormState({
      ...formState,
      work_history: {
        ...formState.work_history,
        [index]: {
          ...formState.work_history[index],
          [fieldId]: value
        }
      }
    });
  }

  function handleEducationFieldChange(fieldId: string, value: any, index: number) {
    setFormState({
      ...formState,
      education: {
        ...formState.education,
        [index]: {
          ...formState.education[index],
          [fieldId]: value
        }
      }
    });
  }

  if (verifyTokenState.loading === LoadingType.pending) {

    return (
      <div className="verification-token-holder">
        <SpinnerScreen />
      </div>
    )
  }

  if (verifyTokenState.loading === LoadingType.failed) {

    return (
      <div className="verification-token-failed-holder">
        <div className="token-failed-container">
          <div className="title-holder">
            <span>{t('invalid_token')}</span>
            <span style={{ fontSize: '1.1458333333333333vw' }}>{t('contact_your_recruiter')}</span>
          </div>
          <div className="failed-action">
            {/* <div className="btn-save">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('go_to_login')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => handleGoToLogin()}
                            />
                        </div> */}
          </div>
        </div>
      </div>
    )
  }

  if (isApplicationSubmitted()) {

    return (
      <>
        <Default>
          <OnlineApplicationHolder history={history} location={location} hideSubTitle={true} agencyLogoURL={verifyTokenState?.response?.agency_logo_download_url}>
            <div className="oa-application-form-container">
              <div className="success-message-holder">
                <div className="message-txt">
                  <span>{t('congratualtions_you_did_great_job_your_application_has_been_submitted', { name: `${formState.contact_information.first_name} ${formState.contact_information.last_name}` })}</span>
                </div>
              </div>
              <div className="oa-actions">
                {/* <div className="btn-save">
                            <CustomButton
                                loading={getSubmitLoading()}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('ok')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => handleOnsuccess()}
                            />
                        </div> */}
              </div>
            </div>
          </OnlineApplicationHolder>
        </Default>
        <Mobile>
          <OnlineApplicationHolderMobile history={history}
            location={location}
            hideSubTitle={true}
            hideNav={false}>
            <div className="oa-application-form-container-mobile">
              <div className="success-message-holder">
                <div className="header">
                  <div className="page-info">
                    <div className="icon-holder">
                      <MarkIcon
                        width={"100%"}
                        height={"100%"}
                        style={{ color: THEME.defaultHighLightColor }}
                      />
                    </div>
                    <div className="title-holder">
                      <div className="title">
                        <span>
                          {t(
                            "congratulations",
                            {
                              name: `${formState.contact_information.first_name} ${formState.contact_information.last_name}`,
                            }
                          )}
                        </span>
                      </div>
                      <div className="sub-title">
                        <span>
                          {t("you_did_great_job_your_application_submitted")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="agency-branding">{/* <AlphaOmegaStaffing /> */}</div>
                </div>

              </div>

            </div>
          </OnlineApplicationHolderMobile>
        </Mobile>
      </>
    );
  }

  return (
    <>
      <Default>
        <OnlineApplicationHolder history={history} location={location} agencyLogoURL={verifyTokenState?.response?.agency_logo_download_url}>
          <div className="oa-application-form-container">
            <div className="oa-holder">
              <div className="oa-tasks-list">
                {Object.keys(tasks).map((key, index) => {
                  return (
                    <div
                      key={key + index + 'tsk'}
                      className="task-holder"
                      style={{
                        borderBottom: (index === Object.keys(tasks).length - 1) ? 'none' : undefined,
                      }}
                    >
                      <div className="task-icon">
                        <CircleTickIcon
                          width={"100%"}
                          height={"100%"}
                          style={{ color: tasks[key].open ? THEME.defaultHighLightColor : THEME.optionDisableColor }}
                        />
                      </div>
                      <div className="task-title">
                        <span style={{ color: tasks[key].open ? THEME.defaultHighLightColor : THEME.optionDisableColor }}>{t(tasks[key].title)}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="oa-task-form">
                <div className="oa-task-form-header">
                  {/* <span>{t('we_hate_data_entry_too_either_upload_resume_or_go_old_school')}</span> */}
                  {activeForm === "contact_information" && <span>{t('we_hate_data_entry_too_either_upload_resume_or_go_old_school')}</span>}
                  {activeForm === "work_history" && <span>{t('if_you_have_not_uploaded_your_resume_or_go_old_school_work_history')}</span>}
                  {activeForm === "education" && <span>{t('if_you_have_not_uploaded_your_resume_or_go_old_school_education')}</span>}
                </div>
                <div className="oa-task-form-content">
                  {getForm()}
                </div>
                {submitApplicationState.error && <div className={"error-section"}>
                  <ApiError message={submitApplicationState.error.message} onClose={handleClearSubmitFormError} />
                </div>}
              </div>
            </div>
            {getActions()}
          </div>

        </OnlineApplicationHolder>
      </Default>
      <Mobile>
        <OnlineApplicationHolderMobile history={history} location={location} hideNav={true}>
          <div className="oa-application-form-container  oa-application-form-container-mobile">
            <div className="oa-holder">
              <div className="oa-task-form">
                <div className="cnt-f-steps-indicator">
                  <CustomStepIndicator
                    selected={currentStep}
                    stepsList={stepsList}
                    onClick={(value) => { }}
                  />
                </div>
                <div className="oa-task-form-header">
                  {activeForm === "contact_information" && (
                    <>
                      <div className="title">
                        <span>{t("contact_information")}</span>
                      </div>
                      <span>
                        {t(
                          "we_hate_data_entry_too_either_upload_resume_or_go_old_school_mobile"
                        )}
                      </span></>
                  )}

                </div>
                <div className="oa-task-form-content">{getForm()}</div>
                {submitApplicationState.error && (
                  <div className={"error-section"}>
                    <ApiError
                      message={submitApplicationState.error.message}
                      onClose={handleClearSubmitFormError}
                    />
                  </div>
                )}
              </div>
            </div>
            {getActions()}
          </div>
        </OnlineApplicationHolderMobile>
      </Mobile>
    </>
  );


  function getForm() {
    if (activeForm === 'education') {
      return (
        <div className="oa-edk-form">
          {/* <span>{t('education').toUpperCase()}</span> */}
          <span>{Object.keys(formState.education).length > 0 ? '' : <div className="oa-edk-header-txt"> {t('education').toUpperCase()}</div>}</span>
          {Object.keys(formState.education).map((key, index) => {
            return (
              <div key={key + index + 'form'} className="child-form-holder">
                {index !== -1 && getCloseExtraForm(t('education').toUpperCase(), parseInt(key), index, handleRemoveExtraForm)}
                <EducationForm
                  formState={formState.education[key]}
                  handleFieldChange={(field, value) => handleEducationFieldChange(field, value, index)}
                />
              </div>
            )
          })}
          <div className="oa-edk-action">
            <button className="add-new-position" onClick={handleAddAnotherSchool}>
              <div className="add-icon">
                <PlusIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
              </div>
              <div className="add-title">
                <span>{Object.keys(formState.education).length > 0 ? t('add_another_school') : t('add_school')}</span>
              </div>
            </button>
          </div>
          <div className="terms-condtions-input">
            <RenderInput
              doc={termsAndConditions}
              formState={formState}
              handleFieldChange={handleFieldChange}
              className={"oa-edk-f-input-holder"}
              inputLabelStyle={{ fontWeight: 800 }}
            />
            <div className='terms-conditions-font-color'>
              {params.lng === undefined ? verifyTokenState.response?.terms_and_conditions : verifyTokenState.response?.terms_and_conditions_spanish}
            </div>
          </div>
        </div>
      )
    }
    if (activeForm === 'work_history') {
      return (
        <div className="oa-wrk-form">
          {/* <span>{t('most_recent_position').toUpperCase()}</span> */}
          <span>{Object.keys(formState.work_history).length > 0 ? '' : <div className="oa-wrk-header-txt"> {t('most_recent_position').toUpperCase()}</div>}</span>
          {Object.keys(formState.work_history).map((key, index) => {
            return (
              <div key={key + index + 'form'} className="child-form-holder">
                {index != -1 && getCloseExtraForm(t('most_recent_position').toUpperCase(), parseInt(key), index, handleRemoveExtraForm)}
                <WorkHistoryForm
                  formState={formState.work_history[key]}
                  handleFieldChange={(field, value) => handleWorkHistoryFieldChange(field, value, index)}
                />
              </div>

            )
          })}
          <div className="oa-wrk-action">
            <button className="add-new-position" onClick={handleAddAnotherPosition}>
              <div className="add-icon">
                <PlusIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
              </div>
              <div className="add-title">
                <span>{Object.keys(formState.work_history).length > 0 ? t('add_another_position') : t('add_position')}</span>
              </div>
            </button>
          </div>
        </div>
      )
    }

    return (
      <ContactInformationForm
        formState={formState.contact_information}
        selectedPhoto={userPhoto}
        selectedResume={userResume}
        handleFieldChange={handleContactInfoFieldChange}
        onPhotoChange={setUserPhoto}
        onResumeChange={setUserResume}
        onRemovePhoto={() => setUserPhoto(null)}
        onRemoveResume={() => setUserResume(null)}
        tokenEmail={verifyTokenState?.response?.email}
      />
    )
  }

  function getCloseExtraForm(title: string, key: number, index: number, onClick: (key: number) => void) {

    return (
      <div className="close-extra-form">
        <div className="option-info">
          <span>{`${title} ${index + 1} `}</span>
        </div>
        <div className="option-action">
          <button className="icon-btn close-action" onClick={() => onClick(key)}>
            <CloseIcon width={"100%"} height={"100%"} />
          </button>
        </div>
      </div>
    )
  }

  function getActions() {
    if (activeForm === "education") {
      return (
        <div className="oa-actions">
          <div className="btn-cancel" style={{ marginRight: '1em' }}>
            <CustomButton
              loading={false}
              textStyle={{ textTransform: 'capitalize' }}
              name={t('back')}
              enable={true}
              backgroundColor={THEME.toggleDisableColor}
              onClick={handleBack}
            />
          </div>
          <div className="btn-save">
            <CustomButton
              loading={getSubmitLoading()}
              textStyle={{ textTransform: 'capitalize' }}
              name={t('finish')}
              enable={_isFormValid()}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      )
    }
    if (activeForm === "work_history") {
      return (
        <div className="oa-actions">
          <div className="btn-cancel" style={{ marginRight: '1em' }}>
            <CustomButton
              loading={false}
              textStyle={{ textTransform: 'capitalize' }}
              name={t('back')}
              enable={true}
              backgroundColor={THEME.toggleDisableColor}
              onClick={handleBack}
            />
          </div>
          <div className="btn-save">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: 'capitalize' }}
              name={t('next')}
              enable={_isWorkHistoryValid()}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleNext}
            />
          </div>
        </div>
      )
    }
    return (
      <div className="oa-actions">
        <div className="btn-save">
          <CustomButton
            loading={false}
            textStyle={{ textTransform: 'capitalize' }}
            name={t('next')}
            enable={_isContactInformationValid()}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={handleNext}
          />
        </div>
      </div>
    )
  }

  /// [handle on success submission]
  function handleOnsuccess() {

  }

  /// [invalid token] handle go to login page
  function handleGoToLogin() {
    history.push(AuthRoutes.loginPage);
  }

  function handleClearSubmitFormError() {
    dispatch(submitOnlineApplicationStateActions.clearSubmitApplicationStateError());
  }

  function handleSubmit() {

    const payload: IOnlineApplication = {
      ...formState,
      work_history: Object.keys(formState.work_history).map(key => formState.work_history[key]),
      education: Object.keys(formState.education).map(key => formState.education[key]),
    }
    dispatch(submitOnlineApplicationThunk(payload));
  }

  function handleNext() {
    if (activeForm === "education") {
      setActiveForm("education");
      setTasks({ ...tasks, education: { ...tasks.education, open: true } });
    }
    if (activeForm === "work_history") {
      setActiveForm("education");
      setTasks({ ...tasks, education: { ...tasks.education, open: true } });
    }
    if (activeForm === "contact_information") {
      setActiveForm("work_history");
      setTasks({ ...tasks, work_history: { ...tasks.work_history, open: true } });
    }
    if (currentStep.value < lastStep) {
      setCurrentStep(stepsList[currentStep.value + 1]);
    }

  }

  function handleBack() {

    if (activeForm === "education") {
      setActiveForm("work_history");
      setTasks({ ...tasks, education: { ...tasks.education, open: false } });
    }
    if (activeForm === "work_history") {
      setActiveForm("contact_information");
      setTasks({ ...tasks, work_history: { ...tasks.work_history, open: false } });

    }
    if (activeForm === "contact_information") {
      /// ignore
    }
    if (currentStep.value != 0 && currentStep.value <= lastStep) {
      setCurrentStep(stepsList[currentStep.value - 1]);
    }
  }


  function handleRemoveExtraForm(index: number) {
    if (activeForm === "education") {
      delete formState.education[index];
      setFormState({
        ...formState,
        education: {
          ...formState.education,
        },
      });
    }
    if (activeForm === "work_history") {
      delete formState.work_history[index];
      setFormState({
        ...formState,
        work_history: {
          ...formState.work_history,
        },
      });

    }
  }


  function handleAddAnotherPosition() {
    const length = Object.keys(formState.work_history).length;
    setFormState({
      ...formState,
      work_history: {
        ...formState.work_history,
        [length]: {
          job_title: '',
          start_date: 0,
          company_name: '',
          end_date: 0,
          company_department: '',
          is_currently_work_here: false,
          job_accomplishments_details_and_responsibilities: ''
        }
      }
    })
  }

  function handleAddAnotherSchool() {
    const length = Object.keys(formState.education).length;

    setFormState({
      ...formState,
      education: {
        ...formState.education,
        [length]: {
          last_school_attended: '',
          degree: '',
          year_completed: 0,
          is_currently_in_progress: false,
          awards_achievement_and_honours: ''
        }
      }
    })

  }

  function _isContactInformationValid() {
    const {
      first_name,
      phone,
      email,
    } = formState.contact_information;
    if (first_name === '' ||
      email === '') return false;
    if (userResume && userResume.type.startsWith('image/')) return false;
    if (RegxPattern.email.test(email) === false) return false;
    if (RegxPattern.phone.test(phone) === false) return false;
    return true;
  }
  function _isWorkHistoryValid() {

    const res = Object.keys(formState.work_history).map(key => {
      const {
        company_name,
        start_date,
        end_date,
      } = formState.work_history[key];
      if (company_name === '' || start_date === 0) return false;
      return true;
    }).filter(doc => doc === false);
    return res.length > 0 ? res[0] : true;
  }

  function _isFormValid() {
    const {
      agree_with_terms_and_conditions,
      token,
      agency_domain,
    } = formState;
    if (_isContactInformationValid() === false) return false;
    if (token === '' || agency_domain === '') return false;
    if (agree_with_terms_and_conditions === false) return false;
    return true;
  }
}

export default OnlineApplication;