import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, SuccessProfileQuestions } from "../../../../interfaces";
import * as thunk from "./success_profile_questions_list_thunk";

export interface SuccessProfileQuestionsListState extends EntityState<SuccessProfileQuestions> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const successProfileQuestionsListAdapter: EntityAdapter<SuccessProfileQuestions> = createEntityAdapter<SuccessProfileQuestions>({
    selectId: (doc) => doc.id
})

const initialState: SuccessProfileQuestionsListState = successProfileQuestionsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const successProfileQuestionListSlice = createSlice({
    name: 'successProfileQuestionList',
    initialState: initialState,
    reducers: {
        clearSuccessProfileQuestionListError(state){
            return {...state, error: null};
        },
        clearSuccessProfileQuestionListState(state){
            return successProfileQuestionsListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearSuccessProfileQuestionCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearSuccessProfileQuestionUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearSuccessProfileQuestionDeleteState(state) {
            return { ...state, remove : {...initialState.remove} };
        },
        clearSuccessProfileQuestionCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearSuccessProfileQuestionUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearSuccessProfileQuestionDeleteStateError(state) {
            return { ...state, remove : { ...initialState.remove , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getSuccessProfileQuestions.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getSuccessProfileQuestions.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getSuccessProfileQuestions.fulfilled, (state, action) => successProfileQuestionsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createSuccessProfileQuestions.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createSuccessProfileQuestions.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createSuccessProfileQuestions.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteSuccessProfileQuestions.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteSuccessProfileQuestions.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteSuccessProfileQuestions.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateSuccessProfileQuestions.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateSuccessProfileQuestions.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateSuccessProfileQuestions.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const successProfileQuestionListActions = successProfileQuestionListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = successProfileQuestionsListAdapter.getSelectors();
export const selectState = (state: SuccessProfileQuestionsListState) => state;
export const selectLoading = (state: SuccessProfileQuestionsListState) => state.loading;
export const selectError = (state: SuccessProfileQuestionsListState) => state.error;


