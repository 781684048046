import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { AppError, IJobOrder, CompanyData } from "../../../interfaces";
import { LoadingType } from "../../../enums";
import * as thunk from './job_order_thunk';
import { ContactListItem } from "../../../entities/ContactInfo";


export interface CompanyJobOrderState extends EntityState<IJobOrder> {
    error?: AppError | null,
    loading: LoadingType,
    contacts: {
        error?: AppError | null,
        loading: LoadingType,
        data: Array<ContactListItem> | null;
    }
    metaData: {
        error?: AppError | null,
        loading: LoadingType,
        data: CompanyData | null,
    },
    createJobOrder: {
        error?: AppError | null,
        loading: LoadingType,
        response: String,
    }
}

const companyJobOrderAdapter: EntityAdapter<IJobOrder> = createEntityAdapter<IJobOrder>({
    selectId: (doc) => doc.id,
})

const initialState: CompanyJobOrderState = companyJobOrderAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    contacts: {
        error: null,
        loading: LoadingType.idle,
        data: null
    },
    metaData: {
        error: null,
        loading: LoadingType.idle,
        data: null
    },
    createJobOrder: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const companyJobOrderSlice = createSlice({
    name: "companyJobOrderSlice",
    initialState: initialState,
    reducers: {
        clearContactsByCompany(state) {
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    error: null,
                    loading: LoadingType.idle,
                    data: null
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getJobsListByCompanies.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null }))
            .addCase(thunk.getJobsListByCompanies.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload }))
            .addCase(thunk.getJobsListByCompanies.fulfilled, (state, action) => companyJobOrderAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.getContactsByCompany.pending, (state, action) => ({ ...state, contacts: { ...state.contacts, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getContactsByCompany.rejected, (state, action) => ({ ...state, contacts: { ...state.contacts, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.getContactsByCompany.fulfilled, (state, action) => ({ ...state, contacts: { ...state.contacts, loading: LoadingType.succeeded, error: null, data: action.payload } }))

            .addCase(thunk.getCompanyMetaData.pending, (state, action) => ({ ...state, metaData: { ...state.metaData, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getCompanyMetaData.rejected, (state, action) => ({ ...state, metaData: { ...state.metaData, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.getCompanyMetaData.fulfilled, (state, action) => ({ ...state, metaData: { ...state.metaData, loading: LoadingType.succeeded, error: null, data: action.payload } }))

            .addCase(thunk.createCompanyJobOrder.pending, (state, action) => ({ ...state, createJobOrder: { ...state.createJobOrder, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.createCompanyJobOrder.rejected, (state, action) => ({ ...state, createJobOrder: { ...state.createJobOrder, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.createCompanyJobOrder.fulfilled, (state, action) => ({ ...state, createJobOrder: { ...state.createJobOrder, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
})

export const companyJobOrderActions = companyJobOrderSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = companyJobOrderAdapter.getSelectors();
export const selectState = (state: CompanyJobOrderState) => state;
export const selectContacts = (state: CompanyJobOrderState) => state.contacts;
