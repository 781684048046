import {createAsyncThunk} from "@reduxjs/toolkit";
import { catchRequestError} from "../../utils";
import {  AccountAPI } from "../../apis";
import { AppError } from "../../interfaces";


export const forgotPassword = createAsyncThunk<
    string, 
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>("@forgot-password/post", async (data, thunkAPI) => {
    try {
        const res = await AccountAPI.forgotPassword(data);
        return res.data;
    } catch (error) {
        return catchRequestError(error, thunkAPI);
    }
})


export const changePassword = createAsyncThunk<
    string, 
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>("@change-password/post", async (data, thunkAPI) => {
    try {
        const res = await AccountAPI.changePassword(data);
        return res.data;
    } catch (error) {
        return catchRequestError(error, thunkAPI);
    }
})