import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRCNewHireReport } from "../../../../interfaces/report_center_payroll_tax";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ReportCenterAPI } from "../../../../apis/report_center_api";

export const getNewHireReportList = createAsyncThunk<
    Array<IRCNewHireReport>,
    { start_date: number, end_date: number },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/new_hire_report/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getNewHireReport(payload.start_date, payload.end_date);
            if (typeof res.data === "string") return [];
            const arr = res.data;
            return arr as IRCNewHireReport[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });