import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { IAgencies } from "../../../../interfaces/agency_management";
import * as thunk from "./agencies_list_thunk";

export interface AgenciesListState extends EntityState<IAgencies> {
    error?: AppError | null,
    loading: LoadingType,
}

const AgenciesListAdapter: EntityAdapter<IAgencies> = createEntityAdapter<IAgencies>({
    selectId: (doc) => doc.id
})

const initialState: AgenciesListState = AgenciesListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const agenciesListSlice = createSlice({
    name: 'agenciesList',
    initialState: initialState,
    reducers: {
        clearAgencyListError(state){
            return {...state, error: null};
        },
        clearAgencyListState(state){
            return AgenciesListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAdminCenterAgencyManagementAgencies.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getAdminCenterAgencyManagementAgencies.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAdminCenterAgencyManagementAgencies.fulfilled, (state, action) => AgenciesListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearAgencyListError, clearAgencyListState } = agenciesListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = AgenciesListAdapter.getSelectors();
export const selectState = (state: AgenciesListState) => state;
export const selectLoading = (state: AgenciesListState) => state.loading;
export const selectError = (state: AgenciesListState) => state.error;


