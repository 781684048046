import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import TabNavigation from "../../../components/tab_navigation/tab_navigation";
import { AppRoutes } from "../../../routes";
import { GetCloseAction } from "../../../utils";
import WAErrorsReportPage from "../pages/weekly_accounting/errors_report/errors_report";
import WeeklyAccountingDashboardPage from "../pages/weekly_accounting/weekly_accounting_dashboard/weekly_accounting_dashboard";

const WeeklyAccounitngTabNav = (props: RouteComponentProps<any>) => {

    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();
    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                {
                    title: 'weekly_accounting_dashboard',
                    route: `${AppRoutes.weeklyAccountingDashboardPage}`,
                },
                {
                    title: "errors_report",
                    route: `${AppRoutes.weeklyAccountingErrorsReportPage}`,
                },

            ]}
            onChange={(value) =>
                history.push({ pathname: value, state: location.state })
            }
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.backOfficePage)} />}
        >
            <Switch>
                <Route
                    exact
                    path={`${AppRoutes.weeklyAccountingDashboardPage}`}
                    component={WeeklyAccountingDashboardPage}
                />
                <Route
                    exact
                    path={`${AppRoutes.weeklyAccountingErrorsReportPage}`}
                    component={WAErrorsReportPage}
                />
                <Route
                    path={"/"}
                    render={({ location }) => (
                        <Redirect
                            to={{
                                pathname: `${location.pathname}/dashboard`,
                                state: { ...(location.state as any) },
                            }}
                        />
                    )}
                />
            </Switch>
        </TabNavigation>
    );
}

export default WeeklyAccounitngTabNav;