import { useTranslation } from 'react-i18next';
import { THEME } from '../../enums';
import { ReloadIcon } from '../../icons';
import { AppError } from '../../interfaces';
import { CustomButton, SpinnerScreen } from '../../utils';
import './error_handler.scss';

interface Props {
    error: AppError,
    onRefresh: () => void,
}

/// error
export const ErrorHandler = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div className="error-handler-container">
            <div className="error-msg">
                <span>{props.error.message}</span>
            </div>
            <div className="error-action">
                <CustomButton
                    leftIcon={getIcon()}
                    loading={false}
                    textStyle={{ textTransform: 'capitalize' }}
                    name={t('refresh')}
                    enable={true}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={props.onRefresh}
                />
            </div>
        </div>
    );

    function getIcon() {
        return (
            <div className="err-h-add-icon">
                <ReloadIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
            </div>

        )
    }
}


/// Empty list


interface EmptyListProps {
    title: string,
    onClick: () => void,
    height?: any,
}
export const EmptyList = (props: EmptyListProps) => {
    const { t } = useTranslation();
    return (
        <div className="empty-list-container" style={{minHeight: props.height}}>
            <div className="empty-msg">
                <span>{t(props.title)}</span>
            </div>
            <div className="refresh-action">
                <CustomButton
                    leftIcon={getIcon()}
                    loading={false}
                    textStyle={{ textTransform: 'capitalize' }}
                    name={t('refresh')}
                    enable={true}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={props.onClick}
                />
            </div>
        </div>
    );

    function getIcon() {
        return (
            <div className="err-h-add-icon">
                <ReloadIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
            </div>

        )
    }
}

/// loading

/// Table loading
export const LoadingHandler = (props) => {

    return (
        <div className="loading-handler ">
            <SpinnerScreen />
        </div>
    );
}
