import { IEngagement } from "../interfaces";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const EngagementAPI = {


    getEngagementDataById(object_id: string) {
        return interceptor.get(`${UrlBuilder.engagementDetails}?object_id=${object_id}`);
    },

    addEngagement(doc: IEngagement) {
        return interceptor.post(UrlBuilder.engagementDetails, doc);
    },

    updateEngagement(doc: IEngagement) {
        return interceptor.patch(UrlBuilder.engagementDetails, doc);
    },

    deleteEngagement(id: string | null) {
        return interceptor.delete(`${UrlBuilder.engagementDetails}?engagement_details_id=${id}`);
    },

   
}