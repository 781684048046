import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as thunk from "./e_docs_onboarding_thunk";
import { AppError, IEmpOnboardingDocsReviewStatus } from "../../../interfaces";
import { LoadingType } from "../../../enums";

interface CRUDState {
  error?: AppError | null;
  loading: LoadingType;
  response: string | null;
}
const initialUpdateState: CRUDState = {
  response: null,
  error: null,
  loading: LoadingType.idle,
};

export interface SingleEmpODRSState {
  data: IEmpOnboardingDocsReviewStatus | null;
  error?: AppError | null;
  loading: LoadingType;
  update: CRUDState;
}
export interface EmpODRSState {
  [key: string]: SingleEmpODRSState;
}

const initialState: EmpODRSState = {};

function getLoadingState(state: any) {
  return {
    ...state,
    loading: LoadingType.pending,
    error: null,
  };
}

function getErrorState(state: any, payload: any) {
  return {
    ...state,
    loading: LoadingType.failed,
    error: payload,
  };
}

function getSuccessState(state: any, payload: any) {
  return {
    ...state,
    loading: LoadingType.succeeded,
    data: payload,
    error: null,
  };
}

export const empDashboardODRSSlice = createSlice({
  name: "empOnboardingReviewStatus",
  initialState: initialState,
  reducers: {
    clearState(state) {
      return { ...state, ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getEmpODRSThunk.pending, (state, action) => ({
        ...state,
        [action.meta.arg.talent_id]: {
          ...getLoadingState(state[action.meta.arg.talent_id]),
          update: initialUpdateState,
        },
      }))
      .addCase(thunk.getEmpODRSThunk.rejected, (state, action) => ({
        ...state,
        [action.meta.arg.talent_id]: {
          ...getErrorState(state[action.meta.arg.talent_id], action.payload),
        },
      }))
      .addCase(thunk.getEmpODRSThunk.fulfilled, (state, action) => ({
        ...state,
        [action.meta.arg.talent_id]: {
          ...getSuccessState(state[action.meta.arg.talent_id], action.payload),
        },
      }))


      .addCase(thunk.updateEmpODRSThunk.pending, (state, action) => ({
        ...state,
        update: { ...state.update, loading: LoadingType.pending, error: null },
      }))
      .addCase(thunk.updateEmpODRSThunk.rejected, (state, action) => ({
        ...state,
        update: {
          ...state.update,
          loading: LoadingType.failed,
          error: action.payload,
          response: null,
        },
      }))
      .addCase(thunk.updateEmpODRSThunk.fulfilled, (state, action) => ({
        ...state,
        update: {
          ...state.update,
          loading: LoadingType.succeeded,
          error: null,
          response: action.payload,
        },
      }))

      .addDefaultCase((state) => ({ ...state }));
  },
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const empDashboardODRSSliceActions = empDashboardODRSSlice.actions;
export const selectEmpODRSState = (state: EmpODRSState) => state;
export const selectSingleEmpODRSState = (
  state: EmpODRSState,
  id: string
): SingleEmpODRSState => state[id];

export const selectInitialSingleEmpODRSState =
  (): SingleEmpODRSState => ({
    data: null,
    error: null,
    loading: LoadingType.idle,
    update: initialUpdateState,
  });
