import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./archive_application_thunk";
export interface ArchiveApplicationState {
    response: string | null,
    error?: AppError | null,
    loading: LoadingType,
}

const initialState: ArchiveApplicationState = {
    response: null,
    error: null,
    loading: LoadingType.idle,

}

export const archiveApplicationSlice = createSlice({
    name: 'archiveApplication',
    initialState: initialState,
    reducers: {
        clearError(state) {
            return { ...state, error: null };
        },
        clearState(state) {
            return ({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.archiveOAPPLinkThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.archiveOAPPLinkThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.archiveOAPPLinkThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const archiveApplicationSliceActions = archiveApplicationSlice.actions;
export const selectState = (state: ArchiveApplicationState) => state;


