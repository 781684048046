
import { ICreateUnEmploymentClaim, ICreateWorkCompClaim } from "../interfaces";
import { } from "../interfaces/talent";
import { IUpdateReport } from "../interfaces/work_comp_audit_and_compliance_reporting";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const RiskManagementAPI = {

    getAllWorkCompClaims(branch_id?: string) {
        if (branch_id) {
            return interceptor.get(
                `${UrlBuilder.allworkCompClaims}?branch_id=${branch_id}`
            );
        }
        return interceptor.get(UrlBuilder.allworkCompClaims);
    },

    getWorkCompClaimsById(work_comp_claim_id: string) {
        return interceptor.get(`${UrlBuilder.workCompClaims}?work_comp_claim_id=${work_comp_claim_id}`);
    },

    getWorkCompClaimsByTalentId(talent_id: string) {
        return interceptor.get(`${UrlBuilder.workCompClaimsByTalentId}?talent_id=${talent_id}`);
    },

    postWorkCompClaims(doc: ICreateWorkCompClaim) {
        return interceptor.post(UrlBuilder.workCompClaims, doc);
    },

    patchWorkCompClaims(doc: ICreateWorkCompClaim) {
        return interceptor.patch(UrlBuilder.workCompClaims, doc);
    },

    deleteWorkCompClaims(work_comp_claims_id: string) {
        return interceptor.delete(`${UrlBuilder.workCompClaims}?work_comp_claims_id=${work_comp_claims_id}`);
    },

    getWorkCompClaimDocs(work_comp_claims_id: string, docs: any) {
        return interceptor.post(`${UrlBuilder.workCompClaimDocs}?object_id=${work_comp_claims_id}`,{docs_names:docs});
    },


    /// UN Employment claims

    getUnEmploymentClaims(branch_id?: string) {
        if (branch_id) {
            return interceptor.get(
                `${UrlBuilder.unEmploymentClaims}?branch_id=${branch_id}`
            );
        }
        return interceptor.get(UrlBuilder.unEmploymentClaims);
    },

    postUnEmploymentClaims(doc: ICreateUnEmploymentClaim) {
        return interceptor.post(UrlBuilder.unEmploymentClaims, doc);
    },

    patchUnEmploymentClaims(doc: ICreateUnEmploymentClaim) {
        return interceptor.patch(UrlBuilder.unEmploymentClaims, doc);
    },

    deleteUnEmploymentClaims(id: string) {
        return interceptor.delete(`${UrlBuilder.unEmploymentClaims}?risk_management_unemployment_claim_id=${id}`);
    },

    getUnEmploymentClaimDocs(un_employment_claims_id: string, docs: any) {
        return interceptor.post(`${UrlBuilder.unEmploymentClaimDocs}?object_id=${un_employment_claims_id}`,{docs_names:docs});
    },

    getUnEmploymentClaimsByTalentId(talent_id:string){
        return interceptor.get(`${UrlBuilder.unEmploymentClaimsByTalentId}?talent_id=${talent_id}`);
    },

    getFileDownloadURL(file_path:string){
        return interceptor.get(`${UrlBuilder.fileDownLoad}?file_path=${file_path}`);
    },

    getAllWorkCompAuditAndComplianceReports(record_id?: string) {
        if (record_id) {
            return interceptor.get(`${UrlBuilder.workCompAndComplianceReport}?record_id=${record_id}`);
        }
        return interceptor.get(UrlBuilder.workCompAndComplianceReport);
    },

    getWorkCompAndComplianceReportDocs(id: string, docs: any) {
        return interceptor.post(`${UrlBuilder.workCompAndComplianceReportDocs}?object_id=${id}`,{docs_names:docs});
    },

    createWorkCompAuditAndComplianceReports(doc: IUpdateReport) {
        return interceptor.post(UrlBuilder.workCompAndComplianceReport, doc);
    },

    updateWorkCompAuditAndComplianceReports(doc: IUpdateReport) {
        return interceptor.patch(UrlBuilder.workCompAndComplianceReport, doc);
    },


    deleteWorkCompAuditAndComplianceReports(rm_wc_audits_compliance_report_id) {
        return interceptor.delete(UrlBuilder.workCompAndComplianceReport, {params: {rm_wc_audits_compliance_report_id}});
    },

    getAllInsuranceCompanies() {
        return interceptor.get(UrlBuilder.riskManagementInsuranceCompany);
    },

}