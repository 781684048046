import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, TalentInterviewStatus } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getTalentInterviewStatus = createAsyncThunk<
    Array<TalentInterviewStatus>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/talent_interview_status/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getTalentInterviewStatus();
            return res.data as TalentInterviewStatus[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createTalentInterviewStatus = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_interview_status/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createTalentInterviewStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteTalentInterviewStatus = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_interview_status/delete',
    async (status_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteTalentInterviewStatus(status_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateTalentInterviewStatus = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_interview_status/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateTalentInterviewStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);