import { createAsyncThunk } from "@reduxjs/toolkit";
import { IntegrationAPI } from "../../../../../apis/integration_partners_api";
import { AppError } from "../../../../../interfaces";
import { IMasterTaxCredntials } from "../../../../../interfaces/master_tax";
import { catchRequestError } from "../../../../../utils";


export const getMasterTaxAuthThunk = createAsyncThunk<
    IMasterTaxCredntials,
    void,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@admin_center/integrations/masster_tax/credentials/get", async (_, thunkAPI) => {
    try {
        const res = await IntegrationAPI.getMasterTaxAuth();

        return res.data as IMasterTaxCredntials;
    } catch (error) {
        return catchRequestError(error, thunkAPI);
    }
});

export const updateMasterTaxAuthThunk = createAsyncThunk<
    { ssl_cert_upload_url: string, ssl_key_upload_url: string },
    IMasterTaxCredntials,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@admin_center/integrations/masster_tax/credentials/post", async (payload, thunkAPI) => {
    try {
        const res = await IntegrationAPI.updateMasterTaxAuth(payload);

        return res.data;
    } catch (error) {
        return catchRequestError(error, thunkAPI);
    }
});