import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./direct_deposit_add_thunk";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";

export interface TalentDirectDepositState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }
    submit: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }

}

const initialState: TalentDirectDepositState = {
    create: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    submit: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}


export const talentDirectDepositSlice = createSlice({
    name: 'talentDirectDepositBO',
    initialState: initialState,
    reducers: {
        clearState(state){
            return {...state, ...initialState};
        },
        clearError(state) {
            return {
                ...state,
                create: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },
                update: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },
                submit: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },

            };
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(thunk.updateTalentBOPayrollDirectDepositThunk.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
        .addCase(thunk.updateTalentBOPayrollDirectDepositThunk.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, response: null, } }))
        .addCase(thunk.updateTalentBOPayrollDirectDepositThunk.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))
        
        .addCase(thunk.validateTalentDirectDepositAcntThunk.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
        .addCase(thunk.validateTalentDirectDepositAcntThunk.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, response: null, } }))
        .addCase(thunk.validateTalentDirectDepositAcntThunk.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))
        
        .addDefaultCase(state => ({ ...state }));

    }
});

export const talentDirectDepositSliceActions = talentDirectDepositSlice.actions;
export const selectState = (state: TalentDirectDepositState) => state;


