import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContactsAPI } from "../../../../apis";
import { S3API } from "../../../../apis/s3_api";
import { AppError, IContact, ICreateContact, ICreateResponse, IProfilePictureRequestQuery, IProfilePictureUploadUrlResponse } from "../../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../../utils";
import { getContactsListThunk } from "../contacts_list/contacts_list_thunk";

/// Create user
export const createContact = createAsyncThunk<
    ICreateResponse,
    { forceAdd: boolean, value: ICreateContact },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/universal-directory/manage_contact/create',
    async (payload, thunkAPI) => {
        try {
            const res = await ContactsAPI.createContact(payload)
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data as ICreateResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Update user
export const updateContact = createAsyncThunk<
    ICreateResponse,
    IContact,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/universal-directory/manage_contact/update',
    async (payload, thunkAPI) => {
        try {
            const res = await ContactsAPI.updateContact(payload);
            thunkAPI.dispatch(getContactsListThunk());
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data as ICreateResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


/// Files upload
export const getContactFileUploadURLThunk = createAsyncThunk<
    IProfilePictureUploadUrlResponse,
    { type: string, query: IProfilePictureRequestQuery },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/universal-directory/manage_contact/file-upload-url/get',
    async (payload, thunkAPI) => {
        try {
            const res = await S3API.getPreSignedURLForUpload(payload.query)
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data as IProfilePictureUploadUrlResponse
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const uploadContactFileThunk = createAsyncThunk<
    any,
    { type: string, data: IProfilePictureUploadUrlResponse, file: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/universal-directory/manage_contact/file-upload-url/post',
    async (payload, thunkAPI) => {
        try {
            if (payload.file && payload.data.url) {
                const photoRes = await S3API.uploadFile(payload.data?.url, payload.file);
                return photoRes.data;
            }
            return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


