import { combineReducers } from "@reduxjs/toolkit";
import { ThirdPartyDeductionTypeListSlice } from "./deduction_type/deduction_type_reducer";
import { manageDeductionSlice } from "./manage_deductions/manage_deduction_type_reducer";
import { deductionDetailsSlice } from "./deduction_details_page/deduction_details_page_reducer";


export const thirdPartyDeductionReducers = combineReducers({

    [ThirdPartyDeductionTypeListSlice.name]: ThirdPartyDeductionTypeListSlice.reducer,
    [manageDeductionSlice.name]: manageDeductionSlice.reducer,
    [deductionDetailsSlice.name]:deductionDetailsSlice.reducer,

});


export * from "./deduction_type/deduction_type_thunk";
export * from "./deduction_type/deduction_type_selector";

export * from "./manage_deductions/manage_deduction_type_thunk";
export * from "./manage_deductions/manage_deduction_type_selector";

export * from "./deduction_details_page/deduction_details_page_thunk";
export * from "./deduction_details_page/deduction_details_page_selector";

