import { combineReducers } from "@reduxjs/toolkit";
import { talentWorkCompClaimsListSlice } from "./talent_work_comp_claims_list/talent_work_comp_claims_list_reducer";
import { talentUnEmploymentClaimsListSlice } from "./talent_unemployement_claims_list/talent_unemployement_claims_list_reducer";

export const talentRiskManagementReducers = combineReducers({

    [talentWorkCompClaimsListSlice.name]: talentWorkCompClaimsListSlice.reducer,
    [talentUnEmploymentClaimsListSlice.name]: talentUnEmploymentClaimsListSlice.reducer,
});

export * from './talent_unemployement_claims_list/talent_unemployement_claims_list_selector';
export * from './talent_unemployement_claims_list/talent_unemployement_claims_list_thunk';

export * from './talent_work_comp_claims_list/talent_work_comp_claims_list_selector';
export * from './talent_work_comp_claims_list/talent_work_comp_claims_list_thunk';