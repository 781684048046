import { combineReducers } from "@reduxjs/toolkit";
import { directDepositSlice } from "./direct_deposit/direct_deposit_reducer";
import { employeeHandbookSlice } from "./employee_handbook/employee_handbook_reducer";
import { employeeSignatureSlice } from "./employee_signature/employee_signature_reducer";
import { essentialStaffcareSlice } from "./essential_staffcare/essential_staffcare_reducer";
import { healthCareExchangeSlice } from "./healthcare_exchange/healthcare_exchange_reducer";
import { i9Slice } from "./i9/i9_reducer";
import { onBoardingSubmitApplicationSlice } from "./submit_application/submit_application_reducer";
import { talentOnboardingSlice } from "./talent_onboarding/talent_onboarding_reducer";
import { onBoardingVerifyTokenSlice } from "./verify_token/verify_token_reducer";
import { w4Slice } from "./w4/w4_reducer";
import { wotcSlice } from "./wotc/wotc_reducer";

export const onboardingPortalReducers = combineReducers({
    [onBoardingVerifyTokenSlice.name]: onBoardingVerifyTokenSlice.reducer,
    [onBoardingSubmitApplicationSlice.name]: onBoardingSubmitApplicationSlice.reducer,
    [employeeHandbookSlice.name]: employeeHandbookSlice.reducer,
    [employeeSignatureSlice.name]: employeeSignatureSlice.reducer,
    [directDepositSlice.name]: directDepositSlice.reducer,
    [essentialStaffcareSlice.name]: essentialStaffcareSlice.reducer,
    [healthCareExchangeSlice.name]: healthCareExchangeSlice.reducer,
    [i9Slice.name]: i9Slice.reducer,
    [w4Slice.name]: w4Slice.reducer,
    [wotcSlice.name]: wotcSlice.reducer,
    [talentOnboardingSlice.name]: talentOnboardingSlice.reducer,
});

export * from './verify_token/verify_token_selector';
export * from './verify_token/verify_token_thunk';

export * from './submit_application/submit_application_selector';
export * from './submit_application/submit_application_thunk';

export * from './employee_handbook/employee_handbook_selector';
export * from './employee_handbook/employee_handbook_thunk';

export * from './direct_deposit/direct_deposit_selector';
export * from './direct_deposit/direct_deposit_thunk';

export * from './essential_staffcare/essential_staffcare_selector';
export * from './essential_staffcare/essential_staffcare_thunk';

export * from './healthcare_exchange/healthcare_exchange_selector';
export * from './healthcare_exchange/healthcare_exchange_thunk';

export * from './i9/i9_selector';
export * from './i9/i9_thunk';

export * from './w4/w4_selector';
export * from './w4/w4_thunk';

export * from './wotc/wotc_selector';
export * from './wotc/wotc_thunk';

export * from './talent_onboarding/talent_onboarding_selector';
export * from './talent_onboarding/talent_onboarding_thunk';
