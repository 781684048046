import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI  } from "../../../apis";
import { AppError, IBusinessSector} from "../../../interfaces";
import { catchRequestError } from "../../../utils";

export const getBusinessSectorSelector = createAsyncThunk<
    Array<IBusinessSector>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@selectors/business-sectors/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getBusinessSector();
            return res.data as IBusinessSector[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);