import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, Profile } from "../../../../interfaces";
import * as thunk from "./agencies_details_thunk";
import { IAgencies } from "../../../../interfaces/agency_management";

export interface AgencyDetailsState {
    error?: AppError | null,
    loading: LoadingType,
    agency: IAgencies | null,
}

const initialState: AgencyDetailsState = {
    error: null,
    loading: LoadingType.idle,
    agency: null,
}


export const agenciesDetailsSlice = createSlice({
    name: 'agenciesDetailsSlice',
    initialState: initialState,
    reducers: {
        clearAgencyDetailsState(state){
            return {...state, ...initialState};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAdminCenterAgencyManagementAgencyDetails.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getAdminCenterAgencyManagementAgencyDetails.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAdminCenterAgencyManagementAgencyDetails.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, agency: action.payload, }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearAgencyDetailsState } = agenciesDetailsSlice.actions;
export const selectState = (state: AgencyDetailsState) => state;
export const selectLoading = (state: AgencyDetailsState) => state.loading;
export const selectError = (state: AgencyDetailsState) => state.error;


