import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./employer_tax_thunk";
import { IRCEmployerTax } from "../../../../interfaces/report_center";


export interface EmplyerTaxState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCEmployerTax | null
}

const initialState: EmplyerTaxState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}

export const employerTaxSlice = createSlice({
    name: 'employerTaxSlice',
    initialState: initialState,
    reducers: {
        clearETErrorState(state) {
            return { ...state, error: null };
        },
        clearETstate(state) {
            return { ...state, ...initialState };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getEmployerTaxThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getEmployerTaxThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getEmployerTaxThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const employerTaxSliceActions = employerTaxSlice.actions;
export const selectState = (state: EmplyerTaxState) => state;
export const selectLoading = (state: EmplyerTaxState) => state.loading;
export const selectError = (state: EmplyerTaxState) => state.error;



