import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ITrafficLightEngagementDetails } from "../../../../interfaces";
import * as thunk from "./send_online_application_thunk";

export interface SendOnlineApplicationState {
    response: string | null,
    error?: AppError | null,
    loading: LoadingType,
}



const initialState: SendOnlineApplicationState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


export const dasboardSendOnlineApplicationsSlice = createSlice({
    name: 'sendOnlineApplications',
    initialState: initialState,
    reducers: {
        clearSendOnlineApplicationsError(state) {
            return { ...state, error: null };
        },
        clearSendOnlineApplicationState(state) {
            return ({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.sendOnlineApplicationThunk.pending,
                (state, action) => ({
                    ...state,
                    loading:  LoadingType.pending,
                    error: null,
                })
            )
            .addCase(
                thunk.sendOnlineApplicationThunk.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.sendOnlineApplicationThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null,
                    response: action.payload,
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const sendOnlineApplicationsStateActions = dasboardSendOnlineApplicationsSlice.actions;
export const selectState = (state: SendOnlineApplicationState) => state;
export const selectLoading = (state: SendOnlineApplicationState) => state.loading;
export const selectError = (state: SendOnlineApplicationState) => state.error;


