import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormDialogTilteHeader } from "../../../../../components/dialog_wrapper/dialog_wrapper";
import MenuOption, {
  MenuOptionDocInput,
} from "../../../../../components/menu_option/menu_option";
import { THEME } from "../../../../../enums";
import { OnboardingPaperWorkOptions } from "../../../../../enums/onboarding_paperwork";
import { OnBoardingIcon } from "../../../../../icons";
import { CustomButton } from "../../../../../utils";
import "./emp_options_menu.scss";
import OnboardingReviewForm, {
  IReviewForm,
} from "../../../forms/onboarding_review_form/onboarding_review_form";
import {
  IOnboardingDocsReviewStatus, 
  IProfileDropdown,
  ITalent,
} from "../../../../../interfaces";
import { getDateFromTimeStamp, getDateString } from "../../../../../variables";

interface Props {
  title: string;
  enableSave: boolean;
  menuOptions: Array<{
    type: OnboardingPaperWorkOptions;
    title: string;
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    visible: boolean;
    onClick: (type: string) => void;
  }>;
  onCancel: () => void;
  onSave: () => void;
  talentData: ITalent | null;
  state: IOnboardingDocsReviewStatus;
  today: number;
  currentLoginUserName: string;
  recruitersList: IProfileDropdown[];
}

function EmployeeHandbookOptionMenu(props: Props) {
  const {
    title,
    enableSave,
    menuOptions,
    onCancel,
    onSave,
    talentData,
    state,
    today,
    currentLoginUserName,
    recruitersList,
  } = props;
  const { t } = useTranslation();
  const reviewedByData = recruitersList.filter(
    (recruiter) =>
      state && recruiter.id === state?.handbook_document?.reviewed_by
  );
  const reviewedBy = reviewedByData
    .map((recruiter) => `${recruiter.first_name} ${recruiter.last_name}`)
    .join(", ");

  const [formState, setFormState] = useState<IReviewForm>({
    name:
      state && talentData
        ? talentData?.first_name + " " + talentData?.last_name
        : "",
    recruiter_completed_review:
      state && state?.handbook_document?.recruiter_completed_review
        ? "Reviewed"
        : "Not Reviewed",
    reviewed_on_timestamp:
      state && state?.handbook_document?.reviewed_on_timestamp !== null
        ? getDateString(
            state?.handbook_document?.reviewed_on_timestamp,
            "mm/dd/yyyy"
          )
        : getDateString(today, "mm/dd/yyyy"),
    reviewed_by:
      state && state?.handbook_document?.reviewed_by !== null
        ? (reviewedBy ?? "")
        : currentLoginUserName,
  });

  return (
    <div className="talent-eop-menu-container">
      <div className="talent-eop-menu-header">
        <FormDialogTilteHeader
          title={title}
          subTitle={t("please_read_employee_handbook")}
          subTitleStyle={{ fontWeight: "normal" }}
          titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
        />
      </div>
      <div className="talent-eop-menu-content">
        <div>
          <OnboardingReviewForm
            handleFieldChange={() => {}}
            formState={formState}
          />
        </div>
        <div className="talent-eop-menu-options">
          {menuOptions.map((doc, index) => {
            return (
              <MenuOption
                key={index}
                customStyle={{
                  width: "16.968vw",
                  border: `1px solid ${THEME.defaultHighLightColor}50`,
                }}
                doc={{ title: doc.title, icon: doc.icon }}
                t={t}
                onClick={() => doc.onClick(doc.type)}
              />
            );
          })}
        </div>
      </div>
      <div className="talent-eop-menu-actions">
        <div className="btn-cancel">
          <CustomButton
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("cancel")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={onCancel}
          />
        </div>
        <div className="btn-new">
          <CustomButton
            loading={false}
            textStyle={{
              textTransform: "capitalize",
            }}
            name={t("save")}
            enable={enableSave}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={onSave}
          />
        </div>
      </div>
    </div>
  );
}

export default EmployeeHandbookOptionMenu;
