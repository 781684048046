import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import {
    selectState,
    selectContactState,
    selectInitialTalentState,
    selectContactSkillsState,
    selectContactCredentialsState,
    selectContactCertificationsState,
    selectContactEducationState,
    selectContactEquipmentState,
} from "./contact_details_reducer";

const select = (state: IRootState) => state.contacts.details.dashboard.contactDetails;
export const selectContactDetailsState = createSelector(select, selectState);

export const selectContactDetailsByIdState = (id: string | undefined | null) => createSelector(
    selectContactDetailsState,
    (state) => {
        if (id && state[id]) return selectContactState(state, id);
        return selectInitialTalentState();
    }
);
export const selectContactSkillsStateById = (id: string | undefined | null) => createSelector(
    selectContactDetailsByIdState(id),
    selectContactSkillsState,
);
export const selectContactCredentialsStateById = (id: string | undefined | null) => createSelector(
    selectContactDetailsByIdState(id),
    selectContactCredentialsState,
);
export const selectContactCertificationsStateById = (id: string | undefined | null) => createSelector(
    selectContactDetailsByIdState(id),
    selectContactCertificationsState,
);
export const selectContactEducationStateById = (id: string | undefined | null) => createSelector(
    selectContactDetailsByIdState(id),
    selectContactEducationState,
);
export const selectContactEquipmentStateById = (id: string | undefined | null) => createSelector(
    selectContactDetailsByIdState(id),
    selectContactEquipmentState,
);
// export const selectContactDetailsDepartmentState = createSelector(select, selectDepartmentState);
