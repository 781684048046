import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import MultiOptionSection from '../../../../../../components/multi_option_section/multi_option_section';
import { TALENT_SKILLS_TYPE } from '../../../../../../enums';
import { ISkillOption } from '../../../../../../interfaces';
import { selectCompanyDetailsByIdState } from '../../../../../../redux/store';
import { useAppSelector } from '../../../../../../redux/store';
import './company_overview.scss';

interface Props {
    onAddOption: (type: string) => void,
    onRemoveOption: (type: string, value: ISkillOption) => void,
}

const CompanyOverView: React.FunctionComponent<Props> = (props) => {
    const { params } = useRouteMatch();
    const companyState = useAppSelector((state) => selectCompanyDetailsByIdState((params as { id: string, departmentId: string }).departmentId ?? (params as { id: string, departmentId: string }).id)(state));
    const { t } = useTranslation();
    return (
        <div className="cd-cs-covv-container"> 
            <MultiOptionSection
                headerTitle={t('professional_skills').toUpperCase()}
                actionName={t('add_skills')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.professional_skills)}
                list={companyState.professional_skills.data ?? []}
                onRemove={(value)=> props.onRemoveOption(TALENT_SKILLS_TYPE.professional_skills, value)}
            />
            <MultiOptionSection
                headerTitle={t('credentials').toUpperCase()}
                actionName={t('add_credentials')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.credentials)}
                list={companyState.credentials.data ?? []}
                onRemove={(value)=> props.onRemoveOption(TALENT_SKILLS_TYPE.credentials, value)}
            />
            <MultiOptionSection
                headerTitle={t('certifications').toUpperCase()}
                actionName={t('add_certifications')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.certifications)}
                list={companyState.certifications.data ?? []}
                onRemove={(value)=> props.onRemoveOption(TALENT_SKILLS_TYPE.certifications, value)}
            />
            <MultiOptionSection
                headerTitle={t('education').toUpperCase()}
                actionName={t('add_education')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.education)}
                list={companyState.education.data ?? []}
                onRemove={(value)=> props.onRemoveOption(TALENT_SKILLS_TYPE.education, value)}
            />
            <MultiOptionSection
                headerTitle={t('required_equipment').toUpperCase()}
                actionName={t('add_equipment')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.safety_equipment)}
                list={companyState.safety_equipment.data ?? []}
                onRemove={(value)=> props.onRemoveOption(TALENT_SKILLS_TYPE.safety_equipment, value)}
            />
            <div className='company_about_us'>
                <div className='title'>{t('company_about_us').toUpperCase()}</div>
                <div className='about_us_content'>{companyState.data?.summary}</div>
            </div>
        </div>
    )
}



export default CompanyOverView;