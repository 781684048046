import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI,  } from "../../../../apis";
import { AppError, } from "../../../../interfaces";
import { ISendOnBoardingLink } from "../../../../interfaces/onboarding_link";
import { catchRequestError } from "../../../../utils";

export const sendOnBoardingLinkThunk = createAsyncThunk<
string,
{ forceResend: boolean, value: ISendOnBoardingLink },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/onboarding/post',
    async (payload, thunkAPI) => {
        try {
            const res = await DashboardAPI.postOnBoardingLinks(payload);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);