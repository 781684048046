import { useTranslation } from 'react-i18next';
import { LoadingType, THEME } from '../../enums';
import { CustomButton } from '../../utils';
import './company_invoice_edoc_card.scss';
import { PlusWhiteIcon } from '../../icons';
import { useEffect, useState } from 'react';
import { Portal } from 'react-portal';
import InvoiceEdocsPopup from '../../main/companies/popup/edocs_invoice/edocs_invoice';
import { addNewCompanyEdDocsThunk, getCompanyEdDocsThunk, getCompanyInvoicesThunk, getInvoicePDFURLThunk, mailCompanyEdocsThunk, mailCompanyInvoicesThunk, selectCompanyEDocsState, selectCompanyInvoicesState, selectProfileState, selectePaycheckInvoiceState, useAppDispatch, useAppSelector } from '../../redux/store';
import NewEdocPopup from '../../main/companies/popup/add_new_edoc/add_new_edoc';
import { ICompanyEdocs } from '../../interfaces';
import { convertDateToTimeStamp } from '../../variables';
import { companyEdocsListActions } from '../../redux/companies/details/company_edocs/company_edocs_reducer';
import { companyInvoiceListActions } from '../../redux/companies/details/company_invoices/company_invoices_reducer';
import { paycheckInvoiceSliceActions } from '../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer';

interface Props {
    title: string,
    backgroundColor?: string,
    activeColor: string,
    icon?: JSX.Element,
    isButton?: boolean,
    type: string,
    company_id: string,
    companyName: string,
    department_id: string,
};

const CompanyBOInvoiceEdocsCard = (props: Props) => {
    const {
        title, backgroundColor, activeColor, icon, isButton, type, company_id, companyName, department_id } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`;
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [addNewEdoc, setAddNewEdoc] = useState<boolean>(false);

    const companyInvoiceState = useAppSelector((state) => selectCompanyInvoicesState(state));
    const companyEDocsState = useAppSelector((state) => selectCompanyEDocsState(state));
    const invoicePDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));
    useEffect(() => {
        if (invoicePDFURLState.invoice.loading === LoadingType.succeeded && invoicePDFURLState.invoice.response !== "") {
            window.open(invoicePDFURLState.invoice.response, "_blank");
            dispatch(paycheckInvoiceSliceActions.clearInoviceState());
        }
    }, [invoicePDFURLState.invoice.loading, invoicePDFURLState.invoice.response]);
    const handleClose = () => {
        setShowPopup(false);
        dispatch(companyInvoiceListActions.clearState());
        dispatch(companyEdocsListActions.clearState());
    }
    const onHeaderClick = () => {
        setShowPopup(true);
        if (department_id) {
            if (type === "invoices") {
                dispatch(getCompanyInvoicesThunk({ department_id: department_id }))
            }
            if (type === "edocs") {
                dispatch(getCompanyEdDocsThunk({ company_id: company_id, department_id: department_id }))
            }
        }
        else {
            if (type === "invoices") {
                dispatch(getCompanyInvoicesThunk({ company_id: company_id }))
            }
            if (type === "edocs") {
                dispatch(getCompanyEdDocsThunk({ company_id: company_id }))
            }
        }
    }

    const handleSave = (formState: ICompanyEdocs, selectedFile: File | null) => {
        dispatch(addNewCompanyEdDocsThunk({
            data: {
                ...formState,
                company_id: company_id,
                department_id: department_id,
                upload_date: convertDateToTimeStamp(new Date()),
                file_name: selectedFile?.name ?? ""
            }, file: selectedFile
        }));
    }
    const handleClearError = () => {
        dispatch(companyEdocsListActions.clearAddErrorState());
    }
    const handleEdocClose = () => {
        setAddNewEdoc(false);
        dispatch(companyEdocsListActions.clearAddState());
    }
    const getInvoiceURL = (invoice_id: string) => {
        dispatch(getInvoicePDFURLThunk(invoice_id));
    }
    const sendInvoicesByMail = (invoice_ids: string[]) => {
        dispatch(mailCompanyInvoicesThunk(invoice_ids));
    }
    const sendEdocsByMail = (email_id: string, invoice_ids: string[]) => {
        dispatch(mailCompanyEdocsThunk({ email_id: email_id, edoc_ids: invoice_ids }));
    }
    return (
        <div className="bo-card-container">
            <div className="bo-card-header" style={{ backgroundColor: props.backgroundColor }}>
                <div className="bo-card-sub-header" onClick={onHeaderClick}>
                    <div className="bo-card-path-name">
                        <span className="bo-card-path-txt" style={{ color: props.activeColor }}>{props.title}</span>
                    </div>
                    {props.icon && <div className="bo-card-icon">
                        {props.icon}
                    </div>}
                </div>
                {props.isButton && <div className="bo-card-button">
                    <CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        name={t("add_new_doc")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => setAddNewEdoc(true)}
                    />
                </div>}
            </div>
            {showPopup &&
                <Portal>
                    <InvoiceEdocsPopup
                        visible={showPopup}
                        title={props.title}
                        onClose={handleClose}
                        type={type}
                        invoiceState={companyInvoiceState}
                        edocState={companyEDocsState}
                        getInvoiceURL={(invoice_id) => getInvoiceURL(invoice_id)}
                        sendInvoicesByMail={(invoice_ids) => sendInvoicesByMail(invoice_ids)}
                        currentLoginUserName={currentLoginUserName}
                        sendEdocsByMail={(email_id, edoc_ids) => sendEdocsByMail(email_id, edoc_ids)}
                        company_id={company_id}
                    />
                </Portal>
            }
            {addNewEdoc &&
                <Portal>
                    <NewEdocPopup
                        visible={addNewEdoc}
                        title={t('hi_user_complete_add_doc', { name: currentLoginUserName })}
                        onClose={handleEdocClose}
                        edocAddState={companyEDocsState.add}
                        currentLoginUserName={currentLoginUserName}
                        handleSave={(formState, selectedFile) => handleSave(formState, selectedFile)}
                        successTitle={''}
                        company_name={companyName}
                        handleClearError={handleClearError}
                    />
                </Portal>
            }
        </div>

    );
}
function getPlusBtnIcon() {
    return (
        <div className="btn-icon">
            <PlusWhiteIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
        </div>
    );
}
export default CompanyBOInvoiceEdocsCard;