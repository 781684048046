import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { NavLink, RouteComponentProps } from "react-router-dom";
import SearchBar from "../../../../components/search_bar";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableFilterEmpty,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType, TALENT_STATUS, THEME } from "../../../../enums";
import { CircleDownloadIcon, DownloadIcon, TalentActiveStatusIcon } from "../../../../icons";
import { ITalent } from "../../../../interfaces";
import {
  getTalentList,
  selectTalentList,
  selectTalentListState,
  selectTalentListEntities,
  selectRoleUsersListState,
  selectRecruiterRoleUsersList,
  getRoleUsersList,
  selectProfileState,
  getTEADraftBroadCast,
  selectTextEmAllState,
} from "../../../../redux/store";
import { manageTalentActions } from "../../../../redux/talent/universal_directory/manage_talent/manage_talent_reducer";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  CustomCheckBox,
  getContactTypeIcon,
  getPlusBtnIcon,
  getTableAccessIcon,
  getTextEmAllIcon,
} from "../../../../utils";
import "./universal_directory_page.scss";
import { getDaysAgoWithDateAMPM } from "../../../../variables";
import CustomFormSelect, {
  CustomMultiFormSelect,
} from "../../../../components/form_selector/form_select";
import CreateTalentPopup from "../../popups/create_talent/create_talent";
import SSNHolder from "../../../../components/ssn_holder/ssn_holder";
import ViewSSNPopup from "../../popups/view_ssn/view_ssn";
import { talentRequestSSNActions } from "../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer";
import BroadcastToTalentPopup from "../../../../components/broadcast_talent/broadcast_talent";
import { TEADraftBroadCast } from "../../../../interfaces/text_em_all";
import { textEmAllSliceActions } from "../../../../redux/textEmAll/text_Em_All_reducer";
import BroadcastToTalentErrorPopup from "../../../../components/broadcast_talent/broadcast_talent_error";
import { getTalentStatus, selectTalentStatusList, selectTalentStatusState } from "../../../../redux/admin_center";
import UrlBuilder from "../../../../apis/url_builder";
import UploadRecordPopup from "../../../components/import_data_file/import_data_file";
import { selectImportDataFileUrlState, selectUpdateImportDataFileUrlState, updateImpDataFileUploadUrl } from "../../../../redux/upload_files";
import { importUrlActions } from "../../../../redux/upload_files/import_data_file_reducer";

interface Props extends RouteComponentProps<any> { }

const UniversalDirectoryPage: React.FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { history, location } = props;
  const { t } = useTranslation();
  const SelectAllCheckBox = () => {
    return (
      <CustomCheckBox
        name={""}
        title={""}
        checked={selectedItems.size === getFilteredList().length}
        onClick={handleMarkAllItem}
      />
    );
  };
  const tableHeader = [
    { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
    { title: "access_talent", code: "access_talent" },
    { title: "name", code: "first_name" },
    { title: "job_title", code: "title" },
    { title: "on_assignment", code: "on_assignment_company" },
    { title: "status", code: "status" },
    { title: "address", code: "street_address" },
    { title: "city", code: "city" },
    { title: "state", code: "state" },
    { title: "zip_code", code: "zip_code" },
    { title: "ssn_hash", code: "last_4_digits_of_ssn" },
    { title: "last_contact", code: "last_engagement_date" },
    { title: "contact", code: "preferred_contact_method" },
    // { title: 'recruiter', code: 'recruiter' },
  ];
  const profileState = useAppSelector((state) => selectProfileState(state));

  const canSendText =
    profileState?.profile?.texting &&
    profileState?.profile?.text_em_all_token !== null;
  const [showSSNPopup, setShowSSNPopup] = useState<{
    talentId: string;
    visible: boolean;
  } | null>(null);
  const talentListState = useAppSelector((state) =>
    selectTalentListState(state)
  );
  const talentList = useAppSelector((state) => selectTalentList(state));
  const recruitersList = useAppSelector((state) =>
    selectRecruiterRoleUsersList(state)
  );

  const textEmAllState = useAppSelector((state) => selectTextEmAllState(state));

   ///Talent Status
  //  const talentStatusState = useAppSelector((state) => selectTalentStatusState(state));
  const talentStatusList = useAppSelector((state) => selectTalentStatusList(state));

  const importDataFileUrlState = useAppSelector((state) => selectImportDataFileUrlState(state));
  const updateImportDataFileUrlState = useAppSelector((state) => selectUpdateImportDataFileUrlState(state));

  const [importTalents, setImportTalents] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const [search, setSearch] = useState("");
  const [sortedField, setSortedField] = useState<string | null>("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [addTalent, setAddTalent] = useState<boolean>(false);
  const [recruiters, setRecruiter] = useState<string[]>([]);
  // const [status, setStatus] = useState<string | null>(null);
  const [status, setStatus] = useState<string[]>([]);
  const [activeAssignment, setActiveAssignment] = useState<boolean>(false);
  const [broadcastTalent, setBroadcastTalent] = useState<boolean>(false);
  const [broadcastTalentError, setBroadcastTalentError] =
    useState<boolean>(false);

  const [selectedItems, setSelectedItems] = useState<
    Map<string, { id: string; name: string }>
  >(new Map());

  const handleMarkItem = (value: ITalent) => {
    const newSet = new Map(selectedItems);
    if (selectedItems.has(value.id)) {
      newSet.delete(value.id);
    } else {
      newSet.set(value.id, {
        id: value.id,
        name: value.first_name + " " + value.last_name,
      });
    }
    setSelectedItems(newSet);
  };
  const handleMarkAllItem = () => {
    const newSet = new Map<string, { id: string; name: string }>();
    if (selectedItems.size === getFilteredList().length) {
      setSelectedItems(newSet);
    } else {
      getFilteredList().forEach((doc) =>
        newSet.set(doc.id, {
          id: doc.id,
          name: doc.first_name + " " + doc.last_name,
        })
      );
      setSelectedItems(newSet);
    }
  };

  useEffect(() => {
    getTalents();
    checkAddTalent();
    dispatch(getRoleUsersList());
    dispatch(getTalentStatus());
    return () => { };
  }, []);

  useEffect(() => {
    if (talentListState.error != null && talentList.length !== 0) {
      dispatch(postSnackbarMessage(talentListState.error.message));
    }
    return () => { };
  }, [talentListState.error]);
  useEffect(() => {
    dispatch(textEmAllSliceActions.clearState());
    return () => { };
  }, []);
  useEffect(() => {
    if (
      textEmAllState &&
      textEmAllState?.error !== null &&
      textEmAllState?.loading === LoadingType.failed
    ) {
      setBroadcastTalentError(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.succeeded
    ) {
      setBroadcastTalent(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.idle
    ) {
      setBroadcastTalent(false);
      setBroadcastTalentError(false);

    }
  }, [textEmAllState, textEmAllState.error, textEmAllState.loading]);

  function getTalents() {
    dispatch(getTalentList());
  }

  function checkAddTalent() {
    setTimeout(() => {
      if (
        location &&
        location.state != null &&
        (location.state as any).newTalent === true
      ) {
        setAddTalent(true);
      }
    }, 1000);
  }

  useEffect(() => {
    const state = history.location.state as any;
    if (state?.activeAssignment) {
      setActiveAssignment(true);
    }
  }, [history]);

  useEffect(() => {
    if (updateImportDataFileUrlState?.loading === LoadingType.succeeded && updateImportDataFileUrlState?.response) {
      if (snackBarMessage !== "") {
        dispatch(postSnackbarMessage(snackBarMessage ?? null));
        dispatch(importUrlActions.clearUpdateImportUrlStateError())
      }
      setImportTalents(false)
      // getTalents();
    }
    return () => { };
  }, [updateImportDataFileUrlState?.loading]);

  useEffect(() => {
    if (importDataFileUrlState?.loading === LoadingType.succeeded && importDataFileUrlState?.response) {
      dispatch(updateImpDataFileUploadUrl({
        baseUrl: UrlBuilder.updateImportTalents,
        import_id: importDataFileUrlState?.response?.id ?? "" 
      }))
    }
    if (snackBarMessage !== "") {
      dispatch(postSnackbarMessage(snackBarMessage ?? null));
    }
  }, [importDataFileUrlState?.loading, importDataFileUrlState?.response]);

  const [formState] = useState<TEADraftBroadCast>({
    modified_by: "",
    created_by: "",
    modified_date: 0,
    talent_ids: [],
  });
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = useState<string>("");

  const handleClearError = () => {
    dispatch(importUrlActions.clearImportUrlStateError())
  }

  const handleSnackBar = () => {
    setSnackBarMessage(t("talents_uploaded_successfully"))
  }

  const handleBroadCastTalent = (forceSend?:boolean) => {
    if (selectedItems.size !== 0) {
      const talentids_list = Array.from(selectedItems.keys());
      dispatch(
        getTEADraftBroadCast({forceSend: forceSend, data:{ ...formState, talent_ids: talentids_list }})
      );
      // setBroadcastTalent(true);
      setShowMsg(false);
    } else {
      setShowMsg(true);
      setShowErrorMsg(t("please_select_talent_to_broadcast"));
      setTimeout(() => setShowErrorMsg(""), 5000);
    }
  };

  function getFilteredList(): ITalent[] {
    let sortedList: ITalent[] | undefined;
    let resultList: ITalent[] | undefined;

    if (activeAssignment) {
      resultList = talentList.filter((doc) => {
        const onAssignmentFilter =
          doc.on_assignment_company !== null
            ? doc.on_assignment_company
            : false;
        return onAssignmentFilter;
      });
    }
    if (recruiters.length !== 0) {
      resultList = (resultList ?? talentList).filter((doc) => {
        const filter =
          doc.recruiters && doc.recruiters.length !== 0
            ? doc.recruiters.some((s) => recruiters.indexOf(s) >= 0)
            : false;
        return filter;
      });
    }

    // if (status != null && status != "") {
    //   resultList = (resultList ?? talentList).filter((doc) => {
    //     const statusFilter =
    //       doc.status_id && status
    //         ? doc.status_id === status
    //         : false;
    //     return statusFilter;
    //   });
    // }

    if (status.length != 0) {
      resultList = (resultList ?? talentList).filter((doc) => {
        const filter =
          doc.status && doc.status.length != 0
            ? status.includes(doc.status_id!)
            : false;
        return filter;
      });
    }

    if (sortedField != null) {
      sortedList = [...(resultList ?? talentList)].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] !== undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return (sortedList ?? resultList ?? talentList).filter((doc) => {
      const str = search.trim().toLowerCase();
      const firstName = `${doc.first_name ?? ""}`
        .trim()
        .toLowerCase()
        .includes(str);
      const fullName = `${doc.first_name ?? ""} ${doc.middle_name ?? ""} ${doc.last_name ?? ""
        }`
        .trim()
        .toLowerCase()
        .includes(str);
      const firstAndLastName = `${doc.first_name ?? ""} ${doc.last_name ?? ""}`
        .trim()
        .toLowerCase()
        .includes(str);
      const firstAndMiddleName = `${doc.first_name ?? ""} ${doc.middle_name ?? ""
        }`
        .trim()
        .toLowerCase()
        .includes(str);

      const nameFilter =
        doc.first_name && doc.middle_name && doc.last_name
          ? fullName
          : doc.first_name && doc.middle_name
            ? firstAndMiddleName
            : doc.first_name && doc.last_name
              ? firstAndLastName
              : firstName;
      const cityFilter = doc.city
        ? doc.city.trim().toLowerCase().includes(str)
        : false;
      const stateFilter = doc.state
        ? doc.state.trim().toLowerCase().includes(str)
        : false;
      const zipcodeFilter = doc.zip_code
        ? doc.zip_code.trim().toLowerCase().includes(str)
        : false;
      const ssnFilter = doc.last_4_digits_of_ssn
        ? doc.last_4_digits_of_ssn.trim().toLowerCase().includes(str)
        : false;
      const onAssignmentFilter = doc.on_assignment_company
        ? doc.on_assignment_company.trim().toLowerCase().includes(str)
        : false;
      const onJobTitleFilter = doc.title
        ? doc.title.trim().toLowerCase().includes(str)
        : false;
      const onStatusFilter = doc.status
        ? doc.status.trim().toLowerCase().includes(str)
        : false;
      const onAddressFilter = doc.street_address
        ? doc.street_address.trim().toLowerCase().includes(str)
        : false;
      return nameFilter || cityFilter || stateFilter || zipcodeFilter || ssnFilter || onAssignmentFilter || onJobTitleFilter || onStatusFilter || onAddressFilter;
    });
  }

  function handleSortFieldChange(value: string) {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }

  function handleUserSelect(value: ITalent) {
    props.history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${value.id}`,
      state: {
        id: value.id,
        name: `${value.first_name ?? ""} ${value.middle_name ?? ""} ${value.last_name ?? ""
          }`,
      },
    });
    return;
  }

  function handleOnAssignmentSelect(value: ITalent) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.on_assignment_company_id}`,
      state: {
        id: value.on_assignment_company_id,
        name: `${value.on_assignment_company ?? ""}`,
      },
    });
    return;
  }

  return (
    <div className="tlnt-ud-container">
      <div className="tlnt-ud-table-header">
        <TableHeaderOptions
          totalCount={getFilteredList().length}
          search={search}
          onSearchChange={(value) => setSearch(value)}
          roleUsers={recruitersList.map((doc) => ({
            label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
            value: doc.id,
          }))}
          onChangeRoleUser={setRecruiter}
          selectedRoleUsers={recruiters}
          selectedStatus={status}
          onStatusChange={setStatus}
          // companyStatusList={[{ label: "all", value: "" }, ...statusOptions]}
          companyStatusList={talentStatusList.map((doc) => ({
            label: doc.talent_status,
            value: doc.id,
          }))}
        />

        <div className="tlnt-ud-actions">
          <div className="upl-files">
            <CustomButton
              leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} />}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("upload_talents")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setImportTalents(true)}
            />
          </div>
          <div className="tlnt-ud-brdcast-btn">
            <CustomButton
              leftIcon={getTextEmAllIcon()}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("broadcast_to_talent")}
              enable={canSendText ? true : false}
              onClick={handleBroadCastTalent}
            />
            {showMsg && (
              <span className="select_talent_msg">{showErrorMsg}</span>
            )}
          </div>
          <div style={{ marginRight: '1rem' }}>
            <NavLink to={`${AppRoutes.apolloSearchImport}/talent`} >
              <CustomButton
                leftIcon={<div className="btn-icon circle-download-svg">
                  <CircleDownloadIcon width={"100%"} height={"100%"} style={{ fill: "#fff" }} />
                </div>}
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("get_talent")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => { }}
              />
            </NavLink>
          </div>
          <div className="tlnt-ud-add-user-btn">
            <CustomButton
              leftIcon={getPlusBtnIcon()}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("add_talent")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setAddTalent(true)}
            />
          </div>
        </div>
      </div>
      <div className="tlnt-ud-table">{getTalentTableList()}</div>
      {addTalent && (
        <Portal>
          <CreateTalentPopup
            visible={addTalent}
            title={t("add_talent")}
            successTitle={t("new_talent")}
            onClose={() => setAddTalent(false)}
            onSuccessClose={handleAddTalentSuccess}
            onGotoAgencyDashboard={handleGotoAgencyDashboard}
            onGotoCreated={handleOnGotoTalent}
            showSSN={profileState?.profile?.show_ssn}
          />
        </Portal>
      )}
      {showSSNPopup && (
        <Portal>
          <ViewSSNPopup
            visible={showSSNPopup.visible}
            title={t("social_security_number")}
            talentId={showSSNPopup.talentId}
            onClose={() => setShowSSNPopup(null)}
            onDissmiss={() => setShowSSNPopup(null)}
            onSuccessClose={handleOnSuccessSSNClose}
          />
        </Portal>
      )}
      {canSendText &&
        broadcastTalentError &&
        textEmAllState &&
        textEmAllState?.error !== null && (
          <Portal>
            <BroadcastToTalentErrorPopup
              error={textEmAllState?.error}
              onClose={() => {
                setBroadcastTalentError(false);
                dispatch(textEmAllSliceActions.clearState());
              }}
              visible={broadcastTalentError}
              title={t("something_went_wrong")}
              loading={textEmAllState?.loading === LoadingType.pending}
              onForceSend={handleBroadCastTalent}
            />
          </Portal>
        )}
      {canSendText &&
        broadcastTalent && (
          <Portal>
            <BroadcastToTalentPopup
              visible={broadcastTalent}
              title={""}
              successTitle={""}
              responseURL={textEmAllState?.response}
              loading={textEmAllState?.loading === LoadingType.pending}
              onClose={() => {
                setBroadcastTalent(false);
                dispatch(textEmAllSliceActions.clearState());
              }}
              onSuccessClose={() => { }}
            />
          </Portal>
        )}
      {importTalents && (
        <Portal>
          <UploadRecordPopup
            visible={importTalents}
            title={t("upload_talents")}
            baseUrl={UrlBuilder.importTalents}
            onClose={() => setImportTalents(false)}
            loading={importDataFileUrlState?.loading}
            error={importDataFileUrlState?.error}
            onClearError={handleClearError}
            handleSnackBar={handleSnackBar}
            importType={"Talent"}
          />
        </Portal>
      )}
    </div>
  );


  function getSSNInfo(value: string, id: string) {
    return (
      // <div className="ssn-holder">
      //     <div className="ssn-number">
      //         <span>{value}</span>
      //     </div>
      //     <button className="loc-open-icon">
      //         <LockOpenIcon width={"100%"} height={"100%"} style={{ color: '#00A4DC' }} />
      //     </button>
      // </div>
      <SSNHolder
        value={value}
        iconColor={THEME.tableRowTextColor}
        onClick={() => handleOnSSNView(id)}
        showFormat={false}
      />
    );
  }
  function handleOnSSNView(id: string) {
    dispatch(talentRequestSSNActions.initialiseRequestSSN(id));
    if (profileState.profile && profileState.profile.show_ssn) {
      setShowSSNPopup({ visible: true, talentId: id });
    } else {
      dispatch(postSnackbarMessage(t("ssn_access_is_disabled")));
    }
  }

  function handleOnSuccessSSNClose() {
    setShowSSNPopup(null);
    // dispatch(talentAvailableDatesActions.clearSingleTalentAvailableDatesManageState(params.id));
  }

  ///Add user action
  function handleAddTalentSuccess() {
    setAddTalent(false);
    getTalents();
    dispatch(manageTalentActions.clearManageTalentState());
  }

  function handleGotoAgencyDashboard() {
    handleAddTalentSuccess();
    props.history.push(AppRoutes.dashboardPage);
  }

  function handleOnGotoTalent(id: string, name: string) {
    handleAddTalentSuccess();
    props.history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${id}`,
      state: {
        id: id,
        name: name,
      },
    });
  }

  /// Talent table list
  function getTalentTableList() {
    if (talentListState.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (talentListState.error != null && talentList.length === 0) {
      return (
        <TableErrorHandler
          error={talentListState.error}
          onRefresh={getTalents}
        />
      );
    }
    if (talentList.length === 0) {
      return (
        <TableEmpty title={t("no_talent_found")} onClick={() => getTalents()} />
      );
    }
    if (getFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_talent_found")} />;
    }

    return (
      <SortableTable
        headerList={tableHeader}
        sortedField={sortedField}
        onSortChange={handleSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={sortDirection}
      >
        {getFilteredList().map((doc) => {
          return (
            <tr key={doc.id}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                <CustomCheckBox
                  name={""}
                  title={""}
                  checked={selectedItems.has(doc.id)}
                  onClick={() => handleMarkItem(doc)}
                />
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(1) }}>
                {getTableAccessIcon(() => handleUserSelect(doc))}
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(2) }}
                isButton={true}
                onClick={() => handleUserSelect(doc)}
                prefix={getTalentStatusIcon(doc)}
              >
                <span>{`${doc.first_name ?? ""} ${doc.middle_name ?? ""} ${doc.last_name ?? ""
                  }`}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(3) }}>
                <span>{doc.title}</span>
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(4) }}
                isButton={doc.on_assignment_company ? true : false}
                onClick={() => handleOnAssignmentSelect(doc)}
              >
                <span>{doc.on_assignment_company ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                {/* <span>{talentStatus(doc.status)}</span> */}
                <span>{doc.status ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(6) }}>
                <span>{doc.street_address}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(7) }}>
                <span>{doc.city ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(8) }}>
                <span>{doc.state ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(9) }}>
                <span>{doc.zip_code ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(10) }}>
                {doc.last_4_digits_of_ssn
                  ? getSSNInfo(doc.last_4_digits_of_ssn, doc.id)
                  : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(11) }}>
                <span>
                  {doc.last_engagement_date
                    ? getDaysAgoWithDateAMPM(t, doc.last_engagement_date)
                    : ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(12) }}>
                {getPrefferedContact(doc.preferred_contact_method)}
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 0.4;
    if (value === 1) return 1.5;
    if (value === 2) return 3;
    if (value === 3) return 2;
    if (value === 4) return 2;
    if (value === 5) return 1.5;
    if (value === 6) return 2.4;
    if (value === 7) return 1.5;
    if (value === 8) return 1;
    if (value === 9) return 1;
    if (value === 10) return 1;
    if (value === 11) return 3;
    if (value === 12) return 2;
    return 1;
  }

  // function getNameField(doc: ITalent) {
  //     return (
  //         <div className="tlnt-ud-table-name">
  //             <div className="status-icon">
  //                 <TalentActiveStatusIcon
  //                     width={"100%"}
  //                     height={"100%"}
  //                     style={{ color: (doc.on_assignment_company != null) ? THEME.buttonColor13 : THEME.statusDisabledColor }}
  //                 />
  //             </div>
  //             <div className="name-txt">
  //                 <span>{`${doc.first_name} ${doc.middle_name} ${doc.last_name}`}</span>
  //             </div>
  //         </div>
  //     )
  // }

  function getTalentStatusIcon(doc: ITalent) {
    return (
      <div className="status-icon">
        <TalentActiveStatusIcon
          width={"100%"}
          height={"100%"}
          style={{
            color:
              doc.on_assignment_company != null
                ? THEME.buttonColor13
                : THEME.statusDisabledColor,
          }}
        />
      </div>
    );
  }

  function talentStatus(status: string) {
    if (status === TALENT_STATUS.active)
      return <span style={{ color: THEME.buttonColor17 }}>{t("active")}</span>;
    else if (status === TALENT_STATUS.inactive)
      return <span style={{ color: "#b1b1b1" }}>{t("in_active")}</span>;
    else return <span>{""}</span>;
  }

  function getPrefferedContact(value: string) {
    return (
      <div className="pref-contact-icon">
        {getContactTypeIcon(value, "#00A4DC")}
      </div>
    );
  }
};

interface TableHeaderOptionsProps {
  totalCount: number;
  search: string;
  onSearchChange: (value: string) => void;
  /// [Role users]
  roleUsers: Array<{ label: string; value: string }>;
  selectedRoleUsers: string[];
  onChangeRoleUser: (value: string[]) => void;
  /// [Status]
  // selectedStatus: string | null;
  selectedStatus: string[];
  onStatusChange: (value: string[]) => void;
  companyStatusList: Array<{ label: string; value: string }>;
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
  const { t } = useTranslation();
  return (
    <div className="tlnt-ud-table-header-options">
      <div className="recruiter-selector">
        <CustomMultiFormSelect
          label={t("recruiter")}
          name={"recruiter"}
          list={props.roleUsers}
          onChange={props.onChangeRoleUser}
          required={false}
          placeholder={t("select")}
          value={props.selectedRoleUsers}
          customStyle={{ width: "-webkit-fill-available" }}
        />
      </div>
      <div className="company-status-selector">
        {/* <CustomFormSelect
          customStyle={{ width: "-webkit-fill-available" }}
          name={"company_status"}
          value={props.selectedStatus}
          required={false}
          label={t("status")}
          placeholder={t("all")}
          list={props.companyStatusList}
          onChange={props.onStatusChange}
        /> */}
         <CustomMultiFormSelect
              label={t("status")}
              name={"company_status"}
              list={props.companyStatusList}
              onChange={props.onStatusChange}
              required={false}
              placeholder={t("all")}
              value={props.selectedStatus}
              customStyle={{ width: "-webkit-fill-available" }}
            />
      </div>
      <div className="tlnt-ud-table-search-bar">
        <SearchBar
          value={props.search}
          onChange={props.onSearchChange}
          onSearch={() => { }}
        />
      </div>
      <div className="total-count">
        <span>
          {`${t("total_count")}: `}
          <span className="total-count-number">{props.totalCount}</span>
        </span>
      </div>
    </div>
  );
};

export default UniversalDirectoryPage;
