import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { LoadingType, THEME } from "../../../../enums";
import { IJobOrderDetail, IUpdateJobOrderDetail } from "../../../../interfaces";
import {
  getWorkCompCodes,
  getWorkCompConfigList,
  selectWorkCompCodeList,
  selectWorkCompCodeState,
  selectWorkCompConfigList,
  selectWorkCompConfigListState,
} from "../../../../redux/admin_center";
import {
  postSnackbarMessage,
  selectJobDetailsByIdState,
  selectManageJobUpdateState,
  updateJob,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { CustomButton, FormInput, SpinnerScreen } from "../../../../utils";
import "./job-details.scss";

import { FormControl } from "@material-ui/core";
import { DateInput } from "../../../../components/date_input/date_input";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import { manageJobActions } from "../../../../redux/job_order/universal_directory/manage_job/manage_job_reducer";
import { convertNumberToMoney, enforceUSFormat, formatToMoney } from "../../../../utils/form_validations";
import { getUSFormat } from "../../../../utils/helpers";
import TimePickerComponent from "../../../../components/time_picker/time_picker";
import { convertTimestampToStringTime, convertStringTimeToTimestamp } from "../../../../variables";
import { ArrowDownIcon } from "../../../../icons";
import { selectWorkCompConfigDropdown, selectWorkCompConfigDropdownState } from "../../../../redux/admin_center/work_comp_config_dropdown/work_comp_config_dropdown_selector";
import { getWorkCompConfigDropdown } from "../../../../redux/admin_center/work_comp_config_dropdown/work_comp_config_dropdown_thunk";

interface Props extends RouteComponentProps<any> { }
const PageLoading = (props) => {
  return (
    <div className="table-loading">
      <SpinnerScreen />
    </div>
  );
};

interface IJobDetails {
  job_id: string;
  customStyle?: React.CSSProperties;
}



const days = [
  { id: "MONDAY", label: "Monday", startTime: "MONDAY_START_TIME", endTime: "MONDAY_END_TIME" },
  { id: "TUESDAY", label: "Tuesday", startTime: "TUESDAY_START_TIME", endTime: "TUESDAY_END_TIME" },
  { id: "WEDNESDAY", label: "Wednesday", startTime: "WEDNESDAY_START_TIME", endTime: "WEDNESDAY_END_TIME" },
  { id: "THURSDAY", label: "Thursday", startTime: "THURSDAY_START_TIME", endTime: "THURSDAY_END_TIME" },
  { id: "FRIDAY", label: "Friday", startTime: "FRIDAY_START_TIME", endTime: "FRIDAY_END_TIME" },
  { id: "SATURDAY", label: "Saturday", startTime: "SATURDAY_START_TIME", endTime: "SATURDAY_END_TIME" },
  { id: "SUNDAY", label: "Sunday", startTime: "SUNDAY_START_TIME", endTime: "SUNDAY_END_TIME" },
];

const getInitialState = (value?: IJobOrderDetail | null) => {
  if (value) {
    return {
      ...value,
      regular_bill_rate: convertNumberToMoney(value.regular_bill_rate, 'string') as string,
      regular_pay_rate: convertNumberToMoney(value.regular_pay_rate, 'string') as string,
      overtime_bill_rate: convertNumberToMoney(value.overtime_bill_rate, 'string') as string,
      overtime_pay_rate: convertNumberToMoney(value.overtime_pay_rate, 'string') as string,
      double_time_bill_rate: convertNumberToMoney(value.double_time_bill_rate, 'string') as string,
      double_time_pay_rate: convertNumberToMoney(value.double_time_pay_rate, 'string') as string,
      work_schedule: {
        FRIDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.FRIDAY_END_TIME!),
        FRIDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.FRIDAY_START_TIME!),
        MONDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.MONDAY_END_TIME!),
        MONDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.MONDAY_START_TIME!),
        SATURDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.SATURDAY_END_TIME!),
        SATURDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.SATURDAY_START_TIME!),
        SUNDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.SUNDAY_END_TIME!),
        SUNDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.SUNDAY_START_TIME!),
        THURSDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.THURSDAY_END_TIME!),
        THURSDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.THURSDAY_START_TIME!),
        TUESDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.TUESDAY_END_TIME!),
        TUESDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.TUESDAY_START_TIME!),
        WEDNESDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.WEDNESDAY_END_TIME!),
        WEDNESDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.WEDNESDAY_START_TIME!),
        FRIDAY: value.work_schedule?.FRIDAY!,
        MONDAY: value.work_schedule?.MONDAY!,
        SATURDAY: value.work_schedule?.SATURDAY!,
        SUNDAY: value.work_schedule?.SUNDAY!,
        THURSDAY: value.work_schedule?.THURSDAY!,
        TUESDAY: value.work_schedule?.TUESDAY!,
        WEDNESDAY: value.work_schedule?.WEDNESDAY!,

      }
    }
  }

  return new IUpdateJobOrderDetail();
}
const JobDetailsPage = ({ job_id, customStyle }: IJobDetails) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const jobState = useAppSelector((state) =>
    selectJobDetailsByIdState(job_id)(state)
  );
  const WorkCompConfigDropdownState = useAppSelector((state) => selectWorkCompConfigDropdownState(state))
  const WorkCompConfigDropdown = useAppSelector((state) => selectWorkCompConfigDropdown(state))
  const jobUpdateState = useAppSelector((state) =>
    selectManageJobUpdateState(state)
  );
  const [startDateValid, setStartDateValid] = useState(true);
  const [endDateValid, setEndDateValid] = useState(true);

  const initialState: IJobOrderDetail = {
    id: job_id,
    company_id: "",
    department_id: null,
    job_title: "",
    is_deleted: false,
    regular_pay_rate: 0,
    regular_bill_rate: 0,
    overtime_pay_rate: null,
    overtime_bill_rate: null,
    double_time_pay_rate: null,
    double_time_bill_rate: null,
    status: "OPEN",
    placement_type: "",
    job_order_source: "",
    logo: "",
    wc_code: null,
    branch_id: null,
    agency_id: null,
    rating: 0,
    requested: 0,
    placed: 0,
    over_under_placed: 0,
    start_date: null,
    end_date: null,
    order_date: null,
    account_manager: null,
    recruiter: null,
    attach_timecard: false,
    bill_to_attn: null,
    work_comp_code: null,
    contact_id_for_placement: null,
    ordered_by_contact_id: null,
    report_to_contact_id: null,
    company_name: "",
    department_name: "",
    company_address: "",
    contact_name_for_placement: "",
    ordered_by_contact_name: "",
    report_to_contact_name: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    success_profile_question_answers: [],
    placement_type_id: null,
    job_order_source_id: null,
    placement_type_value: "",
    job_order_source_value: "",
    triple_time_pay_rate: null,
    triple_time_bill_rate: null,
    service_calls: [],
    account_managers: [],
    recruiters: [],
    professional_skills: [],
    certifications: [],
    education: [],
    credentials: [],
    safety_equipment: [],
    open_under_placed: 0,
    shift_name: "",
    estimated_shift_hours: 0,
    start_time: 0,
    end_time: 0,
    job_status: "",
    break_in_minutes: 0,
    work_schedule: {
      FRIDAY: false,
      FRIDAY_END_TIME: 0,
      FRIDAY_START_TIME: 0,
      MONDAY: false,
      MONDAY_END_TIME: 0,
      MONDAY_START_TIME: 0,
      SATURDAY: false,
      SATURDAY_END_TIME: 0,
      SATURDAY_START_TIME: 0,
      SUNDAY: false,
      SUNDAY_END_TIME: 0,
      SUNDAY_START_TIME: 0,
      THURSDAY: false,
      THURSDAY_END_TIME: 0,
      THURSDAY_START_TIME: 0,
      TUESDAY: false,
      TUESDAY_END_TIME: 0,
      TUESDAY_START_TIME: 0,
      WEDNESDAY: false,
      WEDNESDAY_END_TIME: 0,
      WEDNESDAY_START_TIME: 0,
    },
  };
  const [jobOrderData, setJobOrderData] = useState<IUpdateJobOrderDetail>(getInitialState(initialState));

  useEffect(() => {
    if (job_id !== "") {
      // dispatch(getWorkCompCodes());
      dispatch(getWorkCompConfigDropdown());
      // let jobData: any = [];
      // if (jobState.data && jobState.data.job_title) {
      //   jobData = {
      //     ...jobState.data,
      //     start_date: jobState.data.start_date
      //       ? jobState.data.start_date
      //       : null,
      //     end_date: jobState.data.end_date ? jobState.data.end_date : null,
      //     regular_pay_rate: jobState.data.regular_pay_rate
      //       ? getUSFormat(jobState.data.regular_pay_rate, false)
      //       : "0.00",
      //     regular_bill_rate: jobState.data.regular_bill_rate
      //       ? getUSFormat(jobState.data.regular_bill_rate, false)
      //       : "0.00",
      //     overtime_pay_rate: jobState.data.overtime_pay_rate
      //       ? getUSFormat(jobState.data.overtime_pay_rate, false)
      //       : "0.00",
      //     overtime_bill_rate: jobState.data.overtime_bill_rate
      //       ? getUSFormat(jobState.data.overtime_bill_rate, false)
      //       : "0.00",
      //     double_time_pay_rate: jobState.data.double_time_pay_rate
      //       ? getUSFormat(jobState.data.double_time_pay_rate, false)
      //       : "0.00",
      //     double_time_bill_rate: jobState.data.double_time_bill_rate
      //       ? getUSFormat(jobState.data.double_time_bill_rate, false)
      //       : "0.00",
      //     triple_time_pay_rate: jobState.data.triple_time_pay_rate
      //       ? getUSFormat(jobState.data.triple_time_pay_rate, false)
      //       : "0.00",
      //     triple_time_bill_rate: jobState.data.triple_time_bill_rate
      //       ? getUSFormat(jobState.data.triple_time_bill_rate, false)
      //       : "0.00",
      //   };
      // }
      // setJobOrderData(jobData);
    }
  }, []);

  useEffect(() => {
    if (jobUpdateState.loading == LoadingType.succeeded) {
      dispatch(
        postSnackbarMessage("Job Order Details Saved Successfully" ?? null)
      );
      dispatch(manageJobActions.clearManageJobState([]));
    }
    return () => { };
  }, [jobUpdateState.loading]);

  useEffect(() => {
    if (jobState.loading === LoadingType.succeeded) {
      updateForm(jobState?.data!)
      // getInitialState(jobState?.data!)
      // let jobData: any = {};
      // if (jobState.data && jobState.data.job_title) {
      //   jobData = {
      //     ...jobState.data,
      //     start_date: jobState.data.start_date
      //       ? jobState.data.start_date
      //       : null,
      //     end_date: jobState.data.end_date ? jobState.data.end_date : null,
      //     regular_pay_rate: jobState.data.regular_pay_rate
      //       ? getUSFormat(jobState.data.regular_pay_rate, false)
      //       : "0.00",
      //     regular_bill_rate: jobState.data.regular_bill_rate
      //       ? getUSFormat(jobState.data.regular_bill_rate, false)
      //       : "0.00",
      //     overtime_pay_rate: jobState.data.overtime_pay_rate
      //       ? getUSFormat(jobState.data.overtime_pay_rate, false)
      //       : "0.00",
      //     overtime_bill_rate: jobState.data.overtime_bill_rate
      //       ? getUSFormat(jobState.data.overtime_bill_rate, false)
      //       : "0.00",
      //     double_time_pay_rate: jobState.data.double_time_pay_rate
      //       ? getUSFormat(jobState.data.double_time_pay_rate, false)
      //       : "0.00",
      //     double_time_bill_rate: jobState.data.double_time_bill_rate
      //       ? getUSFormat(jobState.data.double_time_bill_rate, false)
      //       : "0.00",
      //     triple_time_pay_rate: jobState.data.triple_time_pay_rate
      //       ? getUSFormat(jobState.data.triple_time_pay_rate, false)
      //       : "0.00",
      //     triple_time_bill_rate: jobState.data.triple_time_bill_rate
      //       ? getUSFormat(jobState.data.triple_time_bill_rate, false)
      //       : "0.00",
      //   };
      // }
      // setJobOrderData(jobData);
    }
  }, [jobState.loading]);

  const handleSaveClick = () => {
    const payload: IJobOrderDetail = {
      ...jobOrderData,
      start_date: jobOrderData.start_date ? jobOrderData.start_date : null,
      end_date: jobOrderData.end_date ? jobOrderData.end_date : null,
      requested: jobOrderData.requested ? Number(jobOrderData.requested) : 0,
      placed: jobOrderData.placed ? Number(jobOrderData.placed) : 0,
      over_under_placed:
        jobOrderData.over_under_placed
          // && jobOrderData.over_under_placed !== "."
          ? Number(jobOrderData.over_under_placed)
          : 0,
      // regular_pay_rate:
      //   jobOrderData.regular_pay_rate && jobOrderData.regular_pay_rate !== "."
      //     ? Number(jobOrderData.regular_pay_rate)
      //     : 0,
      // regular_bill_rate:
      //   jobOrderData.regular_bill_rate && jobOrderData.regular_bill_rate !== "."
      //     ? Number(jobOrderData.regular_bill_rate)
      //     : 0,
      // overtime_pay_rate:
      //   jobOrderData.overtime_pay_rate && jobOrderData.overtime_pay_rate !== "."
      //     ? Number(jobOrderData.overtime_pay_rate)
      //     : 0,
      // overtime_bill_rate:
      //   jobOrderData.overtime_bill_rate &&
      //     jobOrderData.overtime_bill_rate !== "."
      //     ? Number(jobOrderData.overtime_bill_rate)
      //     : 0,
      // double_time_pay_rate:
      //   jobOrderData.double_time_pay_rate &&
      //     jobOrderData.double_time_pay_rate !== "."
      //     ? Number(jobOrderData.double_time_pay_rate)
      //     : 0,
      // double_time_bill_rate:
      //   jobOrderData.double_time_bill_rate &&
      //     jobOrderData.double_time_bill_rate !== "."
      //     ? Number(jobOrderData.double_time_bill_rate)
      //     : 0,
      triple_time_pay_rate:
        jobOrderData.triple_time_pay_rate
          // && jobOrderData.triple_time_pay_rate !== "."
          ? Number(jobOrderData.triple_time_pay_rate)
          : 0,
      triple_time_bill_rate:
        jobOrderData.triple_time_bill_rate
          //  && jobOrderData.triple_time_bill_rate !== "."
          ? Number(jobOrderData.triple_time_bill_rate)
          : 0,
      regular_bill_rate: convertNumberToMoney(jobOrderData.regular_bill_rate, 'number') as number,
      regular_pay_rate: convertNumberToMoney(jobOrderData.regular_pay_rate, 'number') as number,
      overtime_bill_rate: convertNumberToMoney(jobOrderData.overtime_bill_rate, 'number') as number,
      overtime_pay_rate: convertNumberToMoney(jobOrderData.overtime_pay_rate, 'number') as number,
      double_time_bill_rate: convertNumberToMoney(jobOrderData.double_time_bill_rate, 'number') as number,
      double_time_pay_rate: convertNumberToMoney(jobOrderData.double_time_pay_rate, 'number') as number,
      work_schedule: {
        FRIDAY_END_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.FRIDAY_END_TIME!),
        FRIDAY_START_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.FRIDAY_START_TIME!),
        MONDAY_END_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.MONDAY_END_TIME!),
        MONDAY_START_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.MONDAY_START_TIME!),
        SATURDAY_END_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.SATURDAY_END_TIME!),
        SATURDAY_START_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.SATURDAY_START_TIME!),
        SUNDAY_END_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.SUNDAY_END_TIME!),
        SUNDAY_START_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.SUNDAY_START_TIME!),
        THURSDAY_END_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.THURSDAY_END_TIME!),
        THURSDAY_START_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.THURSDAY_START_TIME!),
        TUESDAY_END_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.TUESDAY_END_TIME!),
        TUESDAY_START_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.TUESDAY_START_TIME!),
        WEDNESDAY_END_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.WEDNESDAY_END_TIME!),
        WEDNESDAY_START_TIME: convertStringTimeToTimestamp(jobOrderData?.work_schedule.WEDNESDAY_START_TIME!),
        FRIDAY: jobOrderData.work_schedule?.FRIDAY!,
        MONDAY: jobOrderData.work_schedule?.MONDAY!,
        SATURDAY: jobOrderData.work_schedule?.SATURDAY!,
        SUNDAY: jobOrderData.work_schedule?.SUNDAY!,
        THURSDAY: jobOrderData.work_schedule?.THURSDAY!,
        TUESDAY: jobOrderData.work_schedule?.TUESDAY!,
        WEDNESDAY: jobOrderData.work_schedule?.WEDNESDAY!,

      }
    };
    dispatch(updateJob(payload));
    // let jobData: any = [];
    // jobData = {
    //   ...jobOrderData,
    //   regular_pay_rate:
    //     jobState.data && jobState.data.regular_pay_rate
    //       ? getUSFormat(jobState.data.regular_pay_rate, false)
    //       : "0.00",
    //   regular_bill_rate:
    //     jobState.data && jobState.data.regular_bill_rate
    //       ? getUSFormat(jobState.data.regular_bill_rate, false)
    //       : "0.00",
    //   overtime_pay_rate:
    //     jobState.data && jobState.data.overtime_pay_rate
    //       ? getUSFormat(jobState.data.overtime_pay_rate, false)
    //       : "0.00",
    //   overtime_bill_rate:
    //     jobState.data && jobState.data.overtime_bill_rate
    //       ? getUSFormat(jobState.data.overtime_bill_rate, false)
    //       : "0.00",
    //   double_time_pay_rate:
    //     jobState.data && jobState.data.double_time_pay_rate
    //       ? getUSFormat(jobState.data.double_time_pay_rate, false)
    //       : "0.00",
    //   double_time_bill_rate:
    //     jobState.data && jobState.data.double_time_bill_rate
    //       ? getUSFormat(jobState.data.double_time_bill_rate, false)
    //       : "0.00",
    //   triple_time_pay_rate:
    //     jobState.data && jobState.data.triple_time_pay_rate
    //       ? getUSFormat(jobState.data.triple_time_pay_rate, false)
    //       : "0.00",
    //   triple_time_bill_rate:
    //     jobState.data && jobState.data.triple_time_bill_rate
    //       ? getUSFormat(jobState.data.triple_time_bill_rate, false)
    //       : "0.00",
    // };
    // setJobOrderData(jobData);
  };

  function isLoading() {
    return WorkCompConfigDropdownState.loading === LoadingType.pending;
  }

  const updateWorkSchedule = (key: string, value: boolean) => {
    let newWorkSchedule = { ...jobOrderData.work_schedule };
    newWorkSchedule[key] = value;
    setJobOrderData({ ...jobOrderData, work_schedule: newWorkSchedule });
  };
  const updateWorkScheduleTime = (key: string, value: number) => {
    let newWorkSchedule = { ...jobOrderData.work_schedule };
    newWorkSchedule[key] = value;
    setJobOrderData({ ...jobOrderData, work_schedule: newWorkSchedule });
  };

  const handlePayRateChange = (id, value) => {
    const payRate = value.replace(/,/g, ''); // Remove commas from input value
    if (payRate.trim() === '') {
      setJobOrderData({
        ...jobOrderData,
        regular_pay_rate: '',
        double_time_pay_rate: '0.00', // Set bill rate to '0.00' if pay rate is empty
        overtime_pay_rate: '0.00' // Set bill rate to '0.00' if pay rate is empty
      });
    } else if (!isNaN(payRate)) {
      const doubleTimePayRate = parseFloat(payRate) * 2;
      const overTimePayRate = parseFloat(payRate) * 1.5;
      setJobOrderData({
        ...jobOrderData,
        regular_pay_rate: value,
        double_time_pay_rate: isNaN(doubleTimePayRate) ? '' : convertNumberToMoney(doubleTimePayRate, "string") as string,
        overtime_pay_rate: isNaN(overTimePayRate) ? '' : convertNumberToMoney(overTimePayRate, "string") as string
      });
    }
  };

  const handleBillRateChange = (id, value) => {
    const regularbillRate = value.replace(/,/g, ''); // Remove commas from input value
    if (regularbillRate.trim() === '') {
      setJobOrderData({
        ...jobOrderData,
        regular_bill_rate: '',
        double_time_bill_rate: '0.00', // Set bill rate to '0.00' if pay rate is empty
        overtime_bill_rate: '0.00' // Set bill rate to '0.00' if pay rate is empty
      });
    } else if (!isNaN(regularbillRate)) {
      const doubleTimeBillRate = parseFloat(regularbillRate) * 2;
      const overTimeTimeBillRate = parseFloat(regularbillRate) * 1.5;
      setJobOrderData({
        ...jobOrderData,
        regular_bill_rate: value,
        double_time_bill_rate: isNaN(doubleTimeBillRate) ? '' : convertNumberToMoney(doubleTimeBillRate, "string") as string,
        overtime_bill_rate: isNaN(overTimeTimeBillRate) ? '' : convertNumberToMoney(overTimeTimeBillRate, "string") as string
      });
    }
  };


  function updateForm(value: IJobOrderDetail) {
    setJobOrderData({
      ...value,
      regular_bill_rate: convertNumberToMoney(value.regular_bill_rate, 'string') as string,
      regular_pay_rate: convertNumberToMoney(value.regular_pay_rate, 'string') as string,
      overtime_bill_rate: convertNumberToMoney(value.overtime_bill_rate, 'string') as string,
      overtime_pay_rate: jobState.data?.regular_pay_rate !== 0 ? convertNumberToMoney(value?.regular_pay_rate! * 1.5, 'string') as string : convertNumberToMoney(value.overtime_pay_rate, 'string') as string,
      double_time_bill_rate: convertNumberToMoney(value.double_time_bill_rate, 'string') as string,
      double_time_pay_rate: jobState.data?.regular_pay_rate !== 0 ? convertNumberToMoney(value?.regular_pay_rate! * 2, 'string') as string : convertNumberToMoney(value.double_time_pay_rate, 'string') as string,
      work_schedule: {
        FRIDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.FRIDAY_END_TIME!),
        FRIDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.FRIDAY_START_TIME!),
        MONDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.MONDAY_END_TIME!),
        MONDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.MONDAY_START_TIME!),
        SATURDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.SATURDAY_END_TIME!),
        SATURDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.SATURDAY_START_TIME!),
        SUNDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.SUNDAY_END_TIME!),
        SUNDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.SUNDAY_START_TIME!),
        THURSDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.THURSDAY_END_TIME!),
        THURSDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.THURSDAY_START_TIME!),
        TUESDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.TUESDAY_END_TIME!),
        TUESDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.TUESDAY_START_TIME!),
        WEDNESDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.WEDNESDAY_END_TIME!),
        WEDNESDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.WEDNESDAY_START_TIME!),
        FRIDAY: value.work_schedule?.FRIDAY!,
        MONDAY: value.work_schedule?.MONDAY!,
        SATURDAY: value.work_schedule?.SATURDAY!,
        SUNDAY: value.work_schedule?.SUNDAY!,
        THURSDAY: value.work_schedule?.THURSDAY!,
        TUESDAY: value.work_schedule?.TUESDAY!,
        WEDNESDAY: value.work_schedule?.WEDNESDAY!,

      }
    });
  }

  const dateCss = `.MuiOutlinedInput-adornedEnd {
         padding-right: 0px; 
    }
    .MuiInputAdornment-positionEnd {
         margin-left: 0px; 
    }`;
  return (
    <>
      {isLoading() ? (
        <PageLoading />
      ) : (
        <div className="ac-sales-profile-container job-details-container" style={customStyle}>
          <div className="ac-sales-profile-content job-details-content">
            <div className="ac-sc-job-fields col1 ">
              <p className="ac-service-text">JOB ORDER DETAILS</p>
              <div className="job-ord-sp-field-row">
                <div className="ac-sc-fields-job-row1">
                  <DateInput
                    id={"start_date"}
                    onChange={(fieldId, value) => {
                      setJobOrderData((prevState: any) => ({
                        ...prevState,
                        start_date: value,
                      }));
                    }}
                    placeholder={"MM/DD/YYYY"}
                    value={jobOrderData.start_date}
                    label={t("start_date")}
                    setHoursToZero={true}
                  />
                </div>
                <div className="ac-sc-fields-job-row1">
                  <DateInput
                    id={"end_date"}
                    onChange={(fieldId, value) => {
                      setJobOrderData((prevState: any) => ({
                        ...prevState,
                        end_date: value,
                      }));
                    }}
                    placeholder={"MM/DD/YYYY"}
                    value={jobOrderData.end_date}
                    label={t("projected_end_date")}
                    setHoursToZero={true}
                  />
                </div>
                <div className="ac-sc-fields-job-row1">
                  <DateInput
                    id={"order_date"}
                    onChange={(fieldId, value) => {
                      setJobOrderData((prevState: any) => ({
                        ...prevState,
                        order_date: value,
                      }));
                    }}
                    placeholder={"MM/DD/YYYY"}
                    value={jobOrderData.order_date}
                    label={t("order_date")}
                    setHoursToZero={true}
                  />
                </div>
              </div>
              <div className="job-ord-sp-field-row">
                <div className="ac-sc-fields-job-row2">
                  <FormControl
                    size="small"
                    style={{ marginTop: "-6px" }}
                    className={"input-form-control"}
                  >
                    <FormInput
                      id={"requested"}
                      onChange={(fieldId, value) => {
                        setJobOrderData({
                          ...jobOrderData,
                          requested: value,
                        });
                      }}
                      value={jobOrderData.requested}
                      required={false}
                      placeholder={t("number_needed")}
                      type={"number"}
                      label={t("number_requested")}
                    />
                  </FormControl>
                </div>
                <div className="ac-sc-fields-job-row2">
                  <FormControl
                    size="small"
                    style={{ marginTop: "-6px" }}
                    className={"input-form-control"}
                  >
                    <FormInput
                      id={"placed"}
                      onChange={(fieldId, value) => {
                        setJobOrderData({
                          ...jobOrderData,
                          placed: value,
                        });
                      }}
                      value={jobOrderData.placed}
                      required={false}
                      placeholder={t("number_placed")}
                      type={"number"}
                      label={t("number_placed")}
                      disabled={true}
                      backgroundColor="#E6F1F9"
                      wrapperStyles={{ opacity: 1 }}
                      customInputStyle={{
                        color: THEME.statusActiveColor,
                        fontWeight: 900,
                      }}
                    />
                  </FormControl>
                </div>
                <div className="ac-sc-fields-job-row2">
                  <FormControl
                    size="small"
                    style={{ marginTop: "-6px" }}
                    className={"input-form-control"}
                  >
                    <FormInput
                      id={"over_under_placed"}
                      onChange={(fieldId, value) => {
                        setJobOrderData({
                          ...jobOrderData,
                          over_under_placed: value,
                        });
                      }}
                      value={jobOrderData.over_under_placed}
                      required={false}
                      placeholder={t("over_under")}
                      type={"number"}
                      label={t("number_still_needed")}
                      disabled={true}
                      wrapperStyles={{ opacity: 1 }}
                      customInputStyle={{ color: THEME.red, fontWeight: 900 }}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="job-ord-sp-field-row">
                <div className="ac-sc-fields-job-row3">
                  <FormControl
                    size="small"
                    className={"input-form-control ac-sc-fields-job"}
                  >
                    <CustomFormSelect
                      label={t("work_comp_code")}
                      customStyle={{ width: "-webkit-fill-available" }}
                      name={"work_comp_code"}
                      value={jobOrderData.work_comp_code}
                      required={false}
                      placeholder={"Select"}
                      list={WorkCompConfigDropdown?.map((doc) => ({
                        label: doc.work_comp_code,
                        value: doc.id,
                    }))}
                      onChange={(value) => {
                        setJobOrderData({
                          ...jobOrderData,
                          work_comp_code: value,
                        });
                      }}
                    />
                  </FormControl>
                </div>
                <div className="ac-sc-fields-job-row3">
                  <FormControl
                    size="small"
                    className={"input-form-control "}
                  >
                    <FormInput
                      id={"bill_to_attn"}
                      onChange={(fieldId, value) => {
                        setJobOrderData({
                          ...jobOrderData,
                          bill_to_attn: value,
                        });
                      }}
                      value={jobOrderData.bill_to_attn}
                      required={false}
                      placeholder={""}
                      label={t("bill_to_attn")}
                      type={"text"}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="job-ord-sp-field-row">
                <div className="ac-sc-fields-payroll-toggle">
                  <ToggleSwitch
                    label={t("attached_time_card")}
                    checked={jobOrderData.attach_timecard}
                    onChange={(value) => {
                      setJobOrderData({
                        ...jobOrderData,
                        attach_timecard: value,
                      });
                    }}
                  />
                </div>
              </div>

            </div>
            <div className="job-order-column col2">
              <div className="ac-sc-payroll-fields">
                <p className="ac-service-text">PAYROLL & BILLING</p>
                <SectionHolder title={t("regular")}>
                  <div className="job-ord-sp-field-row">
                    <div className="ac-sc-fields-payroll form-input-control">
                      <FormControl
                        size="small"
                        className={"input-form-control form-input-control"}
                      >
                        <FormInput
                          id={"regular_pay_rate"}
                          onChange={(id, value) => handlePayRateChange(id, value)}
                          value={convertNumberToMoney(jobOrderData.regular_pay_rate, "string") as string}
                          required={false}
                          placeholder={t("pay_rate")}
                          type={"text"}
                          step={"0.01"}
                          min={"0.00"}
                          label={t("pay_rate")}
                          prefix={<span>{"$"}</span>}
                          customInputStyle={{ minWidth: "2.8958333333vw" }}
                          onBlur={formatToMoney}
                          onKeyDown={formatToMoney}
                          onKeyUp={formatToMoney}
                        />
                      </FormControl>
                    </div>
                    <div className="ac-sc-fields-payroll form-input-control">
                      <FormControl
                        size="small"
                        className={"input-form-control"}
                      >
                        <FormInput
                          id={"regular_bill_rate"}
                          onChange={(id, value) => handleBillRateChange(id, value)}
                          value={convertNumberToMoney(jobOrderData.regular_bill_rate, "string") as string}
                          required={false}
                          placeholder={t("bill_rate")}
                          type={"text"}
                          step={"0.01"}
                          min={"0.00"}
                          label={t("bill_rate")}
                          prefix={<span>{"$"}</span>}
                          customInputStyle={{ minWidth: "2.8958333333vw" }}
                          onBlur={formatToMoney}
                          onKeyDown={formatToMoney}
                          onKeyUp={formatToMoney}
                        />
                      </FormControl>
                    </div>
                  </div>
                </SectionHolder>
                <SectionHolder title={t("overtime")}>
                  <div className="job-ord-sp-field-row">
                    <div className="ac-sc-fields-payroll form-input-control">
                      <FormControl
                        size="small"
                        className={"input-form-control"}
                      >
                        <FormInput
                          id={"overtime_pay_rate"}
                          onChange={(fieldId, value) => {
                            setJobOrderData({
                              ...jobOrderData,
                              overtime_pay_rate: enforceUSFormat(value),
                            });
                          }}
                          value={convertNumberToMoney(jobOrderData.overtime_pay_rate, "string") as string}
                          required={false}
                          placeholder={t("pay_rate")}
                          type={"text"}
                          step={"0.01"}
                          min={"0.00"}
                          label={t("pay_rate")}
                          prefix={<span>{"$"}</span>}
                          customInputStyle={{ minWidth: "2.8958333333vw" }}
                          onBlur={formatToMoney}
                          onKeyDown={formatToMoney}
                          onKeyUp={formatToMoney}
                          disabled
                        />
                      </FormControl>
                    </div>
                    <div className="ac-sc-fields-payroll form-input-control">
                      <FormControl
                        size="small"
                        className={"input-form-control"}
                      >
                        <FormInput
                          id={"overtime_bill_rate"}
                          onChange={(fieldId, value) => {
                            setJobOrderData({
                              ...jobOrderData,
                              overtime_bill_rate: enforceUSFormat(value),
                            });
                          }}
                          value={convertNumberToMoney(jobOrderData.overtime_bill_rate, "string") as string}
                          required={false}
                          placeholder={t("bill_rate")}
                          type={"text"}
                          step={"0.01"}
                          min={"0.00"}
                          label={t("bill_rate")}
                          prefix={<span>{"$"}</span>}
                          customInputStyle={{ minWidth: "2.8958333333vw" }}
                          onBlur={formatToMoney}
                          onKeyDown={formatToMoney}
                          onKeyUp={formatToMoney}
                        />
                      </FormControl>
                    </div>
                  </div>
                </SectionHolder>

                {/* <p className='ac-Field-text-header' style={{ fontSize: 16 }}>{t('double_time')}</p> */}
                <SectionHolder title={t("double_time")}>
                  <div
                    className="job-ord-sp-field-row"
                    style={{ alignItems: "center" }}
                  >
                    <div className="ac-sc-fields-payroll form-input-control">
                      <FormControl
                        size="small"
                        className={"input-form-control"}
                      >
                        <FormInput
                          id={"double_time_pay_rate"}
                          onChange={(fieldId, value) => {
                            setJobOrderData({
                              ...jobOrderData,
                              double_time_pay_rate: enforceUSFormat(value),
                            });
                          }}
                          value={convertNumberToMoney(jobOrderData.double_time_pay_rate, "string") as string}
                          required={false}
                          placeholder={t("pay_rate")}
                          type={"text"}
                          step={"0.01"}
                          min={"0.00"}
                          label={t("pay_rate")}
                          prefix={<span>{"$"}</span>}
                          customInputStyle={{ minWidth: "2.8958333333vw" }}
                          onBlur={formatToMoney}
                          onKeyDown={formatToMoney}
                          onKeyUp={formatToMoney}
                          disabled
                        />
                      </FormControl>
                    </div>
                    <div className="ac-sc-fields-payroll form-input-control">
                      <FormControl
                        size="small"
                        className={"input-form-control"}
                      >
                        <FormInput
                          id={"double_time_bill_rate"}
                          onChange={(fieldId, value) => {
                            setJobOrderData({
                              ...jobOrderData,
                              double_time_bill_rate: enforceUSFormat(value),
                            });
                          }}
                          value={convertNumberToMoney(jobOrderData.double_time_bill_rate, "string") as string}
                          required={false}
                          placeholder={t("bill_rate")}
                          type={"text"}
                          step={"0.01"}
                          min={"0.00"}
                          label={t("bill_rate")}
                          prefix={<span>{"$"}</span>}
                          customInputStyle={{ minWidth: "2.8958333333vw" }}
                          onBlur={formatToMoney}
                          onKeyDown={formatToMoney}
                          onKeyUp={formatToMoney}
                        />
                      </FormControl>
                    </div>
                  </div>
                </SectionHolder>
              </div>
            </div>
            <div className="job-order-column col3">
              <table>
                <div className={"assignment-summary flex-row"}>
                  <div className="schdule-sections-holder">
                    <div className="work-schedule-table">
                      <div className="section-header">{"WORK SCHEDULE"}</div>
                      {days.map((day, index) => (
                        <tr key={index}>
                          <td >
                            <ToggleSwitch
                              label={t(day.label)}
                              checked={
                                jobOrderData?.work_schedule
                                  ? jobOrderData?.work_schedule[day.id]
                                  : false
                              }
                              onChange={(value) => updateWorkSchedule(day.id, value)}
                            />
                          </td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <TimePickerComponent
                                id={jobOrderData.work_schedule[day.startTime]}
                                onChange={(id, value) => updateWorkScheduleTime(day.startTime, value)}
                                placeholder={''}
                                value={jobOrderData?.work_schedule[day.startTime]}
                              />
                              <ArrowDownIcon
                                width={"0.7vw"}
                                height={"0.7vw"}
                                style={{ color: THEME.defaultHighLightColor }}
                              />

                              <div className="label">{t("to")}</div>
                              <TimePickerComponent
                                id={jobOrderData.work_schedule[day.endTime]}
                                onChange={(id, value) => updateWorkScheduleTime(day.endTime, value)}
                                placeholder={''}
                                value={jobOrderData?.work_schedule[day.endTime]}
                              />
                              <ArrowDownIcon
                                width={"0.7vw"}
                                height={"0.7vw"}
                                style={{ color: THEME.defaultHighLightColor }} />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </div>
                  </div>
                </div>
              </table>
            </div>

          </div >
          <div className="add-role-action-holder">
            <div className="add-role-action">
              <div className="btn-cancel" style={{ marginRight: "1em" }}>
                <CustomButton
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("cancel")}
                  enable={true}
                  backgroundColor={THEME.toggleDisableColor}
                  onClick={() => {
                    updateForm(jobState?.data!)
                  }}
                />
              </div>
              <div className="btn-yes">
                <CustomButton
                  loading={jobUpdateState.loading === LoadingType.pending}
                  textStyle={{ textTransform: "capitalize" }}
                  name={"Save"}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={handleSaveClick}
                  enable={true}
                />
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  );
};

interface SectionHolderProps {
  title: string;
  children: React.ReactNode;
}

const SectionHolder: React.FunctionComponent<SectionHolderProps> = (props) => {
  return (
    <div className="jod-section-holder">
      <div className="jod-section-title">
        <span>{props.title}</span>
      </div>
      <div className="jod-section-content">{props.children}</div>
    </div>
  );
};

export default JobDetailsPage;
