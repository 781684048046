import { useTranslation } from "react-i18next";
import { convertNumberToMoney, currencyConversion, formatToMoney, FormInput, formValidators } from "../../../../utils";
import { useEffect, useState } from "react";

interface Props {
    revEmpList: { time_card_id: string, talent_id: string, talent_name: string, check_reversal_amount: number }[]
    handleFieldChange: (id, value, check_val) => void,
    values: { id: string, amount: number }[];
    userName: string;
}
const RevCheckEmpPage = (props: Props) => {
    const {
        revEmpList,
        handleFieldChange,
        values, userName
    } = props;

    const { t } = useTranslation();
    const formValidators = (value: { text: string; field: string }) => {
        const check_amnt = revEmpList?.find((rev) => rev.talent_id === value.field)?.check_reversal_amount;
        const newValAmnt = convertNumberToMoney(value.text, "number") as number;

        switch (value.field) {
            case value.field: {
                if (value.text && check_amnt && (newValAmnt) > check_amnt) {
                    return t("validators.check_amnt_great");

                }
                return null;
            }
            default: {
                return null;
            }
        }
    }

    return (
        <div className="rev-check-page">
            <span className="title-sp">{t("below_talent_neg_bal",{name:userName})}</span>
            <div className="ded-table">
                <table>
                    <thead>
                        <th>{t('employee_name')}</th>
                        <th>{t('check_reversal_amount')}</th>
                        <th>{t('check_rev_ded_amnt')}</th>

                    </thead>
                    <tbody>
                        {revEmpList.map((rev, index) =>
                        (
                            <tr>
                                <td className="label-div">
                                    {rev.talent_name}
                                </td>
                                <td className="fi-div">
                                    {currencyConversion(rev.check_reversal_amount, 2)}
                                </td>
                                <td className="fi-div">
                                    <FormInput
                                        id={rev.talent_id}
                                        onChange={(id, val) => handleFieldChange(id, val, rev.check_reversal_amount)}
                                        required={false}
                                        placeholder={"0"}
                                        type={"text"}
                                        value={convertNumberToMoney(values?.find((value) => value.id === rev.talent_id)?.amount, "string")}
                                        label=""
                                        min={"0.00"}
                                        onBlur={formatToMoney}
                                        onKeyDown={formatToMoney}
                                        onKeyUp={formatToMoney}
                                        validations={formValidators}
                                    ></FormInput>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default RevCheckEmpPage;