import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import {
    selectAll,
    selectEntities,
    selectState,
} from "./archived_directory_list_reducer";

const select = (state: IRootState) => state.dashboard.onboarding.completedThisWeek.archivedDirectoryList;
export const selectOCTWArchivedDirectoryListState = createSelector(
    select,
    selectState
);
export const selectOCTWArchivedDirectoryList = createSelector(
    select,
    selectAll
);
export const selectOCTWArchivedDirectoryEntities = createSelector(
    select,
    selectEntities
);
