import { IPDFAutoFill } from "../../../interfaces/pdf_auto_fill";

export const W2FormFields: { [key: string]: IPDFAutoFill } = {
//Page 1 - COPY 1
"topmostSubform[0]": { key: "topmostSubform[0]", mapTo: null },
"topmostSubform[0].Copy1[0]": { key: "topmostSubform[0].Copy1[0]", mapTo: null },
"topmostSubform[0].Copy1[0].f2_42[0]": { key: "topmostSubform[0].Copy1[0].f2_42[0]", mapTo: null },
"topmostSubform[0].Copy1[0].f2_41[0]": { key: "topmostSubform[0].Copy1[0].f2_41[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box19_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Box19_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box19_ReadOrder[0].f2_40[0]": { key: "topmostSubform[0].Copy1[0].Box19_ReadOrder[0].f2_40[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box19_ReadOrder[0].f2_39[0]": { key: "topmostSubform[0].Copy1[0].Box19_ReadOrder[0].f2_39[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box18_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Box18_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box18_ReadOrder[0].f2_38[0]": { key: "topmostSubform[0].Copy1[0].Box18_ReadOrder[0].f2_38[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box18_ReadOrder[0].f2_37[0]": { key: "topmostSubform[0].Copy1[0].Box18_ReadOrder[0].f2_37[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box17_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Box17_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box17_ReadOrder[0].f2_36[0]": { key: "topmostSubform[0].Copy1[0].Box17_ReadOrder[0].f2_36[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box17_ReadOrder[0].f2_35[0]": { key: "topmostSubform[0].Copy1[0].Box17_ReadOrder[0].f2_35[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box16_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Box16_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box16_ReadOrder[0].f2_34[0]": { key: "topmostSubform[0].Copy1[0].Box16_ReadOrder[0].f2_34[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Box16_ReadOrder[0].f2_33[0]": { key: "topmostSubform[0].Copy1[0].Box16_ReadOrder[0].f2_33[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0].f2_32[0]": { key: "topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0].f2_32[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0].f2_31[0]": { key: "topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0].f2_31[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0].f2_30[0]": { key: "topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0].f2_30[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0].f2_29[0]": { key: "topmostSubform[0].Copy1[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0].f2_29[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].f2_28[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].f2_28[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].c2_4[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].c2_4[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Retirement_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Retirement_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Retirement_ReadOrder[0].c2_3[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Retirement_ReadOrder[0].c2_3[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Statutory_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Statutory_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Statutory_ReadOrder[0].c2_2[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Statutory_ReadOrder[0].c2_2[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_27[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_27[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_26[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_26[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_25[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_25[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_24[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_24[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_23[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_23[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_22[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_22[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_21[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_21[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_20[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box12_ReadOrder[0].f2_20[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box11__ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box11__ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box11__ReadOrder[0].f2_19[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box11__ReadOrder[0].f2_19[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].f2_18[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].f2_18[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box9_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box9_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box9_ReadOrder[0].f2_17[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box9_ReadOrder[0].f2_17[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].f2_16[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].f2_16[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box7_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box7_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box7_ReadOrder[0].f2_15[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box7_ReadOrder[0].f2_15[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].f2_14[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].f2_14[0]", mapTo: ["medicare_tax_withheld"] },
"topmostSubform[0].Copy1[0].Col_Right[0].Box5_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box5_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box5_ReadOrder[0].f2_13[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box5_ReadOrder[0].f2_13[0]", mapTo: ["medicare_wages"] },
"topmostSubform[0].Copy1[0].Col_Right[0].f2_12[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].f2_12[0]", mapTo: ["social_security_tax_withheld"] },
"topmostSubform[0].Copy1[0].Col_Right[0].Box3_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box3_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box3_ReadOrder[0].f2_11[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box3_ReadOrder[0].f2_11[0]", mapTo: ["social_security_wages"] },
"topmostSubform[0].Copy1[0].Col_Right[0].f2_10[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].f2_10[0]", mapTo: ["federal_tax_withheld"] },
"topmostSubform[0].Copy1[0].Col_Right[0].Box1_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box1_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Right[0].Box1_ReadOrder[0].f2_09[0]": { key: "topmostSubform[0].Copy1[0].Col_Right[0].Box1_ReadOrder[0].f2_09[0]", mapTo: ["wages"] },
"topmostSubform[0].Copy1[0].Col_Left[0]": { key: "topmostSubform[0].Copy1[0].Col_Left[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Left[0].f2_07[0]": { key: "topmostSubform[0].Copy1[0].Col_Left[0].f2_07[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Left[0].LastName_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Col_Left[0].LastName_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Left[0].LastName_ReadOrder[0].f2_06[0]": { key: "topmostSubform[0].Copy1[0].Col_Left[0].LastName_ReadOrder[0].f2_06[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Left[0].FirstName_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].Col_Left[0].FirstName_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Left[0].FirstName_ReadOrder[0].f2_05[0]": { key: "topmostSubform[0].Copy1[0].Col_Left[0].FirstName_ReadOrder[0].f2_05[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Left[0].f2_04[0]": { key: "topmostSubform[0].Copy1[0].Col_Left[0].f2_04[0]", mapTo: null },
"topmostSubform[0].Copy1[0].Col_Left[0].f2_08[0]": { key: "topmostSubform[0].Copy1[0].Col_Left[0].f2_08[0]", mapTo: "employee_full_address" },
"topmostSubform[0].Copy1[0].Col_Left[0].f2_03[0]": { key: "topmostSubform[0].Copy1[0].Col_Left[0].f2_03[0]", mapTo: "employer_full_address" },
"topmostSubform[0].Copy1[0].Col_Left[0].f2_02[0]": { key: "topmostSubform[0].Copy1[0].Col_Left[0].f2_02[0]", mapTo: "employer_fein" },
"topmostSubform[0].Copy1[0].BoxA_ReadOrder[0]": { key: "topmostSubform[0].Copy1[0].BoxA_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy1[0].BoxA_ReadOrder[0].f2_01[0]": { key: "topmostSubform[0].Copy1[0].BoxA_ReadOrder[0].f2_01[0]", mapTo: "employee_ssn" },

// PAGE 2 - COPY B

"topmostSubform[0].CopyB[0]": { key: "topmostSubform[0].CopyB[0]", mapTo: null },
"topmostSubform[0].CopyB[0].f2_42[0]": { key: "topmostSubform[0].CopyB[0].f2_42[0]", mapTo: null },
"topmostSubform[0].CopyB[0].f2_41[0]": { key: "topmostSubform[0].CopyB[0].f2_41[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box19_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Box19_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box19_ReadOrder[0].f2_40[0]": { key: "topmostSubform[0].CopyB[0].Box19_ReadOrder[0].f2_40[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box19_ReadOrder[0].f2_39[0]": { key: "topmostSubform[0].CopyB[0].Box19_ReadOrder[0].f2_39[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box18_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Box18_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box18_ReadOrder[0].f2_38[0]": { key: "topmostSubform[0].CopyB[0].Box18_ReadOrder[0].f2_38[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box18_ReadOrder[0].f2_37[0]": { key: "topmostSubform[0].CopyB[0].Box18_ReadOrder[0].f2_37[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box17_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Box17_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box17_ReadOrder[0].f2_36[0]": { key: "topmostSubform[0].CopyB[0].Box17_ReadOrder[0].f2_36[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box17_ReadOrder[0].f2_35[0]": { key: "topmostSubform[0].CopyB[0].Box17_ReadOrder[0].f2_35[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box16_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Box16_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box16_ReadOrder[0].f2_34[0]": { key: "topmostSubform[0].CopyB[0].Box16_ReadOrder[0].f2_34[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Box16_ReadOrder[0].f2_33[0]": { key: "topmostSubform[0].CopyB[0].Box16_ReadOrder[0].f2_33[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0].f2_32[0]": { key: "topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0].f2_32[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0].f2_31[0]": { key: "topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0].f2_31[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0].f2_30[0]": { key: "topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0].f2_30[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0].f2_29[0]": { key: "topmostSubform[0].CopyB[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0].f2_29[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].f2_28[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].f2_28[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].c2_4[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].c2_4[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Retirement_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Retirement_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Retirement_ReadOrder[0].c2_3[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Retirement_ReadOrder[0].c2_3[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Statutory_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Statutory_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Statutory_ReadOrder[0].c2_2[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Statutory_ReadOrder[0].c2_2[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_27[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_27[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_26[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_26[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_25[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_25[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_24[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_24[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_23[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_23[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_22[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_22[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_21[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_21[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_20[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box12_ReadOrder[0].f2_20[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box11__ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box11__ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box11__ReadOrder[0].f2_19[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box11__ReadOrder[0].f2_19[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].f2_18[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].f2_18[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box9_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box9_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box9_ReadOrder[0].f2_17[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box9_ReadOrder[0].f2_17[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].f2_16[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].f2_16[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box7_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box7_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box7_ReadOrder[0].f2_15[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box7_ReadOrder[0].f2_15[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].f2_14[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].f2_14[0]", mapTo: ["medicare_tax_withheld"] },
"topmostSubform[0].CopyB[0].Col_Right[0].Box5_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box5_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box5_ReadOrder[0].f2_13[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box5_ReadOrder[0].f2_13[0]", mapTo: ["medicare_wages"] },
"topmostSubform[0].CopyB[0].Col_Right[0].f2_12[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].f2_12[0]", mapTo: ["social_security_tax_withheld"] },
"topmostSubform[0].CopyB[0].Col_Right[0].Box3_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box3_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box3_ReadOrder[0].f2_11[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box3_ReadOrder[0].f2_11[0]", mapTo: ["social_security_wages"] },
"topmostSubform[0].CopyB[0].Col_Right[0].f2_10[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].f2_10[0]", mapTo: ["federal_tax_withheld"] },
"topmostSubform[0].CopyB[0].Col_Right[0].Box1_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box1_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Right[0].Box1_ReadOrder[0].f2_09[0]": { key: "topmostSubform[0].CopyB[0].Col_Right[0].Box1_ReadOrder[0].f2_09[0]", mapTo: ["wages"] },
"topmostSubform[0].CopyB[0].Col_Left[0]": { key: "topmostSubform[0].CopyB[0].Col_Left[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Left[0].f2_07[0]": { key: "topmostSubform[0].CopyB[0].Col_Left[0].f2_07[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Left[0].LastName_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Col_Left[0].LastName_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Left[0].LastName_ReadOrder[0].f2_06[0]": { key: "topmostSubform[0].CopyB[0].Col_Left[0].LastName_ReadOrder[0].f2_06[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Left[0].FirstName_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].Col_Left[0].FirstName_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Left[0].FirstName_ReadOrder[0].f2_05[0]": { key: "topmostSubform[0].CopyB[0].Col_Left[0].FirstName_ReadOrder[0].f2_05[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Left[0].f2_04[0]": { key: "topmostSubform[0].CopyB[0].Col_Left[0].f2_04[0]", mapTo: null },
"topmostSubform[0].CopyB[0].Col_Left[0].f2_08[0]": { key: "topmostSubform[0].CopyB[0].Col_Left[0].f2_08[0]", mapTo: "employee_full_address" },
"topmostSubform[0].CopyB[0].Col_Left[0].f2_03[0]": { key: "topmostSubform[0].CopyB[0].Col_Left[0].f2_03[0]", mapTo: "employer_full_address" },
"topmostSubform[0].CopyB[0].Col_Left[0].f2_02[0]": { key: "topmostSubform[0].CopyB[0].Col_Left[0].f2_02[0]", mapTo: "employer_fein" },
"topmostSubform[0].CopyB[0].BoxA_ReadOrder[0]": { key: "topmostSubform[0].CopyB[0].BoxA_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyB[0].BoxA_ReadOrder[0].f2_01[0]": { key: "topmostSubform[0].CopyB[0].BoxA_ReadOrder[0].f2_01[0]", mapTo: "employee_ssn" },

// PAGE 3 - COPY C
"topmostSubform[0].CopyC[0]": { key: "topmostSubform[0].CopyC[0]", mapTo: null },
"topmostSubform[0].CopyC[0].f2_42[0]": { key: "topmostSubform[0].CopyC[0].f2_42[0]", mapTo: null },
"topmostSubform[0].CopyC[0].f2_41[0]": { key: "topmostSubform[0].CopyC[0].f2_41[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box19_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Box19_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box19_ReadOrder[0].f2_40[0]": { key: "topmostSubform[0].CopyC[0].Box19_ReadOrder[0].f2_40[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box19_ReadOrder[0].f2_39[0]": { key: "topmostSubform[0].CopyC[0].Box19_ReadOrder[0].f2_39[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box18_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Box18_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box18_ReadOrder[0].f2_38[0]": { key: "topmostSubform[0].CopyC[0].Box18_ReadOrder[0].f2_38[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box18_ReadOrder[0].f2_37[0]": { key: "topmostSubform[0].CopyC[0].Box18_ReadOrder[0].f2_37[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box17_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Box17_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box17_ReadOrder[0].f2_36[0]": { key: "topmostSubform[0].CopyC[0].Box17_ReadOrder[0].f2_36[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box17_ReadOrder[0].f2_35[0]": { key: "topmostSubform[0].CopyC[0].Box17_ReadOrder[0].f2_35[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box16_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Box16_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box16_ReadOrder[0].f2_34[0]": { key: "topmostSubform[0].CopyC[0].Box16_ReadOrder[0].f2_34[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Box16_ReadOrder[0].f2_33[0]": { key: "topmostSubform[0].CopyC[0].Box16_ReadOrder[0].f2_33[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0].f2_32[0]": { key: "topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0].f2_32[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0].f2_31[0]": { key: "topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0].f2_31[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0].f2_30[0]": { key: "topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0].f2_30[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0].f2_29[0]": { key: "topmostSubform[0].CopyC[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0].f2_29[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].f2_28[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].f2_28[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].c2_4[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].c2_4[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Retirement_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Retirement_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Retirement_ReadOrder[0].c2_3[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Retirement_ReadOrder[0].c2_3[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Statutory_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Statutory_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Statutory_ReadOrder[0].c2_2[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Statutory_ReadOrder[0].c2_2[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_27[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_27[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_26[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_26[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_25[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_25[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_24[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_24[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_23[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_23[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_22[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_22[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_21[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_21[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_20[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box12_ReadOrder[0].f2_20[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box11__ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box11__ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box11__ReadOrder[0].f2_19[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box11__ReadOrder[0].f2_19[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].f2_18[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].f2_18[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box9_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box9_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box9_ReadOrder[0].f2_17[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box9_ReadOrder[0].f2_17[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].f2_16[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].f2_16[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box7_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box7_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box7_ReadOrder[0].f2_15[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box7_ReadOrder[0].f2_15[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].f2_14[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].f2_14[0]", mapTo: ["medicare_tax_withheld"] },
"topmostSubform[0].CopyC[0].Col_Right[0].Box5_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box5_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box5_ReadOrder[0].f2_13[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box5_ReadOrder[0].f2_13[0]", mapTo: ["medicare_wages"] },
"topmostSubform[0].CopyC[0].Col_Right[0].f2_12[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].f2_12[0]", mapTo: ["social_security_tax_withheld"] },
"topmostSubform[0].CopyC[0].Col_Right[0].Box3_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box3_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box3_ReadOrder[0].f2_11[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box3_ReadOrder[0].f2_11[0]", mapTo: ["social_security_wages"] },
"topmostSubform[0].CopyC[0].Col_Right[0].f2_10[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].f2_10[0]", mapTo: ["federal_tax_withheld"] },
"topmostSubform[0].CopyC[0].Col_Right[0].Box1_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box1_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Right[0].Box1_ReadOrder[0].f2_09[0]": { key: "topmostSubform[0].CopyC[0].Col_Right[0].Box1_ReadOrder[0].f2_09[0]", mapTo: ["wages"] },
"topmostSubform[0].CopyC[0].Col_Left[0]": { key: "topmostSubform[0].CopyC[0].Col_Left[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Left[0].f2_07[0]": { key: "topmostSubform[0].CopyC[0].Col_Left[0].f2_07[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Left[0].LastName_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Col_Left[0].LastName_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Left[0].LastName_ReadOrder[0].f2_06[0]": { key: "topmostSubform[0].CopyC[0].Col_Left[0].LastName_ReadOrder[0].f2_06[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Left[0].FirstName_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].Col_Left[0].FirstName_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Left[0].FirstName_ReadOrder[0].f2_05[0]": { key: "topmostSubform[0].CopyC[0].Col_Left[0].FirstName_ReadOrder[0].f2_05[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Left[0].f2_04[0]": { key: "topmostSubform[0].CopyC[0].Col_Left[0].f2_04[0]", mapTo: null },
"topmostSubform[0].CopyC[0].Col_Left[0].f2_08[0]": { key: "topmostSubform[0].CopyC[0].Col_Left[0].f2_08[0]", mapTo: "employee_full_address" },
"topmostSubform[0].CopyC[0].Col_Left[0].f2_03[0]": { key: "topmostSubform[0].CopyC[0].Col_Left[0].f2_03[0]", mapTo: "employer_full_address" },
"topmostSubform[0].CopyC[0].Col_Left[0].f2_02[0]": { key: "topmostSubform[0].CopyC[0].Col_Left[0].f2_02[0]", mapTo: "employer_fein" },
"topmostSubform[0].CopyC[0].BoxA_ReadOrder[0]": { key: "topmostSubform[0].CopyC[0].BoxA_ReadOrder[0]", mapTo: null },
"topmostSubform[0].CopyC[0].BoxA_ReadOrder[0].f2_01[0]": { key: "topmostSubform[0].CopyC[0].BoxA_ReadOrder[0].f2_01[0]", mapTo: "employee_ssn" },

// PAGE 4 - COPY 2
"topmostSubform[0].Copy2[0]": { key: "topmostSubform[0].Copy2[0]", mapTo: null },
"topmostSubform[0].Copy2[0].f2_42[0]": { key: "topmostSubform[0].Copy2[0].f2_42[0]", mapTo: null },
"topmostSubform[0].Copy2[0].f2_41[0]": { key: "topmostSubform[0].Copy2[0].f2_41[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box19_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Box19_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box19_ReadOrder[0].f2_40[0]": { key: "topmostSubform[0].Copy2[0].Box19_ReadOrder[0].f2_40[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box19_ReadOrder[0].f2_39[0]": { key: "topmostSubform[0].Copy2[0].Box19_ReadOrder[0].f2_39[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box18_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Box18_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box18_ReadOrder[0].f2_38[0]": { key: "topmostSubform[0].Copy2[0].Box18_ReadOrder[0].f2_38[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box18_ReadOrder[0].f2_37[0]": { key: "topmostSubform[0].Copy2[0].Box18_ReadOrder[0].f2_37[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box17_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Box17_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box17_ReadOrder[0].f2_36[0]": { key: "topmostSubform[0].Copy2[0].Box17_ReadOrder[0].f2_36[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box17_ReadOrder[0].f2_35[0]": { key: "topmostSubform[0].Copy2[0].Box17_ReadOrder[0].f2_35[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box16_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Box16_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box16_ReadOrder[0].f2_34[0]": { key: "topmostSubform[0].Copy2[0].Box16_ReadOrder[0].f2_34[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Box16_ReadOrder[0].f2_33[0]": { key: "topmostSubform[0].Copy2[0].Box16_ReadOrder[0].f2_33[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0].f2_32[0]": { key: "topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0].f2_32[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0].f2_31[0]": { key: "topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0].f2_31[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0].f2_30[0]": { key: "topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0].f2_30[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0].f2_29[0]": { key: "topmostSubform[0].Copy2[0].Boxes15_ReadOrder[0].Box15_ReadOrder[0].f2_29[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].f2_28[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].f2_28[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].c2_4[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].c2_4[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Retirement_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Retirement_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Retirement_ReadOrder[0].c2_3[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Retirement_ReadOrder[0].c2_3[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Statutory_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Statutory_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Statutory_ReadOrder[0].c2_2[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Statutory_ReadOrder[0].c2_2[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_27[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_27[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_26[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_26[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_25[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_25[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_24[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_24[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_23[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_23[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_22[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_22[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_21[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_21[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_20[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box12_ReadOrder[0].f2_20[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box11__ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box11__ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box11__ReadOrder[0].f2_19[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box11__ReadOrder[0].f2_19[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].f2_18[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].f2_18[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box9_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box9_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box9_ReadOrder[0].f2_17[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box9_ReadOrder[0].f2_17[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].f2_16[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].f2_16[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box7_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box7_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box7_ReadOrder[0].f2_15[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box7_ReadOrder[0].f2_15[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].f2_14[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].f2_14[0]", mapTo: ["medicare_tax_withheld"] },
"topmostSubform[0].Copy2[0].Col_Right[0].Box5_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box5_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box5_ReadOrder[0].f2_13[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box5_ReadOrder[0].f2_13[0]", mapTo: ["medicare_wages"] },
"topmostSubform[0].Copy2[0].Col_Right[0].f2_12[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].f2_12[0]", mapTo: ["social_security_tax_withheld"] },
"topmostSubform[0].Copy2[0].Col_Right[0].Box3_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box3_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box3_ReadOrder[0].f2_11[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box3_ReadOrder[0].f2_11[0]", mapTo: ["social_security_wages"] },
"topmostSubform[0].Copy2[0].Col_Right[0].f2_10[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].f2_10[0]", mapTo: ["federal_tax_withheld"] },
"topmostSubform[0].Copy2[0].Col_Right[0].Box1_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box1_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Right[0].Box1_ReadOrder[0].f2_09[0]": { key: "topmostSubform[0].Copy2[0].Col_Right[0].Box1_ReadOrder[0].f2_09[0]", mapTo: ["wages"] },
"topmostSubform[0].Copy2[0].Col_Left[0]": { key: "topmostSubform[0].Copy2[0].Col_Left[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Left[0].f2_07[0]": { key: "topmostSubform[0].Copy2[0].Col_Left[0].f2_07[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Left[0].LastName_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Col_Left[0].LastName_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Left[0].LastName_ReadOrder[0].f2_06[0]": { key: "topmostSubform[0].Copy2[0].Col_Left[0].LastName_ReadOrder[0].f2_06[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Left[0].FirstName_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].Col_Left[0].FirstName_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Left[0].FirstName_ReadOrder[0].f2_05[0]": { key: "topmostSubform[0].Copy2[0].Col_Left[0].FirstName_ReadOrder[0].f2_05[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Left[0].f2_04[0]": { key: "topmostSubform[0].Copy2[0].Col_Left[0].f2_04[0]", mapTo: null },
"topmostSubform[0].Copy2[0].Col_Left[0].f2_08[0]": { key: "topmostSubform[0].Copy2[0].Col_Left[0].f2_08[0]", mapTo: "employee_full_address" },
"topmostSubform[0].Copy2[0].Col_Left[0].f2_03[0]": { key: "topmostSubform[0].Copy2[0].Col_Left[0].f2_03[0]", mapTo: "employer_full_address" },
"topmostSubform[0].Copy2[0].Col_Left[0].f2_02[0]": { key: "topmostSubform[0].Copy2[0].Col_Left[0].f2_02[0]", mapTo: "employer_fein" },
"topmostSubform[0].Copy2[0].BoxA_ReadOrder[0]": { key: "topmostSubform[0].Copy2[0].BoxA_ReadOrder[0]", mapTo: null },
"topmostSubform[0].Copy2[0].BoxA_ReadOrder[0].f2_01[0]": { key: "topmostSubform[0].Copy2[0].BoxA_ReadOrder[0].f2_01[0]", mapTo: "employee_ssn" },


}