
import { createEntityAdapter, createSlice, EntityAdapter, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, ITalentClientCompanyInterview, ITalentInHouseInterview } from "../../../../../interfaces";
import * as thunk from "./talent_list_thunk";

interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

interface UpdateState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialUpdateState: UpdateState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

export interface SingleTalentJobInterviewsToClientCompaniesState {
    data: {
        [key: string]: ITalentClientCompanyInterview,
    },
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    update: UpdateState,
}


export interface JobInterviewsToClientCompaniesState {
    [key: string]: SingleTalentJobInterviewsToClientCompaniesState,
}


const initialState: JobInterviewsToClientCompaniesState = {}


export const jobOrderTalentSlice = createSlice({
    name: 'talentList',
    initialState: initialState,
    reducers: {
        initializeState(state, action: PayloadAction<string>){
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    loading: LoadingType.idle,
                    error: null,
                    add: { ...initialAddState },
                    update: { ...initialUpdateState },
                }
            };
        },
        clearInterviewsToClientCompaniesError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    error: null,
                }
            };
        },
        clearSingleTalentJobInterviewsToClientCompaniesStateError(state, action: PayloadAction<string>) {
        
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: {
                        ...state[action.payload].add,
                        error: null,
                    },
                    update: {
                        ...state[action.payload].update,
                        error: null,
                    },
                }
            }
        },
        clearSingleTalentInterviewsToClientCompaniesManageState(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: { ...initialAddState },
                    update: { ...initialUpdateState },
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getJobOrderTalentInterviewsToClientCompanies.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.pending,
                        error: null,
                        add: { ...initialAddState },
                        update: { ...initialUpdateState },
                    }
                })
            )
            .addCase(
                thunk.getJobOrderTalentInterviewsToClientCompanies.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.failed,
                        error: action.payload,
                    }
                })
            )
            .addCase(
                thunk.getJobOrderTalentInterviewsToClientCompanies.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        data: action.payload.reduce((a, v) => ({ ...a, [v.id]: v }), {}),
                        loading: LoadingType.succeeded,
                        error: null,
                    }
                })
            )
            /// Add new talent in house interview
            .addCase(
                thunk.addNewJobOrderTalentClientCompanyInterview.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.jobId]: {
                        ...state[action.meta.arg.jobId],
                        add: {
                            ...state[action.meta.arg.jobId].add,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.addNewJobOrderTalentClientCompanyInterview.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.jobId]: {
                        ...state[action.meta.arg.jobId],
                        add: {
                            ...state[action.meta.arg.jobId].add,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.addNewJobOrderTalentClientCompanyInterview.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.jobId]: {
                        ...state[action.meta.arg.jobId],
                        add: {
                            ...state[action.meta.arg.jobId].add,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )
            /// update 
            .addCase(
                thunk.updateJobOrderTalentClientCompanyInterview.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.jobId]: {
                        ...state[action.meta.arg.jobId],
                        update: {
                            ...state[action.meta.arg.jobId].add,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.updateJobOrderTalentClientCompanyInterview.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.jobId]: {
                        ...state[action.meta.arg.jobId],
                        update: {
                            ...state[action.meta.arg.jobId].add,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.updateJobOrderTalentClientCompanyInterview.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.jobId]: {
                        ...state[action.meta.arg.jobId],
                        update: {
                            ...state[action.meta.arg.jobId].add,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const jobOrderTalentSliceActions = jobOrderTalentSlice.actions;
export const selectState = (state: JobInterviewsToClientCompaniesState) => state;
export const selectSingleState = (state: JobInterviewsToClientCompaniesState, id: string) => state[id];

export const selectSingleJobInterviewsToClientCompaniesInitialState = (): SingleTalentJobInterviewsToClientCompaniesState => {
    return {
        loading: LoadingType.pending,
        error: null,
        data: {},
        add: initialAddState,
        update: initialUpdateState,
    }
}































// import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { LoadingType } from "../../../../../enums";
// import {AppError} from "../../../../../interfaces/app_error";
// import { IJobOrderContact  } from "../../../../../entities/ContactInfo";
// import * as thunk from './talent_list_thunk';
// import { ITalentClientCompanyInterview } from "../../../../../interfaces";



// interface AddState {
//     error?: AppError | null,
//     loading: LoadingType,
//     response: string | null,
// }

// const initialAddState: AddState = {
//     response: null,
//     error: null,
//     loading: LoadingType.idle,
// }

// interface UpdateState {
//     error?: AppError | null,
//     loading: LoadingType,
//     response: string | null,
// }

// const initialUpdateState: UpdateState = {
//     response: null,
//     error: null,
//     loading: LoadingType.idle,
// }

// export interface SingleJobTalentInterviewsToClientCompaniesState {
//     data: {
//         [key: string]: ITalentClientCompanyInterview,
//     },
//     error?: AppError | null,
//     loading: LoadingType,
//     add: AddState,
//     update: UpdateState,
// }


// export interface InterviewsToClientCompaniesState {
//     [key: string]: SingleJobTalentInterviewsToClientCompaniesState,
// }


// const initialState: InterviewsToClientCompaniesState = {}

// export const jobOrderTalentSlice = createSlice({
//     name: 'talentList',
//     initialState: initialState,
//     reducers: {
//         initializeState(state, action: PayloadAction<string>){
//             return {
//                 ...state,
//                 [action.payload]: {
//                     ...state[action.payload],
//                     loading: LoadingType.idle,
//                     error: null,
//                     add: { ...initialAddState },
//                     update: { ...initialUpdateState },
//                 }
//             };
//         },
//         clearInterviewsToClientCompaniesError(state, action: PayloadAction<string>) {
//             return {
//                 ...state,
//                 [action.payload]: {
//                     ...state[action.payload],
//                     error: null,
//                 }
//             };
//         },
//         clearSingleTalentInterviewsToClientCompaniesStateError(state, action: PayloadAction<string>) {
//             return {
//                 ...state,
//                 [action.payload]: {
//                     ...state[action.payload],
//                     add: {
//                         ...state[action.payload].add,
//                         error: null,
//                     },
//                     update: {
//                         ...state[action.payload].update,
//                         error: null,
//                     },
//                 }
//             }
//         },
//         clearSingleTalentInterviewsToClientCompaniesManageState(state, action: PayloadAction<string>) {
//             return {
//                 ...state,
//                 [action.payload]: {
//                     ...state[action.payload],
//                     add: { ...initialAddState },
//                     update: { ...initialUpdateState },
//                 }
//             }
//         },
//     },
//     extraReducers: (builder) => {
//         builder
//             .addCase(
//                 thunk.getJobOrderTalentInterviewsToClientCompanies.pending,
//                 (state, action) => ({
//                     ...state,
//                     [action.meta.arg]: {
//                         ...state[action.meta.arg],
//                         loading: LoadingType.pending,
//                         error: null,
//                         add: { ...initialAddState },
//                         update: { ...initialUpdateState },
//                     }
//                 })
//             )
//             .addCase(
//                 thunk.getJobOrderTalentInterviewsToClientCompanies.rejected,
//                 (state, action) => ({
//                     ...state,
//                     [action.meta.arg]: {
//                         ...state[action.meta.arg],
//                         loading: LoadingType.failed,
//                         error: action.payload,
//                     }
//                 })
//             )
//             .addCase(
//                 thunk.getJobOrderTalentInterviewsToClientCompanies.fulfilled,
//                 (state, action) => ({
//                     ...state,
//                     [action.meta.arg]: {
//                         ...state[action.meta.arg],
//                         data: action.payload.reduce((a, v) => ({ ...a, [v.id]: v }), {}),
//                         loading: LoadingType.succeeded,
//                         error: null,
//                     }
//                 })
//             )
//             /// Add new talent in house interview
//             .addCase(
//                 thunk.addNewJobOrderTalentClientCompanyInterview.pending,
//                 (state, action) => ({
//                     ...state,
//                     [action.meta.arg.jobId]: {
//                         ...state[action.meta.arg.jobId],
//                         add: {
//                             ...state[action.meta.arg.jobId].add,
//                             loading: LoadingType.pending,
//                             error: null,
//                         }
//                     }
//                 })
//             )
//             .addCase(
//                 thunk.addNewJobOrderTalentClientCompanyInterview.rejected,
//                 (state, action) => ({
//                     ...state,
//                     [action.meta.arg.jobId]: {
//                         ...state[action.meta.arg.jobId],
//                         add: {
//                             ...state[action.meta.arg.jobId].add,
//                             loading: LoadingType.failed,
//                             error: action.payload,
//                         }
//                     }

//                 })
//             )
//             .addCase(
//                 thunk.addNewJobOrderTalentClientCompanyInterview.fulfilled,
//                 (state, action) => ({
//                     ...state,
//                     [action.meta.arg.jobId]: {
//                         ...state[action.meta.arg.jobId],
//                         add: {
//                             ...state[action.meta.arg.jobId].add,
//                             loading: LoadingType.succeeded,
//                             error: null,
//                             response: action.payload,
//                         }

//                     }
//                 })
//             )
//             /// update 
//             .addCase(
//                 thunk.updateJobOrderTalentClientCompanyInterview.pending,
//                 (state, action) => ({
//                     ...state,
//                     [action.meta.arg.jobId]: {
//                         ...state[action.meta.arg.jobId],
//                         update: {
//                             ...state[action.meta.arg.jobId].add,
//                             loading: LoadingType.pending,
//                             error: null,
//                         }
//                     }
//                 })
//             )
//             .addCase(
//                 thunk.updateJobOrderTalentClientCompanyInterview.rejected,
//                 (state, action) => ({
//                     ...state,
//                     [action.meta.arg.jobId]: {
//                         ...state[action.meta.arg.jobId],
//                         update: {
//                             ...state[action.meta.arg.jobId].add,
//                             loading: LoadingType.failed,
//                             error: action.payload,
//                         }
//                     }

//                 })
//             )
//             .addCase(
//                 thunk.updateJobOrderTalentClientCompanyInterview.fulfilled,
//                 (state, action) => ({
//                     ...state,
//                     [action.meta.arg.jobId]: {
//                         ...state[action.meta.arg.jobId],
//                         update: {
//                             ...state[action.meta.arg.jobId].add,
//                             loading: LoadingType.succeeded,
//                             error: null,
//                             response: action.payload,
//                         }

//                     }
//                 })
//             )
//             .addDefaultCase(state => ({ ...state }));
//     }
// });
// //companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

// export const jobOrderTalentSliceActions = jobOrderTalentSlice.actions;
// export const selectState = (state: InterviewsToClientCompaniesState) => state;
// export const selectSingleState = (state: InterviewsToClientCompaniesState, id: string) => state[id];

// export const selectSingleJobInterviewsToClientCompaniesInitialState = (): SingleJobTalentInterviewsToClientCompaniesState => {
//     return {
//         loading: LoadingType.pending,
//         error: null,
//         data: {},
//         add: initialAddState,
//         update: initialUpdateState,
//     }
// }