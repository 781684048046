import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import {
    selectState,
    selectCompanyState,
    selectInitialTalentState,
    selectCompanySkillsState,
    selectCompanyCredentialsState,
    selectCompanyCertificationsState,
    selectCompanyEducationState,
    selectCompanyEquipmentState,
} from "./company_details_reducer";

const select = (state: IRootState) => state.companies.details.dashboard.companyDetails;
export const selectCompanyDetailsState = createSelector(select, selectState);
export const selectCompanyDetailsByIdState = (id: string | undefined | null) => createSelector(
    selectCompanyDetailsState,
    (state) => {
        if (id && state[id]) return selectCompanyState(state, id);
        return selectInitialTalentState();
    }
);
export const selectCompanySkillsStateById = (id: string | undefined | null) => createSelector(
    selectCompanyDetailsByIdState(id),
    selectCompanySkillsState,
);
export const selectCompanyCredentialsStateById = (id: string | undefined | null) => createSelector(
    selectCompanyDetailsByIdState(id),
    selectCompanyCredentialsState,
);
export const selectCompanyCertificationsStateById = (id: string | undefined | null) => createSelector(
    selectCompanyDetailsByIdState(id),
    selectCompanyCertificationsState,
);
export const selectCompanyEducationStateById = (id: string | undefined | null) => createSelector(
    selectCompanyDetailsByIdState(id),
    selectCompanyEducationState,
);
export const selectCompanyEquipmentStateById = (id: string | undefined | null) => createSelector(
    selectCompanyDetailsByIdState(id),
    selectCompanyEquipmentState,
);
// export const selectCompanyDetailsDepartmentState = createSelector(select, selectDepartmentState);
