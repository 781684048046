import { RouteComponentProps } from "react-router-dom"
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import DatePicker from "react-datepicker";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import SearchBar from "../../../../../components/search_bar";
import { useEffect, useState } from "react";
import { AppRoutes } from "../../../../../routes";
import { LoadingType, THEME } from "../../../../../enums";
import { convertDateToTimeStamp, getDateFromTimeStamp, getDateString } from "../../../../../variables";
import { IRCInvoiceRegister, IRCSingleInvoiceRegister } from "../../../../../interfaces/report_center_finance_accounting";
import { getInvoicePDFURLThunk, getInvoiceRegisterList, getReportDownloadURL, getReportStartEndDate, selectePaycheckInvoiceState, selectInvoiceRegisterListState, selectReportsDownloadState, selectReportStartEndDateState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { currencyConversion, CustomButton } from "../../../../../utils";
import './invoice_register.scss'
import UrlBuilder from "../../../../../apis/url_builder";
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import { paycheckInvoiceSliceActions } from "../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
    { title: "customer", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "invoice_date", code: "invoice_date" },
    { title: "due_date", code: "due_date" },
    { title: "invoice_number", code: "invoice_number" },
    { title: "amount_billed", code: "invoice_amount" },
    { title: "sales_tax", code: "sales_tax_amount" },
    { title: "total_invoice_amount", code: "invoice_amount" },
    { title: "discount", code: "discount" },
    { title: "payments_made", code: "payment_amount" },
    { title: "balance_due", code: "balance_amount" },
];

const InvoiceRegister: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const invoiceRegisterListState = useAppSelector((state) => selectInvoiceRegisterListState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));

    const [invoiceRegisterList, setInvoiceRegisterList] = useState<IRCInvoiceRegister | null>(null);
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");

    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>()
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const invoicePDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0)) {
            getInvoiceRegister();
            setDatePickerOpen(false)
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getInvoiceRegister();
            setDatePickerOpen(true);
        }
    }, [formState?.available_from, formState?.available_to]);

    useEffect(() => {
        if (invoiceRegisterListState && invoiceRegisterListState?.loading === LoadingType.succeeded) {
            setInvoiceRegisterList(invoiceRegisterListState?.response);
        }
    }, [invoiceRegisterListState?.loading]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    useEffect(() => {
        if (invoicePDFURLState.invoice.loading === LoadingType.succeeded && invoicePDFURLState.invoice.response !== "") {
            window.open(invoicePDFURLState.invoice.response, "_blank");
            dispatch(paycheckInvoiceSliceActions.clearInoviceState());
        }
    }, [invoicePDFURLState.invoice.loading, invoicePDFURLState.invoice.response]);

    const getInvoiceRegister = () => {
        if (formState) {
            dispatch(getInvoiceRegisterList({ start_date: formState.available_from, end_date: formState.available_to }))
        }
    }

    const handleViewInvoice = (invoice_id: string) => {
        dispatch(getInvoicePDFURLThunk(invoice_id));
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
    }


    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.invoiceRegister,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    download: true
                }));
        }
    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function handleCompanySelect(value: IRCSingleInvoiceRegister) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            },
        });
        return;
    }
    function handleDepartmentSelect(value: IRCSingleInvoiceRegister) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.department_id,
                departmentName: value.department_name,
            },
        });
        return;
    }
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1.5;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1;
        return 1;
    }

    const showSpinner = () => invoiceRegisterListState?.loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: IRCSingleInvoiceRegister[] | undefined = invoiceRegisterList?.invoices;
        let resultList: IRCSingleInvoiceRegister[] | undefined;


        if (list && sortedField != null) {
            list = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return (resultList ?? list)?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (invoiceRegisterListState?.error != null && invoiceRegisterList?.invoices?.length === 0) {
            return <TableErrorHandler error={invoiceRegisterListState?.error} onRefresh={getInvoiceRegister} />;
        }

        if (invoiceRegisterList?.invoices?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No Data found"} onClick={getInvoiceRegister} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <div className="ir-table">
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList()?.map((doc) => {
                            return (
                                <tr key={doc.id}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}
                                        isButton={true}
                                        onClick={() => handleCompanySelect(doc)}
                                    >
                                        <span>{doc.company_name}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}
                                        isButton={true}
                                        onClick={() => handleDepartmentSelect(doc)}
                                    >
                                        <span>{doc.department_name !== "" ? doc.department_name : t("corporate")}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                        <span>{getDateString(doc.invoice_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        <span>{getDateString(doc.due_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}
                                        isButton={true}
                                        onClick={() => handleViewInvoice(doc.id)}
                                    >
                                        <span>{doc.invoice_number}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <span>{currencyConversion(doc.invoice_amount)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                        <span>{currencyConversion(doc.sales_tax_amount)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                        <span>{currencyConversion(doc.invoice_amount)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                        <span>{currencyConversion(doc.discount)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                        <span>{currencyConversion(doc.payment_amount)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(10) }}>
                                        <span>{currencyConversion(doc.balance_amount)}</span>
                                    </TableData>
                                </tr>
                            )
                        })}
                    </SortableTable>
                </div>
            );
        }
    };

    return (
        <div className="invoice-report-container">
            <div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="invoice-report-header">
                <div className="ir-h-left">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                            />
                        </div>
                    </div>
                </div>
                <div className="ir-h-right">
                    <div className="total-count-action">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                    <div className="dwn-btn">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={getFilteredList()?.length !== 0 && true}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    <div>
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                        />
                    </div>
                </div>
            </div>

            <div className="table-container">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t("invoice_register")}</span>
                        </div>
                    </div>
                </div>
                {getTable()}
            </div>
            {invoiceRegisterListState.loading === LoadingType.succeeded && <div>
                <div className="total-counts-first-card">
                    <div className="total-title">
                        Totals
                    </div>
                    <div className="total-count-main">
                        <div className="total-count">
                            {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.invoice_amount
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.sales_tax_amount
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.invoice_amount
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.discount
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.payment_amount
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.balance_amount
                            }, 0) : 0)}
                        </div>
                    </div>
                </div>
                <div className="total-counts-second-card">
                    <div className="total-title">
                        Report Summary
                    </div>
                    <div className="total-count">
                        <span>Total Amount Billed:</span>
                        {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                            return prev + +current.invoice_amount
                        }, 0) : 0)}
                    </div>
                    <div className="total-count">
                        <span>Total Sales Tax:</span>
                        {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                            return prev + +current.sales_tax_amount
                        }, 0) : 0)}
                    </div>
                    <div className="total-count pb">
                        <span>Discount(s) Total:</span>
                        {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                            return prev + +current.discount
                        }, 0) : 0)}
                    </div>
                    <div className="total-count mt-b">
                        <span>Invoice Totals:</span>
                        {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                            return prev + +current.invoice_amount
                        }, 0) : 0)}
                    </div>
                    <div className="total-count pb">
                        <span>Payments Totals:</span>
                        {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                            return prev + +current.payment_amount
                        }, 0) : 0)}
                    </div>
                    <div className="total-count mt-b">
                        <span>Total No. of Customers Billed:</span>
                        <span>{new Set(getFilteredList()?.map(customer => customer.company_name)).size}</span>
                    </div>
                    <div className="total-count pb">
                        <span>Total No. of Invoices:</span>
                        <span>{getFilteredList()?.length}</span>
                    </div>
                    <div className="total-count mt-b">
                        <span>Total Balance Due:</span>
                        {currencyConversion(invoiceRegisterList?.invoices && invoiceRegisterList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                            return prev + +current.balance_amount
                        }, 0) : 0)}
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default InvoiceRegister;