import { combineReducers } from "@reduxjs/toolkit";
import { salesCallSummaryReportListSlice } from "./sales_call_summary_report/sales_call_summary_report_reducer";
import { marketingSummaryReportListSlice } from "./marketing_summary_report/marketing_summary_report_reducer";

export const reportCenterSalesAndMarketingReducers = combineReducers({
    [salesCallSummaryReportListSlice.name]: salesCallSummaryReportListSlice.reducer,
    [marketingSummaryReportListSlice.name]: marketingSummaryReportListSlice.reducer,
});

export * from "./sales_call_summary_report/sales_call_summary_report_selector";
export * from "./sales_call_summary_report/sales_call_summary_report_thunk";

export * from "./marketing_summary_report/marketing_summary_report_selector";
export * from "./marketing_summary_report/marketing_summary_report_thunk";
