import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { LoadingType, THEME } from "../../../../enums";
import { CloseIcon, TickGreenIcon } from "../../../../icons";
import { selectForgotPasswordState } from "../../../../redux/forgot_password/forgot_password_selector";
import { changePassword } from "../../../../redux/forgot_password/forgot_password_thunk";
import { useAppDispatch, useAppSelector, profileLogout } from "../../../../redux/store";
import { FormInput, CustomButton } from "../../../../utils";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import "./update_password.scss"

const UpdatePassword = ({ visible, onClose, onDismiss }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [password, setPassword] = useState({ password: '', confirmPassword: '' });
    const [isValid, setIsValid] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessPage, setShowSuccessPage] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const changePasswordState = useAppSelector((state) => selectForgotPasswordState(state));

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    function handleInput(id, value) {
        let newData = { ...password, [id]: value.trim() }
        setPassword(newData);
        if (newData.password && newData.confirmPassword && newData.password === newData.confirmPassword)
            setIsValid(true);
        else
            setIsValid(false);
    }

    function handleChangePassword() {
        dispatch(changePassword(password.password));
        setSentRequest(true);
    }


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        if (changePasswordState.changePassword.loading === LoadingType.succeeded && sentRequest) {
            setSentRequest(false);
            setSuccessMessage(changePasswordState.changePassword.response);
            setPassword({ ...password, password: '', confirmPassword: '' });
            setShowSuccessPage(true);
            setTimeout(() => (dispatch(profileLogout())), 2000);
        }
    }, [changePasswordState.changePassword.loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'35%'}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="mp-password-update-dialog-container">
                <div className="mp-password-update-dialog-header">
                    <h5>{t("update_password")}</h5>
                    <button style={{ "cursor": "pointer" }} onClick={() => closePopup(onClose)}>
                        <CloseIcon width={"2vw"} height={"2vw"} />
                    </button>
                </div>
                {showSuccessPage ?
                    <div className="mp-password-update-success">
                        <TickGreenIcon />
                        <h6>{successMessage}</h6>
                    </div> :
                    <><div className="mp-password-update-dialog-content">
                        <FormInput
                            id={"password"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={showPassword ? "text" : "password"}
                            value={password.password}
                            label={t("new_password")}
                            placeholder=""
                            onClick={handleTogglePasswordVisibility}
                            suffix={showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        />
                        <div style={password.password.length > 5 ? { color: "green" } : { color: "red" }}>{t("password_update_message")}</div>
                        <FormInput
                            id={"confirmPassword"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={showConfirmPassword ? "text" : "password"}
                            value={password.confirmPassword}
                            label={t("confirm_password")}
                            placeholder=""
                            onClick={handleToggleConfirmPasswordVisibility}
                            suffix={showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        />
                        <div className="mp-password-valid-message">{password.confirmPassword && !isValid ? t("password_not_match") : null}</div>
                    </div>
                        {sentRequest && changePasswordState.changePassword.error ?
                            <p style={{ color: THEME.red }}>{changePasswordState.changePassword.error?.response?.detail?.error}</p> : <></>}
                        <div className="mp-password-update-dialog-footer">
                            <div className="update-button">
                                <CustomButton
                                    textStyle={{ textTransform: 'capitalize' }}
                                    enable={isValid && password.password.length > 5}
                                    loading={changePasswordState.changePassword.loading === LoadingType.pending}
                                    name={t("update")}
                                    backgroundColor={THEME.buttonColor1}
                                    onClick={handleChangePassword}
                                />
                            </div>
                        </div></>}
            </div>
        </ActionDialogHolder>
    )
}

export default UpdatePassword;
