import { useTranslation } from 'react-i18next';
import { TALENT_DASHBOARD_STATUS, TALENT_STATUS, THEME } from '../../enums';
import { SectorIcon, LocationIcon, CallIcon, GlobeIcon, ActiveAssignmentsIcon, PlusWhiteIcon, MarkTalentIcon, PayrateIcon, EmailIcon, AssignIcon, LockOpenIcon, TextEmAllIcon, TalentIcon, ReloadIcon, CloseIcon } from '../../icons';
import { ITalent } from '../../interfaces';
import { addressParser, currencyConversion, CustomButton, getArrayStrings } from '../../utils';
import SocialMediaProfile from '../social_media_profile/social_media_profile';
import StatusBanner from '../status_banner/status_banner';
import './talent_details_section.scss';
import { LoadingType, ProfilePictureType } from "../../enums";
import UploadProfile from "../../components/image_crop/image_crop_popup";
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, getPreSignedURLForDownload, selectSingleProfilePictureStateById, getResumeFileUrlForDownload, selectSingleResumeFileStateById, selectResumeFileState } from "../../redux/store";
import { Portal } from "react-portal";
import { getDateString, getDaysAgoWithAMPM } from '../../variables';
import SSNHolder from '../ssn_holder/ssn_holder';
import ResumeHolder from '../resume_holder/resume_holder';
import UserAvatar from '../user_avatar/user_avatar';

interface Props {
    talent: ITalent | null,
    onBranchChange: (value: string) => void,
    onActionClick: (value: string) => void,
    onSSNClick: () => void,
    onResumeClick: (path: string) => void,
    // onShowRefreshPopup: Dispatch<SetStateAction<boolean>>;
    // availCredits: number | undefined,
}



const TalentDetailsSection: React.FunctionComponent<Props> = (props) => {
    const { talent, onBranchChange, onActionClick, 
        // onShowRefreshPopup,availCredits 
    } = props;
    const { t } = useTranslation();
    const talentProfileSections = [
        {
            sectionTitle: 'sector',
            sectionIcon: SectorIcon,
            direction: 'row',
            sectionContent: [
                { title: 'sector', valueField: 'business_sector_value' },
                { title: 'job_title', valueField: 'title' },
            ]
        },
        {
            sectionTitle: 'payrate',
            sectionIcon: PayrateIcon,
            direction: 'row',
            sectionContent: [
                { title: 'payrate', valueField: 'pay_rate' },
                { title: 'resume', valueField: getResumeIcon() }, ///resume_path
            ]
        },
        {
            sectionTitle: 'geo_code',
            sectionIcon: GlobeIcon,
            direction: 'row',
            sectionContent: [
                { title: 'geo_code', valueField: 'geo_code' },
                { title: 'ss_number', valueField: getSSNLockIcon() },
            ]
        },
        {
            sectionTitle: 'address',
            sectionIcon: LocationIcon,
            direction: 'column',
            sectionContent: [
                {
                    title: '', valueField: addressParser({
                        'street_address': talent ? talent['street_address'] : null,
                        'city': talent ? talent['city'] : null,
                        'state': talent ? talent['state'] : null,
                        'zip_code': talent ? talent['zip_code'] : null,
                    })
                },
            ]
        },

        {
            sectionTitle: 'contact',
            sectionIcon: CallIcon,
            direction: 'column',
            sectionContent: [
                { title: '', valueField: 'phone' },
            ]
        },
        {
            sectionTitle: 'email',
            sectionIcon: EmailIcon,
            direction: 'column',
            sectionContent: [
                { title: '', valueField: 'email' },
            ]
        },

        // {
        //     sectionTitle: 'website',
        //     sectionIcon: GlobeIcon,
        //     sectionContent: [
        //         { title: 'website', valueField: 'website' },
        //     ]
        // },
    ];

    function getSSNLockIcon() {
        if (talent?.last_4_digits_of_ssn == null || talent?.last_4_digits_of_ssn == "") return <span className="na-span">NA</span>
        return (
            <SSNHolder
                value={talent?.last_4_digits_of_ssn}
                iconColor={THEME.defaultHighLightColor}
                onClick={props.onSSNClick}
                showFormat={true}
            />
        )
    }

    function getResumeIcon() {
        if (talent?.resume_file_name == null || talent?.resume_file_name == "") return <span className="na-span">NA</span>
        if (talent && talent.resume_file_name) {
            return (
                <ResumeHolder
                    path={talent?.resume_file_name}
                    onClick={() => talent.resume_path != null && talent.resume_path != "" ? props.onResumeClick(talent.resume_path) : {}}
                />
            )
        }
        return '';
    }
    function talentStatus(status: string) {
        if (status === TALENT_DASHBOARD_STATUS.active) return (<span>{`${t('status')}: `} <span style={{ color: THEME.buttonColor17 }}>{t('active')}</span> </span>);
        else if (status === TALENT_DASHBOARD_STATUS.inactive) return (<span>{`${t('status')}: `}<span style={{ color: "#b1b1b1" }}>{t('in_active')}</span></span>);
        else if (status === TALENT_DASHBOARD_STATUS.terminated) return (<span>{`${t('status')}: `}<span style={{ color: "#ED4857" }}>{t('terminated')}</span></span>);
        else return (<span>{`${t('status')}: `}<span>{''}</span> </span>);
    }
    return (
        <div className="tds-container">
            <TalentInfo
                name={`${talent?.first_name ?? ""} ${talent?.middle_name ?? ""} ${talent?.last_name ?? ""}`}
                uri={talent?.contact_photo}
                objectId={talent?.id}
                rating={talent?.engagement_rating}
                level={talent?.engagement_level}
            />
            {talentActivity()}
            {/* <div className="tds-branch-selector"> */}
            {/* <CustomFormSelect
                    name={'department'}
                    value={branchId}
                    required={false}
                    placeholder={t('select_branch')}
                    list={departmentsState.data != null ? departmentsState.data.map((doc, index) => ({ label: doc.job_title, value: doc.id })) : []}
                    onChange={onBranchChange}
                /> */}

            <div className="tds-branch-selector">
                <div className="tds-status">
                    <div>
                        <span>
                            {`${t('status')}: `}
                            <span
                                style={{
                                    color: talent?.status === "Active"
                                        ? THEME.buttonColor17
                                        : talent?.status === "Inactive"
                                            ? "#b1b1b1"
                                            : "#ED4857",
                                            marginLeft: "0.3em"
                                }}
                            >
                                {talent?.status}
                            </span>
                        </span>
                    </div>
                    {talent?.status === "Terminated" && <div>
                        <span>
                            {`${t('termination_date')}: `}
                            <span>
                                {getDateString(talent?.termination_date)}
                            </span>
                        </span>
                    </div>}
                </div>
                {/* {talent && talent.imported_from_apollo &&
                    (<div>
                        <CustomButton
                            leftIcon={(<div className="err-h-add-icon">
                                <ReloadIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
                            </div>)}
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('refresh_talent')}
                            enable={(availCredits! > 2)}
                            backgroundColor={THEME.buttonColor15}
                            onClick={() => onShowRefreshPopup(true)}
                            customClassName={'refresh_talent'}
                        />
                        {availCredits! <= 2 && (
                            <div className='no-creds-div'>
                                <div className='no-creds'>{t('no_credits_available')}</div>
                                <div>
                                    <div onClick={() => onAddCredit(true)} className='add-creds-div'>
                                        {t("add_credits")}
                                    </div>
                                    
                                </div>
                            </div>
                        )}
                    </div>)} */}
            </div>
            {/* </div> */}
            {talentProfileSections.map((doc, index) => {
                return (
                    <div key={index} className={doc.sectionTitle === "contact" || doc.sectionTitle === "email" ? "tds-info-address-container" : "tds-info-container"}>
                        <div className="tds-info-icon">
                            <doc.sectionIcon width={"100%"} height={"100%"} />
                        </div>
                        <div
                            className="tds-info-content"
                            style={{
                                ...(doc.direction === 'row') && { flexDirection: 'row', justifyContent: 'space-between' },
                            }}
                        >
                            {doc.sectionContent.map((data, index) => {
                                const arrayValue = getValues(data.valueField);

                                return (
                                    <div
                                        key={index}
                                        className="tds-info-holder"
                                        style={{
                                            ...(doc.direction === 'row') && { flex: 1, width: "50%" },
                                            ...(index === 0) && { marginRight: '1em' }
                                        }
                                        }>
                                        <div className="tds-into-title">
                                            {
                                                doc.sectionTitle === "address" || doc.sectionTitle === "contact" || doc.sectionTitle === "email" ?
                                                    <span style={{ display: "grid" }}>{t(data.title).toUpperCase()}</span> :
                                                    <span>{t(data.title).toUpperCase()}</span>
                                            }
                                        </div>
                                        <div className="tds-info-value" style={{ marginBottom: '0.5em' }}>
                                            {
                                                doc.sectionTitle === 'email' ?
                                                    <div className="tds-info-value" style={{ marginBottom: '0.5em' }}>
                                                        <a href={`mailto:${talent?.email}`}><span>{arrayValue}</span></a>
                                                    </div>
                                                    : arrayValue
                                            }
                                        </div>
                                        {/* {data.lineTwo && <div className="tds-info-value">{getValues(data.lineTwo)}</div>} */}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            {(talent?.social_media_links != null && talent?.social_media_links != null) && <SocialMediaProfile
                title={t('social_media').toUpperCase()}
                profile={talent.social_media_links}
            />}
            <TalentActions onClick={onActionClick} disabled={talent?.status}/>
            {talent?.is_available && <div className="available-status">
                <StatusBanner title={t('available')} />
            </div>}
        </div>
    );

    function getValues(obj: any) {
        if (talent == null) return (<span>{''}</span>);
        if (Array.isArray(obj)) {
            return getArrayStrings(obj, talent);
        }
        const value = (obj !== '' && talent![obj] !== null) ? talent![obj] : '';
        if (obj === "pay_rate") {
            return (
                <span>{currencyConversion(value)}</span>
            );
        }
        if (typeof obj === "object") {
            return obj;
        }
        if (obj === "business_sector_value") {
            return (
                (talent.business_sector_value !== null && talent.business_sector_value !== "") ? <span>{talent.business_sector_value}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "title") {
            return (
                (talent.title !== null && talent.title !== "") ? <span>{talent.title}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "geo_code") {
            return (
                (talent.geo_code !== null && talent.geo_code !== "") ? <span>{talent.geo_code}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "phone") {
            return (
                (talent.phone !== null && talent.phone !== "") ? <span>{talent.phone}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "email") {
            return (
                (talent.email !== null && talent.email !== "") ? <span>{talent.email}</span> : <span className="na-span">NA</span>
            );
        }
        return (
            <span>{value}</span>
        );
    }


    function talentActivity() {
        const lastEngagementDate = talent?.last_engagement_date ? getDaysAgoWithAMPM(t, talent?.last_engagement_date) : null;
        return (
            <div className="tds-activity">
                {lastEngagementDate && <div className="last-engagement-txt">
                    <span>{t('last_engagement', { value: lastEngagementDate })}</span>
                </div>}
                {talent?.currently_on_assignment && <div className="assignment-status">
                    <div className="icon">
                        <AssignIcon width={'100%'} height={'100%'} style={{ color: THEME.statusActiveColor }} />
                    </div>
                    <div className="status">
                        <span style={{ color: THEME.statusActiveColor }} >{t('currently_on_assignment')}</span>
                    </div>

                </div>}
            </div>
        )
    }


}

const TalentInfo = (props) => {
    const { name, uri, rating, level } = props;
    const dispatch = useAppDispatch();
    const [showImageUpload, setShowImageUpload] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);
    const [imageURL, setImageURL] = useState('');

    const profilePictureState = useAppSelector((state) => selectSingleProfilePictureStateById(ProfilePictureType.talent + '-' + props.objectId)(state));

    useEffect(() => {
        setSentRequest(true);
        dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.talent }));
    }, [])

    function updateProfile() {
        dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.talent }));
        setSentRequest(true);
    }

    useEffect(() => {
        if (sentRequest && profilePictureState.download.loading === LoadingType.succeeded) {
            setSentRequest(false);
            setImageURL(profilePictureState.download.response ?? '');
        }
    }, [profilePictureState.download.loading])

    return (
        <div className="tds-user-info">
            <UserAvatar
                onClick={() => (setShowImageUpload(true))}
                url={imageURL}
                rating={{ value: rating, level: level }}
            />
            <div className="tds-user-name">
                <span>{name}</span>
            </div>
            {showImageUpload && <Portal>
                <UploadProfile
                    onClose={() => setShowImageUpload(false)}
                    onDismiss={() => setShowImageUpload(false)}
                    visible={showImageUpload}
                    onApprove={updateProfile}
                    objectId={props.objectId}
                    objectType={ProfilePictureType.talent}
                />
            </Portal>}
        </div>
    );
}


const buttonsList = [
    { title: 'assign_to_job_order', icon: PlusWhiteIcon, type: 'assign_to_job_order', color: THEME.defaultHighLightColor },
    { title: 'access_assignments', icon: ActiveAssignmentsIcon, type: 'active_assignments', color: THEME.buttonColor17 },
    { title: 'mark_talent_available', icon: MarkTalentIcon, type: 'mark_talent_available', color: THEME.buttonColor16 },
    { title: 'send_message_to_talent', icon: TextEmAllIcon, type: 'send_message_to_talent', color: '#ff4d00' },
    { title: 'terminate_talent', icon: CloseIcon, type: 'terminate_talent', color: "#ED4857" },
];

interface TalentActionsProps {
    onClick: (type: string) => void
    disabled: string | undefined;
}



const TalentActions: React.FunctionComponent<TalentActionsProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="tds-action-container">
            {buttonsList.map((doc, index) => {
                return (
                    <div key={index} className="tds-action">
                        <CustomButton
                            customStyle={{ width: '-webkit-fill-available' }}
                            leftIcon={(<div className="tds-action-icon"><span style={{ color: "#fff" }}><doc.icon width={"100%"} height={"100%"} /></span></div>)}
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t(doc.title)}
                            enable={(props.disabled === "Terminated" && doc.type === "terminate_talent") ? false : true}
                            backgroundColor={doc.color}
                            onClick={() => props.onClick(doc.type)}
                            customClassName={doc.title}
                        />
                    </div>

                )
            })}

        </div>
    );
}


export default TalentDetailsSection;