import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import * as thunk from "./payroll_tax_configuration_thunk";
import { PayRollTaxConfiguration } from "../../../interfaces/payroll_tax_configuration";
import { AppError } from "../../../interfaces";
import { LoadingType } from "../../../enums";

export interface PayRollTaxConfigListState extends EntityState<PayRollTaxConfiguration> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string,  
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string,  
    },
    delete: {
        error?: AppError | null,
        loading: LoadingType,
        response: string,  
    }
}

const PayRollTaxConfigListAdapter: EntityAdapter<PayRollTaxConfiguration> = createEntityAdapter<PayRollTaxConfiguration>({
    selectId: (doc) => doc.id,
})

const initialState: PayRollTaxConfigListState = PayRollTaxConfigListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: "",  
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: "",  
    },
    delete: {
        error: null,
        loading: LoadingType.idle,
        response: "",  
    }
})


export const PayRollTaxConfigListSlice = createSlice({
    name: 'payRollTaxConfigList',
    initialState: initialState,
    reducers: {
        clearPayRollTaxConfigListError(state){
            return {...state, error: null};
        },
        clearPayRollTaxConfigListState(state){
            return PayRollTaxConfigListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearPayrollTaxCreateState(state) {
            return { ...state, create : {...initialState.create }};
            // state.create = {...initialState.create }
        },
        clearPayrollTaxUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearPayrollTaxDeleteState(state) {
            return { ...state, delete : {...initialState.delete} };
        },
        clearPayrollTaxCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearPayrollTaxUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearPayrollTaxDeleteStateError(state) {
            return { ...state, delete : { ...initialState.delete , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPayRollTaxConfigList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getPayRollTaxConfigList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getPayRollTaxConfigList.fulfilled, (state, action) => PayRollTaxConfigListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addCase(thunk.createPayRollTaxConfig.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.createPayRollTaxConfig.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.createPayRollTaxConfig.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addCase(thunk.updatePayRollTaxConfig.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.updatePayRollTaxConfig.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.updatePayRollTaxConfig.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addCase(thunk.deletePayrollTaxConfig.pending, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deletePayrollTaxConfig.rejected, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deletePayrollTaxConfig.fulfilled, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearPayRollTaxConfigListError, clearPayRollTaxConfigListState, clearPayrollTaxCreateState, clearPayrollTaxUpdateState, clearPayrollTaxCreateStateError, clearPayrollTaxUpdateStateError, clearPayrollTaxDeleteStateError, clearPayrollTaxDeleteState } = PayRollTaxConfigListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = PayRollTaxConfigListAdapter.getSelectors();
export const selectState = (state: PayRollTaxConfigListState) => state;
export const selectLoading = (state: PayRollTaxConfigListState) => state.loading;
export const selectError = (state: PayRollTaxConfigListState) => state.error;


