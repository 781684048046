import RenderInput from "../../../../../components/render_input/render_input";
import { FormInputOption, ICreateJobForm, ICompanyCertification, ICompanyCredential, ICompanyEducationRequirement, ICompanyRequiredEquipment, ICompanySkillSet, ICreateJob, IDepartmentsList } from "../../../../../interfaces";
import './step_seven.scss';

interface Props {
    skillSetList: Array<ICompanySkillSet>,
    credentialsList: Array<ICompanyCredential>,
    certificationsList: Array<ICompanyCertification>,
    educationList: Array<ICompanyEducationRequirement>,
    requiredEquipmentList: Array<ICompanyRequiredEquipment>,
    formState: ICreateJobForm,
    handleFieldChange: (id: string, value: any, parent?: string | undefined) => void,
    validators: ((value: {
        text: string;
        field: string;
    }) => string | null) | undefined,
}

const StepSevenForm: React.FunctionComponent<Props> = (props) => {
    const { formState, handleFieldChange, validators } = props;

    const formOptions: (FormInputOption | FormInputOption[])[] = [
        {
            field: "professional_skills",
            type: "selector",
            label: 'select_the_professional_skills_for_this_job',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: props.skillSetList.map(doc => ({ label: doc.skill_set, value: doc.id })),
            multi: true,
        },
        {
            field: "certifications",
            type: "selector",
            label: 'are_there_any_certifications_required_for_this_job_if_yes_select_them_here',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: props.certificationsList.map(doc => ({ label: doc.certification, value: doc.id })),
            multi: true,
        },
        {
            field: "education",
            type: "selector",
            label: 'any_education_requirements_if_so_go_ahead_and_select_them',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: props.educationList.map(doc => ({ label: doc.education_requirement, value: doc.id })),
            multi: true,
        },
        {
            field: "credentials",
            type: "selector",
            label: 'select_any_credentialing_required',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: props.credentialsList.map(doc => ({ label: doc.credential, value: doc.id })),
            multi: true,
        },
        {
            field: "safety_equipment",
            type: "selector",
            label: 'what_about_safety_equipment_does_the_person_working_in_this_role_need_to_provide_any_if_so_select_them_here',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: props.requiredEquipmentList.map(doc => ({ label: doc.required_equipment, value: doc.id })),
            multi: true,
        },
    ]

    return (
        <div className="jb-f-stp-seven">
            {formOptions.map((doc, index) => {
                if (Array.isArray(doc)) {
                    return (
                        <div key={index + 'row'} className="jb-f-row">
                            {doc.map((subDoc, subIndex) => {
                                return (
                                    <RenderInput
                                        key={index + subIndex}
                                        doc={subDoc}
                                        index={subIndex}
                                        formState={formState}
                                        handleFieldChange={handleFieldChange}
                                        formValidators={validators}
                                        className={"jb-f-input-holder"}
                                        menuPlacement={"top"}
                                    />
                                );
                            })}
                        </div>
                    );
                }

                return (
                    <RenderInput
                        key={index}
                        doc={doc}
                        index={index}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        formValidators={validators}
                        className={"jb-f-input-holder"}
                        menuPlacement="top"
                    />
                )
            })}
        </div>
    )
}

export default StepSevenForm;