import { combineReducers } from "@reduxjs/toolkit";
import { billingInvoicesErrorReportListSlice } from "./errors_report/errors_report_reducer";
import { BillingTimecardsSlice } from "./billing_timecards/billing_timecards_reducer";
import { BillingInvoiceReportSlice } from "./billing_invoices_report_list/billing_invoices_report_list_reducer";
import { billingBatchListSlice } from "./billing_invoices_list/billing_invoices_list_reducer";
import { billingBatchSlice } from "./manage_billing_batch/manage_billing_batch_reducer";
import { invoiceCorrectionSlice } from "./invoice_correction/invoice_correction_reducer";

export const backOfficeBillingReducers = combineReducers({
  [BillingTimecardsSlice.name]: BillingTimecardsSlice.reducer,
  [BillingInvoiceReportSlice.name]: BillingInvoiceReportSlice.reducer,
  [billingInvoicesErrorReportListSlice.name]: billingInvoicesErrorReportListSlice.reducer,
  [billingBatchSlice.name]:billingBatchSlice.reducer,
  [billingBatchListSlice.name]: billingBatchListSlice.reducer,
  [invoiceCorrectionSlice.name]: invoiceCorrectionSlice.reducer

});

export * from "./billing_timecards/billing_timecards_thunk";
export * from "./billing_timecards/billing_timecards_selector";

export * from "./billing_invoices_report_list/billing_invoices_report_list_thunk";
export * from "./billing_invoices_report_list/billing_invoices_report_list_selector";

export * from "./errors_report/errors_report_thunk";
export * from "./errors_report/errors_report_selector";



export * from "./billing_invoices_list/billing_invoices_list_thunk";
export * from "./billing_invoices_list/billing_invoices_list_selector";

export * from "./manage_billing_batch/manage_billing_batch_thunk";
export * from "./manage_billing_batch/manage_billing_batch_selector";

export * from "./invoice_correction/invoice_correction_thunk";
export * from "./invoice_correction/invoice_correction_selector";

