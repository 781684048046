import { createSelector } from "@reduxjs/toolkit";
import { selectState, selectWAEReportDownloadUrl } from "./error_report_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.backOffice.weeklyAErrorReportList;
export const selectWAErrorsReportListState = createSelector(
    select,
    selectState
);

export const selectWAERDownloadUrlState = createSelector(
    select,
    selectWAEReportDownloadUrl
);
