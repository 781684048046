import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import {AppError} from "../../../../../interfaces/app_error";
import { IJobOrderContact  } from "../../../../../entities/ContactInfo";
import * as thunk from './job_order_contact_list_thunk';


export interface SingleJobOrderContactState {
    data: Array<IJobOrderContact> | null,
    error?: AppError | null,
    loading: LoadingType
}


export interface JobOrderContactsState {
    [key: string]: SingleJobOrderContactState,
}


const initialState: JobOrderContactsState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

export const jobOrderContactSlice = createSlice({
    name: 'contactList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getContactsByJobOrder.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.jobOrderId]: {
                        ...getLoadingState(state[action.meta.arg.jobOrderId])
                    }
                })
            )
            .addCase(
                thunk.getContactsByJobOrder.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.jobOrderId]: {
                        ...getErrorState(state[action.meta.arg.jobOrderId], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getContactsByJobOrder.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.jobOrderId]: {
                        ...getSuccessState(state[action.meta.arg.jobOrderId], action.payload),
                    }
                })
            )
            
            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const {} = jobOrderContactSlice.actions;
export const selectJobOrderContactsState = (state: JobOrderContactsState) => state;
export const selectSingleJobOrderContactssState = (state: JobOrderContactsState, id: string): SingleJobOrderContactState => state[id];

export const selectInitialSingleJobOrderContactsState = (): SingleJobOrderContactState => ({
    data: null,
    error: null,
    loading: LoadingType.idle
});