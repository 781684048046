import { useTranslation } from 'react-i18next';
import ApiError from '../../../../components/api_error';
import { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { THEME } from '../../../../enums';
import { ChatIcon, MarkTalentIcon } from '../../../../icons';
import { AppError } from '../../../../interfaces';
import { CustomButton } from '../../../../utils';
import SearchTalent from '../../components/search_talent/search_talent';
import './select_talent_form.scss';

interface Props {
    title: string,
    talent: {id: string, name: string} | null,
    loading: boolean,
    error?: AppError | null,
    onNext: () => void,
    onChange: (value: {id: string, name: string} | null) => void,
    onCancel: () => void,
    onClearError: () => void,
}

const SelectTalentForm: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="dshb-mrk-tlnt-f-container">
            <div className="dshb-mrk-tlnt-f-header">
                <FormDialogTilteHeader
                    title={props.title}
                    titleIcon={<MarkTalentIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                />
            </div>
            <div className="dshb-mrk-tlnt-f-content">
                <div className="dshb-mrk-tlnt-f-holder">
                    <div className="title-txt">
                        <span>{t('search_talent')}</span>
                    </div>
                    <div className="table-holder">
                        <SearchTalent
                            selected={props.talent}
                            onChange={props.onChange}
                        />
                    </div>
                </div>
                {props.error && <div className={"error-section"}>
                    <ApiError message={props.error.message} onClose={props.onClearError} />
                </div>}
            </div>
            <div className="dshb-mrk-tlnt-f-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('back_to_my_agency_dashboard')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={props.onCancel}
                    />
                </div>

                <div className="btn-save">
                    <CustomButton
                        loading={props.loading}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('next')}
                        enable={_isFormValid()}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={props.onNext}
                    />
                </div>
            </div>
        </div>
    );

    function _isFormValid() {
        if (props.talent != null && props.talent.id != "") {
            return true;
        }
        return false;
    }
}

export default SelectTalentForm;