import { RouteComponentProps } from "react-router-dom";
import { postSnackbarMessage, selectProfileState, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingType, THEME } from "../../../../enums";
import TabOptionsHeader from "../../../../components/tab_options_header/tab_options_header";
import SearchBar from "../../../../components/search_bar";
import { CustomButton, SpinnerScreen, getPlusBtnIcon, getTableAccessIcon } from "../../../../utils";
import TableEmpty, { TableErrorHandler, TableFilterEmpty, TableLoading } from "../../../../components/table_empty/table_empty";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { Profile } from "../../../../interfaces";
import { AppRoutes } from "../../../../routes";
import { getAdminCenterAgencyManagementAgencies, selectAgenciesList, selectAgenciesListState } from "../../../../redux/admin_center";
import AddAgency from "../../popups/add_agency/add_agency";
import { Portal } from "react-portal";
import { manageAgencyActions } from "../../../../redux/admin_center/agency_management/manage_agency/manage_agency_reducer";
import { IAgencies } from "../../../../interfaces/agency_management";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import ApiError from "../../../../components/api_error";
import { selectAgencyStatusState } from "../../../../redux/admin_center/agency_management/agency_toggle_status/agency_toggle_status_selector";
import { updateAgencyToggleStatus } from "../../../../redux/admin_center/agency_management/agency_toggle_status/agency_toggle_status_thunk";
import { clearAgencyToggleStatusStateError } from "../../../../redux/admin_center/agency_management/agency_toggle_status/agency_toggle_status_reducer";


interface Props extends RouteComponentProps<any> { }

const tabOptions = [
    {
        title: "agency_directory",
    }
];

const tableHeader = [
    { title: 'access_user', code: 'access_user' },
    { title: 'name', code: 'name' },
    { title: 'phone', code: 'phone' },
    // { title: 'fax', code: 'fax' },
    // { title: 'fein', code: 'fein' },
    { title: 'domain', code: 'domain' },
    { title: 'street_address', code: 'street_address' },
    { title: 'city', code: 'city' },
    { title: 'state', code: 'state' },
    { title: 'zip_code', code: 'zip_code' },
    { title: 'status', code: '' },
];

const AdminCenterAgencyManagementPage: React.FunctionComponent<Props> = (props: Props) => {
    const dispatch = useAppDispatch();
    const { profile } = useAppSelector((state) => selectProfileState(state));
    const { loading, error, } = useAppSelector((state) => selectAgenciesListState(state));
    const agenciesList = useAppSelector((state) => selectAgenciesList(state));
    const agencyToggleState = useAppSelector((state) => selectAgencyStatusState(state));
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const { t } = useTranslation();
    const [addAgency, setAddAgency] = useState<boolean>(false);
    const [selectedAgency, setSelectedAgency] = useState<string>('');


    useEffect(() => {
        getAgencies();
        return () => {
        }
    }, [])

    useEffect(() => {
        if (agencyToggleState.loading === LoadingType.succeeded) {
            getAgencies();
        }
    }, [agencyToggleState.loading])

    useEffect(() => {
        if (error != null && agenciesList.length != 0) {
            dispatch(postSnackbarMessage(error.message));
        }
        return () => {
        }
    }, [error])

    function getAgencies() {
        dispatch(getAdminCenterAgencyManagementAgencies());
    }


    function handleCloseAgency() {
        setAddAgency(false)
        dispatch(manageAgencyActions.clearCreateState());
    }
    function handleDissmissAgency() {
        setAddAgency(false)
        dispatch(manageAgencyActions.clearCreateState());
    }

    ///Handle clear error
    function handleClearError() {
        dispatch(manageAgencyActions.clearCreateState());
    }

    ///Handle clear toggle error
    function handleClearToggleError() {
        dispatch(clearAgencyToggleStatusStateError());
    }

    const handleToggleChange = (id, value, domain) => {
        setSelectedAgency(id)
        dispatch(updateAgencyToggleStatus({ domain: domain, enable: value }))
    }


    function getFilteredList() {
        let list;
        if (sortedField != null) {
            list = [...agenciesList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (list ?? agenciesList).filter(doc => {
            const str = search.trim().toLowerCase();
            const nameFilter = doc.name?.trim().toLowerCase().includes(str);
            const phoneFilter = doc?.phone?.trim().toLowerCase().includes(str);
            // const faxFilter = doc?.fax?.trim().toLowerCase().includes(str);
            // const feinFilter = doc?.fein?.trim().toLowerCase().includes(str);
            const domainFilter = doc?.domain?.trim().toLowerCase().includes(str);
            const streetAddressFilter = doc?.street_address?.trim().toLowerCase().includes(str);
            const cityFilter = doc?.city?.trim().toLowerCase().includes(str);
            const stateFilter = doc?.state?.trim().toLowerCase().includes(str);
            const zipCodeFilter = doc?.zip_code?.trim().toLowerCase().includes(str);
            return nameFilter || phoneFilter || domainFilter || streetAddressFilter || cityFilter || stateFilter || zipCodeFilter;
        });
    }



    return (
        <div className="ac-um-container">
            <div className="ac-um-header">
                <TabOptionsHeader list={tabOptions} onClose={() => props.history.goBack()} />
            </div>
            <div className="ac-um-content">
                <div className="ac-um-table-holder">
                    <div className="ac-um-table-header">
                        <div className="ac-um-table-search-header">
                            <SearchBar value={search} onChange={handleChange} onSearch={() => { }} />
                            <div className="total-count">
                                <span>{`${t('total_count')}: `} <span className="total-count-number">{agenciesList.length}</span> </span>
                            </div>
                        </div>

                        <div className="ac-um-actions">
                            <div className="ac-um-add-user-btn">
                                <CustomButton
                                    leftIcon={getPlusBtnIcon()}
                                    loading={false}
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={t('add_agency')}
                                    enable={true}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => setAddAgency(true)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ac-um-table">
                        {getAgenciesTableList()}
                    </div>
                </div>
            </div>


            {addAgency && profile && profile.branch_id && <Portal>
                <AddAgency
                    visible={addAgency}
                    onClose={handleCloseAgency}
                    onDissmiss={handleDissmissAgency}
                    onSuccessClose={handleAddUserSuccess}
                    onClearError={handleClearError}
                />
            </Portal>}
        </div>

    );

    /// Agencies table list
    function getAgenciesTableList() {
        if (loading === LoadingType.pending) {
            return (<TableLoading />);
        }
        if (error != null && agenciesList.length === 0) {
            return (
                <TableErrorHandler error={error} onRefresh={getAgencies} />
            );
        }
        if (agenciesList.length === 0) {
            return (
                <TableEmpty title={t('no_agencies_found')} onClick={() => getAgencies()} />
            );
        }
        if (getFilteredList().length === 0) {
            return (
                <TableFilterEmpty title={t('no_agencies_found')} />
            );
        }

        return (
            <SortableTable
                headerList={tableHeader}
                sortedField={sortedField}
                onSortChange={handleSortFieldChange}
                flexNumber={getFlexNumber}
                isAsc={sortDirection}
            >
                {getFilteredList().map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleUserSelect(doc))}</TableData>
                            <TableData customStyle={{ flex: getFlexNumber(1) }} isButton={true} onClick={() => handleUserSelect(doc)}><span>{doc?.name}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc?.phone ?? ''}</span></TableData>
                            {/* <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{doc?.fax ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc?.fein ?? ''}</span></TableData> */}
                            <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{doc?.domain ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc?.street_address ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(5) }}><span>{doc?.city ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(6) }}><span>{doc?.state ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(7) }}><span>{doc?.zip_code ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                <span>
                                    {
                                        <ToggleSwitch
                                            label={doc.disabled ? "Disabled" : "Enabled"}
                                            checked={!(doc.disabled)}
                                            onChange={(value) => handleToggleChange(doc.id, value, doc.domain)}
                                        />

                                    }
                                </span>
                                <span className='toggle-spinner'>
                                    {doc.id === selectedAgency && agencyToggleState.loading === LoadingType.pending && <SpinnerScreen size={15} />}
                                    {doc.id === selectedAgency &&
                                        agencyToggleState.error && <div className="error-holder">
                                            <ApiError message={agencyToggleState.error.message} onClose={handleClearToggleError} />
                                        </div>
                                    }
                                </span>
                            </TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 0.5;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 2;
        if (value === 4) return 1.5;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1.;
        if (value === 9) return 1.5;
        if (value === 10) return 1;
        if (value === 11) return 1;
        return 1;
    }

    function handleChange(value: string) {
        setSearch(value);
    }

    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }

    function handleUserSelect(value: IAgencies) {
        props.history.push({
            pathname: `${AppRoutes.adminCenterAgencyManagement}/${value.id}`,
            state: {
                id: value.id,
                name: value.name,
            }
        })
    }

    ///Add agency action
    function handleAddUserSuccess() {
        setAddAgency(false);
        getAgencies();
        dispatch(manageAgencyActions.clearCreateState());
    }
}


export default AdminCenterAgencyManagementPage;