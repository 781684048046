import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as thunk from './back_office_invoices_and_docs_thunk';
import { AppError, ICompanyBackOfficeInvoiceDocs } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

interface UpdateState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialUpdateState: UpdateState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

export interface SingleCompanyBOInvoiceDocsState {
    data: ICompanyBackOfficeInvoiceDocs | null,
    error?: AppError | null,
    loading: LoadingType,
    update: UpdateState
};

export interface CompanyBOInvoiceDocsState {
    [key: string]: SingleCompanyBOInvoiceDocsState,
}

const initialState: CompanyBOInvoiceDocsState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}
function getResponseSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}
export const companyBOInvoiceDocsSlice = createSlice({
    name: 'companyBackOfficeInvoiceDocs',
    initialState: initialState,
    reducers: {
        clearUpdateBackOfficeInvoiceDocsError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    update: { ...initialUpdateState },
                }
            };
        },
        initializeState(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    loading: LoadingType.idle,
                    error: null,
                    update: { ...initialUpdateState },
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getCompanyBackOfficeInvoiceDocsThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getLoadingState(state[action.meta.arg])
                    }
                })
            )
            .addCase(
                thunk.getCompanyBackOfficeInvoiceDocsThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getErrorState(state[action.meta.arg], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getCompanyBackOfficeInvoiceDocsThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getSuccessState(state[action.meta.arg], action.payload),
                    }
                })
            )

            // Updatae Backoffice InvoiceDocs values
            .addCase(
                thunk.updateCompanyBackOfficeInvoiceDocsThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.data.company_id]: {
                        ...state[action.meta.arg.data.company_id],
                        update: {
                            ...getLoadingState(state[action.meta.arg.data.company_id].update),
                        },
                    }
                })
            )
            .addCase(
                thunk.updateCompanyBackOfficeInvoiceDocsThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.data.company_id]: {
                        ...state[action.meta.arg.data.company_id],
                        update: {
                            ...getErrorState(state[action.meta.arg.data.company_id].update, action.payload),
                        },
                    }
                })
            )
            .addCase(
                thunk.updateCompanyBackOfficeInvoiceDocsThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.data.company_id]: {
                        ...state[action.meta.arg.data.company_id],
                        update: {
                            ...getResponseSuccessState(state[action.meta.arg.data.company_id].update, action.payload),
                        },
                    }
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const companyBOInvoiceDocsSliceActions = companyBOInvoiceDocsSlice.actions;
export const selectState = (state: CompanyBOInvoiceDocsState) => state;
export const selectSingleCompanyBOInvoiceDocsState = (state: CompanyBOInvoiceDocsState, id: string): SingleCompanyBOInvoiceDocsState => state[id];
export const selectInitialSingleCompanyBOInvoiceDocsState = (): SingleCompanyBOInvoiceDocsState => ({
    data: null,
    error: null,
    loading: LoadingType.idle,
    update: initialUpdateState
});