import { RouteComponentProps } from "react-router-dom";
import './payroll_cash_req.scss';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectPayrollCashReqState, getPayrollCashThunk, selectReportStartEndDateState, getReportStartEndDate, selectReportsDownloadState, getReportDownloadURL } from "../../../../../redux/report_center";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { convertDateToTimeStamp, dateType, getDateFromTimeStamp } from "../../../../../variables";
import { IPayrollCashReq } from "../../../../../interfaces/report_center";
import { payrollCashSliceActions } from "../../../../../redux/report_center/payroll_tax/payroll_cash_req/payroll_cash_req_reducer";
import { LoadingType, THEME } from "../../../../../enums";
import DatePicker from "react-datepicker";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import { currencyConversion, CustomButton, SpinnerScreen } from "../../../../../utils";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import ApiError from "../../../../../components/api_error";
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import UrlBuilder from "../../../../../apis/url_builder";

interface Props extends RouteComponentProps<any> { }
const PayrollCashRequiredPage: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const paycheckRegisterState = useAppSelector((state) => selectPayrollCashReqState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));

    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>()
    const [payrollCashList, setPayrollCashList] = useState<IPayrollCashReq | null>(null)

    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [isCheckDate, setIsCheckDate] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0)
            || ((formState?.available_from !== 0 && formState?.available_to !== 0) || (formState?.available_from === 0 && formState?.available_to === 0) && isCheckDate)) {
            getPayrollCashData();
            setDatePickerOpen(false);
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getPayrollCashData();
            setDatePickerOpen(true);
        }
    }, [formState?.available_from, formState?.available_to, isCheckDate]);

    useEffect(() => {
        if (paycheckRegisterState?.error && paycheckRegisterState.error?.message) {
            setPayrollCashList({} as IPayrollCashReq);
        }
    }, [paycheckRegisterState?.error?.message]);

    useEffect(() => {
        if (paycheckRegisterState.loading === LoadingType.succeeded && paycheckRegisterState.response) {
            setPayrollCashList(paycheckRegisterState.response);
        }
    }, [paycheckRegisterState.loading, paycheckRegisterState.response]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    const getPayrollCashData = () => {
        if (formState) {
            dispatch(getPayrollCashThunk({ start_date: formState?.available_from!, end_date: formState?.available_to!, is_check_date: isCheckDate! }));
        }
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.payrollCashRequired,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    is_check_date: isCheckDate,
                    download: true
                }));
        }
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
        dispatch(payrollCashSliceActions.clearPRCState());
    }

    const handleClearError = () => {
        dispatch(payrollCashSliceActions.clearPRCErrorState())
    }

    const empEarnings = payrollCashList?.employee_summary?.employee_earnings ?? {};
    const adjustments = payrollCashList?.employee_summary?.adjustments ?? {};
    const deductions = payrollCashList?.employee_summary?.deductions ?? {};
    const taxLiability = payrollCashList?.employee_summary?.tax_liability ?? {};
    const netPay = payrollCashList?.employee_summary?.net_pay ?? {};

    const empContributions = payrollCashList?.employer_expenses?.employer_contributions ?? {};
    const workerContributions = payrollCashList?.employer_expenses?.workers_compensation ?? {};
    const empTaxes = payrollCashList?.employer_expenses?.employer_taxes ?? {};

    const payrollSumary = payrollCashList?.total_cash_requirements?.payroll_summary ?? {};
    const allTaxSummary = payrollCashList?.total_cash_requirements?.all_tax_summary ?? {};
    return (
        <div className="pr-c-container">
            <div className="header-container">
                <div className="table-header">
                    <span>{t("payroll_cash_required")}</span>
                </div>
                <div>
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="pr-c-header">
                <div className="pr-c-header-left">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                            />
                        </div>
                    </div>
                    <div className="date-type">
                        <CustomFormSelect
                            name={t("date_type")}
                            list={dateType.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={setIsCheckDate}
                            value={isCheckDate}
                            placeholder={t('select')}
                            required={false}
                            label={t('date_type')}
                        />
                    </div>
                    <div className="download-btn">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={(Object.keys(payrollCashList?.employee_summary ?? {}).length > 0)
                                && (Object.keys(payrollCashList?.employer_expenses ?? {}).length > 0) &&
                                (Object.keys(payrollCashList?.total_cash_requirements ?? {}).length > 0)}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    {paycheckRegisterState?.error && <div className="error-holder">
                        <ApiError message={paycheckRegisterState?.error?.message} onClose={handleClearError} />
                    </div>}
                </div>
            </div>
            <div className="pr-c-content">
                {paycheckRegisterState.loading === LoadingType.pending ?
                    <SpinnerScreen />
                    :
                    (<div className="pr-c-div-row">
                        <div className="pr-c-div">
                            <div className="pr-c-div-title">{t('employee_summary')}</div>
                            <div className="emp-earn">
                                <table>
                                    <thead>
                                        <th>{t("employee_earnings")}</th>
                                        <th>{t("amount")}</th>
                                    </thead>
                                    <tbody>
                                        {Object.keys(empEarnings).map(key => (
                                            <tr key={key}>
                                                <td>{key}:</td>
                                                <td>{currencyConversion(empEarnings[key])}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td>{t('total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_employee_earnings ?? 0)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="adjustments">
                                <table>
                                    <thead>
                                        <th>{t("adjustments")}</th>
                                        <th>{t("amount")}</th>
                                    </thead>
                                    <tbody>
                                        {Object.keys(adjustments).map(key => (
                                            <tr key={key}>
                                                <td>{key}:</td>
                                                <td>{currencyConversion(adjustments[key])}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td>{t('total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_adjustments ?? 0)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="deductions">
                                <table>
                                    <thead>
                                        <th>{t("deductions")}</th>
                                        <th>{t("amount")}</th>
                                    </thead>
                                    <tbody>
                                        {Object.keys(deductions).map(key => (
                                            <tr key={key}>
                                                <td>{key}:</td>
                                                <td>{currencyConversion(deductions[key])}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td>{t('total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_deductions ?? 0)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tax-liable">
                                <table>
                                    <thead>
                                        <th>{t("tax_liability")}</th>
                                        <th>{t("amount")}</th>
                                    </thead>
                                    <tbody>
                                        {Object.keys(taxLiability).map(key => (
                                            <tr key={key}>
                                                <td>{key}:</td>
                                                <td>{currencyConversion(taxLiability[key])}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td>{t('total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_tax_amount ?? 0)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="net-pay">
                                <table>
                                    <thead>
                                        <th>{t("net_pay")}</th>
                                        <th>{t("amount")}</th>
                                    </thead>
                                    <tbody>
                                        {Object.keys(netPay).map(key => (
                                            <tr key={key}>
                                                <td>{key}:</td>
                                                <td>{currencyConversion(netPay[key])}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td>{t('total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_net_pay ?? 0)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="pr-c-div">
                            <div className="pr-c-div-title">{t('employer_expenses')}</div>
                            <div className="emp-earn">
                                <table>
                                    <thead>
                                        <th>{t("employer_contributions")}</th>
                                        <th>{t("amount")}</th>
                                    </thead>
                                    <tbody>
                                        {Object.keys(empContributions).map(key => (
                                            <tr key={key}>
                                                <td>{key}:</td>
                                                <td>{currencyConversion(empContributions[key])}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td>{t('total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_employer_contribution ?? 0)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="emp-earn">
                                <table>
                                    <thead>
                                        <th>{t("workers_comp_pre")}</th>
                                        <th>{t("amount")}</th>
                                    </thead>
                                    <tbody>
                                        {Object.keys(workerContributions).map(key => (
                                            <tr key={key}>
                                                <td>{key}:</td>
                                                <td>{currencyConversion(workerContributions[key])}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td>{t('total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_workers_compensation ?? 0)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="emp-earn">
                                <table>
                                    <thead>
                                        <th>{t("employer_taxes")}</th>
                                        <th>{t("amount")}</th>
                                    </thead>
                                    <tbody>
                                        {Object.keys(empTaxes).map(key => (
                                            <tr key={key}>
                                                <td>{key}:</td>
                                                <td>{currencyConversion(empTaxes[key])}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td>{t('total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_employer_tax_amount ?? 0)}</td>
                                        </tr>
                                        <tr className="total-row">
                                            <td>{t('grand_total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_employer_expenses ?? 0)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="pr-c-div">
                            <div className="pr-c-div-title">{t('total_cash_requirements')}</div>
                            <div className="emp-earn">
                                <table>
                                    <thead>
                                        <th>{t("payroll_summary")}</th>
                                        <th>{t("amount")}</th>
                                    </thead>
                                    <tbody>
                                        {Object.keys(payrollSumary).map(key => (
                                            <tr key={key}>
                                                <td>{key}:</td>
                                                <td>{currencyConversion(payrollSumary[key])}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td>{t('total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_payroll_summary ?? 0)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="emp-earn">
                                <table>
                                    <thead>
                                        <th>{t("941_summary")}</th>
                                        <th>{t("amount")}</th>
                                    </thead>
                                    <tbody>
                                        {Object.keys(allTaxSummary).map(key => (
                                            <tr key={key}>
                                                <td>{key}:</td>
                                                <td>{currencyConversion(allTaxSummary[key])}</td>
                                            </tr>
                                        ))}
                                        <tr className="total-row">
                                            <td>{t('total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_tax_summary_amount ?? 0)}</td>
                                        </tr>
                                        <tr className="total-row">
                                            <td>{t('grand_total')}</td>
                                            <td>{currencyConversion(payrollCashList?.total_cash_requirement_amount ?? 0)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>)
                }

            </div>
        </div>
    );
}
export default PayrollCashRequiredPage;