import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI } from "../../../../apis";
import { S3API } from "../../../../apis/s3_api";
import { AppError, ICompany, ICreateCompany, ICreateResponse, IProfilePictureRequestQuery, IProfilePictureUploadUrlResponse } from "../../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../../utils";
import { getCompaniesList } from "../companies_list/companies_list_thunk";

/// Create user
export const createCompany = createAsyncThunk<
    ICreateResponse,
    { forceAdd: boolean, value: ICreateCompany },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/manage_company/create',
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.createCompany(payload)
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data as ICreateResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Update user
export const updateCompany = createAsyncThunk<
    ICreateResponse,
    ICompany,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/manage_company/update',
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.updateCompany(payload);
            thunkAPI.dispatch(getCompaniesList());
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data as ICreateResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


/// Files upload
export const getCompanyLogoUploadURLThunk = createAsyncThunk<
    IProfilePictureUploadUrlResponse,
    IProfilePictureRequestQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/manage_company/company-logo-upload-url/get',
    async (query, thunkAPI) => {
        try {
            const res = await S3API.getPreSignedURLForUpload(query)
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data as IProfilePictureUploadUrlResponse
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const uploadCompanyLogoThunk = createAsyncThunk<
    any,
    { data: IProfilePictureUploadUrlResponse, photo: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/manage_company/company-logo-upload-url/post',
    async (payload, thunkAPI) => {
        try {
            if (payload.photo && payload.data.url) {
                const photoRes = await S3API.uploadFile(payload.data?.url, payload.photo);
                return photoRes.data;
            }
            return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
