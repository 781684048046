
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { IRCPayrollDeductions } from "../../../../interfaces/report_center_payroll_tax";
import * as thunk from "./payroll_deductions_thunk";
import { createSlice } from "@reduxjs/toolkit";

export interface PayrollDeductionsListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCPayrollDeductions | null,
}

const initialState: PayrollDeductionsListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const payrollDeductionsListSlice = createSlice({
    name: 'payrollDeductionsList',
    initialState: initialState,
    reducers: {
        clearPayrollDeductionsListError(state) {
            return { ...state, error: null };
        },
        clearPayrollDeductionsListState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPayrollDeductionsList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getPayrollDeductionsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getPayrollDeductionsList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearPayrollDeductionsListError, clearPayrollDeductionsListState } = payrollDeductionsListSlice.actions;
export const selectState = (state: PayrollDeductionsListState) => state;


