import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import { CustomButton, FormInput, getPlusBtnIcon, SpinnerScreen } from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import {
  getTalentEmpHistoryList,
  selectTalentEmpHistoryList,
  useAppDispatch,
  useAppSelector,
  selectTalentEmpHistoryListState,
} from "../../../../redux/store";
import EmploymentHistorySection from "../../../../components/talent_employment_history/talent_employment_history";
import { AddCircleOutline, ArrowUpward, ChevronLeft, ChevronRight, } from "@material-ui/icons";
import CreateEmploymentRecordPopup from "../../popups/create_employment_record/create_employment_record";
import "./employment_history_page.scss";
import {
  getDateFromTimeStamp,
  getDaysRemainingString,
  getDateString,
} from "../../../../variables";
import { manageTalentEmploymentHistoryActions } from "../../../../redux/talent/manage_employment/manage_employment_reducer";
import { clearTalentEmpHistoryListState } from "../../../../redux/talent/employment_history/employment_history_reducer";
import Grid from "@material-ui/core/Grid";
import gsap from "gsap";
import { IEmploymentHistory } from "../../../../interfaces";
import AddCompanyToCallDeck from "../../popups/add_company_to_call_deck/add_company_to_call_deck";
import { AppRoutes } from "../../../../routes";
interface Props extends RouteComponentProps<any> { }
const PageLoading = (props) => {
  return (
    <div className="table-loading">
      <SpinnerScreen />
    </div>
  );
};
const EmploymentHistoryPage: React.FunctionComponent<Props> = (props) => {
  const { history, location, match } = props;
  const { params } = match;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const employmentHistoryState = useAppSelector((state) =>
    selectTalentEmpHistoryListState(state)
  );
  const employmentHistoryList = useAppSelector((state) =>
    selectTalentEmpHistoryList(state)
  );

  const [addTalentEmpHistory, setAddTalentEmpHistory] = useState<boolean>(false);
  const [timelinevalue, setValue] = useState(0);
  const [previous, setPrevious] = useState(0);
  const [addCompanyToCallDeckHistory, setAddCompanyToCallDeckHistory] = useState<{ visible: boolean, value: IEmploymentHistory } | null>(null);


  const [newtimelinevalue, setnewValue] = useState(0);
  const [newprevious, setnewPrevious] = useState(0);
  // Description array corresponding to values
  const description = [];
  useEffect(() => {

    getTalentEmpHistoryListDetails();
    return () => {
      dispatch(clearTalentEmpHistoryListState());
    };
  }, []);
  const getTalentEmpHistoryListDetails = () => {
    if (params && params.id) dispatch(getTalentEmpHistoryList(params.id));
  };

  ///Add user action
  function handleAddTalentEmpHistorySuccess() {
    setAddTalentEmpHistory(false);
    dispatch(
      manageTalentEmploymentHistoryActions.clearCreateTalentEMploymentHistoryState()
    );
    getTalentEmpHistoryListDetails();
  }
  
  function isLoading() {
    return employmentHistoryState.loading === LoadingType.pending;
  }

  function handleAddCompanyToCallDeck(value: IEmploymentHistory) {
    history.push({
      pathname: AppRoutes.companiesUniversalDirectory,
      state: {
        newCompany: true,
        company_name:value?.company_name,
        
      },
    });
  }

  const getDateStringFormat = (value?: number) => {
    if (value == null) return "";
    const date = getDateFromTimeStamp(value);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}`;
  };

  // let employment_years: any[] = [];
  // employmentHistoryList.map((emp) =>
  //   employment_years.push(getDateStringFormat(emp.start_date))
  // );

  const getEmpData = (id) => () => {
    setnewPrevious(id);
    const activeElement = employmentHistoryList.find((item) => item.id == employmentHistoryList[id].id)
    activeElement && setnewValue(id)

  };
  let scrl = useRef<HTMLUListElement | null>(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  const slide = (shift) => {
    scrl.current!.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current!.scrollWidth - scrl.current!.scrollLeft) <=
      scrl.current!.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.5 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current!.scrollLeft);
    if (
      Math.floor(scrl.current!.scrollWidth - scrl.current!.scrollLeft) <=
      scrl.current!.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  if (isLoading()) {
    return (<PageLoading />);
  }

  if (employmentHistoryList.length != 0) {
    return (
      <>
        <div className="tlnt-emp-history-ud-container">

          <div className="history-holder">
            <div className="scrollable-list">
              {scrollX !== 0 && <button
                className="left-action"
                onClick={() => slide(-50)}
                onMouseEnter={(e) => anim(e)}
                onMouseLeave={(e) => anim2(e)}
              >
                <div className="prev">
                  <ChevronLeft fontSize="small" color="inherit" />
                </div>
              </button>}
              <style>
                {` .timeline-ul::before {
                      width: ${scrl.current !== null ? scrl.current.scrollWidth + 'px' : 100 + '%'} !important;
                    }
              `}
              </style>
              <ul
                ref={scrl}
                onScroll={scrollCheck}
                className="timeline-ul"
              >
                {employmentHistoryList.length > 0 && employmentHistoryList.map((item, index) =>
                  <li key={index} onClick={getEmpData(index)} data-index={index}
                    className={newtimelinevalue === index ? 'active_item' : ''}>
                    <div className="year_span">{getDateStringFormat(item.start_date)}</div>

                    <div className="company_span">{item.company_name}</div>

                  </li>
                )}
              </ul>
              {!scrolEnd && <button className="right-action"
                onClick={() => slide(+50)}
                onMouseEnter={(e) => anim(e)}
                onMouseLeave={(e) => anim2(e)}
              >
                <div className="next">
                  <ChevronRight fontSize="small" color="inherit" />
                </div>
              </button>}
            </div>
            <div className="history-actions">
              <div className="tlnt-emp-history-ud-table-header">
                <div className="tlnt-emp-history-ud-actions">
                  <div className="tlnt-emp-history-ud-add-user-btn">
                    <CustomButton
                      leftIcon={getPlusBtnIcon()}
                      loading={false}
                      textStyle={{ textTransform: "capitalize" }}
                      name={t("add_employment_record")}
                      enable={true}
                      backgroundColor={THEME.defaultHighLightColor}
                      onClick={() => setAddTalentEmpHistory(true)}
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="details-container">
            <EmploymentHistorySection
              talentId={params.id}
              employmentHistory={employmentHistoryList[newtimelinevalue]}
              onAddCompanyToCallDeck={handleAddCompanyToCallDeck}
            />
          </div>
        </div>
        {addTalentEmpHistory && (
          <Portal>
            <CreateEmploymentRecordPopup
              talentId={params.id}
              visible={addTalentEmpHistory}
              title={t("new_employment_record")}
              onClose={() => setAddTalentEmpHistory(false)}
              onSuccessClose={handleAddTalentEmpHistorySuccess}
            />
          </Portal>
        )}
        {addCompanyToCallDeckHistory && (
          <Portal>
            <AddCompanyToCallDeck
              visible={addCompanyToCallDeckHistory.visible}
              title={t("adding_company_to_call_deck")}
              company_id={addCompanyToCallDeckHistory.value.company_id}
              onClose={() => setAddCompanyToCallDeckHistory(null)}
              onDissmiss={() => setAddCompanyToCallDeckHistory(null)}
              onSuccessClose={handleAddTalentEmpHistorySuccess}
              onDashboardNav={() => handleNav(AppRoutes.dashboardPage)}
              onSalesNav={() => handleNav(AppRoutes.salesAndMarketingPage)}
            />
          </Portal>
        )}
      </>
    )
  }

  return (
    <div className="tlnt-emp-history-ud-container-empty">
      <div className="no-data-div">
        <span className="span">{t("no_emp_data_available")}</span>
        <div className="tlnt-emp-history-ud-actions">
          <div className="tlnt-emp-history-ud-add-user-btn">
            <CustomButton
              leftIcon={getPlusBtnIcon()}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("add_employment_record")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setAddTalentEmpHistory(true)}
            />
          </div>
          {addTalentEmpHistory && (
            <Portal>
              <CreateEmploymentRecordPopup
                talentId={params.id}
                visible={addTalentEmpHistory}
                title={t("new_employment_record")}
                onClose={() => setAddTalentEmpHistory(false)}
                onSuccessClose={handleAddTalentEmpHistorySuccess}
              />
            </Portal>
          )}
        </div>
      </div>
    </div>
  );


  function handleNav(value: string) {
    history.push(value);
  }
};

export default EmploymentHistoryPage;
