import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI } from "../../../apis";
import { AppError, ICompaniesList, } from "../../../interfaces";
import { catchRequestError } from "../../../utils";


export const getCompaniesDropdown = createAsyncThunk<
    Array<ICompaniesList>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/companies/companies/dropdown/get',
    async (_, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompaniesList();
            if(typeof res.data === 'string')  return [];
            return res.data as  Array<ICompaniesList>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);