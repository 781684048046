
import { createSlice } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { IRCPTTerminationReport } from "../../../../interfaces/report_center_payroll_tax";
import * as thunk from "./termination_report_thunk";
export interface TerminationReportListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCPTTerminationReport[] | null
}

const initialState: TerminationReportListState = {
    error: null,
    loading: LoadingType.idle,
    response: null
}


export const terminationReportListSlice = createSlice({
    name: 'terminationReportList',
    initialState: initialState,
    reducers: {
        clearTerminationReportListError(state) {
            return { ...state, error: null };
        },
        clearTerminationReportListState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTerminationReportList.pending, (state, action) => ({ ...state, loading: LoadingType.pending , error: null, }))
            .addCase(thunk.getTerminationReportList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getTerminationReportList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload}))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearTerminationReportListError, clearTerminationReportListState } = terminationReportListSlice.actions;
export const selectState = (state: TerminationReportListState) => state;


