import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./deduction_type_thunk";
import { ThirdPartyDeductionType } from "../../../../interfaces/third_party_deduction_type";


export interface ThirdPartyDeductionTypeListState extends EntityState<ThirdPartyDeductionType> {
    error?: AppError | null,
    loading: LoadingType,
}

const ThirdPartyDeductionTypeListAdapter: EntityAdapter<ThirdPartyDeductionType> = createEntityAdapter<ThirdPartyDeductionType>({
    selectId: (doc) => doc.id
})

const initialState: ThirdPartyDeductionTypeListState = ThirdPartyDeductionTypeListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const ThirdPartyDeductionTypeListSlice = createSlice({
    name: 'ThirdPartyDeductionTypeList',
    initialState: initialState,
    reducers: {
        clearThirdPartyDeductionTypeListError(state){
            return {...state, error: null};
        },
        clearThirdPartyDeductionTypeListState(state){
            return ThirdPartyDeductionTypeListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getThirdPartyDeductionType.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getThirdPartyDeductionType.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getThirdPartyDeductionType.fulfilled, (state, action) => ThirdPartyDeductionTypeListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const thirdPartyDeductionTypeActions = ThirdPartyDeductionTypeListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = ThirdPartyDeductionTypeListAdapter.getSelectors();
export const selectState = (state: ThirdPartyDeductionTypeListState) => state;
export const selectLoading = (state: ThirdPartyDeductionTypeListState) => state.loading;
export const selectError = (state: ThirdPartyDeductionTypeListState) => state.error;


