import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, CompanyStatus } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getCompanyStatus = createAsyncThunk<
    Array<CompanyStatus>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/company_status/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getCompanyStatus();
            return res.data as CompanyStatus[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createCompanyStatus = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/company_status/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createCompanyStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteCompanyStatus = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/company_status/delete',
    async (status_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteCompanyStatus(status_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateCompanyStatus = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/company_status/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateCompanyStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);