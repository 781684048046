import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { approveWAERThunk, getWAERDownloadUrl, getWAERListThunk, getWeekEndingDatesDropdownThunk, resolveWAERThunk, selectPERDownloadUrlState, selectPayrollErrorsReportListState, selectWAERDownloadUrlState, selectWAErrorsReportListState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { LoadingType } from "../../../../../enums";
import ErrorsReportsPage from "../../errors_reports_page/errors_reports_page";
import { weeklyAErrorReportListSliceActions } from "../../../../../redux/back_office/weekly_accounting/error_report/error_report_reducer";
import { CustomCheckBox } from "../../../../../utils";


interface Props extends RouteComponentProps<any> { }

const WAErrorsReportPage: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { history } = props;
    const wAErrorsReportListState = useAppSelector((state) => selectWAErrorsReportListState(state));
    const wAErrorsReportDownloadUrlState = useAppSelector((state) => selectWAERDownloadUrlState(state));

    const [errorReportsList, setErrorReportsList] = useState<any[] | null>(null);
    const [weekendId, setWeekendId] = useState<string>("");
    const [selectedItems, setSelectedItems] = useState<
        Map<string, { id: string; name: string }>
    >(new Map());

    const isCheckboxDisabled  = errorReportsList?.every(
        item => item.status === "APPROVED" || item.status === "RESOLVED" || item.type === "Error"
      );

    const filteredSelectedItems= errorReportsList?.filter((item)=>item.status === "OPEN" && item.type === "Warning")

    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);
    useEffect(() => {
        if (wAErrorsReportListState && wAErrorsReportListState.loading === LoadingType.succeeded) {
            setErrorReportsList(wAErrorsReportListState.response);

        }
    }, [wAErrorsReportListState, wAErrorsReportListState.loading]);

    useEffect(() => {
        if (wAErrorsReportDownloadUrlState.loading === LoadingType.succeeded &&
            wAErrorsReportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', wAErrorsReportDownloadUrlState?.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(weeklyAErrorReportListSliceActions.clearDownloadState());
        }
    }, [wAErrorsReportDownloadUrlState.loading]);

    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === filteredSelectedItems?.length}
                onClick={handleMarkAllItem}
                disabled={isCheckboxDisabled}
            />
        );
    };

    const tableHeader = [
        { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        { title: 'error_msg', code: 'error_msg' },
        { title: 'area', code: 'error_type' },
        { title: 'category', code: 'type' },
        { title: 'status', code: 'status' },
        { title: 'week_ending_date', code: 'week_ending_date' },
        { title: 'resolve_error', code: 'resolve_error' },
    ];

    const handleMarkAllItem = () => {
        const newSet = new Map<string, { id: string; name: string }>();
        if (selectedItems.size === filteredSelectedItems?.length) {
            setSelectedItems(newSet);
        } else {
            filteredSelectedItems?.forEach((doc) =>
                newSet.set(doc.id, { id: doc.id, name: "" })
            );
            setSelectedItems(newSet);
        }
    };

    const handleMarkItem = (value) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value.id)) {
            newSet.delete(value.id);
        } else {
            newSet.set(value.id, { id: value.id, name: "" });
        }
        setSelectedItems(newSet);
    };

    const handleWeekEndChange = (week_id: string) => {
        dispatch(getWAERListThunk(week_id));
    }

    const handleApproveError = (timeCardids: string[]) => {
        dispatch(approveWAERThunk(timeCardids));
    }

    const handleResolveError = (timeCardid: string) => {
        dispatch(resolveWAERThunk(timeCardid))
    }
    const handleClearApproveError = () => {
        dispatch(weeklyAErrorReportListSliceActions.clearApproveErrorState());
        dispatch(weeklyAErrorReportListSliceActions.clearResolveErrorState());
    }
    const handleOnSuccessClose = () => {
        dispatch(weeklyAErrorReportListSliceActions.clearApproveState());
        dispatch(weeklyAErrorReportListSliceActions.clearResolveState());
        dispatch(getWAERListThunk(weekendId));
    }

    const handleSetWeekendId = (week_id: string) => {
        setWeekendId(week_id)
    }

    function handleDownload() {
        dispatch(getWAERDownloadUrl(weekendId));
    }

    const resetSelectedItems = () => {
        setSelectedItems(new Map());
    };

    return (
        <ErrorsReportsPage
            tableheaderList={tableHeader}
            reportsList={errorReportsList}
            source="timecards"
            weekendChange={handleWeekEndChange}
            WeekendId={(week_id) => handleSetWeekendId(week_id)}
            loading={wAErrorsReportListState.loading === LoadingType.pending}
            onApprove={handleApproveError}
            onResolve={handleResolveError}
            state={wAErrorsReportListState.approve}
            resolveState={wAErrorsReportListState.resolve}
            handleClearError={handleClearApproveError}
            handleOnSuccessClose={handleOnSuccessClose}
            handleDownload={handleDownload}
            isWeeklyAccountingErrorReport={true}
            history={history}
            selectedItems={selectedItems}
            handleMarkItem={handleMarkItem}
            handleClearSelectedItems={resetSelectedItems}
        />
    );
}

export default WAErrorsReportPage;