import { combineReducers } from "@reduxjs/toolkit";
import { empDashboardOBDocsReducers } from "./e_docs";
import { empDashboardDDReducers } from './direct_deposit';

export const employeeDashboardReducers = combineReducers({
    'eDocsOnboardingDocs': empDashboardOBDocsReducers,
    'empDashboardDDReducers': empDashboardDDReducers,

});
export * from "./e_docs"
export * from "./direct_deposit"
