import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './job_order_contact_reducer';

const select = (state: IRootState) => state.jobs.details.contacts.contactCreate;
export const selectJobOrderContactCreateState = createSelector(select, reducer.selectJobOrderContactCreateState);
export const selectSingleJobOrderContactCreateStateById = (id: string | undefined | null) => createSelector(
    selectJobOrderContactCreateState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleJobOrderContactCreateState(state, id);
        return reducer.selectInitialSingleJobOrderContactCreateState();
    }
);