import { useTranslation } from "react-i18next";
import { LoadingType, THEME } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { useRef, useState } from "react";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { BOOpenCloseIcon } from "../../../../icons";
import './create_transaction.scss';
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { CustomButton } from "../../../../utils";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import ApiError from "../../../../components/api_error";
interface Props {
    visible: boolean;
    title: string;
    onClose: () => void;
    handleCreateTransaction: () => void;
    createWeeklyTransactionState: {
        error?: AppError | null | undefined;
        loading: LoadingType;
        createResponse: string | null;
    }
    handleSuccessClose: () => void;
    currentLoginUserName: string;
    handleClearError: () => void;
}
const CreateTransactionPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        handleCreateTransaction,
        createWeeklyTransactionState,
        handleSuccessClose,
        currentLoginUserName,
        handleClearError
    } = props;
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);


    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"30%"}
            maxWrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={onClose}>
                <div className="create-trans-popup-container">
                    <div className="create-trans-popup-header">
                        <FormDialogTilteHeader
                            title={title}
                            titleIcon={<BOOpenCloseIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="create-trans-popup-content">
                        {createWeeklyTransactionState.loading === LoadingType.succeeded ?
                            (<div className="saved-doc-holder">
                                <DocSavedText>
                                    <div><span className="aknw-name">
                                        {t("great", {
                                            name: currentLoginUserName,
                                        })}
                                    </span></div>
                                    {/* <div><span>{t('you_have_added_transaction')}</span></div> */}
                                    <div><span>{createWeeklyTransactionState.createResponse}</span></div>

                                    <div><span className="sub-span">{t('click_ok_timecards_sheet_enter_new')}</span></div>

                                </DocSavedText>
                            </div>)
                            :
                            (<div>
                                <span>{t('sure_create_transaction')}</span>
                            </div>)
                        }
                        {createWeeklyTransactionState.error &&
                            <div className="oc-error-holder">
                                <ApiError
                                    message={createWeeklyTransactionState?.error?.message}
                                    onClose={handleClearError} />
                            </div>
                        }
                    </div>
                    <div className="create-trans-popup-actions">
                        {createWeeklyTransactionState.loading === LoadingType.succeeded
                            ?
                            (<>
                                <div className="btn-save">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("ok")}
                                        enable={true}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={handleSuccessClose}
                                    />
                                </div></>)
                            :
                            (
                                <>
                                    <div className="btn-cancel" style={{ marginRight: "1em" }}>
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("cancel")}
                                            enable={true}
                                            backgroundColor={THEME.toggleDisableColor}
                                            onClick={onClose}
                                        />
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={createWeeklyTransactionState.loading === LoadingType.pending}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("create")}
                                            enable={true}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={handleCreateTransaction}
                                        />
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>);
}

export default CreateTransactionPopup;