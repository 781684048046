import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, OnboardingStep } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getOnboardingSteps = createAsyncThunk<
    Array<OnboardingStep>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/onboarding_step/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getOnboardingSteps();
            return res.data as OnboardingStep[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createOnboardingStep = createAsyncThunk<
    string,
    {'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/onboarding_step/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createOnboardingStep(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteOnboardingStep = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/onboarding_step/delete',
    async (method_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteOnboardingStep(method_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateOnboardingStep = createAsyncThunk<
    string,
    {'id': string, 'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/onboarding_step/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateOnboardingStep(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);