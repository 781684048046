import { combineReducers } from "@reduxjs/toolkit";
import { competitorSheetReducers } from "./competitor_sheet";
import { marketingActivityReducers } from "./marketing_activity";
import { marketingDocsReducers } from "./marketing_docs";
import { myCallDeckReducers } from "./my_call_deck";
import { planMarketingCampaignReducers } from "./plan_marketing_campaign";
import { planMySalesCallsReducers } from "./plan_my_sales_calls";
import { salesCallActivityReducers } from "./sales_call_activity";
import { serviceCallActivityReducers } from "./service_call_activity";

export const salesAndMarketingReducers = combineReducers({
    'myCallDeck': myCallDeckReducers,
    'competitorSheet': competitorSheetReducers,
    'planMySalesCalls': planMySalesCallsReducers,
    'planMarketingCampaign': planMarketingCampaignReducers,
    // ['salesCallActivity']: salesCallActivityReducers,
    'serviceCallActivity': serviceCallActivityReducers,
    'marketingActivity': marketingActivityReducers,
    'marketingDocs': marketingDocsReducers,
});

export * from './my_call_deck';
export * from './competitor_sheet';
export * from './plan_my_sales_calls';
export * from './marketing_docs';