import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";
import * as thunk from "./talent_w4_i9_thunk";

export interface TalentDocsState {
    error?: AppError | null,
    loading: LoadingType,
    response?: String | null,
}

const initialState: TalentDocsState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const talentDocsParserSlice = createSlice({
    name: 'w4i9DocSlice',
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...state, loading: LoadingType.idle, error: null };
        },
        clearErrorState(state) {
            return { ...state, error: null };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.updateTalentW4I9Details.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.updateTalentW4I9Details.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, response: null, }))
            .addCase(thunk.updateTalentW4I9Details.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentDocsParserSliceActions = talentDocsParserSlice.actions;
export const selectState = (state: TalentDocsState) => state;
export const selectLoading = (state: TalentDocsState) => state.loading;
export const selectError = (state: TalentDocsState) => state.error;