import { useTranslation } from "react-i18next";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import { AppRoutes, getRiskManagementNavHeaderProps } from "../../routes";
import { useEffect } from "react";
import { useAppDispatch } from "../../redux/store";
import UniversalDirectory from "./pages/universal_directory/universal_directory";
import WorkCompClaimDashboardPage from "./pages/dashboard/workcompclaim_dashboard/workcompclaim_dashboard";
import UnEmploymentClaimDashboardPage from "./pages/dashboard/unemploymentclaim_dashboard/unemploymentclaim_dashboard";
import { GetCloseAction } from "../../utils";
import { WCCReportDashboard } from "./pages/dashboard/workcompcompliancereport_dashboard/workcompcompliancereport_dashboard";

interface Props extends RouteComponentProps<any> { }


/// Details
const RiskManagement_WC_DetailsTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    // const pathSplit = location.pathname.split('/');
    // const departmentId = pathSplit.length === 6 ? pathSplit[pathSplit.length - 1] : null;

    useEffect(() => {
        return () => { }
    }, [])

    function handleNavigationChange(value: string) {
        history.push({
            pathname: value,
            state: location.state
        });
    }

    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'dashboard', route: `${AppRoutes.workCompClaimDetailsPage}/${params.id}/dashboard` },
            ]}
            onChange={handleNavigationChange}
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.riskManagementPage)}/>}
        >
            <Switch>
                <Route exact path={`${AppRoutes.workCompClaimDetailsPage}/:id/dashboard`} component={WorkCompClaimDashboardPage} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: `${location.pathname}/dashboard`,
                            state: { ...(location.state as any) }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );

   
}

const RiskManagement_UC_DetailsTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    // const pathSplit = location.pathname.split('/');
    // const departmentId = pathSplit.length === 6 ? pathSplit[pathSplit.length - 1] : null;

    useEffect(() => {
        return () => { }
    }, [])

    function handleNavigationChange(value: string) {
        history.push({
            pathname: value,
            state: location.state
        });
    }

    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'dashboard', route: `${AppRoutes.unEmplomentDetailsPage}/${params.id}/dashboard` },
            ]}
            onChange={handleNavigationChange}
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.riskManagementPage)}/>}
        >
            <Switch>
                <Route exact path={`${AppRoutes.unEmplomentDetailsPage}/:id/dashboard`} component={UnEmploymentClaimDashboardPage} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: `${location.pathname}/dashboard`,
                            state: { ...(location.state as any) }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );

   
}


const RiskManagement_WCCR_DetailsTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();

    function handleNavigationChange(value: string) {
        history.push({
            pathname: value,
            state: location.state
        });
    }

    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'dashboard', route: `${AppRoutes.workCompComplianceReportDetailsPage}/${params.id}/dashboard` },
            ]}
            onChange={handleNavigationChange}
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.riskManagementPage)}/>}
        >
            <Switch>
                <Route exact path={`${AppRoutes.workCompComplianceReportDetailsPage}/:id/dashboard`} component={WCCReportDashboard} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: `${location.pathname}/dashboard`,
                            state: { ...(location.state as any) }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );  
}

/// Universal directory folder
const RiskManagementTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'universal_directory', route: AppRoutes.riskManagementUniversalDirectory },
            ]}
            onChange={(value) => history.push(value)}
        >
            <Switch>
                <Route exact path={AppRoutes.riskManagementUniversalDirectory} component={UniversalDirectory} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: AppRoutes.riskManagementUniversalDirectory,
                            state: { from: location }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );
}



export const RiskManagementNavigation: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getRiskManagementNavHeaderProps(location, match, t);

    return (
        <PageNavHeader data={headerOptions} history={history} location={location}>
            <Switch>
                <Route path={`${AppRoutes.workCompClaimDetailsPage}/:id`} component={RiskManagement_WC_DetailsTabNav} />
                <Route path={`${AppRoutes.unEmplomentDetailsPage}/:id`} component={RiskManagement_UC_DetailsTabNav} />
                <Route path={`${AppRoutes.workCompComplianceReportDetailsPage}/:id`} component={RiskManagement_WCCR_DetailsTabNav} />
                <Route path={'/'} component={RiskManagementTabNav} />
            </Switch>
        </PageNavHeader>
    );
}

