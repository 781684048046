import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from './send_by_email_thunk';


export interface SalesDocumentSendByEmailState {
    sendDocumentLinkToEmail: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    sendVideoLinkToEmail: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
}


const initialState: SalesDocumentSendByEmailState = {
    sendDocumentLinkToEmail: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    sendVideoLinkToEmail: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
}


export const salesDocumentSendByEmailSlice = createSlice({
    name: 'salesDocumentSendByEmail',
    initialState: initialState,
    reducers: {
        clearSendDocumentLinkToEmailError(state) {
            state.sendDocumentLinkToEmail= { ...initialState.sendDocumentLinkToEmail}
        },
        clearSendDocumentLinkToEmailState(state) {
            state.sendDocumentLinkToEmail= { ...initialState.sendDocumentLinkToEmail}
        },
        clearsendVideoLinkToEmailError(state) {
            state.sendVideoLinkToEmail= { ...initialState.sendVideoLinkToEmail}
        },
        clearsendVideoLinkToEmailState(state) {
            state.sendVideoLinkToEmail= { ...initialState.sendVideoLinkToEmail}
        },
    },
    extraReducers: (builder) => {
        builder
            
            .addCase(thunk.sendDocumentByEmail.pending, (state, action) => ({ ...state, sendDocumentLinkToEmail: { ...state.sendDocumentLinkToEmail, loading: LoadingType.pending, response: '', error: null } }))
            .addCase(thunk.sendDocumentByEmail.rejected, (state, action) => ({ ...state, sendDocumentLinkToEmail: { ...state.sendDocumentLinkToEmail, loading: LoadingType.failed, response: '', error: action.payload } }))
            .addCase(thunk.sendDocumentByEmail.fulfilled, (state, action) => ({ ...state, sendDocumentLinkToEmail: { ...state.sendDocumentLinkToEmail, loading: LoadingType.succeeded, response: action.payload, error: null } }))

            .addCase(thunk.sendVideoByEmail.pending, (state, action) => ({ ...state, sendVideoLinkToEmail: { ...state.sendVideoLinkToEmail, loading: LoadingType.pending, response: '', error: null } }))
            .addCase(thunk.sendVideoByEmail.rejected, (state, action) => ({ ...state, sendVideoLinkToEmail: { ...state.sendVideoLinkToEmail, loading: LoadingType.failed, response: '', error: action.payload } }))
            .addCase(thunk.sendVideoByEmail.fulfilled, (state, action) => ({ ...state, sendVideoLinkToEmail: { ...state.sendVideoLinkToEmail, loading: LoadingType.succeeded, response: action.payload, error: null } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const salesDocumentSendByEmailActions = salesDocumentSendByEmailSlice.actions;
export const selectSalesDocumentSendByEmailState = (state: SalesDocumentSendByEmailState) => state;
