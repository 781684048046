import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, WorkCompCode } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getWorkCompCodes = createAsyncThunk<
    Array<WorkCompCode>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/work_comp_code/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getWorkCompCodes();
            return res.data as WorkCompCode[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createWorkCompCode = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/work_comp_code/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createWorkCompCode(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteWorkCompCode = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/work_comp_code/delete',
    async (status_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteWorkCompCode(status_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateWorkCompCode = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/work_comp_code/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateWorkCompCode(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);