
import React, { useState } from 'react';

export default function CustomText(props) {

    return (
        <span
            // numberOfLines={props.numberOfLines}
            // ellipsizeMode={props.ellipsizeMode}
            className={props.className}
            style={{
                ...styles.defaultStyle,
                ...props.customStyle
            }}
        >
            {props.children}
        </span>
    );

}

const styles: { [key: string]: React.CSSProperties } = ({
    // ... add your default style here
    defaultStyle: {
        // fontFamily: FONT.MANROPE_REGULAR
        maxWidth: '100%',
        boxSizing: 'border-box',
        wordBreak: 'unset',
    },
});