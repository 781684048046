

import { createSlice } from '@reduxjs/toolkit';
import * as thunk from './file_view_download_thunk';
import { AppError } from '../../../interfaces';
import { LoadingType } from '../../../enums';

export interface FileDownloadURLState {
    response: string | null,
    error?: AppError | null,
    loading: LoadingType,
    viewURL: {
        response: string | null,
        error?: AppError | null,
        loading: LoadingType,
    }
}

const initialState: FileDownloadURLState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    viewURL: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}


export const fileDownloadSlice = createSlice({
    name: "fileDownloadSlice",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearViewState(state) {
            return { ...state, viewURL: { ...initialState.viewURL } };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getDownloadFileURLThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getDownloadFileURLThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getDownloadFileURLThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))

            // view url
            .addCase(thunk.getFileViewURLThunk.pending, (state, action) => ({
                ...state,
                viewURL: {
                    ...state.viewURL,
                    loading: LoadingType.pending,
                    error: null,
                }
            }))
            .addCase(thunk.getFileViewURLThunk.rejected, (state, action) => ({
                ...state,
                viewURL: {
                    ...state.viewURL,
                    loading: LoadingType.failed,
                    error: action.payload,
                }

            }))
            .addCase(thunk.getFileViewURLThunk.fulfilled, (state, action) => ({
                ...state,
                viewURL: {
                    ...state.viewURL,
                    loading: LoadingType.succeeded,
                    response: action.payload,
                }

            }))

            .addDefaultCase((state) => ({ ...state }));
    }
});
export const fileViewDownloadSliceActions = fileDownloadSlice.actions;
export const selectState = (state: FileDownloadURLState) => state;