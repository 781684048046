import { ArrowForwardIos } from '@material-ui/icons';
import { RouteComponentProps } from 'react-router-dom';
import { THEME } from '../../enums';
import {PageNavOptions} from '../../interfaces';
import './page_nav_header.scss';

interface Props {
    history: RouteComponentProps["history"],
    location?: RouteComponentProps["location"],
    children: React.ReactNode,
    data: PageNavOptions,

};

const PageNavHeader = (props: Props) => {
    return (
        <div className="pnh-container">
            <div className="pnh-header" style={{ backgroundColor: props.data.backgroundColor }}>
                <div className="pnh-icon">
                    <props.data.icon width={"100%"} height={"100%"} style={{ color: props.data.activeColor}} />
                </div>
                {
                    props.data.path.map((doc, index) => {
                        if (index == 0) {
                            return (
                                <div key={index} className="pnh-path-name" >
                                    <a className="pnh-active" onClick={() => props.history.push(doc.route)}>
                                        <span className={`pnh-path-txt-${index}`} style={{ color: props.data.activeColor }}>{doc.title}</span>
                                    </a>
                                </div>
                            );
                        }
                        return (
                            <div key={index} className="pnh-path-name">
                                <div className="pnh-arrow-icon" style={{ color: props.data.activeColor }}>
                                    <ArrowForwardIos fontSize={"inherit"} color={"inherit"} width={16} height={16} />
                                </div>
                                <a className="pnh-active" onClick={() => props.history.push(doc.route)}>
                                    <span className={'pnh-path-txt'} style={{ color: props.data.activeColor }}>{doc.title}</span>
                                </a>

                            </div>
                        );
                    })
                }
            </div>
            <div className="pnh-content">
                {props.children}
            </div>
        </div>
    );
}

export default PageNavHeader;