import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./pay_type_thunk";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";

export interface PayTypeListState extends EntityState<AdminCenterBackOfficeType> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const PayTypeListAdapter: EntityAdapter<AdminCenterBackOfficeType> = createEntityAdapter<AdminCenterBackOfficeType>({
    selectId: (doc) => doc.id
})

const initialState: PayTypeListState = PayTypeListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const PayTypeListSlice = createSlice({
    name: 'PayTypeList',
    initialState: initialState,
    reducers: {
        clearPayTypeListError(state){
            return {...state, error: null};
        },
        clearPayTypeListState(state){
            return PayTypeListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearPayTypeCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearPayTypeUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearPayTypeDeleteState(state) {
            return { ...state, remove : {...initialState.remove} };
        },
        clearPayTypeCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearPayTypeUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearPayTypeDeleteStateError(state) {
            return { ...state, remove : { ...initialState.remove , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPayType.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getPayType.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getPayType.fulfilled, (state, action) => PayTypeListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createPayType.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createPayType.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createPayType.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updatePayType.pending, (state, action) => ({...state, update: {...state.update, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updatePayType.rejected, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updatePayType.fulfilled, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            
            .addCase(thunk.deletePayType.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deletePayType.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deletePayType.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const payTypeListActions = PayTypeListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = PayTypeListAdapter.getSelectors();
export const selectState = (state: PayTypeListState) => state;
export const selectLoading = (state: PayTypeListState) => state.loading;
export const selectError = (state: PayTypeListState) => state.error;


