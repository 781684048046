import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import { selectState, selectAll, selectEntities, selectById } from "./insurance_company_reducer";

const select = (state: IRootState) => state.adminCenter.rMInsuranceCompany.insuranceCompanyList;
export const selectInsuranceCompanyListState = createSelector(select, selectState);
export const selectInsuranceCompanyList = createSelector(select, selectAll);
export const selectInsuranceCompanyListEntities = createSelector(
    select,
    selectEntities
);
export const selectInsuranceCompanyByID = (id: string) => createSelector(
    select,
    (state) => selectById(state, id)
);