import { useRef } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { useTranslation } from "react-i18next";
import { BOCloseBatchIcon, BOOpenCloseIcon } from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton, SpinnerScreen } from "../../../../utils";
import './close_batch.scss';
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { AppError, ITimeCardsData } from "../../../../interfaces";
import ApiError from "../../../../components/api_error";
import { selectManageBatchState, useAppDispatch, useAppSelector } from "../../../../redux/store";
interface Props {
    visible: boolean,
    title: string,
    onClose: () => void,
    onDissmiss?: () => void,
    batchNum: string | undefined;
    closeState: {
        error?: AppError | null | undefined;
        loading: LoadingType;
        response: string | null;
    };
    onSuccessClose: () => void;

}

const CloseBatchPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        onDissmiss,
        batchNum, closeState, onSuccessClose
    } = props;
    const { t } = useTranslation();
    // const dispatch = useAppDispatch();

    const actionRef = useRef<ActionDialogHolderType>(null);
    // const manageBatchState = useAppSelector((state) => selectManageBatchState(state));

    return (<ActionDialogHolder
        ref={actionRef}
        visible={visible}
        wrapperWidth={"40%"}
        maxWrapperWidth={"40%"}
        onClose={onClose}
        onDissmiss={onClose}
    >
        <DialogWrapper onClose={onClose}>
            <div className="close-batch-container">
                <div className="close-batch-header">
                    <FormDialogTilteHeader
                        title={title}
                        titleIcon={<BOCloseBatchIcon width={"100%"} height={"100%"} />}
                    />
                </div>
                <div className="close-batch-content">
                    {closeState && closeState.loading === LoadingType.pending &&
                        <SpinnerScreen />
                    }
                    {closeState && closeState.loading === LoadingType.succeeded &&
                        (
                            <div className="saved-doc-holder">
                                <DocSavedText>
                                    <div>
                                        <span className="aknw-name">
                                            {t("batch_closed", { number: batchNum })}
                                        </span>
                                    </div>
                                    <div style={{ marginTop: '1.5rem' }}>
                                        <span className="aknw-name">
                                            {t("transaction_ready_payroll")}
                                        </span>
                                    </div>
                                </DocSavedText>
                            </div>
                        )
                    }
                    {closeState.error &&
                        <div className="saved-doc-holder error-holder">
                            <DocSavedText>
                                <div className="error-title">{t("Warning")}</div>
                                <div>
                                    <span className="aknw-name">
                                        {closeState.error.message}
                                    </span>
                                </div>
                            </DocSavedText>
                        </div>
                    }
                </div>

                <div className="close-batch-actions">
                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("ok")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => { onSuccessClose(); onClose() }}
                        />
                    </div>

                </div>
            </div>
        </DialogWrapper>

    </ActionDialogHolder>);
}

export default CloseBatchPopup;