import { createSlice } from "@reduxjs/toolkit";
import { Agency, AppError } from "../../../interfaces";
import { LoadingType } from "../../../enums";
import * as thunk from './apollo_refresh_thunk';


export interface ApolloRefreshState {
    error?: AppError | null,
    loading: LoadingType,
    response: any,
    refreshTalent: {
        error?: AppError | null,
        loading: LoadingType,
        response: any
    },
    refreshContact: {
        error?: AppError | null,
        loading: LoadingType,
        response: any
    },
    refreshCompany: {
        error?: AppError | null,
        loading: LoadingType,
        response: any
    }
}

const initialState: ApolloRefreshState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    refreshTalent: {
        error: null,
        loading: LoadingType.idle,
        response: null
    },
    refreshContact: {
        error: null,
        loading: LoadingType.idle,
        response: null
    },
    refreshCompany: {
        error: null,
        loading: LoadingType.idle,
        response: null
    }
}

export const apolloRefreshSlice = createSlice({
    name: "apolloRefresh",
    initialState: initialState,
    reducers: {
        // clearGetCreditsStateError(state) {
        //     return { ...state, error: null };
        // },
        clearApolloRefreshTalentState(state) {
            return {
                ...state, refreshTalent: {
                    ...state.refreshTalent, error: null,
                    loading: LoadingType.idle,
                    response: null
                }
            };
        },
        clearApolloRefreshContactState(state) {
            return {
                ...state, refreshContact: {
                    ...state.refreshContact, error: null,
                    loading: LoadingType.idle,
                    response: null
                }
            };
        },
        clearApolloRefreshCompanyState(state) {
            return {
                ...state, refreshCompany: {
                    ...state.refreshCompany, error: null,
                    loading: LoadingType.idle,
                    response: null
                }
            };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.refreshApolloContact.pending, (state, action) => ({ ...state, refreshContact: { ...state.refreshContact, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.refreshApolloContact.rejected, (state, action) => ({ ...state, refreshContact: { ...state.refreshContact, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.refreshApolloContact.fulfilled, (state, action) => ({ ...state, refreshContact: { ...state.refreshContact, loading: LoadingType.succeeded, response: action.payload } }))

            .addCase(thunk.refreshApolloTalent.pending, (state, action) => ({ ...state, refreshTalent: { ...state.refreshTalent, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.refreshApolloTalent.rejected, (state, action) => ({ ...state, refreshTalent: { ...state.refreshTalent, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.refreshApolloTalent.fulfilled, (state, action) => ({ ...state, refreshTalent: { ...state.refreshTalent, loading: LoadingType.succeeded, response: action.payload } }))

            .addCase(thunk.refreshApolloCompany.pending, (state, action) => ({ ...state, refreshCompany: { ...state.refreshCompany, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.refreshApolloCompany.rejected, (state, action) => ({ ...state, refreshCompany: { ...state.refreshCompany, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.refreshApolloCompany.fulfilled, (state, action) => ({ ...state, refreshCompany: { ...state.refreshCompany, loading: LoadingType.succeeded, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const apolloRefreshActions = apolloRefreshSlice.actions;
export const selectState = (state: ApolloRefreshState) => state;