import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../components/api_error";
import { LoadingType, THEME, TALENT_SKILLS_TYPE, ROLE } from "../../../enums";
import { IRemoveCompanyStaff, IRemoveContactStaff, IRemoveJobStaff, IRemoveSkill, IRemoveTalentStaff } from "../../../interfaces";
import { removeContactAccountManagers, removeContactRecruiters, removeContactSkills } from "../../../redux/contacts";
import { contactDetailsSliceActions } from "../../../redux/contacts/details/dashboard/contact_details/contact_details_reducer";
import { useAppDispatch, postSnackbarMessage, removeJobRecruiters, removeTalentRecruiters, removeCompanyRecruiters, removeJobSkills, removeTalentSkills, removeCompanySkills, removeJobAccountManagers, removeTalentAccountManagers, removeCompanyAccountManagers } from "../../../redux/store";
import { CustomButton } from "../../../utils";
import "./remove_option.scss"
import { CloseIcon, DeleteIcon } from "../../../icons";

interface Props {
    id: string;
    departmentId?: string;
    state: any;
    type: string;
    sourceType: string;
    visible: boolean;
    data: { id: string, value: string };
    onClose: () => void;
    onDissmiss?: () => void;
    onSuccessClose: () => void;
    onClearError: () => void;
}

export const RemoveOptionPopup = (props: Props) => {
    const {
        state,
        type,
        sourceType,
        departmentId,
        data,
        id,
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        onClearError
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);

    /// Create optin states
    const skillSetRemoveState = state.professional_skills.remove;
    const credentialsRemoveState = state.credentials.remove;
    const certificationsRemoveState = state.certifications.remove;
    const educationRemoveState = state.education.remove;
    const requiredEquipmentRemoveState = state.safety_equipment.remove;
    const accountManagerRemoveState = state.account_managers.remove;
    const recruitersRemoveState = state.recruiters.remove;

    useEffect(() => {
        if (skillSetRemoveState.loading === LoadingType.succeeded ||
            credentialsRemoveState.loading === LoadingType.succeeded ||
            certificationsRemoveState.loading === LoadingType.succeeded ||
            educationRemoveState.loading === LoadingType.succeeded ||
            requiredEquipmentRemoveState.loading === LoadingType.succeeded ||
            accountManagerRemoveState.loading === LoadingType.succeeded ||
            recruitersRemoveState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(skillSetRemoveState.response ||
                credentialsRemoveState.response ||
                certificationsRemoveState.response ||
                educationRemoveState.response ||
                requiredEquipmentRemoveState.response ||
                accountManagerRemoveState.response ||
                recruitersRemoveState.response));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [
        skillSetRemoveState.loading,
        credentialsRemoveState.loading,
        certificationsRemoveState.loading,
        educationRemoveState.loading,
        requiredEquipmentRemoveState.loading,
        accountManagerRemoveState.loading,
        recruitersRemoveState.loading,
    ])

    function isAddLoading() {
        return skillSetRemoveState.loading === LoadingType.pending ||
            credentialsRemoveState.loading === LoadingType.pending ||
            certificationsRemoveState.loading === LoadingType.pending ||
            educationRemoveState.loading === LoadingType.pending ||
            requiredEquipmentRemoveState.loading === LoadingType.pending ||
            accountManagerRemoveState.loading === LoadingType.pending ||
            recruitersRemoveState.loading === LoadingType.pending;
    }

    function getError() {
        return skillSetRemoveState.error ||
            credentialsRemoveState.error ||
            certificationsRemoveState.error ||
            educationRemoveState.error ||
            requiredEquipmentRemoveState.error ||
            accountManagerRemoveState.error ||
            recruitersRemoveState.error;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={430}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <div className="remove-option-container">
                <div className="remove-option-header">
                    <div className="header-content">
                        <div className="del-icon">
                            <DeleteIcon width={"1vw"} height={"1vw"} color="red" />
                        </div>
                        <div className="header-title">
                            <span>{t("delete_field", { name: t(type) })}</span>
                        </div>
                    </div>
                    <div>
                        <CloseIcon width={"1vw"} height={"1vw"} onClick={() => {
                            closePopup(onClose);
                            onClearError();
                        }} />
                    </div>
                </div>
                <div className="remove-option-content">
                    <div className="message-txt">
                        <span>{t('are_you_sure_you_want_to_delete', { value: data.value })}</span>
                        {/* <span>{t('are_you_sure_you_want_to_delete', { value: `${data.value} ${t(type)}` })}</span> */}
                    </div>
                    {getError() && <div className="error-holder">
                        <ApiError message={getError()?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="remove-option-footer">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('no')}
                            enable={true}
                            backgroundColor={THEME.secondaryColor4}
                            onClick={() => {
                                closePopup(onClose);
                                onClearError();
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={isAddLoading()}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('yes')}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleDeleteOption}
                        />
                    </div>
                </div>
            </div>
        </ActionDialogHolder>

    );

    function handleDeleteOption() {
        if (sourceType === "Contact") {
            switch (type) {
                case TALENT_SKILLS_TYPE.account_managers: {
                    const payload: IRemoveContactStaff = {
                        contact_id: id,
                        user_id: data.id,
                        role: ROLE.accountManager,
                    }
                    dispatch(removeContactAccountManagers(payload))
                    break;
                }
                case TALENT_SKILLS_TYPE.recruiters: {
                    const payload: IRemoveContactStaff = {
                        contact_id: id,
                        user_id: data.id,
                        role: ROLE.recruiter,
                    }
                    dispatch(removeContactRecruiters(payload));
                    break;
                }
                case TALENT_SKILLS_TYPE.professional_skills:
                case TALENT_SKILLS_TYPE.credentials:
                case TALENT_SKILLS_TYPE.certifications:
                case TALENT_SKILLS_TYPE.education:
                case TALENT_SKILLS_TYPE.safety_equipment: {
                    const payload: IRemoveSkill = {
                        skill_ids: [
                            data.id,
                        ],
                        skill_type: type,
                    }
                    dispatch(removeContactSkills({ contact_id: id, value: payload }))
                    break;
                }
                default: { break; }
            }
        }
        else if (sourceType === "Company") {
            switch (type) {
                case TALENT_SKILLS_TYPE.account_managers: {
                    const payload: IRemoveCompanyStaff = {
                        company_id: id,
                        department_id: departmentId,
                        user_id: data.id,
                        role: ROLE.accountManager,
                    }
                    dispatch(removeCompanyAccountManagers(payload))
                    break;
                }
                case TALENT_SKILLS_TYPE.recruiters: {
                    const payload: IRemoveCompanyStaff = {
                        company_id: id,
                        department_id: departmentId,
                        user_id: data.id,
                        role: ROLE.recruiter,
                    }
                    dispatch(removeCompanyRecruiters(payload));
                    break;
                }
                case TALENT_SKILLS_TYPE.professional_skills:
                case TALENT_SKILLS_TYPE.credentials:
                case TALENT_SKILLS_TYPE.certifications:
                case TALENT_SKILLS_TYPE.education:
                case TALENT_SKILLS_TYPE.safety_equipment: {
                    const payload: IRemoveSkill = {
                        skill_ids: [
                            data.id,
                        ],
                        skill_type: type,
                    }
                    dispatch(removeCompanySkills({ companyId: id, departmentId: departmentId, value: payload }))
                    break;
                }
                default: { break; }
            }
        }
        else if (sourceType === "Talent") {
            switch (type) {
                case TALENT_SKILLS_TYPE.account_managers: {
                    const payload: IRemoveTalentStaff = {
                        talent_id: id,
                        user_id: data.id,
                        role: ROLE.accountManager,
                    }
                    dispatch(removeTalentAccountManagers(payload))
                    break;
                }
                case TALENT_SKILLS_TYPE.recruiters: {
                    const payload: IRemoveTalentStaff = {
                        talent_id: id,
                        user_id: data.id,
                        role: ROLE.recruiter,
                    }
                    dispatch(removeTalentRecruiters(payload));
                    break;
                }
                case TALENT_SKILLS_TYPE.professional_skills:
                case TALENT_SKILLS_TYPE.credentials:
                case TALENT_SKILLS_TYPE.certifications:
                case TALENT_SKILLS_TYPE.education:
                case TALENT_SKILLS_TYPE.safety_equipment: {
                    const payload: IRemoveSkill = {
                        skill_ids: [
                            data.id,
                        ],
                        skill_type: type,
                    }
                    dispatch(removeTalentSkills({ talentId: id, value: payload }))
                    break;
                }
                default: { break; }
            }
        }
        else if (sourceType === "Jobs") {
            switch (type) {
                case TALENT_SKILLS_TYPE.account_managers: {
                    const payload: IRemoveJobStaff = {
                        job_id: id,
                        user_id: data.id,
                        role: ROLE.accountManager,
                    }
                    dispatch(removeJobAccountManagers(payload))
                    break;
                }
                case TALENT_SKILLS_TYPE.recruiters: {
                    const payload: IRemoveJobStaff = {
                        job_id: id,
                        user_id: data.id,
                        role: ROLE.recruiter,
                    }
                    dispatch(removeJobRecruiters(payload));
                    break;
                }
                case TALENT_SKILLS_TYPE.professional_skills:
                case TALENT_SKILLS_TYPE.credentials:
                case TALENT_SKILLS_TYPE.certifications:
                case TALENT_SKILLS_TYPE.education:
                case TALENT_SKILLS_TYPE.safety_equipment: {
                    const payload: IRemoveSkill = {
                        skill_ids: [
                            data.id,
                        ],
                        skill_type: type,
                    }
                    dispatch(removeJobSkills({ job_id: id, value: payload }))
                    break;
                }
                default: { break; }
            }
        }
    }

    function handleClearError() {
        dispatch(contactDetailsSliceActions.clearContactDetailsRemoveOptionError({ id: id, type: type }));
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}