import { useTranslation } from 'react-i18next';
import { LoadingType, THEME } from '../../enums';
import { ReloadIcon } from '../../icons';
import { AppError } from '../../interfaces';
import { CustomButton, SpinnerScreen } from '../../utils';
import './state_change_handler.scss';

interface Props {
    loading: LoadingType,
    error: AppError | null | undefined,
    children: React.ReactNode,
    onRefresh: () => void,
}

const StateChangeHandler: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    if (props.loading === LoadingType.pending) {
        return (
            <div className="cdch-spinner-container">
                <SpinnerScreen />
            </div>
        )
    }

    if (props.error != null) {
        return (
            <div className="cdch-error-container">
                <div className="error-msg">
                    <span>{props.error.message}</span>
                </div>
                <div className="error-action">
                    <CustomButton
                        leftIcon={getIcon()}
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('refresh')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={props.onRefresh}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            {props.children}
        </>
    );

    function getIcon() {
        return (
            <div className="cdch-add-icon">
                <ReloadIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
            </div>

        )
    }
}

export default StateChangeHandler;