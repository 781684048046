import {
    createEntityAdapter,
    createSlice,
    EntityAdapter,
    EntityState,
} from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, IOnboardingLink } from "../../../../../interfaces";
import * as thunk from "./archived_directory_list_thunk";

export interface ArchivedDirectoryListState extends EntityState<IOnboardingLink> {
    error?: AppError | null;
    loading: LoadingType;
}

const archivedDirectoryListAdapter: EntityAdapter<IOnboardingLink> =
    createEntityAdapter<IOnboardingLink>({
        selectId: (doc) => doc.id,
    });

const initialState: ArchivedDirectoryListState = archivedDirectoryListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
});

export const archivedDirectoryListSlice = createSlice({
    name: "archivedDirectoryList",
    initialState: initialState,
    reducers: {
        clearError(state) {
            return { ...state, error: null };
        },
        clearState(state) {
            return archivedDirectoryListAdapter.removeAll(initialState);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getOCIRArchivedList.pending,
                (state, action) => ({
                    ...state,
                    loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
                    error: null,
                })
            )
            .addCase(
                thunk.getOCIRArchivedList.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getOCIRArchivedList.fulfilled,
                (state, action) =>
                    archivedDirectoryListAdapter.setAll(
                        { ...state, loading: LoadingType.succeeded, error: null },
                        action.payload
                    )
            )

            .addDefaultCase((state) => ({ ...state }));
    },
});

export const archivedDirectoryListSliceActions = archivedDirectoryListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = archivedDirectoryListAdapter.getSelectors();
export const selectState = (state: ArchivedDirectoryListState) => state;
export const selectLoading = (state: ArchivedDirectoryListState) => state.loading;
export const selectError = (state: ArchivedDirectoryListState) => state.error;
