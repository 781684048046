import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectAll, selectEntities, selectState } from "./work_comp_configuration_reducer";

const select = (state: IRootState) => state.adminCenter.workCompConfigList;
export const selectWorkCompConfigListState = createSelector(select, selectState);
export const selectWorkCompConfigList = createSelector(
    select,
    selectAll
);
export const selectWorkCompConfigListEntities = createSelector(
    select,
    selectEntities
);