
import { createSelector } from "@reduxjs/toolkit";
import { selectState, selectAll, selectEntities } from "./employee_contribution_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.EmployeeContributionsList;
export const selectEmployeeContributionListState = createSelector(select, selectState);
export const selectEmployeeContributionList = createSelector(
    select,
    selectAll
);
export const selectEmployeeContributionListEntities = createSelector(
    select,
    selectEntities
);