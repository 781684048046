import { createAsyncThunk } from "@reduxjs/toolkit";
import { RiskManagementAPI } from "../../../../apis";
import { AppError, IWorkCompClaim, ICreateWorkCompClaim, IWorkCompClaimTalentData } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getAllWorkCompClaimsList = createAsyncThunk<
    Array<IWorkCompClaim>,
    string | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/all-work-comp-claims/get',
    async (id, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getAllWorkCompClaims(id);
            if (typeof res.data === 'string') return [];
            return res.data as IWorkCompClaim[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addWorkCompClaimDetails = createAsyncThunk<
    string,
    ICreateWorkCompClaim,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/work-comp-claims/post',
    async (data, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.postWorkCompClaims(data);
            thunkAPI.dispatch(getAllWorkCompClaimsList());
            if (typeof res.data === 'string') return {};
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateWorkCompClaimDetails = createAsyncThunk<
    string,
    ICreateWorkCompClaim,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/work-comp-claims/patch',
    async (data, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.patchWorkCompClaims(data);
            // if(data.talent_id) thunkAPI.dispatch(getWorkCompClaimDetailsByTalentId(data.talent_id));
            if (typeof res.data === 'string') return {};
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const getWorkCompClaimDocs = createAsyncThunk<
    Array<string>,
    { id: string, docs: any },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/work-comp-claims/getDocs',
    async (data, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getWorkCompClaimDocs(data.id, data.docs);
            if (typeof res.data === 'string') return [];
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getWorkCompClaimDetailsByTalentId = createAsyncThunk<
    IWorkCompClaimTalentData,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/work-comp-claims/talentId',
    async (id, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getWorkCompClaimsByTalentId(id);
            if (typeof res.data === 'string') return {};
            return res.data as IWorkCompClaimTalentData;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getFileDownloadURL = createAsyncThunk<
    any,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/work-comp-claims/fileDownload',
    async (file_path, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getFileDownloadURL(file_path);
            if (typeof res.data === 'string') return {};
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);