import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { CreateTeamManagementTeams, TeamManagementTeams, UpdateTeamManagementTeams } from "../../../../interfaces/team_services";
import { AdminCenterAPI } from "../../../../apis";
import { S3API } from "../../../../apis/s3_api";


/// get Teams
export const getTeamManagementTeamsDetails = createAsyncThunk<
    TeamManagementTeams[],
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@team_management/teams_services/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getTMTeamsDetails();
            return res.data as TeamManagementTeams[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// get Team Members
export const getTeamManagementTeamMembersDetailsById = createAsyncThunk<
    TeamManagementTeams,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@team_management/team_services_by_id/get',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getTMTeamMembersDetailsById(payload);
            return res.data as TeamManagementTeams;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Create Teams
export const createTeamManagementTeams = createAsyncThunk<
    string,
    { data: CreateTeamManagementTeams, file: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@team_management/team_services/post',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createTMTeams(payload.data);
            if (res.data.url !== "" && payload.file) {
                const uploadFile = await S3API.uploadFile(res.data.url, payload.file);
                return uploadFile.data;
            }
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Update Teams
export const updateTeamManagementTeams = createAsyncThunk<
    string,
    { team_id: string | undefined, data: CreateTeamManagementTeams, file?: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@team_management/team_services/patch',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateTMTeams(payload.data);
            if (res.data.url !== "" && payload.file) {
                const uploadFile = await S3API.uploadFile(res.data.url, payload.file);
                return uploadFile.data;
            }
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Delete Teams
export const deleteTeamManagementTeams = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@team_management/team_services/delete',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteTMTeams(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


