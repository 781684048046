import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContactsAPI } from "../../../../../apis";
import { ROLE, TALENT_SKILLS_TYPE } from "../../../../../enums";
import { AppError, IAddSkill, IAddContactStaff, IRemoveContactStaff, IContact, ISkillsInfo, Profile, IRemoveSkill, IDepartment } from "../../../../../interfaces";
import { catchRequestError, getTalentMatchQuery } from "../../../../../utils";


export const getContactById = createAsyncThunk<
    IContact,
    { contact_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/contact_info/contact/get',
    async (doc, thunkAPI) => {
        try {
            const res = await ContactsAPI.contactById(doc.contact_id);
            thunkAPI.dispatch(getContactAllSkills(doc));
            thunkAPI.dispatch(getContactAccountManagers(doc));
            thunkAPI.dispatch(getContactRecruiters(doc));
            return res.data as IContact;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


/// [Company] ---> Skills
export const getContactAllSkills = createAsyncThunk<
    ISkillsInfo,
    { contact_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/contact_info/contact/skills/get',
    async (payload, thunkAPI) => {
        try {
            const res = await ContactsAPI.getContactSkills(payload);
            if (typeof res.data === 'string') return {
                [TALENT_SKILLS_TYPE.professional_skills]: [],
                [TALENT_SKILLS_TYPE.certifications]: [],
                [TALENT_SKILLS_TYPE.education]: [],
                [TALENT_SKILLS_TYPE.credentials]: [],
                [TALENT_SKILLS_TYPE.safety_equipment]: []
            };
            return res.data as ISkillsInfo;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const addContactSkills = createAsyncThunk<
    string,
    { contact_id: string, value: IAddSkill },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/contact_info/contact/skills/post',
    async (data, thunkAPI) => {
        try {
            const res = await ContactsAPI.addContactSkill(data);
            thunkAPI.dispatch(getContactAllSkills({ contact_id: data.contact_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeContactSkills = createAsyncThunk<
    string,
    { contact_id: string, value: IRemoveSkill },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/contact_info/contact/skills/delete',
    async (data, thunkAPI) => {
        try {
            const res = await ContactsAPI.deleteContactSkill(data);
            thunkAPI.dispatch(getContactAllSkills({ contact_id: data.contact_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);



/// [Company]--> [Alpha omega staffing] --> staff

export const getContactAccountManagers = createAsyncThunk<
    Array<Profile>,
    { contact_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/contact_info/contact/account_managers/get',
    async (query, thunkAPI) => {
        try {
            const res = await ContactsAPI.getContactStaff({ ...query, role: ROLE.accountManager, });
            if (typeof res.data === 'string') return [];
            return res.data as Profile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addContactAccountManagers = createAsyncThunk<
    string,
    IAddContactStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/contact_info/contact/account_managers/post',
    async (data, thunkAPI) => {
        try {
            const res = await ContactsAPI.addContactStaff(data);
            thunkAPI.dispatch(getContactAccountManagers({ contact_id: data.contact_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeContactAccountManagers = createAsyncThunk<
    string,
    IRemoveContactStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/contact_info/contact/account_managers/delete',
    async (data, thunkAPI) => {
        try {
            const res = await ContactsAPI.deleteContactStaff(data);
            thunkAPI.dispatch(getContactAccountManagers({ contact_id: data.contact_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const getContactRecruiters = createAsyncThunk<
    Array<Profile>,
    { contact_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/contact_info/contact/recruiters/get',
    async (query, thunkAPI) => {
        try {
            const res = await ContactsAPI.getContactStaff({ ...query, role: ROLE.recruiter, });
            if (typeof res.data === 'string') return [];
            return res.data as Profile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addContactRecruiters = createAsyncThunk<
    string,
    IAddContactStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/contact_info/contact/recruiters/post',
    async (data, thunkAPI) => {
        try {
            const res = await ContactsAPI.addContactStaff(data);
            thunkAPI.dispatch(getContactRecruiters({ contact_id: data.contact_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeContactRecruiters = createAsyncThunk<
    string,
    IRemoveContactStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/contact_info/contact/recruiters/delete',
    async (data, thunkAPI) => {
        try {
            const res = await ContactsAPI.deleteContactStaff(data);
            thunkAPI.dispatch(getContactRecruiters({ contact_id: data.contact_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);