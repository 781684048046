import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import MenuOption from '../../../components/menu_option/menu_option';
import { ROLE } from '../../../enums';
import { AccountManagerIcon, AdministratorIcon, BranchManagerIcon, CallDeckOwnerIcon, RecruiterIcon, SuperUserIcon } from '../../../icons';
import { AppRoutes } from '../../../routes';

interface Props extends RouteComponentProps<any> { }

const options = [
    {
        title: 'administrator',
        icon: AdministratorIcon,
        route: AppRoutes.roleManagementAdministrator,
        role: ROLE.administrator,
    },
    {
        title: 'super_user',
        icon: SuperUserIcon,
        route: AppRoutes.roleManagementSuperUser,
        role: ROLE.superUser,
    },
    {
        title: 'branch_manager',
        icon: BranchManagerIcon,
        route: AppRoutes.roleManagementBranchManager,
        role: ROLE.branchManager,
    },
    {
        title: 'account_manager',
        icon: AccountManagerIcon,
        route: AppRoutes.roleManagementAccountManager,
        role: ROLE.accountManager,
    },
    {
        title: 'recruiter',
        icon: RecruiterIcon,
        route: AppRoutes.roleManagementRecruiter,
        role: ROLE.recruiter,
    },
    {
        title: 'call_deck_owner',
        icon: CallDeckOwnerIcon,
        route: AppRoutes.roleManagementCallDeckOwner,
        role: ROLE.callDeskOwner,
        
    },
]


const AdminCenterRoleManagementPage: React.FunctionComponent<Props> = (props) => {
    const { history } = props;
    const { t } = useTranslation();
    return (
        <div className="menu-option-container">
            {options.map((doc, index) => {
                return (
                    <MenuOption key={index} doc={doc} t={t} onClick={() => history.push({ pathname: doc.route, search: `role=${doc.role}`, state: { role: doc.role } })} />
                )
            })}

        </div>

    )
}

export default AdminCenterRoleManagementPage;