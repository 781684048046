import { createAsyncThunk } from "@reduxjs/toolkit";
import { SalesMarketingAPI } from "../../../apis/sales_marketing_api";
import { AppError } from "../../../interfaces";
import { MarketingCampaign } from "../../../interfaces/marketing_campaign";
import { catchRequestError } from "../../../utils";

export const getMarketingCampaigns = createAsyncThunk<
    Array<MarketingCampaign>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-and-marketing/marketing-campaign/get',
    async (_, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.getMarketingCampaigns();
            return res.data.data ?? res.data as MarketingCampaign[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createMarketingCampaign = createAsyncThunk<
    string,
    MarketingCampaign,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@salesmarketing/marketing-campaign/create',
    async (payload, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.createMarketingCampaign(payload);
            thunkAPI.dispatch(getMarketingCampaigns());
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

