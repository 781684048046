import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { ExportMappingGroup, LoadingType, THEME } from "../../../../enums";
import { BOOpenCloseIcon } from "../../../../icons";
import { CustomButton, SpinnerScreen } from "../../../../utils";
import "./import_file_popup.scss";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { AppError } from "../../../../interfaces";
import ApiError from "../../../../components/api_error";
import { lowBtnStyle, outlineBtnStyle } from "../../../../variables";

interface Props {
    visible: boolean;
    onClose: () => void;
    title: string;
    mapping: ExportMappingGroup | null;
    currentLoginUserName: string;
    error: AppError | null | undefined;
    loading: LoadingType;
    onGotoDirectory: () => void;
}


const ExportFilePopup: React.FunctionComponent<Props> = (props) => {
    const { onClose, visible, title, mapping, currentLoginUserName, error, loading, onGotoDirectory } = props;
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'40%'}
            maxWrapperWidth={'40%'}
            onClose={onClose}
            onDissmiss={onClose}>
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="import-file-container">
                    <div className="import-file-header">
                        <FormDialogTilteHeader
                            title={title}
                            titleIcon={<BOOpenCloseIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="import-file-content">
                        <DocSavedText>
                            {loading === LoadingType.pending &&
                                <SpinnerScreen />
                            }
                            {loading === LoadingType.succeeded &&
                                <div className="">
                                    <span className="aknw-name">
                                        {t("congratulations", {
                                            name: currentLoginUserName,
                                        })}
                                    </span>
                                    <span className="import-text">
                                        {t("your_file_has_generated", { mapping: mapping })}
                                    </span>
                                </div>
                            }
                            <div className="error-msg-holder">
                                {error && error.message &&
                                    <div className="">
                                        {/* <ApiError message={error.message} onClose={onClose} /> */}
                                        <span className="aknw-name">
                                            {t("hey_name", {
                                                name: currentLoginUserName,
                                            })}
                                        </span>
                                        <span className={error.code === 400 ? "warning-text" : "error-text"}>
                                            {t(error.message)}.
                                        </span>
                                    </div>
                                }
                            </div>
                        </DocSavedText>
                    </div>
                    <div className="import-file-actions">
                        {loading === LoadingType.succeeded &&
                            <div className="btn-new">
                                <CustomButton
                                    loading={false}
                                    textStyle={{
                                        textTransform: "capitalize",
                                        ...outlineBtnStyle.text,
                                    }}
                                    name={t("go_to_ie_directory")}
                                    enable={true}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={onGotoDirectory}
                                    customStyle={outlineBtnStyle.btn}
                                />
                            </div>
                        }
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('ok')}
                                backgroundColor={THEME.buttonColor1}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>

                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>);
}

export default ExportFilePopup;