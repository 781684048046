import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getAllKinisoJobOrdersThunk, selectKinisoJobsList, selectKinisoJobsListState } from "../../../redux/kiniso_job_orders";
import { useEffect, useState } from "react";
import { LoadingType } from "../../../enums";
import { IKinisoJobOrderResponse } from "../../../interfaces/kiniso_job_orders";
import './job_orders_list.scss';
import { getDateString } from "../../../variables";
import { CompaniesIcon, LocationIcon } from "../../../icons";
import { GlobalRoutes } from "../../../routes";
import SearchBar from "../../../components/search_bar";
interface Props extends RouteComponentProps<any> { }
const KinisoJobOrders: React.FunctionComponent<Props> = (props) => {
        const { history, location, match } = props;
        useEffect(() => {
                // Find the script element by its widget_id attribute
                const scriptElement = document.querySelector(
                  '#freshworks-container'
                );
                // Remove the script element if found
                if (scriptElement) {
                  scriptElement.remove();
                }
              }, []);
        const { params } = match;
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const [search, setSearch] = useState('');

        const jobsListState = useAppSelector((state) =>
                selectKinisoJobsListState(state)
        );
        const kinisoJobsList = useAppSelector((state) => selectKinisoJobsList(state));
        useEffect(() => {
                dispatch(getAllKinisoJobOrdersThunk({
                        token: params.key,
                        branch_id: params.branch_id,
                        agency_id: params.agency_id
                }));
                return () => { };
        }, []);
        const [jobOrderListData, setJobOrderListData] = useState<IKinisoJobOrderResponse[]>()
        useEffect(() => {
                if (jobsListState && jobsListState.loading === LoadingType.succeeded && kinisoJobsList.length !== 0) {
                        setJobOrderListData(kinisoJobsList)
                }
        }, [jobsListState, jobsListState.loading]);

        function handleJobSelect(value: IKinisoJobOrderResponse) {
                props.history.push({
                        pathname: `${GlobalRoutes.kinisoJobOrders}/${params.key}/${params.branch_id}/${params.agency_id}/${value.job_id}`,
                        state: {
                                id: value.job_id,
                                name: value.job_title ?? "",
                        },
                });
                return;
        }
        function getFilteredList(): IKinisoJobOrderResponse[] | undefined {
                return jobOrderListData?.filter(doc => {
                        const str = search.trim().toLowerCase();
                        
                        const jobTitleFilter = doc.job_title.trim().toLowerCase().includes(str);
                        const companyNameFilter = doc.hiring_organization.trim().toLowerCase().includes(str);
                        const loacationFilter = doc.job_location.trim().toLowerCase().includes(str);

                        return jobTitleFilter || companyNameFilter || loacationFilter;
                });

        }
        function handleChange(value: string) {
                setSearch(value);
        }
        function handleSearch() {
                console.log(search);
        }


        return (<>
                <div className="job-orderlist-container">
                        <div className="job-orderlist-header">
                                <div className="jobs-at-title">Job listings</div>
                                <SearchBar value={search} onChange={handleChange} onSearch={handleSearch} placeholder="Search for jobs by title, keyword, location or company name"/>
                        </div>
                        <div className="job-orderlist-content">
                                {getFilteredList()?.length === 0 && <>
                                        <div className="no-match">
                                                <span>No Matches Found!</span>
                                        </div>
                                </>}
                                {jobOrderListData !== undefined && getFilteredList()?.map((job, index) =>
                                        <>
                                                <div key={index} className="job-card">
                                                        <div className="job-card-row">
                                                                <div className="job-title" onClick={() => handleJobSelect(job)}>{job.job_title}</div>
                                                                <div className="posted">Posted {getDateString(job.date_posted)}</div>
                                                        </div>
                                                        <div className="job-card-row">
                                                                <div className="comp-loc-div"><div className="label-title"> <CompaniesIcon width={'100%'} height={'100%'} /><span>{job?.hiring_organization}</span></div>
                                                                        <div className="label-title"><LocationIcon width={'100%'} height={'100%'} /><span>{job?.job_location}</span></div>
                                                                </div>

                                                        </div>
                                                </div>
                                        </>)}
                        </div>
                        <div className="job-orderlist-footer">

                        </div>
                </div>
        </>)
}

export default KinisoJobOrders;