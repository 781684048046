import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import TabNavigation from "../../../components/tab_navigation/tab_navigation";
import { AppRoutes } from "../../../routes";
import { GetCloseAction } from "../../../utils";
import InDevelopment from "../../../others/in_development";
import BOImportPage from "../pages/import_export/import_page/import_page";
import IEReportsDirectoryPage from "../pages/import_export/reports_directory/reports_directory";
import BOExportPage from "../pages/import_export/export_page/export_page";

const ExportTabNav = (props: RouteComponentProps<any>) => {
    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();

    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[

                {
                    title: "Export",
                    route: `${AppRoutes.boExportFile}`,
                },
                {
                    title: "reports_directory",
                    route: `${AppRoutes.exportReportsDirectory}`,
                },

            ]}
            onChange={(value) =>
                history.push({ pathname: value, state: location.state })
            }
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.backOfficePage)} />}
        >
            <Switch>

                <Route
                    exact
                    path={`${AppRoutes.boExportFile}`}
                    component={BOExportPage}
                />
                <Route
                    exact
                    path={`${AppRoutes.exportReportsDirectory}`}
                    component={IEReportsDirectoryPage}
                />
                <Route
                    path={"/"}
                    render={({ location }) => (
                        <Redirect
                            to={{
                                pathname: `${location.pathname}/export-file`,
                                state: { ...(location.state as any) },
                            }}
                        />
                    )}
                />
            </Switch>
        </TabNavigation>
    );
}

export default ExportTabNav;


