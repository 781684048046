import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { AppError } from "../../../../interfaces";
import { IRCPaycheckRegister, IReportCenterBOPayload, IReportCenterUploadUrlResponse } from "../../../../interfaces/report_center";
import { catchRequestError } from "../../../../utils";

export const getPRListThunk = createAsyncThunk<
    IRCPaycheckRegister,
    IReportCenterBOPayload,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/payroll_tax/paycheck_register/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getPaycheckRegister(payload);
            return res.data as IRCPaycheckRegister;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

