import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LogoIcon, UserIcon } from '../../icons';
import { selectProfileState } from '../../redux/profile/profile_selector';
import { profileLogout } from '../../redux/profile/profile_thunk';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import SearchBar from '../search_bar';
import UserBadge from '../user_badge';
import './top_navbar.scss';
import KinisoIcon from '../../../assets/icons/kiniso.svg';

const TopNavBar = (props: any) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => selectProfileState(state));
  const [search, setSearch] = useState('');
  // const [isOpen, setIsOpen] = useState(false);


  // const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="nvb-top-nav" >
      {(profile?.agency_logo_download_url !== "" && profile?.agency_logo_download_url !== null) ?
        <div className='agency-logo'>
          <Link to={'/'}><img src={profile?.agency_logo_download_url} style={{ height: '2.9282576866764276vw' }} alt=""></img></Link>
        </div>
        : <Link to={'/'}><img src={KinisoIcon} style={{ height: '2.9282576866764276vw' }} alt=""></img></Link>}
      <div className="nvb-nav-actions">
        {/* <div className="nvb-search-bar">
          <SearchBar value={search} onChange={handleChnage} onSearch={handleSearch} />
        </div> */}
        <div className="nvb-user-badge">
          <UserBadge uri={props.profileURL} talentId={profile?.talent_id} 
          displayEmail={profile?.email ?? ''} 
          displayName={`${profile?.first_name ?? ''} ${profile?.last_name ?? ''}`}
          onLogout={handleLogout} 
          onProfile={handleProfile} 
          onProfileUpdate={props.handleProfileUpdate} 
          onPasswordUpdate={props.handlePasswordUpdate}
          />
        </div>
      </div>
    </div>
  );

  function handleChnage(value: string) {
    setSearch(value);
  }

  function handleSearch() {
    console.log(search);
  }

  function handleLogout() {
    dispatch(profileLogout());
  }

  function handleProfile() {
    console.log('profile');
  }
}

export default TopNavBar;