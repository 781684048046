import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import TabNavigation from "../../../components/tab_navigation/tab_navigation";
import { AppRoutes } from "../../../routes";
import UniversalDirectoryPage from "./universal_directory_page/universal_directory_page";
import { GetCloseAction } from "../../../utils";

interface Props extends RouteComponentProps<any> { }

/// Universal directory folder
export const PayrollTaxTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'universal_directory', route: AppRoutes.adminCenterPayrollTaxConfiguration },
            ]}
            onChange={(value) => history.push(value)}
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.adminCenterPage)}/>}
        >
            <Switch>
                <Route exact path={AppRoutes.adminCenterPayrollTaxConfiguration} component={UniversalDirectoryPage} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: AppRoutes.adminCenterPayrollTaxConfiguration,
                            state: { from: location }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );
}