import { useEffect, useState } from "react";
import { CustomButton, FormInput, RegxPattern } from "../../../../utils";
import { useTranslation } from "react-i18next";
import { LoadingType, THEME } from "../../../../enums";
import { Portal } from "react-portal";
import AuthenticationToken from "../../system_configuration/popups/authentication_token";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getTextEmAllAuthToken,
  selectTextEmAllAuthTokenState,
} from "../../../../redux/admin_center";
import { getSSOTokenThunk } from "../../../../redux/admin_center";
import { TextEmAllSSO } from "../../../../interfaces/admin_center_textemall";
import "./text_em_all.scss";
import { textEmAllAuthTokenSliceActions } from "../../../../redux/admin_center/system_configuration/text_em_all/text_em_all_reducer";

interface Props {
  title: boolean;
  userLevelValue: boolean;
  titleHeader: boolean;
}
const TextEmAllPage: React.FunctionComponent<Props> = (props) => {
  const { title, userLevelValue, titleHeader } = props;
  const [showAuthTokenDialog, setShowAuthTokenDialog] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const getSSOTokenState = useAppSelector((state) =>
    selectTextEmAllAuthTokenState(state)
  );
  const initalForm = {
    UserName: "",
    Password: "",
    user_level: userLevelValue ? userLevelValue : false,
  };
  const [formState, setFormState] = useState<TextEmAllSSO>(initalForm);
  const [authToken, setAuthToken] = useState<string>("");
  useEffect(() => {
    if (getSSOTokenState.loading === LoadingType.succeeded) {
      setAuthToken(getSSOTokenState.data.authorization_token);
    }
  }, [
    getSSOTokenState,
    getSSOTokenState.data.authorization_token,
    getSSOTokenState.loading,
  ]);

  useEffect(() => {
    dispatch(getTextEmAllAuthToken());
    return () => { };
  }, []);

  function handleAuthTokenCloseDialog() {
    setShowAuthTokenDialog(false);
    setFormState(initalForm);
    dispatch(textEmAllAuthTokenSliceActions.clearTokenError());
  }

  function handleFieldChange(fieldId, value) {
    setFormState({ ...formState, [fieldId]: value });
  }
  function handleGenerateToken(credentials: TextEmAllSSO) {
    dispatch(getSSOTokenThunk(credentials));
  }
  useEffect(() => {
    if (
      getSSOTokenState &&
      getSSOTokenState?.error === null &&
      getSSOTokenState.loading === LoadingType.succeeded
    ) {
      setShowAuthTokenDialog(false);
      setFormState(initalForm);
    }
  }, [getSSOTokenState, getSSOTokenState?.error]);
  function _isFormValid() {
    const { UserName, Password } = formState;
    const pattern = RegxPattern.email;
    if (UserName === "") return false;
    if (pattern.test(UserName) === false) return false;
    if (Password === "") return false;
    return true;
  }
  function handleClearError() {
    dispatch(textEmAllAuthTokenSliceActions.clearTokenError());
  }

  return (
    <>
      <div className="ac-sc-text-em-all-input">
        <div>
          <FormInput
            id={"token"}
            onChange={handleFieldChange}
            type={"text"}
            value={authToken}
            customStyle={{ minWidth: "20vw" }}
            label={title ? t("text_em_all_authorization_token") : t("authorization_token")}
            placeholder=""
            disabled={true}
          />
        </div>
        <div className="generate-btn">
          <CustomButton
            textStyle={{ textTransform: "capitalize" }}
            name={t("generate")}
            backgroundColor={THEME.buttonColor1}
            onClick={() => setShowAuthTokenDialog(true)}

          />
        </div>

        {showAuthTokenDialog && (
          <Portal>
            <AuthenticationToken
              visible={showAuthTokenDialog}
              onClose={handleAuthTokenCloseDialog}
              onDismiss={handleAuthTokenCloseDialog}
              handleFieldChange={handleFieldChange}
              onSubmit={handleGenerateToken}
              error={getSSOTokenState.error}
              isFormVaid={_isFormValid()}
              loading={getSSOTokenState.loading === LoadingType.pending}
              formState={formState}
              onClearError={handleClearError}
              titleHeader={titleHeader}
            />
          </Portal>
        )}
      </div>
      {/* <div style={{"width":"100%"}}>
        <iframe
          src="https://www.text-em-all.com/"
          title="showing text-em-all"
          width="100%"
          height="100%"
        ></iframe>
      </div> */}
    </>
  );
};

export default TextEmAllPage;
