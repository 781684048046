import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IFileUplodState, IURLState, Profile } from "../../../interfaces";
import * as thunk from "./employee_signature_thunk";

export interface EmployeeSignatureState {
    error?: AppError | null,
    loading: LoadingType,
    response: any | null,

}

const initialState: EmployeeSignatureState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const employeeSignatureSlice = createSlice({
    name: 'employeeSignature',
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.updateEmployeeSignatureThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, } ))
            .addCase(thunk.updateEmployeeSignatureThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, response: null, } ))
            .addCase(thunk.updateEmployeeSignatureThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload } ))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const employeeSignatureSliceActions = employeeSignatureSlice.actions;
export const selectState = (state: EmployeeSignatureState) => state;


