import {createSlice} from "@reduxjs/toolkit";
import {Agency, AppError} from "../../../interfaces";
import {LoadingType} from "../../../enums";
import * as thunk from './agency_setup_thunk'

export interface AgencyDetails {
    error?: AppError | null,
    loading: LoadingType,
    agency: Agency | null
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    }
}

const initialState: AgencyDetails = {
    error: null,
    loading: LoadingType.idle,
    agency: null,
    update: {
        error: null,
        loading: LoadingType.idle,
        response: null
    }
}

export const agencyDetailsSlice = createSlice({
    name: "agencyDetails",
    initialState: initialState,
    reducers: {
        clearAgencyDetailsUpdateState(state) {
            return { ...state, update : { ...initialState.update }};
        },
        clearAgencyDetailsUpdateStateError(state) {
            return { ...state, create : { ...initialState.update , error: null }};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAgencyDetails.pending, (state, action) => ({...state, loading: LoadingType.pending, error: null}))
            .addCase(thunk.getAgencyDetails.rejected, (state, action) => ({...state, loading: LoadingType.failed, error: action.payload}))
            .addCase(thunk.getAgencyDetails.fulfilled, (state, action) => ({...state, loading: LoadingType.succeeded, agency: action.payload}))

            .addCase(thunk.updateAgencyDetails.pending, (state, action) => ({...state, update: {...state.update, loading: LoadingType.pending, error: null}}))
            .addCase(thunk.updateAgencyDetails.rejected, (state, action) => ({...state, update: {...state.update, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateAgencyDetails.fulfilled, (state, action) => ({...state, update: {...state.update, loading: LoadingType.succeeded, response: action.payload}}))
            .addDefaultCase(state => ({...state}));
    }
});

export const { clearAgencyDetailsUpdateState, clearAgencyDetailsUpdateStateError } = agencyDetailsSlice.actions;
export const selectState = (state: AgencyDetails) => state;