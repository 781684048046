import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";
import * as thunk from './manage_time_cards_thunk'


export interface ManageTimeCardsState {
    deleteTimeCard: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    removeTimeCard: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
}

const initialState: ManageTimeCardsState = {
    deleteTimeCard: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    removeTimeCard: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },

}

export const manageTimeCardsSlice = createSlice({
    name: "manageTimecards",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearDeleteTimeCardState(state) {
            return {
                ...state, deleteTimeCard: { ...initialState.deleteTimeCard },
            };
        },
        clearRemoveBatchState(state) {
            return {
                ...state, removeTimeCard: { ...initialState.removeTimeCard },
            };
        },
        clearDeleteTimeCardErrorState(state) {
            return {
                ...state, deleteTimeCard: { ...initialState.deleteTimeCard, error: null, },
            };
        },
        clearRemoveBatchErrorState(state) {
            return {
                ...state, removeTimeCard: { ...initialState.removeTimeCard, error: null, },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.deleteTimeCardThunk.pending, (state, action) => ({ ...state, deleteTimeCard: { ...state.deleteTimeCard, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.deleteTimeCardThunk.rejected, (state, action) => ({ ...state, deleteTimeCard: { ...state.deleteTimeCard, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.deleteTimeCardThunk.fulfilled, (state, action) => ({ ...state, deleteTimeCard: { ...state.deleteTimeCard, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addCase(thunk.removeTimeCardBatchThunk.pending, (state, action) => ({ ...state, removeTimeCard: { ...state.removeTimeCard, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.removeTimeCardBatchThunk.rejected, (state, action) => ({ ...state, removeTimeCard: { ...state.removeTimeCard, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.removeTimeCardBatchThunk.fulfilled, (state, action) => ({ ...state, removeTimeCard: { ...state.removeTimeCard, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase((state) => ({ ...state }));
    }
});
export const manageTimeCardsSliceActions = manageTimeCardsSlice.actions;
export const selectState = (state: ManageTimeCardsState) => state;