import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import ApiError from '../../../../components/api_error';
import DialogWrapper, { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, THEME } from '../../../../enums';
import { ChatIcon } from '../../../../icons';
import { AppError } from '../../../../interfaces';
import { getTalentAvailableDatesThunk, postSnackbarMessage, selectProfileState, selectSingleTalentAvailableDatesStateById, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { talentAvailableDatesActions } from '../../../../redux/talent/details/dashboard/talent_availability/talent_availability_reducer';
import { CustomButton, SpinnerScreen } from '../../../../utils';
import SearchTalent from '../../components/search_talent/search_talent';
import SelectAvailabilityOptions from '../../forms/select_availability_options/select_availability_options';
import SelectTalentForm from '../../forms/select_talent_form/select_talent_form';
import './mark_talent_available.scss';

interface Props {
    visible: boolean,
    title: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

const MarkTalentAvailablePopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [talent, setTalent] = useState<{ id: string, name: string } | null>(null);
    const [activeForm, setActiveForm] = useState<"select_talent" | "select_option" | "select_range">('select_talent');
    const profileState = useAppSelector((state) => selectProfileState(state));




    // useEffect(() => {
    //     if (createState.loading == LoadingType.succeeded) {
    //         dispatch(postSnackbarMessage(createState.response ?? null));
    //         // closePopup(onSuccessClose);
    //     }
    //     return () => { }
    // }, [createState.loading])



    function isLoading() {
        return false;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="mark-tlnt-pop-holder">
                    {getUI()}
                </div>

            </DialogWrapper>
        </ActionDialogHolder>
    )


    function getUI() {
        const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`;
        if (isLoading()) {
            return (
                <div className="loading-spinner"><SpinnerScreen /></div>
            )
        }


        if (activeForm === 'select_option' && talent && talent.id != "") {
            return (
                <SelectAvailabilityOptions
                    title={t('almost_done_which_would_you_like_to_do_next', { name: currentLoginUserName })}
                    talent={talent}
                    onGoBack={() => setActiveForm('select_talent')}
                    onClose={() => { closePopup(onClose) }}
                    onSuccessClose={handleMarkSuccessClose}
                />
            )
        }

        return (
            <SelectTalentForm
                title={t('hi_who_would_you_like_to_mark_available', { name: currentLoginUserName })}
                talent={talent}
                loading={false}
                error={null}
                onNext={handleNext}
                onChange={setTalent}
                onCancel={() => closePopup(onClose)}
                onClearError={() => { }}
            />
        )
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function handleNext() {
        if (talent) {
            setActiveForm('select_option');
        }
    }

    function handleMarkSuccessClose() {
        closePopup(onClose);
    }
}


export default MarkTalentAvailablePopup;