import './status_banner.scss';

interface Props {
    title: string,
    containerClassName?: string,
    textClassName?: string,
}

const StatusBanner: React.FunctionComponent<Props> = (props) => {

    return (
        <div className={`status-mark-container ${props.containerClassName}`}>
            <div className={`title-text ${props.textClassName}`}>
                <span>{props.title}</span>
            </div>
        </div>
    )
}

export default StatusBanner;