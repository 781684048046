import { createAsyncThunk } from "@reduxjs/toolkit";
import {  EngagementAPI } from "../../apis";
import { AppError, IEngagement } from "../../interfaces";
import { catchRequestError } from "../../utils";

export const getEngagementDataById = createAsyncThunk<
    Array<IEngagement>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@engagement-details/get',
    async (id, thunkAPI) => {
        try {
            const res = await EngagementAPI.getEngagementDataById(id);
            if (typeof res.data === 'string') return [];
            return res.data as IEngagement[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const saveEngagement = createAsyncThunk<
    string,
    IEngagement,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@engagement-details/save',
    async (data, thunkAPI) => {
        try {
            const res = await EngagementAPI.addEngagement(data);
           thunkAPI.dispatch(getEngagementDataById(data.object_id));
           return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateEngagement = createAsyncThunk<
    string,
    IEngagement,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@engagement-details/update',
    async (data, thunkAPI) => {
        try {
            const res = await EngagementAPI.updateEngagement(data);
           thunkAPI.dispatch(getEngagementDataById(data.object_id));
           return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteEngagement = createAsyncThunk<
    string,
    IEngagement,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@engagement-details/delete',
    async (data, thunkAPI) => {
        try {
            const res = await EngagementAPI.deleteEngagement(data.id);
           thunkAPI.dispatch(getEngagementDataById(data.object_id));
           return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);