import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloSearcImportAPI } from "../../../apis";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { IApolloCredits } from "../../../interfaces/apollo_search_import";



export const apolloGetCreditsThunk = createAsyncThunk<
    IApolloCredits,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@search-people-apollo/get-credits/get',
    async (credentials, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.apolloGetCreditInfo();
            return res.data as IApolloCredits;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const apolloAddCreditsThunk = createAsyncThunk<
    string,
    { export_credits: Number, mobile_credits: Number },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@search-people-apollo/add-credits/get',
    async (data, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.apolloAddCredits(data.export_credits, data.mobile_credits);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);