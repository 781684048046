import { createEntityAdapter, createSlice, EntityAdapter, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, ITalentInHouseInterview } from "../../../../../interfaces";
import * as thunk from "./request_ssn_thunk";

interface CRUDState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialValidateState: CRUDState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


export interface SingleTalentSSNRequestState {
    data: { ssn: string } | null,
    error?: AppError | null,
    loading: LoadingType,
    validate: CRUDState,
}


export interface TalentSSNRequestState {
    [key: string]: SingleTalentSSNRequestState,
}


const initialState: TalentSSNRequestState = {}


export const talentSSNRequestSlice = createSlice({
    name: 'talentSSNRequest',
    initialState: initialState,
    reducers: {
        initialiseRequestSSN(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    data: null,
                    loading: LoadingType.idle,
                    error: null,
                    validate: { ...initialValidateState },
                }
            };
        },
        clearSingleTalentRequestSSNError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    error: null,
                }
            };
        },
        clearSingleTalentSSNRequestValidateStateError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    validate: {
                        ...state[action.payload].validate,
                        error: null,
                    },
                }
            }
        },
        clearSingleTalentRequestSSNManageState(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    loading: LoadingType.idle,
                    error: null,
                    data: null,
                    validate: { ...initialValidateState },
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getTalentSSNThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.pending,
                        error: null,
                    }
                })
            )
            .addCase(
                thunk.getTalentSSNThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.failed,
                        error: action.payload,
                    }
                })
            )
            .addCase(
                thunk.getTalentSSNThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        data: action.payload,
                        loading: LoadingType.succeeded,
                        error: null,
                    }
                })
            )
            /// Validate user
            /// Add new talent in house interview
            .addCase(
                thunk.validateUserBeforeSSNThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        validate: {
                            ...state[action.meta.arg.talent_id].validate,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.validateUserBeforeSSNThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        validate: {
                            ...state[action.meta.arg.talent_id].validate,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.validateUserBeforeSSNThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        validate: {
                            ...state[action.meta.arg.talent_id].validate,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentRequestSSNActions = talentSSNRequestSlice.actions;
export const selectState = (state: TalentSSNRequestState) => state;
export const selectSingleState = (state: TalentSSNRequestState, id: string) => state[id];

export const selectSingleTalentRequestSSNInitialState = (): SingleTalentSSNRequestState => {
    return {
        loading: LoadingType.pending,
        error: null,
        data: { ssn: '' },
        validate: initialValidateState,
    }
}


