import { PanoramaSharp } from "@material-ui/icons";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI } from "../../../../apis";
import { AppError, ICompanySalesProfile, ICompanyServiceProfile, ICompetitors, IShift } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getCompanySalesProfile = createAsyncThunk<
    ICompanySalesProfile,
    { companyId: string, departmentId?: string | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/companies/company-sales-profile/get',
    async (query, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanySalesProfile(query);
            if (typeof res.data === 'string') return {};
            return res.data as ICompanySalesProfile;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Save Company Profile
export const _saveCompanySalesProfile = createAsyncThunk<
    string,
    ICompanySalesProfile,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/companies/company-sales-profile/save',
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.saveCompanySalesProfile(payload.company_id, payload);
            thunkAPI.dispatch(getCompanySalesProfile({companyId:payload.company_id,departmentId:payload.department_id}));
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getCompetitors = createAsyncThunk<
    Array<any>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/companies/company-sales-profile/getCompetitors',
    async (_, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompetitors();
            return res.data as ICompetitors[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getCompanyServiceProfile = createAsyncThunk<
    ICompanyServiceProfile,
    { companyId: string, departmentId?: string | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/companies/company-service-profile/get',
    async (query, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanyServiceProfile(query);
            if (typeof res.data === 'string') return {};
            return res.data as ICompanyServiceProfile;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Save Company Profile
export const _saveCompanyServiceProfile = createAsyncThunk<
    string,
    ICompanyServiceProfile,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/companies/company-service-profile/save',
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.saveCompanyServiceProfile(payload);
            thunkAPI.dispatch(getCompanyServiceProfile({companyId:payload.company_id,departmentId:payload.department_id}));
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// get Company Shifts
export const getCompanyShifts = createAsyncThunk<
Array<IShift>,
{ companyId: string, departmentId: string | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/companies/company-shifts/get',
    async (query, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanyShifts(query);
            return res.data as IShift[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

//save Company Shift
export const saveCompanyShift = createAsyncThunk<
    string,
    IShift,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/companies/company-shifts/save',
    async (payload, thunkAPI) => {
        try {
            const res = await (payload.id != "" ? CompaniesAPI.updateCompanyShift(payload) : CompaniesAPI.saveCompanyShift(payload));
            thunkAPI.dispatch(getCompanyShifts({companyId:payload.company_id,departmentId:payload.department_id}));
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteCompanyShift = createAsyncThunk<
    string,
    IShift,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@companies/universal-directory/companies/company-shifts/delete',
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.deleteCompanyShift(payload.id);
            thunkAPI.dispatch(getCompanyShifts({companyId:payload.company_id,departmentId:payload.department_id}));
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
