import SearchBar from '../../../../components/search_bar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { IJobOrder } from '../../../../interfaces';
import {
    getJobsListThunk,
    getTalentList,
    postSnackbarMessage,
    selectJobsList,
    selectJobsListState,
    useAppDispatch,
    useAppSelector,
    selectRoleUsersListState,
    getRoleUsersList,
    selectAccountManagerRoleUsersList,
    selectRecruiterRoleUsersList
} from '../../../../redux/store';
import { AppRoutes } from '../../../../routes';
import './universal_directory_page.scss';
import { CustomButton, sortByOrders, currencyConversion, getTableAccessIcon, getPlusBtnIcon, convertNumberToMoney } from '../../../../utils';
import { JOB_STATUS, LoadingType, THEME } from '../../../../enums';
import TableEmpty, { TableErrorHandler, TableFilterEmpty, TableLoading } from '../../../../components/table_empty/table_empty';
import SortableTable, { TableData } from '../../../../components/sortable_table/sortable_table';
import { Portal } from 'react-portal';
import { Rating } from '@material-ui/lab';
import { TickGreenIcon, TickGreyIcon, TickRedIcon } from '../../../../icons';
import CustomFormSelect, { CustomMultiFormSelect } from '../../../../components/form_selector/form_select';
import { jobsStatusOptions } from '../../../../variables';
import CreateJobPopup from '../../popups/create_job/create_job';
import { manageJobActions } from '../../../../redux/job_order/universal_directory/manage_job/manage_job_reducer';
import { jobPostQuestionSliceActions } from '../../../../redux/job_order/details/success_profile/job_post/job_post_reducer';

interface Props extends RouteComponentProps<any> { }
const tableHeader = [
    { title: 'access_job', code: 'access_job' },
    { title: 'Orders', code: 'orders' },
    { title: 'Job Order', code: 'job_title' },
    { title: 'Company', code: 'company_name' },
    { title: 'Department', code: 'department_name' },
    { title: 'Rating', code: 'rating' },
    { title: 'Status', code: 'job_status' },
    { title: 'Pay Rate', code: 'regular_pay_rate' },
    { title: 'Bill Rate', code: 'regular_bill_rate' },
    // { title: 'WC Code', code: 'wc_code' },
    { title: 'Requested', code: 'requested' },
    { title: 'Placed', code: 'placed' },
    { title: 'Still Needed', code: 'open_orders' },
    // { title: 'Action', code: '' },
];


const JobsPage: React.FunctionComponent<Props> = (props) => {
    const { history } = props;
    const { location } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const jobListState = useAppSelector((state) => selectJobsListState(state));
    const jobList = useAppSelector((state) => selectJobsList(state));
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);

    const [sortDirection, setSortDirection] = useState("asc");

    const [showAddJob, setShowAddJob] = useState<boolean>(false);
    const [gotoJobOrder, setGotoJobOrder] = useState(false);
    const [newJobId, setNewJobId] = useState<string>('');
    const [title, setTitle] = useState("");
    const accountManagersList = useAppSelector((state) => selectAccountManagerRoleUsersList(state));
    const recruitersList = useAppSelector((state) => selectRecruiterRoleUsersList(state));
    const [accountManagers, setAccountManagers] = useState<string[]>([]);
    const [recruiters, setRecruiters] = useState<string[]>([]);
    // const [status, setStatus] = useState<string | null>(null);
    const [status, setStatus] = useState<string[]>([]);

    useEffect(() => {
        getJobs();
        checkAddJob();
        dispatch(getRoleUsersList());
        dispatch(jobPostQuestionSliceActions.clearState());
    }, []);

    useEffect(() => {
        if (jobListState.error != null && jobList.length != 0) {
            dispatch(postSnackbarMessage(jobListState.error.message));
        }
        return () => {
        }
    }, [jobListState.error]);

    useEffect(() => {
        if (gotoJobOrder && newJobId) {
            history.push({
                pathname: `${AppRoutes.jobDetailsPage}/${newJobId}/dashboard`,
                state: {
                    id: newJobId,
                    name: title
                },
            });
            setGotoJobOrder(false);

        }
    }, [gotoJobOrder])


    const getJobs = () => {
        dispatch(getJobsListThunk());
    }
    function checkAddJob() {
        setTimeout(() => {
            if (
                location &&
                location.state != null &&
                (location.state as any).newJoborder === true
            ) {
                setShowAddJob(true);
            }
        }, 1000);
    }
    function getFilteredList(): IJobOrder[] {
        let sortedList: IJobOrder[] | undefined;
        let resultList: IJobOrder[] | undefined;

        if (accountManagers.length != 0) {
            resultList = jobList.filter(doc => {
                const accountManagersfilter = (doc.account_managers && doc.account_managers.length != 0 && accountManagers.length != 0) ? doc.account_managers.some(s => accountManagers.indexOf(s) >= 0) : false;
                return accountManagersfilter;
            })
        }

        if (recruiters.length != 0) {
            resultList = jobList.filter(doc => {
                const recruitersfilter = (doc.recruiters && doc.recruiters.length != 0 && recruiters.length != 0) ? doc.recruiters.some(s => recruiters.indexOf(s) >= 0) : false;
                return recruitersfilter;
            })
        }

        // if (status != null && status != '') {
        //     resultList = (resultList ?? jobList).filter(doc => {
        //         const statusFilter = (doc.job_status && status) ? doc.job_status.trim().toLowerCase() === status.trim().toLowerCase() : false;
        //         return statusFilter;
        //     })
        // }

        if (status.length != 0) {
            resultList = (resultList ?? jobList).filter((doc) => {
                const filter =
                    doc.job_status && doc.job_status.length != 0
                        ? status.includes(doc.job_status)
                        : false;
                return filter;
            });
        }

        if (sortedField != null) {
            if (sortedField === "orders") {
                sortedList = sortByOrders([...(resultList ?? jobList)], "job_status");
            } else {
                sortedList = [...(resultList ?? jobList)].sort((a, b) => {
                    const valueA =
                        a[sortedField] != null && a[sortedField] != undefined
                            ? typeof a[sortedField] == typeof "1"
                                ? a[sortedField].trim().toLowerCase()
                                : a[sortedField]
                            : "";
                    const valueB =
                        b[sortedField] != null
                            ? typeof b[sortedField] == typeof "1"
                                ? b[sortedField].trim().toLowerCase()
                                : b[sortedField]
                            : "";
                    if (sortDirection === "asc") {
                        return valueA > valueB ? 1 : -1;
                    } else {
                        return valueA < valueB ? 1 : -1;
                    }
                });
            }
        }
        return (sortedList ?? resultList ?? jobList).filter(doc => {
            const str = search.trim().toLowerCase();
            const companyFilter = doc.company_name ? doc.company_name.trim().toLowerCase().includes(str) : false;
            const departmentFilter = doc.department_name ? doc.department_name.trim().toLowerCase().includes(str) : false;
            const jobFilter = doc.job_title ? doc.job_title.trim().toLowerCase().includes(str) : false;
            const statusFilter = doc.job_status ? doc.job_status.trim().toLowerCase().includes(str) : false;
            const payrateFilter = doc.regular_pay_rate ? convertNumberToMoney(doc.regular_pay_rate).toString().trim().toLowerCase().includes(str) : false;
            const billrateFilter = doc.regular_bill_rate ? convertNumberToMoney(doc.regular_bill_rate).toString().trim().toLowerCase().includes(str) : false;
            const requestedFilter = doc.requested ? doc.requested.toString().trim().toLowerCase().includes(str) : false;
            const placedFilter = doc.placed ? doc.placed.toString().trim().toLowerCase().includes(str) : false;
            const stillNeededFilter = doc.open_orders ? doc.open_orders.toString().trim().toLowerCase().includes(str) : false;
            return companyFilter || departmentFilter || jobFilter || statusFilter || payrateFilter || billrateFilter || requestedFilter || placedFilter || stillNeededFilter;
        });
    }

    function handleSortFieldChange(value: string) {
        if (value === "open_orders") {
            if (sortedField && sortedField === value) {
                //   setSortedField(null)
            } else {
                setSortedField(value);
            }
        }
        else {
            if (sortedField && sortedField === value) {
                // setSortedField(null)
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            } else {
                setSortedField(value);
                setSortDirection('asc');
            }
        }
    }

    function getOpenOrdersInfo(doc: any) {
        return (
            <>
                {doc.requested > 0 ?
                    <>
                        {doc.job_status === "OPEN" && <div className="open-orders-empty">
                            <div className="ord-icon">
                                <TickRedIcon width={"100%"} height={"100%"} />
                            </div>
                            <div className="open-ord-text">
                                <span>{`${doc.open_orders} Open`}</span>
                            </div>
                        </div>
                        }
                        {doc.job_status === "FILLED" && <div className="open-orders-empty">
                            <div className="ord-icon">
                                <TickGreenIcon width={"100%"} height={"100%"} />
                            </div>
                            <div className="filled-ord-text">
                                <span>{`${doc.placed} Filled`}</span>
                            </div>
                        </div>
                        }
                        {(doc.job_status === "CLOSED" || doc.job_status === "CANCELLED_BY_CLIENT" || doc.job_status === "MISTAKEN_ORDER")
                            && <div className="open-orders-empty">
                                <div className="ord-icon">
                                    <TickGreyIcon width={"100%"} height={"100%"} />
                                </div>
                                <div className="closed-ord-text">
                                    <span>{t('unfilled')}</span>
                                </div>
                            </div>
                        }
                    </>
                    :
                    <div className="open-orders-empty">
                        <div className="ord-icon">
                            <TickGreyIcon width={"100%"} height={"100%"} />
                        </div>
                        <div className="closed-ord-text">
                            <span>0 orders</span>
                        </div>
                    </div>
                }
            </>
        )

    }
    function handleJobSelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${value.id}`,
            state: {
                id: value.id,
                name: value.job_title,
            }
        })
        return;
    }

    function handleCompanySelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            }
        })
        return;
    }

    function handleDepartmentSelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.department_id,
                departmentName: value.department_name,
            }
        })
        return;
    }


    function getStatus(status: string) {
        if (status === JOB_STATUS.open) return (<span style={{ color: THEME.red }}>{t('open')}</span>);
        else if (status === JOB_STATUS.filled) return (<span style={{ color: THEME.buttonColor17 }}>{t('filled')}</span>);
        else if (status === JOB_STATUS.cancelledByClient) return (<span style={{ color: "#B1B1B1" }}>{t('cancel_by_client')}</span>);
        else if (status === JOB_STATUS.mistakenOrder) return (<span style={{ color: "#B1B1B1" }}>{t('mistaken_order')}</span>);
        else if (status === JOB_STATUS.closed) return (<span style={{ color: "#B1B1B1" }}>{t('closed')}</span>);
        else return (<span>{''}</span>);
    }

    // function toTitles(s) {
    //     return s.replace(/\w\S*/g, function (t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase(); });
    // }

    const getJobsTableList = () => {
        if (jobListState.loading === LoadingType.pending) {
            return (<TableLoading />);
        }
        if (jobListState.error != null && jobList.length === 0) {
            return (
                <TableErrorHandler error={jobListState.error} onRefresh={getJobs} />
            );
        }
        if (jobList.length === 0) {
            return (
                <TableEmpty title={t('no_jobs_found')} onClick={getJobs} />
            );
        }
        if (getFilteredList().length === 0) {
            return (
                <TableFilterEmpty title={t('no_jobs_found')} />
            );
        }
        return (
            <SortableTable
                headerList={tableHeader}
                sortedField={sortedField}
                onSortChange={handleSortFieldChange}
                flexNumber={getFlexNumber}
                isAsc={sortDirection}
            >
                {getFilteredList().map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleJobSelect(doc))}</TableData>
                            <TableData customStyle={{ flex: getFlexNumber(1) }}>{getOpenOrdersInfo(doc)}</TableData>
                            <TableData customStyle={{ flex: getFlexNumber(2) }} isButton={true} onClick={() => handleJobSelect(doc)}><span>{doc.job_title}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(3) }} isButton={true} onClick={() => handleCompanySelect(doc)}><span>{doc.company_name}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(4) }} isButton={true} onClick={() => handleDepartmentSelect(doc)}><span>{doc.department_name ?? t('corporate')}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(5) }} ><span>{getRatingCell(doc.rating)}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(6) }} ><span>{getStatus(doc.job_status)}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(7) }} ><span>{currencyConversion(doc.regular_pay_rate)}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(8) }} ><span>{currencyConversion(doc.regular_bill_rate)}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(9), color: THEME.statusInActiveColor }} ><span >{doc.requested}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(10), color: THEME.buttonColor17 }} ><span >{doc.placed}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(11), color: THEME.statusInActiveColor }} ><span >{doc.open_orders}</span></TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 2;
        if (value === 2) return 2;
        if (value === 3) return 2;
        if (value === 4) return 2;
        if (value === 5) return 1.5;
        if (value === 6) return 1.5;
        if (value === 7) return 2;
        if (value === 8) return 2;
        if (value === 9) return 1;
        if (value === 10) return 1.2;
        if (value === 11) return 1.3;
        return 1;
    }

    const getRatingCell = (value: number) => {
        return (
            <Rating name="read-only" value={value} readOnly />
        )
    }
    return (
        <>
            <div className="jbs-ud-container">
                <div className="jbs-ud-table-header">
                    <TableHeaderOptions
                        totalCount={getFilteredList().length}
                        search={search}
                        onSearchChange={(value) => setSearch(value)}
                        accountManagersList={accountManagersList.map(doc => ({ label: `${doc.first_name ?? ''} ${doc.last_name ?? ''}`, value: doc.id }))}
                        recruitersList={recruitersList.map(doc => ({ label: `${doc.first_name ?? ''} ${doc.last_name ?? ''}`, value: doc.id }))}
                        onChangeAccountManager={setAccountManagers}
                        onChangeRecruiter={setRecruiters}
                        selectedAccountManagers={accountManagers}
                        selectedRecruiters={recruiters}
                        selectedStatus={status}
                        onStatusChange={setStatus}
                        // companyStatusList={[{ label: 'all', value: '', backgroundColor: 'transparent' }, ...jobsStatusOptions]}
                        companyStatusList={[...jobsStatusOptions]}
                    />
                    <div className="jbs-ud-actions">
                        <div className="jbs-ud-add-user-btn">
                            <CustomButton
                                leftIcon={getPlusBtnIcon()}
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('add_job_order')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => setShowAddJob(true)}
                            />
                        </div>
                    </div>

                </div>
                <div className="jbs-ud-table">
                    {getJobsTableList()}
                </div>
            </div>
            {jobListState.loading === LoadingType.succeeded && <div>
                <div className="mlt-usrs-content">
                    <div className="custom-pab">
                        <div className="pab-title">
                            <span>Total Job Orders</span>
                        </div>
                        <div className='pab-requested-value'>
                            <span>
                                {jobList && jobList.length > 0 ? getFilteredList().reduce(function (prev: any, current: any) {
                                    return prev + +current.requested
                                }, 0) : 0}
                            </span>
                            Requested
                        </div>
                        <div className='pab-placed-value'>
                            <span>
                                {jobList && jobList.length > 0 ? getFilteredList().reduce(function (prev: any, current: any) {
                                    return prev + +current.placed
                                }, 0) : 0}
                            </span>
                            Placed
                        </div>
                        <div className='pab-over-value'>
                            <span>
                                {jobList && jobList.length > 0 ? getFilteredList().reduce(function (prev: any, current: any) {
                                    return prev + +current.open_orders
                                }, 0) : 0}
                            </span>
                            Still Needed
                        </div>
                    </div>
                </div>
            </div>
            }
            {showAddJob && <Portal>
                <CreateJobPopup
                    visible={showAddJob}
                    title={t('add_job_order')}
                    successTitle={t('new_job_order')}
                    onClose={() => setShowAddJob(false)}
                    onSuccessClose={handleAddJobSuccess}
                    onGotoAgencyDashboard={handleGotoAgencyDashboard}
                    onGotoCreated={handleOnGotoJob}
                />
            </Portal>}
        </>
    );


    ///Add user action
    function handleAddJobSuccess() {
        setShowAddJob(false);
        getJobs();
        dispatch(manageJobActions.clearManageJobState([]));
    }

    function handleGotoAgencyDashboard() {
        handleAddJobSuccess();
        props.history.push(AppRoutes.dashboardPage);
    }

    function handleOnGotoJob(id: string, name: string) {
        handleAddJobSuccess();
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${id}`,
            state: {
                id: id,
                name: name,
            }
        })
    }

}

interface TableHeaderOptionsProps {
    totalCount: number,
    search: string,
    onSearchChange: (value: string) => void,
    /// [Account managers users]
    accountManagersList: Array<{ label: string, value: string }>,
    selectedAccountManagers: string[],
    onChangeAccountManager: (value: string[]) => void,
    /// [Account managers users]
    recruitersList: Array<{ label: string, value: string }>,
    selectedRecruiters: string[],
    onChangeRecruiter: (value: string[]) => void,
    /// [Status]
    // selectedStatus: string | null,
    selectedStatus: string[];
    onStatusChange: (value: string[]) => void,
    companyStatusList: Array<{ label: string, value: string }>,
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
    const { t } = useTranslation();
    useEffect(() => {
        const state = history.state;
        if (state.state && state.state["openOrder"]) {
            const openOrder = ['OPEN']
            props.onStatusChange(openOrder)
        }
    }, [history]);
    return (
        <div className="jbs-ud-table-header-options">
            <div className="account-manager-selector">
                <CustomMultiFormSelect
                    label={t('account_manager')}
                    name={'account_manager'}
                    list={props.accountManagersList}
                    onChange={props.onChangeAccountManager}
                    required={false}
                    placeholder={t('select')}
                    value={props.selectedAccountManagers}
                    customStyle={{ width: '-webkit-fill-available' }}
                />
            </div>
            <div className="recruiter-selector">
                <CustomMultiFormSelect
                    label={t('recruiter')}
                    name={'recruiter'}
                    list={props.recruitersList}
                    onChange={props.onChangeRecruiter}
                    required={false}
                    placeholder={t('select')}
                    value={props.selectedRecruiters}
                    customStyle={{ width: '-webkit-fill-available' }}
                />
            </div>
            <div className="company-status-selector">
                {/* <CustomFormSelect
                    customStyle={{ width: '-webkit-fill-available' }}
                    name={"company_status"}
                    value={props.selectedStatus}
                    required={false}
                    label={t('status')}
                    placeholder={t('all')}
                    list={props.companyStatusList}
                    onChange={props.onStatusChange}
                /> */}
                <CustomMultiFormSelect
                    label={t("status")}
                    name={"company_status"}
                    list={props.companyStatusList}
                    onChange={props.onStatusChange}
                    required={false}
                    placeholder={t("all")}
                    value={props.selectedStatus}
                    customStyle={{ width: "-webkit-fill-available" }}
                />
            </div>
            <div className="jbs-ud-table-search-bar">
                <SearchBar value={props.search} onChange={props.onSearchChange} onSearch={() => { }} />
            </div>
            <div className="total-count">
                <span>{`${t('total_count')}: `} <span className="total-count-number">{props.totalCount}</span> </span>
            </div>
        </div>
    );
}


export default JobsPage;