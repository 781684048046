import { createAsyncThunk } from "@reduxjs/toolkit";
import { contactsByCompanyDepartment,   } from "../../../apis/contacts_api";
import { AppError, IContact, } from "../../../interfaces";
import { catchRequestError } from "../../../utils";

export const getContactsAllList = createAsyncThunk<
    Array<IContact>,
    {company_id: string, department_id: string | null} | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/contacts-all/get',
    async (query, thunkAPI) => {
        try {
            const res = await contactsByCompanyDepartment(query);
            if(typeof res.data === 'string') return [];
            return res.data as IContact[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);