import "./image_crop_popup.scss";
import {useRef} from "react";
import {
    ActionDialogHolder,
    ActionDialogHolderType
} from "../action_dialog_holder/action_dialog_holder";
import CloseIcon from "../../../assets/icons/close-btn2.svg";
import { useTranslation } from "react-i18next";
import StyledDropzone from "./image_crop";

interface Props {
    visible: boolean;
    agencyLogoDownloadUrl?: boolean;
    objectId: string | undefined;
    objectType: string;
    onClose: () => void;
    onDismiss: () => void;
    onApprove: () => void;
}


const UploadProfile: React.FunctionComponent<Props> = (props: Props) => {
    const {onClose, onDismiss, visible, onApprove, objectId, objectType, agencyLogoDownloadUrl} = props

    const {t} = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function handleSuccessCallback() {
        closePopup(onClose);
        onApprove();
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={800}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="profile-dialog-container">
                <div className="profile-dialog-header">
                        <h5>{t("update_profile_pic")}</h5>
                        <img style={{"cursor": "pointer"}} src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)}/>
                </div>
                <div className="profile-dialog-content">
                    <div>
                        <StyledDropzone objectId={objectId} objectType={objectType} successCallback={handleSuccessCallback} agencyLogoDownloadUrl={agencyLogoDownloadUrl} />
                    </div>
                </div>
            </div>
        </ActionDialogHolder>
    )
}

export default UploadProfile;