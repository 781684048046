import {
    createEntityAdapter,
    createSlice,
    EntityAdapter,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";
import * as thunk from "./job_post_thunk";
import { IJobPost } from "../../../../../interfaces/job_posting";

export interface JobPostQuestion {
    error?: AppError | null;
    loading: LoadingType;
    response: string;
    getData: {
        error?: AppError | null;
        loading: LoadingType;
        response: IJobPost | null;
    };
    postData: {
        error?: AppError | null;
        loading: LoadingType;
        response: string;
    };
    patchData: {
        error?: AppError | null;
        loading: LoadingType;
        response: string;
    };
    deleteData: {
        error?: AppError | null;
        loading: LoadingType;
        response: string;
    };
    postGoogleData: {
        error?: AppError | null;
        loading: LoadingType;
        response: string;
    };
}
const initialState: JobPostQuestion = {
    error: null,
    loading: LoadingType.idle,
    response: "",
    getData: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    postData: {
        error: null,
        loading: LoadingType.idle,
        response: "",
    },
    patchData: {
        error: null,
        loading: LoadingType.idle,
        response: "",
    },
    deleteData: {
        error: null,
        loading: LoadingType.idle,
        response: "",
    },
    postGoogleData: {
        error: null,
        loading: LoadingType.idle,
        response: "",
    },
};

export const jobPostQuestionSlice = createSlice({
    name: "jobPosting",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...state, ...initialState };
        },
        clearGetState(state) {
            return {
                ...state, getData: { ...initialState.getData }
            }
        },
        clearPostState(state) {
            return {
                ...state, postData: { ...initialState.postData }
            }
        },
        clearPatchState(state) {
            return {
                ...state, patchData: { ...initialState.patchData }
            }
        },
        clearDeleteState(state) {
            return {
                ...state, deleteData: { ...initialState.deleteData }
            }
        },
        clearPostGoogleState(state) {
            return {
                ...state, postGoogleData: { ...initialState.postGoogleData }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getjobpPostChatGPTQueryThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getjobpPostChatGPTQueryThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(
                thunk.getjobpPostChatGPTQueryThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.succeeded,
                    response: action.payload,
                })
            )

            .addCase(thunk.getJobPostThunk.pending, (state, action) => ({
                ...state,
                getData: { ...state.getData, loading: LoadingType.pending, error: null },

            }))
            .addCase(thunk.getJobPostThunk.rejected, (state, action) => ({
                ...state,
                getData: {
                    ...state.getData,
                    loading: LoadingType.failed,
                    error: action.payload,
                },
            }))
            .addCase(thunk.getJobPostThunk.fulfilled, (state, action) => ({
                ...state,
                getData: {
                    ...state.getData,
                    loading: LoadingType.succeeded,
                    response: action.payload,
                },
            }))

            .addCase(thunk.postJobPostThunk.pending, (state, action) => ({
                ...state,
                postData: { ...state.postData, loading: LoadingType.pending, error: null },
            }))
            .addCase(thunk.postJobPostThunk.rejected, (state, action) => ({
                ...state,
                postData: {
                    ...state.postData,
                    loading: LoadingType.failed,
                    error: action.payload,
                },
            }))
            .addCase(thunk.postJobPostThunk.fulfilled, (state, action) => ({
                ...state,
                postData: {
                    ...state.postData,
                    loading: LoadingType.succeeded,
                    response: action.payload,
                },
            }))

            .addCase(thunk.patchJobPostThunk.pending, (state, action) => ({
                ...state,
                patchData: { ...state.patchData, loading: LoadingType.pending, error: null },
            }))
            .addCase(thunk.patchJobPostThunk.rejected, (state, action) => ({
                ...state,
                patchData: {
                    ...state.patchData,
                    loading: LoadingType.failed,
                    error: action.payload,
                },
            }))
            .addCase(thunk.patchJobPostThunk.fulfilled, (state, action) => ({
                ...state,
                patchData: {
                    ...state.patchData,
                    loading: LoadingType.succeeded,
                    response: action.payload,
                },
            }))
            .addCase(thunk.deleteJobPostThunk.pending, (state, action) => ({
                ...state,
                deleteData: { ...state.deleteData, loading: LoadingType.pending, error: null },
            }))
            .addCase(thunk.deleteJobPostThunk.rejected, (state, action) => ({
                ...state,
                deleteData: {
                    ...state.deleteData,
                    loading: LoadingType.failed,
                    error: action.payload,
                },
            }))
            .addCase(thunk.deleteJobPostThunk.fulfilled, (state, action) => ({
                ...state,
                deleteData: {
                    ...state.deleteData,
                    loading: LoadingType.succeeded,
                    response: action.payload,
                },
            }))

            .addCase(thunk.postJobPostToGoogleThunk.pending, (state, action) => ({
                ...state,
                postGoogleData: { ...state.postGoogleData, loading: LoadingType.pending, error: null },
            }))
            .addCase(thunk.postJobPostToGoogleThunk.rejected, (state, action) => ({
                ...state,
                postGoogleData: {
                    ...state.postGoogleData,
                    loading: LoadingType.failed,
                    error: action.payload,
                },
            }))
            .addCase(thunk.postJobPostToGoogleThunk.fulfilled, (state, action) => ({
                ...state,
                postGoogleData: {
                    ...state.postGoogleData,
                    loading: LoadingType.succeeded,
                    response: action.payload,
                },
            }))

            .addDefaultCase((state) => ({ ...state }));
    },
});

export const jobPostQuestionSliceActions = jobPostQuestionSlice.actions;
export const selectState = (state: JobPostQuestion) => state;
