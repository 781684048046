import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloSearcImportAPI } from "../../../apis";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { IApolloIndustriesList } from "../../../interfaces/apollo_search_import";



export const apolloGetIndustriesDropDown = createAsyncThunk<
    Array<IApolloIndustriesList>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@search-people-apollo/get-industries/get',
    async (credentials, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.apolloGetIndustries();
            if(typeof res.data === 'string')  return [];
            
            return res.data as Array<IApolloIndustriesList>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);