import { createAsyncThunk } from "@reduxjs/toolkit";
import { S3API } from "../../../apis/s3_api";
import {
    AppError,
    IProfilePictureDownloadRequestQuery,
    IProfilePictureRequestQuery,
    IProfilePictureUploadUrlResponse
} from "../../../interfaces";
import { catchRequestError } from "../../../utils";


export const getPreSignedURLForUpload = createAsyncThunk<
    string,
    IProfilePictureRequestQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@files/profile-picture/pre-signed-url-for-upload/get',
    async (query, thunkAPI) => {
        try {
            const res = await S3API.getPreSignedURLForUpload(query)
            if (typeof res.data === 'string') return '';
            return (res.data as IProfilePictureUploadUrlResponse).url
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const getPreSignedURLForDownload = createAsyncThunk<
    string,
    IProfilePictureDownloadRequestQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@files/profile-picture/pre-signed-url-for-download/get',
    async (query, thunkAPI) => {
        try {
            const res = await S3API.getPreSignedURLForDownload(query)
            if (typeof res.data === 'string' && res.data.startsWith('profile picture not found for the id')) return '';
            return res.data.url
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

