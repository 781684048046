import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, EngagementActivityType } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getEngagementActivityType = createAsyncThunk<
    Array<EngagementActivityType>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/engagement_activity_type/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getEngagementActivityTypes();
            return res.data as EngagementActivityType[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createEngagementActivityType = createAsyncThunk<
    string,
    {'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/engagement_activity_type/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createEngagementActivityTypes(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteEngagementActivityType = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/engagement_activity_type/delete',
    async (method_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteEngagementActivityTypes(method_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateEngagementActivityType = createAsyncThunk<
    string,
    {'id': string, 'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/engagement_activity_type/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateEngagementActivityTypes(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);