import React from "react";
import SearchBar from "../../../components/search_bar";
import { CustomButton, getTextEmAllIcon } from "../../../utils";
import { useTranslation } from "react-i18next";

interface MatchProps {
  title: string;
  children: React.ReactNode;
  search: string;
  searchPlaceHolder?: string;
  onSearchChange: (value: string) => void;
  onSearch: () => void;
  showBroadCastBtn?: boolean;
  handleBroadCastTalent: () => void;
  enableBtn?: boolean;
  showMsg?: boolean;
  showErrorMsg?: string;
}

const MatchHolder: React.FunctionComponent<MatchProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="jdp-match-container">
      <div className="header">
        <div className="header-title">
          <span>{props.title}</span>
        </div>
        <div className="header-right">
          {props.showBroadCastBtn && (
            <div className={"broadcast_btn_div"}>
              <CustomButton
                leftIcon={getTextEmAllIcon()}
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("broadcast_to_talent")}
                enable={props.enableBtn}
                onClick={props.handleBroadCastTalent}
              />

              {props.showMsg && (
                <span className="select_talent_msg">{props.showErrorMsg}</span>
              )}
            </div>
          )}
          <SearchBar
            placeholder={props.searchPlaceHolder}
            value={props.search}
            onChange={props.onSearchChange}
            onSearch={props.onSearch}
          />
        </div>
      </div>
      <div className="content">{props.children}</div>
    </div>
  );
};

export default MatchHolder;
