import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ITalentAvailableCandidates } from "../../../../interfaces";
import * as thunk from "./talent_available_candidates_thunk";

export interface TalentAvailableCandidatesState extends EntityState<ITalentAvailableCandidates> {
    error?: AppError | null,
    loading: LoadingType,
    count:number | null,
}

const TalentAvailableCandidatesAdapter: EntityAdapter<ITalentAvailableCandidates> = createEntityAdapter<ITalentAvailableCandidates>({
    selectId: (doc) => doc.id,
})

const initialState: TalentAvailableCandidatesState = TalentAvailableCandidatesAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    count:null
})


export const talentAvailableCandidatesSlice = createSlice({
    name: 'talentAvailableCandidates',
    initialState: initialState,
    reducers: {
        clearTalentAvailableCandidatesError(state) {
            return { ...state, error: null };
        },
        clearTalentAvailableCandidatesState(state) {
            return TalentAvailableCandidatesAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getTalentAvailableCandidates.pending,
                (state, action) => ({
                    ...state,
                    loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
                    error: null,
                })
            )
            .addCase(
                thunk.getTalentAvailableCandidates.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getTalentAvailableCandidates.fulfilled,
                (state, action) => TalentAvailableCandidatesAdapter.setAll({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null
                },
                    action.payload
                )
            )

            .addCase(
                thunk.getTalentAvailableCandidatesCount.pending,
                (state, action) => ({
                    ...state,
                    loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
                    error: null,
                })
            )
            .addCase(
                thunk.getTalentAvailableCandidatesCount.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getTalentAvailableCandidatesCount.fulfilled,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null,
                    count:action.payload
                }
                    
                )
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearTalentAvailableCandidatesError, clearTalentAvailableCandidatesState } = talentAvailableCandidatesSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = TalentAvailableCandidatesAdapter.getSelectors();
export const selectState = (state: TalentAvailableCandidatesState) => state;
export const selectLoading = (state: TalentAvailableCandidatesState) => state.loading;
export const selectError = (state: TalentAvailableCandidatesState) => state.error;
export const selectCount=(state: TalentAvailableCandidatesState) => state.count;


