import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ICreateResponse, IProfilePictureUploadUrlResponse } from "../../../../interfaces";
import * as thunk from "./manage_company_thunk";

export interface ManageCompanyState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
    fileURL: {
        error?: AppError | null,
        loading: LoadingType,
        response?: IProfilePictureUploadUrlResponse,
    }
    upload: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    }
}

const initialState: ManageCompanyState = {
    create: {
        error: null,
        loading: LoadingType.idle,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
    },
    fileURL: {
        error: null,
        loading: LoadingType.idle,
    },
    upload: {
        error: null,
        loading: LoadingType.idle,
    },
}


export const manageComapnySlice = createSlice({
    name: 'manageCompany',
    initialState: initialState,
    reducers: {
        clearCreateCompanyState(state) {
            return { ...state, create: { ...initialState.create } };
        },
        clearUpdateCompanyState(state) {
            return { ...state, update: { ...initialState.update } };
        },
        clearManageCompanyStateError(state) {
            return {
                ...state,
                create: {
                    ...state.create,
                    error: null
                },
                update: {
                    ...state.update,
                    error: null
                },
                fileURL: {
                    ...state.fileURL,
                    error: null
                },
                upload: {
                    ...state.upload,
                    error: null
                }
            };
        },
        clearManageCompanyState(state) {
            return {
                ...state,
                create: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                update: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                fileURL: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                upload: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.createCompany.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createCompany.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createCompany.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateCompany.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateCompany.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateCompany.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getCompanyLogoUploadURLThunk.pending, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getCompanyLogoUploadURLThunk.rejected, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getCompanyLogoUploadURLThunk.fulfilled, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.uploadCompanyLogoThunk.pending, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadCompanyLogoThunk.rejected, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.uploadCompanyLogoThunk.fulfilled, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageCompanyActions = manageComapnySlice.actions;
export const selectState = (state: ManageCompanyState) => state;
export const selectCreateState = (state: ManageCompanyState) => state.create;
export const selectUpdate = (state: ManageCompanyState) => state.update;
export const selectFileURL = (state: ManageCompanyState) => state.fileURL;
export const selectUpload = (state: ManageCompanyState) => state.upload;


