import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./talent_list_reducer";

const select = (state: IRootState) => state.selectors.talentsDropdown;
export const selectTalentsDropdownListState = createSelector(select, selectState);
export const selectTalentsDropdownList = createSelector(
    select,
    selectAll
);

export const selectTalentsDropdownListEntities = createSelector(
    select,
    selectEntities
);