export class OnlineApplicationReceived {
  id: string;
  contact_information: OnlineApplicationReceivedContactInfo;
  work_history: OnlineApplicationReceivedWorkHistory[];
  education: OnlineApplicationReceivedEducation[];
  agree_with_terms_and_conditions: boolean;
  branch_id: string;
  professional_skills: string[];
  credentials: string[];
  certifications: string[];
  recruiter_id: string;
  agency_id: string;
  token: string;
  agency_domain: string;
  is_deleted: boolean;
  is_archived: boolean;
  date_of_submission: number;
  status: string;
  applied_for: string;
  job_id: string;
}
export class OnlineApplicationReceivedContactInfo {
  photo_path: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  phone: string;
  email: string;
  last_4_digits_of_ssn: string;
  resume_path: string;
  resume_file_name: string;
}
export class OnlineApplicationReceivedWorkHistory {
  job_title: string;
  start_date: number;
  company_name: string;
  end_date: number;
  company_department: string;
  is_currently_work_here: boolean;
  job_accomplishments_details_and_responsibilities: string;
}
export class OnlineApplicationReceivedEducation {
  last_school_attended: string;
  degree: string;
  year_completed: number;
  is_currently_in_progress: boolean;
  awards_achievement_and_honours: string;
}
