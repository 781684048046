import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { LoadingType, THEME } from "../../../../../enums";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { CustomButton, getPlusBtnIcon } from "../../../../../utils";
import { TableLoading } from "../../../../../components/table_empty/table_empty";
import { Portal } from "react-portal";
import TableBody from "./table_body/table_body";
import EditAction from "./edit_action/edit_action";
import CreateAction from "./create_action/create_action";
import { createInsuranceCompany, deleteInsuranceCompany, getInsuranceCompanyList, selectInsuranceCompanyList, selectInsuranceCompanyListState, updateInsuranceCompany } from "../../../../../redux/admin_center/system_configuration/risk_management";
import './risk_management.scss'
import { DeleteConfirmationDialog } from "../../../../components/delete_confirmation_dialog";
import { insuranceCompanyListActions } from "../../../../../redux/admin_center/system_configuration/risk_management/insurance_company/insurance_company_reducer";


interface Props extends RouteComponentProps<any> { }

const tableHeaderStatusCode = [
    { title: 'insurance_company_name', code: 'actions' },
    { title: 'street_address', code: 'actions' },
    { title: 'city', code: 'actions' },
    { title: 'state', code: 'actions' },
    { title: 'zip_code', code: 'actions' },
    { title: 'contact_name', code: 'actions' },
    { title: 'website', code: 'actions' },
    { title: 'actions', code: 'actions' },
];

const categories = {
    1: {
        label: "insurance_company", value: ["insurance_company_name", "street_address", "city", "state", "zip_code", "contact_name", "website"], addLabel: "add_insurance_company",
        deleteMessage: "delete_insurance_company_message",
        deleteLabel: "delete_insurance_company", updateLabel: "update_insurance_company",
        methods: {
            create: createInsuranceCompany, update: updateInsuranceCompany, remove: deleteInsuranceCompany,
            get: getInsuranceCompanyList
        },
        headers: tableHeaderStatusCode,
        clearCreateState: insuranceCompanyListActions.clearICCreateState,
        clearUpdateState: insuranceCompanyListActions.clearICUpdateState,
        clearDeleteState: insuranceCompanyListActions.clearICDeleteState,
        clearDeleteStateError: insuranceCompanyListActions.clearICDeleteStateError,
    },
}

const AdminCenterSCRiskManagementPage: React.FunctionComponent<Props> = (props) => {
    const { history } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [showDeleteDialog, setShowDeleteDialog] = useState<{ visible: boolean, title: string} | null>(null);
    const [dropdownChanged, setDropDownChanged] = useState(true);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [methodValue, setMethodValue] = useState({
        id: "",
        agency_id: "",
        branch_id: "",
        insurance_company_name: "",
        street_address: "",
        city: "",
        state: "",
        zip_code: "",
        contact_name: "",
        website: "",
        linkedin_url: "",
        twitter_url: "",
        facebook_url: "",
        pinterest_url: "",
        phone: "",
        is_deleted: ""
    });

    const rMInsuranceCompanyState = useAppSelector((state) => selectInsuranceCompanyListState(state))
    const rMInsuranceCompanyList = useAppSelector((state) => selectInsuranceCompanyList(state))

    const reduxMapping = {
        1: { state: rMInsuranceCompanyState, list: rMInsuranceCompanyList },
    }

    useEffect(() => {
        dispatch(getCategory().methods.get());
    }, [])


    useEffect(() => {
        if (getMapping()?.state?.create?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.create?.response));
            dispatch(getCategory()?.clearCreateState())
        }
        else if (getMapping()?.state?.update?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.update?.response));
            dispatch(getCategory()?.clearUpdateState())
        }
        else if (getMapping()?.state?.remove?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.remove?.response));
            dispatch(getCategory()?.clearDeleteState())
        }
    }, [
        getMapping()?.state?.create?.loading, 
        getMapping()?.state?.update?.loading, 
        getMapping()?.state?.remove?.loading
    ]);

    useEffect(() => {
        if (getMapping().state.remove.loading == LoadingType.succeeded)
            dispatch(getCategory().methods.get());
    }, [getMapping().state.remove.loading])

    function handleDropdownChange(value) {
        if (!value)
            value = '1';
        setDropDownChanged(true);
        setSelectedCategory(Number(value));
        dispatch(getCategory(value).methods.get());
    }


    function getCategory(id = null) {
        if (id)
            return categories[id];
        return categories[selectedCategory];
    }

    function getMapping(id = null) {
        if (id)
            return reduxMapping[id];
        return reduxMapping[selectedCategory];
    }


    function retryFetchingData() {
        setDropDownChanged(true);
        dispatch(getCategory().methods.get());
    }


    function handleShowEditDialog(method) {
        setShowEditDialog(true);
        setMethodValue({
            ...methodValue,
            'id': method.id,
            'agency_id': method.agency_id,
            'branch_id': method.branch_id,
            'insurance_company_name': method[getCategory().value[0]],
            'street_address': method[getCategory().value[1]],
            'city': method[getCategory().value[2]],
            'state': method[getCategory().value[3]],
            'zip_code': method[getCategory().value[4]],
            'contact_name': method[getCategory().value[5]],
            'website': method[getCategory().value[6]],
            'linkedin_url': method.social_media.linkedin_url,
            'twitter_url': method.social_media.twitter_url,
            'facebook_url': method.social_media.facebook_url,
            'pinterest_url': method.social_media.pinterest_url,
            'phone': method.phone,
            'is_deleted': method.is_deleted
        });
    }

    function handleShowDeleteDialog(id, title) {
        setShowDeleteDialog({visible : true, title: title});
        setDeleteItemId(id);
    }

    function handleCloseDeleteDialog() {
        setShowDeleteDialog(null);
        setDeleteItemId(null);
    }

    function handleDeleteApprove() {
        if (deleteItemId) {
            dispatch(getCategory().methods.remove(deleteItemId));
        }
        setSnackBarMessage(getCategory().deleteAlertMessage)
        handleCloseDeleteDialog();
    }

    return (
        <div className="ac-sc-rm-container">
            <div className="ac-sc-rm-header">
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="ac-sc-rm-dropdown">
                <div className="ac-sc-rm-dropdown-button">
                    <CustomFormSelect
                        name={t("risk_management_dropdown")}
                        list={Object.keys(categories).map(id => ({ value: id, label: categories[id].label }))}
                        onChange={(val) => handleDropdownChange(val)}
                        value={selectedCategory}
                        placeholder={t('insurance_company')}
                        required={false}
                        label={t('please_select_risk_management_category')}
                        customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}
                    />
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            leftIcon={getPlusBtnIcon()}
                            name={t(categories[selectedCategory].addLabel)}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => (setShowCreateDialog(true))}
                            customStyle={{ width: "max-content" }}
                        />
                    </div>
                </div>
            </div>
            <div className="ac-sc-rm-table">
                {
                    getMapping().state.loading == LoadingType.pending && dropdownChanged ? <TableLoading /> :
                        <TableBody
                            state={getMapping().state}
                            onRetry={retryFetchingData}
                            data={getMapping().list}
                            category={getCategory()}
                            handleShowEdit={handleShowEditDialog}
                            handleShowDelete={handleShowDeleteDialog}
                        />
                }
            </div>
            {showCreateDialog && <Portal>
                <CreateAction
                    visible={showCreateDialog}
                    onClose={() => setShowCreateDialog(false)}
                    onDismiss={() => setShowCreateDialog(false)}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                />
            </Portal>}
            {showDeleteDialog?.visible && (
                <DeleteConfirmationDialog
                    message={t(getCategory()?.deleteMessage, { title: showDeleteDialog?.title })}
                    onCancel={() => setShowDeleteDialog(null)}
                    onConfirm={handleDeleteApprove}
                    deleting={getMapping()?.state?.remove === LoadingType.pending}
                    deleteType={""}
                    customDeleteHeaderTitle={t(getCategory()?.deleteLabel)}
                    state={getMapping()?.state}
                    onClearError={getCategory()?.clearDeleteStateError()}
                />
            )}
            {showEditDialog && <Portal>
                <EditAction
                    visible={showEditDialog}
                    onClose={() => setShowEditDialog(false)}
                    onDismiss={() => setShowEditDialog(false)}
                    methodValue={methodValue}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                />
            </Portal>}
        </div>

    )
}

export default AdminCenterSCRiskManagementPage;