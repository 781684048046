import { createAsyncThunk } from "@reduxjs/toolkit";
import { RiskManagementAPI } from "../../../../../apis";
import { AppError, ITalentUnEmploymentClaimTalentData } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";

export const getTalentUnEmploymentClaimDetailsByTalentId = createAsyncThunk<
    ITalentUnEmploymentClaimTalentData,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/risk-management/universal-directory/un-employment-claims/talentId',
    async (id, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getUnEmploymentClaimsByTalentId(id);
            if (typeof res.data === 'string') return {};
            return res.data as ITalentUnEmploymentClaimTalentData;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);