import { RouteComponentProps } from "react-router-dom";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../redux/store";
import "./vacation_report_config_page.scss";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { IAdminRCVacationConfig } from "../../../interfaces/report_center";
import { allowNumbersWithDotAndFourDecimals, CustomButton, FormInput } from "../../../utils";
import { getVacationConfigThunk, selectVacationConfigState, updateVacationConfigThunk } from "../../../redux/admin_center";
import { LoadingType, THEME } from "../../../enums";
import { vacationConfigSlice } from "../../../redux/admin_center/report_center/vacation_config_reducer";
import ApiError from "../../../components/api_error";

const VacationConfigPage = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const vacationConfigState = useAppSelector((state) => selectVacationConfigState(state));
    const initialForm = {
        id: "",
        no_of_hours: null,
        earned_hours: null
    }
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);
    const [formState, setFormState] = useState<IAdminRCVacationConfig>(initialForm);
    useEffect(() => {
        dispatch(getVacationConfigThunk());
    }, [])
    useEffect(() => {
        if (vacationConfigState.loading === LoadingType.succeeded && vacationConfigState.response) {
            setFormState(vacationConfigState.response)
        }
    }, [vacationConfigState.response, vacationConfigState.loading]);

    useEffect(() => {
        if (vacationConfigState.update.loading === LoadingType.succeeded && vacationConfigState.update.response) {
            dispatch(getVacationConfigThunk());
            dispatch(postSnackbarMessage(vacationConfigState.update.response));
            dispatch(vacationConfigSlice.clearUpdateState());
        }
    }, [vacationConfigState.update.response, vacationConfigState.update.loading]);

    const handleFormChange = (id, val) => {
        setFormState({ ...formState, [id]: val === "" ? "" : parseFloat(val) })
    }

    function formValidators(value: { text: any, field: string }) {
        switch (value.field) {
            case "no_of_hours":
            case "earned_hours": {
                if (value.text === 0 || value.text === "0") return t('validators.hours_not_zero');
                if (value.text === null || value.text === "") return t('validators.required')
                return null;
            }
            default: {
                return null;
            }
        }
    };
    const _isFormValid = () => {
        const { no_of_hours, earned_hours } = formState;
        if (no_of_hours === 0 || no_of_hours === null) return false;
        if (earned_hours === 0 || earned_hours === null) return false;
        return true;
    };

    const handleSave = () => {
        setCheckValidationOnSubmit(true);
        if (_isFormValid()) {
            dispatch(updateVacationConfigThunk(formState));
            setCheckValidationOnSubmit(false);
        }
    };

    const handleCancel = () => {
        if (vacationConfigState.response)
            setFormState(vacationConfigState.response)
    }

    return (
        <div className="ac-vr-container">

            <div className="ac-vr-content">
                <div className="ac-vr-input">
                    <FormInput
                        id={"no_of_hours"}
                        label={t("no_of_hours")}
                        onChange={handleFormChange}
                        type={"text"}
                        value={formState?.no_of_hours}
                        placeholder=""
                        onKeyUp={(e) => allowNumbersWithDotAndFourDecimals(e)}
                        onKeyDown={(e) => allowNumbersWithDotAndFourDecimals(e)}
                        required={true}
                        validations={formValidators}
                        checkValidationOnSubmit={checkValidationOnSubmit}
                    />
                </div>
                <div className="ac-vr-input">
                    <FormInput
                        id={"earned_hours"}
                        label={t("earned_hours")}
                        onChange={handleFormChange}
                        type={"text"}
                        value={formState?.earned_hours}
                        placeholder=""
                        onKeyUp={(e) => allowNumbersWithDotAndFourDecimals(e)}
                        onKeyDown={(e) => allowNumbersWithDotAndFourDecimals(e)}
                        required={true}
                        validations={formValidators}
                        checkValidationOnSubmit={checkValidationOnSubmit}
                    />
                </div>
            </div>
            <div className="ac-vr-actions">
                <div>
                    {vacationConfigState.error &&
                        <div className={"error-section"}>
                            <ApiError message={vacationConfigState.error.message} onClose={() => dispatch(vacationConfigSlice.clearErrorState())} />
                        </div>
                    }
                    {vacationConfigState.update.error &&
                        <div className={"error-section"}>
                            <ApiError message={vacationConfigState.update.error.message} onClose={() => dispatch(vacationConfigSlice.clearUpdateErrorState())} />
                        </div>
                    }
                </div>
                <div className="actions-row">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            name={t("cancel")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleCancel}

                        />
                    </div>
                    <div className="hr-save-input">
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            loading={vacationConfigState.update.loading === LoadingType.pending}
                            name={t("save")}
                            backgroundColor={THEME.buttonColor1}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VacationConfigPage;