import TableEmpty, { TableErrorHandler } from "../../../../components/table_empty/table_empty"
import { LoadingType, THEME } from "../../../../enums"
import { LeftArrowIcon, RightArrowIcon } from "../../../../icons"
import VidePlayer from "./video_player"

const VideoData = ({ data, cursorLeftClick, cursorRightClick, cursor, onRetry, state }) => {
    if (state.loading === LoadingType.failed)
        return (<div className="job-order-documents-videos-content-body no-data"><TableErrorHandler error={state.error} onRefresh={onRetry} /></div>)
    if (data.length > 0) {
        return (
            <div className="job-order-documents-videos-content-body data-found">
                <LeftArrowIcon onClick={cursorLeftClick} style={{ cursor: cursor === 0 ? "auto" : "pointer" }} />
    
                {data[cursor] ? (
                    <VidePlayer
                        url={data[cursor].file_path}
                        title={data[cursor].file_name}
                        id={data[cursor].id}
                        onRetry={onRetry}
                        state={state}
                    />
                ) : null}
    
                {data[cursor + 1] ? (
                    <VidePlayer
                        url={data[cursor + 1].file_path}
                        title={data[cursor + 1].file_name}
                        id={data[cursor + 1].id}
                        onRetry={onRetry}
                        state={state}
                    />
                ) : null}
    
                {data[cursor + 2] ? (
                    <VidePlayer
                        url={data[cursor + 2].file_path}
                        title={data[cursor + 2].file_name}
                        id={data[cursor + 2].id}
                        onRetry={onRetry}
                        state={state}
                    />
                ) : null}
    
                <RightArrowIcon onClick={cursorRightClick} style={{ cursor: data.length > cursor + 2 ? "pointer" : "auto", color: THEME.defaultHighLightColor }} />
            </div>
        );
    }
    
    return (
        (<div className="job-order-documents-videos-content-body no-data"><TableEmpty title={"No data found"} onClick={onRetry} /></div>)
    )
}


export default VideoData;