import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType, TALENT_SKILLS_TYPE } from "../../../../../enums";
import { AppError, ITalent, ITalentJobMatch, ISkillOption, ITalentCompanyMatch, Profile } from "../../../../../interfaces";
import * as thunk from "./talent_details_thunk";


interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

interface RemoveState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialRemoveState: RemoveState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


/// [Job Matches]
export interface SingleTalentJobMatchesState {
    data: Array<ITalentJobMatch> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}


const singleTalentJobMatchesInitialState: SingleTalentJobMatchesState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}

/// [Company Matches]
export interface SingleTalentCompanyMatchesState {
    data: Array<ITalentCompanyMatch> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}


const singleTalentCompanyMatchesInitialState: SingleTalentCompanyMatchesState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}

/// [Skills]
export interface SingleTalentSkillsState {
    data: Array<ISkillOption> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}



const singleTalentSkillsInitialState: SingleTalentSkillsState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}


export interface SingleTalentRoleUsersState {
    data: Array<Profile> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}

const singleTalentRoleUsersInitialState: SingleTalentRoleUsersState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}




export interface SingleTalentState {
    error?: AppError | null,
    loading: LoadingType,
    data: ITalent | null,
    jobMatches: SingleTalentJobMatchesState,
    companyMatches: SingleTalentCompanyMatchesState,
    [TALENT_SKILLS_TYPE.professional_skills]: SingleTalentSkillsState,
    [TALENT_SKILLS_TYPE.credentials]: SingleTalentSkillsState,
    [TALENT_SKILLS_TYPE.certifications]: SingleTalentSkillsState,
    [TALENT_SKILLS_TYPE.education]: SingleTalentSkillsState,
    [TALENT_SKILLS_TYPE.safety_equipment]: SingleTalentSkillsState,
    [TALENT_SKILLS_TYPE.account_managers]: SingleTalentRoleUsersState,
    [TALENT_SKILLS_TYPE.recruiters]: SingleTalentRoleUsersState,
}


export interface TalentDetailsState {
    [key: string]: SingleTalentState,
}


const initialState: TalentDetailsState = {}


function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

function getResponseSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}

export const talentDetailsSlice = createSlice({
    name: 'talentDetails',
    initialState: initialState,
    reducers: {
        clearTalentDetailsAddOptionError(state, action: PayloadAction<{ id: string, type: string }>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        add: {
                            ...state[action.payload.id][action.payload.type].add,
                            ...initialAddState,
                        },

                    },
                }
            };
        },

        clearTalentDetailsRemoveOptionError(state, action: PayloadAction<{ id: string, type: string }>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        remove: {
                            ...state[action.payload.id][action.payload.type].remove,
                            ...initialRemoveState,
                        },

                    },
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getTalentById.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getLoadingState(state[action.meta.arg]),
                        jobMatches: singleTalentJobMatchesInitialState,
                        companyMatches: singleTalentCompanyMatchesInitialState,
                        [TALENT_SKILLS_TYPE.professional_skills]: singleTalentSkillsInitialState,
                        [TALENT_SKILLS_TYPE.credentials]: singleTalentSkillsInitialState,
                        [TALENT_SKILLS_TYPE.certifications]: singleTalentSkillsInitialState,
                        [TALENT_SKILLS_TYPE.education]: singleTalentSkillsInitialState,
                        [TALENT_SKILLS_TYPE.safety_equipment]: singleTalentSkillsInitialState,
                        [TALENT_SKILLS_TYPE.account_managers]: singleTalentRoleUsersInitialState,
                        [TALENT_SKILLS_TYPE.recruiters]: singleTalentRoleUsersInitialState,
                    }
                })
            )
            .addCase(
                thunk.getTalentById.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getErrorState(state[action.meta.arg], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getTalentById.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getSuccessState(state[action.meta.arg], action.payload),
                    }
                })
            )

            /// Job matches list
            .addCase(
                thunk.getTalentJobMatches.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        jobMatches: {
                            ...getLoadingState(state[action.meta.arg.talentId].jobMatches),
                        },
                    }
                })
            )
            .addCase(
                thunk.getTalentJobMatches.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        jobMatches: {
                            ...getErrorState(state[action.meta.arg.talentId].jobMatches, action.payload),
                        },
                    }
                })
            )
            .addCase(
                thunk.getTalentJobMatches.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        jobMatches: {
                            ...getSuccessState(state[action.meta.arg.talentId].jobMatches, action.payload),
                        },
                    }
                })
            )

            /// Company matches list
            .addCase(
                thunk.getTalentCompanyMatches.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        companyMatches: {
                            ...getLoadingState(state[action.meta.arg.talentId].companyMatches),
                        },
                    }
                })
            )
            .addCase(
                thunk.getTalentCompanyMatches.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        companyMatches: {
                            ...getErrorState(state[action.meta.arg.talentId].companyMatches, action.payload),
                        },
                    }
                })
            )
            .addCase(
                thunk.getTalentCompanyMatches.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        companyMatches: {
                            ...getSuccessState(state[action.meta.arg.talentId].companyMatches, action.payload),
                        },
                    }
                })
            )


            /// Get all skillls
            .addCase(
                thunk.getTalentAllSkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getLoadingState(state[action.meta.arg][TALENT_SKILLS_TYPE.professional_skills]),

                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getLoadingState(state[action.meta.arg][TALENT_SKILLS_TYPE.credentials]),

                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getLoadingState(state[action.meta.arg][TALENT_SKILLS_TYPE.certifications]),

                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getLoadingState(state[action.meta.arg][TALENT_SKILLS_TYPE.education]),

                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getLoadingState(state[action.meta.arg][TALENT_SKILLS_TYPE.safety_equipment]),
                        },
                    }
                })
            )
            .addCase(
                thunk.getTalentAllSkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getErrorState(state[action.meta.arg][TALENT_SKILLS_TYPE.professional_skills], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getErrorState(state[action.meta.arg][TALENT_SKILLS_TYPE.credentials], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getErrorState(state[action.meta.arg][TALENT_SKILLS_TYPE.certifications], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getErrorState(state[action.meta.arg][TALENT_SKILLS_TYPE.education], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getErrorState(state[action.meta.arg][TALENT_SKILLS_TYPE.safety_equipment], action.payload),

                        },
                    }
                })
            )
            .addCase(
                thunk.getTalentAllSkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getSuccessState(state[action.meta.arg][TALENT_SKILLS_TYPE.professional_skills], action.payload[TALENT_SKILLS_TYPE.professional_skills]),
                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getSuccessState(state[action.meta.arg][TALENT_SKILLS_TYPE.credentials], action.payload[TALENT_SKILLS_TYPE.credentials]),
                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getSuccessState(state[action.meta.arg][TALENT_SKILLS_TYPE.certifications], action.payload[TALENT_SKILLS_TYPE.certifications]),
                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getSuccessState(state[action.meta.arg][TALENT_SKILLS_TYPE.education], action.payload[TALENT_SKILLS_TYPE.education]),
                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getSuccessState(state[action.meta.arg][TALENT_SKILLS_TYPE.safety_equipment], action.payload[TALENT_SKILLS_TYPE.safety_equipment]),
                        },
                    }
                })
            )

            /// Skills list [DELETE]
            .addCase(
                thunk.removeTalentSkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.talentId][action.meta.arg.value.skill_type],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.talentId][action.meta.arg.value.skill_type].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeTalentSkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.talentId][action.meta.arg.value.skill_type],
                            remove: {
                                ...getErrorState(state[action.meta.arg.talentId][action.meta.arg.value.skill_type].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeTalentSkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.talentId][action.meta.arg.value.skill_type],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.talentId][action.meta.arg.value.skill_type].remove, action.payload),
                            },
                        },

                    }
                })
            )

            /// Skills list [POST]
            .addCase(
                thunk.addTalentSkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.talentId][action.meta.arg.value.skill_type],
                            add: {
                                ...getLoadingState(state[action.meta.arg.talentId][action.meta.arg.value.skill_type].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addTalentSkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.talentId][action.meta.arg.value.skill_type],
                            add: {
                                ...getErrorState(state[action.meta.arg.talentId][action.meta.arg.value.skill_type].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addTalentSkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.talentId][action.meta.arg.value.skill_type],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.talentId][action.meta.arg.value.skill_type].add, action.payload),
                            },
                        },
                    }
                })
            )


            /// Get all account managers
            .addCase(
                thunk.getTalentAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        account_managers: {
                            ...getLoadingState(state[action.meta.arg.talent_id].account_managers),

                        },
                    }
                })
            )
            .addCase(
                thunk.getTalentAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        account_managers: {
                            ...getErrorState(state[action.meta.arg.talent_id].account_managers, action.payload),

                        },
                    }
                })
            )
            .addCase(
                thunk.getTalentAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        account_managers: {
                            ...getSuccessState(state[action.meta.arg.talent_id].account_managers, action.payload),
                        },
                    }
                })
            )


            // /// AccountManagers list [DELETE]
            .addCase(
                thunk.removeTalentAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeTalentAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        'acount_managers': {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getErrorState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeTalentAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers].remove, action.payload),
                            },
                        },

                    }
                })
            )

            // /// Account managers list [POST]
            .addCase(
                thunk.addTalentAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getLoadingState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addTalentAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getErrorState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addTalentAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.account_managers].add, action.payload),
                            },
                        },
                    }
                })
            )


            // /// Get all account managers
            .addCase(
                thunk.getTalentRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getLoadingState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters]),

                        },
                    }
                })
            )
            .addCase(
                thunk.getTalentRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getErrorState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters], action.payload),

                        },
                    }
                })
            )
            .addCase(
                thunk.getTalentRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getSuccessState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters], action.payload),
                        },
                    }
                })
            )


            // /// Recruiters list [DELETE]
            .addCase(
                thunk.removeTalentRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeTalentRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getErrorState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeTalentRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters].remove, action.payload),
                            },
                        },

                    }
                })
            )

            // /// Account managers list [POST]
            .addCase(
                thunk.addTalentRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getLoadingState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addTalentRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getErrorState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addTalentRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.talent_id][TALENT_SKILLS_TYPE.recruiters].add, action.payload),
                            },
                        },
                    }
                })
            )


            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const talentDetailsSliceActions = talentDetailsSlice.actions;
export const selectState = (state: TalentDetailsState) => state;
export const selectTalentState = (state: TalentDetailsState, id: string): SingleTalentState => state[id];
export const selectTalentJobMatchesState = (state: SingleTalentState): SingleTalentJobMatchesState => state.jobMatches;
export const selectTalentCompanyMatchesState = (state: SingleTalentState): SingleTalentCompanyMatchesState => state.companyMatches;
export const selectTalentSkillsState = (state: SingleTalentState): SingleTalentSkillsState => state.professional_skills;
export const selectTalentCredentialsState = (state: SingleTalentState): SingleTalentSkillsState => state.credentials;
export const selectTalentCertificationsState = (state: SingleTalentState): SingleTalentSkillsState => state.certifications;
export const selectTalentEducationState = (state: SingleTalentState): SingleTalentSkillsState => state.education;
export const selectTalentEquipmentState = (state: SingleTalentState): SingleTalentSkillsState => state.safety_equipment;

export const selectInitialTalentState = (): SingleTalentState => ({
    data: null,
    loading: LoadingType.pending,
    error: null,
    jobMatches: singleTalentJobMatchesInitialState,
    companyMatches: singleTalentCompanyMatchesInitialState,
    [TALENT_SKILLS_TYPE.professional_skills]: singleTalentSkillsInitialState,
    [TALENT_SKILLS_TYPE.credentials]: singleTalentSkillsInitialState,
    [TALENT_SKILLS_TYPE.certifications]: singleTalentSkillsInitialState,
    [TALENT_SKILLS_TYPE.education]: singleTalentSkillsInitialState,
    [TALENT_SKILLS_TYPE.safety_equipment]: singleTalentSkillsInitialState,
    [TALENT_SKILLS_TYPE.account_managers]: singleTalentRoleUsersInitialState,
    [TALENT_SKILLS_TYPE.recruiters]: singleTalentRoleUsersInitialState,
});


