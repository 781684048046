import { createAsyncThunk } from "@reduxjs/toolkit";
import { catchRequestError, invalidResponse } from "../../../../utils";
import { ThirdPartyDeductionType, ICreateResponse } from "../../../../interfaces/third_party_deduction_type";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { AppError } from "../../../../interfaces";


export const createDeduction = createAsyncThunk<
    string,
    ThirdPartyDeductionType,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@back_office/third_party_deductions/deduction_type/manage_deduction/create',
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.createDeductionEntity(payload)
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateDeduction = createAsyncThunk<
    string,
    ThirdPartyDeductionType,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@back_office/third_party_deductions/deduction_type/manage_deduction/update',
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.updateDeductionEntity(payload)
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);