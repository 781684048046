import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../../enums";
import { BOPayrollIcon, CautionIcon } from "../../../../icons";
import { currencyConversion, CustomButton } from "../../../../utils";
import "./void_rev_check_popup.scss";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppError, PayCheck } from "../../../../interfaces";
import { getDateString } from "../../../../variables";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import ApiError from "../../../../components/api_error";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import * as MtIcons from '@material-ui/icons';
import { RouteComponentProps } from "react-router-dom";
import { AppRoutes } from "../../../../routes";

interface Props {
    visible: boolean,
    title: string,
    onClose: () => void,
    data: PayCheck | null | undefined;
    handleVoidRev: (paycheck_id: string, recreate: boolean, type: string) => void;
    type: string;
    state: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    }
    handleClearError: () => void;
    currentLoginUserName: string;
    onSuccessClose: () => void;
    history: RouteComponentProps["history"],
}

const VoidRevCheckPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        data,
        handleVoidRev,
        type,
        state,
        handleClearError,
        currentLoginUserName, onSuccessClose
    } = props;

    const actionRef = useRef<ActionDialogHolderType>(null);
    const { t } = useTranslation();
    const [confirm, setConfirm] = useState<boolean>(false);
    const [recreate, setRecreate] = useState<boolean>(false);
    const handleSubmit = () => {
        if (data) {
            handleVoidRev(data.id, recreate, type)
        }
    };
    const handleOk = () => {
        onSuccessClose();
        handleClose();
    }
    const handleClose = () => {
        onClose();
        setConfirm(false);
        setRecreate(false)
    }
    const handleGotoTimecards = () => {
        onSuccessClose();
        handleClose();
        props.history.push(AppRoutes.timeCardsTab);
    }
    return (<ActionDialogHolder
        ref={actionRef}
        visible={visible}
        wrapperWidth={"35%"}
        maxWrapperWidth={"35%"}
        onClose={onClose}
        onDissmiss={onClose}>
        <DialogWrapper onClose={onClose}>
            <div className="void-rev-container">
                <div className="void-rev-header">
                    <FormDialogTilteHeader
                        title={title}
                        titleIcon={<BOPayrollIcon width={"100%"} height={"100%"} />}
                    />
                </div>
                <div className="void-rev-content">
                    <div className="void-rev-data-table">
                        {state.loading !== LoadingType.succeeded &&
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{t("employee_name")}&nbsp;:</td>
                                        <td>{data?.employee_name}</td>

                                        <td>{t("check_number")}&nbsp;:</td>
                                        <td>{data?.check_number}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("check_date")}&nbsp;:</td>
                                        <td> {getDateString(data?.check_date, "mm/dd/yyyy")}</td>
                                        <td>{t("gross_amount")}&nbsp;:</td>
                                        <td>{currencyConversion(data?.gross_amount ?? 0, 2)}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("net_amount")}&nbsp;:</td>
                                        <td>{currencyConversion(data?.net_amount ?? 0, 2)}</td>

                                    </tr>
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className="cnfrm-msg">
                        {state.loading === LoadingType.succeeded ?

                            <DocSavedText>
                                <div >
                                    <span className="aknw-name">
                                        {t("hi_username", { userName: currentLoginUserName })}
                                    </span>
                                    <div className="aknw-name">
                                        <span>
                                            {state.response}
                                        </span>
                                    </div>
                                </div>
                            </DocSavedText>
                            :
                            <div>
                                {!confirm &&
                                    <div className="vr-w-text">
                                        <div className="vr-w-icon">
                                            <CautionIcon width={"100%"} height={"100%"} />
                                        </div>
                                        <span>{type === "void" ? t("are_u_sure_to_void_check") : t("are_u_sure_to_rev_check")}</span>
                                    </div>

                                }
                                {confirm &&
                                    <div className="radio-div">
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-required aria-label="void_rev_action" name="void_rev_action" value={recreate}
                                                onChange={(event) => setRecreate(event.target.value === "true")} className="radio-group-div">
                                                <FormControlLabel value={false} control={<Radio />} label={t(type === "void" ? "Void" : "Reverse")} />
                                                <FormControlLabel value={true} control={<Radio />} label={t(type === "void" ? "void_recreate" : "reverse_recreate")} />
                                            </RadioGroup>
                                        </FormControl>
                                        {recreate &&
                                            <div>
                                                <span className="rc-msg">
                                                    {t("recreate_msg")}
                                                </span>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="void-rev-actions">
                    <div>
                        {state.error &&
                            <ApiError message={state.error.message} onClose={handleClearError} />
                        }
                    </div>
                    {state.loading === LoadingType.succeeded ?
                        <div className="void-rev-actions-row">
                            {recreate &&
                                <div className="btn-cancel">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("go_to_timecards")}
                                        enable={true}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={handleGotoTimecards}
                                    />
                                </div>
                            }
                            <div className="btn-save">
                                <CustomButton
                                    textStyle={{ textTransform: 'capitalize' }}
                                    loading={false}
                                    name={t("ok")}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={handleOk}
                                />
                            </div>

                        </div>

                        :
                        <div className="void-rev-actions-row">
                            <div className="btn-cancel">
                                <CustomButton
                                    loading={false}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("cancel")}
                                    enable={true}
                                    backgroundColor={THEME.toggleDisableColor}
                                    onClick={handleClose}
                                />
                            </div>
                            <div className="btn-save">
                                <CustomButton
                                    textStyle={{ textTransform: 'capitalize' }}
                                    loading={confirm ? state.loading === LoadingType.pending : false}
                                    name={t(confirm ? (type === "void" ? "void_check" : "reverse_check") : "continue")}
                                    backgroundColor={THEME.statusWarningColor}
                                    onClick={() => confirm ? handleSubmit() : setConfirm(true)}
                                />
                            </div>
                        </div>}
                </div>
            </div>
        </DialogWrapper >
    </ActionDialogHolder>);
}

export default VoidRevCheckPopup;