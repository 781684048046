import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { OnlineApplicationReceived } from "../../../../interfaces/online_application_received";
import { catchRequestError } from "../../../../utils";

export const getOnlineApplicationReceivedDetails = createAsyncThunk<
  OnlineApplicationReceived,
  string,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@getOnlineApplicationReceivedDetails", async (id, thunkAPI) => {
  try {
    const res = await DashboardAPI.getOnlineApplicationsReceivedById(id);
    return res.data as OnlineApplicationReceived;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});
