import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { IRCOpenJobOrders } from "../../../../interfaces/report_center";

export const getOpenJobOrdersReportList = createAsyncThunk<
    IRCOpenJobOrders,
    void,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/open_job_orders_report/post",
    async (_, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getOpenJobOrdersReport();
            return res.data as IRCOpenJobOrders;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });