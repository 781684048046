import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import { DashboardAPI } from "../../../../apis";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import { DateInput } from "../../../../components/date_input/date_input";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableFilterEmpty,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType } from "../../../../enums";
import { ApplicationReceivedIcon } from "../../../../icons";
import { IInterviewCalendarInvite } from "../../../../interfaces";
import {
  getInterviewCalendarInviteThunk,
  postSnackbarMessage,
  selectDashboardInterviewCalendarInviteState,
  selectInterviewCalendarInviteList,
  selectInterviewCalendarInviteUpdateState,
  selectProfileState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import {
  CustomButton,
  CustomCheckBox,
  getContactTypeIcon,
} from "../../../../utils";
import {
  convertDateToTimeStamp,
  formatAMPM,
  getDateFromTimeStamp,
  getDateString,
} from "../../../../variables";
import SendInterviewCalendarArchivePopup from "../../popups/archive_interview/archive_interview";
import SendInterviewCalendarInvitePopup from "../../popups/send_interview_calendar_invite/send_interview_calendar_invite";
import "./universal_directory.scss";
import SearchBar from "../../../../components/search_bar";
interface Props extends RouteComponentProps<any> {
  isArchiveDirectory: boolean;
  isCompleted: boolean;
}

export const ScheduledInterviewUniversalDirectory: React.FunctionComponent<
  Props
> = (props) => {
  const { t } = useTranslation();
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const inviteList = useAppSelector((state) =>
    selectInterviewCalendarInviteList(state)
  );
  const inviteState = useAppSelector((state) =>
    selectDashboardInterviewCalendarInviteState(state)
  );
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [asyncLoading_import, setAsyncLoading_import] =
    useState<boolean>(false);
  const [asyncLoading_archive, setAsyncLoading_archive] =
    useState<boolean>(false);
  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const { profile } = useAppSelector((state) => selectProfileState(state));
  const actionRef = useRef<ActionDialogHolderType>(null);
  const [selectedNames, setSelectedNames] = useState<Set<string>>(new Set());
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const monday = new Date(today.setDate(first));
  const [fromDate, setFromDate]: any = useState(
    convertDateToTimeStamp(monday, true)
  );
  const [toDate, setToDate]: any = useState(
    convertDateToTimeStamp(new Date(), true)
  );
  const [thisWeek, setThisWeek] = useState<boolean>(true);
  const [interviewList, setInterviewList]: any = useState([]);
  const [showInvite, setShowInvite] = useState(false);
  const [selectedRow, setSelectedRow] =
    useState<IInterviewCalendarInvite | null>(null);
  const sendInterviewCalendarInviteUpdateState = useAppSelector((state) =>
    selectInterviewCalendarInviteUpdateState(state)
  );
  const [showArchive, setShowArchive] = useState(false);

  const tableHeader = [
    { title: "", code: "" },
    { title: "scheduled_interview", code: "scheduled_interview" },
    { title: "name", code: "name" },
    { title: "applied_for", code: "applied_for" },
    { title: "status", code: "status" },
    { title: "ssn_hash", code: "last_4_digits_of_ssn" },
    { title: "preferred_contact", code: "preferred_contact" },
    { title: "date_applied", code: "date_applied" },
    { title: "recruiter", code: "recruiter" },
  ];

  const getList = () => {
    if (props.isCompleted) {
      dispatch(
        getInterviewCalendarInviteThunk({
          this_week: thisWeek,
          get_count: false,
          completed: props.isCompleted,
          endDate: toDate,
          startDate: fromDate,
        })
      );
    } else {
      dispatch(
        getInterviewCalendarInviteThunk({
          this_week: thisWeek,
          get_count: false,
          completed: props.isCompleted,
        })
      );
    }
  };

  useEffect(() => {
    setInterviewList(inviteList);
  }, [inviteList]);

  useEffect(() => {
    if (
      sendInterviewCalendarInviteUpdateState.loading === LoadingType.succeeded
    ) {
      getList();
      setSelectedItems(new Set());
    }
  }, [sendInterviewCalendarInviteUpdateState.loading]);

  const getFilteredList = () => {
    let list: IInterviewCalendarInvite[] = [
      ...interviewList.filter(
        (i) => i.is_archived === props.isArchiveDirectory
      ),
    ];
    if (sortedField != null) {
      list.sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] != undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    // return list;
    return list.filter((doc) => {
      const str = search.trim().toLowerCase();
      const nameFilter = `${doc.first_name ?? ''} ${doc.last_name ?? ''}`.trim().toLowerCase().includes(str);
      const apppliedFor = doc.applied_for
        ? doc.applied_for.trim().toLowerCase().includes(str)
        : false;
      const status = doc.status
        ? doc.status.trim().toLowerCase().includes(str)
        : false;
      return nameFilter || apppliedFor || status
    });
  };

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (inviteState.error != null && interviewList.length != 0) {
      dispatch(postSnackbarMessage(inviteState.error.message));
    }
  }, [inviteState.error]);

  const getTable = () => {
    if (inviteState.loading === LoadingType.pending) return <TableLoading />;
    if (inviteState.error != null && interviewList.length === 0) {
      return (
        <TableErrorHandler error={inviteState.error} onRefresh={getList} />
      );
    }
    if (interviewList.length === 0) {
      return <TableEmpty title={t("no_data_found")} onClick={getList} />;
    }
    if (getFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_data_found")} />;
    }
    if (getFilteredList().length !== 0) {
      return (
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList().map((doc) => {
            return (
              <tr key={doc.id}>
                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                  <CustomCheckBox
                    name={""}
                    title={""}
                    checked={selectedItems.has(
                      doc.talent_online_application_id
                    )}
                    onClick={() => {
                      const newSet = new Set(selectedItems);
                      if (selectedItems.has(doc.talent_online_application_id)) {
                        newSet.delete(doc.talent_online_application_id);
                      } else {
                        newSet.add(doc.talent_online_application_id);
                      }
                      setSelectedItems(newSet);
                    }}
                  />
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(1) }}>
                  <span>
                    {getDateString(doc.scheduled_interview) +
                      " " +
                      formatAMPM(getDateFromTimeStamp(doc.scheduled_interview))}
                  </span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                  <span>{doc.first_name  ?? "" + " " + doc.last_name ?? ""}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                  <span>{doc.applied_for}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                  <span>{doc.status}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                  {doc.last_4_digits_of_ssn ? getSSNInfo(doc.last_4_digits_of_ssn) : ""}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                  {getPrefferedContact(doc.preferred_contact)}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                  <span>{getDateString(doc.date_applied)}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                  <span></span>
                </TableData>
              </tr>
            );
          })}
        </SortableTable>
      );
    }
  };

  function getFlexNumber(value: number) {
    if (value === 0) return 0.5;
    if (value === 1) return 2;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 1;
    return 1;
  }

  function getSSNInfo(value: string) {
    return (
      <div className="ssn-holder">
        <div className="ssn-number">
          <span>{value}</span>
        </div>
      </div>
    );
  }

  function getPrefferedContact(value: string) {
    return (
      <div className="pref-contact-icon">
        {getContactTypeIcon(value, "#00A4DC")}
      </div>
    );
  }

  const handleImport = async () => {
    setAsyncLoading_import(true);
    try {
      const names: any = [];
      Array.from(selectedItems).map((h: any) => {
        if (
          interviewList.filter(
            (item) => item.talent_online_application_id === h
          ).length > 0
        )
          names.push(
            interviewList.filter(
              (item) => item.talent_online_application_id === h
            )[0].first_name +
            " " +
            interviewList.filter(
              (item) => item.talent_online_application_id === h
            )[0].last_name
          );
      });
      setSelectedNames(names);
      const res = await DashboardAPI.importApplication(
        Array.from(selectedItems)
      );
      postSnackbarMessage(res.data.data ? res.data.data : res.data);
    } catch (error) {
    } finally {
      getList();
      setSelectedItems(new Set());
      setAsyncLoading_import(false);
      setShowImportModal(true);
    }
  };

  const toggle = () => {
    setShowImportModal(false);
    setSelectedNames(new Set());
    setSelectedItems(new Set());
    setShowInvite(false);
    setSelectedRow(null);
    setShowArchive(false);
  };

  const closePopup = (action?: () => void) => {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  };

  const onDateChange = (fieldId, value) => {
    if (fieldId === "from_date") setFromDate(value);
    if (fieldId === "to_date") setToDate(value);
    const from_date = fieldId === "from_date" ? value : fromDate;
    const to_date = fieldId === "to_date" ? value : toDate;
    setThisWeek(false);
    dispatch(
      getInterviewCalendarInviteThunk({
        this_week: false,
        get_count: false,
        completed: props.isCompleted,
        startDate: from_date,
        endDate: to_date,
      })
    );
  };

  const getArchiveIds = () => {
    return getFilteredList()
      .filter((item) => selectedItems.has(item.talent_online_application_id))
      .map((i) => i.id);
  };
  return (
    <div className="cps-ud-container contacts-ud scheduled-interview-ud">
      <div className="w-100">
        <div
          className="p-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {props.isCompleted && (
            <div className="interview-ac-ud-table-search-bar">
              <div style={{ marginRight: 5 }}>
                <DateInput
                  id={"from_date"}
                  onChange={(fieldId, value) =>
                    onDateChange("from_date", value)
                  }
                  placeholder={""}
                  value={fromDate}
                  setHoursToZero={true}
                  label={"From"}
                />
              </div>
              <DateInput
                id={"to_date"}
                onChange={(fieldId, value) => onDateChange("to_date", value)}
                placeholder={""}
                value={toDate}
                setHoursToZero={true}
                label={"To"}
              />
            </div>
          )}
          <div style={{ display: "flex", width: "auto", alignItems: "center" }}>
            <div className="total-count">
              <span>
                {`${t("total_count")}: `}
                <span className="total-count-number">
                  {getFilteredList().length}
                </span>
              </span>
            </div>
          </div>
          <div className="apps-ud-actions">
            <div className="apps-ud-search">
            <SearchBar
              value={search}
              onChange={(value) => setSearch(value)}
              onSearch={() => { }}
            />
            </div>
            {props.isArchiveDirectory === false && (
              <>
                {props.isCompleted === false && (
                  <CustomButton
                    loading={false}
                    textStyle={{ textTransform: "capitalize" }}
                    name={t("reschedule_interview")}
                    enable={selectedItems.size > 0}
                    onClick={() => {
                      setShowInvite(true);
                      setSelectedRow(
                        interviewList.filter(
                          (h) =>
                            h.talent_online_application_id ===
                            Array.from(selectedItems)[0]
                        )[0]
                      );
                    }}
                    backgroundColor={"#074783"}
                    customStyle={{ marginRight: "10px", width: "180px" }}
                  />
                )}
                <CustomButton
                  loading={asyncLoading_import}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("import")}
                  enable={selectedItems.size > 0}
                  backgroundColor={"#00BE4B"}
                  onClick={handleImport}
                  customStyle={{ marginRight: "10px" }}
                />
                <CustomButton
                  loading={asyncLoading_archive}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("archive")}
                  enable={selectedItems.size === 1}
                  backgroundColor={"#B5BAC5"}
                  onClick={() => setShowArchive(true)}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="cps-ud-table">{getTable()}</div>
      {showImportModal && (
        <Portal>
          <ActionDialogHolder
            ref={actionRef}
            visible={showImportModal}
            wrapperWidth={"50%"}
            onClose={toggle}
            onDissmiss={toggle}
          >
            <DialogWrapper onClose={() => closePopup(toggle)}>
              <div className="uf-container contact-form">
                <div className="uf-header">
                  <FormDialogTilteHeader
                    title={"Import Interview"}
                    titleIcon={
                      <ApplicationReceivedIcon width={"100%"} height={"100%"} />
                    }
                  />
                </div>
                <div className="uf-content">
                  <div className="success-step">
                    <DocSavedText>
                      <span className="aknw-name">
                        {t("great", { name: profile?.first_name })}
                      </span>
                      <span className="info-txt">{`${t(
                        "has_been_imported_to_your_talent_database",
                        { name: getAppendedNames(Array.from(selectedNames)) }
                      )}`}</span>
                    </DocSavedText>
                  </div>
                </div>
              </div>
            </DialogWrapper>
          </ActionDialogHolder>
        </Portal>
      )}
      {showInvite && selectedRow && (
        <Portal>
          <ActionDialogHolder
            ref={actionRef}
            visible={showInvite}
            wrapperWidth={"30%"}
            onClose={toggle}
            onDissmiss={toggle}
          >
            <DialogWrapper onClose={() => closePopup(toggle)}>
              <SendInterviewCalendarInvitePopup
                setShowInvite={setShowInvite}
                closePopup={closePopup}
                data={selectedRow}
                isReschedule={true}
              />
            </DialogWrapper>
          </ActionDialogHolder>
        </Portal>
      )}
      {showArchive && (
        <Portal>
          <ActionDialogHolder
            ref={actionRef}
            visible={showArchive}
            wrapperWidth={"50%"}
            onClose={toggle}
            onDissmiss={toggle}
          >
            <DialogWrapper onClose={() => closePopup(toggle)}>
              <SendInterviewCalendarArchivePopup
                setShowArchive={setShowArchive}
                closePopup={closePopup}
                data={getArchiveIds()}
              />
            </DialogWrapper>
          </ActionDialogHolder>
        </Portal>
      )}
    </div>
  );

  function getAppendedNames(list: string[]) {
    let result: string = "";
    list.forEach((doc) => {
      if (result != "") {
        result = result + "," + doc;
      } else {
        result = doc;
      }
    });
    return result;
  }
};
