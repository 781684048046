import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./job_orders_history_report_thunk";
import { IRCOpenJobOrders } from "../../../../interfaces/report_center";


export interface JobOrdersHistoryReportListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCOpenJobOrders | null
}

const initialState: JobOrdersHistoryReportListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const jobOrdersHistoryReportListSlice = createSlice({
    name: 'jobOrdersHRList',
    initialState: initialState,
    reducers: {
        clearJobOrdersHistoryReportListError(state) {
            return { ...state, error: null };
        },
        clearJobOrdersHistoryReportListState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getJobOrdersHistoryReportList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getJobOrdersHistoryReportList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getJobOrdersHistoryReportList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearJobOrdersHistoryReportListError, clearJobOrdersHistoryReportListState } = jobOrdersHistoryReportListSlice.actions;
export const selectState = (state: JobOrdersHistoryReportListState) => state;
export const selectLoading = (state: JobOrdersHistoryReportListState) => state.loading;
export const selectError = (state: JobOrdersHistoryReportListState) => state.error;


