import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectCreateState, selectState, selectUpdate, selectFilesState, selectFilesTypeState, selectInitialFilesTypeState } from "./manage_contact_reducer";

const select = (state: IRootState) => state.contacts.universalDirectory.manageContact;
export const selectManageContactState = createSelector(select, selectState);
export const selectManageContactCreateState = createSelector(selectManageContactState, selectCreateState);
export const selectManageContactUpdateState = createSelector(selectManageContactState, selectUpdate);
export const selectManageContactFilesState = createSelector(selectManageContactState, selectFilesState);

export const selectManageContactFilesStateByType = (type: string | undefined | null) => createSelector(
    selectManageContactState,
    (state) => {
        if (type && state.files[type]) return selectFilesTypeState(state, type);
        return selectInitialFilesTypeState();
    }
);
