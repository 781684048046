import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";
import * as thunk from './time_cards_upload_docs_thunk';


export interface TimeCardsUploadUrlState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialState: TimeCardsUploadUrlState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const timeCardsUploadUrlSlice = createSlice({
    name: 'timeCardsUploadUrl',
    initialState: initialState,
    reducers: {
        clearTimeCardsUploadUrlStateError(state){
            return { ...state, error: null };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.uploadTimeCardsDocsUploadUrl.pending, (state, action) => ({ ...state, loading: LoadingType.pending }))
            .addCase(thunk.uploadTimeCardsDocsUploadUrl.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload }))
            .addCase(thunk.uploadTimeCardsDocsUploadUrl.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, response: action.payload }))

            .addDefaultCase(state => ({ ...state }));
    }
});
export const timeCardsUploadUrlActions = timeCardsUploadUrlSlice.actions;
export const selectTimeCardsUploadUrlState = (state: TimeCardsUploadUrlState) => state;