import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../apis";
import { IUpdateUser, AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";

/// Update user
export const updateMyProfileUser = createAsyncThunk<
    string,
    IUpdateUser,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@my_profile/update',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateProfile(payload);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);