import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { DashboardAPI } from "../../../../apis";
import CurveTabNavigation from "../../../../components/curve_tab_navigation/curve_tab_navigation";
import { TableErrorHandler } from "../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../enums";
import { EditIcon, SkillsSummaryIcon } from "../../../../icons";
import { OnlineApplicationReceived } from "../../../../interfaces/online_application_received";
import {
  clearOnlineApplicationReceivedDetailsState,
  selectOnlineApplicationReceivedDetailsState,
} from "../../../../redux/dashboard/metrics/online_applications_received_details/online_applications_received_selector";
import { getOnlineApplicationReceivedDetails } from "../../../../redux/dashboard/metrics/online_applications_received_details/online_applications_received_thunk";
import {
  getResumeFileUrlForDownload,
  postSnackbarMessage,
  selectSingleResumeFileStateById,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { CustomButton, handleDownload, SpinnerScreen } from "../../../../utils";
import { OnlineApplicationReceivedDetailsSection } from "./details_sections";
import OnlineApplicationReceivedSkillSummary from "./skill_summary";
import "./dashboard.scss";
import { resumeFileSliceActions } from "../../../../redux/files/resume_files/resume_file_reducer";
import OnlineEmploymentHistoryPage from "../online_employment_history/online_employment_history";

export const OnlineApplicationReceivedDashboard: FC<
  RouteComponentProps<any>
> = (props: RouteComponentProps<any>) => {
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showSave, setShowSave] = useState<boolean>(false);
  const { params } = props.match;
  const [asyncLoading, setAsyncLoading] = useState<boolean>(false);
  const { loading, error, appInfo, id } = useAppSelector((state) =>
    selectOnlineApplicationReceivedDetailsState(state)
  );
  
  const { t } = useTranslation();
  const [app, setApp] = useState<OnlineApplicationReceived>(
    appInfo ?? new OnlineApplicationReceived()
  );

  const resumeDownloadState = useAppSelector((state) => selectSingleResumeFileStateById(params.id)(state))
    useEffect(() => {
        if (resumeDownloadState.loading === LoadingType.succeeded && resumeDownloadState.response) handleDownload(resumeDownloadState.response);
        return () => { }
    }, [resumeDownloadState.loading])
  useEffect(() => {
    fetchAppDetails();
    return () => {
      dispatch(clearOnlineApplicationReceivedDetailsState());
      dispatch(resumeFileSliceActions.clearResumeFileState(params.id))
    };
  }, []);

  useEffect(() => {
    const newApp = appInfo ?? new OnlineApplicationReceived();
    setApp({ ...newApp });
  }, [appInfo]);
  
  const fetchAppDetails = () => {
    if (params && params.id) {
      dispatch(getOnlineApplicationReceivedDetails(params.id));
    }
  };

  const DetailsLoading = () => {
    return (
      <div className="ac-um-usp-loading">
        <SpinnerScreen />
      </div>
    );
  };

  const getTabList = () => {
    return [
      {
        title: t("skill_summary").toUpperCase(),
        icon: SkillsSummaryIcon,
        content: (
          <OnlineApplicationReceivedSkillSummary
            updateApplication={(val) => setApp({ ...val })}
            appInfo={app}
            disable={!showSave}
          />
        ),
      },
    ];
  };

  const showloading = loading === LoadingType.pending;

  const handleSave = async () => {
    setAsyncLoading(true);
    try {
      const res = await DashboardAPI.updateOnlineApplicationReceived(app);
      postSnackbarMessage(res.data.data ? res.data.data : res.data);
    } catch (error) {
    } finally {
      dispatch(getOnlineApplicationReceivedDetails(app.id));
      setAsyncLoading(false);
      setShowSave(false);
      if (appInfo) {
        setApp({ ...appInfo });
      }
    }
  };

  const getEditAction = () => {
    if (showSave) {
      return null;
    }
    return (
      <button
        className="icon-btn jdp-edit-icon"
        onClick={() => setShowSave(true)}
      >
        <EditIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
      </button>
    );
  };
  
  function hanleOnResume(path:string) {
    dispatch(getResumeFileUrlForDownload({id:params.id, file_path:path}));
}
  return (
    <div className="ac-um-usp-container online-application-received-dashboard">
      {showloading && <DetailsLoading />}
      {error != null && (
        <TableErrorHandler error={error} onRefresh={fetchAppDetails} />
      )}
      {loading === LoadingType.succeeded && error === null && (
        <div className="ac-um-usp-content">
          <div className="ac-um-usp-profile-container">
            <OnlineApplicationReceivedDetailsSection 
            appInfo={app}
            onResumeClick={hanleOnResume}
            />
           
          </div>
          <div className="ac-um-usp-settings-content">
            <CurveTabNavigation
              tabs={getTabList()}
              selected={tabIndex}
              onChange={(value) => setTabIndex(value)}
              actions={getEditAction()}
            />
            {tabIndex === 0 && showSave && (
              <div className="ad-actions">
                <div className="btn-cancel">
                  <CustomButton
                    textStyle={{ textTransform: "capitalize" }}
                    name={t("cancel")}
                    enable
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={() => {
                      setShowSave(false);
                      if (appInfo) {
                        setApp({ ...appInfo });
                      }
                    }}
                    customStyle={{ marginRight: "10px" }}
                  />
                </div>
                <CustomButton
                  loading={asyncLoading}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("save")}
                  enable
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={handleSave}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
