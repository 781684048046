import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiError from '../../../../components/api_error';
import { DateInput } from '../../../../components/date_input/date_input';
import { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import CustomFormSelect from '../../../../components/form_selector/form_select';
import { LoadingType, THEME } from '../../../../enums';
import { ChatIcon, TalentDarkBlueIcon } from '../../../../icons';
import { AppError, ICreateTalentInHouseInterview, IProfileDropdown, ITalent, ITalentInHouseInterview, Profile, TalentInterviewQuestion } from '../../../../interfaces';
import { CustomButton, FormInput, getUserNameFromProfileDropdown } from '../../../../utils';
import './in_house_interview_form.scss';
import { useAppSelector, selectTalentList, selectTalentListState } from '../../../../redux/store';
import { getDateString } from '../../../../variables';



const formOptions = {
    /// 1st row
    interview_by: {
        field: "user_id",
        type: "text",
        label: 'interview_by',
        secure: false,
        required: true,
        placeholder: 'select',
        keyboardtype: ''
    },
    interview_date: {
        field: "interview_date",
        type: "date",
        label: 'interview_date',
        secure: false,
        required: true,
        placeholder: '',
        keyboardtype: ''
    },
    application_date: {
        field: "application_date",
        type: "date",
        label: 'application_date',
        secure: false,
        required: true,
        placeholder: '',
    },

    candidate_answer: {
        field: "candidate_answer",
        type: "text",
        label: 'candidate_answer',
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: '',
        multiline: true,
    },
    recruiter_notes: {
        field: "recruiter_notes",
        type: "text",
        label: 'recruiter_notes',
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: '',
        multiline: true,
    }
}
interface Props {
    title: string,
    talentId: string,
    loading: boolean,
    error?: AppError | null,
    interview?: ITalentInHouseInterview | null,
    recruiters: IProfileDropdown[],
    questions: TalentInterviewQuestion[],
    onSubmit: (value: ICreateTalentInHouseInterview) => void,
    onCancel: () => void,
    onClearError: () => void,
    onlineAppDate: number | undefined,
}

interface LocalQuestionForm {
    id?: string,
    talent_id: string,
    user_id: string,
    interview_by?: string,
    interview_date: number,
    application_date: number,
    talent_interview_questions: {
        [key: number]: {
            talent_interview_question_id: string,
            interview_question: string,
            candidate_answer: string,
            recruiter_notes: string,
        }
    },
    is_deleted?: boolean,
    is_archived?: boolean
}



const InHouseInterviewForm: React.FunctionComponent<Props> = (props) => {

    const [formState, setFormState] = useState<LocalQuestionForm>(props.interview ? getInterviewFormData(props.interview) : {
        talent_id: props.talentId,
        user_id: '',
        interview_date: 0,
        application_date: props.onlineAppDate ?? 0,
        talent_interview_questions: { ...getQuestionsFormat(props.questions) },
        is_deleted: false,
        is_archived: false,
    });
    const { t } = useTranslation();
    const [validForm, setValidForm] = useState(false);
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>();

    function handleFieldChange(fieldId, value) {
        setFormState({ ...formState, [fieldId]: value });
    }

    function handleInterviewQuestionFieldChange(fieldId, value, index) {
        setFormState({
            ...formState,
            talent_interview_questions: {
                ...formState.talent_interview_questions,
                [index]: {
                    ...formState.talent_interview_questions[index],
                    [fieldId]: value,
                }

            }
        });
    }

    function getInterviewFormData(value: ITalentInHouseInterview): LocalQuestionForm {
        return {
            id: value.id ?? '',
            talent_id: value.talent_id ?? '',
            user_id: value.user_id ?? '',
            interview_by: value.interview_by ?? '',
            interview_date: value.interview_date ?? 0,
            application_date: value.application_date ?? 0,
            talent_interview_questions: value.talent_interview_questions.length != 0 ? { ...value.talent_interview_questions } : { ...getQuestionsFormat(props.questions) },
            is_deleted: value.is_deleted ?? false,
            is_archived: value.is_archived ?? false
        };
    }

    function getQuestionsFormat(questions: TalentInterviewQuestion[]) {
        return questions.map(doc => {
            return {
                talent_interview_question_id: doc.id,
                interview_question: doc.question,
                candidate_answer: '',
                recruiter_notes: '',
            }
        })
    }

    function convertToCreateForm(value: LocalQuestionForm): ICreateTalentInHouseInterview {

        return {
            id: value.id ?? '',
            talent_id: value.talent_id ?? '',
            user_id: value.user_id ?? '',
            interview_by: value.interview_by ?? '',
            interview_date: value.interview_date ?? 0,
            application_date: value.application_date ?? 0,
            talent_interview_questions: Object.keys(value.talent_interview_questions).map((key) => value.talent_interview_questions[key]),
            is_deleted: value.is_deleted ?? false,
            is_archived: value.is_archived ?? false
        }
    }

    return (
        <div className="tlnt-inh-in-f-container">
            <div className="tlnt-inh-in-f-header">
                <FormDialogTilteHeader
                    title={props.title}
                    titleIcon={<ChatIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                />
            </div>
            <div className="tlnt-inh-in-f-content">
                {formUI()}
                {props.error && <div className={"error-section"}>
                    <ApiError message={props.error.message} onClose={props.onClearError} />
                </div>}
            </div>
            <div className="tlnt-inh-in-f-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('cancel')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={props.onCancel}
                    />
                </div>

                <div className="btn-save">
                    <CustomButton
                        loading={props.loading}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('save')}
                        // enable={_isFormValid()}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => {
                            setCheckValidationOnSubmit(true)
                            if (_isFormValid()) {
                                props.onSubmit(convertToCreateForm(formState))
                                setCheckValidationOnSubmit(false)
                            }

                        }}
                    />
                </div>
            </div>
        </div>
    )


    function _isFormValid() {
        const {
            id,
            talent_id,
            user_id,
            interview_by,
            interview_date,
            application_date,
            talent_interview_questions,
            is_deleted,
            is_archived,
        } = formState;

        if (
            user_id === '' || interview_date === 0 || application_date === 0 || interview_date === null || application_date === null
        ) return false;
       
        return true;
    }


    function talentFormValidators(value: { text: string, field: string, }) {
        switch (value.field) {
            case formOptions.interview_by.field:
            case formOptions.interview_date.field:
            case formOptions.application_date.field: {
                if (value.text == '') return t('validators.required');
                return null;
            };

            default: {
                return null;
            }
        }
    }


    function formUI() {
        return (
            <div className="tlnt-inh-in-f-section-divider">

                <div className="tlnt-inh-in-f-row" style={{ marginBottom: '2em' }}>
                    <div key={formOptions.interview_by.field} className={"tlnt-inh-in-f-input-holder"}>
                        <CustomFormSelect
                            customStyle={{ width: '-webkit-fill-available' }}
                            name={formOptions.interview_by.field}
                            value={formState[formOptions.interview_by.field]}
                            required={formOptions.interview_by.required}
                            label={t(formOptions.interview_by.label)}
                            placeholder={t(formOptions.interview_by.placeholder)}
                            list={props.recruiters.map(doc => ({ label: getUserNameFromProfileDropdown(doc), value: doc.id }))}
                            onChange={(value) => handleFieldChange(formOptions.interview_by.field, value)}
                            checkValidationOnSubmit={checkValidationOnSubmit}
                            validations={talentFormValidators}
                        />
                    </div>
                    <div key={formOptions.interview_date.field} className={"tlnt-inh-in-f-input-holder"}>
                        <DateInput
                            validations={talentFormValidators}
                            id={formOptions.interview_date.field}
                            onChange={handleFieldChange}
                            secure={formOptions.interview_date.secure}
                            required={formOptions.interview_date.required}
                            placeholder={formOptions.interview_date.placeholder}
                            value={formState[formOptions.interview_date.field]}
                            label={t(formOptions.interview_date.label)}
                            setHoursToZero={true}
                            checkValidationOnSubmit={checkValidationOnSubmit}
                        />
                    </div>
                    <div key={formOptions.application_date.field} className={"tlnt-inh-in-f-input-holder"}>
                        <DateInput
                            validations={talentFormValidators}
                            id={formOptions.application_date.field}
                            onChange={handleFieldChange}
                            secure={formOptions.application_date.secure}
                            required={formOptions.application_date.required}
                            placeholder={formOptions.application_date.placeholder}
                            value={formState[formOptions.application_date.field]}
                            label={t(formOptions.application_date.label)}
                            setHoursToZero={true}
                            checkValidationOnSubmit={checkValidationOnSubmit}
                        />
                    </div>

                </div>
                <div className="tint-ihi-questions-section">
                    {getInterviewQuestions().map((doc, index) => {

                        return (
                            <div className="tint-ihi-question-container" key={index}>
                                <div className="tint-ihi-question-title">
                                    <span>{`${index + 1}. ${doc.interview_question}`}</span>
                                </div>
                                <div className="tint-ihi-input-form">
                                    <div className="tlnt-f-row">
                                        <div key={formOptions.candidate_answer.field} className={"tlnt-f-input-holder"}>
                                            <FormInput
                                                id={formOptions.candidate_answer.field}
                                                onChange={(field, value) => handleInterviewQuestionFieldChange(field, value, index)}
                                                secure={formOptions.candidate_answer.secure}
                                                required={formOptions.candidate_answer.required}
                                                placeholder={formOptions.candidate_answer.placeholder}
                                                type={formOptions.candidate_answer.type}
                                                value={formState.talent_interview_questions[index] ? formState.talent_interview_questions[index][formOptions.candidate_answer.field] : ''}
                                                label={`${t(formOptions.candidate_answer.label).toUpperCase()}:`}
                                                keyboardtype={formOptions.candidate_answer?.keyboardtype}
                                                multiline={formOptions.candidate_answer.multiline}
                                                labelStyles={{ fontWeight: 'normal' }}
                                            />
                                        </div>
                                        <div key={formOptions.recruiter_notes.field} className={"tlnt-f-input-holder"}>
                                            <FormInput
                                                id={formOptions.recruiter_notes.field}
                                                onChange={(field, value) => handleInterviewQuestionFieldChange(field, value, index)}
                                                secure={formOptions.recruiter_notes.secure}
                                                required={formOptions.recruiter_notes.required}
                                                placeholder={formOptions.recruiter_notes.placeholder}
                                                type={formOptions.recruiter_notes.type}
                                                value={formState.talent_interview_questions[index] ? formState.talent_interview_questions[index][formOptions.recruiter_notes.field] : ''}
                                                label={`${t(formOptions.recruiter_notes.label).toUpperCase()}:`}
                                                keyboardtype={formOptions.recruiter_notes?.keyboardtype}
                                                multiline={formOptions.recruiter_notes.multiline}
                                                labelStyles={{ fontWeight: 'normal' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }


    function getInterviewQuestions() {
        return Object.keys(formState.talent_interview_questions).map((key) => formState.talent_interview_questions[key]);
        // if (props.interview && props.interview.talent_interview_questions.length != 0) {
        //     return props.interview.talent_interview_questions;
        // }
        // return getQuestionsFormat(props.questions);
    }
}


export default InHouseInterviewForm;