export enum COMPANY_STATUS {
    prospect = "PROSPECT", //Prospect
    suspect = "SUSPECT", //Suspect
    currentCustomer = "CURRENT_CUSTOMER", //Current Customer
    //topTenTargetAccount = "TOP_TEN_TARGET_ACCOUNT", //Top 10 Target Account
    inActive = "INACTIVE", //Inactive
}

export enum COMPANY_TYPE {
    company = "COMPANY",
    contact = "CONTACT",
    department = "DEPARTMENT"
}

export enum INVOICE_STATUS {
    OPEN = "Open",
    PAST_DUE = "Past Due",
    PAID = "Paid"
}