import { RouteComponentProps } from "react-router-dom"
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import DatePicker from "react-datepicker";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import { useTranslation } from "react-i18next";
import { getReportDownloadURL, getReportStartEndDate, getTerminationReportList, postSnackbarMessage, selectProfileState, selectReportsDownloadState, selectReportStartEndDateState, selectTerminationReportListState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import SearchBar from "../../../../../components/search_bar";
import { CustomButton } from "../../../../../utils";
import { IRCPTTerminationReport } from "../../../../../interfaces/report_center_payroll_tax";
import { useEffect, useState } from "react";
import { AppRoutes } from "../../../../../routes";
import { LoadingType, THEME } from "../../../../../enums";
import { convertDateToTimeStamp, getDateFromTimeStamp, getDateString } from "../../../../../variables";
import SSNHolder from "../../../../../components/ssn_holder/ssn_holder";
import { talentRequestSSNActions } from "../../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer";
import { Portal } from "react-portal";
import ViewSSNPopup from "../../../../talent/popups/view_ssn/view_ssn";
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import UrlBuilder from "../../../../../apis/url_builder";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
    { title: "ssn_hash", code: "talent_ssn" },
    { title: "talent", code: "talent_name" },
    { title: "address", code: "address" },
    { title: "city", code: "city" },
    { title: "state", code: "state" },
    { title: "zip_code", code: "zip_code" },
    { title: "termination_date", code: "termination_date" },
    { title: "", code: "" },
];

const TerminationReport: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const terminationReportListState = useAppSelector((state) => selectTerminationReportListState(state));
    const profileState = useAppSelector((state) => selectProfileState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));

    const [terminationReportList, setTerminationReportList] = useState<IRCPTTerminationReport[] | null>(null);
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [showSSNPopup, setShowSSNPopup] = useState<{ talentId: string; visible: boolean; } | null>(null);

    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>()
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);

    useEffect(() => {
        dispatch(getReportStartEndDate({ start_and_end: true }));
    }, []);

    useEffect(() => {
        if (terminationReportListState.loading === LoadingType.succeeded && terminationReportListState?.response?.length !== 0) {
            setTerminationReportList(terminationReportListState?.response)
        }
        return () => {
            setTerminationReportList([])
        }
    }, [terminationReportListState.loading, terminationReportListState.response]);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0)) {
            getTerminatedReportList();
            setDatePickerOpen(false);
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getTerminatedReportList();
            openDatePicker();
        }
    }, [formState?.available_from, formState?.available_to]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    const getTerminatedReportList = () => {
        if (formState) {
            dispatch(getTerminationReportList({ start_date: formState?.available_from!, end_date: formState?.available_to! }))
        }
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.terminationReport,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    download: true
                }));
        }
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
    }

    const openDatePicker = () => {
        setDatePickerOpen(true);
    };

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function handleTalentSelect(value: IRCPTTerminationReport) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: value.talent_name,
            },
        });
        return;
    }
    function getSSNInfo(value: string, id: string) {
        return (
            <SSNHolder
                value={value}
                iconColor={THEME.tableRowTextColor}
                onClick={() => handleOnSSNView(id)}
                showFormat={false}
            />
        );
    }

    function handleOnSSNView(id: string) {
        dispatch(talentRequestSSNActions.initialiseRequestSSN(id));
        if (profileState.profile && profileState.profile.show_ssn) {
            setShowSSNPopup({ visible: true, talentId: id });
        } else {
            dispatch(postSnackbarMessage(t("ssn_access_is_disabled")));
        }
    }

    function handleOnSuccessSSNClose() {
        setShowSSNPopup(null);
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 3;
        if (value === 8) return 1;
        if (value === 9) return 1;
        return 1;
    }

    const showSpinner = () => terminationReportListState?.loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: IRCPTTerminationReport[] | undefined = terminationReportList!;

        if (terminationReportList && Array.isArray(terminationReportList) && sortedField != null) {
            list = [...terminationReportList].sort((a, b) => {
                const valueA =
                    a[sortedField] !== null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] !== null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });

    }

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (terminationReportListState?.error != null && terminationReportList?.length === 0) {
            return <TableErrorHandler error={terminationReportListState?.error} onRefresh={getTerminatedReportList} />;
        }

        if (terminationReportList?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No Data found"} onClick={getTerminatedReportList} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <div className="nhr-table">
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList()?.map((doc) => {
                            return (
                                <tr key={doc.talent_id}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                        {doc.talent_ssn
                                            ? getSSNInfo(doc.talent_ssn, doc.talent_id)
                                            : ""}
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}
                                        isButton={true}
                                        onClick={() => handleTalentSelect(doc)}
                                    >
                                        <span>{doc.talent_name}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                        <span>{doc.address}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        <span>{doc.city}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                        <span>{doc.state}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <span>{doc.zip_code}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                        <span>{getDateString(doc.termination_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                        <span>{ }</span>
                                    </TableData>
                                </tr>
                            )
                        })}
                    </SortableTable>
                </div>
            );
        }
    };

    return (
        <div className="new-hire-report-container">
            <div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="new-hire-report-header">
                <div className="nh-r-left">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                            />
                        </div>
                    </div>
                </div>
                <div className="nh-r-right">
                    <div className="total-count-action">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                    <div className="download-btn">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={getFilteredList()?.length !== 0 && true}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    <div>
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                        />
                    </div>
                </div>
            </div>

            <div className="table-container">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t("termination_report")}</span>
                        </div>
                    </div>
                </div>
                {getTable()}
            </div>
            {showSSNPopup && (
                <Portal>
                    <ViewSSNPopup
                        visible={showSSNPopup.visible}
                        title={t("social_security_number")}
                        talentId={showSSNPopup.talentId}
                        onClose={() => setShowSSNPopup(null)}
                        onDissmiss={() => setShowSSNPopup(null)}
                        onSuccessClose={handleOnSuccessSSNClose}
                    />
                </Portal>
            )}
        </div>
    );
}

export default TerminationReport;