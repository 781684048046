import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccountAPI, firebaseLogout } from "../../apis";
import { AppError, Profile } from "../../interfaces";
import preferences from "../../preferences";
import { catchRequestError, delay} from "../../utils";

/// check auth token
export const checkAuthToken = createAsyncThunk<
    // Return type of the payload creator
    String,
    // First argument to the payload creator
    void,
    // Types for ThunkAPI
    {
        fulfilled: String,
        rejectValue: AppError,
        rejectedMeta: AppError,
    }
>(
    '@profile/check',
    async (_, thunkAPI) => {
        const token = preferences.getAuthToken();
        if (token != null) {
            thunkAPI.dispatch(getProfile());
            return token;
        } else {
            const error: AppError = {
                message: 'token not found',
                code: 0
            }
            return thunkAPI.rejectWithValue(error, error);
        }
    },
);

/// get user
export const getProfile = createAsyncThunk<
    Profile,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@profile/get',
    async (_, thunkAPI) => {
        try {
            const res = await AccountAPI.getProfile();
            return res.data as Profile;
        }   catch (error){
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// logout
export const profileLogout = createAsyncThunk<
    boolean,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    'profile/logout',
    async (_, thunkAPI) => {
        try {
            await delay(3000);
            await firebaseLogout();
            // await AccountAPI.logout();
            preferences.removeAuthToken();
            
            return true;
        }   catch (error){
            preferences.removeAuthToken();
            return catchRequestError(error, thunkAPI);
        }
    }
);


