import { combineReducers } from "@reduxjs/toolkit";
import { manageMyProfileUserSlice } from "./update_my_profile_user/update_user_reducer";
import { myProfileSlice } from "./my_profile_details/my_profile_reducer";
import { myProfileTeamsReducers } from "./my_profile_teams/index";

export const myProfileReducers = combineReducers({
    [myProfileSlice.name]: myProfileSlice.reducer,
    [manageMyProfileUserSlice.name]: manageMyProfileUserSlice.reducer,
    'myProfileTeams': myProfileTeamsReducers,
});

export * from "./my_profile_details/my_profile_thunk"
export * from "./my_profile_details/my_profile_selector"

export * from "./update_my_profile_user/update_user_thunk"
export * from "./update_my_profile_user/update_user_selector"

export * from './my_profile_teams'