import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props } from "react-select";
import { LoadingType, THEME } from "../../../../../enums";

import { AgencyOnboarding } from "../../../../../interfaces";
import {
    postSnackbarMessage,
    useAppDispatch,
    useAppSelector,
} from "../../../../../redux/store";
import { CustomButton, FormInput } from "../../../../../utils";
import {
    getAgencyOnBoarding,
    selectAgencyOnboardingListState,
    selectOnboardingUpdatestate,
    updateAgencyOnboarding,
} from "../../../../../redux/admin_center";
import { manageAgencyOnboardingActions } from "../../../../../redux/admin_center/system_configuration/onboarding/onboarding_reducer";
import { EditIcon } from "../../../../../icons";
import SectionCard from "../../../../../components/section_card/section_card";

const AdminCenterOnBoardingAndESCWOTC: React.FunctionComponent<Props> = (
    props: Props
) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [onboardingData, setOnboardingData] = useState({} as AgencyOnboarding);
    const [inputEnable, setInputEnable] = useState<boolean>(true);

    const agencyOnboardingState = useAppSelector((state) =>
        selectAgencyOnboardingListState(state)
    );
    const saveResponse = useAppSelector((state) =>
        selectOnboardingUpdatestate(state)
    );
    const { loading, error, agencyOnboardingData } = useAppSelector((state) =>
        selectAgencyOnboardingListState(state)
    );

    useEffect(() => {
        if (agencyOnboardingState.loading === LoadingType.succeeded) {
            setOnboardingData(agencyOnboardingState.agencyOnboardingData);
            if (
                onboardingData.esc_api_key === "" &&
                onboardingData.wotc_business_id === "" &&
                onboardingData.wotc_partner_password === "" &&
                onboardingData.wotc_Location_id === ""
            ) {
                setInputEnable(false);
            }
        }
    }, [agencyOnboardingState.loading]);

    useEffect(() => {
        dispatch(getAgencyOnBoarding());
        return () => { };
    }, []);

    const isAddLoading = () => {
        return saveResponse.loading === LoadingType.pending;
    };
    const handleFieldChange = (id, value) => {
        setOnboardingData({ ...onboardingData, [id]: value });
    };
    const handleSave = () => {
        if(((onboardingData.esc_api_key !== "" && onboardingData.esc_api_key !== null) ||
        (onboardingData.wotc_business_id !== "" && onboardingData.wotc_business_id !== null) ||
        (onboardingData.wotc_partner_password !== "" && onboardingData.wotc_partner_password !== null) ||
        (onboardingData.wotc_Location_id !== "" && onboardingData.wotc_Location_id !== null))){
            dispatch(
                updateAgencyOnboarding({
                    ...onboardingData,
                    esc_api_key: onboardingData.esc_api_key,
                    wotc_business_id: onboardingData.wotc_business_id,
                    wotc_partner_password: onboardingData.wotc_partner_password,
                    wotc_Location_id: onboardingData.wotc_Location_id,
                })
            );
            setInputEnable(true);
        }
    };
    const handleEdit = () => {

        setInputEnable(false);
    };
    useEffect(() => {
        if (
            saveResponse.loading == LoadingType.succeeded &&
            saveResponse.response
        ) {
            dispatch(postSnackbarMessage(saveResponse.response ?? null));
            dispatch(manageAgencyOnboardingActions.clearUpdateState())
            dispatch(manageAgencyOnboardingActions.clearUpdateStateError())
        }
    }, [saveResponse.loading]);
    return (
        <div className="ac-sc-ob-content-container">
            <div className="ac-ob-btn-header">
                <button className="icon-btn ac-ob-edit-icon" onClick={() => { }}>
                    <EditIcon
                        width={"100%"}
                        height={"100%"}
                        style={{ color: "#fff" }}
                        onClick={handleEdit}
                    />
                </button>
            </div>
            <div className="ac-sc-ob-content ac-sc-ob-company-content">
                <div className="flex-input-holder">
                    <div className="wotc-card">
                        <SectionCard
                            backgroundColor="#EBF1F8"
                            activeColor="#0B3E78"
                            title={t('wotc').toUpperCase()}
                        >
                            <div className="input-holder" >
                                <FormInput
                                    id={"wotc_business_id"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={onboardingData.wotc_business_id ?? ""}
                                    label={t("wotc_business_id")}
                                    placeholder=""
                                    required={false}
                                    multiline={false}
                                    disabled={inputEnable}
                                />
                                <FormInput
                                    id={"wotc_partner_password"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={onboardingData.wotc_partner_password ?? ""}
                                    label={t("wotc_partner_password")}
                                    placeholder=""
                                    required={false}
                                    multiline={false}
                                    disabled={inputEnable}
                                />
                                <FormInput
                                    id={"wotc_Location_id"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={onboardingData.wotc_Location_id ?? ""}
                                    label={t("wotc_Location_id")}
                                    placeholder=""
                                    required={false}
                                    multiline={false}
                                    disabled={inputEnable}
                                />
                            </div>
                        </SectionCard>
                    </div>
                    <div className="esc-card">
                        <SectionCard
                            backgroundColor="#EBF1F8"
                            activeColor="#0B3E78"
                            title={t('esc').toUpperCase()}
                        >
                            <div className="input-holder">
                                <FormInput
                                    id={"esc_api_key"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={onboardingData.esc_api_key ?? ""}
                                    label={t("esc_api_key")}
                                    placeholder=""
                                    required={false}
                                    multiline={false}
                                    disabled={inputEnable}
                                />
                            </div>
                        </SectionCard>
                    </div>
                </div>
            </div>

            <div className="ac-sc-ob-content-actions company-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("cancel")}
                        enable
                        backgroundColor={THEME.secondaryColor4}
                        onClick={() => {
                            setOnboardingData(agencyOnboardingData);
                            setInputEnable(true);
                        }}
                    />
                </div>
                <div className="btn-save">
                    <CustomButton
                        loading={isAddLoading()}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("save")}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={handleSave}
                        enable={!inputEnable}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdminCenterOnBoardingAndESCWOTC;
