import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './company_contact_create_reducer';

const select = (state: IRootState) => state.companies.details.contacts.contactCreate;
export const selectCompanyContactCreateState = createSelector(select, reducer.selectCompanyContactCreateState);
export const selectSingleCompanyContactCreateStateById = (id: string | undefined | null) => createSelector(
    selectCompanyContactCreateState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleCompanyContactCreateState(state, id);
        return reducer.selectInitialSingleCompanyContactCreateState();
    }
);