import { combineReducers } from "@reduxjs/toolkit";
import { PayCheckSlice } from "./paycheck_list/paycheck_list_reducer";
import { PRTransactionSummarySlice } from "./tranaction_summary/transaction_summary_reducer";
import { payrollErrorReportListSlice } from "./errors_report/errors_report_reducer";
import { PayrollManageBatchSlice } from "./manage_payroll/payroll_manage_batch_reducer";
import { PayrollBatchSlice } from "./payroll_batch/payroll_batch_reducer";
import { PayrollTimecardsSlice } from "./payroll_timecards/payroll_timecards_reducer";
import { voidRevCheckSlice } from "./void_reverse_paycheck/void_reverse_check_reducer";

export const backOfficePayRollReducers = combineReducers({
  [PayCheckSlice.name]: PayCheckSlice.reducer,
  [payrollErrorReportListSlice.name]: payrollErrorReportListSlice.reducer,
  [PRTransactionSummarySlice.name]: PRTransactionSummarySlice.reducer,
  [PayrollBatchSlice.name]: PayrollBatchSlice.reducer,
  [PayrollManageBatchSlice.name]: PayrollManageBatchSlice.reducer,
  [PayrollTimecardsSlice.name]: PayrollTimecardsSlice.reducer,
  [voidRevCheckSlice.name]: voidRevCheckSlice.reducer

});

export * from "./paycheck_list/paycheck_list_thunk";
export * from "./paycheck_list/paycheck_list_selector";

export * from "./errors_report/errors_report_thunk";
export * from "./errors_report/errors_report_selector";

export * from "./tranaction_summary/transaction_summary_thunk";
export * from "./tranaction_summary/transaction_summary_selector";


export * from "./manage_payroll/payroll_manage_batch_thunk";
export * from "./manage_payroll/payroll_manage_batch_selector";

export * from "./payroll_batch/payroll_batch_thunk";
export * from "./payroll_batch/payroll_batch_selector";

export * from"./payroll_timecards/payroll_timecards_thunk";
export * from"./payroll_timecards/payroll_timecards_selector";

export * from "./void_reverse_paycheck/void_reverse_check_thunk";
export * from "./void_reverse_paycheck/void_reverse_check_selector";



