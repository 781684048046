import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, JobStatus } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getJobStatus = createAsyncThunk<
    Array<JobStatus>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/job_status/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getJobStatus();
            return res.data as JobStatus[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createJobStatus = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_status/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createJobStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteJobStatus = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_status/delete',
    async (method_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteJobStatus(method_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateJobStatus = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_status/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateJobStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);