import React, { useState } from 'react';
import "./apollo_table_pagination.scss";
const ApolloPagination = ({ totalPages, onPageChange, curPageNum, totalCount }) => {
    // const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (page) => {
        // setCurrentPage(page);
        onPageChange(page);
    };

    const renderPageOptions = (): JSX.Element[] => {
        const options: JSX.Element[] = [];
        for (let i = 1; i <= totalPages; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
        return options;
    };
    const itemsPerPage = 10;
    
    const indexOfLastItem = curPageNum * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    function formatNumber(n: number | undefined) {
        // format number 1000000 to 1,234,567
        return n?.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className='tb-footer-pagination'>
            <div>
                <span>Showing {formatNumber((curPageNum * itemsPerPage) - itemsPerPage + 1)} - {curPageNum === totalPages ? formatNumber(totalCount) : (curPageNum * itemsPerPage)} of {formatNumber(totalCount)}</span>
            </div>
            <div className='prev-next'>
                <button onClick={() => handlePageChange(curPageNum - 1)} disabled={curPageNum === 1} >
                    Prev
                </button>
                <span className='curPage'>{curPageNum}</span>
                <button
                    onClick={() => handlePageChange(curPageNum + 1)}
                    disabled={indexOfLastItem >= totalCount}
                >
                    Next
                </button>
            </div>
            <div>
                <span>Page </span>
                <select value={curPageNum} onChange={(e) => handlePageChange(Number(e.target.value))}>
                    {renderPageOptions()}
                </select>
                <span> of {totalPages}</span>
            </div>

        </div>
    );
};

export default ApolloPagination;
