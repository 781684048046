import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import * as myProfileThunk from "./my_profile_thunk";
import { MyProfile } from "../../../interfaces/my_profile";

export interface MyProfileState {
    error?: AppError | null,
    loading: LoadingType,
    myProfile?: MyProfile | null,
}

const initialState: MyProfileState = {
    error: null,
    loading: LoadingType.idle,
    myProfile: null,
}


export const myProfileSlice = createSlice({
    name: 'myProfile',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
       
            .addCase(myProfileThunk.getMyProfile.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(myProfileThunk.getMyProfile.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.error,  }))
            .addCase(myProfileThunk.getMyProfile.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, myProfile: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = myProfileSlice.actions;
export const selectState = (state: MyProfileState) => state;
export const selectLoading = (state: MyProfileState) => state.loading;
export const selectError = (state: MyProfileState) => state.error;


