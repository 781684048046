import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import {  selectSalesProfile,selectSaveState,selectCompetitors,selectServiceProfile, selectShifts,selectRemoveState, selectShiftSaveState } from "./company_profile_reducer";

const select = (state: IRootState) => state.companies.universalDirectory.companyProfileData;

export const selectCompanySalesProfile = createSelector(select,selectSalesProfile);
export const selectSave_state =createSelector(select,selectSaveState);
export const selectCompanyServiceProfile=createSelector(select,selectServiceProfile)
export const selectCompetitorsData = createSelector(select,selectCompetitors);
export const selectShiftsData=createSelector(select,selectShifts);
export const selectRemove_state=createSelector(select,selectRemoveState);
export const selectShiftSave_State=createSelector(select,selectShiftSaveState);
