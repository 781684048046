import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IBackOfficeUploadUrlResponse, ITCErrorReports } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { S3API } from "../../../../apis/s3_api";


export const getPRERListThunk = createAsyncThunk<
    ITCErrorReports[],
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_errors_report_list/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getPayrollErrorReports(payload);
            return res.data as ITCErrorReports[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


export const approvePRErrorThunk = createAsyncThunk<
    string,
    string[],
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_errors_report/approve/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.payrollErrorsReportsApprove(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const resolvePRErrorThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_errors_report/resolve/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.payrollErrorsReportsResolve(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getPERDownloadUrl = createAsyncThunk<
    IBackOfficeUploadUrlResponse,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@back_office/payroll/payroll_error_report/download_url/get',
    async (weekId, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getPayrollErrorReportDownloadUrl(weekId);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);