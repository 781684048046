import { combineReducers } from "@reduxjs/toolkit";
import { talentPRsetupSlice } from "./back_office_payroll/backoffice_payroll_setup_reducer";
import { talentPRDDsetupSlice } from "./back_office_direct_deposit/back_office_direct_depost_reducer";
import { talentDirectDepositSlice } from "./back_office_direct_deposit_add/direct_deposit_add_reducer";

export const talentBackOfficePayRollSetUpReducers = combineReducers({
    [talentPRsetupSlice.name]: talentPRsetupSlice.reducer,
    [talentPRDDsetupSlice.name]: talentPRDDsetupSlice.reducer,
    [talentDirectDepositSlice.name]: talentDirectDepositSlice.reducer,

});

export * from './back_office_payroll/backoffice_payroll_setup_selector';
export * from './back_office_payroll/backoffice_payroll_setup_thunk';


export * from './back_office_direct_deposit/back_office_direct_depost_selector';
export * from './back_office_direct_deposit/back_office_direct_depost_thunk';

export * from './back_office_direct_deposit_add/direct_deposit_add_selector';
export * from './back_office_direct_deposit_add/direct_deposit_add_thunk'