import { useTranslation } from "react-i18next";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import { AppRoutes, getCompaniesNavHeaderProps } from "../../routes";
import CompanyDashboardPage from "./pages/company_dashboard/company_dashboard_page";
import CompanySalesProfilePage from "./pages/company_sales_profile/company_sales_profile_page";
import CompanyServiceProfilePage from "./pages/company_service_profile/company_service_profile_page";
import UniversalDirectoryPage from "./pages/universal_directory/universal_directory_page";
import { useEffect } from "react";
import { useAppDispatch } from "../../redux/store";
import { getCertificationsList, getCredentialsList, getEducationList, getRequiredEquipmentList, getSkillSetList, } from "../../redux/store";
import { getCompetitorsList, getRoleUsersList } from "../../redux/selectors";
import CompanyJobOrdersPage from "./pages/company_job_orders/company_job_orders_page";
import CompanyAssignmentPage from "./pages/company_assignments/company_assignment_page";
import CompanyContactsPage from "./pages/company_contacts/company_contact_page";
import { GetCloseAction } from "../../utils";
import CompanyBackOfficeFormatSetup from "./pages/company_back_office_format_setup/company_back_office_format_setup";
import CompanyBackOfficeInvoiceDocs from "./pages/company_back_office_invoice_docs/company_back_office_invoice_docs";
import DirectoryUploadStatusDirectoryPage from "../components/directory_upload_status/directory_upload_status";

interface Props extends RouteComponentProps<any> { }


/// Company Details
const CompanyDetailsTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const pathSplit = location.pathname.split('/');
    const departmentId = pathSplit.length === 6 ? pathSplit[pathSplit.length - 1] : null;

    useEffect(() => {
        dispatch(getSkillSetList());
        dispatch(getCertificationsList());
        dispatch(getCredentialsList());
        dispatch(getEducationList());
        dispatch(getRequiredEquipmentList());
        dispatch(getRoleUsersList());
        dispatch(getCompetitorsList());

        return () => { }
    }, [])

    function handleNavigationChange(value: string) {
        history.push({
            pathname: departmentId ? `${value}/${departmentId}` : value,
            state: location.state
        });
    }

    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'dashboard', route: `${AppRoutes.companiesDetailsPage}/${params.id}/dashboard` },
                { title: 'sales_profile', route: `${AppRoutes.companiesDetailsPage}/${params.id}/sales-profile` },
                { title: 'service_profile', route: `${AppRoutes.companiesDetailsPage}/${params.id}/service-profile` },
                { title: 'job_orders', route: `${AppRoutes.companiesDetailsPage}/${params.id}/job-orders` },
                { title: 'assignments', route: `${AppRoutes.companiesDetailsPage}/${params.id}/assignments` },
                { title: 'contacts', route: `${AppRoutes.companiesDetailsPage}/${params.id}/contacts` },
                { title: 'back_office_format_and_set_up', route: `${AppRoutes.companiesDetailsPage}/${params.id}/back-office-format-and-set-up` },
                { title: 'back_office_invoices_and_docs', route: `${AppRoutes.companiesDetailsPage}/${params.id}/back-office-invoices-and-docs` },
            ]}
            onChange={handleNavigationChange}
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.companiesPage)} />}
        >
            <Switch>
                <Route exact path={`${AppRoutes.companiesDetailsPage}/:id/dashboard/:departmentId?`} component={CompanyDashboardPage} />
                <Route exact path={`${AppRoutes.companiesDetailsPage}/:id/sales-profile/:departmentId?`} component={CompanySalesProfilePage} />
                <Route exact path={`${AppRoutes.companiesDetailsPage}/:id/service-profile/:departmentId?`} component={CompanyServiceProfilePage} />
                <Route exact path={`${AppRoutes.companiesDetailsPage}/:id/job-orders/:departmentId?`} component={CompanyJobOrdersPage} />
                <Route exact path={`${AppRoutes.companiesDetailsPage}/:id/assignments/:departmentId?`} component={CompanyAssignmentPage} />
                <Route exact path={`${AppRoutes.companiesDetailsPage}/:id/contacts/:departmentId?`} component={CompanyContactsPage} />
                <Route exact path={`${AppRoutes.companiesDetailsPage}/:id/back-office-format-and-set-up/:departmentId?`} component={CompanyBackOfficeFormatSetup} />
                <Route exact path={`${AppRoutes.companiesDetailsPage}/:id/back-office-invoices-and-docs/:departmentId?`} component={CompanyBackOfficeInvoiceDocs} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: `${location.pathname}/dashboard`,
                            state: { ...(location.state as any) }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );
}

/// Universal directory folder
const CompaniesTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'universal_directory', route: AppRoutes.companiesUniversalDirectory },
                { title: 'companies_upload_status', route: AppRoutes.companiesUploadStatusDirectory },
            ]}
            onChange={(value) => history.push(value)}
        >
            <Switch>
                <Route exact path={AppRoutes.companiesUniversalDirectory} component={UniversalDirectoryPage} />
                <Route exact path={AppRoutes.companiesUploadStatusDirectory} render={(props) => <DirectoryUploadStatusDirectoryPage {...props} importType="Company" headerTitle={t("companies_upload_status")}/>} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: AppRoutes.companiesUniversalDirectory,
                            state: { from: location }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );
}



export const CompaniesNavigation: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getCompaniesNavHeaderProps(location, match, t);

    return (
        <PageNavHeader data={headerOptions} history={history} location={location}>
            <Switch>
                <Route path={`${AppRoutes.companiesDetailsPage}/:id`} component={CompanyDetailsTabNav} />
                <Route path={'/'} component={CompaniesTabNav} />
            </Switch>
        </PageNavHeader>
    );
}

