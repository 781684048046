import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ITrafficLightEngagementDetails } from "../../../../interfaces";
import * as thunk from "./send_onboarding_link_thunk";

export interface SendOnBoardingLinkState {
    response: string | null,
    error?: AppError | null,
    loading: LoadingType,
}



const initialState: SendOnBoardingLinkState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


export const dasboardSendOnBoardingLinksSlice = createSlice({
    name: 'sendOnBoardingLinks',
    initialState: initialState,
    reducers: {
        clearSendOnBoardingLinksError(state) {
            return { ...state, error: null };
        },
        clearSendOnBoardingLinkState(state) {
            return ({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.sendOnBoardingLinkThunk.pending,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.pending,
                    error: null,
                })
            )
            .addCase(
                thunk.sendOnBoardingLinkThunk.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.sendOnBoardingLinkThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null,
                    response: action.payload,
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const sendOnBoardingLinksStateActions = dasboardSendOnBoardingLinksSlice.actions;
export const selectState = (state: SendOnBoardingLinkState) => state;
export const selectLoading = (state: SendOnBoardingLinkState) => state.loading;
export const selectError = (state: SendOnBoardingLinkState) => state.error;


