import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import { selectState, selectTCERDownloadUrl } from "./errors_reports_reducer";

const select = (state: IRootState) => state.backOffice.timeCards.timecardsErrorReportList;
export const selectTimecardsErrorsReportListState = createSelector(
    select,
    selectState
);
export const selectTCERDownloadUrlState = createSelector(
    select,
    selectTCERDownloadUrl
);
