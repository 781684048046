import { combineReducers } from "@reduxjs/toolkit";
import {salesDocumentSlice} from "./marketing_docs_list/marketing_docs_reducer";
import { salesDocumentSendByEmailSlice } from "./marketing_doc_send_by_email/send_by_email_reducer";

export const marketingDocsReducers = combineReducers({
    [salesDocumentSlice.name]: salesDocumentSlice.reducer,
    [salesDocumentSendByEmailSlice.name]: salesDocumentSendByEmailSlice.reducer
});


export * from "./marketing_docs_list/marketing_docs_selector";
export * from "./marketing_docs_list/marketing_docs_thunk";

export * from "./marketing_doc_send_by_email/send_by_email_selector";
export * from "./marketing_doc_send_by_email/send_by_email_thunk";
