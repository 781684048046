import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI, } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { getOCIRActiveList } from "../candidate_input_required";
import { getOCTWActiveList } from "../completed_this_week";
import { getORFAActiveList } from "../ready_for_approval";

export const archiveOAPPLinkThunk = createAsyncThunk<
    string,
    string[],
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/oboarding/application/archive',
    async (payload, thunkAPI) => {
        try {
            const res = await DashboardAPI.archiveOnboardingApplicationLink(payload);
            thunkAPI.dispatch(getOCIRActiveList());
            thunkAPI.dispatch(getORFAActiveList());
            thunkAPI.dispatch(getOCTWActiveList());
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
