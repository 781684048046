import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IDepartmentsList } from "../../../interfaces";
import * as thunk from "./departments_list_thunk";

export interface departmentsDropdownListState extends EntityState<IDepartmentsList> {
    error?: AppError | null,
    loading: LoadingType,
}

const departmenstDropdownListAdapter: EntityAdapter<IDepartmentsList> = createEntityAdapter<IDepartmentsList>({
    selectId: (doc) => doc.id,
})

const initialState: departmentsDropdownListState = departmenstDropdownListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const departmentsDropdownListSlice = createSlice({
    name: 'departmentsDropdown',
    initialState: initialState,
    reducers: {
        clearDepartmentsDropdownListError(state){
            return {...state, error: null};
        },
        clearDepartmentsDropdownListState(state){
            return departmenstDropdownListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getDepartmentsDropdown.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getDepartmentsDropdown.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getDepartmentsDropdown.fulfilled, (state, action) => departmenstDropdownListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const departmentsDropdownListActions = departmentsDropdownListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = departmenstDropdownListAdapter.getSelectors();
export const selectState = (state: departmentsDropdownListState) => state;
export const selectLoading = (state: departmentsDropdownListState) => state.loading;
export const selectError = (state: departmentsDropdownListState) => state.error;


