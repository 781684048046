import { useTranslation } from 'react-i18next';
import { THEME } from '../../enums';
import { LocationIcon, CallIcon, GlobeIcon, ActiveAssignmentsIcon, BriefcaseIcon, CallWhiteIcon, CompaniesIcon, ReloadIcon } from '../../icons';
import { ICompany, IDepartment } from '../../interfaces';
import { SingleCompanyDepartmentsState } from '../../redux/companies/details/dashboard/departments/departments_reducer';
import { addressParser, CustomButton, getArrayStrings, getCompanyStatusText, getHttpsString } from '../../utils';
import SocialMediaProfile from '../social_media_profile/social_media_profile';
import './company_details_section.scss';
import { LoadingType, ProfilePictureType } from "../../enums";
import UploadProfile from "../../components/image_crop/image_crop_popup";
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, getPreSignedURLForDownload, selectSingleProfilePictureStateById } from "../../redux/store";
import { Portal } from "react-portal";
import UserAvatar from '../user_avatar/user_avatar';

interface Props {
    company: ICompany | IDepartment | null,
    branchId: string | null,
    departmentsState: SingleCompanyDepartmentsState,
    isDepartment: boolean,
    onDepartmentChange: (value: string) => void,
    onActionClick: (value: string) => void,
    // onShowRefreshPopup: Dispatch<SetStateAction<boolean>>;
    // onAddCredit: Dispatch<SetStateAction<boolean>>;
    // availCredits: number | undefined,
}

const CompanyDetailsSection: React.FunctionComponent<Props> = (props) => {
    const { company, isDepartment, onActionClick, 
        // onShowRefreshPopup, onAddCredit, availCredits
     } = props;
    const { t } = useTranslation();

    const companyProfileSections = [
        {
            sectionTitle: 'department',
            sectionIcon: CompaniesIcon,
            sectionContent: [
                { title: 'department', valueField: 'name' },
            ]
        },
        {
            sectionTitle: 'address',
            sectionIcon: LocationIcon,
            sectionContent: [
                {
                    title: '', valueField: addressParser({
                        'street_address': company ? company['street_address'] : null,
                        'city': company ? company['city'] : null,
                        'state': company ? company['state'] : null,
                        'zip_code': company ? company['zip_code'] : null,
                    })
                },
            ]
        },

        {
            sectionTitle: 'contact',
            sectionIcon: CallIcon,
            sectionContent: [
                { title: '', valueField: 'phone' },
            ]
        },

        {
            sectionTitle: 'website',
            sectionIcon: GlobeIcon,
            sectionContent: [
                { title: 'website', valueField: 'website' },
            ]
        },
    ];


    return (
        <div className="cds-container">
            <CompanyInfo
                name={company?.name}
                uri={company?.logo}
                objectId={company?.id}
                rating={company?.engagement_rating}
                level={company?.engagement_level}
            />
            <div className="cds-branch-selector">
                <div className="cds-status">
                    <span>{`${t('status')}:`} <span className='status-txt'>{company?.status ?? ''}</span></span>
                </div>
                {/* {company && company.imported_from_apollo &&
                    (<div>
                        <CustomButton
                            leftIcon={(<div className="err-h-add-icon">
                                <ReloadIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
                            </div>)}
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('refresh_company')}
                            enable={(availCredits! > 2)}
                            backgroundColor={THEME.buttonColor15}
                            onClick={() => onShowRefreshPopup(true)}
                            customClassName={'refresh_company'}
                        />
                        {availCredits! <= 2 && (
                            <div className='no-creds-div'>
                                <div className='no-creds'>{t('no_credits_available')}</div>
                                <div>
                                    <div onClick={() => onAddCredit(true)} className='add-creds-div'>
                                        {t("add_credits")}
                                    </div>
                                    
                                </div>
                            </div>
                        )}

                    </div>)} */}
            </div>
            {companyProfileSections.map((doc, index) => {
                return (
                    <div key={index} className={doc.sectionTitle === "contact" ? "cds-info-address-container" : "cds-info-container"}>
                        <div className="cds-info-icon">
                            <doc.sectionIcon width={"100%"} height={"100%"} />
                        </div>
                        <div className="cds-info-content">
                            {doc.sectionContent.map((data, index) => {
                                const arrayValue = getValues(data.valueField);
                                return (
                                    <div key={index} className="cds-info-holder">
                                        <div className="cds-into-title">
                                            {
                                                doc.sectionTitle === "address" || doc.sectionTitle === "contact" ?
                                                    <span style={{ display: "grid" }}>{t(data.title).toUpperCase()}</span> :
                                                    <span>{t(data.title).toUpperCase()}</span>
                                            }
                                        </div>
                                        <div
                                            className="cds-info-value"
                                            style={{ marginBottom: data.lineTwo ? '0px' : '0.5em' }}
                                        >
                                            {arrayValue}
                                        </div>
                                        {data.lineTwo && <div className="cds-info-value">{getValues(data.lineTwo)}</div>}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            {company?.social_media_links != null && <SocialMediaProfile
                title={t('social_media').toUpperCase()}
                profile={company.social_media_links}
            />}
            {props.isDepartment === false && <CompanyActions onClick={onActionClick} />}

        </div>
    );

    function getValues(obj: any) {
        if (company == null) return (<span>{''}</span>);

        if (Array.isArray(obj)) {
            return getArrayStrings(obj, company);
        }

        if (obj === "website") {
            return (
                (company.website !== "" && company.website !== null ? <span style={{ textTransform: "none" }}><a href={getHttpsString(company?.website)} target="_blank" rel="noreferrer">{company?.website}</a></span> : <span className="na-span">NA</span>)
            )
        }

        if (obj === "name" && isDepartment === false) {
            return (
                <span>{t('corporate')}</span>
            )
        }
        if (obj === "phone") {
            return (
                (company.phone !== null && company.phone !== "") ? <span>{company.phone}</span> : <span className="na-span">NA</span>
            )
        }
        if (typeof obj === "object") {
            return obj;
        }
        const value = (obj !== '' && company![obj] !== null) ? company![obj] : '';
        return (
            <span>{value}</span>
        );
    }


}

const CompanyInfo = (props) => {
    const { name, rating, level } = props;
    const dispatch = useAppDispatch();
    const [showImageUpload, setShowImageUpload] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);
    const [imageURL, setImageURL] = useState('');

    const profilePictureState = useAppSelector((state) => selectSingleProfilePictureStateById(ProfilePictureType.companies + '-' + props.objectId)(state));

    useEffect(() => {
        setSentRequest(true);
        dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.companies }));
    }, [])

    function updateProfile() {
        dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.companies }));
        setSentRequest(true);
    }

    useEffect(() => {
        if (sentRequest && profilePictureState.download.loading === LoadingType.succeeded) {
            setSentRequest(false);
            setImageURL(profilePictureState.download.response ?? '');
        }
    }, [profilePictureState.download.loading])

    return (
        <div className="cds-user-info">
            <UserAvatar
                onClick={() => (setShowImageUpload(true))}
                url={imageURL}
                rating={{ value: rating, level: level }}
            />
            <div className="cds-user-name">
                <span>{name}</span>
            </div>
            {showImageUpload && <Portal>
                <UploadProfile
                    onClose={() => setShowImageUpload(false)}
                    onDismiss={() => setShowImageUpload(false)}
                    visible={showImageUpload}
                    onApprove={updateProfile}
                    objectId={props.objectId}
                    objectType={ProfilePictureType.companies}
                />
            </Portal>}
        </div>
    );
}



const buttonsList = [
    //{ title: 'assign_to_job_order', icon: PlusWhiteIcon, type: 'assign_to_job_order', color: THEME.defaultHighLightColor },
    { title: 'company_assignments', icon: ActiveAssignmentsIcon, type: 'company_assignments', color: THEME.statusActiveColor },
    { title: 'company_job_orders', icon: BriefcaseIcon, type: 'company_job_orders', color: THEME.defaultHighLightColor },
    { title: 'schedule_a_sales_call', icon: CallWhiteIcon, type: 'schedule_a_sales_call', color: THEME.buttonColor15 },
];

interface CompanyActionsProps {
    onClick: (type: string) => void
}



const CompanyActions: React.FunctionComponent<CompanyActionsProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="cds-action-container">
            {buttonsList.map((doc, index) => {
                return (
                    <div key={index} className="cds-action">
                        <CustomButton
                            customStyle={{ width: '-webkit-fill-available' }}
                            leftIcon={(<div className="cds-action-icon"><doc.icon width={"100%"} height={"100%"} style={{ color: "#fff" }} /></div>)}
                            loading={false}
                            textStyle={{ textTransform: 'none' }}
                            name={t(doc.title)}
                            enable={true}
                            backgroundColor={doc.color}
                            onClick={() => props.onClick(doc.type)}
                        />
                    </div>

                )
            })}
        </div>
    );
}
export default CompanyDetailsSection;