import {createSelector} from "@reduxjs/toolkit";
import * as reducer from "./profile_picture_reducer";
import {IRootState} from "../../store";


const select = (state: IRootState) => state.files.profilePicture;
export const selectProfilePictureState = createSelector(select, reducer.selectProfilePictureState);
export const selectSingleProfilePictureStateById = (id: string) => createSelector(selectProfilePictureState, 
    (state) => {
        if (id && state[id]) return reducer.selectSingleProfilePictureState(state, id);
        return reducer.selectInitialSingleProfilePictureState();
    })
