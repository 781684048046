export enum INFO_TEXT_CATEGORY {
    PAYROLL_TAX_CONFIGURATION = "PAYROLL_TAX_CONFIGURATION",
    SALES_TAX_CONFIGURATION = "SALES_TAX_CONFIGURATION",
    WORK_COMP_CONFIGURATION = "WORK_COMP_CONFIGURATION",
    AGENCY_SETUP = "AGENCY_SETUP",
    BACKOFFICE = "BACKOFFICE",
    WEEKLY_ACCOUNTING = "WEEKLY_ACCOUNTING",
    TIME_CARDS = "TIME_CARDS",
    TIME_CARDS_PROCESS = "TIME_CARDS_PROCESS",
    PAY_ROLL = "PAY_ROLL",
    BANK_AND_INVOICE = "BANK_AND_INVOICE",
    IMPORT_AND_EXPORT = "IMPORT_AND_EXPORT",
    GENERAL_LEDGER = "GENERAL_LEDGER",
    ACCOUNTS_RECEIVABLE = "ACCOUNTS_RECEIVABLE",
    DEDUCTION_AUTHORITY = "DEDUCTION_AUTHORITY",
}