import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { patchImportFileThunk, postImportFileURLThunk, selectImportFileState, selectProfileState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import "./import_page.scss";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { ImportExportFileStatus, ImportMappingGroup, MappingGroup } from "../../../../../enums/back_office";
import { useEffect, useState } from "react";
import { IIEImport } from "../../../../../interfaces";
import { CustomButton, FormInput } from "../../../../../utils";
import DragAndDrop from "../../../../../components/drag_and_drop/drag_and_drop";
import { LoadingType, THEME } from "../../../../../enums";
import ImportFilePopup from "../../../popups/import_export_file/import_file_popup";
import { Portal } from "react-portal";
import { ImportPageSliceActions } from "../../../../../redux/back_office/import_export/import_page/import_page_reducer";
import { AppRoutes } from "../../../../../routes";

interface Props extends RouteComponentProps<any> { }

const BOImportPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
        }`;
    const importFileUploadState = useAppSelector((state) => selectImportFileState(state));
    const [processFile, setProcessFile] = useState<boolean>(false);
    const initialFormState: IIEImport = {
        id: "",
        status: ImportExportFileStatus.PROCESSING,
        description: "",

    }
    const [formState, setFormState] = useState<IIEImport>({
        id: "",
        status: ImportExportFileStatus.PROCESSING
    });

    const handleFormChange = (id: string, val: any) => {
        if (id === "mapping_group") {
            setFormState({ ...formState, [id]: ImportMappingGroup[val] })
        }
        else {
            setFormState({ ...formState, [id]: val })
        }
    };

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleProcessFile = (force_upload?: boolean) => {
        setProcessFile(true);
        dispatch(postImportFileURLThunk({ data: { ...formState, file_name: selectedFile?.name }, file: selectedFile, force_upload: force_upload }))
    };
    useEffect(() => {
        if (importFileUploadState.loading === LoadingType.succeeded && importFileUploadState.upload.loading === LoadingType.succeeded && importFileUploadState.response && importFileUploadState.response.id !== "") {
            dispatch(patchImportFileThunk(importFileUploadState.response.id));
            // setProcessFile(true);
        }

    }, [importFileUploadState.loading, importFileUploadState.response, importFileUploadState.upload.loading]);

    const handleClose = () => {
        setProcessFile(false);
        setSelectedFile(null);
        dispatch(ImportPageSliceActions.clearState());
        setFormState(initialFormState)
    }
    const handleGotoDirectory = () => {
        handleClose();
        props.history.push(AppRoutes.importReportsDirectory);
    }
    return (
        <div className="import-page-container">
            <div className="import-page-header">
                <span>{t('lets_import_file')}</span>
            </div>
            <div className="import-page-content">
                <div className="input-div">
                    <div className="ip-input-row">
                        <div className="ip-input">
                            <CustomFormSelect
                                name={t("mapping_group")}
                                list={Object.keys(ImportMappingGroup).map(id => ({
                                    value: id,
                                    label: ImportMappingGroup[id as keyof typeof ImportMappingGroup]
                                }))}
                                onChange={(val) => handleFormChange("mapping_group", val)}
                                value={formState?.mapping_group}
                                placeholder={t('Select')}
                                required={true}
                                label={t('which_mapping_group_choose')}
                                customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}

                            />
                        </div>
                        <div className="ip-input">
                            <DragAndDrop
                                type={"small"}
                                label={t('which_file_choose')}
                                accept={".csv,.xls,.xlsx"}
                                multiple={false}
                                placeholder={"Select or Drag a File"}
                                selectedFile={selectedFile}
                                onChange={setSelectedFile}
                                onRemove={() => setSelectedFile(null)}
                            />
                        </div>
                    </div>
                    <div className="ip-input-row">
                        <div className="ip-input desc">
                            <FormInput
                                placeholder={t('enter_desc_file_optional')}
                                type={"text"}
                                id={"description"}
                                onChange={(id, val) => handleFormChange(id, val)}
                                value={formState.description}
                                label={t('description')}
                                multiline={true}
                                numberOfLines={10}
                            />
                        </div>

                    </div>
                    {/* <div className="ip-input-row">
                        <div className="ip-input status ">
                            <FormInput
                                value={formState.status}
                                placeholder={t('')}
                                type={"text"}
                                id={"status"}
                                onChange={(val) => { }}
                                label={t('status')}
                                disabled
                            />
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="import-page-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('cancel')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => { setFormState(initialFormState); setSelectedFile(null) }}
                    />
                </div>

                <div className="btn-save">
                    <CustomButton
                        loading={importFileUploadState.loading === LoadingType.pending}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('process_file')}
                        enable={selectedFile && formState.mapping_group ? true : false}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => handleProcessFile(false)}
                    />
                </div>
            </div>
            {processFile &&
                (
                    <Portal>
                        <ImportFilePopup
                            visible={processFile}
                            onClose={handleClose}
                            title={t("import_file")}
                            file_name={selectedFile?.name ?? ""}
                            currentLoginUserName={currentLoginUserName}
                            error={importFileUploadState.error}
                            loading={importFileUploadState.loading}
                            forceUpload={(force_upload) => handleProcessFile(force_upload)}
                            onClearError={() => { }}
                            onGotoDirectory={handleGotoDirectory}

                        />
                    </Portal>
                )
            }
        </div>
    )

}

export default BOImportPage;