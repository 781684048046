import { DocSavedIcon } from "../../icons";
import "./doc_saved_text.scss";

interface Props {
  icon?: React.ReactNode;
  children: React.ReactNode;
  customStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  hideIcon?: boolean;
}

const DocSavedText: React.FunctionComponent<Props> = (props) => {
  return (
    <div className="doc-saved-holder" style={props.customStyle}>
      {!props.hideIcon && (
        <div className="doc-saved-icon">
          {props.icon ?? <DocSavedIcon width={"100%"} height={"100%"} />}
        </div>
      )}
      <div className="doc-saved-content" style={props.contentStyle}>
        {props.children}
      </div>
    </div>
  );
};

export default DocSavedText;
