import { MyProfileTeams } from "../interfaces/my_profile";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const MyProfileAPI = {

    getMyProfile() {
        return interceptor.get(UrlBuilder.myProfile);
    },
    getMyTeamsDetails() {
        return interceptor.get(UrlBuilder.myTeamsDetails);
    },
    getMyTeamsMembersDetailsById(id: string) {
        return interceptor.get(`${UrlBuilder.myTeamsDetails}?team_id=${id}`);
    },
    createMyTeamsMembersDetailsById(doc: {teamId: string, teamMemberIds: Array<string>}) {
        return interceptor.patch(`${UrlBuilder.addTeamsDetails}?team_id=${doc.teamId}`, doc.teamMemberIds);
    },
}