import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom"
import SearchBar from "../../../../components/search_bar";
import { CustomButton, currencyConversion, formatPercentage, getPlusBtnIcon, getTableAccessIcon } from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { deleteSalesTaxConfig, getSalesTaxTaxConfigList, selectSalesTaxConfigList, selectSalesTaxConfigListState } from "../../../../redux/admin_center";
import TableEmpty, { TableErrorHandler, TableFilterEmpty, TableLoading } from "../../../../components/table_empty/table_empty";
import { useEffect, useState } from "react";
import { SalesTaxConfiguration } from "../../../../interfaces/sales_tax_configuration";
import { DeleteIcon, EditIcon } from "../../../../icons";
import { Portal } from "react-portal";
import SalesTaxPopUp from "../../popups/add_sales_tax/add_sales_tax";
import { clearSalesTaxCreateState, clearSalesTaxCreateStateError, clearSalesTaxDeleteState, clearSalesTaxDeleteStateError, clearSalesTaxUpdateState, clearSalesTaxUpdateStateError } from "../../../../redux/admin_center/sales_tax_configuration/sales_tax_configuration_reducer";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";
import "../../payroll_tax_configuration/universal_directory_page/universal_directory_page.scss";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
  { title: "sales_tax_name", code: "sales_tax_name" },
  { title: "state", code: "state" },
  { title: "customer_tax", code: "customer_tax" },
  { title: "tax_type", code: "tax_type_value" },
  { title: "tax_rate", code: "tax_rate" },
  { title: "tax_limit", code: "tax_limit" },
  { title: "tax_limit_cap", code: "tax_limit_cap" },
  { title: "", code: "" },
  { title: "", code: "" },
];

const UniversalDirectoryPage: React.FunctionComponent<Props> = (props) => {
  const { history, match, location } = props;
  const { params } = match;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const SalesTaxListState = useAppSelector((state) => selectSalesTaxConfigListState(state))
  const SalesTaxList = useAppSelector((state) => selectSalesTaxConfigList(state))

  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState('');
  const [addSalesTax, setAddSalesTax] = useState<boolean>();
  const [editSalesTax, setEditSalesTax] = useState<{ visible: boolean, data: SalesTaxConfiguration } | null>(null);
  const [deleteSalesTax, setDelteSalesTax] = useState<{ visible: boolean, salesTaxId: string | undefined, salesTaxName: string | undefined } | null>()

  useEffect(() => {
    getSalesTaxTaxConfigDetails()
  }, []);

  useEffect(() => {
    if (SalesTaxListState?.create?.loading === LoadingType.succeeded || SalesTaxListState?.update?.loading === LoadingType.succeeded || SalesTaxListState?.delete?.loading === LoadingType.succeeded ) {
      getSalesTaxTaxConfigDetails()
    }
  }, [SalesTaxListState?.create?.loading, SalesTaxListState?.update?.loading, SalesTaxListState?.delete?.loading]);

  useEffect(() => {
    if (SalesTaxListState?.delete?.loading === LoadingType.succeeded) {
        dispatch(postSnackbarMessage("Sales Tax Configuration deleted successfully!"));
        dispatch(clearSalesTaxDeleteState())
    }
}, [SalesTaxListState?.delete?.loading])

  const getSalesTaxTaxConfigDetails = () => {
    dispatch(getSalesTaxTaxConfigList());
  }

  function handleSortFieldChange(value: string) {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }

  const handleEdit = (val: SalesTaxConfiguration) => {
    setEditSalesTax({ visible: true, data: val });
  };

  const handleOnDelete = (id: string) => {
    if (id) {
      dispatch(deleteSalesTaxConfig(id))
    }
    setDelteSalesTax(null)
    dispatch(clearSalesTaxDeleteStateError())
  };
  const handleDeleteSalesTax = (id: string, name: string) => {
    setDelteSalesTax({ visible: true, salesTaxId: id, salesTaxName: name });
  };

  function isError() {
    return SalesTaxListState.create.error || SalesTaxListState.update.error
  }

  function isLoading() {
    return SalesTaxListState.create.loading || SalesTaxListState.update.loading
  }

    function handleSalesTaxSuccess() {
      setAddSalesTax(false);
      setEditSalesTax(null);
      dispatch(clearSalesTaxCreateState());
      dispatch(clearSalesTaxUpdateState());
    }

    function handleOnClose() {
      setAddSalesTax(false);
      setEditSalesTax(null);
      handleClearError()
    }
    function handleOnDissmiss() {
      setAddSalesTax(false);
      setEditSalesTax(null);
      handleClearError()
    }
  
    //Handle clear error
    function handleClearError() {
      dispatch(clearSalesTaxCreateStateError());
      dispatch(clearSalesTaxUpdateStateError());
      dispatch(clearSalesTaxDeleteStateError())
    }

  function getFilteredList(): SalesTaxConfiguration[] {
    const list = [...SalesTaxList];
    let sortedList: SalesTaxConfiguration[] | undefined;
    let resultList: SalesTaxConfiguration[] | undefined;

    if (sortedField != null) {
      sortedList = [...list].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] != undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }


    return (sortedList ?? resultList ?? list).filter(doc => {
      const str = search.trim().toLowerCase();
      const paySalesTaxName = doc.sales_tax_name ? doc.sales_tax_name.trim().toLowerCase().includes(str) : false;
      const state = doc.state ? doc.state.trim().toLowerCase().includes(str) : false;
      const customerTax = doc.customer_tax ? doc.customer_tax.trim().toLowerCase().includes(str) : false;
      const taxType = doc.tax_type_value ? doc.tax_type_value.trim().toLowerCase().includes(str) : false;
      const taxRate = doc.tax_rate ? doc.tax_rate.toString().trim().toLowerCase().includes(str) : false;
      const taxLimit = doc.tax_limit ? doc.tax_limit.toString().trim().toLowerCase().includes(str) : false;
      const taxLimitCap = doc.tax_limit_cap ? doc.tax_limit_cap.toString().trim().toLowerCase().includes(str) : false;

      return paySalesTaxName || customerTax || taxType || state || taxRate || taxLimit || taxLimitCap;
    });
  }

  function getSalesTaxTableList() {
    if (SalesTaxListState.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (SalesTaxListState.error != null && SalesTaxList.length === 0) {
      return (
        <TableErrorHandler
          error={SalesTaxListState.error}
          onRefresh={getSalesTaxTaxConfigDetails}
        />
      );
    }
    if (SalesTaxList.length === 0) {
      return (
        <TableEmpty
          title={t("no_sales_tax_name_matches_found")}
          onClick={() => getSalesTaxTaxConfigDetails()}
        />
      );
    }
    if (getFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_sales_tax_name_found")} />;
    }


    return (
      <SortableTable
        headerList={tableHeader}
        sortedField={sortedField}
        onSortChange={handleSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={sortDirection}
      >
        {getFilteredList().map((doc, index) => {
          return (
            <tr key={doc.id}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                <span>
                  {doc.sales_tax_name ?? ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(3) }}>
                <span>{doc.state ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(1) }}>
                <span>{doc.customer_tax ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(2) }}>
                <span>{doc.tax_type_value ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(4) }}>
                <span>{formatPercentage(doc.tax_rate)}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                <span>{doc.tax_limit ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(6) }}>
                <span>{doc.tax_limit_cap
                  ? currencyConversion(doc.tax_limit_cap)
                  : "$0.00"}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(7) }}>
                <span>
                  <button
                    className="icon-btn tdp-edit-icon"
                    onClick={() => handleEdit(doc)}
                  >
                    <EditIcon
                      width={"100%"}
                      height={"100%"}
                      style={{ color: "#fff" }}
                    />
                  </button>
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(8) }}>
                <span>
                  <button
                    className="icon-btn tdp-edit-icon"
                    onClick={() => handleDeleteSalesTax(doc.id, doc.sales_tax_name)}
                  >
                    <DeleteIcon
                      width={"100%"}
                      height={"100%"}
                      style={{ color: "#fff" }}
                    />
                  </button>
                </span>
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 1;
    if (value === 1) return 1;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 0.3;
    if (value === 8) return 0.3;
    if (value === 9) return 1;
    if (value === 10) return 1;
    return 1;
  }

  return (
    <div className="ac-prtc-ud-container">
      <div className="ac-prtc-ud-table-header">
        <div className="total-count">
          <span>
            {`${t("total_count")}: `}
            <span className="total-count-number">{getFilteredList().length}</span>
          </span>
        </div>
        <div className="ac-prtc-ud-actions">
          <div className="ac-prtc-ud-table-search-bar">
            <SearchBar
              value={search}
              onChange={setSearch}
              onSearch={() => { }}
            />
          </div>
          <div className="ac-prtc-ud-add-user-btn">
            <CustomButton
              leftIcon={getPlusBtnIcon()}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("add_sales_tax")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setAddSalesTax(true)}
            />
          </div>
        </div>
      </div>
      <div className="ac-prtc-ud-table">{getSalesTaxTableList()}</div>
      {addSalesTax && (
        <Portal>
          <SalesTaxPopUp
            visible={addSalesTax}
            onClose={handleOnClose}
            onDismiss={handleOnDissmiss}
            onSuccessClose={handleSalesTaxSuccess}
            onClearError={handleClearError}
            error={isError()}
            loading={isLoading()}
            salesTaxListState={SalesTaxListState}
          />
        </Portal>
      )}
      {editSalesTax && (
        <Portal>
          <SalesTaxPopUp
            visible={editSalesTax.visible}
            onClose={handleOnClose}
            onDismiss={handleOnDissmiss}
            salesTaxState={editSalesTax?.data}
            onSuccessClose={handleSalesTaxSuccess}
            onClearError={handleClearError}
            error={isError()}
            loading={isLoading()}
            salesTaxListState={SalesTaxListState}
          />
        </Portal>
      )}
       {deleteSalesTax?.visible && (
        <DeleteConfirmationDialog
          message={t("delete_sales_tax_message", {
            salesTax: deleteSalesTax?.salesTaxName,
          })}
          onCancel={() => setDelteSalesTax(null)}
          onConfirm={() => handleOnDelete(deleteSalesTax?.salesTaxId!)}
          deleting={SalesTaxListState?.delete?.loading === LoadingType.pending}
          deleteType={t("salesTax")}
          state={SalesTaxListState?.delete}
          onClearError={handleClearError}
        />
      )}
    </div>
  );
}

export default UniversalDirectoryPage
