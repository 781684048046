import { combineReducers } from "@reduxjs/toolkit";
import { salesCallListSlice } from "./sales_call_list/sales_call_list_reducer";
import { myCallDeckListSlice } from "./my_call_deck_reducer"
import { salesCallDetailSlice } from "./sales_call_details/sales_call_details_reducer";

export const myCallDeckReducers = combineReducers({
    "salesCallList": salesCallListSlice.reducer,
    "salesCallDetails": salesCallDetailSlice.reducer,

    "myCallDeckList": myCallDeckListSlice.reducer,
});