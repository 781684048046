import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./arrival_check_calls_report_thunk";
import { IReportCenterService } from "../../../../interfaces/report_center_service";

export interface ArrivalCheckCallsReportListState extends EntityState<IReportCenterService> {
    error?: AppError | null,
    loading: LoadingType,  
}

const arrivalCheckCallsReportListAdapter: EntityAdapter<IReportCenterService> = createEntityAdapter<IReportCenterService>({
    selectId: (doc) => doc.id,
})

const initialState: ArrivalCheckCallsReportListState = arrivalCheckCallsReportListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const arrivalCheckCallsReportListSlice = createSlice({
    name: 'arrivalCheckCallsReportList',
    initialState: initialState,
    reducers: {
        clearArrivalCheckCallsReportListError(state) {
            return { ...state, error: null };
        },
        clearArrivalCheckCallsReportListState(state) {
            return arrivalCheckCallsReportListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getArrivalCheckCallsReportList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getArrivalCheckCallsReportList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getArrivalCheckCallsReportList.fulfilled, (state, action) => arrivalCheckCallsReportListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearArrivalCheckCallsReportListError, clearArrivalCheckCallsReportListState } = arrivalCheckCallsReportListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = arrivalCheckCallsReportListAdapter.getSelectors();
export const selectState = (state: ArrivalCheckCallsReportListState) => state;


