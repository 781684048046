import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../apis";
import { AppError, WorkCompCode, } from "../../../interfaces";
import { catchRequestError } from "../../../utils";


export const getWorkCompCodesThunk = createAsyncThunk<
    Array<WorkCompCode>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/work-comp-codes/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getWorkCompCodes();
            if(typeof res.data === 'string')  return [];
            return res.data as  Array<WorkCompCode>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);