import { combineReducers } from "@reduxjs/toolkit";
import { activeDirectoryListSlice } from "./active_directory_list/active_directory_list_reducer";
import { archivedDirectoryListSlice } from "./archived_directory_list/archived_directory_list_reducer";

export const readyForApprovalReducers = combineReducers({
    [activeDirectoryListSlice.name]: activeDirectoryListSlice.reducer,
    [archivedDirectoryListSlice.name]: archivedDirectoryListSlice.reducer,
});

// export * from './active_directory_list/active_directory_list_reducer';
export * from './active_directory_list/active_directory_list_selector';
export * from './active_directory_list/active_directory_list_thunk';

// export * from './archived_directory_list/archived_directory_list_reducer';
export * from './archived_directory_list/archived_directory_list_selector';
export * from './archived_directory_list/archived_directory_list_thunk';