import { combineReducers } from "@reduxjs/toolkit";
import { epPayrollDetailsSlice } from "./payroll_details_reducer";
import { vacationHistSlice } from "./vacation_history/vacation_history_reducer";
import { paycheckRevSlice } from "./check_rev_history/check_rev_history_reducer";

export const employeePortalPDReducers = combineReducers({
    [epPayrollDetailsSlice.name]: epPayrollDetailsSlice.reducer,
    [vacationHistSlice.name]: vacationHistSlice.reducer,
    [paycheckRevSlice.name] : paycheckRevSlice.reducer,

});

export * from './payroll_details_selector';
export * from './payroll_details_thunk';

export * from "./vacation_history/vacation_history_selector";
export * from "./vacation_history/vacation_history_thunk";

export * from "./check_rev_history/check_rev_history_selector";
export * from "./check_rev_history/check_rev_history_thunk";




