import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./cash_receipts_thunk";
import { IRCCashReceipts } from "../../../../interfaces/report_center_finance_accounting";

export interface CashReceiptsListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCCashReceipts | null
}

const initialState: CashReceiptsListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}

export const cashReceiptsListSlice = createSlice({
    name: 'cashReceiptsList',
    initialState: initialState,
    reducers: {
        clearCashReceiptsListError(state) {
            return { ...state, error: null };
        },
        clearCashReceiptsState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCashReceiptsList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getCashReceiptsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCashReceiptsList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    },
});

export const { clearCashReceiptsListError, clearCashReceiptsState } = cashReceiptsListSlice.actions;
export const selectState = (state: CashReceiptsListState) => state;



