import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import {
    selectState,
    selectJobState,
    selectInitialSingleJobState,
    selectJobSkillsState,
    selectJobCredentialsState,
    selectJobCertificationsState,
    selectJobEducationState,
    selectJobEquipmentState,
} from "./job_details_reducer";

const select = (state: IRootState) => state.jobs.details.dashboard.jobDetails;
export const selectJobDetailsState = createSelector(select, selectState);

export const selectJobDetailsByIdState = (id: string | undefined | null) => createSelector(
    selectJobDetailsState,
    (state) => {
        if (id && state[id]) return selectJobState(state, id);
        return selectInitialSingleJobState();
    }
);
export const selectJobSkillsStateById = (id: string | undefined | null) => createSelector(
    selectJobDetailsByIdState(id),
    selectJobSkillsState,
);
export const selectJobCredentialsStateById = (id: string | undefined | null) => createSelector(
    selectJobDetailsByIdState(id),
    selectJobCredentialsState,
);
export const selectJobCertificationsStateById = (id: string | undefined | null) => createSelector(
    selectJobDetailsByIdState(id),
    selectJobCertificationsState,
);
export const selectJobEducationStateById = (id: string | undefined | null) => createSelector(
    selectJobDetailsByIdState(id),
    selectJobEducationState,
);
export const selectJobEquipmentStateById = (id: string | undefined | null) => createSelector(
    selectJobDetailsByIdState(id),
    selectJobEquipmentState,
);
// export const selectJobDetailsDepartmentState = createSelector(select, selectDepartmentState);
