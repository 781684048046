import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, ICompanyInvoices } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { CompaniesAPI } from "../../../../apis";


export const getCompanyInvoicesThunk = createAsyncThunk<
    ICompanyInvoices[],
    { company_id?: string, department_id?: string },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@companies/details/back_office_invoice_docs/invoices/get",
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanyInvoices(payload);
            return res.data as ICompanyInvoices[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


export const mailCompanyInvoicesThunk = createAsyncThunk<
    string,
    string[],
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@companies/details/back_office_invoice_docs/invoices/mail_invoices/post",
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.mailInvoices(payload);
            return res.data as string;
        } catch (error) {

            return catchRequestError(error, thunkAPI);
        }
    });


