import { createAsyncThunk } from "@reduxjs/toolkit";
import { SalesMarketingAPI } from "../../../../apis/sales_marketing_api";
import { AppError, ICreateResponse, ServiceCallInfo } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { getServiceCallList } from "../service_call_list/service_call_list_thunk";

/// Update user
export const updateServiceCall = createAsyncThunk<
ICreateResponse,
    ServiceCallInfo,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@salesmarketing/serviceCallActivity/manageServiceCall/update',
    async (payload, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.updateServiceCalls(payload);
            thunkAPI.dispatch(getServiceCallList(false));
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

