import { CustomButton } from '../../../../utils';
import './board_header.scss';

interface BoardHeaderProps {
    title: string,
    titleIcon: React.ReactNode,
    badgeName: string,
    onBadgeClick: () => void,
    badgeColor: string,
    backgroundColor: string,
    borderColor: string,
}

const BoardHeader: React.FunctionComponent<BoardHeaderProps> = (props) => {

    return (
        <div className="board-header"
            style={{
                backgroundColor: props.backgroundColor,
                borderBottom: `1px solid ${props.borderColor}`,
            }}
        >
            <div className="header-title">
                <div className="board-icon">
                    {props.titleIcon}
                </div>
                <div className="borad-title">
                    <span style={{ color: props.badgeColor }}>{props.title}</span>
                </div>
            </div>
            <div className="header-badge">
                <CustomButton
                    loading={false}
                    textStyle={{ textTransform: 'capitalize', fontSize: '0.625vw' }}
                    name={props.badgeName}
                    enable={true}
                    backgroundColor={props.badgeColor}
                    onClick={props.onBadgeClick}
                    customStyle={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}
                />
            </div>
        </div>
    )
}

export default BoardHeader;