import { createAsyncThunk } from "@reduxjs/toolkit";
import { IApolloCompanyDetails } from "../../../interfaces/apollo_search_import";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { ApolloSearcImportAPI } from "../../../apis";

export const getApolloCompanyById = createAsyncThunk<
    IApolloCompanyDetails,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@apolloai/companies/company_details/post',
    async (domain, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.appolloCompanyDetails(domain);
            if (typeof res.data === 'string') return [];
            return res.data as IApolloCompanyDetails;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);