export enum CONTACT_METHODS {
    email = "EMAIL",
    linkedIn = "LINKEDIN",
    phone = "PHONE",
    text = "TEXT",
    zipRecruiter = "ZIPRECRUITER",
    indeed = "INDEED",
    facebook = "FACEBOOK",
    inperson ="IN_PERSON",
    zoom = "ZOOM",
    teams = "TEAMS",
    googleMeet = "GOOGLE_MEET",
}