import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import { THEME } from "../../../../enums";
import { OnBoardingIcon } from "../../../../icons";
import {
  IOnboardingDocsReviewStatus,
  IProfileDropdown,
  ITalent,
} from "../../../../interfaces";
import { CustomButton } from "../../../../utils";
import "./direct_deposit.scss";
import OnboardingReviewForm, {
  IReviewForm,
} from "../../forms/onboarding_review_form/onboarding_review_form";
import { getDateString } from "../../../../variables";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
interface Props {
  visible: boolean;
  type: string;
  title: string;
  talentId: string;
  state: IOnboardingDocsReviewStatus | null;
  successTitle: string;
  onClose: () => void;
  onDissmiss?: () => void;
  onSuccessClose: (type: string, value?: any) => void;
  talentData: ITalent | null;
  today: number;
  currentLoginUserName: string;
  recruitersList: IProfileDropdown[];
}

const DirectDepositPopup: React.FunctionComponent<Props> = (props) => {
  const {
    visible,
    state,
    title,
    onClose,
    onDissmiss,
    talentData,
    onSuccessClose,
    type,
    today,
    currentLoginUserName,
    recruitersList,
  } = props;
  const { t } = useTranslation();

  const actionRef = useRef<ActionDialogHolderType>(null);
  const reviewedByData = recruitersList.filter(
    (recruiter) =>
      state && recruiter.id === state?.direct_deposit_document?.reviewed_by
  );
  const reviewedBy = reviewedByData
    .map((recruiter) => `${recruiter.first_name} ${recruiter.last_name}`)
    .join(", ");
  const [formState, setFormState] = useState<IReviewForm>({
    name:
      state && talentData
        ? talentData?.first_name + " " + talentData?.last_name
        : "",
    recruiter_completed_review:
      state && state?.direct_deposit_document?.recruiter_completed_review
        ? "Reviewed"
        : "Not Reviewed",
    reviewed_on_timestamp:
      state && state?.direct_deposit_document?.reviewed_on_timestamp !== null
        ? getDateString(
            state?.direct_deposit_document?.reviewed_on_timestamp,
            "mm/dd/yyyy"
          )
        : getDateString(today, "mm/dd/yyyy"),
    reviewed_by:
      state && state?.direct_deposit_document?.reviewed_by !== null
        ? reviewedBy ?? ""
        : currentLoginUserName,
  });
  function handleClose() {
    closePopup(onClose);
  }
  function getFlexNumber(value: number) {
    if (value === 0) return 2;
    if (value === 1) return 2;
    if (value === 2) return 2;
    if (value === 3) return 2;
    if (value === 4) return 3;
    if (value === 5) return 2;
    return 1;
  }
  const getAmountType = (type) => {
    if (type === "FIXED$AMOUNT") {
      return "Fixed";
    } else if (type === "PERCENTAGE") {
      return "Percentage";
    } else if (type === "REMAININGAMOUNT") {
      return "Remaining";
    } else {
      return type;
    }
  };
  const getAmountPrefix = (type, val) => {
    if (type === "FIXED$AMOUNT") {
      return "$ " + val.toFixed(2);
    } else if (type === "PERCENTAGE") {
      return val.toFixed(2) + " %";
    } else {
      return val;
    }
  };
  const tableHeader = [
    { title: "financial_institution", code: "name_of_financial_institution" },
    { title: "type_of_account", code: "type_of_account" },
    { title: "bank_routing_number", code: "bank_routing_number" },
    { title: "bank_account_number", code: "bank_account_number" },
    {
      title: "amount_of_paycheck_to_deposit",
      code: "amount_of_paycheck_to_deposit_to_this_account",
    },
    { title: "amount_type", code: "amount_type" },
    { title: "actions", code: "actions" },
  ];
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");

  function handleSortFieldChange(value: string) {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={"70%"}
      onClose={onClose}
      onDissmiss={onDissmiss}
    >
      <DialogWrapper onClose={handleClose}>
        <div className="drd-container">
          <div className="drd-header">
            <FormDialogTilteHeader
              title={title}
              subTitle={t("please_scroll_down_and_fill_out_the_form_below")}
              subTitleStyle={{ fontWeight: "normal" }}
              titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <div className="drd-content">
            <div>
              <OnboardingReviewForm
                handleFieldChange={() => {}}
                formState={formState}
              />
            </div>
            <div>
              <div className="talent-direct-deposit-sortable-table ">
                <SortableTable
                  headerList={tableHeader}
                  sortedField={sortedField}
                  onSortChange={handleSortFieldChange}
                  flexNumber={getFlexNumber}
                  isAsc={sortDirection}
                >
                  {state?.direct_deposit_document?.bank_accounts?.map(
                    (doc, index) => {
                      return (
                        <tr key={"branch_list" + index}>
                          <TableData
                            customStyle={{
                              flex: getFlexNumber(0),
                              wordWrap: "break-word",
                            }}
                          >
                            <span>{doc.bank_name}</span>
                          </TableData>
                          <TableData
                            customStyle={{
                              flex: getFlexNumber(1),
                              wordWrap: "break-word",
                            }}
                          >
                            <span>{doc.account_type}</span>
                          </TableData>
                          <TableData
                            customStyle={{
                              flex: getFlexNumber(2),
                              wordWrap: "break-word",
                            }}
                          >
                            <span>{doc.routing_number}</span>
                          </TableData>
                          <TableData
                            customStyle={{
                              flex: getFlexNumber(3),
                              wordWrap: "break-word",
                            }}
                          >
                            <span>{doc.account_number}</span>
                          </TableData>
                          <TableData
                            customStyle={{
                              flex: getFlexNumber(4),
                              wordWrap: "break-word",
                            }}
                          >
                            <span>
                              {getAmountPrefix(
                                doc.amount_type,
                                doc.amount_per_check
                              )}
                            </span>
                          </TableData>
                          <TableData
                            customStyle={{
                              flex: getFlexNumber(5),
                              wordWrap: "break-word",
                            }}
                          >
                            <span>{getAmountType(doc.amount_type)}</span>
                          </TableData>
                          <TableData
                            customStyle={{
                              flex: getFlexNumber(6),
                              wordWrap: "break-word",
                            }}
                          >
                            <ToggleSwitch
                              label={""}
                              labelPosition={"top"}
                              checked={doc.is_active ? true : false}
                              onChange={() => {}}
                              
                            />
                          </TableData>
                        </tr>
                      );
                    }
                  )}
                </SortableTable>
              </div>
            </div>
          </div>
          <div className="drd-actions">
            {/* <div className="btn-cancel">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("cancel")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => closePopup(onClose)}
              />
            </div> */}
            <div className="btn-new">
              <CustomButton
                // loading={loading}
                textStyle={{
                  textTransform: "capitalize",
                }}
                name={t("ok")}
                enable={
                  state &&
                  (state?.direct_deposit_document?.bank_accounts.length !== 0 &&
                    !state?.direct_deposit_document
                      ?.recruiter_completed_review)
                    ? true
                    : false
                }
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => onSuccessClose(type)}
              />
            </div>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }
};

export default DirectDepositPopup;
