import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, PayrollBatch } from "../../../../interfaces";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { catchRequestError } from "../../../../utils";


export const getPayrollThunk = createAsyncThunk<
    PayrollBatch[],
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_batch/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getPayrollBatch(payload);
            return res.data as PayrollBatch[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


