import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectCreateState, selectState, selectUpdate, selectFilesState, selectFilesTypeState, selectInitialFilesTypeState } from "./manage_assignment_reducer";

const select = (state: IRootState) => state.assignments.universalDirectory.manageAssignment;
export const selectManageAssignmentState = createSelector(select, selectState);
export const selectManageAssignmentCreateState = createSelector(selectManageAssignmentState, selectCreateState);
export const selectManageAssignmentUpdateState = createSelector(selectManageAssignmentState, selectUpdate);
export const selectManageAssignmentFilesState = createSelector(selectManageAssignmentState, selectFilesState);

export const selectManageAssignmentFilesStateByType = (type: string | undefined | null) => createSelector(
    selectManageAssignmentState,
    (state) => {
        if (type && state.files[type]) return selectFilesTypeState(state, type);
        return selectInitialFilesTypeState();
    }
);
