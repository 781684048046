import { useTranslation } from "react-i18next";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { IRCPaycheckSummary, IRCPaycheckTalents } from "../../../../interfaces/report_center";
// import './paycheck_talent_table.scss';
import { getDateString } from "../../../../variables";
import { currencyConversion, formatNumber } from "../../../../utils";
import { useState } from "react";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";

interface Props {
    error?: AppError | null;
    loading: LoadingType;
    summaryList: IRCPaycheckSummary;
}
const transactionheaderList = [
    { title: "transaction_type", code: "transaction_type" },
    { title: "rate", code: "pay_rate" },
    { title: "hours_units", code: "pay_units" },
    { title: "total_pay", code: "item_pay" },
];
const taxheaderList = [
    { title: "tax_deductions", code: "deduction" },
    { title: "taxable_gross", code: "taxable_gross" },
    { title: "tax_amount", code: "amount" },
];
const odHeaderList = [
    { title: "other_deductions", code: "deduction" },
    { title: "amount_withheld", code: "amount" },
];
const PaycheckSummaryTable: React.FunctionComponent<Props> = (props) => {
    const { error, loading, summaryList } = props;
    const { t } = useTranslation();
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    const getTransactionList = (list: any) => {
        let resultList = list;
        if (list && sortedField != null) {
            resultList = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return resultList;
    };
    const getTaxList = (list: any) => {
        let resultList = list;
        if (list && sortedField != null) {
            resultList = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return resultList;
    };
    const getOtherDedList = (list: any) => {
        let resultList = list;
        if (list && sortedField != null) {
            resultList = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return resultList;
    };
    const getFlexNumber = (value: number) => {
        if (value === 0) return 1.5;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 2;
        if (value === 20) return 1;
        return 1;
    }
    return (
        <div className="pr-tlnt-container">
            <div className="pr-tlnt-section">
                <span className="title-header">{t('paycheck_register_summary')}</span>
                <div className="pr-tlnt-content">
                    <div className="transaction-div col-div">
                        <span className="title-span">{t('transaction')}</span>
                        <div className="col-div-table">
                            <div className="table-container">
                                <SortableTable
                                    headerList={transactionheaderList}
                                    sortedField={sortedField}
                                    onSortChange={handleSortFieldChange}
                                    flexNumber={getFlexNumber}
                                    isAsc={sortDirection}
                                >
                                    <div className="table-content">
                                        {getTransactionList(summaryList.all_transactions.transactions) && getTransactionList(summaryList.all_transactions.transactions)?.length! > 0 ? (
                                            <>
                                                {getTransactionList(summaryList.all_transactions.transactions)?.map((transaction, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                                <span>{transaction.transaction_type}</span>
                                                            </TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                                                <span>{transaction.pay_rate ? currencyConversion(transaction.pay_rate, 2) : "$0.00"}</span>
                                                            </TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                                <span>
                                                                    {formatNumber(transaction.pay_units,2)}</span>
                                                            </TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                                <span>{transaction.item_pay ? currencyConversion(transaction.item_pay, 2) : "$0.00"}
                                                                </span>
                                                            </TableData>
                                                        </tr>
                                                    )
                                                })}

                                            </>
                                        )
                                            :
                                            <>
                                                <tr className="na-row">
                                                    <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                                        <div className="na_div">
                                                            {t("no_transactions_available")}
                                                        </div>
                                                    </TableData>
                                                </tr>
                                            </>
                                        }


                                    </div>

                                </SortableTable>
                            </div>
                            <div className="total-row">
                                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                    <span>Total</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                    <span></span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                    <span>
                                        {summaryList.all_transactions.total_pay_units ?
                                            (formatNumber(summaryList.all_transactions.total_pay_units,2)) :
                                            "0"
                                        }</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                    <span>{summaryList.all_transactions.total_pay ?
                                        currencyConversion(summaryList.all_transactions.total_pay)
                                        : "$0.00"}
                                    </span>
                                </TableData>
                            </div>
                        </div>
                    </div>
                    <div className="tax-div col-div">
                        <span className="title-span">{t('tax')}</span>
                        <div className="col-div-table">
                            <div className="table-container">
                                <SortableTable
                                    headerList={taxheaderList}
                                    sortedField={sortedField}
                                    onSortChange={handleSortFieldChange}
                                    flexNumber={getFlexNumber}
                                    isAsc={sortDirection}
                                >
                                    <div className="table-content">
                                        {getTaxList(summaryList.all_tax_deductions.tax_deductions) && getTaxList(summaryList.all_tax_deductions.tax_deductions)?.length! > 0 ? (
                                            <>
                                                {getTaxList(summaryList.all_tax_deductions.tax_deductions)?.map((tax, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                                <span>{tax.deduction}</span>
                                                            </TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                                                <span>{tax.taxable_gross ? currencyConversion(tax.taxable_gross, 2) : "$0.00"}</span>
                                                            </TableData>

                                                            <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                                <span>{tax.amount ? currencyConversion(tax.amount, 2) : "$0.00"}
                                                                </span>
                                                            </TableData>
                                                        </tr>
                                                    )
                                                })}

                                            </>
                                        )
                                            :
                                            <>
                                                <tr className="na-row">
                                                    <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                                        <div className="na_div">
                                                            {t("no_tax_available")}
                                                        </div>
                                                    </TableData>
                                                </tr>
                                            </>
                                        }

                                    </div>

                                </SortableTable>
                            </div>
                            <tr className="total-row">
                                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                    <span>Total</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                    <span></span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                    <span>
                                        {summaryList.all_tax_deductions.total_tax_deductions_amount ?
                                            currencyConversion(summaryList.all_tax_deductions.total_tax_deductions_amount)
                                            :
                                            "$0.00"}</span>
                                </TableData>
                            </tr>
                        </div>
                    </div>
                    <div className="od-div col-div">
                        <span className="title-span">{t('other_deductions')}</span>
                        <div className="col-div-table">
                            <div className="table-container">
                                <SortableTable
                                    headerList={odHeaderList}
                                    sortedField={sortedField}
                                    onSortChange={handleSortFieldChange}
                                    flexNumber={getFlexNumber}
                                    isAsc={sortDirection}
                                >
                                    <>
                                        {getOtherDedList(summaryList.all_other_deductions.other_deductions) && getOtherDedList(summaryList.all_other_deductions.other_deductions)?.length! > 0 ? (
                                            <>
                                                {getOtherDedList(summaryList.all_other_deductions.other_deductions)?.map((tax, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                                <span>{tax.deduction}</span>
                                                            </TableData>
                                                            <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                                                <span>{tax.amount ? currencyConversion(tax.amount, 2) : "$0.00"}</span>
                                                            </TableData>

                                                        </tr>
                                                    )
                                                })}

                                            </>
                                        )
                                            :
                                            <>
                                                <tr className="na-row">
                                                    <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                                        <div className="na_div">
                                                            {t("no_other_deductions_available")}
                                                        </div>
                                                    </TableData>
                                                </tr>
                                            </>
                                        }


                                    </>

                                </SortableTable>
                            </div>
                            <div className="total-row">
                                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                    <span>Total</span>
                                </TableData>

                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                    <span>
                                        {summaryList.all_other_deductions.total_other_deductions_amount ?
                                            currencyConversion(summaryList.all_other_deductions.total_other_deductions_amount) :
                                            "$0.00"}</span>
                                </TableData>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default PaycheckSummaryTable;