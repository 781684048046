import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, JobOrderSource } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getJobOrderSources = createAsyncThunk<
    Array<JobOrderSource>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/job_order_source/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getJobOrderSources();
            return res.data as JobOrderSource[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createJobOrderSource = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_order_source/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createJobOrderSource(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteJobOrderSource = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_order_source/delete',
    async (status_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteJobOrderSource(status_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateJobOrderSource = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_order_source/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateJobOrderSource(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);