import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, ICompanyEdocs } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { CompaniesAPI } from "../../../../apis";
import { S3API } from "../../../../apis/s3_api";


export const getCompanyEdDocsThunk = createAsyncThunk<
    ICompanyEdocs[],
    { company_id: string, department_id?: string },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@companies/details/back_office_invoice_docs/edocs/get",
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanyEdocs(payload);
            return res.data as ICompanyEdocs[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const addNewCompanyEdDocsThunk = createAsyncThunk<
    string,
    { data: ICompanyEdocs, file: File | null },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@companies/details/back_office_invoice_docs/edocs/post",
    async (doc, thunkAPI) => {
        try {
            const res = await CompaniesAPI.postCompanyEdocs(doc.data);
            if (res.data && doc.file) {
                const url = res.data.url;
                S3API.uploadWithPreSignedURL(url, doc.file);
            }
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const mailCompanyEdocsThunk = createAsyncThunk<
    string,
    { email_id: string, edoc_ids: string[] },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@companies/details/back_office_invoice_docs/edocs/mail_edocs/post",
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.mailEDocs(payload.email_id, payload.edoc_ids);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


