import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll } from "./billing_invoices_list_reducer";

const select = (state: IRootState) => state.backOffice.billingInvoice.billingBatchListSlice;
export const selecteBIListState = createSelector(
    select,
    selectState
);
export const selectBillingInvoiceList = createSelector(select, selectAll)
