import { createSlice } from "@reduxjs/toolkit";
import * as thunk from './send_by_email_thunk';
import { AppError } from "../../../../../interfaces";
import { LoadingType } from "../../../../../enums";


export interface JobDocumentSendByEmailState {
    sendDocVidLinkToEmail: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}


const initialState: JobDocumentSendByEmailState = {
    sendDocVidLinkToEmail: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
}


export const jobDocumentSendByEmailSlice = createSlice({
    name: 'jobDocumentSendByEmail',
    initialState: initialState,
    reducers: {
        clearSendDocVidLinkToEmailError(state) {
            state.sendDocVidLinkToEmail= { ...initialState.sendDocVidLinkToEmail}
        },
        clearSendDocVidLinkToEmailState(state) {
            state.sendDocVidLinkToEmail= { ...initialState.sendDocVidLinkToEmail}
        },
    },
    extraReducers: (builder) => {
        builder
            
            .addCase(thunk.sendDocVidByEmail.pending, (state, action) => ({ ...state, sendDocVidLinkToEmail: { ...state.sendDocVidLinkToEmail, loading: LoadingType.pending, response: '', error: null } }))
            .addCase(thunk.sendDocVidByEmail.rejected, (state, action) => ({ ...state, sendDocVidLinkToEmail: { ...state.sendDocVidLinkToEmail, loading: LoadingType.failed, response: '', error: action.payload } }))
            .addCase(thunk.sendDocVidByEmail.fulfilled, (state, action) => ({ ...state, sendDocVidLinkToEmail: { ...state.sendDocVidLinkToEmail, loading: LoadingType.succeeded, response: action.payload, error: null } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const jobDocumentSendByEmailActions = jobDocumentSendByEmailSlice.actions;
export const selectJobDocVidSendByEmailState = (state: JobDocumentSendByEmailState) => state;
