import { combineReducers } from "@reduxjs/toolkit";
import { companiesBilledListSlice } from "./companies_billed/companies_billed_reducer";

export const reportCenterCompaniesReducers = combineReducers({
    [companiesBilledListSlice.name]: companiesBilledListSlice.reducer,
});

export * from "./companies_billed/companies_billed_thunk";
export * from "./companies_billed/companies_billed_selector";


