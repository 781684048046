import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState,
} from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { OnlineApplicationSent } from "../../../../interfaces/online_application_sent";
import * as thunk from "./online_applications_sent_list_thunk";

export interface OnlineApplicationSentListState
  extends EntityState<OnlineApplicationSent> {
  error?: AppError | null;
  loading: LoadingType;
  countState: {
    error?: AppError | null;
    loading: LoadingType;
    response?: number,
  }
}

const onlineApplicationSentListAdapter: EntityAdapter<OnlineApplicationSent> =
  createEntityAdapter<OnlineApplicationSent>({
    selectId: (doc) => doc.id,
  });

const initialState: OnlineApplicationSentListState =
  onlineApplicationSentListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    countState: {
      error: null,
      loading: LoadingType.idle,
    }
  });

export const onlineApplicationSentListSlice = createSlice({
  name: "onlineApplicationSentList",
  initialState: initialState,
  reducers: {
    clearOnlineApplicationSentListError(state) {
      return { ...state, error: null };
    },
    clearOnlineApplicationSentListState(state) {
      return onlineApplicationSentListAdapter.removeAll({
        ...state,
        loading: LoadingType.idle,
        error: null,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        thunk.getOnlineApplicationsSentList.pending,
        (state, action) => ({
          ...state,
          loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
          error: null,
        })
      )
      .addCase(
        thunk.getOnlineApplicationsSentList.rejected,
        (state, action) => ({
          ...state,
          loading: LoadingType.failed,
          error: action.payload,
        })
      )
      .addCase(
        thunk.getOnlineApplicationsSentList.fulfilled,
        (state, action) =>
          onlineApplicationSentListAdapter.setAll(
            { ...state, loading: LoadingType.succeeded, error: null },
            action.payload
          )
      )

      .addCase(
        thunk.getOnlineApplicationsSentCount.pending,
        (state, action) => ({
          ...state,
          countState: {
            ...state.countState,
            loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
            error: null,
          }
        })
      )
      .addCase(
        thunk.getOnlineApplicationsSentCount.rejected,
        (state, action) => ({
          ...state,
          countState: {
            ...state.countState,
            loading: LoadingType.failed,
            error: action.payload,
          }

        })
      )
      .addCase(
        thunk.getOnlineApplicationsSentCount.fulfilled,
        (state, action) => ({
          ...state,
          countState: {
            ...state.countState,
            loading: LoadingType.succeeded,
            response: action.payload,
          }

        })
      )

      .addDefaultCase((state) => ({ ...state }));
  },
});

export const {
  clearOnlineApplicationSentListError,
  clearOnlineApplicationSentListState,
} = onlineApplicationSentListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } =
  onlineApplicationSentListAdapter.getSelectors();
export const selectState = (state: OnlineApplicationSentListState) => state;
export const selectLoading = (state: OnlineApplicationSentListState) =>
  state.loading;
export const selectError = (state: OnlineApplicationSentListState) =>
  state.error;

  export const selectCountState = (state: OnlineApplicationSentListState) => state.countState;
