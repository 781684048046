import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./talent_list_reducer";

const select = (state: IRootState) => state.talent.universalDirectory.talentList;
export const selectTalentListState = createSelector(select, selectState);
export const selectTalentList = createSelector(
    select,
    selectAll
);
export const selectTalentListEntities = createSelector(
    select,
    selectEntities
);