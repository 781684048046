import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, ICreateUser, IUpdateUser, Profile, ToggleRoleData } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { refreshUserDetails } from "../user_details/user_details_thunk";

/// Create user
export const createUser = createAsyncThunk<
    string,
    ICreateUser,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/user-management/manage_user/create',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createUser(payload)
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Update user
export const updateUser = createAsyncThunk<
    string,
    IUpdateUser,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/user-management/manage_user/update',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateProfile(payload);
            thunkAPI.dispatch(refreshUserDetails());
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


/// Add or remove user role
export const toggleUserRole = createAsyncThunk<
    string,
    ToggleRoleData,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/user-management/manage_user/toggle_role',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.toggleUserRole(data);
            // thunkAPI.dispatch(refreshUserDetails());
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);