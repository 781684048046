import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../../../../components/search_bar";
import { LoadingType, THEME } from "../../../../../../enums";
import { CustomButton, currencyConversion, formatPercentage, getPlusBtnIcon } from "../../../../../../utils";
import { getCompanyBackOfficeInvoiceDocsThunk, useAppDispatch } from "../../../../../../redux/store";
import { AppError, ICompBOInvoiceDocsMarkUp, ICompanyBackOfficeInvoiceDocs } from "../../../../../../interfaces";
import SortableTable, { TableData } from "../../../../../../components/sortable_table/sortable_table";
import { DeleteIcon, EditIcon, TickGreenIcon, TickGreyIcon } from "../../../../../../icons";
import './markup_shift.scss';
import { Portal } from "react-portal";
import AddNewMarkUpShiftPopUp from "../../../../popup/add_new_mark_up/add_new_mark_up";
import { companyBOInvoiceDocsSliceActions } from "../../../../../../redux/companies/details/back_office_invoices_and_docs/back_office_invoices_and_docs_reducer";
import { DeleteConfirmationDialog } from "../../../../../components/delete_confirmation_dialog";
const MarkUpShiftTableHeader = [
    { title: 'markup_name', code: 'mark_up_name' },
    { title: 'regular_markup', code: 'regular_mark_up' },
    { title: 'overtime_markup', code: 'over_time_mark_up' },
    { title: 'doubletime_markup', code: 'double_time_mark_up' },
    { title: 'active', code: 'is_active' },
    { title: '', code: '' },

];
interface Props {
    formState: ICompanyBackOfficeInvoiceDocs;
    currentLoginUserName: string;
    handleAddMarkup: (doc: ICompBOInvoiceDocsMarkUp, index?: number, removeItem?: boolean) => void;
    markupPOUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleClearError: () => void;
    handleUpdateClearState: () => void;
    getInvoiceDocsData: () => void;
    companyId: string;
    companyName: string;
}
const CompanyMarkUpShift: React.FC<Props> = (props) => {
    const { formState, currentLoginUserName, handleAddMarkup, handleClearError, markupPOUpdateState,
        handleUpdateClearState, getInvoiceDocsData, companyId, companyName } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [addNewMarkUpShift, setNewMarkUpShift] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [markUpSorted, setMarkupSorted] = useState<string | null>(null);
    const [editIndex, setEditIndex] = useState<number | undefined>();
    const [delRecord, setDelRecord] = useState<boolean>(false);

    const initialForm = {
        mark_up_name: "",
        regular_mark_up: 0,
        over_time_mark_up: 0,
        double_time_mark_up: 0,
        is_active: false
    }
    const [markUpFormState, setMarkUpFormState] = useState<ICompBOInvoiceDocsMarkUp>(initialForm);

    function handleSortFieldChange(value: string) {
        if (markUpSorted && markUpSorted === value) {
            setMarkupSorted(null);
        } else {
            setMarkupSorted(value);
        }
    }
    function handleFormFieldChange(fieldId: string, value: any, parent: string) {
        if (fieldId === "regular_mark_up" || fieldId === "over_time_mark_up" || fieldId === "double_time_mark_up") {
            if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
                setMarkUpFormState({
                    ...markUpFormState,
                    [fieldId]: value,
                });
            }
        }
        else {
            setMarkUpFormState({
                ...markUpFormState,
                [fieldId]: value,
            });
        }
    };
    function getMarkupFilteredList(): ICompBOInvoiceDocsMarkUp[] {
        let sortedList: ICompBOInvoiceDocsMarkUp[] | undefined;
        if (markUpSorted != null) {
            sortedList = [...(formState.mark_up ?? [])].sort((a, b) => {
                if (a[markUpSorted] < b[markUpSorted]) {
                    return -1;
                }
                if (a[markUpSorted] > b[markUpSorted]) {
                    return 1;
                }
                return 0;
            });
        }
        return (sortedList ?? (formState.mark_up ?? [])).filter(doc => {
            const str = search.trim().toLowerCase();
            const mark_up_name = doc.mark_up_name ? doc.mark_up_name.trim().toLowerCase().includes(str) : false;
            const regular_mark_up = doc.regular_mark_up ? doc.regular_mark_up.toString().trim().toLowerCase().includes(str) : false;
            const over_time_mark_up = doc.over_time_mark_up ? doc.over_time_mark_up.toString().trim().toLowerCase().includes(str) : false;
            const double_time_mark_up = doc.double_time_mark_up ? doc.double_time_mark_up.toString().trim().toLowerCase().includes(str) : false;

            return mark_up_name || regular_mark_up || over_time_mark_up || double_time_mark_up;
        });
    }
    const handleClose = () => {
        setNewMarkUpShift(false);
        setMarkUpFormState(initialForm);
    }

    const handleSave = () => {
        if (JSON.stringify(markUpFormState) === JSON.stringify(initialForm)) {
            handleClose();
        }
        else {
            handleAddMarkup(markUpFormState, editIndex);
        }
    };

    const handleOnSuccessClose = () => {
        setEditIndex(undefined);
        handleClose();
        handleUpdateClearState();
        getInvoiceDocsData();
    }
    const handleEdit = (val: ICompBOInvoiceDocsMarkUp, index: number) => {
        setNewMarkUpShift(true);
        setMarkUpFormState(val);
        setEditIndex(index);
    };
    const handleDelete = (val: ICompBOInvoiceDocsMarkUp, index: number) => {
        setDelRecord(true);
        setMarkUpFormState(val);
        setEditIndex(index);
    };
    const handleOnSuccessRemove = () => {
        handleAddMarkup(markUpFormState, editIndex, true);
        setEditIndex(undefined);
        handleClose();
        handleUpdateClearState();
        setDelRecord(false);
    }
    return (
        <div className="markup-main-container">
            <div className="markup-po-header">
                <div className="section-header">{t("mark_up")}</div>
                <div className="markup-po-header-actions">
                    <div className="">
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                            placeholder={t('Search')}
                        />
                    </div>
                    <CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("add_new_mark_up")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => setNewMarkUpShift(true)}
                    />
                </div>
            </div>
            <div className="markup-po-content">
                <div className="markup-po-content-table">
                    <SortableTable
                        headerList={MarkUpShiftTableHeader}
                        sortedField={markUpSorted}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                    >
                        {getMarkupFilteredList().map((doc, index) => {
                            return (
                                <tr key={index} onClick={() => { }}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}>{doc.mark_up_name}</TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                        {/* <span>{doc.regular_mark_up
                                        ? currencyConversion(doc.regular_mark_up)
                                        : "$0.00"}</span> */}
                                        <span>{formatPercentage(doc.regular_mark_up)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                        {/* <span>{doc.over_time_mark_up
                                        ? currencyConversion(doc.over_time_mark_up)
                                        : "$0.00"}</span> */}
                                        <span>{formatPercentage(doc.over_time_mark_up)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        {/* <span>{doc.double_time_mark_up
                                        ? currencyConversion(doc.double_time_mark_up)
                                        : "$0.00"}</span> */}
                                        <span>{formatPercentage(doc.double_time_mark_up)}</span>
                                    </TableData>

                                    <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc.is_active ?
                                        <div className="tick-icon">
                                            <TickGreenIcon width={"100%"} height={"100%"} />
                                        </div> : <div className="tick-icon">
                                            <TickGreyIcon width={"100%"} height={"100%"} />
                                        </div>}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <button className="edit-icon" >
                                            <EditIcon width={"100%"} height={"100%"} onClick={() => handleEdit(doc, index)} />
                                        </button>
                                        <button className="delete-icon" >
                                            <DeleteIcon width={"100%"} height={"100%"} onClick={() => handleDelete(doc, index)} />
                                        </button>
                                    </TableData>
                                </tr>
                            );
                        })}
                    </SortableTable>
                </div>
            </div>
            {addNewMarkUpShift &&
                <Portal>
                    <AddNewMarkUpShiftPopUp
                        visible={addNewMarkUpShift}
                        title={t("complete_info_to_add_new_markup", { name: currentLoginUserName })}
                        successTitle={t("new_markup")}
                        onClose={handleClose}
                        onSuccessClose={handleOnSuccessClose}
                        handleSave={handleSave}
                        markUpFormState={markUpFormState}
                        currentLoginUserName={currentLoginUserName}
                        markupPOUpdateState={markupPOUpdateState}
                        index={editIndex}
                        handleFormFieldChange={handleFormFieldChange}
                        companyName={companyName}
                    />
                </Portal>
            }
            {delRecord && (
                <DeleteConfirmationDialog
                    message={t("delete_bo_record_message", {
                        name: markUpFormState.mark_up_name,
                    })}
                    onCancel={() => setDelRecord(false)}
                    onConfirm={handleOnSuccessRemove}
                    deleting={markupPOUpdateState.loading === LoadingType.pending}
                    deleteType={t("mark_up")}
                    state={markupPOUpdateState}
                    onClearError={handleClearError}
                />
            )}
        </div>

    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1.2;
        if (value === 2) return 1.2;
        if (value === 3) return 1.5;
        if (value === 4) return 0.5;
        if (value === 5) return 0.5;

        return 1;
    }
}

export default CompanyMarkUpShift;