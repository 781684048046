import { useTranslation } from 'react-i18next';
import EmployeeAvatar from '../employee_avatar/employee_avatar';
import { IInBoundLeads } from '../../interfaces/inbound_leads';
import { CallIcon, GlobeIcon, LocationIcon } from '../../icons';
import { addressParser, convertToPhoneFormat, getArrayStrings } from '../../utils';
import SocialMediaProfile from '../social_media_profile/social_media_profile';
import { ProfileSocialMedia } from '../../interfaces';

interface Props {
    inboundLeadDetails: IInBoundLeads | null,
}

const LeadDashboardDetailsSection: React.FunctionComponent<Props> = (props) => {
    const { inboundLeadDetails } = props;
    const { t } = useTranslation();

    const inboundLeadProfileSection = [
        {
            sectionTitle: 'address',
            sectionIcon: LocationIcon,
            direction: 'column',
            sectionContent: [
                {
                    title: 'address', valueField: addressParser({
                        'street_address': "",
                        'city': "",
                        'state': "",
                        'zip_code': "",
                    })
                },
            ]
        },

        {
            sectionTitle: 'main_conatct',
            sectionIcon: CallIcon,
            direction: 'column',
            sectionContent: [
                { title: 'main_conatct', valueField: "first_name", valueField2: "last_name" },
                { title: 'contact_number', valueField: "contact_number" },
            ]
        },
        {
            sectionTitle: 'website',
            sectionIcon: GlobeIcon,
            direction: 'column',
            sectionContent: [
                { title: 'website', valueField: 'website' },
            ]
        },
    ];

    return (
        <div className="tds-container">
            <EmployeePortalInfo
                name={inboundLeadDetails?.company_name}
                uri={""}
                objectId={inboundLeadDetails?.id}
                rating={""}
                level={""}
            />
            {inboundLeadProfileSection.map((doc, index) => {
                return (
                    <div key={index} className="tds-info-container" style={{ marginTop: "1rem" }}>
                        <div className="tds-info-icon">
                            <doc.sectionIcon width={"100%"} height={"100%"} />
                        </div>
                        <div
                            className="tds-info-content"
                            style={{
                                ...(doc.direction === 'row') && { flexDirection: 'row', justifyContent: 'space-between' },
                            }}
                        >
                            {doc.sectionContent.map((data, index) => {
                                const arrayValue = getValues(data.valueField);
                                const arrayValue2 = getValues(data.valueField2);

                                return (
                                    <div
                                        key={index}
                                        className="tds-info-holder"
                                        style={{
                                            ...(doc.direction === 'row') && { flex: 1, width: "50%" },
                                            ...(index === 0) && { marginRight: '1em' }
                                        }
                                        }>
                                        <div className="tds-into-title">
                                            {data.title === "contact_number" ? null : <span>{t(data.title).toUpperCase()}</span>}
                                        </div>
                                        <div className="tds-info-value" style={{ marginBottom: '0.5em' }}>
                                            {data.title === "contact_number" ? <span>{convertToPhoneFormat(inboundLeadDetails?.contact_number)}</span> : <span>{arrayValue}</span>}
                                            <span style={{ marginLeft: "0.5em" }}>{arrayValue2}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            <SocialMediaProfile
                title={t('social_media').toUpperCase()}
                profile={{} as ProfileSocialMedia}
            />
        </div>
    );

    function getValues(obj: any) {
        if (inboundLeadDetails == null) return (<span>{''}</span>);
        if (Array.isArray(obj)) {
            return getArrayStrings(obj, inboundLeadDetails);
        }
        const value = (obj !== '' && inboundLeadDetails![obj] !== null) ? inboundLeadDetails![obj] : '';
        if (typeof obj === "object") {
            return obj;
        }
        return (
            <span>{value}</span>
        );
    }

}

const EmployeePortalInfo = (props) => {
    const { name } = props;

    return (
        <div className="tds-user-info">
            <EmployeeAvatar
                onClick={() => { }}
                url={""}
            />
            <div className="tds-user-name">
                <span>{name}</span>
            </div>
        </div>
    );
}

export default LeadDashboardDetailsSection;