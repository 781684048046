import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, TalentStatus } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getTalentStatus = createAsyncThunk<
    Array<TalentStatus>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/talent_status/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getTalentStatus();
            return res.data as TalentStatus[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createTalentStatus = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_status/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createTalentStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteTalentStatus = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_status/delete',
    async (method_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteTalentStatus(method_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateTalentStatus = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_status/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateTalentStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);