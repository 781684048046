import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import * as thunk from "./talent_unemployement_claims_list_thunk";
import { AppError, ITalentUnEmploymentClaimTalentData, IUnEmploymentClaim, IUnEmploymentClaimTalentData } from "../../../../../interfaces";
import { LoadingType } from "../../../../../enums";

export interface TalentUnEmploymentClaimsListState extends EntityState<IUnEmploymentClaim> {
    error?: AppError | null,
    loading: LoadingType,
    data: ITalentUnEmploymentClaimTalentData
}

const unEmploymentClaimsListAdapter: EntityAdapter<IUnEmploymentClaim> = createEntityAdapter<IUnEmploymentClaim>({
    selectId: (doc) => doc.id,
})

const initialState: TalentUnEmploymentClaimsListState = unEmploymentClaimsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    data: {} as ITalentUnEmploymentClaimTalentData
})


export const talentUnEmploymentClaimsListSlice = createSlice({
    name: 'talentUnEmploymentClaimsList',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTalentUnEmploymentClaimDetailsByTalentId.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null } ))
            .addCase(thunk.getTalentUnEmploymentClaimDetailsByTalentId.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload } ))
            .addCase(thunk.getTalentUnEmploymentClaimDetailsByTalentId.fulfilled, (state, action) => unEmploymentClaimsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload.unemployment_claims))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentUnEmploymentClaimsListActions = talentUnEmploymentClaimsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = unEmploymentClaimsListAdapter.getSelectors();
export const selectState = (state: TalentUnEmploymentClaimsListState) => state;
export const selectLoading = (state: TalentUnEmploymentClaimsListState) => state.loading;
export const selectError = (state: TalentUnEmploymentClaimsListState) => state.error;

