import { createAsyncThunk } from "@reduxjs/toolkit";
import { JobOrderAPI } from "../../../../../apis";
import { IJobServiceProfile, AppError } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";


export const getJobServiceProfile = createAsyncThunk<
    IJobServiceProfile,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/service_profile/job_order_service_profile/get',
    async (id, thunkAPI) => {
        try {

            const res = await JobOrderAPI.getJobOrderServiceProfile(id);
            if (typeof res.data === 'string') return {};
            return res.data as IJobServiceProfile;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Save Job Profile
export const updateJobServiceProfile = createAsyncThunk<
    string,
    {job_id: string, data:IJobServiceProfile},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/service_profile/job_order_service_profile/update',
    async (payload, thunkAPI) => {
        try {
            const res = await JobOrderAPI.saveJobOrderServiceProfile(payload.data);
            thunkAPI.dispatch(getJobServiceProfile(payload.job_id));
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);