import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import MultiUserSection from '../../../../../components/multi_users_section/multi_users_section';
import { TALENT_SKILLS_TYPE } from '../../../../../enums';
import { Profile } from '../../../../../interfaces';
import { selectCompanyDetailsByIdState } from '../../../../../redux/store';
import { useAppSelector } from '../../../../../redux/store';
import { getUserNameFromProfileDropdown } from '../../../../../utils';
import './alpha_omega_staffing_representatives.scss';

interface Props {
    onAddOption: (type: string) => void,
    onRemoveOption: (type: string, value: {id: string, value: string}) => void,
    accountManagers: Profile[],
    recruiters: Profile[],
    companyId: string,
    departmentId?: string,
}

const AlphaOmegaStaffingRepresentatives: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="cd-aosr-container">
            <MultiUserSection
                headerTitle={t('account_manager').toUpperCase()}
                actionName={t('add_account_manager')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.account_managers)}
                list={props.accountManagers.map((doc, index) => ({ id: doc.id, value: getUserNameFromProfileDropdown(doc) }))}
                onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.account_managers, doc)}
            />
            <MultiUserSection
                headerTitle={t('recruiter').toUpperCase()}
                actionName={t('add_recruiter')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.recruiters)}
                list={props.recruiters.map((doc, index) => ({ id: doc.id, value: getUserNameFromProfileDropdown(doc) }))}
                onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.recruiters, doc)}
            />
        </div>
    );

}

export default AlphaOmegaStaffingRepresentatives;