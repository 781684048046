import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, IBusinessSector } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getBusinessSectors = createAsyncThunk<
    Array<IBusinessSector>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/business_sector/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getBusinessSector();
            return res.data as IBusinessSector[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createBusinessSector = createAsyncThunk<
    string,
    {'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/business_sector/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createBusinessSector(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteBusinessSector = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/business_sector/delete',
    async (sector_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteBusinessSector(sector_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateBusinessSector = createAsyncThunk<
    string,
    {'id': string, 'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/business_sector/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateBusinessSector(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);