import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccountAPI, TalentAPI } from "../../apis";
import { EmployeeDashboardAPI } from "../../apis/employee_dashboard_api";
import { AppError, ICreateResponse, ICreateTalent, IEmpDBOBUpdateDD, ITalent, IUploadUrlsQuery, IUploadUrlsResponse } from "../../interfaces";
import { catchRequestError, invalidResponse } from "../../utils";


///get Employee
export const getemployeeDashboardDetailsThunk = createAsyncThunk<
    ITalent,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee/dashboard/get',
    async (thunkAPI) => {
        try {
            const res = await EmployeeDashboardAPI.getEmployeeDashboardDetails();
            return res.data as ITalent;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


///update Employee
export const updateEmployeeDashboardDetailsThunk = createAsyncThunk<
    ICreateResponse,
    ITalent,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee/dashboard/update',
    async (data, thunkAPI) => {
        try {
            const res = await EmployeeDashboardAPI.updateEmployeeDashboardDetails(data);
            return res.data as ITalent;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


/// Files upload
export const getEmployeeFileUploadUrlsThunk = createAsyncThunk<
    IUploadUrlsResponse,
    IUploadUrlsQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee-dashboard/upload-urls/get',
    async (query, thunkAPI) => {
        try {
            const res = await TalentAPI.getFileUploadUrls(query);
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data["data"] as IUploadUrlsResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const uploadEmployeeFilesThunk = createAsyncThunk<
    any,
    { data: IUploadUrlsResponse, photo?: File | null, resume?: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee-dashboard/upload-files/post',
    async (payload, thunkAPI) => {
        try {
            if (payload.photo && payload.resume && payload.data?.profile_pic_upload_url && payload.data?.resume_upload_url) {
                const photoRes = await TalentAPI.uploadPhotoAndResume(payload.data?.profile_pic_upload_url?.url, payload.photo);
                const resumeRes = await TalentAPI.uploadPhotoAndResume(payload.data?.resume_upload_url?.url, payload.resume);
                return photoRes.data || resumeRes.data;
            } else if (payload.photo && payload.resume === null && payload.data?.profile_pic_upload_url) {
                const photoRes = await TalentAPI.uploadPhotoAndResume(payload.data?.profile_pic_upload_url?.url, payload.photo);
                return photoRes.data;
            } else if (payload.photo === null && payload.resume && payload.data?.resume_upload_url) {
                const resumeRes = await TalentAPI.uploadPhotoAndResume(payload.data?.resume_upload_url?.url, payload.resume);
                return resumeRes.data;
            } else {
                /// ignore
            }
            // return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);