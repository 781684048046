import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../enums";
import { AppError } from "../../interfaces";
import * as thunk from "./forgot_password_thunk";


export interface ForgotPassword {
    error?: AppError | null,
    loading: LoadingType
    response: string,
    changePassword: {
        error?: AppError | null,
        loading: LoadingType
        response: string,
    }
}


const initialState: ForgotPassword = {
    error: null,
    loading: LoadingType.idle,
    response: '',
    changePassword: {
        error: null,
        loading: LoadingType.idle,
        response: '',
    }
}


export const forgotPasswordSlice = createSlice({
    name: "forgotPassword",
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.forgotPassword.pending, (state, action) => ({...state, loading: LoadingType.pending}))
            .addCase(thunk.forgotPassword.rejected, (state, action) => ({...state, loading: LoadingType.failed, error: action.payload}))
            .addCase(thunk.forgotPassword.fulfilled, (state, action) => ({...state, loading: LoadingType.succeeded, response: action.payload}))

            .addCase(thunk.changePassword.pending, (state, action) => ({...state, changePassword: {...state.changePassword, loading: LoadingType.pending}}))
            .addCase(thunk.changePassword.rejected, (state, action) => ({...state, changePassword: {...state.changePassword, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.changePassword.fulfilled, (state, action) => ({...state, changePassword: {...state.changePassword, loading: LoadingType.succeeded, response: action.payload}}))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const {} = forgotPasswordSlice.actions;
export const selectState = (state: ForgotPassword) => state;