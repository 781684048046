import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, Assignment } from "../../../../interfaces";
import * as thunk from "./assignments_list_thunk";

export interface AssignmentsListState extends EntityState<Assignment> {
    error?: AppError | null,
    loading: LoadingType,
}

const assignmentsListAdapter: EntityAdapter<Assignment> = createEntityAdapter<Assignment>({
    selectId: (doc) => doc.id,
})

const initialState: AssignmentsListState = assignmentsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const assignmentsListSlice = createSlice({
    name: 'assignmentsList',
    initialState: initialState,
    reducers: {
        clearAssignmentsListError(state){
            return {...state, error: null};
        },
        clearAssignmentsListState(state){
            return assignmentsListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAssignmentsList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getAssignmentsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAssignmentsList.fulfilled, (state, action) => assignmentsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearAssignmentsListError, clearAssignmentsListState } = assignmentsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = assignmentsListAdapter.getSelectors();
export const selectState = (state: AssignmentsListState) => state;
export const selectLoading = (state: AssignmentsListState) => state.loading;
export const selectError = (state: AssignmentsListState) => state.error;


