import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import {
  AppError,
  IOnboardingDocsReviewStatus,
} from "../../../../../interfaces";
import * as thunk from "./application_status_thunk";

interface CRUDState {
  error?: AppError | null;
  loading: LoadingType;
  response: string | null;
}
const initialUpdateState: CRUDState = {
  response: null,
  error: null,
  loading: LoadingType.idle,
};

export interface SingleTalentODRSState {
  data: IOnboardingDocsReviewStatus | null;
  error?: AppError | null;
  loading: LoadingType;
  update: CRUDState;
}
export interface TalentODRSState {
  [key: string]: SingleTalentODRSState;
}

const initialState: TalentODRSState = {};

function getLoadingState(state: any) {
  return {
    ...state,
    loading: LoadingType.pending,
    error: null,
  };
}

function getErrorState(state: any, payload: any) {
  return {
    ...state,
    loading: LoadingType.failed,
    error: payload,
  };
}

function getSuccessState(state: any, payload: any) {
  return {
    ...state,
    loading: LoadingType.succeeded,
    data: payload,
    error: null,
  };
}

export const talentODRSSlice = createSlice({
  name: "onboardingReviewStatus",
  initialState: initialState,
  reducers: {
    clearState(state) {
      return { ...state, ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getTalentODRSThunk.pending, (state, action) => ({
        ...state,
        [action.meta.arg.talent_id]: {
          ...getLoadingState(state[action.meta.arg.talent_id]),
          update: initialUpdateState,
        },
      }))
      .addCase(thunk.getTalentODRSThunk.rejected, (state, action) => ({
        ...state,
        [action.meta.arg.talent_id]: {
          ...getErrorState(state[action.meta.arg.talent_id], action.payload),
        },
      }))
      .addCase(thunk.getTalentODRSThunk.fulfilled, (state, action) => ({
        ...state,
        [action.meta.arg.talent_id]: {
          ...getSuccessState(state[action.meta.arg.talent_id], action.payload),
        },
      }))

    //   .addCase(
    //     thunk.updateTalentODRSThunk.pending,
    //     (state, action) => ({
    //         ...state,
    //         [action.meta.arg.talent_id]: {
    //             ...state[action.meta.arg.talent_id],
    //             update: {
    //                 ...getLoadingState(state[action.meta.arg.talent_id].update),
    //             },
    //         }
    //     })
    // )
    // .addCase(
    //     thunk.updateTalentODRSThunk.rejected,
    //     (state, action) => ({
    //         ...state,
    //         [action.meta.arg.talent_id]: {
    //             ...state[action.meta.arg.talent_id],
    //             update: {
    //                 ...getErrorState(state[action.meta.arg.talent_id].update, action.payload),
    //             },
    //         }
    //     })
    // )
    // .addCase(
    //     thunk.updateTalentODRSThunk.fulfilled,
    //     (state, action) => ({
    //         ...state,
    //         [action.meta.arg.talent_id]: {
    //             ...state[action.meta.arg.talent_id],
    //             update: {
    //                 ...getSuccessState(state[action.meta.arg.talent_id].update, action.payload),
    //             },           
    //         }
    //     })
    // )


      .addCase(thunk.updateTalentODRSThunk.pending, (state, action) => ({
        ...state,
        update: { ...state.update, loading: LoadingType.pending, error: null },
      }))
      .addCase(thunk.updateTalentODRSThunk.rejected, (state, action) => ({
        ...state,
        update: {
          ...state.update,
          loading: LoadingType.failed,
          error: action.payload,
          response: null,
        },
      }))
      .addCase(thunk.updateTalentODRSThunk.fulfilled, (state, action) => ({
        ...state,
        update: {
          ...state.update,
          loading: LoadingType.succeeded,
          error: null,
          response: action.payload,
        },
      }))

      .addDefaultCase((state) => ({ ...state }));
  },
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const talentODRSSliceActions = talentODRSSlice.actions;
export const selectTalentODRSState = (state: TalentODRSState) => state;
export const selectSingleTalentODRSState = (
  state: TalentODRSState,
  id: string
): SingleTalentODRSState => state[id];

export const selectInitialSingleTalentODRSState =
  (): SingleTalentODRSState => ({
    data: null,
    error: null,
    loading: LoadingType.idle,
    update: initialUpdateState,
  });
