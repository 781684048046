import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { useAppDispatch } from "../../../../redux/store";
import { CustomButton, FormInput } from "../../../../utils";
import { PlusIcon } from "../../../../icons";
import "./add_credits_popup.scss";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";

interface Props {
    visible: boolean;
    onClose: () => void;
    loading: LoadingType;
    error?: AppError | null | undefined;
    handleAddCredits: (export_credits: Number, mobile_credits: Number) => void;
}

const AddCreditsPopup: React.FunctionComponent<Props> = (props) => {
    const { visible, onClose, handleAddCredits, loading } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);

    const [addCreditNum, setAddCreditNum] = useState<{ export_credits: Number, mobile_credits: Number }>
        ({ export_credits: 0, mobile_credits: 0 });
    const [dialStatus, setDialStatus] = useState<string>("");
    const handleCreditsChange = (val) => {

        setAddCreditNum(prevState => ({
            ...prevState,
            export_credits: dialStatus === "export" ? val : 0,
            mobile_credits: dialStatus === "mobile" ? val : 0,

        }));
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"30%"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="add-credits-container">
                    <div className="add-credits-title-header">
                        <FormDialogTilteHeader
                            title={t("add_credits")}
                            titleIcon={<></>}
                        />
                    </div>
                    <div className="add-credits-content">
                        <div>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" className="imp-radio-label">{t('credit_type')} *</FormLabel>
                                <RadioGroup aria-required row aria-label="credit_type" name="credit_type" value={dialStatus}
                                    onChange={(event) => setDialStatus(event.target.value)} className="radio-group-div">
                                    <FormControlLabel value="export" control={<Radio />} label={t("email_export_credits")} />
                                    <FormControlLabel value="mobile" control={<Radio />} label={t("mobile_credits")} />
                                    <FormControlLabel value="both" control={<Radio />} label={t("Both")} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="add-creds-input-row">
                            {dialStatus !== "both" &&
                                (<FormInput
                                    id={"credits"}
                                    onChange={(id, val) => handleCreditsChange(val)}
                                    type={"number"}
                                    value={dialStatus === "export" ? addCreditNum.export_credits : addCreditNum.mobile_credits}
                                    label={t("Enter Credits")}
                                    placeholder="Enter Credits Count"

                                />)
                            }
                            {dialStatus === "both" &&
                                (<>
                                    <FormInput
                                        id={"export_credits"}
                                        onChange={(id, val) => setAddCreditNum(prevState => ({
                                            ...prevState,
                                            export_credits: val
                                        }))}
                                        type={"number"}
                                        value={addCreditNum.export_credits}
                                        label={t("enter") + " " + t("email_export_credits")}
                                        placeholder="Enter Credits Count"

                                    />
                                    <FormInput
                                        id={"mobile_credits"}
                                        onChange={(id, val) => setAddCreditNum(prevState => ({
                                            ...prevState,
                                            mobile_credits: val
                                        }))}
                                        type={"number"}
                                        value={addCreditNum.mobile_credits}
                                        label={t("enter") + " " + t("mobile_credits")}
                                        placeholder="Enter Credits Count"

                                    /></>)
                            }

                        </div>
                    </div>
                    <div className="add-credit-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable={true}
                                backgroundColor={THEME.secondaryColor4}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('add')}
                                enable={dialStatus !== "" ? true : false}
                                // loading={apolloCreditsState.addCredits.loading === LoadingType.pending}
                                loading={loading === LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={() => handleAddCredits(addCreditNum.export_credits, addCreditNum.mobile_credits)}
                                customClassName="add-creds-btn"
                            />
                        </div>
                    </div>
                </div>

            </DialogWrapper>
        </ActionDialogHolder>
    );
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
        // handleClearError();
    }
}

export default AddCreditsPopup;
