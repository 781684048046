import { combineReducers } from "@reduxjs/toolkit";
import { talentSSNRequestSlice } from "./request_ssn/request_ssn_reducer";
import { talentAvailableDatesSlice } from "./talent_availability/talent_availability_reducer";
import { talentDetailsSlice } from "./talent_details/talent_details_reducer";

export const talentDashboardReducers = combineReducers({
    [talentDetailsSlice.name]: talentDetailsSlice.reducer,
    [talentAvailableDatesSlice.name]: talentAvailableDatesSlice.reducer,
    [talentSSNRequestSlice.name]: talentSSNRequestSlice.reducer,
});

/// Talent details
export * from './talent_details/talent_details_selector';
export * from './talent_details/talent_details_thunk';

/// Talent Availability
export * from './talent_availability/talent_availability_selector';
export * from './talent_availability/talent_availability_thunk';

/// Talent ssn
export * from './request_ssn/request_ssn_selector';
export * from './request_ssn/request_ssn_thunk';
