import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI } from "../../../../apis";
import { AppError, IBusinessSector, Profile, UsersQuery } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getBusinessSectors = createAsyncThunk<
    Array<IBusinessSector>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/business_sectors/get',
    async (_, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getBusinessSectors();
            return res.data as IBusinessSector[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);