import { useTranslation } from "react-i18next";
import CurveTabNavigation from "../../../../../components/curve_tab_navigation/curve_tab_navigation";
import "../job_posting.scss";
import { FC, useState } from "react";
import { ChatGPTIcon } from "../../../../../icons";
import { CustomButton, FormInput, getPlusBtnIcon } from "../../../../../utils";
import { THEME } from "../../../../../enums";
import { TextareaAutosize } from "@material-ui/core";
import { IJobPost } from "../../../../../interfaces/job_posting";

interface Props {
  currentLoginUserName: string | undefined;
  handleCreateJobPost: () => void;
  qstnsAnswered: boolean;
  showErrorMsg: string;
  formState: IJobPost,
  handleFieldChange: (fieldId: string, value: any) => void;
  // loading:boolean;

}
const JobPostingStepOne: FC<Props> = (props) => {
  const {
    currentLoginUserName,
    handleCreateJobPost,
    qstnsAnswered,
    showErrorMsg,
    formState,
    handleFieldChange,
    // loading
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="jp-step-container">
      
        <div className="jp-step-right-panel">
          <div className="jp-step-right-panel-container">
            {/* <div className="left-row-panel">
              <div>
                <span>{t("job_post")}</span>
              </div>
              <div className="btn-err">
                <div>
                  <CustomButton
                    leftIcon={getPlusBtnIcon()}
                    loading={false}
                    textStyle={{ textTransform: "capitalize" }}
                    name={t("create_new_job_post")}
                    enable={true}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={handleCreateJobPost}
                  />
                </div>
                <div className="">
                  {qstnsAnswered && <span className="fill-ans">{showErrorMsg}</span>}
                </div>
              </div>

            </div> */}
            <div className="jp-step-header">
              <div>
                <div className="jp-step-header-title">
                  {t("hey_lets_get_start_building_ad", {
                    name: currentLoginUserName,
                  })}
                </div>
                <div className="jp-step-header-subtitle">
                  <span>{t("job_post_sub_title")}</span>
                </div>
              </div>
              <div className="btn-err">
                <div>
                  <CustomButton
                    leftIcon={getPlusBtnIcon()}
                    loading={false}
                    textStyle={{ textTransform: "capitalize" }}
                    name={t("create_new_job_post")}
                    enable={true}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={handleCreateJobPost}
                  />
                </div>
                <div className="">
                  {qstnsAnswered && <span className="fill-ans">{showErrorMsg}</span>}
                </div>
              </div>
              {/* <div className="chatgpt-icon">
                <ChatGPTIcon width={"100%"} height={"100%"} onClick={()=>{window.open("https://chat.openai.com/","_blank");}} />
                <div>
                  <b>{t("chatgpt")}</b>
                </div>
              </div> */}
            </div>
            <div className="jp-step-content">
              <div className="textarea-div">
                <FormInput
                  id={"description"}
                  onChange={handleFieldChange}
                  type={"text"}
                  value={formState.description}
                  label={""}
                  placeholder=""
                  required={false}
                  customStyle={{ minWidth: "15vw" }}
                  multiline={true}
                // disabled={inputEnable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobPostingStepOne;
