import { createAsyncThunk } from "@reduxjs/toolkit";
import { SalesCall, SalesCallsDataParam } from "../../../../interfaces/sales_call";
import { catchRequestError } from "../../../../utils";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { AppError } from "../../../../interfaces";
import { IReportCenterUploadUrlResponse } from "../../../../interfaces/report_center";

export const getSalesCallSummaryReportFilteredList = createAsyncThunk<
    Array<SalesCall>,
    SalesCallsDataParam,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/sales_calls_summary_report_list/post",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getSalesCallSummaryReportGetByUsers(payload);
            if (typeof res.data === "string") return [];
            const arr = res.data.data ? res.data.data : res.data;
            return arr as SalesCall[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getSalesCallSummaryReportDownloadUrl = createAsyncThunk<
    IReportCenterUploadUrlResponse,
    { upcoming: boolean, start_date: number, end_date: number, download: boolean, users?: Array<string> },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@report_center/sales_calls_summary_report_list/download/post',
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getSalesCallSummaryReportDownloadUrl(payload.upcoming, payload.start_date, payload.end_date, payload.download, payload.users);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);