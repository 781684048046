import { createAsyncThunk } from "@reduxjs/toolkit";
import { JobOrderAPI } from "../../../../../apis";
import { ROLE, TALENT_SKILLS_TYPE } from "../../../../../enums";
import { AppError, IAddSkill, IAddJobStaff, IRemoveJobStaff, IJobOrderDetail, ISkillsInfo, Profile, IRemoveSkill, IDepartment, IJobTalentMatches, IJobCompanyMatches, ITalentMatchesRequestBody } from "../../../../../interfaces";
import { catchRequestError, getTalentMatchQuery } from "../../../../../utils";


export const getJobById = createAsyncThunk<
    IJobOrderDetail,
    { job_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/dashboard/job_details/get',
    async (doc, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobById(doc.job_id);
            thunkAPI.dispatch(getJobAllSkills(doc));
            thunkAPI.dispatch(getJobAccountManagers(doc));
            thunkAPI.dispatch(getJobRecruiters(doc));
            thunkAPI.dispatch(getJobTalentMatches({ job_id: doc.job_id, query: getTalentMatchQuery(res.data) }));
            thunkAPI.dispatch(getJobCompanyMatches({ job_id: doc.job_id, query: getTalentMatchQuery(res.data) }));
            return res.data as IJobOrderDetail;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


/// [Company] ---> Skills
export const getJobAllSkills = createAsyncThunk<
    ISkillsInfo,
    { job_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/dashboard/job_details/skills/get',
    async (payload, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobSkills(payload);
            if (typeof res.data === 'string') return {
                [TALENT_SKILLS_TYPE.professional_skills]: [],
                [TALENT_SKILLS_TYPE.certifications]: [],
                [TALENT_SKILLS_TYPE.education]: [],
                [TALENT_SKILLS_TYPE.credentials]: [],
                [TALENT_SKILLS_TYPE.safety_equipment]: []
            };
            return res.data as ISkillsInfo;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const addJobSkills = createAsyncThunk<
    string,
    { job_id: string, value: IAddSkill },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/dashboard/job_details/skills/post',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.addJobSkill(data);
            thunkAPI.dispatch(getJobAllSkills({ job_id: data.job_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeJobSkills = createAsyncThunk<
    string,
    { job_id: string, value: IRemoveSkill },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/dashboard/job_details/skills/delete',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.deleteJobSkill(data);
            thunkAPI.dispatch(getJobAllSkills({ job_id: data.job_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);



/// [Jobs]--> [Alpha omega staffing] --> staff

export const getJobAccountManagers = createAsyncThunk<
    Array<Profile>,
    { job_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/dashboard/job_details/account_managers/get',
    async (query, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobStaff({ ...query, role: ROLE.accountManager, });

            if (typeof res.data === 'string') return [];
            return res.data as Profile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addJobAccountManagers = createAsyncThunk<
    string,
    IAddJobStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/dashboard/job_details/account_managers/post',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.addJobStaff(data);
            thunkAPI.dispatch(getJobAccountManagers({ job_id: data.job_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeJobAccountManagers = createAsyncThunk<
    string,
    IRemoveJobStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/dashboard/job_details/account_managers/delete',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.deleteJobStaff(data);
            thunkAPI.dispatch(getJobAccountManagers({ job_id: data.job_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const getJobRecruiters = createAsyncThunk<
    Array<Profile>,
    { job_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/dashboard/job_details/recruiters/get',
    async (query, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobStaff({ ...query, role: ROLE.recruiter, });
            if (typeof res.data === 'string') return [];
            return res.data as Profile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addJobRecruiters = createAsyncThunk<
    string,
    IAddJobStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/dashboard/job_details/recruiters/post',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.addJobStaff(data);
            thunkAPI.dispatch(getJobRecruiters({ job_id: data.job_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const removeJobRecruiters = createAsyncThunk<
    string,
    IRemoveJobStaff,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/dashboard/job_details/recruiters/delete',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.deleteJobStaff(data);
            thunkAPI.dispatch(getJobRecruiters({ job_id: data.job_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


/// [Talent] ---> JOB Matches
export const getJobTalentMatches = createAsyncThunk<
    Array<IJobTalentMatches>,
    { job_id: string, query: ITalentMatchesRequestBody },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/job_details/job/job_matches/get',
    async (payload, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobTalentMatches(payload);
            if (typeof res.data === 'string') return [];
            return res.data as IJobTalentMatches[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// [Talent] ---> Company Matches
export const getJobCompanyMatches = createAsyncThunk<
    Array<IJobCompanyMatches>,
    { job_id: string, query: ITalentMatchesRequestBody },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/job_details/job/company_matches/get',
    async (payload, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobCompanyMatches(payload);
            if (typeof res.data === 'string') return [];
            return res.data as IJobCompanyMatches[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
