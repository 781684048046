import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateInput } from "../../../../../../components/date_input/date_input";
import CustomFormSelect from "../../../../../../components/form_selector/form_select";
import { TimeInput } from "../../../../../../components/time_input/time_input";
import ToggleSwitch from "../../../../../../components/toggle_switch/toggle_switch";
import {
  UpdateAssignment,
  EndAssignmentReason,
  ServiceCall,
  Assignment,
  WorkCompCode,
} from "../../../../../../interfaces";
import { FormInput, mapOptions } from "../../../../../../utils";
import "./assignment_summary.scss";
import { PayrollBilling } from "../payroll_billing/payroll_billing";
import TimePickerComponent from "../../../../../../components/time_picker/time_picker";
import { ArrowDownIcon } from "../../../../../../icons";
import { THEME } from "../../../../../../enums";
import { Timer } from "@material-ui/icons";
import { WorkCompConfiguration } from "../../../../../../interfaces/work_comp_configuration";

export interface AssignmentSummaryProps {
  assignmentInfo: UpdateAssignment;
  setAssignment: Dispatch<SetStateAction<UpdateAssignment>>;
  endReason: EndAssignmentReason[];
  serviceCalls: ServiceCall[];
  updateAssignmentProp: Function;
  updateServiceCall: Function;
  updateWorkSchedule: Function;
  updateWorkScheduleTime: Function;
  disabled: boolean;
  workCompCodeList: { id: string, work_comp_code: string }[];
}

export const AssignmentSummary: FC<AssignmentSummaryProps> = (
  props: AssignmentSummaryProps
) => {
  const {
    assignmentInfo,
    endReason,
    serviceCalls,
    updateAssignmentProp,
    updateServiceCall,
    updateWorkSchedule,
    updateWorkScheduleTime,
    disabled,
    workCompCodeList,
    setAssignment
  } = props;
  const { t } = useTranslation();
  const [startDateValid, setStartDateValid] = useState(true);
  const [endDateValid, setEndDateValid] = useState(true);

  const days = [
    { id: "MONDAY", label: "Monday", startTime: "MONDAY_START_TIME", endTime: "MONDAY_END_TIME" },
    { id: "TUESDAY", label: "Tuesday", startTime: "TUESDAY_START_TIME", endTime: "TUESDAY_END_TIME" },
    { id: "WEDNESDAY", label: "Wednesday", startTime: "WEDNESDAY_START_TIME", endTime: "WEDNESDAY_END_TIME" },
    { id: "THURSDAY", label: "Thursday", startTime: "THURSDAY_START_TIME", endTime: "THURSDAY_END_TIME" },
    { id: "FRIDAY", label: "Friday", startTime: "FRIDAY_START_TIME", endTime: "FRIDAY_END_TIME" },
    { id: "SATURDAY", label: "Saturday", startTime: "SATURDAY_START_TIME", endTime: "SATURDAY_END_TIME" },
    { id: "SUNDAY", label: "Sunday", startTime: "SUNDAY_START_TIME", endTime: "SUNDAY_END_TIME" },
  ];

  function getDate(date) {
    if (typeof date === "string" && date.includes("-")) return date;
    let dateString: any = new Date(date * 1000);
    dateString = dateString.toLocaleDateString();
    dateString = dateString.split("/");
    return `${dateString[2]}-${dateString[1]}-${dateString[0]}`;
  }

  return (
    <div>
      <div
        className={`assignment-summary flex-row ${disabled ? "disabled" : ""}`}
      >
        <div className="shift-details section">
          <div className="shift-form">
            <div className="section-header">{"SHIFT DETAILS"}</div>
            <FormInput
              id={"shift_name"}
              onChange={(id, val) => updateAssignmentProp(id, val)}
              type={"text"}
              value={assignmentInfo?.shift_name}
              label={t("shift_name")}
              placeholder=""
            />
            <div className="flex-row">
              <div className="assnt-form-input" style={{ marginRight: "1em" }}>
                <div className="label-header">{t("start_date")}</div>
                <DateInput
                  id={"start_date"}
                  onChange={(f, v) => updateAssignmentProp(f, v)}
                  placeholder={""}
                  value={assignmentInfo.start_date}
                  setHoursToZero={true}
                />
              </div>
              <div className="assnt-form-input">
                <div className="label-header">{t("end_date")}</div>
                <DateInput
                  id={"end_date"}
                  onChange={(f, v) => updateAssignmentProp(f, v)}
                  placeholder={""}
                  value={assignmentInfo.end_date}
                  setHoursToZero={true}
                />
              </div>
            </div>
            <CustomFormSelect
              customStyle={{ width: "-webkit-fill-available" }}
              name="reason_assignment_ended"
              value={assignmentInfo.assignment_end_reason_id}
              label={"Reason Assignment Ended"}
              placeholder={t("select_placeholder")}
              list={mapOptions(endReason, "title", "id")}
              onChange={(value) =>
                updateAssignmentProp("assignment_end_reason_id", value)
              }
            />
            <div className="flex-row">
              <div className="assnt-form-input" style={{ marginRight: "1em" }}>
                <div className="label-header">Shift Start</div>
                {/* <TimeInput
                  id={"shift_start"}
                  onChange={(id, val) => {
                    // const d = new Date(val * 1000);
                    updateAssignmentProp(id, val);
                  }}
                  placeholder={""}
                  value={assignmentInfo.shift_start}
                /> */}
                <TimePickerComponent
                  id={"shift_start"}
                  onChange={(id, val) => {
                    updateAssignmentProp(id, val);
                  }}
                  placeholder={''}
                  value={assignmentInfo.shift_start}
                  icon={<Timer />}
                />
              </div>
              <div className="assnt-form-input">
                <div className="label-header">Shift End</div>
                {/* <TimeInput
                  id={"shift_end"}
                  onChange={(id, val) => {
                    // const d = new Date(val * 1000);
                    updateAssignmentProp(id, val);
                  }}
                  placeholder={""}
                  value={assignmentInfo.shift_end}
                /> */}
                <TimePickerComponent
                  id={"shift_end"}
                  onChange={(id, val) => {
                    updateAssignmentProp(id, val);
                  }}
                  placeholder={''}
                  value={assignmentInfo.shift_end}
                  icon={<Timer />}
                />
              </div>
            </div>
            <div className="flex-row">
              <div className="assnt-form-input" style={{ marginRight: "1em" }}>
                <FormInput
                  id={"shift_break"}
                  onChange={(_field: any, value: any) => {
                    if (value !== null) {
                      updateAssignmentProp("shift_break", Number(value));
                    }
                  }}
                  required={false}
                  placeholder={""}
                  type={"number"}
                  value={assignmentInfo.shift_break}
                  label={"Break Length (In Minutes)"}
                />
              </div>
              <div className="assnt-form-input">
                <FormInput
                  id={"shift_hours"}
                  onChange={(_field: any, value: any) => {
                    if (value !== null) {
                      updateAssignmentProp("shift_hours", Number(value));
                    }
                  }}
                  required={false}
                  placeholder={""}
                  type={"number"}
                  value={assignmentInfo.shift_hours}
                  label={"Shift Hours"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="schdule-sections-holder section">
          {/* <div className="work-schedule ">
            <div className="section-header">{"WORK SCHEDULE"}</div>
            {days.map((day, index) => (
              <div key={index} className="work-schedule-input" aria-disabled>
                <ToggleSwitch
                  label={t(day.label)}
                  checked={
                    assignmentInfo.work_schedule
                      ? assignmentInfo.work_schedule[day.id]
                      : false
                  }
                  onChange={(value) => updateWorkSchedule(day.id, value)}
                />
              </div>
            ))}
          </div> */}
          <div className="service-calls">
            <div className="sc-section-header">
              {t("service_calls").toUpperCase()}
            </div>
            {serviceCalls.map((doc, index) => {
              return (
                <div key={"service" + index} className="service-calls-input">
                  <ToggleSwitch
                    label={doc.service_call}
                    checked={
                      !!assignmentInfo.service_calls?.find((id) => id === doc.id)
                    }
                    onChange={(value) => updateServiceCall(doc.id, value)}
                  />
                </div>
              );
            })}
          </div>
          <div className="work-schedule ">
            <table>
              {/* <div className={"flex-row"}> */}
              {/* <div className="schdule-sections-holder"> */}
              <div className="work-schedule-table">
                <div className="section-header">{"WORK SCHEDULE"}</div>
                {days.map((day, index) => (
                  <tr key={index}>
                    <td >
                      <ToggleSwitch
                        label={t(day.label)}
                        checked={
                          assignmentInfo.work_schedule
                            ? assignmentInfo.work_schedule[day.id]
                            : false
                        }
                        onChange={(value) => updateWorkSchedule(day.id, value)}
                      />
                    </td>
                    <td>
                      <div style={{ display: "flex" }}>
                        <TimePickerComponent
                          id={assignmentInfo.work_schedule[day.startTime]}
                          onChange={(id, value) => updateWorkScheduleTime(day.startTime, value)}
                          placeholder={''}
                          value={assignmentInfo?.work_schedule[day.startTime]}
                        />
                        <ArrowDownIcon
                          width={"0.7vw"}
                          height={"0.7vw"}
                          style={{ color: THEME.defaultHighLightColor }}
                        />

                        <div className="label">{t("to")}</div>
                        <TimePickerComponent
                          id={assignmentInfo.work_schedule[day.endTime]}
                          onChange={(id, value) => updateWorkScheduleTime(day.endTime, value)}
                          placeholder={''}
                          value={assignmentInfo?.work_schedule[day.endTime]}
                        />
                        <ArrowDownIcon
                          width={"0.7vw"}
                          height={"0.7vw"}
                          style={{ color: THEME.defaultHighLightColor }} />
                      </div>
                    </td>
                  </tr>
                ))}
              </div>
              {/* </div> */}
              {/* </div> */}
            </table>
          </div>
        </div>
      </div>
      <div>
        <PayrollBilling
          assignmentInfo={
            assignmentInfo ?? new Assignment() ?? new UpdateAssignment()
          }
          setAssignment={setAssignment}
          updateProp={updateAssignmentProp}
          wcCodes={workCompCodeList}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
