import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./manage_billing_batch_thunk";
import { AppError, IBillingBatch } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

export interface BillingBatchState {
  error?: AppError | null;
  loading: LoadingType;
  response: IBillingBatch | null;
  post: {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
  },
  biProcess: {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
  },
  deleteBatch: {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
  },
}

const initialState: BillingBatchState = {
  error: null,
  loading: LoadingType.idle,
  response: null,
  post: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  },
  biProcess: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  },
  deleteBatch: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  },
};

export const billingBatchSlice = createSlice({
  name: "billingBatch",
  initialState: initialState,
  reducers: {
    clearState(state) {
      return { ...initialState };
    },
    clearErrorState(state) {
      return { ...initialState, error: null };
    },
    clearPostErrorState(state) {
      return {
        ...initialState,
        post: { ...initialState.post, error: null }
      }

    },
    clearPostProcessErrorState(state) {
      return {
        ...initialState,
        post: { ...initialState.biProcess, error: null }
      }

    },
    clearDeleteBatchState(state) {
      return {
          ...state, deleteBatch: { ...initialState.deleteBatch },
      };
  },
    clearDeleteBatchErrorState(state) {
      return {
        ...state, deleteBatch: { ...initialState.deleteBatch, error: null, },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.createBillingBatchThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.createBillingBatchThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.createBillingBatchThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        response: action.payload,
      }))
      .addCase(thunk.postBillingBatchThunk.pending, (state, action) => ({ ...state, post: { ...state.post, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.postBillingBatchThunk.rejected, (state, action) => ({ ...state, post: { ...state.post, loading: LoadingType.failed, error: action.payload, } }))
      .addCase(thunk.postBillingBatchThunk.fulfilled, (state, action) => ({ ...state, post: { ...state.post, loading: LoadingType.succeeded, error: null, response: action.payload } }))

      .addCase(thunk.postBillingInvoicesProcessThunk.pending, (state, action) => ({ ...state, biProcess: { ...state.biProcess, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.postBillingInvoicesProcessThunk.rejected, (state, action) => ({ ...state, biProcess: { ...state.biProcess, loading: LoadingType.failed, error: action.payload, } }))
      .addCase(thunk.postBillingInvoicesProcessThunk.fulfilled, (state, action) => ({ ...state, biProcess: { ...state.biProcess, loading: LoadingType.succeeded, error: null, response: action.payload } }))

      .addCase(thunk.deleteBillingBatchThunk.pending, (state, action) => ({ ...state, deleteBatch: { ...state.deleteBatch, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.deleteBillingBatchThunk.rejected, (state, action) => ({ ...state, deleteBatch: { ...state.deleteBatch, loading: LoadingType.failed, error: action.payload, } }))
      .addCase(thunk.deleteBillingBatchThunk.fulfilled, (state, action) => ({ ...state, deleteBatch: { ...state.deleteBatch, loading: LoadingType.succeeded, error: null, response: action.payload } }))

      .addDefaultCase((state) => ({ ...state }));
  },
});
export const BillingManageBatchSliceActions = billingBatchSlice.actions;
export const selectState = (state: BillingBatchState) => state;
