import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import AlphaOmegaStaffing from '../../../components/alpha_omega_staffing';
import { THEME } from '../../../enums';
import { MarkIcon } from '../../../icons';
import './online_application_holder.scss';

interface Props {
    history?: any,
    location?: any,
    hideSubTitle?: boolean,
    children: any,
    agencyLogoURL?: string,
};
const OnlineApplicationHolder: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="oah-holder-container">
            <div className="oah-banner-view"></div>
            <div className="oah-content-view">
                <div className="header">
                    <div className="page-info">
                        <div className="icon-holder">
                            <MarkIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
                        </div>
                        <div className="title-holder">
                            <div className="title">
                                <span>{t('online_application')}</span>
                            </div>
                            <div className="sub-title">
                                <span>{props.hideSubTitle ? '' : t('please_fallow_the_steps_to_finish_online_application')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="agency-branding">
                    <img src={props?.agencyLogoURL} style={{ height: '3.2vw' }} alt=""></img>
                    </div>
                </div>
                <div className="content">
                   {props.children}
                </div>
            </div>
        </div>
    )
}

export default OnlineApplicationHolder;