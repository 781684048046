import { combineReducers } from "@reduxjs/toolkit";
import { companyDetailsSlice } from "./company_details/company_details_reducer";
import { companyDepartmentSlice } from "./departments/departments_reducer";
import { companyDepartmentStructureSlice } from "./departments_structure/departments_structure_reducer";

export const companyDashboardReducers = combineReducers({
    [companyDetailsSlice.name]: companyDetailsSlice.reducer,
    [companyDepartmentSlice.name]: companyDepartmentSlice.reducer,
    [companyDepartmentStructureSlice.name]: companyDepartmentStructureSlice.reducer,
});


/// [Companies] Company details
export * from './company_details/company_details_selector';
export * from './company_details/company_details_thunk';

/// [Companies] departments
export * from './departments/departments_selector';
export * from './departments/departments_thunk';

/// [Companies] departments structure
export * from './departments_structure/departments_structure_selector';
export * from './departments_structure/departments_structure_thunk';


// /// [Companies] professional_skills
// export * from './professional_skills/professional_skills_selector';
// export * from './professional_skills/professional_skills_thunk';

// /// [Companies] credentials
// export * from './credentials/credentials_selector';
// export * from './credentials/credentials_thunk';

// /// [Companies] certifications
// export * from './certifications/certifications_selector';
// export * from './certifications/certifications_thunk';

// /// [Companies] education
// export * from './education/education_selector';
// export * from './education/education_thunk';

// /// [Companies] required_equipment
// export * from './required_equipment/required_equipment_selector';
// export * from './required_equipment/required_equipment_thunk';

/// [Companies] account_managers
// export * from './account_managers/account_managers_selector';
// export * from './account_managers/account_managers_thunk';

// /// [Companies] recruiters
// export * from './recruiters/recruiters_selector';
// export * from './recruiters/recruiters_thunk';