import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { OtherDocs } from "../../../../interfaces/other_docs";

export const getOtherDocs = createAsyncThunk<
    Array<OtherDocs>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/talent_other_docs/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getOtherDocs();
            return res.data as OtherDocs[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createOtherDocs = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_other_docs/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createOtherDocs(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteOtherDocs = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_other_docs/delete',
    async (other_doc_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteOtherDocs(other_doc_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateOtherDocs = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/talent_other_docs/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateOtherDocs(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);