import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI,  } from "../../../apis";
import { AppError, ICompanyEducationRequirement, } from "../../../interfaces";
import { catchRequestError } from "../../../utils";

export const getEducationList = createAsyncThunk<
    Array<ICompanyEducationRequirement>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/education/get',
    async (_, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getAllEducationRequirement();
            if(typeof res.data === 'string') return [];
            return res.data  as ICompanyEducationRequirement[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);