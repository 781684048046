import { useTranslation } from 'react-i18next';
import './tab_navigation.scss';

interface Props {
    children: React.ReactNode,
    activeRoute: string,
    tabList: Array<{ title: string, route: string }>,
    actions?: React.ReactNode,
    onChange: (value: string) => void,
    onClose?: () => void,
}

const TabNavigation = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div className="tab-navigation-container">
            <div className="header">
                <div className="tab-options-container">
                    {props && props.tabList && props.tabList.map((doc, index) => {
                        return (
                            <a
                                key={index}
                                className={`tab-option ${(props.activeRoute.startsWith(doc.route) ? 'active' : '')}`}
                                onClick={() => props.onChange(doc.route)}
                            >
                                <span>{t(doc.title)}</span>
                            </a>
                        )
                    })}
                </div>
                {props.actions ?? <div></div>}
            </div>
            <div className="content">
                {props.children}
            </div>
        </div>
    );
}

export default TabNavigation;