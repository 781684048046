import { combineReducers } from "@reduxjs/toolkit";
import { invoiceRegisterListSlice } from "./invoice_register/invoice_register_reducer";
import { cashReceiptsListSlice } from "./cash_receipts/cash_receipts_reducer";
import { aRAgingReportListSlice } from "./ar_aging_report/ar_aging_report_reducer";
import { grossProfitSlice } from "./gross_profit/gross_profit_reducer";


export const reportCenterFinanceAccountingReducers = combineReducers({
    [invoiceRegisterListSlice.name]: invoiceRegisterListSlice.reducer,
    [cashReceiptsListSlice.name]: cashReceiptsListSlice.reducer,
    [aRAgingReportListSlice.name]: aRAgingReportListSlice.reducer,
    [grossProfitSlice.name]: grossProfitSlice.reducer,

});

export * from "./invoice_register/invoice_register_thunk";
export * from "./invoice_register/invoice_register_selector";

export * from "./cash_receipts/cash_receipts_thunk";
export * from "./cash_receipts/cash_receipts_selector";

export * from "./ar_aging_report/ar_aging_report_thunk";
export * from "./ar_aging_report/ar_aging_report_selector";

export * from "./gross_profit/gross_profit_thunk";
export * from "./gross_profit/gross_profit_selector";


