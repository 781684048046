
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./default_payment_option_reducer";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.DeafaultPaymentOptionList;
export const selectDeafaultPaymentOptionListState = createSelector(select, selectState);
export const selectDeafaultPaymentOptionList = createSelector(
    select,
    selectAll
);
export const selectDeafaultPaymentOptionListEntities = createSelector(
    select,
    selectEntities
);