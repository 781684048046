import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../components/dialog_wrapper/dialog_wrapper';
import { SpinnerScreen } from '../../../utils';
import SelectAvailabilityOptions from '../forms/select_employee_available_options/select_employee_available_options';

interface Props {
    visible: boolean,
    title: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    talentId: string,
    talentName: string,
    profile: string
}

const MarkEmployeeAvailablePopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        talentId,
        talentName,
        profile
    } = props;
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);



    function isLoading() {
        return false;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="mark-tlnt-pop-holder">
                    {getUI()}
                </div>

            </DialogWrapper>
        </ActionDialogHolder>
    )


    function getUI() {
        if (isLoading()) {
            return (
                <div className="loading-spinner"><SpinnerScreen /></div>
            )
        }

        return (
            <SelectAvailabilityOptions
                title={t('almost_done_which_would_you_like_to_do_next', { name: props.profile })}
                talentName={talentName}
                talentId={talentId}
                onClose={() => { closePopup(onClose) }}
                onSuccessClose={handleMarkSuccessClose}
            />
        )
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function handleMarkSuccessClose() {
        closePopup(onClose);
    }
}


export default MarkEmployeeAvailablePopup;