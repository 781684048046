

import { createSlice } from '@reduxjs/toolkit';
import { LoadingType } from '../../../../../enums';
import { AppError, IBackOfficeUploadUrlResponse, ITCErrorReports } from '../../../../../interfaces';
import * as thunk from './errors_reports_thunk';

export interface TCERListState {
    error?: AppError | null;
    loading: LoadingType;
    response: ITCErrorReports[] | null;
    approve: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    resolve: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    fileURL: {
        error?: AppError | null;
        loading: LoadingType;
        response: IBackOfficeUploadUrlResponse | null;
    };
}

const initialState: TCERListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    approve: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    resolve: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    fileURL: {
        error: null,
        loading: LoadingType.idle,
        response: null,

    },
}

export const timecardsErrorReportListSlice = createSlice({
    name: "timecardsErrorReportList",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearAprroveErrorState(state) {
            return { ...state, approve: { ...state.approve, error: null } };
        },
        clearAprroveState(state) {
            return { ...state, approve: { ...initialState.approve } };
        },
        clearResolveErrorState(state) {
            return { ...state, resolve: { ...state.resolve, error: null } };
        },
        clearResolveState(state) {
            return { ...state, resolve: { ...initialState.resolve } };
        },
        clearDownloadState(state) {
            return {
                ...state, fileURL: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTCERListThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getTCERListThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getTCERListThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))
            .addCase(thunk.approveTCErrorThunk.pending, (state, action) => ({ ...state, approve: { ...state.approve, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.approveTCErrorThunk.rejected, (state, action) => ({ ...state, approve: { ...state.approve, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.approveTCErrorThunk.fulfilled, (state, action) => ({ ...state, approve: { ...state.approve, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addCase(thunk.resolveTCErrorThunk.pending, (state, action) => ({ ...state, resolve: { ...state.resolve, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.resolveTCErrorThunk.rejected, (state, action) => ({ ...state, resolve: { ...state.resolve, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.resolveTCErrorThunk.fulfilled, (state, action) => ({ ...state, resolve: { ...state.resolve, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getTimeCardsErrorReportDownloadUrl.pending, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getTimeCardsErrorReportDownloadUrl.rejected, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.getTimeCardsErrorReportDownloadUrl.fulfilled, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase((state) => ({ ...state }));
    }
});
export const timecardsErrorReportListSliceActions = timecardsErrorReportListSlice.actions;
export const selectState = (state: TCERListState) => state;
export const selectTCERDownloadUrl = (state: TCERListState) => state.fileURL;
