import {createAsyncThunk} from "@reduxjs/toolkit";
import {ContactListItem} from "../../../../../entities/ContactInfo";
import {AppError} from "../../../../../interfaces";
import {catchRequestError} from "../../../../../utils";
import {contactsByCompanyDepartment} from "../../../../../apis/contacts_api";

export const getCompanyContacts = createAsyncThunk<
    Array<ContactListItem>,
    {company_id: string, department_id: string | null},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@companies/details/contacts/contact-list/get',
    async (data, thunkAPI) => {
        try {
            const res = await contactsByCompanyDepartment(data);
            return res.data as ContactListItem[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
