import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { OnlineApplicationSent } from "../../../../interfaces/online_application_sent";
import { catchRequestError } from "../../../../utils";
import { getOnlineApplicationsSentList } from "../online_applications_sent_list/online_applications_sent_list_thunk";

/// Update
export const updateApplicationSent = createAsyncThunk<
  string,
  OnlineApplicationSent,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@manage_online_application_sent/update", async (payload, thunkAPI) => {
  try {
    const res = await DashboardAPI.updateOnlineApplicationsSent(payload);
    thunkAPI.dispatch(getOnlineApplicationsSentList());
    return res.data as string;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});
