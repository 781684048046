import {createAsyncThunk} from "@reduxjs/toolkit";
import {ContactInfo} from "../../../../../entities/ContactInfo";
import {AppError} from "../../../../../interfaces";
import {catchRequestError} from "../../../../../utils";
import {createContact} from "../../../../../apis/contacts_api";


export const saveCompanyContacts = createAsyncThunk<
    string,
    ContactInfo,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@companies/details/contacts/contact-create/post',
    async (data, thunkAPI) => {
        try {
            const res = await createContact(data);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);