import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../utils";
import { THEME } from "../../../enums";
import "./onboarding_paperwork.scss";
import { SearchIcon, TickMarkIcon } from "../../../icons";
import { useEffect, useState } from "react";
import { Portal } from "react-portal";
import EmployeeHandbookPopup from "../../popups/employee_handbook/employee_handbook";
import {
  ICreateTalentOnboardingData,
  IOnboardingVerifyTokenResponse,
} from "../../../interfaces";
import NoticeOfHealthcareExchangePopup from "../../popups/notice_of_healthcare_exchange/notice_of_healthcare_exchange";
// import EssentialStaffcarePopup from '../../popups/essential_staffcare/essential_staffcare';
// import WOTCPopup from '../../popups/wotc/wotc';
import DirectDepositPopup from "../../popups/direct_deposit/direct_deposit";
import W4Popup from "../../popups/w4/w4";
import I9Popup from "../../popups/i9/i9";
import { updateTalentOnboardingThunk } from "../../../redux/onboarding_portal";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { selectSuccessProfileQuestionGroupList } from "../../../redux/admin_center";
import EssentialStaffcarePopup from "../../popups/essential_staffcare/essential_staffcare";
import WOTCPopup from "../../popups/wotc/wotc";
interface PaperWorkProps {
  token: string;
  termsAndConditions: any;
  state: IOnboardingVerifyTokenResponse;
  loading: boolean;
  formState: ICreateTalentOnboardingData;
}

interface DialogItemProps {
  visible: boolean;
  title: string;
  type: string;
  enable: boolean;
  recruiter_review?: boolean;
}
interface DialogState {
  handbook_document: DialogItemProps;
  notice_of_healthcare_exchange_document: DialogItemProps;
  essential_staffcare_document: DialogItemProps,
  wotc_document: DialogItemProps,
  direct_deposit_document: DialogItemProps;
  w4_document: DialogItemProps;
  i9_document: DialogItemProps;
}
const OnboardingPaperWork = (props: PaperWorkProps) => {
  const { state, termsAndConditions, token, loading, formState } = props;
  const {
    talent_onboarding_data: talent,
    team_member_detail: recruiter,
    agency_onboarding_data: agency,
  } = state;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const successProfileQuestionsGroupList = useAppSelector((state) =>
    selectSuccessProfileQuestionGroupList(state)
  );

  // const
  const [options, setOptions] = useState<DialogState>({
    handbook_document: {
      type: "handbook_document",
      visible: false,
      title: t("employee_handbook"),
      enable: true,
      recruiter_review: false,
    },
    notice_of_healthcare_exchange_document: {
      type: "notice_of_healthcare_exchange_document",
      visible: false,
      title: t("notice_of_healthcare_exchange"),
      enable: true,
      recruiter_review: false,
    },
    essential_staffcare_document: { type: 'essential_staffcare_document', visible: false, title: t('essential_staff_care'), enable: true, },
    wotc_document: { type: 'wotc_document', visible: false, title: t('wotc'), enable: true, },
    direct_deposit_document: {
      type: "direct_deposit_document",
      visible: false,
      title: t("direct_deposit"),
      enable: true,
      recruiter_review: false,
    },
    w4_document: {
      type: "w4_document",
      visible: false,
      title: t("w4"),
      enable: true,
      recruiter_review: false,
    },
    i9_document: {
      type: "i9_document",
      visible: false,
      title: t("i9"),
      enable: true,
      recruiter_review: false,
    },
  });

  useEffect(() => {
    if (talent) {
      setOptions({
        ...options,
        handbook_document: {
          ...options.handbook_document,
          enable: true,
          recruiter_review: isTalentRecruiterReviewCompleted(
            "handbook_document",
            "recruiter_completed_review"
          ),
        },
        notice_of_healthcare_exchange_document: {
          ...options.notice_of_healthcare_exchange_document,
          enable: isTalentRecruiterReviewCompleted(
            "handbook_document",
            "talent_completed_review"
          ),
          recruiter_review: isTalentRecruiterReviewCompleted(
            "notice_of_healthcare_exchange_document",
            "recruiter_completed_review"
          ),
        },
        essential_staffcare_document: {
          ...options.essential_staffcare_document, enable: !talent.essential_staffcare_document.talent_completed_review,
          recruiter_review: isTalentRecruiterReviewCompleted(
            "essential_staffcare_document",
            "recruiter_completed_review"
          ),
        },
        wotc_document: {
          ...options.wotc_document, enable: !talent.wotc_document.talent_completed_review,
          recruiter_review: isTalentRecruiterReviewCompleted(
            "wotc_document",
            "recruiter_completed_review"
          ),
        },
        direct_deposit_document: {
          ...options.direct_deposit_document, 
          enable: isTalentRecruiterReviewCompleted(
            "notice_of_healthcare_exchange_document",
            "talent_completed_review"
          ),
          recruiter_review: isTalentRecruiterReviewCompleted(
            "direct_deposit_document",
            "recruiter_completed_review"
          ),
        },
        w4_document: {
          ...options.w4_document,
          enable: isTalentRecruiterReviewCompleted(
            "direct_deposit_document",
            "talent_completed_review"
          ),
          recruiter_review: isTalentRecruiterReviewCompleted(
            "w4_document",
            "recruiter_completed_review"
          ),
        },
        i9_document: {
          ...options.i9_document,
          enable: isTalentRecruiterReviewCompleted(
            "w4_document",
            "talent_completed_review"
          ),
          recruiter_review: isTalentRecruiterReviewCompleted(
            "i9_document",
            "recruiter_completed_review"
          ),
        },
      });
    }
  }, [talent]);

  function isTalentRecruiterReviewCompleted(type: string, key: string) {
    const {
      handbook_document,
      terms_and_conditions,
      notice_of_healthcare_exchange_document,
      essential_staffcare_document,
      wotc_document,
      direct_deposit_document,
      w4_document,
      i9_document,
    } = talent;

    switch (type) {
      case "handbook_document":
        return (
          handbook_document[key] &&
          terms_and_conditions.talent_read_tc &&
          terms_and_conditions.talent_signature !== null
        );
      case "notice_of_healthcare_exchange_document":
        return notice_of_healthcare_exchange_document[key];
      case "essential_staffcare_document":
        return essential_staffcare_document[key];
      case "wotc_document":
        return wotc_document[key];
      case "direct_deposit_document":
        return direct_deposit_document[key];
      case "w4_document":
        return w4_document[key];
      case "i9_document":
        return i9_document[key];
      default:
        return false;
    }
  }

  function handleSuccessClose(type: string, value?: any) {
    dispatch(
      updateTalentOnboardingThunk({
        ...talent,
        [type]: value ?? { ...talent[type], talent_completed_review: true },
      })
    );
    setOptions({ ...options, [type]: { ...options[type], visible: false } });
  }

  function handleCancelClose(type: string) {
    setOptions({ ...options, [type]: { ...options[type], visible: false } });
  }

  function handleOpenOption(type: string) {
    if (!talent[type].recruiter_completed_review) {
      setOptions({ ...options, [type]: { ...options[type], visible: true } });
    }
  }

  function getButtonName(doc: DialogItemProps) {
    if (isTalentRecruiterReviewCompleted(doc.type, "talent_completed_review")) {
      return (
        <div className="btn-name-container">
          <div className="icon-holder">
            <TickMarkIcon className="review_searchicon" />
          </div>
          <div className="label-name">
            <span>
              {doc.recruiter_review
                ? `${t("recruiter_review_finished_cant_open")}`
                : `${t("review_completed")}!`}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="btn-name-container">
        <div className="icon-holder">
          <SearchIcon
            className="review_searchicon"
            style={{ color: THEME.defaultHighLightColor }}
          />
        </div>
        <div className="label-name">
          <span>{t("click_to_review_and_complete")}</span>
        </div>
      </div>
    );
  }
  return (
    <>
      <div>
        <div className="team-title-holder">
          <span>{t("please_review_and_complete_paperwork")}</span>
        </div>
        <div>
          {Object.keys(options).map((key, index) => {
            return (
              <div key={index} className="doc-holder">
                <div className="doc-holder-title">
                  <span>{t(options[key].title)}</span>
                </div>
                <div>
                  <CustomButton
                    title={
                      options[key].enable
                        ? t("open")
                        : t("complete_previous_section")
                    }
                    loading={false}
                    textStyle={{
                      textTransform: "capitalize",
                    }}
                    name={getButtonName(options[key])}
                    enable={options[key].enable && !loading}
                    customClassName="review_btn"
                    // backgroundColor={THEME.defaultHighLightColor}
                    onClick={() => handleOpenOption(options[key].type)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {options.handbook_document.visible && (
        <Portal>
          <EmployeeHandbookPopup
            visible={options.handbook_document.visible}
            type={options.handbook_document.type}
            token={token}
            state={talent}
            termsAndConditions={termsAndConditions}
            title={options.handbook_document.title}
            onClose={() => handleCancelClose(options.handbook_document.type)}
            successTitle={""}
            onSuccessClose={handleSuccessClose}
          />
        </Portal>
      )}
      {options.notice_of_healthcare_exchange_document.visible && (
        <Portal>
          <NoticeOfHealthcareExchangePopup
            visible={options.notice_of_healthcare_exchange_document.visible}
            type={options.notice_of_healthcare_exchange_document.type}
            token={token}
            state={talent}
            recruiter={recruiter}
            agency={agency}
            title={options.notice_of_healthcare_exchange_document.title}
            onClose={() =>
              handleCancelClose(
                options.notice_of_healthcare_exchange_document.type
              )
            }
            successTitle={""}
            onSuccessClose={handleSuccessClose}
          />
        </Portal>
      )}
      {options.essential_staffcare_document.visible && <Portal>
        <EssentialStaffcarePopup
          visible={options.essential_staffcare_document.visible}
          type={options.essential_staffcare_document.type}
          token={token}
          state={talent}
          title={options.essential_staffcare_document.title}
          onClose={() => handleCancelClose(options.essential_staffcare_document.type)}
          successTitle={''}
          onSuccessClose={handleSuccessClose}
        />
      </Portal>}
      {options.wotc_document.visible && <Portal>
        <WOTCPopup
          visible={options.wotc_document.visible}
          type={options.wotc_document.type}
          token={token}
          state={talent}
          title={options.wotc_document.title}
          onClose={() => handleCancelClose(options.wotc_document.type)}
          successTitle={''}
          onSuccessClose={handleSuccessClose}
        />
      </Portal>}
      {options.direct_deposit_document.visible && (
        <Portal>
          <DirectDepositPopup
            visible={options.direct_deposit_document.visible}
            type={options.direct_deposit_document.type}
            token={token}
            state={talent}
            title={options.direct_deposit_document.title}
            onClose={() =>
              handleCancelClose(options.direct_deposit_document.type)
            }
            successTitle={""}
            onSuccessClose={handleSuccessClose}
            lastStep={Object.keys(successProfileQuestionsGroupList).length}
            talentState={state}
            formState={formState}
          />
        </Portal>
      )}
      {options.w4_document.visible && (
        <Portal>
          <W4Popup
            visible={options.w4_document.visible}
            type={options.w4_document.type}
            token={token}
            state={talent}
            recruiter={recruiter}
            agency={agency}
            title={options.w4_document.title}
            onClose={() => handleCancelClose(options.w4_document.type)}
            successTitle={""}
            onSuccessClose={handleSuccessClose}
          />
        </Portal>
      )}
      {options.i9_document.visible && (
        <Portal>
          <I9Popup
            visible={options.i9_document.visible}
            type={options.i9_document.type}
            token={token}
            state={talent}
            recruiter={recruiter}
            agency={agency}
            title={options.i9_document.title}
            onClose={() => handleCancelClose(options.i9_document.type)}
            successTitle={""}
            onSuccessClose={handleSuccessClose}
          />
        </Portal>
      )}
    </>
  );
};
export default OnboardingPaperWork;
