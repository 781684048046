import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, IWeeklyTransactionAssignment } from "../../../../interfaces";
import * as thunk from './assignment_transactions_thunk'


export interface AssignmentTransactionState {
    error?: AppError | null;
    loading: LoadingType;
    response: IWeeklyTransactionAssignment[] | null;
    create: {
        error?: AppError | null;
        loading: LoadingType;
        createResponse: string | null;
    },
}

const initialState: AssignmentTransactionState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    create: {
        error: null,
        loading: LoadingType.idle,
        createResponse: null,
    },
}

export const transactionAssignmentSlice = createSlice({
    name: "assignmentTransactions",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearErrorState(state) {
            return {
                ...state,
                error: null,
                create: {
                    ...initialState.create,
                    error: null,
                },

            };
        },
        clearCreateState(state) {
            return {
                ...state,
                create: {
                    ...initialState.create,

                },

            };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getWeeklyTransactionAssignmentsThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getWeeklyTransactionAssignmentsThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getWeeklyTransactionAssignmentsThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))

            .addCase(thunk.createTimeCardsThunk.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createTimeCardsThunk.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createTimeCardsThunk.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, createResponse: action.payload } }))


            .addDefaultCase((state) => ({ ...state }));
    }
});
export const transactionAssignmentSliceActions = transactionAssignmentSlice.actions;
export const selectState = (state: AssignmentTransactionState) => state;