import { LoadingType, THEME } from "../../../../enums";
import { ISuccessProfileQA } from "../../../../interfaces";
import { CustomButton, FormInput } from "../../../../utils";
import { cloneDeep } from "lodash";
interface Props {
  job_id: string;
  isLoading: boolean;
  loading: boolean;
  customStyle?: React.CSSProperties;
  PageLoading: (props: any) => JSX.Element;
  successProfileData: Array<ISuccessProfileQA>;
  handleSaveClick: () => void;
  setJobSProfileData: (questionsData: any) => void;
}
const JobSuccessProfile: React.FunctionComponent<Props> = (props) => {
  const {
    isLoading,
    job_id,
    PageLoading,
    successProfileData,
    loading,
    handleSaveClick,
    setJobSProfileData,
  } = props;
  return (
    <>
      {isLoading ? (
        <PageLoading />
      ) : (
        <div className="ac-sales-profile-container">
          <div className="job-sales-profile-content ">
            {successProfileData.map((question, index) => {
              return (
                <div key={index} className="ac-question">
                  <FormInput
                    label={index + 1 + ". " + question.question_value}
                    id={`answer${index}`}
                    onChange={(fieldId, value) => {
                      let questionsData = cloneDeep(successProfileData);
                      const questindex = questionsData.findIndex(
                        (h: any) => h.question_id === question.question_id
                      );
                      if (questindex >= 0) {
                        questionsData[questindex].answer = value;
                      }
                      setJobSProfileData(questionsData)
                    }}
                    value={question.answer}
                    required={false}
                    placeholder={""}
                    type={"text"}
                    multiline={true}
                  />
                </div>
              );
            })}
          </div>
          <div className="add-role-action-holder">
            <div className="add-role-action">
              <div className="btn-yes">
                <CustomButton
                  loading={loading}
                  textStyle={{ textTransform: "capitalize" }}
                  name={"Save"}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={handleSaveClick}
                  enable={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobSuccessProfile;
