import { useTranslation } from "react-i18next";
import * as MatIcons from '@material-ui/icons';
import { IApolloCredits, IContactsApolloSearch } from "../../../../interfaces/apollo_search_import";
import { useEffect, useState } from "react";
import { CustomButton, FormInput, allowNumbers, convertNumberToMoney, enforceUSFormat, formatToMoney, getPlusBtnIcon } from "../../../../utils";
import { AppError } from "../../../../interfaces";
import { LoadingType, THEME } from "../../../../enums";
import './search_people_filters.scss';
import CreatableTextSelectField from "./create_new_text";
import { DateInput } from "../../../../components/date_input/date_input";
import { getDateString } from "../../../../variables";
import { apolloAddCreditsThunk, apolloGetCreditsThunk, apolloGetIndustriesDropDown, selectApolloCreditsState, selectApolloIndustriesDDListState, selectApolloIndustriesDropDownList, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import { PlusIcon } from "../../../../icons";
import AddCreditsPopup from "../../popups/add_credits_popup/add_credits_popup";
import { Snackbar, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { apolloCreditsActions } from "../../../../redux/apollo_search_import/apollo_credits/apollo_credits_reducer";

const initialForm: IContactsApolloSearch = {
    name: "",
    email: "",
    company_name: "",
    q_keywords: "",
    q_organization_domains: "",
    organization_locations: [],
    person_titles: [],
    person_not_titles: [],
    person_seniorities: [],
    person_department_or_subdepartments: [],
    person_locations: [],
    person_not_locations: [],
    organization_not_locations: [],
    organization_num_employees_ranges: [],
    q_organization_keyword_tags: [],
    currently_using_any_of_technology_uids: [],
    currently_usinga_ll_of_technology_uids: [],
    revenue_range_min: "",
    revenue_range_max: "",
    revenue_range: {},
    q_organization_job_titles: [],
    organization_job_locations: [],
    organization_job_posted_at_range_min: "",
    organization_job_posted_at_range_max: "",
    organization_job_posted_at_range: {},
    organization_num_jobs_range_min: 0,
    organization_num_jobs_range_max: 0,
    organization_num_jobs_range: {},
    contact_email_status: [],
    per_page: 10,
    organization_founded_year_range_min: 0,
    organization_founded_year_range_max: 0,
    organization_trading_status: [],
    organization_industry_tag_ids: [],
    organization_not_industry_tag_ids: [],
    page: 1,

};
interface Props {
    expanded: boolean;
    onToggleExpanded: () => void;
    onSubmit: (doc: IContactsApolloSearch) => void;
    error: AppError | null | undefined;
    loading: LoadingType;
    handleClearFilterData: () => void;
    source: string;
    importLoading: LoadingType;
    creditsCount: number | undefined;
    mobileCreditsCount: number | undefined;
}
export const SearchPeopleFilters: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { onSubmit, handleClearFilterData, source, importLoading, creditsCount, mobileCreditsCount } = props;

    const apolloIndustriesDropDownList = useAppSelector((state) => selectApolloIndustriesDropDownList(state));
    const apolloCreditsState = useAppSelector((state) => selectApolloCreditsState(state));
    const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
    useEffect(() => {
        dispatch(apolloGetIndustriesDropDown());
        dispatch(apolloGetCreditsThunk());
    }, []);
    const [availCredits, setAvailCredits] = useState<IApolloCredits | null>();

    useEffect(() => {
        if (apolloCreditsState && apolloCreditsState.loading === LoadingType.succeeded && apolloCreditsState.response) {
            setAvailCredits(apolloCreditsState.response)
        }
    }, [apolloCreditsState, apolloCreditsState.loading])

    useEffect(() => {
        if (apolloCreditsState && apolloCreditsState.addCredits && apolloCreditsState.addCredits.loading === LoadingType.succeeded && apolloCreditsState.addCredits.response) {
            setSnackBarMessage(apolloCreditsState.addCredits.response);
            setAddCredit(false);
            dispatch(apolloGetCreditsThunk());
            dispatch(apolloCreditsActions.clearApolloAddCreditsState());
        }
    }, [apolloCreditsState.addCredits.loading]);

    useEffect(() => {
        if (importLoading === LoadingType.succeeded) {
            dispatch(apolloGetCreditsThunk());
        }
    }, [importLoading]);

    const [formState, setFormState] = useState<IContactsApolloSearch>(initialForm);
    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission if inside a form;
            handleApplyFilters();
        }
    }
    const handleApplyFilters = () => {

        const formattedMinDate = (formState.organization_job_posted_at_range_min === "" || formState.organization_job_posted_at_range_min === null) ? "" : getDateString(Number(formState.organization_job_posted_at_range_min), "yyyy-mm-dd");
        const formattedMaxDate = (formState.organization_job_posted_at_range_max === "" || formState.organization_job_posted_at_range_max === null) ? "" : getDateString(Number(formState.organization_job_posted_at_range_max), "yyyy-mm-dd");
        onSubmit({
            ...formState,
            organization_job_posted_at_range_min: formattedMinDate,
            organization_job_posted_at_range_max: formattedMaxDate
        });
        filteredCounts();
        // dispatch(apolloGetCreditsThunk());
    }

    const [filterCount, setFilterCount] = useState(0);
    function handleFieldChange(fieldId: string, value: any) {

        if (fieldId === 'person_titles' || fieldId === 'person_not_titles' || fieldId === 'person_seniorities' || fieldId === "organization_trading_status") {
            if (value !== "") {
                const titles = value.split(',');
                setFormState({ ...formState, [fieldId]: titles });
            }
            else {
                setFormState({ ...formState, [fieldId]: [] });
            }
        }
        else if (fieldId === "organization_num_jobs_range_max" || fieldId === "organization_num_jobs_range_min" || fieldId === "organization_founded_year_range_min" || fieldId === "organization_founded_year_range_max") {
            setFormState({ ...formState, [fieldId]: Number(value) });
        }
        else {
            setFormState({ ...formState, [fieldId]: value });
        }
    }



    const filteredCounts = () => {
        const filledKeysCount = Object.keys(formState).filter(key => {
            if (key !== "per_page" && key !== "page") {
                const value = formState[key];
                return (value !== null && typeof value === 'string' && value.trim() !== '') ||
                    (value !== null && typeof value === 'number' && value !== 0) ||
                    (value !== null && Array.isArray(value) && value.length > 0) ||
                    (value !== null && typeof value === 'object' && Object.keys(value).length > 0);
            }
        }).length;
        setFilterCount(filledKeysCount);
    }
    const [clearSetValue, setClearSetValue] = useState([]);
    const handleClearFilters = () => {
        setFormState(initialForm);
        setFilterCount(0);
        handleClearFilterData();
        setClearSetValue([]);
    }
    const [addCredit, setAddCredit] = useState<boolean>(false);
    const handleAddCredits = (export_credits: Number, mobile_credits: Number) => {
        dispatch(apolloAddCreditsThunk({ export_credits: export_credits, mobile_credits: mobile_credits }));
    }
    const snackbarAction = () => {
        return (
            <>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setSnackBarMessage(null)}
                >
                    <Close fontSize="small" />
                </IconButton>
            </>
        );
    };
    const isEnabled = (availCredits?.credits_available === undefined || availCredits?.credits_available <= 2) ? true : false;
    return (<div className="as-side-nav-holder">
        <div className="as-left-nav-container">
            <div className="as-cnt-imp-f-section-divider">
                {/* {source !== "companies" &&
                    ( */}
                <div className="as-cnt-credits-f-row flx-start">
                    <div className="credits-row">
                        <span>{t("available_email_export_credits")} : </span>
                        <span className="cred-num" style={{ color: (availCredits?.credits_available! >= 2) ? THEME.buttonColor13 : THEME.statusWarningColor, marginLeft: '0.1em' }}>{availCredits?.credits_available ?? creditsCount}</span>
                    </div>
                    <div className="credits-row">
                        <span>{t("available_mobile_credits")} : </span>
                        <span className="cred-num" style={{ color: (availCredits?.available_mobile_credits! >= 1) ? THEME.buttonColor13 : THEME.statusWarningColor, marginLeft: '0.1em' }}>{availCredits?.available_mobile_credits ?? mobileCreditsCount}</span>
                    </div>
                    {(availCredits?.credits_available! <= 2 || availCredits?.available_mobile_credits! <= 1) &&
                        (
                            <div className='no-creds-div'>
                                <div className='no-creds'>{t('insufficient_credits')}</div>
                                <div>
                                    <div onClick={() => setAddCredit(true)} className='add-creds-div'>
                                        {t("add_credits")}
                                    </div>

                                </div>
                            </div>

                        )}
                </div>
                {/* )} */}
                <div className="as-cnt-title-f-row  flx-start">
                    <div className="as-section-title-txt">
                        <span>{t("filters").toLocaleUpperCase()}</span>
                    </div>
                    {filterCount !== 0 && (<div className="filter-count">
                        <span>{filterCount}</span>
                        <span style={{ marginLeft: '0.2rem' }} onClick={handleClearFilters}>
                            <MatIcons.Close fontSize='small' color='inherit' />
                        </span>
                    </div>)}
                </div>
                <div className="as-filters-container">
                    <div className="as-cnt-imp-f-input-holder" style={{ 'display': source === "companies" ? 'none' : 'block' }}>
                        <FormInput
                            id={"name"}
                            onChange={(id, val) => handleFieldChange(id, val)}
                            type={"text"}
                            value={formState.name ?? ""}
                            label={t("name")}
                            placeholder={t("enter") + t("name")}
                            required={false}
                            onKeyUp={handleEnterPress}
                            multiline={false}
                            disabled={isEnabled}
                        />
                    </div>
                    <div className="as-cnt-imp-f-input-holder" style={{ 'display': source === "companies" ? 'none' : 'block' }}>
                        <FormInput
                            id={"email"}
                            onChange={(id, val) => handleFieldChange(id, val)}
                            type={"text"}
                            value={formState.email ?? ""}
                            label={t("email")}
                            placeholder={t("enter") + t("email")}
                            required={false}
                            onKeyUp={handleEnterPress}
                            multiline={false}
                            disabled={isEnabled}
                        />
                    </div>
                    <div className="as-cnt-imp-f-input-holder">
                        <FormInput
                            id={"company_name"}
                            onChange={(id, val) => handleFieldChange(id, val)}
                            type={"text"}
                            value={formState.company_name ?? ""}
                            label={t("company_name")}
                            placeholder={t("enter") + t("company_name")}
                            required={false}
                            onKeyUp={handleEnterPress}
                            multiline={false}
                            disabled={isEnabled}
                        />
                    </div>
                    <div className="as-cnt-imp-f-input-holder" style={{ 'display': (source === "talent" || source === "contacts") ? 'none' : 'block' }}>
                        <FormInput
                            id={"organization_trading_status"}
                            onChange={(id, val) => handleFieldChange(id, val)}
                            type={"text"}
                            value={formState.organization_trading_status ?? ""}
                            label={t("organization_trading_status")}
                            placeholder={t("org_trading_status_placeholder")}
                            required={false}
                            onKeyUp={handleEnterPress}
                            multiline={false}
                            disabled={isEnabled}

                        />
                    </div>

                    <div className="as-cnt-imp-f-input-holder">
                        <FormInput
                            id={"person_titles"}
                            onChange={(id, val) => handleFieldChange(id, val)}
                            type={"text"}
                            value={formState.person_titles ?? ""}
                            label={t("person_titles")}
                            placeholder={t("person_titles_placeholder")}
                            required={false}
                            onKeyUp={handleEnterPress}
                            multiline={false}
                            disabled={isEnabled}

                        />
                    </div>
                    <div className="as-cnt-imp-f-input-holder">
                        <FormInput
                            id={"person_not_titles"}
                            onChange={(id, val) => handleFieldChange(id, val)}
                            type={"text"}
                            value={formState.person_not_titles ?? ""}
                            label={t("person_not_titles")}
                            placeholder={t("person_titles_placeholder")}
                            required={false}
                            onKeyUp={handleEnterPress}
                            multiline={false}
                            disabled={isEnabled}

                        />
                    </div>
                    <div className="as-cnt-imp-f-input-holder">
                        <CustomMultiFormSelect
                            customStyle={{ width: '-webkit-fill-available' }}
                            name={"organization_industry_tag_ids"}
                            label={t("industry_keywords")}
                            placeholder={"Select"}
                            value={formState.organization_industry_tag_ids}
                            list={apolloIndustriesDropDownList.map((doc) => ({
                                label: doc.name,
                                value: doc.id,
                            }))}
                            onChange={(val) => handleFieldChange("organization_industry_tag_ids", (val))}
                            disabled={isEnabled}
                        />
                    </div>
                    <div className="as-cnt-imp-f-input-holder">
                        <CustomMultiFormSelect
                            customStyle={{ width: '-webkit-fill-available' }}
                            name={"organization_not_industry_tag_ids"}
                            label={t("industry_not_keywords")}
                            placeholder={"Select"}
                            value={formState.organization_not_industry_tag_ids}
                            list={apolloIndustriesDropDownList.map((doc) => ({
                                label: doc.name,
                                value: doc.id,
                            }))}
                            onChange={(val) => handleFieldChange("organization_not_industry_tag_ids", (val))}
                            disabled={isEnabled}
                        />
                    </div>
                    <div className="as-cnt-imp-f-input-holder" style={{ 'display': source === "companies" ? 'none' : 'block' }}>
                        <label className="label-text-field">
                            {t('contact_location')}
                        </label>
                        <CreatableTextSelectField
                            label={t("contact_location")}
                            formState={formState}
                            setFormState={setFormState}
                            fieldId={'person_locations'}
                            placeHolder={t("location_placeholder")}
                            disabled={isEnabled}
                            clearSetValue={clearSetValue}
                            onSubmit={onSubmit}
                            filteredCounts={filteredCounts}
                        />
                    </div>
                    <div className="as-cnt-imp-f-input-holder" style={{ 'display': source === "companies" ? 'none' : 'block' }}>
                        <label className="label-text-field">
                            {t('person_not_locations')}
                        </label>
                        <CreatableTextSelectField
                            label={t("person_not_locations")}
                            formState={formState}
                            setFormState={setFormState}
                            fieldId={'person_not_locations'}
                            placeHolder={t("location_placeholder")}
                            disabled={isEnabled}
                            clearSetValue={clearSetValue}
                            onSubmit={onSubmit}
                            filteredCounts={filteredCounts}
                        />
                    </div>
                    <div className="as-cnt-imp-f-input-holder" style={{ 'display': source === "talent" ? 'none' : 'block' }}>
                        <label className="label-text-field">
                            {t('organization_locations')}
                        </label>
                        <CreatableTextSelectField
                            label={t("organization_locations")}
                            formState={formState}
                            setFormState={setFormState}
                            fieldId={'organization_locations'}
                            placeHolder={t("location_placeholder")}
                            disabled={isEnabled}
                            clearSetValue={clearSetValue}
                            onSubmit={onSubmit}
                            filteredCounts={filteredCounts}
                        />
                    </div>
                    <div className="as-cnt-imp-f-input-holder" style={{ 'display': source === "talent" ? 'none' : 'block' }}>
                        <label className="label-text-field">
                            {t('organization_not_locations')}
                        </label>
                        <CreatableTextSelectField
                            label={t("organization_not_locations")}
                            formState={formState}
                            setFormState={setFormState}
                            fieldId={'organization_not_locations'}
                            placeHolder={t("location_placeholder")}
                            disabled={isEnabled}
                            clearSetValue={clearSetValue}
                            onSubmit={onSubmit}
                            filteredCounts={filteredCounts}
                        />
                    </div>
                    <div className="as-cnt-imp-f-input-holder" style={{ 'display': source === "talent" ? 'none' : 'block' }}>
                        <label className="label-text-field">
                            {t('organization_num_employees_ranges')}
                        </label>
                        <CreatableTextSelectField
                            label={t("organization_num_employees_ranges")}
                            formState={formState}
                            setFormState={setFormState}
                            fieldId={'organization_num_employees_ranges'}
                            placeHolder={t("emp_range_ex")}
                            disabled={isEnabled}
                            clearSetValue={clearSetValue}
                            onSubmit={onSubmit}
                            filteredCounts={filteredCounts}
                        />
                    </div>

                    <div className="input-row" style={{ 'display': source === "talent" ? 'none' : 'flex' }}>
                        <div className="label-div">
                            <label>{t("revenue")}</label>
                        </div>
                        <div className="as-cnt-imp-f-input-holder" >
                            <FormInput
                                id={"revenue_range_min"}
                                onChange={(id, val) => handleFieldChange(id, enforceUSFormat(val))}
                                type={"text"}
                                value={convertNumberToMoney(formState.revenue_range_min, "string") as string ?? ""}
                                label={t("min")}
                                placeholder={t("revenue_range_min")}
                                required={false}
                                onKeyUp={(event) => { formatToMoney(event); handleEnterPress(event); }}
                                multiline={false}
                                disabled={isEnabled}
                                prefix={<span>$</span>}
                                onBlur={formatToMoney}
                                onKeyDown={formatToMoney}
                            />
                        </div>
                        <div className="as-cnt-imp-f-input-holder">
                            <FormInput
                                id={"revenue_range_max"}
                                onChange={(id, val) => handleFieldChange(id, enforceUSFormat(val))}
                                type={"text"}
                                value={convertNumberToMoney(formState.revenue_range_max, "string") as string ?? ""}
                                label={t("max")}
                                placeholder={t("revenue_range_max")}
                                required={false}
                                onKeyUp={(event) => { formatToMoney(event); handleEnterPress(event); }}
                                multiline={false}
                                disabled={isEnabled}
                                prefix={<span>$</span>}
                                onBlur={formatToMoney}
                                onKeyDown={formatToMoney}

                            />
                        </div>
                    </div>

                    <div className="input-row" style={{ 'display': source === "talent" ? 'none' : 'flex' }}>
                        <div className="label-div">
                            <label>{t("job_posting_date")}</label>
                        </div>
                        <div className="as-cnt-imp-f-input-holder">
                            <DateInput
                                id={'organization_job_posted_at_range_min'}
                                onChange={(id, val) => handleFieldChange(id, val)}
                                placeholder={t("organization_job_posted_at_range_min")}
                                value={formState.organization_job_posted_at_range_min ?? ""}
                                label={t('min')}
                                setHoursToZero={true}
                                disabled={isEnabled}
                            />
                        </div>
                        <div className="as-cnt-imp-f-input-holder">
                            <DateInput
                                id={'organization_job_posted_at_range_max'}
                                onChange={(id, val) => handleFieldChange(id, val)}
                                placeholder={t("organization_job_posted_at_range_max")}
                                value={formState.organization_job_posted_at_range_max ?? ""}
                                label={t('max')}
                                setHoursToZero={true}
                                disabled={isEnabled}
                            />
                        </div>
                    </div>
                    <div className="input-row" style={{ 'display': source === "talent" ? 'none' : 'flex' }}>
                        <div className="label-div">
                            <label>{t("job_openings")}</label>
                        </div>
                        <div className="as-cnt-imp-f-input-holder" >
                            <FormInput
                                id={"organization_num_jobs_range_min"}
                                onChange={(id, val) => handleFieldChange(id, val)}
                                type={"text"}
                                value={formState.organization_num_jobs_range_min}
                                label={t("min")}
                                placeholder={t("enter") + t("organization_num_jobs_range_min")}
                                required={false}
                                onKeyUp={(e) => { allowNumbers(e); handleEnterPress(e); }}
                                multiline={false}
                                disabled={isEnabled}

                            />
                        </div>
                        <div className="as-cnt-imp-f-input-holder">
                            <FormInput
                                id={"organization_num_jobs_range_max"}
                                onChange={(id, val) => handleFieldChange(id, val)}
                                type={"text"}
                                value={formState.organization_num_jobs_range_max}
                                label={t("max")}
                                placeholder={t("enter") + t("organization_num_jobs_range_max")}
                                required={false}
                                onKeyUp={(e) => { allowNumbers(e); handleEnterPress(e); }}
                                multiline={false}
                                disabled={isEnabled}

                            />
                        </div>
                    </div>
                    <div className="input-row" style={{ 'display': (source === "talent" || source === "contacts") ? 'none' : 'flex' }}>
                        <div className="label-div">
                            <label>{t("founded_year")}</label>
                        </div>
                        <div className="as-cnt-imp-f-input-holder" >
                            <FormInput
                                id={"organization_founded_year_range_min"}
                                onChange={(id, val) => handleFieldChange(id, val)}
                                type={"text"}
                                value={formState.organization_founded_year_range_min ?? ""}
                                label={t("min")}
                                placeholder={t("enter") + t("organization_founded_year_range_min")}
                                required={false}
                                onKeyUp={(e) => { allowNumbers(e); handleEnterPress(e); }}
                                multiline={false}
                                disabled={isEnabled}
                                min={4}
                                maxLength={4}
                            />
                        </div>
                        <div className="as-cnt-imp-f-input-holder">
                            <FormInput
                                id={"organization_founded_year_range_max"}
                                onChange={(id, val) => handleFieldChange(id, val)}
                                type={"text"}
                                value={formState.organization_founded_year_range_max ?? ""}
                                label={t("max")}
                                placeholder={t("enter") + t("organization_founded_year_range_max")}
                                required={false}
                                onKeyUp={(e) => { allowNumbers(e); handleEnterPress(e); }}
                                multiline={false}
                                disabled={isEnabled}
                                min={4}
                                maxLength={4}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className="as-search-actions">
            <div className="btn-cancel">
                <CustomButton
                    loading={false}
                    textStyle={{ textTransform: "capitalize" }}
                    name={t("cancel")}
                    enable={true}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={handleClearFilters}
                />
            </div>
            <div className="btn-save">
                <CustomButton
                    loading={props.loading === LoadingType.pending}
                    textStyle={{ textTransform: "capitalize" }}
                    name={t("search")}
                    enable={!isEnabled}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={handleApplyFilters}
                />
            </div>
        </div>
        {addCredit && (
            <AddCreditsPopup
                visible={addCredit}
                onClose={() => setAddCredit(false)}
                loading={apolloCreditsState.addCredits.loading}
                handleAddCredits={handleAddCredits}
                error={apolloCreditsState.addCredits.error?.message}
            />
        )
        }
        <Snackbar
            open={snackBarMessage !== null}
            autoHideDuration={5000}
            onClose={() => { setSnackBarMessage(null); dispatch(apolloCreditsActions.clearApolloAddCreditsState()); }}
            message={snackBarMessage}
            action={snackbarAction()}
        />

    </div >
    );
}


export default SearchPeopleFilters;