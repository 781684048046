import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./assignment_list_reducer";

const select = (state: IRootState) => state.selectors.assignmentDropdown;
export const selectAssignmentDropdownListState = createSelector(select, selectState);
export const selectAssignmentDropdownList = createSelector(
    select,
    selectAll
);

export const selectassignmentDropdownListEntities = createSelector(
    select,
    selectEntities
);