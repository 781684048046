import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./education_list_reducer";

const select = (state: IRootState) => state.selectors.educationList;
export const selectEducationListState = createSelector(select, selectState);
export const selectEducationList = createSelector(
    select,
    selectAll
);
export const selectEducationListEntities = createSelector(
    select,
    selectEntities
);