import Webviewer, { WebViewerInstance } from '@pdftron/webviewer';
import { useEffect, useRef } from 'react';
import { webviewOptions } from '../../variables';
import './pdf_reader.scss';



interface Props {
    onInstanceChange: (value: WebViewerInstance) => void,
}

const PDFReader: React.FunctionComponent<Props> = (props) => {
    const {
        onInstanceChange,
    } = props;
    const viewer = useRef<HTMLDivElement | null>(null);
   

    useEffect(() => {
        if (viewer.current) {
            Webviewer(webviewOptions, viewer.current).then(onInstanceChange);
        }
    }, [])

    return (
        <div id="pdf-view" className="webviewer" ref={viewer}></div>
    )
}

export default PDFReader;