import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import SortableTable, {
  IHeaderListProps,
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import SSNHolder from "../../../../components/ssn_holder/ssn_holder";
import TableEmpty, {
  TableLoading,
  TableErrorHandler,
  TableFilterEmpty,
} from "../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../enums";
import { ONBOARDING_STATUS } from "../../../../enums/onboarding_status";
import { TickBlueIcon } from "../../../../icons";
import { IOnboardingLink } from "../../../../interfaces";
import { archiveApplicationSliceActions } from "../../../../redux/dashboard/onboarding/archive_application/archive_application_reducer";
import { resendApplicationSliceActions } from "../../../../redux/dashboard/onboarding/resend_application_link/resend_application_link_reducer";
import {
  useAppDispatch,
  useAppSelector,
  getORFAActiveList,
  selectORFAActiveDirectoryListState,
  selectORFAActiveDirectoryList,
  selectOnboardingArchiveApplicationState,
  archiveOAPPLinkThunk,
  selectOnboardingResendApplicationState,
  resendOAPPLinkThunk,
  selectProfileState,
  postSnackbarMessage,
} from "../../../../redux/store";
import {
  CustomButton,
  CustomCheckBox,
  getContactTypeIcon,
} from "../../../../utils";
import {
  getClickedOnLinkContact,
  getDateString,
  getDaysAgoWithDateAMPM,
  getPrefferedContact,
  getStatus,
} from "../../../../variables";
import ArchiveOnboardingLink from "../../popups/archive_onboarding_link/archive_onbording_link";
import ResendOnboardingLink from "../../popups/resend_onboarding_link/resend_onboarding_link";
import "./active_directory.scss";
import { ArrowForward } from "@material-ui/icons";
import { AppRoutes } from "../../../../routes";
import SearchBar from "../../../../components/search_bar";
import { talentRequestSSNActions } from "../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer";
import ViewSSNPopup from "../../../talent/popups/view_ssn/view_ssn";

interface Props extends RouteComponentProps<any> { }

const ActiveDirectory: React.FunctionComponent<Props> = (props) => {
  const { history } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { loading, error } = useAppSelector((state) =>
    selectORFAActiveDirectoryListState(state)
  );
  const activeDirectoryList = useAppSelector((state) =>
    selectORFAActiveDirectoryList(state)
  );
  const archiveState = useAppSelector((state) =>
    selectOnboardingArchiveApplicationState(state)
  );
  const resendLinkState = useAppSelector((state) =>
    selectOnboardingResendApplicationState(state)
  );
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");
  const [selectedItems, setSelectedItems] = useState<
    Map<string, { id: string; name: string }>
  >(new Map());
  const [openResendPopup, setOpenResendPopup] = useState<boolean>(false);
  const [openArchivePopup, setOpenArchivePopup] = useState<boolean>(false);
  const [showSSNPopup, setShowSSNPopup] = useState<{
    talentId: string;
    visible: boolean;
} | null>(null);
  const profileState = useAppSelector((state) => selectProfileState(state));
  const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
    }`;

  useEffect(() => {
    getList();
    return () => {
      clearState();
    };
  }, []);

  useEffect(() => {
    if (resendLinkState.loading === LoadingType.succeeded) {
      setOpenResendPopup(true);
    }
  }, [resendLinkState.loading]);

  useEffect(() => {
    if (archiveState.loading === LoadingType.succeeded) {
      setOpenArchivePopup(true);
    }
  }, [archiveState.loading]);

  function clearState() {
    dispatch(archiveApplicationSliceActions.clearState());
    dispatch(resendApplicationSliceActions.clearState());
  }

  const getList = () => dispatch(getORFAActiveList());

  const handleMarkItem = (value: IOnboardingLink) => {
    const newSet = new Map(selectedItems);
    if (selectedItems.has(value.id)) {
      newSet.delete(value.id);
    } else {
      newSet.set(value.id, { id: value.id, name: value.name });
    }
    setSelectedItems(newSet);
  };

  const handleMarkAllItem = () => {
    const newSet = new Map<string, { id: string; name: string }>();
    if (selectedItems.size === activeDirectoryList.length) {
      setSelectedItems(newSet);
    } else {
      activeDirectoryList.forEach((doc) =>
        newSet.set(doc.id, { id: doc.id, name: doc.name })
      );
      setSelectedItems(newSet);
    }
  };

  const handleArchiveList = () => {
    if (selectedItems.size !== 0) {
      dispatch(archiveOAPPLinkThunk(Array.from(selectedItems.keys())));
    }
  };

  const handleResendLinkList = () => {
    if (selectedItems.size !== 0) {
      dispatch(resendOAPPLinkThunk(Array.from(selectedItems.keys())));
    }
  };

  const handlePopUpOpen = () => {
    setOpenResendPopup(true);
    setOpenArchivePopup(true);
  };

  const toggle = () => {
    setOpenResendPopup(false);
    setOpenArchivePopup(false);
  };

  const handlePopUpClose = () => {
    setOpenResendPopup(false);
    setOpenArchivePopup(false);
    clearState();
  };

  const SelectAllCheckBox = () => {
    return (
      <CustomCheckBox
        name={""}
        title={""}
        checked={selectedItems.size === activeDirectoryList.length}
        onClick={handleMarkAllItem}
      />
    );
  };
  function handleOnStartReview(talentId: string, talentName: string) {
    history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${talentId}/onboarding-and-docs`,
      state: {
        id: talentId,
        name: talentName,
      },
    });
  }
  const tableHeader: IHeaderListProps[] = [
    { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
    { title: "name", code: "name" },
    { title: "applied_for", code: "applied_for" },
    { title: "status", code: "status" },
    { title: "address", code: "address" },
    { title: "city", code: "city" },
    { title: "state", code: "state" },
    { title: "zip_code", code: "zip_code" },
    { title: "ssn_hash", code: "ssn" },
    { title: "last_contact", code: "last_contact" },
    { title: "preferred_contact", code: "preferred_contact" },
    { title: "onboarding_link_last_sent", code: "onboarding_link_sent_date" },
    { title: "clicked_on_link", code: "clicked_on_link" },
    { title: "recruiter", code: "recruiter_name" },
    { title: "", code: "" },
  ];

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  const getFilteredList = () => {
    let list: IOnboardingLink[] = [...activeDirectoryList];
    if (sortedField != null) {
      list.sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] != undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    // return list;
    return list.filter((doc) => {
      const str = search.trim().toLowerCase();
      const nameFilter = doc.name
        ? doc.name.trim().toLowerCase().includes(str)
        : false;
      const status = doc.status
        ? doc.status.trim().toLowerCase().includes(str)
        : false;
      const ssn = doc.last_4_digits_of_ssn
        ? doc.last_4_digits_of_ssn.trim().toLowerCase().includes(str)
        : false;
      const appliedFor = doc.applied_for
        ? doc.applied_for.trim().toLowerCase().includes(str)
        : false;
      const address = doc.address
        ? doc.address.trim().toLowerCase().includes(str)
        : false;
      const city = doc.city
        ? doc.city.trim().toLowerCase().includes(str)
        : false;
      const state = doc.state
        ? doc.state.trim().toLowerCase().includes(str)
        : false;
      const zipCode = doc.zip_code
        ? doc.zip_code.trim().toLowerCase().includes(str)
        : false;
      const recruiter = doc.recruiter_name
        ? doc.recruiter_name.trim().toLowerCase().includes(str)
        : false;
      return nameFilter || status || ssn || appliedFor || address || city || state || zipCode || recruiter
    });
  };

  function getFlexNumber(value: number): React.CSSProperties {
    if (value === 0) return { flex: 0.5, width: 30 }; /// checkbox
    if (value === 1) return { flex: 3 }; /// name
    if (value === 2) return { flex: 3 }; /// applied for
    if (value === 3) return { flex: 3 }; /// status
    if (value === 4) return { flex: 2 }; /// address
    if (value === 5) return { flex: 2 }; /// city
    if (value === 6) return { flex: 1 }; /// state
    if (value === 7) return { flex: 1.2 }; /// zipcode
    if (value === 8) return { flex: 2 }; /// ssn
    if (value === 9) return { flex: 2 }; /// last contact
    if (value === 10) return { flex: 1 }; /// preferred contact
    if (value === 11) return { flex: 2 }; /// onboaarding
    if (value === 12) return { flex: 1 }; /// clicked on
    if (value === 13) return { flex: 3 }; /// recruiter
    if (value === 14) return { flex: 3 }; /// start review button

    return { flex: 1 };
  }

  function handleUserSelect(value: IOnboardingLink) {
    props.history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
      state: {
        id: value.talent_id,
        name:
          value.name ?? "",
      },
    });
    return;
  }

  function getSSNInfo(value: string, id: string) {
    return (
        <SSNHolder
            value={value}
            iconColor={THEME.tableRowTextColor}
            onClick={() => handleOnSSNView(id)}
            showFormat={false}
        />
    );
}

function handleOnSSNView(id: string) {
    dispatch(talentRequestSSNActions.initialiseRequestSSN(id));
    if (profileState.profile && profileState.profile.show_ssn) {
        setShowSSNPopup({ visible: true, talentId: id });
    } else {
        dispatch(postSnackbarMessage(t("ssn_access_is_disabled")));
    }
}

  const getTable = () => {
    if (loading === LoadingType.pending) return <TableLoading />;
    if (error != null && activeDirectoryList.length === 0) {
      return <TableErrorHandler error={error} onRefresh={getList} />;
    }
    if (activeDirectoryList.length === 0) {
      return (
        <TableEmpty title={t("No Applications found")} onClick={getList} />
      );
    }
    if (getFilteredList().length === 0) {
      return <TableFilterEmpty title={t("No Applications found")} />;
    }
    if (getFilteredList().length !== 0) {
      return (
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList().map((doc) => {
            return (
              <tr key={doc.id}>
                <TableData customStyle={{ ...getFlexNumber(0) }}>
                  <CustomCheckBox
                    name={""}
                    title={""}
                    checked={selectedItems.has(doc.id)}
                    onClick={() => handleMarkItem(doc)}
                  />
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(1) }}
                  isButton={true}
                  onClick={() => handleUserSelect(doc)}
                >
                  <span>{doc?.name}</span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(2) }}>
                  <span>{doc.applied_for}</span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(3) }}>
                  <span>{getStatus(doc.status, t)}</span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(4) }}>
                  <span>{doc.address}</span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(5) }}>
                  <span>{doc.city}</span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(6) }}>
                  <span>{doc.state}</span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(7) }}>
                  <span>{doc.zip_code}</span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(8) }}>
                {doc.ssn ? getSSNInfo(doc.ssn, doc.talent_id) : ""}
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(9) }}>
                  <span>
                    {doc.last_contact
                      ? getDaysAgoWithDateAMPM(t, parseInt(doc.last_contact))
                      : ""}
                  </span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(10) }}>
                  <span>
                    {getPrefferedContact(doc.preferred_contact_method)}
                  </span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(11) }}>
                  <span>{getDateString(doc.onboarding_link_sent_date)}</span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(12) }}>
                  <span>{getClickedOnLinkContact(doc.clicked_on_link)}</span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(13) }}>
                  <span>{doc.recruiter_name}</span>
                </TableData>
                <TableData customStyle={{ ...getFlexNumber(14) }}>
                  <span className="start-review">
                    <CustomButton
                      leftIcon={
                        <div className="arrow-forward-icon">
                          <ArrowForward
                            width={"100%"}
                            height={"100%"}
                            style={{ color: "white" }}
                          />
                        </div>
                      }
                      loading={false}
                      textStyle={{
                        textTransform: "capitalize",
                        fontSize: "0.625vw",
                        color: "white",
                      }}
                      name={t("start_review")}
                      enable={true}
                      customStyle={{minWidth: "2.5vw"}}
                      onClick={() => handleOnStartReview(doc.talent_id, doc.name)}
                    />
                  </span>
                </TableData>
              </tr>
            );
          })}
        </SortableTable>
      );
    }
  };

  return (
    <div className="orfa-ad-container">
      <div className="orfa-ad-table-header">
        <div className="orfa-ad-table-header-options">
          <div className="total-count">
            <span>
              {`${t("total_count")}: `}
              <span className="total-count-number">
                {getFilteredList().length}
              </span>
            </span>
          </div>
        </div>
        <div className="orfa-ad-actions">
          {/* <div className="orfa-ad-add-user-btn" style={{ marginRight: '1em' }}>
                        <CustomButton
                            loading={resendLinkState.loading === LoadingType.pending}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("resend")}
                            enable={selectedItems.size !== 0}
                            backgroundColor={selectedItems.size !== 0 ? THEME.statusActiveColor : THEME.optionDisableColor}
                            onClick={handleResendLinkList}
                        />
                    </div> */}
          <div className="orfa-ad-add-user-btn">
            <div className="apps-ud-search">
              <SearchBar
                value={search}
                onChange={(value) => setSearch(value)}
                onSearch={() => { }}
              />
            </div>
            <CustomButton
              loading={archiveState.loading === LoadingType.pending}
              textStyle={{ textTransform: "capitalize" }}
              name={t("archive")}
              enable={selectedItems.size !== 0}
              backgroundColor={
                selectedItems.size !== 0
                  ? THEME.defaultHighLightColor
                  : THEME.optionDisableColor
              }
              onClick={handleArchiveList}
            />
          </div>
        </div>
      </div>
      <div className="orfa-ad-table">{getTable()}</div>
      {/* <div>
                {openResendPopup &&
                    <Portal>
                        <ResendOnboardingLink
                            visible={openResendPopup}
                            currentLoginUserName={currentLoginUserName}
                            onboardingCandidate={Array.from(selectedItems.values()).map(doc => doc.name).join(',')}
                            onClose={handlePopUpClose}
                            handlePopUpOpen={handlePopUpOpen}
                            onDissmiss={toggle}
                        />
                    </Portal>
                }
            </div> */}
      <div>
        {openArchivePopup && (
          <Portal>
            <ArchiveOnboardingLink
              visible={openArchivePopup}
              currentLoginUserName={currentLoginUserName}
              onboardingCandidate={Array.from(selectedItems.values())
                .map((doc) => doc.name)
                .join(",")}
              onClose={handlePopUpClose}
              handlePopUpOpen={handlePopUpOpen}
              onDissmiss={toggle}
            />
          </Portal>
        )}
      </div>
      {showSSNPopup && (
                <Portal>
                    <ViewSSNPopup
                        visible={showSSNPopup.visible}
                        title={t("social_security_number")}
                        talentId={showSSNPopup.talentId}
                        onClose={() => setShowSSNPopup(null)}
                        onDissmiss={() => setShowSSNPopup(null)}
                        onSuccessClose={handleOnSuccessSSNClose}
                    />
                </Portal>
            )}
    </div>
  );
  function handleOnSuccessSSNClose() {
    setShowSSNPopup(null);
  }
};

export default ActiveDirectory;
