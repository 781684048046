import { useEffect, useState } from "react";
import { LoadingType, THEME } from "../../../../../enums";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
  selectManageJobUpdateState,
  selectJobDetailsByIdState,
  selectJobsListState,
  updateJob,
  getJobById,
  selectProfileState,
} from "../../../../../redux/store";
import {
  CustomButton,
  FormInput,
  SpinnerScreen,
  getStatusFromICreateState,
} from "../../../../../utils";
import "./job_success_profile.scss";
import { cloneDeep } from "lodash";
import { AddCircleOutline } from "@material-ui/icons";
import { IJobOrderDetail, ISuccessProfileQA } from "../../../../../interfaces";
import { manageJobActions } from "../../../../../redux/job_order/universal_directory/manage_job/manage_job_reducer";
import StateChangeHandler from "../../../../../components/state_change_handler/state_change_handler";
import CurveTabNavigation from "../../../../../components/curve_tab_navigation/curve_tab_navigation";
import { BriefcaseIcon, SkillsSummaryIcon } from "../../../../../icons";
import JobSuccessProfile from "../../job_success_profile/job_success_profile";
import JobPosting from "../../job_posting/job_posting";

interface Props extends RouteComponentProps<any> {}
const PageLoading = (props) => {
  return (
    <div className="table-loading">
      <SpinnerScreen />
    </div>
  );
};
const JobSuccessProfilePage: React.FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { history, location, match } = props;
  const { params } = match;
  const jobState = useAppSelector((state) =>
    selectJobDetailsByIdState(params.id)(state)
  );
  const jobUpdateState = useAppSelector((state) =>
    selectManageJobUpdateState(state)
  );
  const [loader, setIsLoader] = useState(true);
  const [jobId, setJobId]: any = useState("");
  const [tabIndex, setTabIndex] = useState<number>(0);

  const [successProfileData, setJobSuccessProfileData] = useState<
    Array<ISuccessProfileQA>
  >([]);
  const { profile } = useAppSelector((state) => selectProfileState(state));

  useEffect(() => {
    if (params && params.id) dispatch(getJobById({ job_id: params.id }));
  }, []);

  useEffect(() => {
    if (
      jobState.data &&
      jobState.data.success_profile_question_answers &&
      jobState.data.success_profile_question_answers.length > 0
    ) {
      setJobSuccessProfileData(jobState.data.success_profile_question_answers);
    }
  }, [jobState.data]);


  useEffect(() => {
    if (jobUpdateState.loading == LoadingType.succeeded) {
      dispatch(
        postSnackbarMessage(getStatusFromICreateState(jobUpdateState.response))
      );

      dispatch(manageJobActions.clearManageJobState(["photo", "resume"]));
    }
    return () => {};
  }, [jobUpdateState.loading]);

  const handleSaveClick = () => {
    if (jobState.data) {
      let payload: IJobOrderDetail = {
        ...jobState.data,
        success_profile_question_answers: successProfileData,
      };
      dispatch(updateJob(payload));
    }
  };
  function setJobSProfileData(questionsData) {
    setJobSuccessProfileData(questionsData);
  }

  function getTabList() {
    return [
      {
        title: t("success_profile").toUpperCase(),
        icon: SkillsSummaryIcon,
        content: (
          <JobSuccessProfile
            job_id={params.id}
            // customStyle={{
            //   overflow: 'auto',
            //   height: '20.3125vw',
            // }}
            isLoading={jobState.loading === LoadingType.pending}
            PageLoading={PageLoading}
            successProfileData={successProfileData}
            loading={jobUpdateState.loading === LoadingType.pending}
            handleSaveClick={handleSaveClick}
            setJobSProfileData={setJobSProfileData}
          />
        ),
      },
      {
        title: t("job_posting").toUpperCase(),
        icon: BriefcaseIcon,
        content: (
          <JobPosting
            currentLoginUserName={profile?.first_name}
            // successProfileData={jobState?.data?.success_profile_question_answers}
            job_id={params.id}

          />
        ),
      },
    ];
  }
  return (
    <>
      <StateChangeHandler
        loading={jobState.loading}
        error={jobState.error}
        onRefresh={() => {}}
      >
        <div className="jdp-container">
          {/* <div className="jdp-left-panel"></div> */}
          <div className="jdp-right-panel">
            <CurveTabNavigation
              tabs={getTabList()}
              selected={tabIndex}
              onChange={(value) => setTabIndex(value)}
              customStyle={{ height: "fit-content" }}
            />
          </div>
        </div>
      </StateChangeHandler>
    </>
  );
};

export default JobSuccessProfilePage;
