import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import {
    selectAll,
    selectEntities,
    selectState,
} from "./archived_directory_list_reducer";

const select = (state: IRootState) => state.dashboard.onboarding.readyForApproval.archivedDirectoryList;
export const selectORFAArchivedDirectoryListState = createSelector(
    select,
    selectState
);
export const selectORFAArchivedDirectoryList = createSelector(
    select,
    selectAll
);
export const selectORFAArchivedDirectoryEntities = createSelector(
    select,
    selectEntities
);
