import { createAsyncThunk } from "@reduxjs/toolkit";
import { SalesMarketingAPI } from "../../../apis/sales_marketing_api";
import { AppError } from "../../../interfaces";
import { MarketingCampaign } from "../../../interfaces/marketing_campaign";
import { catchRequestError } from "../../../utils";
import { getMarketingCampaigns } from "../marketing_activity/marketing_campaign_thunk";

/// Create user
export const createMarketingCampaign = createAsyncThunk<
  string,
  MarketingCampaign,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@manage_marketing_campaign/create",
  async (payload, thunkAPI) => {
    try {
      const res = await SalesMarketingAPI.createMarketingCampaign(payload);
      thunkAPI.dispatch(getMarketingCampaigns());
      return res.data as string;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const updateMarketingCampaign = createAsyncThunk<
    string,
    MarketingCampaign,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@manage_marketing_campaign/update',
    async (payload, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.updateMarketingCampaign(payload);
            thunkAPI.dispatch(getMarketingCampaigns());
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
