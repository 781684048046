import { ChevronLeft } from "@material-ui/icons";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { THEME } from "../../../../enums";
import { MarkIcon } from "../../../../icons";
import "./online_application_holder_mobile.scss";

interface Props {
  history?: any;
  location?: any;
  hideSubTitle?: boolean;
  hideNav?: boolean;
  children: any;

}
const OnlineApplicationHolderMobile: React.FunctionComponent<Props> = (
  props
) => {
  const { t } = useTranslation();
  return (
    <div className="oah-mobile-holder-container">

      <div className="oah-mobile-content-view">
        {/* <div className="header-nav">
          <div className="prev">
            <ChevronLeft fontSize="large" color="inherit" />
          </div>
        </div> */}
        {props.hideNav && (
          <div className="header">
            <div className="page-info">
              <div className="icon-holder">
                <MarkIcon
                  width={"100%"}
                  height={"100%"}
                  style={{ color: THEME.defaultHighLightColor }}
                />
              </div>
              <div className="title-holder">
                <div className="title">
                  <span>{t("online_application")}</span>
                </div>
                <div className="sub-title">
                  <span>

                    {props.hideSubTitle
                      ? ""
                      : t("please_fallow_the_steps_to_finish_online_application")}
                  </span>
                </div>
              </div>
            </div>
            <div className="agency-branding">{/* <AlphaOmegaStaffing /> */}</div>
          </div>
        )}
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
};

export default OnlineApplicationHolderMobile;
