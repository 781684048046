import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './left_side_nav.scss';
import { AppRoutes } from '../../routes';
import { useTranslation } from 'react-i18next';
import {
    AdminCenterIcon,
    AssignIcon,
    BackOfficeIcon,
    ClientportalIcon,
    CompaniesIcon,
    ContactsIcon,
    DashboardIcon,
    EmplportalIcon,
    Jobsicon,
    ReportCenterIcon,
    RiskmanagIcon,
    SalesmarketIcon,
    TalentIcon
} from '../../icons';
import { ROLE, THEME } from '../../enums';
import * as MatIcons from '@material-ui/icons';
import { ProfileRole } from '../../interfaces';
import { IS_DEVELOPMENT } from '../../variables';


interface Props {
    roles: { [key: string]: ProfileRole },
    expanded: boolean,
    onToggleExpanded: () => void,
    domain: string | null;
}

const Sidenavbar: React.FunctionComponent<Props> = (props) => {
    const { expanded, onToggleExpanded, domain } = props;
    const { t } = useTranslation();
    const allowedDomainsList = ["kinisosoftware.com",
        "dynamicglobal.net",
        "kineticwhiteboard.com",
        "felixsolutions.ai",
        "felix.ai"];
    const sideNavOptions = [
        {
            route: AppRoutes.dashboardPage,
            icon: <DashboardIcon width={'100%'} height={'100%'} style={{ color: THEME.dasboardOptionTextColor }} />,
            css: 'dashboard',
            title: 'dashboard',
        },
        {
            route: AppRoutes.salesAndMarketingPage,
            icon: <SalesmarketIcon width={'100%'} height={'100%'} style={{ color: THEME.stateMarketingOptionTextColor }} />,
            css: 'sales-marketing',
            title: 'sales_and_marketing',
        },
        {
            route: AppRoutes.companiesPage,
            icon: <CompaniesIcon width={'100%'} height={'100%'} style={{ color: THEME.companiesOptionTextColor }} />,
            css: 'companies',
            title: 'companies',
        },
        {
            route: AppRoutes.contactsPage,
            icon: <ContactsIcon width={'100%'} height={'100%'} style={{ color: THEME.contactsOptionTextColor }} />,
            css: 'contacts',
            title: 'contacts',
        },
        {
            route: AppRoutes.talentPage,
            icon: <TalentIcon width={'100%'} height={'100%'} style={{ color: THEME.talentOptionTextColor }} />,
            css: 'talent',
            title: 'talent',
        },
        {
            route: AppRoutes.jobsPage,
            icon: <Jobsicon width={'100%'} height={'100%'} style={{ color: THEME.jobsOptionTextColor }} />,
            css: 'jobs',
            title: 'jobs',
        },
        {
            route: AppRoutes.assignmentsPage,
            icon: <AssignIcon width={'100%'} height={'100%'} style={{ color: THEME.assignmentsOptionTextColor }} />,
            css: 'assignments',
            title: 'assignments',
        },
        {
            route: AppRoutes.reportCenterPage,
            icon: <ReportCenterIcon width={'100%'} height={'100%'} style={{ color: THEME.reportCenterOptionTextColor }} />,
            css: 'report-center',
            title: 'report_center',
        },
        ...IS_DEVELOPMENT ?
            [
                {
                    route: AppRoutes.backOfficePage,
                    icon: <BackOfficeIcon width={'100%'} height={'100%'} style={{ color: THEME.backOfficeOptionTextColor }} />,
                    css: 'back-office',
                    title: 'back_office',
                },
            ] :
            (domain && allowedDomainsList.includes(domain)
                ?
                ([
                    {
                        route: AppRoutes.backOfficePage,
                        icon: <BackOfficeIcon width={'100%'} height={'100%'} style={{ color: THEME.backOfficeOptionTextColor }} />,
                        css: 'back-office',
                        title: 'back_office',
                    },
                ])
                :
                [])
        ,
        {
            route: AppRoutes.riskManagementPage,
            icon: <RiskmanagIcon width={'100%'} height={'100%'} style={{ color: THEME.riskManagementOptionTextColor }} />,
            css: 'risk-management',
            title: 'risk_management',
        },
        {
            route: AppRoutes.employeePortalPage,
            icon: <EmplportalIcon width={'100%'} height={'100%'} style={{ color: THEME.employeePortalOptionTextColor }} />,
            css: 'employee-portal',
            title: 'employee_portal',
        },
        ...IS_DEVELOPMENT ? [

            {
                route: AppRoutes.clientPortalPage,
                icon: <ClientportalIcon width={'100%'} height={'100%'} style={{ color: THEME.clientPortalOptionTextColor }} />,
                css: 'client-portal',
                title: 'client_portal',
            }
        ] : []
    ];

    const adminCenter = {
        route: AppRoutes.adminCenterPage,
        icon: <AdminCenterIcon width={'100%'} height={'100%'} style={{ color: THEME.adminCenterOptionTextColor }} />,
        css: 'admin-center',
        title: 'admin_center',
    };
    const [menu, setMenu] = useState(sideNavOptions);

    useEffect(() => {
        const isAnAdmin = props.roles[ROLE.administrator] != null || props.roles[ROLE.superUser] != null || props.roles[ROLE.branchManager] != null;
        if (isAnAdmin) {
            setMenu([...sideNavOptions, adminCenter]);
        }
        return () => {

        }
    }, [props]);




    return (
        <div className="side-nav-holder">
            <ul className="left-nav-container">
                {menu.map((doc, index) => {
                    return (
                        <li key={index} className={`nav-item-container ${doc.css}`} title={t(doc.title)}>
                            <NavLink activeClassName='is-active' to={doc.route}>
                                <div className="nav-item-holder">
                                    <div className="nav-item-icon">
                                        <div className="icon">{doc.icon}</div>
                                    </div>
                                    {expanded && <div className="nav-item-title">
                                        <span className={`left-heading ${doc.css}-txt`}>{t(doc.title)}</span>
                                    </div>}
                                </div>
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
            <div className={`nav-exp-item-container`} >
                <button type='button' className="expand-tile" onClick={onToggleExpanded} title={expanded ? t('minimize') : t('expand')}>
                    <div className={`expand-icon ${expanded ? 'rotate' : ''}`}>
                        <MatIcons.DoubleArrow fontSize={"inherit"} color={"inherit"} />
                    </div>
                </button>
            </div>
        </div>

    )
}

export default Sidenavbar;
