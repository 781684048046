import { createSlice } from "@reduxjs/toolkit"
import { LoadingType } from "../../../enums"
import { AppError } from "../../../interfaces"
import * as thunk from './view_paycheck_invoice_thunk'

export interface PaycheckInvoiceState {
    paycheck: {
        error?: AppError | null,
        loading: LoadingType,
        response: string,
    }
    invoice: {
        error?: AppError | null,
        loading: LoadingType,
        response: string,
    }
}

const initialState: PaycheckInvoiceState = {
    paycheck: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    invoice: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
}

export const paycheckInvoiceSlice = createSlice({
    name: "paycheckInvoice",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearInoviceState(state) {
            state.invoice = { ...initialState.invoice, error: null };
        },
        clearPaycheckState(state) {
            state.paycheck = { ...initialState.paycheck, error: null };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getInvoicePDFURLThunk.pending, (state, action) => ({ ...state, invoice: { ...state.invoice, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getInvoicePDFURLThunk.rejected, (state, action) => ({ ...state, invoice: { ...state.invoice, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.getInvoicePDFURLThunk.fulfilled, (state, action) => ({ ...state, invoice: { ...state.invoice, loading: LoadingType.succeeded, response: action.payload, error: null } }))


            .addCase(thunk.getPaycheckPDFURLThunk.pending, (state, action) => ({ ...state, paycheck: { ...state.paycheck, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getPaycheckPDFURLThunk.rejected, (state, action) => ({ ...state, paycheck: { ...state.paycheck, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.getPaycheckPDFURLThunk.fulfilled, (state, action) => ({ ...state, paycheck: { ...state.paycheck, loading: LoadingType.succeeded, response: action.payload, error: null } }))

            .addDefaultCase((state) => ({ ...state }));
    }
});
export const paycheckInvoiceSliceActions = paycheckInvoiceSlice.actions;
export const selectState = (state: PaycheckInvoiceState) => state;