import "./allcompanies.scss";
import "./contacts.scss";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import { AppRoutes } from "../../routes/routes";
import { getContactsNavHeaderProps } from "../../routes/route_helpers";
import UniversalDirectoryPage from "./pages/universal_directory/universal_directory";
import { ContactSalesProfile } from "./pages/contact_info/sales_profile/contact_sales_profile";
import { ContactServiceProfile } from "./pages/contact_info/service_profile/contact_service_profile";
import CompanyJobOrdersPage from '../companies/pages/company_job_orders/company_job_orders_page';
import ContactsAssignmentPage from "./pages/contact_info/assignment/contacts_assignment_page";
import ContactDashboard from "./pages/contact_info/dashboard/dashboard";
import {
  useAppDispatch,
  getCertificationsList,
  getCredentialsList,
  getEducationList,
  getRequiredEquipmentList,
  getSkillSetList,
  getCompetitorsList,
  getRoleUsersList
} from "../../redux/store";
import { GetCloseAction } from "../../utils";
import DirectoryUploadStatusDirectoryPage from "../components/directory_upload_status/directory_upload_status";

const ContactsTabNav: FC<RouteComponentProps<any>> = (
  props: RouteComponentProps
) => {
  const { history, location } = props;
  const { t } = useTranslation();
  return (
    <TabNavigation
      activeRoute={location.pathname}
      tabList={[
        { title: "universal_directory", route: AppRoutes.contactsUniversalDirectory, },
        { title: 'contacts_upload_status', route: AppRoutes.contactsUploadStatusDirectory },
      ]}
      onChange={(value) => history.push(value)}
    >
      <Switch>
        <Route exact path={AppRoutes.contactsUniversalDirectory} component={UniversalDirectoryPage} />
        <Route exact path={AppRoutes.contactsUploadStatusDirectory} render={(props) => <DirectoryUploadStatusDirectoryPage {...props} importType="Contact" headerTitle={t("contacts_upload_status")}/>} />
        <Route
          path={"/"}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: AppRoutes.contactsUniversalDirectory,
                state: { from: location },
              }}
            />
          )}
        />
      </Switch>
    </TabNavigation>
  );
};

const ContactDetailsTabNav = (props: RouteComponentProps<any>) => {

  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSkillSetList());
    dispatch(getCertificationsList());
    dispatch(getCredentialsList());
    dispatch(getEducationList());
    dispatch(getRequiredEquipmentList());
    dispatch(getRoleUsersList());
    dispatch(getCompetitorsList());

    return () => { }
  }, [])

  return (
    <TabNavigation
      activeRoute={location.pathname}
      tabList={[
        { title: "dashboard", route: `${AppRoutes.contactsDetailsPage}/${params.id}/dashboard`, },
        { title: "sales_profile", route: `${AppRoutes.contactsDetailsPage}/${params.id}/sales-profile`, },
        { title: "service_profile", route: `${AppRoutes.contactsDetailsPage}/${params.id}/service-profile`, },
        { title: "job_orders", route: `${AppRoutes.contactsDetailsPage}/${params.id}/job-orders`, },
        { title: "assignments", route: `${AppRoutes.contactsDetailsPage}/${params.id}/assignments`, },
      ]}
      onChange={(value) =>
        history.push({ pathname: value, state: location.state })
      }
      actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.contactsPage)}/>}
    >
      <Switch>
        <Route exact path={`${AppRoutes.contactsDetailsPage}/:id/dashboard`} component={ContactDashboard} />
        <Route exact path={`${AppRoutes.contactsDetailsPage}/:id/sales-profile`} component={ContactSalesProfile} />
        <Route exact path={`${AppRoutes.contactsDetailsPage}/:id/service-profile`} component={ContactServiceProfile} />
        <Route exact path={`${AppRoutes.contactsDetailsPage}/:id/job-orders`} component={CompanyJobOrdersPage} />
        <Route exact path={`${AppRoutes.contactsDetailsPage}/:id/assignments`} component={ContactsAssignmentPage} />
        <Route path={"/"} render={({ location }) => (
          <Redirect
            to={{
              pathname: `${location.pathname}/dashboard`,
              state: { ...(location.state as any) },
            }}
          />
        )}
        />
      </Switch>
    </TabNavigation>
  );
};

export const ContactsNavigation = (props: RouteComponentProps<any>) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  const headerOptions = getContactsNavHeaderProps(location, match, t);

  return (
      <PageNavHeader data={headerOptions} history={history} location={location}>
        <Switch>
          <Route path={`${AppRoutes.contactsDetailsPage}/:id`} component={ContactDetailsTabNav} />
          <Route path={"/"} component={ContactsTabNav} />
        </Switch>
      </PageNavHeader>
  );
};

class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
