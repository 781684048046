import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { MyProfileAPI } from "../../../../apis/my_profile_api";
import { MyProfileTeams } from "../../../../interfaces/my_profile";


/// get Teams
export const getMyProfileTeamsDetails = createAsyncThunk<
    MyProfileTeams[],
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@myprofile/teams/get',
    async (_, thunkAPI) => {
        try {
            const res = await MyProfileAPI.getMyTeamsDetails();
            return res.data as MyProfileTeams[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

// get Team Members
export const getMyProfileTeamMembersDetailsById = createAsyncThunk<
    MyProfileTeams,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@myprofile/team_members/get',
    async (payload, thunkAPI) => {
        try {
            const res = await MyProfileAPI.getMyTeamsMembersDetailsById(payload);
            return res.data as MyProfileTeams;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const createMyProfileTeamMembersDetailsById = createAsyncThunk<
    string,
    {teamId: string, teamMemberIds: Array<string>},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@myprofile/team_members/patch',
    async (payload, thunkAPI) => {
        try {
            const res = await MyProfileAPI.createMyTeamsMembersDetailsById(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


