import { createAsyncThunk } from "@reduxjs/toolkit";
import { firebaseLogin, TalentAPI } from "../../../../../apis";
import { AppError, LoginCredentials } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";


export const getTalentSSNThunk = createAsyncThunk<
    { ssn: string },
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/dashboard/ssn/get',
    async (talentId, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalentSSN(talentId);
            if (typeof res.data === 'string') return { ssn: '' };
            return res.data as { ssn: string };
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const validateUserBeforeSSNThunk = createAsyncThunk<
    string,
    {talent_id: string, credentials: LoginCredentials},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/dashboard/ssn/validate/user',
    async (payload, thunkAPI) => {
        try {
            const tokenInfo = await firebaseLogin(payload.credentials);
            thunkAPI.dispatch(getTalentSSNThunk(payload.talent_id));
            return tokenInfo.idToken;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

