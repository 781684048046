import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import CurveTabNavigation from "../../../../../components/curve_tab_navigation/curve_tab_navigation";
import { TableErrorHandler } from "../../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../../enums";
import {
  EditIcon,
  EngagementIcon,
  RatingIcon,
  SkillsSummaryIcon,
  StaffIcon,
} from "../../../../../icons";
import { Assignment, UpdateAssignment } from "../../../../../interfaces";
import InDevelopment from "../../../../../others/in_development";
import {
  getEndAssignmentReasons,
  getServiceCalls,
  getWorkCompCodes,
  getWorkCompConfigDropdown,
  getWorkCompConfigList,
  selectEndAssignmentReasonList,
  selectEndAssignmentReasonState,
  selectServiceCallList,
  selectServiceCallState,
  selectWorkCompCodeList,
  selectWorkCompCodeState,
  selectWorkCompConfigDropdown,
  selectWorkCompConfigDropdownState,
  selectWorkCompConfigList,
  selectWorkCompConfigListState,
} from "../../../../../redux/admin_center";
import { manageAssignmentActions } from "../../../../../redux/assignments/universal_directory/manage_assignment/manage_assignment_reducer";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
  selectProfileState,
  selectManageAssignmentState,
  selectAssignmentDetailsByIdState,
  getAssignmentById,
} from "../../../../../redux/store";
import {
  convertNumberToMoney,
  CustomButton,
  getStatusFromICreateState,
  getUSFormat,
  SpinnerScreen,
} from "../../../../../utils";
import { AssignmentDetailsSection } from "./assignment_details_section";
import { PayrollBilling } from "./payroll_billing/payroll_billing";
import { AssignmentSummary } from "./summary/assignment_summary";
import "./assignment_dashboard.scss";
import { updateAssignment } from "../../../../../redux/assignments/universal_directory/manage_assignment/manage_assignment_thunk";
import { CreateOption } from "../../../../jobs/pages/job-skills/createOption";
import { Portal } from "react-portal";
import AssignmentStaffDetails from "./staffing/staffing";
import EngagementDetails from "../../../../components/engagementDetails";
import RatingsTab from "../../../../components/ratingsDetails";
import { CreateAssignmentOptionPopup } from "../../../popups/create_option/create_option";
import { RemoveAssignmentOptionPopup } from "../../../popups/remove_option/remove_option";
import { assignmentDetailsSliceActions } from "../../../../../redux/assignments/details/dashboard/assignment_details/assignment_details_reducer";
import { AppRoutes } from "../../../../../routes";
import { convertStringTimeToTimestamp, convertTimestampToStringTime, extractTimeFromTimestamp } from "../../../../../variables";

const floatFields = [
  "regular_pay_rate",
  "regular_bill_rate",
  "overtime_pay_rate",
  "overtime_bill_rate",
  "double_time_pay_rate",
  "double_time_bill_rate",
  "triple_time_pay_rate",
  "triple_time_bill_rate",
];

const getInitialState = (value?: Assignment | null) => {
  if (value) {
    return {
      ...value,
      regular_bill_rate: convertNumberToMoney(
        value.regular_bill_rate,
        "string"
      ) as string,
      regular_pay_rate: convertNumberToMoney(
        value.regular_pay_rate,
        "string"
      ) as string,
      overtime_bill_rate: convertNumberToMoney(
        value.overtime_bill_rate,
        "string"
      ) as string,
      overtime_pay_rate: convertNumberToMoney(
        value.overtime_pay_rate,
        "string"
      ) as string,
      double_time_bill_rate: convertNumberToMoney(
        value.double_time_bill_rate,
        "string"
      ) as string,
      double_time_pay_rate: convertNumberToMoney(
        value.double_time_pay_rate,
        "string"
      ) as string,
      shift_start: extractTimeFromTimestamp(value?.shift_start!),
      shift_end: extractTimeFromTimestamp(value?.shift_end!),
      work_schedule: {
        FRIDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.FRIDAY_END_TIME!),
        FRIDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.FRIDAY_START_TIME!),
        MONDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.MONDAY_END_TIME!),
        MONDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.MONDAY_START_TIME!),
        SATURDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.SATURDAY_END_TIME!),
        SATURDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.SATURDAY_START_TIME!),
        SUNDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.SUNDAY_END_TIME!),
        SUNDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.SUNDAY_START_TIME!),
        THURSDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.THURSDAY_END_TIME!),
        THURSDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.THURSDAY_START_TIME!),
        TUESDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.TUESDAY_END_TIME!),
        TUESDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.TUESDAY_START_TIME!),
        WEDNESDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.WEDNESDAY_END_TIME!),
        WEDNESDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.WEDNESDAY_START_TIME!),
        FRIDAY: value.work_schedule?.FRIDAY!,
        MONDAY: value.work_schedule?.MONDAY!,
        SATURDAY: value.work_schedule?.SATURDAY!,
        SUNDAY: value.work_schedule?.SUNDAY!,
        THURSDAY: value.work_schedule?.THURSDAY!,
        TUESDAY: value.work_schedule?.TUESDAY!,
        WEDNESDAY: value.work_schedule?.WEDNESDAY!,

      }
    };
  }

  return new UpdateAssignment();
};

export const AssignmentDashboard: FC<RouteComponentProps<any>> = (
  props: RouteComponentProps<any>
) => {
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showSave, setShowSave] = useState<boolean>(false);
  const { history, location } = props;

  const { params } = props.match;
  const profile = useAppSelector((state) => selectProfileState(state));
  const [removeOption, setRemoveOption] = useState<{
    visible: boolean;
    type: string;
    value: { id: string; value: string };
  } | null>(null);
  const [addOption, setAddOption] = useState<{
    visible: boolean;
    type: string;
  } | null>(null);
  const assignmentState = useAppSelector((state) =>
    selectAssignmentDetailsByIdState(params.id)(state)
  );
  const serviceCallState = useAppSelector((state) =>
    selectServiceCallState(state)
  );
  const serviceCallList = useAppSelector((state) =>
    selectServiceCallList(state)
  );

  const reasonListState = useAppSelector((state) =>
    selectEndAssignmentReasonState(state)
  );
  const reasonList = useAppSelector((state) =>
    selectEndAssignmentReasonList(state)
  );
  const manageAssignmentState = useAppSelector((state) =>
    selectManageAssignmentState(state)
  );
  const updateState = manageAssignmentState.update;
  // const workCompCodeState = useAppSelector((state) =>
  //   selectWorkCompCodeState(state)
  // );
  // const workCompCodeList = useAppSelector((state) =>
  //   selectWorkCompCodeList(state)
  // );

  const WorkCompConfigDropdownState = useAppSelector((state) => selectWorkCompConfigDropdownState(state))
  const WorkCompConfigDropdown = useAppSelector((state) => selectWorkCompConfigDropdown(state))

  const { t } = useTranslation();
  const initialState: Assignment = {
    agency_id: "",
    assignment_end_reason_id: "",
    branch_id: "",
    company_id: "",
    company_name: "",
    contact_id: null,
    contact_name: null,
    department_id: "",
    department_name: "",
    double_time_bill_rate: null,
    double_time_pay_rate: null,
    end_date: null,
    id: "",
    is_deleted: false,
    job_order_id: "",
    job_title: "",
    mark_up_factor: 0,
    overtime_bill_rate: null,
    overtime_pay_rate: null,
    performance_rating: 0,
    regular_bill_rate: null,
    regular_pay_rate: null,
    service_calls: [],
    shift_break: "",
    shift_end: null,
    shift_hours: null,
    shift_name: "",
    shift_start: null,
    start_date: null,
    status: "",
    engagement_rating: 0,
    engagement_level: "",
    talent_id: "",
    talent_name: "",
    triple_time_bill_rate: null,
    triple_time_pay_rate: null,
    wc_code: null,
    wc_code_id: null,
    work_schedule: {
      FRIDAY: false,
      FRIDAY_END_TIME: 0,
      FRIDAY_START_TIME: 0,
      MONDAY: false,
      MONDAY_END_TIME: 0,
      MONDAY_START_TIME: 0,
      SATURDAY: false,
      SATURDAY_END_TIME: 0,
      SATURDAY_START_TIME: 0,
      SUNDAY: false,
      SUNDAY_END_TIME: 0,
      SUNDAY_START_TIME: 0,
      THURSDAY: false,
      THURSDAY_END_TIME: 0,
      THURSDAY_START_TIME: 0,
      TUESDAY: false,
      TUESDAY_END_TIME: 0,
      TUESDAY_START_TIME: 0,
      WEDNESDAY: false,
      WEDNESDAY_END_TIME: 0,
      WEDNESDAY_START_TIME: 0,
    },
    talent_profile_url: null,
    account_managers: [],
    recruiters: [],
  };
  const [assignment, setAssignment] = useState<UpdateAssignment>(
    getInitialState(assignmentState.data ?? initialState)
  );

  const updateAssignmentProp = (
    key: string,
    value: string | Number | boolean
  ) => {
    const newassignment = { ...assignment };
    newassignment[key] = value;
    setAssignment(newassignment);
  };

  const updateServiceCall = (key: string, value: boolean) => {
    const newassignment = { ...assignment };
    if (!assignment.service_calls) {
      newassignment.service_calls = [];
    }
    let newServiceCalls = [...newassignment.service_calls];
    if (value) {
      newServiceCalls.push(key);
    } else {
      newServiceCalls = newServiceCalls.filter((item) => item !== key);
    }

    newassignment.service_calls = newServiceCalls;
    setAssignment(newassignment);
  };

  const updateWorkSchedule = (key: string, value: boolean) => {
    let newWorkSchedule = { ...assignment.work_schedule };
    newWorkSchedule[key] = value;
    setAssignment({ ...assignment, work_schedule: newWorkSchedule });
  };

  const updateWorkScheduleTime = (key: string, value: number) => {
    let newWorkSchedule = { ...assignment.work_schedule };
    newWorkSchedule[key] = value;
    setAssignment({ ...assignment, work_schedule: newWorkSchedule });
  };

  useEffect(() => {
    fetchAssignmentDetails();
    // dispatch(getWorkCompCodes());
    dispatch(getWorkCompConfigDropdown());
    return () => {
      // dispatch(assignmentDetailsSliceActions. clearAssignmentDetailsState());
    };
  }, []);

  useEffect(() => {
    if (updateState.loading == LoadingType.succeeded) {
      dispatch(
        postSnackbarMessage(getStatusFromICreateState(updateState.response))
      );
      if (params && params.id) {
        dispatch(getAssignmentById({ assignment_id: params.id }));
      }
      dispatch(manageAssignmentActions.clearManageAssignmentState([]));
      setAddOption(null);
      setShowSave(false);
    }
  }, [updateState.loading]);

  useEffect(() => {
    const newAssignment = assignmentState.data ?? new Assignment();
    const amountData = Object.fromEntries(
      floatFields.map((field) => [
        field,
        newAssignment[field]
          ? getUSFormat(newAssignment[field], false)
          : "0.00",
      ])
    );
    updateForm({ ...newAssignment, ...amountData, ...assignmentState?.data! });
  }, [assignmentState.data]);

  const fetchAssignmentDetails = () => {
    if (params && params.id) {
      dispatch(getAssignmentById({ assignment_id: params.id }));
    }
    dispatch(getEndAssignmentReasons());
    dispatch(getServiceCalls());
  };

  function updateForm(value: Assignment) {
    setAssignment({
      ...value,
      regular_bill_rate: convertNumberToMoney(
        value.regular_bill_rate,
        "string"
      ) as string,
      regular_pay_rate: convertNumberToMoney(
        value.regular_pay_rate,
        "string"
      ) as string,
      overtime_bill_rate: convertNumberToMoney(
        value.overtime_bill_rate,
        "string"
      ) as string,
      overtime_pay_rate: convertNumberToMoney(
        value.overtime_pay_rate,
        "string"
      ) as string,
      double_time_bill_rate: convertNumberToMoney(
        value.double_time_bill_rate,
        "string"
      ) as string,
      double_time_pay_rate: convertNumberToMoney(
        value.double_time_pay_rate,
        "string"
      ) as string,
      shift_start: extractTimeFromTimestamp(value?.shift_start!),
      shift_end: extractTimeFromTimestamp(value?.shift_end!),
      work_schedule: {
        FRIDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.FRIDAY_END_TIME!),
        FRIDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.FRIDAY_START_TIME!),
        MONDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.MONDAY_END_TIME!),
        MONDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.MONDAY_START_TIME!),
        SATURDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.SATURDAY_END_TIME!),
        SATURDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.SATURDAY_START_TIME!),
        SUNDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.SUNDAY_END_TIME!),
        SUNDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.SUNDAY_START_TIME!),
        THURSDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.THURSDAY_END_TIME!),
        THURSDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.THURSDAY_START_TIME!),
        TUESDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.TUESDAY_END_TIME!),
        TUESDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.TUESDAY_START_TIME!),
        WEDNESDAY_END_TIME: convertTimestampToStringTime(value.work_schedule?.WEDNESDAY_END_TIME!),
        WEDNESDAY_START_TIME: convertTimestampToStringTime(value.work_schedule?.WEDNESDAY_START_TIME!),
        FRIDAY: value.work_schedule?.FRIDAY!,
        MONDAY: value.work_schedule?.MONDAY!,
        SATURDAY: value.work_schedule?.SATURDAY!,
        SUNDAY: value.work_schedule?.SUNDAY!,
        THURSDAY: value.work_schedule?.THURSDAY!,
        TUESDAY: value.work_schedule?.TUESDAY!,
        WEDNESDAY: value.work_schedule?.WEDNESDAY!,
      }
    });
  }

  const DetailsLoading = () => {
    return (
      <div className="ac-um-usp-loading">
        <SpinnerScreen />
      </div>
    );
  };

  const getTabList = () => {
    return [
      {
        title: t("assignment_summary").toUpperCase(),
        icon: SkillsSummaryIcon,
        content: (
          <AssignmentSummary
            assignmentInfo={assignment ?? assignmentState.data}
            setAssignment={setAssignment}
            endReason={reasonList}
            serviceCalls={serviceCallList}
            updateAssignmentProp={updateAssignmentProp}
            updateServiceCall={updateServiceCall}
            updateWorkSchedule={updateWorkSchedule}
            updateWorkScheduleTime={updateWorkScheduleTime}
            disabled={!showSave}
            workCompCodeList={WorkCompConfigDropdown}
          />
        ),
      },
      {
        title: t("engagement_details").toUpperCase(),
        icon: EngagementIcon,
        content: (
          <EngagementDetails
            id={params.id}
            source={"ASSIGNMENTS"}
            onSuccessCreate={fetchAssignmentDetails}
          />
        ),
      },
      {
        title: t("staffing_representatives", {
          value: profile.profile?.agency_name,
        }).toUpperCase(),
        icon: StaffIcon,
        content: (
          <AssignmentStaffDetails
            assignment_id={params.id}
            onAddOption={handleAddOption}
            onRemoveOption={handleRemoveOption}
          />
        ),
      },
      {
        title: t("ratings").toUpperCase(),
        icon: RatingIcon,
        content: <RatingsTab id={params.id} source={"assignment"} />,
      },
    ];
  };
  /// Add option
  function handleAddOption(type: string) {
    setAddOption({ visible: true, type: type });
  }
  /// close add option dialog
  function handleCloseAddDialog() {
    setAddOption(null);
  }

  /// Remove Option
  function handleRemoveOption(
    type: string,
    value: { id: string; value: string }
  ) {
    setRemoveOption({ visible: true, type: type, value: value });
  }

  /// Add on success add option
  function handleOnSuccessAddDialog() {
    if (addOption) {
      dispatch(
        assignmentDetailsSliceActions.clearAssignmentDetailsAddOptionError({
          id: params.id,
          type: addOption.type,
        })
      );
      setAddOption(null);
      fetchAssignmentDetails()
    }
  }

  const showloading =
    assignmentState.loading === LoadingType.pending ||
    reasonListState.loading === LoadingType.pending ||
    serviceCallState.loading === LoadingType.pending ||
    WorkCompConfigDropdownState.loading === LoadingType.pending;

  const handleUpdateAssignment = () => {
    let data: Assignment = {
      ...assignment,
      regular_bill_rate: convertNumberToMoney(
        assignment.regular_bill_rate,
        "number"
      ) as number,
      regular_pay_rate: convertNumberToMoney(
        assignment.regular_pay_rate,
        "number"
      ) as number,
      overtime_bill_rate: convertNumberToMoney(
        assignment.overtime_bill_rate,
        "number"
      ) as number,
      overtime_pay_rate: convertNumberToMoney(
        assignment.overtime_pay_rate,
        "number"
      ) as number,
      double_time_bill_rate: convertNumberToMoney(
        assignment.double_time_bill_rate,
        "number"
      ) as number,
      double_time_pay_rate: convertNumberToMoney(
        assignment.double_time_pay_rate,
        "number"
      ) as number,
      shift_start: convertStringTimeToTimestamp(assignment?.shift_start!)! / 1000,
      shift_end: convertStringTimeToTimestamp(assignment?.shift_end!)! / 1000,
      work_schedule: {
        FRIDAY_END_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.FRIDAY_END_TIME!),
        FRIDAY_START_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.FRIDAY_START_TIME!),
        MONDAY_END_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.MONDAY_END_TIME!),
        MONDAY_START_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.MONDAY_START_TIME!),
        SATURDAY_END_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.SATURDAY_END_TIME!),
        SATURDAY_START_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.SATURDAY_START_TIME!),
        SUNDAY_END_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.SUNDAY_END_TIME!),
        SUNDAY_START_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.SUNDAY_START_TIME!),
        THURSDAY_END_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.THURSDAY_END_TIME!),
        THURSDAY_START_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.THURSDAY_START_TIME!),
        TUESDAY_END_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.TUESDAY_END_TIME!),
        TUESDAY_START_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.TUESDAY_START_TIME!),
        WEDNESDAY_END_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.WEDNESDAY_END_TIME!),
        WEDNESDAY_START_TIME: convertStringTimeToTimestamp(assignment?.work_schedule.WEDNESDAY_START_TIME!),
        FRIDAY: assignment.work_schedule?.FRIDAY!,
        MONDAY: assignment.work_schedule?.MONDAY!,
        SATURDAY: assignment.work_schedule?.SATURDAY!,
        SUNDAY: assignment.work_schedule?.SUNDAY!,
        THURSDAY: assignment.work_schedule?.THURSDAY!,
        TUESDAY: assignment.work_schedule?.TUESDAY!,
        WEDNESDAY: assignment.work_schedule?.WEDNESDAY!,

      }
    };
    let start_date: any = data["start_date"];
    let end_date: any = data["end_date"];
    if (typeof start_date === "string") {
      start_date = new Date(start_date);
      start_date = start_date / 1000;
    }
    if (typeof end_date === "string") {
      end_date = new Date(end_date);
      end_date = end_date / 1000;
    }
    data = { ...data, start_date: start_date, end_date: end_date };
    for (let field of floatFields)
      data = {
        ...data,
        [field]: data[field] && data[field] !== "." ? Number(data[field]) : 0,
      };
    dispatch(updateAssignment(data));
  };

  const getEditAction = () => {
    if (showSave) {
      return null;
    }
    return (
      <button
        className="icon-btn jdp-edit-icon"
        onClick={() => setShowSave(true)}
      >
        <EditIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
      </button>
    );
  };
  function handleActionPress(value: string) {
    if (value === "send_message_to_talent" && assignmentState.data !== null) {
      history.push({
        pathname: `${AppRoutes.talentDetailsPage}/${assignmentState.data.talent_id}/dashboard`,
        state: {
          redirectFrom: "assignment",
          id: assignmentState.data.talent_id,
          name: assignmentState.data.talent_name
        }
      })
    }
  }
  return (
    <div className="ac-um-usp-container assignment-dashboard">
      {showloading && <DetailsLoading />}
      {assignmentState.error != null && (
        <TableErrorHandler
          error={assignmentState.error}
          onRefresh={fetchAssignmentDetails}
        />
      )}
      {assignmentState.loading === LoadingType.succeeded &&
        assignmentState.error === null && (
          <div className="ac-um-usp-content">
            <div className="ac-um-usp-profile-container">
              <AssignmentDetailsSection
                assignmentInfo={assignment}
                onActionClick={handleActionPress}
              />
            </div>
            <div className="ac-um-usp-settings-content">
              <CurveTabNavigation
                tabs={getTabList()}
                selected={tabIndex}
                onChange={(value) => setTabIndex(value)}
                actions={getEditAction()}
              />
              {/* <PayrollBilling
                assignmentInfo={
                  assignment ?? new Assignment() ?? new UpdateAssignment()
                }
                updateProp={updateAssignmentProp}
                wcCodes={workCompCodeList}
                disabled={!showSave}
              /> */}
              {tabIndex === 0 && showSave && (
                <div className="ad-actions">
                  <div className="btn-cancel">
                    <CustomButton
                      textStyle={{ textTransform: "capitalize" }}
                      name={t("cancel")}
                      enable
                      backgroundColor={THEME.defaultHighLightColor}
                      onClick={() => {
                        setShowSave(false);
                        if (assignmentState.data) {
                          updateForm({ ...assignmentState.data });
                        }
                      }}
                      customStyle={{ marginRight: "10px" }}
                    />
                  </div>
                  <CustomButton
                    loading={updateState.loading === LoadingType.pending}
                    textStyle={{ textTransform: "capitalize" }}
                    name={t("save")}
                    enable
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={handleUpdateAssignment}
                  />
                </div>
              )}
            </div>
            {addOption && (
              <Portal>
                <CreateAssignmentOptionPopup
                  data={{
                    assignment_id: params.id,
                    assignment: assignmentState.data,
                  }}
                  visible={addOption.visible}
                  type={addOption.type}
                  onClose={handleCloseAddDialog}
                  onDissmiss={handleCloseAddDialog}
                  onSuccessClose={handleOnSuccessAddDialog}
                />
              </Portal>
            )}
            {removeOption && (
              <Portal>
                <RemoveAssignmentOptionPopup
                  data={removeOption.value}
                  assignment_id={params.id}
                  type={removeOption.type}
                  visible={removeOption.visible}
                  onClose={handleCloseRemoveDialog}
                  onDissmiss={handleCloseRemoveDialog}
                  onSuccessClose={handleOnSuccessRemoveDialog}
                />
              </Portal>
            )}
          </div>
        )}
    </div>
  );

  /// close add option dialog
  function handleCloseRemoveDialog() {
    setRemoveOption(null);
  }

  /// Add on success remove option
  function handleOnSuccessRemoveDialog() {
    if (removeOption) {
      dispatch(
        assignmentDetailsSliceActions.clearAssignmentDetailsRemoveOptionError({
          id: params.id,
          type: removeOption?.type,
        })
      );
      setRemoveOption(null);
    }
  }
};
