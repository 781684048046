import { createSlice } from "@reduxjs/toolkit";
import { AppError, IFileUplodState, ITalentW2, IURLState } from "../../../../interfaces";
import * as thunk from "./talent_w2_thunk";
import { LoadingType } from "../../../../enums";

export interface talentW2State {
    error?: AppError | null,
    loading: LoadingType,
    response: ITalentW2[] | null,
    uploadURL: IURLState,
    uploadFile: IFileUplodState,
}

const initialState: talentW2State = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    uploadURL: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    uploadFile: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}

export const talentW2Slice = createSlice({
    name: 'talentW2',
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...state, ...initialState };
        },
        clearUploadUrlError(state) {
            return { ...state, uploadURL: { ...state.uploadURL, error: null } };
        },
        clearUploadFileError(state) {
            return { ...state, uploadFile: { ...state.uploadFile, error: null } };
        },
        clearUploadUrl(state) {
            return { ...state, uploadURL: { ...initialState.uploadURL } };
        },
        clearUploadFile(state) {
            return { ...state, uploadFile: { ...initialState.uploadFile } };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getEmployeeW2Thunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getEmployeeW2Thunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getEmployeeW2Thunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))

            .addCase(thunk.getW2UploadURLThunk.pending, (state, action) => ({ ...state, uploadURL: { ...state.uploadURL, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getW2UploadURLThunk.rejected, (state, action) => ({ ...state, uploadURL: { ...state.uploadURL, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getW2UploadURLThunk.fulfilled, (state, action) => ({ ...state, uploadURL: { ...state.uploadURL, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.uploadW2FileThunk.pending, (state, action) => ({ ...state, uploadFile: { ...state.uploadFile, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadW2FileThunk.rejected, (state, action) => ({ ...state, uploadFile: { ...state.uploadFile, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.uploadW2FileThunk.fulfilled, (state, action) => ({ ...state, uploadFile: { ...state.uploadFile, loading: LoadingType.succeeded, error: null, response: action.payload } }))


            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentW2SliceActions = talentW2Slice.actions;
export const selectState = (state: talentW2State) => state;



