import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../../apis";
import { ITalentOBW4I9Data, AppError } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";

export const updateTalentW4I9Details = createAsyncThunk<
    string,
    ITalentOBW4I9Data,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboard-docs/recruiter-review/w4-i9-data/patch',
    async (doc, thunkAPI) => {
        try {
            const res = await TalentAPI.updateW4I9DocsData(doc);
            return res.data
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);