

import { createSlice } from '@reduxjs/toolkit';
import { LoadingType } from '../../../../../enums';
import { AppError, ITimeCards } from '../../../../../interfaces';
import * as thunk from './time_cards_list_thunk';

export interface TimecardsListState {
    error?: AppError | null;
    loading: LoadingType;
    response: ITimeCards | null;
    documentDelete: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const initialState: TimecardsListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    documentDelete: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
}

export const timecardsListSlice = createSlice({
    name: "timecardsList",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearDeleteState(state) {
            state.documentDelete = { ...initialState.documentDelete, error: null };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTimeCardsListThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getTimeCardsListThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getTimeCardsListThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))

            .addCase(thunk.deleteTimeCardsUploadDocument.pending, (state, action) => ({ ...state, documentDelete: { ...state.documentDelete, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deleteTimeCardsUploadDocument.rejected, (state, action) => ({ ...state, documentDelete: { ...state.documentDelete, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deleteTimeCardsUploadDocument.fulfilled, (state, action) => ({ ...state, documentDelete: { ...state.documentDelete, loading: LoadingType.succeeded, response: action.payload, error: null } }))

            .addDefaultCase((state) => ({ ...state }));
    }
});
export const timecardsListSliceActions = timecardsListSlice.actions;
export const selectState = (state: TimecardsListState) => state;