import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import './payroll_details.scss'
import ContributionHistorySection from "./contribution_history/contribution_history_section";
import DeductionHistorySection from "./deduction_history/deduction_history_section";
import PaycheckHistorySection from "./paycheck_history/paycheck_history_section";
import { getEPPayrollDetailsList, getPaycheckRevHistTHunk, getVacationHistoryThunk, selectCheckRevHistState, selectEPPayrollDetailsState, selectVacationHistoryState, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { LoadingType } from "../../../../enums";
import VacationHistorySection from "./vacation_history/vacation_history";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { ExpandMoreIcon } from "../../../../icons";
import PaycheckReverseHistorySection from "./check_rev_history/check_rev_history";
import { IPCRevHistory } from "../../../../interfaces";

interface Props {
    talentId: string;
    history: RouteComponentProps["history"];
}

export const PayrollDetails: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { history, talentId } = props;
    const dispatch = useAppDispatch();
    const [expanded, setExpanded] = useState<boolean | string>('false');

    const handleExpand = (panel: string) => {
        setExpanded((expanded === panel ? false : panel));
    };
    const epPayrollDetailsState = useAppSelector((state) => selectEPPayrollDetailsState(state));
    const vacationHistState = useAppSelector((state) => selectVacationHistoryState(state));
    const checkRevHistState = useAppSelector((state) => selectCheckRevHistState(state));
    const contributionHistoryList = []

    const [checkListData, setCheckListData] = useState<IPCRevHistory | null>(null);


    useEffect(() => {
        dispatch(getEPPayrollDetailsList({ ePortal: true, talent_id: talentId }));
        dispatch(getVacationHistoryThunk({ ePortal: true, talent_id: talentId }));
        dispatch(getPaycheckRevHistTHunk({ ePortal: true, talent_id: talentId }));
        handleExpand('panel1')
    }, []);
    useEffect(() => {
        if (checkRevHistState.loading === LoadingType.succeeded && checkRevHistState.response) {
            setCheckListData(checkRevHistState.response)
        }
    }, [checkRevHistState.loading, checkRevHistState.response]);

    return (
        <div className="ep-pd-container">
            <Accordion className="accordion-container"
                expanded={expanded === 'panel1'} onChange={() => handleExpand('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accordion-title"
                    aria-controls="acc-paycheck"
                >
                    <span>{t("paycheck_history").toUpperCase()}</span>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                    <PaycheckHistorySection
                        paycheckHistoryList={epPayrollDetailsState?.response?.paychecks!}
                        loading={epPayrollDetailsState.loading === LoadingType.pending}
                        ePortal={true}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion className="accordion-container"
                expanded={expanded === 'panel2'} onChange={() => handleExpand('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accordion-title"
                    aria-controls="acc-vacation"
                >
                    <span>{t("vacation_holiday_history").toUpperCase()}</span>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                    <VacationHistorySection
                        vacationHistoryList={vacationHistState?.response!}
                        loading={vacationHistState.loading === LoadingType.pending}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion className="accordion-container"
                expanded={expanded === 'panel3'} onChange={() => handleExpand('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accordion-title"
                    aria-controls="acc-vacation"
                >
                    <span>{t("deduction_history").toUpperCase()}</span>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                    <DeductionHistorySection
                        deductionHistoryList={epPayrollDetailsState?.response?.deductions!}
                        loading={epPayrollDetailsState.loading === LoadingType.pending}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion className="accordion-container"
                expanded={expanded === 'panel4'} onChange={() => handleExpand('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accordion-title"
                    aria-controls="acc-vacation"
                >
                    <span>{t("contribution_history").toUpperCase()}</span>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                    <ContributionHistorySection
                        contributionHistoryList={contributionHistoryList}
                        loading={epPayrollDetailsState.loading === LoadingType.pending}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion className="accordion-container"
                expanded={expanded === 'panel5'} onChange={() => handleExpand('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accordion-title"
                    aria-controls="acc-vacation"
                >
                    <span>{t("reversal_bal_history").toUpperCase()}</span>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                    <PaycheckReverseHistorySection
                        checkRevHistList={checkListData}
                        loading={checkRevHistState.loading === LoadingType.pending}
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

