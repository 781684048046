import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiError from '../../../../components/api_error';
import { DateInput } from '../../../../components/date_input/date_input';
import { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import DocSavedText from '../../../../components/doc_saved_text/doc_saved_text';
import { LoadingType, THEME } from '../../../../enums';
import { MarkTalentIcon } from '../../../../icons';
import { addTalentAvailableDatesThunk, getTalentAvailableDatesThunk, selectSingleTalentAvailableDatesStateById, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { talentAvailableDatesActions } from '../../../../redux/talent/details/dashboard/talent_availability/talent_availability_reducer';
import { CustomButton, CustomRadioButton, SpinnerScreen } from '../../../../utils';
import { convertDateToTimeStamp, getDateFromTimeStamp, getDateString, getDatesBetween } from '../../../../variables';

const formOptions = {
    available_from: {
        field: "available_from",
        type: "date",
        label: 'from',
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: ''
    },
    available_to: {
        field: "available_to",
        type: "date",
        label: 'to',
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: ''
    },
}

enum OptionType {
    today = "today",
    range = "range",
    none = "none",
}

interface Props {
    title: string,
    talentId: string,
    talentName: string,
    onClose: () => void,
    onSuccessClose: () => void,
}

const SelectAvailabilityOptions: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [optionType, setOptionType] = useState<OptionType | null>(null);
    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>({
        available_from: 0,
        available_to: 0,
    })
    const availabilityState = useAppSelector(state => selectSingleTalentAvailableDatesStateById(props.talentId)(state));
    const createState = availabilityState.add;

    useEffect(() => {
        getTalentAvailabilityDates();
        return () => { }
    }, [])

    useEffect(() => {
        if (optionType === OptionType.none) handleWrapperClose(props.onClose);
        return () => { }
    }, [optionType])

    function handleFieldChange(fieldId, value) {
        setFormState({ ...formState, [fieldId]: value });
    }

    function getTalentAvailabilityDates() {
        dispatch(getTalentAvailableDatesThunk(props.talentId));
    }

    function getLoading() {
        return availabilityState.loading === LoadingType.pending;
    }

    function handleWrapperClose(action?: () => void) {
        if (createState.loading == LoadingType.succeeded) {
            props.onSuccessClose();

        } else {
            if (action) action();
        }
    }

    function handleClearError() {
        dispatch(talentAvailableDatesActions.clearSingleTalentAvailableDatesStateError(props.talentId));
    }



    return (
        <div className="dshb-mrk-tlnt-op-f-container">
            <div className="dshb-mrk-tlnt-op-f-header">
                <FormDialogTilteHeader
                    title={props.title}
                    titleIcon={<MarkTalentIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                />
            </div>
            <div className="dshb-mrk-tlnt-op-f-content">
                {getUI()}
                {createState.error && <div className={"error-section"}>
                    <ApiError message={createState.error.message} onClose={handleClearError} />
                </div>}
            </div>
            {getActions()}
        </div>
    )


    function getActions() {
        if (createState.loading === LoadingType.succeeded) {
            return (
                <div className="dshb-mrk-tlnt-op-f-actions">

                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('ok')}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={props.onSuccessClose}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className="dshb-mrk-tlnt-op-f-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('cancel')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={props.onSuccessClose}
                    />
                </div>

                <div className="btn-save">
                    <CustomButton
                        loading={createState.loading === LoadingType.pending}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('next')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={handleNext}
                    />
                </div>
            </div>
        )
    }



    function getUI() {
        const optionsList = [
            {
                lable: (
                    <div className="option-label-txt">
                        <span>{t('mark')}</span>
                        <span className='name-txt'>{props.talentName}</span>
                        <span>{t('available_for_work_today')}</span>
                    </div>
                ),
                type: OptionType.today,
            },
            {
                lable: (
                    <div className="option-label-txt">
                        <span>{t('mark')}</span>
                        <span className='name-txt'>{props.talentName}</span>
                        <span>{t('available_for_work_within_a_date_range')}</span>
                    </div>
                ),
                type: OptionType.range,
            },
            {
                lable: (
                    <div className="option-label-txt">
                        <span>{t('i_dnt_want_to_mark')}</span>
                        <span className='name-txt'>{props.talentName}</span>
                        <span>{t('available_for_work')}</span>
                    </div>
                ),
                type: OptionType.none,
            },
        ];
        if (getLoading()) {
            return (<div className="loading-spinner" ><SpinnerScreen /></div>)
        }

        if (createState.loading === LoadingType.succeeded) {
            return (
                <div className="mark-success-holder">
                    <DocSavedText>
                        {
                            optionType && optionType === OptionType.range
                                ? <div><span>{t('great_has_been_marked_availble_for_within_the_range', { name: props.talentName })}&nbsp;{getDateString(formState.available_from)}&nbsp;{t('to')}&nbsp;{getDateString(formState.available_to)}</span></div>
                                : <span>{t('great_has_been_marked_availble_for_today', { name: props.talentName })}</span>
                        }
                    </DocSavedText>
                </div>
            )
        }

        if (optionType && optionType === OptionType.range) {
            return (
                <div className="date-range-holder">
                    <div key={formOptions.available_from.field} className={"date-range-input-holder"}>
                        <DateInput
                            validations={formValidators}
                            id={formOptions.available_from.field}
                            onChange={handleFieldChange}
                            secure={formOptions.available_from.secure}
                            required={formOptions.available_from.required}
                            placeholder={formOptions.available_from.placeholder}
                            value={formState[formOptions.available_from.field]}
                            label={t(formOptions.available_from.label)}
                            disablePast={true}
                            minDate={new Date()}
                            setHoursToZero={true}
                        />
                    </div>
                    <div className="divider">
                        <div className="line"></div>
                    </div>
                    <div key={formOptions.available_to.field} className={"date-range-input-holder"}>
                        <DateInput
                            validations={formValidators}
                            id={formOptions.available_to.field}
                            onChange={handleFieldChange}
                            secure={formOptions.available_to.secure}
                            required={formOptions.available_to.required}
                            placeholder={formOptions.available_to.placeholder}
                            value={formState[formOptions.available_to.field]}
                            label={t(formOptions.available_to.label)}
                            disablePast={true}
                            minDate={formState.available_from ? getDateFromTimeStamp(formState.available_from) : new Date()}
                            setHoursToZero={true}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className="options-list-holder">
                {optionsList.map((doc, index) => {
                    return (
                        <div key={index} className="option-holder">
                            <CustomRadioButton
                                selected={optionType != null && optionType === doc.type}
                                highlightColor={THEME.defaultHighLightColor}
                                label={doc.lable}
                                onClick={(value) => setOptionType(value ? doc.type : null)}
                                labelStyle={{ fontSize: '0.8721611721611722vw', color: THEME.defaultHighLightColor }}
                            />
                        </div>
                    )
                })}

            </div>
        )
    }

    function formValidators(value: { text: any, field: string, }) {
        switch (value.field) {
            case formOptions.available_from.field: {
                if (value.text == null) return t('validators.required');
                return null;
            };
            case formOptions.available_to.field: {
                if (value.text == null) return t('validators.required');
                return null;
            };

            default: {
                return null;
            }
        }
    }

    function handleNext() {
        const today = convertDateToTimeStamp(new Date(), true);
        if (optionType && optionType === OptionType.today) {
            markDatesAvailable([today]);
            return;
        }
        if (optionType && optionType === OptionType.range) {
            if (formState.available_from === formState.available_to) {
                markDatesAvailable([today]);
                return;
            } else {
                const dates = getDatesBetween(formState.available_from, formState.available_to, true);
                markDatesAvailable(dates);
                return;
            }

        }

        if (optionType && optionType === OptionType.none) {
            handleWrapperClose(props.onClose);
            return;
        }


    }

    function markDatesAvailable(dates: number[]) {
        const payload = {
            talent_id: props.talentId,
            available_dates: [...availabilityState.data, ...dates]
        };
        dispatch(addTalentAvailableDatesThunk(payload));
    }
}

export default SelectAvailabilityOptions;