import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRCPayrollDeductions } from "../../../../interfaces/report_center_payroll_tax";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { IReportCenterBOPayload } from "../../../../interfaces/report_center";

export const getPayrollDeductionsList = createAsyncThunk<
    IRCPayrollDeductions,
    IReportCenterBOPayload,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/payroll_deductions/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getPayrollDeductions(payload);
            return res.data as IRCPayrollDeductions;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });