import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getHolidayReportThunk, postSnackbarMessage, selectHolidayReportState, selectProfileState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import DatePicker from "react-datepicker";
import { CalenderIcon } from "../../../../../icons";
import { allowNumbersWithDotAndFourDecimals, CustomButton, FormInput, RegxPattern, SpinnerScreen } from "../../../../../utils";
import "./holiday_report.scss";
import { IHolidayReport, IHolidayReportParam } from "../../../../../interfaces/report_center";
import { convertDateToTimeStamp } from "../../../../../variables";
import ToggleSwitch from "../../../../../components/toggle_switch/toggle_switch";
import { LoadingType, THEME } from "../../../../../enums";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import SSNHolder from "../../../../../components/ssn_holder/ssn_holder";
import { talentRequestSSNActions } from "../../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer";
import { AppRoutes } from "../../../../../routes";
import { Portal } from "react-portal";
import ViewSSNPopup from "../../../../talent/popups/view_ssn/view_ssn";
import SearchBar from "../../../../../components/search_bar";
import CustomText from "../../../../../utils/custom_text";
import { holidayVacationReportSliceActions } from "../../../../../redux/report_center/payroll_tax/holiday_vacation_report/holiday_vacation_report_reducer";


interface Props extends RouteComponentProps<any> { }

const tableHeader = [
    { title: "ssn", code: "ssn" },
    { title: "talent", code: "talent_name" },
    { title: "regular_hours", code: "regular_hours" },
    { title: "over_time_hours", code: "over_time_hours" },
    { title: "double_time_hours", code: "double_time_hours" },
    { title: "total_hours", code: "total_hours" },
    { title: "earned_holiday_hours", code: "earned_holiday_hours" },
    { title: "on_assignment", code: "on_assignment" },
];
const HolidayReport: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const holidayReportState = useAppSelector((state) => selectHolidayReportState(state));
    const profileState = useAppSelector((state) => selectProfileState(state));

    const initialForm = {
        start_date: null,
        end_date: null,
        hours_worked: null,
        earned_hours: null,
        include_overtime: false,
    };
    const [holidayReportList, setHolidayReportList] = useState<IHolidayReport[]>([]);
    const [formState, setFormState] = useState<IHolidayReportParam>(initialForm)
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [showSSNPopup, setShowSSNPopup] = useState<{ talentId: string; visible: boolean; } | null>(null);
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);
    const [checkDate, setCheckDate] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            dispatch(holidayVacationReportSliceActions.clearHRState());
            setHolidayReportList([])
        };
    }, []);

    useEffect(() => {
        if (holidayReportState.loading === LoadingType.succeeded && holidayReportState.response) {
            setHolidayReportList(holidayReportState.response)
        }
    }, [holidayReportState.loading, holidayReportState.response])

    useEffect(() => {
        if (formState.start_date !== 0 && formState.end_date !== 0) {
            setDatePickerOpen(false)
        }
        else if (formState.start_date === 0 && formState.end_date === 0) {
            openDatePicker();
        }
    }, [formState.start_date, formState.end_date])
    const openDatePicker = () => {
        setDatePickerOpen(true);
    };
    function handleFormChange(id, value) {
        if (id === "date") {
            setDateRange(value);
            setFormState({
                ...formState,
                start_date: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
                end_date: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
            });
            if (value[0] !== null && value[0] !== 0 && value[1] !== null && value[1] !== 0) {
                setCheckDate(false);
            }
            else {
                setCheckDate(true);
            }
        }
        else {
            setFormState({ ...formState, [id]: value === "" ? null : value })
        }
    }
    const _isFormValid = () => {
        const { start_date, end_date, hours_worked, earned_hours } = formState;
        if (start_date === 0 || start_date === null) return false;
        if (end_date === 0 || end_date === null) return false;
        if (hours_worked === 0 || hours_worked === null) return false;
        if (earned_hours === 0 || earned_hours === null) return false;
        return true;
    }

    const handleSave = () => {
        setCheckValidationOnSubmit(true);
        if ((formState.start_date === 0 && formState.end_date === 0) || (formState.start_date === null && formState.end_date === null)) {
            setCheckDate(true);
        }

        if (_isFormValid()) {
            dispatch(getHolidayReportThunk(formState));
            setCheckValidationOnSubmit(false);
            setCheckDate(false);
        }

    }
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };
    const getFilteredList = () => {
        let list: IHolidayReport[] = holidayReportList;

        if (holidayReportList && Array.isArray(holidayReportList) && sortedField != null) {
            list = [...holidayReportList].sort((a, b) => {
                const valueA =
                    a[sortedField] !== null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] !== null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });

    }
    function getSSNInfo(value: string, id: string) {
        return (
            <SSNHolder
                value={value}
                iconColor={THEME.tableRowTextColor}
                onClick={() => handleOnSSNView(id)}
                showFormat={false}
            />
        );
    }
    function handleOnSSNView(id: string) {
        dispatch(talentRequestSSNActions.initialiseRequestSSN(id));
        if (profileState.profile && profileState.profile.show_ssn) {
            setShowSSNPopup({ visible: true, talentId: id });
        } else {
            dispatch(postSnackbarMessage(t("ssn_access_is_disabled")));
        }
    }
    function handleTalentSelect(id: string, name: string) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${id}`,
            state: {
                id: id,
                name: name,
            },
        });
        return;
    }
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 0.8;
        if (value === 3) return 0.8;
        if (value === 4) return 0.8;
        if (value === 5) return 0.8;
        if (value === 6) return 0.8;
        if (value === 7) return 0.8;
        if (value === 8) return 1;
        if (value === 9) return 1;
        return 1;
    }
    function formValidators(value: { text: any, field: string }) {
        switch (value.field) {
            case "start_date":
            case "end_date": {
                if (value.text === 0) return t('validators.required');
                return null;
            }
            case "hours_worked":
            case "earned_hours": {
                if (value.text === 0) return t('validators.hours_not_zero');
                if (value.text === null || value.text === "") return t('validators.required')
                return null;
            }
            default: {
                return null;
            }
        }
    }
    return (
        <div className="hr-container">
            <div className="hr-header">
                <div className="hr-r-left">
                    <div className="dateRange">
                        <label>{t("date_range")}*</label>
                        <div className={checkValidationOnSubmit ? "date-input-icon error-date" : "date-input-icon"}>
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(val) => handleFormChange("date", val)}
                                isClearable={true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                                required={true}
                                formValidators={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                            />

                        </div>
                        {checkDate ? <CustomText className="error-span">{t("enter_valid_daterange")}</CustomText> : null}
                    </div>
                    <div className="hr-input">
                        <FormInput
                            id={"hours_worked"}
                            label={t("hours_worked")}
                            onChange={handleFormChange}
                            type={"text"}
                            step={"0.01"}
                            min={"0.00"}
                            value={formState?.hours_worked}
                            placeholder=""
                            onKeyUp={(e) => allowNumbersWithDotAndFourDecimals(e)}
                            onKeyDown={(e) => allowNumbersWithDotAndFourDecimals(e)}
                            required={true}
                            validations={formValidators}
                            checkValidationOnSubmit={checkValidationOnSubmit}
                        />
                    </div>
                    <div className="hr-input">
                        <FormInput
                            id={"earned_hours"}
                            label={t("earned_hours")}
                            onChange={handleFormChange}
                            type={"text"}
                            step={"0.01"}
                            min={"0.00"}
                            value={formState?.earned_hours}
                            placeholder=""
                            onKeyUp={(e) => allowNumbersWithDotAndFourDecimals(e)}
                            onKeyDown={(e) => allowNumbersWithDotAndFourDecimals(e)}
                            required={true}
                            validations={formValidators}
                            checkValidationOnSubmit={checkValidationOnSubmit}
                        />
                    </div>
                    <div className="hr-input">
                        <ToggleSwitch
                            onChange={(val) => handleFormChange('include_overtime', val)}
                            label={t("include_overtime")}
                            checked={formState.include_overtime!}
                            labelPosition={'top'}
                        />
                    </div>
                    <div className="hr-save-input">
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            loading={holidayReportState.loading === LoadingType.pending}
                            name={t("show_report")}
                            backgroundColor={THEME.buttonColor1}
                            onClick={handleSave}
                        />
                    </div>
                </div>
                <div className="hr-r-right">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="hr-content">
                <div className="hr-table-c">
                    <div className="hr-table-title">
                        <div className="hr-t-left">{t("employee_holiday_report")}</div>
                        <div className="hr-t-right">
                            <span className="total-span">
                                {`${t("total_count")}: `}
                                <span className="total-count-number">
                                    {getFilteredList()?.length}
                                </span>
                            </span>
                            <SearchBar
                                value={search}
                                onChange={setSearch}
                                onSearch={() => { }}
                            />
                        </div>
                    </div>
                    <div>
                        {holidayReportState.loading === LoadingType.pending ?
                            <SpinnerScreen />
                            :
                            <div className="hr-table">
                                <SortableTable
                                    headerList={tableHeader}
                                    sortedField={sortedField}
                                    onSortChange={handleSortFieldChange}
                                    flexNumber={getFlexNumber}
                                    isAsc={sortDirection}
                                >
                                    {getFilteredList().length > 0 ?
                                        <>
                                            {getFilteredList()?.map((doc) => {
                                                return (
                                                    <tr key={doc.talent_id}>
                                                        <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                            {doc.ssn
                                                                ? getSSNInfo(doc.ssn, doc.talent_id)
                                                                : ""}
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(1) }}
                                                            isButton={true}
                                                            onClick={() => handleTalentSelect(doc.talent_id, doc.talent_name)}
                                                        >
                                                            <span>{doc.talent_name}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                            <span>{doc.regular_hours}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                            <span>{doc.over_time_hours}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                            <span>{doc.double_time_hours}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                            <span>{doc.total_hours}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                            <span>{doc.earned_holiday_hours}</span>
                                                        </TableData>
                                                        <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                            <span>{doc.on_assignment ? "Yes" : "No"}</span>
                                                        </TableData>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                        :
                                        <>
                                            <tr>
                                                <TableData customStyle={{ flex: getFlexNumber(20), wordWrap: "break-word" }} align="center">
                                                    <div style={{ width: "100%", fontSize: "0.9vw", textAlign: "center" }}>
                                                        {t("no_data_available")}
                                                    </div>
                                                </TableData>
                                            </tr>
                                        </>
                                    }
                                </SortableTable>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {showSSNPopup && (
                <Portal>
                    <ViewSSNPopup
                        visible={showSSNPopup.visible}
                        title={t("social_security_number")}
                        talentId={showSSNPopup.talentId}
                        onClose={() => setShowSSNPopup(null)}
                        onDissmiss={() => setShowSSNPopup(null)}
                        onSuccessClose={() => setShowSSNPopup(null)}
                    />
                </Portal>
            )}
        </div>

    );
}

export default HolidayReport;