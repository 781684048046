import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./traffic_engagement_reducer";

const select = (state: IRootState) => state.dashboard.metrics.trafficLightEngagement;
export const selectDashboardTrafficEngagementListState = createSelector(select, selectState);
export const selectDashboardTrafficEngagementList = createSelector(
    select,
    selectAll
);
export const selectDashboardTrafficEngagementListEntities = createSelector(
    select,
    selectEntities
);

export const selectDashboardTrafficEngagementCompanyList = createSelector(
    selectDashboardTrafficEngagementList,
    (docs) => {
        const obj = docs.filter(doc => doc.category === "COMPANY").reduce((a, v) => ({ ...a, [v.type]: v }), {});
        return obj;

    },
);
export const selectDashboardTrafficEngagementContactList = createSelector(
    selectDashboardTrafficEngagementList,
    (docs) => {
        const obj = docs.filter(doc => doc.category === "CONTACT").reduce((a, v) => ({ ...a, [v.type]: v }), {});
        return obj;

    },
);

export const selectDashboardTrafficEngagementTalentList = createSelector(
    selectDashboardTrafficEngagementList,
    (docs) => {
        return docs.filter(doc => doc.category === "TALENT").reduce((a, v) => ({ ...a, [v.type]: v }), {});
    },
);