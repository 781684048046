import { useTranslation } from "react-i18next";
import { THEME } from "../../enums";
import { IEmploymentHistory } from "../../interfaces";
import { addressParser, CustomButton } from "../../utils";
import "./talent_employemnt_history.scss";
import {
  getDateString,
  getDaysDifference,
  getDateFromTimeStamp,
} from "../../variables";

import { getArrayStrings } from '../../utils';
import ToggleSwitch from "../toggle_switch/toggle_switch";
import { EditIcon } from "../../icons";
import { useState } from "react";
import { manageTalentEmploymentHistoryActions } from "../../redux/talent/manage_employment/manage_employment_reducer";
import { getTalentEmpHistoryList, useAppDispatch } from "../../redux/store";
import CreateEmploymentRecordPopup from "../../main/talent/popups/create_employment_record/create_employment_record";
import { Portal } from "react-portal";

interface Props {
  employmentHistory: IEmploymentHistory | null;
  talentId: string;
  onAddCompanyToCallDeck: (value: IEmploymentHistory) => void;
  // onEditEmployementRecord: (value: IEmploymentHistory) => void;
  // departmentsState: string | null,
}


const empInfoDetails = [
  {
    sectionTitle: "dates_of_employment",
    sectionContent: [
      {
        title: "dates_of_employment",
        valueField: ["start_date", "end_date", "days_difference"],
      },
    ],
  },
  {
    sectionTitle: "job_title",
    sectionContent: [{ title: "job_title", valueField: "job_title" }],
  },

  {
    sectionTitle: "job_duties",
    sectionContent: [
      { title: "job_duties", valueField: "job_description_duties" },
    ],
  },
  {
    sectionTitle: "reason_for_leaving",
    sectionContent: [
      { title: "reason_for_leaving", valueField: "reason_for_leaving" },
    ],
  },
];
const refCheckInfo = [
  {
    sectionTitle: "reference_check_notes",
    sectionContent: [{ title: "reference_check_notes", valueField: "reference_check_notes" }],
  },
  {
    sectionTitle: "with_who_at_this_company_check_reference_with",
    sectionContent: [{ title: "with_who_at_this_company_check_reference_with", valueField: "reference_checked_with" }],
  },
  {
    sectionTitle: "on_what_date_check_this_reference",
    sectionContent: [
      { title: "on_what_date_check_this_reference", valueField: "reference_check_date" },
    ],
  },
  {
    sectionTitle: "who_checked_reference",
    sectionContent: [
      { title: "who_checked_reference", valueField: "reference_check_by" },
    ],
  },

];


const EmploymentHistorySection: React.FunctionComponent<Props> = (props) => {
  const { employmentHistory, talentId } = props;
  const [editTalentEmpHistory, setEditTalentEmpHistory] = useState<{ visible: boolean, data: IEmploymentHistory } | null>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const newemploymentHistory = {
    ...employmentHistory,
    days_difference: getDaysDifference(
      employmentHistory?.end_date
        ? getDateFromTimeStamp(employmentHistory?.end_date!)
        : new Date(),
      employmentHistory?.start_date
        ? getDateFromTimeStamp(employmentHistory?.start_date!)
        : new Date()
    ),
    end_date: getDateString(employmentHistory?.end_date),
    start_date: getDateString(employmentHistory?.start_date),
    reference_check_date: employmentHistory?.reference_check_date ? getDateString(employmentHistory?.reference_check_date) : 0,
  };

   
  function handleEdit() {
    setEditTalentEmpHistory({ visible: true, data: employmentHistory! });
  }

  const getTalentEmpHistoryListDetails = () => {
    if (talentId) dispatch(getTalentEmpHistoryList(talentId));
  };

   ///Edit user action
   function handleEditTalentEmpHistorySuccess() {
    setEditTalentEmpHistory(null);
    dispatch(
      manageTalentEmploymentHistoryActions.clearUpdateTalentEMploymentHistoryState()
    );
    getTalentEmpHistoryListDetails();
  }

  const companyInfoDetails = [
    {
      sectionTitle: "company",
      sectionContent: [{ title: "company_name", valueField: ["company_name"] }],
    },

    {
      sectionTitle: "department",
      sectionContent: [{ title: "department", valueField: "department_name" }],
    },
    {
      sectionTitle: "address",
      sectionContent: [{
        title: "address", valueField: addressParser({
          'street_address': employmentHistory ? employmentHistory['street_address'] : null,
          'city': employmentHistory ? employmentHistory['city'] : null,
          'state': employmentHistory ? employmentHistory['state'] : null,
          'zip_code': employmentHistory ? employmentHistory['zip_code'] : null,
        })
      }],
    },
    {
      sectionTitle: "supervisor_name",
      sectionContent: [
        { title: "supervisor_name", valueField: "supervisor_name", valueField2: "supervisor_contact" },
      ],
    },
    {
      sectionTitle: "is_placed_person",
      sectionContent: [
        { title: "is_placed_person_by_staffing_agency", valueField: "is_placed_by_staffing_agency" },
      ],
    },
    {
      sectionTitle: "placed_person_name",
      sectionContent: [
        { title: "placed_person_by_staffing_agency_name", valueField: "placed_by_staffing_agency_name" },
      ],
    },

  ];

  return (
    <div className="teh-container">
      <div className="teh-panel1">
        <div className="teh-panel-header">Company Information</div>
        {companyInfoDetails.map((doc, index) => {
          return (
            <div key={index} className="teh-info-container">
              <div className="teh-info-content">
                {doc.sectionContent.map((data, index) => {
                  const arrayValue = getValues(data.valueField);
                  const arrayValue2 = getValues(data.valueField2);


                  return (
                    <div key={index} className="teh-info-holder">
                      <div className="teh-into-title">
                        <span>{t(data.title).toUpperCase()}</span>
                      </div>
                      {data.title !== 'is_placed_person_by_staffing_agency' && <div className="teh-info-value">{arrayValue}</div>}
                      {arrayValue2 && data.title === 'supervisor_name' && employmentHistory?.ok_to_contact_supervisor ?
                        <div className="sup_contact_div">
                          <div className="teh-info-value">{arrayValue2}</div>
                          <ToggleSwitch label={t('ok_to_contact_supervisor')}
                            checked={employmentHistory.ok_to_contact_supervisor}
                            onChange={() => { }}
                          />
                        </div>
                        :
                        <></>
                      }
                      {arrayValue && data.title === 'is_placed_person_by_staffing_agency' &&
                        <div className="placed_person_div">
                          <ToggleSwitch
                            checked={employmentHistory?.is_placed_by_staffing_agency!}
                            onChange={() => { }}
                          />
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="teh-panel2">
        <div className="teh-panel-header">Employment Information</div>
        {empInfoDetails.map((doc, index) => {
          return (
            <div key={index} className="teh-info-container">
              <div className="teh-info-content">
                {doc.sectionContent.map((data, index) => {
                  const arrayValue = getValues(data.valueField);

                  return (
                    <div key={index} className="teh-info-holder">
                      <div className="teh-into-title">
                        <span>{t(data.title).toUpperCase()}</span>
                      </div>
                      <div className="teh-info-value">{arrayValue}</div>

                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="teh-panel3">
        <div>
          <div className="teh-panel-header">Reference Check Information</div>
          {refCheckInfo.map((doc, index) => {
            return (
              <div key={index} className="teh-info-container">
                <div className="teh-info-content">
                  {doc.sectionContent.map((data, index) => {
                    const arrayValue = getValues(data.valueField);
                    return (
                      <div key={index} className="teh-info-holder">
                        <div className="teh-into-title">
                          <span>{t(data.title).toUpperCase()}</span>
                        </div>
                        <div className="teh-info-value">{arrayValue}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="teh-action-container">
          <div className="teh-action">
            {employmentHistory?.company_id === "" || employmentHistory?.company_id === null ?
              <CustomButton
                customStyle={{ width: "fit-content" }}
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("add_company_to_call_deck")}
                enable={true}
                backgroundColor={THEME.buttonColor17}
                onClick={() =>
                  props.employmentHistory
                    ? props.onAddCompanyToCallDeck(props.employmentHistory)
                    : {}
                }
              />
              :
              <></>
            }
          </div>
        </div>
      </div>
      <div>
        <button className="icon-btn tdp-edit-icon" onClick={handleEdit}>
          <EditIcon width={"100%"} height={"100%"} style={{ color: '#fff' }} />
        </button>
      </div>
      {editTalentEmpHistory && (
          <Portal>
            <CreateEmploymentRecordPopup
              talentId={talentId}
              talent={editTalentEmpHistory?.data}
              visible={editTalentEmpHistory.visible}
              title={t("edit_employment_record")}
              onClose={() => setEditTalentEmpHistory(null)}
              onSuccessClose={handleEditTalentEmpHistorySuccess}
            />
          </Portal>
        )}
    </div>
  );


  function getValues(obj: any) {
    if (newemploymentHistory == null) return <span>{""}</span>;

    if (Array.isArray(obj)) {
      return getArrayStrings(obj, newemploymentHistory);
    }
    if (typeof obj === "object") {
      return obj;
    }
    const value =
      obj != "" && newemploymentHistory![obj] != null && newemploymentHistory![obj] != ""
        ? newemploymentHistory![obj]
        : "-";
    return <span>{value}</span>;
  }
};

export default EmploymentHistorySection;
