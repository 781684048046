import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ThirdPartyDeductionType } from "../../../../interfaces/third_party_deduction_type";
import { BackOfficeAPI } from "../../../../apis/back_office_api";

export const getThirdPartyDeductionType = createAsyncThunk<
    Array<ThirdPartyDeductionType>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@back_office/deduction_type/get',
    async (_, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getThirdPartyDeductionTypeDetails();
            return res.data as ThirdPartyDeductionType[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);