import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, WorkCompCode } from "../../../interfaces";
import * as thunk from "./work_comp_list_thunk";

export interface WorkCompCodesListState extends EntityState<WorkCompCode> {
    error?: AppError | null,
    loading: LoadingType,
}

const workCompCodeListAdapter: EntityAdapter<WorkCompCode> = createEntityAdapter<WorkCompCode>({
    selectId: (doc) => doc.id,
})

const initialState: WorkCompCodesListState = workCompCodeListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const workCompCodesListSlice = createSlice({
    name: 'workCompCodes',
    initialState: initialState,
    reducers: {
        clearworkCompCodesListError(state){
            return {...state, error: null};
        },
        clearWorkCompCodesListState(state){
            return workCompCodeListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getWorkCompCodesThunk.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getWorkCompCodesThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getWorkCompCodesThunk.fulfilled, (state, action) => workCompCodeListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const workCompCodesListActions = workCompCodesListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = workCompCodeListAdapter.getSelectors();
export const selectState = (state: WorkCompCodesListState) => state;
export const selectLoading = (state: WorkCompCodesListState) => state.loading;
export const selectError = (state: WorkCompCodesListState) => state.error;


