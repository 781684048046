import { ChangeEvent, ChangeEventHandler, LegacyRef, useEffect, useRef, useState } from 'react';
import { THEME } from '../../enums';
import { CameraIcon, CloseIcon } from '../../icons';
import './photo_input.scss';

interface Props {
    selectedFile: File | null,
    label?: string | React.ReactNode,
    accept?: string,
    onChange: (value: File) => void,
    onRemove: () => void,
}

const PhotoInput: React.FunctionComponent<Props> = (props) => {
    const { selectedFile, accept, label, onChange, onRemove } = props;
    const [preview, setPreview] = useState<string | null>(null)
    const photoRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!selectedFile) {
            setPreview(null)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile])

    function handleCameraClick() {
        if (photoRef && photoRef.current != null) {
            photoRef.current.click();
        }
    }

    return (
        <div className="ph-info-container">
            <input
                style={{ display: 'none' }}
                ref={(ref) => photoRef.current = ref}
                type="file"
                name="myImage"
                accept={accept ?? "image/*"}
                onChange={(e) => {
                    if(e.target.files){
                        onChange(e.target.files[0]);
                        e.target.value = ''
                    }
                }}
            />
            {label && <div className="ph-info-label">
                {getLabel()}
            </div>}
            <div className="ph-info-input-holder">

                {getPreviewImage()}

            </div>
        </div>
    );

    function getPreviewImage() {
        if (preview) {
            return (
                <div className="camera-icon-holder">
                    <button className="img-preview" onClick={handleCameraClick}>
                        <img src={preview} width={"100%"} height={"100%"} style={{ objectFit: 'cover', borderRadius: '100%' }} />
                    </button>
                    <button className="icon-btn close-action" onClick={onRemove}>
                        <CloseIcon width={"100%"} height={"100%"} />
                    </button>
                </div>

            )
        }

        return (
            <button className="camera-icon-holder" onClick={handleCameraClick}>
                <div className="camera-icon">
                    <CameraIcon
                        width={"100%"}
                        height={"100%"}
                        style={{ color: THEME.defaultHighLightColor }}
                    />
                </div>
            </button>
        )
    }

    function getLabel() {
        if (typeof label === "string") {
            return (<span className='label-txt'>{label}</span>);
        }
        return label;
    }
}

export default PhotoInput;