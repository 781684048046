import React, { useEffect, useRef, useState } from "react";

import './company_job_orders_page.scss';
import SearchBar from "../../../../components/search_bar";
import { CustomButton, getPlusBtnIcon } from "../../../../utils";
import { JOB_STATUS, LoadingType, THEME } from "../../../../enums";
import { useTranslation } from "react-i18next";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { useAppDispatch, useAppSelector, selectContactDetailsByIdState, getContactById } from "../../../../redux/store";
import {
    getJobsListByCompanies,
    selectCompanyJobOrderList,
    selectCompanyJobOrderState,
    selectCompanyDetailsByIdState,
    getCompanyById
} from '../../../../redux/companies';
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../components/table_empty/table_empty";
import { AppRoutes } from "../../../../routes";
import { Portal } from "react-portal";
import { Rating } from "@material-ui/lab";
import OpenOrderRow from "../../../../utils/open_orders";
import { currencyConversion, getTableAccessIcon, sortByOrders } from "../../../../utils/helpers";
import { IJobOrder } from "../../../../interfaces";
import CreateJobPopup from "../../../jobs/popups/create_job/create_job";
import { manageJobActions } from "../../../../redux/job_order/universal_directory/manage_job/manage_job_reducer";

const headers = [
    { 'title': 'access_job', 'code': 'access_job' },
    { 'title': 'orders', 'code': 'open_orders' },
    { 'title': 'company', 'code': 'company_name' },
    { 'title': 'department', 'code': 'department_name' },
    { 'title': 'job_title', 'code': 'job_title' },
    { 'title': 'rating', 'code': 'rating' },
    { 'title': 'status', 'code': 'job_status' },
    { 'title': 'pay_rate', 'code': 'regular_pay_rate' },
    { 'title': 'bill_rate', 'code': 'regular_bill_rate' },
    { 'title': 'requested', 'code': 'requested' },
    { 'title': 'placed', 'code': 'placed' },
    { 'title': 'over_under', 'code': 'over_under_placed' },
]

const category = [
    'access_job',
    'open_orders',
    'company_name',
    'department_name',
    'job_title',
    'rating',
    'job_status',
    'regular_pay_rate',
    'regular_bill_rate',
    'requested',
    'placed',
    'over_under_placed',
]

const pages = { companies: 'companies', contacts: 'contacts' };

const CompanyJobOrdersPage = (props) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { params } = props.match;
    const { id, departmentId } = props.match.params;
    const [totalCount, setTotalCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [sortedField, setSortedField] = useState<null | string>('id');

    const [sortDirection, setSortDirection] = useState("asc");

    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);
    const companyState = useAppSelector((state) => selectCompanyDetailsByIdState(params.departmentId ?? params.id)(state));
    const contactState = useAppSelector((state) => selectContactDetailsByIdState(params.id)(state));
    const jobOrderState = useAppSelector((state) => selectCompanyJobOrderState(state));
    const jobOrderList = useAppSelector((state) => selectCompanyJobOrderList(state));
    let mainPage = pages.companies;
    if (props.match.path.startsWith('/contacts/'))
        mainPage = pages.contacts;



    function handleSearchChange(value) {
        setSearchValue(value);
    }

    function handleSortChange(value) {
        if (value === "open_orders") {
            if (sortedField && sortedField === value) {
            //   setSortedField(null)
            } else {
              setSortedField(value);
            }
          }
          else {
            if (sortedField && sortedField === value) {
              // setSortedField(null)
              setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            } else {
              setSortedField(value);
              setSortDirection('asc');
            }
          }
    }

    function getFilteredList() {
        let list;
        if (!jobOrderList)
            return [];
        if (sortedField != null) {
            if (sortedField === "open_orders") {
                list = sortByOrders([...jobOrderList], "job_status");
            } else {
                list = [...jobOrderList].sort((a, b) => {
                    const valueA =
                        a[sortedField] != null && a[sortedField] != undefined
                            ? typeof a[sortedField] == typeof "1"
                                ? a[sortedField].trim().toLowerCase()
                                : a[sortedField]
                            : "";
                    const valueB =
                        b[sortedField] != null
                            ? typeof b[sortedField] == typeof "1"
                                ? b[sortedField].trim().toLowerCase()
                                : b[sortedField]
                            : "";
                    if (sortDirection === "asc") {
                        return valueA > valueB ? 1 : -1;
                    } else {
                        return valueA < valueB ? 1 : -1;
                    }
                });
            }
        }
        return (list ?? jobOrderList).filter(doc => {
            const str = searchValue.trim().toLowerCase();
            for (let key of category)
                if (doc[key] && String(doc[key]).trim().toLowerCase().includes(str))
                    return true;
            return false;
        });
    }

    function getRequested() {
        let data = getFilteredList();
        let requested = 0;
        for (let i of data)
            requested += Number(i['requested']);
        return requested;
    }

    function getPlaced() {
        let data = getFilteredList();
        let placed = 0;
        for (let i of data)
            placed += Number(i['placed']);
        return placed;
    }

    function getOver() {
        let data = getFilteredList();
        let over = 0;
        for (let i of data)
            over += Number(i['over_under_placed'])
        return over;
    }

    function handleRetry() {
        let data = { company_id: null, department_id: null, contact_id: null };
        if (mainPage === pages.companies)
            data = { ...data, company_id: id, department_id: departmentId };
        else
            data = { ...data, contact_id: id };
        dispatch(getJobsListByCompanies(data));
    }

    function handleAccessJob(job) {
        props.history.push(`${AppRoutes.jobDetailsPage}/${job.id}/dashboard`);
    }

    useEffect(() => {
        if (jobOrderState.loading === LoadingType.succeeded) {
            setSentRequest(false);
            setTotalCount(jobOrderList.length);
        }
    }, [jobOrderState.loading]);

    useEffect(() => {
        getJobs();
    }, [props.match.params.departmentId])

    function getJobs() {
        setSentRequest(true);
        let data = { company_id: null, department_id: null, contact_id: null };
        if (mainPage === pages.companies) {
            data = { ...data, company_id: id, department_id: departmentId };
            getCompanyDetails();
        } else {
            data = { ...data, contact_id: id };
            getContactDetails();
        }
        dispatch(getJobsListByCompanies(data));
    }

    function getCompanyDetails() {
        if (params) {
            if (params.departmentId != null) {
                dispatch(getCompanyById({ companyId: params.id, departmentId: params.departmentId }));
                return;
            }
            if (params.id != null) {
                dispatch(getCompanyById({ companyId: params.id }));
            }

        }
    }

    function getContactDetails() {
        if (params && params.id != null) {
            dispatch(getContactById({ contact_id: params.id }));
        }
    }

    return (
        <div>
            <div className={"cmp-jo-main-container"}>
                <div className={"cmp-jo-header"}>
                    <div className={"left-fields"}>
                        <div style={{ "width": "250px" }}>
                            <SearchBar value={searchValue} onChange={handleSearchChange} onSearch={() => (console.log('testing'))} />
                        </div>
                        <div className={"total-count"}>
                            <div style={{ "marginRight": "3px" }}>{t("count_total")}</div>
                            <span>{totalCount}</span>
                        </div>
                    </div>
                    <div className={"add-button"}>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            leftIcon={getPlusBtnIcon()}
                            name={t("add_job_order")}
                            // padding={12}
                            // fontSize={15}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => (setShowCreateDialog(true))}
                        />
                    </div>
                </div>
                <div style={{ "overflowX": "scroll" }}>
                    {jobOrderState.loading === LoadingType.pending && (companyState.loading === LoadingType.pending || contactState.loading === LoadingType.pending) && sentRequest ? <TableLoading /> :
                        <TableBody
                            state={jobOrderState}
                            getFilteredList={getFilteredList}
                            sortedField={sortedField}
                            handleSortChange={handleSortChange}
                            onRetry={handleRetry}
                            data={jobOrderList}
                            accessJob={handleAccessJob}
                            handleJobSelect={handleJobSelect}
                            handleCompanySelect={handleCompanySelect}
                            handleDepartmentSelect={handleDepartmentSelect}
                            sortDirection={sortDirection}

                        />}
                </div>
                {showCreateDialog && <Portal>
                    <CreateJobPopup
                        visible={showCreateDialog}
                        title={t('add_job_order')}
                        successTitle={t('new_job_order')}
                        data={getDataProps()}
                        onClose={() => setShowCreateDialog(false)}
                        onSuccessClose={handleAddJobSuccess}
                        onGotoAgencyDashboard={handleGotoAgencyDashboard}
                        onGotoCreated={handleOnGotoJob}
                        contactState={contactState}
                    />
                </Portal>}
            </div>
            {jobOrderState.loading === LoadingType.pending && sentRequest && !showCreateDialog ? <></> :
                <div className="cmp-job-order-analysis">
                    <div className="heading">
                        <span>Total Job Orders:</span>
                    </div>
                    <div className="fields">
                        <div className="field" style={{ "color": THEME.red }}>
                            <span className="field-m">{getRequested()}</span>
                            <span>Requested</span>
                        </div>
                        <div className="field" style={{ "color": THEME.buttonColor17}}>
                            <span className="field-m">{getPlaced()}</span>
                            <span>Placed</span>
                        </div>
                        <div className="field" style={{ "color": THEME.red }}>
                            <span className="field-m">{getOver()}</span>
                            <span>Over / under</span>
                        </div>
                    </div>
                </div>}
        </div>
    );

    function getDataProps() {
        if (mainPage === 'companies') return { company_id: id, department_id: departmentId };
        if (mainPage === 'contacts') return { company_id: contactState.data?.company_id, department_ids: contactState.data?.department_ids };
        return undefined;
    }


    ///Add user action
    function handleAddJobSuccess() {
        setShowCreateDialog(false);
        getJobs();
        dispatch(manageJobActions.clearManageJobState([]));
    }

    function handleGotoAgencyDashboard() {
        handleAddJobSuccess();
        props.history.push(AppRoutes.dashboardPage);
    }

    function handleOnGotoJob(id: string, name: string) {
        handleAddJobSuccess();
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${id}`,
            state: {
                id: id,
                name: name,
            }
        })
    }

    function handleJobSelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${value.id}`,
            state: {
                id: value.id,
                name: value.job_title,
            }
        })
        return;
    }

    function handleCompanySelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            }
        })
        return;
    }

    function handleDepartmentSelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.department_id,
                departmentName: value.department_name,
            }
        })
        return;
    }
}
{/* <Rating name="size-small" defaultValue={5} size="small" /> */ }
const TableBody = ({ state, getFilteredList, sortedField, handleSortChange, onRetry, data, accessJob, handleJobSelect, handleCompanySelect, handleDepartmentSelect, sortDirection }) => {
    function getStatus(status: string) {
        if (status === JOB_STATUS.open) return (<span style={{ color: THEME.statusInActiveColor }}>{t('open')}</span>);
        else if (status === JOB_STATUS.filled) return (<span style={{ color: THEME.buttonColor17 }}>{t('filled')}</span>);
        else if (status === JOB_STATUS.cancelledByClient) return (<span style={{ color: "#B1B1B1" }}>{t('cancelled_by_client')}</span>);
        else if (status === JOB_STATUS.mistakenOrder) return (<span style={{ color: "#B1B1B1" }}>{t('mistaken_order')}</span>);
        else if (status === JOB_STATUS.closed) return (<span style={{ color: "#B1B1B1" }}>{t('closed')}</span>);
        else return (<span>{''}</span>);
    }
    const { t } = useTranslation();
    if (state.loading === LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    else if (data && data.length === 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }
    return (
        <SortableTable headerList={headers} sortedField={sortedField} onSortChange={handleSortChange} flexNumber={getFlexNumber} isAsc={sortDirection}>
            {getFilteredList().map((doc, index) => {
                return (
                    <tr key={"cmp_job_order_row" + index}>
                        <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleJobSelect(doc))}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(1) }}>{OpenOrderRow(doc, 'job_status', t)}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(2) }} isButton={true} onClick={() => handleCompanySelect(doc)}><span>{doc['company_name']}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(3) }} isButton={doc.department_name ? true : false} onClick={() => handleDepartmentSelect(doc)}><span>{doc.department_name ? doc['department_name'] : t('corporate')}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(4) }} isButton={true} onClick={() => handleJobSelect(doc)}><span>{doc['job_title']}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(5) }}><span><Rating name="read-only" value={doc['rating']} readOnly /></span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(6) }}><span>{getStatus(doc.job_status)}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(7) }}><span>{currencyConversion(doc["regular_pay_rate"])}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(8) }}><span>{currencyConversion(doc["regular_bill_rate"])}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(9) }}><span style={{ color: THEME.statusInActiveColor }}>{doc.requested}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(10) }}><span style={{ color: THEME.buttonColor17 }}>{doc.placed}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(11) }}><span style={{ color: THEME.statusInActiveColor }}>{doc.over_under_placed}</span></TableData>
                    </tr>
                )
            })}
        </SortableTable>
    );

    function getFlexNumber(value: number) {
        if (value === 1) return 1.5;
        if (value === 2) return 2;
        if (value === 3) return 2;
        if (value === 4) return 2;
        if (value === 5) return 2;
        if (value === 7) return 2;
        if (value === 8) return 2;
        if (value === 9) return 1.15;
        if (value === 10) return 1.15;
        if (value === 11) return 1.22
        return 1;
    }


}

export default CompanyJobOrdersPage;