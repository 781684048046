import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { RouteComponentProps } from 'react-router-dom';
import StateChangeHandler from '../../../../../components/state_change_handler/state_change_handler';
import CurveTabNavigation from '../../../../../components/curve_tab_navigation/curve_tab_navigation';
import { COMPANY_TYPE, LoadingType, THEME } from '../../../../../enums';
import { EditIcon, ClaimStatusIcon } from '../../../../../icons';
import { IWorkCompClaimData } from '../../../../../interfaces';
import { getWorkCompClaimDetailsByTalentId, selectWorkCompClaimDocUploadState, selectWorkCompClaimSaveResponse, selectWorkCompClaimTalentDetailsState } from '../../../../../redux/risk_management';
import { useAppDispatch, useAppSelector, selectProfileState, postSnackbarMessage, getResumeFileUrlForDownload, selectSingleResumeFileStateById } from '../../../../../redux/store';
import { AppRoutes } from '../../../../../routes';
import './workcompclaim_dashboard.scss';
import WorkCompClaimTabDataPage from './workcompclaim_tabData';
import CreateWorkCompClaim from '../../../popup/new_work_comp_claim';
import { talentRequestSSNActions } from '../../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer';
import ViewSSNPopup from '../../../../talent/popups/view_ssn/view_ssn';
import { unEmploymentClaimsListActions } from '../../../../../redux/risk_management/universal_directory/unemployment_claims_list/unemployment_claims_list_reducer';
import { workCompClaimsListActions } from '../../../../../redux/risk_management/universal_directory/work_comp_claims_list/work_comp_claims_list_reducer';
import WorkCompClaimDetailsPage from './workcompclaim_details';
import { handleDownload } from '../../../../../utils';
import { resumeFileSliceActions } from '../../../../../redux/files/resume_files/resume_file_reducer';

interface Props extends RouteComponentProps<any> { }
const WorkCompClaimDashboardPage: React.FunctionComponent<Props> = (props) => {
    const { history, location, match } = props;
    const { params } = match;
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const workCompClaimDetailsState = useAppSelector((state) => selectWorkCompClaimTalentDetailsState(state));
    const updateWorkCompClaimDetailsState = useAppSelector((state) => selectWorkCompClaimSaveResponse(state));
    const resumeDownloadState = useAppSelector((state) => selectSingleResumeFileStateById(params.id)(state))

    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [branchId, setBranchId] = useState<string | null>(null);
    const [editwcc, seteditwcc] = useState<{ visible: boolean, data: IWorkCompClaimData } | null>(null);
    const [showSSNPopup, setShowSSNPopup] = useState<{ talentId: string, visible: boolean } | null>(null);
    const [delAction, setDelAction] = useState<boolean>(false);
    useEffect(() => {
        if (resumeDownloadState.loading === LoadingType.succeeded && resumeDownloadState.response) handleDownload(resumeDownloadState.response);
        return () => { }
    }, [resumeDownloadState.loading])

    useEffect(() => {
        /// Get Work Comp Claim Details
        getWorkCompClaimDetails();
        dispatch(talentRequestSSNActions.initialiseRequestSSN(params.id));
        return () => { }
    }, [params.id])

    useEffect(() => {
        return () => {
            dispatch(resumeFileSliceActions.clearResumeFileState(params.id))
        }
    }, [])

    useEffect(() => {
        if (delAction && updateWorkCompClaimDetailsState.loading === LoadingType.succeeded) {
            handleUpdateUecSuccess()
            setDelAction(false)
        }
        return () => { }
    }, [delAction,updateWorkCompClaimDetailsState.loading]);

    function getWorkCompClaimDetails() {
        if (params && params.id != null) {
            setBranchId(null);
            dispatch(getWorkCompClaimDetailsByTalentId(params.id));
            // dispatch(talentRequestSSNActions.initialiseRequestSSN(params.id));
        }

    }

    const handleClearError = () => {
        dispatch(unEmploymentClaimsListActions.clearUnEmploymentClaimSaveState());
    }


    function handleBranchChange(value: string) {
        if (value != '') {
            history.push({
                pathname: `${location.pathname}/${value}`,
                state: {
                    ...(location.state as any),
                    departmentId: value,
                    departmentName: value,
                }
            });
        } else {
            history.push({
                pathname: `${AppRoutes.companiesDetailsPage}/${params.id}/dashboard`,
                state: {
                    ...(location.state as any),
                    departmentId: null,
                    departmentName: null,
                }
            });
        }

    }


    /// [Navigation update when details edited]
    useEffect(() => {
        if (workCompClaimDetailsState.loading === LoadingType.succeeded) {
            updateNavigationNames();
        }
        return () => { }
    }, [workCompClaimDetailsState.loading])


    function updateNavigationNames() {
        if (params) {
            if (params.id != null && workCompClaimDetailsState.data != null) {
                const workCompClaim = workCompClaimDetailsState.data;
                history.replace({
                    pathname: location.pathname,
                    state: {
                        ...(location.state as any),
                        name: `${workCompClaim.talent.first_name} ${workCompClaim.talent.middle_name} ${workCompClaim.talent.last_name}`,
                    }
                });
            }

        }

    }

    function getTabList() {
        const tabsList: any = [];
        if (workCompClaimDetailsState.data && workCompClaimDetailsState.data.work_comp_claims) {
            workCompClaimDetailsState.data.work_comp_claims.map((h: IWorkCompClaimData) => {
                tabsList.push(
                    {
                        title: `WC Claim ${h.claim_number ?? ''}`,
                        icon: ClaimStatusIcon,
                        content: <WorkCompClaimTabDataPage workCompClaimData={h} setDelAction={setDelAction} onClearError={handleClearError} updateState={updateWorkCompClaimDetailsState}/>,
                    }
                )
            })
        }
        return tabsList;
    }

    /// [SSN View]
    function handleOnSSNView() {
        if (profileState.profile && profileState.profile.show_ssn) {
            setShowSSNPopup({ visible: true, talentId: params.id });
        } else {
            dispatch(postSnackbarMessage(t('ssn_access_is_disabled')));
        }

    }

    function handleOnSuccessSSNClose() {
        setShowSSNPopup(null);
    }

    function handleGotoAgencyDashboard() {
        handleUpdateUecSuccess();
        props.history.push(AppRoutes.dashboardPage);
    }

    function hanleOnResume(path: string) {
        dispatch(getResumeFileUrlForDownload({ id: params.id, file_path: path }));
    }

    return (
        <StateChangeHandler
            loading={workCompClaimDetailsState.loading}
            error={workCompClaimDetailsState.error}
            onRefresh={getWorkCompClaimDetails}
        >
            <div className="wcc-db-container">
                <div className="wcc-db-left-panel">
                    <WorkCompClaimDetailsPage
                        talentData={workCompClaimDetailsState?.data?.talent}
                        onSSNClick={handleOnSSNView}
                        history={props.history}
                        onResumeClick={hanleOnResume}
                    />
                </div>
                <div className="wcc-db-right-panel">
                    <CurveTabNavigation
                        tabs={getTabList()}
                        selected={tabIndex}
                        onChange={(value) => setTabIndex(value)}
                        actions={getEditAction()}
                    />

                </div>
            </div>
            {editwcc && <Portal>
                <CreateWorkCompClaim
                    visible={editwcc.visible}
                    claim={editwcc.data}
                    onClose={() => seteditwcc(null)}
                    onSuccessClose={handleUpdateUecSuccess}
                    type={'WORKCOMPCLAIM'}
                    title={t("edit_work_comp_claim")}
                    successTitle={t("New_comp_claim")}
                    onGotoAgencyDashboard={handleGotoAgencyDashboard}
                    onGotoCreated={handleGoToCreated}
                />
            </Portal>}
            {showSSNPopup && (
                <Portal>
                    <ViewSSNPopup
                        visible={showSSNPopup.visible}
                        title={t('social_security_number')}
                        talentId={params.id}
                        onClose={() => setShowSSNPopup(null)}
                        onDissmiss={() => setShowSSNPopup(null)}
                        onSuccessClose={handleOnSuccessSSNClose}
                    />
                </Portal>
            )}
        </StateChangeHandler>
    );

    function handleGoToCreated() {
        seteditwcc(null);
        dispatch(workCompClaimsListActions.clearWorkCompClaimDocState());
        dispatch(workCompClaimsListActions.clearWorkCompClaimSaveState());
        dispatch(unEmploymentClaimsListActions.clearUnEmploymentClaimDocState());
        dispatch(unEmploymentClaimsListActions.clearUnEmploymentClaimSaveState());
    }

    /// Edit icon actions
    function getEditAction() {
        return (
            <button className="icon-btn wcc-db-edit-icon" onClick={() => handleEdit(workCompClaimDetailsState.data.work_comp_claims[tabIndex])}>
                <EditIcon width={"100%"} height={"100%"} style={{ color: '#fff' }} />
            </button>
        );
    }

    function handleEdit(value: IWorkCompClaimData) {
        seteditwcc({ visible: true, data: value });

    }
    function handleUpdateUecSuccess() {
        seteditwcc(null);
        dispatch(workCompClaimsListActions.clearWorkCompClaimDocState());
        dispatch(workCompClaimsListActions.clearWorkCompClaimSaveState());
        dispatch(unEmploymentClaimsListActions.clearUnEmploymentClaimDocState());
        dispatch(unEmploymentClaimsListActions.clearUnEmploymentClaimSaveState());
        getWorkCompClaimDetails();
    }
}
export default WorkCompClaimDashboardPage;