import { useTranslation } from 'react-i18next';
import { THEME } from '../../enums';
import { ChatIcon, PlusIcon } from '../../icons';
import { ITalentInHouseInterview } from '../../interfaces';
import { getDateString } from '../../variables';
import './interviews_list_view.scss';

interface Props {
    interviews: ITalentInHouseInterview[],
    onClick: (value: ITalentInHouseInterview) => void,
    onAddNew: () => void,
    selectedInterview: ITalentInHouseInterview | null,
}



const InterviewsListView: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="ilv-container">
            <div className="ilv-header">
                <div className="header-txt">
                    <span>{t('interviews')}</span>
                </div>
                <div className="header-action">
                    <button className='header-action-icon' onClick={props.onAddNew}>
                        <PlusIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
                    </button>
                </div>
            </div>
            <div className="ilv-content">
                {props.interviews.map((doc, index) => {
                    return (
                        <button key={index} type={"button"} className="interview-card" onClick={() => props.onClick(doc)}>
                            <InterviewCard
                                title={t('internal_interview')}
                                interviewBy={`${t('interview_by')} ${doc.interview_by}`}
                                date={getDateString(doc.interview_date)}
                                isSelected={props.selectedInterview?.id === doc.id}
                            />
                        </button>

                    )
                })}
            </div>
        </div>
    )
}

interface InterviewCardProps {
    title: string,
    interviewBy: string,
    date: string,
    isSelected: boolean,
}

function InterviewCard(props: InterviewCardProps) {
    return (
        <div
            className="ilv-ic-container"
            style={{
                ...props.isSelected && { border: `1px solid ${THEME.defaultHighLightColor}` }
            }}
        >
            <div className="ilv-ic-content">
                <div className="chat-icon">
                    <ChatIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
                </div>
                <div className="chat-info">
                    <div className="chat-title-txt">
                        <span>{props.title}</span>
                    </div>
                    <div className="chat-by-txt">
                        <span>{props.interviewBy}</span>
                    </div>
                </div>
            </div>
            <div className="ilv-ic-footer">
                <div className="ilv-ic-date-txt">
                    <span>{props.date}</span>
                </div>
            </div>
            {props.isSelected && <div className="ilv-ic-shape-pyramid"></div>}
        </div>
    )
}


interface InterviewDetailsCardProps {
    title: string,
    interviewBy: string,
    interViewDate: string,
    applicationDate: string,
}

export function InterviewDetailsCard(props: InterviewDetailsCardProps) {
    const { t } = useTranslation();
    return (
        <div className="ilv-icd-container">
            <div className="ilv-icd-content">
                <div className="chat-icon">
                    <ChatIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
                </div>
                <div className="chat-info">
                    <div className="chat-title-txt">
                        <span>{props.title}</span>
                    </div>
                    <div className="chat-by-txt">
                        <span>{props.interviewBy}</span>
                    </div>
                </div>
            </div>
            <div className="ilv-icd-footer">
                <div className="ilv-icd-date-txt">
                    <span>{props.interViewDate}</span>
                </div>
                <div className="ilv-icd-date-txt">
                    <span>{props.applicationDate}</span>
                </div>
            </div>
        </div>
    )
}

export default InterviewsListView;