import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IBillingBatch } from "../../../../interfaces";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { catchRequestError } from "../../../../utils";


export const createBillingBatchThunk = createAsyncThunk<
    IBillingBatch,
    IBillingBatch,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing_invoices/dashboard/create_billing_batch/post",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.createBillingBatch(payload);
            return res.data.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const postBillingBatchThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing_invoices/dashboard/post_billing_batch/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.postBillingBatch(payload);
            return res.data.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const postBillingInvoicesProcessThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing_invoices/dashboard/process_billing_invoices/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.postBillingInvoicesProcess(payload);
            return res.data.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const deleteBillingBatchThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing_invoices/dashboard/delete_billing_invoices/delete",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.deleteBillingBatch(payload);
            return res.data.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });