import { useTranslation } from "react-i18next";
import SectionCard from "../../../../../components/section_card/section_card";
import { LoadingType } from "../../../../../enums";
import { ICompanyBackOfficeInvoiceDocs, ICompBOInvoiceDocsMarkUp, ICompBOInvoiceDocsPOoptions, AppError } from "../../../../../interfaces";
import CompanyMarkUpShift from "./markup_shift/markup_shift";
import CompanyPOOptions from "./po_options/po_options";
import './markup_shift_po.scss';
import { companyBOInvoiceDocsSliceActions } from "../../../../../redux/companies/details/back_office_invoices_and_docs/back_office_invoices_and_docs_reducer";
import { getCompanyBackOfficeInvoiceDocsThunk } from "../../../../../redux/companies";
import { useAppDispatch } from "../../../../../redux/store";
interface Props {
    formState: ICompanyBackOfficeInvoiceDocs;
    currentLoginUserName: string;
    handleAddMarkup: (doc: ICompBOInvoiceDocsMarkUp, index?: number, removeItem?: boolean) => void;
    handleAddPOOptions: (doc: ICompBOInvoiceDocsPOoptions, index?: number, removeItem?: boolean) => void;
    markupPOUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleClearError: () => void;
    companyId: string;
    companyName: string;
    handleUpdateClearState: () => void;
    getInvoiceDocsData: () => void;
}
const MarkUpShiftPO: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { formState, currentLoginUserName, handleAddMarkup, handleAddPOOptions, markupPOUpdateState,
        handleClearError, companyId, companyName, handleUpdateClearState, getInvoiceDocsData } = props;

    return (
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('markup_shift_po')} >
            <div className="markup-po-container">
                <div className="markup-container">
                    <CompanyMarkUpShift
                        formState={formState}
                        currentLoginUserName={currentLoginUserName}
                        handleAddMarkup={handleAddMarkup}
                        markupPOUpdateState={markupPOUpdateState}
                        handleClearError={handleClearError}
                        companyId={companyId}
                        handleUpdateClearState={handleUpdateClearState}
                        getInvoiceDocsData={getInvoiceDocsData}
                        companyName={companyName}
                    />
                </div>
                <div className="po-container">
                    <CompanyPOOptions
                        formState={formState}
                        currentLoginUserName={currentLoginUserName}
                        handleAddPOOptions={handleAddPOOptions}
                        markupPOUpdateState={markupPOUpdateState}
                        handleClearError={handleClearError}
                        companyId={companyId}
                        companyName={companyName}
                        handleUpdateClearState={handleUpdateClearState}
                        getInvoiceDocsData={getInvoiceDocsData}
                    />
                </div>

            </div>

        </SectionCard>
    );
}

export default MarkUpShiftPO;