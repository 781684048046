import { TFunction } from "react-i18next";

export const allowNumbers = (event) => {
  const target = event.target;
  const input = event.target.value.replace(/[^0-9.]/g, "");
  target.value = input
};

export function allowNumbersWithDotAndFourDecimals(event) {
  const { key, target } = event;
  const { value, selectionStart } = target;
  
  // Allow: backspace, delete, tab, escape, enter, and arrow keys
  if (
    /^[.\d\b\t\r\n]+$/.test(key) ||
    key === "Backspace" ||
    key === "Delete" ||
    key === "Tab" ||
    key === "Escape" ||
    key === "Enter" ||
    key === "ArrowLeft" ||
    key === "ArrowRight"
  ) {
    // Check if the value already contains a dot and prevent entering another dot
    if (key === "." && value.indexOf(".") !== -1) {
      event.preventDefault();
      return;
    }
    // Allow only one dot and make sure dot is not the first character
    if (key === "." && selectionStart === 0) {
      event.preventDefault();
      return;
    }
    // Allow navigation within decimal values
    if (key === "ArrowLeft" || key === "ArrowRight") {
      const dotIndex = value.indexOf(".");
      // Adjust caret position if after the dot
      if (dotIndex !== -1 && selectionStart > dotIndex) {
        target.setSelectionRange(dotIndex + 1, dotIndex + 1);
      }
    }
    // Check if the value contains a dot and caret position is after the dot
    if (value.indexOf(".") !== -1 && selectionStart > value.indexOf(".")) {
      // Allow only four digits after the dot
      if (value.split(".")[1].length >= 4 && key !== "Backspace" && key !== "Delete") {
        event.preventDefault();
      }
    }
  } else {
    // Remove or delete input data that doesn't meet criteria
    target.value = value.replace(/[^.\d]/g, '');
    event.preventDefault();
  }
}

export const isNumericInput = (event) => {
  const key = event.keyCode;
  return (
    (key >= 48 && key <= 57) || // Allow number line
    (key >= 96 && key <= 105) // Allow number pad
  );
};

export const isModifierKey = (event) => {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
};

/// Mobile number format
export const enforceFormat = (event) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

export const formatToPhone = (event) => {
  if (isModifierKey(event)) {
    return;
  }

  // I am lazy and don't like to type things more than once
  const target = event.target;
  const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    target.value = `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    target.value = `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    target.value = `(${zip})`;
  }
};

export const convertToPhoneFormat = phone => {
  if (typeof phone !== 'string') {
    return
  }
  const input = phone?.replace(/\D/g, "").substring(0, 10);
  return `(${input.slice(0, 3)}) ${input.slice(3, 6)} - ${input.slice(6, 10)}`;
};


export const formatToPhoneWithoutCode = (event) => {
  if (isModifierKey(event)) {
    return;
  }

  // I am lazy and don't like to type things more than once
  const target = event.target;
  const input = event.target.value.replace(/\D/g, "").substring(0, 7); // First ten digits of input only
  const first = input.substring(0, 3);
  const last = input.substring(3, 7);
  // const last = input.substring(6, 10);

  if (input.length > 3) {
    target.value = `${first}-${last}`;
  } else if (input.length > 0) {
    target.value = `${first}`;
  }
};
//EIN format

export const formatToEIN = (event) => {
  if (isModifierKey(event)) {
    return;
  }

  // I am lazy and don't like to type things more than once
  const target = event.target;
  const input = event.target.value.replace(/\D/g, "").substring(0, 9); // First ten digits of input only
  const first = input.substring(0, 2);
  const last = input.substring(2, 9);

  if (input.length > 2) {
    target.value = `${first}-${last}`;

  } else if (input.length > 0) {
    target.value = `${first}`;
  }
};

/// SSN Format

export const formatToSSN = (event) => {
  const target = event.target;
  var input = event.target.value.replace(/\D/g, "").substring(0, 9);
  var newVal = "";
  var sizes = [3, 2, 4];

  for (var i in sizes) {
    if (input.length > sizes[i]) {
      newVal += input.substr(0, sizes[i]) + "-";
      input = input.substr(sizes[i]);
    } else break;
  }

  newVal += input;
  target.value = newVal;
};

export const formatToMoney = (event, number?: number) => {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // Store the current cursor position and selection length
  const selectionStart = event.target.selectionStart;
  const selectionEnd = event.target.selectionEnd;
  const selectionLength = selectionEnd - selectionStart;
  // get input value
  var input_val = event.target.value;

  // don't validate empty input
  if (input_val === "") {
    return;
  }

  // original length
  var original_len = input_val.length;

  // initial caret position
  var caret_pos = event.target.selectionStart ?? 0;

  // check for decimal
  if (input_val.indexOf(".") >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = input_val.indexOf(".");

    // split number by decimal point
    var left_side = input_val.substring(0, decimal_pos);
    var right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);

    // On blur make sure 2 numbers after decimal
    if (event.type === "blur") {
      right_side += "00";
    }

    // Limit decimal to only 2 or 3 digits
    right_side = right_side.substring(0, number ? number : 2);

    // join number by .
    input_val = left_side + "." + right_side;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);

    // final formatting
    if (event.type === "blur") {
      input_val += ".00";
    }
  }
  // send updated string to input
  event.target.value = input_val;
  // Calculate the adjustment needed for cursor position
  const adjustment = event.target.value.length - original_len;

  // Calculate the new cursor position based on the selection length
  const newSelectionStart = selectionStart + adjustment;
  const newSelectionEnd = selectionEnd + adjustment;

  // Set the updated selection range
  event.target.setSelectionRange(newSelectionStart, newSelectionEnd);


  // function formatNumber(n) {
  //   // format number 1000000 to 1,234,567
  //   return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }
  function formatNumber(n) {
    // Split the number by decimal point
    const parts = n.split(".");

    // Format only the part before the decimal point
    const formattedIntegerPart = parts[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // If there's a decimal part, append it after the formatted integer part
    return parts.length > 1 ? formattedIntegerPart + parts[1] : formattedIntegerPart;
  }

};

export function convertNumberToMoney(
  input: string | number | undefined | null,
  fix?: "string" | "number",
  digitsDecimal?: number
) {
  if (input === undefined || input === null) {
    if (fix === "string") return "";
    if (fix === "number") return 0;
    return "";
  }

  if (typeof input === fix) {
    return input;
  }

  if (typeof input === "string" && fix === "number") {
    return convertStringNum(input);
  }
  if (typeof input === "number" && fix === "string") {
    return convertNumToString(input);
  }

  if (typeof input === "string") {
    return convertStringNum(input);
  }

  if (typeof input === "number") {
    return convertNumToString(input);
  }

  function convertStringNum(input: string) {
    const cleanedString = input.replace(/[^0-9\.-]+/g, "");
    if (cleanedString === "") {
      return 0;
    } else {
      return parseFloat(cleanedString);
    }
  }

  function convertNumToString(input: number) {
    // Round the value to the specified number of decimal places
    const roundedValue = parseFloat(input.toFixed(digitsDecimal));

    // Determine the number of significant decimal places
    const decimalPart = roundedValue.toString().split('.')[1] || '';
    const hasSignificantDecimals = decimalPart.length > 2 && decimalPart.slice(2).replace(/0/g, '').length > 0;

    // Set the minimum and maximum fraction digits based on the presence of significant decimals
    const minFractionDigits = hasSignificantDecimals ? 2 : 2;
    const maxFractionDigits = hasSignificantDecimals ? digitsDecimal : 2;

    const formattedString = input.toString().startsWith('-') ? '-' + parseFloat(input.toString().substring(1,))
      .toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
      .substring(1)
      :
      input
        .toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: minFractionDigits,
          maximumFractionDigits: maxFractionDigits,
        })
        .substring(1);
    if (formattedString.endsWith(".")) {
      return formattedString.slice(0, -1);
    } else {
      return formattedString;
    }
  }

  return "";
}

export function formValidators(
  t: TFunction<"translation">,
  text: string,
  field: string,
  pattern: RegExp | undefined,
  password
) {
  switch (field) {
    case "first_name": {
      if (!text) return null;
      if (text && text.length >= 40)
        return t("validators.firstnameLessThan40Characters");
      return null;
    }
    case "last_name": {
      if (text == "") return t("validators.required");
      if (text && text.length >= 40)
        return t("validators.lastnameLessThan40Characters");
      return null;
    }
    case "email": {
      if (text == "") return t("validators.required");
      if (pattern != null && pattern.test(text) === false)
        return t("validators.enterValidEmail");
      return null;
    }
    case "city": {
      if (text == "") return t("validators.required");
      return null;
    }
    case "state": {
      if (text == "") return t("validators.required");
      return null;
    }
    case "zip_code": {
      if (text == "") return t("validators.required");
      return null;
    }
    case "street_address": {
      if (text == "") return t("validators.required");
      return null;
    }
    case "job_title": {
      if (!text) return null;
      return null;
    }
    case "company_summary": {
      if (text == "") return t("validators.required");
      return null;
    }
    case "website":
    case "linkedin_url":
    case "facebook_url":
    case "twitter_url":
    case "pinterest_url": {
      if (!text) return null;
      if (pattern != null && pattern.test(text) === false)
        return t("validators.enterValidUrl");
      return null;
    }
    case "phone": {
      if (!text) return null;
      // if (text == '') return t('validators.required');
      if (isNaN(parseInt(text))) return t("validators.enterValidPhoneNumber");
      if (pattern != null && pattern.test(text) === false)
        return t("validators.enterValidPhoneNumber");
      return null;
    }
    case "currentPassword": {
      if (text == "") return t("validators.required");
      if (text.match(" ")) return t("validators.passwordCannotContainSpaces");
      if (text && text.length < 6) return t("validators.passwordAtleast");
      return null;
    }
    case "password": {
      if (text == "") return t("validators.required");
      // if (text && text.length < 6) return t('validators.passwordAtleast');
      // if (text && text.length >= 15) return t('validators.passwordInbetween');
      // if (text.match(' ')) return t('validators.passwordCannotContainSpaces');
      // if (confirmPassword === text) return t('validators.passwordMustBeDifferent');
      return null;
    }
    case "confirmPassword": {
      if (text === "") return t("validators.required");
      if (text && text.length < 6) return t("validators.passwordAtleast");
      if (text && text.length >= 15) return t("validators.passwordInbetween");
      if (text.match(" ")) return t("validators.passwordCannotContainSpaces");
      if (password !== text) return t("validators.passwordDoNotMatch");
      return null;
    }
    default: {
      return null;
    }
  }
}

export function enforceUSFormat(value) {
  if (!value) return value;
  if (value.indexOf(".") > -1) {
    let amount = value.split(".");
    return `${amount[0]}.${amount[1].slice(0, 2)}`;
  }
  return value;
}
