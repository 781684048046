import './title_section_header.scss';

interface TitleSectionHeaderProps {
    title: string,
    children: React.ReactNode, 
    onClick?: () => void,
}

const TitleSectionHeader: React.FunctionComponent<TitleSectionHeaderProps> = (props) => {

    return (
        <a className="dsh-section-header-container" onClick={props.onClick}>
            <div className="title">
                <span>{props.title}</span>
            </div>
            <div className="actions">
                {props.children}
            </div>
        </a>
    )
}

export default TitleSectionHeader;