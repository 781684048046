import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as thunk from './backoffice_setup_thunk';
import { AppError, ICompanyBackOfficeSetup } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

interface UpdateState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialUpdateState: UpdateState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

export interface SingleCompanyBackOfficeSetupState {
    data: ICompanyBackOfficeSetup | null,
    error?: AppError | null,
    loading: LoadingType,
    update: UpdateState
};

export interface CompanyBackofficeSetupState {
    [key: string]: SingleCompanyBackOfficeSetupState,
}

const initialState: CompanyBackofficeSetupState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}
function getResponseSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}
export const companyBackOfficeSetupSlice = createSlice({
    name: 'companyBackOfficeSetup',
    initialState: initialState,
    reducers: {
        clearUpdateBackOfficeSetupError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    update: { ...initialUpdateState },
                }
            };
        },
        initializeState(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    loading: LoadingType.idle,
                    error: null,
                    update: { ...initialUpdateState },
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getCompanyBackOfficeSetupThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.company_id ?? action.meta.arg.department_id ?? ""]: {
                        ...getLoadingState(state[action.meta.arg.company_id ?? action.meta.arg.department_id ?? ""])
                    }
                })
            )
            .addCase(
                thunk.getCompanyBackOfficeSetupThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.company_id ?? action.meta.arg.department_id ?? ""]: {
                        ...getErrorState(state[action.meta.arg.company_id ?? action.meta.arg.department_id ?? ""], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getCompanyBackOfficeSetupThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.company_id ?? action.meta.arg.department_id ?? ""]: {
                        ...getSuccessState(state[action.meta.arg.company_id ?? action.meta.arg.department_id ?? ""], action.payload),
                    }
                })
            )

            // Updatae Backoffice setup values
            .addCase(
                thunk.updateCompanyBackOfficeSetupThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.data.company_id ?? action.meta.arg.data.department_id ?? ""]: {
                        ...state[action.meta.arg.data.company_id ?? action.meta.arg.data.department_id ?? ""],
                        update: {
                            ...getLoadingState(state[action.meta.arg.data.company_id ?? action.meta.arg.data.department_id ?? ""].update),
                        },
                    }
                })
            )
            .addCase(
                thunk.updateCompanyBackOfficeSetupThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.data.company_id ?? action.meta.arg.data.department_id ?? ""]: {
                        ...state[action.meta.arg.data.company_id ?? action.meta.arg.data.department_id ?? ""],
                        update: {
                            ...getErrorState(state[action.meta.arg.data.company_id ?? action.meta.arg.data.department_id ?? ""].update, action.payload),
                        },
                    }
                })
            )
            .addCase(
                thunk.updateCompanyBackOfficeSetupThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.data.company_id ?? action.meta.arg.data.department_id ?? ""]: {
                        ...state[action.meta.arg.data.company_id ?? action.meta.arg.data.department_id ?? ""],
                        update: {
                            ...getResponseSuccessState(state[action.meta.arg.data.company_id ?? action.meta.arg.data.department_id ?? ""].update, action.payload),
                        },
                    }
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const companyBackOfficeSetupSliceActions = companyBackOfficeSetupSlice.actions;
export const selectState = (state: CompanyBackofficeSetupState) => state;
export const selectSingleCompanyBackOfficeSetupState = (state: CompanyBackofficeSetupState, id: string): SingleCompanyBackOfficeSetupState => state[id];
export const selectInitialSingleCompanyBackOfficeSetupState = (): SingleCompanyBackOfficeSetupState => ({
    data: null,
    error: null,
    loading: LoadingType.idle,
    update: initialUpdateState
});