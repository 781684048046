import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import {
  selectAll,
  selectEntities,
  selectState,
  selectCountState
} from "./online_applications_received_list_reducer";

const select = (state: IRootState) =>
  state.dashboard.metrics.onlineApplicationReceivedList;
export const selectOnlineApplicationsReceivedListState = createSelector(
  select,
  selectState
);
export const selectOnlineApplicationsReceivedList = createSelector(
  select,
  selectAll
);
export const selectOnlineApplicationsReceivedEntities = createSelector(
  select,
  selectEntities
);

export const selectOnlineApplicationsReceivedCountState = createSelector(
  select,
  selectCountState
);
