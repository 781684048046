import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./contacts_list_all_reducer";

const select = (state: IRootState) => state.selectors.contactsListAll;
export const selectContactsListAllState = createSelector(select, selectState);
export const selectContactsListAll = createSelector(
    select,
    selectAll
);
export const selectContactsListAllEntities = createSelector(
    select,
    selectEntities
);