import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../enums";
import { AppError } from "../../interfaces";
import * as thunk from "./text_Em_All_thunk";

export interface TEAState {
  error?: AppError | null;
  loading: LoadingType;
  response: string ;
}

const initialState: TEAState = {
  error: null,
  loading: LoadingType.idle,
  response: "",
};

export const textEmAllSlice = createSlice({
  name: "textEmAll",
  initialState: initialState,
  reducers: {
    clearState(state){
      return {...state, ...initialState};
  },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getTEADraftBroadCast.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getTEADraftBroadCast.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
        response: "",
      }))
      .addCase(thunk.getTEADraftBroadCast.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        error: null,
        response: action.payload,
      }))

      .addCase(thunk.getTEAConversation.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getTEAConversation.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
        response: "",
      }))
      .addCase(thunk.getTEAConversation.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        error: null,
        response: action.payload,
      }))
      .addDefaultCase((state) => ({ ...state }));
  },
});


export const textEmAllSliceActions = textEmAllSlice.actions;
export const selectTEAState = (state: TEAState) => state;
export const selectLoading = (state: TEAState) => state.loading;
export const selectError = (state: TEAState) => state.error;