
import { ICreateUnEmploymentClaim, ICreateWorkCompClaim } from "../interfaces";
import { } from "../interfaces/talent";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const EmployeePortalAPI = {

    getAllEmployees(branch_id?: string) {
        if (branch_id) {
            return interceptor.get(
                `${UrlBuilder.allworkCompClaims}?branch_id=${branch_id}`
            );
        }
        return interceptor.get(UrlBuilder.allworkCompClaims);
    },

    getEmployeeById(work_comp_claim_id: string) {
        return interceptor.get(`${UrlBuilder.workCompClaims}?work_comp_claim_id=${work_comp_claim_id}`);
    },

    getEPPayrollDetails(ePortal: boolean, talent_id?: string) {
        if (ePortal && talent_id) {
            return interceptor.get(`${UrlBuilder.employeePortalPayrollDetails}?talent_id=${talent_id}`);
        }
        else {
            return interceptor.get(`${UrlBuilder.employeeDashboardPayrollDetails}`);
        }
    },

    postEmployees(doc: ICreateWorkCompClaim) {
        return interceptor.post(UrlBuilder.workCompClaims, doc);
    },

    deleteEmployees(work_comp_claims_id: string) {
        return interceptor.delete(`${UrlBuilder.workCompClaims}?work_comp_claims_id=${work_comp_claims_id}`);
    },

    getVacationHistoryDetails(ePortal: boolean, talent_id?: string) {
        if (ePortal && talent_id) {
            return interceptor.get(`${UrlBuilder.talentVacationHistory}?talent_id=${talent_id}`);
        }
        else {
            return interceptor.get(`${UrlBuilder.empVacationHistory}`);
        }
    },

    getTalentReversalCheckHistory(ePortal: boolean, talent_id?: string) {
        if (ePortal && talent_id) {
            return interceptor.get(`${UrlBuilder.talentPaycheckRevHistory}?talent_id=${talent_id}`);
        }
        else {
            return interceptor.get(`${UrlBuilder.empPaycheckRevHistory}`);
        }
    },
    getTalentW2(talent_id: string, year?: number) {
        let url = `${UrlBuilder.talentW2}?talent_id=${talent_id}`;
        if (year) {
            url += `&year=${year}`;
        }
        return interceptor.get(url);
    },
    getW2UploadURL(talent_id: string, year: number) {
        return interceptor.get(`${UrlBuilder.uploadW2}?talent_id=${talent_id}&year=${year}`)
    }
}