import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import { DateInput } from "../../../../components/date_input/date_input";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import { CONTACT_METHODS, LoadingType, THEME } from "../../../../enums";
import { ScheduledCallIcon } from "../../../../icons";
import { IProfileDropdown, ISelectorOption, ServiceCallInfo } from "../../../../interfaces";
import { manageServiceCallActions } from "../../../../redux/sales_marketing/service_call_activity/manage_service_call/manage_service_calls_reducer";
import { selectManageServiceCallUpdateState } from "../../../../redux/sales_marketing/service_call_activity/manage_service_call/manage_service_calls_selector";
import { updateServiceCall } from "../../../../redux/sales_marketing/service_call_activity/manage_service_call/manage_service_calls_thunk";
import {
  getContactsByCompany,
  getDashboardServiceCallsList,
  postSnackbarMessage,
  selectCompanyContactsList,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import {
  CustomButton,
  FormInput,
  mapOptions,
  SpinnerScreen,
} from "../../../../utils";
import { lowBtnStyle } from "../../../../variables";
import "../sales_call_activity/complete_sales_call.scss";

export interface CompleteServiceCallProps {
  showModal: boolean;
  callType: string;
  serviceCall: ServiceCallInfo;
  successTitle: string;
  currentLoginUserName?: string;
  usersList: IProfileDropdown[];
  onGotoAgencyDashboard: () => void;
  onGotoCreated: (id: string, name: string) => void;
  onClose: () => void,
  onDissmiss?: () => void,
  onSuccessClose: () => void,
}

export const CompleteServiceCall: FC<CompleteServiceCallProps> = (
  props: CompleteServiceCallProps
) => {
  const contactMethods: ISelectorOption[] = [
    { label: "email", value: CONTACT_METHODS.email },
    { label: "phone", value: CONTACT_METHODS.phone },
    { label: "text", value: CONTACT_METHODS.text },
    { label: "linked_in", value: CONTACT_METHODS.linkedIn },
  ];
  const { showModal, callType, serviceCall, usersList } = props;
  const [call, setCall] = useState<ServiceCallInfo>(serviceCall);
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);
  const actionRef = useRef<ActionDialogHolderType>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const updateState = useAppSelector((state) =>
    selectManageServiceCallUpdateState(state)
  );
  const contactsList = useAppSelector((state) =>
    selectCompanyContactsList(state)
  );

  useEffect(() => {
    if (updateState.loading == LoadingType.succeeded) {
      dispatch(postSnackbarMessage(updateState.response?.status ?? null));
      dispatch(getDashboardServiceCallsList({ call_completed: false }));
      // dispatch(manageServiceCallActions.clearUpdateServiceCallState());
      // closePopup();
    }
  }, [updateState.loading]);

  useEffect(() => {
    dispatch(
      getContactsByCompany({
        company_id: serviceCall.company_id,
        department_id: serviceCall.department_id ?? null,
      })
    );
    if (!serviceCall.date_of_actual_call) {
      setCall({
        ...serviceCall,
        date_of_actual_call: serviceCall.date_of_scheduled_call,
      });
    }
  }, []);


  const showSpinner = (): boolean => false;

  const handleSave = () => {
    setCheckValidationOnSubmit(true);
    if (!isFormValid()) {
      return;
    }
    if (!!call.call_result) {
      const newCall = { ...call };
      newCall.call_completed = true;
      dispatch(updateServiceCall(newCall));
      setCheckValidationOnSubmit(false);
    }
  };

  const updateServiceCallProp = (
    key: string,
    value: string | boolean | number
  ) => {
    const newserviceCall = { ...call };
    newserviceCall[key] = value;
    setCall(newserviceCall);
  };

  const isFormValid = () =>
    !!call.call_result && !!call.call_method;

  const validations = (value: { text: string; field: string }) => {
    switch (value.field) {
      case "call_method":
      case "call_result": {
        if (value.text === "" || value.text === undefined || value.text === null) return t("validators.required");
        return null;
      }
      default:
        return null;
    }
  };


  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={showModal}
      wrapperWidth={"60%"}
      onClose={props.onClose}
      onDissmiss={props.onClose}
    >
      <DialogWrapper onClose={() => closePopup(props.onClose)}>
        {showSpinner() ? (
          <div className="loading-spinner">
            <SpinnerScreen />
          </div>
        ) : getForm()}
      </DialogWrapper>
    </ActionDialogHolder>
  );

  function getForm() {
    if (updateState.loading === LoadingType.succeeded) {
      return (
        <div className="tlnt-f-container">
          <div className="tlnt-f-header">
            <FormDialogTilteHeader
              title={props.successTitle}
              titleIcon={<ScheduledCallIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <div className="tlnt-f-content">
            <div className="saved-doc-holder">
              <DocSavedText>
                <span className="aknw-name">
                  {t("good_job", {
                    name: props.currentLoginUserName,
                  })}
                </span>
                <span className="info-txt">{`${t("complete_call_recorded")}`}</span>
              </DocSavedText>
            </div>
          </div>
          <div className="tlnt-f-actions">
            <div className="btn-cancel">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize", ...lowBtnStyle.text }}
                name={t("back_to_my_agency_dashboard")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={handleGotoAgencyDashboard}
                customStyle={lowBtnStyle.btn}
              />
            </div>

            <div className="btn-save">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("go_to_service_call_activity")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() =>
                  handleGotoCreated(
                    `${call.contact_name ?? ""}`
                  )
                }
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="complete-sales-call">
        <div className="sales-call-header">
          <FormDialogTilteHeader
            title={`Complete Service Call: ${callType}`}
            titleIcon={<ScheduledCallIcon width={"100%"} height={"100%"} />}
          />
        </div>
        <div className="form-content">
          <div className="form-row section-divider">
            <div style={{ width: "45%" }}>
              <div className="form-row1 ">
                <div className="form-item">
                  <FormInput
                    id={"company_name"}
                    onChange={() => { }}
                    type={"text"}
                    value={serviceCall.company_name}
                    label={t("company")}
                    disabled
                    placeholder=""
                  />
                </div>
                <div className="form-item">
                  <FormInput
                    id={"department_name"}
                    onChange={() => { }}
                    type={"text"}
                    value={serviceCall.department_name}
                    label={t("department")}
                    disabled
                    placeholder=""
                  />
                </div>
                <div className="form-item">
                  <DateInput
                    id={"date_of_scheduled_call"}
                    onChange={() => { }}
                    value={serviceCall.date_of_scheduled_call}
                    label={"Date of Scheduled call"}
                    editable={false}
                    placeholder=""
                    labelStyles={{ padding: 5 }}
                    fieldStyles={{ marginRight: 20, pointerEvents: "none" }}
                  />
                </div>
                <div className="form-item" style={{ marginTop: "auto" }}>
                  <FormInput
                    id={"contact_name"}
                    onChange={() => { }}
                    type={"text"}
                    value={serviceCall.contact_name}
                    label={"Company Contact"}
                    disabled
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className="form-item" style={{ width: "50%" }}>
              <FormInput
                id={"call_goal"}
                onChange={updateServiceCallProp}
                multiline
                type={"text"}
                value={serviceCall.call_goal}
                label={"Call Goal"}
                placeholder=""
                numberOfLines={4}
              />
            </div>
          </div>
          <div className="form-row section2">
            <div style={{ width: "45%" }}>
              <div className="form-row1">
                <div className="form-item">
                  <CustomFormSelect
                    value={call.call_method ?? serviceCall.call_method}
                    name="call_method"
                    label={"Call Method"}
                    placeholder={t("select_placeholder")}
                    list={contactMethods}
                    onChange={(value) =>
                      updateServiceCallProp("call_method", value)
                    }
                    customStyle={{ width: "-webkit-fill-available" }}
                    validations={validations}
                    checkValidationOnSubmit={checkValidationOnSubmit}
                    required
                  />
                </div>
                <div className="form-item">
                  <CustomFormSelect
                    value={call.call_made_to_id}
                    name="call_made_to"
                    label={"Call made to"}
                    placeholder={t("select_placeholder")}
                    list={mapOptions(
                      contactsList.data ?? [],
                      "contact_name",
                      "id"
                    )}
                    onChange={(value) =>
                      updateServiceCallProp("call_made_to_id", value)
                    }
                    customStyle={{ width: "-webkit-fill-available" }}
                  />
                </div>
              </div>
            </div>
            <div className="form-item">
              <div className="label" style={{ marginTop: 0 }}>
                On what date did you make this call?
              </div>
              <DateInput
                id={"date_of_actual_call"}
                onChange={updateServiceCallProp}
                placeholder={""}
                value={call.date_of_actual_call}
                setHoursToZero={true}
                disableFuture
              />
            </div>
            <div>
              <FormInput
                id={"talent_name"}
                required={false}
                placeholder={""}
                type={"text"}
                label={"Talent"}
                labelStyles={{
                  fontSize: "0.850vw",
                  fontWeight: 700,
                }}
                customStyle={{ width: "12vw" }}
                value={call.talent_name}
                onChange={updateServiceCallProp}
              />
            </div>
          </div>
          <div className="form-row section3">
            <div>
              <div style={{ display: "flex", width: "72%" }}>
                <div className="form-item">
                  <div className="label">
                    Were you able to communicate with someone at this company?
                  </div>
                  <ToggleSwitch
                    label=""
                    checked={call.communicated_with_company}
                    onChange={(value) => {
                      updateServiceCallProp(
                        "communicated_with_company",
                        value
                      );
                    }}
                  />
                </div>
                <div className="form-item">
                  <div className="label">
                    Is this person a Decision Maker ?
                  </div>
                  <ToggleSwitch
                    label=""
                    checked={call.is_decision_maker}
                    onChange={(value) => {
                      updateServiceCallProp("is_decision_maker", value);
                    }}
                  />
                </div>
              </div>
              {/* <div style={{ display: "flex" }}> */}
              {/* <div className="form-item" style={{ marginRight: "2em"}}>
                  <FormInput
                    id={"planned_by_id"}
                    required={false}
                    placeholder={""}
                    type={"text"}
                    label={t("this_call_is_planned_by")}
                    labelStyles={{
                      fontSize: "0.850vw",
                      fontWeight: 700,
                      textTransform: "none"
                    }}
                    customStyle={{ width: "11.66vw" }}
                    value={props.currentLoginUserName}
                    onChange={updateServiceCallProp}
                    disabled
                  />
                </div> */}
              <div className="form-item">
                <CustomFormSelect
                  customStyle={{ width: "11.5vw" }}
                  name="assigned_to_id"
                  value={call.assignment_id ?? serviceCall.assignment_id}
                  label={t("this_call_is_assigned_to")}
                  placeholder={t("select_placeholder")}
                  list={usersList.map((doc) => ({
                    label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                    value: doc.id,
                  }))}
                  onChange={(value) => updateServiceCallProp("assigned_to_id", value)}
                  menuPlacement={"top"}
                />
              </div>
              {/* </div> */}
            </div>
            <div style={{ width: "57%" }}>
              <div className="form-item">
                <FormInput
                  id={"call_result"}
                  onChange={(f, v) => updateServiceCallProp(f, v)}
                  multiline
                  type={"text"}
                  value={call.call_result}
                  label={"What were the Call Results?"}
                  placeholder=""
                  numberOfLines={5}
                  validations={validations}
                  checkValidationOnSubmit={checkValidationOnSubmit}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-actions">
          <div className="btn-cancel">
            <CustomButton
              textStyle={{ textTransform: "capitalize" }}
              name={t("cancel")}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => closePopup()}
              enable
            />
          </div>
          <div className="btn-save">
            <CustomButton
              textStyle={{ textTransform: "capitalize" }}
              name={t("record_this_call")}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleSave}
              // enable={!!call.call_result}
              enable={true}
              loading={updateState.loading === LoadingType.pending}
            />
          </div>
        </div>
      </div>
    )
  }

  function handleGotoAgencyDashboard() {
    closePopup(props.onGotoAgencyDashboard);
  }

  function handleGotoCreated(name: string) {
    if (props.serviceCall && props.serviceCall.id) {
      closePopup(props.onSuccessClose);
    } else if (updateState.response && updateState.response.object_id) {
      closePopup(() => props.onGotoCreated(updateState?.response?.object_id!, name));
    }
  }

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }
};