import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import * as thunk from "./wotc_thunk";

export interface WOTCState {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
}

const initialState: WOTCState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}

export const wotcSlice = createSlice({
    name: 'wotc',
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...state, ...initialState };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getWOTCScreeningURLThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getWOTCScreeningURLThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
                response: null,
            }))
            .addCase(thunk.getWOTCScreeningURLThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                error: null,
                response: action.payload
            }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const wotcSliceActions = wotcSlice.actions;
export const selectState = (state: WOTCState) => state;



