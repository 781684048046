import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { THEME, LoadingType } from "../../../../enums";
import { AgencySetup } from "../../../../icons";
import { AppError } from "../../../../interfaces";
import { FormInput, CustomButton, RegxPattern, formatToMoney, allowNumbers, convertNumberToMoney } from "../../../../utils";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { US_states } from "../../../../utils/States_US";
import "./add_payroll_tax.scss"
import { AddPayRollTaxConfiguration, PayRollTaxConfiguration } from "../../../../interfaces/payroll_tax_configuration";
import { createPayRollTaxConfig, updatePayRollTaxConfig } from "../../../../redux/admin_center";
import { payrollTaxConfigTaxLimitOptions, payrollTaxConfigTaxOptions, payrollTaxConfigTaxTypeOptions } from "../../../../variables";
import { postSnackbarMessage, useAppDispatch } from "../../../../redux/store";
import { PayRollTaxConfigListState } from "../../../../redux/admin_center/payroll_tax_configuration/payroll_tax_configuration_reducer";
import ApiError from "../../../../components/api_error";
import { DateInput } from "../../../../components/date_input/date_input";

interface Props {
    visible: boolean;
    payrollTaxState?: PayRollTaxConfiguration;
    onClose: () => void;
    onDismiss: () => void;
    error: AppError | null | undefined;
    loading: LoadingType;
    onSuccessClose: () => void;
    onClearError: () => void;
    payRollTaxListState: PayRollTaxConfigListState;
}

const payrollTaxInitialForm: AddPayRollTaxConfiguration = {
    agency_id: "",
    id: "",
    payroll_tax_name: "",
    tax: "",
    tax_type_value: "",
    tax_rate: 0,
    start_date: 0,
    end_date: 0,
    tax_limit: "",
    tax_limit_wage_cap: 0,
    tax_limit_payment_cap: 0,
    state: "",
};

const getPayrollTaxFormData = (value: PayRollTaxConfiguration): AddPayRollTaxConfiguration => {
    return {
        id: value.id ?? "",
        agency_id: value.agency_id ?? "",
        payroll_tax_name: value.payroll_tax_name ?? "",
        tax: value.tax ?? "",
        tax_type_value: value.tax_type_value ?? "",
        tax_rate: value.tax_rate ?? 0,
        start_date: value.start_date ?? 0,
        end_date: value.end_date ?? 0,
        tax_limit: value.tax_limit ?? "",
        tax_limit_wage_cap: value.tax_limit_wage_cap ?? 0,
        tax_limit_payment_cap: value.tax_limit_payment_cap ?? 0,
        state: value.state ?? "",
    }
}

const PayrollTaxPopUp: React.FunctionComponent<Props> = (props) => {
    const { onClose, onDismiss, visible, payrollTaxState, loading, error, onSuccessClose, payRollTaxListState, onClearError } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [payrollTaxDetails, setPayrollTaxDetails] = useState<AddPayRollTaxConfiguration>(
        payrollTaxState ? getPayrollTaxFormData(payrollTaxState) : payrollTaxInitialForm
    );
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>();

    useEffect(() => {
        if (payRollTaxListState.create.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage("Payroll Tax Configuration added successfully!"));
            closePopup(onSuccessClose);
        }
        else if (payRollTaxListState.update.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage("Payroll Tax Configuration updated successfully!"));
            closePopup(onSuccessClose);
        }

    }, [payRollTaxListState.create.loading, payRollTaxListState.update.loading])

    function handleSave() {
        setCheckValidationOnSubmit(true);
        if (_isFormValid()) {
            if (payrollTaxState?.id) {
                dispatch(updatePayRollTaxConfig({
                    ...payrollTaxDetails,
                    payroll_tax_name: payrollTaxDetails.payroll_tax_name.trim(),
                    tax_limit_wage_cap: convertNumberToMoney(payrollTaxDetails.tax_limit_wage_cap, 'number') as number,
                    tax_limit_payment_cap: convertNumberToMoney(payrollTaxDetails.tax_limit_payment_cap, 'number') as number,
                    tax_rate: convertNumberToMoney(payrollTaxDetails.tax_rate, 'number') as number,
                }));
            }
            else {
                dispatch(createPayRollTaxConfig({
                    ...payrollTaxDetails,
                    payroll_tax_name: payrollTaxDetails.payroll_tax_name.trim(),
                    tax_limit_wage_cap: convertNumberToMoney(payrollTaxDetails.tax_limit_wage_cap, 'number') as number,
                    tax_limit_payment_cap: convertNumberToMoney(payrollTaxDetails.tax_limit_payment_cap, 'number') as number,
                    tax_rate: convertNumberToMoney(payrollTaxDetails.tax_rate, 'number') as number,
                }));
            }
            setCheckValidationOnSubmit(false);
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function handleOnChange(id, value) {
        if (id === "tax_rate") {
            if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
                setPayrollTaxDetails({ ...payrollTaxDetails, tax_rate: value })
            }
        }
        else {
            setPayrollTaxDetails({ ...payrollTaxDetails, [id]: value });

        }
    }
    const isRelevantPayrollTax = (payrollTaxName) => {
        if (typeof payrollTaxName !== 'string') {
            return false;
        }
        const relevantNames = ["SUTA", "Employment and Training Investment Assessment"];
        return relevantNames.includes(payrollTaxName.trim());
    };


    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'30%'}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="ac-ptc-apt-popup-container">
                    <div className="ac-ptc-apt-popup-header">
                        <FormDialogTilteHeader
                            title={payrollTaxState && payrollTaxState?.id ? "Edit Payroll Tax" : "Add Payroll Tax"}
                            titleIcon={<AgencySetup width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="ac-ptc-apt-popup-content">
                        <div className={"ac-ptc-apt-popup-input-holder"}>
                            <FormInput
                                id={"payroll_tax_name"}
                                onChange={(id, val) => handleOnChange(id, val)}
                                placeholder={"Payroll Tax Name"}
                                type={"text"}
                                value={payrollTaxDetails.payroll_tax_name}
                                required={true}
                                label={t("payroll_tax_name")}
                                // disabled={payrollTaxState?.id ? true : false}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                            />
                        </div>
                        <div className="ac-ptc-apt-popup-section-divider">
                            <div className={"ac-ptc-apt-popup-input-holder"}>
                                <CustomFormSelect
                                    list={payrollTaxConfigTaxOptions}
                                    name={"tax"}
                                    value={payrollTaxDetails.tax}
                                    required={true}
                                    label={t("tax")}
                                    placeholder={"Select"}
                                    onChange={(val) => handleOnChange('tax', val)}
                                    validations={formValidators}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className={"ac-ptc-apt-popup-input-holder"}>
                                <CustomFormSelect
                                    list={payrollTaxConfigTaxTypeOptions}
                                    name={"tax_type_value"}
                                    value={payrollTaxDetails.tax_type_value}
                                    required={false}
                                    label={t("tax_type")}
                                    placeholder={"Select"}
                                    onChange={(val) => handleOnChange('tax_type_value', val)}
                                />
                            </div>
                        </div>
                        <div className="ac-ptc-apt-popup-section-divider">
                            <div className={"ac-ptc-apt-popup-input-holder"}>
                                <DateInput
                                    id={'start_date'}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    placeholder={t("")}
                                    value={payrollTaxDetails.start_date}
                                    label={t('start_date')}
                                    setHoursToZero={true}
                                    required={isRelevantPayrollTax(payrollTaxState?.payroll_tax_name)}
                                    validations={isRelevantPayrollTax(payrollTaxState?.payroll_tax_name) ? formValidators : undefined}
                                    checkValidationOnSubmit={isRelevantPayrollTax(payrollTaxState?.payroll_tax_name) && checkValidationOnSubmit}
                                />
                            </div>
                            <div className={"ac-ptc-apt-popup-input-holder"}>
                                <DateInput
                                    id={'end_date'}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    placeholder={t("")}
                                    value={payrollTaxDetails.end_date}
                                    label={t('end_date')}
                                    setHoursToZero={true}
                                    required={isRelevantPayrollTax(payrollTaxState?.payroll_tax_name)}
                                    validations={isRelevantPayrollTax(payrollTaxState?.payroll_tax_name) ? formValidators : undefined}
                                    checkValidationOnSubmit={isRelevantPayrollTax(payrollTaxState?.payroll_tax_name) && checkValidationOnSubmit}
                                />
                            </div>
                        </div>
                        <div className="ac-ptc-apt-popup-section-divider">
                            <div className={"ac-ptc-apt-popup-input-holder"}>
                                <FormInput
                                    id={'tax_rate'}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    required={false}
                                    placeholder={""}
                                    type={"text"}
                                    value={payrollTaxDetails.tax_rate}
                                    label={t("tax_rate")}
                                    suffix={<span>%</span>}
                                    onKeyUp={(e) => { allowNumbers(e); }}
                                    onKeyDown={(e) => { allowNumbers(e); }}

                                />
                            </div>
                            <div className={"ac-ptc-apt-popup-input-holder"}>
                                <CustomFormSelect
                                    list={payrollTaxConfigTaxLimitOptions}
                                    name={"tax_limit"}
                                    value={payrollTaxDetails.tax_limit}
                                    required={false}
                                    label={t("tax_limit")}
                                    placeholder={"Select"}
                                    onChange={(val) => handleOnChange('tax_limit', val)}
                                />
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="ac-ptc-apt-popup-section-divider">
                            <div className={"ac-ptc-apt-popup-input-holder"}>
                                <FormInput
                                    id={'tax_limit_wage_cap'}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    required={false}
                                    placeholder={""}
                                    type={"text"}
                                    value={convertNumberToMoney(payrollTaxDetails.tax_limit_wage_cap, "string") as string}
                                    label={t("tax_limit_wage_cap")}
                                    prefix={<span>$</span>}
                                    onBlur={formatToMoney}
                                    onKeyDown={formatToMoney}
                                    onKeyUp={formatToMoney}
                                />
                            </div>
                            <div className={"ac-ptc-apt-popup-input-holder"}>
                                <CustomFormSelect
                                    list={US_states.map((doc) => ({
                                        label: doc.name,
                                        value: doc.code,
                                    }))}
                                    name={"state"}
                                    value={payrollTaxDetails.state}
                                    required={false}
                                    label={t("state")}
                                    placeholder={"Select"}
                                    onChange={(val) => handleOnChange('state', val)}
                                    menuPlacement={"top"}
                                />
                            </div>
                        </div>
                        <div className="ac-ptc-apt-popup-section-divider">
                            <div className={"ac-ptc-apt-popup-input-holder"}>
                                <FormInput
                                    id={'tax_limit_payment_cap'}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    required={false}
                                    placeholder={""}
                                    type={"text"}
                                    value={convertNumberToMoney(payrollTaxDetails.tax_limit_payment_cap, "string") as string}
                                    label={t("tax_limit_payment_cap")}
                                    prefix={<span>$</span>}
                                    onBlur={formatToMoney}
                                    onKeyDown={formatToMoney}
                                    onKeyUp={formatToMoney}
                                />
                            </div>
                        </div>
                        {error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={error?.message}
                                    onClose={onClearError}
                                />
                            </div>
                        )}
                    </div>
                    <div className="ac-ptc-apt-popup-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={(payrollTaxState && payrollTaxState?.id) ? t('update') : t('add')}
                                // enable={_isFormValid()}
                                enable={true}
                                loading={loading === LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={handleSave}
                            />
                        </div>

                    </div>
                </div>
            </DialogWrapper>


        </ActionDialogHolder>
    )

    function _isFormValid() {
        const { payroll_tax_name, tax, start_date, end_date } = payrollTaxDetails;
        if (payroll_tax_name === '') return false;
        if (tax === '') return false;
        if (isRelevantPayrollTax(payrollTaxState?.payroll_tax_name) && !start_date) return false;
        if (isRelevantPayrollTax(payrollTaxState?.payroll_tax_name) && !end_date) return false;
        return true;
    }

    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "payroll_tax_name": {
                if (value.text === "") return t("validators.required");
                return null;
            }
            case "tax": {
                if (value.text === "") return t("validators.required");
                return null;
            }
            case "start_date": {
                if (!value.text) return t("validators.required");
                return null;
            }
            case "end_date": {
                if (!value.text) return t("validators.required");
                return null;
            }
            default: {
                return null;
            }
        }
    }
}

export default PayrollTaxPopUp