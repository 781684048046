import { createAsyncThunk } from "@reduxjs/toolkit";
import { OnboardingPortalAPI } from "../../../apis";
import { S3API } from "../../../apis/s3_api";
import { AppError } from "../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../utils";

/// upload file
export const uploadEmployeeHandbookThunk = createAsyncThunk<
  any,
  { url: string, file: File | Blob },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/employee-handbook/upload/put",
  async (payload, thunkAPI) => {
    try {
      const res = await S3API.uploadFile(payload.url, payload.file);
      // if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

/// upload url
export const getUploadEmployeeHandbookUrlThunk = createAsyncThunk<
  { url: string },
  { token?: string, talent_id?: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/employee-handbook/upload-url/get",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.getHandbookUploadUrl(payload);
      if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

/// Download url
export const getDownloadEmployeeHandbookUrlThunk = createAsyncThunk<
  { url: string },
  { token?: string, talent_id?: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/employee-handbook/download-url/get",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.getHandbookDownloadUrl(payload);
      if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);