import {
  ICreateTalentOnboardingData, ITalentOnBoardingData,
} from "../interfaces";
import { stringifyParams } from "../utils";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const OnboardingPortalAPI = {
  onboardingVerifyToken(token: string,refNumber?: string | undefined) {
    if(refNumber !== undefined)
    {
      return interceptor.post(
        `${UrlBuilder.onboardingPortalVerifyToken}?token=${token}&reference_number=${refNumber}`
      );
    }
    return interceptor.post(
      `${UrlBuilder.onboardingPortalVerifyToken}?token=${token}`
    );

  },
  getWOTCScreeningURL(token: string) {
    return interceptor.post(
      `${UrlBuilder.onboardingWOTCScreeningUrl}?onboarding_token=${token}`
    );
  },
  getESCEnrollmentURL(token: string,data:any) {
    return interceptor.post(
      `${UrlBuilder.onboardingESCEnrollmentUrl}?onboarding_token=${token}`,data);
  },
  /// [talent onboarding]
  postTalentOnBoarding(data: ICreateTalentOnboardingData) {
    return interceptor.post(`${UrlBuilder.talentOnBoarding}`, data);
  },
  patchTalentOnBoarding(data: ITalentOnBoardingData) {
    return interceptor.patch(`${UrlBuilder.talentOnBoarding}`, data);
  },
  deleteTalentOnBoarding(id: string) {
    return interceptor.delete(`${UrlBuilder.talentOnBoarding}?onboarding_id=${id}`);
  },
  postValidateDDOnBoarding(data: ITalentOnBoardingData) {
    return interceptor.post(`${UrlBuilder.validateOBDD}`, data);
  },

  /// [upload urls]
  getHandbookUploadUrl(data: { token?: string, talent_id?: string }) {
    return interceptor.get(`${UrlBuilder.talentOnBoardingGetHandbookUploadUrl}?${stringifyParams(data)}`);
  },
  getDirectDepositUploadUrl(data: { token?: string, talent_id?: string }) {
    return interceptor.get(`${UrlBuilder.talentOnBoardingGetDirectDepositUploadUrl}?${stringifyParams(data)}`);
  },
  getFw4UploadUrl(data: { token?: string, talent_id?: string }) {
    return interceptor.get(`${UrlBuilder.talentOnBoardingGetFw4uploadUrl}?${stringifyParams(data)}`);
  },
  getI9UploadUrl(data: { token?: string, talent_id?: string }) {
    return interceptor.get(`${UrlBuilder.talentOnBoardingGetI9UploadUrl}?${stringifyParams(data)}`);
  },
  getNoticeOfHealthcareExchangeUploadUrl(data: { token?: string, talent_id?: string }) {
    return interceptor.get(`${UrlBuilder.talentOnBoardingGetNoticeOfHealthcareExchangeUploadUrl}?${stringifyParams(data)}`);
  },

  /// [Download urls]
  getHandbookDownloadUrl(data: { token?: string, talent_id?: string }) {
    return interceptor.get(`${UrlBuilder.talentOnBoardingGetHandbookDownloadUrl}?${stringifyParams(data)}`);
  },
  getDirectDepositDownloadUrl(data: { token?: string, talent_id?: string }) {
    return interceptor.get(`${UrlBuilder.talentOnBoardingGetDirectDepositDownloadUrl}?${stringifyParams(data)}`);
  },
  getFw4DownloadUrl(data: { token?: string, talent_id?: string }) {
    return interceptor.get(`${UrlBuilder.talentOnBoardingGetFw4DownloadUrl}?${stringifyParams(data)}`);
  },
  getI9DownloadUrl(data: { token?: string, talent_id?: string }) {
    return interceptor.get(`${UrlBuilder.talentOnBoardingGetI9DownloadUrl}?${stringifyParams(data)}`);
  },
  getNoticeOfHealthcareExchangeDownloadUrl(data: { token?: string, talent_id?: string }) {
    return interceptor.get(`${UrlBuilder.talentOnBoardingGetNoticeOfHealthcareExchangeDownloadUrl}?${stringifyParams(data)}`);
  },
};
