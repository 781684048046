
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./transaction_type_reducer";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.TransactionTypeList;
export const selectTransactionTypeListState = createSelector(select, selectState);
export const selectTransactionTypeList = createSelector(
    select,
    selectAll
);
export const selectTransactionTypeListEntities = createSelector(
    select,
    selectEntities
);