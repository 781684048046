import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ContactMethod } from "../../../../interfaces";
import * as thunk from "./contact_method_thunk";

export interface ContactMethodState extends EntityState<ContactMethod> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const contactMethodAdapter: EntityAdapter<ContactMethod> = createEntityAdapter<ContactMethod>({
    selectId: (doc) => doc.id
})

const initialState: ContactMethodState = contactMethodAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const contactMethodSlice = createSlice({
    name: 'contactMethod',
    initialState: initialState,
    reducers: {
        clearContactMethodListError(state){
            return {...state, error: null};
        },
        clearContactMethodListState(state){
            return contactMethodAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearContactMethodCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearContactMethodUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearContactMethodDeleteState(state) {
            return { ...state, remove : {...initialState.remove} };
        },
        clearContactMethodCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearContactMethodUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearContactMethodDeleteStateError(state) {
            return { ...state, remove : { ...initialState.remove , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getContactMethods.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getContactMethods.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getContactMethods.fulfilled, (state, action) => contactMethodAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createContactMethod.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createContactMethod.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createContactMethod.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteContactMethod.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteContactMethod.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteContactMethod.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateContactMethod.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateContactMethod.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateContactMethod.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const contactMethodActions = contactMethodSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = contactMethodAdapter.getSelectors();
export const selectState = (state: ContactMethodState) => state;
export const selectLoading = (state: ContactMethodState) => state.loading;
export const selectError = (state: ContactMethodState) => state.error;


