import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI } from "../../../../apis";
import { AppError, ICompanyBackOfficeInvoiceDocs } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getCompanyBackOfficeInvoiceDocsThunk = createAsyncThunk<
    ICompanyBackOfficeInvoiceDocs,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/details/back_office_invoice_docs/get',
    async (query, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getBackOfficeInvoiceDocs(query);
            if (typeof res.data === 'string') return [];
            return res.data as ICompanyBackOfficeInvoiceDocs;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const updateCompanyBackOfficeInvoiceDocsThunk = createAsyncThunk<
    string,
    { data: ICompanyBackOfficeInvoiceDocs, isNotCredit: boolean, isRemove?: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/details/back_office_invoice_docs/patch',
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.updateBackOfficeInvoiceDocs(payload.data);
            if (payload.isNotCredit || payload.isRemove) {
                thunkAPI.dispatch(getCompanyBackOfficeInvoiceDocsThunk(payload.data.company_id));
            }
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);