import { createSlice } from "@reduxjs/toolkit";
import { AppError } from "../../../interfaces";
import { LoadingType } from "../../../enums";
import * as thunk from "./info_text_thunk";
import { AdminCenterInfoTextCategory } from "../../../interfaces/admin_center_info_text_category";


export interface AdminCenterInfoTextState {
    data: AdminCenterInfoTextCategory | null,
    error?: AppError | null,
    loading: LoadingType,
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string,
    }
}



const initialState: AdminCenterInfoTextState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    update: {
        error: null,
        loading: LoadingType.idle,
        response: "",
    }
}


export const adminCenterInfoTextSlice = createSlice({
    name: 'adminCenterInfoText',
    initialState: initialState,
    reducers: {
        clearAdminCenterInfoTextError(state) {
            return { ...state, update : { ...initialState.update , error: null }};
        },
        clearAdminCenterInfoTextState(state) {
            return ({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAdminCenterInfoText.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getAdminCenterInfoText.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAdminCenterInfoText.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, data: action.payload, }))
            .addCase(thunk.updateAdminCenterInfoText.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.updateAdminCenterInfoText.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.error } }))
            .addCase(thunk.updateAdminCenterInfoText.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearAdminCenterInfoTextError, clearAdminCenterInfoTextState } = adminCenterInfoTextSlice.actions;
export const selectState = (state: AdminCenterInfoTextState) => state;
export const selectLoading = (state: AdminCenterInfoTextState) => state.loading;
export const selectError = (state: AdminCenterInfoTextState) => state.error;