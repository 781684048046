import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { AppError } from "../../../../interfaces";
import { IGrossProfit } from "../../../../interfaces/report_center";
import { catchRequestError } from "../../../../utils";

export const getGrossProfitThunk = createAsyncThunk<
    IGrossProfit,
    { start_date: number, end_date: number },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/finance_accounting/gross_profit/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getGrossProfitData(payload.start_date, payload.end_date);
            return res.data as IGrossProfit;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });