import { useTranslation } from "react-i18next";
import RenderInput from "../../../../../components/render_input/render_input";
import { AgencyBranch, FormInputOption } from "../../../../../interfaces";
import { formatToPhone, enforceFormat, RegxPattern } from "../../../../../utils";
import { US_states } from "../../../../../utils/States_US";


interface Props {
    formState: AgencyBranch,
    handleFieldChange:(fieldId: string, value: any, parent?: string)=>void
}

const StepTwoAgencyBranchForm: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const {formState, handleFieldChange} = props

    const formAgencyInformation: (FormInputOption | FormInputOption[])[] = [
        /// 1st row
        [
            {
                field: "name",
                type: "text",
                label: 'name',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "phone",
                type: "tel",
                label: 'mobile',
                secure: false,
                required: false,
                placeholder: "(555) 555 - 5555",
                keyboardtype: '',
                maxLength: 16,
                onKeyUp: formatToPhone,
                onKeyDown: enforceFormat,
            },
        ],
        /// 2nd row
        [
            {
                field: "street_address",
                type: "text",
                label: 'street_address',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "city",
                type: "text",
                label: 'city',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "state",
                type: "selector",
                label: 'state',
                secure: false,
                required: false,
                placeholder: "select",
                keyboardtype: '',
                selectorList: US_states.map((doc) => ({
                    label: doc.name,
                    value: doc.code,
                })),
                multi: false,
            },
            {
                field: "zip_code",
                type: "text",
                label: 'zip_code',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
        ],

        /// 3rd row
        [
            {
                field: "web_url",
                type: "text",
                label: 'web_url',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "sic_code",
                type: "text",
                label: 'sic_code',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "fax",
                type: "text",
                label: 'fax',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
        ],
    ];
    return (
        <div className="uf-section-divider">
            <div className="uf-row flx-start">
                <div className="section-title-txt">
                    <span>{t("branch_basic_information").toLocaleUpperCase()}</span>
                </div>
            </div>
            {formAgencyInformation.map((doc, index) => {
                if (Array.isArray(doc)) {
                    return (
                        <div key={index + "row"} className="uf-row ">
                            {doc.map((subDoc, subIndex) => {
                                return (
                                    <RenderInput
                                        key={index + subIndex}
                                        doc={subDoc}
                                        index={subIndex}
                                        formState={formState}
                                        handleFieldChange={handleFieldChange}
                                        formValidators={agencyBranchFormValidators}
                                        className={"uf-input-holder"}
                                    />
                                );
                            })}
                        </div>
                    );
                }

                return (
                    <RenderInput
                        key={index}
                        doc={doc}
                        index={index}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        formValidators={agencyBranchFormValidators}
                        className={"uf-input-holder"}
                    />
                );
            })}
            <div className="uf-row">
                <div>
                    <RenderInput
                        doc={{
                            field: "search_and_import_data",
                            type: "switch",
                            label: "search_and_import_data",
                            secure: false,
                            required: false,
                            placeholder: "",
                            keyboardtype: "",
                            labelPosition: "top",
                        }}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        className={"tlnt-f-input-holder"}
                        customStyle={{ flex: 1 }}
                    />
                </div>
            </div>
        </div>


    );

    
    function agencyBranchFormValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "name": {
                if (value.text === "") return null;
                if (RegxPattern.username.test(value.text) === false)
                    return t("validators.enterValidName");
                return null;
            }
            case "phone": {
                if (value.text === "") return null;
                if (RegxPattern.usPhone.test(value.text) === false)
                    return t("validators.enterValidPhoneNumber");
                return null;
            }
            case "zip_code": {
                if (value.text === "") return null;
                if (RegxPattern.zipCode.test(value.text) === false)
                    return t("validators.enterValidZipCode");
                return null;
            }
            case "web_url": {
                if (value.text === "") return null;
                if (RegxPattern.url.test(value.text) === false)
                    return t("validators.enterValidUrl");
                return null;
            }
            default: {
                return null;
            }
        }
    }
}

export default StepTwoAgencyBranchForm