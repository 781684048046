import { combineReducers } from "@reduxjs/toolkit";
import { dashboardMetricsReducers } from "./metrics";
import { onboardingReducers } from "./onboarding";

export const dashboardReducers = combineReducers({
    'metrics': dashboardMetricsReducers,
    'onboarding': onboardingReducers,
});

export * from './metrics';
export * from './onboarding';