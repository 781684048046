import { createSlice, Dictionary, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums"
import { AppError, IDepartment, ISkillOption } from "../../../../../interfaces"
import * as thunk from './departments_thunk';


interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

interface RemoveState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialRemoveState: RemoveState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


/// [Skills]
export interface SingleCompanyDepartmentsState {
    data: Array<IDepartment> | null,
    entities: Dictionary<IDepartment>,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}



const singleCompanyDepartmentsInitialState: SingleCompanyDepartmentsState = {
    data: null,
    entities: {},
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}


export interface CompanyDepartmentstate {
    [key: string]: SingleCompanyDepartmentsState,
}


const initialState: CompanyDepartmentstate = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: IDepartment[]) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        entities: payload.reduce((obj, item) => (obj[item.id] = item, obj) ,{}),
        error: null,
    }
}

function getResponseSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}

export const companyDepartmentSlice = createSlice({
    name: 'departments',
    initialState: initialState,
    reducers: {
        clearCompanyDepartmentAddOptionError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: {
                        ...state[action.payload].add,
                        ...initialAddState,
                    },
                }
            };
        },

        clearCompanyDepartmentRemoveOptionError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    remove: {
                        ...state[action.payload].remove,
                        ...initialAddState,
                    },

                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getCompanyDepartments.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...getLoadingState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId]),
                        add: initialAddState,
                        remove: initialRemoveState,
                    }
                })
            )
            .addCase(
                thunk.getCompanyDepartments.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...getErrorState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getCompanyDepartments.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.departmentId ?? action.meta.arg.companyId]: {
                        ...getSuccessState(state[action.meta.arg.departmentId ?? action.meta.arg.companyId], action.payload),
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const companyDepartmentSliceActions = companyDepartmentSlice.actions;
export const selectCompanyDepartmentsState = (state: CompanyDepartmentstate) => state;
export const selectSingleCompanyDepartmentsState = (state: CompanyDepartmentstate, id: string): SingleCompanyDepartmentsState => state[id];

export const selectInitialSingleCompanyDepartmentsState = (): SingleCompanyDepartmentsState => ({
    data: null,
    entities: {},
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
});