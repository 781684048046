import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { IReportCenterService } from "../../../../interfaces/report_center_service";

export const getArrivalCheckCallsReportList = createAsyncThunk<
    Array<IReportCenterService>,
    { start_date: number, end_date: number },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/arrival_check_calls_report/post",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getServiceRCArrivalCheckCallsReport(payload.start_date, payload.end_date);
            if (typeof res.data === "string") return [];
            const arr = res.data;
            return arr as IReportCenterService[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });