import { combineReducers } from "@reduxjs/toolkit";
import { reportCenterJobOrdersReducers } from "./job_orders";
import { reportCenterSalesAndMarketingReducers } from "./sales_and_marketing";
import { reportCenterServiceReducers } from "./service";
import { reportCenterPayrollTaxReducers } from "./payroll_tax";
import { reportCenterFinanceAccountingReducers } from "./finance_accounting";
import { reportDownloadSlice } from "./download_report/download_report_reducer";
import { recentClosedWeekSlice } from "./recently_closed_week/recently_closed_week_reducer";
import { reportCenterCompaniesReducers } from "./companies";
import { reportCenterTalentReducers } from "./talent";

export const reportCenterReducers = combineReducers({
    "reportCenterJobOrders": reportCenterJobOrdersReducers,
    "reportCenterSalesAndMarketing": reportCenterSalesAndMarketingReducers,
    "reportCenterService": reportCenterServiceReducers,
    "reportCenterPayrollTax": reportCenterPayrollTaxReducers,
    "reportCenterFinanceAccounting": reportCenterFinanceAccountingReducers,
    "reportCenterCompanies": reportCenterCompaniesReducers,
    "reportCenterTalent": reportCenterTalentReducers,
    [reportDownloadSlice.name] :reportDownloadSlice.reducer,
    [recentClosedWeekSlice.name] :recentClosedWeekSlice.reducer

});

export * from "./job_orders";
export * from "./sales_and_marketing";
export * from "./service";
export * from './payroll_tax';
export * from './finance_accounting';
export * from './companies';
export * from './talent';

export * from "./download_report/download_report_thunk";
export * from "./download_report/download_report_selector";

export * from "./recently_closed_week/recently_closed_week_thunk";
export * from "./recently_closed_week/recently_closed_week_selector";
