import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ITrafficLightEngagementDetails } from "../../../../interfaces";
import * as thunk from "./contact_taffic_engagement_thunk";

export interface ContactTrafficLightEngagementState {
    data: ITrafficLightEngagementDetails | null,
    error?: AppError | null,
    loading: LoadingType,
}



const initialState: ContactTrafficLightEngagementState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
}


export const contactTrafficLightEngagementSlice = createSlice({
    name: 'contactTrafficLightEngagement',
    initialState: initialState,
    reducers: {
        clearContactTrafficLightEngagementError(state) {
            return { ...state, error: null };
        },
        clearContactTrafficLightEngagementState(state) {
            return ({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getDashboardContactTrafficEngagement.pending,
                (state, action) => ({
                    ...state,
                    loading:  LoadingType.pending,
                    error: null,
                })
            )
            .addCase(
                thunk.getDashboardContactTrafficEngagement.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getDashboardContactTrafficEngagement.fulfilled,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null,
                    data: action.payload,
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearContactTrafficLightEngagementError, clearContactTrafficLightEngagementState } = contactTrafficLightEngagementSlice.actions;
export const selectState = (state: ContactTrafficLightEngagementState) => state;
export const selectLoading = (state: ContactTrafficLightEngagementState) => state.loading;
export const selectError = (state: ContactTrafficLightEngagementState) => state.error;


