import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./export_page_thunk";
import { AppError, PayrollBatch } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

export interface ExportFileState {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;

}

const initialState: ExportFileState = {
    error: null,
    loading: LoadingType.idle,
    response: null,

};

export const exportFileSlice = createSlice({
    name: "exportFileSlice",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearErrorState(state) {
            return { ...initialState, error: null };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.exportPageThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.exportPageThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.exportPageThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))

            .addDefaultCase((state) => ({ ...state }));
    },
});
export const exportPageSliceActions = exportFileSlice.actions;
export const selectState = (state: ExportFileState) => state;
