import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import * as thunk from "./work_comp_config_dropdown_thunk";
import { AppError } from "../../../interfaces";
import { LoadingType } from "../../../enums";

export interface WorkCompConfigDropdownState extends EntityState<{ id: string, work_comp_code: string }> {
    error?: AppError | null,
    loading: LoadingType,
}

const WorkCompConfigDropdownAdapter: EntityAdapter<{ id: string, work_comp_code: string }> = createEntityAdapter<{ id: string, work_comp_code: string }>({
    selectId: (doc) => doc.id,
})

const initialState: WorkCompConfigDropdownState = WorkCompConfigDropdownAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const WorkCompConfigDropdownSlice = createSlice({
    name: 'workCompConfigDropdown',
    initialState: initialState,
    reducers: {
        clearWorkCompConfigDropdownError(state){
            return {...state, error: null};
        },
        clearWorkCompConfigDropdownState(state){
            return WorkCompConfigDropdownAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getWorkCompConfigDropdown.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getWorkCompConfigDropdown.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getWorkCompConfigDropdown.fulfilled, (state, action) => WorkCompConfigDropdownAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearWorkCompConfigDropdownError, clearWorkCompConfigDropdownState } = WorkCompConfigDropdownSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = WorkCompConfigDropdownAdapter.getSelectors();
export const selectState = (state: WorkCompConfigDropdownState) => state;