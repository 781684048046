import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./invoice_correction_thunk";
import { AppError, IBillingInvoicesReportData, ICorrectTimeCards } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

export interface InvoiceCorrectionState {
    error?: AppError | null;
    loading: LoadingType;
    response: ICorrectTimeCards[] | null;
    update: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    }
}

const initialState: InvoiceCorrectionState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    update: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
};

export const invoiceCorrectionSlice = createSlice({
    name: "invoiceCorrection",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearUpdateState(state) {
            return {
                ...initialState,
                update: { ...initialState.update }
            };
        },
        clearErrorState(state) {
            return { ...initialState, error: null };
        },
        clearUpdateErrorState(state) {
            return {
                ...state,
                update: { ...state.update, error: null }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCorrectionTimecards.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getCorrectionTimecards.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getCorrectionTimecards.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))

            .addCase(thunk.correctTimecards.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.correctTimecards.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.correctTimecards.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase((state) => ({ ...state }));
    },
});
export const InvoiceCorrectionSliceActions = invoiceCorrectionSlice.actions;
export const selectState = (state: InvoiceCorrectionState) => state;
export const selectAll = (state: InvoiceCorrectionState) => state.response;
