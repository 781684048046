import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, ICompanyCredential } from "../../../interfaces";
import * as thunk from "./credentials_list_thunk";

export interface CredentialsListState extends EntityState<ICompanyCredential> {
    error?: AppError | null,
    loading: LoadingType,
}

const credentialsListAdapter: EntityAdapter<ICompanyCredential> = createEntityAdapter<ICompanyCredential>({
    selectId: (doc) => doc.id,
})

const initialState: CredentialsListState = credentialsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const credentialsListSlice = createSlice({
    name: 'credentialsList',
    initialState: initialState,
    reducers: {
        clearCredentialsListError(state){
            return {...state, error: null};
        },
        clearCredentialsListState(state){
            return credentialsListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCredentialsList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getCredentialsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCredentialsList.fulfilled, (state, action) => credentialsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearCredentialsListError, clearCredentialsListState } = credentialsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = credentialsListAdapter.getSelectors();
export const selectState = (state: CredentialsListState) => state;
export const selectLoading = (state: CredentialsListState) => state.loading;
export const selectError = (state: CredentialsListState) => state.error;


