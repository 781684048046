import CodeRateOptions from "./code_rate_options/code_rate_options";
import MarkUpShiftPO from "./markup_shift_po/markup_shift_po";
import './company_back_office_invoice_docs.scss';
import { useTranslation } from "react-i18next";
import { getCompanyBackOfficeInvoiceDocsThunk, postSnackbarMessage, selectProfileState, selectSingleCompanyBOSetupById, updateCompanyBackOfficeInvoiceDocsThunk, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { RouteComponentProps } from "react-router-dom";
import { ICompBOInvoiceDocsMarkUp, ICompBOInvoiceDocsPOoptions, ICompBOInvoiceDocsRatesJobTitle, ICompBOInvoiceDocsWCCodes, ICompanyBackOfficeInvoiceDocs } from "../../../../interfaces";
import { useEffect, useState } from "react";
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton, SpinnerScreen, convertNumberToMoney } from "../../../../utils";
import { companyBOInvoiceDocsSliceActions } from "../../../../redux/companies/details/back_office_invoices_and_docs/back_office_invoices_and_docs_reducer";
import { RightArrowIcon } from "../../../../icons";
import CompanyBOInvoiceEdocsCard from "../../../../components/companies_back_office_card/company_invoice_edoc_card";
import { ArrowForward } from "@material-ui/icons";


interface Props extends RouteComponentProps<any> { }
const getBackOfficeInvoiceDocsForm = (value?: ICompanyBackOfficeInvoiceDocs | null): ICompanyBackOfficeInvoiceDocs => {
    if (value) {
        return {
            id: value ? value.id : '',
            company_id: value ? value.company_id : '',
            mark_up: value.mark_up,
            po_options: value.po_options,
            rates_by_job_title: value.rates_by_job_title,
            work_comp_codes: value.work_comp_codes
        }
    }
    return {
        id: "",
        company_id: "",
        mark_up: [{
            mark_up_name: "",
            regular_mark_up: 0,
            over_time_mark_up: 0,
            double_time_mark_up: 0,
            is_active: false
        }],
        po_options: [{
            po_number: "",
            description: "",
            po_limit: 0,
            warning_value: 0,
            amount_used: 0
        }],
        rates_by_job_title: [{
            job_title: "",
            shift: "",
            mark_up_date: 0,
            pay_rate: 0
        }],
        work_comp_codes: [{
            wc_code: "",
            base_rate: 0,
            description: "",
            wc_code_id: ""
        }]
    }
};
const PageLoading = () => {
    return (
        <div className="table-loading">
            <SpinnerScreen />
        </div>
    );
}
const CompanyBackOfficeInvoiceDocs: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { match, location } = props;
    const { params } = match;
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`;
    const companyId = params.id;
    const departmentId = params.departmentId;

    const backOfficeInvoiceDocsState = useAppSelector((state) => selectSingleCompanyBOSetupById(companyId)(state));
    const updateBackOfficeInvoiceDocsState = backOfficeInvoiceDocsState.update;
    const getInvoiceDocsData = () => {
        dispatch(getCompanyBackOfficeInvoiceDocsThunk(companyId));
    }
    useEffect(() => {
        getInvoiceDocsData();
        // handleUpdateClearState();
    }, []);
    const [formState, setFormState] = useState<ICompanyBackOfficeInvoiceDocs>(getBackOfficeInvoiceDocsForm());

    useEffect(() => {
        if (backOfficeInvoiceDocsState && backOfficeInvoiceDocsState.loading === LoadingType.succeeded) {
            setFormState(getBackOfficeInvoiceDocsForm(backOfficeInvoiceDocsState.data));
            handleUpdateClearState();
        }
    }, [backOfficeInvoiceDocsState, backOfficeInvoiceDocsState.loading])

    useEffect(() => {
        if (updateBackOfficeInvoiceDocsState !== undefined && updateBackOfficeInvoiceDocsState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(updateBackOfficeInvoiceDocsState.response ?? null));
        }
        if (updateBackOfficeInvoiceDocsState !== undefined && updateBackOfficeInvoiceDocsState.loading === LoadingType.pending) {
            setFormState(formState);
        }
    }, [updateBackOfficeInvoiceDocsState, updateBackOfficeInvoiceDocsState?.loading]);
    function handleClearError() {
        dispatch(companyBOInvoiceDocsSliceActions.clearUpdateBackOfficeInvoiceDocsError(companyId));
    };
    function handleUpdateClearState() {
        dispatch(companyBOInvoiceDocsSliceActions.initializeState(companyId));
    };
    // Adding Rates by Job API call
    const handleAddRateJob = (doc: ICompBOInvoiceDocsRatesJobTitle, indexToUpdate?: number, removeItem?: boolean): void => {
        const updatedDoc = {
            ...doc,
            mark_up_date: convertNumberToMoney(doc.mark_up_date, 'number') as number,
            pay_rate: convertNumberToMoney(doc.pay_rate, 'number') as number,

        }
        if (formState && formState.rates_by_job_title) {
            if (removeItem && indexToUpdate !== undefined) {
                const updatedRateJobs = formState.rates_by_job_title.filter((_, index) => index !== indexToUpdate);
                if (indexToUpdate !== -1) {
                    dispatch(updateCompanyBackOfficeInvoiceDocsThunk({
                        data: {
                            ...formState,
                            rates_by_job_title: updatedRateJobs,
                        },
                        isNotCredit: true
                    }));
                }
            }
            else {
                if (indexToUpdate === undefined) {
                    dispatch(updateCompanyBackOfficeInvoiceDocsThunk({
                        data: {
                            ...formState,
                            rates_by_job_title: formState.rates_by_job_title.concat(updatedDoc),
                        }, isNotCredit: true
                    }));
                }
                else if (indexToUpdate !== -1) {
                    const updatedRateJobs = [...formState.rates_by_job_title];
                    updatedRateJobs[indexToUpdate!] = {
                        ...updatedRateJobs[indexToUpdate!],
                        ...updatedDoc,
                    };

                    dispatch(updateCompanyBackOfficeInvoiceDocsThunk({
                        data: {
                            ...formState,
                            rates_by_job_title: updatedRateJobs,
                        }, isNotCredit: true
                    }));
                }
            }
        }
    };
    const handleAddWCCode = (doc?: { wc_code_id: string; }[], removeId?: string, removeItem?: boolean): void => {
        if (formState && formState.work_comp_codes) {
            if (removeItem && removeId !== undefined) {
                const updatedWCcodes = formState.work_comp_codes.filter((val, index) => val.wc_code_id !== removeId);
                dispatch(updateCompanyBackOfficeInvoiceDocsThunk({
                    data: {
                        ...formState,
                        work_comp_codes: updatedWCcodes,
                    },
                    isNotCredit: true
                }));
            }
            else {
                if (doc !== undefined) {
                    const existingWCCodes = formState.work_comp_codes.map(obj => obj.wc_code_id);
                    const newWCCodes = doc.map(obj => obj.wc_code_id);
                    const uniqueWCCodes = Array.from(new Set([...existingWCCodes, ...newWCCodes]));
                    const mergedWCCodes = uniqueWCCodes.map(wc_code_id => ({
                        wc_code_id,
                    }));

                    dispatch(updateCompanyBackOfficeInvoiceDocsThunk({
                        data: {
                            ...formState,
                            work_comp_codes: mergedWCCodes,
                        }, isNotCredit: true
                    }));
                }
            }

        }
    };
    const handleAddMarkUpShift = (doc: ICompBOInvoiceDocsMarkUp, indexToUpdate?: number, removeItem?: boolean) => {
        const updatedDoc = {
            ...doc,
            regular_mark_up: convertNumberToMoney(doc.regular_mark_up, 'number') as number,
            over_time_mark_up: convertNumberToMoney(doc.over_time_mark_up, 'number') as number,
            double_time_mark_up: convertNumberToMoney(doc.double_time_mark_up, 'number') as number,
        }
        if (formState && formState.mark_up) {
            if (removeItem && indexToUpdate !== undefined && indexToUpdate !== -1) {
                dispatch(updateCompanyBackOfficeInvoiceDocsThunk({
                    data: {
                        ...formState,
                        mark_up: formState.mark_up.filter((_, index) => index !== indexToUpdate),
                    },
                    isNotCredit: false, isRemove: true
                }));
            }
            else {
                if (indexToUpdate === undefined) {
                    dispatch(updateCompanyBackOfficeInvoiceDocsThunk({
                        data: {
                            ...formState,
                            mark_up: formState.mark_up.concat(updatedDoc),
                        }, isNotCredit: false
                    }));
                }
                else if (indexToUpdate !== -1) {
                    const updatedMarkups = [...formState.mark_up];
                    updatedMarkups[indexToUpdate!] = {
                        ...updatedMarkups[indexToUpdate!],
                        ...updatedDoc,
                    };

                    dispatch(updateCompanyBackOfficeInvoiceDocsThunk({
                        data: {
                            ...formState,
                            mark_up: updatedMarkups,
                        }, isNotCredit: false
                    }));
                }
            }

        }
    };
    const handleAddPO = (doc: ICompBOInvoiceDocsPOoptions, indexToUpdate?: number, removeItem?: boolean) => {
        const updatedDoc = {
            ...doc,
            po_limit: convertNumberToMoney(doc.po_limit, 'number') as number,
            warning_value: convertNumberToMoney(doc.warning_value, 'number') as number,
            amount_used: convertNumberToMoney(doc.amount_used, 'number') as number,
        }
        if (formState && formState.po_options) {
            if (removeItem && indexToUpdate !== undefined && indexToUpdate !== -1) {
                dispatch(updateCompanyBackOfficeInvoiceDocsThunk({
                    data: {
                        ...formState,
                        po_options: formState.po_options.filter((_, index) => index !== indexToUpdate),
                    },
                    isNotCredit: false, isRemove: true
                }));
            }
            else {
                if (indexToUpdate === undefined) {
                    dispatch(updateCompanyBackOfficeInvoiceDocsThunk({
                        data: {
                            ...formState,
                            po_options: formState.po_options.concat(updatedDoc),
                        }, isNotCredit: false
                    }));
                }
                else if (indexToUpdate !== -1) {
                    const updatedPOoptions = [...formState.po_options];
                    updatedPOoptions[indexToUpdate!] = {
                        ...updatedPOoptions[indexToUpdate!],
                        ...updatedDoc,
                    };

                    dispatch(updateCompanyBackOfficeInvoiceDocsThunk({
                        data: {
                            ...formState,
                            po_options: updatedPOoptions,
                        }, isNotCredit: false
                    }));
                }
            }

        }
    };

    return (<>
        {backOfficeInvoiceDocsState.loading === LoadingType.pending ? <PageLoading /> :
            (
                <div className="backoffice-invoice-docs-container">
                    <div className="backoffice-id-container-holder">
                        <div className="backoffice-code-rate-card backoffice-id-card">
                            <CodeRateOptions
                                formState={formState}
                                currentLoginUserName={currentLoginUserName}
                                handleAddRateJob={handleAddRateJob}
                                handleAddWCCode={handleAddWCCode}
                                codeRateOptionsUpdateState={updateBackOfficeInvoiceDocsState}
                                handleClearError={handleClearError}
                                companyId={companyId}
                                handleUpdateClearState={handleUpdateClearState}
                                getInvoiceDocsData={getInvoiceDocsData}
                            />
                        </div>
                        <div className="backoffice-markup-shift-card backoffice-id-card">
                            <MarkUpShiftPO
                                formState={formState}
                                currentLoginUserName={currentLoginUserName}
                                handleAddMarkup={handleAddMarkUpShift}
                                handleAddPOOptions={handleAddPO}
                                markupPOUpdateState={updateBackOfficeInvoiceDocsState}
                                handleClearError={handleClearError}
                                companyId={companyId}
                                companyName={location !== undefined ? (location!.state as any).name : ""}
                                handleUpdateClearState={handleUpdateClearState}
                                getInvoiceDocsData={getInvoiceDocsData}
                            />
                            <CompanyBOInvoiceEdocsCard
                                title={t("invoices")}
                                backgroundColor="#EBF1F8"
                                activeColor="#0B3E78"
                                icon={<ArrowForward />}
                                type="invoices"
                                company_id={companyId}
                                department_id={departmentId}
                                companyName={location !== undefined ? (location!.state as any).name : ""}

                            />
                            <CompanyBOInvoiceEdocsCard
                                title={t("edocs")}
                                backgroundColor="#EBF1F8"
                                activeColor="#0B3E78"
                                icon={<ArrowForward />}
                                isButton
                                company_id={companyId}
                                department_id={departmentId}
                                type="edocs"
                                companyName={location !== undefined ? (location!.state as any).name : ""}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    </>);
}


export default CompanyBackOfficeInvoiceDocs;