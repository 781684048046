import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { IWeeklyAccounting } from "../../../../interfaces/back_office_weekly_accounting";
import * as thunk from './weekly_accounting_dashboard_thunk'


export interface WeeklyAccountingDashboardState {
    error?: AppError | null;
    loading: LoadingType;
    response: IWeeklyAccounting[] | null;
    open: {
        error?: AppError | null;
        loading: LoadingType;
        openResponse: string | null;
    },
    close: {
        error?: AppError | null;
        loading: LoadingType;
        closeResponse: string | null;
    }
}

const initialState: WeeklyAccountingDashboardState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    open: {
        error: null,
        loading: LoadingType.idle,
        openResponse: null,
    },
    close: {
        error: null,
        loading: LoadingType.idle,
        closeResponse: null,
    }
}

export const weeklyAccountingSlice = createSlice({
    name: "weeklyAccountingDashboardSlice",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearErrorState(state) {
            return {
                ...state, open: {
                    ...initialState.open,
                    error: null,
                },
                close: {
                    ...initialState.close,
                    error: null,
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getWeeklyAccountingThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getWeeklyAccountingThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getWeeklyAccountingThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))

            .addCase(thunk.getWeeklyAccountingOpenThunk.pending, (state, action) => ({ ...state, open: { ...state.open, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getWeeklyAccountingOpenThunk.rejected, (state, action) => ({ ...state, open: { ...state.open, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getWeeklyAccountingOpenThunk.fulfilled, (state, action) => ({ ...state, open: { ...state.open, loading: LoadingType.succeeded, error: null, openResponse: action.payload } }))

            .addCase(thunk.getWeeklyAccountingCloseThunk.pending, (state, action) => ({ ...state, close: { ...state.close, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getWeeklyAccountingCloseThunk.rejected, (state, action) => ({ ...state, close: { ...state.close, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getWeeklyAccountingCloseThunk.fulfilled, (state, action) => ({ ...state, close: { ...state.close, loading: LoadingType.succeeded, error: null, closeResponse: action.payload } }))

            .addDefaultCase((state) => ({ ...state }));
    }
});
export const weeklyAccountingSliceActions = weeklyAccountingSlice.actions;
export const selectState = (state: WeeklyAccountingDashboardState) => state;