import React from 'react';
import './splash_page.scss';

const SplashPage = () => {
    return (
        <div className="spl-container">
            <div className="spl-logo-size">
                <div className="spl-app-logo background-img"></div>
            </div>
        </div>
    );
}

export default SplashPage;