import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, ICertiUploadUrlResponse, ITalentCrimeHistory } from "../../../../../interfaces";
import * as thunk from "./crime_history_thunk"

interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: ICertiUploadUrlResponse[] | null,
    upload: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
    upload: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
}
interface UpdateState {
    error?: AppError | null,
    loading: LoadingType,
    response: ICertiUploadUrlResponse[] | null,
    upload: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }
}

const initialUpdateState: UpdateState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
    upload: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
}
interface RemoveState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialRemoveState: RemoveState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}
export interface SingleTalentCrimeHistoryState {
    data: ITalentCrimeHistory[] | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    update: UpdateState,
    delete: RemoveState,
}
export const selectSingleTalentCrimeHistInitialState = (): SingleTalentCrimeHistoryState => {
    return {
        loading: LoadingType.pending,
        error: null,
        data: null,
        add: initialAddState,
        update: initialUpdateState,
        delete: initialRemoveState
    }
}
export interface TalentCrimeHistoryState {
    [key: string]: SingleTalentCrimeHistoryState,
}
function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

const initialState: TalentCrimeHistoryState = {}


export const talentCrimeHistorySlice = createSlice({
    name: 'talentCrimeHistory',
    initialState: initialState,
    reducers: {

        clearTalentAddCrimeHistoryError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: { ...initialAddState },
                    // update: { ...initialUpdateState },
                }
            }
        },
        clearTalentUpdateCrimeHistoryError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    update: { ...initialUpdateState },
                }
            }
        },
        clearTalentRemoveCrimeHistoryError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    delete: { ...initialRemoveState },
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getTalentCrimeHistoryThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getLoadingState(state[action.meta.arg]),
                    }
                })
            )
            .addCase(
                thunk.getTalentCrimeHistoryThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getErrorState(state[action.meta.arg], action.payload),

                    }
                })
            )
            .addCase(
                thunk.getTalentCrimeHistoryThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getSuccessState(state[action.meta.arg], action.payload),
                    }
                })
            )
            /// add crime history record
            .addCase(
                thunk.addNewTalentCrimeHistoryThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        add: {
                            ...state[action.meta.arg.talent_id].add,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.addNewTalentCrimeHistoryThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        add: {
                            ...state[action.meta.arg.talent_id].add,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.addNewTalentCrimeHistoryThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        add: {
                            ...state[action.meta.arg.talent_id].add,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )
             /// update crime history record
             .addCase(
                thunk.updateTalentCrimeHistoryThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        update: {
                            ...state[action.meta.arg.talent_id].update,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.updateTalentCrimeHistoryThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        update: {
                            ...state[action.meta.arg.talent_id].update,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.updateTalentCrimeHistoryThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        update: {
                            ...state[action.meta.arg.talent_id].update,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )

            /// deleting crime history record
            .addCase(
                thunk.deleteTalentCrimeHistoryThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        delete: {
                            ...state[action.meta.arg.talent_id].delete,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.deleteTalentCrimeHistoryThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        delete: {
                            ...state[action.meta.arg.talent_id].delete,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.deleteTalentCrimeHistoryThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        delete: {
                            ...state[action.meta.arg.talent_id].delete,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )

            .addCase(thunk.uploadCrimeHistFileThunk.pending, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadCrimeHistFileThunk.rejected, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.uploadCrimeHistFileThunk.fulfilled, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            // .addCase(thunk.deleteTalentCrimeHistoryThunk.pending, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.pending, error: null } }))
            // .addCase(thunk.deleteTalentCrimeHistoryThunk.rejected, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.failed, error: action.payload } }))
            // .addCase(thunk.deleteTalentCrimeHistoryThunk.fulfilled, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.succeeded, response: action.payload, error: null } }))

            .addDefaultCase(state => ({ ...state }));


    }

});


export const talentCrimeHistoryActions = talentCrimeHistorySlice.actions;
export const selectState = (state: TalentCrimeHistoryState) => state;
export const selectSingleState = (state: TalentCrimeHistoryState, id: string) => state[id];

