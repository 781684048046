import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";

export const getDeductionInstitution = createAsyncThunk<
    Array<AdminCenterBackOfficeType>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/back_office/deduction_institution/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getDeductionInstitutionDetails();
            return res.data as AdminCenterBackOfficeType[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createDeductionInstitution = createAsyncThunk<
    string,
    { 'value': string, 'description': string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/deduction_institution/post',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.createDeductionInstitutionDetails(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );

export const updateDeductionInstitution = createAsyncThunk<
    string,
    { 'id': string, 'value': string, 'description': string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/deduction_institution/update',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.updateDeductionInstitutionDetails(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );

export const deleteDeductionInstitution = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/deduction_institution/delete',
        async (option_id, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.deleteDeductionInstitutionDetails(option_id);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );