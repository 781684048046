import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI, } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getOnboardingCountThunk = createAsyncThunk<
    { input_required: number, pending_for_review: number, completed_this_week: number },
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/recruiting/onboarding-count',
    async (_, thunkAPI) => {
        try {
            const res = await DashboardAPI.getSendOnboardingCount({ is_archived: false, get_counts: true });
            return res.data as number;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
