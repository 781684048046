import { THEME } from '../../enums';
import { CloseIcon, DeleteIcon, PlusWhiteIcon } from '../../icons';
import { CustomButton, getPlusBtnIcon } from '../../utils';
import './multi_users_section.scss';

interface Props {
    onClick: () => void,
    headerTitle: string,
    actionName: string,
    list: Array<{ id: string, value: string }>,
    onRemove: (doc: {id: string, value: string}) => void,
}

const MultiUserSection: React.FunctionComponent<Props> = (props) => {
    

    
    return (
        <div className="mlt-usrs-section">
            <div className="mlt-usrs-header">
                <div className="mlt-usrs-header-title-txt">
                    <span>{props.headerTitle}</span>
                </div>
                <div className="mlt-usrs-header-action">
                    <CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={props.actionName}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={props.onClick}
                    />
                </div>
            </div>
            <div className="mlt-usrs-content">
                {props.list.map((doc, index) => {
                    return (
                        <button key={index} className="custom-pab">
                            <div className="pab-title">
                                <span>{doc.value}</span>
                            </div>
                            <DeleteIcon className='delete-icon' onClick={() => props.onRemove(doc)} />
                        </button>
                    )
                })}
            </div>
        </div>
    )


}

export default MultiUserSection;