import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { FONT, THEME } from '../../enums';
import { getLabelText } from '../../utils';
import CustomText from '../../utils/custom_text';

import "./pdf_form_input.scss";

/* ----------------- FormInput --------------------- */


interface Props {
    value: any,
    secure?: any,
    placeholder: any,
    keyboardtype?: any,
    type: React.HTMLInputTypeAttribute | undefined,
    id: string,
    pattern?: RegExp,
    label?: string,
    labelPosition?: "top" | "left" | "right",
    labelIcon?: React.ReactNode,

    clearButtonMode?: any,
    disabled?: boolean,
    placeholderTextColor?: string,
    confirmPassword?: string,
    multiline?: boolean,
    numberOfLines?: number,
    required?: boolean,
    backgroundColor?: string,
    maxLength?: number,
    prefix?: React.ReactNode,
    suffix?: React.ReactNode,
    step?: string | number | undefined,
    min?: string | number | undefined,
    max?: string | number | undefined,
    validations?: (value: { text: string, field: string }) => string | null,
    onChange: (id: string, value: any) => void,
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined,
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined,
    fieldStyles?: React.CSSProperties,
    labelStyles?: React.CSSProperties,
    labelContainerStyles?: React.CSSProperties,
    customStyle?: React.CSSProperties,
    customInputStyle?: React.CSSProperties,
    wrapperStyles?: React.CSSProperties,
}

export function PDFFormInput(props: Props) {
    const { t } = useTranslation();
    const {
        value,
        secure,
        placeholder,
        keyboardtype,
        type,
        id,
        pattern,
        labelPosition,
        label,
        labelIcon,
        labelStyles,
        fieldStyles,
        clearButtonMode,
        disabled,
        placeholderTextColor,
        multiline,
        numberOfLines,
        required,
        backgroundColor,
        maxLength,
        prefix,
        suffix,
        onKeyDown,
        onKeyUp,
        customStyle,
        customInputStyle,
        labelContainerStyles,
        wrapperStyles,
    } = props;
    const [error, setError] = useState<string | null>('');
    // const [prevPassword, setPrevPassword] = useState<string | undefined>(props.password);



    // useEffect(() => {
    //     if ((id === "password" || id === "confirmPassword") && props.password && props.password != prevPassword) {
    //         setPrevPassword(props.password);
    //         handleValidation(props.value);
    //     }
    // }, [props.password])


    const handleChange = (value: any) => {
        const text = value;
        props.onChange(props.id, text);
    }

    const handleValidation = (text: any) => {
        setError(props.validations ? props.validations({ text, field: props.id }) : null);
    }


    const InputComp = (
        <input
            className={"pdf-form-input"}
            style={{
                ...{ backgroundColor: backgroundColor ? backgroundColor : THEME.white },
                ...customInputStyle,

            }}
            type={type}
            placeholder={t(placeholder)}
            onChange={event => {
                handleChange(event.target.value);
                handleValidation(event.target.value);
            }}
            value={value ?? ''}
            autoCapitalize={"none"}
            autoFocus={false}
            disabled={disabled}
            maxLength={maxLength}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            step={props.step}
            min={props.min}
            max={props.max}
        />
    );

    const TextAreaComp = (
        <textarea
            className={"pdf-form-input"}
            style={{
                ...{
                    minWidth: '100%',
                    maxWidth: '100%',
                    boxSizing: 'border-box',
                    minHeight: 80,
                    maxHeight: numberOfLines ? numberOfLines * 30 : 45
                },
                ...{ backgroundColor: backgroundColor ? backgroundColor : THEME.white },
                ...customInputStyle,
            }}
            placeholder={t(placeholder)}
            onChange={event => {
                handleChange(event.target.value);
                handleValidation(event.target.value);
            }}
            value={value}
            autoCapitalize={"none"}
            autoFocus={false}
            disabled={disabled}

        />
    );

    if (prefix || suffix) {

        return (
            <div
                style={{
                    ...{ display: 'flex', flexDirection: 'column' },
                    ...(labelPosition === "top") && { flexDirection: 'column' },
                    ...(labelPosition === "left") && { flexDirection: 'row', alignItems: 'center' },
                    ...(labelPosition === "top") && { flexDirection: 'row-reverse', alignItems: 'center' },
                    ...fieldStyles,
                    ...customStyle,
                }}>
                <LabelComp />
                <div
                    style={{
                        ...styles.inputIconView,
                        ...(disabled ? { opacity: 0.6, } : null),
                        ...wrapperStyles,
                    }}
                >
                    <div className="pdf-form-input-holder">
                        <div className="prefix-holder">
                            {prefix}
                        </div>
                        {multiline ? TextAreaComp : InputComp}
                        <div className="suffix-holder">
                            {suffix}
                        </div>

                    </div>
                    {error ? <CustomText customStyle={styles.validatorError}>{error}</CustomText> : null}
                </div>

            </div>
        )
    }



    return (
        <div
            style={{
                ...{ display: 'flex', flexDirection: 'column' },
                ...(labelPosition === "top") && { flexDirection: 'column' },
                ...(labelPosition === "left") && { flexDirection: 'row', alignItems: 'center' },
                ...(labelPosition === "top") && { flexDirection: 'row-reverse', alignItems: 'center' },
                ...fieldStyles,
                ...customStyle
            }}
        >

            <div
                className={""}
                style={{
                    ...styles.inputIconView,
                    ...(disabled ? { opacity: 0.6, } : null),
                    ...wrapperStyles,

                }}
            >
                {multiline ? TextAreaComp : InputComp}
            </div>
            <div className="input-footer">
                <LabelComp />
                {error ? <CustomText customStyle={styles.validatorError}>{error}</CustomText> : null}
            </div>

        </div>
    );




    function LabelComp() {
        if (label || labelIcon) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        ...labelPosition === 'left' && { marginRight: '1em', width: '-webkit-fill-available' },
                        ...labelPosition === 'right' && { marginLeft: '1em', width: '-webkit-fill-available' },
                    }}
                >
                    {props.labelIcon}
                    <CustomText
                        customStyle={{
                            ...styles.lableHeader,
                            ...labelStyles,
                        }}
                    >
                        {label && getLabelText(label, { position: labelPosition, required: required })}
                    </CustomText>

                </div>
            )
        }
        return null;
    }


}

const styles: { [key: string]: React.CSSProperties } = ({
    lableHeader: {
        fontSize: '0.729vw',
        fontFamily: FONT.lato,
        color: THEME.black
    },
    inputIconView: {
        display: 'flex',
        flexDirection: 'column',
    },
    validatorError: {
        color: 'red',
        fontSize: '0.629vw',
        fontFamily: FONT.lato,
        marginLeft: '1em',
    },
});