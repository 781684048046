import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolderType, ActionDialogHolder } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, ROLE } from '../../../../enums';
import { IEmploymentHistory, ICreateEmploymentHistory } from '../../../../interfaces';
import {
    selectCertificationsList,
    selectCertificationsListState,
    selectCredentialsList,
    selectCredentialsListState,
    selectEducationList,
    selectEducationListState,
    selectRequiredEquipmentList,
    selectRequiredEquipmentListState,
    selectSkillSetList,
    selectSkillSetListState,
    selectRoleUsersListState,
    getRoleUsersList,
    selectAccountManagerRoleUsersList,
    selectRecruiterRoleUsersList,
    useAppDispatch, useAppSelector, postSnackbarMessage,
    selectCompetitorsListState,
    selectCompetitorsList,
    getCredentialsList,
    getEducationList,
    getRequiredEquipmentList,
    getSkillSetList,
    getCertificationsList,
    getCompetitorsList,
    createTalentEmploymentHistory,
    selectManageTalentEmploymentHistoryState,
    updateTalentEmploymentHistory,
    selectRoleUsersList,
} from "../../../../redux/store";

import { SpinnerScreen } from '../../../../utils';
import TalentEmploymentForm from '../../forms/talent_employment_form/talent_employment_form';

import './create_employment_record.scss';
import { manageTalentEmploymentHistoryActions } from '../../../../redux/talent/manage_employment/manage_employment_reducer';
import { convertDateToTimeStamp } from '../../../../variables';

interface Props {
    visible: boolean,
    title: string,
    talent?: IEmploymentHistory,
    talentId: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}
const CreateEmploymentRecordPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        talent,
        talentId,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const manageEmpState = useAppSelector((state) => selectManageTalentEmploymentHistoryState(state));
    const createState = manageEmpState.create;
    const updateState = manageEmpState.update;


    
    // const updateState = useAppSelector((state) => selectManageTalentUpdateState(state));
    const competitorsListState = useAppSelector((state) => selectCompetitorsListState(state));
    const skillSetListState = useAppSelector((state) => selectSkillSetListState(state));
    const credentialsListState = useAppSelector((state) => selectCredentialsListState(state));
    const certificationsListState = useAppSelector((state) => selectCertificationsListState(state));
    const educationListState = useAppSelector((state) => selectEducationListState(state));
    const requiredEquipmentListState = useAppSelector((state) => selectRequiredEquipmentListState(state));
    const roleUsersListState = useAppSelector((state) => selectRoleUsersListState(state));

    const skillSetList = useAppSelector((state) => selectSkillSetList(state));
    const credentialsList = useAppSelector((state) => selectCredentialsList(state));
    const certificationsList = useAppSelector((state) => selectCertificationsList(state));
    const educationList = useAppSelector((state) => selectEducationList(state));
    const requiredEquipmentList = useAppSelector((state) => selectRequiredEquipmentList(state));
    const accountManagersList = useAppSelector((state) => selectAccountManagerRoleUsersList(state));
    const recruitersList = useAppSelector((state) => selectRecruiterRoleUsersList(state));
    const usersList = useAppSelector((state) => selectRoleUsersList(state));

    /// list
    const competitorsList = useAppSelector((state) => selectCompetitorsList(state));
    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        dispatch(getSkillSetList());
        dispatch(getCertificationsList());
        dispatch(getCredentialsList());
        dispatch(getEducationList());
        dispatch(getRequiredEquipmentList());
        dispatch(getRoleUsersList());
        dispatch(getCompetitorsList());
    }, []);

    useEffect(() => {
        if (createState.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(createState.response ?? null));
            closePopup(onSuccessClose);
        }
        if (updateState.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(updateState.response ?? null));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [createState.loading, updateState.loading])

  

    function isLoading() {
        return competitorsListState.loading === LoadingType.pending ||
            skillSetListState.loading === LoadingType.pending ||
            credentialsListState.loading === LoadingType.pending ||
            certificationsListState.loading === LoadingType.pending ||
            educationListState.loading === LoadingType.pending ||
            requiredEquipmentListState.loading === LoadingType.pending ||
            roleUsersListState.loading === LoadingType.pending;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                {isLoading() ? <div className="loading-spinner"><SpinnerScreen /></div> : <TalentEmploymentForm
                    title={props.title}
                    talent={props.talent}
                    usersList={usersList}
                    skillSetList={skillSetList}
                    credentialsList={credentialsList}
                    certificationsList={certificationsList}
                    educationList={educationList}
                    requiredEquipmentList={requiredEquipmentList}
                    competitors={competitorsList}
                    loading={props.talent? updateState.loading===LoadingType.pending : createState.loading === LoadingType.pending}
                    error={props.talent ? updateState.error : createState.error}
                    onClearError={handleClearError}
                    onClose={() => closePopup(onClose)}
                    onSubmit={handleSubmit}
                />}
            </DialogWrapper>
        </ActionDialogHolder>

    );

    ///Handle clear error
    function handleClearError() {
        dispatch(manageTalentEmploymentHistoryActions.clearCreateTalentEMploymentHistoryState());
        dispatch(manageTalentEmploymentHistoryActions.clearUpdateTalentEMploymentHistoryState());
    }

    // handle submit
    function handleSubmit(doc: { forceAdd: boolean, value: ICreateEmploymentHistory }) {
       
        if (props.talent && props.talentId && props.talent.id) {
            dispatch(updateTalentEmploymentHistory({
                    ...doc.value,
                    id: props.talent.id,
            }));
        } else {
            if(props.talentId != null) dispatch(createTalentEmploymentHistory({
                forceAdd: doc.forceAdd,
                value: {
                    ...doc.value,
                    talent_id : props.talentId,
                    company_id: doc.value.is_new_company ?  '' : doc.value.company_name 
                }
            }));        
        }
        
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default CreateEmploymentRecordPopup;