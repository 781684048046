import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../../../../components/search_bar";
import { LoadingType, THEME } from "../../../../../../enums";
import { CustomButton, currencyConversion, getPlusBtnIcon } from "../../../../../../utils";
import { useAppDispatch } from "../../../../../../redux/store";
import { AppError, ICompBOInvoiceDocsPOoptions, ICompanyBackOfficeInvoiceDocs } from "../../../../../../interfaces";
import SortableTable, { TableData } from "../../../../../../components/sortable_table/sortable_table";
import { DeleteIcon, EditIcon } from "../../../../../../icons";
import './po_options.scss';
import { Portal } from "react-portal";
import AddNewPOPopup from "../../../../popup/add_new_po/add_new_po";
import { DeleteConfirmationDialog } from "../../../../../components/delete_confirmation_dialog";


const POTableHeader = [
    { title: 'po_number', code: 'po_number' },
    { title: 'description', code: 'description' },
    { title: 'po_limit', code: 'po_limit' },
    { title: 'warning_value', code: 'warning_value' },
    { title: 'amount_used', code: 'amount_used' },
    { title: '', code: '' },

];
interface Props {
    formState: ICompanyBackOfficeInvoiceDocs;
    currentLoginUserName: string;
    handleAddPOOptions: (doc: ICompBOInvoiceDocsPOoptions, index?: number, removeItem?: boolean) => void;
    markupPOUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleClearError: () => void;
    handleUpdateClearState: () => void;
    getInvoiceDocsData: () => void;
    companyId: string;
    companyName: string;
}
const CompanyPOOptions: React.FC<Props> = (props) => {
    const { formState, currentLoginUserName, handleAddPOOptions, handleClearError, markupPOUpdateState, handleUpdateClearState, getInvoiceDocsData, companyName } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [addNewPO, setAddNewPO] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [poSorted, setPoSorted] = useState<string | null>(null);
    const [editIndex, setEditIndex] = useState<number | undefined>();
    const [delRecord, setDelRecord] = useState<boolean>(false);

    const initialForm = {
        po_number: "",
        description: "",
        po_limit: 0,
        warning_value: 0,
        amount_used: 0
    }
    const [poFormState, setPOFormState] = useState<ICompBOInvoiceDocsPOoptions>(initialForm);

    function handleSortFieldChange(value: string) {
        if (poSorted && poSorted === value) {
            setPoSorted(null);
        } else {
            setPoSorted(value);
        }
    }

    function getPOFilteredList(): ICompBOInvoiceDocsPOoptions[] {
        let sortedList: ICompBOInvoiceDocsPOoptions[] | undefined;
        if (poSorted != null) {
            sortedList = [...(formState.po_options ?? [])].sort((a, b) => {
                if (a[poSorted] < b[poSorted]) {
                    return -1;
                }
                if (a[poSorted] > b[poSorted]) {
                    return 1;
                }
                return 0;
            });
        }
        return (sortedList ?? (formState.po_options ?? [])).filter(doc => {
            const str = search.trim().toLowerCase();
            const po_number = doc.po_number ? doc.po_number.trim().toLowerCase().includes(str) : false;
            const description = doc.description ? doc.description.trim().toLowerCase().includes(str) : false;
            const po_limit = doc.po_limit ? doc.po_limit.toString().trim().toLowerCase().includes(str) : false;
            const warning_value = doc.warning_value ? doc.warning_value.toString().trim().toLowerCase().includes(str) : false;
            const amount_used = doc.amount_used ? doc.amount_used.toString().trim().toLowerCase().includes(str) : false;

            return description || po_limit || warning_value || amount_used || po_number;
        });
    }
    function handleFormFieldChange(fieldId: string, value: any, parent: string) {
        setPOFormState({
            ...poFormState,
            [fieldId]: value,
        });
    };
    const handleClose = () => {
        setAddNewPO(false);
        setPOFormState(initialForm);
    }
    const handleOnSuccessClose = () => {
        setEditIndex(undefined);
        handleClose();
        handleUpdateClearState();
        getInvoiceDocsData();
    }

    const handleSave = () => {
        if (JSON.stringify(poFormState) === JSON.stringify(initialForm)) {
            handleClose();
        }
        else {
            handleAddPOOptions(poFormState, editIndex);
        }
    }

    const handleEdit = (val: ICompBOInvoiceDocsPOoptions, index: number) => {
        setAddNewPO(true);
        setPOFormState(val);
        setEditIndex(index);
    };
    const handleDelete = (val: ICompBOInvoiceDocsPOoptions, index: number) => {
        setDelRecord(true);
        setPOFormState(val);
        setEditIndex(index);

    };
    const handleOnSuccessRemove = () => {
        handleAddPOOptions(poFormState, editIndex, true);
        setEditIndex(undefined);
        handleClose();
        handleUpdateClearState();
        setDelRecord(false);
    }
    return (
        <div className="po-main-container">
            <div className="markup-po-header">
                <div className="section-header">{t("po_options")}</div>
                <div className="markup-po-header-actions">
                    <div className="">
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                            placeholder={t('Search')}
                        />
                    </div>
                    <CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("add_new_po")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => setAddNewPO(true)}
                    />
                </div>
            </div>
            <div className="markup-po-content">
                <div className="markup-po-content-table">
                    <SortableTable
                        headerList={POTableHeader}
                        sortedField={poSorted}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                    >
                        {getPOFilteredList().map((doc, index) => {
                            return (
                                <tr key={index} onClick={() => { }}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}>{doc.po_number}</TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}>{doc.description !== undefined && doc.description.length > 50 ? doc?.description?.substring(0, 50) + "..." : doc?.description}</TableData>

                                    <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.po_limit
                                        ? currencyConversion(doc.po_limit)
                                        : "$0.00"}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{doc.warning_value
                                        ? currencyConversion(doc.warning_value)
                                        : "$0.00"}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc.amount_used
                                        ? currencyConversion(doc.amount_used)
                                        : "$0.00"}</span></TableData>

                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <button className="edit-icon" >
                                            <EditIcon width={"100%"} height={"100%"} onClick={() => handleEdit(doc, index)} />
                                        </button>
                                        <button className="delete-icon" >
                                            <DeleteIcon width={"100%"} height={"100%"} onClick={() => handleDelete(doc, index)} />
                                        </button>
                                    </TableData>
                                </tr>
                            );
                        })}
                    </SortableTable>
                </div>
            </div>
            {addNewPO &&
                <Portal>
                    <AddNewPOPopup
                        visible={addNewPO}
                        title={t("complete_info_to_add_new_po", { name: currentLoginUserName })}
                        successTitle={t("new_po")}
                        onClose={handleClose}
                        onSuccessClose={handleOnSuccessClose}
                        handleSave={handleSave}
                        poFormState={poFormState}
                        currentLoginUserName={currentLoginUserName}
                        markupPOUpdateState={markupPOUpdateState}
                        index={editIndex}
                        handleFormFieldChange={handleFormFieldChange}
                        companyName={companyName}
                    />
                </Portal>
            }

            {delRecord && (
                <DeleteConfirmationDialog
                    message={t("delete_bo_record_message", {
                        name: poFormState.po_number,
                    })}
                    onCancel={() => setDelRecord(false)}
                    onConfirm={handleOnSuccessRemove}
                    deleting={markupPOUpdateState.loading === LoadingType.pending}
                    deleteType={t("po_options")}
                    state={markupPOUpdateState}
                    onClearError={handleClearError}
                />
            )}
        </div>

    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 0.5;

        return 1;
    }
}

export default CompanyPOOptions;