import { useTranslation } from 'react-i18next';
import './note_card.scss';

interface Props {
    icon: JSX.Element;
    // text: string;
    textLineOne: string;
    textLineTwo?: string;
    textLineThree?: string;
    confirmBtn?: boolean;
    // customTextColor?: React.CSSProperties,
    customTextColor?: string,
    customIconBGColor?: string,
    customCardWidth?: string,
    // customTextWidth?: string,
    customTextWidthLineOne?: string,
    customTextWidthLineTwo?: string,
    customTextWidthLineThree?: string,
    customCardBGColor?: string,
    customFontWeight?: string,
    cursor?: string,
    onClick?: () => void,
}

const NoteCard: React.FunctionComponent<Props> = (props) => {
    const {
        icon,
        confirmBtn,
        customTextColor,
        customIconBGColor,
        customCardWidth,
        customCardBGColor,
        textLineOne,
        textLineTwo,
        textLineThree,
        customTextWidthLineOne,
        customTextWidthLineTwo,
        customTextWidthLineThree,
        customFontWeight,
        cursor,
        onClick
    } = props
    const { t } = useTranslation();
    return (
        <div className="card" style={{ width: customCardWidth, backgroundColor: customCardBGColor }}>
            <span className="icon" style={{ backgroundColor: customIconBGColor }}>{icon}</span>
            <div className="text-btn">
                <div className='text-line-one' style={{ color: customTextColor, width: customTextWidthLineOne, cursor: cursor, fontWeight: customFontWeight }} onClick={confirmBtn ? () => { } : onClick}>{textLineOne}</div>
                {textLineTwo && <div className='text-line-two' style={{ color: customTextColor, width: customTextWidthLineTwo }}>{textLineTwo}</div>}
                {textLineThree && <div className='text-line-three' style={{ color: customTextColor, width: customTextWidthLineThree }}>{textLineThree}</div>}
                {confirmBtn && <div className='btn-yes-no'>
                    <span className='yes' onClick={onClick}>{t("yes")}</span>
                    <span className='no'>{t("no")}</span>
                </div>}
            </div>
        </div>
    );
};

export default NoteCard;
