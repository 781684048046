import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { BOBillingInvoiceIcon, BOImportExportIcon, BOPayrollIcon, BulbIcon, TimeCardsIcon, WeeklyAccountingIcon, BODeductionAuthorityIcon, BOAcntReceivableIcon} from "../../icons";
import { AppRoutes } from "../../routes";
import MenuOption from "../../components/menu_option/menu_option";
import NoteCard from "../../components/note_card/note_card";
import { CustomCheckBox } from "../../utils";
import { useState } from "react";
import { Portal } from "react-portal";
import popupInfoContent from "./popup_info_text";
import InfoTextPopup from "./popups/infoTextPopup/info_text_popup";

interface Props extends RouteComponentProps<any> { }
const BackOfficeMainPage: React.FunctionComponent<Props> = (props: Props) => {
    const { history } = props;
    const { t } = useTranslation();
    const [showInactive, setShowInactive] = useState(false);
    const [openbBOInfoTextPopup, setBOInfoTextPopup] = useState(false)
    const options = [
        {
            title: 'weekly_accounting',
            icon: WeeklyAccountingIcon,
            route: AppRoutes.weeklyAccountingPage,
            supText: "step 1"
        },
        {
            title: 'Import',
            icon: BOImportExportIcon,
            route: AppRoutes.boImportPage,
            supText: "step 2"
        },
        {
            title: 'timecards',
            icon: TimeCardsIcon,
            route: AppRoutes.timeCardsPage,
            supText: "step 3"
        },
        {
            title: 'Payroll',
            icon: BOPayrollIcon,
            route: AppRoutes.payrollPage,
            supText: "step 4"
        },
        {
            title: 'billing_invoicing',
            icon: BOBillingInvoiceIcon,
            route: AppRoutes.billingInvoicesPage,
            supText: "step 5"
        },
        {
            title: 'Export',
            icon: BOImportExportIcon,
            route: AppRoutes.boExportPage,
            supText: "step 6"
        },
        {
            title: 'accounts_receivable',
            icon: BOAcntReceivableIcon,
            route: AppRoutes.accountsReceivablePage,
        },
        {
            title: 'third_party_deduction_authority',
            icon: BODeductionAuthorityIcon,
            route: AppRoutes.deductionAuthoritiyPage,
        }
    ]
    function returnValidMenu() {
        // let roles = state.profile ? state.profile.roles : []
        // for (let role of roles)
        //     if (role['role'] === ROLE.administrator || role['role'] === ROLE.superUser)
        // return [...options, ...restrictedRoutes]
        return options;
    }
    return (<div className="menu-option-container">
        {returnValidMenu().map((doc, index) => {
            return (
                <MenuOption key={index} doc={doc} t={t} onClick={() => history.push(doc.route)} />
            )
        })}
        <div className="note-card-container">
            <NoteCard
                icon={<BulbIcon />}
                textLineOne={t("are_you_new_to_back_office_and_need_help_getting_started")}
                confirmBtn={true}
                customTextColor={"#074783"}
                customIconBGColor={"#D9E6F4"}
                customCardWidth={"74%"}
                customCardBGColor={"#EAF4FF"}
                customTextWidthLineOne={"75%"}
                onClick={() => setBOInfoTextPopup(true)}
                customFontWeight={'800'}
            />
            <CustomCheckBox
                name={""}
                title={t("don't_show_this_message_in_the_future")}
                checked={showInactive}
                onClick={() => setShowInactive(!showInactive)}
            />
        </div>
        {openbBOInfoTextPopup &&
            <Portal>
                <InfoTextPopup
                    visible={openbBOInfoTextPopup}
                    onClose={() => setBOInfoTextPopup(false)}
                    onDismiss={() => setBOInfoTextPopup(false)}
                    title={t("back_office_help")}
                    textContent={popupInfoContent.backOfficeInfo}
                />
            </Portal>}
    </div>);
}

export default BackOfficeMainPage;