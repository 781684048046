import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../../apis";
import { AppError, ICertiUploadUrlResponse, ICreateTalentCertifications, ITalentCertifications } from "../../../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../../../utils";
import { S3API } from "../../../../../apis/s3_api";

export const getTalentCertificationsThunk = createAsyncThunk<
    Array<ITalentCertifications>,
    { talent_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboard-docs/certifications-other-docs/get',
    async (doc, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalentCertifications(doc.talent_id);
            if (typeof res.data === 'string') return [];
            return res.data as ITalentCertifications;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addNewTalentCertificationsThunk = createAsyncThunk<
    ICertiUploadUrlResponse,
    { talent_id: string, data: ICreateTalentCertifications, file: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboard-docs/certifications-other-docs/post',
    async (doc, thunkAPI) => {
        try {
            const res = await TalentAPI.postTalentCertifications(doc.data);

            thunkAPI.dispatch(getTalentCertificationsThunk({ talent_id: doc.talent_id }));
            if (res.data.url !== "" && doc.file) {
                const uploadFile = await S3API.uploadFile(res.data.url, doc.file);
                return uploadFile.data;
            }
            return res.data as ICertiUploadUrlResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const updateTalentCertificationsThunk = createAsyncThunk<
    ICertiUploadUrlResponse,
    { talent_id: string, data: ICreateTalentCertifications,file: File | null  },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboard-docs/certifications-other-docs/patch',
    async (doc, thunkAPI) => {
        try {
            const res = await TalentAPI.updateTalentCertifications(doc.data);
            thunkAPI.dispatch(getTalentCertificationsThunk({ talent_id: doc.talent_id }));
            if (res.data.url !== "" && doc.file) {
                const uploadFile = await S3API.uploadFile(res.data.url, doc.file);
                return uploadFile.data;
            }
            return res.data as ICertiUploadUrlResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const uploadCertificationFileThunk = createAsyncThunk<
    any,
    { type: string, url: string, file: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboard-docs/certifications-other-docs-certification-file/post',
    async (payload, thunkAPI) => {
        try {
            if (payload.file && payload.url) {
                const photoRes = await S3API.uploadFile(payload.url, payload.file);

                return photoRes.data;
            }
            return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);



export const deleteTalentCertificationsThunk = createAsyncThunk<
    string,
    { talent_id: string, skill_doc_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboard-docs/certifications-other-docs/delete',
    async (doc, thunkAPI) => {
        try {
            const res = await TalentAPI.deleteTalentCertifications(doc.skill_doc_id);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
