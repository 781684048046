import { useTranslation } from "react-i18next";
import SectionCard from "../../../../../components/section_card/section_card";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { LoadingType, THEME } from "../../../../../enums";
import { useEffect, useState } from "react";
import { AppError, ICompanyBackOfficeSetup, IDepartmentsList } from "../../../../../interfaces";
import { EditIcon } from "../../../../../icons";
import { CustomButton, FormInput, RegxPattern, allowNumbers, mapOptions } from "../../../../../utils";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { getGroupBy, selectGroupByList } from "../../../../../redux/admin_center/back_office";
import ToggleSwitch from "../../../../../components/toggle_switch/toggle_switch";

import './invoice_format.scss';
import ApiError from "../../../../../components/api_error";
import { US_states } from "../../../../../utils/States_US";
interface Props {
    invoiceUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleFieldChange: (fieldId: string, value: any, parent: string) => void;
    formState: ICompanyBackOfficeSetup;
    handleSubmit: (parent: string) => void;
    companyDepartments: IDepartmentsList[],
    onClearError: () => void;
    handleEditCancel: () => void;
    isLoading: boolean;
    deptId: string;
    invoiceStyles: Array<{ title: string, value: string }>
    groupingOptions: Array<{ title: string, value: string }>
}

const InvoiceFormat: React.FC<Props> = props => {
    const { invoiceUpdateState, handleFieldChange, formState, handleSubmit, companyDepartments, onClearError, isLoading, handleEditCancel, deptId, invoiceStyles, groupingOptions } = props
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const parentField = 'invoice_format';

    useEffect(() => {
        dispatch(getGroupBy());
    }, [])


    const handleSave = () => {
        handleSubmit(parentField);
    };
    const isAddLoading = () => {
        return isLoading && invoiceUpdateState?.loading === LoadingType.pending;
    };
    function _isFormValid() {
        if (formState.invoice_format?.email_invoice_to &&
            formState.invoice_format?.email_invoice_to !== "" &&
            RegxPattern.email.test(formState.invoice_format?.email_invoice_to) === false) return false;
        return true;
    }
    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "email_invoice_to": {
                if (value.text !== "" && RegxPattern.email.test(value.text) === false)
                    return t("validators.enterValidEmail");
                return null;
            }
            case "bill_to_address_zip_code": {
                if (value.text !== "" && RegxPattern.zipCode.test(value.text) === false)
                    return t("validators.enterValidZipCode");
                return null;
            }
            default: {
                return null;
            }
        }
    }

    return (
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('invoice_format')} >
            <div className="if-container">
                <div className="if-content">
                    <div className="if-input-holder-row row-3">
                        <div className={"if-content-input-holder"}>
                            <CustomFormSelect
                                name={t("invoice_style_id")}
                                list={mapOptions(invoiceStyles, "title", "value")}
                                onChange={(val) => handleFieldChange("invoice_style_id", val, parentField)}
                                value={formState.invoice_format?.invoice_style_id}
                                placeholder={t('select')}
                                required={false}
                                label={t('invoice_style')}
                                isClearable={false}
                            />
                        </div>
                        <div className={"if-content-input-holder"}>
                            <FormInput
                                id={'invoice_attention_to'}
                                onChange={(id, val) => handleFieldChange(id, val, parentField)}
                                required={false}
                                placeholder={""}
                                type={"text"}
                                value={formState.invoice_format?.invoice_attention_to}
                                label={t("invoice_attention_to")}
                            />
                        </div>
                        <div className={"if-content-input-holder"}>
                            <CustomFormSelect
                                name={t("invoice_to_department_id")}
                                list={companyDepartments.map((doc) => ({
                                    label: doc.name,
                                    value: doc.id,
                                }))}
                                onChange={(val) => handleFieldChange("invoice_to_department_id", val, parentField)}
                                value={formState.invoice_format?.invoice_to_department_id}
                                placeholder={t('Corporate')}
                                required={false}
                                label={t('invoice_to_dept')}
                                disabled={deptId ? true : false}
                            />
                        </div>
                    </div>
                    <div className="if-input-holder-row row-3">
                        <div className={"if-content-input-holder"}>
                            <CustomFormSelect
                                name={t("separate_invoice_by_value")}
                                list={mapOptions(groupingOptions, "title", "value")}
                                onChange={(val) => handleFieldChange("separate_invoice_by_value", val, parentField)}
                                value={formState.invoice_format?.separate_invoice_by_value}
                                placeholder={t('select')}
                                required={false}
                                label={t('separate_invoice_by')}
                                isClearable={false}
                            />
                        </div>
                        <div className={"if-content-input-holder"}>
                            <CustomFormSelect
                                name={t("group_invoice_by_value")}
                                list={mapOptions(groupingOptions, "title", "value")}
                                onChange={(val) => handleFieldChange("group_invoice_by_value", val, parentField)}
                                value={formState.invoice_format?.group_invoice_by_value}
                                placeholder={t('select')}
                                required={false}
                                label={t('group_invoice_by')}
                                isClearable={false}
                            />
                        </div>
                        <div className={"if-content-input-holder"}>
                            <CustomFormSelect
                                name={t("sort_invoice_by_value")}
                                list={mapOptions(groupingOptions, "title", "value")}
                                onChange={(val) => handleFieldChange("sort_invoice_by_value", val, parentField)}
                                value={formState.invoice_format?.sort_invoice_by_value}
                                placeholder={t('select')}
                                required={false}
                                label={t('sort_invoice_by')}
                                isClearable={false}
                            />
                        </div>
                    </div>
                    <div className="if-input-holder-row row3">
                        <div className={"if-content-input-holder"}>
                            <CustomFormSelect
                                name={t("display_items_value")}
                                list={mapOptions(groupingOptions, "title", "value")}
                                onChange={(val) => handleFieldChange("display_items_value", val, parentField)}
                                value={formState.invoice_format?.display_items_value}
                                placeholder={t('select')}
                                required={false}
                                label={t('display_items')}
                                isClearable={false}
                            />
                        </div>
                        <div className={"if-content-input-holder"}>
                            <FormInput
                                id={'email_invoice_to'}
                                onChange={(id, val) => handleFieldChange(id, val, parentField)}
                                required={false}
                                placeholder={""}
                                type={"text"}
                                value={formState.invoice_format?.email_invoice_to}
                                label={t("email_invoice_to")}
                                validations={formValidators}
                            />
                        </div>
                    </div>
                    <div className="section-header">{t("bill_to_address")}</div>
                    <div className="if-input-holder-row row4" >
                        <div className="if-content-input-holder">
                            <FormInput
                                id={"bill_to_address_street_address"}
                                onChange={(id, val) => handleFieldChange(id, val, parentField)}
                                type={"text"}
                                value={formState.invoice_format?.bill_to_address_street_address}
                                label={t("street_address")}
                                placeholder=""
                                validations={formValidators}
                            />
                        </div>
                    </div>
                    <div className="if-input-holder-row row-3">
                        <div className="if-content-input-holder">
                            <FormInput
                                id={"bill_to_address_city"}
                                onChange={(id, val) => handleFieldChange(id, val, parentField)}
                                type={"text"}
                                value={formState.invoice_format?.bill_to_address_city}
                                label={t("city")}
                                placeholder=""
                                validations={formValidators}
                            />
                        </div>
                        <div className="if-content-input-holder">
                            <CustomFormSelect
                                list={US_states.map((doc) => ({
                                    label: doc.name,
                                    value: doc.code,
                                }))}
                                customStyle={{ width: "-webkit-fill-available" }}
                                name={"bill_to_address_state"}
                                value={formState.invoice_format?.bill_to_address_state}
                                label={t("state")}
                                placeholder={"Select"}
                                onChange={(val) => handleFieldChange('bill_to_address_state', val, parentField)}
                                menuPlacement={"top"}
                            />
                        </div>
                        <div className="if-content-input-holder">
                            <FormInput
                                id={"bill_to_address_zip_code"}
                                onChange={(id, val) => handleFieldChange(id, val, parentField)}
                                type={"text"}
                                value={formState.invoice_format?.bill_to_address_zip_code}
                                label={t("zip_code")}
                                placeholder=""
                                validations={formValidators}
                            />
                        </div>
                    </div>
                    <div className="if-input-holder-row row-3 toggle-row">
                        {/* <div className={"if-content-input-holder"}>
                            <ToggleSwitch
                                onChange={(val) => handleFieldChange('print_invoice', val, parentField)}
                                label={t("print_invoice")}
                                checked={formState.invoice_format?.print_invoice!}
                                labelPosition={'right'}
                            />
                        </div> */}
                        <div className={"if-content-input-holder"}>
                            <ToggleSwitch
                                onChange={(val) => handleFieldChange('display_timecard', val, parentField)}
                                label={t("display_timecard")}
                                checked={formState.invoice_format?.display_timecard!}
                                labelPosition={'right'}
                            />
                        </div>
                        <div className={"if-content-input-holder"}>
                            <ToggleSwitch
                                onChange={(val) => handleFieldChange('display_daily_hours', val, parentField)}
                                label={t("display_daily_hours")}
                                checked={formState.invoice_format?.display_daily_hours!}
                                labelPosition={'right'}
                            />
                        </div>
                    </div>
                </div>
                <div className="if-actions">
                    <div className="er-div">
                        {isLoading && invoiceUpdateState !== undefined && invoiceUpdateState?.error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={invoiceUpdateState.error.message}
                                    onClose={onClearError}
                                />
                            </div>
                        )}
                    </div>


                    <div className="if-actions-btn">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable
                                backgroundColor={THEME.secondaryColor4}
                                onClick={handleEditCancel}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                loading={isAddLoading()}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("save")}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleSave}
                                enable={_isFormValid()}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </SectionCard >);
}

export default InvoiceFormat;