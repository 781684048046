import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll } from "./payroll_batch_reducer";

const select = (state: IRootState) => state.backOffice.payRoll.payrollBatch;
export const selectPayrollBatchState = createSelector(
    select,
    selectState
);
export const selectPayrollBatchList = createSelector(select, selectAll)
