import { useTranslation } from "react-i18next";
import SectionCard from "../../../../../components/section_card/section_card";
import { getTalentBOPayrollDirectDepositThunk, selectSingleTalentPRDDSStateById, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { LoadingType, THEME } from "../../../../../enums";
import { useEffect, useState } from "react";
import { AppError, ICompanyBackOfficeSetup, IDepartmentsList } from "../../../../../interfaces";
import { EditIcon, TickGreenIcon, TickGreyIcon } from "../../../../../icons";
import { CustomButton, FormInput, RegxPattern, allowNumbers, currencyConversion, getPlusBtnIcon, mapOptions } from "../../../../../utils";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { getGroupBy, selectGroupByList } from "../../../../../redux/admin_center/back_office";
import ToggleSwitch from "../../../../../components/toggle_switch/toggle_switch";

import './direct_deposit.scss';
import ApiError from "../../../../../components/api_error";
import { US_states } from "../../../../../utils/States_US";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { IBOTalentAccountInfo, ICreateTalentBOPRDirectDeposit, ITalentBOPRDirectDeposit, ITalentBackOfficeDeduction, ITalentBackOfficePayrollSetup } from "../../../../../interfaces/talent_back_office";
import TalentBODirectDepositPopup from "../../../popups/add_direct_deposit/add_direct_deposit";
import { Portal } from "react-portal";
import { talentDirectDepositSliceActions } from "../../../../../redux/talent/details/back_office_payroll_set_up/back_office_direct_deposit_add/direct_deposit_add_reducer";
import { RouteComponentProps } from "react-router-dom";
import { AppRoutes } from "../../../../../routes";
interface Props {
    onClearError: () => void;
    isLoading?: boolean,
    currentLoginUserName: string;
    talentId: string;
    talentName: string;
    handleUpdateClearState: () => void;
    history: RouteComponentProps['history'];
}

const directDepositTableHeader = [
    { title: 'bank_name', code: 'bank_name' },
    { title: 'account_type', code: 'account_type' },
    { title: 'amount', code: 'amount_per_check' },
    { title: 'account_number', code: 'account_number' },
    { title: 'routing_number', code: 'routing_number' },
    { title: 'active', code: 'is_active' },
    { title: 'amount_type', code: 'amount_type' },
]

const TalentBODirectDeposit: React.FC<Props> = props => {
    const {
        onClearError,
        currentLoginUserName,
        talentName,
        talentId,
        handleUpdateClearState
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [addDirectDeposit, setAddDirectDeposit] = useState<boolean>(false);
    const [directDepositSorted, setDirectDepositSorted] = useState<string | null>(null);
    const talentDirectDepositState = useAppSelector((state) => selectSingleTalentPRDDSStateById(talentId)(state));

    const [directDepositData, setDirectDepositData] = useState({} as ITalentBOPRDirectDeposit | null);
    useEffect(() => {
        dispatch(getTalentBOPayrollDirectDepositThunk(talentId));
    }, [])
    useEffect(() => {
        if (talentDirectDepositState.loading === LoadingType.succeeded) {
            setDirectDepositData(talentDirectDepositState.data);
        }
    }, [talentDirectDepositState.loading, talentDirectDepositState.data]);

    function handleDDSortFieldChange(value: string) {
        if (directDepositSorted && directDepositSorted === value) {
            setDirectDepositSorted(null);
        } else {
            setDirectDepositSorted(value);
        }
    }

    const getAmountType = (type) => {
        if (type === "FIXED$AMOUNT") {
            return "Fixed";
        } else if (type === "PERCENTAGE") {
            return "Percentage";
        } else if (type === "REMAININGAMOUNT") {
            return "Remaining";
        } else {
            return type;
        }
    };
    const getAmountPrefix = (type, val) => {
        if (type === "FIXED$AMOUNT") {
            return "$ " + val.toFixed(2);
        } else if (type === "PERCENTAGE") {
            return val.toFixed(2) + " %";
        } else {
            return val;
        }
    };

    const handleClose = () => {
        setAddDirectDeposit(false);
        dispatch(talentDirectDepositSliceActions.clearState());
    }
    const handleSuccessClose = () => {
        setAddDirectDeposit(false);
        handleUpdateClearState();
        dispatch(talentDirectDepositSliceActions.clearState());
    }

    function handleGoToPaycheckHistory(talent_id: string, talent_name: string) {
        props.history.push({
            pathname: `${AppRoutes.employeePortalDetailsPage}/${talent_id}`,
            state: {
                id: talent_id,
                name: talent_name,
            }
        })
    }

    return (
        // <></>
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('direct_deposit')} >
            <div className="tal-dd-container">
                <div className="tal-dd-header">
                    <div className="section-header">{t("bank_setup")}</div>
                    <div><CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("add_change_bank")}
                        enable={talentDirectDepositState.error?.code !== 404 }
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => setAddDirectDeposit(true)}
                    /></div>
                </div>
                <div className="tal-dd-content">
                    {talentDirectDepositState.error && talentDirectDepositState?.error.code === 404 ?
                        (
                            <div className="error-data-msg">
                                <span>{talentDirectDepositState?.error?.message}</span>
                            </div>
                        )
                        :
                        (
                            <div className="tal-dd-table">
                                <SortableTable
                                    headerList={directDepositTableHeader}
                                    sortedField={directDepositSorted}
                                    onSortChange={handleDDSortFieldChange}
                                    flexNumber={getFlexNumber}
                                >
                                    {directDepositData?.bank_accounts?.map((doc, index) => {
                                        return (
                                            <tr key={"branch_list" + index}>
                                                <TableData
                                                    customStyle={{
                                                        flex: getFlexNumber(0),
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    <span>{doc.bank_name}</span>
                                                </TableData>
                                                <TableData
                                                    customStyle={{
                                                        flex: getFlexNumber(1),
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    <span>{doc.account_type}</span>
                                                </TableData>
                                                <TableData
                                                    customStyle={{
                                                        flex: getFlexNumber(2),
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    <span>{getAmountPrefix(doc.amount_type, doc.amount_per_check)}</span>
                                                </TableData>
                                                <TableData
                                                    customStyle={{
                                                        flex: getFlexNumber(3),
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    <span>{doc.account_number}</span>
                                                </TableData>
                                                <TableData
                                                    customStyle={{
                                                        flex: getFlexNumber(4),
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    <span>
                                                        {doc.routing_number}
                                                    </span>
                                                </TableData>
                                                <TableData
                                                    customStyle={{
                                                        flex: getFlexNumber(5),
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    <span>{doc.is_active ?
                                                        <div className="tick-icon">
                                                            <TickGreenIcon width={"100%"} height={"100%"} />
                                                        </div> : <div className="tick-icon">
                                                            <TickGreyIcon width={"100%"} height={"100%"} />
                                                        </div>}</span>

                                                </TableData>
                                                <TableData
                                                    customStyle={{
                                                        flex: getFlexNumber(6),
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    <span>{getAmountType(doc.amount_type)}</span>
                                                </TableData>

                                            </tr>
                                        );
                                    })}
                                </SortableTable>
                            </div>
                        )
                    }
                </div>
                <div className="paycheck-history-label" onClick={()=>handleGoToPaycheckHistory(talentId, talentName)}><span>{t("paycheck_history")}</span></div>
            </div>
            {addDirectDeposit &&
                <Portal>
                    <TalentBODirectDepositPopup
                        visible={addDirectDeposit}
                        title={t("direct_deposit")}
                        onClose={handleClose}
                        onDissmiss={handleClose}
                        currentLoginUserName={currentLoginUserName}
                        talentName={talentName ? talentName.split(" ")[0] : ""}
                        handleClearError={onClearError}
                        lastStep={0}
                        handleSuccessClose={handleSuccessClose}
                        talentId={talentId}

                    />
                </Portal>
            }
        </SectionCard>
    );

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1.2;
        if (value === 2) return 0.8;
        if (value === 3) return 1.5;
        if (value === 4) return 1.5;
        if (value === 5) return 0.5;
        if (value === 6) return 1;

        return 1;
    }
}

export default TalentBODirectDeposit;