import { combineReducers } from "@reduxjs/toolkit";
import { talentAssignmentsReducers } from "./assignments";
// import { talentBackOfficePayChecksAndRiskManagementReducers } from "./back_office_paychecks_and_risk_management";
import { talentBackOfficePayRollSetUpReducers } from "./back_office_payroll_set_up";
import { talentDashboardReducers } from "./dashboard";
// import { talentEmploymentHistoryReducers } from "./employment_history";
import { talentInterviewsReducers } from "./interviews";
import { talentOnBoardingAndDocsReducers } from "./onboarding_and_docs";
import { talentRiskManagementReducers } from "./back_office_paychecks_and_risk_management";
import { terminateTalentSlice } from "./terminate_talent/terminate_talent_reducer";

export const talentDetailsReducers = combineReducers({
    'dashboard': talentDashboardReducers,
    'assignments': talentAssignmentsReducers,
    // ['employmentHistory']: talentEmploymentHistoryReducers,
    'interviews': talentInterviewsReducers,
    'backOfficePayrollSetUp': talentBackOfficePayRollSetUpReducers,
    'RiskManagement': talentRiskManagementReducers,
    'onBoardingAndDocs': talentOnBoardingAndDocsReducers,
    [terminateTalentSlice.name]: terminateTalentSlice.reducer,
});

export * from './dashboard';
export * from './assignments';
export * from './employment_history';
export * from './interviews';
export * from './back_office_payroll_set_up';
export * from './back_office_paychecks_and_risk_management';
export * from './onboarding_and_docs';

export * from './terminate_talent/terminate_talent_selector';
export * from './terminate_talent/terminate_talent_thunk';