import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { IARAgingReport, IInvoiceList } from "../../../../interfaces/report_center_finance_accounting";

export const getARAgingReportList = createAsyncThunk<
    IARAgingReport,
    number,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/ar_aging_report/get",
    async (as_of_date, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getARAgingReport(as_of_date);
            return res.data as IARAgingReport;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getInvoiceList = createAsyncThunk<
    IInvoiceList,
    { company_id: string, end_date: number, aging_type: string },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/ar_aging_report_invoice_list/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getARAgingReportInvoiceList(payload.company_id, payload.end_date, payload.aging_type);
            return res.data as IInvoiceList;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });