import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { IWeeklyTransactionAssignment, AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";




export const getWeeklyTransactionAssignmentsThunk = createAsyncThunk<
    IWeeklyTransactionAssignment[],
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_dashboard//weekly_transactions/assignments/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getWeeklyTransactionAssignments(payload);
            return res.data as IWeeklyTransactionAssignment[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const createTimeCardsThunk = createAsyncThunk<
    string,
    { doc: string[], week_accounting_id: string },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_dashboard/weekly-transactions/create-time-cards/post",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.createTimeCards(payload.week_accounting_id, payload.doc);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });