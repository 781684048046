import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll } from "./paycheck_list_reducer";

const select = (state: IRootState) => state.backOffice.payRoll.PayCheck;
export const selectPayCheckState = createSelector(
    select,
    selectState
);
export const selectPayCheckList = createSelector(select, selectAll)
