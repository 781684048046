import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, IProfilePictureRequestQuery, IProfilePictureUploadUrlResponse } from "../../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../../utils";
import { IAgencies, IAgencyUploadUrlsQuery, ICreateAgency, ICreateAgencyAgencyBranchUser, IUpdateAgency } from "../../../../interfaces/agency_management";
import { S3API } from "../../../../apis/s3_api";

/// Create Agency
export const createAgency = createAsyncThunk<
    string,
    ICreateAgencyAgencyBranchUser,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/agency-management/manage-agency/create',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createAgency(payload);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Update Agency
export const updateAgency = createAsyncThunk<
    string,
    // IAgencies,
    {data: IAgencies, file?: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/agency-management/manage-agency/update',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateAgency(payload.data);
            // if (res.data.url !== "" && payload.file) {
            //     const uploadFile = await S3API.uploadFile(res.data.agency_logo_file_url, payload.file);
            //     return uploadFile.data;
            // }
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Files upload
export const getAgencyLogoUploadURLThunk = createAsyncThunk<
    string,
    IAgencyUploadUrlsQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/agency-directory/manage-agency/agency-logo-upload-url/get',
    async (query, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getAgencyURLForUpload(query);
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data as string
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const uploadAgencyLogoThunk = createAsyncThunk<
    any,
    { url: string, file: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/agency-directory/manage-agency/agency-logo-upload-url/post',
    async (payload, thunkAPI) => {
        try {
            if (payload.file && payload.url) {
         
                const photoRes = await AdminCenterAPI.uploadAgencyLogo(payload.url, payload.file);
                return photoRes.data;
            }
            return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
