import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { NavLink, RouteComponentProps } from "react-router-dom";
import CustomFormSelect, {
  CustomMultiFormSelect,
} from "../../../../components/form_selector/form_select";
import SearchBar from "../../../../components/search_bar";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableFilterEmpty,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import {
  COMPANY_STATUS,
  COMPANY_TYPE,
  LoadingType,
  THEME,
} from "../../../../enums";
import { ICompany, ICreateCompany, IDepartment } from "../../../../interfaces";
import { manageCompanyActions } from "../../../../redux/companies/universal_directory/manage_company/manage_company_reducer";
import {
  getCompaniesList,
  getRoleUsersList,
  postSnackbarMessage,
  selectAccountManagerRoleUsersList,
  selectCompaniesList,
  selectCompaniesListState,
  selectRoleUsersListState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  CustomCheckBox,
  getCompanyStatusText,
  getPlusBtnIcon,
  getTableAccessIcon,
  getWebsiteInfo,
  sortByOrders,
} from "../../../../utils";
import OpenOrderRow from "../../../../utils/open_orders";
// import { companyStatusOptions } from "../../../../variables";
import CreateCompanyPopup from "../../popup/create_company/create_company";
import "./universal_directory_page.scss";
import { getCompanyStatus, selectCompanyStatusList, selectCompanyStatusState } from "../../../../redux/admin_center";
import { CircleDownloadIcon, DownloadIcon } from "../../../../icons";
import UploadRecordPopup from "../../../components/import_data_file/import_data_file";
import UrlBuilder from "../../../../apis/url_builder";
import { selectImportDataFileUrlState, selectUpdateImportDataFileUrlState, updateImpDataFileUploadUrl } from "../../../../redux/upload_files";
import { importUrlActions } from "../../../../redux/upload_files/import_data_file_reducer";

interface Props extends RouteComponentProps<any> { }
const tableHeader = [
  { title: "access_company", code: "access_company" },
  { title: "orders", code: "open_orders" },
  { title: "company", code: "name" },
  { title: "status", code: "status" },
  { title: "department", code: "name" },
  { title: "website", code: "website" },
  { title: "address", code: "street_address" },
  { title: "city", code: "city" },
  { title: "state", code: "state" },
  { title: "zip_code", code: "zip_code" },
];
const companyInitialForm: ICreateCompany = {
  name: "",
  status: COMPANY_STATUS.prospect,
  street_address: "",
  city: "",
  state: "",
  zip_code: "",
  website: "",
  phone: "",
  sector_type: "",
  summary: "",
  logo: "",
  social_media_links: {
    linkedin_url: null,
    twitter_url: null,
    facebook_url: null,
    pinterest_url: null,
  },
  staffing_agency_competitors: [],
  professional_skills: [],
  certifications: [],
  education: [],
  credentials: [],
  safety_equipment: [],
  uses_competitors: false,
};

const UniversalDirectoryPage: React.FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const companiesListState = useAppSelector((state) =>
    selectCompaniesListState(state)
  );
  const companiesList = useAppSelector((state) => selectCompaniesList(state));
  const accountManagersList = useAppSelector((state) =>
    selectAccountManagerRoleUsersList(state)
  );
  const [search, setSearch] = useState("");
  const [sortedField, setSortedField] = useState<string | null>('id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [addCompany, setAddCompany] = useState<boolean>(false);

  const [addCompanyData, setAddCompanyData] = useState<ICompany | undefined>(
    undefined
  );

  const { history, match, location } = props;
  const { t } = useTranslation();
  const [includeDepartment, setIncludeDepartment] = useState(false);
  const [accountManagers, setAccountManagers] = useState<string[]>([]);
  // const [status, setStatus] = useState<string | null>(null);
  const [status, setStatus] = useState<string[]>([]);
  const [activeAssignment, setActiveAssignment] = useState<boolean>(false);

  ///Company Status
  //  const companyStatusState = useAppSelector((state) => selectCompanyStatusState(state));
  const companyStatusList = useAppSelector((state) => selectCompanyStatusList(state));

  const importDataFileUrlState = useAppSelector((state) => selectImportDataFileUrlState(state));
  const updateImportDataFileUrlState = useAppSelector((state) => selectUpdateImportDataFileUrlState(state));

  const [importCompanies, setImportCompanies] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  useEffect(() => {
    getCompanies();
    checkAddCompany();
    dispatch(getRoleUsersList());
    dispatch(getCompanyStatus());
    return () => { };
  }, []);

  useEffect(() => {
    if (companiesListState.error != null && companiesList.length != 0) {
      dispatch(postSnackbarMessage(companiesListState.error.message));
    }
    return () => { };
  }, [companiesListState.error]);

  useEffect(() => {
    if (updateImportDataFileUrlState?.loading === LoadingType.succeeded && updateImportDataFileUrlState?.response) {
      if (snackBarMessage !== "") {
        dispatch(postSnackbarMessage(snackBarMessage ?? null));
        dispatch(importUrlActions.clearUpdateImportUrlStateError())
      }
      setImportCompanies(false)
      // getCompanies()
    }
    return () => { };
  }, [updateImportDataFileUrlState?.loading]);

  useEffect(() => {
    if (importDataFileUrlState?.loading === LoadingType.succeeded && importDataFileUrlState?.response) {
      dispatch(updateImpDataFileUploadUrl({
        baseUrl: UrlBuilder.updateImportCompanies,
        import_id: importDataFileUrlState?.response?.id ?? ""
      }))
    }

  }, [importDataFileUrlState?.loading, importDataFileUrlState?.response]);

  function getCompanies() {
    dispatch(getCompaniesList());
  }

  function checkAddCompany() {
    setTimeout(() => {
      if (
        location &&
        location.state != null &&
        (location.state as any).newCompany === true
      ) {
        setAddCompany(true);
      }
    }, 1000);
  }

  useEffect(() => {
    const state = history.location.state as any;
    if (state && state.activeAssignment) {
      setActiveAssignment(true);
      setIncludeDepartment(!state.includeDepartments);
    }
    if (state && state.includeDepartments) {
      setIncludeDepartment(state.includeDepartments);
    }
  }, [history]);

  useEffect(() => {
    const state = location.state as any;
    if (state && state["newCompany"]) {
      setAddCompanyData({
        ...(companyInitialForm as ICompany),
        name: state["company_name"] ?? "",
      });
    }
  }, [location]);
  function getFilteredList(): (ICompany | IDepartment)[] {
    const list = includeDepartment ? companiesList : getOnlyCompanies();
    let sortedList: (ICompany | IDepartment)[] | undefined;
    let resultList: (ICompany | IDepartment)[] | undefined;

    if (activeAssignment) {
      resultList = list.filter((doc) => {
        const onActiveAssignmentFilter =
          doc.active_assignment > 0 ? doc.active_assignment : false;
        return onActiveAssignmentFilter;
      });
    }

    if (accountManagers.length != 0) {
      resultList = (resultList ?? list).filter((doc) => {
        const filter =
          doc.account_managers && doc.account_managers.length != 0
            ? doc.account_managers.some((s) => accountManagers.indexOf(s) >= 0)
            : false;
        return filter;
      });
    }

    // if (status != null && status != "") {
    //   resultList = (resultList ?? list).filter((doc) => {
    //     const statusFilter =
    //       doc.status_id && status
    //         ? doc.status_id === status
    //         : false;
    //     return statusFilter;
    //   });
    // }

    if (status.length != 0) {
      resultList = (resultList ?? list).filter((doc) => {
        const filter =
          doc.status && doc.status.length != 0
            ? status.includes(doc.status_id!)
            : false;
        return filter;
      });
    }

    if (sortedField != null) {
      if (sortedField === "open_orders") {
        sortedList = sortByOrders([...(resultList ?? list)], "order_status");
      }
      else {
        sortedList = [...(resultList ?? list)].sort((a, b) => {
          const valueA =
            a[sortedField] != null && a[sortedField] != undefined
              ? typeof a[sortedField] == typeof "1"
                ? a[sortedField].trim().toLowerCase()
                : a[sortedField]
              : "";
          const valueB =
            b[sortedField] != null
              ? typeof b[sortedField] == typeof "1"
                ? b[sortedField].trim().toLowerCase()
                : b[sortedField]
              : "";
          if (sortDirection === "asc") {
            return valueA > valueB ? 1 : -1;
          } else {
            return valueA < valueB ? 1 : -1;
          }
        });
      }
    }

    return (sortedList ?? resultList ?? list).filter((doc) => {
      const str = search.trim().toLowerCase();
      return Object.values(doc).some(
        (val) => val && val.toString().trim().toLowerCase().includes(str)
      );
    });
  }

  function handleSortFieldChange(value: string) {
    if (value === "open_orders") {
      if (sortedField && sortedField === value) {
        // setSortedField(null)
      } else {
        setSortedField(value);
      }
    }
    else {
      if (sortedField && sortedField === value) {
        // setSortedField(null)
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortedField(value);
        setSortDirection('asc');
      }
    }
  }


  function handleUserSelect(value: ICompany | IDepartment) {
    if (value.type === COMPANY_TYPE.company) {
      props.history.push({
        pathname: `${AppRoutes.companiesDetailsPage}/${value.id}`,
        state: {
          id: value.id,
          name: value.name,
        },
      });
      return;
    } else if (
      value.type === COMPANY_TYPE.department &&
      (value as IDepartment).company_id != null
    ) {
      const department = value as IDepartment;
      props.history.push({
        pathname: `${AppRoutes.companiesDetailsPage}/${department.company_id}/dashboard/${department.id}`,
        state: {
          id: department.company_id,
          name: department.company_name,
          departmentId: department.id,
          departmentName: department.name,
        },
      });
      return;
    } else {
      dispatch(postSnackbarMessage(t("unable_to_open_please_try_again_later")));
    }
  }


  function handleCompanySelect(value: ICompany | IDepartment) {
    if (value.type === COMPANY_TYPE.company) {
      props.history.push({
        pathname: `${AppRoutes.companiesDetailsPage}/${value.id}`,
        state: {
          id: value.id,
          name: value.company_name,
        },
      });
      return;
    }
    if (value.type === COMPANY_TYPE.department) {
      props.history.push({
        pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
        state: {
          id: value.company_id,
          name: value.company_name,
        },
      });
      return;
    }
  }

  function handleDepartmentSelect(value: ICompany | IDepartment) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.id,
        departmentName: value.name,
      },
    });
    return;
  }

  function getOnlyCompanies() {
    return companiesList.filter((doc) => doc.type === COMPANY_TYPE.company);
  }

  const handleClearError = () => {
    dispatch(importUrlActions.clearImportUrlStateError())
  }

  const handleSnackBar = () => {
    setSnackBarMessage(t("companies_uploaded_successfully"))
  }

  return (
    <div className="cps-ud-container">
      <div className="cps-ud-table-header">
        <TableHeaderOptions
          roleUsers={accountManagersList.map((doc) => ({
            label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
            value: doc.id,
          }))}
          totalCount={getFilteredList().length}
          departmentActive={includeDepartment}
          onChangeDepartment={() => setIncludeDepartment(!includeDepartment)}
          onChangeRoleUser={setAccountManagers}
          selectedRoleUsers={accountManagers}
          search={search}
          onSearchChange={setSearch}
          selectedStatus={status}
          onStatusChange={setStatus}
          // companyStatusList={[
          //   { label: "all", value: "" },
          //   ...companyStatusOptions,
          // ]}
          companyStatusList={companyStatusList.map((doc) => ({
            label: doc.company_status,
            value: doc.id,
          }))}
        />

        <div className="cps-ud-actions">
          <div className="upl-files">
            <CustomButton
              leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} />}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("upload_companies")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setImportCompanies(true)}
            />
          </div>
          <div className="get-comp">
            <NavLink to={`${AppRoutes.apolloSearchImport}/companies`} >
              <CustomButton
                leftIcon={<div className="btn-icon circle-download-svg">
                  <CircleDownloadIcon width={"100%"} height={"100%"} style={{ fill: "#fff" }} />
                </div>}
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("get_company")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => { }}
              />
            </NavLink>
          </div>
          <div className="cps-ud-add-user-btn">
            <CustomButton
              leftIcon={getPlusBtnIcon()}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("add_company")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setAddCompany(true)}
            />
          </div>
        </div>
      </div>
      <div className="cps-ud-table">{getCompaniesTableList()}</div>
      {addCompany && (
        <Portal>
          <CreateCompanyPopup
            visible={addCompany}
            title={t("add_company")}
            successTitle={t("new_company")}
            onClose={() => {
              setAddCompany(false);
              dispatch(manageCompanyActions.clearManageCompanyState());
            }}
            onSuccessClose={handleAddCompanySuccess}
            onGotoAgencyDashboard={handleGotoAgencyDashboard}
            onGotoCreated={handleOnGotoCompany}
            company={addCompanyData}
          />
        </Portal>
      )}
      {importCompanies && (
        <Portal>
          <UploadRecordPopup
            visible={importCompanies}
            title={t("upload_companies")}
            baseUrl={UrlBuilder.importCompanies}
            onClose={() => setImportCompanies(false)}
            loading={importDataFileUrlState?.loading}
            error={importDataFileUrlState?.error}
            onClearError={handleClearError}
            handleSnackBar={handleSnackBar}
            importType={"Company"}
          />
        </Portal>
      )}
    </div>
  );

  ///Add user action
  function handleAddCompanySuccess() {
    setAddCompany(false);
    getCompanies();
    dispatch(manageCompanyActions.clearManageCompanyState());
  }

  function handleGotoAgencyDashboard() {
    handleAddCompanySuccess();
    props.history.push(AppRoutes.dashboardPage);
  }

  function handleOnGotoCompany(id: string, name: string) {
    handleAddCompanySuccess();
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${id}`,
      state: {
        id: id,
        name: name,
      },
    });
  }

  /// Users table list
  function getCompaniesTableList() {
    if (companiesListState.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (companiesListState.error != null && companiesList.length === 0) {
      return (
        <TableErrorHandler
          error={companiesListState.error}
          onRefresh={getCompanies}
        />
      );
    }
    if (companiesList.length === 0) {
      return (
        <TableEmpty
          title={t("no_company_matches_found")}
          onClick={() => getCompanies()}
        />
      );
    }
    if (getFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_companies_found")} />;
    }


    return (
      <SortableTable
        headerList={tableHeader}
        sortedField={sortedField}
        onSortChange={handleSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={sortDirection}
      >
        {getFilteredList().map((doc, index) => {
          return (
            <tr key={doc.id}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                {getTableAccessIcon(() => handleUserSelect(doc))}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(1) }}>
                {OpenOrderRow(doc, "order_status", t)}
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(2) }}
                onClick={() => handleCompanySelect(doc)}
                isButton={true}
              >
                <span>
                  {doc.type === COMPANY_TYPE.company
                    ? doc.name
                    : doc.company_name}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(3) }}>
                <span>
                  {doc.status ?? ""}
                </span>
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(4) }}
                onClick={() => doc.type === COMPANY_TYPE.department ? handleDepartmentSelect(doc) : handleCompanySelect(doc)}
                // isButton={doc.type === COMPANY_TYPE.department}
                isButton={true}
              >
                <span>
                  {doc.type === COMPANY_TYPE.department
                    ? doc.name
                    : doc.corporate
                  }
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                {getWebsiteInfo(doc.website)}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(6) }}>
                <span>{doc.street_address ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(7) }}>
                <span>{doc.city ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(8) }}>
                <span>{doc.state ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(9) }}>
                <span>{doc.zip_code ?? ""}</span>
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 1;
    if (value === 1) return 1;
    if (value === 2) return 2;
    if (value === 3) return 1.5;
    if (value === 4) return 2;
    if (value === 5) return 1;
    if (value === 6) return 2;
    if (value === 7) return 1;
    if (value === 8) return 1;
    if (value === 9) return 1;
    return 1;
  }
};

interface TableHeaderOptionsProps {
  /// [Role users]
  roleUsers: Array<{ label: string; value: string }>;
  selectedRoleUsers: string[];
  onChangeRoleUser: (value: string[]) => void;
  /// [Department]
  departmentActive: boolean;
  onChangeDepartment: () => void;
  totalCount: number;
  /// [Search]
  search: string;
  onSearchChange: (value: string) => void;
  /// [Status]
  // selectedStatus: string | null;
  selectedStatus: string[];
  onStatusChange: (value: string[]) => void;
  companyStatusList: Array<{ label: string; value: string }>;
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
  const { t } = useTranslation();
  return (
    <div className="cps-ud-table-header-options">
      <div className="account-manager-selector">
        <CustomMultiFormSelect
          label={t("account_manager")}
          name={"account_manager"}
          list={props.roleUsers}
          onChange={props.onChangeRoleUser}
          required={false}
          placeholder={t("select")}
          value={props.selectedRoleUsers}
          customStyle={{ width: "-webkit-fill-available" }}
        />
      </div>
      <div className="company-status-selector">
        {/* <CustomFormSelect
          customStyle={{ width: "-webkit-fill-available" }}
          name={"company_status"}
          value={props.selectedStatus}
          required={false}
          label={t("status")}
          placeholder={t("all")}
          list={props.companyStatusList}
          onChange={props.onStatusChange}
        /> */}
        <CustomMultiFormSelect
          label={t("status")}
          name={"company_status"}
          list={props.companyStatusList}
          onChange={props.onStatusChange}
          required={false}
          placeholder={t("all")}
          value={props.selectedStatus}
          customStyle={{ width: "-webkit-fill-available" }}
        />
      </div>
      <div className="cps-ud-table-search-bar">
        <SearchBar
          value={props.search}
          onChange={props.onSearchChange}
          onSearch={() => { }}
        />
      </div>
      <div className="deparment-toggle">
        <CustomCheckBox
          name={"department"}
          title={t("include_departments")}
          checked={props.departmentActive}
          onClick={props.onChangeDepartment}
        />
      </div>
      <div className="total-count">
        <span>
          {`${t("total_count")}: `}
          <span className="total-count-number">{props.totalCount}</span>
        </span>
      </div>
    </div>
  );
};

export default UniversalDirectoryPage;
