
import { useState } from 'react';
import { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { CompaniesIcon, UserIcon } from '../../../../icons';
import { AgencyBranch, AppError, ICreateUser, ProfileRole } from '../../../../interfaces';
import { CustomButton, RegxPattern } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { ROLE, THEME } from '../../../../enums';
import ApiError from '../../../../components/api_error';
import { IAgencies, ICreateAgency } from '../../../../interfaces/agency_management';
import CustomStepIndicator from '../../../../components/custom_step_indicator/custom_step_indicator';
import StepThreeUserForm from './step_three/step_three_user_form';
import StepTwoAgencyBranchForm from './step_two/step_two_agency_branch_form';
import StepOneAgencyForm from './step_one/step_one_agency_form';


interface Props {
    loading: boolean,
    error: AppError | null | undefined,
    agency?: IAgencies,
    selectedPhoto: File | null;
    lastStep: number;
    onAgencyLogoChange: (value: File) => void;
    onRemoveAgencyLogo: () => void;
    onClose: () => void,
    onSubmit: (value: { agency: ICreateAgency, agency_branch: AgencyBranch, user: ICreateUser }) => void,
    onDissmiss?: () => void,
    onClearError: () => void,
}

const getAgencyFormData = (value: IAgencies): ICreateAgency => {
    return {
        id: value.id ?? "",
        name: value.name ?? "",
        fein: value?.fein ?? "",
        street_address: value?.street_address ?? "",
        city: value?.city ?? "",
        state: value?.state ?? "",
        zip_code: value?.zip_code ?? "",
        phone: value?.phone ?? "",
        fax: value?.fax ?? "",
        web_url: value?.web_url ?? "",
        agency_logo_file_path: value?.agency_logo_file_path ?? "",
        agency_logo_file_url: value?.agency_logo_file_url ?? "",
        sic_code: value?.sic_code ?? '',
        domain: value.domain ?? '',
        search_and_import_data: value?.search_and_import_data ?? false,
    };
};

const agencyInitialForm: ICreateAgency = {
    id: "",
    name: "",
    fein: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    phone: "",
    fax: "",
    web_url: "",
    agency_logo_file_path: "",
    agency_logo_file_url: "",
    sic_code: "",
    domain: '',
    search_and_import_data: false,
}

// const getAgencyBranchFormData = (value: Agency): AgencyBranch => {
//     return {
//         name: value.name ?? "",
//         fein: value?.fein ?? "",
//         street_address: value?.street_address ?? "",
//         city: value?.city ?? "",
//         state: value?.state ?? "",
//         zip_code: value?.zip_code ?? "",
//         phone: value?.phone ?? "",
//         fax: value?.fax ?? "",
//         web_url: value?.web_url ?? "",
//         sic_code: value?.sic_code ?? '',
//         search_and_import_data: value?.search_and_import_data ?? false,
//     };
// };

const agencyBranchInitialForm: AgencyBranch = {
    name: "",
    fein: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    phone: "",
    fax: "",
    web_url: "",
    sic_code: "",
    search_and_import_data: false,
}

// const getUserFormData = (value: Profile): ICreateUser => {
//     return {
//         first_name: value.first_name ?? '',
//         last_name: value.last_name ?? '',
//         street_address: value.street_address ?? '',
//         city: value.city ?? '',
//         state: value.state ?? '',
//         country: value.country ?? '',
//         zip_code: value.zip_code ?? '',
//         email: value.email ?? '',
//         phone: value.phone ?? '',
//         office_phone: value.office_phone ?? '',
//         roles: getUserProfileRoles(value.roles) ?? [],
//         job_title: value.job_title,
//         show_ssn: value.show_ssn,
//         texting: value.texting,
//         view_onboarding_data: value.view_onboarding_data,
//         search_and_import_data: value.search_and_import_data,
//         social_media: value.social_media ?? {
//             linkedin_url: null,
//             facebook_url: null,
//             twitter_url: null,
//             pinterest_url: null,
//         },
//     };
// };

const userInitialForm: ICreateUser = {
    first_name: '',
    last_name: '',
    street_address: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    email: '',
    phone: '',
    office_phone: '',
    roles: [ROLE.administrator],
    job_title: '',
    show_ssn: false,
    texting: false,
    view_onboarding_data: false,
    search_and_import_data: false,
    social_media: {
        linkedin_url: null,
        facebook_url: null,
        twitter_url: null,
        pinterest_url: null,
    },

}

const AgencyForm: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { loading, error, agency, selectedPhoto, onClose, onSubmit, onClearError, onRemoveAgencyLogo, onAgencyLogoChange } = props
    const [agencyFormState, setAgencyFormState] = useState<ICreateAgency>(
        agency ? getAgencyFormData(agency) : agencyInitialForm);
    const [agencyBranchFormState, setAgencyBranchFormState] = useState<AgencyBranch>(
        // agency ? getAgencyBranchFormData(agency) : agencyBranchInitialForm
        agencyBranchInitialForm
    );

    const [userFormState, setUserFormState] = useState<ICreateUser>(
        // props.user ? getUserFormData(props.user) : userInitialForm
        userInitialForm
    );

    function handleAgencyFieldChange(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setAgencyFormState({
                ...agencyFormState,
                [parent]: { ...agencyFormState[parent], [fieldId]: value },
            });
        } else {
            setAgencyFormState({ ...agencyFormState, [fieldId]: value });
        }
    }

    function handleAgencyBranchFieldChange(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setAgencyBranchFormState({
                ...agencyFormState,
                [parent]: { ...agencyBranchFormState[parent], [fieldId]: value },
            });
        } else {
            setAgencyBranchFormState({ ...agencyBranchFormState, [fieldId]: value });
        }
    }


    function handleUserFieldChange(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setUserFormState({
                ...userFormState,
                [parent]: { ...userFormState[parent], [fieldId]: value },
            });
        } else {
            setUserFormState({ ...userFormState, [fieldId]: value });
        }
    }



    const [currentStep, setCurrentStep] = useState<{
        label: string;
        value: number;
    }>({ label: t("step_option", { value: 1 }), value: 0 });

    const stepsList: Array<{ label: string; value: number }> = [
        { label: t("step_option", { value: 1 }), value: 0 },
        ...Array.from(Array(props.lastStep).keys()).map((doc, index) => ({
            label: t("step_option", { value: index + 2 }),
            value: index + 1,
        })),
        {
            label: t("step_option", { value: props.lastStep + 2 }),
            value: props.lastStep + 1,
        },
        {
            label: t("step_option", { value: props.lastStep + 3 }),
            value: props.lastStep + 2,
        },
    ];


    function getTitleByStep(): string {
        if (currentStep.value === props.lastStep + 2)
            return t("add_admin_user");
        if (currentStep.value === props.lastStep + 1)
            return t("add_agency_branch");
        return t("add_agency");
    }
    function getIconByStep(): any {
        if (currentStep.value === props.lastStep + 2)
            return <UserIcon width={"100%"} height={"100%"} />
        if (currentStep.value === props.lastStep + 1)
            return <CompaniesIcon width={"100%"} height={"100%"} />
        return <CompaniesIcon width={"100%"} height={"100%"} />;
    }

    function _isFormValid() {
        const { name, domains, domain } = agencyFormState;
        const { first_name, email } = userFormState;
        const pattern = RegxPattern.email;
        if (currentStep.value === props.lastStep + 0) {
            if (name === '') return false;
            if (domain === '') return false;
        }
        if (currentStep.value === props.lastStep + 2) {
            if (first_name === '') return false;
            if (pattern.test(email) === false) return false;
        }
        return true;
    }

    return (
        <div className="uf-container">
            <div className="uf-header">
                {agency && agency.id ?
                    <FormDialogTilteHeader
                        title={currentStep.value === props.lastStep + 0 ? t("edit_agency") : ""}
                        titleIcon={currentStep.value === props.lastStep + 0 ? <UserIcon width={"100%"} height={"100%"} /> : ""}
                    />
                    : <FormDialogTilteHeader
                        title={getTitleByStep()}
                        titleIcon={getIconByStep()}
                    />}
            </div>
            <div className="cnt-f-steps-indicator">
                {agency && agency.id ? "" : <CustomStepIndicator
                    selected={currentStep}
                    stepsList={stepsList}
                    onClick={(value) => {
                    }}
                />}
            </div>
            <div className="uf-content">
                {getActiveStep()}
                {error && <div className={"error-section"}>
                    <ApiError message={error.message} onClose={onClearError} />
                </div>}
            </div>
            <div className="uf-actions">
                <div className="btn-cancel">
                    {currentStep.value != 0 && currentStep.value <= props.lastStep + 2 ? (
                        agency && agency.id && currentStep.value === props.lastStep + 0 ? "" :
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("back")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleBack}
                            />) :
                        (<CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('cancel')}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={onClose}
                        />)}
                </div>

                <div className="btn-save">
                    {currentStep.value === props.lastStep + 2 ? (
                        <CustomButton
                            loading={loading}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('save')}
                            enable={_isFormValid()}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => onSubmit({ agency: agencyFormState, agency_branch: agencyBranchFormState, user: userFormState })}
                        />)
                        : (
                            agency && agency.id && currentStep.value === props.lastStep + 0 ?

                                <CustomButton
                                    loading={loading}
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={t('save')}
                                    enable={_isFormValid()}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => onSubmit({
                                        agency: agencyFormState,
                                        agency_branch: agencyBranchFormState,
                                        user: userFormState
                                    })}
                                />

                                :
                                <CustomButton
                                    loading={false}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("next")}
                                    enable={_isFormValid()}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={handleNext}
                                />
                        )}
                </div>
            </div>
        </div>
    );

    function handleBack() {
        if (currentStep.value != 0 && currentStep.value <= props.lastStep + 2) {
            setCurrentStep(stepsList[currentStep.value - 1]);

        }
    }

    function handleNext() {
        if (currentStep.value < props.lastStep + 2) {
            setCurrentStep(stepsList[currentStep.value + 1]);
        }
    }

    function getActiveStep() {
        if (currentStep.value === props.lastStep + 2) {
            return (
                <div>
                    {agency && agency.id ? "" : <StepThreeUserForm
                        handleFieldChange={handleUserFieldChange}
                        formState={userFormState}
                    />}
                </div>
            );
        }
        if (currentStep.value === props.lastStep + 1) {
            return (
                <div>
                    {agency && agency.id ? "" : <StepTwoAgencyBranchForm
                        formState={agencyBranchFormState}
                        handleFieldChange={handleAgencyBranchFieldChange}
                    />}
                </div>
            );
        }

        return (
            <StepOneAgencyForm
                formState={agencyFormState}
                selectedPhoto={selectedPhoto}
                setFormState={setAgencyFormState}
                onAgencyLogoChange={onAgencyLogoChange}
                onRemoveAgencyLogo={onRemoveAgencyLogo}
                handleFieldChange={handleAgencyFieldChange}
            />
        );
    }

}

export default AgencyForm;