import './custom_pab.scss';

interface Props {
    children: React.ReactNode,
    onClick: () => void,
    customStyle?: React.CSSProperties,
}

const CustomPab: React.FunctionComponent<Props> = (props) => {

    return (
        <button className="custom-pab" onClick={props.onClick} style={{ ...props.customStyle }}>
            {props.children}
        </button>
    )
}

export default CustomPab;