import { createSlice } from "@reduxjs/toolkit";
import { AppError, IPayRollDetails } from "../../../interfaces";
import { LoadingType } from "../../../enums";
import * as thunk from "./payroll_details_thunk";

export interface EPPayRollDetailsState {
    error?: AppError | null,
    loading: LoadingType,
    response?: IPayRollDetails,
}

const initialState: EPPayRollDetailsState = {
    error: null,
    loading: LoadingType.idle,
    response: {} as IPayRollDetails
}


export const epPayrollDetailsSlice = createSlice({
    name: 'epPayrollDetails',
    initialState: initialState,
    reducers: {
    
    },
    extraReducers: (builder) => {
        builder
        .addCase(thunk.getEPPayrollDetailsList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
        .addCase(thunk.getEPPayrollDetailsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
        .addCase(thunk.getEPPayrollDetailsList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))

        .addDefaultCase(state => ({ ...state }));
    }
});

export const epPayrollDetailsActions = epPayrollDetailsSlice.actions;
export const selectState = (state: EPPayRollDetailsState) => state;



