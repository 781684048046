import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ApiError from "../../../../components/api_error";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { LoadingType, THEME } from "../../../../enums";
import { PaperPlaneIcon } from "../../../../icons";
import { AppError } from "../../../../interfaces";
import { ISendOnBoardingLink } from "../../../../interfaces/onboarding_link";
import {
  getTalentDropdown,
  selectTalentsDropdownList,
  selectTalentsDropdownListEntities,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import {
  CustomButton,
  enforceFormat,
  formatToPhone,
  FormInput,
  RegxPattern,
} from "../../../../utils";
import "./send_onboarding_link_form.scss";

const formOptions = {
  /// 1st row
  talent: {
    field: "talent_id",
    type: "text",
    label: "talent",
    secure: false,
    required: true,
    placeholder: "select",
    keyboardtype: "",
  },
  email: {
    field: "email",
    type: "email",
    label: "email",
    pattern: RegxPattern.email,
    secure: false,
    required: true,
    placeholder: "",
    keyboardtype: "",
  },
  phone_number: {
    field: "phone_number",
    type: "tel",
    label: "phone_number",
    secure: false,
    required: false,
    placeholder: "(888) 888-8888",
    keyboardtype: "",
    maxLength: 16,
    onKeyUp: formatToPhone,
    onKeyDown: enforceFormat,
  },
};

interface Props {
  title: string;
  error?: AppError | null;
  loading: LoadingType;
  currentLoginUserName: string;
  onCancel: () => void;
  onClearError: () => void;
  onSuccessClose: () => void;
  onSubmit: (doc: { forceResend: boolean; value: ISendOnBoardingLink }) => void;
  talentId?: string;
  setDisable?: boolean;
}

const SendOnBoardingLinkForm: React.FunctionComponent<Props> = (props) => {
  const [formState, setFormState] = useState<ISendOnBoardingLink>({
    talent_id: props.talentId ?? "",
    email: "",
    phone_number: "",
    name: "",
    talent_name: "",
    first_name: "",
    last_name: "",
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getTalentDropdown({}));
    return () => {};
  }, []);
  const talentList = useAppSelector((state) =>
    selectTalentsDropdownList(state)
  );
  const talentEntities = useAppSelector((state) =>
    selectTalentsDropdownListEntities(state)
  );

  useEffect(() => {
    if (formState.talent_id && talentEntities !== undefined) {
      const talent = talentEntities[formState.talent_id];
      setFormState({
        ...formState,
        email: talent && talent?.id ? talent?.email : "",
        name: talent && talent?.id ? talent?.name : "",
      });
    }
    return () => {};
  }, [formState.talent_id, talentEntities]);

  function handleFieldChange(fieldId, value) {
    setFormState({ ...formState, [fieldId]: value });
  }

  function getTitle() {
    if (props.loading === LoadingType.succeeded)
      return `${t("onboarding_link")}`;
    return `${t("hi_lets_do_some_onboarding", {
      name: props.currentLoginUserName,
    })}`;
  }

  return (
    <div className="dshb-soa-f-container send-onboarding-form">
      <div className="dshb-soa-f-header">
        <FormDialogTilteHeader
          title={getTitle()}
          subTitle={
            props.loading === LoadingType.succeeded
              ? undefined
              : t("to_whom_would_you_like_to_send_an_onboarding")
          }
          titleIcon={
            <PaperPlaneIcon
              width={"100%"}
              height={"100%"}
              style={{ color: THEME.talentOptionTextColor }}
            />
          }
        />
      </div>
      <div className="dshb-soa-f-content">
        {getUI()}
        {props.error && (
          <div className={"error-section"}>
            <ApiError
              message={props.error.message}
              onClose={props.onClearError}
            />
          </div>
        )}
      </div>
      {getActions()}
    </div>
  );

  function _isFormValid() {
    const { talent_id, email } = formState;
    if (talent_id === "") return false;
    if (RegxPattern.email.test(email) === false) return false;
    return true;
  }

  function getUI() {
    if (props.loading === LoadingType.succeeded) {
      return (
        <div className="dshb-soa-f-mark-success-holder">
          <DocSavedText>
            <span className="aknw-name">
              {t("well_done", { name: props.currentLoginUserName })}
            </span>
            <span className="info-txt">{`${t(
              "you_sent_onboarding_link_to"
            )}:`}</span>
            <span className="talent_name">{formState.name}</span>
            <span className="email">{formState.email}</span>
          </DocSavedText>
        </div>
      );
    }

    return (
      <div className="dshb-soa-form">
        <div className="dshb-soa-f-column">
          <div
            key={formOptions.talent.field}
            className={"dshb-soa-f-input-holder"}
          >
            <CustomFormSelect
              list={talentList.map((doc) => ({
                label: doc.name,
                value: doc.id,
              }))}
              customStyle={{ width: "-webkit-fill-available" }}
              name={formOptions.talent.field}
              value={formState[formOptions.talent.field]}
              required={formOptions.talent.required}
              label={t(formOptions.talent.label)}
              placeholder={t(formOptions.talent.placeholder)}
              onChange={(value) =>
                handleFieldChange(formOptions.talent.field, value)
              }
              disabled={props.setDisable ?? false}
            />
          </div>
          <div
            key={formOptions.email.field}
            className={"dshb-soa-f-input-holder"}
          >
            <FormInput
              validations={formValidators}
              id={formOptions.email.field}
              onChange={handleFieldChange}
              secure={formOptions.email.secure}
              required={formOptions.email.required}
              placeholder={formOptions.email.placeholder}
              type={formOptions.email.type}
              value={formState[formOptions.email.field]}
              label={t(formOptions.email.label)}
              keyboardtype={formOptions.email?.keyboardtype}
            />
          </div>
          {/* <div key={formOptions.phone_number.field} className={"dshb-soa-f-input-holder"}>
                        <FormInput
                            validations={formValidators}
                            id={formOptions.phone_number.field}
                            onChange={handleFieldChange}
                            secure={formOptions.phone_number.secure}
                            required={formOptions.phone_number.required}
                            placeholder={formOptions.phone_number.placeholder}
                            type={formOptions.phone_number.type}
                            value={formState[formOptions.phone_number.field]}
                            label={t(formOptions.phone_number.label)}
                            keyboardtype={formOptions.phone_number?.keyboardtype}
                            maxLength={formOptions.phone_number.maxLength}
                            onKeyDown={enforceFormat}
                            onKeyUp={formatToPhone}
                        />
                    </div> */}
        </div>
      </div>
    );
  }

  function getActions() {
    if (props.loading === LoadingType.succeeded) {
      return (
        <div className="dshb-soa-f-actions">
          <div className="btn-save">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("ok")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={props.onSuccessClose}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="dshb-soa-f-actions">
        <div className="btn-save">
          <CustomButton
            loading={props.loading === LoadingType.pending}
            textStyle={{ textTransform: "capitalize" }}
            name={
              props.error?.message === "link already sent to talent" &&
              props.error?.code === 400
                ? t("send_anyway")
                : t("send")
            }
            enable={_isFormValid()}
            backgroundColor={
              props.error != null && props.error?.code === 400
                ? THEME.secondaryColor6
                : THEME.defaultHighLightColor
            }
            onClick={() =>
              props.onSubmit({
                forceResend:
                  props.error?.message === "link already sent to talent" &&
                  props.error?.code === 400
                    ? true
                    : false,
                value: formState,
              })
            }
          />
        </div>
      </div>
    );
  }

  function formValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case formOptions.talent.field: {
        if (value.text === "") return t("validators.required");
        return null;
      }
      case formOptions.email.field: {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.email.test(value.text) === false)
          return t("validators.enterValidEmail");
        return null;
      }
      case formOptions.phone_number.field: {
        if (!value.text) return null;
        if (RegxPattern.usPhone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      default: {
        return null;
      }
    }
  }
};

export default SendOnBoardingLinkForm;
