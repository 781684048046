import React, { useMemo, useState } from 'react';
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: "column" as "column",
    justifyContent: 'center',
    alignItems: "center",
    padding: "25px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

interface Props {
    maxFiles: number | null;
    maxSize?: number | null;
    accept?: any | null;
    handleFiles: (any) => void;
    handbookMsg?: boolean;
}

function DropZone(props: Props) {
    const { maxFiles, handleFiles, handbookMsg } = props
    const { t } = useTranslation();

    const [files, setFiles] = useState<any>();
    const [rejectedFiles, setRejectedFiles] = useState<any>();

    function otherProperties() {
        let properties = {}
        const options = ['accept', 'maxSize'];
        for (let option of options)
            if (props[option])
                properties = { ...properties, [option]: props[option] };
        return properties;
    }

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxFiles: maxFiles ? maxFiles : 0,
        multiple: (maxFiles && maxFiles === 1) ? false : true,
        onDrop: (acceptedFiles, rejectedFiles) => {
            setRejectedFiles(rejectedFiles);
            let files = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            setFiles(files);
            handleFiles(files);
        },

        ...otherProperties()
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className="container" style={{ "padding": "0" }}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>{handbookMsg ? t('drag_and_drop_handbook_message') : t('drag_and_drop_message')}</p>
                {props.maxSize ? (<div> Note: File max size is {props.maxSize / (1024 * 1024)} MB</div>) : <></>}
            </div>
            {/* <div style={{'height': "90px", "marginTop": "10px"}}> */}
            <div style={{ 'display': "flex", "flexDirection": "column", "height": "90px", "overflowY": "scroll", "marginTop": "10px" }} className="drop-files-name">
                {rejectedFiles && rejectedFiles.length > 0 ? (
                    <div>
                        <span style={{ "color": "red", "fontWeight": 700, "marginBottom": "5px" }}>{t('rejected_files')}:</span>
                        {rejectedFiles.map((file, index) => {
                            return (
                                <div key={'rfile' + index} style={{ "color": "#0b3e79" }}>{file.file.name}: {file.errors[0].message}</div>
                            )
                        })}
                    </div>
                ) : <></>}
                {files && files.length > 0 ? (
                    <div>
                        <span style={{ "fontWeight": 700, "marginBottom": "5px", "color": "#0b3e79" }}>{t('select_files')}:</span>
                        <div>
                            {files.map((file, index) => {
                                return <div key={'afile' + index} style={{ "color": "#0b3e79" }}>{file.name}</div>
                            }
                            )}
                        </div>
                    </div>
                ) : <></>}
            </div>
        </div>
    );
}

export default DropZone;
