import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import {
    selectState,
    selectTalentState,
    selectInitialTalentState,
    selectTalentSkillsState,
    selectTalentCredentialsState,
    selectTalentCertificationsState,
    selectTalentEducationState,
    selectTalentEquipmentState,
} from "./talent_details_reducer";

const select = (state: IRootState) => state.talent.details.dashboard.talentDetails;
export const selectTalentDetailsState = createSelector(select, selectState);

export const selectTalentDetailsByIdState = (id: string | undefined | null) => createSelector(
    selectTalentDetailsState,
    (state) => {
        if (id && state[id]) return selectTalentState(state, id);
        return selectInitialTalentState();
    }
);
export const selectTalentSkillsStateById = (id: string | undefined | null) => createSelector(
    selectTalentDetailsByIdState(id),
    selectTalentSkillsState,
);
export const selectTalentCredentialsStateById = (id: string | undefined | null) => createSelector(
    selectTalentDetailsByIdState(id),
    selectTalentCredentialsState,
);
export const selectTalentCertificationsStateById = (id: string | undefined | null) => createSelector(
    selectTalentDetailsByIdState(id),
    selectTalentCertificationsState,
);
export const selectTalentEducationStateById = (id: string | undefined | null) => createSelector(
    selectTalentDetailsByIdState(id),
    selectTalentEducationState,
);
export const selectTalentEquipmentStateById = (id: string | undefined | null) => createSelector(
    selectTalentDetailsByIdState(id),
    selectTalentEquipmentState,
);
// export const selectTalentDetailsDepartmentState = createSelector(select, selectDepartmentState);
