import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportCenterAPI } from "../../../apis/report_center_api";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";


export const getReportStartEndDate = createAsyncThunk<
    any,
    {start_and_end?: boolean},
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/payroll_tax/recently_closed_week/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getReportStartEndDate(payload.start_and_end);
            return res.data as any;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

