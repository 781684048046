import { combineReducers } from "@reduxjs/toolkit";
import { skillSetListSlice } from "./skill_list/skill_list_reducer";
import { requiredEquipmentListSlice } from "./required_equipment_list/required_equipment_list_reducer";
import { educationListSlice } from "./education_list/education_list_reducer";
import { credentialsListSlice } from "./credentials_list/credentials_list_reducer";
import { certificationsListSlice } from "./certifications_list/certifications_list_reducer";
import { roleUsersListSlice } from "./role_users_list/role_users_list_reducer";
import { competitorsListSlice } from "./competitors_list/competitors_list_reducer";
import { businessSectorSlice } from "./business_sectors/business_sectors_reducer";
import { contactsDropdownListSlice } from "./contacts_list/contact_list_reducer";
import { talentInterviewStatusSlice } from "./talent_interview_status/talent_interview_status_reducer";
import { contactsListAllSlice } from "./contacts_list_all/contacts_list_all_reducer";
import { companiesDropdownListSlice } from "./company_list/company_list_reducer";
import { departmentsDropdownListSlice } from "./departments_list/departments_list_reducer";
import { jobsDropdownListSlice } from "./jobs_list/jobs_list_reducer";
import { workCompCodesListSlice } from "./work_comp_list/work_comp_list_reducer";
import { talentsDropdownListSlice } from "./talent_list/talent_list_reducer";
import { assignmentDropdownListSlice } from "./assignment_list/assignment_list_reducer";
import { apolloIndustriesDDListSlice } from "../apollo_search_import/apollo_industries_list/apollo_industries_list_reducer";
import { weekEndingDatesDDSlice } from "./week_ending_dates/week_ending_dates_reducer";


export const selectorsReducers = combineReducers({
    [skillSetListSlice.name]: skillSetListSlice.reducer,
    [credentialsListSlice.name]: credentialsListSlice.reducer,
    [certificationsListSlice.name]: certificationsListSlice.reducer,
    [educationListSlice.name]: educationListSlice.reducer,
    [requiredEquipmentListSlice.name]: requiredEquipmentListSlice.reducer,
    [roleUsersListSlice.name]: roleUsersListSlice.reducer,
    [competitorsListSlice.name]: competitorsListSlice.reducer,
    [businessSectorSlice.name]: businessSectorSlice.reducer,
    [contactsDropdownListSlice.name]: contactsDropdownListSlice.reducer,
    [talentInterviewStatusSlice.name]: talentInterviewStatusSlice.reducer,
    [contactsListAllSlice.name]: contactsListAllSlice.reducer,
    [companiesDropdownListSlice.name]: companiesDropdownListSlice.reducer,
    [departmentsDropdownListSlice.name]: departmentsDropdownListSlice.reducer,
    [jobsDropdownListSlice.name]: jobsDropdownListSlice.reducer,
    [workCompCodesListSlice.name]: workCompCodesListSlice.reducer,
    [talentsDropdownListSlice.name]: talentsDropdownListSlice.reducer,
    [assignmentDropdownListSlice.name]: assignmentDropdownListSlice.reducer,
    [apolloIndustriesDDListSlice.name]: apolloIndustriesDDListSlice.reducer,
    [weekEndingDatesDDSlice.name]: weekEndingDatesDDSlice.reducer,

});

/// [Companies] Company details
export * from './skill_list/skill_list_selector';
export * from './skill_list/skill_list_thunk';

/// [Companies] Credentials details
export * from './credentials_list/credentials_list_selector';
export * from './credentials_list/credentials_list_thunk';

export * from './company_list/company_list_selector';
export * from './company_list/company_list_thunk';

export * from './departments_list/departments_list_selector';
export * from './departments_list/departments_list_thunk';

/// [Companies] Company details
export * from './certifications_list/certifications_list_selector';
export * from './certifications_list/certifications_list_thunk';

/// [Companies] Company details
export * from './education_list/education_list_selector';
export * from './education_list/education_list_thunk';

/// [Companies] Company details
export * from './required_equipment_list/required_equipment_list_selector';
export * from './required_equipment_list/required_equipment_list_thunk';

/// Role management
export * from './role_users_list/role_users_list_thunk';
export * from './role_users_list/role_users_list_selector';

/// Competitors
export * from './competitors_list/competitors_list_thunk';
export * from './competitors_list/competitors_list_selector';

export * from "./business_sectors/business_sectors_selector";
export * from "./business_sectors/business_sectors_thunk";

export * from "./contacts_list/contact_list_selector";
export * from "./contacts_list/contact_list_thunk";

export * from "./talent_interview_status/talent_interview_status_selector";
export * from "./talent_interview_status/talent_interview_status_thunk";

export * from "./contacts_list_all/contacts_list_all_selector";
export * from "./contacts_list_all/contacts_list_all_thunk";

export * from "./jobs_list/jobs_list_selector";
export * from "./jobs_list/jobs_list_thunk";
/// Talent

export * from "./talent_list/talent_list_selector";
export * from "./talent_list/talent_list_thunk";

/// Assignmet
export * from "./assignment_list/assignment_list_selector";
export * from "./assignment_list/assignment_list_thunk";

export * from "../apollo_search_import/apollo_industries_list/apollo_industries_list_selector";
export * from "../apollo_search_import/apollo_industries_list/apollo_industries_list_thunk";

export * from "./week_ending_dates/week_ending_dates_selector";
export * from './week_ending_dates/week_ending_dates_thunk';