import { ICompetitor, SalesDocument, ServiceCallInfo } from "../interfaces";
import { MarketingCampaign, SendByEmail } from "../interfaces/marketing_campaign";
import { SalesCall, SalesCallsDataParam } from "../interfaces/sales_call";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const SalesMarketingAPI = {
  getSalesCalls() {
    return interceptor.get(UrlBuilder.salesCalls);
  },
  getSalesCallById(call_id: string) {
    return interceptor.get(
      `${UrlBuilder.salesCallById}?call_id=${call_id}`
      );
  },
  getContactSalesCalls(contact_id: string, upcoming_calls: boolean) {
    return interceptor.get(
      `${UrlBuilder.salesCalls}?contact_id=${contact_id}&my_upcoming_calls=${upcoming_calls}`
    );
  },
  getSalesCallGetByUsers(salesCallFilteredUsers: SalesCallsDataParam) {
    return interceptor.post(
      `${UrlBuilder.salesCallsGetByUsers}?my_upcoming_calls=${salesCallFilteredUsers.my_upcoming_calls}&start_date=${salesCallFilteredUsers.start_date}&end_date=${salesCallFilteredUsers.end_date}`
      );
  },
  createSalesCall(salesCall: SalesCall) {
    return interceptor.post(UrlBuilder.salesCalls, salesCall);
  },
  updateSalesCall(salesCall: SalesCall) {
    return interceptor.patch(UrlBuilder.salesCalls, salesCall);
  },
  createCompetitor(competitor: ICompetitor) {
    return interceptor.post(UrlBuilder.competitors, competitor);
  },
  updateCompetitor(competitor: ICompetitor) {
    return interceptor.patch(
      `${UrlBuilder.competitors}?competitor_id=${competitor.id}`,
      competitor
    );
  },
  deleteCompetitor(competitor_id: string) {
    return interceptor.delete(
      `${UrlBuilder.competitors}?competitor_id=${competitor_id}`
    );
  },
  getCompetitor(id: string) {
    return interceptor.get(
      `${UrlBuilder.getCompetitorById}?competitor_id=${id}`
    );
  },
  getSalesDocumentsAPI(data) {
    let queryString = "";
    if (data) queryString = `?branch_id=${data}`;
    return interceptor.get(
      `${UrlBuilder.salesAndMarketingDocuments}${queryString}`
    );
  },

  createSalesDocumentVideo(data) {
    let queryString = "";
    return interceptor.post(UrlBuilder.uploadSalesAndMarketingVideo, data);
  },

  createSalesDocument(data) {
    return interceptor.post(`${UrlBuilder.salesAndMarketingDocuments}`, data);
  },

  getServiceCalls() {
    return interceptor.get(UrlBuilder.dashboardServiceCall);
  },

  getServiceCallsDirectory(completed: boolean) {
    return interceptor.get(
      `${UrlBuilder.dashboardServiceCall}?call_completed=${completed}&directory=true`
    );
  },

  updateServiceCalls(call: ServiceCallInfo) {
    return interceptor.patch(UrlBuilder.dashboardServiceCall, call);
  },

  getMarketingCampaigns() {
    return interceptor.get(UrlBuilder.marketingCampaign);
  },

  createMarketingCampaign(data: MarketingCampaign) {
    return interceptor.post(UrlBuilder.marketingCampaign, data);
  },
  updateMarketingCampaign(data: MarketingCampaign) {
    return interceptor.patch(UrlBuilder.marketingCampaign, data);
  },


  updateUploadDocument(data) {
    return interceptor.patch(UrlBuilder.salesAndMarketingDocuments, data);
  },
  deleteUploadDocument(file_id: string) {
    return interceptor.delete(
      `${UrlBuilder.salesAndMarketingDocuments}?file_id=${file_id}`
    );
  },
  CreateDocumentDownloadLinkByEmail(data: SendByEmail) {
    return interceptor.post(`${UrlBuilder.sendByEmailSalesAndMarketingDocument}?file_id=${data.file_id}&email_id=${data.email_id}`);
  },

  CreateVideoDownloadLinkByEmail(data: {file_id: string, email_id: string}) {
    return interceptor.post(`${UrlBuilder.sendByEmailSalesAndMarketingVideo}?file_id=${data.file_id}&email_id=${data.email_id}`);
  },
};
