import React from "react";
import './authentication_page_holder.scss';
interface Props {
    history?: any,
    location?: any,
    children: any,
    onChange?: (id: string, value: any) => void,
};
const AuthenticationPageHolder = (props: Props) => {

    return (
        <div className="aut-page-container">
            <div className="aut-page-container">
                <div className="aut-lotty-container">
                    <div className="aut-lotty-wrapper">
                        {/* <div className="aut-lotty-extra-space"></div> */}
                        <div className="aut-users-lotty">
                            <div className="background-img aut-users-svg"></div>
                        </div>
                    </div>

                    <div className="aut-wrapper">
                        <div className="aut-shape-wrapper">
                            {/* <div className="aut-shape-pyramid"></div> */}
                            <div className="aut-holder">
                                {props.children}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default AuthenticationPageHolder;