
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./pay_type_reducer";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.PayTypeList;
export const selectPayTypeListState = createSelector(select, selectState);
export const selectPayTypeList = createSelector(
    select,
    selectAll
);
export const selectPayTypeListEntities = createSelector(
    select,
    selectEntities
);