import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { AppError, IBillingTimeCardsData } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getBillingTimecardsThunk = createAsyncThunk<
    IBillingTimeCardsData[],
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing/billing_timecards/get", async (payload, thunkAPI) => {
    try {
        const res = await BackOfficeAPI.getBillingTimecards(payload);
        return res.data.time_cards as IBillingTimeCardsData[];
    } catch (error) {
        return catchRequestError(error, thunkAPI);
    }
});
