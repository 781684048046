import { useTranslation } from "react-i18next";
import SocialMediaProfile from "../../components/social_media_profile/social_media_profile";
import { ContactInfo } from "../../entities/ContactInfo";
import { CallIcon, CompaniesIcon, EmailIcon, LocationIcon, ReloadIcon } from "../../icons";
import { addressParser, CustomButton, getCompanyStatusText, getUserNameFromProfileDropdown } from "../../utils";
import "./contact_details_section.scss";
import { LoadingType, ProfilePictureType, THEME } from "../../enums";
import UploadProfile from "../../components/image_crop/image_crop_popup";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  getPreSignedURLForDownload,
  selectSingleProfilePictureStateById,
} from "../../redux/store";
import { Portal } from "react-portal";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../routes";
import { IContact } from "../../interfaces";
import { FieldNav } from "../../components/field_nav/field_nav";
import UserAvatar from "../../components/user_avatar/user_avatar";
import { ProfileState } from "../../redux/profile/profile_reducer";

interface Props {
  contactInfo: IContact | null;
  profile: ProfileState;
  // onShowRefreshPopup: Dispatch<SetStateAction<boolean>>;
  // availCredits: number | undefined,
  // onAddCredit: Dispatch<SetStateAction<boolean>>;
}

const ContactHeader = (props) => {
  const { name, uri, rating, level } = props;
  const dispatch = useAppDispatch();
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const profilePictureState = useAppSelector((state) =>
    selectSingleProfilePictureStateById(
      ProfilePictureType.contact + "-" + props.objectId
    )(state)
  );

  useEffect(() => {
    setSentRequest(true);
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.contact,
      })
    );
  }, []);

  function updateProfile() {
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.contact,
      })
    );
    setSentRequest(true);
  }

  useEffect(() => {
    if (
      sentRequest &&
      profilePictureState.download.loading === LoadingType.succeeded
    ) {
      setSentRequest(false);
      setImageURL(profilePictureState.download.response ?? "");
    }
  }, [profilePictureState.download.loading]);

  return (
    <div className="cont-ds-user-info">
      <UserAvatar
        onClick={() => setShowImageUpload(true)}
        url={imageURL}
        rating={{ value: rating, level: level }}
      />
      <div className="cont-ds-user-name">
        <span>{name}</span>
      </div>
      {showImageUpload && (
        <Portal>
          <UploadProfile
            onClose={() => setShowImageUpload(false)}
            onDismiss={() => setShowImageUpload(false)}
            visible={showImageUpload}
            onApprove={updateProfile}
            objectId={props.objectId}
            objectType={ProfilePictureType.contact}
          />
        </Portal>
      )}
    </div>
  );
};

const ContactDetailsSection: React.FunctionComponent<Props> = (props) => {
  const { contactInfo, profile,
    //  onShowRefreshPopup, availCredits, onAddCredit
     } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const scheduleSalesCall = () => {
    history.push({
      pathname: AppRoutes.salesAndMarketingSalesCallActivity,
      state: {
        companyId: contactInfo?.company_id,
        departmentId: contactInfo?.department_ids
          ? contactInfo?.department_ids[0]
          : null,
        contactId: contactInfo?.id,
        planCall: true,
        planned_by_name: getUserNameFromProfileDropdown(
          profile?.profile!
        ),
        source:"contacts-details"
      },
    });
  };

  function handleCompanySelect(company_id, company_name) {
    history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${company_id}`,
      state: {
        id: company_id,
        name: company_name,
      },
    });
    return;
  }

  function handleDepartmentSelect(value: IContact, index) {
    history.push({
      pathname: value.department_ids[index] === undefined ?
        `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/` :
        `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_ids[index]}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_ids[index],
        departmentName: value.department_names[index],
      },
    });
    return;
  }

  return (
    <div className="cont-ds-container">
      <ContactHeader
        name={`${contactInfo?.first_name ?? ""} ${contactInfo?.last_name ?? ""
          } `}
        objectId={contactInfo?.id}
        rating={contactInfo?.engagement_rating}
        level={contactInfo?.engagement_level}
      />
      <div className="cont-ds-branch-selector">
        <div className="cont-ds-status">
          <span>
            {`${t("status")}:`}
            <span className="status-txt">
              {contactInfo?.status ?? ""}
            </span>
          </span>
        </div>
        {/* {contactInfo && contactInfo.imported_from_apollo &&
          (<div>
            <CustomButton
              leftIcon={(<div className="err-h-add-icon">
                <ReloadIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
              </div>)}
              loading={false}
              textStyle={{ textTransform: 'capitalize' }}
              name={t('refresh_contact')}
              enable={(availCredits! > 2)}
              backgroundColor={THEME.buttonColor15}
              onClick={() => onShowRefreshPopup(true)}
              customClassName={'refresh_contact'}
            />
            {availCredits! <= 2 && (
              <div className='no-creds-div'>
                <div className='no-creds'>{t('no_credits_available')}</div>
                <div>
                  <div onClick={() => onAddCredit(true)} className='add-creds-div'>
                    {t("add_credits")}
                  </div>

                </div>
              </div>
            )}
          </div>)} */}
      </div>
      <div className="cont-ds-info-container">
        <div className="cont-ds-info-icon">
          <CompaniesIcon width={"100%"} height={"100%"} />
        </div>
        <div className="cont-ds-info-content">
          <div className="cont-ds-info-holder">
            <div className="cont-ds-into-title">
              <span>{t("company_name").toUpperCase()}</span>
            </div>
            {contactInfo?.company_id !== null && contactInfo?.company_id !== "" ?
              (<FieldNav
                isButton={true}
                onClick={() =>
                  handleCompanySelect(
                    contactInfo?.company_id,
                    contactInfo?.company_name
                  )
                }
              >
                <span>{contactInfo?.company_name}</span>
              </FieldNav>)
              :
              <span className="na-span">NA</span>
            }

            <div className="cont-ds-into-title">
              <span>{t("department_name").toUpperCase()}</span>
            </div>
            {contactInfo?.company_id !== null && contactInfo?.department_names &&
              contactInfo?.department_names.length !== 0 ? (
              <>
                {contactInfo?.department_names.map((d, index, arr) => (
                  <FieldNav
                    isButton={true}
                    onClick={() => handleDepartmentSelect(contactInfo, index)}
                  >
                    <span>
                      {d} {index !== arr.length - 1 ? ", " : ""}
                    </span>
                  </FieldNav>
                ))}
              </>
            ) :
              (
                <>{contactInfo?.company_id !== null ? (<span className="cont-ds-info-value">{t('corporate')}</span>) : <span className="na-span">NA</span>}</>
              )

            }

            <div className="cont-ds-into-title">
              <span>{t("job_title").toUpperCase()}</span>
            </div>
            <div className="cont-ds-info-value">{(contactInfo?.job_title !== null && contactInfo?.job_title !== "") ? contactInfo?.job_title : <span className="na-span">NA</span>}</div>
          </div>
        </div>
      </div>
      <div className="cont-ds-info-container">
        <div className="cont-ds-info-icon">
          <LocationIcon width={"100%"} height={"100%"} />
        </div>
        <div className="cont-ds-info-content">
          <div className="cont-ds-info-holder">
            <div className="cont-ds-into-title">
              {/* <span>{t("address").toUpperCase()}</span> */}
            </div>
            <div className="cont-ds-info-value">
              {addressParser({
                street_address: contactInfo
                  ? contactInfo["street_address"]
                  : null,
                city: contactInfo ? contactInfo["city"] : null,
                state: contactInfo ? contactInfo["state"] : null,
                zip_code: contactInfo ? contactInfo["zip_code"] : null,
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="cont-ds-info-container" style={{ border: "none", paddingTop: "0" }}>
        <div className="cont-ds-info-icon">
          <CallIcon
            width={"100%"}
            height={"100%"}
            style={{ color: THEME.primaryColor }}
          />
        </div>
        <div className="cont-ds-info-content">
          <div className="cont-ds-info-holder">
            <div className="cont-ds-into-title">
              {/* <span>{t("contact").toUpperCase()}</span> */}
            </div>
            <div className="cont-ds-info-value">
              {contactInfo?.mobile_no && (
                <div>
                  <span style={{ fontWeight: "normal" }}>{t("mobile")}</span>
                  <span style={{ marginLeft: "5px" }}>
                    {contactInfo?.mobile_no}
                  </span>
                </div>
              )}
              {contactInfo?.office_no && (
                <div>
                  <span style={{ fontWeight: "normal" }}>{t("office_no")}</span>
                  <span style={{ marginLeft: "5px" }}>
                    {contactInfo?.office_no}
                  </span>
                </div>
              )}
              {((contactInfo?.mobile_no == null || contactInfo?.mobile_no == "") && (contactInfo?.office_no == null || contactInfo?.office_no == "")) && (<span className="na-span">NA</span>)}
            </div>
          </div>
        </div>
      </div>

      <div className="cont-ds-info-container" style={{ border: "none", paddingTop: "0" }}>
        <div className="cont-ds-info-icon">
          <EmailIcon
            width={"100%"}
            height={"100%"}
            style={{ color: THEME.primaryColor }}
          />
        </div>
        <div className="cont-ds-info-content">
          <div className="cont-ds-info-holder">
            <div className="cont-ds-info-value">
              {(contactInfo?.email !== null && contactInfo?.email !== "") ? (
                <div>
                  {/* <span style={{ fontWeight: "normal" }}>{t("email")}</span> */}
                  <a href={`mailto:${contactInfo?.email}`}>
                    <span style={{ marginLeft: "5px", display: "inline-block" }}>
                      {contactInfo?.email}
                    </span>
                  </a>
                </div>
              ) : <span className="na-span">NA</span>
              }
            </div>
          </div>
        </div>
      </div>

      {contactInfo?.social_media_links != null && (
        <SocialMediaProfile
          title={t("social_media").toUpperCase()}
          profile={contactInfo.social_media_links}
        />
      )}
      <CustomButton
        loading={false}
        textStyle={{ textTransform: "capitalize" }}
        name={t("schedule_a_sales_call")}
        enable
        backgroundColor={"#1198AB"}
        onClick={scheduleSalesCall}
        leftIcon={
          <CallIcon width={"17px"} height={"17px"} style={{ color: "white" }} />
        }
      />
    </div>
  );
};

export default ContactDetailsSection;
