import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getAdminCenterAgencyManagementAgencyDetails, selectAgenciesDetailsState, selectAgencyStatusState, updateAgencyToggleStatus } from "../../../../redux/admin_center";
import TabOptionsHeader from "../../../../components/tab_options_header/tab_options_header";
import { LoadingType } from "../../../../enums";
import { TableErrorHandler } from "../../../../components/table_empty/table_empty";
import CurveTabNavigation from "../../../../components/curve_tab_navigation/curve_tab_navigation";
import { AdminCenterIcon, EditIcon } from "../../../../icons";
import { SpinnerScreen } from "../../../../utils";
import AgencyProfileSection from "../../../../components/agency_profile_section/agency_profile_section";
import AddAgency from "../../popups/add_agency/add_agency";
import { Portal } from "react-portal";
import { clearAgencyDetailsState } from "../../../../redux/admin_center/agency_management/agencies_details/agencies_details_reducer";
import { manageAgencyActions } from "../../../../redux/admin_center/agency_management/manage_agency/manage_agency_reducer";
import { clearAgencyToggleStatusStateError } from "../../../../redux/admin_center/agency_management/agency_toggle_status/agency_toggle_status_reducer";

interface Props extends RouteComponentProps<any> { }

const tabOptions = [
  {
    title: "dashboard",
  }
];

const AgencyDashboardPage: React.FunctionComponent<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const { params } = props.match;
  const { loading, error, agency } = useAppSelector((state) => selectAgenciesDetailsState(state));
  const agencyToggleState = useAppSelector((state) => selectAgencyStatusState(state));
  const { t } = useTranslation();
  const [editAgency, setEditAgency] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedAgency, setSelectedAgency] = useState<string>('');

  useEffect(() => {
    getAgencyDetails();
    return () => {
      dispatch(clearAgencyDetailsState());
    }
  }, [])

  useEffect(() => {
    if (agencyToggleState.loading === LoadingType.succeeded) {
      getAgencyDetails();
    }
  }, [agencyToggleState.loading])

  function getAgencyDetails() {
    if (params && params.id) dispatch(getAdminCenterAgencyManagementAgencyDetails(params.id));
  }
  function handleCloseAgency() {
    setEditAgency(false)
    dispatch(manageAgencyActions.clearUpdateState());
  }
  function handleDissmissAgency() {
    setEditAgency(false)
    dispatch(manageAgencyActions.clearUpdateState());
  }

  ///Handle clear error
  function handleClearError() {
    dispatch(manageAgencyActions.clearUpdateState());
  }

  ///Handle toggle change
  const handleToggleChange = (id, value, domain) => {
    setSelectedAgency(id)
    dispatch(updateAgencyToggleStatus({domain: domain, enable: value}))
  }

  ///Handle clear toggle error
  function handleClearToggleError() {
    dispatch(clearAgencyToggleStatusStateError());
  }

  return (

    <div className="ac-um-usp-container">
      <div className="ac-um-usp-header">
        <TabOptionsHeader list={tabOptions} onClose={() => props.history.goBack()} />
      </div>
      {handleAgencyDetails()}

      {editAgency && agency && agency.id && <Portal>
        <AddAgency
          visible={editAgency}
          agency={agency}
          onClose={handleCloseAgency}
          onDissmiss={handleDissmissAgency}
          onSuccessClose={handleEditAgencySuccess}
          onClearError={handleClearError}
        />
      </Portal>}
    </div>
  );

  /// Agency details screen
  function handleAgencyDetails() {
    if (loading === LoadingType.pending) {
      return (
        <DetailsLoading />
      );
    }
    if (error != null) {
      return (
        <TableErrorHandler error={error} onRefresh={getAgencyDetails} />
      )
    }
    return (
      <div className="ac-um-usp-content">
        <div className="ac-um-usp-profile-container">
          <AgencyProfileSection
            agency={agency}
            selectedAgency={selectedAgency}
            onToggleChange={handleToggleChange}
            onToggleError={handleClearToggleError}
            agencyToggleState={agencyToggleState}
          />
        </div>
        <div className="ac-um-usp-settings-content">
          <CurveTabNavigation
            tabs={getTabList()}
            selected={tabIndex}
            onChange={(value) => setTabIndex(value)}
            actions={getEditAgencyAction()}
          />
        </div>
      </div>
    )
  }

  function getTabList() {
    return [
      {
        title: t('agency_settings').toUpperCase(),
        icon: AdminCenterIcon,
        content: "",
      },
    ];
  }

  /// Edit agency iocn actions
  function getEditAgencyAction() {
    return (
      <button className="icon-btn ac-um-usp-edit-icon" onClick={() => setEditAgency(true)}>
        <EditIcon width={"1vw"} height={"1vw"} />
      </button>
    );
  }

  /// handle edit agency 
  function handleEditAgencySuccess() {
    setEditAgency(false);
    getAgencyDetails()
    dispatch(manageAgencyActions.clearUpdateState());
  }
}

/// spinner 

const DetailsLoading = (props) => {
  return (
    <div className="ac-um-usp-loading">
      <SpinnerScreen />
    </div>
  );
}

export default AgencyDashboardPage;