
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { IRCTaxByEmplyee } from "../../../../interfaces/report_center_payroll_tax";
import * as thunk from "./tax_by_employee_thunk";
import { createSlice } from "@reduxjs/toolkit";

export interface TaxByEmployeeListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCTaxByEmplyee | null,
}

const initialState: TaxByEmployeeListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const taxByEmployeeListSlice = createSlice({
    name: 'taxByEmployeeList',
    initialState: initialState,
    reducers: {
        clearTaxByEmployeeListError(state) {
            return { ...state, error: null };
        },
        clearTaxByEmployeeListState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTaxByEmployeeList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getTaxByEmployeeList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getTaxByEmployeeList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearTaxByEmployeeListError, clearTaxByEmployeeListState } = taxByEmployeeListSlice.actions;
export const selectState = (state: TaxByEmployeeListState) => state;



