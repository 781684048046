import { createAsyncThunk } from "@reduxjs/toolkit";
import {  AccountAPI, firebaseLogin } from "../../apis";
import { AppError, LoginCredentials } from "../../interfaces";
import preferences from "../../preferences";
import { catchRequestError} from "../../utils";
import { checkAuthToken, getProfile } from "../profile/profile_thunk";


export const loginUser = createAsyncThunk<
    string,
    LoginCredentials,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@login/post',
    async (credentials, thunkAPI) => {
        try {
            const tokenInfo = await firebaseLogin(credentials);
            const res = await AccountAPI.login({ "id_token": tokenInfo.idToken });
            const token = res.data['auth_token'];
            preferences.setAuthToken(token);
            thunkAPI.dispatch(checkAuthToken());
            return token;
        }   catch (error){
            return catchRequestError(error, thunkAPI);
        }
    }
);