import { createSelector } from "@reduxjs/toolkit";
import { selectState, selectAll, selectEntities, selectById, selectCreateReportState, selectUpdateReportState, selectUploadReportState, selectDeleteReportState} from "./work_comp_audit_and_compliance_reporting_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.riskManagement.universalDirectory.workCompAuditAndComplianceReportingList;
export const selectWorkCompAuditAndComplianceReportingListState = createSelector(select, selectState);
export const selectWorkCompAuditAndComplianceReportingList = createSelector(select, selectAll);
export const selectWorkCompAuditAndComplianceReportingListEntities = createSelector(
    select,
    selectEntities
);
export const selectWorkCompAuditAndComplianceReportinByID = (id: string) => createSelector(
    select,
    (state) => selectById(state, id)
);

export const selectWCCRCreateState = createSelector(select, selectCreateReportState);
export const selectWCCRUpdateState = createSelector(select, selectUpdateReportState);
export const selectWCCRUploadDocState = createSelector(select, selectUploadReportState);
export const selectWCCRDeleteDocState = createSelector(select, selectDeleteReportState);