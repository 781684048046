import { createSlice } from "@reduxjs/toolkit";
import ApiState from "../../../../entities/ApiState";
import { LoadingType } from "../../../../enums";
import { createCompetitor, updateCompetitor } from "./manage_competitors_thunk";

export interface ManageCompetitorState {
  create: ApiState;
  update: ApiState;
}

const initialState: ManageCompetitorState = {
  create: {
    error: null,
    loading: LoadingType.idle,
  },
  update: {
    error: null,
    loading: LoadingType.idle,
  },
};

export const manageCompetitorSlice = createSlice({
  name: "manageCompetitor",
  initialState: initialState,
  reducers: {
    clearCreateState(state) {
      state.create = { ...initialState.create };
    },
    clearUpdateState(state) {
      state.update = { ...initialState.update };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCompetitor.pending, (state, _action) => ({
        ...state,
        create: { ...state.create, loading: LoadingType.pending, error: null },
      }))
      .addCase(createCompetitor.rejected, (state, action) => ({
        ...state,
        create: {
          ...state.create,
          loading: LoadingType.failed,
          error: action.payload,
        },
      }))
      .addCase(createCompetitor.fulfilled, (state, action) => ({
        ...state,
        create: {
          ...state.create,
          loading: LoadingType.succeeded,
          error: null,
          response: action.payload,
        },
      }))

      .addCase(updateCompetitor.pending, (state, action) => ({
        ...state,
        update: { ...state.update, loading: LoadingType.pending, error: null },
      }))
      .addCase(updateCompetitor.rejected, (state, action) => ({
        ...state,
        update: {
          ...state.update,
          loading: LoadingType.failed,
          error: action.payload,
        },
      }))
      .addCase(updateCompetitor.fulfilled, (state, action) => ({
        ...state,
        update: {
          ...state.update,
          loading: LoadingType.succeeded,
          error: null,
          response: action.payload,
        },
      }))

      .addDefaultCase((state) => ({ ...state }));
  },
});

export const manageCompetitorActions = manageCompetitorSlice.actions;
