import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ICreateResponse, IProfilePictureUploadUrlResponse } from "../../../../interfaces";
import * as thunk from "./manage_job_thunk";

export interface SingleFileState {
    fileURL: {
        error?: AppError | null,
        loading: LoadingType,
        response?: IProfilePictureUploadUrlResponse,
    }
    upload: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    }
}

export interface ManageJobState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
    files: {
        [key: string]: SingleFileState,
    }
}

const initialState: ManageJobState = {
    create: {
        error: null,
        loading: LoadingType.idle,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
    },
    files: {}
}


export const manageJobSlice = createSlice({
    name: 'manageJob',
    initialState: initialState,
    reducers: {
        initializeManageJobState(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                create: { ...initialState.create },
                update: { ...initialState.update },
                files: {
                    ...action.payload.reduce((a, v) => ({
                        ...a,
                        [v]: {
                            fileURL: {
                                error: null,
                                loading: LoadingType.idle,
                            },
                            upload: {
                                error: null,
                                loading: LoadingType.idle,
                            },
                        }
                    }), {})
                }

            }
        },
        clearCreateJobState(state) {
            return { ...state, create: { ...initialState.create } };
        },
        clearUpdateJobState(state) {
            return { ...state, update: { ...initialState.update } };
        },
        clearManageJobStateError(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                create: {
                    ...state.create,
                    error: null
                },
                update: {
                    ...state.update,
                    error: null
                },

                files: {
                    ...state.files,
                    ...action.payload.reduce((a, v) => ({
                        ...a,
                        [v]: {
                            ...state.files[v],
                            fileURL: {
                                error: null,
                            },
                            upload: {
                                error: null,
                            },
                        }
                    }), {})
                }
            };
        },
        clearManageJobState(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                create: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                update: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                files: {
                    ...state.files,
                    ...action.payload.reduce((a, v) => ({
                        ...a,
                        [v]: {
                            ...state.files[v],
                            fileURL: { error: null, loading: LoadingType.idle, response: undefined, },
                            upload: { error: null, loading: LoadingType.idle, response: undefined, }
                        }
                    }), {})
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.createJob.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createJob.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createJob.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateJob.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateJob.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateJob.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(
                thunk.getJobFileUploadURLThunk.pending,
                (state, action) => ({
                    ...state,
                    files: {
                        ...state.files,
                        [action.meta.arg.type]: {
                            ...state.files[action.meta.arg.type],
                            fileURL: {
                                ...state.files[action.meta.arg.type].fileURL,
                                loading: LoadingType.pending,
                                error: null,
                            }

                        }
                    }
                })
            )
            .addCase(
                thunk.getJobFileUploadURLThunk.rejected,
                (state, action) => ({
                    ...state,
                    files: {
                        ...state.files,
                        [action.meta.arg.type]: {
                            ...state.files[action.meta.arg.type],
                            fileURL: {
                                ...state.files[action.meta.arg.type].fileURL,
                                loading: LoadingType.failed,
                                error: action.payload,
                            }
                        }
                    }
                })
            )
            .addCase(
                thunk.getJobFileUploadURLThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    files: {
                        ...state.files,
                        [action.meta.arg.type]: {
                            ...state.files[action.meta.arg.type],
                            fileURL: {
                                ...state.files[action.meta.arg.type].fileURL,
                                loading: LoadingType.succeeded,
                                error: null,
                                response: action.payload
                            }
                        }
                    }
                })
            )

            .addCase(
                thunk.uploadJobFileThunk.pending,
                (state, action) => ({
                    ...state,
                    files: {
                        ...state.files,
                        [action.meta.arg.type]: {
                            ...state.files[action.meta.arg.type],
                            upload: {
                                ...state.files[action.meta.arg.type].upload,
                                loading: LoadingType.pending,
                                error: null,
                            }
                        }
                    }
                })
            )
            .addCase(
                thunk.uploadJobFileThunk.rejected,
                (state, action) => ({
                    ...state,
                    files: {
                        ...state.files,
                        [action.meta.arg.type]: {
                            ...state.files[action.meta.arg.type],
                            upload: {
                                ...state.files[action.meta.arg.type].upload,
                                loading: LoadingType.failed,
                                error: action.payload,
                            }
                        }
                    }
                })
            )
            .addCase(
                thunk.uploadJobFileThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    files: {
                        ...state.files,
                        [action.meta.arg.type]: {
                            ...state.files[action.meta.arg.type],
                            upload: {
                                ...state.files[action.meta.arg.type].upload,
                                loading: LoadingType.succeeded,
                                error: null,
                                response: action.payload
                            }
                        }
                    }
                })
            )


            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageJobActions = manageJobSlice.actions;
export const selectState = (state: ManageJobState) => state;
export const selectCreateState = (state: ManageJobState) => state.create;
export const selectUpdate = (state: ManageJobState) => state.update;
export const selectFilesState = (state: ManageJobState) => state.files;

export const selectFilesTypeState = (state: ManageJobState, type: string): SingleFileState => state.files[type];
export const selectInitialFilesTypeState = (): SingleFileState => ({
    fileURL: {
        error: null,
        loading: LoadingType.idle,
    },
    upload: {
        error: null,
        loading: LoadingType.idle,
    },
});

