import { createAsyncThunk } from "@reduxjs/toolkit";
import { IContactApolloImport, ICompaniesApolloSearchResults, ICompaniesApolloSearch, ICompanyApolloImport, IContactsApolloSearch, IApolloCompaniesResults } from "../../../interfaces/apollo_search_import";
import { AppError } from "../../../interfaces";
import { ApolloSearcImportAPI } from "../../../apis";
import { catchRequestError } from "../../../utils";
import { apolloGetCreditsThunk } from "../apollo_credits/apollo_credits_thunk";


export const appolloCompaniesSearchThunk = createAsyncThunk<
    IApolloCompaniesResults,
    IContactsApolloSearch,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/search-companies-apollo/post',
    async (data, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.appolloCompaniesSearch(data);
            thunkAPI.dispatch(apolloGetCreditsThunk());
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const apolloCompaniesImportThunk = createAsyncThunk<
    string,
    { data: ICompanyApolloImport[], force_import: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/universal-directory/import-company-apollo/post',
    async (payload, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.apolloCompaniesImport(payload.data, payload.force_import);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);