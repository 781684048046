import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { IBillingBatch, IWeekEndingDatesDropdown } from "../../../../interfaces";
import { selectProfileState, useAppDispatch, useAppSelector } from "../../../../redux/store";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { BOOpenCloseIcon } from "../../../../icons";
import ApiError from "../../../../components/api_error";
import { CustomButton, FormInput, currencyConversion } from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import './create_billing_batch_popup.scss';
import CustomStepIndicator from "../../../../components/custom_step_indicator/custom_step_indicator";
import CreateBillingStepOne from "./create_billing_step_one";
import { convertDateToTimeStamp } from "../../../../variables";
import { createBillingBatchThunk, deleteBillingBatchThunk, getBillingTimecardsThunk, selectBillingTimecardsList, selectBillingTimecardsState, selectManageBillingBatchState } from "../../../../redux/back_office/billing_invoices";
import { CreateBillingStepTwo } from "./create_billing_step_two";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { BillingManageBatchSliceActions } from "../../../../redux/back_office/billing_invoices/manage_billing_batch/manage_billing_batch_reducer";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import CNBBBillingAndInvoicesReportPage from "../../pages/billing_invoicing/cnbb_billing_and_invoices_report/cnbb_billing_and_invoices_report";

interface Props {
    onClose: () => void;
    onSuccessClose: () => void;
    weekEndingDate: string;
    onSave: (payroll_batch_id: string) => void;
    onProcess: (payroll_batch_id: string) => void;

    weekEndingDateList: IWeekEndingDatesDropdown[];
}
const CreateBillingBathcPopup = (props: Props) => {
    const { onClose, onSave, weekEndingDate, weekEndingDateList, onSuccessClose, onProcess } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = profileState?.profile?.first_name;

    const createState = useAppSelector((state) => selectManageBillingBatchState(state));

    const timeCardsState = useAppSelector((state) =>
        selectBillingTimecardsState(state)
    );
    const timeCardList = useAppSelector((state) =>
        selectBillingTimecardsList(state)
    );

    const [batch, setBatch] = useState<IBillingBatch>({
        ...new IBillingBatch(),
        action: "Process Invoice(s)",
        week_accounting_id: weekEndingDate,
        invoice_date: convertDateToTimeStamp(new Date()),
    });

    const [processAllInvoices, setProcessAllInvoices] = useState<boolean>(false)

    const [previewInvoices, setPreviewInvoices] = useState<boolean>(false)
    const [proceed, setProceed] = useState<boolean>(false)

    useEffect(() => {
        if (createState.loading === LoadingType.succeeded) {
            setCurrentStep(stepsList[2]);
            if (createState.response) {
                setBatch(createState.response);
            }
        }
    }, [createState.loading]);

    useEffect(() => {
        if (batch.week_accounting_id) {
            dispatch(getBillingTimecardsThunk(batch.week_accounting_id));
        }
    }, [batch.week_accounting_id]);

    const updateBatchProp = (key: string, value: any) => {
        const newBatch = { ...batch };
        newBatch[key] = value;
        setBatch(newBatch);
    };

    const handleTimeCardSelection = (id?: string) => {
        let timeCardSet = new Set(batch.time_card_ids);
        if (id) {
            if (timeCardSet.has(id)) {
                timeCardSet.delete(id);
            } else {
                timeCardSet.add(id);
            }
        } else {
            if (timeCardSet.size === timeCardList?.length) {
                timeCardSet = new Set();
            } else {
                timeCardSet = new Set(timeCardList?.map((x: any) => x.time_card_id));
            }
        }
        updateBatchProp("time_card_ids", Array.from(timeCardSet));
        setBatch((prevBatch) => ({
            ...prevBatch,
            time_card_ids: Array.from(timeCardSet),
        }));
    };

    const [currentStep, setCurrentStep] = useState<{
        label: string;
        value: number;
    }>({ label: t("step_option", { value: 1 }), value: 1 });

    const stepsList: Array<{ label: string; value: number }> = Array.from(
        // Array(7).keys()
        Array(previewInvoices ? (proceed ? 9 : 8) : (proceed ? 8 : 7)).keys()
    ).map((_doc, index) => ({
        label: t("step_option", { value: index + 1 }),
        value: index + 1,
    }));

    const getTitle = () => {
        switch (currentStep.value) {
            case 1:
                return "Create New Billing Batch";
            case 2:
                return "Select Billing Transaction";
            default:
                return "Process Invoices";
        }
    }
    const getSubtitle = () => {
        switch (currentStep.value) {
            case 1:
                return `Hey ${currentLoginUserName}, Let's process your Invoices.`;
            case 2:
                return `Hey ${currentLoginUserName}, select how would you like to group your invoices.\nThen, select the invoices you would like to process and click Next.`;
            case 4:
                return `Hey ${currentLoginUserName}, confirm your invoice count, starting and ending Invoice Numbers.\nOnce you have done that, turn on "Process All Invoices" and click "Next".`;
            default:
                return "";
        }
    };
    const getBackBtnText = () => {
        const { value } = currentStep;
        const isStep1 = value === 1;
        const isStep6 = value === 6;
        const isStep7 = value === 7;
        const isStep8 = value === 8;
        const isStep9 = value === 9;

        if (isStep1) {
            return t("cancel");
        }

        if (isStep6) {
            return previewInvoices ? t("back") : t("no");
        }

        if (isStep7) {
            if (previewInvoices) {
                return t("no");
            }
            return proceed ? t("cancel") : null;
        }

        if (isStep8) {
            if (previewInvoices) {
                return proceed ? t("cancel") : null;
            }
            return proceed ? null : t("no");
        }

        if (isStep9 && previewInvoices && proceed) {
            return null;
        }

        // Default case
        return t("back");

    };

    const getNextbtnText = () => {
        if (currentStep.value === 6 && !previewInvoices) {
            return t("yes");
        }

        if (currentStep.value === 7) {
            if (previewInvoices) {
                return t("yes");
            } else {
                return proceed ? t("Proceed") : t("ok");
            }
        }

        if (currentStep.value === 8) {
            if (previewInvoices) {
                return proceed ? t("Proceed") : t("ok");
            } else {
                return t("ok");
            }
        }

        if (currentStep.value === 9 && previewInvoices) {
            return t("ok");
        }

        return t("next");

    };

    const handleNextStep = () => {
        if (currentStep.value === 2) {
            dispatch(createBillingBatchThunk(batch));
            return;
        }
        if (!previewInvoices && currentStep.value === 6) {
            onSave(batch.id);
            if (createState.post.loading === LoadingType.succeeded) {
                setCurrentStep(stepsList[currentStep.value]);
                return;

            }
        }
        if (!previewInvoices && proceed && currentStep.value === 7) {
            dispatch(deleteBillingBatchThunk(batch.id))
        }
        if (previewInvoices && currentStep.value === 7) {
            onSave(batch.id);
            if (createState.post.loading === LoadingType.succeeded) {
                setCurrentStep(stepsList[currentStep.value]);
                return;

            }
        }
        if (currentStep.value === 4) {
            onProcess(batch.id);
            if (createState.biProcess.loading === LoadingType.succeeded) {
                setCurrentStep(stepsList[currentStep.value]);
                return;

            }
        }

        if (currentStep.value === 7) {
            if (!previewInvoices) {
                if (proceed) {
                    // Proceed without preview invoices
                    setCurrentStep(stepsList[currentStep.value]);
                } else {
                    // Do not proceed, close on success
                    onSuccessClose();
                }
            } else {
                // Preview invoices case
                setCurrentStep(stepsList[currentStep.value]);
            }
        } else {
            // For all other values of currentStep.value
            setCurrentStep(stepsList[currentStep.value]);
        }

        if (currentStep.value === 8) {
            if ((previewInvoices && !proceed) || (!previewInvoices && proceed)) {
                // When previewing invoices and not proceeding
                onSuccessClose();
            } else if (!previewInvoices && proceed) {
                // When not previewing invoices but proceeding
                setCurrentStep(stepsList[currentStep.value]);
            } else if (previewInvoices && proceed) {
                // When both previewing invoices and proceeding
                setCurrentStep(stepsList[currentStep.value]);
                dispatch(deleteBillingBatchThunk(batch.id));
            }
        }
        if (currentStep.value === 9) {
            if(previewInvoices && proceed){
                onSuccessClose()
            }
        }
    };

    const handleBack = () => {
        if (currentStep.value === 1) {
            onClose();
        }
        if (!previewInvoices && proceed && currentStep.value === 7 || previewInvoices && proceed && currentStep.value === 8) {
            setCurrentStep(stepsList[currentStep.value]);
            setProceed(false)
        }
        if (!previewInvoices && currentStep.value === 6 || previewInvoices && currentStep.value === 7) {
            setCurrentStep(stepsList[currentStep.value]);
            setProceed(true)
        }
        else {
            setCurrentStep(stepsList[currentStep.value - 2]);
        }
    };

    const handleToggleChange = (val) => {
        setProcessAllInvoices(val)
    }
    const handlePIToggleChange = (val) => {
        setPreviewInvoices(val)
    }

    const handleWeekEndChange = (val: string) => {
        updateBatchProp("week_accounting_id", val);
    };

    const getCurrentStepView = (): ReactNode => {
        switch (currentStep.value) {
            case 1:
                return (
                    <CreateBillingStepOne
                        batch={batch}
                        weekEndingDatesList={weekEndingDateList}
                        selectedWeekEndingDate={weekEndingDate}
                        updateBatchProp={updateBatchProp}
                        handleWeekEndChange={handleWeekEndChange}
                    />
                );
            case 2:
                return (
                    <CreateBillingStepTwo
                        billingTimecards={timeCardList ?? []}
                        handleSelection={handleTimeCardSelection}
                        selectedCards={new Set(batch.time_card_ids)}
                    />
                );
            case 3:
                return (
                    <DocSavedText customStyle={{ padding: "5em" }}>
                        <span>{`Congrats ${profileState.profile?.first_name}!`}</span>
                        <span>{`There are no errors or warnings.`}</span>
                        <span>{`Click Next to continue.`}</span>
                    </DocSavedText>
                );
            case 4:
                return (
                    <div className="pi-content">
                        <div style={{ width: "50%" }}>
                            <FormInput
                                id={"starting_invoice_number"}
                                onChange={updateBatchProp}
                                required={false}
                                disabled
                                placeholder={""}
                                type={"text"}
                                value={batch.starting_invoice_number}
                                label={t("starting_invoice_number")}
                            ></FormInput>
                            <FormInput
                                id={"ending_invoice_number"}
                                onChange={updateBatchProp}
                                required={false}
                                disabled
                                placeholder={""}
                                type={"text"}
                                value={batch.ending_invoice_number}
                                label={t("ending_invoice_number")}
                            ></FormInput>
                            <FormInput
                                id={"invoice_count"}
                                onChange={updateBatchProp}
                                required={false}
                                disabled
                                placeholder={""}
                                type={"text"}
                                value={batch.invoice_count}
                                label={t("invoice_count")}
                            ></FormInput>
                        </div>
                        <div className="pi-toggle">
                            <ToggleSwitch
                                label={t("process_all_invoices")}
                                checked={processAllInvoices}
                                onChange={(val) => handleToggleChange(val)}
                            />
                        </div>
                    </div>
                );
            case 5:
                return (
                    <>
                        <DocSavedText customStyle={{ padding: "5em" }}>
                            <span>{`Congratulations, ${profileState.profile?.first_name}!`}</span>
                            <span>{`Invoice Process is complete.`}</span>
                            <span>{`There ${batch.invoice_count > 1 ? 'are' : 'is'} ${batch.invoice_count} ${batch.invoice_count > 1 ? 'Invoices' : 'Invoice'}  with a total sum of ${currencyConversion(batch.total_billed)}`}</span>
                            <span>
                            </span>
                        </DocSavedText>
                        <div className="pi-toggle" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <ToggleSwitch
                                label={t("preview_invoices")}
                                checked={previewInvoices}
                                onChange={(val) => handlePIToggleChange(val)}
                            />
                        </div>
                    </>
                );
            case 6: {
                if (previewInvoices) {
                    return (
                        <div className="pc-directory">
                            <CNBBBillingAndInvoicesReportPage
                                batch_id={batch.id}
                                week_accounting_id={batch.week_accounting_id}
                            />
                        </div>
                    );
                }
                return (
                    <DocSavedText customStyle={{ padding: "5em" }}>
                        <span>Click Yes and this invoices batch will be posted</span>
                    </DocSavedText>
                );
            }
            case 7: {
                if (previewInvoices) {
                    return (
                        <DocSavedText customStyle={{ padding: "5em" }}>
                            <span>Did all invoices process correctly?</span>
                            <span>If so, click "Yes" to post your invoices.</span>
                        </DocSavedText>
                    );
                }
                if (proceed) {
                    return (
                        <DocSavedText customStyle={{ padding: "5em" }}>
                            <span>This billing batch will be deleted, please confirm proceed</span>
                        </DocSavedText>
                    );
                }
                else {
                    return (
                        <DocSavedText customStyle={{ padding: "5em" }}>
                            <span>Congratulations!</span>
                            <span>Invoice(s) has been posted.</span>
                        </DocSavedText>
                    );
                }
            }
            case 8: {
                if (previewInvoices && proceed) {
                    return (
                        <DocSavedText customStyle={{ padding: "5em" }}>
                            <span>This billing batch will be deleted, please confirm proceed</span>
                        </DocSavedText>
                    );
                }
                if (!previewInvoices && proceed) {
                    return (
                        <DocSavedText customStyle={{ padding: "5em" }}>
                            <span>Billing batch deleted successfully</span>
                        </DocSavedText>
                    )
                }
                else {
                    return (
                        <DocSavedText customStyle={{ padding: "5em" }}>
                            <span>Congratulations!</span>
                            <span>Invoice(s) has been posted.</span>
                        </DocSavedText>
                    );
                }
            }
            case 9: {
                if (proceed) {
                    return (
                        <DocSavedText customStyle={{ padding: "5em" }}>
                            <span>Billing batch deleted successfully</span>
                        </DocSavedText>
                    )
                }
                else {
                    return (
                        <DocSavedText customStyle={{ padding: "5em" }}>
                            <span>Congratulations!</span>
                            <span>Invoice(s) has been posted.</span>
                        </DocSavedText>
                    );
                }
            }
        }
    };
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible
            wrapperWidth={"50%"}
            maxWrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={onClose}>
                <div className="cb-batch-container">
                    <div className="cb-batch-header">
                        <FormDialogTilteHeader
                            title={getTitle()}
                            subTitle={getSubtitle()}
                            titleIcon={<BOOpenCloseIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <CustomStepIndicator
                        onClick={(val) => setCurrentStep(stepsList[val.value])}
                        selected={currentStep}
                        stepsList={stepsList}
                    ></CustomStepIndicator>
                    <div className="cb-batch-content">
                        {getCurrentStepView()}
                    </div>
                    <div className="cb-batch-actions">
                        <div className="oc-error-holder">
                            {currentStep.value === 2 && createState.error &&
                                <ApiError message={createState.error.message} onClose={() => dispatch(BillingManageBatchSliceActions.clearErrorState())} />
                            }
                            {currentStep.value === 2 && createState.loading === LoadingType.pending &&
                                <span className="alert-message">{t("please_wait_we_are_processing_your_billing")}</span>
                            }
                            {currentStep.value === 6 && createState.post.error &&
                                <ApiError message={createState.post.error.message} onClose={() => dispatch(BillingManageBatchSliceActions.clearPostErrorState())} />
                            }
                        </div>
                        <div className="actions-row">
                            <div className="btn-cancel" style={{ marginRight: "1em" }}>
                                {/* {(currentStep.value !== 7 && currentStep.value !== 8) && getBackBtnText() && ( */}
                                {getBackBtnText() && (
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={getBackBtnText()}
                                        enable={currentStep.value === 3 ? (createState.loading === LoadingType.succeeded ? false : true) : true}
                                        backgroundColor={THEME.toggleDisableColor}
                                        onClick={() => handleBack()}
                                    />
                                )}
                            </div>
                            <div className="btn-save">
                                <CustomButton
                                    loading={createState.loading === LoadingType.pending}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={getNextbtnText()}
                                    enable={currentStep.value === 2 ? ((timeCardList?.length! > 0 && batch?.time_card_ids?.length! > 0) ? true : false) : (currentStep.value === 4 && !processAllInvoices) ? false : true}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => handleNextStep()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    );
}

export default CreateBillingBathcPopup;