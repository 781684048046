import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../enums";
import { AppError, Profile } from "../../interfaces";
import * as loginThunk from "./login_thunk";

export interface LoginState {
    error?: AppError | null,
    loading: LoadingType,
    token?: String | null,
}

const initialState: LoginState = {
    error: null,
    loading: LoadingType.idle,
    token: null,
}


export const loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        clearLoginError(state){
            return { ...state, error: null };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginThunk.loginUser.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(loginThunk.loginUser.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, token: null, }))
            .addCase(loginThunk.loginUser.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, token: action.payload }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearLoginError } = loginSlice.actions;
export const selectState = (state: LoginState) => state;
export const selectLoading = (state: LoginState) => state.loading;
export const selectError = (state: LoginState) => state.error;


