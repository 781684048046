import { createAsyncThunk } from "@reduxjs/toolkit";
import { JobOrderAPI } from "../../../../apis";
import { catchRequestError, invalidResponse } from "../../../../utils";
import { ICreateGrossProfitCalculator } from "../../../../interfaces/gross_profit_calculator";
import { AppError } from "../../../../interfaces";


export const getGrossProfitCalculator = createAsyncThunk<
  ICreateGrossProfitCalculator,
  void,
  {
    rejectValue: AppError | null,
    rejectedMeta: AppError | null,
  }
>(
  '@jobs/dashboard/gross_profit_calculator/get',
  async (_, thunkAPI) => {
    try {
      const res = await JobOrderAPI.getGrossProfitCalculatorDetails();
      if (typeof res.data === 'string') return {};
      return res.data as ICreateGrossProfitCalculator;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const createGrossProfitCalculator = createAsyncThunk<
  ICreateGrossProfitCalculator,
  ICreateGrossProfitCalculator,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@jobs/dashboard/gross_profit_calculator/create",
  async (payload, thunkAPI) => {
    try {
      const res = await JobOrderAPI.createGrossProfitCalculatorDetails(payload);
      if (typeof res.data === "string")
        return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data as ICreateGrossProfitCalculator;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  });
