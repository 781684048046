import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SortableTable, { TableData } from "../../../components/sortable_table/sortable_table";
import { LoadingType, THEME } from "../../../enums";
import { DownloadIcon } from "../../../icons";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { selectUploadFileStatusState, getUploadFileStatus, downloadUploadFileStatus } from "../../../redux/upload_files";
import { clearUploadedFileStatusError } from "../../../redux/upload_files/upload_file_status/upload_file_status_reducer";
import { CustomButton, SpinnerScreen } from "../../../utils";
import SearchBar from "../../../components/search_bar";
import './directory_upload_status.scss';
import { getDateString } from "../../../variables";

interface Props {
    importType: string;
    headerTitle: string;
}
const tableHeader = [
    { title: "file_name", code: "file_path" },
    { title: "uploaded_date", code: "uploaded_date" },
    { title: "status", code: "status" },
    { title: "error_file_path", code: "error_file_path" },
    { title: "", code: "" },
];

const DirectoryUploadStatusDirectoryPage: React.FunctionComponent<Props> = (props) => {
    const { importType, headerTitle } = props
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const uploadFileStatusState = useAppSelector((state) => selectUploadFileStatusState(state));

    const [search, setSearch] = useState("");
    const [sortedField, setSortedField] = useState<string | null>('id');
    const [sortDirection, setSortDirection] = useState('asc');

    useEffect(() => {
        dispatch(getUploadFileStatus(importType))
        return () => { }
    }, [])

    useEffect(() => {
        if (uploadFileStatusState?.download?.loading === LoadingType.succeeded) {
            let aTag = document.createElement("a");
            aTag.setAttribute("href", uploadFileStatusState?.download?.response?.url!);
            aTag.setAttribute("target", "_blank");
            aTag.click();
            dispatch(clearUploadedFileStatusError());
        }
    }, [uploadFileStatusState?.download?.loading]);

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }

    const getFlexNumber = (value: number) => {
        if (value === 0) return 1.5;
        if (value === 2) return 0.5;
        if (value === 4) return 2;
        return 1;
    }

    const handleDownload = (filePath: string) => {
        dispatch(downloadUploadFileStatus(filePath));
    }
    const handleErrorDownload = (errorFilePath: string) => {
        dispatch(downloadUploadFileStatus(errorFilePath));
    }

    const getFilteredList = () => {
        let list = uploadFileStatusState?.response;

        if (uploadFileStatusState?.response && sortedField != null) {
            list = [...uploadFileStatusState?.response].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    return (
        <div className="dir-upl-status-container">
            <div className="header-container">
                <div className="header-title">
                    <span>{t(headerTitle)}</span>
                </div>
                <div className="right-side-header">
                    <div className="total-count" style={{ marginBottom: "0.5rem", }}>
                        <span>{`${t("total_count")}: `}<span className="total-count-number"><b>{getFilteredList()?.length}</b></span></span>
                    </div>
                    <SearchBar value={search} onChange={setSearch} onSearch={() => { }} />
                </div>
            </div>
            <div className="status-table">
                {uploadFileStatusState && uploadFileStatusState.loading === LoadingType.pending ?
                    (
                        <>
                            <SpinnerScreen />
                        </>
                    ) :

                    (<SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList() && getFilteredList()!.length > 0 ?
                            getFilteredList()?.map((doc, index) => {
                                return (
                                    <tr key={doc.id}>
                                        <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                            <div className="dwn-btn">
                                                <CustomButton
                                                    loading={false}
                                                    textStyle={{ textTransform: "capitalize" }}
                                                    leftIcon={<DownloadIcon />}
                                                    name={t("")}
                                                    backgroundColor={THEME.buttonColor16}
                                                    onClick={() => handleDownload(doc.file_path)}
                                                />
                                            </div>
                                            <span>{doc.file_path?.split('/').pop() ?? ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                            <span>{getDateString(doc.uploaded_date) ?? ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                            <span style={{ color: doc.status === "Failed" ? THEME.statusInActiveColor : (doc.status === "Completed" ? THEME.statusActiveColor : THEME.primaryColor) }}>{doc.status ?? ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                            {
                                                doc.status === "Failed" && doc.error_file_path !== "" && doc.error_file_path !== null && (<div>
                                                    <CustomButton
                                                        loading={false}
                                                        textStyle={{ textTransform: "capitalize" }}
                                                        leftIcon={<DownloadIcon height={"1vw"} width={"1vw"} />}
                                                        name={t("download_errors")}
                                                        backgroundColor={THEME.buttonColor16}
                                                        onClick={() => handleErrorDownload(doc.error_file_path)}
                                                    />
                                                </div>)
                                            }
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                            <span></span>
                                        </TableData>
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <TableData customStyle={{ flex: getFlexNumber(16) }}><div style={{ width: '100%', textAlign: "center", fontSize: '0.9vw' }}>{t("no_status_available")}</div></TableData>
                            </tr>
                        }
                    </SortableTable>
                    )}
            </div>
        </div>
    );
}

export default DirectoryUploadStatusDirectoryPage;