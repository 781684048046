import { createSelector} from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectAll, selectState } from "./my_profile_teams_details_reducer";

const select = (state: IRootState) => state.myProfile.myProfileTeams.myProfileTeamsDetails;
export const selectMyProfileTeamsDetailsState = createSelector(select, selectState);
export const selectMyProfileTeamsDetailsList = createSelector(
    select,
    selectAll
);
