import { useTranslation } from 'react-i18next';
import { THEME } from '../../enums';
import { ChatIcon, PlusIcon } from '../../icons';
import { ITalentClientCompanyInterview } from '../../interfaces';
import { getDateString } from '../../variables';
import './client_company_interviews_list.scss';

interface Props {
    interviews: ITalentClientCompanyInterview[],
    onClick: (value: ITalentClientCompanyInterview) => void,
    onAddNew: () => void,
    selectedInterview: ITalentClientCompanyInterview | null,
}



const ClientCompanyInterviewsList: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="clnt-cmpny-ilv-container">
            <div className="clnt-cmpny-ilv-header">
                <div className="header-txt">
                    <span>{t('interviews')}</span>
                </div>
                <div className="header-action">
                    <button className='header-action-icon' onClick={props.onAddNew}>
                        <PlusIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
                    </button>
                </div>
            </div>
            <div className="clnt-cmpny-ilv-content">
                {props.interviews.map((doc, index) => {
                    return (
                        <button key={index} type={"button"} className="interview-card" onClick={() => props.onClick(doc)}>
                            <InterviewCard
                                title={t('client_company_interview')}
                                interviewBy={`${t('interview_by')} ${doc.interview_with ?? ''}`}
                                date={getDateString(doc.interview_date!)}
                                isSelected={props.selectedInterview?.id === doc.id}
                            />
                        </button>

                    )
                })}
            </div>
        </div>
    )
}

interface InterviewCardProps {
    title: string,
    interviewBy: string,
    date: string,
    isSelected: boolean,
}

function InterviewCard(props: InterviewCardProps) {
    return (
        <div
            className="clnt-cmpny-ilv-ic-container"
            style={{
                ...props.isSelected && { border: `1px solid ${THEME.defaultHighLightColor}` }
            }}
        >
            <div className="clnt-cmpny-ilv-ic-content">
                <div className="chat-icon">
                    <ChatIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
                </div>
                <div className="chat-info">
                    <div className="chat-title-txt">
                        <span>{props.title}</span>
                    </div>
                    <div className="chat-by-txt">
                        <span>{props.interviewBy}</span>
                    </div>
                </div>
            </div>
            <div className="clnt-cmpny-ilv-ic-footer">
                <div className="clnt-cmpny-ilv-ic-date-txt">
                    <span>{props.date}</span>
                </div>
            </div>
            {props.isSelected && <div className="clnt-cmpny-ilv-ic-shape-pyramid"></div>}
        </div>
    )
}


interface InterviewFeedbackCardProps {
    talent_feedback: string,
    client_company_contact_feedback: string,
}

export function InterviewFeedbackCard(props: InterviewFeedbackCardProps) {
    const { t } = useTranslation();
    return (
        <div className="clnt-cmpny-ilv-icf-container">
            <div className="clnt-cmpny-ilv-icf-title">
                <span>{t('interview_feedback')}</span>
            </div>
            <div className="interview-content">
                <div className="feedback-holder">
                    <div className="feedback-title">
                        <span>{`${t('talent_candidate_feedback').toUpperCase()}:`}</span>
                    </div>
                    <div className="feed-back-answ">
                        <span>{props.talent_feedback}</span>
                    </div>
                </div>
                <div className="feedback-holder">
                    <div className="feedback-title">
                        <span>{`${t('client_company_contact_feedback').toUpperCase()}:`}</span>
                    </div>
                    <div className="feed-back-answ">
                        <span>{props.client_company_contact_feedback}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientCompanyInterviewsList;