import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../apis";
import { AppError, ITalentInterviewStatus, } from "../../../interfaces";
import { catchRequestError } from "../../../utils";

export const getTalentInterviewStatusList = createAsyncThunk<
    Array<ITalentInterviewStatus>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/talent-interview-status/get',
    async (_, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalentInterviewStatus();
            if (typeof res.data === 'string') return [];
            return res.data as ITalentInterviewStatus[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);