import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll } from "./billing_invoices_report_list_reducer";

const select = (state: IRootState) => state.backOffice.billingInvoice.billingInvoiceReport;
export const selectBillingInvoiceReportState = createSelector(
    select,
    selectState
);
export const selectBillingInvoiceReportList = createSelector(select, selectAll)
