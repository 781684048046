import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../../apis";
import { AppError } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";
import { RiskMgmtInsuranceCompany } from "../../../../../interfaces/risk_mgmt_insurance_company";

export const getInsuranceCompanyList = createAsyncThunk<
    Array<RiskMgmtInsuranceCompany>,
    string | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin-center/system_configuration/risk_management/insurance_company/get',
    async (thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getInsuranceCompanyData();
            if (typeof res.data === 'string') return [];
            return res.data as RiskMgmtInsuranceCompany[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createInsuranceCompany = createAsyncThunk<
    string,
    RiskMgmtInsuranceCompany,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin-center/system_configuration/risk_management/insurance_company/create',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createInsuranceCompany(data);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const updateInsuranceCompany = createAsyncThunk<
    string,
    RiskMgmtInsuranceCompany,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin-center/system_configuration/risk_management/insurance_company/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateInsuranceCompany(data)
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteInsuranceCompany = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin-center/system_configuration/risk_management/insurance_company/delete',
    async (insurance_company_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteInsuranceCompany(insurance_company_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);