import { useTranslation } from "react-i18next";
import { getDateString } from "../../../../variables";
import { currencyConversion } from "../../../../utils";
import React from "react";


const tableheaderList = [
    { title: "week_ending_date", code: "week_ending_date" },
    { title: "taxable_gross_amount", code: "taxable_gross_amount" },
    { title: "total_tax_amount", code: "total_tax_amount" },
    { title: "tax_name", code: "tax_name" },
    { title: "tax_amount", code: "tax_amount" },
];
const EmployerTaxTable = ({ record }) => {
    const { t } = useTranslation();

    return (
        <div className="et-table">
            <table>
                <thead>
                    <th>{t("week_ending_date")}</th>
                    <th>{t("taxable_gross_amount")}</th>
                    <th>{t("tax_name")}</th>
                    <th>{t("tax_amount")}</th>
                </thead>
                <tbody>
                    {[record].map((etax, etaxIndex) => (
                        <React.Fragment key={etaxIndex}>
                            <tr>
                                <td><span>{getDateString(etax.week_ending_date)}</span></td>
                                <td><span>{currencyConversion(etax.taxable_gross_amount)}</span></td>
                                <td><span>{etax.taxes[0]?.tax_name}</span></td>
                                <td><span>{currencyConversion(etax.taxes[0]?.tax_amount)}</span></td>
                            </tr>
                            {etax.taxes.slice(1).map((tax, taxIndex) => (
                                <tr key={`${etaxIndex}-${taxIndex}`}>
                                    <td></td>
                                    <td></td>
                                    <td><span>{tax.tax_name}</span></td>
                                    <td><span>{currencyConversion(tax.tax_amount)}</span></td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                    <tr className="total-row">
                        <td>{t('total_for_week',{week: getDateString(record.week_ending_date)})}</td>
                        <td></td>
                        <td></td>
                        <td>{currencyConversion(record.total_tax_amount)}</td>

                    </tr>
                </tbody>
            </table>
        </div>

    )
}

export default EmployerTaxTable;