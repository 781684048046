import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import CurveTabNavigation from "../../../../components/curve_tab_navigation/curve_tab_navigation";
import DialogWrapper from "../../../../components/dialog_wrapper/dialog_wrapper";
import StateChangeHandler from "../../../../components/state_change_handler/state_change_handler";
import { LoadingType } from "../../../../enums";
import {
  ICreateResponse,
  ICreateTalent,
  ITalent,
} from "../../../../interfaces";
import TalentForm from "../../../../main/talent/forms/talent_form/talent_form";
import { manageEmployeeActions } from "../../../../redux/employee_dashboard/employee_dashboard_reducer";
import {
  getemployeeDashboardDetailsThunk,
  getEmployeeFileUploadUrlsThunk,
  getResumeFileUrlForDownload,
  getTalentODRSThunk,
  postSnackbarMessage,
  selectEmployeeDashboardCreateDetailsState,
  selectEmployeeDashboardGetDetaisState,
  selectEmployeeDashboardUpdateDetailsState,
  selectManageEmployeeGetUploadURLState,
  selectManageEmployeeUploadFilesState,
  selectProfileState,
  selectSingleResumeFileStateById,
  selectSingleTalentODRSStateById,
  updateEmployeeDashboardDetailsThunk,
  uploadEmployeeFilesThunk,
  useAppDispatch,
  useAppSelector,
  selectOnboardingVerifyTokenState,
} from "../../../../redux/store";
import MarkEmployeeAvailablePopup from "../../../popups/mark_employee_available";
import EmployeePortalDashboardSection from "../../../../components/employee_dashboard_details_section/employee_dashboard_details_section";

import "./employee_dashboard.scss";
import { DocumentIcon, EditIcon, PayrollIcon } from "../../../../icons";
import { PayrollDetails } from "../../../components/payroll_details/payroll_details";
import { EDocAndCertifications } from "../../../components/e_doc_and_certifications/e_doc_and_certifications";
import { RouteComponentProps } from "react-router-dom";
import { getTalentStatus, selectTalentStatusList, selectTalentStatusState } from "../../../../redux/admin_center";

interface Props extends RouteComponentProps<any> { }

const EmployeeDashboardPage: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { params } = match;
  const dispatch = useAppDispatch();
  useEffect(() => {
    // Find the script element by its widget_id attribute
    const scriptElement = document.querySelector(
      '#freshworks-container'
    );
    // Remove the script element if found
    if (scriptElement) {
      scriptElement.remove();
    }
  }, []);
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const profileState = useAppSelector((state) => selectProfileState(state));
  const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
    }`;

  const employeeDashboardState = useAppSelector((state) =>
    selectEmployeeDashboardGetDetaisState(state)
  );
  const [employeeDashboard, setEmployeeDashboard] = useState<ITalent | null>(
    null
  );
  const talentId = employeeDashboard?.id;

  const uploadURLState = useAppSelector((state) =>
    selectManageEmployeeGetUploadURLState(state)
  );
  const uploadFileState = useAppSelector((state) =>
    selectManageEmployeeUploadFilesState(state)
  );
  const updateState = useAppSelector((state) =>
    selectEmployeeDashboardUpdateDetailsState(state)
  );
  const createState = useAppSelector((state) =>
    selectEmployeeDashboardCreateDetailsState(state)
  );
  const {
    loading: refetchLoading,
    error: verifyTokenError,
    response: onboardingState,
  } = useAppSelector((state) => selectOnboardingVerifyTokenState(state));

  // const resumeDownloadState = useAppSelector((state) => selectSingleResumeFileStateById(talentId)(state));

  ///Talent Status
  // const talentStatusState = useAppSelector((state) => selectTalentStatusState(state));
  // const talentStatusList = useAppSelector((state) => selectTalentStatusList(state));

  const [editEmployee, setEditEmployee] = useState<{
    visible: boolean;
    data: ITalent | null;
  } | null>(null);
  const [showMarkEmployeeAvailable, setShowMarkEmployeeAvailable] =
    useState(false);

  const [contactLogo, setContactLogo] = useState<File | null>(null);
  const [contactResume, setContactResume] = useState<File | null>(null);

  const actionRef = useRef<ActionDialogHolderType>(null);

  useEffect(() => {
    dispatch(getemployeeDashboardDetailsThunk());
    dispatch(manageEmployeeActions.initializeManageEmployeeState());
    dispatch(getTalentStatus());
  }, []);
  useEffect(() => {
    if (
      employeeDashboardState.loading === LoadingType.succeeded &&
      employeeDashboardState.response &&
      employeeDashboardState.response != null
    ) {
      setEmployeeDashboard(employeeDashboardState.response);
    }
    return () => { };
  }, [employeeDashboardState.loading]);
  useEffect(() => {
    if (updateState.loading == LoadingType.succeeded) {
      if (updateState.response) handleNextStep(updateState.response);
    }
    return () => { };
  }, [updateState.loading]);

  /// [handle get file upload urls]
  useEffect(() => {
    if (
      uploadURLState.loading === LoadingType.succeeded &&
      uploadURLState.response
    ) {
      dispatch(
        uploadEmployeeFilesThunk({
          data: uploadURLState.response,
          photo: contactLogo,
          resume: contactResume,
        })
      );
    }
    return () => { };
  }, [uploadURLState.loading]);

  /// [handle upload files]
  useEffect(() => {
    if (uploadFileState.loading === LoadingType.succeeded) {
      if (employeeDashboard && talentId)
        closePopup(handleUpdateEmployeeSuccess);
    }
    return () => { };
  }, [uploadFileState.loading]);

  function handleNextStep(response: ICreateResponse) {
    if (contactLogo != null || contactResume != null) {
      dispatch(
        getEmployeeFileUploadUrlsThunk({
          object_id: response.object_id,
          profile_pic_file_name: contactLogo ? contactLogo?.name : undefined,
          resume_file_name: contactResume ? contactResume?.name : undefined,
        })
      );
    } else if (employeeDashboard && talentId) {
      dispatch(postSnackbarMessage(response.status ?? null));
      closePopup(handleUpdateEmployeeSuccess);
    } else {
    }
  }
  function handleUpdateEmployeeSuccess() {
    setEditEmployee(null);
    if (employeeDashboard && talentId != null) {
      dispatch(getemployeeDashboardDetailsThunk());
    }
    dispatch(manageEmployeeActions.clearManageEmployeeState());
  }
  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }
  function hanleOnResume(path: string) {
    dispatch(getResumeFileUrlForDownload({ id: talentId, file_path: path }));
  }
  function isLoading() {
    return (
      createState.loading ||
      updateState.loading ||
      uploadURLState.loading ||
      uploadFileState.loading
    );
  }

  function isError() {
    return (
      createState.error ||
      updateState.error ||
      uploadURLState.error ||
      uploadFileState.error
    );
  }

  ///Handle clear error
  function handleClearError() {
    dispatch(manageEmployeeActions.clearEmployeeDashboardStateError());
  }

  /// handle submit
  function handleSubmit(doc: { forceAdd: boolean; value: ICreateTalent }) {
    if (employeeDashboard && employeeDashboard.id) {
      dispatch(
        updateEmployeeDashboardDetailsThunk({
          ...employeeDashboard,
          ...doc.value,
        })
      );
    }
  }
  const talentName = `${employeeDashboard?.first_name ?? ""} ${employeeDashboard?.middle_name ?? ""
    } ${employeeDashboard?.last_name ?? ""}`;

  return (
    <StateChangeHandler
      loading={employeeDashboardState.loading}
      error={employeeDashboardState.error}
      onRefresh={() => { }}>
      <div className="tdp-container">
        <div className="tdp-left-panel">
          <EmployeePortalDashboardSection
            talent={employeeDashboardState.response}
            onActionClick={handleActionPress}
            onBranchChange={() => { }}
            onSSNClick={() => { }}
            onResumeClick={hanleOnResume}
            isTalent={profileState.profile?.is_talent}
            talentId={talentId}
          />
        </div>
        <div className="tdp-right-panel">
          <CurveTabNavigation
            tabs={getTabList()}
            selected={tabIndex}
            onChange={(value) => setTabIndex(value)}
            actions={getEditAction()}
            customStyle={{ height: "fit-content" }}
          />
        </div>
      </div>
      {editEmployee && (
        <Portal>
          <ActionDialogHolder
            ref={actionRef}
            visible={editEmployee.visible}
            wrapperWidth={"50%"}
            onClose={handleOnClose}
            onDissmiss={() => { }}>
            <DialogWrapper onClose={() => closePopup(handleOnClose)}>
              <TalentForm
                title={t("edit_my_details")}
                successTitle={t("edit_my_details")}
                currentLoginUserName={currentLoginUserName}
                talent={employeeDashboardState.response}
                accountManagers={[]}
                recruiters={[]}
                businessSectors={[]}
                competitors={[]}
                loading={isLoading()}
                error={isError()}
                onClearError={handleClearError}
                onClose={() => closePopup(handleOnClose)}
                onSubmit={handleSubmit}
                skillSetList={[]}
                credentialsList={[]}
                certificationsList={[]}
                educationList={[]}
                requiredEquipmentList={[]}
                /// Photo
                selectedPhoto={contactLogo}
                onPhotoChange={setContactLogo}
                onRemovePhoto={() => setContactLogo(null)}
                /// Resume
                selectedResume={contactResume}
                onResumeChange={setContactResume}
                onRemoveResume={() => setContactResume(null)}
                onGotoAgencyDashboard={() => { }}
                onCreateNew={() => { }}
                onGotoCreated={() => { }}
                isTalent={profileState.profile?.is_talent}
              // talentStatus={talentStatusList}
              />
            </DialogWrapper>
          </ActionDialogHolder>
        </Portal>
      )}
      {showMarkEmployeeAvailable && (
        <Portal>
          <MarkEmployeeAvailablePopup
            visible={showMarkEmployeeAvailable}
            title={t("hi_who_would_you_like_to_mark_available")}
            onClose={() => setShowMarkEmployeeAvailable(false)}
            onDissmiss={() => setShowMarkEmployeeAvailable(false)}
            onSuccessClose={() => setShowMarkEmployeeAvailable(false)}
            talentId={talentId}
            profile={currentLoginUserName}
            talentName={talentName}
          />
        </Portal>
      )}
    </StateChangeHandler>
  );

  function handleActionPress(value: string) {
    if (value === "mark_myself_available") {
      setShowMarkEmployeeAvailable(true);
    }
  }
  function handleEdit() {
    setEditEmployee({ visible: true, data: employeeDashboard });
  }
  function handleOnClose() {
    setEditEmployee(null);
  }
  /// Edit icon actions
  function getEditAction() {
    return (
      <button className="icon-btn tdp-edit-icon" onClick={handleEdit}>
        <EditIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
      </button>
    );
  }
  function getTabList() {

    return [
      {
        title: t("payroll details").toUpperCase(),
        icon: PayrollIcon,
        content: <PayrollDetails
          history={history}
        />
      },

      {
        title: t("e-docs & certifications").toUpperCase(),
        icon: DocumentIcon,
        content: (
          <EDocAndCertifications
            talentId={talentId}
            token={employeeDashboard?.onboarding_token}
            onboardingState={onboardingState}
            currentLoginUserName={currentLoginUserName}
            talentName={talentName}
          />
        ),
      },
    ];
  }
};

export default EmployeeDashboardPage;
