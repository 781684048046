import { RouteComponentProps } from 'react-router-dom';
import './invoices_list.scss';
import { useTranslation } from 'react-i18next';
import { getCompanyInvoicesThunk, selectCompanyInvoicesState } from '../../../../../redux/companies';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import { INVOICE_STATUS, LoadingType, THEME } from '../../../../../enums';
import { AppError, ICompanyInvoices } from '../../../../../interfaces';
import { getDateString } from '../../../../../variables';
import { CustomButton, CustomCheckBox, currencyConversion } from '../../../../../utils';
import SortableTable, { TableData } from '../../../../../components/sortable_table/sortable_table';
import { EmailIcon, SearchIcon } from '../../../../../icons';
import SearchBar from '../../../../../components/search_bar';
import { EmailOutlined } from '@material-ui/icons';
import { Portal } from 'react-portal';
import SendInvoiceEdocsByMailPopup from '../../../popup/send_invoice_edocs_mail/send_invoice_edocs_mail';
import { companyInvoiceListActions } from '../../../../../redux/companies/details/company_invoices/company_invoices_reducer';


interface Props {
    invoiceState: {
        error?: AppError | null;
        loading: LoadingType;
        response: ICompanyInvoices[] | null;
    },
    getInvoiceURL: (invoice_id: string) => void;
    sendInvoicesByMail: (invoice_ids: string[]) => void;
    invoiceMailState: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    currentLoginUserName: string
}

const CompanyInvoicesList: React.FunctionComponent<Props> = (props) => {
    const { invoiceState, getInvoiceURL, invoiceMailState, sendInvoicesByMail, currentLoginUserName } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState('');
    const [invoiceList, setInvoiceList] = useState<ICompanyInvoices[] | null>(null);
    const [selectedItems, setSelectedItems] = useState<
        Map<string, { id: string; name: string }>
    >(new Map());
    const [showMailPopup, setShowMailPopup] = useState<boolean>(false);
    useEffect(() => {
        if (invoiceState && invoiceState.loading === LoadingType.succeeded) {
            setInvoiceList(invoiceState.response)
        }
    }, [invoiceState.loading]);
    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === invoiceList?.length}
                onClick={handleMarkAllItem}
            />
        );
    };

    const tableHeader = [
        { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        { title: "department_name", code: "department_name" },
        { title: "invoice_number", code: "invoice_number" },
        { title: "invoice_date", code: "invoice_date" },
        { title: "due_date", code: "due_date" },
        { title: "invoice_amount", code: "invoice_amount" },
        { title: "sales_tax_amount", code: "sales_tax_amount" },
        { title: "invoice_status", code: "invoice_status" },
        { title: "payment_date", code: "payment_date" },
        { title: "payment_amount", code: "payment_amount" },
        { title: "invoice_balance_amnt", code: "balance_amount" },
        { title: "", code: "" },
    ];
    const getFlexNumber = (value: number) => {
        if (value === 0) return 0.2;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1;
        if (value === 11) return 0.5;

        return 1;
    }
    const handleSearch = (value: string) => {
        setSearch(value);
    }
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    function getFilteredList(): ICompanyInvoices[] {
        let list = invoiceList ? [...invoiceList] : [];

        if (sortedField != null) {
            list = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }


        return (list).filter(doc => {
            const str = search.trim().toLowerCase();
            const invoice_number = doc.invoice_number ? doc.invoice_number.toString().trim().toLowerCase().includes(str) : false;
            const invoice_date = doc.invoice_date ? doc.invoice_date.toString().trim().toLowerCase().includes(str) : false;
            const due_date = doc.due_date ? doc.due_date.toString().trim().toLowerCase().includes(str) : false;
            const invoice_amount = doc.invoice_amount ? doc.invoice_amount.toString().trim().toLowerCase().includes(str) : false;
            const sales_tax_amount = doc.sales_tax_amount ? doc.sales_tax_amount.toString().trim().toLowerCase().includes(str) : false;
            const invoice_status = doc.invoice_status ? doc.invoice_status.toString().trim().toLowerCase().includes(str) : false;
            const payment_date = doc.payment_date ? doc.payment_date.toString().trim().toLowerCase().includes(str) : false;
            const payment_amount = doc.payment_amount ? doc.payment_amount.toString().trim().toLowerCase().includes(str) : false;
            const balance_amount = doc.balance_amount ? doc.balance_amount.toString().trim().toLowerCase().includes(str) : false;

            return invoice_number || invoice_date || due_date || invoice_amount || sales_tax_amount || payment_date || payment_amount || balance_amount || invoice_status;
        });
    }
    const handleMarkAllItem = () => {
        const newSet = new Map<string, { id: string; name: string }>();
        if (selectedItems.size === invoiceList?.length) {
            setSelectedItems(newSet);
        } else {
            invoiceList?.forEach((doc) =>
                newSet.set(doc.id, { id: doc.id, name: "" })
            );
            setSelectedItems(newSet);
        }
    };

    const handleMarkItem = (value) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value.id)) {
            newSet.delete(value.id);
        } else {
            newSet.set(value.id, { id: value.id, name: "" });
        }
        setSelectedItems(newSet);
    };
    const initialForm = { invoice_ids: [], email: "" }
    const [formState, setFormState] = useState<{ invoice_ids: string[], email: string }>(initialForm)
    const handleFieldChange = (id: string, val: string) => {
        setFormState({
            ...formState, [id]: val, invoice_ids: Array.from(
                selectedItems.values(),
                (entry) => entry.id
            )
        })
    }
    const handleEmailClose = () => {
        setShowMailPopup(false);
        setFormState(initialForm);
        dispatch(companyInvoiceListActions.clearMailState());
        setSelectedItems(new Map())
    }

    return (
        <div className='cmpny-invoice-list'>
            <div className='cmpny-invoice-header'>
                <div>
                    <span className='title-span'>{t('invoice_history')}</span>
                    <span className='total-count'>{t('total_count')}: <b>{getFilteredList().length}</b></span>
                </div>
                <div className='cmpny-header-right'>
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            leftIcon={<div><EmailOutlined style={{ color: THEME.white, height: "1vw", width: "1vw" }} /></div>}
                            name={t("send_by_email")}
                            backgroundColor={THEME.buttonBackgroundColor4}
                            onClick={() => {
                                sendInvoicesByMail(Array.from(
                                    selectedItems.values(),
                                    (entry) => entry.id
                                )); setShowMailPopup(true)
                            }}
                            enable={selectedItems.size > 0}
                        />
                    </div>
                    <div className="search-bar">
                        <SearchBar value={search} onChange={(value) => setSearch(value)} onSearch={() => { }} />
                    </div>
                </div>
            </div>
            <div className='invoice-list-table'>
                <SortableTable
                    headerList={tableHeader}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {getFilteredList() && getFilteredList()?.length! > 0 ?
                        <>
                            {getFilteredList().map((doc, index) => {
                                return (
                                    <tr key={doc.id}>
                                        <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                            <CustomCheckBox
                                                name={""}
                                                title={""}
                                                checked={selectedItems!.has(doc.id)}
                                                onClick={() => handleMarkItem(doc)}
                                            />

                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                            <span>{doc.department_name ?? ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                            <span>{doc.invoice_number ?? ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                            <span>{doc.invoice_date ? getDateString(doc.invoice_date) : ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                            <span>{doc.due_date ? getDateString(doc.due_date) : ""}</span>

                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                            <span>{doc.invoice_amount
                                                ? currencyConversion(doc.invoice_amount)
                                                : "$0.00"}</span>
                                        </TableData>

                                        <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                            <span>{doc.sales_tax_amount
                                                ? currencyConversion(doc.sales_tax_amount)
                                                : "$0.00"}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                            <span style={{ color: doc.invoice_status === INVOICE_STATUS.PAST_DUE ? 'red' : '#00a4dc' }}>{doc.invoice_status ?? ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                            <span>{doc.payment_date ? getDateString(doc.payment_date) : ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                            <span>{doc.payment_amount
                                                ? currencyConversion(doc.payment_amount)
                                                : "$0.00"}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(10) }}>
                                            <span>{doc.balance_amount
                                                ? currencyConversion(doc.balance_amount)
                                                : "$0.00"}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(11) }}>
                                            <div className="view-icon" onClick={() => getInvoiceURL(doc.id)}>
                                                <SearchIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
                                            </div>

                                        </TableData>
                                    </tr>

                                );

                            })}
                        </>
                        :
                        <>
                            <tr>
                                <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                    <div className="na_div" style={{ width: "100%", fontSize: "1vw", textAlign: "center" }}>
                                        {t("no_invoice_available")}
                                    </div>
                                </TableData>
                            </tr>
                        </>


                    }



                </SortableTable>
            </div>
            {showMailPopup &&
                <Portal>
                    <SendInvoiceEdocsByMailPopup
                        visible={showMailPopup}
                        onClose={handleEmailClose}
                        onSubmit={() => { }}
                        mailState={invoiceMailState}
                        currentLoginUserName={currentLoginUserName}
                        title={t("hi_lets_send_invoices", {
                            name: currentLoginUserName,
                        })}
                        successTitle={t("sent_invoices_by_email")}
                        successMsg={t("sent_invoices_by_email", { count: selectedItems.size })}
                        subTitle={t("to_whom_you_send_invoices")}
                    />
                </Portal>
            }
        </div>
    )
}

export default CompanyInvoicesList;