import { TEADraftBroadCast } from "../interfaces/text_em_all";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const TextEmAllAPI = {
    getTextEmAllDraftBroadCast(doc: {forceSend: boolean | undefined, data:TEADraftBroadCast}) {
        if (doc.forceSend) return interceptor.post(`${UrlBuilder.textEmAllDraftBroadCast}?force_send=${doc.forceSend}`, doc.data);
        return interceptor.post(UrlBuilder.textEmAllDraftBroadCast, doc.data);
    },
    getTextEmAllConversation(data) {
        return interceptor.post(`${UrlBuilder.textEmAllGetConversation}?phone=${data.phone}&talent_id=${data.talent_id}`);
    },
}