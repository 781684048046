import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, ITalentInterviewStatus } from "../../../interfaces";
import * as thunk from "./talent_interview_status_thunk";

export interface TalentInterviewStatusState extends EntityState<ITalentInterviewStatus> {
    error?: AppError | null,
    loading: LoadingType,
}

const talentInterviewStatusAdapter: EntityAdapter<ITalentInterviewStatus> = createEntityAdapter<ITalentInterviewStatus>({
    selectId: (doc) => doc.id,
})

const initialState: TalentInterviewStatusState = talentInterviewStatusAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const talentInterviewStatusSlice = createSlice({
    name: 'talentInterviewStatus',
    initialState: initialState,
    reducers: {
        clearRoleUserListError(state){
            return {...state, error: null};
        },
        clearRoleUserListState(state){
            return talentInterviewStatusAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTalentInterviewStatusList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getTalentInterviewStatusList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getTalentInterviewStatusList.fulfilled, (state, action) => talentInterviewStatusAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearRoleUserListError, clearRoleUserListState } = talentInterviewStatusSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = talentInterviewStatusAdapter.getSelectors();
export const selectState = (state: TalentInterviewStatusState) => state;
export const selectLoading = (state: TalentInterviewStatusState) => state.loading;
export const selectError = (state: TalentInterviewStatusState) => state.error;


