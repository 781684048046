import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { RiskManagementAPI } from "../../../../apis";
import { catchRequestError } from "../../../../utils";
import { RiskMgmtInsuranceCompany } from "../../../../interfaces/risk_mgmt_insurance_company";

export const getAllInsuranceCompanies = createAsyncThunk<
    Array<RiskMgmtInsuranceCompany>,
    string | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/risk-management-insurance-company/get',
    async (thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getAllInsuranceCompanies();
            if(typeof res.data === 'string') return [];
            return res.data as RiskMgmtInsuranceCompany[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);