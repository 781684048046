import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI,  } from "../../../../apis";
import { AppError, ITalentAvailableCandidates, } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getTalentAvailableCandidates = createAsyncThunk<
    Array<ITalentAvailableCandidates>,
    {from_date:number,to_date:number},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/traffic-available-candidates/get',
    async (payload, thunkAPI) => {
        try {
            const res = await DashboardAPI.getAvailableCandidates(payload.from_date,payload.to_date,false);
            if(typeof res.data === 'string') return [];
            return res.data as ITalentAvailableCandidates[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getTalentAvailableCandidatesCount = createAsyncThunk<
    number,
    {from_date:number,to_date:number},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/traffic-available-candidates/getCount',
    async (payload, thunkAPI) => {
        try {
            const res = await DashboardAPI.getAvailableCandidates(payload.from_date,payload.to_date,true);
            if(typeof res.data === 'string') return [];
            return res.data as ITalentAvailableCandidates[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);