import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { LoadingType, TalentOnboardingStatus, THEME } from "../../../enums";
import { CircleTickIcon } from "../../../icons";
import { ICreateTalentOnboardingData } from "../../../interfaces";

import {
  addTalentOnboardingThunk,
  onboardingVerifyTokenThunk,
  selectOnboardingVerifyTokenLoading,
  selectOnboardingVerifyTokenState,
  selectTalentOnboardingState,
  submitTalentOnboardingThunk,
  updateTalentOnboardingThunk,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import { CustomButton, isFormDirty, SpinnerScreen } from "../../../utils";
import OnboardingTalentProfile from "../../forms/onboarding_profile/onboarding_profile";
import OnBoardingPortalHolder from "../../components/onboarding_portal_holder/onboarding_portal_holder";
import OnboardingTeam from "../onboarding_team/onboarding_team";
import "./onboarding_portal.scss";
import OnboardingPaperWork from "../onboarding_paperwork/onboarding_paperwork";

enum OnBoardingSteps {
  welcomeVideo = "welcome_video",
  team = "team",
  profile = "profile",
  paperwork = "paperwork",
}
export const createTalentOnboardingInitialForm = {
  profile: {
    id: "",
    first_name: "",
    last_name: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    phone: "",
    email: "",
    social_media_links: {
      linkedin_url: null,
      twitter_url: null,
      facebook_url: null,
      pinterest_url: null,
    },
  },
  healthcare_response: {
    healthcare_response: {},
  },
  wotc_response: {
    wotc_response: {},
  },
  token: "",
  talent_id: "",
  branch_id: "",
  agency_id: "",
  submit_date_timestamp: "",
  status: TalentOnboardingStatus.TALENT_ONBOARDING_IN_PROGRESS,
  handbook_document: {
    talent_completed_review: false,
    recruiter_completed_review: false,
  },
  notice_of_healthcare_exchange_document: {
    talent_completed_review: false,
    recruiter_completed_review: false,
  },
  essential_staffcare_document: {
    talent_completed_review: false,
    recruiter_completed_review: false,
  },
  wotc_document: {
    talent_completed_review: false,
    recruiter_completed_review: false,
  },
  direct_deposit_document: {
    talent_completed_review: false,
    recruiter_completed_review: false,
    bank_accounts: [],
  },
  w4_document: {
    talent_completed_review: false,
    recruiter_completed_review: false,
  },
  i9_document: {
    talent_completed_review: false,
    recruiter_completed_review: false,
  },
  terms_and_conditions: {
    talent_read_tc: false,
  },
};
interface Props extends RouteComponentProps<any> { }
const OnBoardingPortal: React.FunctionComponent<Props> = (props) => {
  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    // Find the script element by its widget_id attribute
    const scriptElement = document.querySelector(
      '#freshworks-container'
    );
    // Remove the script element if found
    if (scriptElement) {
      scriptElement.remove();
    }
  }, []);


  const {
    loading: refetchLoading,
    error: verifyTokenError,
    response: onboardingState,
  } = useAppSelector((state) => selectOnboardingVerifyTokenState(state));


  const verifyTokenLoading = useAppSelector((state) =>
    selectOnboardingVerifyTokenLoading(state)
  );
  const { create, update, submit } = useAppSelector((state) =>
    selectTalentOnboardingState(state)
  );
  const [activeForm, setActiveForm] = useState<OnBoardingSteps>(
    OnBoardingSteps.welcomeVideo
  );
  useEffect(() => {
    if (location && location.search !== "") {
      setTasks({
        welcome_video: {
          title: "welcome_video",
          open: true,
        },
        team: {
          title: "team",
          open: true,
        },
        profile: {
          title: "profile",
          open: true,
        },
        paperwork: {
          title: "paperwork",
          open: true,
        },
      })

      setActiveForm(OnBoardingSteps.paperwork);
      const refNumber = location.search.split("?referenceNumber=")[1];
      dispatch(onboardingVerifyTokenThunk({ token: params.key, refNumber: refNumber }));
    }
    else if (params && params.key) {
      dispatch(onboardingVerifyTokenThunk({ token: params.key }));
    }
  }, [location]);
  /// [Verify key]
  //  useEffect(() => {
  //   if (params && params.key) {
  //     dispatch(onboardingVerifyTokenThunk({token:params.key}));
  //   }
  //   return () => { };
  // }, []);
  const [formState, setFormState] = useState<ICreateTalentOnboardingData>(
    createTalentOnboardingInitialForm
  );

  const [errorMessage, setErrorMessage] = useState<string>();
  useEffect(() => {
    if (verifyTokenError !== null) {
      setErrorMessage(verifyTokenError?.message)
    }
  }, [verifyTokenError])

  const [tasks, setTasks] = useState({
    welcome_video: {
      title: "welcome_video",
      open: true,
    },
    team: {
      title: "team",
      open: false,
    },
    profile: {
      title: "profile",
      open: false,
    },
    paperwork: {
      title: "paperwork",
      open: false,
    },
  });


  /// [Initialize form]
  useEffect(() => {
    if (
      verifyTokenLoading === LoadingType.succeeded &&
      onboardingState &&
      params &&
      params.key
    ) {
      const { talent_onboarding_data } = onboardingState;
      setFormState({
        ...formState,
        token: params.key,
        profile: {
          ...formState.profile,
          ...talent_onboarding_data.profile,
        },
        talent_id: talent_onboarding_data.profile.id,
      });
    }
  }, [verifyTokenLoading]);

  function handleSubmit() {
    if (onboardingState?.talent_onboarding_data) {
      dispatch(
        submitTalentOnboardingThunk({
          ...onboardingState?.talent_onboarding_data,
          status: TalentOnboardingStatus.PENDING_FOR_REVIEW,
        })
      );
    }
  }

  function handleBack() {
    if (activeForm === OnBoardingSteps.paperwork) {
      setActiveForm(OnBoardingSteps.profile);
      setTasks({ ...tasks, paperwork: { ...tasks.paperwork, open: false } });
    }
    if (activeForm === OnBoardingSteps.profile) {
      setActiveForm(OnBoardingSteps.team);
      setTasks({
        ...tasks,
        profile: { ...tasks.profile, open: false },
      });
    }
    if (activeForm === OnBoardingSteps.team) {
      setActiveForm(OnBoardingSteps.welcomeVideo);
      setTasks({ ...tasks, team: { ...tasks.team, open: false } });
    }
    if (activeForm === OnBoardingSteps.welcomeVideo) {
      //ignore
    }
  }

  function handleNext() {
    if (activeForm === OnBoardingSteps.paperwork) {
      setActiveForm(OnBoardingSteps.paperwork);
      setTasks({ ...tasks, paperwork: { ...tasks.paperwork, open: true } });
    }
    if (activeForm === OnBoardingSteps.profile) {
      setActiveForm(OnBoardingSteps.paperwork);
      setTasks({ ...tasks, paperwork: { ...tasks.paperwork, open: true } });
    }
    if (activeForm === OnBoardingSteps.team) {
      setActiveForm(OnBoardingSteps.profile);
      setTasks({
        ...tasks,
        profile: { ...tasks.profile, open: true },
      });
    }
    if (activeForm === OnBoardingSteps.welcomeVideo) {
      setActiveForm(OnBoardingSteps.team);
      setTasks({
        ...tasks,
        team: { ...tasks.team, open: true },
      });
    }
  }

  function handleProfileFieldChange(
    fieldId: string,
    value: any,
    parent?: string
  ) {
    if (parent) {
      setFormState({
        ...formState,
        profile: {
          ...formState.profile,
          [parent]: {
            ...formState.profile[parent],
            [fieldId]: value,
          },
        },
      });
    } else {
      setFormState({
        ...formState,
        profile: {
          ...formState.profile,
          [fieldId]: value,
        },
      });
    }
  }

  function handleProfileSubmit() {
    const isDirty = isFormDirty(
      formState.profile,
      onboardingState?.talent_onboarding_data?.profile ?? null
    );
    if (isDirty || onboardingState?.talent_onboarding_data.id === null) {
      updateProfile();
    } else {
      handleNext();
    }
  }

  function updateProfile() {
    if (onboardingState?.talent_onboarding_data.id) {
      dispatch(
        updateTalentOnboardingThunk({
          ...onboardingState.talent_onboarding_data,
          profile: formState.profile,
          talent_id: formState.profile.id,
        })
      );
    } else {
      dispatch(addTalentOnboardingThunk(formState));
    }
  }

  useEffect(() => {
    if (
      create.loading === LoadingType.succeeded ||
      update.loading === LoadingType.succeeded
    ) {
      handleNext();
    }
  }, [create.loading, update.loading]);

  const getFinishLoading = submit.loading === LoadingType.pending;

  if (verifyTokenLoading === LoadingType.failed) {
    return (
      <div className="onboarding-verification-token-failed-holder">
        <div className="token-failed-container">
          <div className="title-holder">
            <span style={{ marginBottom: "1rem" }}>{t("invalid_token")}</span>
            <span style={{ fontSize: "1.1458333333333333vw" }}>{errorMessage}</span>
            <span style={{ fontSize: "1.1458333333333333vw" }}>
              {t("contact_your_recruiter")}
            </span>
          </div>
          <div className="failed-action">
            {/* <div className="btn-save">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('go_to_login')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => handleGoToLogin()}
                            />
                        </div> */}
          </div>
        </div>
      </div>
    );
  }

  if (submit.loading === LoadingType.succeeded) {
    return (
      <OnBoardingPortalHolder
        history={history}
        location={location}
        hideSubTitle={true}
        agencyLogoURL={onboardingState?.agency_logo_download_url}
      >
        <div className="ob-application-form-container">
          <div className="success-message-holder">
            <div className="message-txt">
              <span>
                {t("onboarding_congratulations", {
                  name: onboardingState?.talent_onboarding_data
                    ? `${onboardingState?.talent_onboarding_data.profile.first_name  ?? ""} ${onboardingState?.talent_onboarding_data.profile.last_name ?? ""}`
                    : "",
                })}
              </span>
            </div>
          </div>
          <div className="ob-actions">
            {/* <div className="btn-save">
                        <CustomButton
                            loading={getSubmitLoading()}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('ok')}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => handleOnsuccess()}
                        />
                    </div> */}
          </div>
        </div>
      </OnBoardingPortalHolder>
    );
  }

  if (verifyTokenLoading === LoadingType.pending) {
    return (
      <OnBoardingPortalHolder history={history} location={location}  agencyLogoURL={onboardingState?.agency_logo_download_url}>
        <div className="ob-application-form-container">
          <SpinnerScreen />
        </div>
      </OnBoardingPortalHolder>
    );
  }

  return (
    <OnBoardingPortalHolder history={history} location={location}  agencyLogoURL={onboardingState?.agency_logo_download_url}>
      <div className="ob-application-form-container">
        <div className="ob-holder">
          <div className="ob-tasks-list">
            {Object.keys(tasks).map((key, index) => {
              return (
                <div
                  key={key + index + "tsk"}
                  className="task-holder"
                  style={{
                    borderBottom:
                      index === Object.keys(tasks).length - 1
                        ? "none"
                        : undefined,
                  }}
                >
                  <div className="task-icon">
                    <CircleTickIcon
                      width={"100%"}
                      height={"100%"}
                      style={{
                        color: tasks[key].open
                          ? THEME.defaultHighLightColor
                          : THEME.optionDisableColor,
                      }}
                    />
                  </div>
                  <div className="task-title">
                    <span
                      style={{
                        color: tasks[key].open
                          ? THEME.defaultHighLightColor
                          : THEME.optionDisableColor,
                      }}
                    >
                      {t(tasks[key].title)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ob-task-form">
            <div className="ob-task-form-header">
              {/* <span>
                {t(
                  "we_hate_data_entry_too_either_upload_resume_or_go_old_school"
                )}
              </span> */}
            </div>
            <div className="ob-task-form-content">{getForm()}</div>

            {/* {submitApplicationState.error && <div className={"error-section"}>
                        <ApiError message={submitApplicationState.error.message} onClose={handleClearSubmitFormError} />
                    </div>} */}
          </div>
        </div>
        {getActions()}
      </div>
    </OnBoardingPortalHolder>
  );

  /// [Section based actions]
  function getActions() {
    if (activeForm === OnBoardingSteps.team) {
      return (
        <div className="ob-actions">
          <div className="btn-cancel" style={{ marginRight: "1em" }}>
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("back")}
              enable={true}
              backgroundColor={THEME.toggleDisableColor}
              onClick={handleBack}
            />
          </div>
          <div className="btn-save">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("next")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleNext}
            />
          </div>
        </div>
      );
    }

    if (activeForm === OnBoardingSteps.profile) {
      return (
        <div className="ob-actions">
          <div className="btn-cancel" style={{ marginRight: "1em" }}>
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("back")}
              enable={true}
              backgroundColor={THEME.toggleDisableColor}
              onClick={handleBack}
            />
          </div>
          <div className="btn-save">
            <CustomButton
              loading={
                create.loading === LoadingType.pending ||
                update.loading === LoadingType.pending
              }
              textStyle={{ textTransform: "capitalize" }}
              name={t("next")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleProfileSubmit}
            />
          </div>
        </div>
      );
    }
    if (activeForm === OnBoardingSteps.paperwork) {
      return (
        <div className="ob-actions">
          <div className="btn-cancel" style={{ marginRight: "1em" }}>
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("back")}
              enable={true}
              backgroundColor={THEME.toggleDisableColor}
              onClick={handleBack}
            />
          </div>
          <div className="btn-save">
            <CustomButton
              loading={getFinishLoading}
              textStyle={{ textTransform: "capitalize" }}
              name={t("finish")}
              enable={isOnboardingPaperWorkCompleted()}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleSubmit}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="ob-actions">
        <div className="btn-save">
          <CustomButton
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("next")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={handleNext}
          />
        </div>
      </div>
    );
  }

  /// [Sections]
  function getForm() {
    if (activeForm === OnBoardingSteps.paperwork && onboardingState) {
      return (
        <>
          <OnboardingPaperWork
            token={params.key}
            termsAndConditions={
              onboardingState?.agency_onboarding_data.terms_and_conditions
            }
            state={onboardingState}
            loading={refetchLoading === LoadingType.pending}
            formState={formState}
          />
        </>
      );
    }
    if (activeForm === OnBoardingSteps.profile) {
      return (
        <>
          <OnboardingTalentProfile
            formState={formState.profile}
            // talentData={onboardingData?.talent_data}
            handleFieldChange={handleProfileFieldChange}
          />
        </>
      );
    }
    if (activeForm === OnBoardingSteps.team) {
      return (
        <>
          <OnboardingTeam
            teamData={onboardingState?.team_member_detail}
            talentName={
              onboardingState?.talent_onboarding_data.profile.first_name ?? ""
            }
            agencyName={onboardingState?.agency_onboarding_data?.name}
          />
        </>
      );
    }
    return (
      <>
        {onboardingState &&
          onboardingState?.agency_onboarding_data.welcome_video_path 
            ?
          (
            <div className="ob-task-form-content">
              <VideoPlayer
                url={onboardingState?.agency_onboarding_data.welcome_video_path}
              />
            </div>
          )
          :
          <div className="no_video">
            <span>{t("no_video_available")}</span>
          </div>
        }
      </>
    );
  }
  /// [video player]
  function VideoPlayer({ url }) {
    let videoId = "";

    if (url.toLowerCase().includes("v="))
      videoId = url.split("v=")[1].split("&")[0];
    else {
      videoId = url.split("/");
      videoId = videoId[videoId.length - 1];
    }

    return (
      <div>
        <div className="iframe-div">
          <iframe
            title="12314341321"
            style={{ borderRadius: "5px" }}
            width="500"
            height="350"
            src={`https://www.youtube.com/embed/${videoId}?controls=1`}
            allowFullScreen={true}
            frameBorder={0}
          ></iframe>
        </div>
      </div>
    );
  }

  /// [Is onboarding valid]
  function isOnboardingPaperWorkCompleted() {
    if (onboardingState?.talent_onboarding_data) {
      const {
        handbook_document,
        terms_and_conditions,
        notice_of_healthcare_exchange_document,
        // essential_staffcare_document,
        // wotc_document,
        direct_deposit_document,
        w4_document,
        i9_document,
      } = onboardingState?.talent_onboarding_data;
      return (
        handbook_document["talent_completed_review"] &&
        terms_and_conditions.talent_read_tc &&
        terms_and_conditions.talent_signature !== null &&
        notice_of_healthcare_exchange_document["talent_completed_review"] &&
        // essential_staffcare_document["talent_completed_review"] &&
        // wotc_document["talent_completed_review"] &&
        direct_deposit_document["talent_completed_review"] &&
        w4_document["talent_completed_review"] &&
        i9_document["talent_completed_review"]
      );
    }
    return false;
  }
};

export default OnBoardingPortal;
