import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";
import { BackOfficeAPI } from "../../../../../apis/back_office_api";
import { S3API } from "../../../../../apis/s3_api";

export const uploadTimeCardsDocsUploadUrl = createAsyncThunk<
    string,
    { time_card_id: string, pay_type_id: string, files: Array<File> | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    "@back_office/timecards/timecards_upload_docs/post",
    async (doc, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.uploadTimeCardsDocument({time_card_id: doc.time_card_id, pay_type_id: doc.pay_type_id, files: doc.files?.map(file=>file.name) });
            if(res.data && doc.files){
                doc.files.map((file:any) => {
                    const url = res.data[file.name];
                    S3API.uploadWithPreSignedURL(url, file);
                })
            }           
            
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);