import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectCreateState, selectState, selectUpdate, selectUpload, selectFileURL } from "./manage_company_reducer";

const select = (state: IRootState) => state.companies.universalDirectory.manageCompany;
export const selectManageCompanyState = createSelector(select, selectState);
export const selectManageCompanyCreateState = createSelector(selectManageCompanyState, selectCreateState);
export const selectManageCompanyUpdateState = createSelector(selectManageCompanyState, selectUpdate);
export const selectManageCompanyFileURLState = createSelector(selectManageCompanyState, selectFileURL);
export const selectManageCompanyUploadState = createSelector(selectManageCompanyState, selectUpload);
