import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper from "../../../../components/dialog_wrapper/dialog_wrapper";
import { useAppDispatch } from "../../../../redux/store";
import TalentBODirectDepositForm from "./add_direct_deposit_form";
import './add_direct_deposit.scss';
interface Props {
    visible: boolean;
    title: string;
    onClose: () => void;
    onDissmiss?: () => void;
    lastStep: number;
    currentLoginUserName: string;
    talentName: string;
    handleClearError: () => void;
    handleSuccessClose: () => void;
    talentId:string,
}

const TalentBODirectDepositPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        onDissmiss,
        lastStep,
        handleClearError,
        handleSuccessClose,
        talentName,
        currentLoginUserName,
        talentId
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);

    function handleClose() {
        closePopup(onClose);
        handleClearError();
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"70%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClose}>
                <div className="drd-container">
                        <div className="drd-content talent-drd-content">
                            <TalentBODirectDepositForm
                                lastStep={lastStep}
                                onClose={onClose}
                                title={title}
                                currentLoginUserName={currentLoginUserName}
                                talentName={talentName}
                                handleClearError={handleClearError}
                                onSuccessClose={handleSuccessClose}
                                talentId={talentId}
                            />
                        </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    );



    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
};

export default TalentBODirectDepositPopup;
