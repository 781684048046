import { createSlice } from "@reduxjs/toolkit";
import { AppError } from "../../../interfaces";
import { LoadingType } from "../../../enums";
import * as thunk from "./upload_file_status_thunk";
import { IIUploadFileUrlResponse, IUploadFile } from "../../../interfaces/upload_file";

export interface UploadedFileStatusState {
    error?: AppError | null,
    loading: LoadingType,
    response: Array<IUploadFile> | null,
    download: {
        error?: AppError | null,
        loading: LoadingType,
        response: IIUploadFileUrlResponse | null,
    }
}

const initialState: UploadedFileStatusState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    download: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}


export const uploadedFileStatusSlice = createSlice({
    name: 'uploadedFileStatus',
    initialState: initialState,
    reducers: {
        clearUploadedFileStatusState(state) {
            return ({ ...state, loading: LoadingType.idle, error: null });
        },
        clearUploadedFileStatusError(state) {
            return { ...state, download : { ...initialState.download , error: null }};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getUploadFileStatus.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getUploadFileStatus.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getUploadFileStatus.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))
            .addCase(thunk.downloadUploadFileStatus.pending, (state, action) => ({ ...state, download: { ...state.download, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.downloadUploadFileStatus.rejected, (state, action) => ({ ...state, download: { ...state.download, loading: LoadingType.failed, error: action.error } }))
            .addCase(thunk.downloadUploadFileStatus.fulfilled, (state, action) => ({ ...state, download: { ...state.download, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearUploadedFileStatusError, clearUploadedFileStatusState } = uploadedFileStatusSlice.actions;
export const selectState = (state: UploadedFileStatusState) => state;