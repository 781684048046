import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./employment_history_reducer";

const select = (state: IRootState) => state.talent.getTalentEmp;
export const selectTalentEmpHistoryListState = createSelector(select, selectState);
export const selectTalentEmpHistoryList = createSelector(
    select,
    selectAll
);
export const selectTalentEmpHistoryListEntities = createSelector(
    select,
    selectEntities
);