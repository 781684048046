import { IPDFAutoFill } from "../../interfaces/pdf_auto_fill";

export const NoticeOfHealthcareExchangeFormFields: {
  [key: string]: IPDFAutoFill;
} = {
  "Name Contact": {
    key: "Name Contact",
    mapTo: { parent: "health_care_info", child: "healthcare_adviser_name" },
  },
  "Name Contact 2": { key: "Name Contact 2", mapTo: "name" },
  "Name Contact 3": { key: "Name Contact 3", mapTo: "fein" },
  "Name Contact 4": { key: "Name Contact 4", mapTo: "street_address" },
  "Name Contact 5": { key: "Name Contact 5", mapTo: "city" },
  "Name Contact 6": { key: "Name Contact 6", mapTo: "state" },
  "Name Contact 7": { key: "Name Contact 7", mapTo: "zip_code" },
  "Name Contact 8": { key: "Name Contact 8", mapTo: "phone" },
  "Name Contact 9": {
    key: "Name Contact 9",
    mapTo: { parent: "health_care_info", child: "healthcare_adviser_name" },
  },
  "Name Contact 10": {
    key: "Name Contact 10",
    mapTo: { parent: "health_care_info", child: "healthcare_adviser_phone" },
  },
  "Name Contact 11": {
    key: "Name Contact 11",
    mapTo: { parent: "health_care_info", child: "healthcare_adviser_email" },
  },
  "Name Contact 12": {
    key: "Name Contact 12",
    mapTo: { parent: "health_care_info", child: "eligible_employees" },
  },
  "Name Contact 13": {
    key: "Name Contact 13",
    mapTo: { parent: "health_care_info", child: "dependents_covered" },
  },
  "Name Contact 14": { key: "Name Contact 14", mapTo: null },
  "Name Contact 15": { key: "Name Contact 15", mapTo: null },
  "Name Contact 16": { key: "Name Contact 16", mapTo: null },
  "Name Contact 17": { key: "Name Contact 17", mapTo: null },

  "Check Box 2": {
    key: "Check Box 2",
    mapTo: {
      parent: "health_care_info",
      child: "some_employees_are_eligible",
    },
  }, // Some Employees eligible
  "Check Box 3": {
    key: "Check Box 3",
    mapTo: {
      parent: "health_care_info",
      child: "dependent_coverage_available",
    },
  },
  "Check Box 4": {
    key: "Check Box 4",
    mapTo: {
      parent: "health_care_info",
      child: "health_plan_meets_minimum_value_standard",
    },
  }, ///coverage meets the minimum value standard, page 1 checkbox
  "Check Box 5": {
    key: "Check Box 5",
    mapTo: {
      parent: "health_care_info",
      child: "all_employees_are_eligible",
    },
  }, // All employees

  "Check Box 6": {
    key: "Check Box 6",
    mapTo: {
      parent: "health_care_info",
      child: "all_employees_are_eligible",
    },
  }, //  All employees page 2 checkbox-- for YES checkbox

  "Check Box 7": {
    key: "Check Box 7", mapTo: {
      parent: "health_care_info",
      child: "all_employees_are_eligible",
    },
  },//  All employees page 2 checkbox-- for NO checkbox
  "Check Box 8": {
    key: "Check Box 8",
    mapTo: {
      parent: "health_care_info",
      child: "health_plan_meets_minimum_value_standard",
    },
  }, ///coverage meets the minimum value standard, page 2 checkbox-- for YES checkbox
  "Check Box 9": {
    key: "Check Box 9", mapTo: {
      parent: "health_care_info",
      child: "health_plan_meets_minimum_value_standard",
    },
  },///coverage meets the minimum value standard, page 2 checkbox -- for NO checkbox
  "Check Box 10": { key: "Check Box 10", mapTo: null },
  "Check Box 11": { key: "Check Box 11", mapTo: null },
  "Check Box 12": { key: "Check Box 12", mapTo: null },
  "Check Box 13": { key: "Check Box 13", mapTo: null },
  "Check Box 14": { key: "Check Box 14", mapTo: null },
  "Check Box 15": { key: "Check Box 15", mapTo: null },
  "Check Box 16": { key: "Check Box 16", mapTo: null },
  "Check Box 17": { key: "Check Box 17", mapTo: null },
  "Check Box 18": { key: "Check Box 18", mapTo: null },
  "Check Box 19": { key: "Check Box 19", mapTo: null },
  "Check Box 20": { key: "Check Box 20", mapTo: null },
  "Check Box 21": { key: "Check Box 21", mapTo: null },
  "Check Box 22": { key: "Check Box 22", mapTo: null },
  "Check Box 23": { key: "Check Box 23", mapTo: null },
};
