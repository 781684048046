import { createAsyncThunk } from "@reduxjs/toolkit";
import { MyProfileAPI } from "../../../apis/my_profile_api";
import { Profile, AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { MyProfile } from "../../../interfaces/my_profile";

/// get user
export const getMyProfile = createAsyncThunk<
    MyProfile,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@myprofile/get',
    async (_, thunkAPI) => {
        try {
            const res = await MyProfileAPI.getMyProfile();
            return res.data as MyProfile;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


