import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";
import { IInBoundLeads } from "../../../../../interfaces/inbound_leads";
import * as thunk from "./inbound_leads_directory_thunk";

export interface InBoundLeadsState {
    error?: AppError | null;
    loading: LoadingType;
    response: Array<IInBoundLeads> | null;
    getById: {
        error?: AppError | null;
        loading: LoadingType;
        response: IInBoundLeads | null,
    };
    getCount: {
        error?: AppError | null;
        loading: LoadingType;
        response: number | null,
    };
    sendInvite: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null,
    };
    import: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null,
    }
    archive: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null,
    }
}


const initialState: InBoundLeadsState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    getById: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    getCount: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    sendInvite: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    import: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    archive: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}

export const inBoundLeadsStateSlice = createSlice({
    name: "inBoundLeadsState",
    initialState: initialState,
    reducers: {
        clearInBoundLeadsStateError(state) {
            return { ...state, error: null };
        },
        clearInBoundLeadsSendInviteState(state) {
            return { ...state, sendInvite: initialState.sendInvite };
        },
        clearInBoundLeadsImportState(state) {
            return { ...state, import: initialState.import };
        },
        clearInBoundLeadsArchiveState(state) {
            return { ...state, archive: initialState.archive };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getInBoundLeadsList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getInBoundLeadsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getInBoundLeadsList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))
            .addCase(thunk.getInBoundLeadGetById.pending, (state, action) => ({ ...state, getById: { ...state.getById, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getInBoundLeadGetById.rejected, (state, action) => ({ ...state, getById: { ...state.getById, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getInBoundLeadGetById.fulfilled, (state, action) => ({ ...state, getById: { ...state.getById, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addCase(thunk.getInBoundLeadsListCount.pending, (state, action) => ({ ...state, getCount: { ...state.getCount, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getInBoundLeadsListCount.rejected, (state, action) => ({ ...state, getCount: { ...state.getCount, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getInBoundLeadsListCount.fulfilled, (state, action) => ({ ...state, getCount: { ...state.getCount, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addCase(thunk.saveInBoundLeadSendInvite.pending, (state, action) => ({ ...state, sendInvite: { ...state.sendInvite, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.saveInBoundLeadSendInvite.rejected, (state, action) => ({ ...state, sendInvite: { ...state.sendInvite, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.saveInBoundLeadSendInvite.fulfilled, (state, action) => ({ ...state, sendInvite: { ...state.sendInvite, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addCase(thunk.saveInBoundLeadImport.pending, (state, action) => ({ ...state, import: { ...state.import, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.saveInBoundLeadImport.rejected, (state, action) => ({ ...state, import: { ...state.import, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.saveInBoundLeadImport.fulfilled, (state, action) => ({ ...state, import: { ...state.import, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addCase(thunk.saveInBoundLeadArchive.pending, (state, action) => ({ ...state, archive: { ...state.archive, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.saveInBoundLeadArchive.rejected, (state, action) => ({ ...state, archive: { ...state.archive, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.saveInBoundLeadArchive.fulfilled, (state, action) => ({ ...state, archive: { ...state.archive, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const inBoundLeadsStateActions = inBoundLeadsStateSlice.actions;
export const selectState = (state: InBoundLeadsState) => state;
