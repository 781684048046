import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../../interfaces";
import { JobOrderAPI } from "../../../../../apis";
import { catchRequestError } from "../../../../../utils";
import { IJobPost } from "../../../../../interfaces/job_posting";


export const getjobpPostChatGPTQueryThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/success-profile-job-post/job-post-chatgpt-question/get',
    async (id, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getjobpPostChatGPTQuery(id);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getJobPostThunk = createAsyncThunk<
    IJobPost,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/success-profile-job-post/job-posts/get',
    async (id, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobPosts(id);
            return res.data as IJobPost;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const postJobPostThunk = createAsyncThunk<
    string,
    { job_id: string, description: string },

    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/success-profile-job-post/job-posts/post',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.postJobPosts(data);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const patchJobPostThunk = createAsyncThunk<
    string,
    IJobPost,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/success-profile-job-post/job-posts/patch',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.patchJobPosts(data);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteJobPostThunk = createAsyncThunk<
    string,
    { job_id: string, job_post_id: string | undefined },

    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/success-profile-job-post/job-posts/delete',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.deleteJobPosts(data);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const postJobPostToGoogleThunk = createAsyncThunk<
    string,
    IJobPost,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/success-profile-job-post/job-post-to-google/post',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.postJobPostToGoogle(data);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);