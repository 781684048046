import { useTranslation } from "react-i18next";
import SectionCard from "../../../../../components/section_card/section_card";
import { useAppDispatch } from "../../../../../redux/store";
import { useState } from "react";
import { AppError } from "../../../../../interfaces";
import { LoadingType, THEME } from "../../../../../enums";
import { EditIcon, TickGreenIcon, TickGreyIcon } from "../../../../../icons";
import { CustomButton, currencyConversion, getPlusBtnIcon } from "../../../../../utils";
import './tax_information.scss';
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { ITalentBackOfficePayrollSetup, ITalentBackOfficeTalentAdditionalTax } from "../../../../../interfaces/talent_back_office";
import { Portal } from "react-portal";
import AddTaxInformationPopup from "../../../popups/add_additional_tax/add_additional_tax";

interface Props {
    taxInfoUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    // handleFieldChange: (fieldId: string, value: any, parent: string, subParent: string) => void;
    formState: ITalentBackOfficePayrollSetup;
    onClearError: () => void;
    handleEditCancel?: () => void;
    isLoading?: boolean,
    currentLoginUserName: string;
    talentId: string;
    talentName: string;
    handleUpdateClearState: () => void;
    getTalentBOPayrollData: () => void;
    handleAddTaxInfo: (doc: ITalentBackOfficeTalentAdditionalTax, parent: string, index?: number, removeItem?: boolean) => void;

}

const taxInformationTableHeader = [
    { title: 'tax', code: 'tax' },
    { title: 'filling_status', code: 'filling_status' },
    { title: 'dependent_and_other_credits', code: 'dependent_and_other_credits' },
    { title: 'other_income', code: 'other_income' },
    { title: 'deductions', code: 'deductions' },
    { title: 'extra_withholding', code: 'extra_withholding' },
    { title: 'has_lockin_letter', code: 'has_lockin_letter' },
    { title: 'geo_code', code: 'geo_code' },
    // { title: '', code: '' },

]

const initialForm = {
    additional_tax_id: "",
    additional_tax_value: "",
    w4_data: {
        modified_by: "",
        created_by: "",
        modified_date: 0,
        filing_status: "",
        multiple_jobs: false,
        dependent_and_other_credits: 0,
        other_income: 0,
        deductions: 0,
        extra_withholding: 0
    },
    has_lockin_letter: false,
    resident: false,
    geo_code: "",
}

const TaxInformation: React.FC<Props> = props => {
    const {
        formState,
        onClearError,
        currentLoginUserName,
        talentName,
        handleUpdateClearState,
        getTalentBOPayrollData,
        handleAddTaxInfo,
        taxInfoUpdateState
    } = props

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const parentField = 'talent_additional_tax'
    const [addTaxInformation, setAddTaxInformation] = useState<boolean>(false);
    const [taxInformationSorted, setTaxInformationSorted] = useState<string | null>(null);
    const [editIndex, setEditIndex] = useState<number | undefined>();
    const [taxInformationFormState, setTaxInformationFormState] = useState<ITalentBackOfficeTalentAdditionalTax>(initialForm);

    function handleTISortFieldChange(value: string) {
        if (taxInformationSorted && taxInformationSorted === value) {
            setTaxInformationSorted(null);
        } else {
            setTaxInformationSorted(value);
        }
    }
    function handleFormFieldChange(fieldId: string, value: any, parent: string) {
        setTaxInformationFormState({
            ...taxInformationFormState,
            [fieldId]: value,
        });
    };
    const handleSave = () => {
        if (JSON.stringify(taxInformationFormState) === JSON.stringify(initialForm)) {
            handleClose();
        }
        else {
            handleAddTaxInfo(taxInformationFormState, parentField, editIndex);
        }
    }
    const handleClose = () => {
        setAddTaxInformation(false);
        setTaxInformationFormState(initialForm);
        setEditIndex(undefined);
    }
    const handleOnSuccessClose = () => {
        setEditIndex(undefined);
        handleClose();
        handleUpdateClearState();
        getTalentBOPayrollData();
    }
    const handleEdit = (val: ITalentBackOfficeTalentAdditionalTax, index: number) => {
        setAddTaxInformation(true);
        setTaxInformationFormState(val);
        setEditIndex(index);
    };
    function getTIFilteredList(): ITalentBackOfficeTalentAdditionalTax[] {
        let sortedList: ITalentBackOfficeTalentAdditionalTax[] | undefined;
        if (taxInformationSorted != null) {
            sortedList = [...(formState?.talent_additional_tax ?? [])].sort((a, b) => {
                if (a[taxInformationSorted] < b[taxInformationSorted]) {
                    return -1;
                }
                if (a[taxInformationSorted] > b[taxInformationSorted]) {
                    return 1;
                }
                return 0;
            });
        }
        return (sortedList ?? (formState?.talent_additional_tax ?? []));
    }

    return (
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('tax_information')} >
            <div className="tax-info-container">
                <div className="tax-info-header">
                    {/* <div className="section-header">{t("tax_setup")}</div> */}
                    {/* <div>
                        <CustomButton
                            leftIcon={getPlusBtnIcon()}
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("add_additional_tax")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => setAddTaxInformation(true)}
                        /></div> */}
                </div>
                <div className="tax-info-content">
                    {/* {formState?.talent_additional_tax && formState?.talent_additional_tax?.length === 0 ?
                        (
                            <div className="empty-data-msg">
                                <span>{t("no_data_available")}</span>
                            </div>
                        )
                        :
                        ( */}
                            <div className="tax-info-table">
                                <SortableTable
                                    headerList={taxInformationTableHeader}
                                    sortedField={taxInformationSorted}
                                    onSortChange={handleTISortFieldChange}
                                    flexNumber={getFlexNumber}
                                >
                                    {getTIFilteredList().map((doc, index) => {
                                        return (
                                            <tr key={index} onClick={() => { }}>
                                                <TableData customStyle={{ flex: getFlexNumber(0) }}>{doc.additional_tax_value}</TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(1) }}>{doc.w4_data.filing_status}</TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                    <span>{doc.w4_data.dependent_and_other_credits
                                                        ? currencyConversion(doc.w4_data.dependent_and_other_credits)
                                                        : "$0.00"}</span>

                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                    <span>{doc.w4_data.other_income
                                                        ? currencyConversion(doc.w4_data.other_income)
                                                        : "$0.00"}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                    <span>{doc.w4_data.deductions
                                                        ? currencyConversion(doc.w4_data.deductions)
                                                        : "$0.00"}</span>
                                                </TableData>

                                                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                    <span>{doc.w4_data.extra_withholding
                                                        ? currencyConversion(doc.w4_data.extra_withholding)
                                                        : "$0.00"}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                                    <span>{doc.has_lockin_letter ?
                                                        <div className="tick-icon">
                                                            <TickGreenIcon width={"100%"} height={"100%"} />
                                                        </div> : <div className="tick-icon">
                                                            <TickGreyIcon width={"100%"} height={"100%"} />
                                                        </div>}</span>
                                                </TableData>

                                                <TableData customStyle={{ flex: getFlexNumber(7) }}>{doc.geo_code} </TableData>
                                                {/* <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                                    <span>
                                                        <button
                                                            className="icon-btn tdp-edit-icon"
                                                            onClick={() => handleEdit(doc, index)}
                                                        >
                                                            <EditIcon
                                                                width={"100%"}
                                                                height={"100%"}
                                                                style={{ color: "#fff" }}
                                                            />
                                                        </button>
                                                    </span>
                                                </TableData> */}

                                            </tr>
                                        );
                                    })}
                                </SortableTable>
                            </div>
                        {/* )
                    } */}

                </div>
            </div>
            {addTaxInformation &&
                <Portal>
                    <AddTaxInformationPopup
                        visible={addTaxInformation}
                        title={t("hi_name_add_additional_tax", { name: currentLoginUserName })}
                        successTitle={t("additional_tax_backoffice_profile")}
                        onClose={handleClose}
                        onSuccessClose={handleOnSuccessClose}
                        onDissmiss={handleClose}
                        currentLoginUserName={currentLoginUserName}
                        taxInformationFormState={taxInformationFormState}
                        handleFormFieldChange={handleFormFieldChange}
                        handleSave={handleSave}
                        index={editIndex}
                        talentName={talentName ? talentName.split(" ")[0] : ""}
                        parentField={parentField}
                        taxInfoUpdateState={taxInfoUpdateState}
                        handleClearError={onClearError}
                    />
                </Portal>}
        </SectionCard>
    );

    function getFlexNumber(value: number) {
        if (value === 0) return 1.5;
        if (value === 1) return 1.5;
        if (value === 2) return 2;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1.5;
        if (value === 6) return 1.2;
        if (value === 7) return 0.7;
        if (value === 8) return 1;
        if (value === 9) return 0.5;
        if (value === 10) return 1;


        return 1;
    }
}

export default TaxInformation;