import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import CustomFormSelect, { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import { LoadingType, ROLE, TALENT_SKILLS_TYPE, THEME } from "../../../../enums";
import { IAddCompanyStaff, IAddSkill } from "../../../../interfaces";

import {
    addCompanyAccountManagers,
    addCompanyRecruiters,
    selectCertificationsList,
    selectCertificationsListState,
    selectCompanyDetailsByIdState,
    selectCredentialsList,
    selectCredentialsListState,
    selectEducationList,
    selectEducationListState,
    selectRequiredEquipmentList,
    selectRequiredEquipmentListState,
    selectSkillSetList,
    selectSkillSetListState,
    selectRoleUsersListState,
    selectAccountManagerRoleUsersList,
    selectRecruiterRoleUsersList,
    useAppDispatch, useAppSelector, postSnackbarMessage,
    selectManageCompanyUpdateState,
    addCompanySkills,
} from "../../../../redux/store";

import { companyDetailsSliceActions } from "../../../../redux/companies/details/dashboard/company_details/company_details_reducer";
import { CustomButton, getStatusFromICreateState, getUserNameFromProfileDropdown, SpinnerScreen } from "../../../../utils";
import './create_option.scss';
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { CompaniesIcon } from "../../../../icons";
import { manageCompanyActions } from "../../../../redux/companies/universal_directory/manage_company/manage_company_reducer";
interface CreateOptionProps {
    visible: boolean,
    data: {
        company_id: string,
        department_id?: string,
    },
    type: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

export const CreateOption = (props: CreateOptionProps) => {
    const {
        visible,
        data,
        type,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const skillSetListState = useAppSelector((state) => selectSkillSetListState(state));
    const credentialsListState = useAppSelector((state) => selectCredentialsListState(state));
    const certificationsListState = useAppSelector((state) => selectCertificationsListState(state));
    const educationListState = useAppSelector((state) => selectEducationListState(state));
    const requiredEquipmentListState = useAppSelector((state) => selectRequiredEquipmentListState(state));
    const roleUsersListState = useAppSelector((state) => selectRoleUsersListState(state));
    const comapnyUpdateState = useAppSelector((state) => selectManageCompanyUpdateState(state));

    const skillSetList = useAppSelector((state) => selectSkillSetList(state));
    const credentialsList = useAppSelector((state) => selectCredentialsList(state));
    const certificationsList = useAppSelector((state) => selectCertificationsList(state));
    const educationList = useAppSelector((state) => selectEducationList(state));
    const requiredEquipmentList = useAppSelector((state) => selectRequiredEquipmentList(state));
    const accountManagersList = useAppSelector((state) => selectAccountManagerRoleUsersList(state));
    const recruitersList = useAppSelector((state) => selectRecruiterRoleUsersList(state));

    /// Create optin states
    const companyState = useAppSelector((state) => selectCompanyDetailsByIdState(data.department_id ?? data.company_id)(state));
    const skillSetAddState = companyState.professional_skills.add;
    const credentialsAddState = companyState.credentials.add;
    const certificationsAddState = companyState.certifications.add;
    const educationAddState = companyState.education.add;
    const requiredEquipmentAddState = companyState.safety_equipment.add;
    const accountManagerAddState = companyState.account_managers.add;
    const recruitersAddState = companyState.recruiters.add;

    const [selected, setSelected] = useState<string[]>([]);
    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        if (skillSetAddState.loading === LoadingType.succeeded ||
            credentialsAddState.loading === LoadingType.succeeded ||
            certificationsAddState.loading === LoadingType.succeeded ||
            educationAddState.loading === LoadingType.succeeded ||
            requiredEquipmentAddState.loading === LoadingType.succeeded ||
            comapnyUpdateState.loading === LoadingType.succeeded ||
            accountManagerAddState.loading === LoadingType.succeeded ||
            recruitersAddState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(skillSetAddState.response ||
                credentialsAddState.response ||
                certificationsAddState.response ||
                educationAddState.response ||
                requiredEquipmentAddState.response ||
                accountManagerAddState.response ||
                recruitersAddState.response ||
                getStatusFromICreateState(comapnyUpdateState.response)));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [
        skillSetAddState.loading,
        credentialsAddState.loading,
        certificationsAddState.loading,
        educationAddState.loading,
        requiredEquipmentAddState.loading,
        comapnyUpdateState.loading,
        accountManagerAddState.loading,
        recruitersAddState.loading,
    ])

    function isFetchLoading() {
        return skillSetListState.loading === LoadingType.pending ||
            credentialsListState.loading === LoadingType.pending ||
            certificationsListState.loading === LoadingType.pending ||
            educationListState.loading === LoadingType.pending ||
            requiredEquipmentListState.loading === LoadingType.pending ||
            roleUsersListState.loading === LoadingType.pending ||
            companyState.loading === LoadingType.pending;
            
    }

    function isAddLoading() {
        return skillSetAddState.loading === LoadingType.pending ||
            credentialsAddState.loading === LoadingType.pending ||
            certificationsAddState.loading === LoadingType.pending ||
            educationAddState.loading === LoadingType.pending ||
            requiredEquipmentAddState.loading === LoadingType.pending ||
            comapnyUpdateState.loading === LoadingType.pending ||
            accountManagerAddState.loading === LoadingType.pending ||
            recruitersAddState.loading === LoadingType.pending;
    }

    function getError() {
        return skillSetAddState.error ||
            credentialsAddState.error ||
            certificationsAddState.error ||
            educationAddState.error ||
            requiredEquipmentAddState.error ||
            comapnyUpdateState.error ||
            accountManagerAddState.error ||
            recruitersAddState.error;
    }

    function getListOptions(): Array<{ label: string, value: string }> {
        switch (type) {
            case TALENT_SKILLS_TYPE.professional_skills: return skillSetList.map((doc, index) => ({ label: doc.skill_set, value: doc.id, isDisabled: companyState?.professional_skills?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.credentials: return credentialsList.map((doc, index) => ({ label: doc.credential, value: doc.id, isDisabled: companyState?.credentials?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.certifications: return certificationsList.map((doc, index) => ({ label: doc.certification, value: doc.id, isDisabled: companyState?.certifications?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.education: return educationList.map((doc, index) => ({ label: doc.education_requirement, value: doc.id, isDisabled: companyState?.education?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.safety_equipment: return requiredEquipmentList.map((doc, index) => ({ label: doc.required_equipment, value: doc.id, isDisabled: companyState?.safety_equipment?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.account_managers: return accountManagersList.map((doc, index) => ({ label: getUserNameFromProfileDropdown(doc), value: doc.id, isDisabled: companyState?.account_managers?.data!.map(doc => doc.id).includes(doc.id) }));
            case TALENT_SKILLS_TYPE.recruiters: return recruitersList.map((doc, index) => ({ label: getUserNameFromProfileDropdown(doc), value: doc.id, isDisabled: companyState?.recruiters?.data!.map(doc => doc.id).includes(doc.id) }));
            default: return [];
        }
    }

    function getOptionForm() {
        return (
            <div className="cmpny-op-f-container">
                <div className="cmpny-op-f-header">
                    <FormDialogTilteHeader
                        title={`${t('add_option', { value: t(type) })}`}
                        titleIcon={<CompaniesIcon width={"100%"} height={"100%"} />}
                    />
                </div>
                <div className="cmpny-op-f-content">
                    <CustomMultiFormSelect
                        name={type}
                        list={getListOptions()}
                        onChange={(value) => setSelected(value)}
                        required={true}
                        placeholder={t('select_option', { value: t(type) })}
                        value={selected}
                        isOptionSelected={(option)=> 
                            (companyState?.professional_skills?.data!.map(doc => doc.id).includes(option.value) || 
                            companyState?.credentials?.data!.map(doc => doc.id).includes(option.value)||
                            companyState?.certifications?.data!.map(doc => doc.id).includes(option.value) ||
                            companyState?.education?.data!.map(doc => doc.id).includes(option.value) ||
                            companyState?.safety_equipment?.data!.map(doc => doc.id).includes(option.value) ||
                            (companyState?.account_managers?.data!.map(doc => doc.id).includes(option.value) && type === "account_managers") || 
                            (companyState?.recruiters?.data!.map(doc => doc.id).includes(option.value)) && type === "recruiters") ||
                            selected.some(selectedOption => selectedOption === option.value)
                        }
                        customStyle={{ width: '-webkit-fill-available' }}
                    />
                    {getError() != null && <div className={"error-section"}>
                        <ApiError message={getError()?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="cmpny-op-f-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('cancel')}
                            enable={true}
                            backgroundColor={THEME.secondaryColor4}
                            onClick={() => {
                                if (getError()) handleClearError();
                                closePopup(onClose)
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={isAddLoading()}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('add')}
                            enable={selected.length != 0}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleAddOption}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={430}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                {isFetchLoading() ? <div className="loading-spinner"><SpinnerScreen /></div> : getOptionForm()}
            </DialogWrapper>

        </ActionDialogHolder>

    );

    // function 

    function handleClearError() {
        dispatch(companyDetailsSliceActions.clearCompanyDetailsAddOptionError({id: data.department_id ?? data.company_id, type: type}));
        dispatch(manageCompanyActions.clearManageCompanyStateError());
    }

    function handleAddOption() {
        switch (type) {
            case TALENT_SKILLS_TYPE.account_managers: {

                const payload: IAddCompanyStaff = {
                    company_id: data.company_id,
                    department_id: data.department_id,
                    user_id_list: [...selected, ...(companyState?.data?.account_managers ?? []),],
                    role: ROLE.accountManager,
                }
                dispatch(addCompanyAccountManagers(payload));
                break;
            }
            case TALENT_SKILLS_TYPE.recruiters: {
                const payload: IAddCompanyStaff = {
                    company_id: data.company_id,
                    department_id: data.department_id,
                    user_id_list: [...selected, ...(companyState?.data?.recruiters ?? []),],
                    role: ROLE.recruiter,
                }
                dispatch(addCompanyRecruiters(payload));
                break;
            }

            case TALENT_SKILLS_TYPE.professional_skills: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addCompanySkills({ companyId: data.company_id, departmentId: data.department_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.credentials: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addCompanySkills({ companyId: data.company_id, departmentId: data.department_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.certifications: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addCompanySkills({ companyId: data.company_id, departmentId: data.department_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.education: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addCompanySkills({ companyId: data.company_id, departmentId: data.department_id, value: payload }));
                break;
            }
            case TALENT_SKILLS_TYPE.safety_equipment: {
                const payload: IAddSkill = {
                    skill_type: type,
                    skill_ids: selected,
                };
                dispatch(addCompanySkills({ companyId: data.company_id, departmentId: data.department_id, value: payload }));
                break;
            }
            default: { break; }
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}