import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IBillingInvoicesReportData } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { BackOfficeAPI } from "../../../../apis/back_office_api";


export const getBillingInvoiceReportThunk = createAsyncThunk<
    IBillingInvoicesReportData[],
    { week_accounting_id: string, batch_id?: string },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing_invoices/billing_invoices_report/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getBillingInvoiceReportData(payload.week_accounting_id, payload.batch_id);
            return res.data as IBillingInvoicesReportData[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


