import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectDeductionState, selectInitialDeductionState } from "./deduction_details_page_reducer";


const select = (state: IRootState) => state.backOffice.thirdPartyDeduction.deductionDetails;
export const selectDeductionDetailsState = createSelector(select, selectState);

export const selectDeductionDetailsByIdState = (id: string | undefined | null) => createSelector(
    selectDeductionDetailsState,
    (state) => {
        if (id && state[id]) return selectDeductionState(state, id);
        return selectInitialDeductionState();
    }
);
