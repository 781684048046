import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ITalent } from "../../../../interfaces";
import * as thunk from "./talent_list_thunk";

export interface TalentListState extends EntityState<ITalent> {
    error?: AppError | null,
    loading: LoadingType,
}

const talentListAdapter: EntityAdapter<ITalent> = createEntityAdapter<ITalent>({
    selectId: (doc) => doc.id,
})

const initialState: TalentListState = talentListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const talentListSlice = createSlice({
    name: 'talentList',
    initialState: initialState,
    reducers: {
        clearTalentListError(state){
            return {...state, error: null};
        },
        clearTalentListState(state){
            return talentListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTalentList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getTalentList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getTalentList.fulfilled, (state, action) => talentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearTalentListError, clearTalentListState } = talentListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = talentListAdapter.getSelectors();
export const selectState = (state: TalentListState) => state;
export const selectLoading = (state: TalentListState) => state.loading;
export const selectError = (state: TalentListState) => state.error;


