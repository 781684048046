import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportCenterAPI } from "../../../apis/report_center_api";
import { AppError } from "../../../interfaces";
import { IReportCenterBOPayload, IReportCenterUploadUrlResponse } from "../../../interfaces/report_center";
import { catchRequestError } from "../../../utils";

export const getReportDownloadURL = createAsyncThunk<
    IReportCenterUploadUrlResponse,
    IReportCenterBOPayload,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@report_center/payroll_tax/reports/download/get',
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getReportDownloadURL(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);