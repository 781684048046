import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CompaniesIcon } from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton, FormInput, formatToMoney, mapOptions } from "../../../../utils";
import './add_rate_by_job_popup.scss';
import { AppError, ICompBOInvoiceDocsRatesJobTitle } from "../../../../interfaces";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { selectMarkUpList, getMarkUp } from "../../../../redux/admin_center/back_office";
import ApiError from "../../../../components/api_error";

interface Props {
    visible: boolean,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    currentLoginUserName: string;
    handleFormFieldChange: (fieldId: string, value: any, parent: string) => void;
    rateJobFormState: ICompBOInvoiceDocsRatesJobTitle;
    handleSave: () => void;
    codeRateOptionsUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleClearError: () => void;
    index: number | undefined;

}
const AddNewRateByJobPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        currentLoginUserName,
        rateJobFormState,
        handleFormFieldChange,
        handleSave,
        codeRateOptionsUpdateState,
        handleClearError,
        index
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const parentField = "rates_by_job_title";
    const actionRef = useRef<ActionDialogHolderType>(null);
    const markUpList = useAppSelector((state) => selectMarkUpList(state));
    useEffect(() => {
        dispatch(getMarkUp());
    }, []);
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="add-rate-container">
                    <div className="add-rate-header">
                        <FormDialogTilteHeader
                            title={t('hi_complete_info_add_new_rate_job', { name: currentLoginUserName })}
                            titleIcon={<CompaniesIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="add-rate-content">
                        <div className="add-rate-input-holder-row">
                            <div className="add-rate-input-holder">
                                <FormInput
                                    id={'job_title'}
                                    onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                    required={false}
                                    placeholder={""}
                                    type={"text"}
                                    value={rateJobFormState?.job_title}
                                    label={t("what_job_title")}
                                />
                            </div>
                            <div className="add-rate-input-holder">
                                <FormInput
                                    id={'shift'}
                                    onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                    required={false}
                                    placeholder={""}
                                    type={"text"}
                                    value={rateJobFormState.shift}
                                    label={t("what_shift_assosciated")}
                                />
                            </div>
                        </div>
                        <div className="add-rate-input-holder-row">
                            <div className="add-rate-input-holder">
                                <CustomFormSelect
                                    name={t("mark_up_date")}
                                    list={mapOptions(markUpList, "title", "title", true)}
                                    onChange={(val) => handleFormFieldChange("mark_up_date", val, parentField)}
                                    value={rateJobFormState?.mark_up_date}
                                    placeholder={t('select')}
                                    required={false}
                                    label={t('what_is_the_markup')}
                                />
                            </div>
                            <div className="add-rate-input-holder">
                                <FormInput
                                    id={'pay_rate'}
                                    onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                    required={false}
                                    placeholder={""}
                                    type={"text"}
                                    value={rateJobFormState.pay_rate}
                                    label={t("what_payrate_to_use_rate_job")}
                                    prefix={<span>$</span>}
                                    onBlur={formatToMoney}
                                    onKeyDown={formatToMoney}
                                    onKeyUp={formatToMoney}
                                />
                            </div>
                        </div>
                        {codeRateOptionsUpdateState && codeRateOptionsUpdateState.error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={codeRateOptionsUpdateState?.error?.message}
                                    onClose={handleClearError}
                                />
                            </div>
                        )}

                    </div>
                    <div className="add-rate-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={onClose}
                            />
                        </div>

                        <div className="btn-save">
                            <CustomButton
                                loading={codeRateOptionsUpdateState?.loading === LoadingType.pending}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('save')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleSave}
                            />
                        </div>
                    </div>
                </div>

            </DialogWrapper>
        </ActionDialogHolder>
    );
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default AddNewRateByJobPopup;