import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import {AppError} from "../../../../../interfaces/app_error";
import { ContactListItem  } from "../../../../../entities/ContactInfo";
import * as thunk from './company_contact_create_thunk';


export interface SingleCompanyContactCreateState {
    error?: AppError | null,
    loading: LoadingType,
    response: string
}


export interface CompanyContactCreateState {
    [key: string]: SingleCompanyContactCreateState,
}


const initialState: CompanyContactCreateState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        response: '',
        error: null
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
        response: ''
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        error: null,
        response: payload
    }
}

export const companyContactCreateSlice = createSlice({
    name: 'contactCreate',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.saveCompanyContacts.pending, (state, action) => ({...state, [action.meta.arg.company_id]: {...getLoadingState(state[action.meta.arg.company_id])}}))
            .addCase(thunk.saveCompanyContacts.rejected, (state, action) => ({...state, [action.meta.arg.company_id]: {...getErrorState(state[action.meta.arg.company_id], action.payload)}}))
            .addCase(thunk.saveCompanyContacts.fulfilled, (state, action) => ({...state, [action.meta.arg.company_id]: {...getSuccessState(state[action.meta.arg.company_id], action.payload)}}))
            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const {} = companyContactCreateSlice.actions;
export const selectCompanyContactCreateState = (state: CompanyContactCreateState) => state;
export const selectSingleCompanyContactCreateState = (state: CompanyContactCreateState, id: string): SingleCompanyContactCreateState => state[id];

export const selectInitialSingleCompanyContactCreateState = (): SingleCompanyContactCreateState => ({
    error: null,
    loading: LoadingType.idle,
    response: ''
});