import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../../apis";
import { AppError,  TalentInterviewQuestion } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";


export const getTalentInHouseInterviewQuestions = createAsyncThunk<
    Array<TalentInterviewQuestion>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/interviews/talent-in-house-interview/interview-questions/get',
    async (talentId, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalentInHouseInterviewQuestions();
            if (typeof res.data === 'string') return [];
            return res.data as TalentInterviewQuestion;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


