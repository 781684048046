import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, JobOrderPlacement } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getJobOrderPlacements = createAsyncThunk<
    Array<JobOrderPlacement>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/job_order_placement/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getJobOrderPlacements();
            return res.data as JobOrderPlacement[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createJobOrderPlacement = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_order_placement/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createJobOrderPlacement(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteJobOrderPlacement = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_order_placement/delete',
    async (status_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteJobOrderPlacement(status_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateJobOrderPlacement = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/job_order_placement/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateJobOrderPlacement(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);