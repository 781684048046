import { CircularProgress } from "@material-ui/core";
import React from "react";
// import { StyleSheet, View, Text, Image, TextInput, Pressable } from "react-native";
import { FONT, THEME } from "../enums";
// import Svg, { Path } from "react-native-svg";
import CustomText from "./custom_text";

interface Props {
    name: React.ReactNode | any,
    enable?: boolean,
    backgroundColor?: string,
    padding?: number,
    fontSize?: any,
    fontFamily?: string,
    textStyle?: React.CSSProperties,
    customStyle?: React.CSSProperties,
    customClassName?: string,
    loading?: boolean,
    leftIcon?: JSX.Element,
    rightIcon?: JSX.Element,
    type?: "button" | "submit" | "reset" ,
    title?: string,
    onClick: () => void;
}
export function CustomButton(props: Props) {
    const {
        name,
        enable = true,
        backgroundColor,
        padding,
        fontSize,
        fontFamily,
        textStyle,
        customStyle,
        loading,
        leftIcon,
        rightIcon,
        customClassName,
        onClick,
        type,
        title,
    } = props;

    return (
        <div className="custom-btn" style={styles.container}>
            <button
                type={type ?? 'button'}
                title={title}
                onClick={() => {
                    if (loading) return;
                    if (enable) return onClick();
                }}
                className={`custom-button ${enable ? 'custom-button-enabled' : 'custom-button-disabled'} ${customClassName}`}
                style={{

                    ...enable === false && { backgroundColor: 'rgba(0,0,0,0.2)' },
                    ...enable && { backgroundColor: backgroundColor ? backgroundColor : THEME.primaryColor },
                    ...(leftIcon || rightIcon) && { justifyContent: 'flex-start' },
                    ...(leftIcon && rightIcon) && { justifyContent: 'space-between' },
                    ...customStyle,

                }}

            >
                <LeftIcon />
                <div className="btn-text">
                    <CustomText customStyle={{
                        ...styles.text,
                        ...{
                            fontSize: fontSize ? fontSize : '0.833vw',
                            fontFamily: fontFamily ? fontFamily : FONT.lato,
                            fontWeight: 900,
                            marginLeft: '0.5em',
                            marginRight: '0.5em',
                        },
                        ...textStyle,
                    }}>
                        {name}
                    </CustomText>
                </div>
                <RightIcon />
            </button>
        </div>
    )

    function LeftIcon() {
        if (loading) {
            return (
                <CircularProgress
                    style={{
                        ...{ marginTop: 4, color: THEME.primaryColor },
                        ...loading && { color: THEME.white }
                    }}
                    size={'0.8333333333333334vw'}
                />
            )
        }
        if (leftIcon) {
            return leftIcon;
        }
        if (rightIcon && leftIcon == null) {
            return <></>;
        }
        return null;
    }

    function RightIcon() {
        if (leftIcon && rightIcon == null) {
            return <></>;
        }
        if (rightIcon) {
            return rightIcon;
        }
        return null;
    }
}

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        width: '100%',
        position: 'relative',
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderRadius: 50,

    },
    text: {
        fontSize: '0.625vw',
        // fontWeight: 'bold',
        fontFamily: FONT.lato,
        color: THEME.white,
        textOverflow: 'ellipsis',

    }
}