import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './backoffice_setup_reducer';
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.companies.details.backOfficeFormatAndSetUp.companyBackOfficeSetup;
export const selectCompanyBackOfficeSetupState = createSelector(select, reducer.selectState);
export const selectSingleCompanyBackOfficeSetupById = (id: string | undefined | null) => createSelector(
    selectCompanyBackOfficeSetupState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleCompanyBackOfficeSetupState(state, id);
        return reducer.selectInitialSingleCompanyBackOfficeSetupState();
    }
);