import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../../apis/back_office_api";
import { ITimeCardsData, AppError, ICreateBatch, ICreateBatchResponse, ITimeCards } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";


export const createBatchThunk = createAsyncThunk<
    ICreateBatchResponse,
    ICreateBatch,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_tab/create_batch/post",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.createBatch(payload);
            return res.data as ICreateBatchResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


export const verifyBatchThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_tab/verify_batch/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.patchVerifyBatch(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const closeBatchThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_tab/close_batch/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.patchCloseBatch(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const deleteBatchThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_tab/delete_batch/delete",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.deleteBatch(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


export const addTimecardstoBatchThunk = createAsyncThunk<
    string,
    { doc: ITimeCardsData },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_tab/add_time_cards/post",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.addTimecardsBatch(payload.doc);
            return res.data as ICreateBatchResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });