import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./manage_deduction_type_thunk";

export interface ManageDeductionState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null,
    }
}

const initialState: ManageDeductionState = {
    create: {
        error: null,
        loading: LoadingType.idle,
        response: null
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: null
    }
}


export const manageDeductionSlice = createSlice({
    name: 'manageDeductionState',
    initialState: initialState,
    reducers: {
        clearCreateDeductionState(state) {
            return { ...state, create: { ...initialState.create } };
        },
        clearManageDeductionStateError(state) {
            return {
                ...state,
                create: {
                    ...state.create,
                    error: null
                },
                update: {
                    ...state.create,
                    error: null
                }
            };
        },
        clearManageDeductionState(state) {
            return {
                ...state,
                create: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },
                update: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.createDeduction.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createDeduction.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createDeduction.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateDeduction.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateDeduction.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateDeduction.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageDeductionActions = manageDeductionSlice.actions;
export const selectState = (state: ManageDeductionState) => state;
export const selectCreateState = (state: ManageDeductionState) => state.create;
export const selectUpdateState = (state: ManageDeductionState) => state.update;


