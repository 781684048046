import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssignmentsAPI  } from "../../../../apis";
import { AppError, Assignment, IAssignmentApiQuery} from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getAssignmentsList = createAsyncThunk<
    Array<Assignment>,
    IAssignmentApiQuery | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@assignments/universal-directory/all-assignments/get',
    async (query, thunkAPI) => {
        try {
            const res = await AssignmentsAPI.getAllAssignments(query);
            if(typeof res.data === 'string') return [];
            return res.data as Assignment[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);