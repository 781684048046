import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppError, IJobOrder, CompanyData} from "../../../interfaces";
import {JobOrderAPI} from "../../../apis";
import {catchRequestError} from "../../../utils";
import {ContactListItem} from "../../../entities/ContactInfo";
import {contactsByCompany} from "../../../apis/contacts_api";
import {CompaniesAPI} from "../../../apis/companies_api";


export const getJobsListByCompanies = createAsyncThunk<
    Array<IJobOrder>,
    {company_id: string | null, department_id: string | null, contact_id: string | null},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@jobs/universal-directory/job-order-companies/get',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobsByCompany(data);
            if(typeof res.data === 'string') return [];
            return res.data as IJobOrder[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getContactsByCompany = createAsyncThunk<
    Array<ContactListItem>,
    {company_id: string | null, department_id: string | null},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@jobs/universal-directory/company-contacts/get',
    async (data, thunkAPI) => {
        try {
            const res = await contactsByCompany(data);
            return res.data as ContactListItem[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const getCompanyMetaData = createAsyncThunk<
    CompanyData,
    {companyId: string, contact: boolean},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@jobs/universal-directory/company-meta-data/get',
    async (data, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanyMetaData(data);
            return res.data as CompanyData;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const createCompanyJobOrder = createAsyncThunk<
    string,
    any,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@jobs/universal-directory/job-order/post',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.createJobOrder(data);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);