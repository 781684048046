import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../enums";
import { AppError, Profile } from "../../interfaces";
import * as thunk from "./snackbar_thunk";

export interface SnackbarState {
    message: String | null,
    error?: AppError | null,
}

const initialState: SnackbarState = {
    message: null,
    error: null,
}


export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: initialState,
    reducers: {
        clearSnackbarState(state) {
            return { ...state, message: null };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.postSnackbarMessage.pending, (state, action) => ({ ...state, message: null, error: null }))
            .addCase(thunk.postSnackbarMessage.rejected, (state, action) => ({ ...state, error: action.payload, message: null }))
            .addCase(thunk.postSnackbarMessage.fulfilled, (state, action) => ({ ...state, message: action.payload, error: null }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const snackbarActions = snackbarSlice.actions;
export const selectState = (state: SnackbarState) => state;


