import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmployeePortalAPI } from "../../../../apis";
import { ITalentW2, AppError } from "../../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../../utils";
import { S3API } from "../../../../apis/s3_api";

export const getEmployeeW2Thunk = createAsyncThunk<
    ITalentW2[],
    { talent_id: string, year?: number },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee-portal/dashbord/e_docs_certifications/w2/get',
    async (doc, thunkAPI) => {
        try {
            const res = await EmployeePortalAPI.getTalentW2(doc.talent_id, doc.year);
            if (typeof res.data === 'string') return [];
            return res.data as ITalentW2[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getW2UploadURLThunk = createAsyncThunk<
    { url: string },
    { talent_id: string, year: number },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee-portal/dashbord/e_docs_certifications/w2/upload-url/get',
    async (doc, thunkAPI) => {
        try {
            const res = await EmployeePortalAPI.getW2UploadURL(doc.talent_id, doc.year);
            if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const uploadW2FileThunk = createAsyncThunk<
    any,
    { url: string, file: File | Blob },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee-portal/dashbord/e_docs_certifications/w2/upload-file/put',
    async (doc, thunkAPI) => {
        try {
            const res = await S3API.uploadFile(doc.url, doc.file);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);