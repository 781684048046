import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectCustomersData, selectCandidatesData, selectContactsData } from "./dashboard-engagement-details-reducer";

const select = (state: IRootState) => state.dashboard.metrics.dashboardEngagement;
export const selectDashboardEngagementListState = createSelector(select,selectState);
export const selectDashboardEngagementCustomers = createSelector(select,selectCustomersData);
export const selectDashboardEngagementContacts = createSelector(select,selectContactsData);
export const selectDashboardEngagementCandidates = createSelector(select,selectCandidatesData);


