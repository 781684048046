import { combineReducers } from "@reduxjs/toolkit";
import { KinisoJobOrdersSlice } from "./kiniso_job_orders/kiniso_job_orders_reducer";
import { kinisoJobOrderDetailsSlice } from "./kiniso_job_order_details/kiniso_job_order_details_reducer";



export const kinisoJobOrdersReducers = combineReducers({
    [KinisoJobOrdersSlice.name]: KinisoJobOrdersSlice.reducer,
    [kinisoJobOrderDetailsSlice.name]: kinisoJobOrderDetailsSlice.reducer,

});

export * from './kiniso_job_orders/kiniso_job_orders_selector';
export * from './kiniso_job_orders/kiniso_job_orders_thunk';

export * from './kiniso_job_order_details/kiniso_job_order_details_selector';
export * from './kiniso_job_order_details/kiniso_job_order_details_thunk';