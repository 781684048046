import { createAsyncThunk } from "@reduxjs/toolkit";
import { IntegrationAPI } from "../../../../../apis/integration_partners_api";
import { AppError } from "../../../../../interfaces";
import { IMasterTaxSetup } from "../../../../../interfaces/master_tax";
import { catchRequestError } from "../../../../../utils";


export const getMasterTaxSetup = createAsyncThunk<
    IMasterTaxSetup,
    void,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@admin_center/integrations/master_tax/setup/get",
    async (_, thunkAPI) => {
        try {
            const res = await IntegrationAPI.getMasterTaxConfig();
            return res.data as IMasterTaxSetup;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const updateMasterTaxSetup = createAsyncThunk<
    string,
    IMasterTaxSetup,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@admin_center/integrations/master_tax/setup/post",
    async (payload, thunkAPI) => {
        try {
            const res = await IntegrationAPI.updateMasterTaxConfig(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const exportCompanySetupThunk = createAsyncThunk<
    { url: string },
    void,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@admin_center/integrations/master_tax/export_company_setup/get",
    async (payload, thunkAPI) => {
        try {
            const res = await IntegrationAPI.getMasterExportCompanySetup();
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });