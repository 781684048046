import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ICompanySalesProfile, ICompanyServiceProfile, ICompetitors, IShift } from "../../../../interfaces";
import * as thunk from "./company_profile_thunk";

export interface CompanyProfileState extends EntityState<ICompanySalesProfile> {
    error?: AppError | null,
    loading: LoadingType,
    salesProfile: ICompanySalesProfile,
    competitorsData: {
        error?: AppError | null,
        loading: LoadingType,
        data: ICompetitors[],
    }
    save: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    shift_save: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    shift_remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    shiftsData: {
        error?: AppError | null,
        loading: LoadingType,
        data: IShift[],
    },
    serviceProfile: ICompanyServiceProfile
}

const companyProfileListAdapter: EntityAdapter<ICompanySalesProfile> = createEntityAdapter<ICompanySalesProfile>({
    selectId: (doc) => doc.id,
})

const initialState: CompanyProfileState = companyProfileListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    salesProfile: {} as ICompanySalesProfile,
    competitorsData: {
        loading: LoadingType.idle,
        error: null,
        data: []
    },
    save: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    shift_save: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    shift_remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    shiftsData: {
        error: null,
        loading: LoadingType.idle,
        data: [],
    },
    serviceProfile: {} as ICompanyServiceProfile
})

export const companyProfileSlice = createSlice({
    name: 'companyProfileData',
    initialState: initialState,
    reducers: {
        clearState(state){
            state={...initialState};
        },
        clearSaveState(state) {
            state.save = { ...initialState.save,
            loading:LoadingType.idle };
        },
        clearShiftSaveState(state) {
            state.shift_save = { 
                ...initialState.shift_save,
            loading:LoadingType.idle };
        },
        clearRemoveSaveState(state) {
            state.shift_remove = { ...initialState.shift_remove,
                loading:LoadingType.idle };
        },
        clearSalesProfileState(state) {
            state = { ...state, loading: LoadingType.pending, error: null, salesProfile: {} as ICompanySalesProfile };
        },
        clearServiceProfileState(state) {
            state = { ...state, loading: LoadingType.pending, error: null, serviceProfile: {} as ICompanyServiceProfile };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCompanySalesProfile.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getCompanySalesProfile.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCompanySalesProfile.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, salesProfile: action.payload }))

            .addCase(thunk.getCompetitors.pending, (state, action) => ({ ...state, competitorsData: { loading: LoadingType.pending, error: null, data: [] } }))
            .addCase(thunk.getCompetitors.rejected, (state, action) => ({ ...state, competitorsData: { loading: LoadingType.failed, error: action.payload, data: [] } }))
            .addCase(thunk.getCompetitors.fulfilled, (state, action) => ({ ...state, competitorsData: { loading: LoadingType.succeeded, error: null, data: action.payload } }))


            .addCase(thunk._saveCompanySalesProfile.pending, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.pending, error: null } }))
            .addCase(thunk._saveCompanySalesProfile.rejected, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk._saveCompanySalesProfile.fulfilled, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getCompanyServiceProfile.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getCompanyServiceProfile.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCompanyServiceProfile.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, serviceProfile: action.payload }))

            .addCase(thunk._saveCompanyServiceProfile.pending, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.pending, error: null } }))
            .addCase(thunk._saveCompanyServiceProfile.rejected, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk._saveCompanyServiceProfile.fulfilled, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getCompanyShifts.pending, (state, action) => ({ ...state, shiftsData: {...state.shiftsData, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getCompanyShifts.rejected, (state, action) => ({ ...state, shiftsData: {...state.shiftsData, loading: LoadingType.failed, error: action.payload} }))
            .addCase(thunk.getCompanyShifts.fulfilled, (state, action) => ({ ...state, shiftsData: { ...state.shiftsData,loading: LoadingType.succeeded, error: null, data: action.payload } }))

            .addCase(thunk.saveCompanyShift.pending, (state, action) => ({ ...state, shift_save: { ...state.shift_save, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.saveCompanyShift.rejected, (state, action) => ({ ...state, shift_save: { ...state.shift_save, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.saveCompanyShift.fulfilled, (state, action) => ({ ...state, shift_save: { ...state.shift_save, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.deleteCompanyShift.pending, (state, action) => ({ ...state, shift_remove: { ...state.shift_remove, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deleteCompanyShift.rejected, (state, action) => ({ ...state, shift_remove: { ...state.shift_remove, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deleteCompanyShift.fulfilled, (state, action) => ({ ...state, shift_remove: { ...state.shift_remove, loading: LoadingType.succeeded, error: null, response: action.payload } }))



            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageCompanyProfileActions = companyProfileSlice.actions;
export const selectSalesProfile = (state: CompanyProfileState) => state;
export const selectSaveState = (state: CompanyProfileState) => state.save;
export const selectServiceProfile = (state: CompanyProfileState) => state;
export const selectCompetitors = (state: CompanyProfileState) => state.competitorsData;
export const selectShifts = (state: CompanyProfileState) => state.shiftsData;
export const selectRemoveState = (state: CompanyProfileState) => state.shift_remove;
export const selectShiftSaveState=(state: CompanyProfileState) => state.shift_save;
