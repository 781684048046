import { useTranslation } from "react-i18next";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { createTimeCardsThunk, createWeeklyTransactionThunk, getWeekEndingDatesDropdownThunk, getWeeklyTransactionsThunk, selectProfileState, selectTimeCardsDashboardState, selectWeekEndingDatesDDList, selectWeekEndingDatesState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { getDateString } from "../../../../../variables";
import { useEffect, useState } from "react";
import { LoadingType, THEME } from "../../../../../enums";
import { CustomButton, SpinnerScreen, currencyConversion, getPlusBtnIcon } from "../../../../../utils";
import './timecards_dashboard_page.scss';
import CreateTransactionPopup from "../../../popups/create_transaction/create_transaction";
import { Portal } from "react-portal";
import { IWeeklyTransactions } from "../../../../../interfaces";
import { RouteComponentProps } from "react-router-dom";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { timecardsDashboardSliceActions } from "../../../../../redux/back_office/timecards/timecards_dashboard/timecards_dashboard_reducer";
import CreateTransactionAssignmentPopup from "../../../popups/create_transaction_options/create_transaction_assignment";
import { transactionAssignmentSliceActions } from "../../../../../redux/back_office/timecards/assignment_transactions/assignment_transactions_reducer";
import { BulbIcon, PlusIcon, RightArrowIcon } from "../../../../../icons";
import { TransactionStatus } from "../../../../../enums/back_office";
import NoteCard from "../../../../../components/note_card/note_card";
import InfoTextPopup from "../../../popups/infoTextPopup/info_text_popup";
import popupInfoContent from "../../../popup_info_text";



const tableHeader = [
    { title: 'status', code: 'status' },
    { title: 'total_pay', code: 'total_pay' },
    { title: 'total_bill', code: 'total_bill' },
    { title: 'total_transaction_count', code: 'total_transaction_count' },
    { title: 'updated_transaction_count', code: 'updated_transaction_count' },
    { title: 'non_updated_transaction_count', code: 'non_updated_transaction_count' },
    { title: 'created_date', code: 'created_date' },
    { title: 'created_by', code: 'created_by' },
    { title: 'supplier', code: 'supplier' },

];
interface Props extends RouteComponentProps<any> { }

const TimeCardsDashboardPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
        }`;
    // const weekEndingDatesListState = useAppSelector((state) => selectWeekEndingDatesState(state));
    const weekEndingDatesList = useAppSelector((state) => selectWeekEndingDatesDDList(state));
    const weeklyTransactionsState = useAppSelector((state) => selectTimeCardsDashboardState(state));
    const createWeeklyTransactionState = weeklyTransactionsState.create;
    const [selectedWeekDate, setSelectedWeekDate] = useState<{ id: string, date: string }>({ id: "", date: "" });
    const [addTransaction, setAddTransaction] = useState<boolean>(false);
    const [weeklyTransactionsList, setWeeklyTransactionsList] = useState<IWeeklyTransactions[]>([]);

    const [openTimeCardInfoPopup, setOpenTimeCardInfoPopup] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);
    useEffect(() => {
        if (weekEndingDatesList && weekEndingDatesList.length > 0) {
            setSelectedWeekDate({ id: weekEndingDatesList[0].id, date: getDateString(weekEndingDatesList[0].week_ending_date, "mm/dd/yyyy") });
            dispatch(getWeeklyTransactionsThunk(weekEndingDatesList[0].id));
        }
    }, [weekEndingDatesList, weekEndingDatesList.length]);
    useEffect(() => {
        if (weeklyTransactionsState && weeklyTransactionsState.loading === LoadingType.succeeded && weeklyTransactionsState.response) {
            setWeeklyTransactionsList(weeklyTransactionsState.response);
        }
    }, [weeklyTransactionsState, weeklyTransactionsState.loading, weeklyTransactionsState.response]);
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    function getFilteredList() {
        let list;
        if (sortedField != null) {
            list = [...weeklyTransactionsList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (list ?? weeklyTransactionsList);
    }


    const moreOptionsBtnsList = [
        {
            id: "from_assignment",
            label: "create_transaction_assignment",
            icon: PlusIcon,
        },

    ];
    const [selectedMoreOption, setSelectedMoreOption] = useState<string>("");
    const [clickMoreOption, setClickMoreOption] = useState<boolean>(false)
    const handleMoreOptionsClick = (id: string) => {
        if (id === "from_assignment") {
            setClickMoreOption(true);
            setSelectedMoreOption(id);
        }
    }
    function getFlexNumber(value: number) {
        if (value === 0) return 0.5;
        if (value === 1) return 0.7;
        if (value === 2) return 0.7;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1.2;
        if (value === 6) return 0.7;
        if (value === 7) return 0.7;
        if (value === 8) return 0.8;


        return 1;
    }
    const handleWeekEndChange = (val) => {
        const selectedWeekObject = weekEndingDatesList.find(obj => obj.id === val);
        setSelectedWeekDate({ id: val, date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy") });
        dispatch(getWeeklyTransactionsThunk(val));
    }

    const handleCreateTransaction = () => {
        dispatch(createWeeklyTransactionThunk(selectedWeekDate.id))
    }
    const handleCreateTimecard = (doc: string[], weekId: string) => {
        dispatch(createTimeCardsThunk({ week_accounting_id: weekId, doc: doc }))
    }
    const handleSuccessClose = () => {
        setAddTransaction(false);
        dispatch(timecardsDashboardSliceActions.clearCreateState());
        dispatch(getWeeklyTransactionsThunk(selectedWeekDate.id));
    }
    const handleAssignmentsSuccessClose = () => {
        setClickMoreOption(false);
        dispatch(transactionAssignmentSliceActions.clearState());
        dispatch(getWeeklyTransactionsThunk(selectedWeekDate.id));
        setSelectedMoreOption("");
    };

    return (

        <div className="timecards-dashboard-container">
            <div className="timecards-dashboard-header">
                <div className="select-row">
                    <div>
                        <CustomFormSelect
                            list={
                                weekEndingDatesList.map((doc) => ({
                                    label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                    value: doc.id,
                                }))
                            }
                            customStyle={{ width: "100%" }}
                            name={"week_ending_date"}
                            value={selectedWeekDate.id}
                            label={t("week_ending_date")}
                            placeholder={"Select"}
                            onChange={handleWeekEndChange}
                            isClearable={false}
                        />
                    </div>
                    <div>
                        <CustomFormSelect
                            list={
                                moreOptionsBtnsList.map((doc) => ({
                                    label: doc.label,
                                    value: doc.id,
                                    icon: doc.icon
                                }))
                            }
                            customStyle={{ minWidth: "15vw" }}
                            name={""}
                            value={selectedMoreOption}
                            label={t("more_options")}
                            placeholder={"Select"}
                            onChange={(val) => handleMoreOptionsClick(val)}
                        />
                    </div>
                </div>
                <div>
                    <CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("create_transaction")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => setAddTransaction(true)}
                    />
                </div>
            </div>
            <div className="timecards-dashboard-content">
                <div className="timecards-dashboard-table">
                    {weeklyTransactionsState && weeklyTransactionsState.loading === LoadingType.pending ?
                        (<>
                            <SpinnerScreen></SpinnerScreen></>)
                        :
                        (<SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList().map((doc, index) => {
                                return (
                                    <tr key={doc.id}>
                                        <TableData customStyle={{ flex: getFlexNumber(0) }}><span style={{ color: doc.status === TransactionStatus.OPEN ? THEME.statusInActiveColor : THEME.statusDisabledColor }}>{doc.status}</span></TableData>

                                        <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.total_pay
                                            ? currencyConversion(doc.total_pay)
                                            : "$0.00"}</span></TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.total_bill
                                            ? currencyConversion(doc.total_bill)
                                            : "$0.00"}</span></TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{doc.total_transaction_count}</span></TableData>

                                        <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc.updated_transaction_count}</span></TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(5) }}><span>{doc.non_updated_transaction_count}</span></TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(6) }}>{getDateString(doc.created_date)}</TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(7) }}><span>{doc.created_by}</span></TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(8) }}><span>{doc.supplier}</span></TableData>
                                    </tr>
                                );
                            })}
                        </SortableTable>)
                    }
                </div>
                <div className="time-card-note1">
                    <NoteCard
                        icon={<div><RightArrowIcon /><RightArrowIcon /></div>}
                        textLineOne={t("time_cards_section_message_one", { name: currentLoginUserName })}
                        textLineTwo={t("time_cards_section_message_two")}
                        textLineThree={t("time_cards_section_message_three")}
                        confirmBtn={false}
                        customTextColor={"#119646"}
                        customIconBGColor={"#E6FAED"}
                        customCardWidth={"46%"}
                        customCardBGColor={"#FAFFFB"}
                        customTextWidthLineOne={"92%"}
                        customTextWidthLineTwo={"100%"}
                        customTextWidthLineThree={"100%"}
                    />
                </div>

                <div className="time-card-note2">
                    <NoteCard
                        icon={<BulbIcon />}
                        textLineOne={t("need_to_see_timecards_process_flow")}
                        confirmBtn={false}
                        customTextColor={"#074783"}
                        customIconBGColor={"#D9E6F4"}
                        customCardWidth={"82%"}
                        customCardBGColor={"#EAF4FF"}
                        customTextWidthLineOne={"84%"}
                        cursor="pointer"
                        onClick={() => setOpenTimeCardInfoPopup(true)}
                        customFontWeight={'800'}

                    />
                </div>
            </div>
            {addTransaction &&
                <Portal>
                    <CreateTransactionPopup
                        visible={addTransaction}
                        title={t("create_transaction")}
                        onClose={() => { setAddTransaction(false); dispatch(timecardsDashboardSliceActions.clearCreateState()) }}
                        handleCreateTransaction={handleCreateTransaction}
                        createWeeklyTransactionState={createWeeklyTransactionState}
                        handleSuccessClose={handleSuccessClose}
                        currentLoginUserName={currentLoginUserName}
                        handleClearError={() => dispatch(timecardsDashboardSliceActions.clearErrorState())}

                    />
                </Portal>
            }
            {clickMoreOption &&
                <Portal>
                    <CreateTransactionAssignmentPopup
                        visible={clickMoreOption}
                        title={t("create_transaction_assignment")}
                        onClose={() => { setClickMoreOption(false); setSelectedMoreOption("") }}
                        handleCreateTimecard={handleCreateTimecard}
                        handleSuccessClose={handleAssignmentsSuccessClose}
                        currentLoginUserName={currentLoginUserName}
                        handleClearError={() => dispatch(transactionAssignmentSliceActions.clearErrorState())}
                        weekEndingDatesList={weekEndingDatesList}
                    />
                </Portal>

            }
            {openTimeCardInfoPopup &&
                <Portal>
                    <InfoTextPopup
                        visible={openTimeCardInfoPopup}
                        onClose={() => setOpenTimeCardInfoPopup(false)}
                        onDismiss={() => setOpenTimeCardInfoPopup(false)}
                        title={t("time_cards_help")}
                        textContent={popupInfoContent.timeCardsInfo}
                    />
                </Portal>}
        </div>

    );
}

export default TimeCardsDashboardPage;