import { createSelector } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { IRootState } from "../../store";
import { selectState } from "./verify_token_reducer";

const select = (state: IRootState) => state.onboardingPortal.verifyToken;
export const selectOnboardingVerifyTokenState = createSelector(select, selectState);
export const selectOnboardingVerifyTokenLoading = createSelector(
    selectOnboardingVerifyTokenState,
    state => {
        if(state.response === null) return state.loading;
        return LoadingType.succeeded;
     }
)