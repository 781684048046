import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./company_invoices_thunk";
import { AppError, ICompanyInvoices } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

export interface CompanyInvoiceListState {
  error?: AppError | null;
  loading: LoadingType;
  response: ICompanyInvoices[] | null;
  mailInvoices: {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
  }
}

const initialState: CompanyInvoiceListState = {
  error: null,
  loading: LoadingType.idle,
  response: null,
  mailInvoices: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  }
};

export const companyInvoicesSlice = createSlice({
  name: "companyInvoices",
  initialState: initialState,
  reducers: {
    clearState(state) {
      return { ...initialState };
    },
    clearMailState(state) {
      return { ...state, mailInvoices: { ...initialState.mailInvoices } };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getCompanyInvoicesThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getCompanyInvoicesThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.getCompanyInvoicesThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        response: action.payload,
      }))
      .addCase(thunk.mailCompanyInvoicesThunk.pending, (state, action) => ({ ...state, mailInvoices: { ...state.mailInvoices, loading: LoadingType.pending, error: null } }))
      .addCase(thunk.mailCompanyInvoicesThunk.rejected, (state, action) => ({ ...state, mailInvoices: { ...state.mailInvoices, loading: LoadingType.failed, error: action.payload } }))
      .addCase(thunk.mailCompanyInvoicesThunk.fulfilled, (state, action) => ({ ...state, mailInvoices: { ...state.mailInvoices, loading: LoadingType.succeeded, error: null, response: action.payload } }))

      .addDefaultCase((state) => ({ ...state }));
  },
});
export const companyInvoiceListActions = companyInvoicesSlice.actions;
export const selectState = (state: CompanyInvoiceListState) => state;
export const selectAll = (state: CompanyInvoiceListState) => state.response;
