import { useEffect, useRef } from "react";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { CustomButton, FormInput, SpinnerScreen, allowNumbers, convertNumberToMoney, formatToMoney, mapOptions } from "../../../../utils";
import { CompaniesIcon } from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import { useTranslation } from "react-i18next";
import './add_credit_profile.scss';
import { ICompanyBackOfficeCreditProfile, ICompanyBackOfficeSetup } from "../../../../interfaces";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { getDeafaultPaymentOption, selectDeafaultPaymentOptionList } from "../../../../redux/admin_center/back_office";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
interface Props {
    visible: boolean,
    title: string,
    successTitle: string,
    onClose: () => void,
    onDissmiss?: () => void,
    handleFormFieldChange: (fieldId: string, value: any, parent: string) => void;
    creditFormState: ICompanyBackOfficeCreditProfile;
    handleSave: () => void;
    isAddLoading: boolean;
    currentLoginUserName: string;
    onSuccessClose: () => void;
    loading: boolean;
    index: number | undefined;
}

const AddCreditProfilePopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        title,
        successTitle,
        handleFormFieldChange, creditFormState, handleSave, isAddLoading, currentLoginUserName, onSuccessClose, loading, index
    } = props;
    const actionRef = useRef<ActionDialogHolderType>(null);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getDeafaultPaymentOption());
    }, []);
    const deafaultPaymentOptionList = useAppSelector((state) => selectDeafaultPaymentOptionList(state))

    function handleClose() {
        closePopup(onClose)
    }
    function handleWrapperClose(action?: () => void) {
        if (loading) {
            closePopup(props.onSuccessClose);
        } else {
            if (action) closePopup(action);
        }
    }
    const parentField = 'credit_profile';
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"35%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
                <div className="add-credit-profile-container">
                    <div className="add-credit-profile-header">
                        <FormDialogTilteHeader
                            title={loading ? (index !== undefined ? t("update_credit_profile") : successTitle) : (index !== undefined ? t("update_credit_profile") : title)}
                            titleIcon={<CompaniesIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="add-credit-profile-content">
                        {loading ?
                            (<div className="saved-doc-holder">
                                <DocSavedText>
                                    <span className="aknw-name">
                                        {t("well_done_credit_profile", {
                                            name: currentLoginUserName,
                                        })}
                                    </span>
                                    <span>{index !== undefined ? t("you_have_updated_credit_profile") : t("you_have_added_new_credit_profile")}</span>
                                </DocSavedText>
                            </div>)
                            :
                            (
                                <>
                                    <div className="add-credit-profile-input-holder-row">
                                        <div className={"add-credit-profile-input-holder"}>
                                            <FormInput
                                                id={'credit_limit'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(creditFormState.credit_limit, "string") as string}
                                                label={t("credit_limit")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                        <div className={"add-credit-profile-input-holder"}>
                                            <CustomFormSelect
                                                name={t("default_payment_option_id")}
                                                list={mapOptions(deafaultPaymentOptionList, "title", "id")}
                                                onChange={(val) => handleFormFieldChange("default_payment_option_id", val, parentField)}
                                                value={creditFormState.default_payment_option_id}
                                                placeholder={t('select')}
                                                required={false}
                                                label={t('default_payment_option')}
                                            />
                                        </div>
                                    </div>
                                    <div className="add-credit-profile-input-holder-row">
                                        <div className={"add-credit-profile-input-holder"}>
                                            <FormInput
                                                id={'warning_by_dollar'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(creditFormState.warning_by_dollar, "string") as string}
                                                label={t("warning_limit")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                        <div className={"add-credit-profile-input-holder toggle-row"}>
                                            <ToggleSwitch
                                                onChange={(val) => handleFormFieldChange('currently_on_hold', val, parentField)}
                                                label={t("currently_on_hold")}
                                                checked={creditFormState.currently_on_hold!}
                                                labelPosition={'right'}
                                            />
                                        </div>
                                    </div>
                                    <div className="add-credit-profile-input-holder-row">
                                        <div className={"add-credit-profile-input-holder"}>
                                            <FormInput
                                                id={'comment'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={creditFormState.comment}
                                                label={t("comment")}
                                                multiline={true}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    </div>
                    <div className="add-credit-profile-actions">
                        {loading ?
                            <div className="btn-save">
                                <CustomButton
                                    loading={isAddLoading}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("ok")}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => closePopup(onSuccessClose)}
                                    enable={true}
                                />
                            </div>
                            :
                            (
                                <>
                                    <div className="btn-cancel">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("cancel")}
                                            enable
                                            backgroundColor={THEME.secondaryColor4}
                                            onClick={onClose}
                                        />
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={isAddLoading}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("save")}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={handleSave}
                                            enable={true}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>);

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default AddCreditProfilePopup;