import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI, } from "../../../../apis";
import { ServiceCallsTypes } from "../../../../enums";
import { AppError, IServiceCall, } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getDashboardServiceCallsList = createAsyncThunk<
    {
        [ServiceCallsTypes.arrival_calls]: IServiceCall[],
        [ServiceCallsTypes.end_of_first_day_calls]: IServiceCall[],
        [ServiceCallsTypes.end_of_second_day_calls]: IServiceCall[],
        [ServiceCallsTypes.friday_calls]: IServiceCall[],
        [ServiceCallsTypes.four_hour_guarantee]: IServiceCall[],
        [ServiceCallsTypes.end_of_assignment_call]: IServiceCall[],
        [ServiceCallsTypes.incomplete_calls]: IServiceCall[],
    },
    { call_completed: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/service-board/service-calls/get',
    async (query, thunkAPI) => {
        try {
            const res = await DashboardAPI.getDashboardServiceCall(query);
            if (typeof res.data === 'string') return [];
            return res.data as IServiceCall[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);