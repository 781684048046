import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import {
  selectAll,
  selectEntities,
  selectState,
  selectCountState
} from "./online_applications_sent_list_reducer";

const select = (state: IRootState) =>
  state.dashboard.metrics.onlineApplicationSentList;
export const selectOnlineApplicationsSentListState = createSelector(
  select,
  selectState
);
export const selectOnlineApplicationsSentList = createSelector(
  select,
  selectAll
);
export const selectOnlineApplicationsSentEntities = createSelector(
  select,
  selectEntities
);

export const selectOnlineApplicationsSentCountState = createSelector(
  select,
  selectCountState
);
