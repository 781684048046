import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";

export const getTransactionType = createAsyncThunk<
    Array<AdminCenterBackOfficeType>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/back_office/transaction_type/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getTransactionTypeDetails();
            return res.data as AdminCenterBackOfficeType[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createTransactionType = createAsyncThunk<
    string,
    { 'value': string, 'description': string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/transaction_type/post',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.createTransactionTypeDetails(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );

export const updateTransactionType = createAsyncThunk<
    string,
    { 'id': string, 'value': string, 'description': string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/transaction_type/update',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.updateTransactionTypeDetails(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );

export const deleteTransactionType = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/transaction_type/delete',
        async (option_id, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.deleteTransactionTypeDetails(option_id);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );