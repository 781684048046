import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './assignment_list_reducer';

const select = (state: IRootState) => state.talent.details.assignments.assignmentsList;
export const selectTalentAssignmentsState = createSelector(select, reducer.selectTalentAssignmentsState);
export const selectSingleTalentAssignmentsStateById = (id: string | undefined | null) => createSelector(
    selectTalentAssignmentsState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleTalentAssignmentsState(state, id);
        return reducer.selectInitialSingleTalentAssignmentsState();
    }
);