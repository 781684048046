import { Rating } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { COMPANY_TYPE, LoadingType, THEME } from "../../enums";
import {
    useAppSelector,
    useAppDispatch,
    selectCompanyDetailsByIdState,
    updateCompany,
    selectTalentDetailsByIdState,
    updateTalent,
    selectManageTalentUpdateState,
    postSnackbarMessage,
    selectManageCompanyUpdateState,
    selectManageDepartmentUpdateState,
    updateDepartment,
    selectJobDetailsByIdState,
    selectContactDetailsState,
    updateContact,
    selectContactDetailsByIdState,
    selectManageContactUpdateState,
    getContactById,
    selectManageJobUpdateState,
    updateJob,
    selectManageAssignmentUpdateState,
    selectAssignmentDetailsByIdState,
    getAssignmentById
} from "../../redux/store";
import { CustomButton, getStatusFromICreateState } from "../../utils";
import './ratingsDetails.scss';
import { updateAssignment } from "../../redux/assignments/universal_directory/manage_assignment/manage_assignment_thunk";
import { cloneDeep } from 'lodash';
import { manageTalentActions } from "../../redux/talent/universal_directory/manage_talent/manage_talent_reducer";
import { manageAssignmentActions } from "../../redux/assignments/universal_directory/manage_assignment/manage_assignment_reducer";
import { manageCompanyActions } from "../../redux/companies/universal_directory/manage_company/manage_company_reducer";
import { ICompany, IContact, IDepartment } from "../../interfaces";
import { manageDepartmentActions } from "../../redux/companies/universal_directory/manage_department/manage_department_reducer";
import { manageContactActions } from "../../redux/contacts/universal_directory/manage_contact/manage_contact_reducer";
import { manageJobActions } from "../../redux/job_order/universal_directory/manage_job/manage_job_reducer";

interface IRatings {
    id: string;
    departmentId?: string,
    source: string,
    onSuccessCallback?: () => void,
    customStyle?: React.CSSProperties,
}
const RatingsTab = ({ id, departmentId, source, customStyle, onSuccessCallback }: IRatings) => {
    const assignmentState = useAppSelector((state) => selectAssignmentDetailsByIdState(id)(state));
    const assignmentUpdateState = useAppSelector((state) => selectManageAssignmentUpdateState(state))
    const contactState = useAppSelector((state) => selectContactDetailsByIdState(id)(state));
    const contactUpdateState = useAppSelector((state) => selectManageContactUpdateState(state));
    const companyDetailsState = useAppSelector((state) => selectCompanyDetailsByIdState(departmentId ?? id)(state));
    const companyUpdateState = useAppSelector((state) => selectManageCompanyUpdateState(state));
    const jobState = useAppSelector((state) => selectJobDetailsByIdState(id)(state));
    const jobUpdateState = useAppSelector((state) => selectManageJobUpdateState(state));
    const talentDetailsState = useAppSelector((state) => selectTalentDetailsByIdState(id)(state));
    const talentUpdateState = useAppSelector((state) => selectManageTalentUpdateState(state));
    const updateState = useAppSelector((state) => selectManageDepartmentUpdateState(state));
    //const companyDepartments = useAppSelector((state) => selectSingleCompanyDepartmentsStateById(departmentId ?? id)(state));

    const [ratingValue, setRatingValue] = useState<number>(0);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (source === "job") setRatingValue(jobState.data ? jobState.data.rating : 0);
        if (source === "assignment") setRatingValue(assignmentState.data?.performance_rating ?? 0);
        if (source === "contact") setRatingValue(contactState.data?.rating ?? 0);
        if (source === "company") setRatingValue(getCompanyRating() ?? 0);
        if (source === "talent") setRatingValue(talentDetailsState.data?.rating ?? 0);
    }, [
        jobState.data?.rating,
        assignmentState.data?.performance_rating,
        contactState.data?.rating,
        companyDetailsState.data?.rating,
        talentDetailsState.data?.rating
    ]);

    function getCompanyRating() {
        if (companyDetailsState.data?.type === COMPANY_TYPE.company) {
            return (companyDetailsState.data as ICompany).rating ?? 0;
        } else {
            return (companyDetailsState.data as IDepartment).rating ?? 0;
        }
    }

    useEffect(() => {
        if (jobUpdateState.loading === LoadingType.succeeded ||
            contactUpdateState.loading === LoadingType.succeeded ||
            assignmentUpdateState.loading === LoadingType.succeeded ||
            talentUpdateState.loading === LoadingType.succeeded ||
            companyUpdateState.loading === LoadingType.succeeded ||
            updateState.loading === LoadingType.succeeded
        ) {
            dispatch(postSnackbarMessage(getStatusFromICreateState(jobUpdateState.response) ||
                getStatusFromICreateState(assignmentUpdateState.response) ||
                getStatusFromICreateState(talentUpdateState.response) ||
                getStatusFromICreateState(companyUpdateState.response) ||
                getStatusFromICreateState(contactUpdateState.response) ||
                getStatusFromICreateState(updateState.response) ||
                ""));
            // if (source === "job") setRatingValue(jobState.data.rating);
            if (source === "assignment") {
                dispatch(manageAssignmentActions.clearUpdateAssignmentState());
                dispatch(getAssignmentById({ assignment_id: id }));
            }
            if (source === "contact") {
                dispatch(manageContactActions.clearUpdateContactState());
                dispatch(getContactById({ contact_id: id }));
            }
            if (source === "company") {
                dispatch(manageCompanyActions.clearUpdateCompanyState());
                dispatch(manageDepartmentActions.clearUpdateDepartmentState());
            };
            if (source === "talent") dispatch(manageTalentActions.clearUpdateTalentState());
            dispatch(manageJobActions.clearManageJobState([]));
            if (onSuccessCallback) onSuccessCallback();

        }
        return () => { }
    }, [
        jobUpdateState.loading,
        assignmentUpdateState.loading,
        talentUpdateState.loading,
        companyUpdateState.loading,
        contactUpdateState.loading,
        updateState.loading
    ])


    function isBtnLoading() {
        return jobUpdateState.loading === LoadingType.pending ||
            contactUpdateState.loading === LoadingType.pending ||
            assignmentUpdateState.loading === LoadingType.pending ||
            talentUpdateState.loading === LoadingType.pending ||
            companyUpdateState.loading === LoadingType.pending ||
            updateState.loading === LoadingType.pending;
    }

    const handleSaveClick = () => {
        switch (source) {
            case "company":
                if (departmentId) {
                    const deptDetails = cloneDeep(companyDetailsState.data);
                    dispatch(updateDepartment({
                        ...deptDetails,
                        rating: ratingValue
                    }))
                }
                else {
                    const companyDetails = cloneDeep(companyDetailsState.data);
                    dispatch(updateCompany({
                        ...companyDetails,
                        rating: ratingValue,
                    }));
                }
                break;
            case "contact":
                const contactDetails: IContact = cloneDeep(contactState.data);
                dispatch(updateContact({
                    ...contactDetails,
                    rating: ratingValue,
                }))
                break;
            case "talent":
                const talentDetails = cloneDeep(talentDetailsState.data);
                dispatch(updateTalent({
                    ...talentDetails,
                    rating: ratingValue,
                }))
                break;
            case "assignment":
                const assignmentDetails = cloneDeep(assignmentState.data)
                dispatch(updateAssignment({
                    ...assignmentDetails,
                    performance_rating: ratingValue,
                }));
                break;
            case "job":
                if (jobState.data) {
                    dispatch(updateJob({
                        ...jobState.data,
                        rating: ratingValue,
                    }))
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className="rt-ud-container" style={customStyle}>
            <div style={{ textAlign: "center", fontSize: "25px", marginTop: "20px" }}>
                <span style={{ textAlign: "center", fontSize: "25px !important", marginTop: "20px" }}>
                    <Rating
                        name="simple-controlled"
                        value={ratingValue}
                        onChange={(event: any) => {
                            setRatingValue(parseInt(event.target.value))
                        }}
                        size={"large"}
                    />
                </span>
            </div>
            <div className="add-role-action-holder">
                <div className="add-role-action">
                    <div className="btn-yes">
                        <CustomButton
                            loading={isBtnLoading()}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={'Save'}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleSaveClick}
                            enable={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default RatingsTab