import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getAllKinisoJobOrderByIdThunk, selectJobOrderDetailsByIdState } from "../../../redux/kiniso_job_orders";
import { RouteComponentProps } from "react-router-dom";
import { IKinisoJobOrderResponse } from "../../../interfaces/kiniso_job_orders";
import { LoadingType, THEME } from "../../../enums";
import { CalenderIcon, CompaniesIcon, LocationIcon } from "../../../icons";
import { CustomButton, SpinnerScreen } from "../../../utils";
import { getDateString } from "../../../variables";
import "./job_order_details.scss"
import { GlobalRoutes } from "../../../routes";
import * as MatIcons from '@material-ui/icons';

interface Props extends RouteComponentProps<any> { }

const KinisoJobOrderDetailsPage: React.FunctionComponent<Props> = (props) => {

    const { match } = props;
    const { params } = match;
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    useEffect(() => {
        // Find the script element by its widget_id attribute
        const scriptElement = document.querySelector(
          '#freshworks-container'
        );
        // Remove the script element if found
        if (scriptElement) {
          scriptElement.remove();
        }
      }, []);
    const dispatch = useAppDispatch();
    const jobOrderDetailsState = useAppSelector((state) => selectJobOrderDetailsByIdState(params.job_id)(state));
    const [jobOrderDetailsData, setJobOrderDetailsData] = useState<IKinisoJobOrderResponse>();
    useEffect(() => {
        dispatch(getAllKinisoJobOrderByIdThunk({
            token: params.key,
            branch_id: params.branch_id,
            agency_id: params.agency_id,
            job_id: params.job_id
        }));
        return () => { };
    }, []);
    useEffect(() => {
        if (jobOrderDetailsState && jobOrderDetailsState.loading === LoadingType.succeeded && jobOrderDetailsState.data) {
            setJobOrderDetailsData(jobOrderDetailsState.data)
        }
    }, [jobOrderDetailsState, jobOrderDetailsState.loading]);
    const { t } = useTranslation();

    function handleRedirectToJobs() {
        props.history.push({
            pathname: `${GlobalRoutes.kinisoJobOrders}/${params.key}/${params.branch_id}/${params.agency_id}`,
        });
        return;
    }

    const BoldText = ({ text }) => {
        const boldTextRegex = /\*\*(.*?)\*\*/g;
        const parts = text.split("\n");

        return (
            <>
                {parts.map((part, index) => {
                    if (part.match(boldTextRegex)) {
                        return <b key={index} >{part.replace(/\*\*/g, '')}</b>;
                    } else {
                        return <span key={index} style={{ marginBottom: '1rem' }}>{part}</span>;
                    }
                })}
            </>
        );
    };

    if (jobOrderDetailsState.loading === LoadingType.pending) {
        return <SpinnerScreen></SpinnerScreen>
    }
    const isLoading = () => {
        return (
            jobOrderDetailsState?.loading === LoadingType.pending
        );
    };

    const PageLoading = (props) => {
        return (
            <div className="table-loading">
                <SpinnerScreen />
            </div>
        );
    };
    return (
        <>
            <div className="job-order-details-container">
                <div className="job-order-details-content">
                    <div className="job-order-desc-content">
                        <div className="back-div">
                            <CustomButton
                                loading={false}
                                name={t("back_to_jobs")}
                                enable={true}
                                backgroundColor={'transparent'}
                                onClick={handleRedirectToJobs}
                                customClassName="back_btn"
                                leftIcon={(<div className="cds-action-icon"><MatIcons.ArrowBack color={"inherit"} className="back-icon"/></div>)}
                            />
                        </div>
                        {isLoading() &&
                            <PageLoading />}
                        {jobOrderDetailsData !== undefined && jobOrderDetailsData !== null && <div>
                            <div className="job-order-title-header">
                                <div>
                                    <div className="job-title">{jobOrderDetailsData?.job_title}</div>
                                    <div className="comp-loc-div"><div className="label-title"> <CompaniesIcon width={'100%'} height={'100%'} /><span>{jobOrderDetailsData?.hiring_organization}</span></div>
                                        <div className="label-title"><LocationIcon width={'100%'} height={'100%'} /><span>{jobOrderDetailsData?.job_location}</span></div>
                                        <div className="label-title"><CalenderIcon width={'100%'} height={'100%'} /><span>{getDateString(jobOrderDetailsData?.date_posted)}</span></div>
                                    </div>
                                </div>

                                <div className="label-btn">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("apply_now")}
                                        enable={true}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={() => { window.open(jobOrderDetailsData?.link_to_apply, "_blank"); }}
                                    />

                                </div>
                            </div>
                            <div className="label">Description: </div>
                            <div className="description-span"><BoldText text={jobOrderDetailsData?.description} /></div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default KinisoJobOrderDetailsPage;
