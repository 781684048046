import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI,  } from "../../../../apis";
import { AppError, ICompanyEngagement, ITalentEngagement, } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { IContactEngagement } from "../../../../interfaces/contacts_engagement";

export const getDashboardEngagementCustomerDetails = createAsyncThunk<
    Array<any>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/dashboard-engagement/getCustomers',
    async (type, thunkAPI) => {
        try {
            const res = await DashboardAPI.getDashboardEngagementDetailsGetCustomers(type);
            if(typeof res.data === 'string') return [];
            return res.data as ICompanyEngagement[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getDashboardEngagementCandidateDetails = createAsyncThunk<
    Array<any>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/dashboard-engagement/getCandidates',
    async (type, thunkAPI) => {
        try {
            const res = await DashboardAPI.getDashboardEngagementDetailsGetCandidates(type);
            if(typeof res.data === 'string') return [];
            return res.data as ITalentEngagement[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const getDashboardEngagementContactsDetails = createAsyncThunk<
    Array<any>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/dashboard-engagement/getContacts',
    async (type, thunkAPI) => {
        try {
            const res = await DashboardAPI.getDashboardEngagementDetailsGetContacts(type);
            if(typeof res.data === 'string') return [];
            return res.data as IContactEngagement[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);