import { createSlice } from "@reduxjs/toolkit";
import {
  IContactSalesProfile,
  IContactServiceProfile,
} from "../../../entities/ContactInfo";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import {
  getContactSalesProfileThunk,
  getContactServiceProfileThunk,
  saveContactSalesProfileThunk,
  saveContactServiceProfileThunk,
} from "./contact_profile_thunk";

export interface ContactProfileState {
  error?: AppError | null;
  loading: LoadingType;
  salesProfile: IContactSalesProfile;
  save: {
    error?: AppError | null;
    loading: LoadingType;
    response: string;
  };
  serviceProfile: IContactServiceProfile;
}

const salesProfileInitialState: IContactSalesProfile = {
  id: "",
  contact_id: "",
  company_annual_revenue: 0,
  number_of_full_time_staff: 0,
  industry: "",
  uses_staffing_agency: false,
  staffing_agencies: [],
  staffing_agencies_ids: [],
  summary: "",
  profile_mystique: "",
  industry_averages: "",
  additional_notes: ""
};

const initialState: ContactProfileState = {
  error: null,
  loading: LoadingType.idle,
  salesProfile: salesProfileInitialState,
  save: {
    error: null,
    loading: LoadingType.idle,
    response: "",
  },
  serviceProfile: {} as IContactServiceProfile,
};

export const contactProfileSlice = createSlice({
  name: "contactProfileData",
  initialState: initialState,
  reducers: {
    clearState(state) {
      state = { ...initialState };
    },
    clearSaveState(state) {
      state.save = { ...initialState.save };
    },
    clearSalesProfileState(state) {
      state = {
        ...state,
        loading: LoadingType.pending,
        error: null,
        salesProfile: {} as IContactSalesProfile,
      };
    },
    clearServiceProfileState(state) {
      state = {
        ...state,
        loading: LoadingType.pending,
        error: null,
        serviceProfile: {} as IContactServiceProfile,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactSalesProfileThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(getContactSalesProfileThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(getContactSalesProfileThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        error: null,
        salesProfile: action.payload,
      }))

      .addCase(saveContactSalesProfileThunk.pending, (state, action) => ({
        ...state,
        save: { ...state.save, loading: LoadingType.pending, error: null },
      }))
      .addCase(saveContactSalesProfileThunk.rejected, (state, action) => ({
        ...state,
        save: {
          ...state.save,
          loading: LoadingType.failed,
          error: action.payload,
        },
      }))
      .addCase(saveContactSalesProfileThunk.fulfilled, (state, action) => ({
        ...state,
        save: {
          ...state.save,
          loading: LoadingType.succeeded,
          error: null,
          response: action.payload,
        },
      }))

      .addCase(getContactServiceProfileThunk.pending, (state, _action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(getContactServiceProfileThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(getContactServiceProfileThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        error: null,
        serviceProfile: action.payload,
      }))

      .addCase(saveContactServiceProfileThunk.pending, (state, _action) => ({
        ...state,
        save: { ...state.save, loading: LoadingType.pending, error: null },
      }))
      .addCase(saveContactServiceProfileThunk.rejected, (state, action) => ({
        ...state,
        save: {
          ...state.save,
          loading: LoadingType.failed,
          error: action.payload,
        },
      }))
      .addCase(saveContactServiceProfileThunk.fulfilled, (state, action) => ({
        ...state,
        save: {
          ...state.save,
          loading: LoadingType.succeeded,
          error: null,
          response: action.payload,
        },
      }))

      .addDefaultCase((state) => ({ ...state }));
  },
});

export const manageContactProfileActions = contactProfileSlice.actions;
export const selectSalesProfile = (state: ContactProfileState) => state;
export const selectSaveState = (state: ContactProfileState) => state.save;
export const selectServiceProfile = (state: ContactProfileState) => state;
