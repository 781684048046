import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./transaction_type_thunk";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";

export interface TransactionTypeListState extends EntityState<AdminCenterBackOfficeType> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const TransactionTypeListAdapter: EntityAdapter<AdminCenterBackOfficeType> = createEntityAdapter<AdminCenterBackOfficeType>({
    selectId: (doc) => doc.id
})

const initialState: TransactionTypeListState = TransactionTypeListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const TransactionTypeListSlice = createSlice({
    name: 'TransactionTypeList',
    initialState: initialState,
    reducers: {
        clearTransactionTypeListError(state){
            return {...state, error: null};
        },
        clearTransactionTypeListState(state){
            return TransactionTypeListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearTransactionTypeCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearTransactionTypeUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearTransactionTypeDeleteState(state) {
            return { ...state, remove : {...initialState.remove} };
        },
        clearTransactionTypeCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearTransactionTypeUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearTransactionTypeDeleteStateError(state) {
            return { ...state, remove : { ...initialState.remove , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTransactionType.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getTransactionType.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getTransactionType.fulfilled, (state, action) => TransactionTypeListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createTransactionType.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createTransactionType.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createTransactionType.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateTransactionType.pending, (state, action) => ({...state, update: {...state.update, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateTransactionType.rejected, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateTransactionType.fulfilled, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            
            .addCase(thunk.deleteTransactionType.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteTransactionType.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteTransactionType.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const transactionTypeListActions = TransactionTypeListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = TransactionTypeListAdapter.getSelectors();
export const selectState = (state: TransactionTypeListState) => state;
export const selectLoading = (state: TransactionTypeListState) => state.loading;
export const selectError = (state: TransactionTypeListState) => state.error;


