import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType, TALENT_SKILLS_TYPE } from "../../../../../enums";
import { AppError, ISkillOption, ICompany, IDepartment, Profile, IContact } from "../../../../../interfaces";
import * as thunk from "./contact_details_thunk";


interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

interface RemoveState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialRemoveState: RemoveState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


/// [Job Matches]
// export interface SingleCompanyJobMatchesState {
//     data: Array<ITalentJobMatch> | null,
//     error?: AppError | null,
//     loading: LoadingType,
//     add: AddState,
//     remove: RemoveState,
// }


// const singleCompanyJobMatchesInitialState: SingleCompanyJobMatchesState = {
//     data: null,
//     error: null,
//     loading: LoadingType.idle,
//     add: initialAddState,
//     remove: initialRemoveState,
// }

// /// [Company Matches]
// export interface SingleCompanyMatchesState {
//     data: Array<ITalentCompanyMatch> | null,
//     error?: AppError | null,
//     loading: LoadingType,
//     add: AddState,
//     remove: RemoveState,
// }


// const singleTalentCompanyMatchesInitialState: SingleCompanyMatchesState = {
//     data: null,
//     error: null,
//     loading: LoadingType.idle,
//     add: initialAddState,
//     remove: initialRemoveState,
// }

/// [Skills]
export interface SingleContactSkillsState {
    data: Array<ISkillOption> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}



const singleContactSkillsInitialState: SingleContactSkillsState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}

export interface SingleContactRoleUsersState {
    data: Array<Profile> | null,
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    remove: RemoveState,
}

const singleContactRoleUsersInitialState: SingleContactRoleUsersState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    add: initialAddState,
    remove: initialRemoveState,
}



export interface SingleContactState {
    error?: AppError | null,
    loading: LoadingType,
    data: IContact | null,
    // jobMatches: SingleCompanyJobMatchesState,
    // companyMatches: SingleCompanyMatchesState,
    [TALENT_SKILLS_TYPE.professional_skills]: SingleContactSkillsState,
    [TALENT_SKILLS_TYPE.credentials]: SingleContactSkillsState,
    [TALENT_SKILLS_TYPE.certifications]: SingleContactSkillsState,
    [TALENT_SKILLS_TYPE.education]: SingleContactSkillsState,
    [TALENT_SKILLS_TYPE.safety_equipment]: SingleContactSkillsState,
    [TALENT_SKILLS_TYPE.account_managers]: SingleContactRoleUsersState,
    [TALENT_SKILLS_TYPE.recruiters]: SingleContactRoleUsersState,
}


export interface ContactDetailsState {
    [key: string]: SingleContactState,
}


const initialState: ContactDetailsState = {}


function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

function getResponseSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}

export const contactDetailsSlice = createSlice({
    name: 'contactDetails',
    initialState: initialState,
    reducers: {
        clearContactDetailsAddOptionError(state, action: PayloadAction<{id: string, type: string}>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        add: {
                            ...state[action.payload.id][action.payload.type].add,
                            ...initialAddState,
                        },

                    },
                }
            };
        },

        clearContactDetailsRemoveOptionError(state, action: PayloadAction<{id: string, type: string}>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        remove: {
                            ...state[action.payload.id][action.payload.type].remove,
                            ...initialRemoveState,
                        },

                    },
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getContactById.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...getLoadingState(state[action.meta.arg.contact_id]),
                        // jobMatches: singleCompanyJobMatchesInitialState,
                        // companyMatches: singleTalentCompanyMatchesInitialState,
                        [TALENT_SKILLS_TYPE.professional_skills]: singleContactSkillsInitialState,
                        [TALENT_SKILLS_TYPE.credentials]: singleContactSkillsInitialState,
                        [TALENT_SKILLS_TYPE.certifications]: singleContactSkillsInitialState,
                        [TALENT_SKILLS_TYPE.education]: singleContactSkillsInitialState,
                        [TALENT_SKILLS_TYPE.safety_equipment]: singleContactSkillsInitialState,
                        [TALENT_SKILLS_TYPE.account_managers]: singleContactRoleUsersInitialState,
                        [TALENT_SKILLS_TYPE.recruiters]: singleContactRoleUsersInitialState,
                    }
                })
            )
            .addCase(
                thunk.getContactById.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...getErrorState(state[action.meta.arg.contact_id], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getContactById.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...getSuccessState(state[action.meta.arg.contact_id], action.payload),
                    }
                })
            )


            /// Get all skillls
            .addCase(
                thunk.getContactAllSkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getLoadingState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.professional_skills]),

                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getLoadingState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.credentials]),

                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getLoadingState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.certifications]),

                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getLoadingState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.education]),

                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getLoadingState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.safety_equipment]),
                        },
                    }
                })
            )
            .addCase(
                thunk.getContactAllSkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getErrorState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.professional_skills], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getErrorState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.credentials], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getErrorState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.certifications], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getErrorState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.education], action.payload),

                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getErrorState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.safety_equipment], action.payload),
                        },
                    }
                })
            )
            .addCase(
                thunk.getContactAllSkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.professional_skills]: {
                            ...getSuccessState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.professional_skills], action.payload[TALENT_SKILLS_TYPE.professional_skills]),
                        },
                        [TALENT_SKILLS_TYPE.credentials]: {
                            ...getSuccessState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.credentials], action.payload[TALENT_SKILLS_TYPE.credentials]),
                        },
                        [TALENT_SKILLS_TYPE.certifications]: {
                            ...getSuccessState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.certifications], action.payload[TALENT_SKILLS_TYPE.certifications]),
                        },
                        [TALENT_SKILLS_TYPE.education]: {
                            ...getSuccessState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.education], action.payload[TALENT_SKILLS_TYPE.education]),
                        },
                        [TALENT_SKILLS_TYPE.safety_equipment]: {
                            ...getSuccessState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.safety_equipment], action.payload[TALENT_SKILLS_TYPE.safety_equipment]),
                        },
                    }
                })
            )

            /// Skills list [DELETE]
            .addCase(
                thunk.removeContactSkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.contact_id][action.meta.arg.value.skill_type],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.contact_id][action.meta.arg.value.skill_type].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeContactSkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.contact_id][action.meta.arg.value.skill_type],
                            remove: {
                                ...getErrorState(state[action.meta.arg.contact_id][action.meta.arg.value.skill_type].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeContactSkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.contact_id][action.meta.arg.value.skill_type],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.contact_id][action.meta.arg.value.skill_type].remove, action.payload),
                            },
                        },
                        
                    }
                })
            )

            /// Skills list [POST]
            .addCase(
                thunk.addContactSkills.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.contact_id][action.meta.arg.value.skill_type],
                            add: {
                                ...getLoadingState(state[action.meta.arg.contact_id][action.meta.arg.value.skill_type].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addContactSkills.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.contact_id][action.meta.arg.value.skill_type],
                            add: {
                                ...getErrorState(state[action.meta.arg.contact_id][action.meta.arg.value.skill_type].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addContactSkills.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [action.meta.arg.value.skill_type]: {
                            ...state[action.meta.arg.contact_id][action.meta.arg.value.skill_type],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.contact_id][action.meta.arg.value.skill_type].add, action.payload),
                            },
                        },
                    }
                })
            )


            /// Get all account managers
            .addCase(
                thunk.getContactAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...getLoadingState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers]),

                        },
                    }
                })
            )
            .addCase(
                thunk.getContactAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...getErrorState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers], action.payload),

                        },
                    }
                })
            )
            .addCase(
                thunk.getContactAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...getSuccessState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers], action.payload),
                        },
                    }
                })
            )


            /// AccountManagers list [DELETE]
            .addCase(
                thunk.removeContactAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeContactAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getErrorState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeContactAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers].remove, action.payload),
                            },
                        },
                        
                    }
                })
            )

            /// Account managers list [POST]
            .addCase(
                thunk.addContactAccountManagers.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getLoadingState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addContactAccountManagers.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getErrorState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addContactAccountManagers.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.account_managers]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.account_managers].add, action.payload),
                            },
                        },
                    }
                })
            )


            /// Get all account managers
            .addCase(
                thunk.getContactRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getLoadingState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters]),

                        },
                    }
                })
            )
            .addCase(
                thunk.getContactRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getErrorState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters], action.payload),

                        },
                    }
                })
            )
            .addCase(
                thunk.getContactRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...getSuccessState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters], action.payload),
                        },
                    }
                })
            )


            /// Recruiters list [DELETE]
            .addCase(
                thunk.removeContactRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getLoadingState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters].remove),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeContactRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getErrorState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters].remove, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.removeContactRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters],
                            remove: {
                                ...getResponseSuccessState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters].remove, action.payload),
                            },
                        },
                        
                    }
                })
            )

            /// Account managers list [POST]
            .addCase(
                thunk.addContactRecruiters.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getLoadingState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters].add),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addContactRecruiters.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getErrorState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters].add, action.payload),
                            },
                        },
                    }
                })
            )
            .addCase(
                thunk.addContactRecruiters.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...state[action.meta.arg.contact_id],
                        [TALENT_SKILLS_TYPE.recruiters]: {
                            ...state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters],
                            add: {
                                ...getResponseSuccessState(state[action.meta.arg.contact_id][TALENT_SKILLS_TYPE.recruiters].add, action.payload),
                            },
                        },
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const contactDetailsSliceActions = contactDetailsSlice.actions;
export const selectState = (state: ContactDetailsState) => state;
export const selectContactState = (state: ContactDetailsState, id: string): SingleContactState => state[id];
// export const selectContactJobMatchesState = (state: SingleContactState): SingleCompanyJobMatchesState => state.jobMatches;
// export const selectContactCompanyMatchesState = (state: SingleContactState): SingleCompanyMatchesState => state.companyMatches;
export const selectContactSkillsState = (state: SingleContactState): SingleContactSkillsState => state.professional_skills;
export const selectContactCredentialsState = (state: SingleContactState): SingleContactSkillsState => state.credentials;
export const selectContactCertificationsState = (state: SingleContactState): SingleContactSkillsState => state.certifications;
export const selectContactEducationState = (state: SingleContactState): SingleContactSkillsState => state.education;
export const selectContactEquipmentState = (state: SingleContactState): SingleContactSkillsState => state.safety_equipment;

export const selectInitialTalentState = (): SingleContactState => ({
    data: null,
    loading: LoadingType.pending,
    error: null,
    // jobMatches: singleCompanyJobMatchesInitialState,
    // companyMatches: singleTalentCompanyMatchesInitialState,
    [TALENT_SKILLS_TYPE.professional_skills]: singleContactSkillsInitialState,
    [TALENT_SKILLS_TYPE.credentials]: singleContactSkillsInitialState,
    [TALENT_SKILLS_TYPE.certifications]: singleContactSkillsInitialState,
    [TALENT_SKILLS_TYPE.education]: singleContactSkillsInitialState,
    [TALENT_SKILLS_TYPE.safety_equipment]: singleContactSkillsInitialState,
    [TALENT_SKILLS_TYPE.account_managers]: singleContactRoleUsersInitialState,
    [TALENT_SKILLS_TYPE.recruiters]: singleContactRoleUsersInitialState,
});


