import { KeyboardBackspace } from "@material-ui/icons";
import { EditIcon } from "../../../../icons";
import { CustomButton, SpinnerScreen, getPlusBtnIcon } from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import MyProfileUserInfoCard from "../../../../components/my_profile_info_card/my_profile_info_card";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { getTeamManagementTeamMembersDetailsById, getTeamManagementTeamsDetails, selectTeamManagementTeamsDetailsState } from "../../../../redux/admin_center/team_management";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import TabOptionsHeader from "../../../../components/tab_options_header/tab_options_header";
import './team_details_page.scss'
import { AppRoutes } from "../../../../routes";
import { Portal } from "react-portal";
import AddTMTeamMember from "../../popups/add_team_member/add_team_member";
import { selectMyProfileTeamsDetailsState } from "../../../../redux/my_profile";
import { clearMyProfileTeamError } from "../../../../redux/my_profile/my_profile_teams/my_profile_teams_details/my_profile_teams_details_reducer";
import AddTeams from "../../popups/add_team/add_team";
import { teamManagementActions } from "../../../../redux/admin_center/team_management/team_services/team_services_reducer";

interface Props extends RouteComponentProps<any> { }

const TeamDetailsListPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showEditTeam, setShowEditTeam] = useState<boolean>(false);
    const [showAddTeamMember, setShowAddTeamMember] = useState<boolean>(false);

    const myProfileTeamsDetailsState = useAppSelector((state) => selectMyProfileTeamsDetailsState(state))
    const teamManagementTeamDetailsState = useAppSelector((state) => selectTeamManagementTeamsDetailsState(state))

    useEffect(() => {
        if (props.match.params?.id) {
            dispatch(getTeamManagementTeamMembersDetailsById(props.match.params?.id))
        }
    }, [])

    useEffect(() => {
        if (teamManagementTeamDetailsState?.UpdateTeam?.loading === LoadingType.succeeded) {
            dispatch(getTeamManagementTeamsDetails())
            dispatch(getTeamManagementTeamMembersDetailsById(props.match.params?.id))
            setShowEditTeam(false)
        }
        if (myProfileTeamsDetailsState?.createMyProfileTeams?.loading === LoadingType.succeeded) {
            dispatch(getTeamManagementTeamsDetails())
            dispatch(getTeamManagementTeamMembersDetailsById(props.match.params?.id))
            setShowAddTeamMember(false)
        }
    }, [teamManagementTeamDetailsState?.UpdateTeam?.loading, myProfileTeamsDetailsState?.createMyProfileTeams?.loading])

    function handleEdit() {
        setShowEditTeam(true)
    }

    function handleBackToTeam() {
        props.history.push({
            pathname: `${AppRoutes.adminCenterTeamManagement}`,
        });
        dispatch(teamManagementActions.clearTeamDetailsListState())

    }

    function handleClearTeamMemberError() {
        setShowAddTeamMember(false)
        dispatch(clearMyProfileTeamError())
    }

    function handleClearTeamError() {
        setShowEditTeam(false)
        dispatch(teamManagementActions.clearTeamManagementError())
        dispatch(teamManagementActions.clearUpdateTeamManagementState())
    }

    function isLoading() {
        return teamManagementTeamDetailsState?.UpdateTeam.loading
    }

    function isError() {
        return teamManagementTeamDetailsState?.UpdateTeam.error
    }
    /// spinner 

    const DetailsLoading = () => {

        return (
            <div className="ac-tm-team-member-loading">
                <SpinnerScreen />
            </div>
        );
    }
    return (
        <div className="team-manage-container">
            <div className="team-manage-header">
                <TabOptionsHeader list={[]} onClose={handleBackToTeam} />
            </div>
            <div className="team-manage-content">
                <div className="team-bt-container">
                    <div className="team-name-content">
                        <div onClick={handleBackToTeam} className="keyboard-backspace">
                            <KeyboardBackspace style={{ marginBottom: "0.3em" }} /><span>{teamManagementTeamDetailsState && teamManagementTeamDetailsState?.TMTeamMembers?.TeamManagementTeamMembers?.name}</span>
                        </div>
                        <div className="team-total-count" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="total-count">
                                <span>{`${t('total_count')}: `} <span className="total-count-number">{teamManagementTeamDetailsState && teamManagementTeamDetailsState?.TMTeamMembers?.TeamManagementTeamMembers?.members_ids?.length}</span> </span>
                            </div>
                        </div>
                    </div>
                    <div className="team-management-update-bt-content">
                        <div className="team-management-update-bt">
                            <button className="icon-btn tdp-edit-icon" onClick={handleEdit}>
                                <EditIcon width={"100%"} height={"100%"} style={{ color: '#fff' }} />
                            </button>
                        </div>
                        <div>
                            <CustomButton
                                leftIcon={getPlusBtnIcon()}
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('add_member')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => setShowAddTeamMember(true)}
                            />
                        </div>
                    </div>
                </div>
                <div className="team-member-section">
                    {teamManagementTeamDetailsState.TMTeamMembers.loading  === LoadingType.pending ? DetailsLoading() : teamManagementTeamDetailsState &&
                        teamManagementTeamDetailsState?.TMTeamMembers?.TeamManagementTeamMembers?.members_details?.map((doc, index) => (
                            <div key={index} className="tms-flex-card">
                                <MyProfileUserInfoCard
                                    teamMemberData={doc}
                                    teamId={doc.id}
                                    onRemove={()=>{}} 
                                    onUpdate={()=>{}}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
            {showAddTeamMember &&
                <Portal>
                    <AddTMTeamMember
                        visible={showAddTeamMember}
                        onClose={handleClearTeamMemberError}
                        onDissmiss={() => setShowAddTeamMember(false)}
                        onSuccessClose={() => setShowAddTeamMember(false)}
                        teamId={props.match.params?.id}
                        MPTeamState={myProfileTeamsDetailsState}
                        TMTeamState={teamManagementTeamDetailsState}
                    />
                </Portal>
            }
            {showEditTeam &&
                <Portal>
                    <AddTeams
                        visible={showEditTeam}
                        onClose={handleClearTeamError}
                        onDissmiss={() => setShowEditTeam(false)}
                        onSuccessClose={() => setShowEditTeam(false)}
                        teamId={props.match.params?.id}
                        error={isError()}
                        loading={isLoading()}
                        TMTeamState={teamManagementTeamDetailsState}
                    />
                </Portal>
            }
        </div>
    )
}

export default TeamDetailsListPage;