import { combineReducers } from "@reduxjs/toolkit";
import { jobsListSlice } from "./jobs_list/jobs_list_reducer";
import { manageJobSlice } from "./manage_job/manage_job_reducer";

export const jobsUniversalDirectoryReducers = combineReducers({
    [jobsListSlice.name]: jobsListSlice.reducer,
    [manageJobSlice.name]: manageJobSlice.reducer,
});

export * from './jobs_list/jobs_list_selector';
export * from './jobs_list/jobs_list_thunk';

export * from './manage_job/manage_job_selector';
export * from './manage_job/manage_job_thunk';