import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { AppError } from "../../../../interfaces";
import { IRCEmployerTax, IReportCenterBOPayload } from "../../../../interfaces/report_center";
import { catchRequestError } from "../../../../utils";

export const getEmployerTaxThunk = createAsyncThunk<
    IRCEmployerTax,
    IReportCenterBOPayload,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/payroll_tax/employer_tax/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getEmployerTax(payload);
            return res.data as IRCEmployerTax;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });