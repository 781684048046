import { useRef, useState } from "react";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../../enums";
import { AppError, ICompanyEdocs } from "../../../../interfaces";
import { CompaniesIcon } from "../../../../icons";
import './add_new_edoc.scss';
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { useTranslation } from "react-i18next";
import { CustomButton, FormInput } from "../../../../utils";
import DragAndDrop from "../../../../components/drag_and_drop/drag_and_drop";
import ApiError from "../../../../components/api_error";


interface Props {
    visible: boolean,
    title: string,
    successTitle: string,
    onClose: () => void,
    onDissmiss?: () => void,
    handleSave: (formState: ICompanyEdocs, selectedFile: File | null) => void;
    currentLoginUserName: string;
    edocAddState: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    }
    company_name: string;
    handleClearError: () => void;
}
const NewEdocPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        title,
        successTitle,
        handleSave, currentLoginUserName, edocAddState, company_name, handleClearError
    } = props;
    const actionRef = useRef<ActionDialogHolderType>(null);
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const loading = edocAddState.loading === LoadingType.succeeded;
    function handleWrapperClose(action?: () => void) {
        if (loading) {
            closePopup(onClose);
        } else {
            if (action) closePopup(action);
        }
    }
    const initialFormState = {
        id: "",
        company_id: "",
        department_id: "",
        document_type: "",
        document_name: "",
        upload_date: 0,
        document_path: "",
        notes: "",
        file_name: ""
    }
    const [formState, setFormState] = useState<ICompanyEdocs>(initialFormState);
    const handleFormFieldChange = (fieldId: string, value: any) => {
        setFormState({ ...formState, [fieldId]: value })
    }

    function validateFileType() {
        return null;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
                <div className="new-edoc-container">
                    <div className="new-edoc-header">
                        <FormDialogTilteHeader
                            title={loading ? t("new_document") : title}
                            titleIcon={<CompaniesIcon width='100%' height="100%" />}
                        />
                    </div>
                    <div className="new-edoc-content">
                        {loading ?
                            (<div className="saved-doc-holder">
                                <DocSavedText>
                                    <div className="aknw-name">
                                        {t("well_done", { name: currentLoginUserName })}
                                    </div>
                                    <div className="acknw-div">{t('you_added_new_doc_cmpny_profile', { cmpny_name: company_name })}</div>
                                </DocSavedText>
                            </div>)

                            :
                            <div className="edoc-form">
                                <div className="edoc-input-holder-row">
                                    <div className={"edoc-ip-holder"}>
                                        <FormInput
                                            id={'document_name'}
                                            onChange={(id, val) => handleFormFieldChange(id, val)}
                                            required={true}
                                            placeholder={""}
                                            type={"text"}
                                            value={formState.document_name}
                                            label={t("document_name")}

                                        />
                                    </div>
                                    <div className={"edoc-ip-holder"}>
                                        <FormInput
                                            id={'document_type'}
                                            onChange={(id, val) => handleFormFieldChange(id, val)}
                                            required={true}
                                            placeholder={""}
                                            type={"text"}
                                            value={formState.document_type}
                                            label={t("document_type")}

                                        />
                                    </div>

                                </div>
                                <div className="edoc-input-holder-row">
                                    <div className={"edoc-ip-holder"}>
                                        <FormInput
                                            id={'notes'}
                                            onChange={(id, val) => handleFormFieldChange(id, val)}
                                            required={false}
                                            placeholder={""}
                                            type={"text"}
                                            value={formState.notes}
                                            label={t("notes")}
                                            multiline={true}
                                        />
                                    </div>
                                    <div className="edoc-ip-holder">
                                        <DragAndDrop
                                            label={t('attach_doc')}
                                            selectedFile={selectedFile}
                                            accept={
                                                "image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            }
                                            onChange={setSelectedFile}
                                            onRemove={() => setSelectedFile(null)}
                                            formValidators={validateFileType}
                                            placeholder={t('upload_here')}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {edocAddState.error && (
                            <div className={"error-section"}>
                                <ApiError message={edocAddState.error.message} onClose={handleClearError} />
                            </div>
                        )}

                    </div>
                    <div className="new-edoc-actions">
                        {loading ?
                            <div className="btn-save">
                                <CustomButton
                                    loading={false}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("ok")}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => closePopup(onClose)}
                                    enable={true}
                                />
                            </div>
                            :
                            (
                                <>
                                    <div className="btn-cancel">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("cancel")}
                                            enable
                                            backgroundColor={THEME.secondaryColor4}
                                            onClick={onClose}
                                        />
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={edocAddState.loading === LoadingType.pending}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("save")}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={() => handleSave(formState, selectedFile)}
                                            enable={selectedFile && formState.document_name && formState.document_name !== "" ? true : false}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    );
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default NewEdocPopup;