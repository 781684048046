import { createAsyncThunk } from "@reduxjs/toolkit";
import { SalesMarketingAPI } from "../../../../apis/sales_marketing_api";
import { AppError, ICompetitor } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const createCompetitor = createAsyncThunk<
  string,
  ICompetitor,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@sales_marketing/competitor_sheet/manage_competitor/create", async (payload, thunkAPI) => {
  try {
    const res = await SalesMarketingAPI.createCompetitor(payload);
    return res.data as string;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});

export const updateCompetitor = createAsyncThunk<
  string,
  ICompetitor,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@sales_marketing/competitor_sheet/manage_competitor/update", async (payload, thunkAPI) => {
  try {
    const res = await SalesMarketingAPI.updateCompetitor(payload);
    return res.data as string;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});
