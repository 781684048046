import { useEffect, useRef, useState } from "react";
import { CustomButton, FormInput, allowNumbers, convertNumberToMoney, formatToMoney } from "../../../../utils"
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../../enums";
import { useTranslation } from "react-i18next";
import './gross_profit_calculator.scss'
import { SuccessProfileIcon } from "../../../../icons";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { createGrossProfitCalculator, getGrossProfitCalculator, selectGrossProfitCalculatorState, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { getWorkCompConfigList, selectWorkCompConfigList } from "../../../../redux/admin_center";
import { ICreateGrossProfitCalculator } from "../../../../interfaces/gross_profit_calculator";
import { GROSS_PROFIT_CALCULATOR } from "../../../../enums/gross_profit_calculator";
import ApiError from "../../../../components/api_error";
import { manageGrossProfitCalculatorActions } from "../../../../redux/job_order/details/gross_profit_calculator/gross_profit_calculator_reducer";
import { selectWorkCompConfigDropdown, selectWorkCompConfigDropdownState } from "../../../../redux/admin_center/work_comp_config_dropdown/work_comp_config_dropdown_selector";
import { getWorkCompConfigDropdown } from "../../../../redux/admin_center/work_comp_config_dropdown/work_comp_config_dropdown_thunk";

interface Props {
    visible: boolean;
    onClose: () => void,
    onDissmiss?: () => void,
}

const initialForm: ICreateGrossProfitCalculator = {
    social_security_tax: 0,
    medicare_tax: 0,
    suta: 0,
    futa: 0,
    extra_burden: 0,
    work_comp_code_id: "",
    pay_rate: 0,
    bill_rate: 0,
    mark_up: 0,
    gross_profit: 0,
    total_burden: 0,
    gross_profit_percentage: 0,
    total_cost_of_good_sold: 0,
    calculate_type: GROSS_PROFIT_CALCULATOR.payRate
}

const GrossProfitCalculatorPopup: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { visible, onClose, onDissmiss } = props
    const actionRef = useRef<ActionDialogHolderType>(null);
    const WorkCompConfigDropdown = useAppSelector((state) => selectWorkCompConfigDropdown(state))
    const GPCTaxDetailsState = useAppSelector((state) => selectGrossProfitCalculatorState(state))
    const [addGrossProfitCalculatorState, setGrossProfitCalculatorState] = useState<ICreateGrossProfitCalculator>(initialForm)

    useEffect(() => {
        dispatch(getWorkCompConfigDropdown());
        dispatch(getGrossProfitCalculator());
    }, []);

    useEffect(() => {
        if (GPCTaxDetailsState.loading === LoadingType.succeeded) {
            setGrossProfitCalculatorState({
                ...addGrossProfitCalculatorState,
                social_security_tax: GPCTaxDetailsState.data?.social_security_tax!,
                medicare_tax: GPCTaxDetailsState.data?.medicare_tax!,
                suta: GPCTaxDetailsState.data?.suta!,
                futa: GPCTaxDetailsState.data?.futa!,
            })
        }
        if (GPCTaxDetailsState.create.loading === LoadingType.succeeded) {
            setGrossProfitCalculatorState(GPCTaxDetailsState?.create?.response!)
        }
    }, [GPCTaxDetailsState.loading, GPCTaxDetailsState.create.loading]);


    function handleFieldChange(fieldId, value) {
        if (fieldId === "calculate_type") {
            setGrossProfitCalculatorState({ ...addGrossProfitCalculatorState, calculate_type: value });
            if (addGrossProfitCalculatorState.calculate_type === "BILL_RATE") {
                setGrossProfitCalculatorState({
                    ...initialForm,
                    social_security_tax: addGrossProfitCalculatorState.social_security_tax ?? 0,
                    medicare_tax: addGrossProfitCalculatorState.medicare_tax ?? 0,
                    suta: addGrossProfitCalculatorState.suta ?? 0,
                    futa: addGrossProfitCalculatorState.futa ?? 0,
                    calculate_type: addGrossProfitCalculatorState.calculate_type === "BILL_RATE" ? GROSS_PROFIT_CALCULATOR.payRate : GROSS_PROFIT_CALCULATOR.billRate,
                });
            }
            else {
                setGrossProfitCalculatorState({
                    ...initialForm,
                    social_security_tax: addGrossProfitCalculatorState.social_security_tax ?? 0,
                    medicare_tax: addGrossProfitCalculatorState.medicare_tax ?? 0,
                    suta: addGrossProfitCalculatorState.suta ?? 0,
                    futa: addGrossProfitCalculatorState.futa ?? 0,
                    calculate_type: addGrossProfitCalculatorState.calculate_type === "PAY_RATE" ? GROSS_PROFIT_CALCULATOR.billRate : GROSS_PROFIT_CALCULATOR.payRate,
                })
            }
        }
        else {
            setGrossProfitCalculatorState({ ...addGrossProfitCalculatorState, [fieldId]: value });
        }
    }
    function handleFieldPercentageChange(fieldId, value) {
        if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
            setGrossProfitCalculatorState({ ...addGrossProfitCalculatorState, [fieldId]: value });
        }
    }

    const handleGrossProfitCalulator = () => {
        const payload: ICreateGrossProfitCalculator = {
            ...addGrossProfitCalculatorState,
            social_security_tax: convertNumberToMoney(addGrossProfitCalculatorState.social_security_tax, 'number') as number,
            medicare_tax: convertNumberToMoney(addGrossProfitCalculatorState.medicare_tax, 'number') as number,
            suta: convertNumberToMoney(addGrossProfitCalculatorState.suta, 'number') as number,
            futa: convertNumberToMoney(addGrossProfitCalculatorState.futa, 'number') as number,
            extra_burden: convertNumberToMoney(addGrossProfitCalculatorState.extra_burden, 'number') as number,
            pay_rate: convertNumberToMoney(addGrossProfitCalculatorState.pay_rate, 'number') as number,
            bill_rate: convertNumberToMoney(addGrossProfitCalculatorState.bill_rate, 'number') as number,
            mark_up: convertNumberToMoney(addGrossProfitCalculatorState.mark_up, 'number') as number,
            gross_profit: convertNumberToMoney(addGrossProfitCalculatorState.gross_profit, 'number') as number,
            total_burden: convertNumberToMoney(addGrossProfitCalculatorState.total_burden, 'number') as number,
            gross_profit_percentage: convertNumberToMoney(addGrossProfitCalculatorState.gross_profit_percentage, 'number') as number,
            total_cost_of_good_sold: convertNumberToMoney(addGrossProfitCalculatorState.total_cost_of_good_sold, 'number') as number,
        }
        dispatch(createGrossProfitCalculator(payload))
    }

    const handleClearError = () => {
        dispatch(manageGrossProfitCalculatorActions.clearCreateGrossProfitCalculatorError())
    }

    const handleClose = () => {
        setGrossProfitCalculatorState(initialForm)
        dispatch(manageGrossProfitCalculatorActions.clearCreateGrossProfitCalculatorState());
        closePopup(onClose);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClose}>
                <FormDialogTilteHeader
                    title={t("gross_profit_calculator")}
                    titleIcon={<SuccessProfileIcon width={"100%"} height={"100%"} />}
                />
                <div className="gpc-form-content">
                    <div className="gpc-form-section">
                        <div className="gpc-form-sub-title">{t("payroll_burden_taxes_work_comp")}</div>
                        <div className="gpc-form-row">
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"social_security_tax"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={addGrossProfitCalculatorState.social_security_tax}
                                    label={t("social_security_tax")}
                                    placeholder=""
                                    disabled
                                />
                            </div>
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"suta"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={addGrossProfitCalculatorState.suta}
                                    label={t("suta")}
                                    placeholder={t("")}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="gpc-form-row">
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"medicare_tax"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={addGrossProfitCalculatorState.medicare_tax}
                                    label={t("medicare_tax")}
                                    placeholder=""
                                    disabled
                                />
                            </div>
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"futa"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={addGrossProfitCalculatorState.futa}
                                    label={t("futa")}
                                    placeholder=""
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="gpc-form-row">
                            <div className="gpc-form-item">
                                <CustomFormSelect
                                    label={t("work_comp")}
                                    name={"work_comp_code_id"}
                                    list={WorkCompConfigDropdown?.map((doc) => ({
                                        label: doc.work_comp_code,
                                        value: doc.id,
                                    }))}
                                    value={addGrossProfitCalculatorState.work_comp_code_id}
                                    onChange={(val) => handleFieldChange("work_comp_code_id", val)}
                                    placeholder={t("select")}
                                />
                            </div>
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"extra_burden"}
                                    onChange={(id, val) => handleFieldPercentageChange(id, val)}
                                    type={"text"}
                                    value={addGrossProfitCalculatorState.extra_burden}
                                    label={t("extra_burden_percentage")}
                                    placeholder=""
                                    customStyle={{ width: "8.6584vw" }}
                                    onKeyUp={(e) => { allowNumbers(e); }}
                                    onKeyDown={(e) => { allowNumbers(e); }}
                                />
                            </div>
                        </div>
                        <div className="gpc-form-row">
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"total_cost_of_good_sold"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={convertNumberToMoney(addGrossProfitCalculatorState.total_cost_of_good_sold, "string") as string}
                                    label={t("total_cost_of_good_sold")}
                                    prefix={<span>$</span>}
                                    placeholder={t("")}
                                    disabled
                                    customStyle={{ width: "9vw" }}
                                    onBlur={formatToMoney}
                                    onKeyDown={formatToMoney}
                                    onKeyUp={formatToMoney}
                                />
                            </div>
                            <div className="gpc-form-item">
                                <div className="gpc-form-radio-btn-title">{t("i_want_to_calculate")}</div>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-required aria-label="option" name="dial_option" value={addGrossProfitCalculatorState.calculate_type}
                                        onChange={(event) => handleFieldChange("calculate_type", event.target.value)}
                                        className="radio-group-div">
                                        <FormControlLabel value={GROSS_PROFIT_CALCULATOR.payRate} control={<Radio />} label={t("pay_rate")} />
                                        <FormControlLabel value={GROSS_PROFIT_CALCULATOR.billRate} control={<Radio />} label={t("bill_rate")} />
                                    </RadioGroup>
                                </FormControl>

                            </div>
                        </div>
                    </div>
                    <div className="gpc-form-section">
                        <div className="gpc-form-sub-title">{t("gross_profit_payrate_billrate_markup")}</div>
                        <div className="gpc-form-row">
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"pay_rate"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={convertNumberToMoney(addGrossProfitCalculatorState.pay_rate, "string") as string}
                                    label={t("pay_rate")}
                                    placeholder=""
                                    disabled={addGrossProfitCalculatorState.calculate_type === GROSS_PROFIT_CALCULATOR.payRate}
                                    prefix={<span>$</span>}
                                    onBlur={formatToMoney}
                                    onKeyDown={formatToMoney}
                                    onKeyUp={formatToMoney}
                                />
                            </div>
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"mark_up"}
                                    onChange={(id, val) => handleFieldPercentageChange(id, val)}
                                    type={"text"}
                                    value={addGrossProfitCalculatorState.mark_up}
                                    label={t("mark_up_percentage")}
                                    placeholder={t("")}
                                    onKeyUp={(e) => { allowNumbers(e); }}
                                    onKeyDown={(e) => { allowNumbers(e); }}
                                />
                            </div>
                        </div>
                        <div className="gpc-form-row">
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"bill_rate"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={convertNumberToMoney(addGrossProfitCalculatorState.bill_rate, "string") as string}
                                    label={t("bill_rate")}
                                    placeholder=""
                                    disabled={addGrossProfitCalculatorState.calculate_type === GROSS_PROFIT_CALCULATOR.billRate}
                                    prefix={<span>$</span>}
                                    onBlur={formatToMoney}
                                    onKeyDown={formatToMoney}
                                    onKeyUp={formatToMoney}
                                />
                            </div>
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"gross_profit"}
                                    onChange={(id, val) => handleFieldChange(id, val)}
                                    type={"text"}
                                    value={convertNumberToMoney(addGrossProfitCalculatorState.gross_profit, "string") as string}
                                    label={t("gross_profit_doller")}
                                    placeholder=""
                                    prefix={<span>$</span>}
                                    onBlur={formatToMoney}
                                    onKeyDown={formatToMoney}
                                    onKeyUp={formatToMoney}
                                />
                            </div>
                        </div>
                        <div className="gpc-form-row">
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"total_burden"}
                                    onChange={(id, val) => handleFieldPercentageChange(id, val)}
                                    type={"text"}
                                    value={addGrossProfitCalculatorState.total_burden}
                                    label={t("total_burden_percentage")}
                                    placeholder={t("")}
                                    disabled
                                    onKeyUp={(e) => { allowNumbers(e); }}
                                    onKeyDown={(e) => { allowNumbers(e); }}
                                />
                            </div>
                            <div className="gpc-form-item">
                                <FormInput
                                    id={"gross_profit_percentage"}
                                    onChange={(id, val) => handleFieldPercentageChange(id, val)}
                                    type={"text"}
                                    value={addGrossProfitCalculatorState.gross_profit_percentage}
                                    label={t("gross_profit_percentage")}
                                    placeholder=""
                                    onKeyUp={(e) => { allowNumbers(e); }}
                                    onKeyDown={(e) => { allowNumbers(e); }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {GPCTaxDetailsState.create.error && <div>
                    <ApiError message={GPCTaxDetailsState.create.error.message} onClose={handleClearError} />
                </div>}
                <div className="gpc-form-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("cancel")}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleClose}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("calculate")}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleGrossProfitCalulator}
                            loading={false}
                        />
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default GrossProfitCalculatorPopup;
