import {createSlice} from "@reduxjs/toolkit";
import {AgencyBranch, AppError} from "../../../../interfaces";
import {LoadingType} from "../../../../enums";
import * as thunk from './agency_branch_thunk'

export interface AgencyBranchDetails {
    error?: AppError | null,
    loading: LoadingType,
    branches: AgencyBranch[] | null
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const initialState: AgencyBranchDetails = {
    error: null,
    loading: LoadingType.idle,
    branches: null,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
}

export const agencyBranchDetailsSlice = createSlice({
    name: "agencyBranchDetails",
    initialState: initialState,
    reducers: {
        clearAgencyDetailsUpdateState(state) {
            return { ...state, create : { ...initialState.create }};
        },
        clearAgencyDetailsCreateState(state) {
            return { ...state, update : { ...initialState.update }};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAgencyBranches.pending, (state, action) => ({...state, loading: LoadingType.pending, error: null}))
            .addCase(thunk.getAgencyBranches.rejected, (state, action) => ({...state, loading: LoadingType.failed, error: action.payload}))
            .addCase(thunk.getAgencyBranches.fulfilled, (state, action) => ({...state, loading: LoadingType.succeeded, branches: action.payload}))

            .addCase(thunk.createAgencyBranch.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null}}))
            .addCase(thunk.createAgencyBranch.rejected, (state, action) => ({...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createAgencyBranch.fulfilled, (state, action) => ({...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateAgencyBranch.pending, (state, action) => ({...state, update: {...state.update, loading: LoadingType.pending, error: null}}))
            .addCase(thunk.updateAgencyBranch.rejected, (state, action) => ({...state, update: {...state.update, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateAgencyBranch.fulfilled, (state, action) => ({...state, update: {...state.update, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({...state}));
    }
});

export const agencyBranchDetailsActions = agencyBranchDetailsSlice.actions;
export const selectState = (state: AgencyBranchDetails) => state;