import { EntityAdapter, EntityState, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import * as TeamServicesDetailsThunk from "./team_services_thunk";
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { TeamManagementTeams } from "../../../../interfaces/team_services";

export interface TeamManagementTeamsDetailsState extends EntityState<TeamManagementTeams> {
    error?: AppError | null,
    loading: LoadingType,
    TMTeamMembers: {
        error?: AppError | null,
        loading: LoadingType,
        TeamManagementTeamMembers?: TeamManagementTeams,
    },
    CreateTeam: {
        error?: AppError | null,
        loading: LoadingType,
        CreateTeam?: string,
    },
    UpdateTeam: {
        error?: AppError | null,
        loading: LoadingType,
        UpdateTeam?: string,
    },
    DeleteTeam: {
        error?: AppError | null,
        loading: LoadingType,
        DeleteTeam?: string,
    }

}

const TeamManagementTeamsDetailsAdapter: EntityAdapter<TeamManagementTeams> = createEntityAdapter<TeamManagementTeams>({
    selectId: (doc) => doc.id
})

const initialState: TeamManagementTeamsDetailsState = TeamManagementTeamsDetailsAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    TMTeamMembers: {
        error: null,
        loading: LoadingType.idle,
        TeamManagementTeamMembers: {} as TeamManagementTeams,
    },
    CreateTeam: {
        error: null,
        loading: LoadingType.idle,
        CreateTeam: "",
    },
    UpdateTeam: {
        error: null,
        loading: LoadingType.idle,
        UpdateTeam: "",
    },
    DeleteTeam: {
        error: null,
        loading: LoadingType.idle,
        DeleteTeam: "",
    }
})



export const TeamManagementTeamsDetailsSlice = createSlice({
    name: 'TeamManagementTeamDetails',
    initialState: initialState,
    reducers: {

        clearCreateTeamManagementState(state) {
            return { ...state, CreateTeam: { ...initialState.CreateTeam } };
        },
        clearUpdateTeamManagementState(state) {
            return { ...state, UpdateTeam: { ...initialState.UpdateTeam } };
        },

        clearTeamManagementError(state) {
            return {
                ...state,
                CreateTeam: {
                    ...state.CreateTeam,
                    error: null
                },
                UpdateTeam: {
                    ...state.UpdateTeam,
                    error: null
                },
                DeleteTeam: {
                    ...state.DeleteTeam,
                    error: null
                },
            }
        },
        clearTeamListState(state) {
            return { ...state, loading: LoadingType.idle, error: null };
        },
        clearTeamDetailsListState(state) {
            return { ...state, TMTeamMembers: { ...initialState.TMTeamMembers } };
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(TeamServicesDetailsThunk.getTeamManagementTeamsDetails.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(TeamServicesDetailsThunk.getTeamManagementTeamsDetails.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.error, }))
            .addCase(TeamServicesDetailsThunk.getTeamManagementTeamsDetails.fulfilled, (state, action) => TeamManagementTeamsDetailsAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(TeamServicesDetailsThunk.getTeamManagementTeamMembersDetailsById.pending, (state, action) => ({ ...state, TMTeamMembers: { ...state.TMTeamMembers, loading: LoadingType.pending, error: null } }))
            .addCase(TeamServicesDetailsThunk.getTeamManagementTeamMembersDetailsById.rejected, (state, action) => ({ ...state, TMTeamMembers: { ...state.TMTeamMembers, loading: LoadingType.failed, error: action.error } }))
            .addCase(TeamServicesDetailsThunk.getTeamManagementTeamMembersDetailsById.fulfilled, (state, action) => ({ ...state, TMTeamMembers: { ...state.TMTeamMembers, loading: LoadingType.succeeded, error: null, TeamManagementTeamMembers: action.payload } }))

            .addCase(TeamServicesDetailsThunk.createTeamManagementTeams.pending, (state, action) => ({ ...state, CreateTeam: { ...state.CreateTeam, loading: LoadingType.pending, error: null } }))
            .addCase(TeamServicesDetailsThunk.createTeamManagementTeams.rejected, (state, action) => ({ ...state, CreateTeam: { ...state.CreateTeam, loading: LoadingType.failed, error: action.error } }))
            .addCase(TeamServicesDetailsThunk.createTeamManagementTeams.fulfilled, (state, action) => ({ ...state, CreateTeam: { ...state.CreateTeam, loading: LoadingType.succeeded, error: null, CreateTeam: action.payload } }))

            .addCase(TeamServicesDetailsThunk.updateTeamManagementTeams.pending, (state, action) => ({ ...state, UpdateTeam: { ...state.UpdateTeam, loading: LoadingType.pending, error: null } }))
            .addCase(TeamServicesDetailsThunk.updateTeamManagementTeams.rejected, (state, action) => ({ ...state, UpdateTeam: { ...state.UpdateTeam, loading: LoadingType.failed, error: action.error } }))
            .addCase(TeamServicesDetailsThunk.updateTeamManagementTeams.fulfilled, (state, action) => ({ ...state, UpdateTeam: { ...state.UpdateTeam, loading: LoadingType.succeeded, error: null, UpdateTeam: action.payload } }))

            .addCase(TeamServicesDetailsThunk.deleteTeamManagementTeams.pending, (state, action) => ({ ...state, DeleteTeam: { ...state.DeleteTeam, loading: LoadingType.pending, error: null } }))
            .addCase(TeamServicesDetailsThunk.deleteTeamManagementTeams.rejected, (state, action) => ({ ...state, DeleteTeam: { ...state.DeleteTeam, loading: LoadingType.failed, error: action.error } }))
            .addCase(TeamServicesDetailsThunk.deleteTeamManagementTeams.fulfilled, (state, action) => ({ ...state, DeleteTeam: { ...state.DeleteTeam, loading: LoadingType.succeeded, error: null, DeleteTeam: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

// export const { clearCreateTeamManagementState, clearUpdateTeamManagementState, clearTeamManagementError } = TeamManagementTeamsDetailsSlice.actions;
export const teamManagementActions = TeamManagementTeamsDetailsSlice.actions;

export const { selectAll, selectEntities, selectIds, selectById } = TeamManagementTeamsDetailsAdapter.getSelectors();
export const selectState = (state: TeamManagementTeamsDetailsState) => state;
export const selectLoading = (state: TeamManagementTeamsDetailsState) => state.loading;
export const selectError = (state: TeamManagementTeamsDetailsState) => state.error;


