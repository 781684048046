import { createAsyncThunk } from "@reduxjs/toolkit";
import { OnlineApplicationAPI } from "../../../apis";
import { AppError, IVerifyTokenResponse, } from "../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../utils";

export const verifyTokenThunk = createAsyncThunk<
    IVerifyTokenResponse,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@online-application/verify-token/post',
    async (token, thunkAPI) => {
        try {
            const res = await OnlineApplicationAPI.verifyToken(token);
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data as IVerifyTokenResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);