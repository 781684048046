import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectCreateState, selectUploadURLState, selectUploadFileState, selectState, selectUpdate } from "./manage_talent_reducer";

const select = (state: IRootState) => state.talent.universalDirectory.manageTalent;
export const selectManageTalentState = createSelector(select, selectState);
export const selectManageTalentCreateState = createSelector(selectManageTalentState, selectCreateState);
export const selectManageTalentUpdateState = createSelector(selectManageTalentState, selectUpdate);

export const selectManageTalentGetUploadURLState = createSelector(select, selectUploadURLState);
export const selectManageTalentUploadFilesState = createSelector(select, selectUploadFileState);
