import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities, selectOpenOrdersState } from "./sales_calls_reducer";

const select = (state: IRootState) => state.dashboard.metrics.salesCallsList;
export const selectDashboardSalesCallListState = createSelector(select, selectState);
export const selectDashboardSalesCallList = createSelector(
    select,
    selectAll
);
export const selectDashboardSalesCallListEntities = createSelector(
    select,
    selectEntities
);

export const selectDashboardOpenOrdersState = createSelector(select, selectOpenOrdersState);
