import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRCPaymentRegister } from "../../../../interfaces/report_center_payroll_tax";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { IReportCenterBOPayload } from "../../../../interfaces/report_center";

export const getPaymentRegisterList = createAsyncThunk<
    IRCPaymentRegister,
    IReportCenterBOPayload,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/payment_register/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getPaymentRegister(payload);
            return res.data as IRCPaymentRegister;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });