import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./invoice_register_thunk";
import { IRCInvoiceRegister } from "../../../../interfaces/report_center_finance_accounting";

export interface InvoiceRegisterListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCInvoiceRegister | null
}

const initialState: InvoiceRegisterListState = {
    error: null,
    loading: LoadingType.idle,
    response: null, 
}

export const invoiceRegisterListSlice = createSlice({
    name: 'invoiceRegisterList',
    initialState: initialState,
    reducers: {
        clearInvoiceRegisterListError(state) {
            return { ...state, error: null };
        },
        clearInvoiceRegisterState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getInvoiceRegisterList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getInvoiceRegisterList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getInvoiceRegisterList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    },
});

export const { clearInvoiceRegisterListError, clearInvoiceRegisterState } = invoiceRegisterListSlice.actions;
export const selectState = (state: InvoiceRegisterListState) => state;


