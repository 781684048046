import { combineReducers } from "@reduxjs/toolkit";
import { archiveApplicationSlice } from "./archive_application/archive_application_reducer";
import { candidateInputRequiredReducers } from "./candidate_input_required";
import { completedThisWeekReducers } from "./completed_this_week";
import { readyForApprovalReducers } from "./ready_for_approval";
import { resendApplicationSlice } from "./resend_application_link/resend_application_link_reducer";
import { resendLinkSlice } from "./resend_link/resend_link_reducer";
import { updateLinkSlice } from "./update_link/update_link_reducer";

export const onboardingReducers = combineReducers({
    "candidateInputRequired": candidateInputRequiredReducers,
    "completedThisWeek": completedThisWeekReducers,
    "readyForApproval": readyForApprovalReducers,
    [updateLinkSlice.name]:  updateLinkSlice.reducer,
    [archiveApplicationSlice.name]: archiveApplicationSlice.reducer,
    [resendLinkSlice.name]: resendLinkSlice.reducer,
    [resendApplicationSlice.name]: resendApplicationSlice.reducer,
});

export * from './candidate_input_required';
export * from './completed_this_week';
export * from './ready_for_approval';

export * from './update_link/update_link_selector';
export * from './update_link/update_link_thunk';

export * from './archive_application/archive_application_selector';
export * from './archive_application/archive_application_thunk';

export * from './resend_application_link/resend_application_link_selector';
export * from './resend_application_link/resend_application_link_thunk';

export * from './resend_link/resend_link_selector';
export * from './resend_link/resend_link_thunk';