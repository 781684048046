
import { useState } from 'react';
import { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LinkIcon, UserIcon } from '../../../../icons';
import { AppError, FormInputOption, ICreateUser, Profile, ProfileRole } from '../../../../interfaces';
import { CustomButton, enforceFormat, formatToPhone, FormInput, RegxPattern } from '../../../../utils';
import { US_states } from "../../../../utils/States_US";
import { rolesList } from '../../../../variables';
import './user_form.scss';
import { useTranslation } from 'react-i18next';
import { THEME } from '../../../../enums';
import ApiError from '../../../../components/api_error';
import RenderInput from '../../../../components/render_input/render_input';


interface Props {
    title: string,
    loading: boolean,
    branchId: string,
    error: AppError | null | undefined,
    user?: Profile,
    onClose: () => void,
    onSubmit: (value: ICreateUser) => void,
    onClearError: () => void,
}

const getUserFormData = (value: Profile): ICreateUser => {
    return {
        first_name: value.first_name ?? '',
        last_name: value.last_name ?? '',
        street_address: value.street_address ?? '',
        city: value.city ?? '',
        state: value.state ?? '',
        country: value.country ?? '',
        zip_code: value.zip_code ?? '',
        email: value.email ?? '',
        phone: value.phone ?? '',
        office_phone: value.office_phone ?? '',
        roles: getUserProfileRoles(value.roles) ?? [],
        job_title: value.job_title,
        show_ssn: value.show_ssn,
        texting: value.texting,
        view_onboarding_data: value.view_onboarding_data,
        search_and_import_data: value.search_and_import_data,
        social_media: value.social_media ?? {
            linkedin_url: null,
            facebook_url: null,
            twitter_url: null,
            pinterest_url: null,
        },
    };
};
const getUserProfileRoles = (value: ProfileRole[]) => {
    return value.map(doc => doc.role);
}

const initialForm: ICreateUser = {
    first_name: '',
    last_name: '',
    street_address: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    email: '',
    phone: '',
    office_phone: '',
    roles: [],
    job_title: '',
    show_ssn: false,
    texting: false,
    view_onboarding_data: false,
    search_and_import_data: false,
    social_media: {
        linkedin_url: null,
        facebook_url: null,
        twitter_url: null,
        pinterest_url: null,
    },

}

const UserForm: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [formState, setFormState] = useState<ICreateUser>(
        props.user ? getUserFormData(props.user) : initialForm);

    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>();

    function handleFieldChange(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setFormState({
                ...formState,
                [parent]: { ...formState[parent], [fieldId]: value },
            });
        } else {
            setFormState({ ...formState, [fieldId]: value });
        }
    }


    const formBasicInformation: (FormInputOption | FormInputOption[])[] = [
        /// 1st row
        [
            {
                field: "first_name",
                type: "text",
                label: 'first_name',
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "last_name",
                type: "text",
                label: 'last_name',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
        ],
        /// 2nd row
        [
            {
                field: "street_address",
                type: "text",
                label: 'street_address',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "city",
                type: "text",
                label: 'city',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "state",
                type: "selector",
                label: 'state',
                secure: false,
                required: false,
                placeholder: "select",
                keyboardtype: '',
                selectorList: US_states.map((doc) => ({
                    label: doc.name,
                    value: doc.code,
                })),
                multi: false,
            },
            {
                field: "zip_code",
                type: "text",
                label: 'zip_code',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
        ],

        /// 3rd row
        [
            {
                field: "email",
                type: "text",
                label: 'email',
                pattern: RegxPattern.email,
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "phone",
                type: "tel",
                label: 'phone',
                secure: false,
                required: false,
                placeholder: "(555) 555 - 5555",
                keyboardtype: '',
                maxLength: 16,
                onKeyUp: formatToPhone,
                onKeyDown: enforceFormat,
            },
            {
                field: "office_phone",
                type: "tel",
                label: 'office',
                secure: false,
                required: false,
                placeholder: "(555) 555 - 5555",
                keyboardtype: '',
                maxLength: 16,
                onKeyUp: formatToPhone,
                onKeyDown: enforceFormat,
            },
        ],
        /// 4th row
        [
            {
                field: "linkedin_url",
                parentField: "social_media",
                type: "text",
                label: 'linked_in',
                labelIcon: getLinkIcon(),
                pattern: RegxPattern.url,
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "facebook_url",
                parentField: "social_media",
                type: "text",
                label: 'facebook',
                labelIcon: getLinkIcon(),
                pattern: RegxPattern.url,
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "twitter_url",
                parentField: "social_media",
                type: "text",
                label: 'twitter',
                labelIcon: getLinkIcon(),
                pattern: RegxPattern.url,
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "pinterest_url",
                parentField: "social_media",
                type: "text",
                label: 'pinterest',
                labelIcon: getLinkIcon(),
                pattern: RegxPattern.url,
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },

        ]
    ];

    const formProfessionalInformation: (FormInputOption | FormInputOption[])[] = [
        [
            /// 5th row
            {
                field: "job_title",
                type: "text",
                label: 'job_title',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "roles",
                type: "selector",
                label: 'user_roles',
                secure: false,
                required: true,
                placeholder: 'select',
                keyboardtype: '',
                selectorList: rolesList.map(doc => ({ ...doc, label: t(doc.label) })),
                multi: true
            },
        ],
        /// 6th row
        [

            {
                field: "show_ssn",
                type: "switch",
                label: 'view_ssn',
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: '',
                labelPosition: 'right'
            },
            {
                field: "texting",
                type: "switch",
                label: 'can_send_text',
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: '',
                labelPosition: 'right'
            },
            {
                field: "view_onboarding_data",
                type: "switch",
                label: 'view_onboarding_data',
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: '',
                labelPosition: 'right'
            },
            {
                field: "search_and_import_data",
                type: "switch",
                label: 'search_and_import_data',
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: '',
                labelPosition: 'right'
            },

        ]

    ];

    function _isFormValid() {
        const { first_name, email, roles, social_media } = formState;

        const { facebook_url, twitter_url, pinterest_url, linkedin_url } =
            social_media;

        if (first_name === '' || email === '') return false;
        if (RegxPattern.username.test(first_name) === false) return false;
        if (RegxPattern.email.test(email) === false) return false;
        if (roles.length == 0) return false;
        if (
            linkedin_url != null &&
            linkedin_url !== "" &&
            RegxPattern.url.test(linkedin_url) === false
        )
            return false;
        if (
            facebook_url != null &&
            facebook_url !== "" &&
            RegxPattern.url.test(facebook_url) === false
        )
            return false;
        if (
            twitter_url != null &&
            twitter_url !== "" &&
            RegxPattern.url.test(twitter_url) === false
        )
            return false;
        if (
            pinterest_url != null &&
            pinterest_url !== "" &&
            RegxPattern.url.test(pinterest_url) === false
        )
            return false;
        return true;
    }

    return (
        <div className="uf-container">
            <div className="uf-header">
                <FormDialogTilteHeader
                    title={props.title}
                    titleIcon={<UserIcon width={"100%"} height={"100%"} />}
                />
            </div>
            <div className="uf-content">
                {getSectionOne()}
                {getSectionTwo()}
                {props.error && <div className={"error-section"}>
                    <ApiError message={props.error.message} onClose={props.onClearError} />
                </div>}
            </div>
            <div className="uf-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('cancel')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={props.onClose}
                    />
                </div>

                <div className="btn-save">
                    <CustomButton
                        loading={props.loading}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('save')}
                        // enable={_isFormValid()}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => {
                            setCheckValidationOnSubmit(true);
                            if (_isFormValid()) {
                                props.onSubmit(formState)
                                setCheckValidationOnSubmit(false);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );


    /// Section one 
    function getSectionOne() {

        return (
            <div className="uf-section-divider">
                <div className="uf-row flx-start">
                    <div className="section-title-txt">
                        <span>{t("basic_information").toLocaleUpperCase()}</span>
                    </div>
                </div>
                {formBasicInformation.map((doc, index) => {
                    if (Array.isArray(doc)) {
                        return (
                            <div key={index + "row"} className="uf-row ">
                                {doc.map((subDoc, subIndex) => {
                                    return (
                                        <RenderInput
                                            key={index + subIndex}
                                            doc={subDoc}
                                            index={subIndex}
                                            formState={formState}
                                            handleFieldChange={handleFieldChange}
                                            formValidators={userFormValidators}
                                            checkValidationOnSubmit={checkValidationOnSubmit}
                                            className={"uf-input-holder"}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }

                    return (
                        <RenderInput
                            key={index}
                            doc={doc}
                            index={index}
                            formState={formState}
                            handleFieldChange={handleFieldChange}
                            formValidators={userFormValidators}
                            checkValidationOnSubmit={checkValidationOnSubmit}
                            className={"uf-input-holder"}
                        />
                    );
                })}

            </div>


        );
    }

    function getSectionTwo() {
        return (
            <div className="uf-section-divider">
                <div className="uf-row flx-start">
                    <div className="section-title-txt">
                        <span>{t("professional_information").toLocaleUpperCase()}</span>
                    </div>
                </div>
                {formProfessionalInformation.map((doc, index) => {
                    if (Array.isArray(doc)) {
                        return (
                            <div key={index + "row"} className="uf-row ">
                                {doc.map((subDoc, subIndex) => {
                                    return (
                                        <RenderInput
                                            key={index + subIndex}
                                            doc={subDoc}
                                            index={subIndex}
                                            formState={formState}
                                            handleFieldChange={handleFieldChange}
                                            formValidators={userFormValidators}
                                            className={"uf-input-holder"}
                                            menuPlacement={'top'}
                                            checkValidationOnSubmit={checkValidationOnSubmit}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }

                    return (
                        <RenderInput
                            key={index}
                            doc={doc}
                            index={index}
                            formState={formState}
                            handleFieldChange={handleFieldChange}
                            formValidators={userFormValidators}
                            className={"uf-input-holder"}
                            checkValidationOnSubmit={checkValidationOnSubmit}
                        />
                    );
                })}
            </div>
        )
    }


    function getLinkIcon() {
        return (
            <div className="uf-link-icon">
                <LinkIcon width={"100%"} height={"100%"} />
            </div>
        );
    }

    function userFormValidators(value: { text: string, field: string }) {
        switch (value.field) {
            case "first_name": {
                if (value.text == '') return t('validators.required');
                if (RegxPattern.username.test(value.text) === false) return t("validators.enterValidName");
                return null;
            }
            case "email": {
                if (!value.text) return t('validators.required');
                if (RegxPattern.email.test(value.text) === false)
                    return t("validators.enterValidEmail");
                return null;
            }
            case "roles": {
                if (Array.isArray(value.text) && value.text.length === 0) return t('validators.required');
                return null;
            }
            case "linkedin_url":
            case "facebook_url":
            case "twitter_url":
            case "pinterest_url": {
                if (!value.text) return null;
                if (RegxPattern.url.test(value.text) === false)
                    return t("validators.enterValidUrl");
                return null;
            }
            case "phone": {
                if (!value.text) return null;
                if (RegxPattern.usPhone.test(value.text) === false)
                    return t("validators.enterValidPhoneNumber");
                return null;
            }
            case "office_phone": {
                if (!value.text) return null;
                if (RegxPattern.usPhone.test(value.text) === false)
                    return t("validators.enterValidPhoneNumber");
                return null;
            }
            case "zip_code": {
                if (value.text === "") return null;
                if (RegxPattern.zipCode.test(value.text) === false)
                    return t("validators.enterValidZipCode");
                return null;
            }
            default: {
                return null;
            }
        }
    }
}

export default UserForm;