import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { catchRequestError } from "../../../../utils";
import { TransactionSummary } from "../../../../interfaces/back_office_payroll";


export const getPRTransactionSummaryThunk = createAsyncThunk<
    TransactionSummary[],
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_transaction_summary/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getTransactionSummary(payload);
            return res.data as TransactionSummary[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


