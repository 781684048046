
import { useTranslation } from "react-i18next";
import React from "react";
import DocSavedText from "../../../../../components/doc_saved_text/doc_saved_text";
interface Props {
  name: string;
}
const TalBODirectDepositStepThreeForm: React.FunctionComponent<Props> = (props) => {
  const { name } = props;
  const { t } = useTranslation();
  return (
    <div className="dshb-soa-f-mark-success-holder">
      <DocSavedText>
        <span className="aknw-name">
          {t("well_done", {
            name: name,
          })}
        </span>
        <span className="info-txt">
          {"Your Direct Deposit(s) are all set!"}
        </span>
      </DocSavedText>
    </div>
  );
};

export default TalBODirectDepositStepThreeForm;
