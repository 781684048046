import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { ICorrectTimeCards, AppError, InvoiceCorrectionQueryParam } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";


export const getCorrectionTimecards = createAsyncThunk<
    ICorrectTimeCards[],
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing_invoices/billing_invoices_report/correction_timecards/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getCorrectedTimecards(payload);
            return res.data as ICorrectTimeCards[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });



export const correctTimecards = createAsyncThunk<
    string,
    InvoiceCorrectionQueryParam,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing_invoices/billing_invoices_report/corrected_timecards/patch",
    async (doc, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.correctInvoice(doc);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });