import { TALENT_STATUS } from "../enums";

export interface ServiceCall {
  id: string;
  service_call: string;
}

export interface IServiceCall {
  id: string;
  assignment_id: string;
  assigned_to_name: string;
  service_call: string;
  start_date: number;
  end_date: number;
  assignment_status: string;
  dm_reached: boolean;
  company_name: string;
  department_name: string;
  talent_name: string;
  contact_name: string;
  call_method: string;
  call_goal: string;
  communicated_with_company: boolean;
  is_decision_maker: boolean;
  date_of_scheduled_call: number;
  date_of_actual_call: number;
  call_result: string;
  is_deleted: boolean;
  enabled: boolean;
  call_completed: boolean;
  modified_by?: string;
  created_by?: string;
}

export class DashboardServiceCalls {
  arrival_calls: ServiceCallInfo[];
  four_hour_guarantee: ServiceCallInfo[];
  end_of_first_day_calls: ServiceCallInfo[];
  end_of_second_day_calls: ServiceCallInfo[];
  end_of_assignment_call: ServiceCallInfo[];
  friday_calls: ServiceCallInfo[];
}

export class ServiceCallInfo {
  assignment_id: string;
  assignment_status: TALENT_STATUS;
  call_completed: boolean;
  call_goal: string;
  call_made_to: string;
  call_made_to_id: string;
  call_method: string;
  call_result: string;
  communicated_with_company: boolean;
  company_id: string;
  company_name: string;
  contact_name: string;
  date_of_actual_call: number;
  date_of_scheduled_call: number;
  department_id: string;
  department_name: string;
  dm_reached: boolean;
  enabled: boolean;
  end_date: number;
  id: string;
  is_decision_maker: boolean;
  is_deleted: boolean;
  service_call: string;
  start_date: number;
  talent_id: string;
  talent_name: string;
  contact_id: string;
  modified_by: string;
  created_by: string;
}

export interface ICreateServiceCall {
  id?: string;
  assignment_id: string;
  service_call: string;
  start_date: number;
  end_date: number;
  assignment_status: string;
  dm_reached: boolean;
  company_name: string;
  department_name: string;
  talent_name: string;
  contact_name: string;
  call_method: string;
  call_goal: string;
  communicated_with_company: boolean;
  is_decision_maker: boolean;
  date_of_scheduled_call: number;
  date_of_actual_call: number;
  call_result: string;
  is_deleted?: boolean;
  enabled: boolean;
  call_completed: boolean;
}
