import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ICompany, IDepartment } from "../../../../interfaces";
import * as thunk from "./companies_list_thunk";

export interface CompaniesListState extends EntityState<ICompany | IDepartment> {
    error?: AppError | null,
    loading: LoadingType,
}

const companiesListAdapter: EntityAdapter<ICompany | IDepartment> = createEntityAdapter<ICompany | IDepartment>({
    selectId: (doc) => doc.id,
})

const initialState: CompaniesListState = companiesListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const companiesListSlice = createSlice({
    name: 'companiesList',
    initialState: initialState,
    reducers: {
        clearCompaniesListError(state){
            return {...state, error: null};
        },
        clearCompaniesListState(state){
            return companiesListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCompaniesList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getCompaniesList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCompaniesList.fulfilled, (state, action) => companiesListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearCompaniesListError, clearCompaniesListState } = companiesListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = companiesListAdapter.getSelectors();
export const selectState = (state: CompaniesListState) => state;
export const selectLoading = (state: CompaniesListState) => state.loading;
export const selectError = (state: CompaniesListState) => state.error;


