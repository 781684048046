import { createSelector } from "@reduxjs/toolkit";
import { selectBEReportDownloadUrl, selectState } from "./errors_report_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.backOffice.billingInvoice.biErrorReportList;
export const selectBIErrorsReportListState = createSelector(
    select,
    selectState
);
export const selectBERDownloadUrlState = createSelector(
    select,
    selectBEReportDownloadUrl
);
