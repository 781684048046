import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, TrafficLightEngagementMetric } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getTrafficLightEngagementMetrics = createAsyncThunk<
    Array<TrafficLightEngagementMetric>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/traffic_light_engagement_metric/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getTrafficLightEngagementMetrics();
            return res.data as TrafficLightEngagementMetric[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createTrafficLightEngagementMetric = createAsyncThunk<
    string,
    {category: string, type: string, range_from: number, range_to: number | null},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/traffic_light_engagement_metric/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createTrafficLightEngagementMetric(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteTrafficLightEngagementMetric = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/traffic_light_engagement_metric/delete',
    async (method_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteTrafficLightEngagementMetric(method_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateTrafficLightEngagementMetric = createAsyncThunk<
    string,
    {'id': string, category: string, type: string, range_from: number, range_to: number | null},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/traffic_light_engagement_metric/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateTrafficLightEngagementMetric(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);