import { useTranslation } from 'react-i18next';
import MultiUserSection from '../../../../../../components/multi_users_section/multi_users_section';
import {
    useAppSelector,
    selectRoleUsersListState,
    selectAssignmentDetailsByIdState
} from '../../../../../../redux/store';
import { getUserNameFromProfileDropdown, SpinnerScreen } from '../../../../../../utils';
import { LoadingType, ROLE, TALENT_SKILLS_TYPE } from '../../../../../../enums';
import { Spinner } from 'reactstrap';

interface Props {
    assignment_id: string,
    onAddOption: (type: string) => void,
    onRemoveOption: (type: string, value: { id: string, value: string }) => void,
}
const AssignmentStaffDetails: React.FunctionComponent<Props> = (props) => {
    const assignmentState = useAppSelector((state) => selectAssignmentDetailsByIdState(props.assignment_id)(state));
    const roleUsersListState = useAppSelector((state) => selectRoleUsersListState(state));
    const { t } = useTranslation();



    const isLoading = () => {
        return roleUsersListState.loading === LoadingType.pending
    }
    return (
        <div className="cd-aosr-container">
            {isLoading() ? <Spinner /> :
                <>  <MultiUserSection
                    headerTitle={t('account_manager').toUpperCase()}
                    actionName={t('add_account_manager')}
                    onClick={() => { props.onAddOption(TALENT_SKILLS_TYPE.account_managers) }}
                    list={(assignmentState?.account_managers.data ?? []).map((doc, index) => ({ id: doc.id, value: getUserNameFromProfileDropdown(doc) }))}
                    onRemove={(doc) => props.onRemoveOption(TALENT_SKILLS_TYPE.account_managers, doc)}
                />
                    <MultiUserSection
                        headerTitle={t('recruiter').toUpperCase()}
                        actionName={t('add_recruiter')}
                        onClick={() => { props.onAddOption(TALENT_SKILLS_TYPE.recruiters) }}
                        list={(assignmentState?.recruiters.data ?? []).map((doc, index) => ({ id: doc.id, value: getUserNameFromProfileDropdown(doc) }))}
                        onRemove={(doc) => props.onRemoveOption(TALENT_SKILLS_TYPE.recruiters, doc)}
                    />
                </>
            }
        </div>
    );
}

export default AssignmentStaffDetails;