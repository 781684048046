import React, { useEffect, useState } from "react";

import "./company_assignment_page.scss";
import SearchBar from "../../../../components/search_bar";
import { CustomButton, CustomCheckBox } from "../../../../utils";
import { LoadingType, TALENT_STATUS, THEME } from "../../../../enums";
import { AddCircleOutline } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import {
  getTEADraftBroadCast,
  selectProfileState,
  selectTextEmAllState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import {
  getCompanyAssignmentsList,
  selectSingleCompanyAssignmentsStateById,
} from "../../../../redux/companies/details/assignments/";
import TableEmpty, {
  TableErrorHandler,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { AppRoutes } from "../../../../routes";
import { Rating } from "@material-ui/lab";
import {
  currencyConversion,
  getPlusBtnIcon,
  getTableAccessIcon,
  getTextEmAllIcon,
} from "../../../../utils/helpers";
import { Assignment, ITalent } from "../../../../interfaces";
import { Portal } from "react-portal";
import BroadcastToTalentPopup from "../../../../components/broadcast_talent/broadcast_talent";
import { TEADraftBroadCast } from "../../../../interfaces/text_em_all";
import { textEmAllSliceActions } from "../../../../redux/textEmAll/text_Em_All_reducer";
import BroadcastToTalentErrorPopup from "../../../../components/broadcast_talent/broadcast_talent_error";

const category = [
  "talent_name",
  "company_name",
  "department_name",
  "job_title",
  "performance_rating",
  "status",
  "regular_pay_rate",
  "regular_bill_rate",
  // "wc_code"
];

const CompanyAssignmentPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id, departmentId } = props.match.params;

  const profileState = useAppSelector((state) => selectProfileState(state));
  const canSendText = profileState?.profile?.texting && profileState?.profile?.text_em_all_token !== null;

  let assignmentState: any = null;
  assignmentState = useAppSelector((state) =>
    selectSingleCompanyAssignmentsStateById(departmentId ? departmentId : id)(
      state
    )
  );
  const textEmAllState = useAppSelector((state) => selectTextEmAllState(state));

  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [sortedField, setSortedField] = useState<null | string>(null);

  const [sortDirection, setSortDirection] = useState("asc");

  const [sentRequest, setSentRequest] = useState(false);
  const [showInActive, setShowInActive] = useState(false);

  function handleSearchChange(value) {
    setSearchValue(value);
  }

  function handleSortChange(value) {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }

  function getFilteredList() {
    let list;
    if (!assignmentState.data) return [];
    if (sortedField != null) {
      list = [...assignmentState.data].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] != undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }
    let filteredData = (list ?? assignmentState.data).filter((doc) => {
      const str = searchValue.trim().toLowerCase();
      for (let key of category)
        if (doc[key] && String(doc[key]).trim().toLowerCase().includes(str))
          return true;
      return false;
    });
    filteredData = filteredData.filter(
      (doc) =>
        doc.status === TALENT_STATUS.active ||
        (doc.status === TALENT_STATUS.inactive && showInActive)
    );
    setTotalCount(filteredData.length);
    return filteredData;
  }

  function callAPI() {
    let data: any = {
      company_id: id,
      department_id: null,
      contact_id: null,
      talent_id: null,
      job_order_id: null,
      branch_id: null,
    };
    if (departmentId) data = { ...data, department_id: departmentId };
    dispatch(getCompanyAssignmentsList(data));
  }

  function handleRetry() {
    callAPI();
  }

  function handleAccessAssignment(assignment) {
    props.history.push(
      `${AppRoutes.assignmentsDetailsPage}/${assignment.id}/dashboard`
    );
  }

  function handleOnChange(e) {
    setShowInActive(showInActive ? false : true);
  }

  useEffect(() => {
    if (assignmentState.loading === LoadingType.succeeded && sentRequest) {
      setSentRequest(false);
      // setTotalCount(assignmentState.data.length);
    }
  }, [assignmentState.loading]);

  useEffect(() => {
    setSentRequest(true);
    callAPI();
  }, [props.match.params.departmentId]);
  const [broadcastTalent, setBroadcastTalent] = useState<boolean>(false);
  const [broadcastTalentError, setBroadcastTalentError] =
    useState<boolean>(false);
  useEffect(() => {
    dispatch(textEmAllSliceActions.clearState());
    return () => { };
  }, []);
  useEffect(() => {
    if (
      textEmAllState &&
      textEmAllState?.error !== null &&
      textEmAllState?.loading === LoadingType.failed
    ) {
      setBroadcastTalentError(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.succeeded
    ) {
      setBroadcastTalent(true);
    }
    if (
      textEmAllState &&
      textEmAllState?.error === null &&
      textEmAllState?.loading === LoadingType.idle
    ) {
      setBroadcastTalent(false);
      setBroadcastTalentError(false);

    }
  }, [textEmAllState, textEmAllState.error]);
  const [formState] = useState<TEADraftBroadCast>({
    modified_by: "",
    created_by: "",
    modified_date: 0,
    talent_ids: [],
  });
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<
    Map<string, { id: string; name: string }>
  >(new Map());

  const handleMarkItem = (value: Assignment) => {
    const newSet = new Map(selectedItems);
    if (selectedItems.has(value.id)) {
      newSet.delete(value.id);
    } else {
      newSet.set(value.id, { id: value.talent_id, name: value.talent_name });
    }
    setSelectedItems(newSet);
  };
  const handleMarkAllItem = () => {
    const newSet = new Map<string, { id: string; name: string }>();
    if (selectedItems.size === getFilteredList().length) {
      setSelectedItems(newSet);
    } else {
      getFilteredList().forEach((doc) =>
        newSet.set(doc.id, { id: doc.talent_id, name: doc.talent_name })
      );
      setSelectedItems(newSet);
    }
  };
  const handleBroadCastTalent = (forceSend?: boolean) => {
    if (selectedItems.size !== 0) {
      const talentids_list = Array.from(selectedItems.values());
      dispatch(
        getTEADraftBroadCast({ forceSend: forceSend, data: { ...formState, talent_ids: talentids_list.map(ids => ids.id) } })
      );
      // setBroadcastTalent(true);
      setShowMsg(false);
    } else {
      setShowMsg(true);
      setShowErrorMsg(t("please_select_talent_to_broadcast"));
      setTimeout(() => setShowErrorMsg(""), 5000);
    }
  };
  return (
    <div className={"cmp-asmnt-main-container"}>
      <div className={"cmp-asmnt-header"}>
        <div className={"cmp-asmnt-header-left"}>
          <div style={{ width: "250px" }}>
            <SearchBar
              value={searchValue}
              onChange={handleSearchChange}
              onSearch={() => console.log("testing")}
            />
          </div>
          <div className="active-checkbox">
            <input
              type="checkbox"
              checked={showInActive}
              onChange={handleOnChange}
            />
            <span>{t("include_inactive_assignment")}</span>
          </div>
          <div className={"total-count"}>
            <div style={{ marginRight: "3px" }}>{t("count_total")}</div>
            <span>{totalCount}</span>
          </div>
        </div>
        <div>
          <CustomButton
            leftIcon={getTextEmAllIcon()}
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("broadcast_to_talent")}
            enable={canSendText ? true : false}
            onClick={handleBroadCastTalent}
          />
          {showMsg && <span className="select_talent_msg">{showErrorMsg}</span>}
        </div>
      </div>
      <div>
        {assignmentState.loading === LoadingType.pending && sentRequest ? (
          <TableLoading />
        ) : (
          <TableBody
            state={assignmentState}
            getFilteredList={getFilteredList}
            sortedField={sortedField}
            handleSortChange={handleSortChange}
            onRetry={handleRetry}
            data={assignmentState.data}
            handleUserSelect={handleUserSelect}
            handleCompanySelect={handleCompanySelect}
            handleDepartmentSelect={handleDepartmentSelect}
            handleJobSelect={handleJobSelect}
            handleTalentSelect={handleTalentSelect}
            sortDirection={sortDirection}
            handleMarkItem={handleMarkItem}
            handleMarkAllItem={handleMarkAllItem}
            selectedItems={selectedItems}
          />
        )}
      </div>
      {canSendText &&
        broadcastTalentError &&
        textEmAllState &&
        textEmAllState?.error !== null && (
          <Portal>
            <BroadcastToTalentErrorPopup
              error={textEmAllState?.error}
              onClose={() => {
                setBroadcastTalentError(false);
                dispatch(textEmAllSliceActions.clearState());
              }}
              visible={broadcastTalentError}
              title={t("something_went_wrong")}
              loading={textEmAllState?.loading === LoadingType.pending}
              onForceSend={handleBroadCastTalent}
            />
          </Portal>
        )}
      {canSendText && broadcastTalent && (
        <Portal>
          <BroadcastToTalentPopup
            visible={broadcastTalent}
            title={""}
            successTitle={""}
            onClose={() => {
              setBroadcastTalent(false);
              dispatch(textEmAllSliceActions.clearState());
            }}
            onSuccessClose={() => { }}
            responseURL={textEmAllState?.response}
            loading={textEmAllState?.loading === LoadingType.pending}

          />
        </Portal>
      )}
    </div>
  );

  function handleUserSelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.assignmentsDetailsPage}/${value.id}`,
      state: {
        id: value.id,
        name: `${value.talent_name}-${value.job_title}`,
      },
    });
    return;
  }

  function handleCompanySelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
      },
    });
    return;
  }

  function handleDepartmentSelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_id,
        departmentName: value.department_name,
      },
    });
    return;
  }

  function handleJobSelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.jobDetailsPage}/${value.job_order_id}`,
      state: {
        id: value.job_order_id,
        name: value.job_title,
      },
    });
    return;
  }

  function handleTalentSelect(value: Assignment) {
    props.history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
      state: {
        id: value.talent_id,
        name: value.talent_name,
      },
    });
    return;
  }
};

const TableBody = ({
  state,
  getFilteredList,
  sortedField,
  handleSortChange,
  onRetry,
  data,
  handleUserSelect,
  handleCompanySelect,
  handleDepartmentSelect,
  handleJobSelect,
  handleTalentSelect,
  sortDirection,
  handleMarkAllItem,
  handleMarkItem,
  selectedItems,
}) => {
  const { t } = useTranslation();


  const SelectAllCheckBox = () => {
    return (
      <CustomCheckBox
        name={""}
        title={""}
        checked={selectedItems.size === getFilteredList().length}
        onClick={handleMarkAllItem}
      />
    );
  };
  const headers = [
    { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
    { title: "access_assignment", code: "access_assignment" },
    { title: "talent", code: "talent_name" },
    { title: "company", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "job_title", code: "job_title" },
    { title: "rating", code: "performance_rating" },
    { title: "status", code: "status" },
    { title: "pay_rate", code: "regular_pay_rate" },
    { title: "bill_rate", code: "regular_bill_rate" },
    // { 'title': 'wc_code', 'code': 'wc_code' },
    // {'title': 'action', 'code': ''},
  ];

  function getTableData(doc, key) {
    if (key === "performance_rating")
      return (
        <span>
          <Rating name="read-only" value={doc[key]} readOnly />
        </span>
      );
    else if (key === "regular_bill_rate" || key === "regular_pay_rate")
      return <span>{currencyConversion(doc[key])}</span>;
    else if (key === "status") {
      if (doc[key] === TALENT_STATUS.active)
        return (
          <span style={{ color: THEME.statusActiveColor }}>{t("active")}</span>
        );
      return <span style={{ color: "#b1b1b1" }}>{t("inactive")}</span>;
    }
    return <span>{doc[key]}</span>;
  }

  if (state.loading === LoadingType.failed) {
    return <TableErrorHandler error={state.error} onRefresh={onRetry} />;
  } else if (data && data.length === 0) {
    return <TableEmpty title={"No data found"} onClick={onRetry} />;
  }

  return (
    <SortableTable
      headerList={headers}
      sortedField={sortedField}
      onSortChange={handleSortChange}
      flexNumber={getFlexNumber}
      isAsc={sortDirection}
    >
      {getFilteredList().map((doc, index) => {
        return (
          <tr key={"cmp_job_order_row" + index}>
            <TableData customStyle={{ flex: getFlexNumber(0) }}>
              <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.has(doc.id)}
                onClick={() => handleMarkItem(doc)}
              />
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(1) }}>
              {getTableAccessIcon(() => handleUserSelect(doc))}
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(2) }}
              isButton={true}
              onClick={() => handleTalentSelect(doc)}
            >
              {getTableData(doc, "talent_name")}
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(3) }}
              isButton={true}
              onClick={() => handleCompanySelect(doc)}
            >
              {getTableData(doc, "company_name")}
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(4) }}
              // isButton={doc.department_name ? true : false}
              isButton={true}
              onClick={() => handleDepartmentSelect(doc)}
            >
              {doc.department_name
                ? getTableData(doc, "department_name")
                : t("corporate")}
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(5) }}
              isButton={true}
              onClick={() => handleJobSelect(doc)}
            >
              {getTableData(doc, "job_title")}
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(6) }}>
              {getTableData(doc, "performance_rating")}
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(7) }}>
              {getTableData(doc, "status")}
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(8) }}>
              {getTableData(doc, "regular_pay_rate")}
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(9) }}>
              {getTableData(doc, "regular_bill_rate")}
            </TableData>
            {/* <TableData customStyle={{ flex: getFlexNumber(9) }}>{getTableData(doc, "wc_code")}</TableData> */}
          </tr>
        );
      })}
    </SortableTable>
  );

  function getFlexNumber(value: number) {
    if (value === 0) return 0.3;
    if (value === 1) return 1.3;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 1;
    if (value === 8) return 1;
    if (value === 9) return 1;
    if (value === 10) return 1;

    return 1;
  }
};

export default CompanyAssignmentPage;
