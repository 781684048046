import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiError from '../../../../components/api_error';
import { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import DocSavedText from '../../../../components/doc_saved_text/doc_saved_text';
import { LoadingType, THEME } from '../../../../enums';
import { PaperPlaneIcon } from '../../../../icons';
import { AppError, ISendOnlineApplication } from '../../../../interfaces';
import { CustomButton, enforceFormat, formatToPhone, FormInput, RegxPattern } from '../../../../utils';
import './send_online_application_form.scss';

const formOptions = {
    /// 1st row
    name: {
        field: "name",
        type: "text",
        label: 'talent',
        secure: false,
        required: true,
        placeholder: '',
        keyboardtype: ''
    },
    email: {
        field: "email",
        type: "email",
        label: 'email',
        pattern: RegxPattern.email,
        secure: false,
        required: true,
        placeholder: '',
        keyboardtype: ''
    },
    phone_number: {
        field: "phone_number",
        type: "tel",
        label: 'phone_number',
        secure: false,
        required: false,
        placeholder: '(888) 888-8888',
        keyboardtype: '',
        maxLength: 16,
        onKeyUp: formatToPhone,
        onKeyDown: enforceFormat,
        
    },
}

interface Props {
    title: string,
    error?: AppError | null,
    loading: LoadingType,
    currentLoginUserName: string,
    onCancel: () => void,
    onClearError: () => void,
    onSuccessClose: () => void,
    onSubmit: (value: ISendOnlineApplication) => void,
}

const SendOnlineApplicationForm: React.FunctionComponent<Props> = (props) => {
    const [formState, setFormState] = useState<ISendOnlineApplication>({
        name: '',
        email: '',
        phone_number: '',
    });
    const { t } = useTranslation();

    function handleFieldChange(fieldId, value) {
        setFormState({ ...formState, [fieldId]: value });
    }

    function getTitle() {
        if (props.loading === LoadingType.succeeded) return `${t('online_application')}`;
        return `${t('hi_lets_do_some_recruiting', { name: props.currentLoginUserName })}`;
    }


    return (
        <div className="dshb-soa-f-container">
            <div className="dshb-soa-f-header">
                <FormDialogTilteHeader
                    title={getTitle()}
                    subTitle={props.loading === LoadingType.succeeded ? undefined : t('to_whom_would_you_like_to_send_an_application')}
                    titleIcon={<PaperPlaneIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                />
            </div>
            <div className="dshb-soa-f-content">
                {getUI()}
                {props.error && <div className={"error-section"}>
                    <ApiError message={props.error.message} onClose={props.onClearError} />
                </div>}
            </div>
            {getActions()}
        </div>
    );

    function _isFormValid() {
        const { name, email } = formState;
        if (name === '') return false;
        if (RegxPattern.email.test(email) === false) return false;
        return true;
    }

    function getUI() {
        if (props.loading === LoadingType.succeeded) {
            return (
                <div className="dshb-soa-f-mark-success-holder">
                    <DocSavedText>
                        <span className="aknw-name">{t('well_done', { name: props.currentLoginUserName })}</span>
                        <span className="info-txt">{`${t('you_sent_online_application_to')}:`}</span>
                        <span className="name">{formState.name}</span>
                        <span className="email">{formState.email}</span>
                        <span className="phone-number">{formState.phone_number}</span>
                    </DocSavedText>
                </div>
            )
        }

        return (
            <div className="dshb-soa-form">
                <div className="dshb-soa-f-column">
                    <div key={formOptions.name.field} className={"dshb-soa-f-input-holder"}>
                        <FormInput
                            validations={formValidators}
                            id={formOptions.name.field}
                            onChange={handleFieldChange}
                            secure={formOptions.name.secure}
                            required={formOptions.name.required}
                            placeholder={formOptions.name.placeholder}
                            type={formOptions.name.type}
                            value={formState[formOptions.name.field]}
                            label={t(formOptions.name.label)}
                            keyboardtype={formOptions.name?.keyboardtype}
                        />
                    </div>
                    <div key={formOptions.email.field} className={"dshb-soa-f-input-holder"}>
                        <FormInput
                            validations={formValidators}
                            id={formOptions.email.field}
                            onChange={handleFieldChange}
                            secure={formOptions.email.secure}
                            required={formOptions.email.required}
                            placeholder={formOptions.email.placeholder}
                            type={formOptions.email.type}
                            value={formState[formOptions.email.field]}
                            label={t(formOptions.email.label)}
                            keyboardtype={formOptions.email?.keyboardtype}
                        />
                    </div>
                    {/* <div key={formOptions.phone_number.field} className={"dshb-soa-f-input-holder"}>
                        <FormInput
                            validations={formValidators}
                            id={formOptions.phone_number.field}
                            onChange={handleFieldChange}
                            secure={formOptions.phone_number.secure}
                            required={formOptions.phone_number.required}
                            placeholder={formOptions.phone_number.placeholder}
                            type={formOptions.phone_number.type}
                            value={formState[formOptions.phone_number.field]}
                            label={t(formOptions.phone_number.label)}
                            keyboardtype={formOptions.phone_number?.keyboardtype}
                            maxLength={formOptions.phone_number.maxLength}
                            onKeyDown={enforceFormat}
                            onKeyUp={formatToPhone}
                        />
                    </div> */}
                </div>
            </div>
        )
    }


    function getActions() {
        if (props.loading === LoadingType.succeeded) {
            return (
                <div className="dshb-soa-f-actions">
                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('ok')}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={props.onSuccessClose}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className="dshb-soa-f-actions">
                <div className="btn-save">
                    <CustomButton
                        loading={props.loading === LoadingType.pending}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('send')}
                        enable={_isFormValid()}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => props.onSubmit(formState)}
                    />
                </div>
            </div>
        )
    }

    function formValidators(value: { text: string, field: string, }) {
        switch (value.field) {
            case formOptions.name.field: {
                if (value.text == '') return t('validators.required');
                if (RegxPattern.username.test(value.text) === false) return t("validators.enterValidName");
                return null;
            };
            case formOptions.email.field: {
                if (value.text == '') return t('validators.required');
                if (RegxPattern.email.test(value.text) === false) return t('validators.enterValidEmail');
                return null;
            };
            case formOptions.phone_number.field: {
                if (!value.text) return null;
                if (RegxPattern.usPhone.test(value.text) === false) return t('validators.enterValidPhoneNumber');
                return null;
            };
            default: {
                return null;
            }
        }
    }
}

export default SendOnlineApplicationForm;