import { createSelector } from "@reduxjs/toolkit";
import { SuccessProfileQuestions } from "../../../../interfaces";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./success_profile_questions_list_reducer";

const select = (state: IRootState) => state.adminCenter.successProfileQuestionList;
export const selectSuccessProfileQuestionListState = createSelector(select, selectState);
export const selectSuccessProfileQuestionList = createSelector(
    select,
    selectAll
);
export const selectSuccessProfileQuestionsListEntities = createSelector(
    select,
    selectEntities
);

export const selectSuccessProfileQuestionGroupList = createSelector(
    selectSuccessProfileQuestionList,
    (docs) => {
        const result: { [key: number]: SuccessProfileQuestions[] } = docs.reduce(function (r, a) {
            r[a.step_number] = r[a.step_number] || [];
            r[a.step_number].push(a);
            return r;
        }, {});

        return result;
    }
);