import {createAsyncThunk} from "@reduxjs/toolkit";
import {IJobOrderContact} from "../../../../../entities/ContactInfo";
import {AppError} from "../../../../../interfaces";
import {catchRequestError} from "../../../../../utils";
import {getJobOrderContacts} from "../../../../../apis/contacts_api";

export const getContactsByJobOrder = createAsyncThunk<
    Array<IJobOrderContact>,
    {jobOrderId: string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@jobs/details/contacts/contact-list/get',
    async (data, thunkAPI) => {
        try {
            const res = await getJobOrderContacts(data);
            return res.data as IJobOrderContact[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
