import React, { useRef, useState } from "react";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../../components/action_dialog_holder/action_dialog_holder";
import { AppError } from "../../../../../interfaces";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../../components/dialog_wrapper/dialog_wrapper";
import "./job_posting_question_popup.scss";
import { ChatGPTIcon, CopyIcon, QuestionMarkIcon } from "../../../../../icons";
import { useTranslation } from "react-i18next";
import { CustomButton, SpinnerScreen } from "../../../../../utils";
import { THEME } from "../../../../../enums";
interface Props {
  visible: boolean;
  title: string;
  onClose: () => void;
  loading?: boolean;
  error?: AppError | null | undefined;
  question: string;
}
const JobPostingQuestionPopUp: React.FunctionComponent<Props> = (props) => {
  const { visible, onClose, loading, error, question } = props;

  const actionRef = useRef<ActionDialogHolderType>(null);
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const [copiedMsg, setCopiedMsg] = useState<string>("");
  const [showCopymsg, setShowCopyMsg] = useState<boolean>(false);

  
 const handleCopy = async () => {
    const content = contentRef?.current?.textContent ?? "";
    setShowCopyMsg(true);
   await navigator.clipboard
      .writeText(content)
      .then(() => {
        setCopiedMsg("Content copied!");
        setTimeout(() => (setCopiedMsg('')), 5000);
        
      })
      .catch((error) => {
        setCopiedMsg("Failed to copy content");
        setTimeout(() => (setCopiedMsg('')), 5000);

      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      window.open("https://chat.openai.com/","_blank");
      onClose();
  };
  function getChatGPTIcon() {
    return (
      <div className="btn-icon">
        <ChatGPTIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
      </div>
    );
  }

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={"50%"}
      customStyle={{ maxWidth: "40%" }}
      onClose={onClose}
      onDissmiss={onClose}
    >
      <DialogWrapper onClose={onClose}>
        <div className="jp-qstn-container">
          <div className="jp-qstn-header">
            
            <span className="header-title">{t('ai_job_ad_creation')}</span>
            <span className="header-sub-title">{t('summary_what_youre_directing_ai_build_for')}</span>

          </div>
          <div className="jp-qstn-content">
            {loading && <SpinnerScreen />}
            <span className="qstn-span" ref={contentRef}>
              {question}
               
            </span>
          </div>
          <div className="jp-qstn-actions">
            <div className="msg-span">
              {showCopymsg && copiedMsg !== "" && <span>{copiedMsg}</span>}
            </div>
        
            <div className="btn-cancel">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("cancel")}
                enable={true}
                backgroundColor={THEME.secondaryColor4}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="btn-redirect">
            
                <div className="copy-icon">
                  <CustomButton
                    leftIcon={getChatGPTIcon()}
                    loading={false}
                    textStyle={{ textTransform: 'capitalize' }}
                    name={t('copy_go_to_chatgpt')}
                    enable={true}
                    onClick={handleCopy}
                    customClassName="copy-btn"
                    
                  />
                </div>
   
            </div>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );
};

export default JobPostingQuestionPopUp;
