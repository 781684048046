import { useTranslation } from "react-i18next";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";
import PageNavHeader from "../../../components/page_header/page_nav_header";
import TabNavigation from "../../../components/tab_navigation/tab_navigation";
import { getDashboardNavHeaderProps, AppRoutes } from "../../../routes";
import ActiveDirectory from "./active_directory/active_directory";
import ArchivedDirectory from "./archived_directory/archived_directory";


interface Props extends RouteComponentProps<any> { }

const OnboardingCandidateInputRequiredTabNav: React.FunctionComponent<Props> = (
    props: Props
  ) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getDashboardNavHeaderProps(location, match, t);
    return (
      <PageNavHeader data={headerOptions} history={history} location={location}>
        <TabNavigation
          activeRoute={location.pathname}
          tabList={[
            {
              title: "active_directory",
              route: `${AppRoutes.onboardingCandidateInputRequired}/active-directory`,
            },
            {
              title: "archive_directory",
              route: `${AppRoutes.onboardingCandidateInputRequired}/archive-directory`,
            },
          ]}
          onChange={(value) => history.push(value)}
        >
          <Switch>
            <Route
              exact
              path={`${AppRoutes.onboardingCandidateInputRequired}/active-directory`}
              component={ActiveDirectory}
            />
            <Route
              exact
              path={`${AppRoutes.onboardingCandidateInputRequired}/archive-directory`}
              component={ArchivedDirectory}
            />
            <Route
              path={"/"}
              render={({ location }) => (
                <Redirect
                  to={{
                    pathname: `${AppRoutes.onboardingCandidateInputRequired}/active-directory`,
                    state: { from: location, ...(location.state as any) },
                  }}
                />
              )}
            />
          </Switch>
        </TabNavigation>
      </PageNavHeader>
    );
  };

  export default OnboardingCandidateInputRequiredTabNav;