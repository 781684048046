import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppError, ICreateTalentClientCompanyInterview, ITalentClientCompanyInterview} from "../../../../../interfaces";
import {catchRequestError} from "../../../../../utils";
import {JobOrderAPI} from "../../../../../apis/job_order_api";

export const getJobOrderTalentInterviews = createAsyncThunk<
    any,
    {job_order_id: string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@jobs/details/talent/talent-list/get',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobOrderInterviews(data);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getJobOrderTalentInterviewsToClientCompanies = createAsyncThunk<
    Array<ITalentClientCompanyInterview>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@job/details/interviews/talent-client-company-interview/get',
    async (job_id, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getAllJobTalentClientInterviews(job_id);
            if(typeof res.data === 'string') return [];
            return res.data as ITalentClientCompanyInterview;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addNewJobOrderTalentClientCompanyInterview = createAsyncThunk<
    string,
    { jobId: string, data: ICreateTalentClientCompanyInterview },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@job/details/interviews/talent-client-company-interview/post',
    async (payload, thunkAPI) => {
        try {
            const res = await JobOrderAPI.postTalentClientInterview(payload.data);
            // thunkAPI.dispatch(getTalentInHouseInterviews(payload.jobId));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const updateJobOrderTalentClientCompanyInterview = createAsyncThunk<
    string,
    { jobId: string, data: ITalentClientCompanyInterview },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@job/details/interviews/talent-client-company-interview/patch',
    async (payload, thunkAPI) => {
        try {
            const res = await JobOrderAPI.patchTalentClientInterview(payload.data);
            // thunkAPI.dispatch(getTalentInHouseInterviews(payload.talentId));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);