import { useTranslation } from 'react-i18next';
import './user_menu_list.scss';
import { AccessEnterIcon, UserIcon } from '../icons';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../routes';

interface Props {
    displayEmail: string,
    displayName: string,
    talentId: string | undefined,
    onProfile: () => void,
    onProfileUpdate: () => void,
    onLogout: () => void,
    onPasswordUpdate: () => void
}

const UserMenuList = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div className='userlist-main-div'>
            <div className='username-view'>
                <div className='user-name-span'>
                    {props.displayName}
                </div>
                <div className='user-mail'>{props.displayEmail}</div>
            </div>
            <ul className='profile-ul'>
                <NavLink to={AppRoutes.myProfileDashboardPage}>
                    {props.talentId ? "" : <li onClick={props.onProfile}>
                        <div className="uml-item-list">
                            <div className="uml-item-icon">
                                <UserIcon width={"1vw"} />
                                {/* <mat-icon>account_circle</mat-icon> */}
                            </div>
                            <div className="uml-item-name">
                                <span>{t('my_profile')}</span>
                            </div>
                        </div>
                    </li>}
                </NavLink>

                <li onClick={props.onLogout}>
                    <div className="uml-item-list">
                        <div className="uml-item-icon">
                            <AccessEnterIcon width={"1vw"} />
                            {/* <mat-icon>lock_open</mat-icon> */}
                        </div>
                        <div className="uml-item-name">
                            <span>{t('logout')}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default UserMenuList;