import { ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { ICreateResponse, ThirdPartyDeductionType } from "../../../../interfaces/third_party_deduction_type";
import DeductionForm from "../../forms/deduction_form";

interface Props {
  visible: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (data: ThirdPartyDeductionType | ThirdPartyDeductionType) => void;
  deductionInfo?: ThirdPartyDeductionType | null;
  state: {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
  };
}

const CreateDeductionPopup: React.FunctionComponent<Props> = (props) => {
  const { visible, onClose, onSubmit } = props;

  const handleSubmit = (data: ThirdPartyDeductionType) => {
    onSubmit(data);
  };

  return (
    <ActionDialogHolder
      visible={visible}
      wrapperWidth={"42%"}
      onClose={onClose}
      onDissmiss={onClose}
    >
      <DialogWrapper onClose={onClose}>
        <DeductionForm
          title={props.title}
          onSubmit={handleSubmit}
          onClose={onClose}
          deductionInfo={props?.deductionInfo}
          state={props.state}
        />
      </DialogWrapper>
    </ActionDialogHolder>
  );
};

export default CreateDeductionPopup;
