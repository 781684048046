import { useEffect, useRef, useState } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../../../components/action_dialog_holder/action_dialog_holder";
import { IInvoiceList, IInvoices } from "../../../../../../interfaces/report_center_finance_accounting";
import { useTranslation } from "react-i18next";
import DialogWrapper from "../../../../../../components/dialog_wrapper/dialog_wrapper";
import { currencyConversion, CustomButton } from "../../../../../../utils";
import { LoadingType, THEME } from "../../../../../../enums";
import SortableTable, { IHeaderListProps, TableData } from "../../../../../../components/sortable_table/sortable_table";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../../components/table_empty/table_empty";
import { getDateString } from "../../../../../../variables";
import { AppRoutes } from "../../../../../../routes";
import { RouteComponentProps } from "react-router-dom";
import { getInvoiceList } from "../../../../../../redux/report_center";
import { getInvoicePDFURLThunk, selectePaycheckInvoiceState, useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { paycheckInvoiceSliceActions } from "../../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";
import { AppError } from "../../../../../../interfaces";
import './invoice_list.scss'
interface Props {
    asOfDate: number;
    visible: boolean;
    invoiceListState: {
        error?: AppError | null;
        loading: LoadingType;
        response: IInvoiceList | null;
    }
    invoiceList: IInvoiceList | null;
    openInvoiceList: {
        visible: boolean;
        company_id: string;
        aging_type: string;
    }
    tableHeader: Array<IHeaderListProps>;
    history: RouteComponentProps["history"];
    onRetry: () => void;
    onClose: () => void;
}

const InvoiceListPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        asOfDate,
        openInvoiceList,
        invoiceListState,
        invoiceList,
        tableHeader,
        onClose,
        onRetry
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const invoicePDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (openInvoiceList?.company_id !== "" && openInvoiceList?.aging_type.toString() !== "" && asOfDate !==0) {
            dispatch(getInvoiceList({ company_id: openInvoiceList?.company_id!, end_date: asOfDate, aging_type: openInvoiceList?.aging_type.toString()! }))
        }
    }, [openInvoiceList]);

    useEffect(() => {
        if (invoicePDFURLState.invoice.loading === LoadingType.succeeded && invoicePDFURLState.invoice.response !== "") {
            window.open(invoicePDFURLState.invoice.response, "_blank");
            dispatch(paycheckInvoiceSliceActions.clearState())
        }
    }, [invoicePDFURLState.invoice.loading, invoicePDFURLState.invoice.response]);



    const getInvoiceURL = (invoice_id: string) => {
        dispatch(getInvoicePDFURLThunk(invoice_id));
    }

    const getFilteredList = () => {
        let list: IInvoices[] | undefined = invoiceList?.invoices;
        let resultList: IInvoices[] | undefined;


        return (resultList ?? list)?.filter((doc) => {
            const str = search.trim().toLowerCase();
            const companyNameFilter = doc.customer
                ? doc.customer.trim().toLowerCase().includes(str)
                : false;
            const departmentFilter = doc.department
                ? doc.department.trim().toLowerCase().includes(str)
                : false;
            const invoiceNumberFilter = doc.invoice_number
                ? doc.invoice_number.toString().trim().toLowerCase().includes(str)
                : false;
            const daysPastDueFilter = doc.days_past_due
                ? doc.days_past_due.toString().trim().toLowerCase().includes(str)
                : false;
            const amountFilter = doc.amount
                ? currencyConversion(doc.amount).toString().trim().toLowerCase().includes(str)
                : false;
            const balanceAmountFilter = doc.balance_amount
                ? currencyConversion(doc.balance_amount).toString().trim().toLowerCase().includes(str)
                : false;
            return companyNameFilter || departmentFilter || invoiceNumberFilter || daysPastDueFilter || amountFilter || balanceAmountFilter;
        });
    }


    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        return 1;
    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function handleCompanySelect(value: IInvoices) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.customer,
            },
        });
        return;
    }

    function handleDepartmentSelect(value: IInvoices) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.customer,
                departmentId: value.department_id,
                departmentName: value.department,
            },
        });
        return;
    }

    const showSpinner = () => invoiceListState?.loading === LoadingType.pending;

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (invoiceListState?.error != null && invoiceList?.invoices?.length === 0) {
            return <TableErrorHandler error={invoiceListState?.error} onRefresh={onRetry} />;
        }

        if (invoiceList?.invoices?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No Invoices found"} onClick={onRetry} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <SortableTable
                    headerList={tableHeader}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {getFilteredList()?.map((doc, index) => {
                        return (
                            <tr key={doc.company_id}>
                                <TableData customStyle={{ flex: getFlexNumber(0) }}
                                    isButton={true}
                                    onClick={() => handleCompanySelect(doc)}
                                >
                                    <span>{doc.customer}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(1) }}
                                    isButton={true}
                                    onClick={() => handleDepartmentSelect(doc)}
                                >
                                    <span>{doc.department ? doc.department : t("corporate")}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                    <span>{getDateString(doc.invoice_date)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(3) }}
                                    isButton={true}
                                    onClick={() => getInvoiceURL(doc.id)}
                                >
                                    <span>{doc.invoice_number}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                    <span>{getDateString(doc.due_date)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                    <span>{doc.days_past_due}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                    <span>{currencyConversion(doc.amount)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                    <span>{currencyConversion(doc.balance_amount)}</span>
                                </TableData>
                            </tr>
                        )
                    })}
                </SortableTable>
            );
        }
    };

    return (<ActionDialogHolder
        ref={actionRef}
        visible={visible}
        wrapperWidth={"60%"}
        maxWrapperWidth={"60%"}
        onClose={onClose}
        onDissmiss={onClose}
    >
        <DialogWrapper onClose={onClose}>
            <div className="invoice-list-container">
                <div className="invoice-list-header">
                    {t("invoice_list")}
                </div>
                <div className="table-content">{getTable()}</div>
                {invoiceListState.loading === LoadingType.succeeded && <div>
                    <div className="total-counts-card">
                        <div className="total-title">
                            Total
                        </div>
                        <div className="total-count-content">
                            <div className="total-count">
                                {currencyConversion(invoiceList?.total_amount!)}
                            </div>
                            <div className="total-count">
                                {currencyConversion(invoiceList?.total_balance_amount!)}
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="invoice-list-actions">
                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("ok")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={onClose}
                        />
                    </div>
                </div>
            </div>
        </DialogWrapper>

    </ActionDialogHolder>);
}

export default InvoiceListPopup;
