
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./run_type_reducer";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.RunTypeList;
export const selectRunTypeListState = createSelector(select, selectState);
export const selectRunTypeList = createSelector(
    select,
    selectAll
);
export const selectRunTypeListEntities = createSelector(
    select,
    selectEntities
);