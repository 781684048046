import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { IIUploadFileUrlResponse, IUploadFile } from "../../../interfaces/upload_file";
import { ImportDataFileAPI } from "../../../apis/import_data_file_api";

export const getUploadFileStatus = createAsyncThunk<
    Array<IUploadFile>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@universal_directories/upload_file_status/get',
    async (payload, thunkAPI) => {
        try {
            const res = await ImportDataFileAPI.getUploadedFileStatus(payload);
            if (typeof res.data === 'string') return [];
            return res.data as IUploadFile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const downloadUploadFileStatus = createAsyncThunk<
    IIUploadFileUrlResponse,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@universal_directories/upload_file_status/download',
    async (payload, thunkAPI) => {
        try {
            const res = await ImportDataFileAPI.getUploadedFileDownloadURL(payload);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);