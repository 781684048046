import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { AdminCenterAPI } from "../../../apis";
import { AddSalesTaxConfiguration, SalesTaxConfiguration } from "../../../interfaces/sales_tax_configuration";

export const getSalesTaxTaxConfigList = createAsyncThunk<
    Array<SalesTaxConfiguration>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/sales_tax_config/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getSalesTaxConfigList();
            if (typeof res.data === 'string') return [];
            return res.data as Array<SalesTaxConfiguration>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const createSalesTaxTaxConfigList = createAsyncThunk<
    string,
    AddSalesTaxConfiguration,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/sales_tax_config/create',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createSalesTaxConfigList(payload);
            if (typeof res.data === 'string') return [];
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const updateSalesTaxTaxConfigList = createAsyncThunk<
    string,
    SalesTaxConfiguration,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/sales_tax_config/update',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateSalesTaxConfigList(payload);
            if (typeof res.data === 'string') return [];
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteSalesTaxConfig = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/sales_tax_config/delete',
        async (sales_tax_id, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.deleteSalesTaxConfigList(sales_tax_id);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
);