import { combineReducers } from "@reduxjs/toolkit";
import { employeePortalUniversalDirectoryReducers } from "./universal_directory";
import { employeePortalPDReducers } from "./payroll_details";
import { epEDocsReducers } from "./edocs_certification";

export const employeePortalReducers = combineReducers({
    'universalDirectory': employeePortalUniversalDirectoryReducers,
    'empportalPayrollDetails': employeePortalPDReducers,
    "eDocsCerti": epEDocsReducers,
});

export * from './universal_directory';
export * from './payroll_details';
export * from './edocs_certification';
