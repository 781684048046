import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import MenuOption from '../../components/menu_option/menu_option';
import { RoleManagementIcon, SecurityPolicy, SystemConfigurationIcon, UserManagementIcon, AgencySetup, BackOfficeIcon, TeamManagementIcon, PayrollTaxConfigIcon, SalesTaxConfigIcon, WorkCompConfigIcon, InfoTextIcon, BulbIcon, IntegrationPartnersIcon, ReportCenterIcon } from '../../icons';
import { AppRoutes } from '../../routes';
import { selectProfileState, useAppSelector } from '../../redux/store';
import { ROLE } from '../../enums';
import { Portal } from 'react-portal';
import PayrollTaxInfoTextPopUp from './popups/payroll_tax_info_text/payroll_tax_info_text';
import { set } from 'date-fns/esm';
import AgencySetUpInfoTextPopUp from './popups/agency_setup_info_text/agency_setup_info_text';
import SalesTaxInfoTextPopUp from './popups/sales_tax_info_text/sales_tax_info_text';
import WorkCompInfoTextPopUp from './popups/work_comp_info_text/work_comp_info_text';

interface Props extends RouteComponentProps<any> { }

export interface InfoTextPopup {
    agency_setup_info_text: boolean;
    payroll_tax_info_text: boolean;
    sales_tax_info_text: boolean;
    work_comp_info_text: boolean;
}

const AdminCenterPage: React.FunctionComponent<Props> = (props: Props) => {
    const { history } = props;
    const { t } = useTranslation();
    const state = useAppSelector((state) => selectProfileState(state));
    const [openAgencySetUpInfoTextPopup, setOpenAgencySetUpInfoTextPopup] = useState<boolean>(false)
    const [openPayrollTaxInfoTextPopup, setOpenPayrollTaxInfoTextPopup] = useState<boolean>(false)
    const [openSalesTaxInfoTextPopup, setOpenSalesTaxInfoTextPopup] = useState<boolean>(false)
    const [openWorkCompInfoTextPopup, setOpenWorkCompInfoTextPopup] = useState<boolean>(false)

    const handleAgencySetUpInfoTextPopupOpen = () => {
        setOpenAgencySetUpInfoTextPopup(true)
    }
    const handlePayrollTaxInfoTextPopupOpen = () => {
        setOpenPayrollTaxInfoTextPopup(true)
    }
    const handleSalesTaxInfoTextPopupOpen = () => {
        setOpenSalesTaxInfoTextPopup(true)
    }
    const handleWorkCompInfoTextPopupOpen = () => {
        setOpenWorkCompInfoTextPopup(true)
    }

    const options = [
        {
            title: 'user_management',
            icon: UserManagementIcon,
            route: AppRoutes.adminCenterUserManagement,
        },
        {
            title: 'role_management',
            icon: RoleManagementIcon,
            route: AppRoutes.adminCenterRoleManagement,
        },
        {
            title: 'system_configuration',
            icon: SystemConfigurationIcon,
            route: AppRoutes.adminCenterSystemConfiguration,
        },
        {
            title: 'agency_setup',
            icon: AgencySetup,
            icon_two: BulbIcon,
            onClick: handleAgencySetUpInfoTextPopupOpen,
            route: AppRoutes.adminCenterAgencySetup,
        },
        {
            title: 'security_policy',
            icon: SecurityPolicy,
            route: AppRoutes.adminCenterSecurityPolicy,
        },
        {
            title: 'integration_partners',
            icon: IntegrationPartnersIcon,
            route: AppRoutes.adminCenterIntegrations,
        },
        {
            title: 'team_management',
            icon: TeamManagementIcon,
            route: AppRoutes.adminCenterTeamManagement,
        },
        {
            title: 'back_office',
            icon: BackOfficeIcon,
            route: AppRoutes.adminCenterBackOffice,
        },
        {
            title: 'payroll_tax_configuration',
            icon: PayrollTaxConfigIcon,
            icon_two: BulbIcon,
            onClick: handlePayrollTaxInfoTextPopupOpen,
            route: AppRoutes.adminCenterPayrollTaxConfiguration,
        },
        {
            title: 'sales_tax_configuration',
            icon: SalesTaxConfigIcon,
            icon_two: BulbIcon,
            onClick: handleSalesTaxInfoTextPopupOpen,
            route: AppRoutes.adminCenterSalesTaxConfiguration,
        },
        {
            title: 'work_comp_configuration',
            icon: WorkCompConfigIcon,
            icon_two: BulbIcon,
            onClick: handleWorkCompInfoTextPopupOpen,
            route: AppRoutes.adminCenterWorkCompConfiguration,
        },
        {
            title: 'report_center',
            icon: ReportCenterIcon,
            route: AppRoutes.adminCenterReportCenter,
        },
    ]

    // const restrictedRoutes = [
    //     {
    //         title: 'agency_setup',
    //         icon: AgencySetup,
    //         icon_two: BulbIcon,
    //         onClick: handleAgencySetUpInfoTextPopupOpen,
    //         route: AppRoutes.adminCenterAgencySetup,
    //     },
    //     {
    //         title: 'security_policy',
    //         icon: SecurityPolicy,
    //         route: AppRoutes.adminCenterSecurityPolicy,
    //     },
    //     {
    //         title: 'back_office',
    //         icon: BackOfficeIcon,
    //         route: AppRoutes.adminCenterBackOffice,
    //     },
    // ]

    const superAdminRoute = [
        {
            title: 'agency_management',
            icon: AgencySetup,
            route: AppRoutes.adminCenterAgencyManagement,
        },
    ]

    // function returnValidMenu() {
    //     let roles = state.profile ? state.profile.roles : []
    //     for(let role of roles)
    //         if (role['role'] === ROLE.administrator || role['role'] === ROLE.superUser)
    //             return [...options, ...restrictedRoutes]
    //     return options;
    // }

    function returnValidMenu() {
        let roles = state.profile ? state.profile.roles : [];
        let finalArr: any;
        for (let role of roles) {
            if (role['role'] === ROLE.superAdmin) {
                finalArr = [...options, ...superAdminRoute];
            }
            if (role['role'] === ROLE.administrator || role['role'] === ROLE.superUser) {
                finalArr = [...options];
            }
        }
        return finalArr || options;
    }

    return (
        <div className="menu-option-container">
            {returnValidMenu().map((doc, index) => {
                return (
                    <MenuOption key={index} doc={doc} t={t} onClick={() => history.push(doc.route)} />
                )
            })}
            {openPayrollTaxInfoTextPopup && (
                <Portal>
                    <PayrollTaxInfoTextPopUp
                        visible={openPayrollTaxInfoTextPopup}
                        onClose={() => setOpenPayrollTaxInfoTextPopup(false)}
                        onDismiss={() => setOpenPayrollTaxInfoTextPopup(false)}
                    />
                </Portal>
            )}
            {openWorkCompInfoTextPopup && (
                <Portal>
                    <WorkCompInfoTextPopUp
                        visible={openWorkCompInfoTextPopup}
                        onClose={() => setOpenWorkCompInfoTextPopup(false)}
                        onDismiss={() => setOpenWorkCompInfoTextPopup(false)}
                    />
                </Portal>
            )}
            {openSalesTaxInfoTextPopup && (
                <Portal>
                    <SalesTaxInfoTextPopUp
                        visible={openSalesTaxInfoTextPopup}
                        onClose={() => setOpenSalesTaxInfoTextPopup(false)}
                        onDismiss={() => setOpenSalesTaxInfoTextPopup(false)}
                    />
                </Portal>
            )}
            {openAgencySetUpInfoTextPopup && (
                <Portal>
                    <AgencySetUpInfoTextPopUp
                        visible={openAgencySetUpInfoTextPopup}
                        onClose={() => setOpenAgencySetUpInfoTextPopup(false)}
                        onDismiss={() => setOpenAgencySetUpInfoTextPopup(false)}
                    />
                </Portal>
            )}

        </div>

    )
}

export default AdminCenterPage;