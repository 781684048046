import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { LoadingType } from "../../../../enums";
import {
  IApolloImportResponse,
  ICompaniesApolloSearchResults,
  ICompanyApolloImport,
  IContactApolloImport,
  IContactsApolloSearch,
  IContactsApolloSearchResults,
} from "../../../../interfaces/apollo_search_import";
import preferences from "../../../../preferences";
import { apolloCompaniesListActions } from "../../../../redux/apollo_search_import/companies_search_import/companies_search_import_reducer";
import { apolloContactsListActions } from "../../../../redux/apollo_search_import/contacts_search_import/contacts_search_import_reducer";
import {
  apolloContactImportPeopleThunk,
  apolloTalentImportPeopleThunk,
  appolloContactSearchPeopleThunk,
  selectApolloContactsListState,
} from "../../../../redux/contacts";
import {
  apolloCompaniesImportThunk,
  appolloCompaniesSearchThunk,
  selectApolloCompaniesListState,
  selectApolloCreditsState,
  selectProfileState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import { SpinnerScreen } from "../../../../utils";
import ApolloCompaniesResults from "../companies_results_table/companies_results_table";
import ApolloContactsResults from "../contacts_results_table/contacts_talent_result_table";
import SearchPeopleFilters from "../search_people/search_people_filters";
import "./apollo_search_import.scss";

interface Props extends RouteComponentProps<any> {}

export const ApolloSearchImport: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { history, location, match } = props;
  const { params } = match;
  const source = params.source;

  const dispatch = useAppDispatch();
  const profileState = useAppSelector((state) => selectProfileState(state));
  const apolloContactsListState = useAppSelector((state) =>
    selectApolloContactsListState(state)
  );
  const apolloCompaniesListState = useAppSelector((state) =>
    selectApolloCompaniesListState(state)
  );
  const apolloCreditsState = useAppSelector((state) =>
    selectApolloCreditsState(state)
  );

  const [selectedContact, setSelectedContact] =
    useState<IContactApolloImport | null>(null);
  const [selectedCompany, setSelectedCompany] =
    useState<ICompanyApolloImport | null>(null);

  const [expanded, setExpanded] = useState(true);
  function handleLeftNavExpand() {
    preferences.setNavExpandState(!expanded);
    setExpanded(!expanded);
  }
  const [formState, setFormState] = useState<IContactsApolloSearch>();
  const [selectedItems, setSelectedItems] = useState<
    Map<any, IContactsApolloSearchResults | ICompaniesApolloSearchResults>
  >(new Map());

  function handleSubmit(
    doc: IContactsApolloSearch | undefined,
    pageNum?: number
  ) {
    // const docData :IContactsApolloSearch = {...doc, page:pageNum};
    setFormState(doc);
    if (source && source === "contacts" && doc !== undefined) {
      dispatch(appolloContactSearchPeopleThunk({ ...doc, page: pageNum }));
      dispatch(apolloContactsListActions.clearApolloContactsLoadingState());
    } else if (source && source === "companies" && doc !== undefined) {
      dispatch(appolloCompaniesSearchThunk({ ...doc, page: pageNum }));
      dispatch(apolloCompaniesListActions.clearApolloCompaniesLoadingState());
    } else if (source && source === "talent" && doc !== undefined) {
      dispatch(
        appolloContactSearchPeopleThunk({
          ...doc,
          page: pageNum,
          search_talent: true,
        })
      );
      dispatch(apolloContactsListActions.clearApolloContactsLoadingState());
    }
  }
  const [importResponse, setImportResponse] = useState<IApolloImportResponse>();
  useEffect(() => {
    if (
      source === "contacts" &&
      apolloContactsListState &&
      apolloContactsListState.importContact.loading === LoadingType.succeeded &&
      apolloContactsListState.importContact.response !== null
    ) {
      setImportResponse(apolloContactsListState.importContact.response);
    }
    if (
      source === "talent" &&
      apolloContactsListState &&
      apolloContactsListState.importTalent.loading === LoadingType.succeeded &&
      apolloContactsListState.importTalent.response !== null
    ) {
      setImportResponse(apolloContactsListState.importTalent.response);
    }
    if (
      source === "companies" &&
      apolloCompaniesListState &&
      apolloCompaniesListState.importCompany.loading ===
        LoadingType.succeeded &&
      apolloCompaniesListState.importCompany.response !== null
    ) {
      setImportResponse(apolloCompaniesListState.importCompany.response);
    }
  }, [apolloCompaniesListState, apolloContactsListState, source]);

  useEffect(() => {
    if (apolloContactsListState.response !== null) {
      setCurrentPage(1);
    }
    if (apolloCompaniesListState.response !== null) {
      setCurrentPage(1);
    }
  }, [
    apolloContactsListState.response?.total_results,
    apolloCompaniesListState.response?.total_results,
  ]);
  const handleClearFilterData = () => {
    dispatch(apolloContactsListActions.clearApolloContactsListState());
    dispatch(apolloContactsListActions.clearApolloImportTalentState());
    dispatch(apolloCompaniesListActions.clearApolloCompaniesListState());
  };
  const handleClearImportState = () => {
    dispatch(apolloContactsListActions.clearApolloImportContactsState());
    dispatch(apolloContactsListActions.clearApolloImportTalentState());
    dispatch(apolloCompaniesListActions.clearApolloImportCompaniesState());
  };
  const handleImportContact = (
    doc: IContactApolloImport[],
    directDial: boolean,
    forceImport: boolean
  ) => {
    const updatedData = doc.map((item) => ({
      ...item,
      state: item?.state !== null ? item?.state : "",
      city: item?.city !== null ? item?.city : "",
      country: item?.country !== null ? item?.country : "",
    }));
    if (source === "contacts") {
      dispatch(
        apolloContactImportPeopleThunk({
          data: updatedData,
          need_direct_dial: directDial,
          force_import: forceImport,
        })
      );
    }
    if (source === "talent") {
      dispatch(
        apolloTalentImportPeopleThunk({
          data: updatedData,
          need_direct_dial: directDial,
          force_import: forceImport,
        })
      );
    }
  };
  const handleImportCompany = (
    doc: ICompanyApolloImport[],
    forceImport: boolean
  ) => {
    const updatedData = doc.map((item) => ({
      ...item,
      state: item?.state !== null ? item?.state : "",
      city: item?.city !== null ? item?.city : "",
      country: item?.country !== null ? item?.country : "",
    }));
    if (source === "companies") {
      dispatch(
        apolloCompaniesImportThunk({
          data: updatedData,
          force_import: forceImport,
        })
      );
    }
  };
  useEffect(() => {
    handleClearFilterData();
  }, []);

  const handleClose = () => {
    handleClearFilterData();
    dispatch(apolloCompaniesListActions.clearApolloCompaniesListState());
  };
  function handleOnGotoContact(id: string, name: string | undefined) {
    handleClose();
    handleClearFilterData();

    if (source === "contacts") {
      props.history.push({
        pathname: `${AppRoutes.contactsDetailsPage}/${id}`,
        state: {
          id: id,
          name: name,
        },
      });
    }
    if (source === "talent") {
      props.history.push({
        pathname: `${AppRoutes.talentDetailsPage}/${id}`,
        state: {
          id: id,
          name: name,
        },
      });
    }
    if (source === "companies") {
      props.history.push({
        pathname: `${AppRoutes.companiesDetailsPage}/${id}`,
        state: {
          id: id,
          name: name,
        },
      });
    }
  }
  function isError() {
    return (
      apolloContactsListState.importContact.error ||
      apolloContactsListState.importTalent.error ||
      apolloCompaniesListState.importCompany.error
    );
  }
  function handleClearError() {
    dispatch(apolloCompaniesListActions.clearApolloCompaniesListError());
    dispatch(apolloContactsListActions.clearApolloImportContactsErrorState());
    dispatch(apolloContactsListActions.clearApolloImportTalentErrorState());
  }
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNum: number) => {
    handleSubmit(formState, pageNum);
    setCurrentPage(pageNum);
    // dispatch(apolloGetCreditsThunk());
  };
  function handleCompanySelect(value: ICompaniesApolloSearchResults) {
    props.history.push({
      pathname: `${AppRoutes.apolloCompanyDetailsPage}/${value.id}`,
      state: {
        domain: value.primary_domain ?? value.domain,
        id: value.id,
        name: value.name,
      },
    });
    return;
  }

  return (
    <div className="as-search-container">
      <div className="as-side-bar">
        <div
          className="as-side-menu"
          style={{ minWidth: expanded ? "16.0208vw" : "4vw" }}
        >
          <SearchPeopleFilters
            expanded={expanded}
            onToggleExpanded={handleLeftNavExpand}
            onSubmit={handleSubmit}
            error={undefined}
            loading={LoadingType.idle}
            handleClearFilterData={handleClearFilterData}
            source={source}
            importLoading={
              source === "contacts"
                ? apolloContactsListState.importContact.loading
                : apolloContactsListState.importTalent.loading
            }
            creditsCount={apolloCreditsState?.response?.credits_available}
            mobileCreditsCount={
              apolloCreditsState?.response?.available_mobile_credits
            }
          />
        </div>
        <div className="as-content-bar">
          {apolloContactsListState?.loading === LoadingType.idle &&
            apolloCompaniesListState?.loading === LoadingType.idle && (
              <div className="no-results-msg">
                <span>{t("start_search_apply_filter_left_panel")}</span>
              </div>
            )}
          {/* {apolloDataState?.loading === LoadingType.pending && <SpinnerScreen />} */}
          {(apolloContactsListState?.loading === LoadingType.pending ||
            apolloCompaniesListState?.loading === LoadingType.pending) && (
            <SpinnerScreen />
          )}

          {apolloContactsListState?.loading === LoadingType.succeeded && (
            <ApolloContactsResults
              selectedItems={selectedItems}
              handleSelection={(val) => setSelectedItems(val)}
              contactsResult={apolloContactsListState.response}
              source={source}
              onChange={setSelectedContact}
              selectedContact={selectedContact}
              title={t("search_people")}
              importContact={handleImportContact}
              importLoading={
                source === "contacts"
                  ? apolloContactsListState.importContact.loading
                  : apolloContactsListState.importTalent.loading
              }
              importResponse={importResponse}
              successTitle={
                source === "contacts" ? t("new_contact") : t("new_talent")
              }
              currentLoginUserName={`${
                profileState.profile?.first_name ?? ""
              } ${profileState.profile?.last_name ?? ""}`}
              onClose={handleClose}
              onGotoCreated={handleOnGotoContact}
              error={isError()}
              onClearError={handleClearError}
              onClearImportState={handleClearImportState}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              onAddCredit={() => {}}
            />
          )}
          {apolloCompaniesListState?.loading === LoadingType.succeeded && (
            <ApolloCompaniesResults
              selectedItems={selectedItems}
              handleSelection={(val) => setSelectedItems(val)}
              companiesResult={apolloCompaniesListState.response}
              source={source}
              onChange={setSelectedCompany}
              selectedContact={selectedCompany}
              title={t("search_companies")}
              importCompany={handleImportCompany}
              importLoading={apolloCompaniesListState.importCompany.loading}
              importResponse={importResponse}
              successTitle={t("new_company")}
              currentLoginUserName={`${
                profileState.profile?.first_name ?? ""
              } ${profileState.profile?.last_name ?? ""}`}
              onClose={handleClose}
              onGotoCreated={handleOnGotoContact}
              error={isError()}
              onClearError={handleClearError}
              onClearImportState={handleClearImportState}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              handleCompanySelect={handleCompanySelect}
            />
          )}
          {((apolloContactsListState?.loading === LoadingType.failed &&
            apolloContactsListState?.error !== null) ||
            (apolloCompaniesListState?.loading === LoadingType.failed &&
              apolloCompaniesListState?.error !== null)) && (
            <div className="no-results-msg">
              <span>{t("no_matching_results_found")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApolloSearchImport;
