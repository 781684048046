import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./update_link_thunk";
export interface UpdateLinkState {
    response: string | null,
    error?: AppError | null,
    loading: LoadingType,
}

const initialState: UpdateLinkState = {
    response: null,
    error: null,
    loading: LoadingType.idle,

}

export const updateLinkSlice = createSlice({
    name: 'updateLink',
    initialState: initialState,
    reducers: {
        clearError(state) {
            return { ...state, error: null };
        },
        clearState(state) {
            return ({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.updateOnboardingLinkThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.updateOnboardingLinkThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.updateOnboardingLinkThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const updateLinkSliceActions = updateLinkSlice.actions;
export const selectState = (state: UpdateLinkState) => state;


