import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../../../components/search_bar";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { LoadingType } from "../../../../../enums";
import { selectPayCheckState, selectPayCheckList, getPayCheckThunk } from "../../../../../redux/back_office";
import { selectWeekEndingDatesState, selectWeekEndingDatesDDList, getWeekEndingDatesDropdownThunk } from "../../../../../redux/selectors";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { convertNumberToMoney, SpinnerScreen, currencyConversion } from "../../../../../utils";
import { getDateString } from "../../../../../variables";


const tableHeader = [
    { title: "employee_name", code: "employee_name" },
    { title: "check_number", code: "check_number" },
    { title: "check_date", code: "check_date" },
    { title: "gross_amount", code: "gross_amount" },
    { title: "total_deductions", code: "total_deduction" },
    { title: "total_taxes", code: "total_tax_amount" },
    { title: "net_amount", code: "net_amount" },
];

interface Props {
    batch_id: string;
    week_accounting_id: string;
}

const CNPBPayCheckDirectoryPage = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState('');

    const payCheckState = useAppSelector((state) =>
        selectPayCheckState(state)
    );
    const payCheckList = useAppSelector((state) => selectPayCheckList(state));

    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);

    useEffect(() => {

            dispatch(getPayCheckThunk({ week_accounting_id: props.week_accounting_id, batch_id: props.batch_id}));

    }, []);

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function getFilteredList() {
        let list;
        if (sortedField != null && payCheckList) {
            list = [...payCheckList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (list ?? payCheckList)?.filter(doc => {
            const str = search.trim().toLowerCase();
            const empNameFilter = doc.employee_name ? doc.employee_name.trim().toLowerCase().includes(str) : false;
            const checkNumberFilter = doc.check_number ? doc.check_number.toString().trim().toLowerCase().includes(str) : false;
            const grossAmountFilter = doc.gross_amount ? convertNumberToMoney(doc.gross_amount).toString().trim().toLowerCase().includes(str) : false;
            const totalDeductionFilter = doc.total_deduction ? convertNumberToMoney(doc.total_deduction).toString().trim().toLowerCase().includes(str) : false;
            const totalTaxesFilter = doc.total_taxes ? convertNumberToMoney(doc.total_taxes).toString().trim().toLowerCase().includes(str) : false;
            const netAmountFilter = doc.net_amount ? convertNumberToMoney(doc.net_amount).toString().trim().toLowerCase().includes(str) : false;
            return empNameFilter || checkNumberFilter || grossAmountFilter || totalDeductionFilter || netAmountFilter || totalTaxesFilter;
        });
    }

    return (
        <div className="payroll-paycheck-container" style={{ boxShadow: "none" }}>
            <div className="payroll-paycheck-header">
                <div className="select-row">
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}{" "}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                </div>
                <div>
                    <SearchBar value={search} onChange={setSearch} onSearch={() => { }} />
                </div>
            </div>
            <div className="payroll-paycheck-content">
                <div className="payroll-paycheck-table">
                    {payCheckList &&
                        payCheckState.loading === LoadingType.pending ? (
                        <>
                            <SpinnerScreen></SpinnerScreen>
                        </>
                    ) : (
                        <SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList() && getFilteredList()!.length > 0 ?
                                getFilteredList()?.map((doc, index) => {
                                    return (
                                        <tr key={'dir' + index}>
                                            <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                <span>{doc.employee_name}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                                <span>{doc.check_number}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                <span>{getDateString(doc.check_date, "mm/dd/yyyy")}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                <span>{currencyConversion(doc.gross_amount, 2)}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                <span>{currencyConversion(doc.total_deduction, 2)}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                <span>{currencyConversion(doc.total_tax_amount, 2)}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                                <span>{currencyConversion(doc.net_amount, 2)}</span>
                                            </TableData>
                                        </tr>
                                    );
                                })
                                :
                                <tr>
                                    <TableData customStyle={{ flex: getFlexNumber(14) }}><div style={{ width: '100%', textAlign: "center", fontSize: '0.9vw' }}>{t("no_data_available")}</div></TableData>
                                </tr>
                            }
                        </SortableTable>
                    )}
                </div>
            </div>
        </div>
    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1.1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1.6;
        if (value === 11) return 1;
        if (value === 12) return 1;
        return 1;
    }
}

export default CNPBPayCheckDirectoryPage;