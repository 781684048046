import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getInvoicePDFURLThunk, getWeekEndingDatesDropdownThunk, mailBOInvoicesThunk, selectePaycheckInvoiceState, selectMailPaycheckInvoiceState, selectProfileState, selectWeekEndingDatesDDList, selectWeekEndingDatesState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { CustomButton, CustomCheckBox, SpinnerScreen, convertNumberToMoney, currencyConversion } from "../../../../../utils";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { useEffect, useRef, useState } from "react";
import { LoadingType, THEME } from "../../../../../enums";
import SearchBar from "../../../../../components/search_bar";
import { getDateString } from "../../../../../variables";
import { MenuDotIcon, SearchIcon } from "../../../../../icons";
import { Portal } from "react-portal";
import { getBillingInvoiceReportThunk, selectBillingInvoiceReportList, selectBillingInvoiceReportState } from "../../../../../redux/back_office/billing_invoices";
import "./billing_and_invoices_report.scss"
import { AppRoutes } from "../../../../../routes";
import { IBillingInvoicesReportData } from "../../../../../interfaces";
import ViewCheckInvoicePopup from "../../../popups/view_check/view_check";
import { paycheckInvoiceSliceActions } from "../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";
import { mailInvoicePaycheckSliceActions } from "../../../../../redux/back_office/mail_paycheck_invoices/mail_paycheck_invoices_reducer";
import { EmailOutlined } from "@material-ui/icons";
import SendInvoiceEdocsByMailPopup from "../../../../companies/popup/send_invoice_edocs_mail/send_invoice_edocs_mail";
import InvoceCorrectionPopup from "../../../popups/invoice_correction_popup/invoice_correction_popup";
import { BillingInvoiceReportSliceActions } from "../../../../../redux/back_office/billing_invoices/billing_invoices_report_list/billing_invoices_report_list_reducer";

interface Props extends RouteComponentProps<any> { }
const BillingAndInvoicesReportPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState('');
    const [selectedWeekDate, setSelectedWeekDate] = useState<{ id: string; date: string; }>({ id: "", date: "" });
    const [openViewInvoicePopup, setOpenViewInvoicePopup] = useState<boolean>(false)
    const [invoiceViewURL, setInvoiceViewURL] = useState<string>("");
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`;

    const [selectedItems, setSelectedItems] = useState<
        Map<string, { id: string; name: string }>
    >(new Map());
    const weekEndingDatesListState = useAppSelector((state) =>
        selectWeekEndingDatesState(state)
    );
    const weekEndingDatesList = useAppSelector((state) =>
        selectWeekEndingDatesDDList(state)
    );
    const billingInvoiceReportState = useAppSelector((state) =>
        selectBillingInvoiceReportState(state)
    );
    const billingInvoiceReportList = useAppSelector((state) =>
        selectBillingInvoiceReportList(state)
    );
    const invoicePDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));
    const mailPaycheckState = useAppSelector((state) => selectMailPaycheckInvoiceState(state));
    const [openMenu, setOpenMenu] = useState<{
        visible: boolean;
        invoice_id: string;
        invoice_date: number;
        company_name: string;
    }>({ visible: false, invoice_id: "", invoice_date: 0, company_name: "" });
    const [openCorrection, setOpenCorrection] = useState<boolean>(false);
    const tooltipRef = useRef<HTMLDivElement | null>(null);

    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === billingInvoiceReportList?.length}
                onClick={handleMarkAllItem}
            />
        );
    };
    const tableHeader = [
        { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        { title: "customer", code: "company_name" },
        { title: "department", code: "department_name" },
        { title: "invoice_number", code: "invoice_number" },
        { title: "invoice_status", code: "invoice_status" },
        { title: "invoice_amount", code: "invoice_amount" },
        { title: "balance_amount", code: "balance_amount" },
        { title: "invoice_date", code: "invoice_date" },
        { title: "due_date", code: "due_date" },
        { title: "sales_tax_amount", code: "sales_tax_amount" },
        { title: "", code: "" },
    ];

    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);

    useEffect(() => {
        if (
            weekEndingDatesListState &&
            weekEndingDatesListState.loading === LoadingType.succeeded &&
            weekEndingDatesList.length > 0
        ) {
            setSelectedWeekDate({
                id: weekEndingDatesList[0].id,
                date: getDateString(
                    weekEndingDatesList[0].week_ending_date,
                    "mm/dd/yyyy"
                ),
            });
            dispatch(getBillingInvoiceReportThunk({ week_accounting_id: weekEndingDatesList[0].id }));
        }
    }, [weekEndingDatesList.length, weekEndingDatesListState.loading]);

    useEffect(() => {
        if (invoicePDFURLState.invoice.loading === LoadingType.succeeded && invoicePDFURLState.invoice.response !== "") {
            setInvoiceViewURL(invoicePDFURLState.invoice.response);
        }
    }, [invoicePDFURLState.invoice.loading, invoicePDFURLState.invoice.response]);

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
                setOpenMenu({ visible: false, invoice_id: "", invoice_date: 0, company_name: "" });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleWeekEndChange = (val) => {
        dispatch(BillingInvoiceReportSliceActions.clearState())
        const selectedWeekObject = weekEndingDatesList.find(
            (obj) => obj.id === val
        );
        setSelectedWeekDate({
            id: val,
            date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy"),
        });
        dispatch(getBillingInvoiceReportThunk({ week_accounting_id: val }));
    };

    function handleCompanySelect(value: IBillingInvoicesReportData) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            },
        });
        return;
    }

    function handleDepartmentSelect(value: IBillingInvoicesReportData) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.department_id,
                departmentName: value.department_name,
            },
        });
        return;
    }

    function getFilteredList() {
        let list = billingInvoiceReportList;
        if (sortedField != null && billingInvoiceReportList) {
            list = [...billingInvoiceReportList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return list?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }
    const handleMarkAllItem = () => {
        const newSet = new Map<string, { id: string; name: string }>();
        if (selectedItems.size === billingInvoiceReportList?.length) {
            setSelectedItems(newSet);
        } else {
            billingInvoiceReportList?.forEach((doc) =>
                newSet.set(doc.id, { id: doc.id, name: "" })
            );
            setSelectedItems(newSet);
        }
    };

    const handleMarkItem = (value) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value)) {
            newSet.delete(value);
        } else {
            newSet.set(value, { id: value, name: "" });
        }
        setSelectedItems(newSet);
    };
    const handleViewInvoice = (invoice_id: string) => {
        dispatch(getInvoicePDFURLThunk(invoice_id));
        setOpenViewInvoicePopup(true)
    }

    const handleClose = () => {
        setOpenViewInvoicePopup(false);
        dispatch(paycheckInvoiceSliceActions.clearInoviceState());
        setInvoiceViewURL("")
    }
    const [showMailPopup, setShowMailPopup] = useState<boolean>(false);

    const initialForm = { invoice_ids: [], email: "" }
    const [formState, setFormState] = useState<{ invoice_ids: string[], email: string }>(initialForm);
    const handleFieldChange = (id: string, val: string) => {
        setFormState({
            ...formState, [id]: val, invoice_ids: Array.from(
                selectedItems.values(),
                (entry) => entry.id
            )
        })
    }
    const handleEmailClose = () => {
        setShowMailPopup(false);
        setFormState(initialForm);
        dispatch(mailInvoicePaycheckSliceActions.clearMailInvoiceState());
        setSelectedItems(new Map());
    }
    const sendInvoicesByMail = (invoice_id: string[]) => {
        dispatch(mailBOInvoicesThunk(invoice_id));
    }

    const handleOpenMenu = (invoice_id: string, invoice_date: number, company_name: string) => {
        setOpenMenu((prevState) =>
            prevState?.invoice_id === invoice_id
                ? { visible: !prevState.visible, invoice_id, invoice_date, company_name }
                : { visible: true, invoice_id, invoice_date, company_name }
        );
    };
    return (
        <div className="billing-invoices-container">
            <div className="billing-invoices-header">
                <div className="select-row">
                    <div>
                        <CustomFormSelect
                            list={weekEndingDatesList.map((doc) => ({
                                label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                value: doc.id,
                            }))}
                            customStyle={{ width: "100%" }}
                            name={"week_ending_date"}
                            value={selectedWeekDate.id}
                            label={t("week_ending_date")}
                            placeholder={"Select"}
                            onChange={handleWeekEndChange}
                            isClearable={false}
                        />
                    </div>
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                </div>
                <div className='bi-header-right'>
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            leftIcon={<div><EmailOutlined style={{ color: THEME.white, height: "1vw", width: "1vw" }} /></div>}
                            name={t("send_by_email")}
                            backgroundColor={THEME.buttonBackgroundColor4}
                            onClick={() => {
                                sendInvoicesByMail(Array.from(
                                    selectedItems.values(),
                                    (entry) => entry.id
                                )); setShowMailPopup(true)
                            }}
                            enable={selectedItems.size > 0}
                        />
                    </div>
                    <div className="search-bar">
                        <SearchBar value={search} onChange={(value) => setSearch(value)} onSearch={() => { }} />
                    </div>
                </div>

            </div>
            <div className="billing-invoices-content">
                <div className="billing-invoices-table">
                    {billingInvoiceReportList &&
                        billingInvoiceReportState.loading === LoadingType.pending ? (
                        <>
                            <SpinnerScreen></SpinnerScreen>
                        </>
                    ) : (
                        <SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList() && getFilteredList()!.length > 0 ?
                                getFilteredList()?.map((doc, index) => {
                                    return (
                                        <tr key={doc.id}>
                                            <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                <CustomCheckBox
                                                    name={""}
                                                    title={""}
                                                    checked={selectedItems.has(doc.id)}
                                                    onClick={() => handleMarkItem(doc.id)}
                                                />
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(1) }} onClick={() => handleCompanySelect(doc)} isButton={true}>
                                                <span>{doc.company_name}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(2) }} onClick={() => handleDepartmentSelect(doc)} isButton={true}>
                                                <span>{doc.department_name}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                <span>{doc.invoice_number}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                <span>{doc.invoice_status}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                <span>{doc.invoice_amount ? currencyConversion(doc.invoice_amount, 2) : "$0.00"}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                                <span>{doc.balance_amount ? currencyConversion(doc.balance_amount, 2) : "$0.00"}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                <span>{getDateString(doc.invoice_date, "mm/dd/yyyy")}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                                <span>{getDateString(doc.due_date, "mm/dd/yyyy")}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                                <span>{doc.sales_tax_amount ? currencyConversion(doc.sales_tax_amount, 2) : "$0.00"}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(10) }}>
                                                <div className="view-invoice-btn">
                                                    <CustomButton
                                                        loading={false}
                                                        customStyle={{ width: '-webkit-fill-available' }}
                                                        leftIcon={<span style={{ color: "#fff" }}><SearchIcon /></span>}
                                                        textStyle={{ textTransform: 'capitalize' }}
                                                        name=""
                                                        enable={true}
                                                        backgroundColor={THEME.buttonBackgroundColor3}
                                                        onClick={() => handleViewInvoice(doc.id)}
                                                    />
                                                </div>
                                                <div
                                                    className={(doc.invoice_corrected || doc.invoice_status === "Paid" || doc.invoice_status === "Partial Payment") ? "menu-icon disable-div" : "menu-icon"}
                                                    onClick={() => handleOpenMenu(doc.id, doc.invoice_date, doc.company_name)}
                                                >
                                                    <MenuDotIcon
                                                        width={"100%"}
                                                        height={"100%"}
                                                        style={{ color: "#fff" }}
                                                    />
                                                    {openMenu && openMenu?.invoice_id === doc.id && openMenu.visible && (
                                                        <>
                                                            {openMenu?.invoice_id === doc.id && (
                                                                <div ref={tooltipRef} className="parent-position">
                                                                    <div className="child-position">
                                                                        <div onClick={() => setOpenCorrection(true)} className="item-menu-title">{t("invoice_correction")}</div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </TableData>
                                        </tr>
                                    );
                                })
                                :
                                <tr>
                                    <TableData customStyle={{ flex: getFlexNumber(10) }}><div style={{ width: '100%', textAlign: "center", fontSize: '0.9vw' }}>{t("no_data_available")}</div></TableData>
                                </tr>
                            }
                        </SortableTable>
                    )}
                </div>
            </div>
            {openViewInvoicePopup &&
                (
                    <Portal>
                        <ViewCheckInvoicePopup
                            visible={openViewInvoicePopup}
                            title={t("view_invoice")}
                            onClose={handleClose}
                            state={invoicePDFURLState.invoice}
                            responseURL={invoiceViewURL}

                        />
                    </Portal>
                )
            }
            {showMailPopup &&
                <Portal>
                    <SendInvoiceEdocsByMailPopup
                        visible={showMailPopup}
                        onClose={handleEmailClose}
                        onSubmit={() => { }}
                        mailState={mailPaycheckState.mailInvoices}
                        currentLoginUserName={currentLoginUserName}
                        title={t("hi_lets_send_invoices", {
                            name: currentLoginUserName,
                        })}
                        successTitle={t("sent_invoices_by_email")}
                        successMsg={t("sent_invoices_by_email", { count: selectedItems.size })}
                        subTitle={t("to_whom_you_send_invoices")}
                    />
                </Portal>
            }
            {openCorrection &&
                (
                    <Portal>
                        <InvoceCorrectionPopup
                            visible={openCorrection}
                            title={t("invoice_correction")}
                            onClose={() => setOpenCorrection(false)}
                            currentLoginUserName={currentLoginUserName}
                            openedInvoice={openMenu}
                            onSuccessClose={() => { dispatch(getBillingInvoiceReportThunk({ week_accounting_id: selectedWeekDate.id })); }}
                        />
                    </Portal>
                )
            }
        </div >
    );
    function getFlexNumber(value: number) {
        if (value === 0) return 0.2;
        if (value === 7) return 0.7;
        if (value === 10) return 1.2;
        return 1;
    }
}

export default BillingAndInvoicesReportPage;