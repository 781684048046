import { createAsyncThunk } from "@reduxjs/toolkit";
import { JobOrderAPI } from "../../../../apis";
import { S3API } from "../../../../apis/s3_api";
import {
  AppError,
  ICreateJob,
  ICreateResponse,
  IJobOrderDetail,
  IProfilePictureRequestQuery,
  IProfilePictureUploadUrlResponse,
} from "../../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../../utils";
import { getJobById } from "../../details";
import { getJobsListThunk } from "../jobs_list/jobs_list_thunk";

export const createJob = createAsyncThunk<
  ICreateResponse,
  { forceAdd: boolean; value: ICreateJob },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@jobs/universal-directory/manage_job/create", async (payload, thunkAPI) => {
  try {
    const res = await JobOrderAPI.createJob(payload);
    if (typeof res.data === "string")
      return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
    return res.data as ICreateResponse;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});

export const updateJob = createAsyncThunk<
  ICreateResponse,
  IJobOrderDetail,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@jobs/universal-directory/manage_job/update", async (payload, thunkAPI) => {
  try {
    const res = await JobOrderAPI.updateJob(payload);
    thunkAPI.dispatch(getJobsListThunk());
    if (typeof res.data === "string")
      return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
    else thunkAPI.dispatch(getJobById({ job_id: payload.id }));
    return res.data as ICreateResponse;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});

/// Files upload
export const getJobFileUploadURLThunk = createAsyncThunk<
  IProfilePictureUploadUrlResponse,
  { type: string; query: IProfilePictureRequestQuery },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@jobs/universal-directory/manage_job/file-upload-url/get",
  async (payload, thunkAPI) => {
    try {
      const res = await S3API.getPreSignedURLForUpload(payload.query);
      if (typeof res.data === "string")
        return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data as IProfilePictureUploadUrlResponse;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const uploadJobFileThunk = createAsyncThunk<
  any,
  { type: string; data: IProfilePictureUploadUrlResponse; file: File | null },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@jobs/universal-directory/manage_job/file-upload-url/post",
  async (payload, thunkAPI) => {
    try {
      if (payload.file && payload.data.url) {
        const photoRes = await S3API.uploadFile(
          payload.data?.url,
          payload.file
        );
        return photoRes.data;
      }
      return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);
