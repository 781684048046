import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { IWorkCompClaim, AppError, ITalentWorkCompClaimTalentData } from "../../../../../interfaces";
import * as thunk from "./talent_work_comp_claims_list_thunk";


export interface TalentWorkCompClaimsListState extends EntityState<IWorkCompClaim> {
    error?: AppError | null,
    loading: LoadingType,
    data: ITalentWorkCompClaimTalentData
}

const workCompClaimsListAdapter: EntityAdapter<IWorkCompClaim> = createEntityAdapter<IWorkCompClaim>({
    selectId: (doc) => doc.id,
})

const initialState: TalentWorkCompClaimsListState = workCompClaimsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    data: {} as ITalentWorkCompClaimTalentData
})


export const talentWorkCompClaimsListSlice = createSlice({
    name: 'talentWorkCompClaimsList',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTalentWorkCompClaimDetailsByTalentId.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null }))
            .addCase(thunk.getTalentWorkCompClaimDetailsByTalentId.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload }))
            .addCase(thunk.getTalentWorkCompClaimDetailsByTalentId.fulfilled, (state, action) => workCompClaimsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload.work_comp_claims))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentWorkCompClaimsListActions = talentWorkCompClaimsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = workCompClaimsListAdapter.getSelectors();
export const selectState = (state: TalentWorkCompClaimsListState) => state;
export const selectLoading = (state: TalentWorkCompClaimsListState) => state.loading;
export const selectError = (state: TalentWorkCompClaimsListState) => state.error;

