export class OnlineApplicationSent {
  id: string;
  name: string;
  email: string;
  phone_number: string;
  agency_domain: string;
  token: string;
  recruiter_id: string;
  token_date_timestamp: number;
  branch_id: string;
  agency_id: string;
  is_deleted: boolean;
  is_completed: boolean;
  clicked_on_link: boolean;
  is_archived: boolean;
  follow_up: OnlineApplicationSentFollowUp[];
}

export class OnlineApplicationSentFollowUp {
  type_of_follow_up: string;
  next_action_date_time: number;
  next_action_date?: number;
  next_action_time?: string;
  followup_goal: string;
  followup_desc: string;
}
