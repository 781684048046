import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import * as thunk from "./apollo_industries_list_thunk";
import { IApolloIndustriesList } from "../../../interfaces/apollo_search_import";

export interface ApolloIndustriesDDListState extends EntityState<IApolloIndustriesList> {
    error?: AppError | null,
    loading: LoadingType,
}

const apolloIndustriesDDListAdapter: EntityAdapter<IApolloIndustriesList> = createEntityAdapter<IApolloIndustriesList>({
    selectId: (doc) => doc.id,
})

const initialState: ApolloIndustriesDDListState = apolloIndustriesDDListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const apolloIndustriesDDListSlice = createSlice({
    name: 'apolloIndustriesDD',
    initialState: initialState,
    reducers: {
        clearApolloIndustriesDDListError(state){
            return {...state, error: null};
        },
        clearApolloIndustriesDDListState(state){
            return apolloIndustriesDDListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.apolloGetIndustriesDropDown.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.apolloGetIndustriesDropDown.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.apolloGetIndustriesDropDown.fulfilled, (state, action) => apolloIndustriesDDListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const apolloIndustriesDDListActions = apolloIndustriesDDListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = apolloIndustriesDDListAdapter.getSelectors();
export const selectState = (state: ApolloIndustriesDDListState) => state;
export const selectLoading = (state: ApolloIndustriesDDListState) => state.loading;
export const selectError = (state: ApolloIndustriesDDListState) => state.error;


