import {createSelector} from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import * as reducer from "./document_list_reducer";


const select = (state: IRootState) => state.jobs.details.document.jobDocument;
export const selectJobDocumentState = createSelector(select, reducer.selectJobDocumentState);
export const selectSingleJobDocumentStateById = (id: string | undefined | null) => createSelector(
    selectJobDocumentState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleJobDocumentState(state, id);
        return reducer.selectInitialSingleJobOrderContactCreateState();
    }
);