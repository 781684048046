import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../store";
import {selectState, selectSaveResponse,selectDeleteResponse} from "./engagement_reducer";

const select = (state: IRootState) => state.enagagementList;

export const selectEngagementListState=createSelector(select,selectState);
export const selectEngagementSavestate = createSelector(select,selectSaveResponse);
export const selectEngagementDeleteState = createSelector(select,selectDeleteResponse);

