import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { AppError, IBillingInvoicesReportData } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { IRecordPayment } from "../../../../interfaces/back_office_acnts_receivable";


export const getPendingInvoicesThunk = createAsyncThunk<
    IBillingInvoicesReportData[],
    void,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/accounts_receivable/record_payment/pending_invoices/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getPendingInvoices();
            return res.data as IBillingInvoicesReportData[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


export const createRecordPaymentThunk = createAsyncThunk<
    { object_id: string, status: string },
    { doc: IRecordPayment, files?: File[] | null},
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/accounts_receivable/record_payment/post",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.createRecordPayment(payload.doc);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getARUploadDocsURLsThunk = createAsyncThunk<
    Array<string>,
    { id: string, docs: any },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/accounts_receivable/record_payment/get_upload_urls/post",
    async (data, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.acntReceivableDocsUpload(data.id, data.docs);
            if (typeof res.data === 'string') return [];
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

