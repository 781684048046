import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { SalesMarketingAPI } from "../../../../apis/sales_marketing_api";
import { SendByEmail } from "../../../../interfaces/marketing_campaign";

export const sendDocumentByEmail = createAsyncThunk<
    string,
    SendByEmail,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-and-marketing/details/documents/documents/send-by-email-documents/post',
    async (data, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.CreateDocumentDownloadLinkByEmail(data);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const sendVideoByEmail = createAsyncThunk<
    string,
    SendByEmail,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-and-marketing/details/documents/video-upload/send-by-email-video/post',
    async (data, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.CreateVideoDownloadLinkByEmail(data);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

