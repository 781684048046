import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./talent_interview_status_reducer";

const select = (state: IRootState) => state.selectors.talentInterviewStatus;
export const selectTalentInterviewStatusState = createSelector(select, selectState);
export const selectTalentInterviewStatusList = createSelector(
    select,
    selectAll
);
export const selectTalentInterviewStatusEntities = createSelector(
    select,
    selectEntities
);

