import { createSelector } from "@reduxjs/toolkit";

import {
    selectState,
    selectJobOrderState,
    selectInitialSingleJobOrderState
} from "./kiniso_job_order_details_reducer";
import { IRootState } from "../../store";

const select = (state: IRootState) => state.kinisoJobOrders.kinisoJobOrderDetails;
export const selectJobOrderDetailsState = createSelector(select, selectState);

export const selectJobOrderDetailsByIdState = (id: string | undefined | null) => createSelector(
    selectJobOrderDetailsState,
    (state) => {
        if (id && state[id]) return selectJobOrderState(state, id);
        return selectInitialSingleJobOrderState();
    }
);

