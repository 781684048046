import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper, { FormDialogTilteHeader } from '../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, THEME } from '../../../enums';
import { OnBoardingIcon } from '../../../icons';
import { ITalentOnBoardingData } from '../../../interfaces';
import { getDownloadWOTCUrlThunk, getWOTCScreeningURLThunk, selectProfileState, selectWOTCState, useAppDispatch, useAppSelector } from '../../../redux/store';
import { CustomButton } from '../../../utils';
import './wotc.scss';
import { wotcSliceActions } from '../../../redux/onboarding_portal/wotc/wotc_reducer';
import ApiError from '../../../components/api_error';

interface Props {
    visible: boolean,
    type: string,
    title: string,
    token: string,
    state: ITalentOnBoardingData,
    successTitle: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: (type: string, value?: any) => void,
}

const WOTCPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        state,
        title,
        token,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const screeningURLState = useAppSelector((state) => selectWOTCState(state));

    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        dispatch(getWOTCScreeningURLThunk(token));
        return () => { }
    }, [])


    function handleClose() {
        closePopup(onClose);
        dispatch(wotcSliceActions.clearState());
    }
    const [redirectLoading, setRedirectLoading] = useState<boolean>(false);
    const handleRedirect = () => {
        // if (screeningURLState.loading === LoadingType.succeeded && screeningURLState.response !== null) {
            window.location.href = `${screeningURLState.response}`;
            setRedirectLoading(true)
            // window.open(`${screeningURLState.response}`,"_blank");
        // }
    }
    function handleClearError() {
        dispatch(wotcSliceActions.clearState());
    };
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClose}>
                <div className="wotc-container">
                    <div className="wotc-header">
                        <FormDialogTilteHeader
                            title={t('work_opportunity_tax_credit_program')}
                            subTitle={""}
                            subTitleStyle={{ fontWeight: 'normal' }}
                            titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="wotc-content">
                        <div className='scr-msg'>{t('redirect_to_cti_screening')}</div>
                        {screeningURLState.error &&
                            <div className="error-holder">
                                <ApiError message={screeningURLState.error.message} onClose={handleClearError} />

                            </div>
                        }
                    </div>
                    <div className="wotc-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-new">
                            <CustomButton
                                loading={redirectLoading}
                                textStyle={{
                                    textTransform: "capitalize",
                                }}
                                name={t("visit_cti")}
                                enable={screeningURLState.loading === LoadingType.succeeded && screeningURLState.response !== null}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleRedirect}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>

    );
    function closePopup(action?: () => void) {
        dispatch(wotcSliceActions.clearState());

        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default WOTCPopup;