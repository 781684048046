import { createAsyncThunk } from "@reduxjs/toolkit";
import { S3API } from "../../../apis/s3_api";
import {
    AppError,
    IResumeFileDownloadRequestQuery,
} from "../../../interfaces";
import { catchRequestError } from "../../../utils";

export const getResumeFileUrlForDownload = createAsyncThunk<
    string,
    IResumeFileDownloadRequestQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@files/resume-file/pre-signed-url-for-download/get',
    async (query, thunkAPI) => {
        try {
            const res = await S3API.getFileUrlForDownload(query)
            if (typeof res.data === 'string') return '';
            return res.data.url
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

