import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./week_ending_dates_reducer";

const select = (state: IRootState) => state.selectors.weekEndingDatesDropDown;
export const selectWeekEndingDatesState = createSelector(select, selectState);
export const selectWeekEndingDatesDDList = createSelector(
    select,
    selectAll
);

export const selectWeekEndingDatesDDEntities = createSelector(
    select,
    selectEntities
);  