import { createEntityAdapter, createSlice, EntityAdapter } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { IApolloContactsResults, IContactsApolloSearchResults } from "../../../interfaces/apollo_search_import";
import * as thunk from "./contacts_search_import_thunk";
import { AppError } from "../../../interfaces";

export interface ApolloContactsListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IApolloContactsResults | null,
    importContact: {
        error?: AppError | null,
        loading: LoadingType,
        response: any,
    },
    importTalent: {
        error?: AppError | null,
        loading: LoadingType,
        response: any,
    }
}

const importApolloContactsListAdapter: EntityAdapter<IContactsApolloSearchResults> = createEntityAdapter<IContactsApolloSearchResults>({
    selectId: (doc) => doc.id,
})

const initialState: ApolloContactsListState = {
    error: null,
    loading: LoadingType.idle,
    response:null,
    importContact: {
        error: null,
        loading: LoadingType.idle,
        response: null
    },
    importTalent: {
        error: null,
        loading: LoadingType.idle,
        response: null
    }
};

export const apolloContactsListSlice = createSlice({
    name: 'apolloContactsList',
    initialState: initialState,
    reducers: {
        clearApolloContactsListError(state) {
            return { ...state, error: null };
        },
        clearApolloContactsLoadingState(state) {
            return { ...state, loading: LoadingType.pending };
        },
        clearApolloImportContactsState(state) {
            return {
                ...state,
                importContact: {
                    ...state.importContact,
                    error: null,
                    loading: LoadingType.idle,
                    response: null
                }
            }
        },
        clearApolloImportTalentState(state) {
            return {
                ...state,
                importTalent: {
                    ...state.importTalent,
                    error: null,
                    loading: LoadingType.idle,
                    response: null
                }
            }
        },
        clearApolloImportContactsErrorState(state) {
            return {
                ...state,
                importContact: {
                    ...state.importContact,
                    error: null,
                }
            }
        },
        clearApolloImportTalentErrorState(state) {
            return {
                ...state,
                importTalent: {
                    ...state.importTalent,
                    error: null,
                }
            }
        },
        clearApolloContactsListState(state) {
            return {
                ...state, loading: LoadingType.idle, error: null,
                importContact: { ...state, loading: LoadingType.idle, error: null, response: null },
                importTalent: { ...state, loading: LoadingType.idle, error: null, response: null }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.appolloContactSearchPeopleThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.appolloContactSearchPeopleThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload }))
            .addCase(thunk.appolloContactSearchPeopleThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, response: action.payload }))

            .addCase(thunk.apolloContactImportPeopleThunk.pending, (state, action) => ({ ...state, importContact: { ...state.importContact, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.apolloContactImportPeopleThunk.rejected, (state, action) => ({ ...state, importContact: { ...state.importContact, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.apolloContactImportPeopleThunk.fulfilled, (state, action) => ({ ...state, importContact: { ...state.importContact, loading: LoadingType.succeeded, response: action.payload } }))

            .addCase(thunk.apolloTalentImportPeopleThunk.pending, (state, action) => ({ ...state, importTalent: { ...state.importTalent, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.apolloTalentImportPeopleThunk.rejected, (state, action) => ({ ...state, importTalent: { ...state.importTalent, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.apolloTalentImportPeopleThunk.fulfilled, (state, action) => ({ ...state, importTalent: { ...state.importTalent, loading: LoadingType.succeeded, response: action.payload } }))


            .addDefaultCase(state => ({ ...state }));
    }
});

export const apolloContactsListActions = apolloContactsListSlice.actions;
// export const { selectAll, selectEntities, selectIds, selectById } = importApolloContactsListAdapter.getSelectors();
export const selectState = (state: ApolloContactsListState) => state;
export const selectLoading = (state: ApolloContactsListState) => state.loading;
export const selectError = (state: ApolloContactsListState) => state.error;   