import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";
import { DashboardAPI } from "../../../../../apis";
import { IInBoundLeads, IInBoundLeadsSendInvite } from "../../../../../interfaces/inbound_leads";

export const getInBoundLeadsList = createAsyncThunk<
    Array<IInBoundLeads>,
    boolean,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/inbound_leads/inbound_leads_directory/get',
    async (archive, thunkAPI) => {
        try {
            const res = await DashboardAPI.getInboundLeads(false, archive);
            if (typeof res.data === 'string') return [];
            return res.data as IInBoundLeads[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getInBoundLeadsListCount = createAsyncThunk<
    number,
    boolean,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/inbound_leads/inbound_leads_directory/get-count/get',
    async (getCount, thunkAPI) => {
        try {
            const res = await DashboardAPI.getInboundLeads(getCount, false);
            if (typeof res.data === 'string') return 0;
            return res.data as number;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getInBoundLeadGetById = createAsyncThunk<
    IInBoundLeads,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/inbound_leads/inbound_leads_directory_by_id/get',
    async (inbound_id, thunkAPI) => {
        try {
            const res = await DashboardAPI.getInboundLeadGetById(inbound_id);
            if (typeof res.data === 'string') return 0;
            return res.data as IInBoundLeads;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const saveInBoundLeadSendInvite = createAsyncThunk<
    string,
    IInBoundLeadsSendInvite,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/inbound_leads/inbound_leads_directory_send_invite/post',
    async (payload, thunkAPI) => {
        try {
            const res = await DashboardAPI.postInboundLeadSendInvite(payload);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const saveInBoundLeadImport = createAsyncThunk<
    string,
    { lead_ids: Array<string>, import_type: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/inbound_leads/inbound_leads_directory_import/post',
    async (payload, thunkAPI) => {
        try {
            const res = await DashboardAPI.postInboundLeadImport(payload);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const saveInBoundLeadArchive = createAsyncThunk<
    string,
    Array<string>,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/inbound_leads/inbound_leads_directory_archive/post',
    async (payload, thunkAPI) => {
        try {
            const res = await DashboardAPI.postInboundLeadArchive(payload);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);