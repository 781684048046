import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./bank_account_thunk";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";

export interface BankAccountListState extends EntityState<AdminCenterBackOfficeType> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const BankAccountListAdapter: EntityAdapter<AdminCenterBackOfficeType> = createEntityAdapter<AdminCenterBackOfficeType>({
    selectId: (doc) => doc.id
})

const initialState: BankAccountListState = BankAccountListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const BankAccountListSlice = createSlice({
    name: 'BankAccountList',
    initialState: initialState,
    reducers: {
        clearBankAccountListError(state){
            return {...state, error: null};
        },
        clearBankAccountListState(state){
            return BankAccountListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearBankAccountCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearBankAccountUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearBankAccountDeleteState(state) {
            return { ...state, remove : {...initialState.remove} };
        },
        clearBankAccountCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearBankAccountUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearBankAccountDeleteStateError(state) {
            return { ...state, remove : { ...initialState.remove , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getBankAccount.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getBankAccount.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getBankAccount.fulfilled, (state, action) => BankAccountListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createBankAccount.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createBankAccount.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createBankAccount.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateBankAccount.pending, (state, action) => ({...state, update: {...state.update, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateBankAccount.rejected, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateBankAccount.fulfilled, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            
            .addCase(thunk.deleteBankAccount.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteBankAccount.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteBankAccount.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const bankAccountListActions = BankAccountListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = BankAccountListAdapter.getSelectors();
export const selectState = (state: BankAccountListState) => state;
export const selectLoading = (state: BankAccountListState) => state.loading;
export const selectError = (state: BankAccountListState) => state.error;


