import { useTranslation } from "react-i18next";
import { IBillingBatch, IWeekEndingDatesDropdown } from "../../../../interfaces";
import { FormInput } from "../../../../utils";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { getDateString } from "../../../../variables";
import { DateInput } from "../../../../components/date_input/date_input";

interface Props {
    weekEndingDatesList: IWeekEndingDatesDropdown[];
    selectedWeekEndingDate: string;
    updateBatchProp: (key: string, value: any) => void;
    handleWeekEndChange: (value: any) => void;
    batch: IBillingBatch;
}
const CreateBillingStepOne = (props: Props) => {
    const {
        batch,
        selectedWeekEndingDate,
        weekEndingDatesList,
        handleWeekEndChange,
        updateBatchProp,
    } = props;
    const { t } = useTranslation();
   
    return (
        <div className="cb-step-one-container">
            <div className="so-form-input-row">
                <div className="so-form-input">
                    <FormInput
                        id={"action"}
                        onChange={updateBatchProp}
                        required={false}
                        placeholder={""}
                        type={"text"}
                        value={batch.action}
                        label={t("action")}
                        disabled
                    />
                </div>
                <div className="so-form-input">
                    {/* <FormInput
                        id={"invoice_date"}
                        onChange={updateBatchProp}
                        required={false}
                        placeholder={""}
                        type={"text"}
                        value={getDateString(batch.invoice_date, "mm/dd/yyyy")}
                        label={t("invoice_date")}
                        disabled
                    /> */}
                    <DateInput
                        id={"invoice_date"}
                        onChange={updateBatchProp}
                        placeholder={""}
                        value={batch.invoice_date}
                        label={t("invoice_date")}
                        required={true}
                    />
                </div>
                <div className="so-form-input">
                    <CustomFormSelect
                        list={weekEndingDatesList.map((doc) => ({
                            label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                            value: doc.id,
                        }))}
                        customStyle={{ width: "100%" }}
                        name={"week_ending_date"}
                        value={batch.week_accounting_id}
                        label={t("week_ending_date")}
                        placeholder={"Select"}
                        onChange={handleWeekEndChange}
                        isClearable={false}
                    />
                </div>
            </div>
            <div>
                <FormInput
                    id={"batch_description"}
                    onChange={updateBatchProp}
                    type={"text"}
                    value={batch.batch_description}
                    label={"Description"}
                    placeholder="Enter a batch description here. This will not appear on your invoices. (optional)"
                    required={false}
                    customStyle={{ minWidth: "15vw" }}
                    multiline={true}
                />
            </div>
        </div>
    );
}

export default CreateBillingStepOne;