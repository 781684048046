// Search and import people from ApolloAI

import { IContactsApolloSearch, IContactApolloImport, ICompanyApolloImport } from "../interfaces/apollo_search_import"
import interceptor from "./interceptor"
import UrlBuilder from "./url_builder"


export const ApolloSearcImportAPI = {
    appolloContactSearchPeople(data: IContactsApolloSearch) {
        return interceptor.post(`${UrlBuilder.apolloContactsSearchAPI}?with_pagination=true`, data)
    },
    apolloContactImportPeople(data: IContactApolloImport[], need_direct_dial: boolean, force_import: boolean) {
        return interceptor.post(`${UrlBuilder.apolloContactsImportAPI}?need_direct_dial=${need_direct_dial}&force_import=${force_import}`, data)
    },
    appolloCompaniesSearch(data: IContactsApolloSearch) {
        return interceptor.post(`${UrlBuilder.apolloCompaniesSearchAPI}?with_pagination=true`, data)
    },
    apolloCompaniesImport(data: ICompanyApolloImport[], force_import: boolean) {
        return interceptor.post(`${UrlBuilder.apolloCompaniesImportAPI}?force_import=${force_import}`, data)
    },
    apolloTalentImport(data: IContactApolloImport[], need_direct_dial: boolean, force_import: boolean) {
        return interceptor.post(`${UrlBuilder.apolloTalentImportAPI}?need_direct_dial=${need_direct_dial}&force_import=${force_import}`, data)
    },
    apolloGetIndustries() {
        return interceptor.get(`${UrlBuilder.apolloIndustriesDropdown}`)
    },
    apolloGetCreditInfo() {
        return interceptor.get(`${UrlBuilder.apolloGetCredits}`)
    },
    apolloAddCredits(no_credits: Number, num_of_mobile_credits: Number) {
        return interceptor.get(`${UrlBuilder.apolloAddCredits}?num_of_credits=${no_credits}&num_of_mobile_credits=${num_of_mobile_credits}`)
    },
    appolloCompanyDetails(domain:string) {
        return interceptor.post(`${UrlBuilder.apolloCompaniesById}?domain=${domain}`)
    },
    refreshTalent(talent_id:string, need_direct_dial:boolean){
        return interceptor.post(`${UrlBuilder.refreshTalent}?talent_id=${talent_id}&need_direct_dial=${need_direct_dial}`);
    },
    refreshContact(contact_id:string, need_direct_dial:boolean){
        return interceptor.post(`${UrlBuilder.refreshContact}?contact_id=${contact_id}&need_direct_dial=${need_direct_dial}`);
    },
    refreshCompany(company_id:string, need_direct_dial:boolean){
        return interceptor.post(`${UrlBuilder.refreshContact}?company_id=${company_id}&need_direct_dial=${need_direct_dial}`);
    },
}



