import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getRoleUsersList, postSnackbarMessage, selectRoleUsersList, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import ApiError from "../../../../components/api_error";
import { CustomButton, FormInput } from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import { TeamManagementTeamsDetailsState } from "../../../../redux/admin_center/team_management/team_services/team_services_reducer";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { RoleManagementIcon, TeamManagementIcon } from "../../../../icons";
import DragAndDrop from "../../../../components/drag_and_drop/drag_and_drop";
import './add_team.scss'
import { CreateTeamManagementTeams, UpdateTeamManagementTeams } from "../../../../interfaces/team_services";
import { createTeamManagementTeams, updateTeamManagementTeams } from "../../../../redux/admin_center/team_management";
import { AppError } from "../../../../interfaces";

interface AddTMTeamProps {
    visible: boolean,
    TMTeamState?: TeamManagementTeamsDetailsState,
    teamId?: string;
    onClose: () => void,
    error: AppError | null | undefined;
    loading: LoadingType;
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

const getTeamFormData = (value: UpdateTeamManagementTeams): CreateTeamManagementTeams => {
    return {
        modified_by: value.modified_by ?? "",
        created_by: value.created_by ?? "",
        modified_date: value.modified_date ?? 0,
        id: value.id ?? "",
        name: value.name ?? "",
        pic_file_name: value.pic_file_name ?? "",
        is_deleted: value.is_deleted ?? false,
        members_ids: value.members_ids ?? [],
    }
}
const initialState: CreateTeamManagementTeams = {
    modified_by: "",
    created_by: "",
    modified_date: 0,
    id: "",
    name: "",
    pic_file_name: "",
    is_deleted: false,
    members_ids: [],
}

export const AddTeams = (props: AddTMTeamProps) => {
    const {
        visible,
        TMTeamState,
        teamId,
        error,
        loading,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [addMember, setAddMember] = useState<string[]>([]);
    ///Files
    const [TeamLogo, setTeamLogo] = useState<File | null>(null);
    const [snackBarMessage, setSnackBarMessage] = useState("");

    const actionRef = useRef<ActionDialogHolderType>(null);
    const usersList = useAppSelector((state) => selectRoleUsersList(state));
  
    const [addTeamState, setAddTeamState] = useState<CreateTeamManagementTeams>(initialState)
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>();

    useEffect(() => {
        dispatch(getRoleUsersList())
    }, [])
    useEffect(() => {
        if (teamId && TMTeamState?.TMTeamMembers?.loading === LoadingType.succeeded && Object.keys(TMTeamState?.TMTeamMembers?.TeamManagementTeamMembers!).length !== 0) {
            setAddTeamState(getTeamFormData(TMTeamState?.TMTeamMembers?.TeamManagementTeamMembers!))
        }
    }, [teamId, Object.keys(TMTeamState?.TMTeamMembers?.TeamManagementTeamMembers!).length, TMTeamState?.TMTeamMembers?.loading])

    useEffect(() => {
        if (TMTeamState?.CreateTeam?.loading === LoadingType.succeeded) {
            if (snackBarMessage != "")
                dispatch(postSnackbarMessage(snackBarMessage ?? null));
        }
        if (TMTeamState?.UpdateTeam?.loading === LoadingType.succeeded) {
            if (snackBarMessage != "")
                dispatch(postSnackbarMessage(snackBarMessage ?? null));
        }
    }, [TMTeamState?.UpdateTeam?.loading, TMTeamState?.CreateTeam?.loading])

    function handleFieldChange(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setAddTeamState({
                ...addTeamState,
                [parent]: { ...addTeamState[parent], [fieldId]: value },
            });
        } else {
            setAddTeamState({ ...addTeamState, [fieldId]: value });
        }
    }

    function _IsValid() {
        // if (teamId) {
        const { name, members_ids } = addTeamState;
        if (name === '' || members_ids.length <= 0) return false;
        return true;
        // }
        // else {
        //     const { name, members_ids } = addTeamState;
        //     if (name === '' || members_ids.length <= 0) return false;
        //     // if (!TeamLogo) return false;
        //     return true;
        // }
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        ><DialogWrapper onClose={onClose}>
                <div className="add-team-container">
                    <div className="add-teams-container">
                        <div className="add-role-container">
                            <FormDialogTilteHeader
                                title={`${teamId ? t('update_team') : t('add_team')}`}
                                titleIcon={<TeamManagementIcon width={"100%"} height={"100%"} />}
                            />
                            <div className="add-role-content">
                                <FormInput
                                    id={"name"}
                                    onChange={handleFieldChange}
                                    type={"text"}
                                    value={addTeamState?.name}
                                    label={t("team_name")}
                                    placeholder=""
                                    required
                                    validations={addTeamFormValidators}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                                <DragAndDrop
                                    type={"large"}
                                    // label={teamId ? t('update_team_logo') : t('team_logo')}
                                    label={t('team_logo')}
                                    selectedFile={TeamLogo}
                                    onChange={setTeamLogo}
                                    onRemove={() => setTeamLogo(null)}
                                    accept={"image/*"}
                                    multiple={true}
                                    placeholder={"Select or drag a file"}
                                />
                                <CustomMultiFormSelect
                                    label={t("add_team_members")}
                                    name={"add_team_member"}
                                    list={usersList?.map((doc) => ({
                                        label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                                        value: doc.id,
                                    }))}
                                    onChange={(val) => {
                                        setAddTeamState((prevState: any) => ({
                                            ...prevState,
                                            members_ids: val
                                        }))
                                    }}
                                    required={true}
                                    placeholder={t("select")}
                                    value={addTeamState.members_ids}
                                    validations={addTeamFormValidators}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                                {error && <div className={"error-section"}>
                                    <ApiError message={error?.message} onClose={onClose} />
                                </div>}
                            </div>
                            <div className="add-role-action-holder">
                                <div className="add-role-action">
                                    <div className="btn-no">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: 'capitalize' }}
                                            name={t('cancel')}
                                            enable={true}
                                            backgroundColor={THEME.secondaryColor4}
                                            onClick={() => closePopup(onClose)}
                                        />
                                    </div>
                                    <div className="btn-yes">
                                        <CustomButton
                                            loading={loading === LoadingType.pending}
                                            textStyle={{ textTransform: 'capitalize' }}
                                            name={t('save')}
                                            // enable={_IsValid()}
                                            enable={true}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={() => addNewTeam(teamId)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>

    );

    function addNewTeam(id: string | undefined) {
        setCheckValidationOnSubmit(true);
        if (_IsValid()) {
            if (id) {
                const payload = {
                    ...addTeamState,
                    pic_file_name: (TeamLogo as File)?.name === '' ? (TeamLogo as File).name : ""
                }
                dispatch(updateTeamManagementTeams({ team_id: id, data: payload, file: TeamLogo }))
                setSnackBarMessage("Team Updated Successfully!");
            }
            else {
                const payload = {
                    ...addTeamState,
                }
                dispatch(createTeamManagementTeams({ data: payload, file: TeamLogo }))
                setSnackBarMessage("Team Created Successfully!");
            }
            setCheckValidationOnSubmit(false);
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function addTeamFormValidators(value: { text: string, field: string }) {
        switch (value.field) {
            case "name": {
                if (value.text == '') return t('validators.required');
                return null;
            }
            case "add_team_member": {
                if (value.text == '') return t('validators.required');
                return null;
            }

            default: {
                return null;
            }
        }
    }

}
export default AddTeams;