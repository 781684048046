import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "./time_cards_list_reducer";
import { IRootState } from "../../../../store";


const select = (state: IRootState) => state.backOffice.timeCards.timecardsList;
export const selectTimecardsListState = createSelector(
    select,
    selectState
);
