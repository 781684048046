import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectCreateState, selectState, selectUpdate, selectFilesState, selectFilesTypeState, selectInitialFilesTypeState } from "./manage_job_reducer";

const select = (state: IRootState) => state.jobs.universalDirectory.manageJob;
export const selectManageJobState = createSelector(select, selectState);
export const selectManageJobCreateState = createSelector(selectManageJobState, selectCreateState);
export const selectManageJobUpdateState = createSelector(selectManageJobState, selectUpdate);
export const selectManageJobFilesState = createSelector(selectManageJobState, selectFilesState);

export const selectManageJobFilesStateByType = (type: string | undefined | null) => createSelector(
    selectManageJobState,
    (state) => {
        if (type && state.files[type]) return selectFilesTypeState(state, type);
        return selectInitialFilesTypeState();
    }
);
