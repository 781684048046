import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IEmpOnboardingDocsReviewStatus, IUpdateEmpOnboardingDocsReviewStatus } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { EmployeeDashboardAPI } from "../../../apis/employee_dashboard_api";


export const getEmpODRSThunk = createAsyncThunk<
    IEmpOnboardingDocsReviewStatus,
    { talent_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee-dashboard/details/onboarding-and-docs/get',
    async (payload, thunkAPI) => {
        try {
            const res = await EmployeeDashboardAPI.getEmpOnboardingReviewStatus(payload.talent_id);
            return res.data as IEmpOnboardingDocsReviewStatus;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateEmpODRSThunk = createAsyncThunk<
    string,
    IUpdateEmpOnboardingDocsReviewStatus,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>(
    "@employee-dashboard/details/onboarding-and-docs/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await EmployeeDashboardAPI.updateEmpOnboardingReviewStatus(payload);
            thunkAPI.dispatch(getEmpODRSThunk({ talent_id: payload.talent_id }))
            // if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
