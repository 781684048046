import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./payroll_manage_batch_thunk";
import { AppError, PayrollBatch } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";

export interface PayrollManageBatchState {
  error?: AppError | null;
  loading: LoadingType;
  response: PayrollBatch | null;
  deleteBatch: {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
  },
  post: {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
  }
}

const initialState: PayrollManageBatchState = {
  error: null,
  loading: LoadingType.idle,
  response: null,
  deleteBatch: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  },
  post: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  }
};

export const PayrollManageBatchSlice = createSlice({
  name: "payrollManageBatch",
  initialState: initialState,
  reducers: {
    clearState(state) {
      return { ...initialState };
    },
    clearErrorState(state) {
      return { ...initialState, error: null };
    },
    clearPostErrorState(state) {
      return {
        ...initialState,
        post: { ...initialState.post, error: null }
      }

    },
    clearDeleteBatchState(state) {
      return {
        ...state, deleteBatch: { ...initialState.deleteBatch },
      };
    },
    clearDeleteBatchErrorState(state) {
      return {
        ...state, deleteBatch: { ...initialState.deleteBatch, error: null, },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.createPayrollBatchThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.createPayrollBatchThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.createPayrollBatchThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        response: action.payload,
      }))
      .addCase(thunk.deletePayrollBatchThunk.pending, (state, action) => ({ ...state, deleteBatch: { ...state.deleteBatch, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.deletePayrollBatchThunk.rejected, (state, action) => ({ ...state, deleteBatch: { ...state.deleteBatch, loading: LoadingType.failed, error: action.payload, } }))
      .addCase(thunk.deletePayrollBatchThunk.fulfilled, (state, action) => ({ ...state, deleteBatch: { ...state.deleteBatch, loading: LoadingType.succeeded, error: null, response: action.payload } }))
      .addCase(thunk.postPayrollBatchThunk.pending, (state, action) => ({ ...state, post: { ...state.post, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.postPayrollBatchThunk.rejected, (state, action) => ({ ...state, post: { ...state.post, loading: LoadingType.failed, error: action.payload, } }))
      .addCase(thunk.postPayrollBatchThunk.fulfilled, (state, action) => ({ ...state, post: { ...state.post, loading: LoadingType.succeeded, error: null, response: action.payload } }))

      .addDefaultCase((state) => ({ ...state }));
  },
});
export const PayrollManageBatchSliceActions = PayrollManageBatchSlice.actions;
export const selectState = (state: PayrollManageBatchState) => state;
