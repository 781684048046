import { IconButton, Snackbar } from "@material-ui/core";
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as MatIcons from '@material-ui/icons';
import MainPageHolder from "../others/main_page_holder";
import { snackbarActions } from "../redux/snackbar/snackbar_reducer";
import { useAppDispatch, useAppSelector, selectSnackbarState } from "../redux/store";
import { AppRoutes } from "../routes";
import { AdminCenterNavigation } from "./admin_center/admin_center_nav";
import { AssignmentsNavigation } from "./assignments/assignments_nav";
import { BackOfficeNavigation } from "./back_office/back_office_nav";
import { ClientPortalNavigation } from "./client_portal/client_portal_nav";
import { CompaniesNavigation } from "./companies/companies_nav";
import { ContactsNavigation } from "./contacts/contacts_nav";
import { DashboardNavigation } from "./dashboard/dashboard_nav";
import { EmployeePortalNavigation } from "./employee_portal/employee_portal_nav";
import { JobNavigation } from "./jobs/jobs_nav";
import { ReportCenterNavigation } from "./report_center/report_center_nav";
import { RiskManagementNavigation } from "./risk_management/risk_management";
import { SalesAndMarketingNavigation } from "./sales_marketing/sales_marketing_nav";
import { TalentNavigation } from "./talent/talent_nav";
import { AdminOnBoardingNavigation } from "./admin_center/admin_center_onboarding_nav";
import { MyProfileNavigation } from "./my_profile/my_profile_nav";
import { ApolloSearchImportNavigation } from "./apollo_search_import/apollo_search_import_nav";
import { MasterTaxNavigation } from "./admin_center/master_tax_page_nav";



export const MainAppRoute = (props) => {
    const dispatch = useAppDispatch();
    return (
        <>
            <MainPageHolder>
                <Switch>
                    {/* Dashboard */}
                    <Route path={AppRoutes.dashboardPage} component={DashboardNavigation} />
                    {/* Sales & Marketing */}
                    <Route path={AppRoutes.salesAndMarketingPage} component={SalesAndMarketingNavigation} />
                    {/* Companies */}
                    <Route path={AppRoutes.companiesPage} component={CompaniesNavigation} />
                    {/* Contacts */}
                    <Route path={AppRoutes.contactsPage} component={ContactsNavigation} />
                    {/* Talent */}
                    <Route path={AppRoutes.talentPage} component={TalentNavigation} />
                    {/* Jobs */}
                    <Route path={AppRoutes.jobsPage} component={JobNavigation} />
                    {/* Assignments */}
                    <Route path={AppRoutes.assignmentsPage} component={AssignmentsNavigation} />
                    {/* Report center */}
                    <Route path={AppRoutes.reportCenterPage} component={ReportCenterNavigation} />
                    {/* Back office */}
                    <Route path={AppRoutes.backOfficePage} component={BackOfficeNavigation} />
                    {/* Risk Management */}
                    <Route path={AppRoutes.riskManagementPage} component={RiskManagementNavigation} />
                    {/* Employee Portal */}
                    <Route path={AppRoutes.employeePortalPage} component={EmployeePortalNavigation} />
                    {/* Client Portal */}
                    <Route path={AppRoutes.clientPortalPage} component={ClientPortalNavigation} />
                    {/* Admin center */}
                    <Route path={AppRoutes.adminCenterPage} component={AdminCenterNavigation} />
                    {/* Admin center Onboarding  */}
                    <Route path={AppRoutes.systemConfigurationOnboarding} component={AdminOnBoardingNavigation} />
                    {/* My Profile Dashboard */}
                    <Route path={AppRoutes.myProfileDashboardPage} component={MyProfileNavigation} />
                    <Route path={AppRoutes.apolloSearchImport} component={ApolloSearchImportNavigation} />
                    <Route path={AppRoutes.integrationPartnersMasterTax} component={MasterTaxNavigation} />


                    <Route path={'/'} render={({ location }) => (
                        <Redirect
                            to={{
                                pathname: AppRoutes.dashboardPage,
                                state: { from: location }
                            }}
                        />
                    )} />
                </Switch>
            </MainPageHolder>
        </>
    );
}
