import { createAsyncThunk } from "@reduxjs/toolkit";
import { KinisoJobOrdersAPI } from "../../../apis";
import { IKinisoJobOrderResponse, IKinisoJobDetail } from "../../../interfaces/kiniso_job_orders";
import { AppError } from "../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../utils";


export const getAllKinisoJobOrderByIdThunk = createAsyncThunk<
    IKinisoJobOrderResponse,
    IKinisoJobDetail,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@kiniso-job-orders/get-jobs/job_details/post',
    async (data, thunkAPI) => {
        try {
            const res = await KinisoJobOrdersAPI.getAllJObOrdersList(data);
         
            return res.data as IKinisoJobOrderResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);