import { useState } from 'react';
import { UserIcon } from '../icons';
import './user_badge.scss';
import UserMenuList from './user_menu_list';

interface Props {
    displayEmail: string,
    displayName: string,
    uri?: string | null,
    talentId?: string,
    onLogout: () => void,
    onProfileUpdate: () => void,
    onProfile: () => void,
    onPasswordUpdate: () => void
}

const UserBadge = (props: Props) => {
    const [userMenu, setUserMenu] = useState(false);

    return (
        <div className="ub-container" onMouseEnter={userMenuAppear} onMouseLeave={userMenuDisapear}>
            <div className="ub-avatar background-img" style={props.uri ? { backgroundImage: `url(${props.uri})` } : { backgroundImage: `url(${UserIcon})` }}></div>
            {userMenu && <div className="ub-user-menu-list">
                <div className="ub-pyramid"></div>
                <UserMenuList
                    displayEmail={props.displayEmail}
                    displayName={props.displayName}
                    talentId={props.talentId}
                    onProfile={props.onProfile}
                    onLogout={props.onLogout}
                    onProfileUpdate={props.onProfileUpdate}
                    onPasswordUpdate={props.onPasswordUpdate} />
            </div>}
        </div>
    );

    function userMenuAppear() {
        setUserMenu(true);
    }
    function userMenuDisapear() {
        setUserMenu(false);
    }

}



export default UserBadge;