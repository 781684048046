import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as thunk from './back_office_direct_depost_thunk';
import { AppError, ITalentBackOfficePayrollSetup } from "../../../../../interfaces";
import { LoadingType } from "../../../../../enums";

interface UpdateState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialUpdateState: UpdateState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

export interface SingleTalentPRSDDState {
    data: ITalentBackOfficePayrollSetup | null,
    error?: AppError | null,
    loading: LoadingType,
    update: UpdateState
};

export interface TalentPRSDDState {
    [key: string]: SingleTalentPRSDDState,
}

const initialState: TalentPRSDDState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

export const talentPRDDsetupSlice = createSlice({
    name: 'talentPRDirectDepositSetup',
    initialState: initialState,
    reducers: {
        clearTalentBOPRstateError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    update: { ...initialUpdateState },
                }
            };
        },
        initializeState(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    loading: LoadingType.idle,
                    error: null,
                    update: { ...initialUpdateState },
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getTalentBOPayrollDirectDepositThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getLoadingState(state[action.meta.arg])
                    }
                })
            )
            .addCase(
                thunk.getTalentBOPayrollDirectDepositThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getErrorState(state[action.meta.arg], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getTalentBOPayrollDirectDepositThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getSuccessState(state[action.meta.arg], action.payload),
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentPRDDsetupSliceActions = talentPRDDsetupSlice.actions;
export const selectState = (state: TalentPRSDDState) => state;
export const selectSingleTalentPRSDDState = (state: TalentPRSDDState, id: string): SingleTalentPRSDDState => state[id];
export const selectInitialSingleTalentPRSDDState = (): SingleTalentPRSDDState => ({
    data: null,
    error: null,
    loading: LoadingType.idle,
    update: initialUpdateState
});