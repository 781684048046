
import { useEffect, useState } from 'react';
import BillingScheduleAdditionalItems from './billing_schedule_additional_items/billing_schedule_additional_items';
import './company_back_office_format_setup.scss';
import CreditProfile from './credit_profile/credit_profile';
import FinanceCollections from './finance_collections/finance_collections';
import InvoiceFormat from './invoice_format/invoice_format';
import { useTranslation } from 'react-i18next';
import { getCompanyBackOfficeSetupThunk, getCompanyDepartments, getDepartmentsDropdown, postSnackbarMessage, selectDepartmentsDropdownList, selectSingleCompanyBackOfficeSetupById, selectSingleCompanyDepartmentsStateById, updateCompanyBackOfficeSetupThunk, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { RouteComponentProps } from 'react-router-dom';
import { ICompanyBackOfficeCreditProfile, ICompanyBackOfficeSetup } from '../../../../interfaces';
import { LoadingType } from '../../../../enums';
import { companyBackOfficeSetupSliceActions } from '../../../../redux/companies/details/back_office_format_and_setup/backoffice_setup_reducer';
import { SpinnerScreen, convertNumberToMoney } from '../../../../utils';
import { selectPaymentTermsList } from '../../../../redux/admin_center/back_office';


interface Props extends RouteComponentProps<any> { }

const PageLoading = () => {
    return (
        <div className="table-loading">
            <SpinnerScreen />
        </div>
    );
}
const CompanyBackOfficeFormatSetup: React.FunctionComponent<Props> = (props) => {
    const { match } = props;
    const { params } = match;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const companyId = params.id;
    const departmentId = params.departmentId;
    const paramID = departmentId ? departmentId : companyId;

    const backOfficeSetupState = useAppSelector((state) => selectSingleCompanyBackOfficeSetupById(paramID)(state));

    const updateBackOfficeSetupState = backOfficeSetupState.update;
    const departmentsList = useAppSelector((state) => selectDepartmentsDropdownList(state));
    const paymentTermsList = useAppSelector((state) => selectPaymentTermsList(state));

    const [findParent, setFindParent] = useState<{ finance_collections: boolean, billing_schedule_and_additional_items: boolean, invoice_format: boolean, credit_profile: boolean }>({ finance_collections: false, billing_schedule_and_additional_items: false, invoice_format: false, credit_profile: false })

    const invoiceStyles = [
        {
            "title": "Kiniso Format",
            "value": "Kiniso Format"
        },
    ]
    const groupingOptions = [
        {
            "title": "Department Name",
            "value": "Department Name"
        },
        {
            "title": "Talent Name",
            "value": "Talent Name"
        },
        {
            "title": "Week Worked",
            "value": "Week Worked"
        },
    ]
    const departmentNameOption = groupingOptions.find(option => option.value === "Department Name");

    useEffect(() => {
        dispatch(getCompanyBackOfficeSetupThunk(departmentId ? { department_id: departmentId } : { company_id: companyId }));
        dispatch(getDepartmentsDropdown(companyId));

    }, []);
    const getBackOfficeSetUpForm = (value?: ICompanyBackOfficeSetup | null): ICompanyBackOfficeSetup => {
        if (value) {
            return {
                id: value ? value.id : '',
                company_id: value ? value.company_id : '',
                department_id: value ? value.department_id : '',

                finance_collections: {
                    finance_charge: value.finance_collections?.finance_charge ?? 0,
                    credit_limit: value.finance_collections?.credit_limit ?? 0,
                    warning_for_credit_limit: value.finance_collections?.warning_for_credit_limit ?? 0,
                    email_statements_to: value.finance_collections?.email_statements_to ?? "",
                    include_finance_charges: value.finance_collections?.include_finance_charges ?? false,
                    email_monthly_statements: value.finance_collections?.email_monthly_statements ?? false,
                },
                billing_schedule_and_additional_items: {
                    billing_schedule: value?.billing_schedule_and_additional_items?.billing_schedule,
                    additional_billing: value?.billing_schedule_and_additional_items?.additional_billing
                },
                invoice_format: {
                    ...value?.invoice_format,
                    invoice_to_department_id: value.invoice_format?.invoice_to_department_id ?? departmentId,
                    invoice_style_id: value.invoice_format?.invoice_style_id ?? invoiceStyles[0].value,
                    separate_invoice_by_value: value.invoice_format?.separate_invoice_by_value ?? departmentNameOption?.value,
                    group_invoice_by_value: value.invoice_format?.group_invoice_by_value ?? departmentNameOption?.value,
                    sort_invoice_by_value: value.invoice_format?.sort_invoice_by_value ?? departmentNameOption?.value,
                    display_items_value: value.invoice_format?.display_items_value ?? departmentNameOption?.value,
                },
                credit_profile: value?.credit_profile
            }
        }
        return {
            id: "",
            company_id: companyId,
            department_id: departmentId,
            finance_collections: {
                finance_charge: 0,
                credit_limit: 0,
                warning_for_credit_limit: 0,
                email_statements_to: "",
                include_finance_charges: false,
                email_monthly_statements: false,
            },
            billing_schedule_and_additional_items: {
                billing_schedule: {
                    billing_period_id: "",
                    billing_period_value: "",
                    payment_terms_id: "",
                    payment_terms_value: "",
                    mark_up: 0,
                },
                additional_billing: {
                    transaction_type_id: "",
                    transaction_type_value: "",
                    rate_id: "",
                    rate_value: "",
                    geo_code_id: "",
                    geo_code_value: "",
                }
            },
            invoice_format: {
                invoice_style_id: invoiceStyles[0].value ?? "",
                invoice_style_value: "",
                invoice_attention_to: "",
                invoice_to_department_id: departmentId ?? "",
                invoice_to_department_value: "",
                // separate_invoice_by_id: "",
                separate_invoice_by_value: departmentNameOption?.value ?? "",
                // group_invoice_by_id: "",
                group_invoice_by_value: departmentNameOption?.value ?? "",
                // sort_invoice_by_id: "",
                sort_invoice_by_value: departmentNameOption?.value ?? "",
                // display_items_id: "",
                display_items_value: departmentNameOption?.value ?? "",
                email_invoice_to: "",
                bill_to_address_street_address: "",
                bill_to_address_city: "",
                bill_to_address_state: "",
                bill_to_address_zip_code: "",
                print_invoice: false,
                display_timecard: false,
                display_daily_hours: false,
            },
            credit_profile: [{
                credit_limit: 0,
                default_payment_option_id: "",
                default_payment_option_value: "",
                currently_on_hold: false,
                warning_by_dollar: 0,
                warning_by_percentage: 0,
                comment: "",
            }],

        }
    };
    const [formState, setFormState] = useState<ICompanyBackOfficeSetup>(getBackOfficeSetUpForm());

    useEffect(() => {
        if (backOfficeSetupState && backOfficeSetupState.loading === LoadingType.succeeded) {
            setFormState(getBackOfficeSetUpForm(backOfficeSetupState.data));
            handleUpdateClearState();
        }
    }, [backOfficeSetupState, backOfficeSetupState.loading]);

    useEffect(() => {
        if (updateBackOfficeSetupState !== undefined && updateBackOfficeSetupState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(updateBackOfficeSetupState.response ?? null));
        }
        if (updateBackOfficeSetupState !== undefined && updateBackOfficeSetupState.loading === LoadingType.pending) {
            setFormState(formState);
        }
    }, [updateBackOfficeSetupState, updateBackOfficeSetupState?.loading]);
    useEffect(() => {
        if (paymentTermsList.length > 0) {
            const newPaymentTermId = paymentTermsList[0].id;
            if (formState.billing_schedule_and_additional_items?.billing_schedule?.payment_terms_id === "" || formState.billing_schedule_and_additional_items?.billing_schedule?.payment_terms_id === null) {
                setFormState({
                    ...formState,
                    billing_schedule_and_additional_items: {
                        ...formState.billing_schedule_and_additional_items,
                        billing_schedule: {
                            ...formState.billing_schedule_and_additional_items?.billing_schedule,
                            payment_terms_id: newPaymentTermId
                        }
                    }
                });
            }
        }
    }, [paymentTermsList, formState.billing_schedule_and_additional_items?.billing_schedule?.payment_terms_id]);
    const handleEditCancel = () => {
        if (backOfficeSetupState && backOfficeSetupState.data) {
            setFindParent({ finance_collections: false, billing_schedule_and_additional_items: false, invoice_format: false, credit_profile: false });
            setFormState(getBackOfficeSetUpForm(backOfficeSetupState.data));
        }
    }

    function handleFieldChange(fieldId: string, value: any, parent: string, subParent?: string) {
        if (subParent) {
            let markUpVal = value;
            if (fieldId === "mark_up") {
                markUpVal = value === "" ? null : value;
            }

            setFormState({
                ...formState,
                [parent]: {
                    ...formState[parent],
                    [subParent]: {
                        ...formState[parent][subParent],
                        [fieldId]: markUpVal
                    },
                },
            });
        }
        else {
            if (fieldId === "finance_charge" || fieldId === "rate_value") {
                if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
                    setFormState({
                        ...formState,
                        [parent]: { ...formState[parent], [fieldId]: value },
                    });
                }
            }
            else {
                setFormState({
                    ...formState,
                    [parent]: { ...formState[parent], [fieldId]: value },
                });
            }
        }
    };

    const handleSubmit = (parent: string) => {
        setFindParent((prevFindParent) => ({
            ...(Object.fromEntries(
                Object.entries(prevFindParent).map(([key]) => [key, key === parent])
            ) as {
                finance_collections: boolean;
                billing_schedule_and_additional_items: boolean;
                invoice_format: boolean;
                credit_profile: boolean;
            }),
        }));
        if (parent === 'finance_collections') {
            dispatch(updateCompanyBackOfficeSetupThunk({
                data: {
                    ...formState,
                    [parent]: {
                        ...formState[parent],
                        credit_limit: convertNumberToMoney(formState.finance_collections?.credit_limit, 'number') as number,
                        finance_charge: convertNumberToMoney(formState.finance_collections?.finance_charge, 'number') as number,
                        warning_for_credit_limit: convertNumberToMoney(formState.finance_collections?.warning_for_credit_limit, 'number') as number,
                    }
                }, isNotCredit: true
            }));
        }
        else {
            dispatch(updateCompanyBackOfficeSetupThunk({ data: formState, isNotCredit: true }));
        }
    };
    const handleAddCreditProfile = (doc: ICompanyBackOfficeCreditProfile, indexToUpdate?: number, removeItem?: boolean) => {
        const updatedDoc = {
            ...doc,
            credit_limit: convertNumberToMoney(doc.credit_limit, 'number') as number,
            warning_by_dollar: convertNumberToMoney(doc.warning_by_dollar, 'number') as number,
            warning_by_percentage: convertNumberToMoney(doc.warning_by_percentage, 'number') as number,

        }
        if (formState && formState.credit_profile) {
            if (removeItem && indexToUpdate !== undefined) {
                const updatedCreditProfiles = formState.credit_profile.filter((_, index) => index !== indexToUpdate);
                if (indexToUpdate !== -1) {
                    dispatch(updateCompanyBackOfficeSetupThunk({
                        data: {
                            ...formState,
                            credit_profile: updatedCreditProfiles,
                        },
                        isNotCredit: false
                    }));
                }
            }
            else {
                if (indexToUpdate === undefined) {
                    dispatch(updateCompanyBackOfficeSetupThunk({
                        data: {
                            ...formState,
                            credit_profile: formState.credit_profile.concat(updatedDoc),
                        }, isNotCredit: false
                    }));
                }
                else if (indexToUpdate !== -1) {
                    const updatedCreditProfiles = [...formState.credit_profile];
                    updatedCreditProfiles[indexToUpdate!] = {
                        ...updatedCreditProfiles[indexToUpdate!],
                        ...updatedDoc,
                    };

                    dispatch(updateCompanyBackOfficeSetupThunk({
                        data: {
                            ...formState,
                            credit_profile: updatedCreditProfiles,
                        }, isNotCredit: false
                    }));
                }
            }
        }
    };
    function handleClearError() {
        dispatch(companyBackOfficeSetupSliceActions.clearUpdateBackOfficeSetupError(paramID));
    };
    function handleUpdateClearState() {
        dispatch(companyBackOfficeSetupSliceActions.initializeState(paramID));
    };
    const getBackOfficeSetupData = () => {
        dispatch(getCompanyBackOfficeSetupThunk(departmentId ? { department_id: departmentId } : { company_id: companyId }));
    }
    return (
        <>
            {backOfficeSetupState.loading === LoadingType.pending ? <PageLoading /> :
                (
                    <div className="backoffice-container">
                        <div className="backoffice-finance-card backoffice-card">
                            <FinanceCollections
                                fcUpdateState={updateBackOfficeSetupState}
                                handleFieldChange={handleFieldChange}
                                formState={formState}
                                handleSubmit={handleSubmit}
                                onClearError={handleClearError}
                                handleEditCancel={handleEditCancel}
                                isLoading={findParent.finance_collections}
                            />
                        </div>
                        <div className="backoffice-billing-card backoffice-card">
                            <BillingScheduleAdditionalItems
                                bsUpdateState={updateBackOfficeSetupState}
                                handleFieldChange={handleFieldChange}
                                formState={formState}
                                handleSubmit={handleSubmit}
                                onClearError={handleClearError}
                                handleEditCancel={handleEditCancel}
                                isLoading={findParent.billing_schedule_and_additional_items}
                                paymentTermsList={paymentTermsList}

                            />
                        </div>
                        <div className="backoffice-invoice-card backoffice-card">
                            <InvoiceFormat
                                invoiceUpdateState={updateBackOfficeSetupState}
                                handleFieldChange={handleFieldChange}
                                formState={formState}
                                handleSubmit={handleSubmit}
                                companyDepartments={departmentsList}
                                onClearError={handleClearError}
                                handleEditCancel={handleEditCancel}
                                isLoading={findParent.invoice_format}
                                deptId={departmentId}
                                invoiceStyles={invoiceStyles}
                                groupingOptions={groupingOptions}

                            />
                        </div>
                        <div className="backoffice-creditprofile-card backoffice-card">
                            <CreditProfile
                                creditProfileUpdateState={updateBackOfficeSetupState}
                                formState={formState}
                                handleAddCreditProfile={handleAddCreditProfile}
                                onClearError={handleClearError}
                                onUpdateClear={() => { handleUpdateClearState(); getBackOfficeSetupData(); }}
                                isLoading={findParent.credit_profile}
                            />
                        </div>

                    </div>)
            }
        </>
    )
}

export default CompanyBackOfficeFormatSetup;