import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../../../../redux/store";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../../../components/action_dialog_holder/action_dialog_holder";
import { LoadingType, THEME } from "../../../../../../enums";
import DialogWrapper from "../../../../../../components/dialog_wrapper/dialog_wrapper";
import { CustomButton, FormInput, RegxPattern, mapOptions } from "../../../../../../utils";
import CustomFormSelect from "../../../../../../components/form_selector/form_select";
import { US_states } from "../../../../../../utils/States_US";
import { LinkIcon } from "../../../../../../icons";

const EditAction = ({ onClose, onDismiss, visible, methodValue, category, category_id, reduxMapping }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>(false);
    const [inputValue, setInputValue] = useState({
        id: methodValue.id,
        agency_id: methodValue.agency_id,
        branch_id: methodValue.branch_id,
        insurance_company_name: methodValue.insurance_company_name,
        contact_name: methodValue.contact_name,
        street_address: methodValue.street_address,
        city: methodValue.city,
        state: methodValue.state,
        zip_code: methodValue.zip_code,
        website: methodValue.website,
        facebook_url: methodValue.facebook_url,
        twitter_url: methodValue.twitter_url,
        pinterest_url: methodValue.pinterest_url,
        linkedin_url: methodValue.linkedin_url,
        phone: methodValue.phone,
        is_deleted: methodValue.is_deleted
    });
    const [sentRequest, setSentRequest] = useState(false);

    function getMapping() {
        return reduxMapping[category_id];
    }

    function handleInput(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setInputValue({
                ...inputValue,
                [parent]: { ...inputValue[parent], [fieldId]: value },
            });
        } else {
            setInputValue({ ...inputValue, [fieldId]: value });
        }
    }

    function _isFormValid() {
        const { insurance_company_name, contact_name, street_address, city, state, zip_code, website,
            facebook_url, twitter_url, pinterest_url, linkedin_url
        } = inputValue;
        if (insurance_company_name === '') return false;
        // if (contact_name === '') return false;
        // if (street_address === '') return false;
        // if (city === '') return false;
        // if (state === '') return false;
        // if (zip_code != '' && RegxPattern.zipCode.test(zip_code) === false) return false;
        // if (
        //     website != null &&
        //     website !== "" &&
        //     RegxPattern.url.test(website) === false
        // )
        //     return false;
        // if (
        //     linkedin_url != null &&
        //     linkedin_url !== "" &&
        //     RegxPattern.url.test(linkedin_url) === false
        // )
        //     return false;
        // if (
        //     facebook_url != null &&
        //     facebook_url !== "" &&
        //     RegxPattern.url.test(facebook_url) === false
        // )
        //     return false;
        // if (
        //     twitter_url != null &&
        //     twitter_url !== "" &&
        //     RegxPattern.url.test(twitter_url) === false
        // )
        //     return false;
        // if (
        //     pinterest_url != null &&
        //     pinterest_url !== "" &&
        //     RegxPattern.url.test(pinterest_url) === false
        // )
        //     return false;

        return true;
    }

    function handleUpdate() {
        setSentRequest(true);
        setCheckValidationOnSubmit(true);
        if (_isFormValid()) {
            dispatch(category.methods.update({
                'id': inputValue.id.trim(),
                'agency_id': inputValue.agency_id,
                'branch_id': inputValue.branch_id,
                'insurance_company_name': inputValue.insurance_company_name.trim(),
                'contact_name': inputValue?.contact_name.trim(),
                'website': inputValue?.website.trim(),
                'street_address': inputValue?.street_address.trim(),
                'city': inputValue?.city.trim(),
                'state': inputValue?.state.trim(),
                'zip_code': inputValue?.zip_code.trim(),
                'social_media': {
                    linkedin_url: inputValue.linkedin_url,
                    twitter_url: inputValue.twitter_url,
                    facebook_url: inputValue.facebook_url,
                    pinterest_url: inputValue.pinterest_url,
                },
                'phone': inputValue.phone,
                'is_deleted': inputValue.is_deleted
            }));
            setCheckValidationOnSubmit(false);
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        if (getMapping().state.update.loading == LoadingType.succeeded && sentRequest) {
            setInputValue({
                ...inputValue,
                insurance_company_name: inputValue.insurance_company_name.trim(),
                contact_name: inputValue.contact_name.trim(),
                website: inputValue.website.trim(),
                street_address: inputValue.street_address.trim(),
                city: inputValue.city.trim(),
                state: inputValue.state.trim(),
                zip_code: inputValue.zip_code.trim(),
                linkedin_url: inputValue?.linkedin_url!.trim(),
                twitter_url: inputValue?.twitter_url!.trim(),
                facebook_url: inputValue?.facebook_url!.trim(),
                pinterest_url: inputValue?.pinterest_url!.trim(),
            });
            setSentRequest(false);
            dispatch(category.methods.get(category.url));
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.update.loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={onClose}>
                <div className="ac-sc-rm-dialog-header">
                    <div className="dialog-header">
                        <span>{t(category.updateLabel)}</span>
                    </div>
                    <div className="dialog-content">
                        <FormInput
                            id={"insurance_company_name"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"text"}
                            value={inputValue.insurance_company_name}
                            label={t("insurance_company_name")}
                            placeholder=""
                            required={true}
                            validations={formValidators}
                            checkValidationOnSubmit={checkValidationOnSubmit}
                        />
                        <div className="row-content">
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"street_address"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={inputValue.street_address}
                                    label={t("street_address")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"city"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={inputValue.city}
                                    label={t("city")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <CustomFormSelect
                                    customStyle={{ width: "-webkit-fill-available" }}
                                    value={inputValue.state}
                                    name="state"
                                    label={t("state")}
                                    placeholder={t("select_placeholder")}
                                    list={mapOptions(US_states, "name", "code")}
                                    onChange={(value) => handleInput("state", value)}
                                    validations={formValidators}
                                />
                            </div>
                            <div>
                                <FormInput
                                    id={"zip_code"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"zip_code"}
                                    value={inputValue.zip_code}
                                    label={t("zip_code")}
                                    placeholder=""
                                    validations={formValidators}
                                    maxLength={5}
                                />
                            </div>
                        </div>
                        <div className="row-content">
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"contact_name"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={inputValue.contact_name}
                                    label={t("contact_name")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"website"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={inputValue.website}
                                    label={t("website")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"facebook_url"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={inputValue.facebook_url}
                                    label={t("facebook")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                            <div>
                                <FormInput
                                    id={"linkedin_url"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={inputValue.linkedin_url}
                                    label={t("linked_in")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                        </div>
                        <div className="row-content">
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"twitter_url"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={inputValue.twitter_url}
                                    label={t("X")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"pinterest_url"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={inputValue.pinterest_url}
                                    label={t("pinterest")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                        </div>
                        {getMapping().state.update.error && sentRequest &&
                            (<p style={{ color: 'red' }} className="message">{getMapping().state.update.error.response.detail.error}</p>)}
                    </div>
                    <div className="dialog-footer">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('update')}
                                enable={true}
                                loading={getMapping().state.update.loading == LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={handleUpdate}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )

    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "insurance_company_name": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.username.test(value.text) === false)
                    return t("validators.enterValidName");
                return null;
            }
            // case "contact_name": {
            //     if (value.text === "") return t("validators.required");
            //     return null;
            // }
            case "zip_code": {
                // if (value.text === "") return t("validators.required");
                if (RegxPattern.zipCode.test(value.text) === false)
                    return t("validators.enterValidZipCode");
                return null;
            }
            // case "street_address": {
            //     if (value.text === "") return t("validators.required");
            //     return null;
            // }
            // case "city": {
            //     if (value.text === "") return t("validators.required");
            //     return null;
            // }
            // case "state": {
            //     if (value.text === "") return t("validators.required");
            //     return null;
            // }
            case "website":
            case "linkedin_url":
            case "twitter_url":
            case "facebook_url":
            case "pinterest_url": {
                if (value.text === "") return t("");
                if (RegxPattern.url.test(value.text) === false)
                    return t("validators.enterValidUrl");
                return null;
            }
            default: {
                return null;
            }
        }
    }
}

export default EditAction;