import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { currencyConversion } from "../../../../utils";
import { getDateString } from "../../../../variables";
import SearchBar from "../../../../components/search_bar";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const tableHeader = [
    { title: "talent", code: "talent_name" },
    { title: "social_security_number", code: "talent_ssn" },
    { title: "check_date", code: "check_date" },
    { title: "week_ending_date", code: "week_ending_date" },
    { title: "check_number", code: "total_tax_amount" },
    { title: "deduction_type", code: "deduction_type" },
    { title: "deduction_amount", code: "deduction_amount" },
];

interface Props {
    record: any;
    title: string;
    onTalentSelect: (talent_id: string, talent_name: string) => void;
    onTotalCount: (totalCount: number) => void;
    onFilteredListLength: (totalLength: number) => void;
    onViewCheck: (pay_check_id: string) => void;
}

const PayrollDeductionTable: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { record, onTalentSelect, title, onTotalCount, onFilteredListLength, onViewCheck } = props;
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");

    useEffect(() => {
        handleTotalCount()
        getFilteredListLength()
    }, [search])

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    const getFilteredList = () => {
        let list = record.deductions;

        if (sortedField != null) {
            list = [...record.deductions].sort((a, b) => {
                const valueA =
                    a[sortedField] !== null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] !== null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    };

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        return 1;
    }
    const handleTotalCount = () => {
        const totalCount = record.deductions && record.deductions.length > 0 ? getFilteredList().reduce(function (prev: any, current: any) {
            return prev + +current.deduction_amount
        }, 0) : 0
        onTotalCount(totalCount)
    }

    const getFilteredListLength = () => {
        onFilteredListLength(getFilteredList().length);
    };

    return (
        <>
            <div className="table-container" key={record.talent_id}>
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t(title)}</span>
                        </div>
                        <div className="total-count-action">
                            <span>
                                {`${t("total_count")}: `}
                                <span className="total-count-number">
                                    {getFilteredList().length}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div>
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                        />
                    </div>
                </div>
                <div className="key-table">
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList().map((doc, index) => {
                            return (
                                <tr key={doc.talent_id + index}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}
                                        isButton={true}
                                        onClick={() => onTalentSelect(doc.talent_id, doc.talent_name)}
                                    >
                                        <span>{doc.talent_name}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                        <span>{doc.talent_ssn}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                        <span>{getDateString(doc.check_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        <span>{getDateString(doc.week_ending_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}
                                        isButton={true}
                                        onClick={() => onViewCheck(doc.pay_check_id)}
                                    >
                                        <span>{doc.check_number}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <span>{doc.deduction_type}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                        <span>{currencyConversion(doc.deduction_amount)}</span>
                                    </TableData>
                                </tr>
                            )
                        })}

                    </SortableTable>
                </div>
            </div>
        </>
    )
}

export default PayrollDeductionTable;