import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, EngagementActivityMethod } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getEngagementActivityMethods = createAsyncThunk<
    Array<EngagementActivityMethod>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/engagement_activity_methods/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getEngagementActivityMethod();
            return res.data as EngagementActivityMethod[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createEngagementMethod = createAsyncThunk<
    string,
    {'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/engagement_activity_methods/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createEngagementActivityMethod(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteEngagementActivityMethod = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/engagement_activity_methods/delete',
    async (method_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteEngagementActivityMethod(method_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateEngagementActivityMethod = createAsyncThunk<
    string,
    {'id': string, 'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/engagement_activity_methods/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateEngagementActivityMethod(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);