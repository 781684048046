import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
TextEmAllIcon,
MasterTaxIcon} from '../../../icons';
import { AppRoutes } from '../../../routes';
import MenuOption from '../../../components/menu_option/menu_option';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps<any> { }

const options = [
    {
        title: 'text_em_all',
        icon: TextEmAllIcon,
        route: AppRoutes.integrationPartnersTextEmAll,
    },
    {
        title: 'master_tax',
        icon: MasterTaxIcon,
        route: AppRoutes.integrationPartnersMasterTax,
    },

]

const IntegrationPartnersPage: React.FunctionComponent<Props> = (props) => {
    const { history } = props;
    const { t } = useTranslation();
    return (
        <div className="menu-option-container">
            {options.map((doc, index) => {
                return (
                    <MenuOption key={index} doc={doc} t={t} onClick={() => history.push(doc.route)} />
                )
            })}

        </div>

    )
}

export default IntegrationPartnersPage;
