import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportCenterAPI } from "../../../../../apis/report_center_api";
import { AppError, IMTExtract } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";


export const postMasterTaxReportImport = createAsyncThunk<
    string,
    IMTExtract,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/mater_tax_reports/import/post",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.postMasterTaxImport(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getMTRdirectory = createAsyncThunk<
    IMTExtract[],
    { year: number, quarter: string },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/mater_tax_reports/reports_directory/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getMasterTaxReportsDirectory(payload.year, payload.quarter);
            return res.data as IMTExtract[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getMTRExtractedFile = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/mater_tax_reports/reports_directory/extracted_file/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getMasterTaxFileView(payload);
            return res.data.url;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });