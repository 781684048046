import { RouteComponentProps } from "react-router-dom";
import './export_page.scss';
import { LoadingType, THEME } from "../../../../../../enums";
import { CustomButton } from "../../../../../../utils";
import { useTranslation } from "react-i18next";
import { exportCompanySetupThunk, selectMasterTaxSetupState } from "../../../../../../redux/admin_center";
import { getDownloadFileURLThunk, postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { useEffect } from "react";
import { masterTaxSetupStateSliceActions } from "../../../../../../redux/admin_center/integration_partners/master_tax/master_tax_setup/master_tax_setup_reducer";

interface Props extends RouteComponentProps<any> { }


const MasterTaxExportPage: React.FunctionComponent<Props> = (props: Props) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const masterTaxState = useAppSelector((state) => selectMasterTaxSetupState(state));

    const handleExport = () => {
        dispatch(exportCompanySetupThunk());
    }
    useEffect(() => {
        if (masterTaxState.export.loading === LoadingType.succeeded && masterTaxState.export.response) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', masterTaxState.export.response.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(postSnackbarMessage("Setup Data File Exported Successfully!" ?? null));
            dispatch(masterTaxSetupStateSliceActions.clearExportState());
        }
    }, [masterTaxState.export.loading, masterTaxState.export.response]);

    return (
        <div className="mt-export-container">
            <div className="btn-div">
                <CustomButton
                    leftIcon={undefined}
                    loading={false}
                    name={t("export_cs_data")}
                    enable={true}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={handleExport}
                />
            </div>
        </div>
    );
}

export default MasterTaxExportPage;