import { createAsyncThunk } from "@reduxjs/toolkit";
import { KinisoJobOrdersAPI } from "../../../apis";
import { IKinisoJobOrderResponse, IKinisoJobsPost } from "../../../interfaces/kiniso_job_orders";
import { AppError } from "../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../utils";

export const getAllKinisoJobOrdersThunk = createAsyncThunk<
Array<IKinisoJobOrderResponse>, 
IKinisoJobsPost,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@kiniso-job-orders/get-jobs/post',
    async (data, thunkAPI) => {
        try {
            const res = await KinisoJobOrdersAPI.getAllJObOrdersList(data);
            if (typeof res.data === 'string') return [];
            return res.data as IKinisoJobOrderResponse[] ;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

