import SortableTable, { TableData } from "../../../../../../components/sortable_table/sortable_table";
import { TableErrorHandler } from "../../../../../../components/table_empty/table_empty";
import { LoadingType } from "../../../../../../enums";
import { useTranslation } from "react-i18next";
import EditIcon from "../../../../../../../assets/icons/edit_button.svg"
import DeleteIcon from "../../../../../../../assets/icons/delete-icon.svg"

const TableBody = ({ state, onRetry, data, category, handleShowEdit, handleShowDelete }) => {
    const { t } = useTranslation();
    function getActions(doc) {
        return (
            <TableData>
                <img src={EditIcon} alt='edit_button' style={{ marginRight: '20px' }}
                    onClick={() => handleShowEdit(doc)} />
                <img src={DeleteIcon} alt='delete_button'
                    onClick={() => handleShowDelete(doc.id, doc.insurance_company_name)} />
            </TableData>
        )
    }

    if (state?.loading == LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    else {
        return (
            <SortableTable headerList={category.headers}>
                {data && data!.length > 0 ?
                    data?.map((doc, index) => {
                        return (
                            <tr key={doc.id}>
                                {category.value?.map((value, index) => {
                                    return (
                                        <TableData key={doc.id + index} customStyle={{ wordWrap: "break-word" }}>
                                            <span>{doc[value]}</span>
                                        </TableData>
                                    )
                                })}
                                {getActions(doc)}
                            </tr>
                        );
                    })
                    :
                    <tr>
                        <TableData><div style={{ width: '100%', textAlign: "center", fontSize: '0.9vw' }}>{t("no_data_available")}</div></TableData>
                    </tr>
                }
            </SortableTable>
        )
    }
}

export default TableBody;