
import { useTranslation } from "react-i18next";
import CustomFormSelect, { CustomMultiFormSelect } from "../../../../../components/form_selector/form_select";
import { LoadingType, THEME } from "../../../../../enums";
import { getWeekEndingDatesDropdownThunk, selectBillingInvoiceList, selectProfileState, selectWeekEndingDatesDDList, useAppDispatch, useAppSelector, selecteBIListState, postBillingBatchThunk, postBillingInvoicesProcessThunk, getBillingInvoiceListThunk } from "../../../../../redux/store";
import { CustomButton, getPlusBtnIcon, SpinnerScreen } from "../../../../../utils";
import { getDateString } from "../../../../../variables";
import "./bi_dashboard_page.scss";
import { useEffect, useState } from "react";
import SearchBar from "../../../../../components/search_bar";
import { Portal } from "react-portal";
import popupInfoContent from "../../../popup_info_text";
import InfoTextPopup from "../../../popups/infoTextPopup/info_text_popup";
import NoteCard from "../../../../../components/note_card/note_card";
import { RightArrowIcon, BulbIcon } from "../../../../../icons";
import CreateBillingBathcPopup from "../../../popups/create_billing_batch_popup/create_billing_batch_popup";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { BillingManageBatchSliceActions } from "../../../../../redux/back_office/billing_invoices/manage_billing_batch/manage_billing_batch_reducer";

const tableHeader = [
    { title: "status", code: "status" },
    { title: "description", code: "batch_description" },
    { title: "total_billed", code: "total_billed" },
    { title: "batch_number", code: "batch_number" },
    { title: "transaction_count", code: "transaction_count" },
    { title: "invoice_count", code: "invoice_count" },
    { title: "date_posted", code: "date_posted" },
    { title: "invoice_date", code: "invoice_date" },
    { title: "processed_by", code: "processed_by" },
];

const BillingInvoicingDashboardPage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const weekEndingDatesList = useAppSelector((state) => selectWeekEndingDatesDDList(state));
    const [selectedWeekDate, setSelectedWeekDate] = useState<{ id: string, date: string }>({ id: "", date: "" });
    const [addBillingBatch, setAddBillingBatch] = useState<boolean>(false);
    const [status, setStatus] = useState<string[]>([]);
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
        }`;
    const [openTimeCardInfoPopup, setOpenTimeCardInfoPopup] = useState<boolean>(false);
    const billingInvoiceListState = useAppSelector((state) => selecteBIListState(state));
    const billingInvoiceList = useAppSelector((state) => selectBillingInvoiceList(state));

    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);
    useEffect(() => {
        if (weekEndingDatesList && weekEndingDatesList.length > 0) {
            setSelectedWeekDate({ id: weekEndingDatesList[0].id, date: getDateString(weekEndingDatesList[0].week_ending_date, "mm/dd/yyyy") });
            dispatch(getBillingInvoiceListThunk(weekEndingDatesList[0].id));
        }
    }, [weekEndingDatesList, weekEndingDatesList.length]);

    const handleWeekEndChange = (val) => {
        const selectedWeekObject = weekEndingDatesList.find(obj => obj.id === val);
        setSelectedWeekDate({ id: val, date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy") });
        dispatch(getBillingInvoiceListThunk(val));

    }
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    const handlePopupClose = () => {
        setAddBillingBatch(false);
        dispatch(BillingManageBatchSliceActions.clearState());
    };

    const handleSave = (payroll_batch_id: string) => {
        dispatch(postBillingBatchThunk(payroll_batch_id));
    };
    const handleBIProcess = (payroll_batch_id: string) => {
        dispatch(postBillingInvoicesProcessThunk(payroll_batch_id));
    };

    const statusList = [
        {
            label: "All",
            value: "all"
        },
        {
            label: "Open",
            value: "open"
        },
        {
            label: "Closed",
            value: "closed"
        },
        {
            label: "Posted",
            value: "posted"
        },
    ];

    const handleSearch = (value: string) => {
        setSearch(value);
    }
    const getFilteredList = () => {
        let list = billingInvoiceList;
        if (sortedField != null && billingInvoiceList) {
            list = [...billingInvoiceList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return list?.filter((doc => {
            const str = search.trim().toLowerCase();
            const descFilter = doc.batch_description ? doc.batch_description.toString().trim().toLowerCase().includes(str) : false;
            const batchNumber = doc.batch_number ? doc.batch_number.trim().toLowerCase().includes(str) : false;
            const statusFilter = doc.status ? doc.status.trim().toLowerCase().includes(str) : false;
            const createdbyFilter = doc.processed_by ? doc.processed_by.trim().toLowerCase().includes(str) : false;
            // const errorMsgFilter = doc.error_msg ? doc.error_msg.trim().toLowerCase().includes(str) : false;

            return descFilter || batchNumber || statusFilter || createdbyFilter;

        }));;
    }
    function getFlexNumber(value: number) {
        if (value === 0) return 0.5;
        if (value === 1) return 1;
        if (value === 2) return 0.8;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 0.7;
        if (value === 6) return 0.5;
        if (value === 7) return 0.7;
        if (value === 8) return 0.6;
        if (value === 9) return 0.8;
        if (value === 10) return 0.8;
        if (value === 11) return 0.8;
        if (value === 12) return 0.8;
        return 1;
    }
    return (
        <div className="bi-container">
            <div className="bi-header">
                <div className="col1">
                    <div>
                        <CustomFormSelect
                            list={
                                weekEndingDatesList.map((doc) => ({
                                    label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                    value: doc.id,
                                }))
                            }
                            customStyle={{ width: "100%" }}
                            name={"week_ending_date"}
                            value={selectedWeekDate.id}
                            label={t("week_ending_date")}
                            placeholder={"Select"}
                            onChange={handleWeekEndChange}
                            isClearable={false}
                        />
                    </div>
                    <div>
                        <CustomMultiFormSelect
                            label={t("status")}
                            name={"status"}
                            list={statusList}
                            onChange={setStatus}
                            required={false}
                            placeholder={"Select"}
                            value={status}
                            customStyle={{ width: "-webkit-fill-available" }}
                        />
                    </div>
                </div>
                <div className="col2">
                    <div>
                        <CustomButton
                            leftIcon={getPlusBtnIcon()}
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("create_new_billing_batch")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => setAddBillingBatch(true)}
                        />
                    </div>
                    <div className="search-bar">
                        <SearchBar value={search} onChange={handleSearch} onSearch={() => { }} placeholder="Search..." />
                    </div>
                </div>
            </div>
            <div className="bi-content">
                <div className="bi-table">
                    {billingInvoiceList &&
                        billingInvoiceListState.loading === LoadingType.pending ? (
                        <>
                            <SpinnerScreen></SpinnerScreen>
                        </>
                    ) : (
                        <SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList()?.map((doc) => {
                                return (
                                    <tr key={doc.id}>
                                        <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                            <span>{doc.status}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                            <span>
                                                {doc.batch_description && doc.batch_description.length > 100 ? doc.batch_description.substring(0, 100) + "..." : doc.batch_description}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                            <span>{doc.total_billed}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                            <span>{doc.batch_number}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                            {doc.transaction_count}
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                            <span>{doc.invoice_count}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                            <span>
                                                {getDateString(doc.date_posted, "mm/dd/yyyy")}
                                            </span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                            <span>
                                                {getDateString(doc.invoice_date, "mm/dd/yyyy")}
                                            </span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                            <span>{doc.processed_by}</span>
                                        </TableData>
                                    </tr>
                                );
                            })}
                        </SortableTable>
                    )}
                </div>
                <div className="bi-card-note1">
                    <NoteCard
                        icon={<div><RightArrowIcon /><RightArrowIcon /></div>}
                        textLineOne={t("bi_card_msg_one", { name: currentLoginUserName })}
                        textLineTwo={t("bi_card_msg_two")}
                        confirmBtn={false}
                        customTextColor={"#119646"}
                        customIconBGColor={"#E6FAED"}
                        customCardWidth={"fit-content"}
                        customCardBGColor={"#FAFFFB"}
                        customTextWidthLineOne={"fit-content"}
                        customTextWidthLineTwo={"100%"}
                        customTextWidthLineThree={"100%"}
                    />
                </div>

                <div className="bi-card-note2">
                    <NoteCard
                        icon={<BulbIcon />}
                        textLineOne={t("need_to_see_billing_process_flow")}
                        confirmBtn={false}
                        customTextColor={"#074783"}
                        customIconBGColor={"#D9E6F4"}
                        customCardWidth={"82%"}
                        customCardBGColor={"#EAF4FF"}
                        customTextWidthLineOne={"84%"}
                        cursor="pointer"
                        onClick={() => setOpenTimeCardInfoPopup(true)}
                        customFontWeight={'800'}

                    />
                </div>

            </div>
            <div className="bi-actions">

            </div>
            {addBillingBatch &&
                <Portal>
                    <CreateBillingBathcPopup
                        onClose={handlePopupClose}
                        onSuccessClose={() => { handlePopupClose(); dispatch(getBillingInvoiceListThunk(selectedWeekDate.id)) }}
                        onSave={handleSave}
                        onProcess={handleBIProcess}
                        weekEndingDate={selectedWeekDate.id}
                        weekEndingDateList={weekEndingDatesList}
                    />

                </Portal>}
            {openTimeCardInfoPopup &&
                <Portal>
                    <InfoTextPopup
                        visible={openTimeCardInfoPopup}
                        onClose={() => setOpenTimeCardInfoPopup(false)}
                        onDismiss={() => setOpenTimeCardInfoPopup(false)}
                        title={t("billing_and_Invoices_help")}
                        textContent={popupInfoContent.billingDashBoardInfoText}
                    />
                </Portal>}
        </div>
    );
}

export default BillingInvoicingDashboardPage;