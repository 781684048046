import { useRef } from "react";
import {
    ActionDialogHolder,
    ActionDialogHolderType,
} from "../action_dialog_holder/action_dialog_holder";
import DialogWrapper, {
    FormDialogTilteHeader,
} from "../dialog_wrapper/dialog_wrapper";
import "./broadcast_talent.scss";
import { AppError } from "../../interfaces";
import { CustomButton, SpinnerScreen } from "../../utils";
import { useTranslation } from "react-i18next";
import { ExclaimOrangeIcon } from "../../icons";
import { LoadingType, THEME } from "../../enums";
interface Props {
    visible: boolean;
    title: string;
    onClose: () => void;
    loading?: boolean;
    error?: AppError | null | undefined;
    onForceSend: (forceSend: boolean) => void;
}
const BroadcastToTalentErrorPopup: React.FunctionComponent<Props> = (props) => {
    const { visible, onClose, loading, error, onForceSend } = props;
    const { t } = useTranslation();

    const actionRef = useRef<ActionDialogHolderType>(null);
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={onClose}>
                <div className="broadcast_talent_error_container">
                    <div className="bc_talent_header">
                        <FormDialogTilteHeader
                            title={t("something_went_wrong")}
                            titleIcon={<ExclaimOrangeIcon width={"100%"} height={"100%"} />}
                        />
                        {/* <span className="error-icon">
              <ExclaimOrangeIcon width={"100%"} height={"100%"} />
            </span>
            <span>{t("something_went_wrong")}</span> */}
                    </div>
                    <div className="bc_talent_content">
                        {loading && <SpinnerScreen />}
                        <table className="error_table">
                            <tbody>
                                {error && typeof (error?.message) !== "string" ?
                                    (<>
                                        {error?.message?.map((msg, index) => {
                                            return (
                                                <tr key={"bc_talent_error_popup" + index}>
                                                    <td>
                                                        <span>
                                                            <b>{t("name")}:</b> {msg.Name}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            <b>{t("phone")}:</b> {msg.Phone}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            <b>{t("error")}:</b> {msg.ErrorMessage}
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </>) : (
                                        <div>
                                            {error?.message}
                                        </div>
                                    )}
                            </tbody>
                        </table>
                    </div>
                    <div className="bc_talent_actions">

                        <div className="btn-save">
                            <CustomButton
                                // loading={props.loading === LoadingType.pending}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("send_anyway")}
                                // enable={_isFormValid()}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => onForceSend(true)}
                            />
                        </div>

                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("close")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={props.onClose}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    );
};

export default BroadcastToTalentErrorPopup;
