import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./transaction_summary_thunk";
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { TransactionSummary } from "../../../../interfaces/back_office_payroll";

export interface PRTransactionSummaryState {
  error?: AppError | null;
  loading: LoadingType;
  response: TransactionSummary[] | null;
}

const initialState: PRTransactionSummaryState = {
  error: null,
  loading: LoadingType.idle,
  response: null,
};

export const PRTransactionSummarySlice = createSlice({
  name: "PRTransactionSummary",
  initialState: initialState,
  reducers: {
    clearState(state) {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getPRTransactionSummaryThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getPRTransactionSummaryThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.getPRTransactionSummaryThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        response: action.payload,
      }))
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const PRTransactionSummarySliceActions = PRTransactionSummarySlice.actions;
export const selectState = (state: PRTransactionSummaryState) => state;
export const selectAll = (state: PRTransactionSummaryState) => state.response;
