import { IMasterTaxSetup } from "../../../../interfaces/master_tax";

export const masterTaxInitialForm: IMasterTaxSetup = {
    company_setup: {
        agency_legal_name: "",
        company_dba_name: "",
        in_care_of_name: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        zip_code: "",
        psd_code: "",
        payroll_description: "",
        name_control: "",
        company_start_date: 0,
        quarterly_wage_reporting: "N",
        worksite_reporting: "N",
        year_end_employee_filing: "N",
        wage_attachment: "N",
        cash_service_level: "",
        kind_of_employer: "N",
        naics_code: "",
        effective_date: 0,
        fein_type: "N",
        fein: "",
        the_944_filter: "N",
        company_status: "",
        service_level: "F",
        contact_first_name: "",
        contact_middle_name: "",
        contact_last_name: "",
        phone_area_code: "",
        phone_number: "",
        phone_extension: "",
        fax_area_code: "",
        fax_number: "",
        email_address: "",
    },
    disbursement_bank: {
        format_id: "",
        effective_date: 0,
        ach_bank_dest_point_number: "",
        payroll_tax_bank: {
            account_name: "",
            routing_number: "",
            account_number: "",
            account_type: null,
            draft_days: 0,
        },
        wage_attachment_bank: {
            account_name: "",
            routing_number: "",
            account_number: "",
            account_type: null,
            draft_days: 0,
        },
    },
    payroll_cash_care_bank: {
        format_id: "",
        effective_date: 0,
        ach_bank_dest_point_number: "",
        payroll_tax_bank: {
            account_name: "",
            routing_number: "",
            account_number: "",
            account_type: null,
            draft_days: 0,
        },
        wage_attachment_bank: {
            account_name: "",
            routing_number: "",
            account_number: "",
            account_type: null,
            draft_days: 0,
        },
    },
    tax_setup_complete:false,
    taxes_setup:[]
};