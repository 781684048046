import { combineReducers } from "@reduxjs/toolkit";
import { companyTrafficLightEngagementSlice } from "./company_traffic_engagement/company_traffic_engagement_reducer";
import { salesCallsListSlice } from "./sales_calls/sales_calls_reducer";
import { serviceCallsListSlice } from "./service_calls/service_calls_reducer";
import { talentTrafficLightEngagementSlice } from "./talent_traffic_engagement/talent_traffic_engagement_reducer";
import { trafficLightEngagementSlice } from "./traffic_engagement/traffic_engagement_reducer";
import { dashboardEngagementSlice} from "./dashboard-engagement-details/dashboard-engagement-details-reducer";
import { dasboardSendOnlineApplicationsSlice } from "./send_online_application/send_online_application_reducer";
import { talentAvailableCandidatesSlice, TalentAvailableCandidatesState } from "./talent_available_candidates/talent_available_candidates_reducer";
import { onlineApplicationReceivedListSlice } from "./online_applications_received_list/online_applications_received_list_reducer";
import { onlineApplicationSentListSlice } from "./online_applications_sent_list/online_applications_sent_list_reducer";
import { OnlineApplicationReceivedDetailsSlice } from "./online_applications_received_details/online_applications_received_reducer";
import { sendInterviewCalendarInviteSlice } from "./send_interview_calendar_invite/send_interview_calendar_redux";
import { manageApplicationSentSlice } from "./manage_application_sent/manage_application_sent_reducer";
import { dasboardSendOnBoardingLinksSlice } from "./send_onboarding_link/send_onboarding_link_reducer";
import { onboardingCountSlice } from "./onboarding_count/onboarding_count_reducer";
import { contactTrafficLightEngagementSlice } from "./contact_taffic_engagement/contact_taffic_engagement_reducer";
import { inBoundLeadsStateSlice } from "./inbound_leads/inbound_leads_directory/inbound_leads_directory_reducer";

export const dashboardMetricsReducers = combineReducers({
    [salesCallsListSlice.name]: salesCallsListSlice.reducer,
    [serviceCallsListSlice.name]: serviceCallsListSlice.reducer,
    [trafficLightEngagementSlice.name]: trafficLightEngagementSlice.reducer,
    [talentTrafficLightEngagementSlice.name]: talentTrafficLightEngagementSlice.reducer,
    [contactTrafficLightEngagementSlice.name]: contactTrafficLightEngagementSlice.reducer,
    [companyTrafficLightEngagementSlice.name]: companyTrafficLightEngagementSlice.reducer,
    [dashboardEngagementSlice.name]:dashboardEngagementSlice.reducer,
    [dasboardSendOnlineApplicationsSlice.name]: dasboardSendOnlineApplicationsSlice.reducer,
    [dasboardSendOnBoardingLinksSlice.name]: dasboardSendOnBoardingLinksSlice.reducer,
    [talentAvailableCandidatesSlice.name]:talentAvailableCandidatesSlice.reducer,
    [onlineApplicationReceivedListSlice.name]: onlineApplicationReceivedListSlice.reducer,
    [onlineApplicationSentListSlice.name]: onlineApplicationSentListSlice.reducer,
    [OnlineApplicationReceivedDetailsSlice.name]: OnlineApplicationReceivedDetailsSlice.reducer,
    [sendInterviewCalendarInviteSlice.name]:sendInterviewCalendarInviteSlice.reducer,
    [manageApplicationSentSlice.name]: manageApplicationSentSlice.reducer,
    [onboardingCountSlice.name]: onboardingCountSlice.reducer,
    [inBoundLeadsStateSlice.name]: inBoundLeadsStateSlice.reducer,
});

export * from './sales_calls/sales_calls_thunk';
export * from './sales_calls/sales_calls_selector';

export * from './service_calls/service_calls_thunk';
export * from './service_calls/service_calls_selector';

export * from './traffic_engagement/traffic_engagement_thunk';
export * from './traffic_engagement/traffic_engagement_selector';

export * from './company_traffic_engagement/company_traffic_engagement_thunk';
export * from './company_traffic_engagement/company_traffic_engagement_selector';

export * from './talent_traffic_engagement/talent_traffic_engagement_thunk';
export * from './talent_traffic_engagement/talent_traffic_engagement_selector';

export * from './dashboard-engagement-details/dashboard-engagement-details-thunk';
export * from './dashboard-engagement-details/dashboard-engagement-details-selector';

export * from './send_online_application/send_online_application_thunk';
export * from './send_online_application/send_online_application_selector';

export * from './talent_available_candidates/talent_available_candidates_thunk';
export * from './talent_available_candidates/talent_available_candidates_selector';

export * from './send_interview_calendar_invite/send_interview_calendar_thunk';
export * from './send_interview_calendar_invite/send_interview_calendar_selector';

export * from './online_applications_sent_list/online_applications_sent_list_thunk';
export * from './online_applications_sent_list/online_applications_sent_list_selector';

export * from './online_applications_received_list/online_applications_received_list_thunk';
export * from './online_applications_received_list/online_applications_received_list_selector';

export * from './send_onboarding_link/send_onboarding_link_thunk';
export * from './send_onboarding_link/send_onboarding_link_selector';

export * from './onboarding_count/onboarding_count_thunk';
export * from './onboarding_count/onboarding_count_selector';

export * from './inbound_leads/inbound_leads_directory/inbound_leads_directory_thunk';
export * from './inbound_leads/inbound_leads_directory/inbound_leads_directory_selector';