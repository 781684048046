import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import UploadProfile from "../../../../components/image_crop/image_crop_popup";
import SocialMediaProfile from "../../../../components/social_media_profile/social_media_profile";
import { LoadingType, ProfilePictureType, THEME } from "../../../../enums";
import { CallIcon, GlobeIcon, LocationIcon } from "../../../../icons";
import { ICompetitor } from "../../../../interfaces";
import {
  useAppDispatch,
  useAppSelector,
  getPreSignedURLForDownload,
  selectSingleProfilePictureStateById,
} from "../../../../redux/store";
import { getHttpsString } from "../../../../utils";

interface Props {
  competitorInfo: ICompetitor | null;
}

const CompetitorHeader = (props) => {
  const { name, uri } = props;

  const dispatch = useAppDispatch();
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const profilePictureState = useAppSelector((state) =>
    selectSingleProfilePictureStateById(
      ProfilePictureType.contact + "-" + props.objectId
    )(state)
  );

  useEffect(() => {
    setSentRequest(true);
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.contact,
      })
    );
  }, []);

  function updateProfile() {
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.contact,
      })
    );
    setSentRequest(true);
  }

  useEffect(() => {
    if (
      sentRequest &&
      profilePictureState.download.loading === LoadingType.succeeded
    ) {
      setSentRequest(false);
      setImageURL(profilePictureState.download.response ?? "");
    }
  }, [profilePictureState.download.loading]);

  return (
    <div className="cont-ds-user-info">
      <div
        className="cont-ds-avatar-holder"
        onClick={() => setShowImageUpload(true)}
      >
        <div
          className="cont-ds-avatar background-img"
          style={imageURL ? { backgroundImage: `url(${imageURL})` } : {}}
        ></div>
      </div>
      <div className="cont-ds-user-name">
        <span>{name}</span>
      </div>
      {showImageUpload && (
        <Portal>
          <UploadProfile
            onClose={() => setShowImageUpload(false)}
            onDismiss={() => setShowImageUpload(false)}
            visible={showImageUpload}
            onApprove={updateProfile}
            objectId={props.objectId}
            objectType={ProfilePictureType.contact}
          />
        </Portal>
      )}
    </div>
  );
};

const CompetitorDetails: React.FunctionComponent<Props> = (props) => {
  const { competitorInfo } = props;
  const { t } = useTranslation();

  const getAddress = () =>
    [
      //competitorInfo?.street_address,
      competitorInfo?.city,
      competitorInfo?.state,
      competitorInfo?.zip_code,
    ]
      .filter((item) => !!item)
      .join(",");

  return (
    <div className="cont-ds-container">
      <CompetitorHeader
        name={competitorInfo?.competitor_name ?? ""}
        objectId={competitorInfo?.id}
      />
      <div className="cont-ds-info-container">
        <div className="cont-ds-info-icon">
          <LocationIcon width={"100%"} height={"100%"} />
        </div>
        <div className="cont-ds-info-content">
          <div className="cont-ds-info-holder">
            <div className="cont-ds-into-title">
              <span>{t("address").toUpperCase()}</span>
            </div>

            <div className="cont-ds-info-value">
              <span>{competitorInfo?.street_address}</span>

              <span>{getAddress()}</span>
            </div>
          </div>
        </div>
      </div>
      {!!competitorInfo?.contact && (
        <div className="cont-ds-info-container">
          <div className="cont-ds-info-icon">
            <CallIcon
              width="15px"
              height="15px"
              style={{ color: THEME.primaryColor }}
            />
          </div>
          <div className="cont-ds-info-content">
            <div className="cont-ds-info-holder">
              <div className="cont-ds-into-title">
                <span>{t("contact").toUpperCase()}</span>
              </div>
              <div className="cont-ds-info-value">
                {competitorInfo?.contact && (
                  <div>
                    <span style={{ fontWeight: "normal" }}>{t("mobile")}</span>
                    <span style={{ marginLeft: "5px" }}>
                      {competitorInfo?.contact}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!!competitorInfo?.website && (
        <div className="cont-ds-info-container">
          <div className="cont-ds-info-icon">
            <GlobeIcon width={"100%"} height={"100%"} />
          </div>
          <div className="cont-ds-info-content">
            <div className="cont-ds-info-holder">
              <div className="cont-ds-into-title">
                <span>{t("website").toUpperCase()}</span>
              </div>
              <div
                className="conts-ds-info-value"
              >
                <a
                  href={getHttpsString(competitorInfo?.website)}
                  className="website-icon"
                  target="_blank"
                  style={{
                    color: "#035090",
                    fontWeight: 800,
                    lineBreak: "anywhere"
                  }} rel="noreferrer"
                >
                  {competitorInfo?.website}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      {competitorInfo?.social_media_links && (
        <SocialMediaProfile
          title={t("social_media").toUpperCase()}
          profile={competitorInfo.social_media_links}
        />
      )}
    </div>
  );
};

export default CompetitorDetails;
