import { initializeApp } from 'firebase/app';
import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updateProfile
} from 'firebase/auth';
import { firebaseConfig } from '../firebase_config';
import { LoginCredentials, RegisterCredentials } from '../interfaces';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

/// Google signin
const googleProvider = new GoogleAuthProvider();
export const firebaseGoogleSignin = async () => {
    const res = await signInWithPopup(auth, googleProvider);
    const idToken = await res.user.getIdToken();
    return { idToken, user: res.user };
}


/// Email/Custom
export const firebaseLogin = async (credentials: LoginCredentials) => {
    const res = await signInWithEmailAndPassword(auth, credentials.username, credentials.password);
    const idToken = await res.user.getIdToken();
    return { idToken, user: res.user };
}

export const firebaseRegister = async (credentials: RegisterCredentials) => {
    const res = await createUserWithEmailAndPassword(auth, credentials.talentName, credentials.email);
    const idToken = await res.user.getIdToken();
    await updateProfile(res.user, { displayName: `${credentials.talentName} ${credentials.email}` });
    return { idToken, user: res.user };
}

export const firebaseEmailVerification = async () => {
    const user = auth.currentUser;
    if (user != null) {
        await sendEmailVerification(user);
    }
}


export const firebaseLogout = () => {
    return signOut(auth);
}


