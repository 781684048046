import { combineReducers } from "@reduxjs/toolkit";
import { assignmentsListSlice } from "./assignments_list/assignments_list_reducer";
import { manageAssignmentSlice } from "./manage_assignment/manage_assignment_reducer";

export const universalDirectoryReducers = combineReducers({
    [assignmentsListSlice.name]: assignmentsListSlice.reducer,
    [manageAssignmentSlice.name]: manageAssignmentSlice.reducer,
});

export * from './assignments_list/assignments_list_thunk';
export * from './assignments_list/assignments_list_selector';

export * from './manage_assignment/manage_assignment_thunk';
export * from './manage_assignment/manage_assignment_selector';