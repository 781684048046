import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import {Slider} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {CustomButton} from "../../utils";
import {LoadingType, THEME} from "../../enums";
import {S3API} from "../../apis/s3_api";
import { useAppSelector, useAppDispatch, getPreSignedURLForUpload, selectSingleProfilePictureStateById } from '../../redux/store';
import { getAgencyLogoURLForUpload, selectAgencyProfilePictureState } from '../../redux/admin_center';

const baseStyle = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function StyledDropzone({objectType, objectId, successCallback, agencyLogoDownloadUrl}) {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [files, setFiles] = useState<any>();
    const [showDropzone, setShowDropzone] = useState(true);
    const [croppedValue, setCroppedValue] = useState<any>({area: {}, pixels: {}});
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [zoomSlider, setZoomSlider] = useState(100);
    const [loading, setLoading] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const profilePictureState = useAppSelector((state) => selectSingleProfilePictureStateById(objectType + '-' + objectId)(state));
    const agencyPictureState = useAppSelector((state) => selectAgencyProfilePictureState(state));

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedValue({area: {...croppedArea}, pixels: {...croppedAreaPixels}});
    }, [])

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { 'image/*': [".jpeg", ".jpg", ".png"] },
        maxFiles: 1,
        multiple: false,
        onDrop: acceptedFiles => {
            setShowDropzone(false);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const handleZoomChange = (e, value) => {
        setZoomSlider(value);
        setZoom(value/100);
    }

    const createImage = () => {
        const image = new Image();
        image.src = files[0].preview;
        return image;
    }

    const getCroppedImage = (image) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) 
            return null;
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
        const data = ctx.getImageData(
            croppedValue.pixels?.x,
            croppedValue.pixels?.y,
            croppedValue.pixels?.width,
            croppedValue.pixels?.height
        );
        canvas.width = croppedValue.pixels?.width;
        canvas.height = croppedValue.pixels?.height;
        ctx.putImageData(data, 0, 0);
        canvas.toBlob((blob) => {
            if (!blob)
                return
            S3API.uploadWithPreSignedURL(profilePictureState.response, blob);
            // S3API.uploadWithPreSignedURL(agencyPictureState.response, blob);
            // TODO handle exception
            setLoading(false);
            successCallback();
        }, 'image/png', 1);
    }

    const getCroppedAgencyImage = (image) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) return null;
        // Set canvas dimensions to match the image dimensions
        canvas.width = image.width;
        canvas.height = image.height;
        // Draw the full image onto the canvas
        ctx.drawImage(image, 0, 0);
        // Convert canvas to blob and upload
        canvas.toBlob((blob) => {
            if (!blob) return;
            // Upload the complete image
            S3API.uploadWithPreSignedURL(agencyPictureState.response, blob);
            // Handle success and loading states
            setLoading(false);
            successCallback();
        }, 'image/png', 1);
    };


    const updateProfile = () => {
        if(agencyLogoDownloadUrl){
            setLoading(true);
            dispatch(getAgencyLogoURLForUpload({ file_name: files[0].name}));
            setSentRequest(true);
        }
        else{
            setLoading(true);
            dispatch(getPreSignedURLForUpload({object_id: objectId, object_type: objectType, file_name: files[0].name}));
            setSentRequest(true);
        }
    }

    useEffect(() => {
        if ((profilePictureState.loading === LoadingType.succeeded && sentRequest) || (agencyPictureState.loading === LoadingType.succeeded && sentRequest)) {
            setSentRequest(false);
            const image = createImage();
            agencyLogoDownloadUrl ? getCroppedAgencyImage(image) : getCroppedImage(image);
        }
        else if ((profilePictureState.loading === LoadingType.failed && sentRequest) || (agencyPictureState.loading === LoadingType.succeeded && sentRequest)) {
            setSentRequest(false);
            setErrorMessage('Something went wrong!')
            setTimeout(() => (setErrorMessage('')), 5000);
            setLoading(false);
        }
    }, [profilePictureState.loading, agencyPictureState.loading])

    return (
        <div className={agencyLogoDownloadUrl ? "agency-logo-container" : "container"}>
            {showDropzone ? 
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div> : 
            <div>
                <div>
                    <Cropper
                    image={files[0].preview}
                    crop={crop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    zoomWithScroll={false}
                    style={{containerStyle: {'width': "800px", "height": "300px", "marginLeft": "10px", "marginTop": "50px"}}}
                    cropSize={agencyLogoDownloadUrl ? { width: 750, height: 250 } : undefined}
                    />
                </div>
                <div className='options'>
                    {agencyLogoDownloadUrl ? null : <div className='zoom-range'>
                        <Slider min={100} max={200} step={1} valueLabelDisplay="auto" value={zoomSlider} onChange={handleZoomChange}/>
                    </div>}
                    <p className='message'>{errorMessage}</p>
                    <div className='upload-button'>
                        <CustomButton
                            textStyle={{textTransform: 'capitalize'}}
                            enable={true}
                            name={t("upload")}
                            padding={12}
                            fontSize={15}
                            loading={loading}
                            backgroundColor={THEME.buttonColor1}
                            onClick={updateProfile}
                        />
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default StyledDropzone;
