import { NumberBadge, NotificationCircle } from '../../../../components/notification_circle/notification_circle';
import { THEME } from '../../../../enums';
import { TrafficLightIcon } from '../../../../icons';
import { getRatingBackgrounColor } from '../../../../utils';
import './engagement_card.scss';


interface EnagementCardProps {
    titleInfo: {
        icon: React.ReactNode;
        title: string;
        notifications: number;
        notificationsLevel: string;
        activeAssignments: number;
        assignmentTitle: string;
    };
    lowValue: { title: string; interactions: string; value: number };
    mediumValue: { title: string; interactions: string; value: number };
    highValue: { title: string; interactions: string; value: number };
    onLow: () => void;
    onMedium: () => void;
    onHigh: () => void;
    onActiveAssignment: () => void;
}

const EnagementCard: React.FunctionComponent<EnagementCardProps> = (props) => {
    return (
        <div className="dsh-eng-card-holder">
            <div className="eng-header-holder">
                <div className="title-section">
                    <div className="title">
                        <div className="icon">{props.titleInfo.icon}</div>
                        <div className="title-text">
                            <div className="title">
                                <span>{props.titleInfo.title}</span>
                            </div>

                            <div className="active-assignments-holder">
                                <div className="assignment-number">
                                    <NumberBadge
                                        value={props.titleInfo.activeAssignments}
                                        onClick={props.onActiveAssignment}
                                        customStyle={{
                                            backgroundColor: "#53C53F",
                                            width: "1.302vw",
                                            height: "1.302vw",
                                        }}
                                        textStyle={{ color: THEME.white, fontSize: "0.9375vw" }}
                                    />
                                </div>
                                <div className="assignment-text">
                                    <span>{props.titleInfo.assignmentTitle}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="notifications">
                        <NotificationCircle
                            value={props.titleInfo.notifications}
                            onClick={() => { }}
                            customStyle={{
                                backgroundColor: getRatingBackgrounColor(
                                    props.titleInfo.notificationsLevel
                                ),
                                width: "1.875vw",
                                height: "1.875vw",
                                cursor: "initial",
                            }}
                            textStyle={{ color: THEME.white, fontSize: "1.0416vw" }}
                        />
                    </div>
                </div>
            </div>
            <div className="dsh-eng-info">
                {/* <div className="traffic-light">
                    <TrafficLightIcon width={"100%"} height={"100%"} />
                </div> */}
                <button className="level low-level" onClick={props.onLow}>
                    {interactions(props.lowValue)}
                </button>
                <button className="level medium-level" onClick={props.onMedium}>
                    {interactions(props.mediumValue)}
                </button>
                <button className="level high-level" onClick={props.onHigh}>
                    {interactions(props.highValue)}
                </button>
            </div>
        </div>
    );

    function interactions(data: {
        title: string;
        interactions: string;
        value: number;
    }) {
        return (
            <div className="trf-ins-container">
                <div className="trf-ins-title">
                    <span>{data.title}</span>
                </div>
                <div className="trf-ins-interactions">
                    <span>{data.interactions}</span>
                </div>
                <div className="trf-ins-result-value">
                    <span>{data.value}</span>
                </div>
            </div>
        );
    }
};

export default EnagementCard;