import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";
import { IMasterTaxCredntials } from "../../../../../interfaces/master_tax";
import * as thunk from './credentials_thunk';

export interface CredentialState {
    error?: AppError | null,
    loading: LoadingType,
    data: IMasterTaxCredntials | null,
    postCreds: {
        error?: AppError | null,
        loading: LoadingType,
        response: { ssl_cert_upload_url: string, ssl_key_upload_url: string } | null
    },
    fileUpload: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const initialState: CredentialState = {
    error: null,
    loading: LoadingType.idle,
    data: null,
    postCreds: {
        error: null,
        loading: LoadingType.idle,
        response: null
    },
    fileUpload: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
}
export const credentialStateSlice = createSlice({
    name: 'masterTaxCredentials',
    initialState: initialState,
    reducers: {
        clearPostState(state) {
            return {
                ...state,
                postCreds: {
                    ...initialState.postCreds
                }
            };
        },
        clearUploadState(state) {
            return {
                ...state,
                fileUpload: {
                    ...initialState.fileUpload
                }
            };
        },
        clearState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(thunk.getMasterTaxAuthThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending }))
            .addCase(thunk.getMasterTaxAuthThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload }))
            .addCase(thunk.getMasterTaxAuthThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, data: action.payload }))

            .addCase(thunk.updateMasterTaxAuthThunk.pending, (state, action) => ({ ...state, postCreds: { ...state.postCreds, loading: LoadingType.pending, response: null, error: null } }))
            .addCase(thunk.updateMasterTaxAuthThunk.rejected, (state, action) => ({ ...state, postCreds: { ...state.postCreds, loading: LoadingType.failed, response: null, error: action.payload } }))
            .addCase(thunk.updateMasterTaxAuthThunk.fulfilled, (state, action) => ({ ...state, postCreds: { ...state.postCreds, loading: LoadingType.succeeded, response: action.payload, error: null } }))

            // .addCase(thunk.createUploadDocument.pending, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.pending, response: '', error: null } }))
            // .addCase(thunk.createUploadDocument.rejected, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.failed, response: '', error: action.payload } }))
            // .addCase(thunk.createUploadDocument.fulfilled, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.succeeded, response: action.payload, error: null } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const credentialsSliceActions = credentialStateSlice.actions;
export const selectState = (state: CredentialState) => state;