
  import interceptor from "./interceptor";
  import UrlBuilder from "./url_builder";
 
  export const KinisoJobOrdersAPI = {
    getAllJObOrdersList(data: any) {
      return interceptor.post(UrlBuilder.kinisoJobOrderList,data);
    },
   
  };
  