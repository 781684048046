import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRCTaxByEmplyee } from "../../../../interfaces/report_center_payroll_tax";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { IReportCenterBOPayload } from "../../../../interfaces/report_center";

export const getTaxByEmployeeList = createAsyncThunk<
    IRCTaxByEmplyee,
    IReportCenterBOPayload,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/tax-by-employee/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getTaxByEmployee(payload);
            return res.data as IRCTaxByEmplyee;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });