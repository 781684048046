import { createSelector } from "@reduxjs/toolkit";
import { selectAll, selectEntities, selectSalesCallSummaryReportDownloadUrl, selectState } from "./sales_call_summary_report_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.reportCenter.reportCenterSalesAndMarketing.salesCallSummaryReportList;
export const selectSalesCallSummaryReportListState = createSelector(select, selectState);
export const selectSalesCallSummaryReportList = createSelector(
    select,
    selectAll
);
export const selectSalesCallSummaryReportListEntities = createSelector(
    select,
    selectEntities
);
export const selectSalesCallSummaryReportDownloadUrlState = createSelector(
    select,
    selectSalesCallSummaryReportDownloadUrl
);