import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./gross_profit_thunk";
import { IGrossProfit, IReportCenterUploadUrlResponse } from "../../../../interfaces/report_center";


export interface GrossProfitState {
    error?: AppError | null,
    loading: LoadingType,
    response: IGrossProfit | null
}

const initialState: GrossProfitState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}

export const grossProfitSlice = createSlice({
    name: 'grossProfit',
    initialState: initialState,
    reducers: {
        clearErrorState(state) {
            return { ...state, error: null };
        },
        clearState(state) {
            return { ...initialState };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getGrossProfitThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getGrossProfitThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getGrossProfitThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const grossProfitSliceActions = grossProfitSlice.actions;
export const selectState = (state: GrossProfitState) => state;
export const selectLoading = (state: GrossProfitState) => state.loading;
export const selectError = (state: GrossProfitState) => state.error;



