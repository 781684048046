import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ICreateResponse, IProfilePictureUploadUrlResponse } from "../../../../interfaces";
import * as thunk from "./manage_department_thunk";

export interface ManageDepartmentState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
    fileURL: {
        error?: AppError | null,
        loading: LoadingType,
        response?: IProfilePictureUploadUrlResponse,
    }
    upload: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    }
}

const initialState: ManageDepartmentState = {
    create: {
        error: null,
        loading: LoadingType.idle,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
    },
    fileURL: {
        error: null,
        loading: LoadingType.idle,
    },
    upload: {
        error: null,
        loading: LoadingType.idle,
    },
}


export const manageDepartmentSlice = createSlice({
    name: 'manageDepartment',
    initialState: initialState,
    reducers: {
        clearCreateDepartmentState(state) {
            state.create = { ...initialState.create };
        },
        clearUpdateDepartmentState(state) {
            state.update = { ...initialState.update };
        },
        clearManageDepartmentStateError(state) {
            return {
                ...state,
                create: {
                    ...state.create,
                    error: null
                },
                update: {
                    ...state.update,
                    error: null
                },
                fileURL: {
                    ...state.fileURL,
                    error: null
                },
                upload: {
                    ...state.upload,
                    error: null
                }
            };
        },
        clearManageDepartmentState(state) {
            return {
                ...state,
                create: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                update: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                fileURL: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                upload: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.createDepartment.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createDepartment.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createDepartment.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateDepartment.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateDepartment.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateDepartment.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getDepartmentLogoUploadURLThunk.pending, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getDepartmentLogoUploadURLThunk.rejected, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getDepartmentLogoUploadURLThunk.fulfilled, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.uploadDepartmentLogoThunk.pending, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadDepartmentLogoThunk.rejected, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.uploadDepartmentLogoThunk.fulfilled, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageDepartmentActions = manageDepartmentSlice.actions;
export const selectState = (state: ManageDepartmentState) => state;
export const selectCreateState = (state: ManageDepartmentState) => state.create;
export const selectUpdate = (state: ManageDepartmentState) => state.update;
export const selectFileURL = (state: ManageDepartmentState) => state.fileURL;
export const selectUpload = (state: ManageDepartmentState) => state.upload;


