import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import {
    selectCreateState,
    selectState,
    selectUpdateState
} from "./manage_marketing_campaign_reducer";

const select = (state: IRootState) =>
  state.salesMarketing.planMarketingCampaign.manageMarketingCampaign;
export const selectManageMarketingCampaignState = createSelector(select, selectState);
export const selectManageMarketingCampaignCreateState = createSelector(
  selectManageMarketingCampaignState,
  selectCreateState
);
export const selectManageMarketingCampaignUpdateState = createSelector(
  selectManageMarketingCampaignState,
  selectUpdateState
);
