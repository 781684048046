import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import {
  selectState,
  selectAll,
  selectEntities,
} from "./service_call_list_reducer";

const select = (state: IRootState) =>
  state.salesMarketing.serviceCallActivity.serviceCallList;
export const selectServiceCallListState = createSelector(select, selectState);
export const selectServiceCallList = createSelector(select, selectAll);
export const selectServiceCallListEntities = createSelector(
  select,
  selectEntities
);
