import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccountAPI, AdminCenterAPI, firebaseLogin } from "../../../../apis";
import { AppError,  ISystemCategoryDetails,ISkills,IBusinessSector, ICredentials, ICertifications, IEducationRequirements, IEquipment } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getAdminCenterSkills = createAsyncThunk<
    Array<ISkills>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/system-configuration/skills/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getSkillsList();
            return res.data as ISkills[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }

);

export const getAdminCenterBusinessSector = createAsyncThunk<
    Array<IBusinessSector>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/system-configuration/businessSector/get',
    async (_,thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getBusinessSector();
            return res.data as IBusinessSector[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getAdminCenterCredentials = createAsyncThunk<
    Array<ICredentials>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/system-configuration/credential/get',
    async (_,thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getCredentials();
            return res.data as ICredentials[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getAdminCenterCertifications = createAsyncThunk<
    Array<ICertifications>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/system-configuration/certification/get',
    async (_,thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getCertifications();
            return res.data as ICertifications[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getAdminCenterEducationRequirements = createAsyncThunk<
    Array<IEducationRequirements>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/system-configuration/education-requirement/get',
    async (_,thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getEducationRequirements();
            return res.data as IEducationRequirements[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getAdminCenterEquipment = createAsyncThunk<
    Array<IEquipment>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/system-configuration/required-equipment/get',
    async (_,thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getEquipments();
            return res.data as IEquipment[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Create/Update Skill
export const saveSkill = createAsyncThunk<
    string,
    ISystemCategoryDetails,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/user-management/system-configuration/skill-set/save',
    async (payload, thunkAPI) => {
        try {
            const res = (payload.id==="")? 
            await AdminCenterAPI.createSkillSet(payload):
            await AdminCenterAPI.updateSkillSet(payload);
            thunkAPI.dispatch(getAdminCenterSkills());
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteSkill = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/skill-set/delete',
    async (id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteSkillSet(id);
            thunkAPI.dispatch(getAdminCenterSkills());
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Create/Update Certifications
export const saveCertification = createAsyncThunk<
    string,
    ISystemCategoryDetails,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/user-management/system-configuration/certifications/save',
    async (payload, thunkAPI) => {
        try {
            const res = (payload.id==="")? 
            await AdminCenterAPI.createCertifications(payload):
            await AdminCenterAPI.updateCertifications(payload);
            thunkAPI.dispatch(getAdminCenterCertifications());
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteCertification = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/certification/delete',
    async (id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteCertifications(id);
            thunkAPI.dispatch(getAdminCenterCertifications());
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


/// Create/Update Certifications
export const saveCredential = createAsyncThunk<
    string,
    ISystemCategoryDetails,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/user-management/system-configuration/credential/save',
    async (payload, thunkAPI) => {
        try {
            const res = (payload.id==="")? 
            await AdminCenterAPI.createCredentials(payload):
            await AdminCenterAPI.updateCredentials(payload);
            thunkAPI.dispatch(getAdminCenterCredentials());
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteCredentials = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/credential/delete',
    async (id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteCredentials(id);
            thunkAPI.dispatch(getAdminCenterCredentials());
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Create/Update Certifications
export const saveEducationRequirement = createAsyncThunk<
    string,
    ISystemCategoryDetails,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/user-management/system-configuration/education-requirement/save',
    async (payload, thunkAPI) => {
        try {
            const res = (payload.id==="")? 
            await AdminCenterAPI.createEducationRequirements(payload):
            await AdminCenterAPI.updateEducationRequirements(payload);
            thunkAPI.dispatch(getAdminCenterEducationRequirements());
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteEducationRequirement = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/education-requirement/delete',
    async (id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteEducationRequirements(id);
            thunkAPI.dispatch(getAdminCenterEducationRequirements());
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Create/Update Certifications
export const saveEquipment = createAsyncThunk<
    string,
    ISystemCategoryDetails,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/user-management/system-configuration/required-equipment/save',
    async (payload, thunkAPI) => {
        try {
            const res = (payload.id==="")? 
            await AdminCenterAPI.createEquipments(payload):
            await AdminCenterAPI.updateEquipments(payload);
            thunkAPI.dispatch(getAdminCenterEquipment());
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteEquipment = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/required-equipment/delete',
    async (id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteEquipments(id);
            thunkAPI.dispatch(getAdminCenterEquipment());
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);