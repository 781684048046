import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, EndAssignmentReason } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getEndAssignmentReasons = createAsyncThunk<
    Array<EndAssignmentReason>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/end_assignment_reason/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getEndAssignmentReasons();
            return res.data as EndAssignmentReason[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createEndAssignmentReason = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/end_assignment_reason/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createEndAssignmentReason(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteEndAssignmentReason = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/end_assignment_reason/delete',
    async (status_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteEndAssignmentReason(status_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateEndAssignmentReason = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/end_assignment_reason/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateEndAssignmentReason(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);