
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./bank_account_reducer";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.BankAccountList;
export const selectBankAccountListState = createSelector(select, selectState);
export const selectBankAccountList = createSelector(
    select,
    selectAll
);
export const selectBankAccountListEntities = createSelector(
    select,
    selectEntities
);