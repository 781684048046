import { createSelector } from "@reduxjs/toolkit";
import { selectAll, selectEntities, selectMarketingSummaryReportDownloadUrl, selectState } from "./marketing_summary_report_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.reportCenter.reportCenterSalesAndMarketing.marketingSummaryReportList;
export const selectMarketingSummaryReportListState = createSelector(select, selectState);
export const selectMarketingSummaryReportList = createSelector(
    select,
    selectAll
);
export const selectMarketingSummaryReportListEntities = createSelector(
    select,
    selectEntities
);
export const selectMarketingSummaryReportDownloadUrlState = createSelector(
    select,
    selectMarketingSummaryReportDownloadUrl
);