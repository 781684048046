import { useState } from 'react';
import SimpleTabbar from '../../../../../components/simple_tab_bar/simple_tab_bar';
import TabNavigation from '../../../../../components/tab_navigation/tab_navigation';
import { THEME } from '../../../../../enums';
import { IDepartmentStructure, ISkillOption } from '../../../../../interfaces';
import { useAppDispatch } from '../../../../../redux/store';
import CompanyOverView from './company_overview/company_overview';
import './company_summery.scss';
import Department from './departments/departments';



interface Props {
    isDepartment: boolean,
    onAddDepartment: () => void,
    onRemoveDepartment: (value: { id: string, value: string }) => void,
    onAddOption: (type: string) => void,
    onRemoveOption: (type: string, value: ISkillOption) => void,
    onDepartmentClick: (value: IDepartmentStructure) => void,
}

const CompanySummery: React.FunctionComponent<Props> = (props) => {
    const dispatch = useAppDispatch();
    const [tabIndex, setTabIndex] = useState<number>(0);

    return (
        <div className="cd-cs-container">
            {props.isDepartment === false ? <SimpleTabbar
                tabs={getTabList()}
                selected={tabIndex}
                onChange={(value) => setTabIndex(value)}
            // customStyle={{ boxShadow: `0px 0px 2px 0px ${THEME.tableBorderColor}` }}
            /> : <CompanyOverView onAddOption={props.onAddOption} onRemoveOption={props.onRemoveOption} />}
        </div>
    );


    function getTabList() {
        return [
            {
                title: 'company_overview',
                content: <CompanyOverView
                    onAddOption={props.onAddOption}
                    onRemoveOption={props.onRemoveOption}
                />,
            },
            {
                title: 'departments',
                content: <Department
                    onAddDepartment={props.onAddDepartment}
                    onRemove={props.onRemoveDepartment}
                    onDepartmentClick={props.onDepartmentClick}
                />,
            },
        ];
    }




}

export default CompanySummery;