import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IBackOfficeUploadUrlResponse, IWAErrorReports } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { S3API } from "../../../../apis/s3_api";


export const getWAERListThunk = createAsyncThunk<
    IWAErrorReports[],
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/weekly_accounting/weekly_accounting_errors_report_list/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getWeeklyAErrorReports(payload);
            return res.data as IWAErrorReports[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const approveWAERThunk = createAsyncThunk<
    string,
    string[],
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/weekly_accounting/weekly_accounting_errors_report_list/approve/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.weeklyAErrorsReportsApprove(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const resolveWAERThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/weekly_accounting/weekly_accounting_report/resolve/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.weeklyAErrorsReportsResolve(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getWAERDownloadUrl = createAsyncThunk<
    IBackOfficeUploadUrlResponse,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@back_office/weekly_accounting/weekly_accounting_report/download_url/get',
    async (weekId, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getWAErrorReportDownloadUrl(weekId);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);