import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../store";
import { selectCreateState, selectState, selectUpdateState, selectUploadFileState, selectUploadURLState } from "./employee_dashboard_reducer";

const select = (state: IRootState) => state.employeeDashboardState;
export const selectEmployeeDashboardGetDetaisState = createSelector(select, selectState);
export const selectEmployeeDashboardCreateDetailsState = createSelector(select, selectCreateState);
export const selectEmployeeDashboardUpdateDetailsState = createSelector(select, selectUpdateState);


export const selectManageEmployeeGetUploadURLState = createSelector(select, selectUploadURLState);
export const selectManageEmployeeUploadFilesState = createSelector(select, selectUploadFileState);
