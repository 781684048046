import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { AppError } from "../../../../interfaces";
import { ThirdPartyDeductionType } from "../../../../interfaces/third_party_deduction_type";
import { catchRequestError } from "../../../../utils";


export const getDeductionById = createAsyncThunk<
    ThirdPartyDeductionType,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@backoffice/third_party_assignment/deduction_data/get',
    async (doc, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getThirdPartyDeductionTypeDetails(doc);
            return res.data as ThirdPartyDeductionType;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);