import './section_card.scss';

interface Props {
    children: React.ReactNode,
    title: string,
    backgroundColor: string,
    activeColor: string,
    icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }>

};

const SectionCard = (props: Props) => {
    return (
        <div className="scrd-container">
            <div className="scrd-header" style={{ backgroundColor: props.backgroundColor }}>
                {props.icon && <div className="scrd-icon">
                    <props.icon width={"100%"} height={"100%"} style={{ color: props.activeColor }} />
                </div>}
                <div className="scrd-path-name">
                    <span className={'scrd-path-txt'} style={{ color: props.activeColor }}>{props.title}</span>
                </div>
            </div>
            <div className="scrd-content">
                {props.children}
            </div>
        </div>
    );
}

export default SectionCard;