import { createAsyncThunk } from "@reduxjs/toolkit";
import { MyCallDeckAPI } from "../../../apis/my_call_deck_api";
import { AppError } from "../../../interfaces";
import { ICompany, IDepartment } from "../../../interfaces";
import { catchRequestError } from "../../../utils";

export const getMyCallDeckList = createAsyncThunk<
  Array<ICompany | IDepartment>,
  void,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@my_call_deck/my_call_deck_list/get", async (_, thunkAPI) => {
  try {
    const res = await MyCallDeckAPI.getAllMyCallDeck();
    if (typeof res.data === "string") return [];
    const arr = res.data.data ? res.data.data : res.data; 
    return arr as (ICompany | IDepartment)[];
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});
