import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./service_profile_list_reducer";

const select = (state: IRootState) => state.adminCenter.serviceProfileList;
export const selectServiceProfileListState = createSelector(select, selectState);
export const selectServiceProfileList = createSelector(
    select,
    selectAll
);
export const selectServiceProfileListEntities = createSelector(
    select,
    selectEntities
);