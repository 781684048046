import { useTranslation } from "react-i18next";
import "./job_posting.scss";
import { FC, useEffect, useState } from "react";
import { CustomButton, SpinnerScreen } from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import JobPostingStepTwo from "./step_two/step_two";
import JobPostingStepOne from "./step_one/step_one";
import {
  getjobpPostChatGPTQueryThunk,
  useAppDispatch,
  useAppSelector,
  selectJobPostQstnState,
  postJobPostThunk,
  getJobPostThunk,
  patchJobPostThunk,
  deleteJobPostThunk,
  postSnackbarMessage,
  postJobPostToGoogleThunk,
  selectJobDetailsByIdState,
} from "../../../../redux/store";
import { ISuccessProfileQA } from "../../../../interfaces";
import JobPostingQuestionPopUp from "./job_post_question_popup/job_posting_question_popup";
import { Portal } from "react-portal";
import { jobPostQuestionSliceActions } from "../../../../redux/job_order/details/success_profile/job_post/job_post_reducer";
import { IJobPost } from "../../../../interfaces/job_posting";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";
import { JobOrderAPI } from "../../../../apis";
import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import PostToGoogleSuccessPopup from "./job_post_to_google_success";

interface Props {
  currentLoginUserName: string | undefined;
  // successProfileData: Array<ISuccessProfileQA> | undefined;
  job_id: string;
}

const JobPosting: FC<Props> = (props) => {
  const { currentLoginUserName, job_id } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const jobState = useAppSelector((state) =>
    selectJobDetailsByIdState(job_id)(state)
  );
  const jobPostQuestionState = useAppSelector((state) =>
    selectJobPostQstnState(state)
  );
  const [successProfileData, setJobSuccessProfileData] = useState<
    Array<ISuccessProfileQA>
  >([]);
  useEffect(() => {
    if (
      jobState.data &&
      jobState.data.success_profile_question_answers &&
      jobState.data.success_profile_question_answers.length > 0
    ) {
      setJobSuccessProfileData(jobState.data.success_profile_question_answers);
    }
  }, [jobState.data]);
  const initialForm = {
    job_id: job_id,
    description: "",
    modified_by: "",
    created_by: "",
    modified_date: 0,
    id: "",
    date_posted: 0,
    job_title: "",
    job_location: "",
    hiring_organization: "",
    status: "",
    is_deleted: false,
    link_to_apply: "",
    is_posted: false,
  }
  const [formState, setFormState] = useState<IJobPost>(initialForm);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const [qstnsAnswered, setQstnsAnswered] = useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = useState<string>("");
  const [showQstnPopup, setShowQstnPopup] = useState<boolean>(false);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);


  useEffect(() => {
    if (job_id) {
      dispatch(getJobPostThunk(job_id));
    }
    return () => {
      dispatch(jobPostQuestionSliceActions.clearState());
    }
  }, []);
  useEffect(() => {
    if (jobPostQuestionState && jobPostQuestionState?.getData?.response && jobPostQuestionState?.getData?.loading === LoadingType.succeeded) {
      setFormState({
        ...formState,
        ...jobPostQuestionState?.getData?.response,
      });
      setCurrentStep(1);

    }
  }, [jobPostQuestionState, jobPostQuestionState?.getData?.response, jobPostQuestionState?.getData?.loading]);


  function handleFieldChange(fieldId, value) {
    setFormState({ ...formState, [fieldId]: value });
  }
  function handleBack() {
    if (currentStep !== 0 && currentStep <= 2) {
      setCurrentStep(currentStep - 1);
    }
  }
  async function handleNext() {
    if (currentStep === 0) {
      if (
        jobPostQuestionState?.getData?.error !== null &&
        jobPostQuestionState?.getData?.loading === LoadingType.failed
      ) {
        await dispatch(postJobPostThunk(formState));
      } else {
        await dispatch(patchJobPostThunk({ ...formState, is_posted: false }));
      }
      setSnackBarMessage("Job Post Saved Successfully!");
      setCurrentStep(currentStep + 1);
      dispatch(getJobPostThunk(job_id));

      // dispatch(jobPostQuestionSliceActions.clearState());
    }
  }
  useEffect(() => {
    if (jobPostQuestionState && jobPostQuestionState?.loading === LoadingType.failed) {
      setQstnsAnswered(true);

      setShowErrorMsg(jobPostQuestionState?.error?.message);
      setTimeout(() => setShowErrorMsg(""), 5000);
    }
    if (jobPostQuestionState && jobPostQuestionState?.loading === LoadingType.succeeded) {
      setQstnsAnswered(false);

      setShowQstnPopup(true);
      setEnableEdit(true);
    }

  }, [jobPostQuestionState?.loading])
  const handleCreateJobPost = async () => {
    const checkAllQstnsAnswered = successProfileData?.some(
      (field) => field.answer === "" || field.answer === null
    );
    if (checkAllQstnsAnswered) {
      setQstnsAnswered(checkAllQstnsAnswered);
      setShowErrorMsg(t("please_fill_all_success_profile_qstns"));
      setTimeout(() => setShowErrorMsg(""), 5000);
    }
    else {
      dispatch(getjobpPostChatGPTQueryThunk(job_id));
    }
  };
  const handleEdit = () => {
    setEnableEdit(true);
    setCurrentStep(currentStep - 1);
    // dispatch(getJobPostThunk(job_id));
  };

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await JobOrderAPI.deleteJobPosts({ job_id: job_id, job_post_id: jobPostQuestionState?.getData?.response?.id });
      if (res.data) {
        setCurrentStep(0);
        dispatch(getJobPostThunk(job_id));
        setShowDeleteDialog(false);
        dispatch(postSnackbarMessage("Job Post " + res.data));
      }
    } catch (e: any) {
      const message = e.response?.data?.detail?.error?.join("\n");
      setSnackBarMessage(message);
    } finally {
      setDeleting(false);
      setShowDeleteDialog(false);
    }
    setFormState(initialForm);
    dispatch(jobPostQuestionSliceActions.clearState());
  };
  function getActiveStep() {
    if (jobPostQuestionState?.getData?.loading === LoadingType.pending) {
      return <SpinnerScreen />;
    }
    if (currentStep === 1) {
      return (
        <div>
          <JobPostingStepTwo
            currentLoginUserName={currentLoginUserName}
            description={formState.description}
            handleDelete={() => { setShowDeleteDialog(true) }}
            handleEdit={handleEdit}
            jobPostData={jobPostQuestionState?.getData?.response}
          />
        </div>
      );
    }
    if (currentStep === 0) {
      return (
        <div>
          <JobPostingStepOne
            currentLoginUserName={currentLoginUserName}
            handleCreateJobPost={handleCreateJobPost}
            qstnsAnswered={qstnsAnswered}
            showErrorMsg={showErrorMsg}
            formState={formState}
            handleFieldChange={handleFieldChange}
          // loading={jobPostQuestionState?.getData?.loading === LoadingType.pending}
          />
        </div>
      );
    }
  }
  function _isCurrentStepValid() {
    if (currentStep === 0) return true;
    return true;
  }
  const snackbarAction = () => {
    return (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setSnackBarMessage(null)}
        >
          <Close fontSize="small" />
        </IconButton>
      </>
    );
  };
  const [showPostGoogleSuccess, setShowPostGoogleSuccess] = useState<boolean>(false);
  useEffect(() => {
    if (jobPostQuestionState?.postGoogleData?.loading === LoadingType.succeeded) {
      setShowPostGoogleSuccess(true);
    }
  }, [jobPostQuestionState?.postGoogleData?.loading])
  const handlePostJobToGoogle = () => {
    dispatch(postJobPostToGoogleThunk(formState));
  }
  const handleOnSuccessPopupClose = () => {
    setShowPostGoogleSuccess(false);
    dispatch(jobPostQuestionSliceActions.clearPostGoogleState());
    dispatch(getJobPostThunk(job_id));
  }
  const handleCancel = () => {
      setFormState({...formState, description: ""});
      setCurrentStep(currentStep)
  }
  return (
    <>
      <div className="jp-container">
        <div style={{ marginBottom: "2rem" }}>{getActiveStep()}</div>
        <div className="jp-actions">
          {/* <div className="btn-cancel">
            {currentStep !== 0 && currentStep <= 2 && (
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("back")}
                enable={_isCurrentStepValid()}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={handleBack}
              />
            )}
          </div> */}

          <div className="btn-cancel">
            {enableEdit && currentStep === 0 && (
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("cancel")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => {
                  jobPostQuestionState && jobPostQuestionState?.getData?.response === null ? handleCancel() : setCurrentStep( currentStep + 1);
                }}
              />
            )}
          </div>
          <div className="btn-new">
            {currentStep === 1 ? (
              <CustomButton
                loading={false}
                textStyle={{
                  textTransform: "capitalize",
                }}
                name={t("post_job_to_google")}
                enable={jobPostQuestionState?.getData?.response?.is_posted === false ? true : false}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={handlePostJobToGoogle}
              />
            ) : (
              <CustomButton
                loading={false}
                textStyle={{
                  textTransform: "capitalize",
                }}
                name={t("save_and_next")}
                enable={formState?.description !== undefined && formState?.description !== "" ? true : false}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={handleNext}
              />
            )}
          </div>
        </div>
        {showQstnPopup && (
          <Portal>
            <JobPostingQuestionPopUp
              error={jobPostQuestionState?.error}
              onClose={() => {
                setShowQstnPopup(false);
                // dispatch(jobPostQuestionSliceActions.clearState());
              }}
              visible={showQstnPopup}
              title={t("something_went_wrong")}
              loading={jobPostQuestionState?.loading === LoadingType.pending}
              question={jobPostQuestionState?.response}
            />
          </Portal>
        )}
        {showDeleteDialog && job_id && (
          <Portal>
            <DeleteConfirmationDialog
              message={t("delete_job_post")}
              onCancel={() => setShowDeleteDialog(false)}
              onConfirm={handleDelete}
              deleting={deleting}
              deleteType={t("job_post")}
              onClearError={()=>{}}
            />
          </Portal>
        )}
        <Snackbar
          open={snackBarMessage !== null}
          autoHideDuration={5000}
          onClose={() => setSnackBarMessage(null)}
          message={snackBarMessage}
          action={snackbarAction()}
        />
        {showPostGoogleSuccess && (
          <Portal>
            <PostToGoogleSuccessPopup
              currentLoginUserName={currentLoginUserName}
              onClose={handleOnSuccessPopupClose}
              visible={showPostGoogleSuccess}
            />
          </Portal>
        )}
      </div>
    </>
  );

};

export default JobPosting;