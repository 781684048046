import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, JobDocument, SalesDocument } from "../../../../interfaces";
import * as thunk from './marketing_docs_thunk';


export interface SalesDocumentState {
    error?: AppError | null,
    loading: LoadingType,
    data: Array<SalesDocument> | null,
    videoUpload: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    documentUpload: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    documentUpdate: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    documentDelete: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}


const initialState: SalesDocumentState = {
    error: null,
    loading: LoadingType.idle,
    data: null,
    videoUpload: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    documentUpload: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    documentUpdate: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    documentDelete: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
}


export const salesDocumentSlice = createSlice({
    name: 'salesDocument',
    initialState: initialState,
    reducers: {
        clearUpdateState(state) {
            state.documentUpdate = { ...initialState.documentUpdate, error: null };
        },
        clearUploadState(state) {
            state.documentUpload = { ...initialState.documentUpload, error: null };
        },
        clearDeleteState(state) {
            state.documentDelete = { ...initialState.documentDelete, error: null };
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(thunk.getSalesDocuments.pending, (state, action) => ({ ...state, loading: LoadingType.pending }))
            .addCase(thunk.getSalesDocuments.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload }))
            .addCase(thunk.getSalesDocuments.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, data: action.payload }))

            .addCase(thunk.createUploadVideo.pending, (state, action) => ({ ...state, videoUpload: { ...state.videoUpload, loading: LoadingType.pending, response: '', error: null } }))
            .addCase(thunk.createUploadVideo.rejected, (state, action) => ({ ...state, videoUpload: { ...state.videoUpload, loading: LoadingType.failed, response: '', error: action.payload } }))
            .addCase(thunk.createUploadVideo.fulfilled, (state, action) => ({ ...state, videoUpload: { ...state.videoUpload, loading: LoadingType.succeeded, response: action.payload, error: null } }))

            .addCase(thunk.createUploadDocument.pending, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.pending, response: '', error: null } }))
            .addCase(thunk.createUploadDocument.rejected, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.failed, response: '', error: action.payload } }))
            .addCase(thunk.createUploadDocument.fulfilled, (state, action) => ({ ...state, documentUpload: { ...state.documentUpload, loading: LoadingType.succeeded, response: action.payload, error: null } }))

            .addCase(thunk.deleteUploadDocument.pending, (state, action) => ({ ...state, documentDelete: { ...state.documentDelete, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deleteUploadDocument.rejected, (state, action) => ({ ...state, documentDelete: { ...state.documentDelete, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deleteUploadDocument.fulfilled, (state, action) => ({ ...state, documentDelete: { ...state.documentDelete, loading: LoadingType.succeeded, response: action.payload, error: null } }))

            .addCase(thunk.updateUploadDocument.pending, (state, action) => ({ ...state, documentUpdate: { ...state.documentUpdate, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.updateUploadDocument.rejected, (state, action) => ({ ...state, documentUpdate: { ...state.documentUpdate, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.updateUploadDocument.fulfilled, (state, action) => ({ ...state, documentUpdate: { ...state.documentUpdate, loading: LoadingType.succeeded, response: action.payload, error: null } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const salesDocumentActions = salesDocumentSlice.actions;
export const selectSalesDocumentState = (state: SalesDocumentState) => state;
