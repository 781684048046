import { useTranslation } from 'react-i18next';
import { LocationIcon, CallIcon, GlobeIcon, ActiveAssignmentsIcon, BriefcaseIcon, CallWhiteIcon } from '../../../../../icons';
import { addressParser, CustomButton, getArrayStrings, getHttpsString } from '../../../../../utils';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, getPreSignedURLForDownload, selectSingleProfilePictureStateById } from "../../../../../redux/store";
import { Portal } from "react-portal";
import UserAvatar from '../../../../../components/user_avatar/user_avatar';
import UploadProfile from '../../../../../components/image_crop/image_crop_popup';
import SocialMediaProfile from '../../../../../components/social_media_profile/social_media_profile';
import { LoadingType, ProfilePictureType, THEME } from '../../../../../enums';
import './insurance_company_details_section.scss';
import { RiskMgmtInsuranceCompany } from '../../../../../interfaces/risk_mgmt_insurance_company';

interface Props {
    insurance_company: RiskMgmtInsuranceCompany|undefined,
}

const InsuranceCompanyDetailsSection: React.FunctionComponent<Props> = (props) => {
    const {insurance_company} = props;
    const { t } = useTranslation();

    const companyProfileSections = [
        {
            sectionTitle: 'address',
            sectionIcon: LocationIcon,
            sectionContent: [
                {
                    title: 'address', valueField: addressParser({
                        'street_address': insurance_company?.street_address,
                        'city': insurance_company?.city,
                        'state':  insurance_company?.state,
                        'zip_code': insurance_company?.zip_code,
                    })
                },
            ]
        },

        {
            sectionTitle: 'contact',
            sectionIcon: CallIcon,
            sectionContent: [
                { title: 'main_contact', valueField: 'contact_name' },
                { title: '', valueField: 'phone' },
            ]
        },

        {
            sectionTitle: 'website',
            sectionIcon: GlobeIcon,
            sectionContent: [
                { title: 'website', valueField: 'website' },
            ]
        },
    ];


    return (
        <div className="cds-container">
            <InsuranceCompanyInfo
                name={insurance_company?.insurance_company_name}
                uri={""}
                objectId={insurance_company?.id}
                rating={5}
                level={5}
            />
            {companyProfileSections.map((doc, index) => {
                return (
                    <div key={index} className="cds-info-container">
                        <div className="cds-info-icon">
                            <doc.sectionIcon width={"100%"} height={"100%"} />
                        </div>
                        <div className="cds-info-content">
                            {doc.sectionContent.map((data, index) => {
                                const arrayValue = getValues(data.valueField);
                                return (
                                    <div key={index} className="cds-info-holder">
                                        <div className="cds-into-title">
                                            {
                                                doc.sectionTitle === "contact" ?
                                                    <span style={{ display: "grid" }}>{t(data.title).toUpperCase()}</span> :
                                                    <span>{t(data.title).toUpperCase()}</span>
                                            }
                                        </div>
                                        <div
                                            className="cds-info-value"
                                            style={{ marginBottom: data.lineTwo ? '0px' : '0.5em' }}
                                        >
                                            {arrayValue}
                                        </div>
                                        {data.lineTwo && <div className="cds-info-value">{getValues(data.lineTwo)}</div>}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            {insurance_company?.social_media != null && <SocialMediaProfile
                title={t('social_media').toUpperCase()}
                profile={insurance_company?.social_media }
            />}
        </div>
    );

    function getValues(obj: any) {
        if (insurance_company == null) return (<span>{''}</span>);

        if (Array.isArray(obj)) {
            return getArrayStrings(obj, insurance_company);
        }

        if (obj === "website") {
            return (
                (insurance_company?.website !== "" && insurance_company?.website !== null ? <span style={{ textTransform: "none" }}><a href={getHttpsString(insurance_company?.website)} target="_blank" rel="noreferrer">{insurance_company?.website}</a></span> : <span className="na-span">NA</span>)
            )
        }

        if (obj === "phone") {
            return (
                (insurance_company?.phone !== null && insurance_company?.phone !== "") ? <span>{insurance_company?.phone}</span> : <span className="na-span">NA</span>
            )
        }
        if (typeof obj === "object") {
            return obj;
        }
        const value = (obj !== '' && insurance_company![obj] !== null) ? insurance_company![obj] : '';
        return (
            <span>{value}</span>
        );
    }


}

const InsuranceCompanyInfo = (props) => {
    const { name, rating, level } = props;
    const dispatch = useAppDispatch();
    const [showImageUpload, setShowImageUpload] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);
    const [imageURL, setImageURL] = useState('');

    const profilePictureState = useAppSelector((state) => selectSingleProfilePictureStateById(ProfilePictureType.auditReport + '-' + props.objectId)(state));

    useEffect(() => {
        setSentRequest(true);
        dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.auditReport }));
    }, [])

    function updateProfile() {
        dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.auditReport }));
        setSentRequest(true);
    }

    useEffect(() => {
        if (sentRequest && profilePictureState.download.loading === LoadingType.succeeded) {
            setSentRequest(false);
            setImageURL(profilePictureState.download.response ?? '');
        }
    }, [profilePictureState.download.loading])

    return (
        <div className="cds-user-info">
            <UserAvatar
                onClick={() => (setShowImageUpload(true))}
                url={imageURL}
                rating={{ value: rating, level: level }}
            />
            <div className="cds-user-name">
                <span>{name}</span>
            </div>
            {showImageUpload && <Portal>
                <UploadProfile
                    onClose={() => setShowImageUpload(false)}
                    onDismiss={() => setShowImageUpload(false)}
                    visible={showImageUpload}
                    onApprove={updateProfile}
                    objectId={props.objectId}
                    objectType={ProfilePictureType.auditReport}
                />
            </Portal>}
        </div>
    );
}



const buttonsList = [
    //{ title: 'assign_to_job_order', icon: PlusWhiteIcon, type: 'assign_to_job_order', color: THEME.defaultHighLightColor },
    { title: 'company_assignments', icon: ActiveAssignmentsIcon, type: 'company_assignments', color: THEME.statusActiveColor },
    { title: 'company_job_orders', icon: BriefcaseIcon, type: 'company_job_orders', color: THEME.defaultHighLightColor },
    { title: 'schedule_a_sales_call', icon: CallWhiteIcon, type: 'schedule_a_sales_call', color: THEME.buttonColor15 },
];

interface CompanyActionsProps {
    onClick: (type: string) => void
}



const CompanyActions: React.FunctionComponent<CompanyActionsProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="cds-action-container">
            {buttonsList.map((doc, index) => {
                return (
                    <div key={index} className="cds-action">
                        <CustomButton
                            customStyle={{ width: '-webkit-fill-available' }}
                            leftIcon={(<div className="cds-action-icon"><doc.icon width={"100%"} height={"100%"} style={{ color: "#fff" }} /></div>)}
                            loading={false}
                            textStyle={{ textTransform: 'none' }}
                            name={t(doc.title)}
                            enable={true}
                            backgroundColor={doc.color}
                            onClick={() => props.onClick(doc.type)}
                        />
                    </div>

                )
            })}
        </div>
    );
}
export default InsuranceCompanyDetailsSection;