import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../interfaces";
import { catchRequestError, delay} from "../../utils";


export const postSnackbarMessage = createAsyncThunk<
    string,
    string | null,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@snackbar/message/post',
    async (message, thunkAPI) => {
        try {
            return message;
        }   catch (error){
            return catchRequestError(error, thunkAPI);
        }
    }
);