export class MarketingCampaign {
  id: string;
  sequence_number: number;
  marketing_campaign_name: string;
  marketing_campaign_goal: string;
  target_audience: string;
  budget: number;
  gross_profit_potential: number;
  start_date_time: number;
  start_date: number;
  start_time: string;
  end_date_time: number;
  end_date: number;
  end_time: string;
  social_media_platforms_to_publish: string[];
  agency_id: string;
  branch_id: string;
  is_deleted: boolean;
  modified_by?:string;
  created_by?:string;
  is_completed?: boolean;

}
export class UpdateMarketingCampaign {
  id: string;
  sequence_number: number;
  marketing_campaign_name: string;
  marketing_campaign_goal: string;
  target_audience: string;
  budget: string;
  gross_profit_potential: string;
  start_date_time: number;
  start_date: number;
  start_time: string;
  end_date_time: number;
  end_date: number;
  end_time: string;
  social_media_platforms_to_publish: string[];
  agency_id: string;
  branch_id: string;
  is_deleted: boolean;
  modified_by?:string;
  created_by?:string;
}

export interface SendByEmail {
  file_id: string;
  email_id: string;
}
