import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, ContactMethod } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getContactMethods = createAsyncThunk<
    Array<ContactMethod>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/contact_method/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getContactMethod();
            return res.data as ContactMethod[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createContactMethod = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/contact_method/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createContactMethod(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteContactMethod = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/contact_method/delete',
    async (method_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteContactMethod(method_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateContactMethod = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/contact_method/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateContactMethod(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);