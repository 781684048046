import { combineReducers } from "@reduxjs/toolkit";
import { arrivalCheckCallsReportListSlice } from "./arrival_check_calls_report/arrival_check_calls_report_reducer";
import { endOFDCReportListSlice } from "./end_of_1st_days_calls_report/end_of_1st_days_calls_report_reducer";
import { endOSDCReportListSlice } from "./end_of_2nd_days_calls_report/end_of_2nd_days_calls_report_reducer";
import { fridayCallsReportsListSlice } from "./friday_calls_reports/friday_calls_reports_reducer";

export const reportCenterServiceReducers = combineReducers({
    [arrivalCheckCallsReportListSlice.name]: arrivalCheckCallsReportListSlice.reducer,
    [endOFDCReportListSlice.name]: endOFDCReportListSlice.reducer,
    [endOSDCReportListSlice.name]: endOSDCReportListSlice.reducer,
    [fridayCallsReportsListSlice.name]: fridayCallsReportsListSlice.reducer,
});

export * from "./arrival_check_calls_report/arrival_check_calls_report_selector";
export * from "./arrival_check_calls_report/arrival_check_calls_report_thunk";

export * from "./end_of_1st_days_calls_report/end_of_1st_days_calls_report_selector";
export * from "./end_of_1st_days_calls_report/end_of_1st_days_calls_report_thunk";

export * from "./end_of_2nd_days_calls_report/end_of_2nd_days_calls_report_selector";
export * from "./end_of_2nd_days_calls_report/end_of_2nd_days_calls_report_thunk";

export * from "./friday_calls_reports/friday_calls_reports_selector";
export * from "./friday_calls_reports/friday_calls_reports_thunk";
