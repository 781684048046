import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, Profile } from "../../../../interfaces";
import * as thunk from "./user_details_thunk";

export interface UserDetailsState {
    id: string | null,
    error?: AppError | null,
    loading: LoadingType,
    refreshLoading: LoadingType,
    profile: Profile | null,
}

const initialState: UserDetailsState = {
    id: null,
    error: null,
    loading: LoadingType.idle,
    refreshLoading: LoadingType.idle,
    profile: null,
}


export const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState: initialState,
    reducers: {
        clearUserDetailsState(state){
            return {...state, ...initialState};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAdminCenterUserManagementUserDetails.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, id: action.meta.arg }))
            .addCase(thunk.getAdminCenterUserManagementUserDetails.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAdminCenterUserManagementUserDetails.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, profile: action.payload, }))

            /// refresh user 
            .addCase(thunk.refreshUserDetails.pending, (state, action) => ({ ...state, refreshLoading: LoadingType.pending, error: null, }))
            .addCase(thunk.refreshUserDetails.rejected, (state, action) => ({ ...state, refreshLoading: LoadingType.failed, error: action.payload }))
            .addCase(thunk.refreshUserDetails.fulfilled, (state, action) => ({ ...state, refreshLoading: LoadingType.succeeded, error: null, profile: action.payload, }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearUserDetailsState } = userDetailsSlice.actions;
export const selectState = (state: UserDetailsState) => state;
export const selectLoading = (state: UserDetailsState) => state.loading;
export const selectError = (state: UserDetailsState) => state.error;


