import { createAsyncThunk } from "@reduxjs/toolkit";
import { SalesMarketingAPI } from "../../../../apis/sales_marketing_api";
import { AppError } from "../../../../interfaces";
import { SalesCall } from "../../../../interfaces/sales_call";
import { catchRequestError } from "../../../../utils";

export const getSalesCallByIdThunk = createAsyncThunk<
  SalesCall,
  string,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@sales_marketing/sales_call_activity/sales_call_by_id/get", 
async (id, thunkAPI) => {
  try {
    const res = await SalesMarketingAPI.getSalesCallById(id);
    return res.data as SalesCall;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});