import React from "react";
import { TFunction } from "react-i18next";
import {
  COMPANY_STATUS,
  PREFERED_CONTACT,
  ROLE,
  THEME,
  TrafficLightMetricsType,
} from "../enums";
import {
  CallBlueIcon,
  CallIcon,
  EmailBlueIcon,
  EmailIcon,
  FacebookIcon,
  IndeedIcon,
  IndeedLogoIcon,
  LinkedInIcon,
  PaperPlaneIcon,
  PlusWhiteIcon,
  TextIcon,
  WebsiteIcon,
  ZipRecruiterChairIcon,
  TextEmAllIcon,
  InPerson,
  Zoom,
  Teams,
  GoogleMeet,
  AdministratorIcon,
  SuperUserIcon,
  BranchManagerIcon,
  AccountManagerIcon,
  RecruiterIcon,
  CallDeckOwnerIcon,
} from "../icons";
import * as MatIcons from '@material-ui/icons';
import { IProfileDropdown, ITalent, ITalentMatchesRequestBody, Profile } from "../interfaces";

import { MapToObj } from "../interfaces/pdf_auto_fill";

export function delay(delayInms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}

export function getUserRoleText(key: string) {
  switch (key) {
    case ROLE.administrator:
      return "administrator";
    case ROLE.superUser:
      return "super_user";
    case ROLE.branchManager:
      return "branch_manager";
    case ROLE.accountManager:
      return "account_manager";
    case ROLE.recruiter:
      return "recruiter";
    case ROLE.callDeskOwner:
      return "call_deck_owner";
    case ROLE.frontOfficeCoordinator:
      return "front_office_coordinator";
    case ROLE.superAdmin:
      return "super_admin";
    default:
      return "NA";
  }
}

export function getUserRoleIcon(key: string) {
  switch (key) {
    case ROLE.administrator:
      return <AdministratorIcon />;
    case ROLE.superUser:
      return <SuperUserIcon />;
    case ROLE.branchManager:
      return <BranchManagerIcon />;
    case ROLE.accountManager:
      return <AccountManagerIcon />;
    case ROLE.recruiter:
      return <RecruiterIcon />;
    case ROLE.callDeskOwner:
      return <CallDeckOwnerIcon />;
    default:
      return "NA";
  }
}


export function GetCloseAction({ name, onClick }: { name: string, onClick: () => void }) {
  return (
    <a className="tab-close-action" onClick={onClick}>
      <div className="tab-close-icon">
        <MatIcons.Close fontSize='small' color='inherit' />
      </div>
      <span>{name}</span>
    </a>
  );
}

export const getCompanyStatusText = (
  key: string | undefined | null,
  t: TFunction<"translation", undefined>
) => {
  switch (key) {
    case COMPANY_STATUS.prospect:
      return (
        <span style={{ color: THEME.tableRowTextColor }}>{t("prospect")}</span>
      );
    case COMPANY_STATUS.suspect:
      return (
        <span style={{ color: THEME.tableRowTextColor }}>{t("suspect")}</span>
      );
    case COMPANY_STATUS.currentCustomer:
      return (
        <span style={{ color: THEME.tableRowTextColor }}>
          {t("current_customer")}
        </span>
      );
    // case COMPANY_STATUS.topTenTargetAccount:
    //   return "top_ten_target_account";
    case COMPANY_STATUS.inActive:
      return <span style={{ color: "#b1b1b1" }}>{t("inactive")}</span>;
    default:
      return "NA";
  }
};

/// [TALENT]

export function getTalentMatchQuery(data: ITalent): ITalentMatchesRequestBody {
  return {
    certification_ids: data.certifications ?? [],
    professional_skills_ids: data.professional_skills ?? [],
    education_ids: data.education ?? [],
    credentials_ids: data.credentials ?? [],
    safety_equipment_ids: data.safety_equipment ?? [],
  };
}

export const mapOptions = (
  options: any[],
  labelProp: string,
  valueProp: string,
  addStringFlag?: boolean,
) => {
  let mappedList: Array<{ label: string; value: string }> = [];
  if (options && options.length > 0) {
    mappedList = options.map((option) => ({
      label: addStringFlag ? option[labelProp] + "%" : option[labelProp],
      value: option[valueProp],
    }));
  }
  return mappedList;
};

export const getContactIcon = (method: string | null) => {
  if (method != null && method !== "") {
    if (method === "TEXT")
      return (
        <TextIcon
          width={"1.25vw"}
          height={"1.3541666666666667vw"}
          fill={"#00A4DC"}
        />
      );
    if (method === "ZIPRECRUITER")
      return (
        <ZipRecruiterChairIcon
          width={"1.25vw"}
          height={"1.3541666666666667vw"}
        />
      );
    if (method === "EMAIL")
      return (
        <EmailIcon
          width={"1.25vw"}
          height={"1.3541666666666667vw"}
          fill={"#00A4DC"}
        />
      );
    if (method === "FACEBOOK")
      return (
        <FacebookIcon
          width={"1.25vw"}
          height={"1.3541666666666667vw"}
          fill={"#00A4DC"}
        />
      );
    if (method === "LINKEDIN")
      return (
        <LinkedInIcon
          width={"1.25vw"}
          height={"1.3541666666666667vw"}
          fill={"#00A4DC"}
        />
      );
    if (method === "IN_PERSON")
      return (
        <InPerson
          width={"1.25vw"}
          height={"1.3541666666666667vw"}
          fill={"#00A4DC"}
        />
      );
    if (method === "ZOOM")
      return (
        <Zoom
          width={"1.25vw"}
          height={"1.3541666666666667vw"}
          fill={"#00A4DC"}
        />
      );
    if (method === "TEAMS")
      return (
        <Teams
          width={"1.25vw"}
          height={"1.3541666666666667vw"}
          fill={"#00A4DC"}
        />
      );
    if (method === "GOOGLE_MEET")
      return (
        <GoogleMeet
          width={"2vw"}
          height={"2.5vw"}
          fill={"#00A4DC"}
          className="gmeet"
        />
      );
    if (method === "PHONE")
      return (
        <CallIcon
          width={"1.25vw"}
          height={"1.3541666666666667vw"}
          fill={"#00A4DC"}
        />
      );
    if (method === "INDEED")
      return (
        <IndeedLogoIcon width={"1.25vw"} height={"1.4vw"} fill={"#00A4DC"} />
      );
  }
  return <span>{"NA"}</span>;
};
export const getHttpsString = (url: string | undefined) => {
  if (url?.includes("https://") || url?.includes("http://")) {
    return url;
  }
  return `https://${url}`;
};

export const getWebsiteInfo = (website: string | null) => {
  if (website != null && website !== "") {
    return (
      <a
        href={getHttpsString(website)}
        className="website-icon"
        target="_blank" rel="noreferrer"
      >
        <WebsiteIcon width={"100%"} height={"100%"} />
      </a>
    );
  }
  return <span>{""}</span>;
};

export const getRatingBackgrounColor = (type: string) => {
  switch (type) {
    case TrafficLightMetricsType.low:
      return "#ED4857";
    case TrafficLightMetricsType.medium:
      return "#EEAC17";
    case TrafficLightMetricsType.high:
      return "#53C53F";
    default:
      return "#ED4857";
  }
};

export const sortByOrders = (list: any[], statusField: string) => {
  let openOrders: any[] = [];
  let filledOrders: any[] = [];
  let zeroOrders: any[] = [];
  list.forEach((i) => {
    if (i?.requested === null || i?.requested === 0) {
      zeroOrders.push(i);
    } else if (i[statusField] === "OPEN") {
      openOrders.push(i);
    } else if (
      i[statusField] === "FILLED" &&
      (i?.placed > 0 || i.requested > 0)
    ) {
      filledOrders.push(i);
    } else {
      zeroOrders.push(i);
    }
  });
  openOrders.sort((a, b) => b.open_orders - a.open_orders);
  filledOrders.sort((a, b) =>
    b.placed ? b.placed - a.placed : b.requested - a.requested
  );
  return [...openOrders, ...filledOrders, ...zeroOrders];
};

export const getUSFormat = (value, needDollar = true) => {
  if (value === null) return null;
  let dollar = needDollar ? "$" : "";
  let amount = String(value).split(".");
  if (amount.length === 1) return `${dollar} ${amount[0]}.00`.trim();
  return `${dollar} ${amount[0]}.${(amount[1] + "00").slice(0, 2)}`.trim();
};

export function getContactTypeIcon(value: string, color?: string) {
  if (value === PREFERED_CONTACT.email)
    return (
      <EmailBlueIcon width={"100%"} height={"100%"} style={{ color: color }} />
    );
  if (value === PREFERED_CONTACT.phone)
    return (
      <CallBlueIcon width={"100%"} height={"100%"} style={{ color: color }} />
    );
  if (value === PREFERED_CONTACT.linkedIn)
    return (
      <LinkedInIcon width={"100%"} height={"100%"} style={{ color: color }} />
    );
  if (value === PREFERED_CONTACT.facebook)
    return (
      <FacebookIcon width={"100%"} height={"100%"} style={{ color: color }} />
    );
  if (value === PREFERED_CONTACT.indeed)
    return (
      <IndeedLogoIcon width={"100%"} height={"100%"} style={{ color: color }} />
    );
  if (value === PREFERED_CONTACT.zoom)
    return (
      <Zoom width={"100%"} height={"100%"} style={{ color: color }} />
    );
  if (value === PREFERED_CONTACT.inperson)
    return (
      <InPerson width={"100%"} height={"100%"} style={{ color: color }} />
    );
  if (value === PREFERED_CONTACT.googleMeet)
    return (
      <GoogleMeet width={"100%"} height={"100%"} style={{ color: color }} />
    );
  if (value === PREFERED_CONTACT.teams)
    return (
      <Teams width={"100%"} height={"100%"} style={{ color: color }} />
    );
  if (value === PREFERED_CONTACT.zipRecruiter)
    return (
      <ZipRecruiterChairIcon
        width={"100%"}
        height={"100%"}
        style={{ color: color }}
      />
    );
  if (value === PREFERED_CONTACT.text)
    return <TextIcon width={"100%"} height={"100%"} style={{ color: color }} />;
  if (value === PREFERED_CONTACT.facebook)
    return (
      <FacebookIcon width={"100%"} height={"100%"} style={{ color: color }} />
    );
  if (value === PREFERED_CONTACT.indeed)
    return (
      <IndeedIcon width={"100%"} height={"100%"} style={{ color: color }} />
    );
  return <span>{""}</span>;
}

export function getTableAccessIcon(onClick: () => void) {
  return (
    <div className="table-access-container">
      <button className="table-access-icon" onClick={onClick}>
        {/* <AccessEnterIcon width={"100%"} height={"100%"} />
        <AccessFullEnterIcon className="img-hover" width={"100%"} height={"100%"} /> */}
      </button>
    </div>
  );
}

export function getStatusFromICreateState(value?: {
  object_id: string;
  status: string;
}) {
  if (value && value.status) return value.status;
  return "";
}
export function getUserNameFromProfileDropdown(
  value: IProfileDropdown | Profile
) {
  return `${value.first_name ?? ""} ${value.last_name ?? ""}`;
}

export function getPlusBtnIcon() {
  return (
    <div className="btn-icon">
      <PlusWhiteIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
    </div>
  );
}

export function getTextEmAllIcon() {
  return (
    <div className="btn-icon">
      <TextEmAllIcon width={"100%"}
        height={"100%"}
        style={{ color: "#fff" }}

      />
    </div>
  );
}


export function paperPlaneBtnIcon() {
  return (
    <div className="btn-icon">
      <PaperPlaneIcon
        width={"100%"}
        height={"100%"}
        style={{ color: "#fff" }}
      />
    </div>

  )
}

export function moneyFormat(value: number) {
  return isNaN(value) ? 0 : new Intl.NumberFormat().format(value);
}

export function formatSSN(ssn) {
  if (ssn === '' || ssn === null || ssn === undefined) {
    return '';
  }
  ssn = ssn.replace(/\D/g, '');
  return ssn.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
}

export const removeLastDecimal = (value: string) => {
  if (value.includes('.')) {
    const [integerPart, decimalPart] = value.split('.');
    if (decimalPart.length > 4) {
      return integerPart + '.' + decimalPart.slice(0, -1);
    }
  }
  return value;
};

export function formatNumber(n: number | undefined, decimalCount?: number) {
  if (isNaN(parseInt(n!.toString().replace(/,/g, '')))) return 0
  // format number 1000000 to 1,234,567
  if (n === undefined) return ""; // Return empty string if n is undefined

  // Check if n has decimal places
  const hasDecimal = n % 1 !== 0;

  // Format the integer part of the number
  let formattedNumber = n.toLocaleString("en-US", {
    minimumFractionDigits: hasDecimal ? decimalCount ? decimalCount : 2 : decimalCount ? decimalCount : 0,
    maximumFractionDigits: hasDecimal ? decimalCount ? decimalCount : 2 : decimalCount ? decimalCount : 0,
  });

  // Add commas to the formatted number
  formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedNumber;
}


export function formatNumberWithCommas(number) {
  if (!number && number !== 0) return '';
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function currencyConversion(
  value: number,
  digitsDecimal: number = 4,
  props?: { type: string; options?: Intl.NumberFormatOptions | undefined },
) {
  let formattedValue: string;

  if (typeof value === "number" && !isNaN(value)) {
    // Round the value to the specified number of decimal places
    const roundedValue = parseFloat(value.toFixed(digitsDecimal));

    // Determine the number of significant decimal places
    const decimalPart = roundedValue.toString().split('.')[1] || '';
    const hasSignificantDecimals = decimalPart.length > 2 && decimalPart.slice(2).replace(/0/g, '').length > 0;

    // Set the minimum and maximum fraction digits based on the presence of significant decimals
    const minFractionDigits = hasSignificantDecimals ? 2 : 2;
    const maxFractionDigits = hasSignificantDecimals ? digitsDecimal : 2;

    // Format the rounded value using Intl.NumberFormat
    formattedValue = Intl.NumberFormat(props?.type ?? "en-US", {
      style: "currency",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits,
    }).format(roundedValue);
  } else {
    // Return empty string if value is not a valid number
    return "";
  }
  return formattedValue;
}

export const formatPercentage = (percentage) => {
  if (percentage === undefined || percentage === null || percentage === "" || percentage === 0) {
    return "0.00%"; // Default value
  }

  // Convert percentage to a float for consistent formatting
  const formattedPercentage = parseFloat(percentage);

  // Check if it's a valid number
  if (isNaN(formattedPercentage)) {
    return "0.00%"; // Handle any invalid numbers
  }

  // Format to two decimal places
  return formattedPercentage.toFixed(2) + "%";
};

export function getLabelText(
  value: string,
  options?: { position?: "right" | "left" | "top"; required?: boolean }
) {
  let textValue = value;
  if (options && options?.required) {
    textValue = textValue + " *";
  }
  if (options && options?.position === "left") {
    textValue = textValue + ":";
  }
  if (options && options?.position === "right") {
    textValue = ":" + textValue;
  }
  return textValue;
}

export function handleDownload(path: string) {
  let aTag = document.createElement("a");
  aTag.setAttribute("href", path);
  aTag.setAttribute("target", "_blank");
  aTag.click();
  // if (path)return ( <a href={path} target="_blank" download={path}></a>)
}

const fixedDicimalsFormat = (value) => {
  let decimalValue = String(value); // Convert to string explicitly
  // Remove non-numeric characters except decimal point
  decimalValue = decimalValue.replace(/[^0-9.]/g, '');
  // Split the decimalValue into integer and fractional parts
  const parts = decimalValue.split('.');
  // Ensure we have at most 4 decimal places
  if (parts[1] && parts[1].length > 4) {
    parts[1] = parts[1].slice(0, 4);
  }
  // Reconstruct the decimalValue with at most 4 decimal places
  decimalValue = parts.join('.');
  // Update the state with the modified decimalValue
  return decimalValue
};

export function getStringArrayAppend(value: string[]) {
  let result = "";
  value.forEach((doc) => {
    if (result === "") {
      result = doc;
    }
    result = result + ", " + doc;
  });
  return result;
}

export function getArrayStrings(obj: Array<any>, data: any) {
  const res = obj
    .map((doc) => ({
      field: doc,
      value: doc !== "" && data![doc] !== null ? data![doc] : null,
    }))
    .filter((doc) => doc.value !== null && doc.value !== "");
  const lastIndex = res.length > 0 ? res.length - 1 : 0;
  return res.map((doc, index) => {
    if (doc.value === null || doc.value === "" || doc.value === 0) {
      if (doc.field === "end_date") {
        return <span>Present&nbsp;&nbsp;</span>;
      }
      return null;
    }
    if (doc.field === "start_date") {
      return (
        <span key={index}>
          {`${doc.value}${lastIndex === index ? " " : " - "}`}&nbsp;
        </span>
      );
    }
    if (doc.field === "end_date") {
      return <span key={index}>{`${doc.value}`}&emsp;</span>;
    }
    return (
      <span key={index}>
        {`${doc.value}${lastIndex === index ? " " : ", "}`}&nbsp;
      </span>
    );
  });
}

export function addressParser(docs: { [key: string]: any }) {
  function getInfo(condition: boolean, value: React.ReactNode) {
    if (condition) return value;
    return "";
  }

  return (
    <div className="address-parser">
      {(docs.street_address == null && docs.city === null && docs.state === null && docs.zip_code === null) ||
        (docs.street_address == "" && docs.city === "" && docs.state === "" && docs.zip_code === "") ?
        (
          <span className="na-span">NA</span>
        )
        :
        (
          <>
            <div className="first-line">
              {docs["street_address"] && (
                <span>{`${docs["street_address"]}${docs["city"] || docs["state"] || docs["zip_code"] ? `,` : ""
                  }`}</span>
              )}
            </div>
            <div className="second-line">
              {getInfo(
                docs["city"] !== "",
                <span>
                  {`${docs["city"]}${docs["city"] !== "" &&
                    (docs["state"] !== "" || docs["zip_code"] !== "")
                    ? ","
                    : ""
                    }`}
                  &nbsp;
                </span>
              )}
              {getInfo(
                docs["state"] !== "" || docs["zip_code"] !== "",
                <span>{`${docs["state"] ?? ""} ${docs["zip_code"] ?? ""}`}</span>
              )}
            </div>
          </>
        )
      }

    </div>
  );
}

export function convertToSSNFormat(value?: string | null) {
  if (value) {
    const splitList = value.split("-");
    const lastDigi =
      value.length === 4 ? value : splitList[splitList.length - 1];
    return `XXX-XX-${lastDigi}`;
  }
  return "";
}

export function isFormDirty(
  form: { [key: string]: any },
  value: { [key: string]: any } | null
) {
  if (value === null) return false;
  return Object.keys(form)
    .map((key) => {
      if (form[key] && typeof form[key] === "object")
        return isFormDirty(form[key], value[key]);
      return form[key] === value[key];
    })
    .includes(false);
}

export function stringifyParams(
  data: { [key: string]: any } | { [key: number]: any }
) {
  return Object.keys(data).reduce((res, key) => {
    if (res === "") return `${key}=${data[key]}`;
    return res + `&${key}=${data[key]}`;
  }, "");
}

export function getValueFromKey(
  keys: MapToObj | string[] | string,
  data: { [key: string]: any }
): any {
  if (Array.isArray(keys)) {
    return keys.map((key) => `${data[key] ?? ""}`).join(" ");
  }
  if (keys && typeof keys === "object") {
    if (typeof keys.child === "object") {
      return getValueFromKey(keys.child, data[keys.parent]);
    }
    return data[keys.parent][keys.child] ?? "";
  }
  return data[keys] ?? "";
}

export function convertToBoolean(value: string) {
  return value === "true" ? true : false;
}

export function renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
  let isFutureDate = day > new Date();

  // Customize the style for future dates
  const style = isFutureDate
    ? {
      backgroundColor: 'whitesmoke',
    }
    : {};

  return React.cloneElement(dayComponent, {
    style,
  });
}
