import { createEntityAdapter, createSlice, EntityAdapter, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { IContactApolloImport, ICompaniesApolloSearchResults, IApolloContactsResults } from "../../../interfaces/apollo_search_import";
import * as thunk from "./companies_search_import_thunk";
import { AppError } from "../../../interfaces";

export interface ApolloCompaniesListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IApolloContactsResults | null,
    importCompany: {
        error?: AppError | null,
        loading: LoadingType,
        response: any,
    }
}



const initialState: ApolloCompaniesListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,

    importCompany: {
        error: null,
        loading: LoadingType.idle,
        response: null
    },

};

export const apolloCompaniesListSlice = createSlice({
    name: 'apolloCompaniesList',
    initialState: initialState,
    reducers: {
        clearApolloCompaniesListError(state) {
            return { ...state, error: null };
        },
        clearApolloCompaniesLoadingState(state) {
            return { ...state, loading: LoadingType.pending };
        },
        clearApolloImportCompaniesState(state) {
            return {
                ...state,
                importCompany: {
                    ...state.importCompany,
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                }
            }
        },
        clearApolloCompaniesListState(state) {
            return {
                ...state, loading: LoadingType.idle, error: null, importCompany: {
                    ...state.importCompany,
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.appolloCompaniesSearchThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.appolloCompaniesSearchThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload }))
            .addCase(thunk.appolloCompaniesSearchThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, response: action.payload }))

            .addCase(thunk.apolloCompaniesImportThunk.pending, (state, action) => ({ ...state, importCompany: { ...state.importCompany, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.apolloCompaniesImportThunk.rejected, (state, action) => ({ ...state, importCompany: { ...state.importCompany, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.apolloCompaniesImportThunk.fulfilled, (state, action) => ({ ...state, importCompany: { ...state.importCompany, loading: LoadingType.succeeded, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const apolloCompaniesListActions = apolloCompaniesListSlice.actions;
// export const { selectAll, selectEntities, selectIds, selectById } = importApolloCompaniesListAdapter.getSelectors();
export const selectState = (state: ApolloCompaniesListState) => state;
export const selectLoading = (state: ApolloCompaniesListState) => state.loading;
export const selectError = (state: ApolloCompaniesListState) => state.error;   