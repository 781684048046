import { combineReducers } from "@reduxjs/toolkit";
import { transactionAssignmentSlice } from "./assignment_transactions/assignment_transactions_reducer";
import { timecardsErrorReportListSlice } from "./time_cards/errors_reports/errors_reports_reducer";
import { timecardsBatchSlice } from "./time_cards/time_cards_batch/time_cards_batch_reducer";
import { timecardsListSlice } from "./time_cards/time_cards_list/time_cards_list_reducer";
import { timecardsReportListSlice } from "./time_cards/time_cards_report_list/time_cards_report_list_reducer";
import { timeCardsUploadUrlSlice } from "./time_cards/time_cards_upload_docs/time_cards_upload_docs_reducer";
import { timecardsDashboardSlice } from "./timecards_dashboard/timecards_dashboard_reducer";
import { manageBatchSlice } from "./time_cards/time_cards_manage_batch/time_cards_manage_batch_reducer";
import { manageTimeCardsSlice } from "./time_cards/manage_time_cards/manage_time_cards_reducer";

export const backOfficeTimeCardsReducers = combineReducers({
    [timeCardsUploadUrlSlice.name]: timeCardsUploadUrlSlice.reducer,
    [timecardsErrorReportListSlice.name]: timecardsErrorReportListSlice.reducer,
    [timecardsBatchSlice.name]: timecardsBatchSlice.reducer,
    [timecardsListSlice.name]: timecardsListSlice.reducer,
    [timecardsReportListSlice.name]: timecardsReportListSlice.reducer,
    [timecardsDashboardSlice.name]: timecardsDashboardSlice.reducer,
    [transactionAssignmentSlice.name]: transactionAssignmentSlice.reducer,
    [manageBatchSlice.name]: manageBatchSlice.reducer,
    [manageTimeCardsSlice.name]: manageTimeCardsSlice.reducer,

});

export * from "./timecards_dashboard/timecards_dashboard_thunk";
export * from "./timecards_dashboard/timecards_dashboard_selector";

export * from "./assignment_transactions/assignment_transactions_thunk";
export * from "./assignment_transactions/assignment_transactions_selector";

export * from "./time_cards/time_cards_batch/time_cards_batch_thunk";
export * from "./time_cards/time_cards_batch/time_cards_batch_selector";

export * from "./time_cards/time_cards_list/time_cards_list_thunk";
export * from "./time_cards/time_cards_list/time_cards_list_selector";

export * from "./time_cards/time_cards_manage_batch/time_cards_manage_batch_thunk";
export * from "./time_cards/time_cards_manage_batch/time_cards_manage_batch_selector";

export * from "./time_cards/time_cards_report_list/time_cards_report_list_thunk";
export * from "./time_cards/time_cards_report_list/time_cards_report_list_selector";

export * from "./time_cards/time_cards_upload_docs/time_cards_upload_docs_thunk";
export * from "./time_cards/time_cards_upload_docs/time_cards_upload_docs_selector";

export * from "./time_cards/errors_reports/errors_reports_thunk";
export * from "./time_cards/errors_reports/errors_reports_selector";

export * from "./time_cards/manage_time_cards/manage_time_cards_thunk";
export * from "./time_cards/manage_time_cards/manage_time_cards_selector";

export * from "./time_cards/manage_time_cards/manage_time_cards_thunk";
export * from "./time_cards/manage_time_cards/manage_time_cards_selector";
