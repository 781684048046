import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";

export const getBankAccount = createAsyncThunk<
    Array<AdminCenterBackOfficeType>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/back_office/bank_account/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getBankAccountDetails();
            return res.data as AdminCenterBackOfficeType[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createBankAccount = createAsyncThunk<
    string,
    { 'value': string, 'description': string, 'bank_name': string, 'routing_number': string, 'account_number': string, 'account_type': string, 'purpose': string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/bank_account/post',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.createBankAccountDetails(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );

export const updateBankAccount = createAsyncThunk<
    string,
    { 'id': string, 'value': string, 'description': string, 'bank_name': string, 'routing_number': string, 'account_number': string, 'account_type': string, 'purpose': string, 'is_active': boolean, 'modified_by': string, 'modified_date': number, 'bank_type': string, 'created_by': string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/bank_account/update',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.updateBankAccountDetails(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );

export const deleteBankAccount = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/bank_account/delete',
        async (option_id, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.deleteBankAccountDetails(option_id);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );