import { useTranslation } from 'react-i18next';
import { TALENT_STATUS, THEME } from '../../enums';
import { SectorIcon, LocationIcon, CallIcon, GlobeIcon, MarkTalentIcon, PayrateIcon, EmailIcon, AssignIcon } from '../../icons';
import { ITalent } from '../../interfaces';
import { addressParser, currencyConversion, CustomButton, getArrayStrings } from '../../utils';
import SocialMediaProfile from '../social_media_profile/social_media_profile';
import StatusBanner from '../status_banner/status_banner';
import { LoadingType, ProfilePictureType } from "../../enums";
import UploadProfile from "../../components/image_crop/image_crop_popup";
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, getPreSignedURLForDownload, selectSingleProfilePictureStateById, getProfile } from "../../redux/store";
import { Portal } from "react-portal";
import { getDaysAgoWithAMPM } from '../../variables';
import ResumeHolder from '../resume_holder/resume_holder';
import UserAvatar from '../user_avatar/user_avatar';
import EmployeeAvatar from '../employee_avatar/employee_avatar';

interface Props {
    talent: ITalent | undefined,
    onBranchChange: (value: string) => void,
    onActionClick: (value: string) => void,
    onSSNClick: () => void,
    onResumeClick: (path: string) => void,
    isTalent?: boolean,
    talentId: string,
}



const EmployeeDashboardDetailsSection: React.FunctionComponent<Props> = (props) => {
    const { talent, onBranchChange, onActionClick, talentId } = props;
    const { t } = useTranslation();

    const employeeProfileSections = [
        {
            sectionTitle: 'sector',
            sectionIcon: SectorIcon,
            direction: 'row',
            sectionContent: [
                { title: 'sector', valueField: 'business_sector_value' },
                { title: 'job_title', valueField: 'title' },
            ]
        },
        {
            sectionTitle: 'payrate',
            sectionIcon: PayrateIcon,
            direction: 'row',
            sectionContent: [
                { title: 'payrate', valueField: 'pay_rate' },
                { title: 'resume', valueField: getResumeIcon() }, ///resume_path
            ]
        },
        {
            sectionTitle: 'geo_code',
            sectionIcon: GlobeIcon,
            direction: 'row',
            sectionContent: [
                { title: 'geo_code', valueField: 'geo_code' },
                { title: 'ss_number', valueField: 'ssn' },
            ]
        },
        {
            sectionTitle: 'address',
            sectionIcon: LocationIcon,
            direction: 'column',
            sectionContent: [
                {
                    title: 'address', valueField: addressParser({
                        'street_address': talent ? talent['street_address'] : null,
                        'city': talent ? talent['city'] : null,
                        'state': talent ? talent['state'] : null,
                        'zip_code': talent ? talent['zip_code'] : null,
                    })
                },
            ]
        },

        {
            sectionTitle: 'contact',
            sectionIcon: CallIcon,
            direction: 'column',
            sectionContent: [
                { title: 'contact', valueField: 'phone' },
            ]
        },
        {
            sectionTitle: 'email',
            sectionIcon: EmailIcon,
            direction: 'column',
            sectionContent: [
                { title: 'email', valueField: 'email' },
            ]
        },
    ];

    function getResumeIcon() {
        if(talent?.resume_file_name == null || talent?.resume_file_name == "") return <span className="na-span">NA</span>
        if (talent && talent.resume_file_name) {
            return (
                <ResumeHolder
                    path={talent?.resume_file_name}
                    onClick={() => talent.resume_path != null && talent.resume_path != "" ? props.onResumeClick(talent.resume_path) : {}}
                />
            )
        }
        return '';
    }
    function talentStatus(status: string) {
        if (status === TALENT_STATUS.active) return (<span>{`${t('status')}: `} <span style={{ color: THEME.buttonColor17 }}>{t('active')}</span> </span>);
        else if (status === TALENT_STATUS.inactive) return (<span>{`${t('status')}: `}<span style={{ color: "#b1b1b1" }}>{t('in_active')}</span></span>);
        else return (<span>{`${t('status')}: `}<span>{''}</span> </span>);
    }
    return (
        <div className="tds-container">
            <EmployeePortalInfo
                name={`${talent?.first_name ?? ""} ${talent?.middle_name ?? ""} ${talent?.last_name ?? ""}`}
                uri={talent?.contact_photo}
                objectId={talent?.id}
                rating={talent?.engagement_rating}
                level={talent?.engagement_level}
            />
            {talentActivity()}
            {/* <div className="tds-branch-selector">
                <div className="tds-status">
                    {talentStatus(talent?.status ?? '')}
                </div>
            </div> */}
            <div className="tds-branch-selector">
                <div className="tds-status">
                    <span>
                        {`${t("status")}:`}
                        <span className="status-txt">
                            {talent?.status ?? ''}
                        </span>
                    </span>
                </div>
            </div>
            {employeeProfileSections.map((doc, index) => {
                return (
                    <div key={index} className="tds-info-container">
                        <div className="tds-info-icon">
                            <doc.sectionIcon width={"100%"} height={"100%"} />
                        </div>
                        <div
                            className="tds-info-content"
                            style={{
                                ...(doc.direction === 'row') && { flexDirection: 'row', justifyContent: 'space-between' },
                            }}
                        >
                            {doc.sectionContent.map((data, index) => {
                                const arrayValue = getValues(data.valueField);

                                return (
                                    <div
                                        key={index}
                                        className="tds-info-holder"
                                        style={{
                                            ...(doc.direction === 'row') && { flex: 1, width: "50%" },
                                            ...(index === 0) && { marginRight: '1em' }
                                        }
                                        }>
                                        <div className="tds-into-title">
                                            <span>{t(data.title).toUpperCase()}</span>
                                        </div>
                                        <div className="tds-info-value" style={{ marginBottom: '0.5em' }}>
                                            {arrayValue}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            {(talent?.social_media_links != null) && <SocialMediaProfile
                title={t('social_media').toUpperCase()}
                profile={talent.social_media_links}
            />}
            <EmployeeDashboardActions onClick={onActionClick} />
            {talent?.is_available && <div className="available-status">
                <StatusBanner title={t('available')} />
            </div>}
        </div>
    );

    function getValues(obj: any) {
        if (talent == null) return (<span>{''}</span>);
        if (Array.isArray(obj)) {
            return getArrayStrings(obj, talent);
        }
        const value = (obj !== '' && talent![obj] !== null) ? talent![obj] : '';
        if (obj === "pay_rate") {
            return (
                <span>{currencyConversion(value)}</span>
            );
        }
        if (obj === "business_sector_value") {
            return (
                (talent.business_sector_value !== null && talent.business_sector_value !== "") ? <span>{talent.business_sector_value}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "title") {
            return (
                (talent.title !== null && talent.title !== "") ? <span>{talent.title}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "geo_code") {
            return (
                (talent.geo_code !== null && talent.geo_code !== "") ? <span>{talent.geo_code}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "phone") {
            return (
                (talent.phone !== null && talent.phone !== "") ? <span>{talent.phone}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "ssn") {
            return (
                (talent.ssn !== null && talent.ssn !== "") ? <span>{talent.ssn}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "email") {
            return (
                (talent.email !== null && talent.email !== "") ? <span>{talent.email}</span> : <span className="na-span">NA</span>
            );
        }
        if (typeof obj === "object") {
            return obj;
        }
        return (
            <span>{value}</span>
        );
    }


    function talentActivity() {
        const lastEngagementDate = talent?.last_engagement_date ? getDaysAgoWithAMPM(t, talent?.last_engagement_date) : null;
        return (
            <div className="tds-activity">
                {lastEngagementDate && <div className="last-engagement-txt">
                    <span>{t('last_engagement', { value: lastEngagementDate })}</span>
                </div>}
                {talent?.currently_on_assignment && <div className="assignment-status">
                    <div className="icon">
                        <AssignIcon width={'100%'} height={'100%'} style={{ color: THEME.statusActiveColor }} />
                    </div>
                    <div className="status">
                        <span style={{ color: THEME.statusActiveColor }} >{t('currently_on_assignment')}</span>
                    </div>

                </div>}
            </div>
        )
    }


}

const EmployeePortalInfo = (props) => {
    const { name, uri, rating, level, id } = props;
    const dispatch = useAppDispatch();
    const [showImageUpload, setShowImageUpload] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);
    const [imageURL, setImageURL] = useState('');

    const profilePictureState = useAppSelector((state) => selectSingleProfilePictureStateById(ProfilePictureType.talent + '-' + props.objectId)(state));

    useEffect(() => {
        setSentRequest(true);
        dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.talent }));
    }, [])

    function updateProfile() {
        dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.talent }));
        setSentRequest(true);
        dispatch(getProfile())
    }

    useEffect(() => {
        if (sentRequest && profilePictureState.download.loading === LoadingType.succeeded) {
            setSentRequest(false);
            setImageURL(profilePictureState.download.response ?? '');
        }
    }, [profilePictureState.download.loading])

    return (
        <div className="tds-user-info">
            <EmployeeAvatar
                onClick={() => (setShowImageUpload(true))}
                url={imageURL}
            />
            <div className="tds-user-name">
                <span>{name}</span>
            </div>
            {showImageUpload && <Portal>
                <UploadProfile
                    onClose={() => setShowImageUpload(false)}
                    onDismiss={() => setShowImageUpload(false)}
                    visible={showImageUpload}
                    onApprove={updateProfile}
                    objectId={props.objectId}
                    objectType={ProfilePictureType.talent}
                />
            </Portal>}
        </div>
    );
}


const buttonsList = [
    { title: 'mark_myself_available', icon: MarkTalentIcon, type: 'mark_myself_available', color: THEME.buttonColor16 },
];

interface EmployeeDashboardActionsProps {
    onClick: (type: string) => void
    talent?: ITalent | null,
    adminName?: string
    isTalent?: boolean
}



const EmployeeDashboardActions: React.FunctionComponent<EmployeeDashboardActionsProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="tds-action-container">
            {buttonsList.map((doc, index) => {
                return (
                    <div key={index} className="tds-action">
                        <CustomButton
                            customStyle={{ width: '-webkit-fill-available' }}
                            leftIcon={(<div className="tds-action-icon"><span style={{ color: "#fff" }}><doc.icon width={"100%"} height={"100%"} /></span></div>)}
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t(doc.title)}
                            enable={true}
                            backgroundColor={doc.color}
                            onClick={() => props.onClick(doc.type)}
                        />
                    </div>

                )
            })}
        </div>
    );
}


export default EmployeeDashboardDetailsSection;