import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AlphaOmegaStaffing from '../../../components/alpha_omega_staffing';
import { LoadingType, THEME } from '../../../enums';
import { LoginCredentials } from '../../../interfaces';
import { clearLoginError } from '../../../redux/login/login_reducer';
import { selectLoginState } from '../../../redux/login/login_selector';
import { loginUser } from '../../../redux/login/login_thunk';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { RegxPattern } from '../../../utils';
import LoginForm from '../../forms/login_form';
import './login_page.scss';
import { Portal } from "react-portal";
import {
    ActionDialogHolder,
    ActionDialogHolderType
} from "../../../components/action_dialog_holder/action_dialog_holder";
import CloseIcon from '../../../../assets/icons/close-btn2.svg';
import { CustomButton } from "../../../utils";
import { selectForgotPasswordState } from "../../../redux/forgot_password/forgot_password_selector";
import { forgotPassword } from "../../../redux/forgot_password/forgot_password_thunk";
import RenderInput from '../../../components/render_input/render_input';

const LoginPage = (props) => {
    const dispatch = useAppDispatch();
    const { loading, token, error } = useAppSelector((state) => selectLoginState(state))
    const { t } = useTranslation();
    const [state, setState] = useState<LoginCredentials>({
        username: '',
        password: '',
    });

    const [showResetPage, setShowResetPage] = useState(false);

    function handleFieldChange(fieldId, value) {
        setState({ ...state, [fieldId]: value });
    }

    function _isFormValid() {
        const { username, password } = state;
        const pattern = RegxPattern.email;
        if (username === '') return false;
        if (pattern.test(username) === false) return false;
        if (password === '') return false;
        return true;
    }

    return (
        <div className="lg-page-container">
            <div className="lg-form">
                <LoginForm
                    loading={loading == LoadingType.pending}
                    state={state}
                    isFormVaid={_isFormValid()}
                    handleStateChange={handleFieldChange}
                    onSubmit={handleLogin}
                    error={error}
                    onClearError={handleClearError}
                    onResetClick={() => (setShowResetPage(true))}
                />
            </div>
            {showResetPage && <Portal>
                <ResetAction
                    onClose={() => (setShowResetPage(false))}
                    onDismiss={() => (setShowResetPage(false))}
                    visible={showResetPage}
                />
            </Portal>}

        </div>
    );


    function handleLogin(credentials: LoginCredentials) {
        dispatch(loginUser(credentials));
    }

    function handleClearError() {
        dispatch(clearLoginError());
    }
}

const resetPassword = {
    field: 'email',
    label: 'email',
    secure: false,
    required: false,
    type: 'emailAddress',
    pattern: RegxPattern.email,
}

const ResetAction = ({ onClose, onDismiss, visible }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [resetState, setResetState] = useState({
        email: "",
    })
    const [sentRequest, setSentRequest] = useState(false);

    const forgotPasswordState = useAppSelector((state) => selectForgotPasswordState(state));

    function handleForgot() {
        dispatch(forgotPassword(resetState?.email));
        setSentRequest(true);
    }

    function handleFieldChange(fieldId, value) {
        setResetState({ ...resetState, [fieldId]: value });
    }

    useEffect(() => {
        if (forgotPasswordState.loading === LoadingType.succeeded && sentRequest) {
            setSuccessMessage(forgotPasswordState.response);
            setTimeout(() => (setSuccessMessage('')), 5000);
            setSentRequest(false);
            setTimeout(() => (onClose()), 1000);
        }
    }, [forgotPasswordState.loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={450}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="reset-password-dialog-container">
                <div className="reset-password-dialog-header">
                    <span className='title'>{t("reset_password")}</span>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                <div className="reset-password-dialog-content">
                    <RenderInput
                        doc={resetPassword}
                        formState={resetState}
                        handleFieldChange={handleFieldChange}
                        formValidators={formValidators}
                    />
                    <div className='email-message'><p style={{ "color": "green", "fontSize": "14px" }}>{successMessage}</p></div>
                </div>
                <div className="reset-password-dialog-footer">
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize',fontSize: '1vw' }}
                        name={t('reset')}
                        enable={isValid()}
                        loading={forgotPasswordState.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleForgot}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )

    function isValid() {
        const { email } = resetState;
        if (email === "" || RegxPattern.email.test(email) === false) return false;
        return true;
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function formValidators(value: { text: string, field: string, }) {
        switch (value.field) {

            case resetPassword.field: {
                if (value.text == '') return t('validators.required');
                if (RegxPattern.email.test(value.text) === false) return t('validators.enterValidEmail');
                return null;
            };
            default: {
                return null;
            }
        }
    }

}

export default LoginPage;