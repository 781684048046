import { combineReducers } from "@reduxjs/toolkit";
import { unEmploymentClaimsListSlice } from "./unemployment_claims_list/unemployment_claims_list_reducer";
import { workCompClaimsListSlice } from "./work_comp_claims_list/work_comp_claims_list_reducer";
import { workCompAuditAndComplianceReportingListSlice } from "./work_comp_audit_and_compliance_reporting/work_comp_audit_and_compliance_reporting_reducer";
import { riskMgmtInsuranceCompanySlice } from "./insurance_company/insurance_company_reducer";

export const universalDirectoryReducers = combineReducers({
    [workCompClaimsListSlice.name]: workCompClaimsListSlice.reducer,
    [unEmploymentClaimsListSlice.name]: unEmploymentClaimsListSlice.reducer,
    [workCompAuditAndComplianceReportingListSlice.name]: workCompAuditAndComplianceReportingListSlice.reducer,
    [riskMgmtInsuranceCompanySlice.name]: riskMgmtInsuranceCompanySlice.reducer
});

export * from './unemployment_claims_list/unemployment_claims_list_selector';
export * from './unemployment_claims_list/unemployment_claims_list_thunk';

export * from './work_comp_claims_list/work_comp_claims_list_selector';
export * from './work_comp_claims_list/work_comp_claims_list_thunk';

export * from './work_comp_audit_and_compliance_reporting/work_comp_audit_and_compliance_reporting_thunk';
export * from './work_comp_audit_and_compliance_reporting/work_comp_audit_and_compliance_reporting_selector';

export * from './insurance_company/insurance_company_thunk';
export * from './insurance_company/insurance_company_selector';