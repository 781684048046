import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectAll, selectEntities, selectState } from "./sales_tax_configuration_reducer";

const select = (state: IRootState) => state.adminCenter.salesTaxConfigList;
export const selectSalesTaxConfigListState = createSelector(select, selectState);
export const selectSalesTaxConfigList = createSelector(
    select,
    selectAll
);
export const selectSalesTaxConfigListEntities = createSelector(
    select,
    selectEntities
);