import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IJobsList, } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { IWeekEndingDatesDropdown } from "../../../interfaces/back_office_weekly_accounting";
import { BackOfficeAPI } from "../../../apis/back_office_api";


export const getWeekEndingDatesDropdownThunk = createAsyncThunk<
    IWeekEndingDatesDropdown[],
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/backoffice/week-ending-dates/dropdown/get',
    async (query, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getWeekEndingDatesDropdown();
            if (typeof res.data === 'string') return [];
            return res.data as IWeekEndingDatesDropdown[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);