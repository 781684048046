import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState,
} from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./insurance_company_thunk";
import { RiskMgmtInsuranceCompany } from "../../../../interfaces/risk_mgmt_insurance_company";

export interface RiskMgmtInsuranceCompanyState
  extends EntityState<RiskMgmtInsuranceCompany> {
  error?: AppError | null;
  loading: LoadingType;
}

const riskMgmtInsuranceCompanyAdapter: EntityAdapter<RiskMgmtInsuranceCompany> =
  createEntityAdapter<RiskMgmtInsuranceCompany>({
    selectId: (doc) => doc.id,
  });

const initialState: RiskMgmtInsuranceCompanyState =
  riskMgmtInsuranceCompanyAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
  });

export const riskMgmtInsuranceCompanySlice = createSlice({
  name: "riskMgmtInsuranceCompany",
  initialState: initialState,
  reducers: {
    clearRiskMgmtInsuranceCompanyAdapterError(state) {
      return { ...state, error: null };
    },
    clearRiskMgmtInsuranceCompanyState(state) {
      return riskMgmtInsuranceCompanyAdapter.removeAll({
        ...state,
        loading: LoadingType.idle,
        error: null,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getAllInsuranceCompanies.pending, (state, action) => ({
        ...state,
        loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
        error: null,
      }))
      .addCase(thunk.getAllInsuranceCompanies.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.getAllInsuranceCompanies.fulfilled, (state, action) =>
        riskMgmtInsuranceCompanyAdapter.setAll(
          { ...state, loading: LoadingType.succeeded, error: null },
          action.payload
        )
      )

      .addDefaultCase((state) => ({ ...state }));
  },
});

export const riskMgmtInsuranceCompanyActions =
  riskMgmtInsuranceCompanySlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } =
  riskMgmtInsuranceCompanyAdapter.getSelectors();
export const selectState = (state: RiskMgmtInsuranceCompanyState) => state;
