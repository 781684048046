import { createAsyncThunk } from "@reduxjs/toolkit";
import { OnboardingPortalAPI } from "../../../apis";
import { S3API } from "../../../apis/s3_api";
import { AppError } from "../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../utils";


export const getWOTCScreeningURLThunk = createAsyncThunk<
  string,
  string,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/wotc/screening-url/post",
  async (token, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.getWOTCScreeningURL(token);
      return res.data ;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);



/// upload file
export const uploadWOTCThunk = createAsyncThunk<
  any,
  { url: string, file: File },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/wotc/upload/put",
  async (payload, thunkAPI) => {
    try {
      const res = await S3API.uploadFile(payload.url, payload.file);
      if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

/// upload url
export const getUploadWOTCUrlThunk = createAsyncThunk<
  { url: string },
  { token?: string, talent_id?: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/wotc/upload-url/get",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.getHandbookUploadUrl(payload); /// TODO: WOTC api missing
      if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

/// Download url
export const getDownloadWOTCUrlThunk = createAsyncThunk<
  { url: string },
  { token?: string, talent_id?: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/wotc/download-url/get",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.getHandbookDownloadUrl(payload);
      if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);