import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../enums";
import { AppError, IEngagement} from "../../interfaces";
import * as thunk from "./engagement_thunk";

export interface EngagementListState extends EntityState<IEngagement> {
    error?: AppError | null,
    loading: LoadingType,
    data: IEngagement[],
    saveResponse: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    deleteResponse: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
   
}

const engagementListAdapter: EntityAdapter<IEngagement> = createEntityAdapter<IEngagement>({
    selectId: (doc) => doc.object_id,
})

const initialState: EngagementListState = engagementListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    data:[],
    saveResponse: {
        error: null,
        loading: LoadingType.idle,
        response: "",
    },
    deleteResponse: {
        error: null,
        loading: LoadingType.idle,
        response: "",
    },
})

export const engagementListSlice = createSlice({
    name: 'enagagementList',
    initialState: initialState,
    reducers: {
        clearEngagementListError(state) {
            return { ...state, error: null };
        },
        clearEngagementListState(state) {
            return engagementListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null, });
        },
        clearSaveState(state) {
            return {
                ...state, saveResponse: {
                    error: null,
                    loading: LoadingType.idle,
                    response: "",
                }
            }
        },
        clearDeleteState(state) {
            return {
                ...state, deleteResponse: {
                    error: null,
                    loading: LoadingType.idle,
                    response: "",
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getEngagementDataById.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getEngagementDataById.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getEngagementDataById.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, data: action.payload }))

            .addCase(thunk.saveEngagement.pending, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.saveEngagement.rejected, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.saveEngagement.fulfilled, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateEngagement.pending, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.updateEngagement.rejected, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.updateEngagement.fulfilled, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.deleteEngagement.pending, (state, action) => ({ ...state, deleteResponse: { ...state.deleteResponse, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deleteEngagement.rejected, (state, action) => ({ ...state, deleteResponse: { ...state.deleteResponse, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deleteEngagement.fulfilled, (state, action) => ({ ...state, deleteResponse: { ...state.deleteResponse, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const engagementListActions = engagementListSlice.actions;

export const selectState = (state: EngagementListState) => state;
export const selectSaveResponse = (state: EngagementListState) => state.saveResponse;
export const selectDeleteResponse = (state:EngagementListState)=>state.deleteResponse;