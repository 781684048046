import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import {
    selectCreateState,
    selectState,
    selectUpdateState
} from "./manage_service_calls_reducer";

const select = (state: IRootState) =>
  state.salesMarketing.serviceCallActivity.manageServiceCall;
export const selectManageServiceCallState = createSelector(select, selectState);
export const selectManageServiceCallCreateState = createSelector(
  selectManageServiceCallState,
  selectCreateState
);
export const selectManageServiceCallUpdateState = createSelector(
  selectManageServiceCallState,
  selectUpdateState
);
