import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getPaycheckPDFURLThunk, getPayrollDeductionsList, getReportDownloadURL, getReportStartEndDate, selectePaycheckInvoiceState, selectReportsDownloadState, selectReportStartEndDateState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { convertDateToTimeStamp, dateType, getDateFromTimeStamp } from "../../../../../variables";
import { LoadingType, THEME } from "../../../../../enums";
import { IRCPayrollDeductions } from "../../../../../interfaces/report_center_payroll_tax";
import DatePicker from "react-datepicker";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { selectPayrollDeductionsListState } from "../../../../../redux/report_center/payroll_tax/payroll_deductions/payroll_deductions_selector";
import { AppRoutes } from "../../../../../routes";
import PayrollDeductionTable from "../../../popups/payroll_deductions_table/pr_deduction_table";
import { currencyConversion, CustomButton } from "../../../../../utils";
import './payroll_deductions.scss';
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import UrlBuilder from "../../../../../apis/url_builder";
import { paycheckInvoiceSliceActions } from "../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";

interface Props extends RouteComponentProps<any> { }

const PayrollDeductions: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const payrollDeductionsListState = useAppSelector((state) => selectPayrollDeductionsListState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));
    const paycheckPDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));

    const [isCheckDate, setIsCheckDate] = useState<boolean>(false);
    const [payrollDeductionsList, setPayrollDeductionsList] = useState<IRCPayrollDeductions | null>(null);

    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>()
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0) 
            || ((formState?.available_from !== 0 && formState?.available_to !== 0) || (formState?.available_from === 0 && formState?.available_to === 0) && isCheckDate)) {
            getPDeductionsList();
            setDatePickerOpen(false);
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getPDeductionsList();
            setDatePickerOpen(true);
        }
    }, [formState?.available_from, formState?.available_to, isCheckDate]);

    useEffect(() => {
        if (payrollDeductionsListState && payrollDeductionsListState?.loading === LoadingType.succeeded) {
            setPayrollDeductionsList(payrollDeductionsListState?.response);
        }
    }, [payrollDeductionsListState?.loading]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    useEffect(() => {
        if (paycheckPDFURLState.paycheck.loading === LoadingType.succeeded && paycheckPDFURLState.paycheck.response !== "") {
            window.open(paycheckPDFURLState.paycheck.response, "_blank");
            dispatch(paycheckInvoiceSliceActions.clearPaycheckState());
        }
    }, [paycheckPDFURLState.paycheck.loading, paycheckPDFURLState.paycheck.response]);

    const getPDeductionsList = () => {
        if (formState) {
            dispatch(getPayrollDeductionsList({ start_date: formState?.available_from, end_date: formState?.available_to, is_check_date: isCheckDate! }))
        }
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
    }

    const handleViewCheck = (pay_check_id: string) => {
        dispatch(getPaycheckPDFURLThunk(pay_check_id));
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.payrollDeductions,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    is_check_date: isCheckDate ? isCheckDate : false,
                    download: true
                }));
        }
    }

    function handleTalentSelect(talent_id: string, talent_name: string) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${talent_id}`,
            state: {
                id: talent_id,
                name: talent_name,
            },
        });
        return;
    }

    const [totalCounts, setTotalCounts] = useState({});
    const [filteredListLengths, setFilteredListLengths] = useState({});
    const handleTotalCount = (key, totalCount) => {
        setTotalCounts(prevCounts => ({
            ...prevCounts,
            [key]: totalCount
        }));
    };

    const handleFilteredListLength = (key, length) => {
        setFilteredListLengths(prevLengths => ({
            ...prevLengths,
            [key]: length
        }));
    };

    const records = payrollDeductionsList?.records || {};
    const showSpinner = () => payrollDeductionsListState?.loading === LoadingType.pending;
    const getTotalForFirstKey = (): number => {
        const firstKey = Object.keys(records)[0];
        if (!firstKey) return 0;
        return totalCounts[firstKey] || 0;
    };
    
    const getTable = () => {
        const error = payrollDeductionsListState?.error;
        const hasDeductions = Object.keys(records).some(
            key => records[key]?.deductions?.length > 0
        );
        const allDeductionsEmpty = Object.keys(records).every(
            key => !records[key]?.deductions || records[key].deductions.length === 0
        );
        if (showSpinner()) return <TableLoading />;

        if (error && !hasDeductions) {
            return <TableErrorHandler error={error} onRefresh={getPDeductionsList} />;
        }

        if (allDeductionsEmpty) {
            return <TableEmpty title={"No Data found"} onClick={getPDeductionsList} />;
        }

        return (
            <>
                {Object.keys(records).map(key => (
                    <PayrollDeductionTable
                        key={key}
                        record={records[key]}
                        title={key}
                        onTalentSelect={handleTalentSelect}
                        onTotalCount={(totalCount) => handleTotalCount(key, totalCount)}
                        onFilteredListLength={(length) => handleFilteredListLength(key, length)}
                        onViewCheck={handleViewCheck}
                    />
                ))}
            </>
        );
    }
    return (
        <div className="payroll-journal-container payroll-dedcution-container">
            <div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="payroll-journal-header">
                <div className="main-header">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                            />
                        </div>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                        <CustomFormSelect
                            name={t("date_type")}
                            list={dateType.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={setIsCheckDate}
                            value={isCheckDate}
                            placeholder={t('select')}
                            required={false}
                            label={t('date_type')}
                        />
                    </div>
                </div>
                <div className="dwn-btn">
                    <CustomButton
                        leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                        loading={false}
                        name={t("download")}
                        enable={Object.keys(records).length > 0}
                        backgroundColor={THEME.buttonColor16}
                        onClick={() => handleDownload()}
                    />
                </div>
            </div>
            <div>
                {getTable()}
                {payrollDeductionsListState.loading === LoadingType.succeeded &&
                    <>
                        <div className="total-counts-card">
                            <div className="total-title">
                                Payroll Deductions Totals
                            </div>
                            <div className="total-count-main">
                                <div className="total-count">
                                    {currencyConversion(getTotalForFirstKey())}
                                </div>
                            </div>
                        </div>
                        <div className="total-counts-second-card">
                            <div className="total-title">
                                Payroll Deductions Summary
                            </div>
                            <div className="total-count">
                                <span>Adjustments Total(s):</span>
                                {currencyConversion(getTotalForFirstKey())}
                            </div>
                            <div className="total-count">
                                <span>Total Employees:</span>
                                {filteredListLengths[Object.keys(records)[0]] || 0}
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default PayrollDeductions;