import {combineReducers} from "@reduxjs/toolkit";
import {jobOrderContactSlice} from "./job_order_contact_list/job_order_contact_list_reducer";
import {jobOrderContactCreateSlice} from "./job_order_contact_create/job_order_contact_reducer";

export const jobOrderContactsReducer = combineReducers({
    [jobOrderContactSlice.name]: jobOrderContactSlice.reducer,
    [jobOrderContactCreateSlice.name]: jobOrderContactCreateSlice.reducer
});

export * from "./job_order_contact_list/job_order_contact_list_selector";
export * from "./job_order_contact_list/job_order_contact_list_thunk";

export * from "./job_order_contact_create/job_order_contact_selector";
export * from "./job_order_contact_create/job_order_contact_thunk";