import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectAll, selectEntities, selectState } from "./marketing_campaign_reducer";

const select = (state: IRootState) =>
  state.salesMarketing.marketingActivity.marketingCampaign;
export const selectMarketingCampaignListState = createSelector(select, selectState);
export const selectMarketingCampaignList = createSelector(select, selectAll);
export const selectMarketingCampaignListEntities = createSelector(
  select,
  selectEntities
);
