import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IBillingBatch } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { BackOfficeAPI } from "../../../../apis/back_office_api";


export const getBillingInvoiceListThunk = createAsyncThunk<
    IBillingBatch[],
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing_invoices/dashboard/billing_invoice_list/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getBillingInvoicesList(payload);
            return res.data as IBillingBatch[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


