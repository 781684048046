import { useState } from "react";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import { IBillingTimeCardsData } from "../../../../interfaces";
import { currencyConversion, CustomCheckBox, mapOptions } from "../../../../utils";
import { useTranslation } from "react-i18next";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { getDateString } from "../../../../variables";

interface Props {
  billingTimecards: IBillingTimeCardsData[];
  selectedCards: Set<String>;
  handleSelection: (id?: string) => void;
}

export const CreateBillingStepTwo = (props: Props) => {
  const { t } = useTranslation();
  const { billingTimecards, selectedCards, handleSelection } = props;
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [groupBySortedField, setGroupBySortedField] = useState<string | null>(null)

  const SelectAllCheckBox = () => {
    return (
      <CustomCheckBox
        name={""}
        title={""}
        checked={selectedCards.size === billingTimecards?.length}
        onClick={() => handleSelection()}
      />
    );
  };

  const tableHeader = [
    { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
    { title: "bill_to", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "talent", code: "talent_name" },
    { title: "invoice_amount", code: "invoice_amount" },
    { title: "week_ending", code: "week_ending_date" },
    { title: "week_worked", code: "week_worked" },
    { title: "transaction_count", code: "transaction_count" },
  ];

  
  const groupingOptions = [
    {
        "title": "Company Name",
        "value": "company_name"
    },
    {
        "title": "Talent Name",
        "value": "talent_name"
    },
    {
        "title": "Week Worked",
        "value": "week_worked"
    },
]

  function getFlexNumber(value: number) {
    if (value === 0) return 0.2;
    if (value === 1) return 1;
    if (value === 2) return 0.8;
    if (value === 3) return 0.7;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 0.6;
    if (value === 8) return 1.74;
    return 1;
  }

  const handleSortFieldChange = (value: string) => {
    setGroupBySortedField(null);
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  const getTotalPay = () => {
    if (
      selectedCards.size === billingTimecards?.length ||
      selectedCards.size === 0
    )
      return billingTimecards
        .map((x) => x.invoice_amount)
        .reduce((current, acc) => current + acc, 0);

    return billingTimecards
      .filter((x: any) => selectedCards.has(x.time_card_id))
      .map((x) => x.invoice_amount)
      .reduce((current, acc) => current + acc, 0);
  };

  function getFilteredList() {
    let list;
    const sortField = sortedField ? sortedField : groupBySortedField;
    if (billingTimecards && sortField) {

      list = [...billingTimecards].sort((a, b) => {
        const valueA = a[sortField] != null
          ? typeof a[sortField] === 'string'
            ? a[sortField].trim().toLowerCase()
            : a[sortField]
          : "";
        const valueB = b[sortField] != null
          ? typeof b[sortField] === 'string'
            ? b[sortField].trim().toLowerCase()
            : b[sortField]
          : "";
  
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        } else {
          return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
        }
      });
    }
  
    return list ?? billingTimecards;
  }

  return (
    <div className="cpb-step-two">
      <div className="cpb-step-header">
        <div className="cpb-search-field">
          <CustomFormSelect
            name={t("group_by")}
            list={mapOptions(groupingOptions, "title", "value")}
            onChange={(val)=> {setGroupBySortedField(val);setSortedField(null)}}
            value={groupBySortedField}
            placeholder={t('select')}
            required={false}
            label={t('group_by')}
            isClearable={false}
          />
        </div>
        <div className="total-count">
          <span>
            {`${t("total_count")}: `}
            <span className="total-count-number">
              {getFilteredList()?.length}
            </span>
          </span>
        </div>
      </div>
      <div className="cpb-step-two-table">
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList().length > 0 ?
            <>
              {getFilteredList().map((doc) => {
                return (
                  <tr key={doc.time_card_id}>
                    <TableData customStyle={{ flex: getFlexNumber(0) }}>
                      <CustomCheckBox
                        name={""}
                        title={""}
                        checked={selectedCards.has(doc.time_card_id)}
                        onClick={() => handleSelection(doc.time_card_id)}
                      />
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(1) }}>
                      <span>{doc.company_name}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                      <span>{doc.department_name}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                      <span>{doc.talent_name}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(4) }}>
                      <span>{currencyConversion(doc.invoice_amount)}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                      <span>{getDateString(doc.week_worked)}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                      <span>{getDateString(doc.week_ending_date)}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(7) }}>
                      <span>{doc.transaction_count}</span>
                    </TableData>
                  </tr>
                );
              })}
            </>
            :
            <>
              <tr className="nda-div">
                <TableData customStyle={{ flex: getFlexNumber(20) }}>
                  <div>
                    {t("no_data_available")}
                  </div>
                </TableData>
              </tr>
            </>
          }
          <div className="total-row">
            <TableData
              customStyle={{
                flex: getFlexNumber(7),
                backgroundColor: "#f4f4fa",
                color: "#074783",
                fontWeight: "bold",
              }}
            >
              <span>Total</span>
            </TableData>

            <TableData
              customStyle={{ flex: getFlexNumber(2), backgroundColor: "#f4f4fa" }}
            >
              <span></span>
            </TableData>

            <TableData
              customStyle={{
                flex: getFlexNumber(8),
                backgroundColor: "#f4f4fa",
                color: "#074783",
                fontWeight: "bold",
              }}
            >
              <span>{currencyConversion(getTotalPay())}</span>
            </TableData>

          </div>
        </SortableTable>
      </div>
    </div>
  );
};
