import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IFileUplodState, IURLState, Profile } from "../../../interfaces";
import * as thunk from "./healthcare_exchange_thunk";

export interface HealthCareExchangeState {
   uploadURL: IURLState,
   downloadURL: IURLState,
   uploadFile: IFileUplodState,

}

const initialState: HealthCareExchangeState = {
    uploadURL: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    downloadURL: {
        error: null,
        loading: LoadingType.idle,
        response: null, 
    },
    uploadFile: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}


export const healthCareExchangeSlice = createSlice({
    name: 'healthCareExchange',
    initialState: initialState,
    reducers: {
        clearState(state){
            return {...state, ...initialState};
        },
        clearUploadUrlError(state){
            return {...state, uploadURL:{ ...state.uploadURL, error: null }};
        },
        clearDownloadUrlError(state){
            return {...state, downloadURL:{ ...state.downloadURL, error: null }};
        },
        clearUploadFileError(state){
            return {...state, uploadFile:{ ...state.uploadFile, error: null }};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getUploadHealthCareExchangeUrlThunk.pending, (state, action) => ({...state, uploadURL:{ ...state.uploadURL, loading: LoadingType.pending, error: null, }}))
            .addCase(thunk.getUploadHealthCareExchangeUrlThunk.rejected, (state, action) => ({...state, uploadURL:{ ...state.uploadURL, loading: LoadingType.failed, error: action.payload, response: null, }}))
            .addCase(thunk.getUploadHealthCareExchangeUrlThunk.fulfilled, (state, action) => ({...state, uploadURL:{ ...state.uploadURL, loading: LoadingType.succeeded, error: null, response: action.payload }}))

            .addCase(thunk.getDownloadHealthCareExchangeUrlThunk.pending, (state, action) => ({...state, downloadURL:{ ...state.downloadURL, loading: LoadingType.pending, error: null, }}))
            .addCase(thunk.getDownloadHealthCareExchangeUrlThunk.rejected, (state, action) => ({...state, downloadURL:{ ...state.downloadURL, loading: LoadingType.failed, error: action.payload, response: null, }}))
            .addCase(thunk.getDownloadHealthCareExchangeUrlThunk.fulfilled, (state, action) => ({...state, downloadURL:{ ...state.downloadURL, loading: LoadingType.succeeded, error: null, response: action.payload }}))

            .addCase(thunk.uploadHealthCareExchangeThunk.pending, (state, action) => ({...state, uploadFile:{ ...state.uploadFile, loading: LoadingType.pending, error: null, }}))
            .addCase(thunk.uploadHealthCareExchangeThunk.rejected, (state, action) => ({...state, uploadFile:{ ...state.uploadFile, loading: LoadingType.failed, error: action.payload, response: null, }}))
            .addCase(thunk.uploadHealthCareExchangeThunk.fulfilled, (state, action) => ({...state, uploadFile:{ ...state.uploadFile, loading: LoadingType.succeeded, error: null, response: action.payload }}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const healthCareExchangeSliceActions = healthCareExchangeSlice.actions;
export const selectState = (state: HealthCareExchangeState) => state;
export const selectUploadURLState = (state: HealthCareExchangeState) => state.uploadURL;
export const selectDownloadURLState = (state: HealthCareExchangeState) => state.downloadURL;
export const selectUploadFileState = (state: HealthCareExchangeState) => state.downloadURL;


