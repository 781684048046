import { createAsyncThunk } from "@reduxjs/toolkit";
import { OnboardingPortalAPI } from "../../../apis";
import { AppError,ITalentOnBoardingData } from "../../../interfaces";
import { catchRequestError } from "../../../utils";

/// upload file
export const addDirectDepositAcntThunk = createAsyncThunk<
  string,
  ITalentOnBoardingData,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/paperwork/direct-deposit/patch",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.patchTalentOnBoarding(payload);
      // if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const validateDirectDepositAcntThunk = createAsyncThunk<
  string,
  ITalentOnBoardingData,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/paperwork/direct-deposit/validate/post",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.postValidateDDOnBoarding(payload);
      // if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);