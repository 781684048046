import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI,  } from "../../../../apis";
import { AppError, ISalesCall, } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getDashboardSalesCallsList = createAsyncThunk<
    Array<ISalesCall>,
    {branch_id?: string, my_upcoming_calls: boolean},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/sales-board/sales-calls/get',
    async (query, thunkAPI) => {
        try {
            const res = await DashboardAPI.getSalesCalls(query);
            if(typeof res.data === 'string') return [];
            return res.data as ISalesCall[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const getDashboardTotalOpenOrdersCount = createAsyncThunk<
    number,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/sales-board/total-open-orders-count/get',
    async (_, thunkAPI) => {
        try {
            const res = await DashboardAPI.getTotalOpenOrdersCount();
            if(typeof res.data === 'string') return 0;
            return res.data as number;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);