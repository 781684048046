import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import { LoadingType } from "../../../../enums";
import {
  IApolloCredits,
  IApolloImportResponse,
  IContactApolloImport,
  IContactsApolloSearch,
  IContactsApolloSearchResults,
} from "../../../../interfaces/apollo_search_import";
import { apolloCreditsActions } from "../../../../redux/apollo_search_import/apollo_credits/apollo_credits_reducer";
import { apolloContactsListActions } from "../../../../redux/apollo_search_import/contacts_search_import/contacts_search_import_reducer";
import {
  apolloAddCreditsThunk,
  apolloContactImportPeopleThunk,
  apolloGetCreditsThunk,
  appolloContactSearchPeopleThunk,
  getApolloCompanyById,
  postSnackbarMessage,
  selectApolloCompanyDetailsByIdState,
  selectApolloContactsListState,
  selectApolloCreditsState,
  selectProfileState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import { SpinnerScreen } from "../../../../utils";
import AddCreditsPopup from "../../popups/add_credits_popup/add_credits_popup";
import ApolloContactsResults from "../contacts_results_table/contacts_talent_result_table";
import ApolloCompanyDetailsSection from "./apollo_company_details_section/apollo_company_details_section";
import "./companies_details_page.scss";
interface Props extends RouteComponentProps<any> {}

const ApolloCompanyDetailsPage: React.FunctionComponent<Props> = (props) => {
  const { history, location, match } = props;
  const { params } = match;
  const source = "contacts";
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedContact, setSelectedContact] =
    useState<IContactApolloImport | null>(null);
  const [selectedItems, setSelectedItems] = useState<
    Map<any, IContactsApolloSearchResults>
  >(new Map());
  const companyDetailsState = useAppSelector((state) =>
    selectApolloCompanyDetailsByIdState((location as any).state.domain)(state)
  );
  const apolloContactsListState = useAppSelector((state) =>
    selectApolloContactsListState(state)
  );
  const profileState = useAppSelector((state) => selectProfileState(state));
  const apolloCreditsState = useAppSelector((state) =>
    selectApolloCreditsState(state)
  );

  const [importResponse, setImportResponse] = useState<IApolloImportResponse>();
  useEffect(() => {
    if (
      source === "contacts" &&
      apolloContactsListState &&
      apolloContactsListState.importContact.loading === LoadingType.succeeded &&
      apolloContactsListState.importContact.response !== null
    ) {
      setImportResponse(apolloContactsListState.importContact.response);
    }
  }, [apolloContactsListState, source]);
  const [formState, setFormState] = useState<IContactsApolloSearch>({
    organization_ids: [params.id],
    page: 1,
    organization_industry_tag_ids: [],
    organization_not_industry_tag_ids: [],
    per_page: 10,
  });
  useEffect(() => {
    dispatch(apolloGetCreditsThunk());
  }, []);
  useEffect(() => {
    if (params && params.id !== null) {
      dispatch(
        appolloContactSearchPeopleThunk({
          ...formState,
          organization_ids: [params.id],
        })
      );
    }
  }, [params.id]);
  useEffect(() => {
    const state = location.state as any;
    if (state && state.domain) {
      dispatch(getApolloCompanyById(state.domain));
    }
  }, []);

  function handleSubmit(
    doc: IContactsApolloSearch | undefined,
    pageNum?: number
  ) {
    // formState(doc);
    if (source && source === "contacts" && doc !== undefined) {
      dispatch(appolloContactSearchPeopleThunk({ ...doc, page: pageNum }));
      dispatch(apolloContactsListActions.clearApolloContactsLoadingState());
    }
  }
  const handleImportContact = (
    doc: IContactApolloImport[],
    directDial: boolean,
    forceImport: boolean
  ) => {
    const updatedData = doc.map((item) => ({
      ...item,
      state: item?.state !== null ? item?.state : "",
      city: item?.city !== null ? item?.city : "",
      country: item?.country !== null ? item?.country : "",
    }));
    if (source === "contacts") {
      dispatch(
        apolloContactImportPeopleThunk({
          data: updatedData,
          need_direct_dial: directDial,
          force_import: forceImport,
        })
      );
    }
  };
  function handleOnGotoContact(id: string, name: string | undefined) {
    handleClose();
    if (source === "contacts") {
      props.history.push({
        pathname: `${AppRoutes.contactsDetailsPage}/${id}`,
        state: {
          id: id,
          name: name,
        },
      });
    }
  }

  const handleClose = () => {
    dispatch(apolloContactsListActions.clearApolloContactsListState());
  };
  function handleClearError() {
    dispatch(apolloContactsListActions.clearApolloImportContactsErrorState());
  }
  const handleClearImportState = () => {
    dispatch(apolloContactsListActions.clearApolloImportContactsState());
  };
  const handlePageChange = (pageNum: number) => {
    handleSubmit(formState, pageNum);
    setCurrentPage(pageNum);
  };
  const [availCredits, setAvailCredits] = useState<IApolloCredits | null>();

  useEffect(() => {
    if (
      apolloCreditsState &&
      apolloCreditsState.loading === LoadingType.succeeded &&
      apolloCreditsState.response
    ) {
      setAvailCredits(apolloCreditsState.response);
    }
  }, [apolloCreditsState, apolloCreditsState.loading]);
  useEffect(() => {
    if (
      apolloCreditsState &&
      apolloCreditsState.addCredits &&
      apolloCreditsState.addCredits.loading === LoadingType.succeeded &&
      apolloCreditsState.addCredits.response
    ) {
      dispatch(
        postSnackbarMessage(apolloCreditsState.addCredits.response ?? null)
      );
      setAddCredit(false);
      dispatch(apolloGetCreditsThunk());
      dispatch(apolloCreditsActions.clearApolloAddCreditsState());
    }
  }, [apolloCreditsState.addCredits.loading]);
  const [addCredit, setAddCredit] = useState<boolean>(false);
  const handleAddCredits = (export_credits: Number, mobile_credits: Number) => {
    dispatch(
      apolloAddCreditsThunk({
        export_credits: export_credits,
        mobile_credits: mobile_credits,
      })
    );
  };
  return (
    <div className="company-details-page-container">
      <div className="cdp-container">
        <div className="cdp-left-panel">
          <ApolloCompanyDetailsSection company={companyDetailsState.data} />
        </div>
        <div className="cdp-right-panel">
          {apolloContactsListState?.loading === LoadingType.pending && (
            <SpinnerScreen />
          )}
          {apolloContactsListState?.loading === LoadingType.succeeded && (
            <ApolloContactsResults
              contactsResult={apolloContactsListState.response}
              source={source}
              onChange={setSelectedContact}
              selectedContact={selectedContact}
              title={t("search_people")}
              importContact={handleImportContact}
              importLoading={apolloContactsListState.importContact.loading}
              importResponse={importResponse}
              successTitle={t("new_contact")}
              currentLoginUserName={`${
                profileState.profile?.first_name ?? ""
              } ${profileState.profile?.last_name ?? ""}`}
              onClose={handleClose}
              onGotoCreated={handleOnGotoContact}
              error={apolloContactsListState.importContact.error}
              onClearError={handleClearError}
              onClearImportState={handleClearImportState}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              onAddCredit={setAddCredit}
              availCredits={availCredits?.credits_available}
              availMobileCredits={availCredits?.available_mobile_credits}
              selectedItems={selectedItems}
              handleSelection={(val) => setSelectedItems(val)}
            />
          )}
        </div>
      </div>
      {addCredit && (
        <Portal>
          <AddCreditsPopup
            visible={addCredit}
            onClose={() => setAddCredit(false)}
            loading={apolloCreditsState.addCredits.loading}
            handleAddCredits={handleAddCredits}
            error={apolloCreditsState.addCredits.error?.message}
          />
        </Portal>
      )}
    </div>
  );
};

export default ApolloCompanyDetailsPage;
