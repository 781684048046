import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./companies_billed_thunk";
import { IRCCompaniesBilled } from "../../../../interfaces/report_center_companies";

export interface CompaniesBilledListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCCompaniesBilled | null
}

const initialState: CompaniesBilledListState = {
    error: null,
    loading: LoadingType.idle,
    response: null, 
}

export const companiesBilledListSlice = createSlice({
    name: 'companiesBilledList',
    initialState: initialState,
    reducers: {
        clearCompaniesBilledListError(state) {
            return { ...state, error: null };
        },
        clearCompaniesBilledState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCompaniesBilledList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getCompaniesBilledList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCompaniesBilledList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    },
});

export const { clearCompaniesBilledListError, clearCompaniesBilledState } = companiesBilledListSlice.actions;
export const selectState = (state: CompaniesBilledListState) => state;


