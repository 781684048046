import { FacebookIcon, LinkedInIcon, PinterestIcon, SocialMediaIcon, TwitterIcon } from '../../icons';
import { ProfileSocialMedia } from '../../interfaces';
import './social_media_profile.scss';

interface Props {
    title: string,
    profile: ProfileSocialMedia,
}

const SocialMediaProfile: React.FunctionComponent<Props> = (props) => {
    const { profile, title } = props;
    return (
        <div className="social-media-info-container">
            <div className="social-media-info-icon">
                <SocialMediaIcon width={"100%"} height={"100%"} />
            </div>
            <div className="social-media-info-content">
                <div className="social-media-info-holder">
                    <div className="social-media-into-title">
                        <span>{title}</span>
                    </div>
                    <div className="social-media-info-value">
                        {profile.linkedin_url && <a href={getHttpsString(profile.linkedin_url)} target="_blank" className="social-media-info-icon" rel="noreferrer">
                            <LinkedInIcon width={"100%"} height={"100%"} />
                        </a>}
                        {profile.twitter_url && <a href={getHttpsString(profile.twitter_url)} target="_blank" className="social-media-info-icon" rel="noreferrer">
                            <TwitterIcon width={"100%"} height={"100%"} />
                        </a>}
                        {profile.facebook_url && <a href={getHttpsString(profile.facebook_url)} target="_blank" className="social-media-info-icon" rel="noreferrer">
                            <FacebookIcon width={"100%"} height={"100%"} />
                        </a>}
                        {profile.pinterest_url && <a href={getHttpsString(profile.pinterest_url)} target="_blank" className="social-media-info-icon" rel="noreferrer">
                            <PinterestIcon width={"100%"} height={"100%"} />
                        </a>}
                        {((profile.linkedin_url=== null && profile.twitter_url=== null && profile.facebook_url=== null && profile.pinterest_url=== null) ||
                        (profile.linkedin_url=== "" && profile.twitter_url=== "" && profile.facebook_url=== "" && profile.pinterest_url=== "")) 
                        && <span className="na-span">NA</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )


    function getHttpsString(url: string) {
        if (url.includes('https://') || url.includes('http://')) {
            return url;
        }
        return `https://${url}`;
    }
}

export default SocialMediaProfile;