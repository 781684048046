import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI,  } from "../../../../apis";
import { AppError, ITrafficEngagement, ITrafficLightEngagementDetails, } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getDashboardCompanyTrafficEngagement = createAsyncThunk<
ITrafficLightEngagementDetails,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/company/traffic-light-engagement/get',
    async (_, thunkAPI) => {
        try {
            const res = await DashboardAPI.getCompanyTrafficLightEngagementMetrics();
            return res.data as ITrafficLightEngagementDetails;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);