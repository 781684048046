import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./jobs_list_reducer";

const select = (state: IRootState) => state.jobs.universalDirectory.jobsList;
export const selectJobsListState = createSelector(select, selectState);
export const selectJobsList = createSelector(
    select,
    selectAll
);
export const selectJobsListEntities = createSelector(
    select,
    selectEntities
);