import { useTranslation } from "react-i18next";
import SectionCard from "../../../../../components/section_card/section_card";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { ICOmpanyBackOfficeBillingSchedule, ICompanyBackOfficeSetup } from "../../../../../interfaces/company_back_office_setup";
import { AppError } from "../../../../../interfaces";
import { LoadingType, THEME } from "../../../../../enums";
import { EditIcon } from "../../../../../icons";
import { CustomButton, FormInput, allowNumbers, mapOptions } from "../../../../../utils";
import './billing_schedule_additional_items.scss';
import { getBillingPeriod, getMarkUp, getPaymentTerms, getTransactionType, selectBillingPeriodList, selectMarkUpList, selectPaymentTermsList, selectTransactionTypeList } from "../../../../../redux/admin_center/back_office";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import ApiError from "../../../../../components/api_error";
import { AdminCenterBackOfficeType } from "../../../../../interfaces/admin_center_back_office_type";

interface Props {
    bsUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleFieldChange: (fieldId: string, value: any, parent: string, subParent: string) => void;
    formState: ICompanyBackOfficeSetup;
    handleSubmit: (parent: string) => void;
    onClearError: () => void;
    handleEditCancel: () => void;
    isLoading: boolean,
    paymentTermsList: AdminCenterBackOfficeType[];
}

const BillingScheduleAdditionalItems: React.FC<Props> = props => {
    const { bsUpdateState, handleFieldChange, formState, handleSubmit, onClearError, isLoading, handleEditCancel, paymentTermsList } = props

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const billingPeriodList = useAppSelector((state) => selectBillingPeriodList(state));
    // const paymentTermsList = useAppSelector((state) => selectPaymentTermsList(state));
    const transactionTypeList = useAppSelector((state) => selectTransactionTypeList(state));
    const markUpList = useAppSelector((state) => selectMarkUpList(state));

    const parentField = 'billing_schedule_and_additional_items';
    const billingSchedulesubParentField = 'billing_schedule';
    const additionalItemssubParentField = 'additional_billing';
    useEffect(() => {
        dispatch(getBillingPeriod());
        dispatch(getPaymentTerms());
        dispatch(getTransactionType());
        dispatch(getMarkUp());
    }, []);

    const handleSave = () => {
        handleSubmit(parentField);
    };
    const isAddLoading = () => {
        return isLoading && bsUpdateState?.loading === LoadingType.pending;
    };
    return (
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('billing_schedule_add_items')} >
            <div className="bs-ai-container">
                <div className="bs-ai-content">
                    <div className="billing-schedule bs-ai-input-holder-row">
                        <div className="section-header">{t("billing_schedule")}</div>
                        <div className={"bs-ai-input-holder"}>
                            <CustomFormSelect
                                name={t("billing_period_id")}
                                list={mapOptions(billingPeriodList, "title", "id")}
                                onChange={(val) => handleFieldChange("billing_period_id", val, parentField, billingSchedulesubParentField)}
                                value={formState.billing_schedule_and_additional_items?.billing_schedule?.billing_period_id}
                                placeholder={t('select')}
                                required={false}
                                label={t('billing_period')}
                            />
                        </div>
                        <div className={"bs-ai-input-holder"}>
                            <CustomFormSelect
                                name={t("payment_terms_id")}
                                list={mapOptions(paymentTermsList, "title", "id")}
                                onChange={(val) => handleFieldChange("payment_terms_id", val, parentField, billingSchedulesubParentField)}
                                value={formState.billing_schedule_and_additional_items?.billing_schedule?.payment_terms_id}
                                placeholder={t('select')}
                                required={false}
                                label={t('payment_terms')}
                            />
                        </div>
                        <div className={"bs-ai-input-holder"}>
                            <CustomFormSelect
                                name={t("mark_up")}
                                list={mapOptions(markUpList, "title", "title", true)}
                                onChange={(val) => handleFieldChange("mark_up", val, parentField, billingSchedulesubParentField)}
                                value={formState.billing_schedule_and_additional_items?.billing_schedule?.mark_up}
                                placeholder={t('select')}
                                required={false}
                                label={t('markup')}
                            />
                        </div>
                    </div>
                    <div className="additional-items bs-ai-input-holder-row">
                        <div className="section-header">{t("additional_billing_items")}</div>
                        <div className={"bs-ai-input-holder"}>
                            <CustomFormSelect
                                name={t("transaction_type_id")}
                                list={mapOptions(transactionTypeList, "title", "id")}
                                onChange={(val) => handleFieldChange("transaction_type_id", val, parentField, additionalItemssubParentField)}
                                value={formState.billing_schedule_and_additional_items?.additional_billing?.transaction_type_id}
                                placeholder={t('select')}
                                required={false}
                                label={t('transaction_type')}
                            />
                        </div>
                        <div className={"bs-ai-input-holder"}>
                            <FormInput
                                id={'rate_value'}
                                onChange={(id, val) => handleFieldChange(id, val, parentField, additionalItemssubParentField)}
                                required={false}
                                placeholder={""}
                                type={"text"}
                                value={formState.billing_schedule_and_additional_items?.additional_billing?.rate_value}
                                label={t("rate")}
                                suffix={<span>%</span>}
                                onKeyUp={(e) => { allowNumbers(e); }}
                                onKeyDown={(e) => { allowNumbers(e); }}
                            />
                        </div>
                        <div className={"bs-ai-input-holder"}>
                            <FormInput
                                id={'geo_code_value'}
                                onChange={(id, val) => handleFieldChange(id, val, parentField, additionalItemssubParentField)}
                                required={false}
                                placeholder={""}
                                type={"text"}
                                value={formState.billing_schedule_and_additional_items?.additional_billing?.geo_code_value}
                                label={t("geo_code")}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="bs-ai-actions">
                    <div className="er-div">
                        {isLoading && bsUpdateState !== undefined && bsUpdateState.error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={bsUpdateState.error.message}
                                    onClose={onClearError}
                                />
                            </div>
                        )}
                    </div> 
                    <div className="bs-ai-actions-btn">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable
                                backgroundColor={THEME.secondaryColor4}
                                onClick={handleEditCancel}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                loading={isAddLoading()}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("save")}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleSave}
                                enable={true}
                            />
                        </div>
                    </div>
                </div>

            </div>

        </SectionCard>);
}

export default BillingScheduleAdditionalItems;