import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, JobOrderSource } from "../../../../interfaces";
import * as thunk from "./job_order_source_thunk";

export interface JobOrderSourceState extends EntityState<JobOrderSource> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const jobOrderSourceAdapter: EntityAdapter<JobOrderSource> = createEntityAdapter<JobOrderSource>({
    selectId: (doc) => doc.id
})

const initialState: JobOrderSourceState = jobOrderSourceAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const jobOrderSourceSlice = createSlice({
    name: 'jobOrderSource',
    initialState: initialState,
    reducers: {
        clearJobOrderSourceListError(state){
            return {...state, error: null};
        },
        clearJobOrderSourceListState(state){
            return jobOrderSourceAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearJobOrderSourceCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearJobOrderSourceUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearJobOrderSourceDeleteState(state) {
            return { ...state, remove : {...initialState.remove} };
        },
        clearJobOrderSourceCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearJobOrderSourceUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearJobOrderSourceDeleteStateError(state) {
            return { ...state, remove : { ...initialState.remove , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getJobOrderSources.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getJobOrderSources.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getJobOrderSources.fulfilled, (state, action) => jobOrderSourceAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createJobOrderSource.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createJobOrderSource.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createJobOrderSource.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteJobOrderSource.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteJobOrderSource.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteJobOrderSource.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateJobOrderSource.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateJobOrderSource.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateJobOrderSource.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const jobOrderSourceActions = jobOrderSourceSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = jobOrderSourceAdapter.getSelectors();
export const selectState = (state: JobOrderSourceState) => state;
export const selectLoading = (state: JobOrderSourceState) => state.loading;
export const selectError = (state: JobOrderSourceState) => state.error;


