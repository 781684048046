import { createAsyncThunk } from "@reduxjs/toolkit";
import { SalesMarketingAPI } from "../../../../apis/sales_marketing_api";
import { AppError, DashboardServiceCalls, ServiceCallInfo } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getServiceCallList = createAsyncThunk<
  Array<ServiceCallInfo>,
  boolean,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@sales_marketing/service_call_activity/service_call_list/get", async (completed, thunkAPI) => {
  try {
    const res = await SalesMarketingAPI.getServiceCallsDirectory(completed);
    if (typeof res.data === "string") return res.data;
    const arr = res.data.data ? res.data.data : res.data; 
    return arr as ServiceCallInfo[];
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});
