import { THEME } from "../enums";

interface Props {
    title?: string
}

const InDevelopment: React.FunctionComponent<Props> = (props) => {

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '-webkit-fill-available',
                height: '-webkit-fill-available',
                minHeight: 240
            }}
        >
            <span
                style={{
                    fontSize: '1.25vw',
                    fontWeight: 900,
                    color: 'grey'
                }}
            >
                {props.title ?? "IN DEVELOPMENT"}
            </span>
        </div>
    );
}

export default InDevelopment;