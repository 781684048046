import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { AdminCenterAPI } from "../../../../apis";
import { TextEmAllToken } from "../../../../interfaces/admin_center_textemall";

export const getTextEmAllAuthToken = createAsyncThunk<
  TextEmAllToken,
  void,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@admin_center/system_configuration/auth_token/get", async (_, thunkAPI) => {
  try {
    const res = await AdminCenterAPI.getTextEmAll();
    return res.data as TextEmAllToken;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});

export const updateTextEmAllAuthToken = createAsyncThunk<
  string,
  { username: string; password: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@admin_center/system_configuration/auth_token/update",
  async (data, thunkAPI) => {
    try {
      const res = await AdminCenterAPI.updateTextEmAll(data);
      return res.data as string;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const getSSOTokenThunk = createAsyncThunk<
  string,
  { UserName: string; Password: string; user_level: boolean },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@admin-center/system-configuration/text-em-all/getSSOToken/post",
  async (data, thunkAPI) => {
    try {
      const res = await AdminCenterAPI.postUserCredSSO(data);
      thunkAPI.dispatch(getTextEmAllAuthToken());
      return res.data as string;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);
