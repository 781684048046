import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { CustomButton, FormInput, RegxPattern, SpinnerScreen } from "../../../../utils";
import { AppError, IContactDropdownItem } from "../../../../interfaces";
import { LoadingType, THEME } from "../../../../enums";
import { PaperPlaneIcon } from "../../../../icons";
import { companyInvoiceListActions } from "../../../../redux/companies/details/company_invoices/company_invoices_reducer";
import "./send_invoice_edocs_mail.scss";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { companyEdocsListActions } from "../../../../redux/companies/details/company_edocs/company_edocs_reducer";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { useAppDispatch } from "../../../../redux/store";
import { useRef } from "react";
interface Props {
    visible: boolean;
    onClose: () => void;
    onSubmit: (value) => void;
    mailState: {
        error?: AppError | null;
        loading: LoadingType;
        response: any | null;
    },
    currentLoginUserName: string;
    handleFieldChange?: ((id: string, val: string) => void);
    formState?: ({ invoice_ids: string[], email: string, contact_id: string, contact_name: string, radio: string });
    title: string,
    subTitle: string,
    successTitle: string,
    successMsg: string,
    isEdocs?: boolean,
    company_id?: string,
    contactsList?: IContactDropdownItem[],
}

const SendInvoiceEdocsByMailPopup: React.FunctionComponent<Props> = (props) => {
    const { visible, onClose, mailState,
        currentLoginUserName,
        formState, handleFieldChange,
        onSubmit, title, subTitle, successTitle, successMsg, isEdocs, contactsList } =
        props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    function handleWrapperClose(action?: () => void) {
        // if (sendByemailState.sendDocumentLinkToEmail.loading === LoadingType.succeeded) {
        //   closePopup(props.onSuccessClose);
        // } else {
        if (action) closePopup(action);
        // }
    }
    const formOptions = {
        email: {
            field: "email",
            type: "email",
            label: "email",
            pattern: RegxPattern.email,
            secure: false,
            required: true,
            placeholder: "",
            keyboardtype: "",
        },
        contact_id: {
            field: "contact_id",
            type: "selector",
            label: "select_contact",
            secure: false,
            required: true,
            placeholder: "select",
            keyboardtype: "",
            selectorList: contactsList?.map((doc) => ({
                label: doc.name,
                value: doc.id,
            })),
            multi: false,
            disabled: false,
        },
    };
    function getTitle() {
        if (mailState.loading === LoadingType.succeeded)
            return `${successTitle}`;
        return `${title}`;
    }
    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case formOptions.email.field: {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.email.test(value.text) === false)
                    return t("validators.enterValidEmail");
                return null;
            }
            case formOptions.contact_id.field: {
                if (value.text === "") return t("validators.required");
                return null;
            }
            default: {
                return null;
            }
        }
    }
    // const handleCompanySelect = (companyId: string, companyName: string) => {
    //     const url = `${AppRoutes.companiesDetailsPage}/${companyId}`;
    //     const newTab = window.open(url, '_blank');

    //     // Check if the new tab is successfully created and then set the state
    //     if (newTab) {
    //         newTab.state = {
    //             id: companyId,
    //             name: companyName,
    //         };
    //     } 
    // }
    // const handleDepartmentSelect = (companyId: string, companyName: string, deptId: string, deptName: string) => {
    //     props.history.push({
    //         pathname: `${AppRoutes.companiesDetailsPage}/${companyId}/dashboard/${deptId}`,
    //         state: {
    //             id: companyId,
    //             name: companyName,
    //             departmentId: deptId,
    //             departmentName: deptName,
    //         },
    //     });
    //     return;
    // }
    function getUI() {
        return (
            <div className="mail-popup dshb-soa-f-container">
                <div className="dshb-soa-f-header">
                    <FormDialogTilteHeader
                        title={isEdocs ? getTitle() : successTitle}
                        subTitle={
                            isEdocs
                                ? (mailState.loading === LoadingType.succeeded ? "" : subTitle)
                                : ""
                        }
                        titleIcon={
                            <PaperPlaneIcon
                                width={"100%"}
                                height={"100%"}
                                style={{ color: THEME.talentOptionTextColor }}
                            />
                        }
                    />
                </div>

                <div className="dshb-soa-f-content">
                    {isEdocs ?
                        <>
                            {mailState.loading === LoadingType.succeeded ?
                                <div className="dshb-soa-f-mark-success-holder">
                                    <DocSavedText>
                                        <span className="aknw-name">
                                            {t("well_done", { name: props.currentLoginUserName })}
                                        </span>
                                        <span className="info-txt">{`${successMsg}:`}</span>
                                        <span className="email">{formState?.radio === "emailID" ? formState.email : formState?.contact_name}</span>
                                    </DocSavedText>
                                </div>
                                :

                                <>
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-required row aria-label="radioEmail" name="radioEmail" value={formState?.radio}
                                                onChange={(val) => { handleFieldChange!("radioEmail", (val.target as HTMLInputElement).value); }}>
                                                <FormControlLabel value="emailID" control={<Radio />} label={t("email")} />
                                                <FormControlLabel value="contactId" control={<Radio />} label={t("select_contact")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    {
                                        formState?.radio === "emailID"
                                            ?
                                            <div className="dshb-soa-f-column">
                                                <div
                                                    key={formOptions.email.field}
                                                    className={"dshb-soa-f-input-holder"}
                                                >
                                                    <FormInput
                                                        id={formOptions.email.field}
                                                        onChange={handleFieldChange ? handleFieldChange : () => { }}
                                                        secure={formOptions.email.secure}
                                                        required={formOptions.email.required}
                                                        placeholder={formOptions.email.placeholder}
                                                        type={formOptions.email.type}
                                                        value={formState ? formState[formOptions.email.field] : ""}
                                                        label={t(formOptions.email.label)}
                                                        keyboardtype={formOptions.email?.keyboardtype}
                                                        validations={formValidators}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <CustomFormSelect
                                                    name={formOptions.contact_id.field}
                                                    list={contactsList?.map((doc) => ({
                                                        label: doc.name,
                                                        value: doc.id,
                                                    }))!}
                                                    value={formState ? formState[formOptions.contact_id.field] : ""}
                                                    required={formOptions.contact_id.required}
                                                    label={t(formOptions.contact_id.label)}
                                                    placeholder={t(formOptions.contact_id.placeholder)}
                                                    onChange={(value) => handleFieldChange!(formOptions.contact_id.field, value)}
                                                    validations={formValidators}
                                                />
                                            </div>
                                    }
                                </>
                            }
                        </>
                        :
                        <>
                            {mailState.loading === LoadingType.pending &&
                                <SpinnerScreen />
                            }
                            {mailState.loading === LoadingType.succeeded &&
                                <div className="dshb-soa-f-mark-success-holder">
                                    <DocSavedText>
                                        <span className="aknw-name">
                                            {t("well_done", { name: props.currentLoginUserName })}
                                        </span>
                                        <span className="info-txt">{mailState.response}.</span>
                                    </DocSavedText>
                                </div>
                            }
                        </>
                    }

                    {mailState?.error &&
                        <div className="saved-doc-holder error-holder">
                            <DocSavedText>
                                <div className="error-title">{t("Warning")}</div>
                                <div>
                                    <span className="aknw-name">
                                        {mailState.error.message.msg ?? ""}
                                    </span>
                                    {mailState.error.message.data.length !== 0 &&
                                        <div className="error-table-container">
                                            <table className="error-table">
                                                <thead>
                                                    <th>{t("company_name")}</th>
                                                    <th>{t("department_name")}</th>
                                                    <th>{t("invoice_number")}</th>

                                                </thead>
                                                <tbody>
                                                    {mailState.error.message.data.map((error) => {
                                                        return (
                                                            <tr>
                                                                <td>{error.company_name}</td>
                                                                <td>{error.department_name}</td>
                                                                <td>{error.invoice_number}</td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </DocSavedText>
                        </div>
                    }
                </div>
                {getActions()}
            </div>
        )
    }
    function getActions() {
        if (mailState.loading === LoadingType.succeeded || mailState.loading === LoadingType.failed) {
            return (
                <div className="dshb-soa-f-actions">
                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("ok")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={onClose}
                        />
                    </div>
                </div>
            );
        }
        if (isEdocs) {
            return (
                <div className="dshb-soa-f-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('cancel')}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={onClose}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={mailState.loading === LoadingType.pending}
                            textStyle={{ textTransform: "capitalize" }}
                            name={
                                t("send")
                            }
                            enable={_isFormValid()}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() =>
                                onSubmit(formState)
                            }
                        />
                    </div>
                </div>
            );
        }


    }
    function _isFormValid() {
        if (formState) {
            const { email } = formState;
            if (formState.radio === "emailID" && RegxPattern.email.test(email) === false) return false;
            if (formState.radio === "contactId" && formState.contact_id === "") return false;
            return true;
        }
    }
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
                {getUI()}

            </DialogWrapper>
        </ActionDialogHolder>
    );
    function handleClearError() {
        dispatch(companyInvoiceListActions.clearMailState());
        dispatch(companyEdocsListActions.clearMailState());
    }
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
        handleClearError();
    }

}

export default SendInvoiceEdocsByMailPopup;