import { useState } from "react";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import { currencyConversion, CustomCheckBox, mapOptions } from "../../../../utils";
import { getDateString } from "../../../../variables";
import { useTranslation } from "react-i18next";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { ITimeCardsData } from "../../../../interfaces";
import SearchBar from "../../../../components/search_bar";

interface Props {
  payrollTimecards: ITimeCardsData[];
  selectedCards: Set<String>;
  handleSelection: (id?: string) => void;
}

export const CreatePayrollStepTwo = (props: Props) => {
  const { t } = useTranslation();
  const { payrollTimecards, selectedCards, handleSelection } = props;
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [groupBySortedField, setGroupBySortedField] = useState<string | null>("")
  const [search, setSearch] = useState('');

  const SelectAllCheckBox = () => {
    return (
      <CustomCheckBox
        name={""}
        title={""}
        checked={selectedCards.size === payrollTimecards?.length}
        onClick={() => handleSelection()}
      />
    );
  };

  const tableHeader = [
    { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
    { title: "bill_to", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "talent", code: "talent_name" },
    { title: "total_pay", code: "total_pay" },
    { title: "week_ending", code: "week_ending_date" },
    { title: "week_worked", code: "week_worked" },
    { title: "pay_method", code: "pay_method" },
  ];

  const groupingOptions = [
    {
      "title": "Company Name",
      "value": "company_name"
    },
    {
      "title": "Talent Name",
      "value": "talent_name"
    },
    {
      "title": "Week Worked",
      "value": "week_worked"
    },
  ]

  function getFlexNumber(value: number) {
    if (value === 0) return 0.2;
    if (value === 1) return 1;
    if (value === 2) return 0.8;
    if (value === 3) return 0.7;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 1;
    if (value === 8) return 1.65;
    if (value === 20) return 1;
    return 1;
  }

  const handleSortFieldChange = (value: string) => {
    setGroupBySortedField(null);
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  const getTotalPay = () => {
    if (
      selectedCards.size === payrollTimecards?.length ||
      selectedCards.size === 0
    )
      return payrollTimecards
        .map((x) => x.total_pay)
        .reduce((current, acc) => current + acc, 0);

    return payrollTimecards
      .filter((x: any) => selectedCards.has(x.time_card_id))
      .map((x) => x.total_pay)
      .reduce((current, acc) => current + acc, 0);
  };

  function getFilteredList() {
    let list;
    const sortField = sortedField ? sortedField : groupBySortedField;

    if (payrollTimecards && sortField) {
      list = [...payrollTimecards].sort((a, b) => {
        const valueA = a[sortField] != null
          ? typeof a[sortField] === 'string'
            ? a[sortField].trim().toLowerCase()
            : a[sortField]
          : "";
        const valueB = b[sortField] != null
          ? typeof b[sortField] === 'string'
            ? b[sortField].trim().toLowerCase()
            : b[sortField]
          : "";

        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        } else {
          return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
        }
      });
    }

    return (list ?? payrollTimecards).filter(doc => {
      const str = search.trim().toLowerCase();
      return Object.values(doc).some(val =>
        val && val.toString().trim().toLowerCase().includes(str)
      );
    });;
  }


  return (
    <div className="cpb-step-two">
      <div className="cpb-step-header">
        <div className="cpb-search-field">
          <CustomFormSelect
            name={t("group_by")}
            list={mapOptions(groupingOptions, "title", "value")}
            onChange={(val) => { setGroupBySortedField(val); setSortedField(null) }}
            value={groupBySortedField}
            placeholder={t('select')}
            required={false}
            label={t('group_by')}
            isClearable={false}
          />
        </div>
        <div className="cpb-step-right">
     
          <div className="total-count">
            <span>
              {`${t("total_count")}: `}
              <span className="total-count-number">
                {getFilteredList()?.length}
              </span>
            </span>
          </div>
          <div>
            <SearchBar
              value={search}
              onChange={setSearch}
              onSearch={() => { }}
            />
          </div>
        </div>
      </div>
      <div className="cpb-step-two-table">
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList().length > 0 ?
            <>
              {getFilteredList().map((doc) => {
                return (
                  <tr key={doc.time_card_id}>
                    <TableData customStyle={{ flex: getFlexNumber(0) }}>
                      <CustomCheckBox
                        name={""}
                        title={""}
                        checked={selectedCards.has(doc.time_card_id)}
                        onClick={() => handleSelection(doc.time_card_id)}
                      />
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(1) }}>
                      <span>{doc.company_name}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                      <span>{doc.department_name}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                      <span>{doc.talent_name}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(4) }}>
                      <span>{currencyConversion(doc.total_pay)}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                      <span>{getDateString(doc.week_ending_date)}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                      <span>{getDateString(doc.week_worked)}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(7) }}>
                      <span>{doc.pay_method}</span>
                    </TableData>
                  </tr>
                );
              })}
            </>
            :
            <>
              <tr className="nda-div">
                <TableData customStyle={{ flex: getFlexNumber(20) }}>
                  <div>
                    {t("no_data_available")}
                  </div>
                </TableData>
              </tr>
            </>
          }
          <div className="total-row">
            <TableData
              customStyle={{
                flex: getFlexNumber(2),
                backgroundColor: "#f4f4fa",
                color: "#074783",
                fontWeight: "bold",
              }}
            >
              <span>Total</span>
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(1), backgroundColor: "#f4f4fa" }}
            >
              <span></span>
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(0), backgroundColor: "#f4f4fa" }}
            >
              <span></span>
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(3), backgroundColor: "#f4f4fa" }}
            >
              <span></span>
            </TableData>
            <TableData
              customStyle={{
                flex: getFlexNumber(8),
                backgroundColor: "#f4f4fa",
                color: "#074783",
                fontWeight: "bold",
              }}
            >
              <span>{currencyConversion(getTotalPay())}</span>
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(5), backgroundColor: "#f4f4fa" }}
            >
              <span></span>
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(6), backgroundColor: "#f4f4fa" }}
            >
              <span></span>
            </TableData>
          </div>
        </SortableTable>
      </div>
    </div>
  );
};
