import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { AdminCenterAPI } from "../../../apis";
import { AdminCenterInfoTextCategory } from "../../../interfaces/admin_center_info_text_category";

export const getAdminCenterInfoText = createAsyncThunk<
    AdminCenterInfoTextCategory,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/admin_center_info_text/get',
    async (info_text_type, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getInfoTextByCategory(info_text_type);
            if (typeof res.data === 'string') return [];
            return res.data as AdminCenterInfoTextCategory;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateAdminCenterInfoText = createAsyncThunk<
    string,
    AdminCenterInfoTextCategory | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/admin_center_info_text/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateInfoTextByCategory(data);
            if (typeof res.data === 'string') return [];
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);