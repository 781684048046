import { useEffect, useRef } from "react";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { CustomButton, FormInput, SpinnerScreen, allowNumbers, convertNumberToMoney, formatToMoney, mapOptions } from "../../../../utils";
import { CompaniesIcon, TalentIcon } from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import { useTranslation } from "react-i18next";
import './add_additional_tax.scss';
import { AppError, ICompanyBackOfficeCreditProfile, ICompanyBackOfficeSetup } from "../../../../interfaces";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { getAdditionalTax, getDeafaultPaymentOption, selectAditionalTaxList, selectDeafaultPaymentOptionList } from "../../../../redux/admin_center/back_office";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { ITalentBackOfficeTalentAdditionalTax } from "../../../../interfaces/talent_back_office";
import { getPayRollTaxConfigList, selectPayRollTaxConfigList } from "../../../../redux/admin_center";
import ApiError from "../../../../components/api_error";
interface Props {
    visible: boolean,
    title: string,
    successTitle: string,
    onClose: () => void,
    onDissmiss?: () => void,
    handleFormFieldChange: (fieldId: string, value: any, parent: string) => void;
    taxInformationFormState: ITalentBackOfficeTalentAdditionalTax;
    handleSave: () => void;
    currentLoginUserName: string;
    onSuccessClose: () => void;
    index: number | undefined;
    talentName: string;
    parentField: string;
    taxInfoUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleClearError: () => void;

}

const AddTaxInformationPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        title,
        successTitle,
        handleFormFieldChange, taxInformationFormState, handleSave, handleClearError, currentLoginUserName, talentName, onSuccessClose, index, parentField, taxInfoUpdateState
    } = props;
    const actionRef = useRef<ActionDialogHolderType>(null);
    const loading = taxInfoUpdateState.loading === LoadingType.succeeded;
    const isAddLoading = () => {
        return taxInfoUpdateState?.loading === LoadingType.pending;
    };
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getPayRollTaxConfigList());
    }, []);
    const payRollTaxList = useAppSelector((state) => selectPayRollTaxConfigList(state))
    const maritalStatus = [
        {
            "title": "Married",
            "value": "Married"
        },
        {
            "title": "Single",
            "value": "Single"
        },
    ]
    function handleClose() {
        closePopup(onClose)
    }
    function handleWrapperClose(action?: () => void) {
        if (loading) {
            closePopup(props.onSuccessClose);
        } else {
            if (action) closePopup(action);
        }
    }
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
                <div className="add-tax-info-container">
                    <div className="add-tax-info-header">
                        <FormDialogTilteHeader
                            title={loading ? (index !== undefined ? t("update_add_tax") : successTitle) : (index !== undefined ? t("update_add_tax") : title)}
                            titleIcon={<TalentIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="add-tax-info-content">
                        {loading ?
                            (<div className="saved-doc-holder">
                                <DocSavedText>
                                    <span className="aknw-name">
                                        {t("well_done_credit_profile", {
                                            name: currentLoginUserName,
                                        })}
                                    </span>
                                    <span>{index !== undefined ? t("you_have_updated_addtax", { talentName: talentName }) : t("you_have_added_addtax", { talentName: talentName })}</span>
                                </DocSavedText>
                            </div>)
                            :
                            (
                                <>
                                    <div className="add-tax-info-input-holder-row">
                                        <div className={"add-tax-info-input-holder"}>
                                            <CustomFormSelect
                                                name={t("additional_tax_id")}
                                                list={mapOptions(payRollTaxList, "payroll_tax_name", "id")}
                                                onChange={(val) => handleFormFieldChange("additional_tax_id", val, parentField)}
                                                value={taxInformationFormState.additional_tax_id}
                                                placeholder={t('select')}
                                                required={false}
                                                label={t('select_the_Tax')}
                                            />
                                        </div>
                                        <div className={"add-tax-info-input-holder"}>
                                            <CustomFormSelect
                                                name={t("filing_status")}
                                                list={mapOptions(maritalStatus, "title", "value")}
                                                onChange={(val) => handleFormFieldChange("filing_status", val, parentField)}
                                                value={taxInformationFormState.w4_data.filing_status}
                                                placeholder={t('select')}
                                                required={false}
                                                label={t('filing_status')}
                                            />
                                        </div>
                                        <div className={"add-tax-info-input-holder"}>
                                            <FormInput
                                                id={'geo_code'}
                                                onChange={(id, val) => handleFormFieldChange("geo_code", val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={taxInformationFormState.geo_code}
                                                label={t("geo_code")}
                                                disabled={true}
                                            />
                                        </div>

                                    </div>
                                    <div className="add-tax-info-input-holder-row">
                                        <div className={"add-tax-info-input-holder"}>
                                            <FormInput
                                                id={'extra_withholding'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(taxInformationFormState.w4_data.extra_withholding, "string") as string}
                                                label={t("does_talent_have_add_withholding_amnt", { talent: talentName })}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                        <div className={"add-tax-info-input-holder"}>
                                            <FormInput
                                                id={'default_exemption_amount'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(taxInformationFormState.w4_data.other_income, "string") as string}
                                                label={t("does_talent_have_def_exemp_amnt", { talent: talentName })}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                        <div className={"add-tax-info-input-holder"}>
                                            <FormInput
                                                id={'fixed_amount'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(taxInformationFormState.w4_data.dependent_and_other_credits, "string") as string}
                                                label={t("does_talent_have_fixed_amnt", { talent: talentName })}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                    </div>
                                    <div className="add-tax-info-input-holder-row">
                                        {/* <div className={"add-tax-info-input-holder"}>
                                            <FormInput
                                                id={'no_of_dependents'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={taxInformationFormState.no_of_dependents}
                                                label={t("how_many_dependents_talent_claiming", { talent: talentName })}

                                            />
                                        </div>
                                        <div className={"add-tax-info-input-holder"}>
                                            <FormInput
                                                id={'no_of_exemptions'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={taxInformationFormState.no_of_exemptions}
                                                label={t("how_many_exemptions_talent_claiming", { talent: talentName })}

                                            />
                                        </div> */}
                                        <div className={"add-tax-info-input-holder toggle-row"}>
                                            <div className={"fc-content-input-holder"}>
                                                <ToggleSwitch
                                                    onChange={(val) => handleFormFieldChange('has_lockin_letter', val, parentField)}
                                                    label={t("has_lockin_letter")}
                                                    checked={taxInformationFormState.has_lockin_letter!}
                                                    labelPosition={'top'}
                                                />
                                            </div>
                                            <div className={"fc-content-input-holder"}>
                                                <ToggleSwitch
                                                    onChange={(val) => handleFormFieldChange('resident', val, parentField)}
                                                    label={t("resident")}
                                                    checked={taxInformationFormState.resident!}
                                                    labelPosition={'top'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {taxInfoUpdateState && taxInfoUpdateState.error && (
                                        <div className={"error-section"}>
                                            <ApiError
                                                message={taxInfoUpdateState?.error?.message}
                                                onClose={handleClearError}
                                            />
                                        </div>
                                    )}
                                </>
                            )
                        }

                    </div>
                    <div className="add-tax-info-actions">
                        {loading ?
                            <div className="btn-save">
                                <CustomButton
                                    loading={isAddLoading()}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("ok")}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => closePopup(onSuccessClose)}
                                    enable={true}
                                />
                            </div>
                            :
                            (
                                <>
                                    <div className="btn-cancel">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("cancel")}
                                            enable
                                            backgroundColor={THEME.secondaryColor4}
                                            onClick={onClose}
                                        />
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={isAddLoading()}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("save")}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={handleSave}
                                            enable={true}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>);

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default AddTaxInformationPopup;