import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, ICompanyCertification } from "../../../interfaces";
import * as thunk from "./certifications_list_thunk";

export interface CertificationsListState extends EntityState<ICompanyCertification> {
    error?: AppError | null,
    loading: LoadingType,
}

const certificationsListAdapter: EntityAdapter<ICompanyCertification> = createEntityAdapter<ICompanyCertification>({
    selectId: (doc) => doc.id,
})

const initialState: CertificationsListState = certificationsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const certificationsListSlice = createSlice({
    name: 'certificationsList',
    initialState: initialState,
    reducers: {
        clearCertificationsListError(state){
            return {...state, error: null};
        },
        clearCertificationsListState(state){
            return certificationsListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCertificationsList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getCertificationsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCertificationsList.fulfilled, (state, action) => certificationsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearCertificationsListError, clearCertificationsListState } = certificationsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = certificationsListAdapter.getSelectors();
export const selectState = (state: CertificationsListState) => state;
export const selectLoading = (state: CertificationsListState) => state.loading;
export const selectError = (state: CertificationsListState) => state.error;


