import { createAsyncThunk } from "@reduxjs/toolkit";
import { OnboardingPortalAPI } from "../../../apis";
import { AppError, ITalentOnBoardingData } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { onboardingVerifyTokenThunk } from "../verify_token/verify_token_thunk";

export const updateEmployeeSignatureThunk = createAsyncThunk<
  string,
  ITalentOnBoardingData,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/employee-signature/patch",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.patchTalentOnBoarding(payload);
      thunkAPI.dispatch(onboardingVerifyTokenThunk({token:payload.token}));
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);