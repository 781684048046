import { CheckCircleOutline, KeyboardBackspace, Timer } from "@material-ui/icons";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import ApplicationCard from "../../../../components/application_card/application_card";
import { DateInput } from "../../../../components/date_input/date_input";
import { TimeInput } from "../../../../components/time_input/time_input";
import { CONTACT_METHODS, LoadingType, THEME } from "../../../../enums";
import {
  ArchiveFolderIcon,
  CallBlueIcon,
  EmailIcon,
  FacebookIcon,
  FollowUpIcon,
  IndeedIcon,
  LinkedInIcon,
  TextIcon,
  ZipRecruiterIcon
} from "../../../../icons";
import {
  OnlineApplicationSent,
  OnlineApplicationSentFollowUp
} from "../../../../interfaces/online_application_sent";
import { manageApplicationSentActions } from "../../../../redux/dashboard/metrics/manage_application_sent/manage_application_sent_reducer";
import { selectManageApplicationSentUpdateState } from "../../../../redux/dashboard/metrics/manage_application_sent/manage_application_sent_selector";
import { updateApplicationSent } from "../../../../redux/dashboard/metrics/manage_application_sent/manage_application_sent_thunk";
import {
  selectOnlineApplicationsSentEntities,
  selectOnlineApplicationsSentListState
} from "../../../../redux/dashboard/metrics/online_applications_sent_list/online_applications_sent_list_selector";
import { getOnlineApplicationsSentList } from "../../../../redux/dashboard/metrics/online_applications_sent_list/online_applications_sent_list_thunk";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import { CustomButton, FormInput } from "../../../../utils";
import "./dashboard.scss";
import TimePickerComponent from "../../../../components/time_picker/time_picker";
import { dateTimeToTimestamp, timestampToStringDate } from "../../../../variables";

export const OnlineApplicationSentDashboard: FC<RouteComponentProps<any>> = (
  props: RouteComponentProps<any>
) => {
  const { t } = useTranslation();
  const [app, setApp] = useState<OnlineApplicationSent>(
    new OnlineApplicationSent()
  );
  const { params } = props.match;
  const dispatch = useAppDispatch();
  const [step, setStep] = useState<number>(0);
  const [followupMethod, setFollowupMethod] = useState<CONTACT_METHODS>();
  const [followUp, setFollowUp] = useState<OnlineApplicationSentFollowUp>(
    new OnlineApplicationSentFollowUp()
  );
  const entities = useAppSelector((state) =>
    selectOnlineApplicationsSentEntities(state)
  );
  const [asyncLoading, setAsyncLoading] = useState<boolean>(false);

  const { loading } = useAppSelector((state) =>
    selectOnlineApplicationsSentListState(state)
  );

  const updateState = useAppSelector((state) =>
    selectManageApplicationSentUpdateState(state)
  );

  useEffect(() => {
    dispatch(getOnlineApplicationsSentList());
  }, []);

  useEffect(() => {
    if (updateState.loading == LoadingType.succeeded) {
      dispatch(postSnackbarMessage(updateState.response ?? null));
      dispatch(getOnlineApplicationsSentList());
      dispatch(
        manageApplicationSentActions.clearUpdateApplicationSentState()
      );
      navigateToUniversalDirectory();
    }
  }, [updateState.loading]);

  useEffect(() => {
    if (loading === LoadingType.succeeded) {
      setApp(entities[params.id] ?? new OnlineApplicationSent());
    }
  }, [loading]);

  const goToPreviousStep = () => {
    setStep(step - 1);
    setFollowUp(new OnlineApplicationSentFollowUp());
  };

  const handleArchive = () => {
    updateApplication({ ...app, is_archived: true });
  };

  const handleFollowUpSelection = (followUpType: CONTACT_METHODS) => {
    setFollowupMethod(followUpType);
    updateFollowUpProp("type_of_follow_up", followUpType);
    setStep(2);
  };

  const updateFollowUpProp = (key, val) => {
    const newfollowUp = { ...followUp };
    newfollowUp[key] = val;
    setFollowUp(newfollowUp);
  };

  const handleFollowUp = () => {
    setStep(1);
    setFollowUp(new OnlineApplicationSentFollowUp());
  };

  const navigateToUniversalDirectory = () => {
    props.history.push({
      pathname: `${AppRoutes.onlineApplicationsSent}/universal-directory`,
    });
  };

  const handleClearTime = () => {
    setFollowUp({...followUp, next_action_time: ""})
  }

  const executeFollowUp = async () => {
    const newApp = { ...app };
    const dateTimestampValue = timestampToStringDate(followUp.next_action_date)
    const timeStringValue = followUp.next_action_time
    const dateAndTimeTimeStampValue= dateTimeToTimestamp(dateTimestampValue, timeStringValue)!/1000
    followUp.next_action_date_time = dateAndTimeTimeStampValue
    newApp.follow_up = [followUp, ...newApp.follow_up];
    updateApplication(newApp);
  };

  const updateApplication = async (appInfo: OnlineApplicationSent) => {
    dispatch(updateApplicationSent(appInfo));
  };

  const getheaderText = () => {
    switch (followupMethod) {
      case CONTACT_METHODS.phone:
        return t("tell_about_phone");
      case CONTACT_METHODS.email:
        return t("tell_about_email");
      case CONTACT_METHODS.text:
        return t("tell_about_text");
      case CONTACT_METHODS.linkedIn ||
        CONTACT_METHODS.facebook ||
        CONTACT_METHODS.indeed ||
        CONTACT_METHODS.zipRecruiter:
        return t("tell_about_social_media");
    }
  };

  const getCheckCircle = (small = false) => (
    <div className={`check-circle-outline ${small ? "small" : ""}`}>
      <CheckCircleOutline htmlColor="#00be4b" />
    </div>
  );

  const getSocialMediaCards = () => (
    <div className="next-steps">
      <div
        className={`step-small ${followUp?.type_of_follow_up === CONTACT_METHODS.linkedIn
          ? "selected"
          : ""
          }`}
        onClick={() =>
          updateFollowUpProp("type_of_follow_up", CONTACT_METHODS.linkedIn)
        }
      >
        {followUp?.type_of_follow_up === CONTACT_METHODS.linkedIn &&
          getCheckCircle()}
        <LinkedInIcon width={"58px"} height={"58px"} />
      </div>
      <div
        className={`step-small ${followUp?.type_of_follow_up === CONTACT_METHODS.indeed
          ? "selected"
          : ""
          }`}
        onClick={() =>
          updateFollowUpProp("type_of_follow_up", CONTACT_METHODS.indeed)
        }
      >
        {followUp.type_of_follow_up === CONTACT_METHODS.indeed &&
          getCheckCircle()}
        <IndeedIcon width={"106px"} height={"56px"} />
      </div>
      <div
        className={`step-small ${followUp?.type_of_follow_up === CONTACT_METHODS.zipRecruiter
          ? "selected"
          : ""
          }`}
        onClick={() =>
          updateFollowUpProp("type_of_follow_up", CONTACT_METHODS.zipRecruiter)
        }
      >
        {followUp.type_of_follow_up === CONTACT_METHODS.zipRecruiter &&
          getCheckCircle()}
        <ZipRecruiterIcon width={"123px"} height={"28px"} />
      </div>
      <div
        className={`step-small ${followUp?.type_of_follow_up === CONTACT_METHODS.facebook
          ? "selected"
          : ""
          }`}
        onClick={() =>
          updateFollowUpProp("type_of_follow_up", CONTACT_METHODS.facebook)
        }
      >
        {followUp.type_of_follow_up === CONTACT_METHODS.facebook &&
          getCheckCircle()}
        <FacebookIcon width={"56px"} height={"56px"} />
      </div>
    </div>
  );

  const panel0 = () => (
    <>
      <div className="header">{t("i_want_to")}</div>
      <div className="next-steps">
        <ApplicationCard
          icon={<FollowUpIcon />}
          label={t("schedule_follow_up")}
          onClick={handleFollowUp}
        />
        <ApplicationCard
          icon={<ArchiveFolderIcon />}
          label={t("archive_application_sent")}
          onClick={handleArchive}
        />
      </div>
    </>
  );

  const panel1 = () => (
    <>
      <div className="header">{t("which_to_schedule")}</div>
      <div className="next-steps">
        <ApplicationCard
          icon={<CallBlueIcon height={"2.5vw"} />}
          label={t("schedule_phone")}
          onClick={() => handleFollowUpSelection(CONTACT_METHODS.phone)}
        />

        <ApplicationCard
          icon={<EmailIcon height={"2.5vw"} />}
          label={t("schedule_email")}
          onClick={() => handleFollowUpSelection(CONTACT_METHODS.email)}
        />
      </div>
      <div className="next-steps">
        <ApplicationCard
          icon={<TextIcon height={"2.5vw"} />}
          label={t("schedule_text")}
          onClick={() => handleFollowUpSelection(CONTACT_METHODS.text)}
        />

        <ApplicationCard
          icon={(
            <div className={"list-icons"}>
              <LinkedInIcon width={"1.3541666667vw"} height={"1.3541666667vw"} />
              <FacebookIcon width={"1.3541666667vw"} height={"1.3541666667vw"} />
              <ZipRecruiterIcon
                width={"1.3541666667vw"}
                height={"1.3541666667vw"}
                viewBox={"0 0 20 98"}
              />
              <IndeedIcon width={"1.3541666667vw"} height={"1.3541666667vw"} viewBox={"0 0 80 400"} />
            </div>
          )}
          label={t("schedule_social_media")}
          onClick={() => handleFollowUpSelection(CONTACT_METHODS.linkedIn)}
        />
      </div>
      <div className="previous-step" onClick={goToPreviousStep}>
        <KeyboardBackspace />
        <div className="label">{t("previous_step")}</div>
      </div>
    </>
  );

  const finalPanel = () => (
    <>
      <div className="header">{getheaderText()}</div>
      {(followUp?.type_of_follow_up === CONTACT_METHODS.linkedIn ||
        followUp?.type_of_follow_up === CONTACT_METHODS.indeed ||
        followUp?.type_of_follow_up === CONTACT_METHODS.zipRecruiter ||
        followUp?.type_of_follow_up === CONTACT_METHODS.facebook) && (
          <>
            {getSocialMediaCards()}
            <div className="header" style={{ marginTop: 20 }}>
              {t("tell_about")}
            </div>
          </>
        )}
      <FormInput
        id={"followup_desc"}
        onChange={updateFollowUpProp}
        required={false}
        placeholder={""}
        type={"text"}
        multiline={true}
        value={followUp?.followup_desc}
        numberOfLines={3}
        fieldStyles={{ width: "30vw", marginTop: "20px", marginBottom: "20px" }}
      />
      <div className="header">{t("follow_up_time")}</div>
      <div className="date-time-container">
        <DateInput
          id={"next_action_date"}
          onChange={updateFollowUpProp}
          placeholder={""}
          value={followUp?.next_action_date}
          label={t("date")}
          disablePast
          minDate={new Date()}
          setHoursToZero={true}
          customStyle={{ marginRight: 20, marginBottom: 20 }}
          required
        />
        {/* <TimeInput
          id={"next_action_date_time"}
          onChange={updateFollowUpProp}
          placeholder={""}
          value={followUp?.next_action_date_time}
          label={t("time")}
          customStyle={{ marginBottom: 20 }}
          required
          isDateTime
        /> */}
        <TimePickerComponent
          id={"next_action_time"}
          onChange={updateFollowUpProp}
          placeholder={''}
          value={followUp?.next_action_time}
          onClearTime={handleClearTime}
          label={t("time")}
          icon={<Timer />}
          required
        />
      </div>
      <div className="header">{t("follow_up_action")}</div>
      <FormInput
        id={"followup_goal"}
        onChange={updateFollowUpProp}
        required={false}
        placeholder={""}
        type={"text"}
        multiline={true}
        value={followUp?.followup_goal}
        numberOfLines={3}
        fieldStyles={{ width: "40vw", marginTop: "20px", marginBottom: "20px" }}
      />
      <div className="footer-action">
        <div className="previous-step" onClick={goToPreviousStep}>
          <KeyboardBackspace />
          <div className="label">{t("previous_step")}</div>
        </div>
        <div className="footer">
          <div className="btn-cancel">
            <CustomButton
              loading={false}
              name={t("wanna_archive")}
              enable
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleArchive}
            />
          </div>
          <div className="btn-save">
            <CustomButton
              loading={asyncLoading}
              name={t("confirm_follow_up")}
              enable={
                !!followUp.type_of_follow_up 
                // && !!followUp.next_action_date_time
                && !!followUp.next_action_date
                && !!followUp.next_action_time
              }
              backgroundColor={THEME.defaultHighLightColor}
              onClick={executeFollowUp}
            />
          </div>
        </div>
      </div>
    </>
  );

  const getNextStep = () => {
    switch (step) {
      case 0:
        return panel0();
      case 1:
        return panel1();
      case 2:
        return finalPanel();
    }
  };

  return <div className="plan-next-step">{getNextStep()}</div>;
};
