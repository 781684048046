import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, SuccessProfileQuestionStep } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getSuccessProfileQuestionSteps = createAsyncThunk<
    Array<SuccessProfileQuestionStep>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/success_profile_question_step/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getSuccessProfileQuestionSteps();
            return res.data as SuccessProfileQuestionStep[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createSuccessProfileQuestionStep = createAsyncThunk<
    string,
    {'step_number': number, 'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/success_profile_question_step/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createSuccessProfileQuestionStep(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteSuccessProfileQuestionStep = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/success_profile_question_step/delete',
    async (method_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteSuccessProfileQuestionStep(method_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateSuccessProfileQuestionStep = createAsyncThunk<
    string,
    {'id': string, 'step_number': number, 'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/success_profile_question_step/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateSuccessProfileQuestionStep(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);