import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../../interfaces";
import { SendByEmail } from "../../../../../interfaces/marketing_campaign";
import { JobOrderAPI } from "../../../../../apis";
import { catchRequestError } from "../../../../../utils";


export const sendDocVidByEmail = createAsyncThunk<
    string,
    SendByEmail,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs-documents/send-by-email-docvid/post',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.CreateDocVidDownloadLinkByEmail(data);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

