import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, SalesCallJourneyMethod } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getSalesCallJourneyMethods = createAsyncThunk<
    Array<SalesCallJourneyMethod>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/sales_call_journey_method/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getSalesCallJourneyMethods();
            return res.data as SalesCallJourneyMethod;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createSalesCallJourneyMethod = createAsyncThunk<
    string,
    {'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/sales_call_journey_method/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createSalesCallJourneyMethod(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteSalesCallJourneyMethod = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/sales_call_journey_method/delete',
    async (method_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteSalesCallJourneyMethods(method_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateSalesCallJourneyMethod = createAsyncThunk<
    string,
    {'id': string, 'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/sales_call_journey_method/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateSalesCallJourneyMethods(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);