import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './job_order_contact_list_reducer';

const select = (state: IRootState) => state.jobs.details.contacts.contactList;
export const selectJobOrderContactsState = createSelector(select, reducer.selectJobOrderContactsState);
export const selectSingleJobOrderContactsStateById = (id: string | undefined | null) => createSelector(
    selectJobOrderContactsState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleJobOrderContactssState(state, id);
        return reducer.selectInitialSingleJobOrderContactsState();
    }
);