import React, { useEffect, useState } from 'react';
import { LoadingType, THEME } from "../../../enums";
import { CustomButton } from "../../../utils";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import './security_configuration_page.scss';
import '../../../custom.scss';
import {
    getPasswordExpirationDays,
    selectPasswordExpirationState,
    updatePasswordExpirationDays
} from "../../../redux/admin_center";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import CustomFormSelect from '../../../components/form_selector/form_select';


const passwordExpiration = [
    { label: "60 days", value: "60" },
    { label: "90 days", value: "90" },
    { label: "120 days", value: "120" },
]

const SecurityConfigurationPage = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [passwordExpiryValue, setPasswordExpiryValue] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    const passwordExpirationDaysState = useAppSelector((state) => selectPasswordExpirationState(state));

    // function handleDropdownChange(e) {
    //     setPasswordExpiryValue(e.target.value);
    // }

    function handleSave() {
        setSentRequest(true);
        dispatch(updatePasswordExpirationDays({ password_expiration_days: Number(passwordExpiryValue) }));
    }

    useEffect(() => {
        if (passwordExpirationDaysState.loading === LoadingType.succeeded) {
            setPasswordExpiryValue(String(passwordExpirationDaysState.passwordExpirationDays));
        }
    }, [passwordExpirationDaysState.loading])

    useEffect(() => {
        if (passwordExpirationDaysState.update.loading === LoadingType.succeeded && sentRequest) {
            setSuccessMessage(passwordExpirationDaysState.update.response);
            setTimeout(() => (setSuccessMessage('')), 5000);
            setSentRequest(false);
        }
    }, [passwordExpirationDaysState.update.loading])

    useEffect(() => {
        dispatch(getPasswordExpirationDays());
        return () => {

        }
    }, [])

    return (
        <div className="ac-sc-container">
            <div className="ac-sc-header">
                <div className="ac-sytm-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="ac-sc-content">
                <div className="ac-sc-sp-content-pe">
                    <CustomFormSelect
                        name={t("customer_password_expires")}
                        list={passwordExpiration}
                        onChange={(val) => (setPasswordExpiryValue(val))}
                        value={passwordExpiryValue}
                        placeholder={"Select"}
                        required={true}
                        label={"Customer Password Expires In"}
                        customStyle={{ width: "30vw" }}
                        labelStyle={{ fontSize: "14px" }}
                    />
                </div>
                <div className={"save-button"}>
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t("save")}
                            loading={passwordExpirationDaysState.update.loading === LoadingType.pending}
                            enable={passwordExpiryValue ? true : false}
                            backgroundColor={THEME.buttonColor1}
                            onClick={handleSave}
                        />
                    </div>
                    <div className="message">{successMessage}</div>
                </div>
            </div>
        </div>

    )
}


export default SecurityConfigurationPage;