import { createAsyncThunk } from "@reduxjs/toolkit";
import { OnboardingPortalAPI } from "../../../apis";
import { AppError, IOnboardingVerifyTokenResponse } from "../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../utils";

export const onboardingSubmitApplicationThunk = createAsyncThunk<
  IOnboardingVerifyTokenResponse,
  string,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/submmit-application/post",
  async (token, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.onboardingVerifyToken(token);
      if (typeof res.data === "string")
        return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data as IOnboardingVerifyTokenResponse;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);
