import { IRootState } from "../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './company_basic_info_reducer';

const select = (state: IRootState) => state.companies.companyInfo.companyBasicInfo;
export const selectCompanyBasicInfoState = createSelector(select, reducer.selectCompanyBasicInfoState);
export const selectSingleCompanyBasicInfoStateById = (id: string | undefined | null) => createSelector(
    selectCompanyBasicInfoState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleCompanyBasicInfoState(state, id);
        return reducer.selectInitialSingleCompanyBasicInfoState();
    }
);