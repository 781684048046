import React, { useEffect, useRef, useState } from 'react';
import EditIcon from '../../../../assets/icons/edit_button.svg';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import CloseIcon from '../../../../assets/icons/close-btn2.svg';
import { LoadingType, THEME } from "../../../enums";
import { CustomButton } from "../../../utils";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import { AddCircleOutline } from "@material-ui/icons";
import SortableTable, { TableData } from '../../../components/sortable_table/sortable_table';
import {
    createTrafficLightEngagementMetric, updateTrafficLightEngagementMetric, getTrafficLightEngagementMetrics,
    deleteTrafficLightEngagementMetric, selectTrafficLightEngagementMetricState, selectTrafficLightEngagementMetricList
} from "../../../redux/admin_center";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../redux/store";
import './system_wide_page.scss';
import './dashboard.scss';
import { Portal } from "react-portal";
import {
    ActionDialogHolder,
    ActionDialogHolderType
} from "../../../components/action_dialog_holder/action_dialog_holder";
import '../../../custom.scss';
import { FormControl, MenuItem, Select } from "@material-ui/core";
import TableEmpty, { TableErrorHandler, TableLoading } from '../../../../app/components/table_empty/table_empty';
import { TrafficLightMetricsCategory, TrafficLightMetricsType } from '../../../../app/enums/traffic_light_engagement_metric';
import CustomFormSelect from '../../../components/form_selector/form_select';
import { FormInput } from "../../../utils/form_input";
import { DeleteConfirmationDialog } from '../../components/delete_confirmation_dialog';
import { trafficLightEngagementMetricActions } from '../../../redux/admin_center/system_configuration/traffic_light_engagement_metric/traffic_light_engagement_metric_reducer';

const tableHeader = {
    traffic_light_engagement_metric: [
        { title: 'category', code: 'category' },
        { title: 'type', code: 'type' },
        { title: 'range_from', code: 'range_from' },
        { title: 'range_to', code: 'range_to' },
        { title: 'actions', code: 'actions' }],
    other: [
        { title: 'value', code: 'actions' },
        { title: 'actions', code: 'actions' },
    ]
};

const categories = {
    1: {
        label: "traffic_light_engagement_metrics",
        value: ["category", "type", "range_from", "range_to"],
        buttonLabel: "add_traffic_light_engagement_metric",
        inputLabel: "traffic_light_engagement_metric",
        deleteLabel: "delete_traffic_light_engagement_metric",
        deleteMessage: "delete_traffic_light_engagement_metric_message",
        updateLabel: "update_traffic_light_engagement_metric",
        methods: {
            create: createTrafficLightEngagementMetric, update: updateTrafficLightEngagementMetric,
            remove: deleteTrafficLightEngagementMetric, get: getTrafficLightEngagementMetrics
        },
        headers: tableHeader.traffic_light_engagement_metric,
        clearCreateState: trafficLightEngagementMetricActions.clearTrafficLightEngagementMetricCreateState,
        clearUpdateState: trafficLightEngagementMetricActions.clearTrafficLightEngagementMetricUpdateState,
        clearDeleteState: trafficLightEngagementMetricActions.clearTrafficLightEngagementMetricDeleteState,
        clearDeleteStateError: trafficLightEngagementMetricActions.clearTrafficLightEngagementMetricDeleteStateError,
    }
}

const trafficLightMetricsCategory = {
    1: TrafficLightMetricsCategory.company,
    2: TrafficLightMetricsCategory.contact,
    3: TrafficLightMetricsCategory.talent
}

const trafficLightMetricsType = {
    1: TrafficLightMetricsType.high,
    2: TrafficLightMetricsType.medium,
    3: TrafficLightMetricsType.low,
}

function getKeyFromValue(map, value) {
    for (let i in map)
        if (map[i] == value)
            return i;
}

const TrafficLightEngagementMetricPage = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<{visible: boolean, title: string} | null>(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [methodValue, setMethodValue] = useState({ 'id': '', 'value': '', 'traffic_light_engagement_metric': {} });
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [dropdownChanged, setDropDownChanged] = useState(true);

    const trafficLightEngagementMetricState = useAppSelector((state) => selectTrafficLightEngagementMetricState(state));
    const trafficLightEngagementMetricList = useAppSelector((state) => selectTrafficLightEngagementMetricList(state));

    const reduxMapping = { 1: { state: trafficLightEngagementMetricState, list: trafficLightEngagementMetricList } }

    useEffect(() => {
        if (getMapping()?.state?.create?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.create?.response));
            dispatch(getCategory()?.clearCreateState())
        }
        else if (getMapping()?.state?.update?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.update?.response));
            dispatch(getCategory()?.clearUpdateState())
        }
        else if (getMapping()?.state?.remove?.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(getMapping()?.state?.remove?.response));
            dispatch(getCategory()?.clearDeleteState())
        }
    }, [
        getMapping()?.state?.create?.loading, 
        getMapping()?.state?.update?.loading, 
        getMapping()?.state?.remove?.loading
    ]);

    function getCategory(id = null) {
        if (id)
            return categories[id];
        return categories[selectedCategory];
    }

    function getMapping(id = null) {
        if (id)
            return reduxMapping[id];
        return reduxMapping[selectedCategory];
    }

    function handleCreateCloseDialog() {
        setShowCreateDialog(false);
    }

    function handleCloseDeleteDialog() {
        setShowDeleteDialog(null);
        setDeleteItemId(null);
    }

    function handleShowDeleteDialog(id, title) {
        setShowDeleteDialog({visible: true, title: title});
        setDeleteItemId(id);
    }

    function handleDeleteApprove() {
        if (deleteItemId) {
            dispatch(getCategory().methods.remove(deleteItemId));
        }
        handleCloseDeleteDialog();
    }

    function handleShowEditDialog(method) {
        setShowEditDialog(true);
        if (selectedCategory == 1)
            setMethodValue({
                ...methodValue, traffic_light_engagement_metric: {
                    ...method,
                    category: getKeyFromValue(trafficLightMetricsCategory, method.category),
                    type: getKeyFromValue(trafficLightMetricsType, method.type)
                }
            });
        else
            setMethodValue({ ...methodValue, id: method.id, value: method[getCategory().value] });
    }

    function handleCloseEditDialog() {
        setShowEditDialog(false);
    }

    function handleDropdownChange(value) {
        if (!value)
            value = '1';
        setDropDownChanged(true);
        setSelectedCategory(Number(value));
        dispatch(getCategory(value).methods.get());
    }

    function retryFetchingData() {
        setDropDownChanged(true);
        dispatch(getCategory().methods.get());
    }

    useEffect(() => {
        if (getMapping().state.loading == LoadingType.succeeded) {
            setDropDownChanged(false);
        }
    }, [getMapping().state.loading])

    useEffect(() => {
        if (getMapping().state.remove.loading == LoadingType.succeeded)
            dispatch(getCategory().methods.get());
    }, [getMapping().state.remove.loading])

    useEffect(() => {
        dispatch(getCategory().methods.get());
        return () => {

        }
    }, [])

    return (
        <div className="ac-sc-container">
            <div className="ac-sc-header">
                <div className="ac-sytm-cns-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="ac-sc-dropdown">
                <div className="ac-sc-dropdown-button">
                    <CustomFormSelect
                        name={t("dashboard_dropdown")}
                        list={Object.keys(categories).map(id => ({ value: id, label: categories[id].label }))}
                        onChange={(val) => handleDropdownChange(val)}
                        value={selectedCategory}
                        placeholder={t('traffic_light_engagement_metrics')}
                        required={false}
                        label={t('please_select_traffic_light_engagement_metric_category')}
                        customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}
                    />
                    <div>
                        {/* <CustomButton
                            textStyle={{textTransform: 'capitalize'}}
                            enable={true}
                            leftIcon={getIcon()}
                            name={t(categories[selectedCategory].buttonLabel)}
                            padding={12}
                            fontSize={15}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => (setShowCreateDialog(true))}
                        /> */}
                    </div>
                </div>
            </div>
            <div className="ac-sc-table ac-sc-tlem-table">
                {getMapping().state.loading == LoadingType.pending && dropdownChanged ? <TableLoading /> :
                    <TableBody
                        state={getMapping().state}
                        onRetry={retryFetchingData}
                        data={getMapping().list}
                        category={getCategory()}
                        handleShowEdit={handleShowEditDialog}
                        handleShowDelete={handleShowDeleteDialog}
                    />
                }
            </div>
            {/* only handled traffic light engagement, if any other thing added, need to condition*/}
            {showCreateDialog && <Portal>
                <TrafficLightEngagementMetricCreateAction
                    visible={showCreateDialog}
                    onClose={handleCreateCloseDialog}
                    onDismiss={handleCreateCloseDialog}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                />
            </Portal>}
            {showDeleteDialog && (
                <Portal>
                    <DeleteConfirmationDialog
                        message={t(getCategory()?.deleteMessage, { value: showDeleteDialog?.title })}
                        onCancel={() => setShowDeleteDialog(null)}
                        onConfirm={handleDeleteApprove}
                        deleting={getMapping()?.state?.remove === LoadingType.pending}
                        deleteType={""}
                        customDeleteHeaderTitle={t(getCategory()?.deleteLabel)}
                        state={getMapping()?.state}
                        onClearError={getCategory()?.clearDeleteStateError()}
                    />
                </Portal>
            )}
            {showEditDialog && <Portal>
                <TrafficLightEngagementMetricEditAction
                    visible={showEditDialog}
                    onClose={handleCloseEditDialog}
                    onDismiss={handleCloseEditDialog}
                    previousValue={methodValue.traffic_light_engagement_metric}
                    category={getCategory()}
                    category_id={selectedCategory}
                    reduxMapping={reduxMapping}
                />
            </Portal>}
        </div>

    )
}

const TableBody = ({ state, onRetry, data, category, handleShowEdit, handleShowDelete }) => {
    if (state.loading == LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    else if (data.length == 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }
    else {
        return (
            <SortableTable headerList={category.headers}>
                {data.map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            {category.value.map((value, index) => {
                                return (
                                    <TableData key={doc.id + index} customStyle={{ wordWrap: "break-word" }}>
                                        <span>{doc[value]}</span>
                                    </TableData>
                                )
                            })}
                            <TableData>
                                <img src={EditIcon} alt='edit_button' style={{ marginRight: '20px' }}
                                    onClick={() => handleShowEdit(doc)} />
                                {/* <img src={DeleteIcon} alt='delete_button'
                                     onClick={() => handleShowDelete(doc.id, doc.category)}/> */}
                            </TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }
}

const TrafficLightEngagementMetricCreateAction = ({ onClose, onDismiss, visible, category, category_id, reduxMapping }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [methodValue, setMethodValue] = useState({ range_from: '0', range_to: '' });
    const [sentRequest, setSentRequest] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [selectedType, setSelectedType] = useState(1);

    function getMapping() {
        return reduxMapping[category_id];
    }

    useEffect(() => {
        let state = getMapping().state;
        if (sentRequest && state.create.loading === LoadingType.succeeded) {
            setMethodValue({ ...methodValue, range_from: '0', range_to: '' });
            setSelectedCategory(1);
            setSelectedType(1);
            setSentRequest(false);
            dispatch(category.methods.get());
        }
    }, [getMapping().state.create.loading])

    function handleInput(e) {
        if (e.target.id === "tlem-range-from") {
            if (Number(e.target.value) > -1)
                setMethodValue({ ...methodValue, range_from: e.target.value });
        }
        if (e.target.id === "tlem-range-to") {
            if (Number(e.target.value) > -1)
                setMethodValue({ ...methodValue, range_to: e.target.value });
        }
    }

    function handleChange(e) {
        if (e.target.name == 'tlem-category')
            setSelectedCategory(e.target.value);
        else {
            setSelectedType(e.target.value);
        }
    }

    function handleSave() {
        setSentRequest(true);
        dispatch(category.methods.create({
            category: trafficLightMetricsCategory[selectedCategory],
            type: trafficLightMetricsType[selectedType], range_from: Number(methodValue.range_from),
            range_to: methodValue.range_to ? Number(methodValue.range_to) : null
        }));
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={600}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-container-tlem">
                <div className="dialog-header-tlem">
                    <h5>{t(category.buttonLabel)}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                <div className="dialog-content-tlem">
                    <span>
                        <p>{t("category")}*</p>
                        {/*<input className="form-input" type="text" value={methodValue.category} onChange={handleInput}/>*/}
                        <FormControl size="small">
                            <Select
                                id={"tlem-category-metric"}
                                name="tlem-category"
                                value={selectedCategory}
                                variant={"outlined"}
                                className={"select-list"}
                                onChange={handleChange}
                            >
                                <MenuItem value={1}>{TrafficLightMetricsCategory.company}</MenuItem>
                                <MenuItem value={2}>{TrafficLightMetricsCategory.contact}</MenuItem>
                                <MenuItem value={3}>{TrafficLightMetricsCategory.talent}</MenuItem>
                            </Select>
                        </FormControl>
                    </span>
                    <span>
                        <p>{t("type")}*</p>
                        <Select
                            id={"tlem-category-metric2"}
                            name="tlem-type"
                            value={selectedType}
                            variant={"outlined"}
                            className={"select-list"}
                            onChange={handleChange}
                        >
                            <MenuItem value={1}>{TrafficLightMetricsType.high}</MenuItem>
                            <MenuItem value={2}>{TrafficLightMetricsType.medium}</MenuItem>
                            <MenuItem value={3}>{TrafficLightMetricsType.low}</MenuItem>
                        </Select>
                    </span>
                    <span>
                        <p>{t("range_from")}*</p>
                        <input className="form-input" type="number" min="0" id="tlem-range-from" value={methodValue.range_from} onChange={handleInput} />
                    </span>
                    <span>
                        <p>{t("range_to")}{selectedType != 1 ? '*' : ''}</p>
                        <input className="form-input" type="number" min="0" id="tlem-range-to" value={methodValue.range_to} onChange={handleInput} />
                    </span>
                    {getMapping().state.create.error && sentRequest &&
                        (<p style={{ color: 'red' }}
                            className="message">{getMapping().state.create.error.response.detail.error}</p>)}
                </div>
                <div className="dialog-footer-tlem">
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('save')}
                        enable={true}
                        loading={getMapping().state.create.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleSave}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

const TrafficLightEngagementMetricEditAction = ({ onClose, onDismiss, visible, category, category_id, previousValue, reduxMapping }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [methodValue, setMethodValue] = useState({
        range_from: String(previousValue.range_from),
        range_to: previousValue.range_to ? String(previousValue.range_to) : ''
    });
    const [sentRequest, setSentRequest] = useState(false);

    function getMapping() {
        return reduxMapping[category_id];
    }

    useEffect(() => {
        let state = getMapping().state;
        if (state.update.loading === LoadingType.succeeded && sentRequest) {
            setSentRequest(false);
            dispatch(category.methods.get());
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.update.loading])

    function handleInput(id, value) {
        if (id === "tlem-range-from") {
            if (Number(value) > -1)
                setMethodValue({ ...methodValue, range_from: value });
        }
        if (id === "tlem-range-to") {
            if (Number(value) > -1)
                setMethodValue({ ...methodValue, range_to: value });
        }
    }

    // function handleChange(e) {
    //     if (e.target.name == 'tlem-category')
    //         setSelectedCategory(e.target.value);
    //     else {
    //         setSelectedType(e.target.value);
    //         if ((e.target.value != 1 && !methodValue.range_to) || !methodValue.range_from)
    //             setShowButton(false);
    //         else
    //             setShowButton(true);
    //     }
    // }

    function handleUpdate() {
        setSentRequest(true);
        dispatch(category.methods.update({
            id: previousValue.id, category: trafficLightMetricsCategory[previousValue.category],
            type: trafficLightMetricsType[previousValue.type], range_from: Number(methodValue.range_from),
            range_to: methodValue.range_to ? Number(methodValue.range_to) : null
        }));
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"32vw"}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-container-tlem">
                <div className="dialog-header-tlem">
                    <h5>{t(category.updateLabel)}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                <div className="dialog-content-tlem">
                    <div style={{ display: "flex", flexDirection: "row", width: "-webkit-fill-available" }}>
                        <FormInput
                            id={"tlem-category-metric"}
                            onChange={(id, val) => { }}
                            type={"text"}
                            value={trafficLightMetricsCategory[previousValue.category]}
                            label={t('category')}
                            placeholder=""
                            required={true}
                        />
                        <FormInput
                            id={"tlem-category-type"}
                            onChange={(id, val) => { }}
                            type={"text"}
                            value={trafficLightMetricsType[previousValue.type]}
                            label={t('type')}
                            placeholder=""
                            required={true}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "-webkit-fill-available" }}>
                        <FormInput
                            id={"tlem-range-from"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"number"}
                            value={methodValue.range_from}
                            label={t('range_from')}
                            min={"0"}
                            placeholder=""
                            required={true}
                        />
                        <FormInput
                            id={"tlem-range-to"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"number"}
                            value={methodValue.range_to}
                            label={t('range_to')}
                            placeholder=""
                            min={"0"}
                            required={previousValue.type == 1 ? false : true}
                        />
                    </div>
                    {getMapping().state.update.error && sentRequest &&
                        (<p style={{ color: 'red' }}
                            className="message">{getMapping().state.update.error.response.detail.error}</p>)}
                </div>
                <div className="dialog-footer-tlem">
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('update')}
                        enable={true}
                        loading={getMapping().state.update.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleUpdate}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default TrafficLightEngagementMetricPage;
