import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IEmploymentHistory } from "../../../interfaces";
import * as thunk from "./employment_history_thunk";

export interface TalentEmpHistoryListState extends EntityState<IEmploymentHistory> {
    error?: AppError | null,
    loading: LoadingType,
    employmentHistoryList:IEmploymentHistory
}

const talentEmpHistoryListAdapter: EntityAdapter<IEmploymentHistory> = createEntityAdapter<IEmploymentHistory>({
    selectId: (doc) => doc.id,
})

const initialState: TalentEmpHistoryListState = talentEmpHistoryListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    employmentHistoryList: {} as IEmploymentHistory

})


export const getTalentEmpDataSlice = createSlice({
    name: 'getTalentEmp',
    initialState: initialState,
    reducers: {
       
        clearTalentEmpHistoryListStateError(state){
            return {...state, error: null};
        },
        clearTalentEmpHistoryListState(state){
            return talentEmpHistoryListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
       
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTalentEmpHistoryList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getTalentEmpHistoryList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getTalentEmpHistoryList.fulfilled, (state, action) => talentEmpHistoryListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearTalentEmpHistoryListStateError, clearTalentEmpHistoryListState } = getTalentEmpDataSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = talentEmpHistoryListAdapter.getSelectors();
export const selectState = (state: TalentEmpHistoryListState) => state;
export const selectLoading = (state: TalentEmpHistoryListState) => state.loading;
export const selectError = (state: TalentEmpHistoryListState) => state.error;


