import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { AdminCenterAPI } from "../../../apis";
import { AddWorkCompConfiguration, WorkCompConfiguration } from "../../../interfaces/work_comp_configuration";

export const getWorkCompConfigList = createAsyncThunk<
    Array<WorkCompConfiguration>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/work_comp_config/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getWorkCompConfigList();
            if (typeof res.data === 'string') return [];
            return res.data as Array<WorkCompConfiguration>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const createWorkCompConfigList = createAsyncThunk<
    string,
    AddWorkCompConfiguration,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/work_comp_config/create',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createWorkCompConfigList(payload);
            if (typeof res.data === 'string') return [];
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const updateWorkCompConfigList = createAsyncThunk<
    string,
    WorkCompConfiguration,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/work_comp_config/update',
    async (payload, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateWorkCompConfigList(payload);
            if (typeof res.data === 'string') return [];
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteWorkCompConfigList = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/work_comp_config/delete',
        async (work_comp_id, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.deleteWorkCompConfigList(work_comp_id);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
);