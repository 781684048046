import React from "react";
import { THEME } from "../enums";

interface Props {
    name: string,
    title: string,
    checked: boolean,
    labelPosition?: "top" | "left" | "right",
    onClick: () => void,
    customStyle?: React.CSSProperties,
    labelStyle?: React.CSSProperties,
    disabled?:boolean,
}

export const CustomCheckBox: React.FunctionComponent<Props> = (props) => {

    return (
        <div className={""} style={styles.container}>
            <button className={"icon-btn"} style={styles.boxContainer}>
                <input
                    type={"checkbox"}
                    id={props.name}
                    name={props.name}
                    checked={props.checked}
                    style={{ marginRight: 5, ...props.customStyle }}
                    onClick={props.onClick}
                    onChange={() => { }}
                    disabled={props.disabled}
                />
                <span
                    style={{
                        fontSize: '0.729vw',
                        cursor: 'pointer',
                        color: THEME.defaultHighLightColor,
                        ...props.labelStyle
                    }}
                >{props.title}</span>
            </button>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({

    //selector styles
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'fit-content',
        position: 'relative',
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },

})