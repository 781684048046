import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums"
import { AppError, CompanyData } from "../../../../interfaces"
import * as thunk from './company_basic_info_thunk';


export interface SingleCompanyBasicInfoState {
    data: CompanyData | null,
    error?: AppError | null,
    loading: LoadingType
}


export interface CompanyBasicInfoState {
    [key: string]: SingleCompanyBasicInfoState,
}


const initialState: CompanyBasicInfoState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

export const companyBasicInfoSlice = createSlice({
    name: 'companyBasicInfo',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getCompanyBasicInfo.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.companyId]: {
                        ...getLoadingState(state[action.meta.arg.companyId])
                    }
                })
            )
            .addCase(
                thunk.getCompanyBasicInfo.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.companyId]: {
                        ...getErrorState(state[action.meta.arg.companyId], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getCompanyBasicInfo.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.companyId]: {
                        ...getSuccessState(state[action.meta.arg.companyId], action.payload),
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const {} = companyBasicInfoSlice.actions;
export const selectCompanyBasicInfoState = (state: CompanyBasicInfoState) => state;
export const selectSingleCompanyBasicInfoState = (state: CompanyBasicInfoState, id: string): SingleCompanyBasicInfoState => state[id];

export const selectInitialSingleCompanyBasicInfoState = (): SingleCompanyBasicInfoState => ({
    data: null,
    error: null,
    loading: LoadingType.idle,
});