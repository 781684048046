import { combineReducers } from "@reduxjs/toolkit";
import { manageTalentSlice } from "./manage_talent/manage_talent_reducer";
import { talentListSlice } from "./talent_list/talent_list_reducer";
import { getTalentEmpDataSlice } from "../employment_history/employment_history_reducer";
import { manageTalentEMploymentHistorySlice } from "../manage_employment/manage_employment_reducer";



export const talentUniversalDirectoryReducers = combineReducers({
    [talentListSlice.name]: talentListSlice.reducer,
    [manageTalentSlice.name]: manageTalentSlice.reducer,
});

/// Talent list
export * from './talent_list/talent_list_thunk';
export * from './talent_list/talent_list_selector';

/// Manage Talent
export * from './manage_talent/manage_talent_thunk';
export * from './manage_talent/manage_talent_selector';

