import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import * as thunk from "./recently_closed_week_thunk";

export interface RecentlyClosedWeekState {
    error?: AppError | null,
    loading: LoadingType,
    response: any

}

const initialState: RecentlyClosedWeekState = {
    error: null,
    loading: LoadingType.idle,
    response: null,

}

export const recentClosedWeekSlice = createSlice({
    name: 'reportStartEndDate',
    initialState: initialState,
    reducers: {
        clearPRErrorState(state) {
            return { ...state, error: null };
        },
        clearPRState(state) {
            return { ...state, ...initialState };
        },
   

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getReportStartEndDate.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getReportStartEndDate.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getReportStartEndDate.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))


            .addDefaultCase(state => ({ ...state }));
    }
});

export const recentClosedWeekSliceActions = recentClosedWeekSlice.actions;
export const selectState = (state: RecentlyClosedWeekState) => state;
export const selectLoading = (state: RecentlyClosedWeekState) => state.loading;
export const selectError = (state: RecentlyClosedWeekState) => state.error;
// export const selectPaycheckRegisterDownloadUrl = (state: RecentlyClosedWeekState) => state.downloadURL;



