import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../../enums";
import { OnBoardingIcon } from "../../../../icons";
import { IOnboardingDocsReviewStatus, IProfileDropdown, ITalent } from "../../../../interfaces";
import {
  getUploadHealthCareExchangeUrlThunk,
  selectHealthCareExchangeState,
  uploadHealthCareExchangeThunk,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { CustomButton } from "../../../../utils";
import { WebViewerInstance } from "@pdftron/webviewer";
import "./notice_of_healthcare_exchange.scss";
import PDFReader from "../../../../components/pdf_reader/pdf_reader";
import { healthCareExchangeSliceActions } from "../../../../redux/onboarding_portal/healthcare_exchange/healthcare_exchange_reducer";
import ApiError from "../../../../components/api_error";
import OnboardingReviewForm, {
  IReviewForm,
} from "../../forms/onboarding_review_form/onboarding_review_form";
import { getDateString } from "../../../../variables";

interface Props {
  visible: boolean;
  type: string;
  title: string;
  talentId: string;
  state: IOnboardingDocsReviewStatus;
  successTitle: string;
  onClose: () => void;
  onDissmiss?: () => void;
  onSuccessClose: (type: string, value?: any) => void;
  talentData: ITalent | null;
  today: number;
  currentLoginUserName: string;
  recruitersList: IProfileDropdown[];

}

const NoticeOfHealthcareExchangePopup: React.FunctionComponent<Props> = (
  props
) => {
  const {
    visible,
    type,
    title,
    state,
    talentId,
    onClose,
    onDissmiss,
    onSuccessClose,
    today,
    currentLoginUserName,
    talentData,
    recruitersList,

  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { uploadFile, uploadURL } = useAppSelector((state) =>
    selectHealthCareExchangeState(state)
  );
  const actionRef = useRef<ActionDialogHolderType>(null);
  const instance = useRef<WebViewerInstance>();
  const reviewedByData = recruitersList.filter(
    (recruiter) =>
      state && recruiter.id === state?.notice_of_healthcare_exchange_document?.reviewed_by
  );
  const reviewedBy = reviewedByData
    .map((recruiter) => `${recruiter.first_name} ${recruiter.last_name}`)
    .join(", ");
  const [formState, setFormState] = useState<IReviewForm>({
    name:
      state && talentData
        ? talentData?.first_name + " " + talentData?.last_name
        : "",
    recruiter_completed_review:
      state &&
      state?.notice_of_healthcare_exchange_document?.recruiter_completed_review
        ? "Reviewed"
        : "Not Reviewed",
    reviewed_on_timestamp:
      state &&
      state?.notice_of_healthcare_exchange_document?.reviewed_on_timestamp !==
        null
        ? getDateString(
            state?.notice_of_healthcare_exchange_document
              ?.reviewed_on_timestamp,
            "mm/dd/yyyy"
          )
        : getDateString(today, "mm/dd/yyyy"),
    reviewed_by:
      state &&
      state?.notice_of_healthcare_exchange_document?.reviewed_by !== null
        ? (reviewedBy ?? "")
        : currentLoginUserName,
  });
  useEffect(() => {
    if (uploadURL.loading === LoadingType.succeeded && uploadURL.response) {
      handleUploadFile(uploadURL.response.url);
    }
  }, [uploadURL.loading]);
  const [endReached, setEndReached] = useState<boolean>(state.notice_of_healthcare_exchange_document.recruiter_completed_review ?? false);

  useEffect(() => {
    if (uploadFile.loading === LoadingType.succeeded) {
      onSuccessClose(type);
    }
    return () => {
      if (instance.current) {
        cleanPDFState(instance.current);
      }
      dispatch(healthCareExchangeSliceActions.clearState());
    };
  }, [uploadFile.loading]);

  useEffect(() => {
    if (instance.current && state.notice_of_healthcare_exchange_document.url) {

      const { UI, Core } = instance.current;
      const { documentViewer } = Core;
      documentViewer.addEventListener('pageNumberUpdated', (page) => {
          const pages = documentViewer.getPageCount();
          if (page === pages) setEndReached(page === pages);
      });
      loadPDFDocument(
        instance.current,
        state.notice_of_healthcare_exchange_document.url
      );
    }
  }, [state.notice_of_healthcare_exchange_document.url]);

  function handleOnInstance(value: WebViewerInstance) {
    instance.current = value;
    const { UI, Core } = value;
    const { documentViewer } = Core;
    documentViewer.addEventListener('pageNumberUpdated', (page) => {
        const pages = documentViewer.getPageCount();
        if (page === pages) setEndReached(page === pages);
    });
    instance.current.UI.disableElements(['toolsHeader']);
    loadPDFDocument(value, state.notice_of_healthcare_exchange_document.url);
  }

  function handleClose() {
    closePopup(onClose);
  }

  async function handleUploadFile(url: string) {
    if (instance.current) {
      const { documentViewer, annotationManager } = instance.current.Core;
      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations();
      const data = await doc.getFileData({
        // saves the document with annotations in it
        xfdfString,
      });
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: "application/pdf" });
      dispatch(uploadHealthCareExchangeThunk({ url: url, file: blob }));
    }
  }

  function loadPDFDocument(value: WebViewerInstance, url: string) {
    const { UI, Core } = value;
    const { documentViewer, annotationManager } = Core;
    documentViewer.addEventListener('documentLoaded', () => {
        documentViewer.getAnnotationsLoadedPromise().then(() => {
            // iterate over fields
            const fieldManager = annotationManager.getFieldManager();
            fieldManager.forEachField((field) => {
              field.flags.set("ReadOnly", true);
            });
            // Object.keys(NoticeOfHealthcareExchangeFormFields).forEach(key => {
            //     const doc = NoticeOfHealthcareExchangeFormFields[key];
            //     if(doc.mapTo) {
            //         const field = fieldManager.getField(doc.key);
            //         field.setValue(getValueFromKey(doc.mapTo, {...recruiter, ...agency}));
            //     }

            //     if(doc.type && doc.type === "date") {
            //         const field = fieldManager.getField(doc.key);
            //         field.setValue(getDateString(new Date(), "mm/dd/yyyy"));
            //     }
            // })
        });
    });
    UI.setFitMode(UI.FitMode.FitWidth);
    UI.loadDocument(url);
  }

  function cleanPDFState(value: WebViewerInstance) {
    const { documentViewer } = value.Core;
    documentViewer.removeEventListener("documentLoaded");
    // documentViewer.removeEventListener('fieldChanged');
  }

  const loading =
    uploadURL.loading === LoadingType.pending ||
    uploadFile.loading === LoadingType.pending;
  const error = uploadURL.error || uploadFile.error;

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={"70%"}
      onClose={onClose}
      onDissmiss={onDissmiss}
    >
      <DialogWrapper onClose={handleClose}>
        <div className="nohe-container">
          <div className="nohe-header">
            <FormDialogTilteHeader
              title={title}
              subTitle={t("please_scroll_down_and_fill_out_the_form_below")}
              subTitleStyle={{ fontWeight: "normal" }}
              titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <div className="talent-nohe-content">
            <div className="ob-review-div">
              <OnboardingReviewForm
                handleFieldChange={() => {}}
                formState={formState}
              />
            </div>
            <PDFReader onInstanceChange={handleOnInstance} />
            {error && (
              <div className={"error-section"}>
                <ApiError message={error.message} onClose={handleClearError} />
              </div>
            )}
          </div>
          <div className="nohe-actions">
            <div className="btn-cancel">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("cancel")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => closePopup(onClose)}
              />
            </div>
            <div className="btn-new">
              <CustomButton
                loading={loading}
                textStyle={{
                  textTransform: "capitalize",
                }}
                name={t("save")}
                enable={state && state?.notice_of_healthcare_exchange_document?.recruiter_completed_review ? false : true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => (loading ? undefined : handleSave())}
              />
            </div>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );

  ///Handle clear error
  function handleClearError() {
    dispatch(healthCareExchangeSliceActions.clearState());
  }

  /// handle submit
  function handleSave() {
    dispatch(getUploadHealthCareExchangeUrlThunk({ talent_id: talentId }));
  
  }

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }
};

export default NoticeOfHealthcareExchangePopup;
