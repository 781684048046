import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../apis";
import {ContactsAPI, contactsByCompanyDepartment} from "../../../apis/contacts_api";
import { AppError, IBusinessSector, IContactDropdownItem} from "../../../interfaces";
import { catchRequestError } from "../../../utils";

export const getContactsDropdownThunk = createAsyncThunk<
    Array<IContactDropdownItem>,
    {company_id: string, department_id?: string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@selectors/contacts/get',
    async (query, thunkAPI) => {
        try {
            const res = await ContactsAPI.getContactsDropdown(query);
            return res.data as IContactDropdownItem[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);