import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectCreateState, selectState, selectUpdateState, selectUploadFilesState, selectUploadURLState } from "./manage_agency_reducer";

const select = (state: IRootState) => state.adminCenter.agency;
export const selectManageAgencyState = createSelector(select, selectState);
export const selectManageAgencyCreateState = createSelector(selectManageAgencyState, selectCreateState);
export const selectManageAgencyUpdateState = createSelector(selectManageAgencyState, selectUpdateState);
export const selectManageAgencyUploadURLState = createSelector(selectManageAgencyState, selectUploadURLState);
export const selectManageAgencyUploadFilesState = createSelector(selectManageAgencyState, selectUploadFilesState);
