import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmployeePortalAPI  } from "../../../../apis";
import { AppError, IEmployee } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getEmployeeList = createAsyncThunk<
    Array<IEmployee>,
    string | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee-portal/universal-directory/all-employees/get',
    async (id, thunkAPI) => {
        try {
            // const res = await EmployeePortalAPI.getAllEmployees(id);
            // if(typeof res.data === 'string') return [];
            // return res.data as IEmployee[];
            return [];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);