import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getPaycheckPDFURLThunk, getPaymentRegisterList, getReportDownloadURL, getReportStartEndDate, selectePaycheckInvoiceState, selectPaymentRegisterListState, selectReportsDownloadState, selectReportStartEndDateState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { convertDateToTimeStamp, dateType, getDateFromTimeStamp, getDateString } from "../../../../../variables";
import { LoadingType, THEME } from "../../../../../enums";
import { IRCPaymentRegisterPaychecks, IRCPaymentRegister } from "../../../../../interfaces/report_center_payroll_tax";
import { AppRoutes } from "../../../../../routes";
import DatePicker from "react-datepicker";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import SearchBar from "../../../../../components/search_bar";
import { currencyConversion, CustomButton } from "../../../../../utils";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { paymentRegisterListSliceActions } from "../../../../../redux/report_center/payroll_tax/payment_register/payment_register_reducer";
import UrlBuilder from "../../../../../apis/url_builder";
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import './payment_register.scss'
import { paycheckInvoiceSliceActions } from "../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
    { title: "employee", code: "employee_name" },
    { title: "check_date", code: "check_date" },
    { title: "check_number", code: "check_number" },
    { title: "account_type", code: "account_type" },
    { title: "financial_institution", code: "bank_name" },
    { title: "routing_number_pr", code: "routing_number" },
    { title: "account_number_pr", code: "account_number" },
    { title: "net_amount", code: "net_amount" },
    { title: "payment_type", code: "payment_type" },
    { title: "ach_sent_date", code: "ach_sent_date" },
    { title: "date_paycard_loaded", code: "date_pay_card_loaded" },
];

const PaymentRegister: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const paymentRegisterListState = useAppSelector((state) => selectPaymentRegisterListState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));

    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [isCheckDate, setIsCheckDate] = useState<boolean>(false);
    const [paymentRegisterList, setPaymentRegisterList] = useState<IRCPaymentRegister | null>(null);
    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>()
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const paycheckPDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }

    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0)
            || ((formState?.available_from !== 0 && formState?.available_to !== 0) || (formState?.available_from === 0 && formState?.available_to === 0) && isCheckDate)) {
            getPRegisterList();
            setDatePickerOpen(false)
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getPRegisterList();
            setDatePickerOpen(true);
        }
    }, [formState?.available_from, formState?.available_to, isCheckDate]);

    useEffect(() => {
        if (paymentRegisterListState && paymentRegisterListState?.loading === LoadingType.succeeded) {
            setPaymentRegisterList(paymentRegisterListState?.response);
        }
    }, [paymentRegisterListState?.loading]);

    useEffect(() => {
        if (paycheckPDFURLState.paycheck.loading === LoadingType.succeeded && paycheckPDFURLState.paycheck.response !== "") {
            window.open(paycheckPDFURLState.paycheck.response, "_blank");
            dispatch(paycheckInvoiceSliceActions.clearPaycheckState());
        }
    }, [paycheckPDFURLState.paycheck.loading, paycheckPDFURLState.paycheck.response]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);


    const getPRegisterList = () => {
        if (formState) {
            dispatch(getPaymentRegisterList({ start_date: formState?.available_from!, end_date: formState?.available_to!, is_check_date: isCheckDate! }))
        }
    }

    const handleViewCheck = (pay_check_id: string) => {
        dispatch(getPaycheckPDFURLThunk(pay_check_id));
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
        dispatch(paymentRegisterListSliceActions.clearPaymentRegisterListState());
    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function handleTalentSelect(value: IRCPaymentRegisterPaychecks) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: value.employee_name,
            },
        });
        return;
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.paymentRegister,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    is_check_date: isCheckDate ? isCheckDate : false,
                    download: true
                }));
        }
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1.2;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1.2;
        return 1;
    }

    const showSpinner = () => paymentRegisterListState?.loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: IRCPaymentRegisterPaychecks[] | undefined = paymentRegisterList?.paychecks;

        if (paymentRegisterList && Array.isArray(paymentRegisterList?.paychecks) && sortedField != null) {
            list = [...paymentRegisterList?.paychecks].sort((a, b) => {
                const valueA =
                    a[sortedField] !== null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] !== null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (paymentRegisterListState?.error != null && paymentRegisterList?.paychecks?.length === 0) {
            return <TableErrorHandler error={paymentRegisterListState?.error} onRefresh={getPRegisterList} />;
        }

        if (paymentRegisterList?.paychecks?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No Data found"} onClick={getPRegisterList} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <div className="pr-table">
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList()?.map((doc) => {
                            return (
                                <tr key={doc.id}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}
                                        isButton={true}
                                        onClick={() => handleTalentSelect(doc)}
                                    >
                                        <span>{doc.employee_name}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                        <span>{getDateString(doc.check_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}
                                        isButton={true}
                                        onClick={() => handleViewCheck(doc.id)}
                                    >
                                        <span>{doc.check_number}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        <span>{doc.account_type}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                        <span>{doc.bank_name}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <span>{doc.routing_number}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                        <span>{doc.account_number}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                        <span>{currencyConversion(doc.net_amount)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                        <span>{doc.payment_type}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                        <span>{getDateString(doc.ach_sent_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(10) }}>
                                        <span>{getDateString(doc.date_pay_card_loaded)}</span>
                                    </TableData>
                                </tr>
                            )
                        })}
                    </SortableTable>
                </div>
            );
        }
    };

    return (
        <div className="payment-register-container">
            <div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="payment-register-header">
                <div className="header-section-one">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                            />
                        </div>
                    </div>
                    <div className="select-type">
                        <CustomFormSelect
                            name={t("date_type")}
                            list={dateType.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={setIsCheckDate}
                            value={isCheckDate}
                            placeholder={t('select')}
                            label={t('date_type')}
                        />
                    </div>
                </div>
                <div className="header-section-two">
                    <div className="total-count-action">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                    <div className="fs-btn dwn-margin">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={getFilteredList()?.length !== 0 && true}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    <div>
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                        />
                    </div>
                </div>
            </div>

            <div className="table-container">
                <div className="table-header-title">
                    <span>{t("payment_register")}</span>
                </div>
                {getTable()}
            </div>
            {paymentRegisterListState.loading === LoadingType.succeeded &&
                <div className="total-counts-card">
                    <div className="total-title">
                        Totals
                    </div>
                    <div className="total-count">
                        {currencyConversion(paymentRegisterList?.paychecks && paymentRegisterList?.paychecks.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                            return prev + +current.net_amount
                        }, 0) : 0)}
                    </div>
                </div>
            }
        </div>
    );
}

export default PaymentRegister;