import { useEffect, useMemo, useState } from "react";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { currencyConversion, formatNumber } from "../../../../utils";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../../components/search_bar";

const tableheaderList = [
    { title: "company_name", code: "company_name" },
    { title: "department_name", code: "department_name" },
    { title: "regular_time_wages", code: "regular_time_wages" },
    { title: "over_time_wages", code: "over_time_wages" },
    { title: "double_time_wages", code: "double_time_wages" },
    { title: "premium_rate", code: "premium_rate" },
    { title: "regular_time_premium", code: "regular_time_premium" },
    { title: "over_time_premium", code: "over_time_premium" },
    { title: "total_premium", code: "total_premium" },
];
const WorkCompRecordTable = ({ record }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const handleSortFieldChange = (value: string) => {
        if (sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    };

    const getFilteredList = (wcCodeRecord) => {
        let list = wcCodeRecord;

        if (sortedField != null) {
            list = [...wcCodeRecord].sort((a, b) => {
                const valueA =
                    a[sortedField] !== null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] !== null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    };
    const filteredList = getFilteredList(record.emp_records);
    const totalRegularTimeWages = useMemo(() =>
        filteredList.length > 0
            ? (filteredList.reduce((prev, current) => prev + +current.regular_time_wages, 0))
            : 0,
        [filteredList]
    );
    const totalOverTimeWages = useMemo(() =>
        filteredList.length > 0
            ? (filteredList.reduce((prev, current) => prev + +current.over_time_wages, 0))
            : 0,
        [filteredList]
    );
    const totalDoubleTimeWages = useMemo(() =>
        filteredList.length > 0
            ? (filteredList.reduce((prev, current) => prev + +current.double_time_wages, 0))
            : 0,
        [filteredList]
    );
    const totalRegularTimePremium = useMemo(() =>
        filteredList.length > 0
            ? (filteredList.reduce((prev, current) => prev + +current.regular_time_premium, 0))
            : 0,
        [filteredList]
    );
    const totalOverTimePremium = useMemo(() =>
        filteredList.length > 0
            ? (filteredList.reduce((prev, current) => prev + +current.over_time_premium, 0))
            : 0,
        [filteredList]
    );
    const totalPremium = useMemo(() =>
        filteredList.length > 0
            ? (filteredList.reduce((prev, current) => prev + +current.total_premium, 0))
            : 0,
        [filteredList]
    );

    // useEffect(() => {
    //     const subtotals = {
    //         regularTimeWages: totalRegularTimeWages,
    //         overTimeWages: totalOverTimeWages,
    //         doubleTimeWages: totalDoubleTimeWages,
    //         regularTimePremium: totalRegularTimePremium,
    //         overTimePremium: totalOverTimePremium,
    //         totalPremium: totalPremium
    //     };
    //     updateTotals(subtotals);
    // }, [search, getFilteredList(record.emp_records).length]);
    return (
        <div className='rc-wc-div'>
            <div className='rc-wc-div-header'>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('wc_code')}</th>
                                <th>{t('base_rate')}</th>
                                <th>{t('wc_code_desc')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{record.work_comp_code}</td>
                                <td>{record.base_rate ? currencyConversion(record.base_rate) : "$0.00"}</td>
                                <td style={{ wordWrap: "break-word" }}>{record.work_comp_description}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <SearchBar
                        value={search}
                        onChange={setSearch}
                        onSearch={() => { }}
                    />
                </div>
            </div>
            <div className='rc-wc-div-table'>

                <SortableTable
                    headerList={tableheaderList}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {getFilteredList(record.emp_records).length > 0 ?
                        (<>
                            {getFilteredList(record.emp_records).map((doc, index) => (
                                <tr key={"branch_list" + index} >
                                    <TableData customStyle={{ flex: getFlexNumber(0), wordWrap: "break-word" }}><span>{doc.company_name}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1), wordWrap: "break-word" }}><span>{doc.department_name}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2), wordWrap: "break-word" }}><span>{doc.regular_time_wages ? currencyConversion(doc.regular_time_wages) : "$0.00"}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3), wordWrap: "break-word" }}><span>{doc.over_time_wages ? currencyConversion(doc.over_time_wages) : "$0.00"}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4), wordWrap: "break-word" }}><span>{doc.double_time_wages ? currencyConversion(doc.double_time_wages) : "$0.00"}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5), wordWrap: "break-word" }}><span>{doc.premium_rate ? formatNumber(doc.premium_rate).toString() + "%" : ""}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(6), wordWrap: "break-word" }}><span>{doc.regular_time_premium ? currencyConversion(doc.regular_time_premium) : "$0.00"}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(7), wordWrap: "break-word" }}><span>{doc.over_time_premium ? currencyConversion(doc.over_time_premium) : "$0.00"}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(8), wordWrap: "break-word" }}><span>{doc.total_premium ? currencyConversion(doc.total_premium) : "$0.00"}</span></TableData>
                                </tr>
                            ))}
                            <tr className="total-row">
                                <TableData customStyle={{ flex: getFlexNumber(0), wordWrap: "break-word" }}>
                                    <span>{t("sub_total")}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(1), wordWrap: "break-word" }}>
                                    <span></span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(2), wordWrap: "break-word" }}>
                                    <span>{currencyConversion(totalRegularTimeWages)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(3), wordWrap: "break-word" }}>
                                    <span>{currencyConversion(totalOverTimeWages)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(4), wordWrap: "break-word" }}>
                                    <span>{currencyConversion(totalDoubleTimeWages)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(5), wordWrap: "break-word" }}>
                                    <span>{""}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(6), wordWrap: "break-word" }}>
                                    <span>{currencyConversion(totalRegularTimePremium)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(7), wordWrap: "break-word" }}>
                                    <span>{currencyConversion(totalOverTimePremium)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(8), wordWrap: "break-word" }}>
                                    <span>{currencyConversion(totalPremium)}</span>
                                </TableData>
                            </tr>
                        </>)
                        :
                        <>
                            <tr>
                                <TableData customStyle={{ flex: getFlexNumber(20), wordWrap: "break-word" }} align="center"> <div className="na_div">
                                    {t("no_data_available")}
                                </div>
                                </TableData>
                            </tr>
                            <tr className="total-row">
                                <TableData customStyle={{ flex: getFlexNumber(0), wordWrap: "break-word" }}><span>{t("sub_total")}</span></TableData>
                                <TableData customStyle={{ flex: getFlexNumber(1), wordWrap: "break-word" }}><span></span></TableData>
                                <TableData customStyle={{ flex: getFlexNumber(8), wordWrap: "break-word" }}><span>{"$0.00"}</span></TableData>
                                <TableData customStyle={{ flex: getFlexNumber(8), wordWrap: "break-word" }}><span>{"$0.00"}</span></TableData>
                                <TableData customStyle={{ flex: getFlexNumber(8), wordWrap: "break-word" }}><span>{"$0.00"}</span></TableData>
                                <TableData customStyle={{ flex: getFlexNumber(8), wordWrap: "break-word" }}><span>{""}</span></TableData>
                                <TableData customStyle={{ flex: getFlexNumber(8), wordWrap: "break-word" }}><span>{"$0.00"}</span></TableData>
                                <TableData customStyle={{ flex: getFlexNumber(8), wordWrap: "break-word" }}><span>{"$0.00"}</span></TableData>
                                <TableData customStyle={{ flex: getFlexNumber(8), wordWrap: "break-word" }}><span>{"$0.00"}</span></TableData>
                            </tr>
                        </>

                    }
                </SortableTable>
            </div>
        </div>
    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 5;

        return 1;
    }
};

export default WorkCompRecordTable;
