import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../components/api_error";
import DialogWrapper, { FormDialogTilteHeader } from "../../../components/dialog_wrapper/dialog_wrapper";
import DropZone from "../../../components/file_upload/file_upload_page";
import { LoadingType, THEME } from "../../../enums";
import { UploadIcon } from "../../../icons";
import { AppError } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { CustomButton } from "../../../utils";
import { downloadSampleFileUploadUrl, getImpDataFileUploadUrl, selectSampleFileDownloadUrlState } from "../../../redux/upload_files";
import "./import_data_file.scss";
import { importUrlActions } from "../../../redux/upload_files/import_data_file_reducer";

interface Props {
    visible: boolean;
    title: string;
    baseUrl: string;
    importType: string;
    loading: LoadingType;
    error: AppError | null | undefined;
    onClose: () => void;
    onClearError: () => void;
    handleSnackBar: () => void;
}

const UploadRecordPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        baseUrl,
        importType,
        onClose,
        onClearError,
        handleSnackBar,
        error,
        loading,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const sampleFileDownloadState = useAppSelector((state) => selectSampleFileDownloadUrlState(state));
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [selectedFile, setSelectedFile] = useState<File[] | null>(null);

    useEffect(() => {
        if (sampleFileDownloadState?.loading === LoadingType.succeeded) {
            let aTag = document.createElement("a");
            aTag.setAttribute("href", sampleFileDownloadState.response?.url!);
            aTag.setAttribute("target", "_blank");
            aTag.click();
            dispatch(importUrlActions.clearDownloadUrlState());
        }
        return () => {}
    }, [sampleFileDownloadState?.loading]);

    const handleUploadUrlSubmit = () => {
        if (selectedFile) {
            dispatch(getImpDataFileUploadUrl({
                baseUrl: baseUrl,
                file: selectedFile && selectedFile[0]
            }))
        }
        handleSnackBar()
    }

    const handleDownload = () => {
        dispatch(downloadSampleFileUploadUrl(importType))
    }


    const acceptedFileFormats = {
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        "text/csv": [".csv"]
    };
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"fit-content"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={() => {
                onClose();
                onClearError();
            }}>
                <div className="import-data-container">
                    <div className="import-data-header">
                        <FormDialogTilteHeader
                            title={t(title)}
                            titleIcon={<UploadIcon width={"1.25vw"} height={"1.25vw"} />}
                            isDownloadSampleBtn={true}
                            downloadBtnTitle={t("download_sample")}
                            onDownload={handleDownload}
                        />
                    </div>
                    <div>
                        <div className="import-data-content">
                            <div>
                                <DropZone maxFiles={1} handleFiles={setSelectedFile} maxSize={26214400} accept={acceptedFileFormats} />
                            </div>
                        </div>
                        {error &&
                            <div className="error-holder">
                                <ApiError message={error.message} onClose={onClearError} />
                            </div>
                        }
                    </div>
                </div>
                <div className="import-data-actions">
                    <div className="btn-cancel" style={{ marginRight: "1em" }}>
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("cancel")}
                            enable={true}
                            backgroundColor={THEME.toggleDisableColor}
                            onClick={() => {
                                onClose();
                                onClearError();
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={loading === LoadingType.pending}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("upload")}
                            enable={selectedFile! && true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => handleUploadUrlSubmit()}
                        />
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder >
    );
}

export default UploadRecordPopup;