import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./required_equipment_list_reducer";

const select = (state: IRootState) => state.selectors.requiredEquipmentList;
export const selectRequiredEquipmentListState = createSelector(select, selectState);
export const selectRequiredEquipmentList = createSelector(
    select,
    selectAll
);
export const selectRequiredEquipmentListEntities = createSelector(
    select,
    selectEntities
);