import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { RouteComponentProps } from 'react-router-dom';
import { DateInput } from '../../../../../components/date_input/date_input';
import SortableTable, { TableData } from '../../../../../components/sortable_table/sortable_table';
import SSNHolder from '../../../../../components/ssn_holder/ssn_holder';
import TableEmpty, { TableErrorHandler, TableFilterEmpty, TableLoading } from '../../../../../components/table_empty/table_empty';
import { LoadingType, TALENT_STATUS, THEME } from '../../../../../enums';
import { LockOpenIcon, TalentActiveStatusIcon } from '../../../../../icons';
import { ITalentAvailableCandidates } from '../../../../../interfaces';
import InDevelopment from '../../../../../others/in_development';
import {
    getTalentAvailableCandidates,
    postSnackbarMessage,
    selectTalentAvailableCandidatesList,
    selectTalentAvailableCandidatesState,
    useAppDispatch,
    useAppSelector,
    selectProfileState,
    selectTextEmAllState,
    getTEADraftBroadCast
} from '../../../../../redux/store';
import { talentRequestSSNActions } from '../../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer';
import { AppRoutes } from '../../../../../routes';
import { CustomButton, CustomCheckBox, getContactTypeIcon, getTableAccessIcon, getTextEmAllIcon } from '../../../../../utils';
import { convertDateToTimeStamp, getDaysAgoWithDateAMPM } from '../../../../../variables';
import ViewSSNPopup from '../../../../talent/popups/view_ssn/view_ssn';
import './universal_directory.scss';
import { textEmAllSliceActions } from '../../../../../redux/textEmAll/text_Em_All_reducer';
import { TEADraftBroadCast } from '../../../../../interfaces/text_em_all';
import BroadcastToTalentPopup from '../../../../../components/broadcast_talent/broadcast_talent';
import BroadcastToTalentErrorPopup from '../../../../../components/broadcast_talent/broadcast_talent_error';
import SearchBar from '../../../../../components/search_bar';

interface Props extends RouteComponentProps<any> { }


const AvailableCandidatesUniversalDirectoryPage: React.FunctionComponent<Props> = (props) => {
    const dispatch = useAppDispatch();
    const availableCandidatesListState = useAppSelector((state) => selectTalentAvailableCandidatesState(state));
    const availableCandidatesList = useAppSelector((state) => selectTalentAvailableCandidatesList(state));
    const profileState = useAppSelector((state) => selectProfileState(state));
    const canSendText =
        profileState?.profile?.texting &&
        profileState?.profile?.text_em_all_token !== null;
    const [showSSNPopup, setShowSSNPopup] = useState<{ talentId: string, visible: boolean } | null>(null);
    const [fromDate, setFromDate]: any = useState(convertDateToTimeStamp(new Date(), true));
    const [toDate, setToDate]: any = useState(convertDateToTimeStamp(new Date(), true));
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const { history, location } = props;
    const { t } = useTranslation();

    useEffect(() => {
        getAvailableCandidatesList();
        return () => {
        }
    }, [])

    useEffect(() => {
        if (availableCandidatesListState.error != null && availableCandidatesList.length >= 0) {
            dispatch(postSnackbarMessage(availableCandidatesListState.error.message));
        }
        return () => {
        }
    }, [availableCandidatesListState.error])

    function getAvailableCandidatesList() {
        dispatch(getTalentAvailableCandidates({ from_date: fromDate, to_date: toDate }));
    }
    const textEmAllState = useAppSelector((state) => selectTextEmAllState(state));

    const [broadcastTalent, setBroadcastTalent] = useState<boolean>(false);
    const [broadcastTalentError, setBroadcastTalentError] =
        useState<boolean>(false);

    const [selectedItems, setSelectedItems] = useState<
        Map<string, { id: string; name: string }>
    >(new Map());

    const handleMarkItem = (value: ITalentAvailableCandidates) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value.id)) {
            newSet.delete(value.id);
        } else {
            newSet.set(value.id, {
                id: value.id,
                name: value.first_name + " " + value.last_name,
            });
        }
        setSelectedItems(newSet);
    };
    const [formState] = useState<TEADraftBroadCast>({
        modified_by: "",
        created_by: "",
        modified_date: 0,
        talent_ids: [],
    });
    const [showMsg, setShowMsg] = useState<boolean>(false);
    const [showErrorMsg, setShowErrorMsg] = useState<string>("");
    const handleMarkAllItem = () => {
        const newSet = new Map<string, { id: string; name: string }>();
        if (selectedItems.size === getFilteredList().length) {
            setSelectedItems(newSet);
        } else {
            getFilteredList().forEach((doc) =>
                newSet.set(doc.id, {
                    id: doc.id,
                    name: doc.first_name + " " + doc.last_name,
                })
            );
            setSelectedItems(newSet);
        }
    };
    const handleBroadCastTalent = (forceSend?:boolean) => {
        if (selectedItems.size !== 0) {
            const talentids_list = Array.from(selectedItems.keys());
            dispatch(
                getTEADraftBroadCast({ forceSend: forceSend, data: { ...formState, talent_ids: talentids_list } })
            );
            
            setShowMsg(false);
        } else {
            setShowMsg(true);
            setShowErrorMsg(t("please_select_talent_to_broadcast"));
            setTimeout(() => setShowErrorMsg(""), 5000);
        }
    };
    useEffect(() => {
        dispatch(textEmAllSliceActions.clearState());
        return () => { };
    }, []);
    useEffect(() => {
        if (
            textEmAllState &&
            textEmAllState?.error !== null &&
            textEmAllState?.loading === LoadingType.failed
        ) {
            setBroadcastTalentError(true);
        }
        if (
            textEmAllState &&
            textEmAllState?.error === null &&
            textEmAllState?.loading === LoadingType.succeeded
        ) {
            setBroadcastTalent(true);
        }
        if (
            textEmAllState &&
            textEmAllState?.error === null &&
            textEmAllState?.loading === LoadingType.idle
        ) {
            setBroadcastTalent(false);
            setBroadcastTalentError(false);

        }
    }, [textEmAllState, textEmAllState.error, textEmAllState.loading]);

    function getFilteredList(): ITalentAvailableCandidates[] {
        let sortedList: ITalentAvailableCandidates[] | undefined;
        if (sortedField != null) {
            sortedList = [...availableCandidatesList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (sortedList ?? availableCandidatesList).filter(doc => {
            const str = search.trim().toLowerCase();
            const nameFilter = `${doc.first_name ?? ''} ${doc.middle_name ?? ''} ${doc.last_name ?? ''}`.trim().toLowerCase().includes(str);
            const cityFilter = doc.city ? doc.city.trim().toLowerCase().includes(str) : false;
            const stateFilter = doc.state ? doc.state.trim().toLowerCase().includes(str) : false;
            const zipcodeFilter = doc.zip_code ? doc.zip_code.trim().toLowerCase().includes(str) : false;
            const addressFilter = doc.street_address ? doc.street_address.trim().toLowerCase().includes(str) : false;
            const onAssignmentFilter = doc.on_assignment_company ? doc.on_assignment_company.trim().toLowerCase().includes(str) : false;
            const jobTitleFilter = doc.title ? doc.title.trim().toLowerCase().includes(str) : false;
            return nameFilter || cityFilter || stateFilter || zipcodeFilter || addressFilter || onAssignmentFilter || jobTitleFilter;
        });
    }


    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            // setSortedField(null);
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    }

    return (
        <div className="tlnt-ac-ud-container">
            <div className="tlnt-ac-ud-table-header">
                <div className="tlnt-ac-ud-table-header-options">
                    <div className="tlnt-ac-ud-table-search-bar">
                        <div style={{ marginRight: 5 }}>
                            <DateInput
                                id={"from_date"}
                                onChange={(key, value) => {
                                    setFromDate(value)
                                    dispatch(getTalentAvailableCandidates({ from_date: value, to_date: toDate }))
                                }}
                                placeholder={""}
                                value={fromDate}
                                setHoursToZero={true}
                                label={"From"}
                            />
                        </div>
                        <DateInput
                            id={"to_date"}
                            onChange={(key, value) => {
                                setToDate(value)
                                dispatch(getTalentAvailableCandidates({ from_date: fromDate, to_date: value }))
                            }}
                            placeholder={""}
                            value={toDate}
                            setHoursToZero={true}
                            label={"To"}
                        />
                    </div>
                    <div className="total-count">
                        <span>{`${t('total_count')}: `} <span className="total-count-number">{availableCandidatesList.length}</span> </span>
                    </div>
                </div>
                <div className="tlnt-ud-brdcast-btn">
                    <div className='tlnt-ud-search'>
                        <SearchBar
                            value={search}
                            onChange={(value) => setSearch(value)}
                            onSearch={() => { }}
                        />
                    </div>
                    <div>
                        <CustomButton
                            leftIcon={getTextEmAllIcon()}
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("broadcast_to_talent")}
                            enable={canSendText ? true : false}
                            onClick={handleBroadCastTalent}
                        />
                    </div>
                    {showMsg && (
                        <span className="select_talent_msg">{showErrorMsg}</span>
                    )}
                </div>
            </div>
            <div className="tlnt-ac-ud-table">
                {getAvailableCandidatesTableList()}
            </div>
            {showSSNPopup && (
                <Portal>
                    <ViewSSNPopup
                        visible={showSSNPopup.visible}
                        title={t('social_security_number')}
                        talentId={showSSNPopup.talentId}
                        onClose={() => setShowSSNPopup(null)}
                        onDissmiss={() => setShowSSNPopup(null)}
                        onSuccessClose={handleOnSuccessSSNClose}
                    />
                </Portal>
            )}
            {canSendText &&
                broadcastTalentError &&
                textEmAllState &&
                textEmAllState?.error !== null && (
                    <Portal>
                        <BroadcastToTalentErrorPopup
                            error={textEmAllState?.error}
                            onClose={() => {
                                setBroadcastTalentError(false);
                                dispatch(textEmAllSliceActions.clearState());
                            }}
                            visible={broadcastTalentError}
                            title={t("something_went_wrong")}
                            loading={textEmAllState?.loading === LoadingType.pending}
                            onForceSend={handleBroadCastTalent}
                        />
                    </Portal>
                )}
            {canSendText &&
                broadcastTalent && (
                    <Portal>
                        <BroadcastToTalentPopup
                            visible={broadcastTalent}
                            title={""}
                            successTitle={""}
                            responseURL={textEmAllState?.response}
                            loading={textEmAllState?.loading === LoadingType.pending}
                            onClose={() => {
                                setBroadcastTalent(false);
                                dispatch(textEmAllSliceActions.clearState());
                            }}
                            onSuccessClose={() => { }}
                        />
                    </Portal>
                )}

        </div>
    );

    function handleUserSelect(value: ITalentAvailableCandidates) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.id}`,
            state: {
                id: value.id,
                name: `${value.first_name ?? ''} ${value.middle_name ?? ''} ${value.last_name ?? ''}`,
            }
        })
        return;


    }

    function handleCompanySelect(value: ITalentAvailableCandidates) {
        props.history.push({
          pathname: `${AppRoutes.companiesDetailsPage}/${value.on_assignment_company_id}`,
          state: {
            id: value.on_assignment_company_id,
            name: value.on_assignment_company,
          },
        });
        return;
      }

    /// Talent table list
    function getAvailableCandidatesTableList() {
        const SelectAllCheckBox = () => {
            return (
                <CustomCheckBox
                    name={""}
                    title={""}
                    checked={selectedItems.size === getFilteredList().length}
                    onClick={handleMarkAllItem}
                />
            );
        };
        const tableHeader = [
            { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },

            { title: 'access_talent', code: 'access_talent' },
            { title: 'name', code: 'first_name' },
            { title: 'job_title', code: 'title' },
            { title: 'on_assignment', code: 'on_assignment_company' },
            { title: 'status', code: 'status' },
            { title: 'address', code: 'street_address' },
            { title: 'city', code: 'city' },
            { title: 'state', code: 'state' },
            { title: 'zip_code', code: 'zip_code' },
            { title: 'ssn_hash', code: 'last_4_digits_of_ssn' },
            { title: 'last_contact', code: 'last_contact' },
            { title: 'contact', code: 'preferred_contact_method' },
            // { title: 'recruiter', code: 'recruiter' },
        ];
        if (availableCandidatesListState.loading === LoadingType.pending) {
            return (<TableLoading />);
        }
        if (availableCandidatesListState.error != null && availableCandidatesList.length === 0) {
            return (
                <TableErrorHandler error={availableCandidatesListState.error} onRefresh={getAvailableCandidatesList} />
            );
        }
        if (availableCandidatesList.length === 0) {
            return (
                <TableEmpty title={t('no_data_found')} onClick={() => getAvailableCandidatesList()} />
            );
        }
        if (getFilteredList().length === 0) {
            return (
                <TableFilterEmpty title={t('no_data_found')} />
            );
        }
        return (
            <SortableTable
                headerList={tableHeader}
                sortedField={sortedField}
                onSortChange={handleSortFieldChange}
                flexNumber={getFlexNumber}
                isAsc={sortDirection}
            >
                {getFilteredList().map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                <CustomCheckBox
                                    name={""}
                                    title={""}
                                    checked={selectedItems.has(doc.id)}
                                    onClick={() => handleMarkItem(doc)}
                                />
                            </TableData>
                            <TableData customStyle={{ flex: getFlexNumber(1) }}>{getTableAccessIcon(() => handleUserSelect(doc))}</TableData>
                            <TableData customStyle={{ flex: getFlexNumber(2) }} isButton={true} onClick={() => handleUserSelect(doc)} prefix={getTalentStatusIcon(doc)}><span>{`${doc.first_name ?? ''} ${doc.middle_name ?? ''} ${doc.last_name ?? ''}`}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{doc.title}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(4) }} isButton={doc.on_assignment_company ? true : false} onClick={() => handleCompanySelect(doc)}><span>{doc.on_assignment_company ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(5) }}><span>{doc.status ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(6) }}><span>{doc.street_address}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(7) }}><span>{doc.city ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(8) }}><span>{doc.state ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(9) }}><span>{doc.zip_code ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(10) }}>{doc.last_4_digits_of_ssn ? getSSNInfo(doc.last_4_digits_of_ssn, doc.id) : ''}</TableData>
                            <TableData customStyle={{ flex: getFlexNumber(11) }}><span>{doc.last_engagement_date ? getDaysAgoWithDateAMPM(t, doc.last_engagement_date) : ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(12) }}>{getPrefferedContact(doc.preferred_contact_method)}</TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }
    function talentStatus(status: string) {
        if (status === TALENT_STATUS.active) return (<span style={{ color: THEME.buttonColor17 }}>{t('active')}</span>);
        else if (status === TALENT_STATUS.inactive) return (<span style={{ color: "#b1b1b1" }}>{t('in_active')}</span>);
        else return (<span>{''}</span>);
    }

    function getPrefferedContact(value: string) {
        return (
            <div className="pref-contact-icon">
                {getContactTypeIcon(value, "#00A4DC")}
            </div>
        );

    }
    function getSSNInfo(value: string, id: string) {
        return (
            // <div className="ssn-holder">
            //     <div className="ssn-number">
            //         <span>{value}</span>
            //     </div>
            //     <button className="loc-open-icon">
            //         <LockOpenIcon width={"100%"} height={"100%"} style={{ color: '#00A4DC' }} />
            //     </button>
            // </div>
            <SSNHolder
                value={value}
                iconColor={THEME.tableRowTextColor}
                onClick={() => handleOnSSNView(id)}
                showFormat={false}
            />
        )
    }
    function handleOnSSNView(id: string) {
        dispatch(talentRequestSSNActions.initialiseRequestSSN(id));
        if (profileState.profile && profileState.profile.show_ssn) {
            setShowSSNPopup({ visible: true, talentId: id });
        } else {
            dispatch(postSnackbarMessage(t('ssn_access_is_disabled')));
        }

    }
    function handleOnSuccessSSNClose() {
        setShowSSNPopup(null);
        // dispatch(talentAvailableDatesActions.clearSingleTalentAvailableDatesManageState(params.id));
    }

    function getTalentStatusIcon(doc: ITalentAvailableCandidates) {
        return (
            <div className="status-icon">
                <TalentActiveStatusIcon
                    width={"100%"}
                    height={"100%"}
                    style={{ color: (doc.on_assignment_company != null) ? THEME.buttonColor13 : THEME.statusDisabledColor }}
                />
            </div>
        )
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 0.3;
        if (value === 1) return 1.2;
        if (value === 2) return 2;
        if (value === 3) return 2;
        if (value === 4) return 1.7;
        if (value === 5) return 1;
        if (value === 6) return 2;
        if (value === 7) return 1.2;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1;
        if (value === 11) return 2;
        if (value === 12) return 1.3;
        return 1;
    }

}

// interface TableHeaderOptionsProps {
//     totalCount: number,
//     fromDate: number,
//     toDate: number,
//     onFromDateChange: (key: string, value: number) => void,
//     onToDateChange: (key: string, value: number) => void
// }

// const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
//     const { t } = useTranslation();
//     return (
//         <div className="tlnt-ac-ud-table-header-options">
//             <div>
//                 <div className="tlnt-ac-ud-table-search-bar">
//                     <div style={{ marginRight: 5 }}>
//                         <DateInput
//                             id={"from_date"}
//                             onChange={props.onFromDateChange}
//                             placeholder={""}
//                             value={props.fromDate}
//                             setHoursToZero={true}
//                             label={"From"}
//                         />
//                     </div>
//                     <DateInput
//                         id={"to_date"}
//                         onChange={props.onToDateChange}
//                         placeholder={""}
//                         value={props.toDate}
//                         setHoursToZero={true}
//                         label={"To"}
//                     />
//                 </div>
//                 <div className="total-count">
//                     <span>{`${t('total_count')}: `} <span className="total-count-number">{props.totalCount}</span> </span>
//                 </div>
//             </div>
//             <div className="tlnt-ud-brdcast-btn">
//                 <CustomButton
//                     leftIcon={getTextEmAllIcon()}
//                     loading={false}
//                     textStyle={{ textTransform: "capitalize" }}
//                     name={t("broadcast_to_talent")}
//                     enable={canSendText ? true : false}
//                     onClick={handleBroadCastTalent}
//                 />
//                 {showMsg && (
//                     <span className="select_talent_msg">{showErrorMsg}</span>
//                 )}
//             </div>
//         </div>
//     );
// }


export default AvailableCandidatesUniversalDirectoryPage;