import { createEntityAdapter, createSlice, EntityAdapter, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, ITalentClientCompanyInterview, ITalentInHouseInterview } from "../../../../../interfaces";
import * as thunk from "./interviews_to_client_companies_thunk";

interface AddState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: AddState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

interface UpdateState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialUpdateState: UpdateState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

export interface SingleTalentInterviewsToClientCompaniesState {
    data: {
        [key: string]: ITalentClientCompanyInterview,
    },
    error?: AppError | null,
    loading: LoadingType,
    add: AddState,
    update: UpdateState,
}


export interface InterviewsToClientCompaniesState {
    [key: string]: SingleTalentInterviewsToClientCompaniesState,
}


const initialState: InterviewsToClientCompaniesState = {}


export const interviewsToClientCompaniesSlice = createSlice({
    name: 'interviewsToClientCompanies',
    initialState: initialState,
    reducers: {
        initializeState(state, action: PayloadAction<string>){
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    loading: LoadingType.idle,
                    error: null,
                    add: { ...initialAddState },
                    update: { ...initialUpdateState },
                }
            };
        },
        clearInterviewsToClientCompaniesError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    error: null,
                }
            };
        },
        clearSingleTalentInterviewsToClientCompaniesStateError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: {
                        ...state[action.payload].add,
                        error: null,
                    },
                    update: {
                        ...state[action.payload].update,
                        error: null,
                    },
                }
            }
        },
        clearSingleTalentInterviewsToClientCompaniesManageState(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: { ...initialAddState },
                    update: { ...initialUpdateState },
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getInterviewsToClientCompanies.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.pending,
                        error: null,
                        add: { ...initialAddState },
                        update: { ...initialUpdateState },
                    }
                })
            )
            .addCase(
                thunk.getInterviewsToClientCompanies.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.failed,
                        error: action.payload,
                    }
                })
            )
            .addCase(
                thunk.getInterviewsToClientCompanies.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        data: action.payload.reduce((a, v) => ({ ...a, [v.id]: v }), {}),
                        loading: LoadingType.succeeded,
                        error: null,
                    }
                })
            )
            /// Add new talent in house interview
            .addCase(
                thunk.addNewTalentClientCompanyInterview.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        add: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.addNewTalentClientCompanyInterview.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        add: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.addNewTalentClientCompanyInterview.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        add: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )
            /// update 
            .addCase(
                thunk.updateTalentClientCompanyInterview.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        update: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.updateTalentClientCompanyInterview.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        update: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.updateTalentClientCompanyInterview.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        update: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentClientCompanyInterviewActions = interviewsToClientCompaniesSlice.actions;
export const selectState = (state: InterviewsToClientCompaniesState) => state;
export const selectSingleState = (state: InterviewsToClientCompaniesState, id: string) => state[id];

export const selectSingleInterviewsToClientCompaniesInitialState = (): SingleTalentInterviewsToClientCompaniesState => {
    return {
        loading: LoadingType.pending,
        error: null,
        data: {},
        add: initialAddState,
        update: initialUpdateState,
    }
}


