import { LockOpenIcon } from '../../icons';
import './ssn_holder.scss';

interface Props {
    value?: string | null,
    showFormat?: boolean,
    iconColor?: string,
    customStyle?: React.CSSProperties,
    iconStyle?: React.CSSProperties,
    onClick?: () => void,
}


const SSNHolder: React.FunctionComponent<Props> = (props) => {
    const { value, showFormat = true, iconColor, customStyle, iconStyle, onClick } = props;
    if (!value || value === null) {
        return (
            <span>{''}</span>
        )
    }

    return (
        <div className="ssn-holder" style={customStyle}>
            <div className="ssn-number">
                <span>{showFormat ? `XXX-XX-${value}` : value}</span>
            </div>
            <button
                className="loc-open-icon"
                style={iconStyle}
                onClick={onClick}
            >
                <LockOpenIcon width={"100%"} height={"100%"} style={{ color: iconColor ?? '#00A4DC' }} />
            </button>
        </div>
    )
}

export default SSNHolder;