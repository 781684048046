import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { PayCheck } from "../../../../interfaces/back_office_payroll";


export const getPayCheckThunk = createAsyncThunk<
    PayCheck[],
    {week_accounting_id: string, batch_id?: string},
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/paycheck/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getPayCheckData(payload.week_accounting_id, payload.batch_id);
            return res.data as PayCheck[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


