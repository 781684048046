import { createSlice } from "@reduxjs/toolkit";
import { AppError, IVacationHistory } from "../../../../interfaces";
import * as thunk from "./vacation_history_thunk";
import { LoadingType } from "../../../../enums";

export interface VacationHistState {
    error?: AppError | null,
    loading: LoadingType,
    response?: IVacationHistory[] | null,
}

const initialState: VacationHistState = {
    error: null,
    loading: LoadingType.idle,
    response: null
}


export const vacationHistSlice = createSlice({
    name: 'vacationHistory',
    initialState: initialState,
    reducers: {
    
    },
    extraReducers: (builder) => {
        builder
        .addCase(thunk.getVacationHistoryThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
        .addCase(thunk.getVacationHistoryThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
        .addCase(thunk.getVacationHistoryThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload }))

        .addDefaultCase(state => ({ ...state }));
    }
});

export const vacationHistorySliceActions = vacationHistSlice.actions;
export const selectState = (state: VacationHistState) => state;



