class Constants {
    static authToken = "auth_token";
    static loginState = "login_state";
    static serverUrl = "server_url";
    static months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    static dateFormatString = "dateFormatString";
    static firebasePNToken = "firebasePNToken";
    static muteNotifications = "muteNotifications";
    static leftNavbar = "left_nav";
}

export default Constants;