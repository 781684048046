import React from 'react';
import {
  ContactInfo,
  IAddContactCertificate,
  IAddContactCredentials,
  IAddContactEducation,
  IAddContactSkill,
  IContactSalesProfile,
  IContactServiceProfile,
  IRemoveContactCertificate,
  IRemoveContactCredentials,
  IRemoveContactEducation,
  IRemoveContactSkill,
} from "../entities/ContactInfo";
import { IAddContactStaff, IAddSkill, IContact, IContactGETQuery, ICreateContact, IRemoveContactStaff } from '../interfaces';
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";
import { IContactApolloImport, IContactsApolloSearch } from '../interfaces/apollo_search_import';

export const ContactsAPI = {

  /// [Contacts]

  getContacts(query?: IContactGETQuery) {
    if (query) {
      return interceptor.get(`${UrlBuilder.getAllContacts}?branch_id=${query?.branch_id}&company_id=${query?.company_id}&department_id=${query?.department_id}`);
    }
    return interceptor.get(`${UrlBuilder.getAllContacts}`);
  },

  getContactsDropdown(query?: IContactGETQuery) {
    if(query){
      if(query.branch_id && query.company_id && query.department_id){
        return interceptor.get(`${UrlBuilder.contactsDropdown}?branch_id=${query?.branch_id}&company_id=${query?.company_id}&department_id=${query?.department_id}`);
      }
      if(query.company_id && query.department_id){
        return interceptor.get(`${UrlBuilder.contactsDropdown}?company_id=${query?.company_id}&department_id=${query?.department_id}`);
      }
      if(query.company_id){
        return interceptor.get(`${UrlBuilder.contactsDropdown}?company_id=${query?.company_id}`);
      }
      return interceptor.get(`${UrlBuilder.contactsDropdown}`);
    }
    return interceptor.get(`${UrlBuilder.contactsDropdown}`);
  },

  createContact(doc: { forceAdd: boolean, value: ICreateContact }) {
    if (doc.forceAdd) return interceptor.post(`${UrlBuilder.contacts}?force_add=${doc.forceAdd}`, doc.value);
    return interceptor.post(UrlBuilder.contacts, doc.value);
  },

  deleteContact(id: string) {
    return interceptor.delete(`${UrlBuilder.contacts}?contact_id=${id}`);
  },

  updateContact(doc: IContact) {
    return interceptor.patch(UrlBuilder.contacts, doc);
  },

  contactById(id: string) {
    return interceptor.get(`${UrlBuilder.getContactById}?contact_id=${id}`);
  },

  /// [Contacts] ---> Details --->  skills
  getContactSkills(doc: { contact_id: string }) {
    return interceptor.get(`${UrlBuilder.contactSkills}?contact_id=${doc.contact_id}`);
  },
  addContactSkill(doc: { contact_id: string, value: IAddSkill }) {
    return interceptor.post(`${UrlBuilder.contactSkills}?contact_id=${doc.contact_id}`, doc.value);
  },

  deleteContactSkill(doc: { contact_id: string, value: IAddSkill }) {
    return interceptor.delete(`${UrlBuilder.contactSkills}?contact_id=${doc.contact_id}`, { data: doc.value });
  },

  ///[Contacts---> ContactsDetails ---> Alpha omega staffing representatives]

  getContactStaff(doc: { contact_id: string, role: string }) {
    return interceptor.get(`${UrlBuilder.contactStaffs}?contact_id=${doc.contact_id}&role=${doc.role}`);
  },

  addContactStaff(data: IAddContactStaff) {
    return interceptor.post(UrlBuilder.contactStaffs, data);
  },

  deleteContactStaff(doc: IRemoveContactStaff) {
    return interceptor.delete(UrlBuilder.contactStaffs, { data: doc });
  },

}

export const createContact = (
  contact: ContactInfo,
  forceAdd: boolean = false
) => {
  const url = UrlBuilder.contacts + `?force_add=${forceAdd}`;
  return interceptor.post(url, contact);
};

export const updateContact = (contact: ContactInfo) => {
  const url = UrlBuilder.contacts + `?contact_id=${contact.id}`;
  return interceptor.patch(url, contact);
};

export const getAllContacts = () => {
  return interceptor.get(UrlBuilder.getAllContacts);
};

export const getContactDetails = (contact_id: string | null) => {
  const url = UrlBuilder.getContactById + `?contact_id=${contact_id}`;
  return interceptor.get(url);
};

export const getContactCertifications = (contact_id: string) => {
  const url = UrlBuilder.contactCertifications + `?contact_id=${contact_id}`;
  return interceptor.get(url);
};

export const addContactCertifications = (data: IAddContactCertificate) => {
  const url = UrlBuilder.contactCertifications;
  return interceptor.post(url, data);
};

export const removeContactCertifications = (
  data: IRemoveContactCertificate
) => {
  const url = UrlBuilder.contactCertifications;
  return interceptor.delete(url, { data });
};

export const getContactCredentials = (contact_id: string) => {
  const url = UrlBuilder.contactCredentials + `?contact_id=${contact_id}`;
  return interceptor.get(url);
};

export const addContactCredentials = (data: IAddContactCredentials) => {
  const url = UrlBuilder.contactCredentials;
  return interceptor.post(url, data);
};

export const removeContactCredentials = (data: IRemoveContactCredentials) => {
  const url = UrlBuilder.contactCredentials;
  return interceptor.delete(url, { data });
};

export const getContactEducation = (contact_id: string) => {
  const url =
    UrlBuilder.contactEducationRequirements + `?contact_id=${contact_id}`;
  return interceptor.get(url);
};

export const addContactEducation = (data: IAddContactEducation) => {
  const url = UrlBuilder.contactEducationRequirements;
  return interceptor.post(url, data);
};

export const removeContactEducation = (data: IRemoveContactEducation) => {
  const url = UrlBuilder.contactEducationRequirements;
  return interceptor.delete(url, { data });
};

export const getContactSkills = (contact_id: string) => {
  const url = UrlBuilder.contactSkills + `?contact_id=${contact_id}`;
  return interceptor.get(url);
};

export const addContactSkills = (data: IAddContactSkill) => {
  const url = UrlBuilder.contactSkills;
  return interceptor.post(url, data);
};

export const removeContactSkills = (data: IRemoveContactSkill) => {
  const url = UrlBuilder.contactSkills;
  return interceptor.delete(url, { data });
};

export const contactsByCompany = (data) => {
  if (data.department_id)
    return interceptor.get(`${UrlBuilder.contactsByCompany}?company_id=${data.company_id}&department_id=${data.department_id}`)
  else
    return interceptor.get(`${UrlBuilder.contactsByCompany}?company_id=${data.company_id}`)
}
export const getContactSalesProfile = (contact_id: string) => {
  return interceptor.get(`${UrlBuilder.contactSalesProfile}?contact_id=${contact_id}`);
};

export const updateContactSalesProfile = (data: IContactSalesProfile) => {
  const url = UrlBuilder.contactSalesProfile;
  return interceptor.patch(url, data);
};

export const getContactServiceProfile = (contact_id: string) => {
  const url = UrlBuilder.contactServiceProfile + `?contact_id=${contact_id}`;
  return interceptor.get(url);
};

export const updateContactServiceProfile = (data: IContactServiceProfile) => {
  const url = UrlBuilder.contactServiceProfile;
  return interceptor.patch(url, data);
};

// export const contactsByCompany = (companyId) => {
//   return interceptor.get(`${UrlBuilder.contactsByCompany}?company_id=${companyId}`)
// };

export const contactsByCompanyDepartment = (query?: { company_id: string, department_id?: string | null } | null) => {
  if (query) {
    let queryString = `?company_id=${query.company_id}`;
    if (query.department_id)
      queryString += `&department_id=${query.department_id}`;
    return interceptor.get(`${UrlBuilder.getAllContacts}${queryString}`);
  }
  return interceptor.get(`${UrlBuilder.getAllContacts}`);
};

export const getJobOrderContacts = (query) => {
  return interceptor.get(`${UrlBuilder.jobOrderContacts}?job_id=${query.jobOrderId}`);
}

export const createJobContact = (data) => {
  return interceptor.post(`${UrlBuilder.jobOrderContacts}`, data);
}

