import { Portal } from "react-portal";
import { LoadingType, THEME } from "../../../../../../enums";
import { DeleteIcon, PaperPlaneIcon } from "../../../../../../icons";
import { deleteJobDocumentUploadDocument, selectJobDocVidSendByEmailState, sendDocVidByEmail } from "../../../../../../redux/job_order";
import { selectProfileState, useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { CustomButton } from "../../../../../../utils";
import SendByEmailPopup from "../../../../popups/send_by_email/sen_by_email";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SendByEmail } from "../../../../../../interfaces/marketing_campaign";
import { jobDocumentSendByEmailActions } from "../../../../../../redux/job_order/details/document/document_send_by_email/send_by_email_reducer";
import { DeleteConfirmationDialog } from "../../../../../components/delete_confirmation_dialog";
import { jobsDocumentActions } from "../../../../../../redux/job_order/details/document/document_list/document_list_reducer";
import { salesDocumentActions } from "../../../../../../redux/sales_marketing/marketing_docs/marketing_docs_list/marketing_docs_reducer";

interface Props {
    id: string;
    job_document_id: string;
    title: string;
    url: any;
    state: any;
    onRetry: () => void;
}

const VidePlayer: React.FC<Props> = (props) => {
    const { url, title, onRetry, id, job_document_id, state } = props
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showSendByEmail, setShowSendByEmail] = useState<boolean>(false);
    const profileState = useAppSelector((state) => selectProfileState(state));
    const sendByemailState = useAppSelector((state) => selectJobDocVidSendByEmailState(state))
    const [showDeleteVideoDialog, setShowDeleteVideoDialog] = useState<{ visible: boolean, id: string, documentName: string | undefined } | null>(null);

    useEffect(() => {
        if (state?.documentDelete && state.documentDelete.loading === LoadingType.succeeded) {
            setShowDeleteVideoDialog(null)
            dispatch(jobsDocumentActions.clearJobDocDeleteState());
        }
    }, [state?.documentDelete])
    
    let videoId = '';
    if (url.toLowerCase().includes('v='))
        videoId = url.split('v=')[1].split('&')[0];
    else {
        videoId = url.split('/');
        videoId = videoId[videoId.length - 1];
    }

    function handleDelete(doc: string, job_order_id: string) {
        dispatch(deleteJobDocumentUploadDocument({ job_order_id: job_order_id, file_object_id: doc }))
        onRetry()
    }

    const handleClearError = () => {
        dispatch(jobsDocumentActions.clearJobDocDeleteState())
    }

    return (
        <div className="job-order-docs-container">
            <iframe style={{ 'borderRadius': "5px" }} width="250" height="250" src={`https://www.youtube.com/embed/${videoId}?controls=1`} allowFullScreen={true} frameBorder={0}></iframe>
            <div className="job-order-docs-container-header">
                <span className="jod-font-style">{title}</span>
                <button onClick={() => setShowDeleteVideoDialog({visible: true, id: id, documentName: title})}>
                    <div className="icon-btn tdp-edit-icon">
                        <DeleteIcon width={"100%"} height={"100%"} />
                    </div>
                </button>
            </div>
            <div>
                <div>
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize' }}
                        enable={true}
                        leftIcon={
                            <div>
                                <PaperPlaneIcon
                                    width={20}
                                    height={20}
                                    style={{ color: THEME.white }}
                                />
                            </div>
                        }
                        name={t("send_by_email")}
                        backgroundColor={THEME.buttonBackgroundColor4}
                        onClick={() => setShowSendByEmail(true)}
                    />
                </div>
                {showSendByEmail && (
                    <Portal>
                        <SendByEmailPopup
                            visible={showSendByEmail}
                            DocumentId={id}
                            onClose={() => setShowSendByEmail(false)}
                            onDissmiss={() => setShowSendByEmail(false)}
                            onSuccessClose={handleOnSendByEmailSuccess}
                            onSubmit={handleOnSubmit}
                            profileState={profileState}
                            sendByemailState={sendByemailState}
                            loading={sendByemailState.sendDocVidLinkToEmail.loading}
                            error={sendByemailState.sendDocVidLinkToEmail.error}
                        />
                    </Portal>
                )}
                {showDeleteVideoDialog && showDeleteVideoDialog.visible && (
                    <DeleteConfirmationDialog
                        message={t("are_you_sure_you_want_to_delete_this_document", {
                            documentName: showDeleteVideoDialog.documentName,
                        })}
                        onCancel={() => setShowDeleteVideoDialog(null)}
                        onConfirm={() => handleDelete(showDeleteVideoDialog.id, job_document_id)}
                        deleting={state?.documentDelete?.loading === LoadingType.pending}
                        deleteType={t("Document")}
                        state={state?.documentDelete}
                        onClearError={handleClearError}
                    />
                )}
            </div>
        </div>
    )
    function handleOnSubmit(data: SendByEmail) {
        dispatch(sendDocVidByEmail(data));
    }

    function handleOnSendByEmailSuccess() {
        setShowSendByEmail(false);
        dispatch(jobDocumentSendByEmailActions.clearSendDocVidLinkToEmailState());
    }
}
export default VidePlayer;