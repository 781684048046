import { combineReducers } from "@reduxjs/toolkit";
import { getTalentEmpDataSlice } from "./employment_history/employment_history_reducer";
import { manageTalentEMploymentHistorySlice } from "./manage_employment/manage_employment_reducer";
import { talentDetailsReducers } from "./details";
import { talentDetailsSlice } from "./details/dashboard/talent_details/talent_details_reducer";
import { talentUniversalDirectoryReducers } from "./universal_directory";


export const talentReducers = combineReducers({
    'universalDirectory': talentUniversalDirectoryReducers,
    [talentDetailsSlice.name]: talentDetailsSlice.reducer,
    [manageTalentEMploymentHistorySlice.name]: manageTalentEMploymentHistorySlice.reducer,
    [getTalentEmpDataSlice.name]: getTalentEmpDataSlice.reducer,
    'details': talentDetailsReducers,
    // [talentDetailsSlice.name]: talentDetailsSlice.reducer,
});

/// Universal directory
export * from './universal_directory';

/// Details
export * from './details';

/// Talent Employment History
export * from './employment_history/employment_history_thunk';
export * from './employment_history/employment_history_selector';

/// Manage Talent Employment History
export * from './manage_employment/manage_employment_thunk';
export * from './manage_employment/manage_employment_selector';


