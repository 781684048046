import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, ICreateTalentBOPRDirectDeposit, ITalentBOPRDirectDeposit } from "../../../../../interfaces";
import { TalentAPI } from "../../../../../apis";
import { catchRequestError } from "../../../../../utils";


// update employee Direct Deposit
export const updateTalentBOPayrollDirectDepositThunk = createAsyncThunk<
  string,
  { data: ICreateTalentBOPRDirectDeposit, talent_id: string },
  {
    rejectValue: AppError | null,
    rejectedMeta: AppError | null,
  }
>(
  '@talent/details/back_office_payroll_setup_direct_deposit/patch',
  async (payload, thunkAPI) => {
    try {
      const res = await TalentAPI.updateBOTalentPayrollSetupDirectDeposit(payload.talent_id, payload.data);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const validateTalentDirectDepositAcntThunk = createAsyncThunk<
  string,
  ITalentBOPRDirectDeposit,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  '@talent/details/back_office_payroll_setup_direct_deposit/validate/post',

  async (payload, thunkAPI) => {
    try {
      const res = await TalentAPI.postValidateTalentDDOnBoarding(payload);
      // if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);
