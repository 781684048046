import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from './check_and_invoice_starting_numbers_thunk';
import { ACBOCheckAndInvoiceStartingNumbersType } from "../../../../interfaces/admin_center_back_office_type";
import { createSlice } from "@reduxjs/toolkit";

export interface CheckAndInvoiceStartingNumbersState {
    error?: AppError | null,
    loading: LoadingType,
    response: ACBOCheckAndInvoiceStartingNumbersType | null
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const initialState: CheckAndInvoiceStartingNumbersState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
}


export const CheckAndInvoiceStartingNumbersSlice = createSlice({
    name: 'CheckAndInvoiceStartingNumbersList',
    initialState: initialState,
    reducers: {
        clearCheckAndInvoiceStartingNumbersListError(state){
            return {...state, error: null};
        },
        clearCheckAndInvoiceStartingNumbersListState(state){
            return { ...state, ...initialState };
        },
        clearCheckAndInvoiceStartingNumbersUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearCheckAndInvoiceStartingNumbersUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCheckAndInvoiceStartingNumbers.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getCheckAndInvoiceStartingNumbers.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCheckAndInvoiceStartingNumbers.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload}))

            .addCase(thunk.updateCheckAndInvoiceStartingNumbers.pending, (state, action) => ({...state, update: {...state.update, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateCheckAndInvoiceStartingNumbers.rejected, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateCheckAndInvoiceStartingNumbers.fulfilled, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const checkAndInvoiceStartingNumbersActions = CheckAndInvoiceStartingNumbersSlice.actions;
export const selectState = (state: CheckAndInvoiceStartingNumbersState) => state;


