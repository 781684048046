import { useTranslation } from "react-i18next";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import { AppRoutes, getEmployeePortalNavHeaderProps } from "../../routes";
import { useEffect } from "react";
import { useAppDispatch } from "../../redux/store";
import { getCertificationsList, getCredentialsList, getEducationList, getRequiredEquipmentList, getSkillSetList, } from "../../redux/store";
import { getCompetitorsList, getRoleUsersList } from "../../redux/selectors";
import UniversalDirectory from "./pages/universal_directory/universal_directory";
import Dashboard from "./pages/details/dashboard/employee_portal_dashboard";
import { GetCloseAction } from "../../utils";

interface Props extends RouteComponentProps<any> { }


/// Company Details
const EmployeePortalDetailsTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const pathSplit = location.pathname.split('/');
    const subId = pathSplit.length === 6 ? pathSplit[pathSplit.length - 1] : null;

    useEffect(() => {
        dispatch(getSkillSetList());
        dispatch(getCertificationsList());
        dispatch(getCredentialsList());
        dispatch(getEducationList());
        dispatch(getRequiredEquipmentList());
        dispatch(getRoleUsersList());
        dispatch(getCompetitorsList());

        return () => { }
    }, [])

    function handleNavigationChange(value: string) {
        history.push({
            pathname: subId ? `${value}/${subId}` : value,
            state: location.state
        });
    }

    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'dashboard', route: `${AppRoutes.employeePortalDetailsPage}/${params.id}/dashboard` },
            ]}
            onChange={handleNavigationChange}
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.employeePortalDetailsPage)}/>}
        >
            <Switch>
                <Route exact path={`${AppRoutes.employeePortalDetailsPage}/:id/dashboard/:subId?`} component={Dashboard} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: `${location.pathname}/dashboard`,
                            state: { ...(location.state as any) }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );

}

/// Universal directory folder
const EmployeePortalTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'universal_directory', route: AppRoutes.employeePortalUniversalDirectory },
            ]}
            onChange={(value) => history.push(value)}
        >
            <Switch>
                <Route exact path={AppRoutes.employeePortalUniversalDirectory} component={UniversalDirectory} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: AppRoutes.employeePortalUniversalDirectory,
                            state: { from: location }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );
}



export const EmployeePortalNavigation: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getEmployeePortalNavHeaderProps(location, match, t);

    return (
        <PageNavHeader data={headerOptions} history={history} location={location}>
            <Switch>
                <Route path={`${AppRoutes.employeePortalDetailsPage}/:id`} component={EmployeePortalDetailsTabNav} />
                <Route path={'/'} component={EmployeePortalTabNav} />
                <Route path={'/'} component={UniversalDirectory} />
            </Switch>
        </PageNavHeader>
    );
}

