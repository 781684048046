import { combineReducers } from "@reduxjs/toolkit";
import {companyContactSlice} from "./company_contacts_list/company_contact_reducer";
import { companyContactCreateSlice } from "./company_contact_create/company_contact_create_reducer";

export const companyContactsReducers = combineReducers({
    [companyContactSlice.name]: companyContactSlice.reducer,
    [companyContactCreateSlice.name]: companyContactCreateSlice.reducer
});


export * from "./company_contacts_list/company_contact_selector";
export * from "./company_contacts_list/company_contact_thunk";

export * from "./company_contact_create/company_contact_create_selector";
export * from "./company_contact_create/company_contact_create_thunk";
