import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType } from '../../../../enums';
import { ISendOnlineApplication } from '../../../../interfaces';
import { sendOnlineApplicationsStateActions } from '../../../../redux/dashboard/metrics/send_online_application/send_online_application_reducer';
import { useAppDispatch, useAppSelector, selectDashboardSendOnlineApplicationsState, postSnackbarMessage, selectProfileState, sendOnlineApplicationThunk } from '../../../../redux/store';
import SendOnlineApplicationForm from '../../forms/send_online_application_form/send_online_application_form';

interface Props {
    visible: boolean,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

const SendOnlineApplicationPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const profileState = useAppSelector((state) => selectProfileState(state));
    const onlineApplicationState = useAppSelector((state) => selectDashboardSendOnlineApplicationsState(state));

    // useEffect(() => {
    //     if (onlineApplicationState.loading == LoadingType.succeeded) {
    //         dispatch(postSnackbarMessage(onlineApplicationState.response ?? null));
    //         closePopup(onSuccessClose);
    //     }
    //     return () => { }
    // }, [onlineApplicationState.loading])

    function handleWrapperClose(action?: () => void) {
        if (onlineApplicationState.loading == LoadingType.succeeded) {
            // dispatch(postSnackbarMessage(createState.response ?? null));
            closePopup(props.onSuccessClose);
        } else {
            if (action) closePopup(action);
        }
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
                {getUI()}
            </DialogWrapper>
        </ActionDialogHolder>
    );


    function getUI() {
        const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`;
        return (
            <div className="mark-tlnt-pop-holder">
                <SendOnlineApplicationForm
                    title={t('hi_lets_do_some_recruiting', { name: currentLoginUserName })}
                    currentLoginUserName={currentLoginUserName}
                    error={onlineApplicationState.error}
                    loading={onlineApplicationState.loading}
                    onCancel={() => closePopup(onClose)}
                    onClearError={handleClearError}
                    onSubmit={handleOnSubmit}
                    onSuccessClose={() => closePopup(props.onSuccessClose)}
                />
            </div>
        )
    }

    function handleOnSubmit(value: ISendOnlineApplication) {
        dispatch(sendOnlineApplicationThunk(value));
    }

    function handleClearError() {
        dispatch(sendOnlineApplicationsStateActions.clearSendOnlineApplicationsError());
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default SendOnlineApplicationPopup;