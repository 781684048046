import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./paycheck_register_thunk";
import { IRCOpenJobOrders, IRCPaycheckRegister, IReportCenterUploadUrlResponse } from "../../../../interfaces/report_center";


export interface PaycheckRegisterState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCPaycheckRegister | null
}

const initialState: PaycheckRegisterState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}

export const paycheckRegisterSlice = createSlice({
    name: 'paycheckRegister',
    initialState: initialState,
    reducers: {
        clearPRErrorState(state) {
            return { ...state, error: null };
        },
        clearPRState(state) {
            return { ...state, ...initialState };
        },
        clearPRDownloadState(state) {
            return {
                ...state, downloadURL: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPRListThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getPRListThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getPRListThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))


            .addDefaultCase(state => ({ ...state }));
    }
});

export const paycheckRegisterSliceActions = paycheckRegisterSlice.actions;
export const selectState = (state: PaycheckRegisterState) => state;
export const selectLoading = (state: PaycheckRegisterState) => state.loading;
export const selectError = (state: PaycheckRegisterState) => state.error;



