import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, PayrollBatch } from "../../../../interfaces";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { catchRequestError } from "../../../../utils";


export const createPayrollBatchThunk = createAsyncThunk<
    PayrollBatch,
    {ignore_elg_change_file: boolean, batch: PayrollBatch},
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_dashboard/create_payroll_batch/post",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.createPayrollBatch(payload.ignore_elg_change_file, payload.batch);
            return res.data.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const postPayrollBatchThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_dashboard/post_payroll_batch/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.postPayrollBatch(payload);
            return res.data.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });
export const deletePayrollBatchThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_dashboard/delete_payroll_batch/delete",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.deletePayrollBatch(payload);
            return res.data.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });