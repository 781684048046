import { createEntityAdapter, createSlice, EntityAdapter, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, TalentInterviewQuestion } from "../../../../../interfaces";
import * as thunk from "./interview_questions_thunk";



export interface SingleTalentInterviewQuestionsState {
    data: Array<TalentInterviewQuestion> | null,
    error?: AppError | null,
    loading: LoadingType,
}


export interface InterviewQuestionsState {
    [key: string]: SingleTalentInterviewQuestionsState,
}


const initialState: InterviewQuestionsState = {}


export const inHouseInterviewQuestionsSlice = createSlice({
    name: 'inHouseInterviewQuestions',
    initialState: initialState,
    reducers: {
        clearIntterviewQuestionsError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    error: null,
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getTalentInHouseInterviewQuestions.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.pending,
                        error: null,
                    }
                })
            )
            .addCase(
                thunk.getTalentInHouseInterviewQuestions.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.failed,
                        error: action.payload,
                    }
                })
            )
            .addCase(
                thunk.getTalentInHouseInterviewQuestions.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        data: action.payload,
                        loading: LoadingType.succeeded,
                        error: null,
                    }
                })
            )
            
            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentInHouseInterviewQuestionsActions = inHouseInterviewQuestionsSlice.actions;
export const selectState = (state: InterviewQuestionsState) => state;
export const selectSingleState = (state: InterviewQuestionsState, id: string) => state[id];

export const selectSingleInterviewQuestionsInitialState = (): SingleTalentInterviewQuestionsState => {
    return {
        loading: LoadingType.pending,
        error: null,
        data: [],
    }
}


