import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolderType, ActionDialogHolder } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType } from '../../../../enums';
import { Profile, ICreateUser } from '../../../../interfaces';
import { useAppDispatch, useAppSelector, postSnackbarMessage } from '../../../../redux/store';
import UpdateMyProfileUserForm from '../../forms/update_my_profile_form/update_my_profile_form';
import { selectManageMyProfileUserUpdateState, updateMyProfileUser } from '../../../../redux/my_profile';
import { manageMyProfileUserActions } from '../../../../redux/my_profile/update_my_profile_user/update_user_reducer';

interface Props {
    visible: boolean,
    branchId: string,
    title: string,
    user?: Profile,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

const UpdateMyProfileUser: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        branchId,
        onClose,
        onDissmiss,
        onSuccessClose
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    // const createState = useAppSelector((state) => selectManageUserCreateState(state));
    const updateState = useAppSelector((state) => selectManageMyProfileUserUpdateState(state));
    const actionRef = useRef<ActionDialogHolderType>(null);


    useEffect(() => {
        // if (createState.loading == LoadingType.succeeded) {
        //     dispatch(postSnackbarMessage(createState.response ?? null));
        //     closePopup(onSuccessClose);
        // }
        if (updateState.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(updateState.response ?? null));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [
        // createState.loading, 
        updateState.loading])


    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <UpdateMyProfileUserForm
                    title={props.title}
                    user={props.user}
                    branchId={branchId}
                    loading={
                        // (createState.loading === LoadingType.pending) || 
                        (updateState.loading === LoadingType.pending)}
                    error={
                        // createState.error || 
                        updateState.error}
                    onClearError={handleClearError}
                    onClose={() => closePopup(onClose)}
                    onSubmit={handleSubmit}
                />
            </DialogWrapper>
        </ActionDialogHolder>

    );

    ///Handle clear error
    function handleClearError() {
        if (props.user && props.user.id) {
            dispatch(manageMyProfileUserActions.clearMyProfileUpdateState());
        } 
        // else {
            // dispatch(manageUserActions.clearCreateState());
        // }
    }

    /// handle submit
    function handleSubmit(value: ICreateUser) {
        if (props.user && props.user.id) {
            dispatch(updateMyProfileUser({ id: props.user.id, data: value }));
        }
        // else {
        //     dispatch(createUser(value));
        // }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default UpdateMyProfileUser;