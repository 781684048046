import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../../apis/back_office_api";
import { ITimeCards, AppError, ITimecardsQueryParams } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";

export const getTimeCardsListThunk = createAsyncThunk<
    ITimeCards,
    {
        week_accounting_id: string, queryParams: ITimecardsQueryParams
    },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards/timecards_list/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getTimecardsData(payload.week_accounting_id, payload.queryParams);
            return res.data as ITimeCards;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const deleteTimeCardsUploadDocument = createAsyncThunk<
    string,
    { time_card_id: string, pay_type_id: string, file_name: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@back_office/timecards/timecards/timecards_list_upload_docs/delete',
    async (data, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.deleteTimeCardsDocument(data.time_card_id, data.pay_type_id, data.file_name);
            return res
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);