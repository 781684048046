import { IPDFAutoFill } from "../../interfaces/pdf_auto_fill";

export const W4FormFields: { [key: string]: IPDFAutoFill } = {
    "topmostSubform[0]": { key: "topmostSubform[0]", mapTo: null },
    "topmostSubform[0].Page3[0]": { key: "topmostSubform[0].Page3[0]", mapTo: null },
    "topmostSubform[0].Page3[0].f3_11[0]": { key: "topmostSubform[0].Page3[0].f3_11[0]", mapTo: null },
    "topmostSubform[0].Page3[0].f3_10[0]": { key: "topmostSubform[0].Page3[0].f3_10[0]", mapTo: null },
    "topmostSubform[0].Page3[0].f3_09[0]": { key: "topmostSubform[0].Page3[0].f3_09[0]", mapTo: null },
    "topmostSubform[0].Page3[0].f3_08[0]": { key: "topmostSubform[0].Page3[0].f3_08[0]", mapTo: null },
    "topmostSubform[0].Page3[0].f3_07[0]": { key: "topmostSubform[0].Page3[0].f3_07[0]", mapTo: null },
    "topmostSubform[0].Page3[0].f3_06[0]": { key: "topmostSubform[0].Page3[0].f3_06[0]", mapTo: null },
    "topmostSubform[0].Page3[0].f3_05[0]": { key: "topmostSubform[0].Page3[0].f3_05[0]", mapTo: null },
    "topmostSubform[0].Page3[0].f3_04[0]": { key: "topmostSubform[0].Page3[0].f3_04[0]", mapTo: null },
    "topmostSubform[0].Page3[0].f3_03[0]": { key: "topmostSubform[0].Page3[0].f3_03[0]", mapTo: null },
    "topmostSubform[0].Page3[0].f3_02[0]": { key: "topmostSubform[0].Page3[0].f3_02[0]", mapTo: null },
    "topmostSubform[0].Page3[0].f3_01[0]": { key: "topmostSubform[0].Page3[0].f3_01[0]", mapTo: null },
    "topmostSubform[0].Page1[0]": { key: "topmostSubform[0].Page1[0]", mapTo: null },
    "topmostSubform[0].Page1[0].f1_15[0]": { key: "topmostSubform[0].Page1[0].f1_15[0]", mapTo: "fein" },
    "topmostSubform[0].Page1[0].f1_14[0]": { key: "topmostSubform[0].Page1[0].f1_14[0]", mapTo: null, type: "date" },
    "topmostSubform[0].Page1[0].f1_13[0]": { key: "topmostSubform[0].Page1[0].f1_13[0]", mapTo: ["name", "street_address", "city", "state", "zip_code"] },
    "topmostSubform[0].Page1[0].f1_12[0]": { key: "topmostSubform[0].Page1[0].f1_12[0]", mapTo: null },
    "topmostSubform[0].Page1[0].f1_11[0]": { key: "topmostSubform[0].Page1[0].f1_11[0]", mapTo: null },
    "topmostSubform[0].Page1[0].f1_10[0]": { key: "topmostSubform[0].Page1[0].f1_10[0]", mapTo: null },
    "topmostSubform[0].Page1[0].f1_09[0]": { key: "topmostSubform[0].Page1[0].f1_09[0]", mapTo: null },
    "topmostSubform[0].Page1[0].Step3_ReadOrder[0]": { key: "topmostSubform[0].Page1[0].Step3_ReadOrder[0]", mapTo: null },
    "topmostSubform[0].Page1[0].Step3_ReadOrder[0].f1_07[0]": { key: "topmostSubform[0].Page1[0].Step3_ReadOrder[0].f1_07[0]", mapTo: null },
    "topmostSubform[0].Page1[0].Step3_ReadOrder[0].f1_06[0]": { key: "topmostSubform[0].Page1[0].Step3_ReadOrder[0].f1_06[0]", mapTo: null },
    "topmostSubform[0].Page1[0].c1_2[0]": { key: "topmostSubform[0].Page1[0].c1_2[0]", mapTo: null },
    "topmostSubform[0].Page1[0].c1_1[2]": { key: "topmostSubform[0].Page1[0].c1_1[2]", mapTo: null },
    "topmostSubform[0].Page1[0].c1_1[1]": { key: "topmostSubform[0].Page1[0].c1_1[1]", mapTo: null },
    "topmostSubform[0].Page1[0].c1_1[0]": { key: "topmostSubform[0].Page1[0].c1_1[0]", mapTo: null },
    "topmostSubform[0].Page1[0].f1_05[0]": { key: "topmostSubform[0].Page1[0].f1_05[0]", mapTo: null },
    "topmostSubform[0].Page1[0].Step1a[0]": { key: "topmostSubform[0].Page1[0].Step1a[0]", mapTo: null },
    "topmostSubform[0].Page1[0].Step1a[0].f1_04[0]": { key: "topmostSubform[0].Page1[0].Step1a[0].f1_04[0]", mapTo: null },
    "topmostSubform[0].Page1[0].Step1a[0].f1_03[0]": { key: "topmostSubform[0].Page1[0].Step1a[0].f1_03[0]", mapTo: null },
    "topmostSubform[0].Page1[0].Step1a[0].f1_02[0]": { key: "topmostSubform[0].Page1[0].Step1a[0].f1_02[0]", mapTo: null },
    "topmostSubform[0].Page1[0].Step1a[0].f1_01[0]": { key: "topmostSubform[0].Page1[0].Step1a[0].f1_01[0]", mapTo: null },
    "employee_signature": { key: "employee_signature", mapTo: null },
    "employee_sign_date": { key: "employee_sign_date", mapTo: null },
}