import {
  ICreateEmploymentHistory,
  IOnlineApplication,
  IOnlineApplicationUploadUrlsQuery,
} from "../interfaces";
import fileInterceptor from "./file_interceptor";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";
import axios from "axios";

export const OnlineApplicationAPI = {
  verifyToken(token: string) {
    return interceptor.post(
      `${UrlBuilder.talentOnlineApplicationVerifyToken}?token=${token}`
    );
  },

  postOnlineApplication(credentials: IOnlineApplication) {
    return interceptor.post(UrlBuilder.talentOnlineApplication, credentials);
  },

  getFileUploadUrls(query: IOnlineApplicationUploadUrlsQuery) {
    if (query.profile_pic_file_name && query.resume_file_name) {
      return interceptor.get(
        `${UrlBuilder.talentOnlineApplicationGetUploadUrls}?object_id=${query.object_id}&domain=${query.domain}&profile_pic_file_name=${query.profile_pic_file_name}&resume_file_name=${query.resume_file_name}`
      );
    }
    if (query.profile_pic_file_name && query.resume_file_name === undefined) {
      return interceptor.get(
        `${UrlBuilder.talentOnlineApplicationGetUploadUrls}?object_id=${query.object_id}&domain=${query.domain}&profile_pic_file_name=${query.profile_pic_file_name}`
      );
    }
    if (query.profile_pic_file_name === undefined && query.resume_file_name) {
      return interceptor.get(
        `${UrlBuilder.talentOnlineApplicationGetUploadUrls}?object_id=${query.object_id}&domain=${query.domain}&resume_file_name=${query.resume_file_name}`
      );
    }
    return interceptor.get(
      `${UrlBuilder.talentOnlineApplicationGetUploadUrls}?object_id=${query.object_id}&domain=${query.domain}&profile_pic_file_name=${query.profile_pic_file_name}&resume_file_name=${query.resume_file_name}`
    );
  },

  uploadPhotoAndResume(url: string, data) {
    return axios.put(url, data);
  },

  /// [Talent Employment History]
  getTalentEmploymentHistory(talentId: string) {
    return interceptor.get(
      `${UrlBuilder.talentEmploymentHistoryAll}?talent_id=${talentId}`
    );
  },

  createTalentEmploymentHistory(doc: {
    forceAdd: boolean;
    value: ICreateEmploymentHistory;
  }) {
    return interceptor.post(
      UrlBuilder.createTalentEploymentHistoryEP,
      doc.value
    );
  },
};
