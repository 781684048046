import './user_avatar.scss';

interface Props {
    onClick: () => void,
    url: string,
}

const SimpleUserAvatar : React.FunctionComponent<Props>= (props) => {

    return (
        <div className="avatar-holder" onClick={props.onClick}>
            <div className="avatar background-img" style={props.url ? { backgroundImage: `url(${props.url})` } : {}}></div>
        </div>
    )
}

export default SimpleUserAvatar;