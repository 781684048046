import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";


export const exportPageThunk = createAsyncThunk<
    string,
    { param: string },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/import_export/export/post",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.backOfficeExport(payload.param);
            return res?.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });



// export const exportCensusFileThunk = createAsyncThunk<
//     string,
//     ExportMappingGroup,
//     {
//         rejectValue: AppError | null;
//         rejectedMeta: AppError | null;
//     }
// >("@back_office/import_export/export/post",
//     async (payload, thunkAPI) => {
//         try {
//             const res = await BackOfficeAPI.postImportFileUpload(payload);

//             return res.data;
//         } catch (error) {
//             return catchRequestError(error, thunkAPI);
//         }
//     });