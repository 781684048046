import { createAsyncThunk } from "@reduxjs/toolkit";
import { OnboardingPortalAPI } from "../../../apis";
import { S3API } from "../../../apis/s3_api";
import { AppError } from "../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../utils";


export const getESCEnrollmentURLThunk = createAsyncThunk<
  string,
  {token:string, data: any},
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/esc/enrollment-url/post",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.getESCEnrollmentURL(payload.token,payload.data);
      return res.data ;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);
/// upload file
export const uploadEssentialStaffcareThunk = createAsyncThunk<
  any,
  { url: string, file: File | Blob },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/essential-staffcare/upload/put",
  async (payload, thunkAPI) => {
    try {
      const res = await S3API.uploadFile(payload.url, payload.file);
      // if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

/// upload url
export const getUploadEssentialStaffcareUrlThunk = createAsyncThunk<
  { url: string },
  { token?: string, talent_id?: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/essential-staffcare/upload-url/get",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.getHandbookUploadUrl(payload); /// TODO: Essential staff care API missing
      if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

/// Download url
export const getDownloadEssentialStaffcareUrlThunk = createAsyncThunk<
  { url: string },
  { token?: string, talent_id?: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@onboarding/essential-staffcare/download-url/get",
  async (payload, thunkAPI) => {
    try {
      const res = await OnboardingPortalAPI.getHandbookDownloadUrl(payload);
      if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);