import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./update_user_thunk";
import { AppError } from "../../../interfaces";
import { LoadingType } from "../../../enums";

export interface ManageMyProfileUserState {
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    },
}

const initialState: ManageMyProfileUserState = {
    update: {
        error: null,
        loading: LoadingType.idle,
    },
}


export const manageMyProfileUserSlice = createSlice({
    name: 'manageMyProfileUser',
    initialState: initialState,
    reducers: {
        clearMyProfileUpdateState(state) {
            state.update = { ...initialState.update };
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(thunk.updateMyProfileUser.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateMyProfileUser.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateMyProfileUser.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageMyProfileUserActions = manageMyProfileUserSlice.actions;
export const selectState = (state: ManageMyProfileUserState) => state;
export const selectUpdate = (state: ManageMyProfileUserState) => state.update;


