import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './back_office_invoices_and_docs_reducer';
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.companies.details.backOfficeInvoicesAndDocs.companyBackOfficeInvoiceDocs;
export const selectCompanyBOSetupState = createSelector(select, reducer.selectState);
export const selectSingleCompanyBOSetupById = (id: string | undefined | null) => createSelector(
    selectCompanyBOSetupState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleCompanyBOInvoiceDocsState(state, id);
        return reducer.selectInitialSingleCompanyBOInvoiceDocsState();
    }
);