import { RouteComponentProps } from "react-router-dom";
import "./company_setup_page.scss";
import { useTranslation } from "react-i18next";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { useEffect, useState } from "react";
import { IMasterTaxCompanySetup, IMasterTaxSetup } from "../../../../../../interfaces/master_tax";
import { CustomButton, enforceFormat, formatToPhoneWithoutCode, FormInput, RegxPattern, SpinnerScreen } from "../../../../../../utils";
import { US_states } from "../../../../../../utils/States_US";
import CustomFormSelect from "../../../../../../components/form_selector/form_select";
import { DateInput } from "../../../../../../components/date_input/date_input";
import { cashServiceLevel, employerKind, feinType, serviceLevel, yesNo } from "../../master_tax_dropdown";
import { LoadingType, THEME } from "../../../../../../enums";
import { getAgencyDetails, getMasterTaxSetup, selectAgencyDetailsState, selectMasterTaxSetupState, updateMasterTaxSetup } from "../../../../../../redux/admin_center";
import { Agency } from "../../../../../../interfaces";
import { masterTaxInitialForm } from "../../master_tax_initialstate";
import { masterTaxSetupStateSliceActions } from "../../../../../../redux/admin_center/integration_partners/master_tax/master_tax_setup/master_tax_setup_reducer";
import ApiError from "../../../../../../components/api_error";

interface Props extends RouteComponentProps<any> { }

const MasterTaxCompanySetupPage: React.FunctionComponent<Props> = (props: Props) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const agencyDetailsState = useAppSelector((state) => selectAgencyDetailsState(state));
    const masterTaxState = useAppSelector((state) => selectMasterTaxSetupState(state));

    const getFormData = (value?: IMasterTaxCompanySetup, agency?: Agency) => {

        if (value) {
            return value;
        }
        return {
            agency_legal_name: agency?.name ?? "",
            payroll_code: "PTSCSV-01",
            company_dba_name: "",
            in_care_of_name: "",
            address_line1: agency?.street_address ?? "",
            address_line2: "",
            city: agency?.city ?? "",
            state: agency?.state ?? "",
            zip_code: agency?.zip_code ?? "",
            psd_code: "",
            payroll_description: "",
            name_control: "",
            company_start_date: 0,
            quarterly_wage_reporting: "N",
            worksite_reporting: "N",
            year_end_employee_filing: "N",
            wage_attachment: "N",
            cash_service_level: null,
            kind_of_employer: "N",
            naics_code: "",
            effective_date: 0,
            fein_type: "N",
            fein: agency?.fein?.replace("-", "") ?? "",
            the_944_filter: "N",
            company_status: "",
            service_level: "F",
            contact_first_name: "",
            contact_middle_name: "",
            contact_last_name: "",
            phone_area_code: "",
            phone_number: "",
            phone_extension: "",
            fax_area_code: "",
            fax_number: "",
            email_address: "",
        }
    }
    const [masterTaxFormState, setMasterTaxFormState] = useState<IMasterTaxSetup>(masterTaxInitialForm);
    const [formState, setFormState] = useState<IMasterTaxCompanySetup>(masterTaxFormState.company_setup);

    useEffect(() => {
        dispatch(masterTaxSetupStateSliceActions.clearState());
        dispatch(getAgencyDetails());
        dispatch(getMasterTaxSetup());
        return () => { }
    }, []);

    useEffect(() => {
        if (masterTaxState.loading === LoadingType.succeeded && masterTaxState.data) {
            setMasterTaxFormState(masterTaxState.data);
            const companySetupData = masterTaxState.data.company_setup;
            if (companySetupData !== null) {
                setFormState(getFormData(companySetupData, undefined))
            }
            else {
                if (agencyDetailsState.agency) {
                    setFormState(getFormData(undefined, agencyDetailsState.agency))
                }
            }
        }
    }, [masterTaxState.loading, masterTaxState.data, agencyDetailsState.agency]);

    useEffect(() => {
        if (masterTaxState.update.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage("Company setup completed successfully!"));
            dispatch(masterTaxSetupStateSliceActions.clearState());
            // setFormState()
            dispatch(getMasterTaxSetup());
        }
    }, [masterTaxState.update.loading]);

    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);

    const literalArray = ["quarterly_wage_reporting", "worksite_reporting",
        "year_end_employee_filing", "wage_attachment", "cash_service_level", "kind_of_employer", "the_944_filter", "service_level", "fein_type",
    ];
    const handleFieldChange = (id: string, val: string | null) => {
        if (literalArray.includes(id) && val === "") {
            val = null;
        }
        setFormState({
            ...formState,
            [id]: val
        })
    }

    const handleSave = () => {
        setCheckValidationOnSubmit(true);
        if (_isFormValid() && formState) {
            dispatch(updateMasterTaxSetup({
                ...masterTaxFormState,
                company_setup: formState,
            }));

            setCheckValidationOnSubmit(false);
        }
    };
    const handleCancel = () => {
        if (masterTaxState.data?.company_setup) {
            setFormState(masterTaxState.data.company_setup);
        }
        setCheckValidationOnSubmit(true)
    }
    const handleClearError = () => {
        dispatch(masterTaxSetupStateSliceActions.clearState());
    }
    function _isFormValid() {
        const { agency_legal_name,
            contact_first_name,
            email_address,
            contact_last_name, phone_area_code, fein,
            effective_date, company_start_date, quarterly_wage_reporting,
            worksite_reporting, year_end_employee_filing, wage_attachment,
            name_control,
            phone_number, payroll_code, address_line1, city, state, zip_code } = formState;

        if (
            agency_legal_name === "" ||
            contact_first_name === "" ||
            contact_last_name === "" ||
            email_address === "" ||
            phone_area_code === "" ||
            ((formState.fein_type !== "Y" && formState.fein_type !== "C") && fein === "") ||
            effective_date === 0 || effective_date === null ||
            company_start_date === 0 || company_start_date === null ||
            quarterly_wage_reporting === "" ||
            worksite_reporting === "" ||
            year_end_employee_filing === "" ||
            wage_attachment === "" ||
            quarterly_wage_reporting === null ||
            worksite_reporting === null ||
            year_end_employee_filing === null ||
            wage_attachment === null ||
            name_control === "" ||
            payroll_code === "" ||
            phone_number === "" ||
            address_line1 === "" ||
            city === "" ||
            state === "" ||
            zip_code === ""
        ) {
            return false;
        }


        return true;
    }
    const formValidators = (value: { text: string; field: string }) => {
        switch (value.field) {
            case "agency_legal_name":
            case "contact_first_name":
            case "address_line1":
            case "contact_last_name": {
                if (value.text === "" || value.text === null) return t("validators.required");
                if (RegxPattern.username.test(value.text) === false)
                    return t("validators.enterValidName");
                return null;
            }
            case "city":
            case "state":
            case "payroll_code": {
                if (value.text === "" || value.text === null) return t("validators.required");
                if (RegxPattern.username.test(value.text) === false)
                    return t("validators.enterValidName");
                return null;
            }
            case "email_address": {
                if (value.text === "" || value.text === null) return t("validators.required");
                if (RegxPattern.email.test(value.text) === false)
                    return t("validators.enterValidEmail");
                return null;
            }
            case "phone_number": {
                if (value.text === "" || value.text === null) return t("validators.required");
                if (RegxPattern.usPhoneWithoutCode.test(value.text) === false)
                    return t("validators.enterValidPhoneNumberWithoutCode");
                return null;
            }
            case "fax_number": {
                if (value.text === "" || value.text === null) return t("validators.required");
                if (RegxPattern.usPhoneWithoutCode.test(value.text) === false)
                    return t("validators.enterValidFaxNumber");
                return null;
            }
            case "phone_area_code":
            case "fax_area_code": {
                if (value.text === "" || value.text === null) return t("validators.required");
                if (RegxPattern.code.test(value.text) === false)
                    return t("validators.enterValidCode");
                return null;
            }
            case "zip_code": {
                if (value.text === "" || value.text === null) return t("validators.required");
                if (RegxPattern.zipCode.test(value.text) === false)
                    return t("validators.enterValidZipCode");
                return null;
            }
            case "fein": {
                if (formState.fein_type !== "Y" && formState.fein_type !== "C") {
                    if (value.text === "" || value.text === null) return t("validators.required");
                    if (RegxPattern.einWithoutDash.test(value.text) === false)
                        return t("validators.enterValidEINwithoutDash");
                }
                return null;
            }
            case "effective_date":
            case "company_start_date": {

                if (value.text === null || parseInt(value.text) === 0) return t("validators.required");
                return null;
            }
            case "quarterly_wage_reporting":
            case "worksite_reporting":
            case "year_end_employee_filing":
            case "wage_attachment":
            case "name_control":
            case "phone_extension": {
                if (value.text === "" || value.text === null) return t("validators.required");
                return null;
            }
            default: {
                return null;
            }
        }
    }
    return (
        <div className="cs-container">

            {masterTaxState.loading === LoadingType.pending ?
                <SpinnerScreen />
                :
                <div className="cs-content">
                    <div className="cmpny-details">
                        <span className="cs-title">{t('company_details')}</span>
                        <div className="cs-cpmny-row">
                            <div className="cs-input-row">
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"agency_legal_name"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.agency_legal_name ?? ""}
                                        label={t("company_name")}
                                        placeholder="Agency Name"
                                        validations={formValidators}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        required
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"company_dba_name"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.company_dba_name ?? ""}
                                        label={t("company_dba_name")}
                                        placeholder={t('company_dba_name_ph')}
                                        validations={formValidators}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"in_care_of_name"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.in_care_of_name ?? ""}
                                        label={t("in_care_of_name")}
                                        placeholder={t('in_care_of_name')}
                                        validations={formValidators}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"payroll_code"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.payroll_code ?? ""}
                                        label={t("payroll_code")}
                                        placeholder={t('')}
                                        required
                                        validations={formValidators}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"address_line1"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.address_line1 ?? ""}
                                        label={t("address_line1")}
                                        placeholder={t('')}
                                        required
                                        validations={formValidators}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                </div>

                            </div>
                            <div className="cs-input-row">
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"address_line2"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.address_line2 ?? ""}
                                        label={t("address_line2")}
                                        placeholder={t('')}
                                        validations={formValidators}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"city"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.city ?? ""}
                                        label={t("city")}
                                        placeholder={t("")}
                                        required
                                        validations={formValidators}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <CustomFormSelect
                                        list={US_states.map((doc) => ({
                                            label: doc.name,
                                            value: doc.code,
                                        }))}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name={"state"}
                                        value={formState.state}
                                        label={t("state")}
                                        placeholder={"Select"}
                                        required
                                        validations={formValidators}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        onChange={(val) => handleFieldChange('state', val)}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"zip_code"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.zip_code ?? ""}
                                        label={t("zip_code")}
                                        placeholder={t('')}
                                        required
                                        maxLength={5}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        validations={formValidators}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"psd_code"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.psd_code ?? ""}
                                        label={t("psd_code")}
                                        placeholder={t('psd_code')}
                                        validations={formValidators}
                                        disabled={formState.state !== "PA"}
                                    />
                                </div>

                            </div>
                            <div className="cs-input-row">
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"name_control"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.name_control ?? ""}
                                        label={t("name_control")}
                                        placeholder={t('name_control')}
                                        validations={formValidators}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <DateInput
                                        id={"company_start_date"}
                                        onChange={handleFieldChange}
                                        placeholder={"mm/dd/yyyy"}
                                        value={formState.company_start_date}
                                        validations={formValidators}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        label={t("company_start_date")}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        required
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <CustomFormSelect
                                        list={yesNo.map((doc) => ({
                                            label: doc.value,
                                            value: doc.id,
                                        }))}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name={"quarterly_wage_reporting"}
                                        value={formState.quarterly_wage_reporting}
                                        label={t("quarterly_wage_reporting")}
                                        placeholder={"Select"}
                                        onChange={(val) => handleFieldChange('quarterly_wage_reporting', val)}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        validations={formValidators}
                                        isClearable={false}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <CustomFormSelect
                                        list={yesNo.map((doc) => ({
                                            label: doc.value,
                                            value: doc.id,
                                        }))}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name={"worksite_reporting"}
                                        value={formState.worksite_reporting}
                                        label={t("worksite_reporting")}
                                        placeholder={"Select"}
                                        onChange={(val) => handleFieldChange('worksite_reporting', val)}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        validations={formValidators}
                                        isClearable={false}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <CustomFormSelect
                                        list={yesNo.map((doc) => ({
                                            label: doc.value,
                                            value: doc.id,
                                        }))}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name={"year_end_employee_filing"}
                                        value={formState.year_end_employee_filing}
                                        label={t("year_end_employee_filing")}
                                        placeholder={"Select"}
                                        onChange={(val) => handleFieldChange('year_end_employee_filing', val)}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        validations={formValidators}
                                        isClearable={false}
                                    />
                                </div>

                            </div>
                            <div className="cs-input-row">
                                <div className="cs-input-holder">
                                    <CustomFormSelect
                                        list={yesNo.map((doc) => ({
                                            label: doc.value,
                                            value: doc.id,
                                        }))}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name={"wage_attachment"}
                                        value={formState.wage_attachment}
                                        label={t("wage_attachment")}
                                        placeholder={"Select"}
                                        onChange={(val) => handleFieldChange('wage_attachment', val)}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        validations={formValidators}
                                        isClearable={false}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <CustomFormSelect
                                        list={cashServiceLevel.map((doc) => ({
                                            label: doc.value,
                                            value: doc.id,
                                        }))}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name={"cash_service_level"}
                                        value={formState.cash_service_level}
                                        label={t("cash_service_level")}
                                        placeholder={"Select"}
                                        onChange={(val) => handleFieldChange('cash_service_level', val)}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <CustomFormSelect
                                        list={employerKind.map((doc) => ({
                                            label: doc.value,
                                            value: doc.id,
                                        }))}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name={"kind_of_employer"}
                                        value={formState.kind_of_employer}
                                        label={t("kind_of_employer")}
                                        placeholder={"Select"}
                                        onChange={(val) => handleFieldChange('kind_of_employer', val)}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"naics_code"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.naics_code ?? ""}
                                        label={t("naics_code")}
                                        placeholder={t('naics_code')}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <DateInput
                                        id={"effective_date"}
                                        onChange={handleFieldChange}
                                        placeholder={"mm/dd/yyyy"}
                                        value={formState.effective_date}
                                        label={t("effective_date")}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        required
                                        validations={formValidators}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                </div>

                            </div>
                            <div className="cs-input-row">
                                <div className="cs-input-holder">
                                    <CustomFormSelect
                                        list={feinType.map((doc) => ({
                                            label: doc.value,
                                            value: doc.id,
                                        }))}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name={"fein_type"}
                                        value={formState.fein_type}
                                        label={t("fein_type")}
                                        placeholder={"Select"}
                                        onChange={(val) => handleFieldChange('fein_type', val)}

                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"fein"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.fein ?? ""}
                                        label={t("fein")}
                                        placeholder={t("fein")}
                                        validations={formValidators}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                        required={(formState.fein_type !== "Y" && formState.fein_type !== "C")}
                                        onKeyUp={enforceFormat}
                                        onKeyDown={enforceFormat}
                                        maxLength={9}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <CustomFormSelect
                                        list={yesNo.map((doc) => ({
                                            label: doc.value,
                                            value: doc.id,
                                        }))}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name={"the_944_filter"}
                                        value={formState.the_944_filter}
                                        label={t("the_944_filter")}
                                        placeholder={"Select"}
                                        onChange={(val) => handleFieldChange('the_944_filter', val)}
                                    />
                                </div>

                                <div className="cs-input-holder sd">
                                    <CustomFormSelect
                                        list={serviceLevel.map((doc) => ({
                                            label: doc.value,
                                            value: doc.id,
                                        }))}
                                        customStyle={{ width: "-webkit-fill-available" }}
                                        name={"service_level"}
                                        value={formState.service_level}
                                        label={t("service_level")}
                                        placeholder={"Select"}
                                        onChange={(val) => handleFieldChange('service_level', val)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-details">
                        <span className="cs-title">{t('contact_details')}</span>
                        <div className="cs-cmpny-row">
                            <div className="cs-input-row">
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"contact_first_name"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.contact_first_name ?? ""}
                                        label={t("contact_first_name")}
                                        placeholder={t('first_name')}
                                        validations={formValidators}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"contact_middle_name"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.contact_middle_name ?? ""}
                                        label={t("contact_middle_name")}
                                        placeholder={t('middle_name')}
                                        validations={formValidators}
                                        maxLength={1}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"contact_last_name"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.contact_last_name ?? ""}
                                        label={t("contact_last_name")}
                                        placeholder={t('last_name')}
                                        validations={formValidators}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"phone_area_code"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.phone_area_code ?? ""}
                                        label={t("phone_area_code")}
                                        placeholder={t('123')}
                                        validations={formValidators}
                                        maxLength={3}
                                        onKeyUp={enforceFormat}
                                        onKeyDown={enforceFormat}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"phone_number"}
                                        onChange={handleFieldChange}
                                        type={"tel"}
                                        value={formState.phone_number ?? ""}
                                        label={t("phone_number")}
                                        placeholder={t('123-4567')}
                                        validations={formValidators}
                                        maxLength={8}
                                        onKeyUp={formatToPhoneWithoutCode}
                                        onKeyDown={enforceFormat}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                </div>
                            </div>
                            <div className="cs-input-row">
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"phone_extension"}
                                        onChange={handleFieldChange}
                                        type={"tel"}
                                        value={formState.phone_extension ?? ""}
                                        label={t("phone_extension")}
                                        placeholder={t('phone_extension')}
                                        validations={formValidators}
                                        maxLength={5}
                                        onKeyUp={enforceFormat}
                                        onKeyDown={enforceFormat}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"fax_area_code"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.fax_area_code ?? ""}
                                        label={t("fax_area_code")}
                                        placeholder={t('123')}
                                        validations={formValidators}
                                        maxLength={3}
                                        onKeyUp={enforceFormat}
                                        onKeyDown={enforceFormat}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"fax_number"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.fax_number ?? ""}
                                        label={t("fax_number")}
                                        placeholder={t('123-4567')}
                                        validations={formValidators}
                                        maxLength={8}
                                        onKeyUp={formatToPhoneWithoutCode}
                                        onKeyDown={enforceFormat}
                                    />
                                </div>
                                <div className="cs-input-holder">
                                    <FormInput
                                        id={"email_address"}
                                        onChange={handleFieldChange}
                                        type={"text"}
                                        value={formState.email_address ?? ""}
                                        label={t("email")}
                                        validations={formValidators}
                                        placeholder={""}
                                        required
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                </div>
                                <div className="cs-input-holder">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="cs-actions-row">
                <div className="">
                    {masterTaxState.error && <div className="error-holder">
                        <ApiError message={masterTaxState.error.message} onClose={handleClearError} />
                    </div>}
                    {masterTaxState.update.error && <div className="error-holder">
                        <ApiError message={masterTaxState.update.error.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="cs-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("cancel")}
                            enable
                            backgroundColor={THEME.secondaryColor4}
                            onClick={handleCancel}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            loading={masterTaxState.update.loading === LoadingType.pending}
                            name={t("save")}
                            backgroundColor={THEME.buttonColor1}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MasterTaxCompanySetupPage;