import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getPasswordExpirationDays = createAsyncThunk<
  number,
  void,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@admin_center/system_configuration/password_expiration_days/get",
  async (_, thunkAPI) => {
    try {
      const res = await AdminCenterAPI.getAgencyDetails();
      return res.data["configuration"]["password_expiration_days"] as number;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const updatePasswordExpirationDays = createAsyncThunk<
  string,
  { password_expiration_days: number },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@admin_center/system_configuration/password_expiration_days/update",
  async (data, thunkAPI) => {
    try {
      const res = await AdminCenterAPI.updatePasswordExpirationDays(data);
      return res.data as string;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);
