import { combineReducers } from "@reduxjs/toolkit";
import { universalDirectoryReducers } from "./universal_directory";
import { assignmentDetailsReducers } from "./details";
export const assignmentsReducers = combineReducers({
    'universalDirectory': universalDirectoryReducers,
    'details': assignmentDetailsReducers,
});

export * from './universal_directory';

export * from './details';