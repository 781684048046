import { createEntityAdapter, createSlice, EntityAdapter, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, IJobOrderSuccessProfile } from "../../../../../interfaces";
import * as thunk from "./success_profile_info_thunk";

interface UpdateState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialUpdateState: UpdateState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


export interface SingleJobSuccessProfileState {
    data: IJobOrderSuccessProfile | null,
    error?: AppError | null,
    loading: LoadingType,
    update: UpdateState,
}



const singleJobSuccessProfileInitialState: SingleJobSuccessProfileState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
    update: initialUpdateState,
}





export interface JobSuccessProfileState {
    [key: string]: SingleJobSuccessProfileState,
}


const initialState: JobSuccessProfileState = {}


function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

function getResponseSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}

export const jobSuccessProfileSlice = createSlice({
    name: 'successProfile',
    initialState: initialState,
    reducers: {
        clearJobSuccessProfileAddOptionError(state, action: PayloadAction<{id: string, type: string}>) {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.type]: {
                        ...state[action.payload.id][action.payload.type],
                        add: {
                            ...state[action.payload.id][action.payload.type].add,
                            ...initialUpdateState,
                        },

                    },
                }
            };
        },

        
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getJobOrderSuccessProfile.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getLoadingState(state[action.meta.arg]),
                        update: initialUpdateState,
                    }
                })
            )
            .addCase(
                thunk.getJobOrderSuccessProfile.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getErrorState(state[action.meta.arg], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getJobOrderSuccessProfile.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getSuccessState(state[action.meta.arg], action.payload),
                    }
                })
            )


            

            

            /// update service profile [POST]
            .addCase(
                thunk.updateJobOrderSuccessProfile.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.id]: {
                        ...state[action.meta.arg.id],
                        update: {
                            ...getLoadingState(state[action.meta.arg.id].update),
                        },
                    }
                })
            )
            .addCase(
                thunk.updateJobOrderSuccessProfile.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.id]: {
                        ...state[action.meta.arg.id],
                        update: {
                            ...getErrorState(state[action.meta.arg.id].update, action.payload),
                        },
                    }
                })
            )
            .addCase(
                thunk.updateJobOrderSuccessProfile.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.id]: {
                        ...state[action.meta.arg.id],
                        update: {
                            ...getResponseSuccessState(state[action.meta.arg.id].update, action.payload),
                        },
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const jobSuccessProfileSliceActions = jobSuccessProfileSlice.actions;
export const selectState = (state: JobSuccessProfileState) => state;
export const selectSingleJobSuccessProfileState = (state: JobSuccessProfileState, id: string): SingleJobSuccessProfileState => state[id];

export const selectInitialSingleJobSuccessProfileState = (): SingleJobSuccessProfileState => ({
    data: null,
    loading: LoadingType.pending,
    error: null,
    update: initialUpdateState
});