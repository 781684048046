import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { ThirdPartyDeductionType } from "../../../../interfaces/third_party_deduction_type";
import * as thunk from './deduction_details_page_thunk';


export interface SingleDeductionState {
    data: ThirdPartyDeductionType | null;
    error?: AppError | null;
    loading: LoadingType;
}

const singleDeductionInitialState: SingleDeductionState = {
    data: null,
    error: null,
    loading: LoadingType.idle,

}

export interface DedutionDetailsState {
    [key: string]: SingleDeductionState,
}

const initialState: DedutionDetailsState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

function getResponseSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        response: payload,
        error: null,
    }
}

export const deductionDetailsSlice = createSlice({
    name: 'deductionDetails',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getDeductionById.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getLoadingState(state[action.meta.arg]),
                    }
                })
            )
            .addCase(
                thunk.getDeductionById.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getErrorState(state[action.meta.arg], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getDeductionById.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...getSuccessState(state[action.meta.arg], action.payload),
                    }
                })
            )
    }
});

export const deductionDetailsSliceActions = deductionDetailsSlice.actions;
export const selectState = (state: DedutionDetailsState) => state;
export const selectDeductionState = (state: DedutionDetailsState, id: string): SingleDeductionState => state[id];

export const selectInitialDeductionState = (): SingleDeductionState => ({
    data: null,
    loading: LoadingType.pending,
    error: null,
});