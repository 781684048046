import { combineReducers } from "@reduxjs/toolkit";
import { accountManagersListSlice } from "./account_managers_list/account_managers_list_reducer";
import { businessSectorListSlice } from "./business_sector_list/business_sector_list_reducer";
import { companiesListSlice } from "./companies_list/companies_list_reducer";
import { departmentsListSlice } from "./department_list/department_list_reducer";
import { manageComapnySlice } from "./manage_company/manage_company_reducer";
import { manageDepartmentSlice } from "./manage_department/manage_department_reducer";
import { recruitersListSlice } from "./recruiters_list/recruiters_list_reducer";
import { companyProfileSlice } from "./company_profile/company_profile_reducer";
import { apolloCompaniesListSlice } from "../../apollo_search_import/companies_search_import/companies_search_import_reducer";


export const companiesUniversalDirectoryReducers = combineReducers({
    [companiesListSlice.name]: companiesListSlice.reducer,
    [departmentsListSlice.name]: departmentsListSlice.reducer,
    [manageComapnySlice.name]: manageComapnySlice.reducer,
    [manageDepartmentSlice.name]: manageDepartmentSlice.reducer,
    [accountManagersListSlice.name]: accountManagersListSlice.reducer,
    [recruitersListSlice.name]: recruitersListSlice.reducer,
    [businessSectorListSlice.name]: businessSectorListSlice.reducer,
    [companyProfileSlice.name]: companyProfileSlice.reducer,
    [apolloCompaniesListSlice.name]: apolloCompaniesListSlice.reducer,

});


/// [Comapnies] list
export * from './companies_list/companies_list_selector';
export * from './companies_list/companies_list_thunk';

/// [Comapnies] [Departments] list
export * from './department_list/department_list_selector';
export * from './department_list/department_list_thunk';

/// [Companies] Manage company
export * from './manage_company/manage_company_selector';
export * from './manage_company/manage_company_thunk';

/// [Companies] Manage department
export * from './manage_department/manage_department_selector';
export * from './manage_department/manage_department_thunk';

/// [Companies] Account managers
export * from './account_managers_list/account_managers_list_selector';
export * from './account_managers_list/account_managers_list_thunk';

/// [Companies] Recruiters
export * from './recruiters_list/recruiters_list_selector';
export * from './recruiters_list/recruiters_list_thunk';

/// [Companies] Business sector
export * from './business_sector_list/business_sector_list_selector';
export * from './business_sector_list/business_sector_list_thunk';

///[Companies] Company Profile
export * from './company_profile/company_profile_selector';
export * from './company_profile/company_profile_thunk';


export * from '../../apollo_search_import/companies_search_import/companies_search_import_thunk';
export * from '../../apollo_search_import/companies_search_import/companies_search_import_selector';