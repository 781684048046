import { animated, useSpring } from "@react-spring/web";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import './action_dialog_holder.scss';


interface Props {
    visible: boolean,
    loading?: boolean,
    wrapperWidth: any,
    onClose: () => void,
    onDissmiss?: () => void,
    onShown?: () => void,
    children: any,
    customStyle?: React.CSSProperties,
    maxWrapperWidth?: any | undefined,
}

interface RefProps {
    closeAction: (onCloseSuccess?: () => void) => void
}

export const ActionDialogHolder = forwardRef((props: Props, ref: React.Ref<RefProps>) => {
    const {
        visible,
        loading,
        wrapperWidth,
        maxWrapperWidth,
        onClose,
        onDissmiss,
        onShown,
        children,
        customStyle,
    } = props;

    // // const width = useSharedValue<number>(0)
    // const scale = useSharedValue<number>(0);
    // const opacity = useSharedValue<number>(0);
    const scaleRef = useRef<number>(0.5);
    const [style, animate] = useSpring(() => ({ scale: scaleRef.current, opacity: 0 }))
    const { t, i18n } = useTranslation();

    useEffect(() => {
        animate.start({ scale: 1, opacity: 1 });
        scaleRef.current = 1;
        return () => { }
    }, [])

    useImperativeHandle(ref, () => ({
        closeAction: (onCloseSuccess?: () => void) => closeAction(onCloseSuccess)
    }));



    return (
        <div
            className="adh-container"
            style={styles.container}
            onClick={() => {
                // handleClose()
                //background press ignore
            }}
        >
            <animated.div className={"adh-content-container"} style={{ minWidth: wrapperWidth, width: 'fit-content', maxHeight: '93%', maxWidth: maxWrapperWidth ? maxWrapperWidth :'90%', ...customStyle, ...style, }}>
                <div className="adh-touchable-holder" >{props.children}</div>
            </animated.div>
        </div>
    );

    function handleClose() {
        if (loading) return;
        closeAction();
    }

    function closeAction(onCloseSuccess?: () => void) {
        animate.start({ scale: 0.5 , opacity: 0 });
        scaleRef.current = 0.5;
        setTimeout(() => {
            if (onCloseSuccess) onCloseSuccess();
            else onClose();
        }, 300)
    }
})

export type ActionDialogHolderType = React.ElementRef<typeof ActionDialogHolder>;


const styles: { [key: string]: React.CSSProperties } = ({
   

})