import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ICompanyEngagement, ITalentEngagement } from "../../../../interfaces";
import * as thunk from "./dashboard-engagement-details-thunk";
import { IContactEngagement } from "../../../../interfaces/contacts_engagement";

export interface DashboardEngagementState extends EntityState<any> {
    error?: AppError | null,
    loading: LoadingType,
    customersData: ICompanyEngagement[],
    candidatesData: ITalentEngagement[],
    contactsData: IContactEngagement[],
}

const DashboardEngagementAdapter: EntityAdapter<any> = createEntityAdapter<any>({
    selectId: (doc) => doc.id,
})

const initialState: DashboardEngagementState = DashboardEngagementAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    customersData: [],
    candidatesData: [],
    contactsData: []
})


export const dashboardEngagementSlice = createSlice({
    name: 'dashboardEngagement',
    initialState: initialState,
    reducers: {
        clearDashboardEngagementError(state) {
            return { ...state, error: null };
        },
        clearDashboardEngagementCustomersState(state) {
            return DashboardEngagementAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getDashboardEngagementCustomerDetails.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getDashboardEngagementCustomerDetails.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getDashboardEngagementCustomerDetails.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, customersData: action.payload }))

            .addCase(thunk.getDashboardEngagementCandidateDetails.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getDashboardEngagementCandidateDetails.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getDashboardEngagementCandidateDetails.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, candidatesData: action.payload }))

            .addCase(thunk.getDashboardEngagementContactsDetails.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getDashboardEngagementContactsDetails.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getDashboardEngagementContactsDetails.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, contactsData: action.payload }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearDashboardEngagementError, clearDashboardEngagementCustomersState } = dashboardEngagementSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = DashboardEngagementAdapter.getSelectors();
export const selectState = (state: DashboardEngagementState) => state;
export const selectCustomersData = (state: DashboardEngagementState) => state.customersData;
export const selectContactsData = (state: DashboardEngagementState) => state.contactsData;
export const selectCandidatesData = (state: DashboardEngagementState) => state.candidatesData;


