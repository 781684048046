import { useTranslation } from 'react-i18next';
import './board.scss';

interface BoardProps {
    header: React.ReactNode,
    children: React.ReactNode,
    customStyle?: React.CSSProperties,
    customContentStyle?: React.CSSProperties,
}

const Board: React.FunctionComponent<BoardProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="board-container" style={{ ...props.customStyle }}>
            <div className="header">
                {props.header}
            </div>
            <div className="content" style={{ ...props.customContentStyle }}>
                {props.children}
            </div>
        </div>
    )
}

export default Board;