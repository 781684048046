import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ActionDialogHolder,
    ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, {
    FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../../enums";
import { OnBoardingIcon } from "../../../../icons";
import { IOnboardingDocsReviewStatus, IProfileDropdown, ITalent, ITalentOBW4I9Data } from "../../../../interfaces";
import {
    getUploadW4UrlThunk,
    selectW4State,
    updateTalentW4I9Details,
    uploadW4Thunk,
    useAppDispatch,
    useAppSelector,
} from "../../../../redux/store";
import { CustomButton } from "../../../../utils";
import { WebViewerInstance } from "@pdftron/webviewer";
import "./w4.scss";
import PDFReader from "../../../../components/pdf_reader/pdf_reader";
import { w4SliceActions } from "../../../../redux/onboarding_portal/w4/w4_reducer";
import ApiError from "../../../../components/api_error";
import { getDateString } from "../../../../variables";
import OnboardingReviewForm, { IReviewForm } from "../../forms/onboarding_review_form/onboarding_review_form";
import { W4FormFields } from "../../../../onboarding/pdf_fields/w4";

interface Props {
    visible: boolean;
    type: string;
    title: string;
    talentId: string;
    state: IOnboardingDocsReviewStatus;
    successTitle: string;
    onClose: () => void;
    onDissmiss?: () => void;
    onSuccessClose: (type: string, value?: any) => void;
    talentData: ITalent | null;
    today: number;
    currentLoginUserName: string;
    recruitersList: IProfileDropdown[];

}

const W4Popup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        type,
        state,
        talentId,
        title,
        onClose,
        onDissmiss,
        onSuccessClose,
        talentData,
        today,
        currentLoginUserName,
        recruitersList
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { uploadFile, uploadURL } = useAppSelector((state) =>
        selectW4State(state)
    );

    const actionRef = useRef<ActionDialogHolderType>(null);
    const instance = useRef<WebViewerInstance>();
    const reviewedByData = recruitersList.filter(
        (recruiter) =>
            state && recruiter.id === state?.w4_document?.reviewed_by
    );
    const reviewedBy = reviewedByData
        .map((recruiter) => `${recruiter.first_name} ${recruiter.last_name}`)
        .join(", ");
    const [formState, setFormState] = useState<IReviewForm>({
        name:
            state && talentData
                ? talentData?.first_name + " " + talentData?.last_name
                : "",
        recruiter_completed_review:
            state && state?.w4_document?.recruiter_completed_review
                ? "Reviewed"
                : "Not Reviewed",
        reviewed_on_timestamp:
            state && state?.w4_document?.reviewed_on_timestamp !== null
                ? getDateString(state?.w4_document?.reviewed_on_timestamp, "mm/dd/yyyy")
                : getDateString(today, "mm/dd/yyyy"),
        reviewed_by:
            state && state?.w4_document?.reviewed_by !== null
                ? (reviewedBy ?? "")
                : currentLoginUserName,
    });

    const [talentW4Data, setTalentW4Data] = useState<ITalentOBW4I9Data>({
        talent_id: talentId,
        w4_data: {
            ssn: '',
            street_address: '',
            filing_status: '',
            multiple_jobs: true,
            dependent_and_other_credits: 0,
            other_income: 0,
            deductions: 0,
            extra_withholding: 0,
            city: "",
            state: "",
            zip_code: ""
        },
        i9_data: null
    });
    /// GET Upload url & initiate upload file
    useEffect(() => {
        if (uploadURL.loading === LoadingType.succeeded && uploadURL.response) {
            handleUploadFile(uploadURL.response.url);
        }
    }, [uploadURL.loading]);

    /// UPLOAD File success
    useEffect(() => {
        if (uploadFile.loading === LoadingType.succeeded) {
            onSuccessClose(type);
        }
        return () => {
            if (instance.current && state.w4_document.url) {
                cleanPDFState(instance.current);
            }
            dispatch(w4SliceActions.clearState());
        };
    }, [uploadFile.loading]);

    /// Reload document on component re-render
    useEffect(() => {
        if (instance.current && state.w4_document.url) {
            loadPDFDocument(instance.current, state.w4_document.url);
        }
    }, [state.w4_document.url]);

    function handleOnInstance(value: WebViewerInstance) {
        instance.current = value;
        instance.current.UI.disableElements(['toolsHeader']);
        loadPDFDocument(value, state.w4_document.url);
        instance.current.UI.disableElements(['toolsHeader']);

    }
    const fieldsToEnableForRecruiter = [
        "topmostSubform[0].Page1[0].f1_13[0]",
        "topmostSubform[0].Page1[0].f1_14[0]",
        "topmostSubform[0].Page1[0].f1_15[0]"
    ];

    const parseLocation = (input) => {
        // Split by commas and trim whitespace
        const parts = input.split(',').map(part => part.trim());

        // Initialize default values
        let city = '';
        let state = '';
        let zipcode = '';

        if (parts.length === 3) {
            // Assume format: "City, State, Zipcode"
            [city, state, zipcode] = parts;
        } else if (parts.length === 2) {
            // Assume format: "State, Zipcode" or "City, Zipcode"
            const [first, second] = parts;
            if (second.match(/^\d{5}$/)) {
                // If the second part is a zipcode (5 digits)
                state = first;
                zipcode = second;
            } else {
                // Assume "City, State Zipcode"
                city = first;
                [state, zipcode] = second.split(' ').filter(Boolean);
            }
        } else if (parts.length === 1) {
            // Assume format: "Zipcode"
            zipcode = parts[0];
        }

        return { city, state, zipcode };
    };
    const filingStatusOptions = {
        'topmostSubform[0].Page1[0].c1_1[0]': 'Single or Married filing separately',
        'topmostSubform[0].Page1[0].c1_1[1]': 'Married filing jointly or Qualifying surviving spouse',
        'topmostSubform[0].Page1[0].c1_1[2]': 'Head of household'
    };

    function loadPDFDocument(value: WebViewerInstance, url: string) {
        const { UI, Core } = value;
        const { documentViewer, annotationManager, Annotations } = Core;
        annotationManager.addEventListener(
            "annotationChanged",
            (annotations, action, { imported }) => {
                // if the annotation change occurs because of an import then
                // these are fields from inside the document
                if (imported && action === "add") {
                    annotations.forEach(function (annot) {
                        if (
                            annot instanceof Annotations.WidgetAnnotation
                        ) {
                            annot.fieldFlags.set("ReadOnly", true); // this is to enable the field
                        }
                        if (
                            annot instanceof Annotations.WidgetAnnotation &&
                            fieldsToEnableForRecruiter.includes(annot.fieldName)
                        ) {
                            annot.fieldFlags.set("ReadOnly", false); // this is to enable the field
                        }
                    });
                }

            }
        );
        documentViewer.addEventListener('documentLoaded', () => {
            documentViewer.getAnnotationsLoadedPromise().then(() => {
                // iterate over fields
                const fieldManager = annotationManager.getFieldManager();
                const formFields = documentViewer
                    .getAnnotationManager()
                    .getFieldManager();

                const formFieldsData = formFields.getFields();

                // Iterate over each field to get the field name and value
                formFieldsData.forEach(field => {
                    const fieldName = field.name; // Get the field name
                    const fieldValue = field.getValue().toString(); // Get the field value
                    setTalentW4Data(prevState => {
                        const newW4Data = {
                            ...prevState.w4_data // Spread current w4_data
                        };
  
                        if (fieldName === "topmostSubform[0].Page1[0].Step1a[0].f1_04[0]") {
                            const { city, state, zipcode } = parseLocation(fieldValue);
                            newW4Data.city = city.trim();
                            newW4Data.state = state.trim();
                            newW4Data.zip_code = zipcode.trim();
                        }
                        else if (fieldName === "topmostSubform[0].Page1[0].Step1a[0].f1_03[0]") {
                            newW4Data.street_address = (fieldValue); // Default to 0 if NaN
                        }
                        else if (fieldName === "topmostSubform[0].Page1[0].f1_05[0]") {
                            newW4Data.ssn = (fieldValue).replaceAll("-", ""); // Default to 0 if NaN
                        }
                        else if (fieldName === "topmostSubform[0].Page1[0].f1_09[0]") {
                            newW4Data.dependent_and_other_credits = parseFloat(fieldValue) || 0; // Default to 0 if NaN
                        }
                        else if (fieldName === "topmostSubform[0].Page1[0].f1_10[0]") {
                            newW4Data.other_income = parseFloat(fieldValue) || 0; // Default to 0 if NaN
                        }
                        else if (fieldName === "topmostSubform[0].Page1[0].f1_11[0]") {
                            newW4Data.deductions = parseFloat(fieldValue) || 0; // Default to 0 if NaN
                        }
                        else if (fieldName === "topmostSubform[0].Page1[0].f1_12[0]") {
                            newW4Data.extra_withholding = parseFloat(fieldValue) || 0; // Default to 0 if NaN
                        }
                        else if (fieldName === "topmostSubform[0].Page1[0].c1_2[0]") {
                            newW4Data.multiple_jobs = (fieldValue === (1).toString() ? true : false);
                        }
                        else if (Object.keys(filingStatusOptions).includes(fieldName)) {
                            if (['1', '2', '3'].includes(fieldValue)) {
                                newW4Data.filing_status = filingStatusOptions[fieldName];
                            }
                        }

                        return {
                            ...prevState,
                            w4_data: newW4Data
                        };
                    });

                });

                // showing the sum of step 3 fields into the total field
                const field1 = formFieldsData.find((field) => {
                    return (field.name === "topmostSubform[0].Page1[0].Step3_ReadOrder[0].f1_06[0]")
                })?.getValue();
                const field2 = formFieldsData.find((field) => {
                    return (field.name === "topmostSubform[0].Page1[0].Step3_ReadOrder[0].f1_07[0]")
                })?.getValue();
                const sumField = fieldManager.getField("topmostSubform[0].Page1[0].f1_09[0]");
                const field1Value = field1 !== undefined && field1 !== '' ? parseFloat(field1.toString()) : 0;
                const field2Value = field2 !== undefined && field2 !== '' ? parseFloat(field2.toString()) : 0;

                const sum = field1Value + field2Value;

                sumField.setValue(sum !== 0 ? sum.toString() : "");

            });
        });
        UI.setFitMode(UI.FitMode.FitWidth);
        UI.loadDocument(url);
    }

    function cleanPDFState(value: WebViewerInstance) {
        const { documentViewer } = value.Core;
        documentViewer.removeEventListener("documentLoaded");
        // documentViewer.removeEventListener('fieldChanged');
    }

    function handleClose() {
        closePopup(onClose);
    }

    async function handleUploadFile(url: string) {
        if (instance.current) {
            const { documentViewer, annotationManager } = instance.current.Core;
            const doc = documentViewer.getDocument();
            const xfdfString = await annotationManager.exportAnnotations();
            const data = await doc.getFileData({
                // saves the document with annotations in it
                xfdfString,
            });
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: "application/pdf" });
            dispatch(uploadW4Thunk({ url: url, file: blob }));
        }
    }

    const loading =
        uploadURL.loading === LoadingType.pending ||
        uploadFile.loading === LoadingType.pending;

    const error = uploadURL.error || uploadFile.error;

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"70%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClose}>
                <div className="w4-container talent-w4-container">
                    <div className="w4-header">
                        <FormDialogTilteHeader
                            title={t("type_form", { name: title })}
                            subTitle={t("please_scroll_down_and_fill_out_the_form_below")}
                            subTitleStyle={{ fontWeight: "normal" }}
                            titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
                        />
                    </div>

                    <div className="talent-w4-content">
                        <div className="ob-review-div">
                            <OnboardingReviewForm
                                handleFieldChange={() => { }}
                                formState={formState}
                            />
                        </div>
                        <PDFReader onInstanceChange={handleOnInstance} />
                        {error && (
                            <div className={"error-section"}>
                                <ApiError message={error.message} onClose={handleClearError} />
                            </div>
                        )}
                    </div>
                    <div className="w4-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-new">
                            <CustomButton
                                loading={loading}
                                textStyle={{
                                    textTransform: "capitalize",
                                }}
                                name={t("save")}
                                enable={state && state?.w4_document?.recruiter_completed_review ? false : true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => (loading ? undefined : handleSave())}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    );

    ///Handle clear error
    function handleClearError() {
        dispatch(w4SliceActions.clearState());
    }

    /// handle submit
    function handleSave() {
        dispatch(getUploadW4UrlThunk({ talent_id: talentId }));
        dispatch(updateTalentW4I9Details(talentW4Data));
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
};

export default W4Popup;
