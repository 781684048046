import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, IProfilePictureUploadUrlResponse } from "../../../../interfaces";
import * as thunk from "./manage_agency_thunk";

export interface ManageAgencyState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    },
    uploadUrls: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    },
    uploadFiles: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    },
}

const initialState: ManageAgencyState = {
    create: {
        error: null,
        loading: LoadingType.idle,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
    },
    uploadUrls: {
        error: null,
        loading: LoadingType.idle,
    },
    uploadFiles: {
        error: null,
        loading: LoadingType.idle,
    }
}


export const manageAgencySlice = createSlice({
    name: 'agency',
    initialState: initialState,
    reducers: {
        clearCreateState(state) {
            state.create = { ...initialState.create };
        },
        clearUpdateState(state) {
            state.update = { ...initialState.update };
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(thunk.createAgency.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createAgency.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createAgency.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateAgency.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateAgency.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateAgency.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getAgencyLogoUploadURLThunk.pending, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getAgencyLogoUploadURLThunk.rejected, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getAgencyLogoUploadURLThunk.fulfilled, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.uploadAgencyLogoThunk.pending, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadAgencyLogoThunk.rejected, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.uploadAgencyLogoThunk.fulfilled, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageAgencyActions = manageAgencySlice.actions;
export const selectState = (state: ManageAgencyState) => state;
export const selectCreateState = (state: ManageAgencyState) => state.create;
export const selectUpdateState = (state: ManageAgencyState) => state.update;
export const selectUploadURLState = (state: ManageAgencyState) => state.uploadUrls;
export const selectUploadFilesState = (state: ManageAgencyState) => state.uploadFiles;
