import "./custom_step_indicator.scss";
import { TickMarkIcon } from "../../icons";
interface Props {
  selected: { label: string; value: number };
  stepsList: Array<{ label: string; value: number }>;
  onClick: (value: { label: string; value: number }) => void;
}

const CustomStepIndicator: React.FunctionComponent<Props> = (props) => {
  const stepbtnWidth = 100/props.stepsList.length;
  return (
    <div className="ct-stp-holder">

      {props.stepsList.map((doc, index) => {

        const isActivated = doc.value <= props.selected.value;
        const isCompleted = doc.value < props.selected.value;
        return (
          <button key={index} className="ct-stp-btn" style={{width:stepbtnWidth+"%"}}>
            <div className={`stp-container ${isActivated ? "active" : ""} ${isCompleted ? "completed" : ""}`}>
              <div className="cricle-icon">
                {isActivated && (
                  <TickMarkIcon
                    width={"100%"}
                    height={"100%"}
                    style={{ color: "#00BE4B" }}
                  />
                )}
              </div>
              <div className="label-txt">
                <span>{doc.label}</span>
              </div>
            </div>
          </button>
        );
      })}
      {/* <div className="horizontal-line"></div> */}
    </div>
  );
};

export default CustomStepIndicator;
