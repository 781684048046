import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ICompetitor } from "../../../../interfaces";
import { getCompetitorDetails } from "./competitor_details_thunk";


export interface CompetitorDetailsState {
  id: string | null;
  error?: AppError | null;
  loading: LoadingType;
  refreshLoading: LoadingType;
  competitorInfo: ICompetitor | null;
}

const initialState: CompetitorDetailsState = {
  id: null,
  error: null,
  loading: LoadingType.idle,
  refreshLoading: LoadingType.idle,
  competitorInfo: null,
};

export const competitorDetailsSlice = createSlice({
  name: "competitorDetails",
  initialState: initialState,
  reducers: {
    clearCompetitorDetailsState(state) {
      return { ...state, ...initialState };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompetitorDetails.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
        id: action.meta.arg,
      }))
      .addCase(getCompetitorDetails.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(getCompetitorDetails.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        error: null,
        competitorInfo: action.payload,
      }))
      .addDefaultCase((state) => ({ ...state }));
  },
});
