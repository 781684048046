import { ITalent } from "./talent"

export interface IWorkCompClaim {
    id: string,
    employee_name: string,
    claim_status: string,
    potential_charges: number,
    cost_to_date: number,
    claim_number: string,
    claim_date: number,
    date_closed: number,
    branch_id: string,
    company_id: string,
    department_id: string,
    next_steps: string,
    agency_id: string,
    assignment_id: string,
    social_security_number: string,
    notes: string,
    claim_docs: Array<string>,
    is_deleted: false,
    company_name: string,
    department_name: string,
    branch_name: string,
    injury_date: number | null,
    reported_date: number | null,
    talent_id: string;
    on_assignment_company: boolean;
    on_assignment: boolean;
}


export interface ICreateWorkCompClaim {
    id: string | null,
    employee_name: string,
    claim_status: string | null,
    potential_charges: number | null,
    cost_to_date: number | null,
    claim_number: number | null,
    claim_date: number | null,
    date_closed: number | null,
    branch_id: string,
    company_id: string | null,
    department_id: string | null,
    next_steps: string,
    agency_id: string | null,
    assignment_id: string | null,
    social_security_number: string | null | undefined,
    notes: string,
    claim_docs: Array<string>,
    is_deleted: boolean,
    claim_type: string | null,
    injury_date: number | null,
    reported_date: number | null,
    injured_body_part: string,
    talent_id: string | null
    company_name: string,
    department_name: string,
}

export class UpdateICreateWorkCompClaim {
    id: string | null;
    employee_name: string;
    claim_status: string | null;
    potential_charges: number | null;
    cost_to_date: number | null;
    claim_number: number | null;
    claim_date: number | null;
    date_closed: number | null;
    branch_id: string;
    company_id: string | null;
    department_id: string | null;
    next_steps: string;
    agency_id: string | null;
    assignment_id: string | null;
    social_security_number: string | null | undefined;
    notes: string;
    claim_docs: Array<string>;
    is_deleted: boolean;
    claim_type: string | null;
    injury_date: number | null;
    reported_date: number | null;
    injured_body_part: string;
    talent_id: string | null;
    company_name: string;
    department_name: string;
}

export interface IWorkCompClaimTalentData {
    talent: ITalent,
    work_comp_claims: IWorkCompClaimData[]
}

export interface IWorkCompClaimData {
    id: string | null,
    employee_name: string,
    claim_status: string,
    potential_charges: number | null,
    cost_to_date: number | null,
    claim_number: number | null,
    claim_date: number | null,
    date_closed: number | null,
    branch_id: string,
    company_id: string | null,
    department_id: string | null,
    next_steps: string,
    agency_id: string | null,
    assignment_id: string | null,
    social_security_number: string | null | undefined,
    notes: string,
    claim_docs: Array<string>,
    is_deleted: boolean,
    claim_type: string | null,
    injury_date: number | null,
    reported_date: number | null,
    injured_body_part: string,
    talent_id: string | null
    company_name: string,
    department_name: string,
}
export interface UpdateIWorkCompClaimData {
    claim_status: string,
    potential_charges: number,
    cost_to_date: number | null,
    claim_number: number | null,
    claim_date: number | null,
    date_closed: number | null,
    company_id: string,
    department_id: string | null,
    next_steps: string,
    notes: string,
    claim_docs: any,
    id: string,
    company_name: string,
    department_name: string | null,
    reported_date: number | null
}

export interface ITalentWorkCompClaimTalentData {
    talent: ITalent,
    work_comp_claims: IWorkCompClaim[]
}
