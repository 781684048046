
import { FirebaseError } from "firebase/app";
import { ERROR_KEYS } from "../enums";
import { AppError } from "../interfaces";
import { AuthRoutes, AppRoutes } from "../routes";



export const ERRORS = {
    BAD_REQUEST: `Bad Request`,
    SESSION_EXPIRED: `Session expired. please login again.`,
    NO_NETWORK: `Check your internet connection and try again.`,
    DATA_NOT_FOUND: `Requested data not found.`,
    UNABLE_TO_PROCESS: `Unable to process your request.`,
    UNKNOWN: `Something went wrong. Try again.`,
    REJECTED: `Invalid data supplied.`,
    PURCHASE_SUBMISSION: `Something went wrong while contacting MentoringMinds. tap to refresh.`,
    UN_AUTHERIZED: `User does not have permission`,
}

/// Invalid response
export const invalidResponse: AppError = {
    response:  null,
    message: 'Invalid Response',
    isAxiosError: false,
    config: null,
    code: null,
    type: "INVALID_RESPONSE"
}

/// catch error

export const catchRequestError = (error, thunkAPI) => {
    const newError = extractError(error);
    return thunkAPI.rejectWithValue(newError, newError);
}

//Firebase error

export const extractFirebaseError = (error: FirebaseError): AppError => {
    const { code, name, message, stack } = error;
    const newError = {
        response: stack,
        message: message,
        isAxiosError: false,
        config: null,
        code: code,
        type: 'firebase'
    };
    switch (code) {
        case "auth/user-not-found": return { ...newError, message: 'User not found' };
        case "auth/wrong-password": return { ...newError, message: 'Invalid user id or password' };
        default: return newError;
    }
}

//error extractor 
export const extractError = (error: any, type?: string): AppError | null => {
    if (!error) return null;
    if (error instanceof FirebaseError) return extractFirebaseError(error);
    const { response, message, config, isAxiosError, code } = error;
    const newCode = setErrorCode(response, code, message);
    const newMessage = setErrorMessage(response, message, newCode);
    return {
        response: response ? response.data : null,
        message: newMessage,
        isAxiosError: isAxiosError,
        config: config,
        code: newCode,
        type: type
    }
}

/// Set error message
const setErrorMessage = (response, message, code) => {
    if (response && response['data'] != null) return setResponseErrorMessage(response);
    else if (code) defaultErrors(code);
    else return message;

}

const setResponseErrorMessage = (response) => {
    const { data, status } = response;
    if (data && data != "" && data['detail'] && data['detail'] != "") {
        const { code, error } = data['detail'];
        if (error) return error;
        return defaultErrors(code);

    }
    else if (status) return defaultErrors(status);
    else return ERRORS.UNKNOWN;
}

/// Set response code
const setErrorCode = (response, code, message) => {
    if (response && response['data'] != null) return setResponseErrorCode(response);
    else if (code) return code;
    else if (message) return message;
    else return 0;
}

const setResponseErrorCode = (response) => {
    const { data, status } = response;
    if (data && data != "" && data['detail'] && data['detail'] != "") {
        const { code, error } = data['detail'];
        if (code) return code;
        return 0;

    }
    else if (status) return status;
    else return 0;
}

export const checkErrorResponse = (error) => {
    if (!error) return;
    const { response, code, message, type } = error;


    if (type && code !== 0 && code !== 400) return identifyTypeOfTheError(type, code);
    else if (type && code !== 0 && code === 400) return identifyTypeOfTheError(type, code);
    else if (response && response.data && typeof response.data !== 'string') return checkResponseData(response.data);
    else if (code === 0) return defaultErrors(code);
    else if (message) return message;
    else return ERRORS.UNKNOWN;
}

const checkResponseData = (data: any) => {
    const { errorKey, title, loginType, status } = data;
    if (errorKey) return identifyErrorKey(errorKey);
    else if (status) return defaultErrors(status);
    else if (title) return title;
    else return ERRORS.UNKNOWN;
}

const identifyErrorKey = (key) => {
    switch (key) {
        case ERROR_KEYS.USER_EXIST: return "User with same email id exist";
        case ERROR_KEYS.EMAIL_EXIST: return "User with same email id exist";
        default: return ERRORS.UNKNOWN;
    }
}



const identifyTypeOfTheError = (type, code) => {
    switch (type) {
        case AuthRoutes.loginPage: return loginSignupErrors(code);
        default: return defaultErrors(code);
    }
}

//default errors
const defaultErrors = (code: any) => {
    switch (code) {
        case "EUNSPECIFIED": return ERRORS.NO_NETWORK;
        case "E_UNKNOWN": return "Cannot connect to iTunes Store. Try again ";
        case "7": return ERRORS.NO_NETWORK;
        case "-5": return ERRORS.NO_NETWORK;
        case "12501": return "Sign in action cancelled";
        case 0: return ERRORS.NO_NETWORK;
        case 400: return ERRORS.BAD_REQUEST;
        case 401: return ERRORS.SESSION_EXPIRED;
        case 500: return "Something went wrong please try again";
        default: return ERRORS.UNKNOWN;
    }
}


const loginSignupErrors = (code: any) => {
    switch (code) {
        case "EUNSPECIFIED": return ERRORS.NO_NETWORK;
        case "E_UNKNOWN": return "Cannot connect to iTunes Store. Try again ";
        case "7": return ERRORS.NO_NETWORK;
        case "-5": return ERRORS.NO_NETWORK;
        case "12501": return "Sign in action cancelled";
        case 304: return ERRORS.UNABLE_TO_PROCESS;
        case 400: return ERRORS.BAD_REQUEST;
        case 401: return "Invalid user id or password";
        case 409: return "User already exists with another social media account";
        case 404: return "User not found";
        case 400: return "User already exists with another social media account";
        // case 406: return "";
        default: return ERRORS.UNKNOWN;
    }
}




