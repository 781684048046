import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError,IUpdateEmpOnboardingDocsReviewStatus } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { EmployeeDashboardAPI } from "../../../apis/employee_dashboard_api";

// update employee Direct Deposit
export const addEmpDirectDepositThunk = createAsyncThunk<
    string,
    IUpdateEmpOnboardingDocsReviewStatus,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee-dashboard/e-docs/direct-deposit/patch',
    async (data, thunkAPI) => {
        try {
            const res = await EmployeeDashboardAPI.updateEmpOnboardingReviewStatus(data);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const validateEmpDirectDepositAcntThunk = createAsyncThunk<
  string,
  IUpdateEmpOnboardingDocsReviewStatus,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@employee-dashboard/e-docs/direct-deposit/validate/post",
  async (payload, thunkAPI) => {
    try {
      const res = await EmployeeDashboardAPI.postValidateEmpDDOnBoarding(payload);
      // if (typeof res.data === "string") return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);
