import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./onboarding_count_thunk";
export interface OnboardingCountState {
    response: { input_required: number, pending_for_review: number, completed_this_week: number } | null,
    error?: AppError | null,
    loading: LoadingType,
}

const initialState: OnboardingCountState = {
    response: null,
    error: null,
    loading: LoadingType.idle,

}

export const onboardingCountSlice = createSlice({
    name: 'onboardingCount',
    initialState: initialState,
    reducers: {
        clearError(state) {
            return { ...state, error: null };
        },
        clearState(state) {
            return ({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getOnboardingCountThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getOnboardingCountThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getOnboardingCountThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))


            .addDefaultCase(state => ({ ...state }));
    }
});

export const onboardingCountSliceActions = onboardingCountSlice.actions;
export const selectState = (state: OnboardingCountState) => state;


