import { useTranslation } from "react-i18next";
import { THEME } from "../../enums";

import { addressParser, CustomButton, getArrayStrings } from "../../utils";

import {
  getDateString,
  getDaysDifference,
  getDateFromTimeStamp,
} from "../../variables";
import { OnlineApplicationReceivedWorkHistory } from "../../interfaces/online_application_received";

interface Props {
  employmentHistory: OnlineApplicationReceivedWorkHistory | null;
  //   talentId: string;
  onAddCompanyToCallDeck: (value: OnlineApplicationReceivedWorkHistory) => void;
  // departmentsState: string | null,
}


const empInfoDetails = [
  {
    sectionTitle: "dates_of_employment",
    sectionContent: [
      {
        title: "dates_of_employment",
        valueField: ["start_date", "end_date", "days_difference"],
      },
    ],
  },
  {
    sectionTitle: "job_title",
    sectionContent: [{ title: "job_title", valueField: "job_title" }],
  },

  {
    sectionTitle: "job_duties",
    sectionContent: [
      { title: "job_duties", valueField: "job_accomplishments_details_and_responsibilities" },
    ],
  },
  {
    sectionTitle: "reason_for_leaving",
    sectionContent: [
      { title: "reason_for_leaving", valueField: "reason_for_leaving" },
    ],
  },
];
const refCheckInfo = [
  {
    sectionTitle: "reference_check_notes",
    sectionContent: [{ title: "reference_check_notes", valueField: "reference_check_notes" }],
  },
  {
    sectionTitle: "agency_name",
    sectionContent: [
      { title: "agency_name", valueField: "placed_by_staffing_agency" },
    ],
  },
];


const OnlineEmploymentHistorySection: React.FunctionComponent<Props> = (props) => {
  const { employmentHistory, } = props;
  const { t } = useTranslation();
  const newemploymentHistory = {
    ...employmentHistory,
    days_difference: getDaysDifference(
      employmentHistory?.end_date != 0
        ? getDateFromTimeStamp(employmentHistory?.end_date!) : new Date(),
      employmentHistory?.start_date
        ? getDateFromTimeStamp(employmentHistory?.start_date!)
        : getDateString(new Date()))
    ,
    end_date: employmentHistory?.end_date != 0 ? getDateString(employmentHistory?.end_date) : (employmentHistory.is_currently_work_here ? getDateString(new Date()) : 0) ,
    start_date: getDateString(employmentHistory?.start_date),
  };

  const companyInfoDetails = [
    {
      sectionTitle: "company",
      sectionContent: [{ title: "company", valueField: "company_name" }],
    },
  
    {
      sectionTitle: "department",
      sectionContent: [{ title: "department", valueField: "company_department" }],
    },
    {
      sectionTitle: "address",
      sectionContent: [{ title: "address", valueField: ''}],
    },
    {
      sectionTitle: "supervisor_name",
      sectionContent: [
        { title: "supervisor_name", valueField: "supervisor_name" },
      ],
    },
  
    {
      sectionTitle: "supervisor_contact",
      sectionContent: [
        { title: "supervisor_contact", valueField: "supervisor_contact" },
      ],
    },
  ];

  return (
    <div className="teh-container">
      <div className="teh-panel1">
        <div className="teh-panel-header">Company Information</div>
        {companyInfoDetails.map((doc, index) => {
          return (
            <div key={index} className="teh-info-container">
              <div className="teh-info-content">
                {doc.sectionContent.map((data, index) => {
                  const arrayValue = getValues(data.valueField);
                  return (
                    <div key={index} className="teh-info-holder">
                      <div className="teh-into-title">
                        <span>{t(data.title).toUpperCase()}</span>
                      </div>
                      <div className="teh-info-value">{arrayValue}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="teh-panel2">
        <div className="teh-panel-header">Employment Information</div>

        {empInfoDetails.map((doc, index) => {
          return (
            <div key={index} className="teh-info-container">
              <div className="teh-info-content">
                {doc.sectionContent.map((data, index) => {
                  const arrayValue = getValues(data.valueField);

                  return (
                    <div key={index} className="teh-info-holder">
                      <div className="teh-into-title">
                        <span>{t(data.title).toUpperCase()}</span>
                      </div>
                      <div className="teh-info-value">{arrayValue}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="teh-panel3">
        <div>
          <div className="teh-panel-header">Reference Check Information</div>
          {refCheckInfo.map((doc, index) => {
            return (
              <div key={index} className="teh-info-container">
                <div className="teh-info-content">
                  {doc.sectionContent.map((data, index) => {
                    const arrayValue = getValues(data.valueField);
                    return (
                      <div key={index} className="teh-info-holder">
                        <div className="teh-into-title">
                          <span>{t(data.title).toUpperCase()}</span>
                        </div>
                        <div className="teh-info-value">{arrayValue}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="teh-action-container">
          <div className="teh-action">
            <CustomButton
              customStyle={{ width: "fit-content" }}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("add_company_to_call_deck")}
              enable={true}
              backgroundColor={THEME.buttonColor17}
              onClick={() =>
                props.employmentHistory
                  ? props.onAddCompanyToCallDeck(props.employmentHistory)
                  : {}
              }
            />
          </div>
        </div> */}
      </div>
    </div>
  );

  function getValues(obj: any) {
    if (newemploymentHistory == null) return <span>{""}</span>;

    if (Array.isArray(obj)) {
      return getArrayStrings(obj, newemploymentHistory);
    }
    if (typeof obj === "object") {
      return obj;
  }
    const value =
      obj !== "" && newemploymentHistory![obj] !== null && newemploymentHistory![obj] !== "" && newemploymentHistory![obj] !== undefined
        ? newemploymentHistory![obj]
        : "-";
    return <span>{value}</span>;
  }
};

export default OnlineEmploymentHistorySection;
