import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IContact } from "../../../interfaces";
import * as thunk from "./contacts_list_all_thunk";

export interface ContactsListState extends EntityState<IContact> {
    error?: AppError | null,
    loading: LoadingType,
}

const contactsListAdapter: EntityAdapter<IContact> = createEntityAdapter<IContact>({
    selectId: (doc) => doc.id,
})

const initialState: ContactsListState = contactsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const contactsListAllSlice = createSlice({
    name: 'contactsListAll',
    initialState: initialState,
    reducers: {
        clearContactsListError(state){
            return {...state, error: null};
        },
        clearContactsListState(state){
            return contactsListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getContactsAllList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getContactsAllList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getContactsAllList.fulfilled, (state, action) => contactsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const contactsListActions = contactsListAllSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = contactsListAdapter.getSelectors();
export const selectState = (state: ContactsListState) => state;
export const selectLoading = (state: ContactsListState) => state.loading;
export const selectError = (state: ContactsListState) => state.error;


