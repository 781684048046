import { combineReducers } from "@reduxjs/toolkit";
import { availabilityReportListSlice } from "./availability_report/availability_report_reducer";
import { employeeAssignmentReportListSlice } from "./employee_assignment_report/employee_assignment_report_reducer";

export const reportCenterTalentReducers = combineReducers({
    [availabilityReportListSlice.name]: availabilityReportListSlice.reducer,
    [employeeAssignmentReportListSlice.name]: employeeAssignmentReportListSlice.reducer,
});

export * from "./availability_report/availability_report_selector";
export * from "./availability_report/availability_report_thunk";

export * from "./employee_assignment_report/employee_assignment_report_selector";
export * from "./employee_assignment_report/employee_assignment_report_thunk";
