import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmployeePortalAPI } from "../../../../apis";
import { IPCRevHistory, AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getPaycheckRevHistTHunk = createAsyncThunk<
    IPCRevHistory,
    { ePortal: boolean, talent_id?: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee-portal/dashbord/payroll_details/paycheck_reversal_history/get',
    async (doc, thunkAPI) => {
        try {
            const res = await EmployeePortalAPI.getTalentReversalCheckHistory(doc.ePortal, doc.talent_id);
            return res.data as IPCRevHistory;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);