import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './departments_structure_reducer';

const select = (state: IRootState) => state.companies.details.dashboard.departmentsStructure;
export const selectCompanyDepartmentsStructuresState = createSelector(select, reducer.selectCompanyDepartmentStructuresState);
export const selectSingleCompanyDepartmentsStructuresStateById = (id: string | undefined | null) => createSelector(
    selectCompanyDepartmentsStructuresState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleCompanyDepartmentStructuresState(state, id);
        return reducer.selectInitialSingleCompanyDepartmentStructuresState();
    }
);