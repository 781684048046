import { useTranslation } from "react-i18next";
import { getEmployeeW2Thunk, selectTalentW2State, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useState, useEffect } from "react";
import { LoadingType, THEME } from "../../../../enums";
import { ITalentW2 } from "../../../../interfaces";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import "./employee_w2_page.scss";
import { CustomButton } from "../../../../utils";
import { SearchIcon } from "../../../../icons";
import { Portal } from "react-portal";
import W2Popup from "../../popups/w2/w2";
import { talentW2SliceActions } from "../../../../redux/employee_portal/edocs_certification/talent_w2/talent_w2_reducer";

interface Props {
    talentId: string;
    currentLoginUserName: string;
}
const tableHeader = [
    { title: "year", code: "year" },
    { title: "name", code: "name" },
    { title: "", code: "", sort: false },

];
const EmployeeW2Page: React.FunctionComponent<Props> = (props) => {
    const { talentId, currentLoginUserName } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const talentW2State = useAppSelector((state) => selectTalentW2State(state));

    const [talentW2List, setTalentW2State] = useState<ITalentW2[]>([]);

    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");

    const [openw2, setOpenW2] = useState<{ visible: boolean, data: ITalentW2 | null }>({ visible: false, data: null });

    const getTalentW2 = () => {
        dispatch(getEmployeeW2Thunk({ talent_id: talentId }));
    }

    useEffect(() => {
        getTalentW2();
        return () => { };
    }, []);

    useEffect(() => {
        if (talentW2State.loading === LoadingType.succeeded && talentW2State.response) {
            setTalentW2State(talentW2State.response)
        }
    }, [talentW2State.loading, talentW2State.response]);

    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            // setSortedField(null);
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1.5;
        if (value === 1) return 2;
        if (value === 2) return 2;
        return 1;
    }
    const getFilteredList = () => {
        let list = talentW2List;

        if (talentW2List && sortedField != null) {
            list = [...talentW2List].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return list;

        // return list?.filter(doc => {
        //   const str = search.trim().toLowerCase();
        //   return Object.values(doc).some(val =>
        //     val && val.toString().trim().toLowerCase().includes(str)
        //   );
        // });
    }
    const handleClose = () => {
        setOpenW2({ visible: false, data: null });
        dispatch(talentW2SliceActions.clearState())
    }
    const handleonSuccessClose = () => {
        handleClose();
        getTalentW2();
    }
    return (
        <div className="ep-ud-db-edc-section">
            <div className="title">{t("w2").toUpperCase()}</div>
            <div className="ep-w2-content">
                <SortableTable
                    headerList={tableHeader}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {getFilteredList().map((doc, index) => {
                        return (
                            <tr key={doc.talent_id + index}>
                                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                    <span>{doc.year}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                    <span>{"W2 - " + doc.year}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                    <div className="view-invoice-btn">
                                        <CustomButton
                                            loading={false}
                                            customStyle={{ width: '-webkit-fill-available' }}
                                            leftIcon={<span style={{ color: "#fff" }}>{doc.w2_path ? <SearchIcon /> : ""}</span>}
                                            textStyle={{ textTransform: 'capitalize' }}
                                            name={doc.w2_path ? t("view_w2") : t("generate_w2")}
                                            enable={true}
                                            backgroundColor={doc.w2_path ? THEME.buttonBackgroundColor3 : THEME.primaryColor}
                                            onClick={() => setOpenW2({ visible: true, data: doc })}
                                        />
                                    </div>
                                </TableData>

                            </tr>
                        );
                    })}
                </SortableTable>
            </div>
            {openw2.visible &&
                <Portal>
                    <W2Popup
                        visible={openw2.visible}
                        data={openw2.data}
                        talentId={talentId}
                        title={t("w2")}
                        onClose={handleClose}
                        onSuccessClose={handleonSuccessClose}
                        currentLoginUserName={currentLoginUserName}
                        generate={openw2.data?.w2_path === null ? true : false}
                        uploadURLState={talentW2State.uploadURL}
                        uploadFileState={talentW2State.uploadFile}

                    />
                </Portal>}
        </div>
    );
}

export default EmployeeW2Page;