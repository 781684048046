import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { competitorDetailsSlice, CompetitorDetailsState } from "./competitor_details_reducer";

export const { clearCompetitorDetailsState } = competitorDetailsSlice.actions;
export const selectState = (state: CompetitorDetailsState) => state;
export const selectLoading = (state: CompetitorDetailsState) => state.loading;
export const selectError = (state: CompetitorDetailsState) => state.error;
const select = (state: IRootState) => state.salesMarketing.competitorSheet.competitorDetails;
export const selectCompetitorDetailsState = createSelector(select, selectState);
export const selectCompetitorDetailsId = createSelector(
  selectCompetitorDetailsState,
  (state) => state.competitorInfo?.id
);
