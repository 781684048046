import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../../apis";
import { AppError } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";
import { ICreateTalentBOPRDirectDeposit, ITalentBOPRDirectDeposit, ITalentBackOfficePayrollSetup } from "../../../../../interfaces/talent_back_office";

export const getTalentBOPayrollDirectDepositThunk = createAsyncThunk<
    ITalentBOPRDirectDeposit,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/back_office_payroll_setup_direct_depost/get',
    async (query, thunkAPI) => {
        try {
            const res = await TalentAPI.getBOTalentPayrollSetupDirectDeposit(query);
            if (typeof res.data === 'string') return [];
            return res.data as ITalentBackOfficePayrollSetup;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

