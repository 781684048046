import RenderInput from "../../../../../components/render_input/render_input";
import { FormInputOption, SuccessProfileQuestions, ICreateJobForm, IDepartmentsList, ISuccessProfileQA } from "../../../../../interfaces";
import './step_success_profile_questions.scss';

interface Props {
    questions: SuccessProfileQuestions[],
    formState: ICreateJobForm,
    handleFieldChange: (id: string, value: any, parent?: string | undefined) => void,
    validators: ((value: {
        text: string;
        field: string;
    }) => string | null) | undefined,
}

const StepSuccessProfileQuestionsForm: React.FunctionComponent<Props> = (props) => {
    const { questions, formState, handleFieldChange, validators } = props;
    const formOptions: (FormInputOption | FormInputOption[])[] = [
        ...questions.map((doc, index) => {
            return {
                field: doc.id,
                parentField: 'success_profile_question_answers',
                type: "text",
                label: doc.success_profile_question,
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: '',
                multiline:true,
            } as FormInputOption;
        }),
    ]

    return (
        <div className="jb-f-stp-one">
            {formOptions.map((doc, index) => {
                if (Array.isArray(doc)) {
                    return (
                        <div key={index + 'row'} className="jb-f-row">
                            {doc.map((subDoc, subIndex) => {
                                return (
                                    <RenderInput
                                        key={index + subIndex}
                                        doc={subDoc}
                                        index={subIndex}
                                        formState={formState}
                                        inputValue={subDoc.parentField ? formState[subDoc.parentField][subDoc.field].answer : undefined}
                                        handleFieldChange={handleFieldChange}
                                        formValidators={validators}
                                        className={"jb-f-input-holder"}
                                    />
                                );
                            })}
                        </div>
                    );
                }
                return (
                    <RenderInput
                        key={index}
                        doc={doc}
                        index={index}
                        formState={formState}
                        inputValue={doc.parentField ? formState[doc.parentField][doc.field].answer : undefined}
                        handleFieldChange={handleFieldChange}
                        formValidators={validators}
                        className={"jb-f-input-holder"}
                    />
                )
            })}
        </div>
    )
}

export default StepSuccessProfileQuestionsForm;