import { createEntityAdapter, createSlice, EntityAdapter, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, ITalentInHouseInterview } from "../../../../../interfaces";
import * as thunk from "./in_house_interviews_thunk";

interface CRUDState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: CRUDState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


const initialUpdateState: CRUDState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


const initialRemoveState: CRUDState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}

export interface SingleTalentInHouseInterviewsState {
    data: {
        [key: string]: ITalentInHouseInterview,
    },
    error?: AppError | null,
    loading: LoadingType,
    add: CRUDState,
    update: CRUDState,
    remove: CRUDState,
}


export interface InHouseInterviewsState {
    [key: string]: SingleTalentInHouseInterviewsState,
}


const initialState: InHouseInterviewsState = {}


export const inHouseInterviewsSlice = createSlice({
    name: 'InHouseInterviews',
    initialState: initialState,
    reducers: {
        clearInHouseInterviewsError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    error: null,
                }
            };
        },
        clearSingleTalentInHouseInterviewsStateError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: {
                        ...state[action.payload].add,
                        error: null,
                    },
                    update: {
                        ...state[action.payload].update,
                        error: null,
                    },
                    remove: {
                        ...state[action.payload].remove,
                        error: null,
                    }
                }
            }
        },
        clearSingleTalentInHouseInterviewsManageState(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: { ...initialAddState },
                    update: { ...initialUpdateState },
                    remove: { ...initialRemoveState },
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getTalentInHouseInterviews.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.pending,
                        error: null,
                        add: { ...initialAddState },
                        update: { ...initialUpdateState },
                        remove: { ...initialRemoveState },
                    }
                })
            )
            .addCase(
                thunk.getTalentInHouseInterviews.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.failed,
                        error: action.payload,
                    }
                })
            )
            .addCase(
                thunk.getTalentInHouseInterviews.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        data: action.payload.reduce((a, v) => ({ ...a, [v.id]: v }), {}),
                        loading: LoadingType.succeeded,
                        error: null,
                    }
                })
            )
            /// Add new talent in house interview
            .addCase(
                thunk.addNewTalentInHouseInterview.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        add: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.addNewTalentInHouseInterview.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        add: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.addNewTalentInHouseInterview.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        add: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )
            /// update 
            .addCase(
                thunk.updateTalentInHouseInterview.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        update: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.updateTalentInHouseInterview.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        update: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.updateTalentInHouseInterview.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talentId]: {
                        ...state[action.meta.arg.talentId],
                        update: {
                            ...state[action.meta.arg.talentId].add,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentInHouseInterviewActions = inHouseInterviewsSlice.actions;
export const selectState = (state: InHouseInterviewsState) => state;
export const selectSingleState = (state: InHouseInterviewsState, id: string) => state[id];

export const selectSingleInHouseInterviewsInitialState = (): SingleTalentInHouseInterviewsState => {
    return {
        loading: LoadingType.pending,
        error: null,
        data: {},
        add: initialAddState,
        update: initialUpdateState,
        remove: initialRemoveState,
    }
}


