import { createSelector } from "@reduxjs/toolkit";
import { selectState, selectAll, selectEntities } from "./kiniso_job_orders_reducer";
import { IRootState } from "../../store";

const select = (state: IRootState) => state.kinisoJobOrders.kinisoJobOrders;
export const selectKinisoJobsListState = createSelector(select, selectState);
export const selectKinisoJobsList = createSelector(
    select,
    selectAll
);
export const selectKinisoJobsListEntities = createSelector(
    select,
    selectEntities
);
