import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccountAPI, AdminCenterAPI, firebaseLogin } from "../../../../apis";
import { AppError, IProfileDropdown, LoginCredentials, Profile, UsersQuery } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getRmRoleUsersList = createAsyncThunk<
    Array<Profile>,
    UsersQuery | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin-center/role-management/users/get',
    async (credentials, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getUsersList(credentials);
            return res.data as Profile[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);