import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI } from "../../../../../apis";
import { AppError, IOnboardingLink } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";

export const getOCTWActiveList = createAsyncThunk<
    Array<IOnboardingLink>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/recruiting/onboarding/completed-this-week/active-list',
    async (_, thunkAPI) => {
        try {
            const res = await DashboardAPI.getAllSendOnboardingLinkList({is_archived: false, is_onboarding_completed: true});
            if(typeof res.data === 'string') return [];
            return res.data as IOnboardingLink[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);