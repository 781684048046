import { createAsyncThunk } from "@reduxjs/toolkit";
import { IContactsApolloSearchResults, IContactApolloImport, IContactsApolloSearch, IApolloContactsResults, IApolloImportResponse } from "../../../interfaces/apollo_search_import";
import { AppError } from "../../../interfaces";
import { ApolloSearcImportAPI } from "../../../apis";
import { catchRequestError } from "../../../utils";
import { apolloGetCreditsThunk } from "../apollo_credits/apollo_credits_thunk";


export const appolloContactSearchPeopleThunk = createAsyncThunk<
    IApolloContactsResults,
    IContactsApolloSearch,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/universal-directory/search-people-apollo/post',
    async (data, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.appolloContactSearchPeople(data);
            thunkAPI.dispatch(apolloGetCreditsThunk());
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const apolloContactImportPeopleThunk = createAsyncThunk<
    IApolloImportResponse,
    { data: IContactApolloImport[], need_direct_dial: boolean, force_import: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/universal-directory/import-people-apollo/post',
    async (payload, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.apolloContactImportPeople(payload.data, payload.need_direct_dial, payload.force_import);
            return res.data as IApolloImportResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const apolloTalentImportPeopleThunk = createAsyncThunk<
IApolloImportResponse,
    { data: IContactApolloImport[], need_direct_dial: boolean, force_import: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/universal-directory/import-talent-apollo/post',
    async (payload, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.apolloTalentImport(payload.data, payload.need_direct_dial, payload.force_import);
            return res.data as IApolloImportResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);