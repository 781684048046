import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import * as thunk from "./sales_tax_configuration_thunk";
import { AppError } from "../../../interfaces";
import { LoadingType } from "../../../enums";
import { SalesTaxConfiguration } from "../../../interfaces/sales_tax_configuration";

export interface SalesTaxConfigListState extends EntityState<SalesTaxConfiguration> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        respose: string,  
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        respose: string,  
    },
    delete: {
        error?: AppError | null,
        loading: LoadingType,
        respose: string,  
    }
}

const SalesTaxConfigListAdapter: EntityAdapter<SalesTaxConfiguration> = createEntityAdapter<SalesTaxConfiguration>({
    selectId: (doc) => doc.id,
})

const initialState: SalesTaxConfigListState = SalesTaxConfigListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        respose: "",  
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        respose: "",  
    },
    delete: {
        error: null,
        loading: LoadingType.idle,
        respose: "",  
    }
})


export const SalesTaxConfigListSlice = createSlice({
    name: 'salesTaxConfigList',
    initialState: initialState,
    reducers: {
        clearSalesTaxConfigListError(state){
            return {...state, error: null};
        },
        clearSalesTaxConfigListState(state){
            return SalesTaxConfigListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearSalesTaxCreateState(state) {
            return { ...state, create : {...initialState.create }};
            // state.create = {...initialState.create }
        },
        clearSalesTaxUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearSalesTaxDeleteState(state) {
            return { ...state, delete : {...initialState.delete} };
        },
        clearSalesTaxCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearSalesTaxUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearSalesTaxDeleteStateError(state) {
            return { ...state, delete : { ...initialState.delete , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getSalesTaxTaxConfigList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getSalesTaxTaxConfigList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getSalesTaxTaxConfigList.fulfilled, (state, action) => SalesTaxConfigListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addCase(thunk.createSalesTaxTaxConfigList.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.createSalesTaxTaxConfigList.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.createSalesTaxTaxConfigList.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, respose: action.payload } }))
            .addCase(thunk.updateSalesTaxTaxConfigList.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.updateSalesTaxTaxConfigList.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.updateSalesTaxTaxConfigList.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, respose: action.payload } }))
            .addCase(thunk.deleteSalesTaxConfig.pending, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deleteSalesTaxConfig.rejected, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deleteSalesTaxConfig.fulfilled, (state, action) => ({ ...state, delete: { ...state.delete, loading: LoadingType.succeeded, error: null, respose: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearSalesTaxConfigListError, clearSalesTaxConfigListState, clearSalesTaxCreateState, clearSalesTaxUpdateState, clearSalesTaxCreateStateError, clearSalesTaxUpdateStateError, clearSalesTaxDeleteState, clearSalesTaxDeleteStateError } = SalesTaxConfigListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = SalesTaxConfigListAdapter.getSelectors();
export const selectState = (state: SalesTaxConfigListState) => state;
export const selectLoading = (state: SalesTaxConfigListState) => state.loading;
export const selectError = (state: SalesTaxConfigListState) => state.error;


