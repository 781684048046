import * as MtIcons from '@material-ui/icons';
import './api_error.scss';

interface Props {
    message: string,
    onClose: () => void,
}

const ApiError = (props: Props) => {
    return (
        <div className="aie-error">
            <div className="aie-error-txt">
                <div className="aie-err-icon">
                    <MtIcons.Error fontSize={"small"} color={"error"} />
                </div>
                <span>{props.message}</span>
            </div>
            <a className="aie-error-action" onClick={props.onClose}>
                <MtIcons.Close fontSize={"small"} color={"error"} />
            </a>
        </div>
    );
}

export default ApiError;