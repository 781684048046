import { useEffect, useRef } from "react";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { CustomButton, FormInput, allowNumbers, convertNumberToMoney, formatToMoney, mapOptions } from "../../../../utils";
import { TalentIcon } from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import { useTranslation } from "react-i18next";
import './add_contribution.scss';
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { ITalentBackOfficeContributions } from "../../../../interfaces/talent_back_office";
import { AppError } from "../../../../interfaces";
import { DateInput } from "../../../../components/date_input/date_input";
import ApiError from "../../../../components/api_error";
import { getEmployeeContribution, selectEmployeeContributionList } from "../../../../redux/admin_center/back_office";
interface Props {
    visible: boolean,
    title: string,
    successTitle: string,
    onClose: () => void,
    onDissmiss?: () => void,
    handleFormFieldChange: (fieldId: string, value: any, parent: string) => void;
    contributionFormState: ITalentBackOfficeContributions;
    handleSave: () => void;
    currentLoginUserName: string;
    onSuccessClose: () => void;
    index: number | undefined;
    talentName: string;
    parentField: string;
    contributionUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleClearError: () => void;
}

const AddContributionPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        title,
        successTitle,
        handleFormFieldChange, contributionFormState, handleSave, handleClearError, currentLoginUserName, talentName, onSuccessClose, index, parentField, contributionUpdateState
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const loading = contributionUpdateState.loading === LoadingType.succeeded;
    const isAddLoading = () => {
        return contributionUpdateState?.loading === LoadingType.pending;
    };
    const employeeContributionList = useAppSelector((state) => selectEmployeeContributionList(state));

    useEffect(() => {
        dispatch(getEmployeeContribution());

    }, []);

    function handleWrapperClose(action?: () => void) {
        if (loading) {
            closePopup(props.onSuccessClose);
        } else {
            if (action) closePopup(action);
        }
    }
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
                <div className="add-cont-container">
                    <div className="add-cont-header">
                        <FormDialogTilteHeader
                            title={loading ? (index !== undefined ? t("update_contribution") : successTitle) : (index !== undefined ? t("update_contribution") : title)}
                            subTitle={t("lets_get_cont_step_fill_info")}
                            titleIcon={<TalentIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="add-cont-content">
                        {loading ?
                            (<div className="saved-doc-holder">
                                <DocSavedText>
                                    <span className="aknw-name">
                                        {t("well_done_credit_profile", {
                                            name: currentLoginUserName,
                                        })}
                                    </span>
                                    <span>{index !== undefined ? t("you_have_updated_contribution", { talentName: talentName }) : t("you_have_added_contribution", { talentName: talentName })}</span>
                                </DocSavedText>
                            </div>)
                            :
                            (
                                <>
                                    <div className="add-cont-input-holder-row row1">
                                        <div className={"add-cont-input-holder"}>
                                            <CustomFormSelect
                                                name={t("contribution_institute_id")}
                                                list={mapOptions(employeeContributionList, "title", "id")}
                                                onChange={(val) => handleFormFieldChange("contribution_institute_id", val, parentField)}
                                                value={contributionFormState.contribution_institute_id}
                                                placeholder={t('select')}
                                                required={false}
                                                label={t('name_institution')}
                                            />
                                        </div>
                                        <div className={"add-cont-input-holder"}>
                                            <FormInput
                                                id={'contribution_reference_no'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={contributionFormState.contribution_reference_no}
                                                label={t("reference_number")}
                                                onKeyUp={(e) => { allowNumbers(e); }}
                                                onKeyDown={(e) => { allowNumbers(e); }}
                                            />
                                        </div>

                                    </div>
                                    <div className="add-cont-input-holder-row row3">
                                        <div className={"add-cont-input-holder"}>
                                            <FormInput
                                                id={'contribution_pay_period_limit'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(contributionFormState.contribution_pay_period_limit, "string") as string}
                                                label={t("pay_period_limit")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                        <div className={"add-cont-input-holder"}>
                                            <FormInput
                                                id={'contribution_monthly_limit'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(contributionFormState.contribution_monthly_limit, "string") as string}
                                                label={t("monthly_limit")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                        <div className={"add-cont-input-holder"}>
                                            <FormInput
                                                id={'contribution_annual_limit'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(contributionFormState.contribution_annual_limit, "string") as string}
                                                label={t("annual_limit")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                        <div className={"add-cont-input-holder"}>
                                            <FormInput
                                                id={'contribution_life_time_limit'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(contributionFormState.contribution_life_time_limit, "string") as string}
                                                label={t("lifetime_limit")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                    </div>
                                    <div className="add-cont-input-holder-row row3">

                                        <div className={"add-cont-input-holder"}>
                                            <DateInput
                                                id={'contribution_start_date'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                placeholder={""}
                                                value={contributionFormState.contribution_start_date}
                                                label={t('contribution_start_date')}
                                                setHoursToZero={true}
                                            />
                                        </div>
                                        <div className={"add-cont-input-holder"}>
                                            <DateInput
                                                id={'contribution_end_date'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                placeholder={""}
                                                value={contributionFormState.contribution_end_date}
                                                label={t('contribution_end_date')}
                                                setHoursToZero={true}
                                            />
                                        </div>
                                        <div className="cont-input-row-holder">
                                            <div className="cont-input-row">
                                                <div>
                                                    <FormInput
                                                        id={'contribution_amount'}
                                                        onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                        required={false}
                                                        placeholder={""}
                                                        type={"text"}
                                                        value={convertNumberToMoney(contributionFormState.contribution_amount, "string") as string}
                                                        label={t("contribution_amount")}
                                                        prefix={<span>$</span>}
                                                        onBlur={formatToMoney}
                                                        onKeyDown={formatToMoney}
                                                        onKeyUp={formatToMoney}
                                                        disabled={contributionFormState.contribution_percentage ? true : false }
                                                    />
                                                </div>
                                                <div className="spacer">
                                                    or
                                                </div>
                                                <div>
                                                    <FormInput
                                                        id={'contribution_percentage'}
                                                        onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                        required={false}
                                                        placeholder={""}
                                                        type={"text"}
                                                        value={contributionFormState.contribution_percentage}
                                                        label={t("contribution_percentage")}
                                                        suffix={<span>%</span>}
                                                        onKeyUp={(e) => { allowNumbers(e); }}
                                                        onKeyDown={(e) => { allowNumbers(e); }}
                                                        disabled={contributionFormState.contribution_amount ? true : false }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-cont-input-holder-row row2">
                                        <div className={"add-cont-input-holder"}>
                                            <FormInput
                                                id={'note'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={contributionFormState.note}
                                                label={t("notes")}
                                            />
                                        </div>
                                        <div className={"add-cont-input-holder"}>
                                            <FormInput
                                                id={'frequency'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={contributionFormState.frequency}
                                                label={t("Frequency")}
                                            />
                                        </div>
                                        <div className={"add-cont-input-holder"}>
                                            <ToggleSwitch
                                                onChange={(val) => handleFormFieldChange('is_active', val, parentField)}
                                                label={t("active")}
                                                checked={contributionFormState.is_active!}
                                                labelPosition={'top'}
                                            />
                                        </div>
                                    </div>
                                    {contributionUpdateState && contributionUpdateState.error && (
                                        <div className={"error-section"}>
                                            <ApiError
                                                message={contributionUpdateState?.error?.message}
                                                onClose={handleClearError}
                                            />
                                        </div>
                                    )}
                                </>
                            )
                        }

                    </div>
                    <div className="add-cont-actions">
                        {loading ?
                            <div className="btn-save">
                                <CustomButton
                                    loading={isAddLoading()}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("ok")}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => closePopup(onSuccessClose)}
                                    enable={true}
                                />
                            </div>
                            :
                            (
                                <>
                                    <div className="btn-cancel">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("cancel")}
                                            enable
                                            backgroundColor={THEME.secondaryColor4}
                                            onClick={onClose}
                                        />
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={isAddLoading()}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("save")}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={handleSave}
                                            enable={true}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>);

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default AddContributionPopup;