
import { useTranslation } from "react-i18next";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import React, { useRef } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { CustomButton } from "../../../../utils";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { SalesmarketIcon } from "../../../../icons";
import { THEME } from "../../../../enums";
interface Props {
    currentLoginUserName: string | undefined;
    onClose: () => void;
    visible: true
}
const PostToGoogleSuccessPopup: React.FunctionComponent<Props> = (props) => {
    const { currentLoginUserName, onClose, visible } = props;
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={400}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={onClose}>
                <div className="jb-pg-dialog-container">
                    <div className="jb-pg-dialog-header">
                        <FormDialogTilteHeader
                            title={t("job_posting_question")}
                            titleIcon={<SalesmarketIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="jb-pg-dialog-content">
                        <DocSavedText>
                            <span className="aknw-name">
                                {t("well_done", { name: currentLoginUserName })}
                            </span>
                            <span className="info-txt">{`${t(
                                "your_job_has_been_posted_broadcast"
                            )}`}</span>

                        </DocSavedText>
                    </div>

                    <div className="jb-pg-dialog-footer">

                        <div>
                            <CustomButton
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("ok")}
                                enable={true}
                                padding={12}
                                fontSize={15}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={onClose}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    );
};

export default PostToGoogleSuccessPopup;
