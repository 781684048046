import  './field_nav.scss';

interface FieldNavProps {
    isButton?: boolean,
    children: React.ReactNode,
    onClick?: () => void,
    customStyle?: React.CSSProperties,
    prefix?: React.ReactNode,
    suffix?: React.ReactNode,
}

export const FieldNav: React.FunctionComponent<FieldNavProps> = (props) => {
    if (props.isButton) {
        return (
            <div style={props.customStyle} className="field-data-container" >
                {props.prefix}
                <a className="field-data-btn" onClick={props.onClick ? props.onClick : () => { }}>
                    {props.children}
                </a>
                {props.suffix}
            </div>

        );
    }
    return (
        <div style={props.customStyle} className="field-data-container" >
            {props.children}
        </div>
    );
}
