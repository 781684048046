import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper, { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { THEME } from '../../../../enums';
import { CompaniesIcon, DocSavedIcon } from '../../../../icons';
import { ICompany } from '../../../../interfaces';
import { AppRoutes } from '../../../../routes';
import { CustomButton, SpinnerScreen } from '../../../../utils';
import './add_company_to_call_deck.scss';

interface Props {
    visible: boolean,
    title: string,
    company_id: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    onDashboardNav: () => void,
    onSalesNav: () => void,
}

const AddCompanyToCallDeck: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        onDashboardNav,
        onSalesNav,
    } = props;
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);

    function isLoading() {
        return false;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                {uiSection()}
            </DialogWrapper>
        </ActionDialogHolder>
    )


    function uiSection() {
        if (isLoading()) {
            return (
                <div className="loading-spinner"><SpinnerScreen /></div>
            )
        }

        return (
            <div className="tlnt-emph-cmpny-cdk-container">
                <div className="tlnt-emph-cmpny-cdk-header">
                    <FormDialogTilteHeader
                        title={props.title}
                        titleIcon={<CompaniesIcon width={"100%"} height={"100%"} style={{ color: THEME.companiesOptionTextColor }} />}
                    />
                </div>
                <div className="tlnt-emph-cmpny-cdk-content">
                    <div className="tlnt-emph-cmpny-cdk-ack-section">
                        <div className="doc-saved-icon">
                            <DocSavedIcon width={"100%"} height={"100%"}/>
                        </div>
                        <div className="ack-text">
                            <span>{t('adding_company_to_call_deck_success_message', { user: 'Lukas', company: 'FedEx'})}</span>
                        </div>
                    </div>

                </div>
                <div className="tlnt-emph-cmpny-cdk-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('go_to_my_agency_dashboard')}
                            enable={true}
                            backgroundColor={THEME.statusDisabledColor}
                            onClick={() => closePopup(props.onDashboardNav)}
                        />
                    </div>

                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('go_to_company_call_deck')}
                            enable={true}
                            // backgroundColor={}
                            onClick={() => closePopup(props.onSalesNav)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default AddCompanyToCallDeck;