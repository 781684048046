import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState } from "./service_calls_reducer";

const select = (state: IRootState) => state.dashboard.metrics.serviceCallsList;
export const selectDashboardServiceCallListState = createSelector(select, selectState);

export const selectDashboardOpenServiceCallsLength = createSelector(
    selectDashboardServiceCallListState,
    (state) => {
        return state.arrival_calls.length + 
        state.end_of_first_day_calls.length + 
        state.end_of_second_day_calls.length + 
        state.friday_calls.length + 
        state.end_of_assignment_call.length
        + state.four_hour_guarantee.length
        ;
    }
);
