import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../../apis";
import { AppError } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";
import { ITalentBackOfficePayrollSetup } from "../../../../../interfaces/talent_back_office";

export const getTalentBOPayrollThunk = createAsyncThunk<
    ITalentBackOfficePayrollSetup,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/back_office_payroll_setup/get',
    async (query, thunkAPI) => {
        try {
            const res = await TalentAPI.getBOTalentPayrollSetup(query);
            if (typeof res.data === 'string') return [];
            return res.data as ITalentBackOfficePayrollSetup;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const updateTalentBOPayrollThunk = createAsyncThunk<
    string,
    { data: ITalentBackOfficePayrollSetup, nextStep: boolean, isRemove?: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/back_office_payroll_setup/patch',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.updateBOTalentPayrollSetup(payload.data);
            if (payload.nextStep) {
                thunkAPI.dispatch(getTalentBOPayrollThunk(payload.data.talent_id));
            }
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);