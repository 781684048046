import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import { selectState, selectSingleState, selectSingleTalentAvailableDatesInitialState } from "./talent_availability_reducer";

const select = (state: IRootState) => state.talent.details.dashboard.talentAvailableDates;
export const selectTalentAvailableDatesState = createSelector(select, selectState);
export const selectSingleTalentAvailableDatesStateById = (id: string) => createSelector(
    select, 
    (state) => {
        if (id && state[id]) return selectSingleState(state, id);
        return selectSingleTalentAvailableDatesInitialState();
    }
);