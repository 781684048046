import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./payment_method_thunk";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";

export interface PaymentMethodListState extends EntityState<AdminCenterBackOfficeType> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const PaymentMethodListAdapter: EntityAdapter<AdminCenterBackOfficeType> = createEntityAdapter<AdminCenterBackOfficeType>({
    selectId: (doc) => doc.id
})

const initialState: PaymentMethodListState = PaymentMethodListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const PaymentMethodListSlice = createSlice({
    name: 'PaymentMethodList',
    initialState: initialState,
    reducers: {
        clearPaymentMethodListError(state){
            return {...state, error: null};
        },
        clearPaymentMethodListState(state){
            return PaymentMethodListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearPaymentMethodCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearPaymentMethodUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearPaymentMethodDeleteState(state) {
            return { ...state, remove : {...initialState.remove} };
        },
        clearPaymentMethodCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearPaymentMethodUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearPaymentMethodDeleteStateError(state) {
            return { ...state, remove : { ...initialState.remove , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPaymentMethod.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getPaymentMethod.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getPaymentMethod.fulfilled, (state, action) => PaymentMethodListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createPaymentMethod.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createPaymentMethod.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createPaymentMethod.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updatePaymentMethod.pending, (state, action) => ({...state, update: {...state.update, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updatePaymentMethod.rejected, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updatePaymentMethod.fulfilled, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            
            .addCase(thunk.deletePaymentMethod.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deletePaymentMethod.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deletePaymentMethod.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const paymentMethodListActions = PaymentMethodListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = PaymentMethodListAdapter.getSelectors();
export const selectState = (state: PaymentMethodListState) => state;
export const selectLoading = (state: PaymentMethodListState) => state.loading;
export const selectError = (state: PaymentMethodListState) => state.error;


