import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import { LoadingType, THEME } from "../../../../enums";
import { CompaniesIcon } from "../../../../icons";
import { AppError, ICompBOInvoiceDocsMarkUp } from "../../../../interfaces";
import { FormInput, convertNumberToMoney, formatToMoney, CustomButton, allowNumbers } from "../../../../utils";
import './add_new_mark_up.scss';
interface Props {
    visible: boolean,
    title: string,
    successTitle: string,
    onClose: () => void,
    onDissmiss?: () => void,
    handleFormFieldChange: (fieldId: string, value: any, parent: string) => void;
    markUpFormState: ICompBOInvoiceDocsMarkUp;
    handleSave: () => void;
    currentLoginUserName: string;
    onSuccessClose: () => void;
    index: number | undefined;
    markupPOUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    companyName: string;
}
const AddNewMarkUpShiftPopUp: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        title,
        successTitle,
        handleFormFieldChange, markUpFormState, handleSave, currentLoginUserName, companyName, onSuccessClose, markupPOUpdateState, index
    } = props;

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const parentField = "mark_up";
    const loading = markupPOUpdateState.loading === LoadingType.succeeded;
    const isAddLoading = () => {
        return markupPOUpdateState?.loading === LoadingType.pending;
    };
    function handleWrapperClose(action?: () => void) {
        if (loading) {
            closePopup(props.onSuccessClose);
        } else {
            if (action) closePopup(action);
        }
    }
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
                <div className="add-new-markup-container">
                    <div className="add-new-markup-header">
                        <FormDialogTilteHeader
                            title={loading ? (index !== undefined ? t("new_markup") : successTitle) : (index === undefined ? title : t("update_markup"))}
                            titleIcon={<CompaniesIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="add-new-markup-content">
                        {loading ?
                            (<div className="saved-doc-holder">
                                <DocSavedText>
                                    <span className="aknw-name">
                                        {t("well_done", {
                                            name: currentLoginUserName,
                                        })}
                                    </span>
                                    <span>{index !== undefined ? t("well_done_updated_new_markup", { name: currentLoginUserName, companyName: companyName }) : t("well_done_added_new_markup", { name: currentLoginUserName, companyName: companyName })}</span>
                                </DocSavedText>
                            </div>)
                            :
                            (
                                <>
                                    <div className="add-new-markup-input-holder-row">
                                        <div className={"add-new-markup-input-holder"}>
                                            <FormInput
                                                id={'mark_up_name'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={markUpFormState.mark_up_name}
                                                label={t("what_markup_name")}

                                            />
                                        </div>
                                        <div className={"add-new-markup-input-holder"}>
                                            <FormInput
                                                id={'regular_mark_up'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                // value={convertNumberToMoney(markUpFormState.regular_mark_up, "string") as string}
                                                value={markUpFormState.regular_mark_up  as number}
                                                label={t("what_markup_regular_time")}
                                                suffix={<span>%</span>}
                                                // onBlur={formatToMoney}
                                                // onKeyDown={formatToMoney}
                                                // onKeyUp={formatToMoney}
                                                onKeyUp={(e) => { allowNumbers(e); }}
                                                onKeyDown={(e) => { allowNumbers(e); }}
                                            />
                                        </div>

                                        <div className={"add-new-markup-input-holder"}>
                                            <FormInput
                                                id={'over_time_mark_up'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                // value={convertNumberToMoney(markUpFormState.over_time_mark_up, "string") as string}
                                                value={markUpFormState.over_time_mark_up as number}
                                                label={t("what_markup_overtime_time")}
                                                suffix={<span>%</span>}
                                                // onBlur={formatToMoney}
                                                // onKeyDown={formatToMoney}
                                                // onKeyUp={formatToMoney}
                                                onKeyUp={(e) => { allowNumbers(e); }}
                                                onKeyDown={(e) => { allowNumbers(e); }}
                                            />
                                        </div>
                                        <div className={"add-new-markup-input-holder"}>
                                            <FormInput
                                                id={'double_time_mark_up'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                // value={convertNumberToMoney(markUpFormState.double_time_mark_up, "string") as string}
                                                value={markUpFormState.double_time_mark_up as number}
                                                label={t("what_markup_doubletime_time")}
                                                suffix={<span>%</span>}
                                                // onBlur={formatToMoney}
                                                // onKeyDown={formatToMoney}
                                                // onKeyUp={formatToMoney}
                                                onKeyUp={(e) => { allowNumbers(e); }}
                                                onKeyDown={(e) => { allowNumbers(e); }}
                                            />
                                        </div>
                                        <div className={"add-new-markup-input-holder toggle-row"}>
                                            <ToggleSwitch
                                                onChange={(val) => handleFormFieldChange('is_active', val, parentField)}
                                                label={t("active")}
                                                checked={markUpFormState.is_active!}
                                                labelPosition={'top'}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    </div>
                    <div className="add-new-markup-actions">
                        {loading ?
                            <div className="btn-save">
                                <CustomButton
                                    loading={isAddLoading()}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("ok")}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => closePopup(onSuccessClose)}
                                    enable={true}
                                />
                            </div>
                            :
                            (
                                <>
                                    <div className="btn-cancel">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("cancel")}
                                            enable
                                            backgroundColor={THEME.secondaryColor4}
                                            onClick={onClose}
                                        />
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={isAddLoading()}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("save")}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={handleSave}
                                            enable={true}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>);
}

export default AddNewMarkUpShiftPopUp;