import { useTranslation } from 'react-i18next';
import './simple_tab_bar.scss';

interface Props {
    selected: number,
    tabs: Array<{
        title: string,
        content: React.ReactNode,
    }>,
    actions?: React.ReactNode,
    onChange: (value: number) => void,
    onClose?: () => void,
    customStyle?: React.CSSProperties,
}

const SimpleTabbar = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div className="stb-container" style={{ ...props.customStyle }}>
            <div className="stb-header">
                <div className="stb-tab-options-container">
                    {props && props.tabs && props.tabs.map((doc, index) => {
                        const active = props.selected === index;
                        return (
                            <a
                                key={index}
                                className={`stb-tab-option ${(active ? 'active' : '')}`}
                                onClick={() => props.onChange(index)}
                            >
                                <span>{t(doc.title)}</span>
                            </a>
                        )
                    })}
                </div>
                {props.actions ?? <div></div>}
            </div>
            <div className="stb-content">
                {props.tabs[props.selected].content}
            </div>
        </div>
    );
}

export default SimpleTabbar;