import { EntityAdapter, EntityState, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import * as myProfileTeamDetailsThunk from "./my_profile_teams_details_thunk";
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { MyProfileTeams } from "../../../../interfaces/my_profile";

export interface MyProfileTeamsDetailsState extends EntityState<MyProfileTeams> {
    error?: AppError | null,
    loading: LoadingType,
    myProfileTeams: {
        error?: AppError | null,
        loading: LoadingType,
        response: MyProfileTeams
    },
    createMyProfileTeams: {
        error?: AppError | null,
        loading: LoadingType,
        response: string,
    },
}

const myProfileTeamsDetailsAdapter: EntityAdapter<MyProfileTeams> = createEntityAdapter<MyProfileTeams>({
    selectId: (doc) => doc.id
})  

const initialState: MyProfileTeamsDetailsState = myProfileTeamsDetailsAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    myProfileTeams: {
        error: null,
        loading: LoadingType.idle,
        response: {} as MyProfileTeams
    },
    createMyProfileTeams: {
        error: null,
        loading: LoadingType.idle,
        response: "",
    },
})



export const myProfileTeamsDetailsSlice = createSlice({
    name: 'myProfileTeamsDetails',
    initialState: initialState,
    reducers: {
        clearCreateMyProfileTeamState(state) {
            return { ...state, createMyProfileTeams: { ...initialState.createMyProfileTeams } };
        },

        clearMyProfileTeamError(state) {
            return {
                ...state,
                createMyProfileTeams: {
                    ...state.createMyProfileTeams,
                    error: null
                },
            }
        },
    },
    extraReducers: (builder) => {
        builder
       
            .addCase(myProfileTeamDetailsThunk.getMyProfileTeamsDetails.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(myProfileTeamDetailsThunk.getMyProfileTeamsDetails.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.error,  }))
            .addCase(myProfileTeamDetailsThunk.getMyProfileTeamsDetails.fulfilled, (state, action) => myProfileTeamsDetailsAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(myProfileTeamDetailsThunk.getMyProfileTeamMembersDetailsById.pending, (state, action) => ({ ...state, myProfileTeams:{ ...state.myProfileTeams, loading: LoadingType.pending, error: null}}))
            .addCase(myProfileTeamDetailsThunk.getMyProfileTeamMembersDetailsById.rejected, (state, action) => ({ ...state, myProfileTeams:{ ...state.myProfileTeams, loading: LoadingType.failed, error: action.error}}))
            .addCase(myProfileTeamDetailsThunk.getMyProfileTeamMembersDetailsById.fulfilled, (state, action) => ({ ...state, myProfileTeams:{ ...state.myProfileTeams, loading: LoadingType.succeeded, error: null , response: action.payload}}))

            .addCase(myProfileTeamDetailsThunk.createMyProfileTeamMembersDetailsById.pending, (state, action) => ({ ...state, createMyProfileTeams:{ ...state.createMyProfileTeams, loading: LoadingType.pending, error: null}}))
            .addCase(myProfileTeamDetailsThunk.createMyProfileTeamMembersDetailsById.rejected, (state, action) => ({ ...state, createMyProfileTeams:{ ...state.createMyProfileTeams, loading: LoadingType.failed, error: action.error}}))
            .addCase(myProfileTeamDetailsThunk.createMyProfileTeamMembersDetailsById.fulfilled, (state, action) => ({ ...state, createMyProfileTeams:{ ...state.createMyProfileTeams, loading: LoadingType.succeeded, error: null , response: action.payload}}))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const {clearCreateMyProfileTeamState, clearMyProfileTeamError } = myProfileTeamsDetailsSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = myProfileTeamsDetailsAdapter.getSelectors();
export const selectState = (state: MyProfileTeamsDetailsState) => state;
export const selectLoading = (state: MyProfileTeamsDetailsState) => state.loading;
export const selectError = (state: MyProfileTeamsDetailsState) => state.error;


