
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./payment_method_reducer";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.PaymentMethodList;
export const selectPaymentMethodListState = createSelector(select, selectState);
export const selectPaymentMethodList = createSelector(
    select,
    selectAll
);
export const selectPaymentMethodListEntities = createSelector(
    select,
    selectEntities
);