import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ITrafficEngagement, ITrafficLightEngagementDetails } from "../../../../interfaces";
import * as thunk from "./talent_traffic_engagement_thunk";

export interface TalentTrafficLightEngagementState {
    data: ITrafficLightEngagementDetails | null,
    error?: AppError | null,
    loading: LoadingType,
}



const initialState: TalentTrafficLightEngagementState = {
    data: null,
    error: null,
    loading: LoadingType.idle,
}


export const talentTrafficLightEngagementSlice = createSlice({
    name: 'talentTrafficLightEngagement',
    initialState: initialState,
    reducers: {
        clearTalentTrafficLightEngagementError(state) {
            return { ...state, error: null };
        },
        clearTalentTrafficLightEngagementState(state) {
            return ({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getDashboardTalentTrafficEngagement.pending,
                (state, action) => ({
                    ...state,
                    loading:  LoadingType.pending,
                    error: null,
                })
            )
            .addCase(
                thunk.getDashboardTalentTrafficEngagement.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getDashboardTalentTrafficEngagement.fulfilled,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null,
                    data: action.payload,
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearTalentTrafficLightEngagementError, clearTalentTrafficLightEngagementState } = talentTrafficLightEngagementSlice.actions;
export const selectState = (state: TalentTrafficLightEngagementState) => state;
export const selectLoading = (state: TalentTrafficLightEngagementState) => state.loading;
export const selectError = (state: TalentTrafficLightEngagementState) => state.error;


