import { createSlice } from "@reduxjs/toolkit"
import { LoadingType } from "../../../enums"
import { AppError } from "../../../interfaces"
import { IAdminRCVacationConfig } from "../../../interfaces/report_center"
import * as thunk from "./vacation_config_thunk";

export interface VacationConfigState {
    error?: AppError | null,
    loading: LoadingType,
    response: IAdminRCVacationConfig | null
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const initialState: VacationConfigState = {
    error: null,
    loading: LoadingType.idle,
    response: null,

    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
}
export const vacationConfigStateSlice = createSlice({
    name: "vacationConfig",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState }
        },
        clearErrorState(state) {
            return { ...initialState, error: null }
        },
        clearUpdateState(state) {
            return { ...state, update: { ...initialState.update } };
        },
        clearUpdateErrorState(state) {
            return { ...state, update: { ...initialState.update, error: null } };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getVacationConfigThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null }))
            .addCase(thunk.getVacationConfigThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload }))
            .addCase(thunk.getVacationConfigThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, response: action.payload }))

            .addCase(thunk.updateVacationConfigThunk.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.updateVacationConfigThunk.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.updateVacationConfigThunk.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});


export const vacationConfigSlice = vacationConfigStateSlice.actions;
export const selectState = (state: VacationConfigState) => state;