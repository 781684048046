import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI } from "../../../../apis";
import {
  AppError,
  ICreateInterviewCalendarInvite,
  IInterviewCalendarInvite,
} from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getInterviewCalendarInviteThunk = createAsyncThunk<
  Array<IInterviewCalendarInvite>,
  {
    this_week: boolean;
    get_count: boolean;
    completed: boolean;
    startDate?: number;
    endDate?: number;
  },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@dashboard/metrics/recruiting/online-applications/getInterviewCalendar",
  async (payload, thunkAPI) => {
    try {
      let res;
      if (payload.startDate && payload.endDate) {
        res = await DashboardAPI.getSendInterviewCalendarInvite(
          payload.this_week,
          payload.get_count,
          payload.completed,
          payload.startDate,
          payload.endDate
        );
      } else {
        res = await DashboardAPI.getSendInterviewCalendarInvite(
          payload.this_week,
          payload.get_count,
          payload.completed
        );
      }
      if (typeof res.data === "string") return [];
      return res.data as IInterviewCalendarInvite[];
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const saveInterviewCalendarInviteThunk = createAsyncThunk<
  string,
  ICreateInterviewCalendarInvite,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@dashboard/metrics/recruiting/online-applications/saveInterviewCalendar",
  async (payload, thunkAPI) => {
    try {
      const res = await DashboardAPI.saveSendInterviewCalendarInvite(payload);
      return res.data as string;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const updateInterviewCalendarInviteThunk = createAsyncThunk<
  string,
  ICreateInterviewCalendarInvite,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@dashboard/metrics/recruiting/online-applications/updateInterviewCalendar",
  async (payload, thunkAPI) => {
    try {
      const res = await DashboardAPI.updateSendInterviewCalendarInvite(payload);
      return res.data as string;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const getRemainingInterviewInviteCount = createAsyncThunk<
  number,
  { this_week: boolean; get_count: boolean; completed: boolean },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@dashboard/metrics/recruiting/online-applications/getRemainingCount",
  async (payload, thunkAPI) => {
    try {
      const res = await DashboardAPI.getSendInterviewCalendarInvite(
        payload.this_week,
        payload.get_count,
        payload.completed
      );
      if (typeof res.data === "string") return [];
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const getCompletedInterviewInviteCount = createAsyncThunk<
  number,
  { this_week: boolean; get_count: boolean; completed: boolean },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@dashboard/metrics/recruiting/online-applications/getCompletedCount",
  async (payload, thunkAPI) => {
    try {
      const res = await DashboardAPI.getSendInterviewCalendarInvite(
        payload.this_week,
        payload.get_count,
        payload.completed
      );
      if (typeof res.data === "string") return [];
      return res.data;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

export const archiveInterviewCalendarInviteThunk = createAsyncThunk<
  string,
  { ids: Array<string>; reason: string },
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>(
  "@dashboard/metrics/recruiting/online-applications/archiveInterview",
  async (payload, thunkAPI) => {
    try {
      const res = await DashboardAPI.archiveInterview(payload);
      return res.data as string;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);
