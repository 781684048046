import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../../apis/back_office_api";
import { ITimeCards, AppError, ITimeCardsReport, ITCErrorReports, IBackOfficeUploadUrlResponse } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";
import { S3API } from "../../../../../apis/s3_api";

export const getTCERListThunk = createAsyncThunk<
    ITCErrorReports[],
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_errors_report_list/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getTimeCardsErrorReports(payload);
            return res.data as ITCErrorReports[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const approveTCErrorThunk = createAsyncThunk<
    string,
    string[],
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_errors_report_list/approve/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.approveTimeCardsErrorReports(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const resolveTCErrorThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_errors_report_list/resolve/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.resolveTimeCardsErrorReports(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getTimeCardsErrorReportDownloadUrl = createAsyncThunk<
    IBackOfficeUploadUrlResponse,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@back_office/timecards/timecards_error_report/download/get',
    async (weekId, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getTimeCardsErrorReportDownloadUrl(weekId);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);