const {
  REACT_APP_API_URL,
  REACT_APP_WEB_CLIENT_ID,
  REACT_APP_FACEBOOK_ID,
  REACT_APP_VERSION,
  REACT_APP_BUILD_NUMBER,
  REACT_APP_YEAR,
  // NODE_ENV,
  REACT_APP_RUNTIME_ENV,
  REACT_APP_WEB_APP_DOMAIN,
  REACT_APP_PRIVACY_POLICY,
  REACT_APP_ABOUT_US,
  REACT_APP_MARKETING,
  // REACT_APP_TEXT_EM_ALL_API,
  REACT_APP_CONSUMER_KEY,
  REACT_APP_SECRET_KEY,
  REACT_APP_BROADCASTSTATUSCHANGE,
  REACT_APP_TEXTINBOUND,
  REACT_APP_TEXTOUTBOUND,
  REACT_APP_PDFTRON_LICENSE_KEY
} = process.env;

const googleSigninConfig = {
  scopes: ["email", "profile"], // what API you want to access on behalf of the user, default is email and profile
  webClientId: REACT_APP_WEB_CLIENT_ID, // client ID of type WEB for your server (needed to verify user ID and offline access)
  offlineAccess: true, // if you want to access Google API on behalf of the user FROM YOUR SERVER
  //hostedDomain: '', // specifies a hosted domain restriction
  //loginHint: '', // [iOS] The user's ID, or email address, to be prefilled in the authentication UI if possible. [See docs here](https://developers.google.com/identity/sign-in/ios/api/interface_g_i_d_sign_in.html#a0a68c7504c31ab0b728432565f6e33fd)
  //forceConsentPrompt: true, // [Android] if you want to show the authorization prompt at each login.
  //accountName: '', // [Android] specifies an account name on the device that should be used
  //iosClientId: '<FROM DEVELOPER CONSOLE>', // [iOS] optional, if you want to specify the client ID of type iOS (otherwise, it is taken from GoogleService-Info.plist)
};


const config = {
  WEB_APP_DOMAIN: REACT_APP_WEB_APP_DOMAIN,
  BASE_API_URL: REACT_APP_API_URL,
  VERSION: REACT_APP_VERSION,
  BUILD_NUMBER: REACT_APP_BUILD_NUMBER,
  YEAR: REACT_APP_YEAR,
  // ENV: NODE_ENV,
  ENV: REACT_APP_RUNTIME_ENV,
  GOOGLE_WEB_CLIENT_ID: REACT_APP_WEB_CLIENT_ID as string,
  FACEBOOK_ID: REACT_APP_FACEBOOK_ID as string,
  PRIVACY_POLICY: REACT_APP_PRIVACY_POLICY,
  ABOUT_US: REACT_APP_ABOUT_US,
  MARKETING: REACT_APP_MARKETING,
  GOOGLE_SIGN_IN_CONFIG: googleSigninConfig,
  TEXT_OUT_BOUND: REACT_APP_TEXTOUTBOUND,
  BROADCAST_STATUS_CHANGE: REACT_APP_BROADCASTSTATUSCHANGE,
  TEXT_IN_BOUND: REACT_APP_TEXTINBOUND,
  TEXT_EM_ALL_CONSUMER_KEY: REACT_APP_CONSUMER_KEY,
  TEXT_EM_ALL_SECRET_KEY: REACT_APP_SECRET_KEY,
  PDFTRON_LICENSE_KEY: REACT_APP_PDFTRON_LICENSE_KEY
};

export default config;
