import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import { AppRoutes, getAdminCenterNavHeaderProps } from "../../routes";
import { GetCloseAction } from "../../utils";
import PageNavHeader from "../../components/page_header/page_nav_header";
import VacationConfigPage from "./report_center/vacation_report_config_page";


export const ACReportCenterTabNav = (props: RouteComponentProps<any>) => {
    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();

    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                {
                    title: "vacation_report",
                    route: AppRoutes.acRCVacationReport,
                },

            ]}
            onChange={(value) =>
                history.push({ pathname: value, state: location.state })
            }
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.adminCenterPage)} />}
        >
            <Switch>
                <Route
                    exact
                    path={AppRoutes.acRCVacationReport}
                    component={VacationConfigPage}
                />
                <Route
                    path={"/"}
                    render={({ location }) => (
                        <Redirect
                            to={{
                                pathname: `${location.pathname}/vacation-report`,
                                state: { ...(location.state as any) },
                            }}
                        />
                    )}
                />
            </Switch>
        </TabNavigation>
    );
}

export const AdminReportCenterNavigation = (props: RouteComponentProps<any>) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getAdminCenterNavHeaderProps(location, match, t);
  
    return (
      <PageNavHeader data={headerOptions} history={history} location={location}>
        <Switch>
          <Route path={"/"} component={ACReportCenterTabNav} />
        </Switch>
      </PageNavHeader>
    );
  };    