import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType } from "../../../../enums";
import { AppError, ICompanyInvoices, ICompanyEdocs } from "../../../../interfaces";
import { TableLoading } from "../../../../components/table_empty/table_empty";
import { SpinnerScreen } from "../../../../utils";
import CompanyInvoicesList from "../../pages/company_back_office_invoice_docs/invoices/invoices_list";
import CompanyEdocsList from "../../pages/company_back_office_invoice_docs/edocs/edocs_list";
import "./edocs_invoice.scss";
import { BOBillingInvoiceIcon, DocumentIcon } from "../../../../icons";
import { selectePaycheckInvoiceState, getInvoicePDFURLThunk } from "../../../../redux/back_office";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
interface Props {
    visible: boolean,
    title: string,
    onClose: () => void,
    onDissmiss?: () => void,
    invoiceState: {
        error?: AppError | null;
        loading: LoadingType;
        response: ICompanyInvoices[] | null;
        mailInvoices: {
            error?: AppError | null;
            loading: LoadingType;
            response: string | null;
        }
    },
    edocState: {
        error?: AppError | null;
        loading: LoadingType;
        response: ICompanyEdocs[] | null;
        mailEdocs: {
            error?: AppError | null;
            loading: LoadingType;
            response: string | null;
        }
    },
    type: string,
    getInvoiceURL: (invoice_id: string) => void;
    sendInvoicesByMail: (invoice_ids: string[]) => void;
    sendEdocsByMail: (email_id: string, edoc_ids: string[]) => void;
    currentLoginUserName: string;
    company_id?: string;

}
const InvoiceEdocsPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        title,
        type,
        invoiceState,
        edocState, getInvoiceURL, sendInvoicesByMail, sendEdocsByMail, currentLoginUserName, company_id
    } = props;
    const actionRef = useRef<ActionDialogHolderType>(null);

    function handleClose() {
        closePopup(onClose)
    };

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"75%"}
            maxWrapperWidth={"75%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClose}>
                <div className="edocs-invoice-container">
                    <div className="edocs-invoice-header">
                        <FormDialogTilteHeader
                            title={title}
                            titleIcon={type === "invoices" ? <BOBillingInvoiceIcon width='100%' height="100%" /> : <DocumentIcon width='100%' height="100%" />}
                        />
                    </div>
                    <div className="edocs-invoice-content">
                        {type === "invoices" &&
                            <>
                                {invoiceState.loading === LoadingType.pending ?
                                    <SpinnerScreen />
                                    :
                                    <CompanyInvoicesList
                                        invoiceState={invoiceState}
                                        getInvoiceURL={(invoice_id) => getInvoiceURL(invoice_id)}
                                        sendInvoicesByMail={(invoice_ids) => sendInvoicesByMail(invoice_ids)}
                                        invoiceMailState={invoiceState.mailInvoices}
                                        currentLoginUserName={currentLoginUserName}
                                    />
                                }
                            </>
                        }
                        {type === "edocs" &&
                            <>
                                {edocState.loading === LoadingType.pending ?
                                    <SpinnerScreen />
                                    :
                                    <CompanyEdocsList
                                        edocState={edocState}
                                        sendEdocsByMail={(email_id, edoc_ids) => sendEdocsByMail(email_id, edoc_ids)}
                                        edocsMailState={edocState.mailEdocs}
                                        currentLoginUserName={currentLoginUserName}
                                        company_id={company_id}
                                    />
                                }
                            </>
                        }
                    </div>

                </div>

            </DialogWrapper>
        </ActionDialogHolder>
    );
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default InvoiceEdocsPopup;