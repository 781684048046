import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssignmentsAPI, ContactsAPI } from "../../../../apis";
import { S3API } from "../../../../apis/s3_api";
import { AppError, Assignment, ICreateResponse, IProfilePictureRequestQuery, IProfilePictureUploadUrlResponse } from "../../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../../utils";
import { getAssignmentsList } from "../assignments_list/assignments_list_thunk";


/// Create user
export const createAssignment = createAsyncThunk<
  ICreateResponse,
  { forceAdd: boolean, value: Assignment },
  {
    rejectValue: AppError | null,
    rejectedMeta: AppError | null,
  }
>(
  '@assignments/universal-directory/manage-assignment/create',
  async (payload, thunkAPI) => {
    try {
      const res = await AssignmentsAPI.createAssignment(payload)
      if (typeof res.data === 'string') return { status: res.data };
      return res.data as ICreateResponse;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);

/// Update user
export const updateAssignment = createAsyncThunk<
  ICreateResponse,
  Assignment,
  {
    rejectValue: AppError | null,
    rejectedMeta: AppError | null,
  }
>(
  '@assignments/universal-directory/manage-assignment/update',
  async (payload, thunkAPI) => {
    try {
      const res = await AssignmentsAPI.updateAssignment(payload);
      thunkAPI.dispatch(getAssignmentsList());
      if (typeof res.data === 'string') return { status: res.data };
      return res.data as ICreateResponse;
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);


/// Files upload
export const getAssignmentFileUploadURLThunk = createAsyncThunk<
  IProfilePictureUploadUrlResponse,
  { type: string, query: IProfilePictureRequestQuery },
  {
    rejectValue: AppError | null,
    rejectedMeta: AppError | null,
  }
>(
  '@assignments/universal-directory/manage-assignment/file-upload-url/get',
  async (payload, thunkAPI) => {
    try {
      const res = await S3API.getPreSignedURLForUpload(payload.query)
      if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
      return res.data as IProfilePictureUploadUrlResponse
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);


export const uploadAssignmentFileThunk = createAsyncThunk<
  any,
  { type: string, data: IProfilePictureUploadUrlResponse, file: File | null },
  {
    rejectValue: AppError | null,
    rejectedMeta: AppError | null,
  }
>(
  '@assignments/universal-directory/manage-assignment/file-upload-url/post',
  async (payload, thunkAPI) => {
    try {
      if (payload.file && payload.data.url) {
        const photoRes = await S3API.uploadFile(payload.data?.url, payload.file);
        return photoRes.data;
      }
      return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
    } catch (error) {
      return catchRequestError(error, thunkAPI);
    }
  }
);



