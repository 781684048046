import { combineReducers } from "@reduxjs/toolkit";

import { talentW2Slice } from "./talent_w2/talent_w2_reducer";


export const epEDocsReducers = combineReducers({
    [talentW2Slice.name] : talentW2Slice.reducer,
});

export * from "./talent_w2/talent_w2_thunk";
export * from "./talent_w2/talent_w2_selector";






