import { useRef } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { useTranslation } from "react-i18next";
import { BOOpenCloseIcon } from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton } from "../../../../utils";
import './open_close_week_ending.scss';
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { AppError } from "../../../../interfaces";
import ApiError from "../../../../components/api_error";
interface Props {
    visible: boolean,
    title: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    handleSubmit: () => void;
    action: string;
    weekEndingDate: string;
    currentLoginUserName: string;
    loading: LoadingType;
    error: AppError | null | undefined;
    handleClearError: () => void;
}

const OpenCloseWeekEndingPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        onDissmiss,
        onSuccessClose,
        handleSubmit,
        action, weekEndingDate, currentLoginUserName, loading, error, handleClearError
    } = props;
    const { t } = useTranslation();

    const actionRef = useRef<ActionDialogHolderType>(null);
    return (<ActionDialogHolder
        ref={actionRef}
        visible={visible}
        wrapperWidth={"fit-content"}
        onClose={onClose}
        onDissmiss={onDissmiss}
    >
        <DialogWrapper onClose={onClose}>
            <div className="oc-we-container">
                <div className="oc-we-header">
                    <FormDialogTilteHeader
                        title={title}
                        titleIcon={<BOOpenCloseIcon width={"100%"} height={"100%"} />}
                    />
                </div>
                <div className="oc-we-content">
                    {loading === LoadingType.succeeded ?
                        (<div className="saved-doc-holder">
                            {action === "open" ?
                                (
                                    <>
                                        <span>{t('congrats_week_ending_open', { name: currentLoginUserName, week: weekEndingDate })}</span>
                                        <div style={{ marginTop: '1rem'}}><span>{t('next_step_go_back')}<b style={{ fontWeight: "900" }}>{t("back_office_dashboard")}</b>{t("to_begin_entering_timecards")}</span></div>

                                    </>
                                )
                                :
                                (
                                    <span>{t('congrats_week_ending_close', { name: currentLoginUserName, week: weekEndingDate })}</span>
                                )
                            }
                        </div>)
                        :
                        (
                            <>
                                <span>{t("hi_name", { name: currentLoginUserName })}</span>
                                <span>{t("please_confirm_week_ending",
                                    { action: action, week: weekEndingDate })}</span>
                            </>
                        )
                    }
                     {error && <div className="oc-error-holder">
                    <ApiError message={error.message} onClose={handleClearError} />
                </div>}
                </div>
               
                <div className="oc-we-actions">
                    {loading === LoadingType.succeeded ? (<>
                        <div className="btn-save">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("ok")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={onSuccessClose}
                            />
                        </div></>) :
                        (<>
                            <div className="btn-cancel" style={{ marginRight: "1em" }}>
                                <CustomButton
                                    loading={false}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("cancel")}
                                    enable={true}
                                    backgroundColor={THEME.toggleDisableColor}
                                    onClick={onClose}
                                />
                            </div>
                            <div className="btn-save">
                                <CustomButton
                                    loading={loading === LoadingType.pending}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={action === "open" ? t("confirm_open") : t("confirm_close")}
                                    enable={true}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={handleSubmit}
                                />
                            </div>
                        </>)
                    }
                </div>
            </div>
        </DialogWrapper>

    </ActionDialogHolder>);
}

export default OpenCloseWeekEndingPopup;