import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, IDepartment } from "../../../../interfaces";
import * as thunk from "./department_list_thunk";

export interface DepartmentsListState extends EntityState<IDepartment> {
    error?: AppError | null,
    loading: LoadingType,
}

const departmentsListAdapter: EntityAdapter<IDepartment> = createEntityAdapter<IDepartment>({
    selectId: (doc) => doc.id,
})

const initialState: DepartmentsListState = departmentsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const departmentsListSlice = createSlice({
    name: 'departmentsList',
    initialState: initialState,
    reducers: {
        clearDepartmentsListError(state){
            return {...state, error: null};
        },
        clearDepartmentsListState(state){
            return departmentsListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getDepartmentsList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getDepartmentsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getDepartmentsList.fulfilled, (state, action) => departmentsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearDepartmentsListError, clearDepartmentsListState } = departmentsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = departmentsListAdapter.getSelectors();
export const selectState = (state: DepartmentsListState) => state;
export const selectLoading = (state: DepartmentsListState) => state.loading;
export const selectError = (state: DepartmentsListState) => state.error;


