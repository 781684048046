

import { createSlice } from '@reduxjs/toolkit';
import * as thunk from './reports_directory_thunk';
import { AppError, IEReportsDirectory } from '../../../../interfaces';
import { LoadingType } from '../../../../enums';

export interface ReportsDirectoryState {
    error?: AppError | null;
    loading: LoadingType;
    response: IEReportsDirectory[] | null;
    uploadMT: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    }
}

const initialState: ReportsDirectoryState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    uploadMT: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}

export const reportsDirectorySlice = createSlice({
    name: "reportsDirectoryList",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearUploadMTState(state){
            return { ...state, uploadMT:{...initialState.uploadMT} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getReportsDirectoryThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getReportsDirectoryThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getReportsDirectoryThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))

            .addCase(thunk.uploadToMasterTax.pending, (state, action) => ({ ...state, uploadMT: { ...state.uploadMT, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadToMasterTax.rejected, (state, action) => ({ ...state, uploadMT: { ...state.uploadMT, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.uploadToMasterTax.fulfilled, (state, action) => ({ ...state, uploadMT: { ...state.uploadMT, loading: LoadingType.succeeded, error: null, response: action.payload } }))


            .addDefaultCase((state) => ({ ...state }));
    }
});
export const reportsDirectorySliceActions = reportsDirectorySlice.actions;
export const selectState = (state: ReportsDirectoryState) => state;