import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { AppError, ITimeCardsData } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getPayrollTimecardsThunk = createAsyncThunk<
  ITimeCardsData[],
  string,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@back_office/payroll/payroll_timecards/get", async (payload, thunkAPI) => {
  try {
    const res = await BackOfficeAPI.getPayrollTimecards(payload);
    return res.data.time_cards as ITimeCardsData[];
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});
