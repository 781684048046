import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { Props } from "react-select";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../../components/api_error";
import DropZone from "../../../../../components/file_upload/file_upload_page";
import SortableTable, {
  TableData,
} from "../../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableLoading,
  TableErrorHandler,
  TableFilterEmpty,
} from "../../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../../enums";
import { DeleteIcon, DownloadIcon } from "../../../../../icons";
import { AgencyOnboarding, ToggleRoleData } from "../../../../../interfaces";
import {
  getAgencyOnBoarding,
  getAgencyOnboardingHandbookUploadURL,
  getHandbookFileDownloadURL,
  selectAgencyOnboardingFileDownloadState,
  selectAgencyOnboardingFileUploadState,
  selectAgencyOnboardingFileURLState,
  selectAgencyOnboardingListState,
  selectManageUserToggleRoleState,
  selectOnboardingUpdatestate,
  toggleUserRole,
  updateAgencyOnboarding,
  uploadAgencyOnboardingHandbookURL,
} from "../../../../../redux/admin_center";
import {
  getAgencyOnboardingDataSlice,
  manageAgencyOnboardingActions,
} from "../../../../../redux/admin_center/system_configuration/onboarding/onboarding_reducer";
import { manageUserActions } from "../../../../../redux/admin_center/user_management/manage_user/manage_user_reducer";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import { CustomButton, getUserRoleText } from "../../../../../utils";
import {
  convertDateToTimeStamp,
  getDateFromTimeStamp,
  getDateString,
} from "../../../../../variables";
import { DeleteConfirmationDialog } from "../../../../components/delete_confirmation_dialog";

const tableHeader = [
  { title: "name", code: "name" },
  { title: "uploaded", code: "uploaded" },
  { title: "actions", code: "actions" },
];

const AdminCenterOnBoardingCompany: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [onboardingData, setOnboardingData] = useState({} as AgencyOnboarding);
  const [files, setFiles] = useState<any>(null);
  const [sentRequest, setSentRequest] = useState(false);
  const [showDeleteAction, setShowDeleteAction] = useState<{
    doc: any;
    visible: boolean;
  } | null>(null);

  const fileDownloadState = useAppSelector((state) =>
    selectAgencyOnboardingFileDownloadState(state)
  );
  const agencyOnboardingState = useAppSelector((state) =>
    selectAgencyOnboardingListState(state)
  );
  const uploadURLState = useAppSelector((state) =>
    selectAgencyOnboardingFileURLState(state)
  );
  const uploadFileState = useAppSelector((state) =>
    selectAgencyOnboardingFileUploadState(state)
  );
  const saveResponse = useAppSelector((state) =>
    selectOnboardingUpdatestate(state)
  );

  useEffect(() => {
    if (
      agencyOnboardingState.loading === LoadingType.succeeded &&
      agencyOnboardingState.agencyOnboardingData
    ) {
      setOnboardingData(agencyOnboardingState.agencyOnboardingData);
    }
  }, [agencyOnboardingState.loading]);
  useEffect(() => {
    dispatch(getAgencyOnBoarding());
    return () => {

    };
  }, []);
  useEffect(() => {
    if (
      agencyOnboardingState.loading === LoadingType.succeeded &&
      agencyOnboardingState.agencyOnboardingData &&
      files &&
      files.length > 0
    ) {
      dispatch(
        getAgencyOnboardingHandbookUploadURL({
          file_name: files ? files[0].name : undefined,
        })
      );


    }
  }, [files]);

  useEffect(() => {
    if (saveResponse.loading == LoadingType.succeeded) {
      dispatch(getAgencyOnBoarding());
    }
  }, [saveResponse.loading]);
  //
  useEffect(() => {
    if (fileDownloadState.loading === LoadingType.succeeded) {
      let aTag = document.createElement("a");
      aTag.setAttribute("href", fileDownloadState.data?.url);
      aTag.setAttribute("target", "_blank");
      aTag.click();
      dispatch(manageAgencyOnboardingActions.clearFileDownloadState());
    }
  }, [fileDownloadState.loading]);

  const handleFiles = (files) => {
    setFiles(files);
  };

  const saveDocument = () => {
    if (
      uploadURLState.loading === LoadingType.succeeded &&
      uploadURLState.response &&
      uploadURLState.response.handbook_upload_url &&
      files
    ) {

      dispatch(
        uploadAgencyOnboardingHandbookURL({
          data: uploadURLState.response,
          handbook: files[0],
        })
      );
    }
    setSentRequest(true);
  };
  useEffect(() => {
    if (
      uploadFileState.loading === LoadingType.succeeded &&
      uploadFileState.response
    ) {
      dispatch(getAgencyOnBoarding());
      // dispatch(postSnackbarMessage(t("document_uploaded_successfully")));
      dispatch(manageAgencyOnboardingActions.clearOnboardingURLState());
      setSentRequest(false);
    }
    return () => { };
  }, [uploadFileState.loading]);
  /// Users table list

  function getDocsTableList() {
    if (agencyOnboardingState.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    return (
      <>
        <span className="tc-span">
          {`${t("total_count")}: `} <b className="total-count-number">1</b>
        </span>
        <SortableTable headerList={tableHeader} flexNumber={getFlexNumber}>
          {onboardingData &&
            [onboardingData].map((doc, index) => {
              const uploadedDate = getDateFromTimeStamp(
                doc.employee_handbook_upload_timestamp
              );

              var day = uploadedDate.getDate(); //To get the Current Date
              var month = uploadedDate.getMonth() + 1; //To get the Current Month
              var year = uploadedDate.getFullYear(); //To get the Current Year
              var hours = uploadedDate.getHours(); //To get the Current Hours
              var min = uploadedDate.getMinutes(); //To get the Current Minutes
              var sec = uploadedDate.getSeconds();
              const formatUploadDate = `${month < 10 ? '0' + month : month}.${day < 10 ? '0' + day : day}.${year}  ${hours < 10 ? '0' + hours : hours}:${min < 10 ? '0' + min : min}:${sec}`;
              const filename = doc?.employee_handbook_path?.split("/")[1];
              return (
                <tr key={1}>
                  <TableData customStyle={{ flex: getFlexNumber(0) }}>
                    <span>{filename}</span>
                  </TableData>
                  <TableData customStyle={{ flex: getFlexNumber(1) }}>
                    <span>{`${formatUploadDate}`}</span>
                  </TableData>
                  <TableData customStyle={{ flex: getFlexNumber(4) }}>
                    <CustomButton
                      customClassName="download-icon"
                      enable={true}
                      leftIcon={<div><DownloadIcon height={"0.7vw"} width={"0.7vw"}/></div>}
                      name={''}
                      onClick={() => handleDownload()}
                      loading={fileDownloadState.loading === LoadingType.pending}
                    />
                    <CustomButton
                      customClassName="delete-icon"
                      enable={true}
                      leftIcon={<div><DeleteIcon height={"0.7vw"} width={"0.7vw"}/></div>}
                      name={''}
                      loading={false}
                      backgroundColor={THEME.buttonColor2A}
                      onClick={() => handleDeleteHandbook(filename)}
                    />
                  </TableData>
                </tr>
              );
            })}
        </SortableTable>
      </>
    );
  }
  ///Delete Role
  function handleDeleteHandbook(doc: any) {
    setShowDeleteAction({ doc: doc, visible: true });
  }

  function handleDownload() {
    dispatch(getHandbookFileDownloadURL());
  }
  function getFlexNumber(value: number) {
    if (value === 0) return 1;
    if (value === 1) return 1;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 1;
    if (value === 8) return 1;
    if (value === 9) return 1;
    if (value === 10) return 1;
    if (value === 11) return 1;
    return 1;
  }
  /// close delete role dialog
  function handleCloseDeleteDialog() {
    setShowDeleteAction(null);
  }

  function handleClearError() {
    dispatch(manageAgencyOnboardingActions.clearUpdateStateError());
  }

  ///Delete user role
  function handleOnSuccessDeleteHandbookDialog() {
    dispatch(
      updateAgencyOnboarding({
        ...onboardingData,
        employee_handbook_path: "",
        employee_handbook_upload_timestamp: 0,
      })
    );
    setFiles(null);
    handleCloseDeleteDialog();
    dispatch(manageAgencyOnboardingActions.clearOnboardingURLState());

  }
  const acceptedFileFormats = {
    "application/pdf": [".pdf"],
  };
  return (
    <div className="ac-sc-ob-content-container">
      <div className="ac-sc-ob-content">
        {onboardingData &&
          onboardingData.employee_handbook_path === "" &&
          onboardingData.employee_handbook_upload_timestamp === 0 ? (
          <div>
            <div>
              <DropZone
                maxFiles={1}
                handleFiles={handleFiles}
                maxSize={26214400}
                accept={acceptedFileFormats}
                handbookMsg={true}
              />
            </div>
            <div className="ac-sc-ob-content-actions">
              <div className="btn-save">
                <CustomButton
                  loading={sentRequest}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("upload_handbook")}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={saveDocument}
                  enable={files}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="table-data">{getDocsTableList()}</div>
        )}
        
        {showDeleteAction?.visible && (
          <DeleteConfirmationDialog
            message={t("are_you_sure_you_want_to_delete_doc", { value: showDeleteAction?.doc })}
            onCancel={() => setShowDeleteAction(null)}
            onConfirm={handleOnSuccessDeleteHandbookDialog}
            deleting={saveResponse?.loading === LoadingType.pending}
            deleteType={t("handbook")}
            state={saveResponse}
            onClearError={handleClearError}
          />
        )}
      </div>
    </div>
  );
};

export default AdminCenterOnBoardingCompany;
