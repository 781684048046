import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './assignment_list_reducer';

const select = (state: IRootState) => state.jobs.details.assignments.assignmentsList;
export const selectJobsAssignmentsState = createSelector(select, reducer.selectJobsAssignmentsState);
export const selectSingleJobsAssignmentsStateById = (id: string | undefined | null) => createSelector(
    selectJobsAssignmentsState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleJobsAssignmentsState(state, id);
        return reducer.selectInitialSingleJobsAssignmentsState();
    }
);