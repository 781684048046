import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./mail_paycheck_invoices_thunk";
import { AppError, ICompanyEdocs } from "../../../interfaces";
import { LoadingType } from "../../../enums";

export interface BOMailPaycheckInvoiceState {
    mailPaycheck: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    mailInvoices: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    }
}

const initialState: BOMailPaycheckInvoiceState = {
    mailInvoices: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    mailPaycheck: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
};

export const mailInvoicePaycheckSlice = createSlice({
    name: "mailInvoicePaycheck",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearMailInvoiceState(state) {
            return { ...state, mailInvoices: { ...initialState.mailInvoices } };
        },
        clearMailPaycheckState(state) {
            return { ...state, mailPaycheck: { ...initialState.mailPaycheck } };
        },
    },
    extraReducers: (builder) => {
        builder
        
            .addCase(thunk.mailBOInvoicesThunk.pending, (state, action) => ({ ...state, mailInvoices: { ...state.mailInvoices, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.mailBOInvoicesThunk.rejected, (state, action) => ({ ...state, mailInvoices: { ...state.mailInvoices, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.mailBOInvoicesThunk.fulfilled, (state, action) => ({ ...state, mailInvoices: { ...state.mailInvoices, loading: LoadingType.succeeded, error: null, response: action.payload } }))


            .addCase(thunk.mailBOPaycheckThunk.pending, (state, action) => ({ ...state, mailPaycheck: { ...state.mailPaycheck, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.mailBOPaycheckThunk.rejected, (state, action) => ({ ...state, mailPaycheck: { ...state.mailPaycheck, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.mailBOPaycheckThunk.fulfilled, (state, action) => ({ ...state, mailPaycheck: { ...state.mailPaycheck, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase((state) => ({ ...state }));
    },
});
export const mailInvoicePaycheckSliceActions = mailInvoicePaycheckSlice.actions;
export const selectState = (state: BOMailPaycheckInvoiceState) => state;
// export const selectAll = (state: BOMailPaycheckInvoiceState) => state.mailInvoices.response;
