import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingType, THEME } from "../../enums";
import { AppError, LoginCredentials } from "../../interfaces";
import { CustomButton, FormInput, RegxPattern } from "../../utils";
import * as MtIcons from '@material-ui/icons';
import './login_form.scss';
import ApiError from "../../components/api_error";
import { TextField } from "@material-ui/core";
import OutlineInput from "../../components/outline_input/outline_input";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

interface Props {
    state: LoginCredentials,
    onSubmit: (credentials: LoginCredentials) => void,
    handleStateChange: (fieldId: any, value: any) => void,
    isFormVaid: boolean,
    loading: boolean,
    error?: AppError | null | undefined,
    onClearError: () => void,
    onResetClick: () => void
}


const LoginForm = (props: Props) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && props.isFormVaid) {
          // Prevent default form submission and call the onSubmit handler
          event.preventDefault();
          if (!props.loading) {
            props.onSubmit(props.state);
          }
        }
      };
    return (
        <div className="login-form-container"  onKeyDown={handleKeyDown}>
            <OutlineInput
                label={t('email')}
                needValidation={true}
                type={"emailAddress"}
                value={props.state.username}
                setValue={props.handleStateChange}
                id={"username"}
                pattern={RegxPattern.email} />
             <OutlineInput
                    label={t('password')}
                    type={showPassword ? "text" : "password"}
                    value={props.state.password}
                    setValue={props.handleStateChange}
                    id={'password'}
                    handlePasswordVisiblity={handleTogglePasswordVisibility}
                    suffix={showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                />
            {props.error && <div className="error-holder">
                <ApiError message={props.error.message} onClose={props.onClearError} />
            </div>}
            <div className="lg-actions">
                <div className="lg-login-btn">
                    <CustomButton
                        loading={props.loading}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('login')}
                        enable={props.isFormVaid}
                        padding={0}
                        fontSize={"1.2vw"}
                        backgroundColor={THEME.defaultHighLightColor} onClick={() => props.loading ? {} : props.onSubmit(props.state)}
                        customStyle={{ width: '-webkit-fill-available', borderRadius: '13px', padding: "0.7em 0em" }}
                    />
                </div>

            </div>
            <div className="lg-account-access" style={{ "marginTop": "15px" }}>
                <span onClick={props.onResetClick} style={{ cursor: "pointer", fontSize: "0.78vw", color: `#074783`, fontWeight: 700 }}>{t("forgot_password")}</span>
            </div>

        </div>
    );

}

export default LoginForm;