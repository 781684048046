import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import { selectState, selectTimeCardsReportDownloadUrl } from "./time_cards_report_list_reducer";

const select = (state: IRootState) => state.backOffice.timeCards.timecardsReportList;
export const selectTimecardsReportListState = createSelector(
    select,
    selectState
);
export const selectTCRDownloadUrlState = createSelector(
    select,
    selectTimeCardsReportDownloadUrl
);
