import { IconButton } from '@material-ui/core';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomPab from '../../../../components/custom_pab/custom_pab';
import { LoadingHandler, ErrorHandler, EmptyList } from '../../../../components/error_handler/error_handler';
import { LoadingType, PREFERED_CONTACT } from '../../../../enums';
import { TalentIcon, EmailBlueIcon, CallIcon, ChatIcon, PaperPlaneIcon } from '../../../../icons';
import { AppError, IServiceCall } from '../../../../interfaces';
import { ServiceCallsListState } from '../../../../redux/dashboard/metrics/service_calls/service_calls_reducer';
import { CustomButton, getContactTypeIcon } from '../../../../utils';
import { formatAMPM, getDateFromTimeStamp, getDateString, WEEK_DAYS } from '../../../../variables';
import TitleSectionHeader from '../title_section_header/title_section_header';
import * as MatIcons from '@material-ui/icons';
import './service_calls_list.scss';


/// [Sales board] list
interface Props {
    state: ServiceCallsListState,
    onClick: (value: IServiceCall) => void,
    onRefresh: () => void,
    serviceUsers: string[];
    serviceFormState: { available_from: number, available_to: number };
    serviceCallLength: Dispatch<SetStateAction<number>>;

}

const ServiceCallsList: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { serviceUsers, state, serviceFormState, serviceCallLength } = props
    if (props.state.loading === LoadingType.pending) {
        return (
            <LoadingHandler />
        )
    }

    if (props.state.error) {
        return (
            <ErrorHandler
                error={props.state.error}
                onRefresh={props.onRefresh}
            />
        )
    }

    function getFilteredList(list: IServiceCall[]) {
        let resultList: IServiceCall[] | undefined;
        // let totalLength = 0;

        if (serviceUsers?.length !== 0 && serviceFormState.available_from && serviceFormState.available_to) {
            resultList = (resultList ?? list).filter((doc) => {
                const isScheduleValid =
                    doc.date_of_scheduled_call !== null &&
                    serviceFormState.available_from <= doc.date_of_scheduled_call &&
                    doc.date_of_scheduled_call < serviceFormState.available_to;

                return isScheduleValid && (doc.modified_by !== null ? serviceUsers.includes(doc?.modified_by!) : serviceUsers.includes(doc?.created_by!));
            });


            if (serviceUsers?.length !== 0 && serviceFormState.available_from && serviceFormState.available_to) {
                return resultList
            }
        }
        if (serviceUsers?.length !== 0) {
            resultList = (resultList ?? list).filter((doc) => {
                const userIncluded = doc.modified_by !== null ? serviceUsers.includes(doc?.modified_by!) : serviceUsers.includes(doc?.created_by!);
                return userIncluded;
            })

            if (serviceUsers) {
                return resultList;
            }
        }
        if (serviceFormState.available_from && serviceFormState.available_to) {
            resultList = (resultList ?? list).filter((doc) => {
                const isScheduleValid =
                    doc.date_of_scheduled_call !== null &&
                    serviceFormState.available_from <= doc.date_of_scheduled_call &&
                    doc.date_of_scheduled_call < serviceFormState.available_to;

                return isScheduleValid;
            });

            if (serviceFormState.available_from && serviceFormState.available_to) {
                return resultList
            }

        }
        return list;
    }


    const totalLength = getFilteredList(state.arrival_calls).length +
        getFilteredList(state.four_hour_guarantee).length +
        getFilteredList(state.end_of_first_day_calls).length +
        getFilteredList(state.end_of_second_day_calls).length +
        getFilteredList(state.friday_calls).length +
        getFilteredList(state.end_of_assignment_call).length;

    serviceCallLength(totalLength);


    return (
        <div className="service-calls-list-holder">
            <ExpandableListItem
                title={t("arrival_calls").toUpperCase()}
                // count={props.state.arrival_calls.length}
                count={getFilteredList(state.arrival_calls).length}
                // list={serviceCards(props.state.arrival_calls, t('no_text', { value: t('arrival_calls') }))}
                list={serviceCards(getFilteredList(state.arrival_calls), t('no_text', { value: t('arrival_calls') }))}
            />
            <ExpandableListItem
                title={t("four_hour_guarantee").toUpperCase()}
                // count={props.state.four_hour_guarantee.length}
                count={getFilteredList(state.four_hour_guarantee).length}
                // list={serviceCards(props.state.four_hour_guarantee, t('no_text', { value: t("four_hour_guarantee") }))}
                list={serviceCards(getFilteredList(state.four_hour_guarantee), t('no_text', { value: t("four_hour_guarantee") }))}
            />
            <ExpandableListItem
                title={t("end_of_first_day_calls").toUpperCase()}
                count={getFilteredList(state.end_of_first_day_calls).length}
                // list={serviceCards(props.state.end_of_first_day_calls, t('no_text', { value: t("end_of_first_day_calls") }))}
                list={serviceCards(getFilteredList(state.end_of_first_day_calls), t('no_text', { value: t("end_of_first_day_calls") }))}
            />
            <ExpandableListItem
                title={t("end_of_second_day_calls").toUpperCase()}
                // count={props.state.end_of_second_day_calls.length}
                count={getFilteredList(state.end_of_second_day_calls).length}
                // list={serviceCards(props.state.end_of_second_day_calls, t('no_text', { value: t("end_of_second_day_calls") }))}
                list={serviceCards(getFilteredList(state.end_of_second_day_calls), t('no_text', { value: t("end_of_second_day_calls") }))}
            />
            <ExpandableListItem
                title={t("friday_calls").toUpperCase()}
                // count={props.state.friday_calls.length}
                count={getFilteredList(state.friday_calls).length}
                // list={serviceCards(props.state.friday_calls, t('no_text', { value: t("friday_calls") }))}
                list={serviceCards(getFilteredList(state.friday_calls), t('no_text', { value: t("friday_calls") }))}
            />

            <ExpandableListItem
                title={t("end_of_assignment_call").toUpperCase()}
                count={getFilteredList(state.end_of_assignment_call).length}
                // list={serviceCards(props.state.end_of_assignment_call, t('no_text', { value: t("end_of_assignment_call") }))}
                list={serviceCards(getFilteredList(state.end_of_assignment_call), t('no_text', { value: t("end_of_assignment_call") }))}
            />
            {/* <ExpandableListItem
                title={t("incomplete_calls").toUpperCase()}
                count={props.state.incomplete_calls.length}
                list={serviceCards(props.state.incomplete_calls, t('no_text', { value: t("incomplete_calls") }))}
            /> */}
        </div>

    )



    function serviceCards(list: IServiceCall[], emptyTitle: string) {
        if (list.length === 0) {
            return (
                <EmptyList
                    title={emptyTitle}
                    onClick={props.onRefresh}
                    height={'5.856515373352855vw'}
                />
            )
        }
        return (
            <div className="service-card-holder">
                {
                    // list.map((doc, index) => {
                    getFilteredList(list).map((doc, index) => {
                        const startDate = getDateFromTimeStamp(doc.date_of_scheduled_call);
                        // const endDate = getDate(doc.end_date);
                        const dateStr = getDateString(doc.date_of_scheduled_call);
                        const startTime = formatAMPM(startDate);
                        // const endTime = formatAMPM(endDate);
                        return (
                            <CustomPab
                                key={index}
                                onClick={() => props.onClick(doc)}
                                customStyle={{
                                    backgroundColor: '#F5FAFC',
                                    borderLeft: `4px solid ${'#79649E'}`,
                                    paddingLeft: '1em',
                                    marginTop: (index === 0) ? 0 : '1em',

                                }}
                            >
                                <div className="holder">
                                    <div className="info" style={{ color: '#79649E' }}>
                                        <div className="date-info" >
                                            <span style={{ fontWeight: 'bold' }}>{WEEK_DAYS[startDate.getDay()]}</span>
                                            <span style={{ fontWeight: 'bold' }}>{dateStr}</span>
                                            {/* <span style={{ fontSize: '0.625vw' }}>{`${startTime}`}</span> */}
                                        </div>
                                        <div className="user-info">
                                            <span style={{ fontSize: '0.78125vw', fontWeight: 'bold' }}>{doc.contact_name ?? '--'}</span>
                                            <span style={{ fontSize: '0.625vw' }}>{getCompanyName(doc)}</span>
                                            <span style={{ fontSize: '0.625vw', textTransform: 'none' }}>{t('assigned_to')}&#x3a;&nbsp;{doc.assigned_to_name}</span>
                                            <div className="talent-info">
                                                <div className="talent-icon">
                                                    <TalentIcon width={"100%"} height={"100%"} style={{ color: '#79649E' }} />
                                                </div>
                                                <div className="talent-name">
                                                    <span>{doc.talent_name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="actions">
                                        {doc.call_method && getPrefferedContact(doc.call_method.toUpperCase())}
                                    </div>
                                </div>
                            </CustomPab>
                        )
                    })
                }
            </div>
        )
    }

    function getCompanyName(doc: IServiceCall) {
        if (doc.company_name && doc.department_name) {
            return `${doc.company_name} - ${doc.department_name}`;
        }
        if (doc.company_name && doc.department_name === null) {
            return doc.company_name;
        }
        if (doc.company_name === null && doc.department_name) {
            return doc.department_name;
        }
        return '--'
    }


    function getPrefferedContact(value: string) {
        return (
            <div className="action-contact-icon">
                {getContactTypeIcon(value, "#79649E")}
            </div>
        );

    }
}

interface ExpandableListItemProps {
    title: string,
    count: number,
    actions?: React.ReactNode,
    list: React.ReactNode,
}

const ExpandableListItem: React.FunctionComponent<ExpandableListItemProps> = (props) => {
    const [expand, setExpand] = useState<boolean>(false);

    return (
        <>
            <TitleSectionHeader
                title={`${props.title} (${props.count})`}
                onClick={() => setExpand(!expand)}
            >
                {props.actions ?? getExpanIcon()}
            </TitleSectionHeader>
            {expand && props.list}
        </>
    )

    function getExpanIcon() {
        return (
            <div className="expnd-icon">
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setExpand(!expand)}
                >
                    {expand ? <MatIcons.ExpandLess fontSize="small" color="inherit" /> : <MatIcons.ExpandMore fontSize="small" color="inherit" />}
                </IconButton>
            </div>
        )
    }
}

export default ServiceCallsList;