import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./ar_aging_report_thunk";
import { IARAgingReport, IInvoiceList } from "../../../../interfaces/report_center_finance_accounting";

export interface ARAgingReportListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IARAgingReport | null,
    invoiceList: {
        error?: AppError | null,
        loading: LoadingType,
        response: IInvoiceList | null
    },
}

const initialState: ARAgingReportListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    invoiceList: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
}

export const aRAgingReportListSlice = createSlice({
    name: 'aRAgingReportList',
    initialState: initialState,
    reducers: {
        clearARAgingReportListError(state) {
            return { ...state, error: null };
        },
        clearARAgingReportListState(state) {
            return { ...state, ...initialState };
        },
        clearInvoiceListError(state) {
            return {
                ...state,
                invoiceList: { ...initialState.invoiceList, error: null }
            }
        },
        clearInvoiceListState(state) {
            return {
                ...state, invoiceList: {
                    ...initialState.invoiceList
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getARAgingReportList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getARAgingReportList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getARAgingReportList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addCase(thunk.getInvoiceList.pending, (state, action) => ({ ...state, invoiceList: { ...state.invoiceList, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getInvoiceList.rejected, (state, action) => ({ ...state, invoiceList: { ...state.invoiceList, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.getInvoiceList.fulfilled, (state, action) => ({ ...state, invoiceList: { ...state.invoiceList, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    },
});

export const { clearARAgingReportListError, clearARAgingReportListState, clearInvoiceListError, clearInvoiceListState } = aRAgingReportListSlice.actions;
export const selectState = (state: ARAgingReportListState) => state;



