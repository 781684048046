import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AppError, ISelectorOption } from "../../../interfaces";
import { IRecordPayment } from "../../../interfaces/back_office_acnts_receivable";
import { InvoicePaymentMethod, LoadingType, THEME } from "../../../enums";
import { convertDateToTimeStamp } from "../../../variables";
import CustomFormSelect from "../../../components/form_selector/form_select";
import { selectBankAccountList } from "../../../redux/admin_center/back_office";
import { useAppSelector } from "../../../redux/store";
import { allowNumbersWithDotAndFourDecimals, convertNumberToMoney, CustomButton, formatToMoney, FormInput } from "../../../utils";
import ApiError from "../../../components/api_error";
import DragAndDrop from "../../../components/drag_and_drop/drag_and_drop";
import { useTranslation } from "react-i18next";
import { DateInput } from "../../../components/date_input/date_input";



interface Props {
    loading: LoadingType,
    error: AppError | null | undefined,
    docsError: AppError | null | undefined,

    onSubmit: () => void,
    onClearError: () => void,
    customerList: ISelectorOption[] | undefined;
    invoiceList: ISelectorOption[] | undefined;
    bankAccountList: ISelectorOption[] | undefined;
    onCustomerChange: Dispatch<SetStateAction<string | null>>;
    customer: string | null;
    formState: IRecordPayment,
    handleFormFieldChange: (id: string, val: any) => void;
    handleCancel: () => void;
    successMessage: string;
    selectedFiles: Array<File> | null,
    onDocumentChange: (value: Array<File>) => void,
    onRemoveDocument: (index: number) => void,
}
const RecordPaymentForm: React.FunctionComponent<Props> = (props) => {
    const {
        loading, docsError,
        error, onSubmit, onClearError,
        customerList, invoiceList, bankAccountList,
        onCustomerChange, customer, formState,
        handleFormFieldChange, handleCancel, successMessage, selectedFiles,
        onDocumentChange, onRemoveDocument
    } = props;
    const { t } = useTranslation();
    const _isFormValid = () => {
        const { deposit_to_bank_id, paid_date, amount_paid, payment_method } = formState;
        if (deposit_to_bank_id === '' || payment_method === null || amount_paid === 0) return false;
        return true;
    }
    function validateFileType() {
        return null;
    }
    
    return (
        <div className="rp-form">
            <div className="rpf-content">
                <div className="rp-form-row">
                    <div className="rp-form-ip-holder">
                        <CustomFormSelect
                            list={customerList ? customerList : []}
                            customStyle={{ width: "-webkit-fill-available" }}
                            name={"customer"}
                            value={customer}
                            label={t("customer")}
                            placeholder={"Select"}
                            onChange={onCustomerChange}
                        />
                    </div>
                    <div className="rp-form-ip-holder">
                        <CustomFormSelect
                            list={invoiceList ? invoiceList : []}
                            customStyle={{ width: "-webkit-fill-available" }}
                            name={"invoice_id"}
                            value={formState.invoice_id}
                            label={t("search_by_invoice_number")}
                            placeholder={"Select"}
                            onChange={(val) => handleFormFieldChange("invoice_id", val)}
                        />
                    </div>
                    <div className="rp-form-ip-holder date-holder">
                        <DateInput
                            id={"paid_date"}
                            onChange={handleFormFieldChange}
                            // onKeyDown={handleKeyDown}
                            placeholder={""}
                            value={formState.paid_date}
                            required={false}
                            label={t('payment_date')}
                        />
                    </div>
                </div>
                <div className="rp-form-row">

                    <div className="rp-form-ip-holder">
                        <CustomFormSelect
                            list={Object.entries(InvoicePaymentMethod).map(([key, value]) => ({
                                label: value,
                                value: value,
                            }))}
                            customStyle={{ width: "-webkit-fill-available" }}
                            name={"payment_method"}
                            value={formState.payment_method}
                            label={t("payment_method")}
                            placeholder={"Select"}
                            required={true}
                            onChange={(val) => handleFormFieldChange("payment_method", val)}
                        />
                    </div>
                    <div className="rp-form-ip-holder">
                        <CustomFormSelect
                            list={bankAccountList ? bankAccountList : []}
                            customStyle={{ width: "-webkit-fill-available" }}
                            name={"deposit_to_bank_id"}
                            value={formState.deposit_to_bank_id}
                            label={t("deposit_to")}
                            placeholder={"Select"}
                            required={true}
                            onChange={(val) => handleFormFieldChange("deposit_to_bank_id", val)}
                        />
                    </div>
                    <div className="rp-form-ip-holder">
                        <FormInput
                            id={"reference_number"}
                            onChange={handleFormFieldChange}
                            type={"text"}
                            value={formState.reference_number}
                            label={t("reference_no")}
                            required={false}
                            placeholder="Enter Reference No."
                        />
                    </div>
                </div>
                <div className="rp-form-row row3">

                    <div className="rp-form-ip-holder">
                        <FormInput
                            id={"notes"}
                            onChange={handleFormFieldChange}
                            type={"text"}
                            value={formState.notes}
                            label={t("notes")}
                            placeholder="Notes"
                            multiline={true}

                        />
                    </div>
                    <div className="rp-form-ip-holder2">
                        <DragAndDrop
                            label={t('attachments')}
                            selectedFiles={selectedFiles}
                            accept={"image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                            onMultiChange={onDocumentChange}
                            onMultiRemove={onRemoveDocument}
                            formValidators={validateFileType}
                            multiple={true}
                            placeholder={t('attach_a_file')}
                        />
                    </div>
                    <div className="rp-form-ip-holder3">
                        <FormInput
                            id={'amount_paid'}
                            onChange={() => { }}
                            type={"text"}
                            step={"0.01"}
                            min={"0.00"}
                            value={convertNumberToMoney(formState.amount_paid, "string", 4) as number}
                            placeholder="0"
                            disabled={true}
                            prefix={<span>$</span>}
                            required={true}
                            label={t("amount_received")}

                        />
                    </div>

                </div>
            </div>
            <div className="rpf-actions-row">
                <div>
                    <div className="email-message">
                        <span style={{ color: "green", fontSize: '1vw' }}>{successMessage}</span>
                    </div>
                    {error && (
                        <div className={"error"}>
                            <ApiError message={error.message} onClose={onClearError} />
                        </div>
                    )}
                    {docsError && (
                        <div className={"error"}>
                            <ApiError message={docsError.message} onClose={onClearError} />
                        </div>
                    )}

                </div>
                <div className="rpf-actions">
                    <div className="btn-cancel" style={{ marginRight: "1em" }}>
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("cancel")}
                            enable={true}
                            backgroundColor={THEME.toggleDisableColor}
                            onClick={handleCancel}
                        />
                    </div>
                    <div className="btn-yes">
                        <CustomButton
                            loading={loading === LoadingType.pending}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("record_payment")}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={onSubmit}
                            enable={_isFormValid()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecordPaymentForm;