import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities, selectWorkCompClaimSave,selectWorkCompClaimDocUpload, selectDetailsState,selectFileDownloadResponse } from "./work_comp_claims_list_reducer";

const select = (state: IRootState) => state.riskManagement.universalDirectory.workCompClaimsList;
export const selectWorkCompClaimsListState = createSelector(select, selectState);
export const selectWorkCompClaimsList = createSelector(
    select,
    selectAll
);
export const selectWorkCompClaimsListEntities = createSelector(
    select,
    selectEntities
);
export const selectWorkCompClaimSaveResponse = createSelector(select,selectWorkCompClaimSave);
export const selectWorkCompClaimDocUploadState = createSelector(select,selectWorkCompClaimDocUpload);
export const selectWorkCompClaimTalentDetailsState=createSelector(select,selectDetailsState);
export const selectFileDownloadResponseState=createSelector(select,selectFileDownloadResponse);