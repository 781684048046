
export enum TrafficLightMetricsCategory {
    company = "COMPANY",
    contact = "CONTACT",
    talent = "TALENT"
}

export enum TrafficLightMetricsType {
    high = "HIGH",
    medium = "MEDIUM",
    low = "LOW",
}