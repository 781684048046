import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectAll, selectEntities, selectState } from "./my_call_deck_reducer";

const select = (state: IRootState) => state.salesMarketing.myCallDeck.myCallDeckList;
export const selectMyCallDeckListState = createSelector(select, selectState);
export const selectMyCallDeckList = createSelector(
    select,
    selectAll
);
export const selectMyCallDeckListEntities = createSelector(
    select,
    selectEntities
);