import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, SalesDocument } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { SalesMarketingAPI } from "../../../../apis/sales_marketing_api";

export const getSalesDocuments = createAsyncThunk<
    Array<SalesDocument>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-and-marketing/details/documents/documents/get',
    async (data, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.getSalesDocumentsAPI(data);
            return res.data as SalesDocument[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const createUploadVideo = createAsyncThunk<
    string,
    { branch_id?: string, file_name: string, file_path: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-and-marketing/details/documents/video-upload/post',
    async (data, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.createSalesDocumentVideo(data);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const createUploadDocument = createAsyncThunk<
    string,
    { branch_id?: string, file_name: string, notes: string | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-and-marketing/details/documents/document-upload/post',
    async (data, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.createSalesDocument(data);
            return res.data.url;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteUploadDocument = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-and-marketing/details/documents/document-upload/delete',
    async (data, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.deleteUploadDocument(data);
            // thunkAPI.dispatch(getSalesDocuments(data));
            return res
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
export const updateUploadDocument = createAsyncThunk<
    string,
    SalesDocument,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-and-marketing/details/documents/document-upload/update',
    async (data, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.updateUploadDocument(data);
            // thunkAPI.dispatch(getSalesDocuments(data.id));
            return res.data.url;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

