import { useTranslation } from "react-i18next";
import { AppError, FormInputOption, ICreateUser } from "../../../../../interfaces";
import ApiError from "../../../../../components/api_error";
import RenderInput from "../../../../../components/render_input/render_input";
import { RegxPattern, formatToPhone, enforceFormat } from "../../../../../utils";
import { US_states } from "../../../../../utils/States_US";
import { rolesList } from "../../../../../variables";
import { LinkIcon } from "../../../../../icons";


interface Props {
  formState: ICreateUser,
  handleFieldChange: (fieldId: string, value: any, parent?: string) => void,
}


const StepThreeUserForm: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { formState, handleFieldChange } = props


  const formBasicInformation: (FormInputOption | FormInputOption[])[] = [
    /// 1st row
    [
      {
        field: "first_name",
        type: "text",
        label: 'first_name',
        secure: false,
        required: true,
        placeholder: '',
        keyboardtype: ''
      },
      {
        field: "last_name",
        type: "text",
        label: 'last_name',
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: ''
      },
    ],
    /// 2nd row
    [
      {
        field: "street_address",
        type: "text",
        label: 'street_address',
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: ''
      },
      {
        field: "city",
        type: "text",
        label: 'city',
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: ''
      },
      {
        field: "state",
        type: "selector",
        label: 'state',
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: '',
        selectorList: US_states.map((doc) => ({
          label: doc.name,
          value: doc.code,
        })),
        multi: false,
      },
      {
        field: "zip_code",
        type: "text",
        label: 'zip_code',
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: ''
      },
    ],

    /// 3rd row
    [
      {
        field: "email",
        type: "text",
        label: 'email',
        pattern: RegxPattern.email,
        secure: false,
        required: true,
        placeholder: '',
        keyboardtype: ''
      },
      {
        field: "phone",
        type: "tel",
        label: 'phone',
        secure: false,
        required: false,
        placeholder: "(555) 555 - 5555",
        keyboardtype: '',
        maxLength: 16,
        onKeyUp: formatToPhone,
        onKeyDown: enforceFormat,
      },
      {
        field: "office_phone",
        type: "tel",
        label: 'office',
        secure: false,
        required: false,
        placeholder: "(555) 555 - 5555",
        keyboardtype: '',
        maxLength: 16,
        onKeyUp: formatToPhone,
        onKeyDown: enforceFormat,
      },
    ],
    /// 4th row
    [
      {
        field: "linkedin_url",
        parentField: "social_media",
        type: "text",
        label: 'linked_in',
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: ''
      },
      {
        field: "facebook_url",
        parentField: "social_media",
        type: "text",
        label: 'facebook',
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: ''
      },
      {
        field: "twitter_url",
        parentField: "social_media",
        type: "text",
        label: 'twitter',
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: ''
      },
      {
        field: "pinterest_url",
        parentField: "social_media",
        type: "text",
        label: 'pinterest',
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: ''
      },

    ]
  ];

  const formProfessionalInformation: (FormInputOption | FormInputOption[])[] = [
    [
      /// 5th row
      {
        field: "job_title",
        type: "text",
        label: 'job_title',
        secure: false,
        required: false,
        placeholder: '',
        keyboardtype: ''
      },
      {
        field: "roles",
        type: "text",
        label: 'user_role',
        secure: false,
        required: false,
        placeholder: 'select',
        keyboardtype: '',
        // selectorList: rolesList.map(doc => ({ ...doc, label: doc.label , value:doc.value })),
        multi: true,
        disabled: true
      },
    ],
    /// 6th row
    [

      {
        field: "show_ssn",
        type: "switch",
        label: 'view_ssn',
        secure: false,
        required: true,
        placeholder: '',
        keyboardtype: '',
        labelPosition: 'right'
      },
      {
        field: "texting",
        type: "switch",
        label: 'can_send_text',
        secure: false,
        required: true,
        placeholder: '',
        keyboardtype: '',
        labelPosition: 'right'
      },
      {
        field: "view_onboarding_data",
        type: "switch",
        label: 'view_onboarding_data',
        secure: false,
        required: true,
        placeholder: '',
        keyboardtype: '',
        labelPosition: 'right'
      },
      {
        field: "search_and_import_data",
        type: "switch",
        label: 'search_and_import_data',
        secure: false,
        required: true,
        placeholder: '',
        keyboardtype: '',
        labelPosition: 'right'
      },

    ]

  ];

  return (
    <div className="uf-container">
      <div className="uf-content">
        {getSectionOne()}
        {getSectionTwo()}
      </div>
    </div>
  );


  /// Section one 
  function getSectionOne() {

    return (
      <div className="uf-section-divider">
        <div className="uf-row flx-start">
          <div className="section-title-txt">
            <span>{t("user_basic_information").toLocaleUpperCase()}</span>
          </div>
        </div>
        {formBasicInformation.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="uf-row ">
                {doc.map((subDoc, subIndex) => {
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={userFormValidators}
                      className={"uf-input-holder"}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={userFormValidators}
              className={"uf-input-holder"}
            />
          );
        })}

      </div>


    );
  }

  function getSectionTwo() {
    return (
      <div className="uf-section-divider">
        <div className="uf-row flx-start">
          <div className="section-title-txt">
            <span>{t("professional_information").toLocaleUpperCase()}</span>
          </div>
        </div>
        {formProfessionalInformation.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="uf-row ">
                {doc.map((subDoc, subIndex) => {
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={userFormValidators}
                      className={"uf-input-holder"}
                      menuPlacement={'top'}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={userFormValidators}
              className={"uf-input-holder"}
            />
          );
        })}
      </div>
    )
  }


  function getLinkIcon() {
    return (
      <div className="uf-link-icon">
        <LinkIcon width={"100%"} height={"100%"} />
      </div>
    );
  }

  function userFormValidators(value: { text: string, field: string }) {
    switch (value.field) {
      case "firstname": {
        if (value.text == '') return t('validators.required');
        if (RegxPattern.username.test(value.text) === false) return t("validators.enterValidName");
        return null;
      }
      case "email": {
        if (!value.text) return null;
        if (RegxPattern.email.test(value.text) === false)
          return t("validators.enterValidEmail");
        return null;
      }
      case "linkedin_url":
      case "facebook_url":
      case "twitter_url":
      case "pinterest_url": {
        if (!value.text) return null;
        if (RegxPattern.url.test(value.text) === false)
          return t("validators.enterValidUrl");
        return null;
      }
      case "phone": {
        if (!value.text) return null;
        if (RegxPattern.usPhone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      case "office_phone": {
        if (!value.text) return null;
        if (RegxPattern.usPhone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      case "zip_code": {
        if (value.text === "") return null;
        if (RegxPattern.zipCode.test(value.text) === false)
          return t("validators.enterValidZipCode");
        return null;
      }
      default: {
        return null;
      }
    }
  }
}

export default StepThreeUserForm;