import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { AppError } from "../../../../interfaces";
import { IWeeklyAccounting } from "../../../../interfaces/back_office_weekly_accounting";
import { catchRequestError } from "../../../../utils";


export const getWeeklyAccountingThunk = createAsyncThunk<
    IWeeklyAccounting[],
    void,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/weekly_accounting/weekly_accounting_dashboard/get",
    async (_, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getWeeklyAccounting();
            return res.data as IWeeklyAccounting[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getWeeklyAccountingOpenThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/weekly_accounting/weekly_accounting_dashboard_open/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getWeeklyAccountingOpen(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getWeeklyAccountingCloseThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/weekly_accounting/weekly_accounting_dashboard_close/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getWeeklyAccountingClose(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });