import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { request } from "http";
import { LoadingType, ProfilePictureType } from "../../../enums"
import { AppError, IProfilePictureUploadUrlResponse } from "../../../interfaces"
import * as thunk from './profile_picture_thunk';


export interface SingleProfilePictureState {
    response: string | null,
    error?: AppError | null,
    loading: LoadingType,
    download: {
        response: string | null,
        error?: AppError | null,
        loading: LoadingType,
    },
}

export interface ProfilePictureState {
    [key: string]: SingleProfilePictureState
}


const initialState: ProfilePictureState = {}

export const profilePictureSlice = createSlice({
    name: 'profilePicture',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getPreSignedURLForUpload.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.object_type + '-' + action.meta.arg.object_id]: {
                        ...state[action.meta.arg.object_type + '-' + action.meta.arg.object_id],
                        loading: LoadingType.pending,
                        response: '',
                        download: {
                            response: '',
                            error: null,
                            loading: LoadingType.idle,
                        },
                    }
                })
            )
            .addCase(
                thunk.getPreSignedURLForUpload.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.object_type + '-' + action.meta.arg.object_id]: {
                        ...state[action.meta.arg.object_type + '-' + action.meta.arg.object_id],
                        loading: LoadingType.failed,
                        error: action.payload,
                        response: '',
                        download: {
                            response: '',
                            error: null,
                            loading: LoadingType.idle,
                        },
                    }
                })
            )
            .addCase(
                thunk.getPreSignedURLForUpload.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.object_type + '-' + action.meta.arg.object_id]: {
                        ...state[action.meta.arg.object_type + '-' + action.meta.arg.object_id],
                        loading: LoadingType.succeeded,
                        response: action.payload,
                        error: null,
                        download: {
                            response: '',
                            error: null,
                            loading: LoadingType.idle,
                        },
                    }
                })
            )

            .addCase(
                thunk.getPreSignedURLForDownload.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.object_type + '-' + action.meta.arg.object_id]: {
                        ...state[action.meta.arg.object_type + '-' + action.meta.arg.object_id],
                        download: {
                            ...state.download,
                            loading: LoadingType.pending,
                            response: '',
                        }
                    }
                })
            )
            .addCase(
                thunk.getPreSignedURLForDownload.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.object_type + '-' + action.meta.arg.object_id]: {
                        ...state[action.meta.arg.object_type + '-' + action.meta.arg.object_id],
                        download: {
                            ...state.download,
                            loading: LoadingType.failed,
                            error: action.payload,
                            response: ''
                        }
                    }
                })
            )
            .addCase(
                thunk.getPreSignedURLForDownload.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.object_type + '-' + action.meta.arg.object_id]: {
                        ...state[action.meta.arg.object_type + '-' + action.meta.arg.object_id],
                        download: {
                            ...state.download,
                            loading: LoadingType.succeeded,
                            response: action.payload,
                            error: null
                        }
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});

export const profilePictureSliceActions = profilePictureSlice.actions;
export const selectProfilePictureState = (state: ProfilePictureState) => state;
export const selectSingleProfilePictureState = (state: ProfilePictureState, id: string): SingleProfilePictureState => state[id];

export const selectInitialSingleProfilePictureState = (): SingleProfilePictureState => ({
    response: '',
    error: null,
    loading: LoadingType.idle,
    download: {
        response: '',
        error: null,
        loading: LoadingType.idle,
    },
});