import { useTranslation } from 'react-i18next';
import './my_profile_info_card.scss'
import { CallIcon, DeleteIcon, EditIcon, EmailIcon, FacebookIcon, LinkedInIcon, LocationIcon, PinterestIcon, TwitterIcon } from '../../icons';
import { MyProfileTeams, TeamMembers } from '../../interfaces/my_profile';
import { addressParser, getHttpsString } from '../../utils';
import { useState } from 'react';

interface Props {
    onMouseOut?: () => void;
    onMouseOver?: () => void;
    onTeamSelect?: () => void;
    onRemove: (id: string | undefined, name: string | undefined) => void;
    onUpdate: (teamId: string | undefined) => void;
    teamMemberData?: TeamMembers;
    teamData?: MyProfileTeams;
    teamId?: string;
    hideActions?: boolean;
}

const MyProfileUserInfoCard: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { teamMemberData, teamData, onRemove, onTeamSelect, onUpdate, teamId, hideActions } = props;
    const isTeamMember = teamId !== undefined && teamId !== "" && teamMemberData;
    const isTeam = teamId === undefined && teamData;
    const picURL = isTeamMember ? teamMemberData?.profile_pic : teamData?.photo_url;
    const [isHovering, setIsHovering] = useState<boolean>(false);

    return (
        <>
            {isTeam && hideActions && <div className='mps-user-del-upd'>
                <div><EditIcon className='edit-icon' onClick={() => onUpdate(teamData !== undefined ? teamData.id : undefined)} /></div>
                <div><DeleteIcon className='delete-icon' onClick={() => onRemove(teamData !== undefined ? teamData.id : undefined, isTeamMember ? teamMemberData?.name : teamData?.name)} /></div>
            </div>}
            <div className={isTeam ? 'mps-user-info cursor-p mps-team' : 'mps-user-info mps-team-member'} onClick={onTeamSelect}>
                <div className='mps-card-row'>
                    <div className='mps-card-row-left'>
                        <div className='mps-name-title'>
                            <div className={`${isTeam ? 'cursor-p mps-avatar-holder' : "mps-avatar-holder"}`} onClick={onTeamSelect}>
                                <div className="mps-avatar tm-mps-avatar" >
                                    <div className="mps-avatar-img background-img" style={picURL ? { backgroundImage: `url(${picURL})` } : {}} ></div>
                                </div>
                            </div>
                            <div className='mps-user-name' onMouseOut={() => { setIsHovering(false) }}
                                onMouseOver={() => { setIsHovering(true) }}>
                                <div>{(teamMemberData?.name !== "" || teamData?.name !== "") ? isTeamMember ? teamMemberData?.name : teamData?.name : '-'}</div>
                            </div>
                            {isHovering && (
                                <div className="parent-position">
                                    <div className="child-position">
                                        <div className="action-list-title" >{isTeamMember ? teamMemberData?.name : teamData?.name}</div>
                                    </div>
                                </div>
                            )}
                            <div>
                                {isTeam &&
                                    <span>{(teamData.members_ids).length} {t("members")}</span>
                                }
                                {isTeamMember && <span>{teamMemberData.title !== "" ? teamMemberData.title : '-'}</span>}
                            </div>
                        </div>
                    </div>
                    <div className='mps-contact-info'>
                        {isTeamMember && (
                            <>
                                <div className='mps-info-row'>
                                    <div className="mps-user-icon"><LocationIcon /></div>
                                    <div>{addressParser({
                                        'street_address': teamMemberData.street_address ?? null,
                                        'city': teamMemberData.city ?? null,
                                        'state': teamMemberData.state ?? null,
                                        'zip_code': teamMemberData.zip_code ?? null,
                                    })}</div>
                                </div>
                                <div className='mps-info-row'>
                                    <div className="mps-user-icon"><CallIcon /></div>
                                    <div>
                                        {teamMemberData.phone !== null && (<div>{t("mobile")}&emsp;<b>{teamMemberData.phone}</b></div>)}
                                        <div>{t("office_phone")}&emsp; {teamMemberData.office_phone !== null ? (<b>{teamMemberData.office_phone}</b>) : "-"}</div>
                                    </div>
                                </div>
                                <div className='mps-info-row'>
                                    <div className="mps-user-icon"><EmailIcon /></div>
                                    <div style={{ textTransform: "none", wordBreak: "break-word" }}>{teamMemberData.email}</div>
                                </div>
                                <div className='mps-info-row'>
                                    <div className='social-media-row'>
                                        {teamMemberData.social_media?.linkedin_url &&
                                            <a href={getHttpsString(teamMemberData.social_media?.linkedin_url)} target="_blank" className="social-media-info-icon" rel="noreferrer"
                                            >
                                                <span className="mps-user-icon" ><LinkedInIcon /></span>
                                            </a>}
                                        {teamMemberData.social_media?.twitter_url &&
                                            <a href={getHttpsString(teamMemberData.social_media?.twitter_url)} target="_blank" className="social-media-info-icon" rel="noreferrer"
                                            >
                                                <span className="mps-user-icon" ><TwitterIcon /></span>
                                            </a>}
                                        {teamMemberData.social_media?.facebook_url &&
                                            <a href={getHttpsString(teamMemberData.social_media?.facebook_url)} target="_blank" className="social-media-info-icon" rel="noreferrer"
                                            >
                                                <span className="mps-user-icon" ><FacebookIcon /></span>
                                            </a>}
                                        {teamMemberData.social_media?.pinterest_url &&
                                            <a href={getHttpsString(teamMemberData.social_media?.pinterest_url)} target="_blank" className="social-media-info-icon" rel="noreferrer"
                                            >
                                                <span className="mps-user-icon" ><PinterestIcon /></span>
                                            </a>}
                                    </div>
                                </div>

                            </>
                        )}
                    </div>
                </div>

            </div>
        </>

    );


}

export default MyProfileUserInfoCard;