import { useTranslation } from 'react-i18next';
import { CallIcon, CompaniesIcon, EditIcon, LocationIcon, LockIcon, TickGreenIcon } from '../../../../../../icons';
import { FormInput, addressParser, getArrayStrings } from '../../../../../../utils';
import { getDaysAgoWithDateAMPM } from '../../../../../../variables';
import SocialMediaProfile from '../../../../../../components/social_media_profile/social_media_profile';
import '../my_profile_dashboard_details_section/my_profile_dashboard_section.scss'
import { MyProfile } from '../../../../../../interfaces/my_profile';



interface Props {
    myProfile: MyProfile | null,
    handlePasswordUpdate: () => void,
    handleProfileUpdate: () => void,
}



const MyProfileSection: React.FunctionComponent<Props> = (props) => {
    const { myProfile, handlePasswordUpdate, handleProfileUpdate } = props;
    const { t } = useTranslation();

    const profileSections = [
        {
            sectionTitle: 'company',
            sectionIcon: CompaniesIcon,
            sectionContent: [
                { title: 'company_name', valueField: 'agency_name' },
                { title: 'job_title', valueField: 'job_title' },
            ]
        },
        {
            sectionTitle: 'address',
            sectionIcon: LocationIcon,
            sectionContent: [
                {
                    title: 'address', valueField: addressParser({
                        'street_address': myProfile ? myProfile['street_address'] : null,
                        'city': myProfile ? myProfile['city'] : null,
                        'state': myProfile ? myProfile['state'] : null,
                        'zip_code': myProfile ? myProfile['zip_code'] : null,
                    })
                },
            ]
        },

        {
            sectionTitle: 'contact',
            sectionIcon: CallIcon,
            sectionContent: [
                { title: 'contact', valueField: '' },
                { title: 'mobile', valueField: 'phone' },
                { title: 'office', valueField: 'office_phone' },
                { title: 'email', valueField: 'email' },
            ]
        },
        {
            sectionTitle: 'password',
            sectionIcon: LockIcon,
            sectionContent: [
                { title: 'password', valueField: 'password' },
            ]
        },
    ]

    function lastLoginActivity() {
        const lastLogin = myProfile?.last_login ? getDaysAgoWithDateAMPM(t, myProfile?.last_login) : null;
        return (
            <div className="mp-user-last-activity">
                {lastLogin && <div className="last-engagement-txt">
                    <span>{t('last_login')}</span>
                    <div className='mp-last-login-txt'>{lastLogin}</div>
                </div>}
            </div>
        )
    }

    return (
        <div className='my-profile-info-container'>
            <div className="ups-container">
                <UserInfo name={`${myProfile?.first_name ?? ""} ${myProfile?.last_name ?? ""}`} url={myProfile?.picture_url} handleProfileUpdate={handleProfileUpdate}/>
                {lastLoginActivity()}
                {profileSections.map((doc, index) => {
                    return (
                        <div key={index} className="ups-info-container">
                            <div className="ups-info-icon">
                                <doc.sectionIcon width={"100%"} height={"100%"} />
                            </div>
                            <div className="ups-info-content">
                                {doc.sectionContent.map((data, index) => {
                                    const arrayValue = getValues(data.valueField);

                                    return (
                                        <div key={index} className="ups-info-holder">
                                            {(data.title !== 'mobile' && data.title !== 'office' && data.title !== 'email') ?
                                                <div>
                                                    <div className="ups-into-title">
                                                        <span>{t(data.title).toUpperCase()}</span>
                                                    </div>
                                                    <div className="ups-info-value"
                                                        style={{ marginBottom: '0.5em' }}
                                                    >
                                                        {arrayValue}
                                                    </div>
                                                </div>
                                                :
                                                <table className='contact-table'>
                                                    <tr><td><span>{t(data.title)}</span></td>
                                                    {
                                                        data.title === 'email' ? 
                                                         <a href={`mailto:${myProfile?.email}`}><td><span className='email-link'>{arrayValue}</span></td></a>
                                                        : <td><span>{arrayValue}</span></td>
                                                    }
                                                    </tr>
                                                </table>
                                            }
                                            {
                                                data.title === 'password' &&
                                                    <div className='password-container'>
                                                        <div className='my-profile-icon-parent'>
                                                            <FormInput value={"12345678901234"} placeholder={""} type={'password'} id={'password'} onChange={() => { }} disabled />
                                                            <div className="my-profile-icon-child">
                                                                <TickGreenIcon width={"100%"} height={"100%"} />
                                                            </div>
                                                        </div>
                                                        <div className='my-profile-edit-icon'>
                                                            <button onClick={handlePasswordUpdate}>
                                                                <EditIcon width={"1vw"} height={"1vw"} />
                                                            </button>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
                {(myProfile != null && myProfile.social_media != null) &&
                    <SocialMediaProfile
                        title={t('social_media').toUpperCase()}
                        profile={myProfile?.social_media}
                    />}
            </div>
        </div>
    );

    function getValues(obj: any) {
        if (myProfile == null) return (<span>{''}</span>);

        if (Array.isArray(obj)) {
            return getArrayStrings(obj, myProfile);
        }
        if (typeof obj === "object") {
            return obj;
        }
        const value = (obj !== '' && myProfile![obj] != null) ? myProfile![obj] : '';
        return (
            <span>{value}</span>
        );
    }
}

const UserInfo = (props) => {
    const { name, url, handleProfileUpdate } = props;
    return (
        <div className="ups-user-info">
            <div className="ups-avatar-holder" onClick={handleProfileUpdate}>
                <div className="ups-avatar background-img" style={url ? { backgroundImage: `url(${url})` } : {}}></div>
            </div>
            <div className="ups-user-name">
                <span>{name}</span>
            </div>
        </div>
    );
}

export default MyProfileSection;
