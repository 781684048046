

import { createSlice } from '@reduxjs/toolkit';
import { LoadingType } from '../../../../../enums';
import { AppError, IBackOfficeUploadUrlResponse, ITimeCardsReport } from '../../../../../interfaces';
import * as thunk from './time_cards_report_list_thunk';

export interface TimecardsReportListState {
    error?: AppError | null;
    loading: LoadingType;
    response: ITimeCardsReport | null;
    fileURL: {
        error?: AppError | null;
        loading: LoadingType;
        response: IBackOfficeUploadUrlResponse | null;
    };
}

const initialState: TimecardsReportListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
    fileURL: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
}

export const timecardsReportListSlice = createSlice({
    name: "timecardsReportList",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
        clearDownloadState(state) {
            return {
                ...state, fileURL: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                }
            }
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTimeCardsReportListThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getTimeCardsReportListThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getTimeCardsReportListThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))

            .addCase(thunk.getTimeCardsReportDownloadUrl.pending, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getTimeCardsReportDownloadUrl.rejected, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.getTimeCardsReportDownloadUrl.fulfilled, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase((state) => ({ ...state }));
    }
});
export const timecardsReportListSliceActions = timecardsReportListSlice.actions;
export const selectState = (state: TimecardsReportListState) => state;
export const selectTimeCardsReportDownloadUrl = (state: TimecardsReportListState) => state.fileURL;