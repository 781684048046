import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, ContactStatus } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getContactStatus = createAsyncThunk<
    Array<ContactStatus>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/contact_status/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getContactStatus();
            return res.data as ContactStatus[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createContactStatus = createAsyncThunk<
    string,
    {'value': string, 'description': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/contact_status/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createContactStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteContactStatus = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/contact_status/delete',
    async (method_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteContactStatus(method_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateContactStatus = createAsyncThunk<
    string,
    {'id': string, 'value': string, 'description': string, 'sequence_number': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/contact_status/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateContactStatus(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);