import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../enums";
import { AppError, ICreateResponse, ITalent, IUploadUrlsResponse } from "../../interfaces";
import * as thunk from "./employee_dashboard_thunk";

export interface EmployeeDashboardState {
    error?: AppError | null,
    loading: LoadingType,
    response: ITalent | undefined,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
    update: {
        error?: AppError | null;
        loading: LoadingType;
        response?: ICreateResponse;
    };
    uploadUrls: {
        error?: AppError | null,
        loading: LoadingType,
        response: IUploadUrlsResponse | null,
    },
    uploadFiles: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }
}

const initialState: EmployeeDashboardState = {
    error: null,
    loading: LoadingType.idle,
    response: undefined,
    create: {
        error: null,
        loading: LoadingType.idle,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: undefined
    },
    uploadUrls: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    uploadFiles: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
}

export const employeeDashboardSlice = createSlice({
    name: 'employeeDashboardState',
    initialState: initialState,
    reducers: {
        clearEmployeeState(state) {
            return { ...state, loading: LoadingType.idle, error: null };
        },
        initializeManageEmployeeState(state) {
            return {
                ...state,
                ...initialState,

            }
        },
        clearUpdateEmployeeState(state) {
            return { ...state, update: { ...initialState.update } };
        },
        clearEmployeeDashboardStateError(state) {
            return {
                ...state,
                loading: LoadingType.pending,
                error: null,
                initialState: initialState,
                update: {
                    ...initialState.update,
                    error: null,
                },

            }
        },
        clearManageEmployeeState(state) {
            return {
                ...state,
                create: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                update: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                uploadFiles: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },
                uploadUrls: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                }
            };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getemployeeDashboardDetailsThunk.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: undefined, }))
            .addCase(thunk.getemployeeDashboardDetailsThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, response: undefined, }))
            .addCase(thunk.getemployeeDashboardDetailsThunk.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: undefined, response: action.payload }))

          
            .addCase(thunk.updateEmployeeDashboardDetailsThunk.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateEmployeeDashboardDetailsThunk.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateEmployeeDashboardDetailsThunk.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getEmployeeFileUploadUrlsThunk.pending, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getEmployeeFileUploadUrlsThunk.rejected, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.getEmployeeFileUploadUrlsThunk.fulfilled, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.uploadEmployeeFilesThunk.pending, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadEmployeeFilesThunk.rejected, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.uploadEmployeeFilesThunk.fulfilled, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.succeeded, error: null, response: action.payload } }))


            .addDefaultCase(state => ({ ...state }));
    }
});

// export const { clearEmployeeDashboardState, clearEmployeeDashboardStateState } = employeeDashboardSlice.actions;
export const manageEmployeeActions = employeeDashboardSlice.actions;
export const selectState = (state: EmployeeDashboardState) => state;
export const selectCreateState = (state: EmployeeDashboardState) => state.create;
export const selectUpdateState = (state: EmployeeDashboardState) => state.update;
export const selectLoading = (state: EmployeeDashboardState) => state.loading;
export const selectError = (state: EmployeeDashboardState) => state.error;

export const selectUploadURLState = (state: EmployeeDashboardState) => state.uploadUrls;
export const selectUploadFileState = (state: EmployeeDashboardState) => state.uploadFiles;