import { useState } from "react";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import { CustomButton, getPlusBtnIcon } from "../../../../utils";
import { useTranslation } from "react-i18next";
import { THEME } from "../../../../enums";

import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import EditIcon from "../../../../../assets/icons/edit_button.svg";
import "../direct_deposit.scss";
import { ITalentDirectDeposit } from "../../../../interfaces";
const tableHeader = [
  { title: "financial_institution", code: "name_of_financial_institution" },
  { title: "type_of_account", code: "type_of_account" },
  { title: "bank_routing_number", code: "bank_routing_number" },
  { title: "bank_account_number", code: "bank_account_number" },
  {
    title: "amount_of_paycheck_to_deposit",
    code: "amount_of_paycheck_to_deposit_to_this_account",
  },
  { title: "amount_type", code: "amount_type" },
  { title: "actions", code: "actions" },
];
interface stepTwoProps {
  talent_direct_deposit_data: ITalentDirectDeposit | undefined;
  addAccount: () => void;
  token: string | undefined;
  handleEditAccount: (index: number | undefined) => void;
}
// interface Props extends RouteComponentProps<any> {}
const DirectDepositStepTwoForm: React.FunctionComponent<stepTwoProps> = (
  props
) => {
  const { talent_direct_deposit_data, addAccount, token, handleEditAccount } =
    props;

  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const { t } = useTranslation();

  // dispatch(onboardingVerifyTokenThunk(token));

  function handleSortFieldChange(value: string) {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 2;
    if (value === 1) return 2;
    if (value === 2) return 2;
    if (value === 3) return 2;
    if (value === 4) return 3;
    if (value === 5) return 2;
    return 1;
  }
  const getAmountType = (type) => {
    if (type === "FIXED$AMOUNT") {
      return "Fixed";
    } else if (type === "PERCENTAGE") {
      return "Percentage";
    } else if (type === "REMAININGAMOUNT") {
      return "Remaining";
    } else {
      return type;
    }
  };
  const getAmountPrefix = (type, val) => {
    if (type === "FIXED$AMOUNT") {
      return "$ " + val.toFixed(2);
    } else if (type === "PERCENTAGE") {
      return val.toFixed(2) + " %";
    } else {
      return val;
    }
  };
  return (
    <>
      <div className="title-txt">
        <span>{t("financial_accounts")}</span>
      </div>
      <div className="direct-deposit-sortable-table ">
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {talent_direct_deposit_data?.bank_accounts?.map((doc, index) => {
            return (
              <tr key={"branch_list" + index}>
                {/* <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleUserSelect(doc))}</TableData> */}
                <TableData
                  customStyle={{
                    flex: getFlexNumber(0),
                    wordWrap: "break-word",
                  }}
                >
                  <span>{doc.bank_name}</span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(1),
                    wordWrap: "break-word",
                  }}
                >
                  <span>{doc.account_type}</span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(2),
                    wordWrap: "break-word",
                  }}
                >
                  <span>{doc.routing_number}</span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(3),
                    wordWrap: "break-word",
                  }}
                >
                  <span>{doc.account_number}</span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(4),
                    wordWrap: "break-word",
                  }}
                >
                  <span>
                    {getAmountPrefix(doc.amount_type, doc.amount_per_check)}
                  </span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(5),
                    wordWrap: "break-word",
                  }}
                >
                  <span>{getAmountType(doc.amount_type)}</span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(6),
                    wordWrap: "break-word",
                  }}
                >
                  <ToggleSwitch
                    label={""}
                    labelPosition={"top"}
                    checked={doc.is_active ? true : false}
                    onChange={() => {}}
                  />
                  <div>
                    <img
                      src={EditIcon}
                      alt="edit_button"
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={(value) => handleEditAccount(index)}
                    />
                  </div>
                </TableData>
              </tr>
            );
          })}
        </SortableTable>
      </div>

      <div style={{ marginTop: "1rem" }}>
        <CustomButton
          leftIcon={getPlusBtnIcon()}
          loading={false}
          textStyle={{ textTransform: "capitalize" }}
          name={t("add_account")}
          enable={true}
          backgroundColor={THEME.defaultHighLightColor}
          onClick={addAccount}
        />
      </div>
    </>
  );
};

export default DirectDepositStepTwoForm;
