import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { AppError, IWeeklyTransactionAssignment, IWeeklyTransactions } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";


export const getWeeklyTransactionsThunk = createAsyncThunk<
    IWeeklyTransactions[],
    string  | undefined,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_dashboard/weekly_transactions/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getWeeklyTransactions(payload);
            return res.data as IWeeklyTransactions[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const createWeeklyTransactionThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_dashboard/weekly-transactions/post",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.createWeeklyTransaction(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });
