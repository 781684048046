import { createSlice } from "@reduxjs/toolkit";
import * as thunk from './import_data_file_thunk';
import { LoadingType } from "../../enums";
import { AppError } from "../../interfaces";
import { IIUploadFileUrlResponse } from "../../interfaces/upload_file";


export interface ImportUrlState {
    importRecord: {
        error?: AppError | null,
        loading: LoadingType,
        response: { id: string, url: string } | null,
    }
    updateImportRecord: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null,
    }
    downloadSample: {
        error?: AppError | null,
        loading: LoadingType,
        response: IIUploadFileUrlResponse | null,
    }
}

const initialState: ImportUrlState = {
    importRecord: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    updateImportRecord: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    downloadSample: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}


export const ImportUrlSlice = createSlice({
    name: 'importUrl',
    initialState: initialState,
    reducers: {
        clearImportUrlStateError(state) {
            return {...state, importRecord: { ...state.importRecord, error: null }};
        },
        clearUpdateImportUrlStateError(state) {
            return {...state, updateImportRecord: { ...state.updateImportRecord, error: null }};
        },
        clearDownloadUrlState(state) {
            return { ...state, downloadSample : { ...initialState.downloadSample }};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getImpDataFileUploadUrl.pending, (state, action) => ({ ...state, importRecord: { ...state.importRecord, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getImpDataFileUploadUrl.rejected, (state, action) => ({ ...state, importRecord: { ...state.importRecord, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.getImpDataFileUploadUrl.fulfilled, (state, action) => ({ ...state, importRecord: { ...state.importRecord, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateImpDataFileUploadUrl.pending, (state, action) => ({ ...state, updateImportRecord: { ...state.updateImportRecord, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateImpDataFileUploadUrl.rejected, (state, action) => ({ ...state, updateImportRecord: { ...state.updateImportRecord, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateImpDataFileUploadUrl.fulfilled, (state, action) => ({ ...state, updateImportRecord: { ...state.updateImportRecord, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.downloadSampleFileUploadUrl.pending, (state, action) => ({ ...state, downloadSample: { ...state.downloadSample, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.downloadSampleFileUploadUrl.rejected, (state, action) => ({ ...state, downloadSample: { ...state.downloadSample, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.downloadSampleFileUploadUrl.fulfilled, (state, action) => ({ ...state, downloadSample: { ...state.downloadSample, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});
export const importUrlActions = ImportUrlSlice.actions;
export const selectImpDataFileUrlState = (state: ImportUrlState) => state.importRecord;
export const selectUpdateImpDataFileUrlState = (state: ImportUrlState) => state.updateImportRecord;
export const selectDownloadSampleFileUrlState = (state: ImportUrlState) => state.downloadSample;