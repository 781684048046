import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IFileUplodState, IURLState, Profile } from "../../../interfaces";
import * as thunk from "./employee_handbook_thunk";

export interface EmployeeHandbookState {
   uploadURL: IURLState,
   downloadURL: IURLState,
   uploadFile: IFileUplodState,

}

const initialState: EmployeeHandbookState = {
    uploadURL: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    downloadURL: {
        error: null,
        loading: LoadingType.idle,
        response: null, 
    },
    uploadFile: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    }
}


export const employeeHandbookSlice = createSlice({
    name: 'employeeHandbook',
    initialState: initialState,
    reducers: {
        clearState(state){
            return {...state, ...initialState};
        },
        clearUploadUrlError(state){
            return {...state, uploadURL:{ ...state.uploadURL, error: null }};
        },
        clearDownloadUrlError(state){
            return {...state, downloadURL:{ ...state.downloadURL, error: null }};
        },
        clearUploadFileError(state){
            return {...state, uploadFile:{ ...state.uploadFile, error: null }};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getUploadEmployeeHandbookUrlThunk.pending, (state, action) => ({...state, uploadURL:{ ...state.uploadURL, loading: LoadingType.pending, error: null, }}))
            .addCase(thunk.getUploadEmployeeHandbookUrlThunk.rejected, (state, action) => ({...state, uploadURL:{ ...state.uploadURL, loading: LoadingType.failed, error: action.payload, response: null, }}))
            .addCase(thunk.getUploadEmployeeHandbookUrlThunk.fulfilled, (state, action) => ({...state, uploadURL:{ ...state.uploadURL, loading: LoadingType.succeeded, error: null, response: action.payload }}))

            .addCase(thunk.getDownloadEmployeeHandbookUrlThunk.pending, (state, action) => ({...state, downloadURL:{ ...state.downloadURL, loading: LoadingType.pending, error: null, }}))
            .addCase(thunk.getDownloadEmployeeHandbookUrlThunk.rejected, (state, action) => ({...state, downloadURL:{ ...state.downloadURL, loading: LoadingType.failed, error: action.payload, response: null, }}))
            .addCase(thunk.getDownloadEmployeeHandbookUrlThunk.fulfilled, (state, action) => ({...state, downloadURL:{ ...state.downloadURL, loading: LoadingType.succeeded, error: null, response: action.payload }}))

            .addCase(thunk.uploadEmployeeHandbookThunk.pending, (state, action) => ({...state, uploadFile:{ ...state.uploadFile, loading: LoadingType.pending, error: null, }}))
            .addCase(thunk.uploadEmployeeHandbookThunk.rejected, (state, action) => ({...state, uploadFile:{ ...state.uploadFile, loading: LoadingType.failed, error: action.payload, response: null, }}))
            .addCase(thunk.uploadEmployeeHandbookThunk.fulfilled, (state, action) => ({...state, uploadFile:{ ...state.uploadFile, loading: LoadingType.succeeded, error: null, response: action.payload }}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const employeeHandbookSliceActions = employeeHandbookSlice.actions;
export const selectState = (state: EmployeeHandbookState) => state;
export const selectUploadURLState = (state: EmployeeHandbookState) => state.uploadURL;
export const selectDownloadURLState = (state: EmployeeHandbookState) => state.downloadURL;
export const selectUploadFileState = (state: EmployeeHandbookState) => state.downloadURL;


