import { createAsyncThunk } from "@reduxjs/toolkit";
import { JobOrderAPI } from "../../../../../apis";
import { IJobServiceProfile, AppError, IJobOrderSuccessProfile } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";


export const getJobOrderSuccessProfile = createAsyncThunk<
    IJobOrderSuccessProfile,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/success-profile/job_order_success_profile/get',
    async (id, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobOrderSuccessProfile(id);
            if (typeof res.data === 'string') return {};
            return res.data as IJobOrderSuccessProfile;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateJobOrderSuccessProfile = createAsyncThunk<
    string,
    IJobOrderSuccessProfile,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@jobs/details/success-profile/job_order_success_profile/update',
    async (data, thunkAPI) => {
        try {
            const res = await JobOrderAPI.saveJobOrderSuccessProfile(data);
           return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
