
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './e_docs_onboarding_reducer';
import { IRootState } from "../../store";

const select = (state: IRootState) => state.employeeDashboard.eDocsOnboardingDocs.empOnboardingReviewStatus;
export const selectEmpODRSState = createSelector(select, reducer.selectEmpODRSState);
export const selectSingleEmpODRSStateById = (id: string | undefined | null) => createSelector(
    selectEmpODRSState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleEmpODRSState(state, id);
        return reducer.selectInitialSingleEmpODRSState();
    }
);