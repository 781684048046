import { combineReducers } from "@reduxjs/toolkit";
import { jobSuccessProfileSlice } from "./success_profile_info/success_profile_info_reducer";
import { jobPostQuestionSlice } from "./job_post/job_post_reducer";


export const jobSuccessProfileReducer = combineReducers({
    [jobSuccessProfileSlice.name]: jobSuccessProfileSlice.reducer,
    [jobPostQuestionSlice.name]: jobPostQuestionSlice.reducer,

})

export * from "./success_profile_info/success_profile_info_thunk";
export * from "./success_profile_info/success_profile_info_selector";

export * from "./job_post/job_post_thunk";
export * from "./job_post/job_post_selector";