import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../interfaces";
import { S3API } from "../../apis/s3_api";
import { catchRequestError } from "../../utils";
import { ImportDataFileAPI } from "../../apis/import_data_file_api";
import { IIUploadFileUrlResponse } from "../../interfaces/upload_file";

export const getImpDataFileUploadUrl = createAsyncThunk<
    { id: string, url: string } | null,
    { baseUrl: string, file: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    "@universal_directories/import_record/get",
    async (doc, thunkAPI) => {
        try {
            const file_name = doc.file && doc.file.name ? doc.file.name : '';
            const res = await ImportDataFileAPI.getImportDataFileUploadUrl(doc.baseUrl, file_name );
            if (res.data && doc.file) {
                S3API.uploadWithPreSignedURL(res.data.url, doc.file);
            }
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateImpDataFileUploadUrl = createAsyncThunk<
    string,
    { baseUrl: string, import_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    "@universal_directories/import_record/patch",
    async (doc, thunkAPI) => {
        try {
            const res = await ImportDataFileAPI.updateImportDataFileUploadUrl(doc.baseUrl, doc.import_id);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const downloadSampleFileUploadUrl = createAsyncThunk<
    IIUploadFileUrlResponse,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    "@universal_directories/download_sample/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ImportDataFileAPI.getSampleFileDownloadURL(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);