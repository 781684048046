import { useTranslation } from "react-i18next";
import RenderInput from "../../../../../components/render_input/render_input";
import { FormInputOption } from "../../../../../interfaces";
import { ICreateAgency } from "../../../../../interfaces/agency_management";
import { RegxPattern, enforceFormat, formatToEIN, formatToPhone } from "../../../../../utils";
import { US_states } from "../../../../../utils/States_US";
import { Dispatch, SetStateAction } from "react";

interface Props {
    formState: ICreateAgency;
    selectedPhoto:File | null;
    onAgencyLogoChange:(value: File)=>void;
    onRemoveAgencyLogo:()=> void;
    handleFieldChange:(fieldId: string, value: any, parent?: string)=>void;
    setFormState: Dispatch<SetStateAction<ICreateAgency>>;
}

const StepOneAgencyForm: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const {formState, handleFieldChange} = props

    const formAgencyInformation: (FormInputOption | FormInputOption[])[] = [
        /// 1st row
        [
            {
                field: "name",
                type: "text",
                label: 'name',
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "phone",
                type: "tel",
                label: 'mobile',
                secure: false,
                required: false,
                placeholder: "(555) 555 - 5555",
                keyboardtype: '',
                maxLength: 16,
                onKeyUp: formatToPhone,
                onKeyDown: enforceFormat,
            },
            {
                field: "domain",
                type: "text",
                label: 'domain',
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: '',
            },
        ],
        /// 2nd row
        [
            {
                field: "street_address",
                type: "text",
                label: 'street_address',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "city",
                type: "text",
                label: 'city',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "state",
                type: "selector",
                label: 'state',
                secure: false,
                required: false,
                placeholder: "select",
                keyboardtype: '',
                selectorList: US_states.map((doc) => ({
                    label: doc.name,
                    value: doc.code,
                })),
                multi: false,
            },
            {
                field: "zip_code",
                type: "text",
                label: 'zip_code',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
        ],

        /// 3rd row
        [
            {
                field: "web_url",
                type: "text",
                label: 'web_url',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "sic_code",
                type: "text",
                label: 'sic_code',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "fax",
                type: "text",
                label: 'fax',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "fein",
                type: "text",
                label: 'fein',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: '',
                onKeyUp: formatToEIN,
                onKeyDown: enforceFormat,
            },
        ],
    ];
    return (
        <div className="uf-section-divider">
            <div className="uf-row flx-start">
                <div className="section-title-txt">
                    <span>{t("agency_basic_information").toLocaleUpperCase()}</span>
                </div>
            </div>
            {formAgencyInformation.map((doc, index) => {
                if (Array.isArray(doc)) {
                    return (
                        <div key={index + "row"} className="uf-row ">
                            {doc.map((subDoc, subIndex) => {
                                return (
                                    <RenderInput
                                        key={index + subIndex}
                                        doc={subDoc}
                                        index={subIndex}
                                        formState={formState}
                                        handleFieldChange={handleFieldChange}
                                        formValidators={agencyFormValidators}
                                        className={"uf-input-holder"}
                                    />
                                );
                            })}
                        </div>
                    );
                }

                return (
                    <RenderInput
                        key={index}
                        doc={doc}
                        index={index}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        formValidators={agencyFormValidators}
                        className={"uf-input-holder"}
                    />
                );
            })}
            <div className="uf-row">
                {/* <div className="uf-input-holder">
                    <DragAndDrop
                        type={"small"}
                        label={t("agency_logo")}
                        placeholder={t("upload_or_drag_a_photo")}
                        selectedFile={selectedPhoto}
                        accept={"image/*"}
                        onChange={onAgencyLogoChange}
                        onRemove={onRemoveAgencyLogo}
                    />
                </div> */}
                <div>
                    <RenderInput
                        doc={{
                            field: "search_and_import_data",
                            type: "switch",
                            label: "search_and_import_data",
                            secure: false,
                            required: false,
                            placeholder: "",
                            keyboardtype: "",
                            labelPosition: "top",
                        }}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        className={"tlnt-f-input-holder"}
                        customStyle={{ flex: 1 }}
                    />
                </div>
            </div>
        </div>


    );

    function agencyFormValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "name": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.username.test(value.text) === false)
                    return t("validators.enterValidName");
                return null;
            }
            case "phone": {
                if (value.text === "") return null;
                if (RegxPattern.usPhone.test(value.text) === false)
                    return t("validators.enterValidPhoneNumber");
                return null;
            }
            case "zip_code": {
                if (value.text === "") return null;
                if (RegxPattern.zipCode.test(value.text) === false)
                    return t("validators.enterValidZipCode");
                return null;
            }
            case "fein": {
                if (value.text === "") return null;
                if (RegxPattern.ein.test(value.text) === false)
                    return t("validators.enterValidEIN");
                return null;
            }
            case "web_url": {
                if (value.text === "") return null;
                if (RegxPattern.url.test(value.text) === false)
                    return t("validators.enterValidUrl");
                return null;
            }
            case "domain": {
                if (value.text === "") return null;
                if (RegxPattern.url.test(value.text) === false)
                    return t("validators.enterValidUrl");
                return null;
            }
            default: {
                return null;
            }
        }
    }
}

export default StepOneAgencyForm