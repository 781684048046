import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ICreateResponse, IProfilePictureUploadUrlResponse, IUploadUrlsResponse } from "../../../../interfaces";
import * as thunk from "./manage_talent_thunk";



export interface ManageTalentState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
    uploadUrls: {
        error?: AppError | null,
        loading: LoadingType,
        response: IUploadUrlsResponse | null,
    },
    uploadFiles: {
        error?: AppError | null,
        loading: LoadingType,
        response: any | null,
    }
}

const initialState: ManageTalentState = {
    create: {
        error: null,
        loading: LoadingType.idle,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
    },
    uploadUrls: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
    uploadFiles: {
        error: null,
        loading: LoadingType.idle,
        response: null,
    },
}


export const manageTalentSlice = createSlice({
    name: 'manageTalent',
    initialState: initialState,
    reducers: {
        initializeManageTalentState(state) {
            return {
                ...state,
                ...initialState,

            }
        },
        clearCreateTalentState(state) {
            return { ...state, create: { ...initialState.create } };
        },
        clearUpdateTalentState(state) {
            return { ...state, update: { ...initialState.update } };
        },
        clearManageTalentStateError(state) {
            return {
                ...state,
                create: {
                    ...state.create,
                    error: null
                },
                update: {
                    ...state.update,
                    error: null
                },
                uploadFiles: {
                    ...state.uploadFiles,
                    error: null,
                },
                uploadUrls: {
                    ...state.uploadUrls,
                    error: null,
                },
            };
        },
        clearManageTalentState(state) {
            return {
                ...state,
                create: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                update: {
                    error: null,
                    loading: LoadingType.idle,
                    response: undefined,
                },
                uploadFiles: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                },
                uploadUrls: {
                    error: null,
                    loading: LoadingType.idle,
                    response: null,
                }
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.createTalent.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createTalent.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createTalent.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateTalent.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateTalent.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateTalent.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getTalentFileUploadUrlsThunk.pending, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.getTalentFileUploadUrlsThunk.rejected, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.getTalentFileUploadUrlsThunk.fulfilled, (state, action) => ({ ...state, uploadUrls: { ...state.uploadUrls, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.uploadTalentFilesThunk.pending, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.uploadTalentFilesThunk.rejected, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.failed, error: action.payload, response: null, } }))
            .addCase(thunk.uploadTalentFilesThunk.fulfilled, (state, action) => ({ ...state, uploadFiles: { ...state.uploadFiles, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageTalentActions = manageTalentSlice.actions;
export const selectState = (state: ManageTalentState) => state;
export const selectCreateState = (state: ManageTalentState) => state.create;
export const selectUpdate = (state: ManageTalentState) => state.update;

export const selectUploadURLState = (state: ManageTalentState) => state.uploadUrls;
export const selectUploadFileState = (state: ManageTalentState) => state.uploadFiles;




