import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../../../../components/search_bar";
import { LoadingType, THEME } from "../../../../../../enums";
import { CustomButton, currencyConversion, getPlusBtnIcon } from "../../../../../../utils";
import './work_comp_codes.scss';
import { selectWorkCompConfigListState, selectWorkCompConfigList, getWorkCompConfigList } from "../../../../../../redux/admin_center";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { AppError, ICompBOInvoiceDocsWCCodes, ICompanyBackOfficeInvoiceDocs } from "../../../../../../interfaces";
import SortableTable, { TableData } from "../../../../../../components/sortable_table/sortable_table";
import { Portal } from "@material-ui/core";
import AddNewWCCodePopup from "../../../../popup/add_new_wc_code/add_new_wc_code_popup";
import { DeleteIcon } from "../../../../../../icons";
import TableEmpty, { TableFilterEmpty } from "../../../../../../components/table_empty/table_empty";
import { DeleteConfirmationDialog } from "../../../../../components/delete_confirmation_dialog";

const WCCodesTableHeader = [
    { title: 'work_comp_code', code: 'wc_code' },
    { title: 'base_rate', code: 'base_rate' },
    { title: 'code_description', code: 'description' },
    { title: '', code: '' },

];
interface Props {
    formState: ICompanyBackOfficeInvoiceDocs;
    currentLoginUserName: string;
    handleAddWCCode: (doc?: { wc_code_id: string; }[], removeId?: string, removeItem?: boolean) => void;
    wcCodeUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleClearError: () => void;
    companyId: string;
    handleUpdateClearState: () => void;
    getInvoiceDocsData: () => void;
}
const CompanyBOWorkCompCodes: React.FC<Props> = (props) => {
    const { formState, currentLoginUserName, handleAddWCCode, handleClearError, wcCodeUpdateState, handleUpdateClearState, getInvoiceDocsData } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [addNewWCCode, setAddNewWCCode] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [wcCodeSorted, setWcCodeSorted] = useState<string | null>(null);
    const [editIndex, setEditIndex] = useState<number | undefined>();
    const [delRecord, setDelRecord] = useState<boolean>(false);

    const initialForm = {
        wc_code: "",
        base_rate: 0,
        description: "",
        wc_code_id: ""
    }
    const [workCompFormState, setWorkCompFormState] = useState<ICompBOInvoiceDocsWCCodes>(initialForm);

    function handleWCCodeSortFieldChange(value: string) {
        if (wcCodeSorted && wcCodeSorted === value) {
            setWcCodeSorted(null);
        } else {
            setWcCodeSorted(value);
        }
    }
    function getWCCodeFilteredList(): ICompBOInvoiceDocsWCCodes[] {
        let sortedList: ICompBOInvoiceDocsWCCodes[] | undefined;
        if (wcCodeSorted != null) {
            sortedList = [...(formState.work_comp_codes ?? [])].sort((a, b) => {
                if (a[wcCodeSorted] < b[wcCodeSorted]) {
                    return -1;
                }
                if (a[wcCodeSorted] > b[wcCodeSorted]) {
                    return 1;
                }
                return 0;
            });
        }
        return (sortedList ?? (formState.work_comp_codes ?? [])).filter(doc => {
            const str = search.trim().toLowerCase();
            const work_comp_code = doc.wc_code ? doc.wc_code.trim().toLowerCase().includes(str) : false;
            const code_description = doc.description ? doc.description.trim().toLowerCase().includes(str) : false;
            const base_rate = doc.base_rate ? doc.base_rate.toString().trim().toLowerCase().includes(str) : false;

            return work_comp_code || code_description || base_rate;
        });
    }
    const handleClose = () => {
        setAddNewWCCode(false);
    }

    const handleSave = (doc: { wc_code_id: string; }[]) => {
        handleAddWCCode(doc);
        handleClose();
    }
    const handleDelete = (doc: ICompBOInvoiceDocsWCCodes) => {
        setDelRecord(true);
        setWorkCompFormState(doc);
    };

    const handleOnSuccessRemove = () => {
        if (workCompFormState && workCompFormState.wc_code_id) {
            handleAddWCCode(undefined, workCompFormState.wc_code_id, true);
            handleClose();
            setDelRecord(false);
        }

    }

    return (
        <div className="wc-code-container">
            <div className="code-ro-header">
                <div className="section-header">{t("work_comp_codes")}</div>
                <div className="code-ro-header-actions">
                    <div className="">
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                            placeholder={t('Search')}
                        />
                    </div>
                    <CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("add_new_work_comp_code")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => setAddNewWCCode(true)}
                    />
                </div>
            </div>
            <div className="code-ro-content">
                <div className="code-ro-content-table">
                    <SortableTable
                        headerList={WCCodesTableHeader}
                        sortedField={wcCodeSorted}
                        onSortChange={handleWCCodeSortFieldChange}
                        flexNumber={getFlexNumber}
                    >
                        {getWCCodeFilteredList().map((doc, index) => {
                            return (
                                <tr key={index} onClick={() => { }}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}>{doc.wc_code}</TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.base_rate
                                        ? currencyConversion(doc.base_rate)
                                        : "$0.00"}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.description !== undefined && doc.description.length > 50 ? doc?.description?.substring(0, 50) + "..." : doc?.description}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        <button className="delete-icon" >
                                            <DeleteIcon width={"100%"} height={"100%"} onClick={() => handleDelete(doc)} />
                                        </button>
                                    </TableData>
                                </tr>
                            );
                        })}
                    </SortableTable>
                </div>
            </div>
            {addNewWCCode &&
                <Portal>
                    <AddNewWCCodePopup
                        visible={addNewWCCode}
                        onClose={handleClose}
                        onSuccessClose={handleClose}
                        handleSave={handleSave}
                    />
                </Portal>
            }
            {delRecord && (
                <DeleteConfirmationDialog
                    message={t("delete_bo_record_message", {
                        name: workCompFormState.wc_code,
                    })}
                    onCancel={() => setDelRecord(false)}
                    onConfirm={handleOnSuccessRemove}
                    deleting={wcCodeUpdateState.loading === LoadingType.pending}
                    deleteType={t("work_comp_code")}
                    state={wcCodeUpdateState}
                    onClearError={handleClearError}
                />
            )}

        </div>

    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 0.2;
        if (value === 4) return 1;
        if (value === 5) return 0.5;

        return 1;
    }
}

export default CompanyBOWorkCompCodes;