import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ContactsAPI } from "../../../../apis";
import ApiError from "../../../../components/api_error";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import DragAndDrop from "../../../../components/drag_and_drop/drag_and_drop";
import RenderInput from "../../../../components/render_input/render_input";

import {
  COMPANY_STATUS,
  CONTACT_METHODS,
  LoadingType,
  THEME,
} from "../../../../enums";
import { ContactsIcon, LinkIcon } from "../../../../icons";
import {
  AppError,
  ContactStatus,
  FormInputOption,
  IBusinessSector,
  ICompanyCertification,
  ICompanyCredential,
  ICompanyEducationRequirement,
  ICompanyRequiredEquipment,
  ICompanySkillSet,
  ICompetitor,
  IContact,
  ICreateContact,
  Profile,
} from "../../../../interfaces";
import { departmentsDropdownListActions } from "../../../../redux/selectors/departments_list/departments_list_reducer";
import {
  getCompaniesDropdown,
  getDepartmentsDropdown,
  postSnackbarMessage,
  selectCompaniesDropdownList,
  selectDepartmentsDropdownList,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  enforceFormat,
  formatToPhone,
  RegxPattern,
} from "../../../../utils";
import { US_states } from "../../../../utils/States_US";
import {
  // companyStatusOptions,
  contactMethodOptions,
  lowBtnStyle,
  outlineBtnStyle,
} from "../../../../variables";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";
import "./contact_form.scss";

interface Props {
  title: string;
  successTitle: string;
  currentLoginUserName?: string;
  loading: LoadingType;
  error: AppError | null | undefined;
  contact?: IContact;
  data?: { company_id: string; department_ids?: string[] };
  accountManagers: Array<Profile>;
  recruiters: Array<Profile>;
  businessSectors: Array<IBusinessSector>;
  competitors: Array<ICompetitor>;

  skillSetList: Array<ICompanySkillSet>;
  credentialsList: Array<ICompanyCredential>;
  certificationsList: Array<ICompanyCertification>;
  educationList: Array<ICompanyEducationRequirement>;
  requiredEquipmentList: Array<ICompanyRequiredEquipment>;
  onClose: () => void;
  onSubmit: (doc: { forceAdd: boolean; value: ICreateContact }) => void;
  onClearError: () => void;
  /// photo
  selectedPhoto: File | null;
  onPhotoChange: (value: File) => void;
  onRemovePhoto: () => void;
  /// resume
  selectedResume: File | null;
  onResumeChange: (value: File) => void;
  onRemoveResume: () => void;

  onGotoAgencyDashboard: () => void;
  onCreateNew: () => void;
  onGotoCreated: (name: string) => void;
  ContactStatus: Array<ContactStatus>;
}

const getContactFormData = (value: IContact): ICreateContact => {
  return {
    first_name: value.first_name ?? "",
    last_name: value.last_name ?? "",
    company_id: value.company_id ?? "",
    department_ids: value.department_ids ?? [],
    status: "",
    status_id: value.status_id ?? "",
    street_address: value.street_address ?? "",
    city: value.city ?? "",
    state: value.state ?? "",
    zip_code: value.zip_code ?? "",
    mobile_no: value.mobile_no ?? "",
    office_no: value.office_no ?? "",
    job_title: value.job_title ?? "",
    summary: value.summary ?? "",
    social_media_links: value.social_media_links ?? {
      linkedin_url: null,
      twitter_url: null,
      facebook_url: null,
      pinterest_url: null,
    },
    staffing_agency_competitors: value.staffing_agency_competitors ?? [],
    professional_skills: value.professional_skills ?? [],
    certifications: value.certifications ?? [],
    education: value.education ?? [],
    credentials: value.credentials ?? [],
    safety_equipment: value.safety_equipment ?? [],
    company_name: value.company_name ?? "",
    email: value.email ?? "",
    email_2: value.email_2 ?? "",
    preferred_contact_method:
      value.preferred_contact_method ?? CONTACT_METHODS.email,
    uses_other_staffing_agencies: value.uses_other_staffing_agencies ?? false,
  };
};
function getInitialForm(data?: {
  company_id: string;
  department_ids?: string[];
}): ICreateContact {
  return {
    first_name: "",
    last_name: "",
    company_id: data && data.company_id ? data.company_id : "",
    department_ids:
      data && data.company_id && data.department_ids ? data.department_ids : [],
    status:"",
    status_id: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    mobile_no: "",
    office_no: "",
    job_title: "",
    summary: "",
    social_media_links: {
      linkedin_url: null,
      twitter_url: null,
      facebook_url: null,
      pinterest_url: null,
    },
    staffing_agency_competitors: [],
    professional_skills: [],
    certifications: [],
    education: [],
    credentials: [],
    safety_equipment: [],
    company_name: "",
    email: "",
    email_2: "",
    preferred_contact_method: CONTACT_METHODS.email,
    uses_other_staffing_agencies: false,
  };
}

const ContactForm: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const companiesList = useAppSelector((state) =>
    selectCompaniesDropdownList(state)
  );
  const departmentsList = useAppSelector((state) =>
    selectDepartmentsDropdownList(state)
  );
  const [formState, setFormState] = useState<ICreateContact>(
    props.contact
      ? getContactFormData(props.contact)
      : getInitialForm(props.data)
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const history = useHistory();
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] =
    useState<boolean>(false);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await ContactsAPI.deleteContact(props.contact?.id ?? "");
      if (Array.isArray(res.data)) {
        const message = res.data.join(", ");
        setSnackBarMessage(message);
      } else if (res.data) {
        setShowDeleteDialog(false);
        dispatch(postSnackbarMessage(res.data));
        history.push(AppRoutes.contactsPage);
      }
    } catch (e: any) {
      const message = e.response?.data?.detail?.error?.join("\n");
      setSnackBarMessage(message);
    } finally {
      setDeleting(false);
      setShowDeleteDialog(false);
    }
  };
  
  useEffect(() => {
    dispatch(getCompaniesDropdown());
    if (props.data) {
      setFormState({
        ...formState,
        company_id: props.data.company_id,
        department_ids: props.data.department_ids,
      });
    }
  }, []);

  useEffect(() => {
    if (formState.company_id) {
      dispatch(getDepartmentsDropdown(formState.company_id));
    } else {
      dispatch(
        departmentsDropdownListActions.clearDepartmentsDropdownListState()
      );
      handleFieldChange("department_ids", []);
    }
  }, [formState.company_id]);

  function handleFieldChange(fieldId: string, value: any, parent?: string) {
    if (parent) {
      setFormState({
        ...formState,
        [parent]: { ...formState[parent], [fieldId]: value },
      });
    } else {
      setFormState({ ...formState, [fieldId]: value });
    }
  }

  const formBasicInformation:
    | Array<FormInputOption>
    | Array<Array<FormInputOption>> = [
    /// 1st row
    [
      {
        field: "first_name",
        type: "text",
        label: "first_name",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "last_name",
        type: "text",
        label: "last_name",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "company_id",
        type: "selector",
        label: "company",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: companiesList.map((doc) => ({
          label: doc.name,
          value: doc.id,
        })),
        multi: false,
        disabled: props.data && props.data.company_id ? true : false,
      },
      {
        field: "department_ids",
        type: "selector",
        label: "department",
        secure: false,
        required: false,
        placeholder: "Corporate",
        keyboardtype: "",
        selectorList: departmentsList.map((doc) => ({
          label: doc.name,
          value: doc.id,
        })),
        multi: true,
        disabled:
          props.data &&
          props.data.company_id &&
          props.data.department_ids &&
          props.data.department_ids.length != 0
            ? true
            : false,
      },
      {
        field: "job_title",
        type: "text",
        label: "job_title",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
    ],

    /// 2nd row
    [
      {
        field: "street_address",
        type: "text",
        label: "street_address",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "city",
        type: "text",
        label: "city",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "state",
        type: "selector",
        label: "state",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: US_states.map((doc) => ({
          label: doc.name,
          value: doc.code,
        })),
        multi: false,
      },
      {
        field: "zip_code",
        type: "text",
        label: "zip_code",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
        maxLength: 5

      },
      {
        field: "status_id",
        type: "selector",
        label: "status",
        secure: false,
        required: true,
        placeholder: "select",
        keyboardtype: "",
        selectorList:props.ContactStatus.map((doc) => ({
          label: doc.contact_status,
          value: doc.id,
        })),
        multi: false,
      },
    ],

    /// 3rd row
    [
      {
        field: "mobile_no",
        type: "tel",
        label: "mobile",
        secure: false,
        required: false,
        placeholder: "(555) 555 - 5555",
        keyboardtype: "",
        maxLength: 16,
        onKeyUp: formatToPhone,
        onKeyDown: enforceFormat,
      },
      {
        field: "office_no",
        type: "tel",
        label: "office",
        secure: false,
        required: false,
        placeholder: "(555) 555 - 5555",
        keyboardtype: "",
        maxLength: 16,
        onKeyUp: formatToPhone,
        onKeyDown: enforceFormat,
      },
      {
        field: "email",
        type: "text",
        label: "personal_email",
        pattern: RegxPattern.email,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "email_2",
        type: "text",
        label: "work_email",
        pattern: RegxPattern.email,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "preferred_contact_method",
        type: "selector",
        label: "preferred_contact",
        secure: false,
        required: true,
        placeholder: "select",
        keyboardtype: "",
        selectorList: contactMethodOptions,
        multi: false,
        isClearable: false,
      },
    ],
  ];

  const formProfessionalInformation : (FormInputOption | FormInputOption[])[]= [
    [
      {
        field: "professional_skills",
        type: "selector",
        label: "professional_skills",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.skillSetList.map((doc) => ({
          label: doc.skill_set,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "certifications",
        type: "selector",
        label: "certifications",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.certificationsList.map((doc) => ({
          label: doc.certification,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "education",
        type: "selector",
        label: "education",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.educationList.map((doc) => ({
          label: doc.education_requirement,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "credentials",
        type: "selector",
        label: "credentials",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.credentialsList.map((doc) => ({
          label: doc.credential,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "safety_equipment",
        type: "selector",
        label: "safety_equipment",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.requiredEquipmentList.map((doc) => ({
          label: doc.required_equipment,
          value: doc.id,
        })),
        multi: true,
      },
    ],
    [
      {
        field: "linkedin_url",
        parentField: "social_media_links",
        type: "text",
        label: "linked_in",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "facebook_url",
        parentField: "social_media_links",
        type: "text",
        label: "facebook",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "twitter_url",
        parentField: "social_media_links",
        type: "text",
        label: "X",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "pinterest_url",
        parentField: "social_media_links",
        type: "text",
        label: "pinterest",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
    ],
  ];
   

  if (props.loading === LoadingType.succeeded) {
    return (
      <div className="cnt-f-container">
        <div className="cnt-f-header">
          <FormDialogTilteHeader
            title={props.successTitle}
            titleIcon={<ContactsIcon width={"100%"} height={"100%"} />}
          />
        </div>
        <div className="cnt-f-content">
          <div className="saved-doc-holder">
            <DocSavedText>
              <span className="aknw-name">
                {t("well_done_on_creating_new", {
                  field: t("contact"),
                  name: props.currentLoginUserName,
                })}
              </span>
              <span className="info-txt">{`${t("a_new_created", {
                name: t("contact"),
              })}:`}</span>
              <span className="name">{`${formState.first_name ?? ""} ${
                formState.last_name ?? ""
              }`}</span>
            </DocSavedText>
          </div>
        </div>
        <div className="cnt-f-actions">
          <div className="btn-cancel">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize", ...lowBtnStyle.text }}
              name={t("go_to_my_agency_dashboard")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={props.onGotoAgencyDashboard}
              customStyle={lowBtnStyle.btn}
            />
          </div>
          <div className="btn-new">
            <CustomButton
              loading={false}
              textStyle={{
                textTransform: "capitalize",
                ...outlineBtnStyle.text,
              }}
              name={t("create_new", { name: t("contact") })}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => {
                setFormState(getInitialForm(props.data));
                props.onCreateNew();
              }}
              customStyle={outlineBtnStyle.btn}
            />
          </div>

          <div className="btn-save">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("go_to_this_newly_created", { name: t("contact") })}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() =>
                props.onGotoCreated(
                  `${formState.first_name ?? ""} ${formState.last_name ?? ""}`
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }

  const snackbarAction = () => {
    return (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setSnackBarMessage(null)}
        >
          <Close fontSize="small" />
        </IconButton>
      </>
    );
  };
     const handleSubmit = () => { 
       setCheckValidationOnSubmit(true);
       const requiredFieldArray = ['first_name','status_id']
       requiredFieldArray.map((field) => {
        handleFieldChange(field, formState[field]);
       })
     if (_isFormValid()) {
          props.onSubmit({
                forceAdd:
                  props.error != null && props.error?.code === 409
                    ? true
                    : false,
                value: {
                  ...formState,
                  first_name: formState?.first_name.trim(),
                  last_name: formState?.last_name.trim(),
                },
              });
       setCheckValidationOnSubmit(false);

       }
     }

  return (
    <div className="cnt-f-container">
      <div className="cnt-f-header">
        <FormDialogTilteHeader
          title={props.title}
          titleIcon={<ContactsIcon width={"100%"} height={"100%"} />}
        />
      </div>
      <div className="cnt-f-content">
        {getSectionOne()}
        {getSectionTwo()}
        {sectionThree()}
        {props.error && (
          <div className={"error-section"}>
            <ApiError
              message={props.error.message}
              onClose={props.onClearError}
            />
          </div>
        )}
      </div>
      <div className="cnt-f-actions">
        {props.contact && (
          <div style={{ marginRight: "auto" }}>
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("delete_field", { name: t("contact") })}
              enable={true}
              backgroundColor={THEME.statusInActiveColor}
              onClick={() => setShowDeleteDialog(true)}
            />
          </div>
        )}
        <div className="btn-cancel">
          <CustomButton
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("cancel")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={props.onClose}
          />
        </div>

        <div className="btn-save">
          <CustomButton
            loading={props.loading === LoadingType.pending}
            textStyle={{ textTransform: "capitalize" }}
            name={
              props.error != null && props.error?.code === 409
                ? t("force_add")
                : props.contact
                ? t("update_field", { name: t("contact") })
                : t("add_field", { name: t("contact") })
            }
            enable={true}
            backgroundColor={props.error != null && props.error?.code === 409
                ? THEME.secondaryColor6
                : THEME.defaultHighLightColor
            }
            onClick={handleSubmit}
          />
        </div>
      </div>
      {showDeleteDialog && props.contact && (
        <DeleteConfirmationDialog
          message={t("delete_contact_message", {
            contact: props.contact?.first_name + " " + props.contact?.last_name,
          })}
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={handleDelete}
          deleting={deleting}
          deleteType={t("contact")}
          onClearError={()=>{}}
        />
      )}
      <Snackbar
        open={snackBarMessage !== null}
        autoHideDuration={5000}
        onClose={() => setSnackBarMessage(null)}
        message={snackBarMessage}
        action={snackbarAction()}
      />
    </div>
  );

  /// Section one
  function getSectionOne() {
    return (
      <div className="cnt-f-section-divider">
        <div className="cnt-f-row  flx-start">
          <div className="section-title-txt">
            <span>{t("basic_information").toLocaleUpperCase()}</span>
          </div>
        </div>
        {formBasicInformation.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="cnt-f-row ">
                {doc.map((subDoc, subIndex) => {
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={contactFormValidators}
                      className={`cnt-f-input-holder`}
                      checkValidationOnSubmit={checkValidationOnSubmit}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={contactFormValidators}
              className={"cnt-f-input-holder"}
              checkValidationOnSubmit={checkValidationOnSubmit}
            />
          );
        })}
      </div>
    );
  }

  function getSectionTwo() {
    return (
      <div className="cnt-f-section-divider">
        <div className="cnt-f-row  flx-start">
          <div className="section-title-txt">
            <span>{t("professional_information").toLocaleUpperCase()}</span>
          </div>
        </div>
        {formProfessionalInformation.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="cnt-f-row ">
                {doc.map((subDoc, subIndex) => {
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={contactFormValidators}
                      className={"cnt-f-input-holder"}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={contactFormValidators}
              className={"cnt-f-input-holder"}
            />
          );
        })}
        <div className="cnt-f-row ">
          <div className={"cnt-f-input-holder"}>
            <DragAndDrop
              type={"small"}
              label={t("contact_photo")}
              placeholder={t("upload_or_drag_a_photo")}
              selectedFile={props.selectedPhoto}
              accept={"image/*"}
              onChange={props.onPhotoChange}
              onRemove={props.onRemovePhoto}
            />
          </div>
          <div className={"cnt-f-input-holder"}>
            <DragAndDrop
              type={"small"}
              label={t("resume")}
              placeholder={t("upload_or_drag_a_photo")}
              selectedFile={props.selectedResume}
              accept={
                ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              }
              onChange={props.onResumeChange}
              onRemove={props.onRemoveResume}
            />
          </div>
        </div>
      </div>
    );
  }

  function sectionThree() {
    return (
      <div className="cnt-f-section-divider">
        <div className="cnt-f-row ">
          <RenderInput
            doc={{
              field: "uses_other_staffing_agencies",
              type: "switch",
              label: "does_this_contact_use_staffing_agencies",
              secure: false,
              required: false,
              placeholder: "",
              keyboardtype: "",
              labelPosition: "top",
            }}
            formState={formState}
            handleFieldChange={handleFieldChange}
            formValidators={contactFormValidators}
            className={"cnt-f-input-holder"}
            customStyle={{ flex: 1 }}
          />
          {formState.uses_other_staffing_agencies && (
            <RenderInput
              doc={{
                field: "staffing_agency_competitors",
                type: "selector",
                label: "competitors_this_contact_worked_with",
                secure: false,
                required: false,
                placeholder: "select",
                keyboardtype: "",
                selectorList: props.competitors.map((doc) => ({
                  label: doc.competitor_name,
                  value: doc.id,
                })),
                multi: true,
              }}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={contactFormValidators}
              className={"cnt-f-input-holder"}
              customStyle={{ flex: 2 }}
              menuPlacement= {"top"}
            />
          )}
        </div>
      </div>
    );
  }

  function getLinkIcon() {
    return (
      <div className="cnt-f-link-icon">
        <LinkIcon width={"100%"} height={"100%"} />
      </div>
    );
  }

  function _isFormValid() {
    const { first_name, social_media_links, email,zip_code,mobile_no,office_no, status_id, preferred_contact_method } = formState;

    const { facebook_url, twitter_url, pinterest_url, linkedin_url } =
      social_media_links;

    if (first_name === "") return false;
    if (status_id === "")  return false;

    if (preferred_contact_method === "")  return false;
    
    if (RegxPattern.username.test(first_name) === false) return false;
    if (RegxPattern.username.test(status_id) === false) return false;
    if (mobile_no !== '' && RegxPattern.phone.test(mobile_no) === false) return false;
    if (office_no !== '' && RegxPattern.phone.test(office_no) === false) return false;
    if (zip_code !=='' && RegxPattern.zipCode.test(zip_code) === false) return false;
    if (email != null && email !== "" && RegxPattern.email.test(email) === false)
      return false;
    if (
      linkedin_url != null &&
      linkedin_url !== "" &&
      RegxPattern.url.test(linkedin_url) === false
    )
      return false;
    if (
      facebook_url != null &&
      facebook_url !== "" &&
      RegxPattern.url.test(facebook_url) === false
    )
      return false;
    if (
      twitter_url != null &&
      twitter_url !== "" &&
      RegxPattern.url.test(twitter_url) === false
    )
      return false;
    if (
      pinterest_url != null &&
      pinterest_url !== "" &&
      RegxPattern.url.test(pinterest_url) === false
    )
      return false;
    return true;
  }

  function contactFormValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case "first_name": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.username.test(value.text) === false) return t("validators.enterValidName");
        return null;
      }
      case "preferred_contact_method":
      case "status_id": {
        if (value.text === "") return t("validators.required");
        return null;
      } 
      case "website":
      case "linkedin_url":
      case "facebook_url":
      case "twitter_url":
      case "pinterest_url": {
        if (!value.text) return null;
        if (RegxPattern.url.test(value.text) === false)
          return t("validators.enterValidUrl");
        return null;
      }
      case "email": {
        if (!value.text) return null;
        if (RegxPattern.email.test(value.text) === false)
          return t("validators.enterValidEmail");
        return null;
      }
      case "mobile_no": {
        if (!value.text) return null;
        if (RegxPattern.usPhone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      case "office_no": {
        if (!value.text) return null;
        if (RegxPattern.usPhone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      case "zip_code": {
        if (!value.text) return null;
        if (RegxPattern.zipCode.test(value.text) === false)
          return t("validators.enterValidZipCode");
        return null;
      }
      default: {
        return null;
      }
    }
  }
};

export default ContactForm;
