import React from 'react';
import { Provider } from 'react-redux';
import store  from './app/redux/store';
import { BrowserRouter } from 'react-router-dom';
import './app/custom.scss';
import './App.scss';
import { AppNavigation } from './app/nav';

function App() {
  return (
    <div className="app-container">
      <Provider store={store}>
        <BrowserRouter>
          <AppNavigation />
        </BrowserRouter>
      </Provider>
    </div>

  );
}

export default App;
