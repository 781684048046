import { useTranslation } from "react-i18next";
import CurveTabNavigation from "../../../../../components/curve_tab_navigation/curve_tab_navigation";
import "../job_posting.scss";
import { FC } from "react";
import { CalenderIcon, ChatGPTIcon, CompaniesIcon, DeleteIcon, EditIcon, LocationIcon } from "../../../../../icons";
import { CustomButton, FormInput, getPlusBtnIcon } from "../../../../../utils";
import { THEME } from "../../../../../enums";
import { TextareaAutosize } from "@material-ui/core";
import { IJobPost } from "../../../../../interfaces/job_posting";
import "../job_posting.scss";
import { getDateFromTimeStamp, getDateString } from "../../../../../variables";
interface Props {
  currentLoginUserName: string | undefined;
  description: string;
  handleEdit: () => void;
  handleDelete: () => void;
  jobPostData: IJobPost | null;
}
const JobPostingStepTwo: FC<Props> = (props) => {
  const { currentLoginUserName, description, handleEdit, handleDelete, jobPostData } = props;
  const { t } = useTranslation();
  // const date_posted = getDateString(jobPostData?.date_posted);
  const BoldText = ({ text }) => {
    const boldTextRegex = /\*\*(.*?)\*\*/g;
    const parts = text.split("\n");

    return (
      <>
        {parts.map((part, index) => {
          if (part.match(boldTextRegex)) {
            return <b key={index} >{part.replace(/\*\*/g, '')}</b>;
          } else {
            return <span key={index} style={{ marginBottom: '1rem' }}>{part}</span>;
          }
        })}
      </>
    );
  };

  return (
    <>
      <div className="jp-step2-container">
        <div className="jp-step2-container">
          <div className="jp-step2-header">
            <div>
              <div className="jp-step2-header-title">
                {t("hey_jop_posting_looks_like", {
                  name: currentLoginUserName,
                })}
              </div>
              <div className="jp-step2-header-subtitle">
                <span>{t("click_edit_icon_make_changes_post_job")}</span>
              </div>
            </div>
          </div>
          <div className="jp-step2-content">
            <div className="edit-actions">
              <EditIcon width={"100%"} height={"100%"} onClick={handleEdit} />
              <DeleteIcon width={"100%"} height={"100%"} onClick={handleDelete} />
            </div>
            <div className="desc-content">
              {jobPostData !== null && <div>
                <div className="title-header">
                  <div>
                    <div className="job-title">{jobPostData?.job_title}</div>
                    <div className="comp-loc-div"><div className="label-title"> <CompaniesIcon width={'100%'} height={'100%'} /><span>{jobPostData?.hiring_organization}</span></div>
                      <div className="label-title"><LocationIcon width={'100%'} height={'100%'} /><span>{jobPostData?.job_location}</span></div>
                      <div className="label-title"><CalenderIcon width={'100%'} height={'100%'} /><span>{getDateString(jobPostData?.date_posted)}</span></div>
                    </div>
                  </div>

                  <div className="label-btn">
                    <CustomButton
                      loading={false}
                      textStyle={{ textTransform: "capitalize" }}
                      name={t("apply_now")}
                      enable={true}
                      backgroundColor={THEME.defaultHighLightColor}
                      onClick={() => { window.open(jobPostData?.link_to_apply, "_blank"); }}
                    />

                  </div>
                </div>
                <div className="label">Description: </div>
                <div style={{ whiteSpace: "pre-wrap" }}><BoldText text={jobPostData?.description} /></div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobPostingStepTwo;
