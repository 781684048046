import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IOnboardingVerifyTokenResponse, Profile } from "../../../interfaces";
import * as thunk from "./verify_token_thunk";

export interface OnBoardingVerifyTokenState {
    error?: AppError | null,
    loading: LoadingType,
    response: IOnboardingVerifyTokenResponse | null,
}

const initialState: OnBoardingVerifyTokenState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const onBoardingVerifyTokenSlice = createSlice({
    name: 'verifyToken',
    initialState: initialState,
    reducers: {
        clearError(state) {
            return { ...state, error: null };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.onboardingVerifyTokenThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.onboardingVerifyTokenThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
                response: null,
            }))
            .addCase(thunk.onboardingVerifyTokenThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                error: null,
                response: action.payload
            }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const onboardingVerifyTokenActions = onBoardingVerifyTokenSlice.actions;
export const selectState = (state: OnBoardingVerifyTokenState) => state;
export const selectLoading = (state: OnBoardingVerifyTokenState) => state.loading;
export const selectError = (state: OnBoardingVerifyTokenState) => state.error;


