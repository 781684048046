import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import { selectState, selectSingleState, selectSingleInHouseInterviewsInitialState } from "./in_house_interviews_reducer";

const select = (state: IRootState) => state.talent.details.interviews.InHouseInterviews;
export const selectInHouseInterviewsState = createSelector(select, selectState);
export const selectSingleInHouseInterviewsStateById = (id: string) => createSelector(
    select, 
    (state) => {
        if (id && state[id]) return selectSingleState(state, id);
        return selectSingleInHouseInterviewsInitialState();
    }
);


export const selectSingleInHouseInterviewsList = (id: string) => createSelector(
    select, 
    (state) => {
        if (id && state[id]) {
            const data = selectSingleState(state, id).data;
            return data ? Object.keys(data).map((key) => data[key]) : [];
        }
        return [];
    }
);