import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./contact_list_reducer";

const select = (state: IRootState) => state.selectors.contactsDropdown;
export const selectContactsDropdownListState = createSelector(select, selectState);
export const selectContactsDropdownList = createSelector(
    select,
    selectAll
);

export const selectContactsDropdownListEntities = createSelector(
    select,
    selectEntities
);