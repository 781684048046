import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IPayRollDetails } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { EmployeePortalAPI } from "../../../apis";


export const getEPPayrollDetailsList = createAsyncThunk<
    IPayRollDetails,
    {ePortal: boolean, talent_id?: string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee-portal/dashbord/payroll_details/get',
    async (doc, thunkAPI) => {
        try {
            const res = await EmployeePortalAPI.getEPPayrollDetails(doc.ePortal,doc.talent_id);
            if (typeof res.data === 'string') return [];
            return res.data as IPayRollDetails;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
