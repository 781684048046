import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, IBillingBatch, IBillingTimeCardsData, ITimeCardsData } from "../../../../interfaces";
import * as thunk from "./billing_timecards_thunk";

export interface BillingTimecardsState {
  error?: AppError | null;
  loading: LoadingType;
  response: IBillingTimeCardsData[] | null;
}

const initialState: BillingTimecardsState = {
  error: null,
  loading: LoadingType.idle,
  response: null,
};

export const BillingTimecardsSlice = createSlice({
  name: "billingTimecards",
  initialState: initialState,
  reducers: {
    clearState(_state) {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getBillingTimecardsThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getBillingTimecardsThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.getBillingTimecardsThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        response: action.payload,
      }))
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const BillingTimecardsSliceActions = BillingTimecardsSlice.actions;
export const selectState = (state: BillingTimecardsState) => state;
export const selectAll = (state: BillingTimecardsState) => state.response;
