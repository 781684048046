import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./department_list_reducer";

const select = (state: IRootState) => state.companies.universalDirectory.departmentsList;
export const selectDepartmentsListState = createSelector(select, selectState);
export const selectDepartmentsList = createSelector(
    select,
    selectAll
);
export const selectDepartmentsListEntities = createSelector(
    select,
    selectEntities
);