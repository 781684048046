import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import TabNavigation from "../../../components/tab_navigation/tab_navigation";
import { AppRoutes } from "../../../routes";
import { GetCloseAction } from "../../../utils";
import TimeCardsDashboardPage from "../pages/time_cards/timecards_dashboard/timecards_dashboard_page";
import TimeCardsTabPage from "../pages/time_cards/timecards_tab/timecards_tab";
import TimeCardsReportPage from "../pages/time_cards/timecards_report/timecards_report";
import InDevelopment from "../../../others/in_development";
import TimeCardsErrorsReportPage from "../pages/time_cards/timecards_errors_report/timecards_errors_report";

const TimeCardsTabNav = (props: RouteComponentProps<any>) => {

    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();
    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                {
                    title: 'timecards_dashboard',
                    route: `${AppRoutes.timeCardsDashboard}`,
                },
                {
                    title: 'timecards',
                    route: `${AppRoutes.timeCardsTab}`,
                },
                {
                    title: "timecards_report",
                    route: `${AppRoutes.timeCardsReport}`,
                },

                {
                    title: "errors_report",
                    route: `${AppRoutes.timeCardsErrorReport}`,
                },

            ]}
            onChange={(value) =>
                history.push({ pathname: value, state: location.state })
            }
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.backOfficePage)} />}
        >
            <Switch>
                <Route
                    exact
                    path={`${AppRoutes.timeCardsDashboard}`}
                    component={TimeCardsDashboardPage}
                />
                <Route
                    exact
                    path={`${AppRoutes.timeCardsTab}`}
                    component={TimeCardsTabPage}
                // component={InDevelopment}

                />
                <Route
                    exact
                    path={`${AppRoutes.timeCardsReport}`}
                    component={TimeCardsReportPage}
                // component={InDevelopment}

                />
                <Route
                    exact
                    path={`${AppRoutes.timeCardsErrorReport}`}
                    component={TimeCardsErrorsReportPage}
                // component={InDevelopment}

                />
                <Route
                    path={"/"}
                    render={({ location }) => (
                        <Redirect
                            to={{
                                pathname: `${location.pathname}/dashboard`,
                                state: { ...(location.state as any) },
                            }}
                        />
                    )}
                />
            </Switch>
        </TabNavigation>);
}

export default TimeCardsTabNav;