import { useTranslation } from "react-i18next";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import { AppRoutes, getJobsNavHeaderProps } from "../../routes";
import JobsPage from './pages/universal_directory/universal_directory_page';
import { useEffect } from "react";
import {
    getCertificationsList,
    getCredentialsList,
    getEducationList,
    getRequiredEquipmentList,
    getRoleUsersList,
    getSkillSetList,
    useAppDispatch
} from "../../redux/store";
import { getCompetitorsList } from "../../redux/selectors/competitors_list/competitors_list_thunk";
import JobsDashboardPage from "./pages/job_info/job_order_dashboard/jobs_dashboard";

import JobsAssignmentPage from "./pages/job_info/job_order_assignments/job_order_assignments_page";
import JobOrderContactPage from "./pages/job_info/job_order_contacts/job_order_contacts_page";
import JobOrderTalentPage from "./pages/job_info/job_order_talent/job_order_talent_page";
import JobServiceProfilePage from "./pages/job_info/job_order_service_profile/service_profile";
import JobSuccessProfilePage from "./pages/job_info/job_order_success_profile/job_success_profile";
import JobOrderDocumentPage from "./pages/job_info/job_order_documents/job_order_document_page";
import { GetCloseAction } from "../../utils";


interface Props extends RouteComponentProps<any> { }

/// Universal directory folder
const JobTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'universal_directory', route: AppRoutes.jobsUniversalDirectory },
            ]}
            onChange={(value) => history.push(value)}
        >
            <Switch>
                <Route exact path={AppRoutes.jobsUniversalDirectory} component={JobsPage} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: AppRoutes.jobsUniversalDirectory,
                            state: { from: location }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );
}


export const JobNavigation: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getJobsNavHeaderProps(location, match, t);

    return (
        <PageNavHeader data={headerOptions} history={history} location={location}>
            <Switch>
                <Route path={`${AppRoutes.jobDetailsPage}/:id`} component={JobDetailsTabNav} />
                <Route path={'/'} component={JobTabNav} />
            </Switch>
        </PageNavHeader>
    );
}


/// Job Details
const JobDetailsTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const pathSplit = location.pathname.split('/');
    const departmentId = pathSplit.length === 6 ? pathSplit[pathSplit.length - 1] : null;

    useEffect(() => {
        dispatch(getSkillSetList());
        dispatch(getCertificationsList());
        dispatch(getCredentialsList());
        dispatch(getEducationList());
        dispatch(getRequiredEquipmentList());
        dispatch(getRoleUsersList());
        dispatch(getCompetitorsList());
        //dispatch(getJobOrder())

        return () => { }
    }, [])

    function handleNavigationChange(value: string) {
        history.push({
            pathname: departmentId ? `${value}/${departmentId}` : value,
            state: location.state
        });
    }

    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'dashboard', route: `${AppRoutes.jobDetailsPage}/${params.id}/dashboard` },
                { title: 'success_profile_job_posting', route: `${AppRoutes.jobDetailsPage}/${params.id}/success-profile-job-posting` },
                { title: 'interviews', route: `${AppRoutes.jobDetailsPage}/${params.id}/interviews` },
                { title: 'assignments', route: `${AppRoutes.jobDetailsPage}/${params.id}/assignments` },
                { title: 'contacts', route: `${AppRoutes.jobDetailsPage}/${params.id}/contacts` },
                { title: 'service_profile', route: `${AppRoutes.jobDetailsPage}/${params.id}/service-profile` },
                { title: 'documents', route: `${AppRoutes.jobDetailsPage}/${params.id}/documents` },
            ]}
            onChange={handleNavigationChange}
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.jobsPage)}/>}
        >
            <Switch>
                <Route exact path={`${AppRoutes.jobDetailsPage}/:id/dashboard/:departmentId?`} component={JobsDashboardPage} />
                <Route exact path={`${AppRoutes.jobDetailsPage}/:id/success-profile-job-posting/:departmentId?`} component={JobSuccessProfilePage} />
                <Route exact path={`${AppRoutes.jobDetailsPage}/:id/interviews/:departmentId?`} component={JobOrderTalentPage} />
                <Route exact path={`${AppRoutes.jobDetailsPage}/:id/assignments/:departmentId?`} component={JobsAssignmentPage} />
                <Route exact path={`${AppRoutes.jobDetailsPage}/:id/contacts/:departmentId?`} component={JobOrderContactPage} />
                <Route exact path={`${AppRoutes.jobDetailsPage}/:id/service-profile/:departmentId?`} component={JobServiceProfilePage} />
                <Route exact path={`${AppRoutes.jobDetailsPage}/:id/documents/:departmentId?`} component={JobOrderDocumentPage} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: `${location.pathname}/dashboard`,
                            state: { ...(location.state as any) }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );
}
