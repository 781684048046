import { combineReducers } from "@reduxjs/toolkit";
import { jobsDetailReducer } from "./details";
import { jobsUniversalDirectoryReducers } from "./universal_directory";


export const jobsReducers = combineReducers({
    'universalDirectory': jobsUniversalDirectoryReducers,
    "details": jobsDetailReducer,
});

//[Dashboard]
export * from './details';
export * from './universal_directory';


