import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums"
import { AppError, Assignment } from "../../../../../interfaces"
import * as thunk from './assignment_list_thunk';


export interface SingleContactAssignmentState {
    data: Array<Assignment> | null,
    error?: AppError | null,
    loading: LoadingType
}


export interface ContactAssignmentState {
    [key: string]: SingleContactAssignmentState,
}


const initialState: ContactAssignmentState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

export const contactAssignmentSlice = createSlice({
    name: 'assignmentsList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getContactAssignmentsList.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...getLoadingState(state[action.meta.arg.contact_id])
                    }
                })
            )
            .addCase(
                thunk.getContactAssignmentsList.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...getErrorState(state[action.meta.arg.contact_id], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getContactAssignmentsList.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.contact_id]: {
                        ...getSuccessState(state[action.meta.arg.contact_id], action.payload),
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const contactAssignmentSliceActions = contactAssignmentSlice.actions;
export const selectContactAssignmentsState = (state: ContactAssignmentState) => state;
export const selectSingleContactAssignmentsState = (state: ContactAssignmentState, id: string): SingleContactAssignmentState => state[id];

export const selectInitialSingleContactAssignmentsState = (): SingleContactAssignmentState => ({
    data: null,
    error: null,
    loading: LoadingType.idle,
});