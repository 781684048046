import { createAsyncThunk } from "@reduxjs/toolkit";
import { SalesMarketingAPI } from "../../../../apis/sales_marketing_api";
import { AppError } from "../../../../interfaces";
import { SalesCall, SalesCallsDataParam } from "../../../../interfaces/sales_call";
import { catchRequestError } from "../../../../utils";

export const getSalesCallList = createAsyncThunk<
  Array<SalesCall>,
  void,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@sales_marketing/my_call_deck/sales_call_list/get", async (_, thunkAPI) => {
  try {
    const res = await SalesMarketingAPI.getSalesCalls();
    if (typeof res.data === "string") return [];
    const arr = res.data.data ? res.data.data : res.data; 
    return arr as SalesCall[];
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});



export const getContactSalesCallsList = createAsyncThunk<
    Array<SalesCall>,
    {contact_id:string, upcoming_calls: boolean},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
  '@sales_marketing/sales_call_activity/contact_sales_call_list/get',
    async (payload, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.getContactSalesCalls(payload.contact_id,payload.upcoming_calls);
            if(typeof res.data === 'string') return [];
            return res.data as SalesCall[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getSalesCallFilteredList = createAsyncThunk<
  Array<SalesCall>,
  SalesCallsDataParam,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@sales_marketing/sales_calls_list/post", async (payload, thunkAPI) => {
  try {
    const res = await SalesMarketingAPI.getSalesCallGetByUsers(payload);
    if (typeof res.data === "string") return [];
    const arr = res.data.data ? res.data.data : res.data; 
    return arr as SalesCall[];
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});
