import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { NavLink, RouteComponentProps } from "react-router-dom";
import CustomFormSelect, {
  CustomMultiFormSelect,
} from "../../../../components/form_selector/form_select";
import SearchBar from "../../../../components/search_bar";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableFilterEmpty,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../enums";
import { IContact } from "../../../../interfaces";
import { manageContactActions } from "../../../../redux/contacts/universal_directory/manage_contact/manage_contact_reducer";
import {
  getContactsListThunk,
  getRoleUsersList,
  postSnackbarMessage,
  selectAccountManagerRoleUsersList,
  selectContactsList,
  selectContactsListState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  getContactIcon,
  getPlusBtnIcon,
  getTableAccessIcon,
  sortByOrders,
} from "../../../../utils";
import OpenOrderRow from "../../../../utils/open_orders";
import {
  // companyStatusOptions,
  getDaysAgoWithDateAMPM,
} from "../../../../variables";
import CreateContactPopup from "../../popups/create_contact/create_contact";
import "./universal_directory.scss";
import { getContactStatus, selectContactStatusList } from "../../../../redux/admin_center";
import { CircleDownloadIcon, DownloadIcon } from "../../../../icons";
import UploadRecordPopup from "../../../components/import_data_file/import_data_file";
import UrlBuilder from "../../../../apis/url_builder";
import { selectImportDataFileUrlState, selectUpdateImportDataFileUrlState, updateImpDataFileUploadUrl } from "../../../../redux/upload_files";
import { importUrlActions } from "../../../../redux/upload_files/import_data_file_reducer";

interface Props extends RouteComponentProps<any> { }
const tableHeader = [
  { title: "access_contact", code: "access_contact" },
  { title: "orders", code: "open_orders" },
  { title: "name", code: "name" },
  { title: "status", code: "status" },
  { title: "company", code: "company_name" },
  { title: "job_title", code: "job_title" },
  { title: "department", code: "department_names" },
  { title: "last_contact", code: "last_contact" },
  { title: "contact", code: "preferred_contact_method" },
];

const UniversalDirectoryPage: React.FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const contactsListState = useAppSelector((state) =>
    selectContactsListState(state)
  );
  const contactsList = useAppSelector((state) => selectContactsList(state));
  const accountManagersList = useAppSelector((state) =>
    selectAccountManagerRoleUsersList(state)
  );
  const [search, setSearch] = useState("");
  const [sortedField, setSortedField] = useState<string | null>(null);

  const [sortDirection, setSortDirection] = useState("asc");

  const [addContact, setAddContact] = useState<boolean>(false);


  const { history, location } = props;
  const { t } = useTranslation();
  const [includeDepartment, setIncludeDepartment] = useState(false);
  const [accountManagers, setAccountManagers] = useState<string[]>([]);
  // const [status, setStatus] = useState<string | null>(null);
  const [status, setStatus] = useState<string[]>([]);

  const [activeAssignment, setActiveAssignment] = useState<boolean>(false);

  ///Contact Status
  //  const contactStatusState = useAppSelector((state) => selectContactStatusState(state));
  const contactStatusList = useAppSelector((state) => selectContactStatusList(state));

  const importDataFileUrlState = useAppSelector((state) => selectImportDataFileUrlState(state));
  const updateImportDataFileUrlState = useAppSelector((state) => selectUpdateImportDataFileUrlState(state));

  const [importContacts, setImportContacts] = useState<boolean>(false)
  const [snackBarMessage, setSnackBarMessage] = useState("");

  useEffect(() => {
    getContacts();
    checkAddContact();
    dispatch(getRoleUsersList());
    dispatch(getContactStatus());
    return () => { };
  }, []);

  useEffect(() => {
    if (contactsListState.error != null && contactsList.length != 0) {
      dispatch(postSnackbarMessage(contactsListState.error.message));
    }
    return () => { };
  }, [contactsListState.error]);

  useEffect(() => {
    if (updateImportDataFileUrlState?.loading === LoadingType.succeeded && updateImportDataFileUrlState?.response) {
      if (snackBarMessage !== "") {
        dispatch(postSnackbarMessage(snackBarMessage ?? null));
        dispatch(importUrlActions.clearUpdateImportUrlStateError())
      }
      setImportContacts(false)
      // getContacts();
    }
    return () => { };
  }, [updateImportDataFileUrlState?.loading]);

  useEffect(() => {
    if (importDataFileUrlState?.loading === LoadingType.succeeded && importDataFileUrlState?.response) {
      dispatch(updateImpDataFileUploadUrl({
        baseUrl: UrlBuilder.updateImportContacts,
        import_id: importDataFileUrlState?.response?.id ?? "" 
      }))
    }
 
  }, [importDataFileUrlState?.loading, importDataFileUrlState?.response]);

  useEffect(() => {
    const state = history.location.state as any;
    if (state && state.activeAssignment) {
      setActiveAssignment(true);
    }
  }, [history]);

  function getContacts() {
    dispatch(getContactsListThunk());
  }

  function checkAddContact() {
    setTimeout(() => {
      if (
        location &&
        location.state != null &&
        (location.state as any).newContact === true
      ) {
        setAddContact(true);
      }
    }, 1000);
  }

  function getFilteredList(): IContact[] {
    const list = [...contactsList];
    let sortedList: IContact[] | undefined;
    let resultList: IContact[] | undefined;

    if (activeAssignment) {
      resultList = list.filter((doc) => {
        const onActiveAssignmentFilter =
          doc.active_assignment > 0 ? doc.active_assignment : false;
        return onActiveAssignmentFilter;
      });
    }

    if (accountManagers.length != 0) {
      resultList = list.filter((doc) => {
        const filter =
          doc.account_managers && doc.account_managers.length != 0
            ? doc.account_managers.some((s) => accountManagers.indexOf(s) >= 0)
            : false;
        return filter;
      });
    }

    // if (status != null && status != "") {
    //   resultList = (resultList ?? list).filter((doc) => {
    //     const statusFilter =
    //       doc.status_id && status
    //         ? doc.status_id === status
    //         : false;
    //     return statusFilter;
    //   });
    // }

    if (status.length != 0) {
      resultList = (resultList ?? list).filter((doc) => {
        const filter =
          doc.status && doc.status.length != 0
            ? status.includes(doc.status_id!)
            : false;
        return filter;
      });
    }

    if (sortedField != null) {
      if (sortedField === "open_orders") {
        sortedList = sortByOrders([...(resultList ?? list)], "order_status");
      } else {
        sortedList = [...(resultList ?? list)].sort((a, b) => {
          const valueA =
            a[sortedField] != null && a[sortedField] != undefined
              ? typeof a[sortedField] == typeof "1"
                ? a[sortedField].trim().toLowerCase()
                : a[sortedField]
              : "";
          const valueB =
            b[sortedField] != null
              ? typeof b[sortedField] == typeof "1"
                ? b[sortedField].trim().toLowerCase()
                : b[sortedField]
              : "";
          if (sortDirection === "asc") {
            return valueA > valueB ? 1 : -1;
          } else {
            return valueA < valueB ? 1 : -1;
          }
        });
      }
    }

    return (sortedList ?? resultList ?? list).filter((doc) => {
      const str = search.trim().toLowerCase();
      const nameFilter = doc.name
        ? doc.name.trim().toLowerCase().includes(str)
        : false;
      const companyName = doc.company_name
        ? doc.company_name.trim().toLowerCase().includes(str)
        : false;
      const jobFilter = doc.job_title
        ? doc.job_title.trim().toLowerCase().includes(str)
        : false;
      const statusFilter = doc.status
        ? doc.status.trim().toLowerCase().includes(str)
        : false;
      const departmentFilter = doc.department_names
        ? doc.department_names.toString().trim().toLowerCase().includes(str)
        : false;
      return (
        nameFilter || companyName || jobFilter || statusFilter || departmentFilter
      );
    });
  }

  function handleSortFieldChange(value: string) {
    if (value === "open_orders") {
      if (sortedField && sortedField === value) {
        // setSortedField(null)
      } else {
        setSortedField(value);
      }
    }
    else {
      if (sortedField && sortedField === value) {
        // setSortedField(null)
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortedField(value);
        setSortDirection('asc');
      }
    }
  }

  function handleContactSelect(value: IContact) {
    props.history.push({
      pathname: `${AppRoutes.contactsDetailsPage}/${value.id}`,
      state: {
        id: value.id,
        name:
          value.name ?? `${value.first_name ?? ""} ${value.last_name ?? ""}`,
      },
    });
    return;
  }

  const handleClearError = () => {
    dispatch(importUrlActions.clearImportUrlStateError())
  }

  const handleSnackBar = () => {
    setSnackBarMessage(t("contacts_uploaded_successfully"))
  }


  return (
    <div className="cnt-ud-container">
      <div className="cnt-ud-table-header">
        <TableHeaderOptions
          roleUsers={accountManagersList.map((doc) => ({
            label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
            value: doc.id,
          }))}
          totalCount={getFilteredList().length}
          departmentActive={includeDepartment}
          onChangeDepartment={() => setIncludeDepartment(!includeDepartment)}
          onChangeRoleUser={setAccountManagers}
          selectedRoleUsers={accountManagers}
          search={search}
          onSearchChange={setSearch}
          selectedStatus={status}
          onStatusChange={setStatus}
          // companyStatusList={[
          //   { label: "all", value: "" },
          //   ...companyStatusOptions,
          // ]}
          companyStatusList={contactStatusList.map((doc) => ({
            label: doc.contact_status,
            value: doc.id,
          }))}
        />

        <div className="cnt-ud-actions">
          <div className="upl-files">
            <CustomButton
              leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} />}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("upload_contacts")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setImportContacts(true)}
            />
          </div>
          <div style={{ marginRight: '1rem' }}>
            <NavLink to={`${AppRoutes.apolloSearchImport}/contacts`} >
              <CustomButton
                leftIcon={<div className="btn-icon circle-download-svg">
                  <CircleDownloadIcon width={"100%"} height={"100%"} style={{ fill: "#fff" }} />
                </div>}
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("get_contact")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => { }}
              />
            </NavLink>
          </div>
          <div className="cnt-ud-add-user-btn">

            <CustomButton
              leftIcon={getPlusBtnIcon()}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("add_contact")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => setAddContact(true)}
            />
          </div>
        </div>
      </div>
      <div className="cnt-ud-table">{getContactsTableList()}</div>
      {addContact && (
        <Portal>
          <CreateContactPopup
            visible={addContact}
            title={t("add_contact")}
            successTitle={t("new_contact")}
            onClose={() => setAddContact(false)}
            onSuccessClose={handleAddContactSuccess}
            onGotoAgencyDashboard={handleGotoAgencyDashboard}
            onGotoCreated={handleOnGotoContact}
          />
        </Portal>
      )}
      {importContacts && (
        <Portal>
          <UploadRecordPopup
            visible={importContacts}
            title={t("upload_contacts")}
            baseUrl={UrlBuilder.importContacts}
            onClose={() => setImportContacts(false)}
            loading={importDataFileUrlState?.loading}
            error={importDataFileUrlState?.error}
            onClearError={handleClearError}
            handleSnackBar={handleSnackBar}
            importType={"Contact"}
          />
        </Portal>
      )}

    </div>
  );

  ///Add user action
  function handleAddContactSuccess() {
    setAddContact(false);
    getContacts();
    dispatch(manageContactActions.clearManageContactState(["photo", "resume"]));
  }

  function handleGotoAgencyDashboard() {
    handleAddContactSuccess();
    props.history.push(AppRoutes.dashboardPage);
  }

  function handleOnGotoContact(id: string, name: string) {
    handleAddContactSuccess();
    props.history.push({
      pathname: `${AppRoutes.contactsDetailsPage}/${id}`,
      state: {
        id: id,
        name: name,
      },
    });
  }

  /// Users table list
  function getContactsTableList() {
    if (contactsListState.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (contactsListState.error != null && contactsList.length === 0) {
      return (
        <TableErrorHandler
          error={contactsListState.error}
          onRefresh={getContacts}
        />
      );
    }
    if (contactsList.length === 0) {
      return (
        <TableEmpty title={t("no_contacts_matches_found")} onClick={() => getContacts()} />
      );
    }
    if (getFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_contacts_found")} />;
    }
    return (
      <SortableTable
        headerList={tableHeader}
        sortedField={sortedField}
        onSortChange={handleSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={sortDirection}
      >
        {getFilteredList().map((doc) => {
          return (
            <tr key={doc.id}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                {getTableAccessIcon(() => handleContactSelect(doc))}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(1) }}>
                {OpenOrderRow(doc, "order_status", t)}
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(2) }}
                isButton={true}
                onClick={() => handleContactSelect(doc)}
              >
                <span>{doc.name}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(3) }}>
                <span>
                  {/* {doc.status ? getCompanyStatusText(doc.status, t) : ""} */}
                  {doc.status ?? ""}
                </span>
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(4) }}
                isButton={true}
                onClick={() => handleCompanySelect(doc)}
              >
                <span>{doc.company_name ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                <span>{doc.job_title ?? ""}</span>
              </TableData>
              <td
                style={{
                  flex: getFlexNumber(6),
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              className="table-data-container"
              >
                {doc.department_names &&
                  doc.department_names.length !== 0 ?
                  doc.department_names.map((item, index, arr) => (
                    <>
                      <a
                        onClick={() => handleDepartmentSelect(doc, index)}
                        className="cursor-p table-data-btn"
                      >
                        {item} {index !== arr.length - 1 ? ", " : ""}
                      </a>
                    </>
                  )) :
                  (<>
                    {doc.company_id !== null ? <div
                      onClick={() => handleCompanySelect(doc)}
                      className="cursor-p"
                    >
                      <span>{t('corporate')}</span>
                    </div> :
                      <span></span>}</>

                  )
                }
              </td>
              <TableData customStyle={{ flex: getFlexNumber(7) }}>
                <span>
                  {doc.last_contact
                    ? getDaysAgoWithDateAMPM(t, doc.last_contact)
                    : ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(8) }}>
                {getContactIcon(doc.preferred_contact_method)}
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 1.2;
    if (value === 1) return 2;
    if (value === 2) return 1.5;
    if (value === 3) return 2;
    if (value === 4) return 2;
    if (value === 5) return 2;
    if (value === 6) return 2;
    if (value === 7) return 2;
    if (value === 8) return 1.3;
    return 1;
  }

  function handleCompanySelect(value: IContact) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
      },
    });
    return;
  }

  function handleDepartmentSelect(value: IContact, index) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_ids[index]}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_ids[index],
        departmentName: value.department_names[index],
      },
    });
    return;
  }
};

interface TableHeaderOptionsProps {
  /// [Role users]
  roleUsers: Array<{ label: string; value: string }>;
  selectedRoleUsers: string[];
  onChangeRoleUser: (value: string[]) => void;
  /// [Department]
  departmentActive: boolean;
  onChangeDepartment: () => void;
  totalCount: number;
  /// [Search]
  search: string;
  onSearchChange: (value: string) => void;
  /// [Status]
  // selectedStatus: string | null;
  selectedStatus: string[];
  onStatusChange: (value: string[]) => void;
  companyStatusList: Array<{ label: string; value: string }>;
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
  const { t } = useTranslation();
  return (
    <div className="cnt-ud-table-header-options">
      <div className="account-manager-selector">
        <CustomMultiFormSelect
          label={t("account_manager")}
          name={"account_manager"}
          list={props.roleUsers}
          onChange={props.onChangeRoleUser}
          required={false}
          placeholder={t("select")}
          value={props.selectedRoleUsers}
          customStyle={{ width: "-webkit-fill-available" }}
        />
      </div>
      <div className="company-status-selector">
        {/* <CustomFormSelect
          customStyle={{ width: "-webkit-fill-available" }}
          name={"company_status"}
          value={props.selectedStatus}
          required={false}
          label={t("status")}
          placeholder={t("all")}
          list={props.companyStatusList}
          onChange={props.onStatusChange}
        /> */}
        <CustomMultiFormSelect
          label={t("status")}
          name={"company_status"}
          list={props.companyStatusList}
          onChange={props.onStatusChange}
          required={false}
          placeholder={t("all")}
          value={props.selectedStatus}
          customStyle={{ width: "-webkit-fill-available" }}
        />
      </div>
      <div className="cnt-ud-table-search-bar">
        <SearchBar
          value={props.search}
          onChange={props.onSearchChange}
          onSearch={() => { }}
        />
      </div>
      {/* <div className="deparment-toggle">
        <CustomCheckBox
          name={"department"}
          title={t('include_departments')}
          checked={props.departmentActive}
          onClick={props.onChangeDepartment}
        />
      </div> */}
      <div className="total-count">
        <span>
          {`${t("total_count")}: `}
          <span className="total-count-number">{props.totalCount}</span>
        </span>
      </div>
    </div>
  );
};

export default UniversalDirectoryPage;
