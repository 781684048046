import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import { IApolloCompanyDetails } from "../../../interfaces/apollo_search_import";
import * as thunk from "./apollo_company_details_thunk";


export interface SingleApolloCompanyState {
    data: IApolloCompanyDetails | null,
    error?: AppError | null,
    loading: LoadingType,
    
}
export const selectInitialApolloCompanyDetailState = (): SingleApolloCompanyState => ({
    data: null,
    loading: LoadingType.idle,
    error: null,
});
export interface ApolloCompanyDetailsState {
    [key: string]: SingleApolloCompanyState,
}

const initialState: ApolloCompanyDetailsState = {}


function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

export const apolloCompanyDetailsSlice = createSlice({
    name: 'apolloCompanyDetailsSlice',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        
        .addCase(
            thunk.getApolloCompanyById.pending,
            (state, action) => ({
                ...state,
                [action.meta.arg]: {
                    ...getLoadingState(state[action.meta.arg]),
                   
                }
            })
        )
        .addCase(
            thunk.getApolloCompanyById.rejected,
            (state, action) => ({
                ...state,
                [action.meta.arg]: {
                    ...getErrorState(state[action.meta.arg], action.payload),
                }
            })
        )
        .addCase(
            thunk.getApolloCompanyById.fulfilled,
            (state, action) => ({
                ...state,
                [action.meta.arg]: {
                    ...getSuccessState(state[action.meta.arg], action.payload),
                }
            })
        )
        .addDefaultCase(state => ({ ...state }));
    },
});

export const apolloCompanyDetailsSliceActions = apolloCompanyDetailsSlice.actions;
export const selectState = (state: ApolloCompanyDetailsState) => state;
export const selectApolloCompanyDetailsState = (state: ApolloCompanyDetailsState, id: string): SingleApolloCompanyState => state[id];



