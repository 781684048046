import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./assignments_list_reducer";

const select = (state: IRootState) => state.assignments.universalDirectory.assignmentsList;
export const selectAssignmentsListState = createSelector(select, selectState);
export const selectAssignmentsList = createSelector(
    select,
    selectAll
);
export const selectAssignmentsListEntities = createSelector(
    select,
    selectEntities
);