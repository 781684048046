import { combineReducers } from "@reduxjs/toolkit";
import { contactsListSlice } from "./contacts_list/contacts_list_reducer";
import { manageContactSlice } from "./manage_contact/manage_contact_reducer";
import { apolloContactsListSlice } from "../../apollo_search_import/contacts_search_import/contacts_search_import_reducer";

export const contactsUniversalDirectoryReducers = combineReducers({
  [contactsListSlice.name]: contactsListSlice.reducer,
  [manageContactSlice.name]: manageContactSlice.reducer,
  [apolloContactsListSlice.name]: apolloContactsListSlice.reducer,
 


});

export * from './contacts_list/contacts_list_selector';
export * from './contacts_list/contacts_list_thunk';

export * from './manage_contact/manage_contact_selector';
export * from './manage_contact/manage_contact_thunk';

export * from '../../apollo_search_import/contacts_search_import/contacts_search_import_thunk';
export * from '../../apollo_search_import/contacts_search_import/contacts_search_import_selector';

