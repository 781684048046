import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums"
import { AppError, Assignment } from "../../../../../interfaces"
import * as thunk from './assignment_list_thunk';


export interface SingleCompanyAssignmentState {
    data: Array<Assignment> | null,
    error?: AppError | null,
    loading: LoadingType
}


export interface CompanyAssignmentState {
    [key: string]: SingleCompanyAssignmentState,
}


const initialState: CompanyAssignmentState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

export const companyAssignmentSlice = createSlice({
    name: 'assignmentsList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getCompanyAssignmentsList.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...getLoadingState(state[action.meta.arg.department_id ?? action.meta.arg.company_id])
                    }
                })
            )
            .addCase(
                thunk.getCompanyAssignmentsList.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...getErrorState(state[action.meta.arg.department_id ?? action.meta.arg.company_id], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getCompanyAssignmentsList.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.department_id ?? action.meta.arg.company_id]: {
                        ...getSuccessState(state[action.meta.arg.department_id ?? action.meta.arg.company_id], action.payload),
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const companyAssignmentSliceActions = companyAssignmentSlice.actions;
export const selectCompanyAssignmentsState = (state: CompanyAssignmentState) => state;
export const selectSingleCompanyAssignmentsState = (state: CompanyAssignmentState, id: string): SingleCompanyAssignmentState => state[id];

export const selectInitialSingleCompanyAssignmentsState = (): SingleCompanyAssignmentState => ({
    data: null,
    error: null,
    loading: LoadingType.idle,
});