import { combineReducers } from "@reduxjs/toolkit";
import { exportFileSlice } from "./export_page/export_page_reducer";
import { importFileSlice } from "./import_page/import_page_reducer";
import { reportsDirectorySlice } from "./reports_directory/reports_directory_reducer";


export const backOfficeImportExportReducers = combineReducers({
    [importFileSlice.name]: importFileSlice.reducer,
    [exportFileSlice.name]: exportFileSlice.reducer,
    [reportsDirectorySlice.name]: reportsDirectorySlice.reducer,
});

export * from "./import_page/import_page_thunk";
export * from "./import_page/import_page_selector";

export * from "./reports_directory/reports_directory_thunk";
export * from "./reports_directory/reports_directory_selector";

export * from "./export_page/export_page_thunk";
export * from "./export_page/export_page_selector";