import { createSelector } from "@reduxjs/toolkit";
import { selectPEReportDownloadUrl, selectState } from "./errors_report_reducer";
import { IRootState } from "../../../store";

const select = (state: IRootState) => state.backOffice.payRoll.payrollErrorReportList;
export const selectPayrollErrorsReportListState = createSelector(
    select,
    selectState
);

export const selectPERDownloadUrlState = createSelector(
    select,
    selectPEReportDownloadUrl
);
