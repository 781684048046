import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import {
  OnlineApplicationReceivedDetailsSlice,
  OnlineApplicationReceivedDetailsState,
} from "./online_applications_received_reducer";

export const { clearOnlineApplicationReceivedDetailsState } =
  OnlineApplicationReceivedDetailsSlice.actions;
export const selectState = (state: OnlineApplicationReceivedDetailsState) =>
  state;
export const selectLoading = (state: OnlineApplicationReceivedDetailsState) =>
  state.loading;
export const selectError = (state: OnlineApplicationReceivedDetailsState) =>
  state.error;
const select = (state: IRootState) =>
  state.dashboard.metrics.OnlineApplicationReceivedDetails;
export const selectOnlineApplicationReceivedDetailsState = createSelector(
  select,
  selectState
);
export const selectOnlineApplicationReceivedDetailsId = createSelector(
  selectOnlineApplicationReceivedDetailsState,
  (state) => state.appInfo?.id
);
export const selectOnlineApplicationReceivedDetails = createSelector(
  selectOnlineApplicationReceivedDetailsState,
  (state) => state.appInfo
);
