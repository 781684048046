import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./certifications_list_reducer";

const select = (state: IRootState) => state.selectors.certificationsList;
export const selectCertificationsListState = createSelector(select, selectState);
export const selectCertificationsList = createSelector(
    select,
    selectAll
);
export const selectCertificationsListEntities = createSelector(
    select,
    selectEntities
);