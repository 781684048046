import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, IEmployee } from "../../../../interfaces";
import * as thunk from "./employee_list_thunk";

export interface EmployeeListState extends EntityState<IEmployee> {
    error?: AppError | null,
    loading: LoadingType,
}

const employeeListAdapter: EntityAdapter<IEmployee> = createEntityAdapter<IEmployee>({
    selectId: (doc) => doc.id,
})

const initialState: EmployeeListState = employeeListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const employeeListSlice = createSlice({
    name: 'employeeList',
    initialState: initialState,
    reducers: {
        clearEmployeeListError(state){
            return {...state, error: null};
        },
        clearEmployeeListState(state){
            return employeeListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getEmployeeList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getEmployeeList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getEmployeeList.fulfilled, (state, action) => employeeListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearEmployeeListError, clearEmployeeListState } = employeeListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = employeeListAdapter.getSelectors();
export const selectState = (state: EmployeeListState) => state;
export const selectLoading = (state: EmployeeListState) => state.loading;
export const selectError = (state: EmployeeListState) => state.error;


