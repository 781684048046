import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import TabNavigation from "../../../components/tab_navigation/tab_navigation";
import { AppRoutes } from "../../../routes";
import { GetCloseAction } from "../../../utils";
import { DeductionAuthorityDashboard } from "../pages/deduction_authority/universal_directory/deduction_authority";
import { DeductionEntityDetails } from "../pages/deduction_authority/dashboard/deduction_entity_dashboard";

export const DeductionAuthorityNav = (props: RouteComponentProps<any>) => {
  const { history, location } = props;
  const { t } = useTranslation();

  return (
    <TabNavigation
      activeRoute={location.pathname}
      tabList={[
        {
          title: "universal_directory",
          route: `${AppRoutes.deductionAuthoritiyPage}`,
        },
      ]}
      onChange={(value) =>
        history.push({ pathname: value, state: location.state })
      }
      actions={
        <GetCloseAction
          name={t("close")}
          onClick={() => history.push(AppRoutes.backOfficePage)}
        />
      }
    >
      <Switch>
        <Route
          exact
          path={`${AppRoutes.deductionAuthoritiyPage}`}
          component={DeductionAuthorityDashboard}
        />
        <Route
          path={"/"}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: AppRoutes.deductionAuthoritiyPage,
                state: { from: location },
              }}
            />
          )}
        />
      </Switch>
    </TabNavigation>
  );
};

export const DeductionAuthorityDetailsNav = (
  props: RouteComponentProps<any>
) => {
  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();
  return (
    <TabNavigation
      activeRoute={location.pathname}
      tabList={[
        {
          title: "dashboard",
          route: `${AppRoutes.deductionAuthoritiyDetailedPage}/${params.id}/dashboard`,
        },
      ]}
      onChange={(value) =>
        history.push({ pathname: value, state: location.state })
      }
      actions={
        <GetCloseAction
          name={t("close")}
          onClick={() => history.push(AppRoutes.deductionAuthoritiyPage)}
        />
      }
    >
      <Switch>
        <Route
          exact
          path={`${AppRoutes.deductionAuthoritiyDetailedPage}/:id/dashboard`}
          component={DeductionEntityDetails}
        />
        <Route
          path={"/"}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: `${location.pathname}/dashboard`,
                state: { ...(location.state as any) },
              }}
            />
          )}
        />
      </Switch>
    </TabNavigation>
  );
};
