import { combineReducers } from "@reduxjs/toolkit";
import { companyDetailsReducers } from "./details";
import { companiesUniversalDirectoryReducers } from "./universal_directory";
import {companyJobOrderSlice} from "./job_order/job_order_reducer";
import {companyInfoReducers} from "./company_info";


export const companiesReducers = combineReducers({
    'universalDirectory': companiesUniversalDirectoryReducers,
    [companyJobOrderSlice.name]: companyJobOrderSlice.reducer,
    'details': companyDetailsReducers,
    'companyInfo': companyInfoReducers

});

export * from './universal_directory';
export * from './company_info';
export * from './details';

export * from './job_order/job_order_thunk';
export * from './job_order/job_order_selector';


