import "./multi_option_section.scss";
import { THEME } from "../../enums";
import { CloseNormalIcon, PlusWhiteIcon } from "../../icons";
import { ISkillOption } from "../../interfaces";
import { CustomButton, getPlusBtnIcon } from "../../utils";

interface Props {
  onClick: () => void;
  headerTitle: string;
  actionName: string;
  list: Array<ISkillOption>;
  actions?: React.ReactNode;
  onRemove?: (value: ISkillOption) => void;
  onSelect?: (value: ISkillOption) => void;
  customStyle?: React.CSSProperties;
  hideAddButton?: boolean;
}

const MultiOptionSection: React.FunctionComponent<Props> = (props) => {
  return (
    <div className="cd-cs-covv-section" style={{ ...props.customStyle }}>
      <div className="cd-cs-covv-header">
        <div className="cd-cs-covv-header-title-txt">
          <span>{props.headerTitle}</span>
        </div>
        {!props.hideAddButton && (
          <div className="cd-cs-covv-header-action">
            <CustomButton
              leftIcon={getPlusBtnIcon()}
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={props.actionName}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={props.onClick}
            />
          </div>
        )}
      </div>
      <div className="cd-cs-covv-content">
        {props.list.map((doc, index) => {
          return (
            <div key={index} className="custom-chip">
              <div className="chip-title">
                <span>
                  <button
                    onClick={() => (props.onSelect ? props.onSelect(doc) : {})}
                  >
                    {doc.value}
                  </button>
                </span>
              </div>

              {props.actions ?? (
                <button
                  className="chip-action"
                  onClick={() => (props.onRemove ? props.onRemove(doc) : {})}
                >
                  <CloseNormalIcon
                    width={"100%"}
                    height={"100%"}
                    style={{ color: THEME.defaultHighLightColor }}
                  />
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiOptionSection;
