
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities } from "./employee_deduction_reducer";

const select = (state: IRootState) => state.adminCenter.adminCenterBackOffice.EmployeeDeductionList;
export const selectEmployeeDeductionListState = createSelector(select, selectState);
export const selectEmployeeDeductionList = createSelector(
    select,
    selectAll
);
export const selectEmployeeDeductionListEntities = createSelector(
    select,
    selectEntities
);