import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";
import { AdminCenterAPI } from "../../../apis";

export const getWorkCompConfigDropdown = createAsyncThunk<
    Array<{ id: string, work_comp_code: string }>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/work_comp_config_dropdown/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getWorkCompConfigDropdown();
            if (typeof res.data === 'string') return [];
            return res.data as Array<{ id: string, work_comp_code: string }>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);