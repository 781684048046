import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { AdminCenterAPI } from "../../../../apis";


export const getEmployeeContribution = createAsyncThunk<
    Array<AdminCenterBackOfficeType>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/back_office/employee_contribution/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getEmployeeContributionDetails();
            return res.data as AdminCenterBackOfficeType[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createEmployeeContribution = createAsyncThunk<
    string,
    { 'value': string, 'description': string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/employee_contribution/post',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.createEmployeeContributionDetails(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );

export const updateEmployeeContribution = createAsyncThunk<
    string,
    { 'id': string, 'value': string, 'description': string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/employee_contribution/patch',
        async (data, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.updateEmployeeContributionDetails(data);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );

export const deleteEmployeeContribution = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
        '@admin_center/back_office/employee_contribution/delete',
        async (option_id, thunkAPI) => {
            try {
                const res = await AdminCenterAPI.deleteEmployeeContributionDetails(option_id);
                return res.data as string;
            } catch (error) {
                return catchRequestError(error, thunkAPI);
            }
        }
    );
