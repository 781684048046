import { FILE_ICONS } from '../../file_icons';
import './resume_holder.scss';


interface Props {
    path: string,
    onClick: () => void,
}

const ResumeHolder: React.FunctionComponent<Props> = (props) => {
    const pathSplit = props.path.split('/');
    const fileName = pathSplit[pathSplit?.length - 1];
    const nameList = fileName.split('.');
    const type = nameList[nameList?.length - 1].toLowerCase();
    return (
        <button className="resume-holder-container" onClick={props.onClick} title={props.path}>
            <div className="icon-holder">
                <img src={FILE_ICONS[type]} width={"100%"} height={"100%"} style={{ objectFit: 'cover' }} />
            </div>
            <div className="file-name-txt">
                <span>{props.path}</span>
            </div>
        </button>
    );
}

export default ResumeHolder;