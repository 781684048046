import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./departments_list_reducer";

const select = (state: IRootState) => state.selectors.departmentsDropdown;
export const selectDepartmentsDropdownListState = createSelector(select, selectState);
export const selectDepartmentsDropdownList = createSelector(
    select,
    selectAll
);

export const selectDepartmentsDropdownListEntities = createSelector(
    select,
    selectEntities
);