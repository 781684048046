import {createAsyncThunk} from '@reduxjs/toolkit';
import { AdminCenterAPI } from "../../../apis";
import { AppError, Agency } from "../../../interfaces";
import { catchRequestError } from "../../../utils";


export const getAgencyDetails = createAsyncThunk<
    Agency,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >('@admin_center/system_configuration/agency_details/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getAgencyDetails();
            return res.data as Agency;
        }
        catch (error) {
            return catchRequestError(error, thunkAPI);
        }
})

export const updateAgencyDetails = createAsyncThunk<
    string,
    Agency,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >('@admin_center/system_configuration/agency_details/update',
    async (agency, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateAgencyDetails(agency);
            return res.data as string;
        }
        catch (error) {
            return catchRequestError(error, thunkAPI);
        }
})