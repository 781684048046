import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getRoleUsersList, postSnackbarMessage, selectRoleUsersList, selectRoleUsersListState, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import ApiError from "../../../../components/api_error";
import { CustomButton } from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import { createMyProfileTeamMembersDetailsById, getMyProfileTeamMembersDetailsById } from "../../../../redux/my_profile";
import { MyProfileTeamsDetailsState, clearCreateMyProfileTeamState, clearMyProfileTeamError } from "../../../../redux/my_profile/my_profile_teams/my_profile_teams_details/my_profile_teams_details_reducer";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { RoleManagementIcon } from "../../../../icons";

interface AddTeamMemberProps {
    visible: boolean,
    teamId: string,
    myProfileTeamState: MyProfileTeamsDetailsState,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

export const AddTeamMember = (props: AddTeamMemberProps) => {
    const {
        visible,
        myProfileTeamState,
        teamId,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [addMember, setAddMember] = useState<string[]>([]);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const actionRef = useRef<ActionDialogHolderType>(null);
    const usersList = useAppSelector((state) => selectRoleUsersList(state));

    useEffect(()=>{
        dispatch(getRoleUsersList())
    },[])

    useEffect(() => {
        if(myProfileTeamState?.createMyProfileTeams?.loading === LoadingType.succeeded){
            if(snackBarMessage!="")
            dispatch(postSnackbarMessage(snackBarMessage ?? null));
        }
    }, [myProfileTeamState?.createMyProfileTeams?.loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"30%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={onClose}>
                <div className="add-team-member-container">
                    <div className="add-role-container">
                        <FormDialogTilteHeader
                            title={`${t('add_teammate')}`}
                            titleIcon={<RoleManagementIcon width={"100%"} height={"100%"} />}
                        />
                        <div className="add-role-content">
                            <CustomMultiFormSelect
                                label={t("add_member")}
                                name={"add_member"}
                                list={usersList?.map((doc) => ({
                                    label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                                    value: doc.id, 
                                    // isDisabled: myProfileTeamState?.myProfileTeams?.response.members_details?.map((doc)=>doc.id).includes(doc.id)
                                    isDisabled: myProfileTeamState?.myProfileTeams?.response?.members_ids?.includes(doc.id)
                                }))}
                                onChange={setAddMember}
                                required={true}
                                placeholder={t("select")}
                                value={addMember}
                                // isOptionSelected={(option)=> myProfileTeamState?.myProfileTeams.response.members_details.map((doc)=>doc.id).includes(option.value)}

                            />
                            {myProfileTeamState?.createMyProfileTeams?.error && <div className={"error-section"}>
                                <ApiError message={myProfileTeamState?.createMyProfileTeams?.error?.message} onClose={onClose} />
                            </div>}
                        </div>
                        <div className="add-role-action-holder">
                            <div className="add-role-action">
                                <div className="btn-no">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={t('cancel')}
                                        enable={true}
                                        backgroundColor={THEME.secondaryColor4}
                                        onClick={() => closePopup(onClose)}
                                    />
                                </div>
                                <div className="btn-yes">
                                    <CustomButton
                                        loading={myProfileTeamState?.createMyProfileTeams?.loading === LoadingType.pending}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={t('save')}
                                        enable={!!addMember.length}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={addNewTeamMember}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>

    );

    function addNewTeamMember() {
        if (addMember != null) {
            dispatch(createMyProfileTeamMembersDetailsById({ teamId: teamId, teamMemberIds: addMember }))
        }
        setSnackBarMessage("Team Member Added Successfully!");
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}
export default AddTeamMember;
