import { ArrowUpward } from '@material-ui/icons';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../components/search_bar';
import SortableTable, { TableData } from '../../../../components/sortable_table/sortable_table';
import TableEmpty, { TableLoading, TableErrorHandler, TableFilterEmpty } from '../../../../components/table_empty/table_empty';
import { LoadingType, THEME } from '../../../../enums';
import { TalentActiveStatusIcon, LockOpenIcon } from '../../../../icons';
import { ITalent } from '../../../../interfaces';
import {
    selectTalentListState,
    selectTalentList,
    getTalentList,
    useAppDispatch,
    useAppSelector
} from '../../../../redux/store';
import { CustomCheckBox } from '../../../../utils';
import { getDaysAgoWithDateAMPM } from '../../../../variables';
import './search_talent.scss';

const tableHeader = [
    { title: '', code: '' },
    { title: 'name', code: 'first_name' },
    { title: 'ssn_hash', code: 'last_4_digits_of_ssn' },
    { title: '', code: '' },
    { title: '', code: '' },
    { title: '', code: '' },
];


interface Props {
    selected: { id: string, name: string } | null,
    onChange: (value: { id: string, name: string } | null) => void,
}

const SearchTalent: React.FunctionComponent<Props> = (props) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const { t } = useTranslation();
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const talentListState = useAppSelector((state) => selectTalentListState(state));
    const talentList = useAppSelector((state) => selectTalentList(state));

    useEffect(() => {
        getTalents();
        return () => {
        }
    }, [])

    function getTalents() {
        dispatch(getTalentList());
    }

    function getFilteredList(): ITalent[] {
        let sortedList: ITalent[] | undefined;
        if (sortedField != null) {
            sortedList = [...talentList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (sortedList ?? talentList).filter(doc => {
            const str = search.trim().toLowerCase();
            const nameFilter = `${doc.first_name ?? ''} ${doc.middle_name ?? ''} ${doc.last_name}`.trim().toLowerCase().includes(str);
            const cityFilter = doc.city ? doc.city.trim().toLowerCase().includes(str) : false;
            const stateFilter = doc.state ? doc.state.trim().toLowerCase().includes(str) : false;
            const zipcodeFilter = doc.zip_code ? doc.zip_code.trim().toLowerCase().includes(str) : false;
            return nameFilter || cityFilter || stateFilter || zipcodeFilter;
        });
    }


    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            // setSortedField(null);
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    }




    if (talentListState.loading === LoadingType.pending) {
        return (
            <div className="srch-talent-holder">
                <TableLoading />
            </div>
        );
    }
    if (talentListState.error != null && talentList.length === 0) {
        return (
            <div className="srch-talent-holder">
                <TableErrorHandler error={talentListState.error} onRefresh={getTalents} />
            </div>
        );
    }
    if (talentList.length === 0) {
        return (
            <div className="srch-talent-holder">
                <TableEmpty title={t('no_talent_found')} onClick={() => getTalents()} />
            </div>
        );
    }

    return (
        <div className="srch-talent-holder">
            <div className="srch-tlnt-ud-table-search-bar">
                <SearchBar value={search} onChange={(value) => setSearch(value)} onSearch={() => { }} />
            </div>
            <div className="srch-table-holder">
                {getFilteredList().length !== 0 ?

                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList().map((doc, index) => {
                            return (
                                <tr key={doc.id} onClick={() => handleRowClick(doc)}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                        <CustomCheckBox
                                            name={""}
                                            title={""}
                                            checked={doc.id === props.selected?.id}
                                            onClick={() => props.onChange({ id: doc.id, name: `${doc.first_name ?? ''} ${doc.middle_name ?? ''} ${doc.last_name ?? ''}` })}
                                        />
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }}>{getNameField(doc)}</TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}>{doc.last_4_digits_of_ssn ? getSSNInfo(doc.last_4_digits_of_ssn) : ''}</TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>{''}</TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}>{''}</TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>{''}</TableData>
                                </tr>
                            );
                        })}
                    </SortableTable>
                    :
                    <div className="srch-talent-holder">
                        <TableFilterEmpty title={t('no_talent_found')} />
                    </div>
                }
            </div>
        </div>

    )

    function handleRowClick(doc: ITalent) {
        if (props.selected && props.selected.id === doc.id) {
            props.onChange(null);
        } else {
            props.onChange({ id: doc.id, name: `${doc.first_name ?? ''} ${doc.middle_name ?? ''} ${doc.last_name ?? ''}` });
        }
    }

    function getNameField(doc: ITalent) {
        return (
            <div className="srch-tlnt-ud-table-name">
                <div className="status-icon">
                    <TalentActiveStatusIcon
                        width={"100%"}
                        height={"100%"}
                        style={{ color: (doc.on_assignment_company != null) ? THEME.buttonColor13 : THEME.statusDisabledColor }}
                    />
                </div>
                <div className="name-txt">
                    <span>{`${doc.first_name ?? ''} ${doc.middle_name ?? ''} ${doc.last_name ?? ''}`}</span>
                </div>
            </div>
        )
    }


    function getSSNInfo(value: string) {
        return (
            <div className="ssn-holder">
                <div className="ssn-number">
                    <span>{value}</span>
                </div>
                <button className="loc-open-icon">
                    <LockOpenIcon width={"100%"} height={"100%"} style={{ color: '#00A4DC' }} />
                </button>
            </div>
        )
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 3;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        return 1;
    }
}

export default SearchTalent;