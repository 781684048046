import {combineReducers} from "@reduxjs/toolkit";
import {jobDocumentSlice} from "./document_list/document_list_reducer";
import { jobDocumentSendByEmailSlice } from "./document_send_by_email/send_by_email_reducer";


export const JobDocumentReducer = combineReducers({
    [jobDocumentSlice.name]: jobDocumentSlice.reducer,
    [jobDocumentSendByEmailSlice.name]: jobDocumentSendByEmailSlice.reducer,
})

export * from "./document_list/document_list_thunk";
export * from "./document_list/document_list_selector";

export * from "./document_send_by_email/send_by_email_thunk";
export * from "./document_send_by_email/send_by_email_selector";

