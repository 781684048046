import SectionCard from "../../../../../components/section_card/section_card";
import { EditIcon, TickGreenIcon, TickGreyIcon } from "../../../../../icons";
import { CustomButton, currencyConversion, formatPercentage, getPlusBtnIcon } from "../../../../../utils";
import { LoadingType, THEME } from "../../../../../enums";
import { AppError } from "../../../../../interfaces";
import { ITalentBackOfficeDeduction, ITalentBackOfficePayrollSetup } from "../../../../../interfaces/talent_back_office";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { Portal } from "react-portal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddDeductionPopup from "../../../popups/add_deduction/add_deduction";
import { RouteComponentProps } from "react-router-dom";
interface Props {
    dedUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    formState: ITalentBackOfficePayrollSetup;
    onClearError: () => void;
    handleEditCancel?: () => void;
    isLoading?: boolean,
    currentLoginUserName: string;
    talentId: string;
    talentName: string;
    handleUpdateClearState: () => void;
    getTalentBOPayrollData: () => void;
    handleAddGarnishmentDeduction: (doc: ITalentBackOfficeDeduction, parent: string, index?: number, removeItem?: boolean) => void;
    history: RouteComponentProps['history'];
}

const garnishmentDeductionsTableHeader = [
    { title: 'deduction_name', code: 'deduction_value' },
    { title: 'amount_percentage', code: '' },
    { title: 'active', code: '' },
    { title: 'notes', code: 'note' },
    { title: '', code: '' },

]
const initialForm = {
    deduction_id: "",
    deduction_value: "",
    deduction_amount: 0,
    deduction_percentage: 0,
    deduction_start_date: 0,
    deduction_end_date: 0,
    deduction_institution_id: "",
    deduction_institution_value: "",
    deduction_reference: 0,
    deduction_pay_period_limit: 0,
    deduction_monthly_limit: 0,
    deduction_annual_limit: 0,
    deduction_life_time_limit: 0,
    deduction_expires: false,
    is_active: false,
    is_deduction_end: false,
    note: "",
    case_number: "",
}

const GarnishmentDeductions: React.FC<Props> = props => {
    const {
        formState,
        onClearError,
        currentLoginUserName,
        talentId,
        talentName,
        handleUpdateClearState,
        getTalentBOPayrollData,
        handleAddGarnishmentDeduction,
        dedUpdateState
    } = props;
    const { t } = useTranslation();
    const parentField = "garnishment_deduction"
    const [addGDeduction, setAddGDeduction] = useState<boolean>(false);
    const [gDeductionSorted, setGDeductionSorted] = useState<string | null>(null);
    const [editIndex, setEditIndex] = useState<number | undefined>();
    const [gDeductionFormState, setGDeductionFormState] = useState<ITalentBackOfficeDeduction>(initialForm);

    function handleDSortFieldChange(value: string) {
        if (gDeductionSorted && gDeductionSorted === value) {
            setGDeductionSorted(null);
        } else {
            setGDeductionSorted(value);
        }
    }
    function getDFilteredList(): ITalentBackOfficeDeduction[] {
        let sortedList: ITalentBackOfficeDeduction[] | undefined;
        if (gDeductionSorted != null) {
            sortedList = [...(formState.garnishment_deduction ?? [])].sort((a, b) => {
                if (a[gDeductionSorted] < b[gDeductionSorted]) {
                    return -1;
                }
                if (a[gDeductionSorted] > b[gDeductionSorted]) {
                    return 1;
                }
                return 0;
            });
        }
        return (sortedList ?? (formState.garnishment_deduction ?? []));
    }

    function handleFormFieldChange(fieldId: string, value: any, parent: string) {
        if (fieldId === "deduction_percentage") {
            if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
                setGDeductionFormState({
                    ...gDeductionFormState,
                    [fieldId]: value,
                });
            }
        }
        else {
            setGDeductionFormState({
                ...gDeductionFormState,
                [fieldId]: value,
            });
        }

    };
    const handleClose = () => {
        setAddGDeduction(false);
        setGDeductionFormState(initialForm);
        setEditIndex(undefined);
    }
    const handleOnSuccessClose = () => {
        handleClose();
        handleUpdateClearState();
        getTalentBOPayrollData();
    }
    const handleEdit = (val: ITalentBackOfficeDeduction, index: number) => {
        setAddGDeduction(true);
        setGDeductionFormState({
            ...val,
            deduction_end_date: val.deduction_expires ? val.deduction_end_date : 0
        });
        setEditIndex(index);
    };
    const handleSave = () => {
        if (JSON.stringify(gDeductionFormState) === JSON.stringify(initialForm)) {
            handleClose();
        }
        else {
            handleAddGarnishmentDeduction(gDeductionFormState, parentField, editIndex);
        }
    }

    // function handleGoToDeductionHistory(talent_id: string, talent_name: string) {
    //     props.history.push({
    //         pathname: `${AppRoutes.employeePortalDetailsPage}/${talent_id}`,
    //         state: {
    //             id: talent_id,
    //             name: talent_name,
    //         }
    //     })
    // }

    return (
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('garnishment_deductions')} >
            <div className="ded-container">
                <div className="ded-header">
                    <div className="section-header">{t("garnishment_deduction_setup")}</div>
                    <div><CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("add_garnishment_deduction")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => setAddGDeduction(true)}
                    /></div>
                </div>
                <div className="ded-content">
                    <div className="ded-content-table">
                        <SortableTable
                            headerList={garnishmentDeductionsTableHeader}
                            sortedField={gDeductionSorted}
                            onSortChange={handleDSortFieldChange}
                            flexNumber={getFlexNumber}
                        >
                            {getDFilteredList().map((doc, index) => {
                                return (
                                    <tr key={index} onClick={() => { }}>
                                        <TableData customStyle={{ flex: getFlexNumber(0) }}>{doc.deduction_value}</TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.deduction_amount && doc.deduction_amount !== null
                                            ? currencyConversion(doc.deduction_amount)
                                            : formatPercentage(doc.deduction_percentage)}</span></TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.is_active ?
                                            <div className="tick-icon">
                                                <TickGreenIcon width={"100%"} height={"100%"} />
                                            </div> : <div className="tick-icon">
                                                <TickGreyIcon width={"100%"} height={"100%"} />
                                            </div>}</span></TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(3) }}><span>
                                            {doc.note && doc.note.length > 50 ? doc.note.substring(0, 50) + "..." : doc.note}

                                        </span></TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                            <span>
                                                <button
                                                    className="icon-btn tdp-edit-icon"
                                                    onClick={() => handleEdit(doc, index)}
                                                >
                                                    <EditIcon
                                                        width={"100%"}
                                                        height={"100%"}
                                                        style={{ color: "#fff" }}
                                                    />
                                                </button>
                                            </span>
                                        </TableData>

                                    </tr>
                                );
                            })}
                        </SortableTable>
                    </div>
                </div>
                {/* <div className="gar-deduction-history-label" onClick={()=>handleGoToDeductionHistory(talentId, talentName)}><span>{t("garnishment_deduction_history")}</span></div> */}
            </div>
            {addGDeduction &&
                <Portal>
                    <AddDeductionPopup
                        visible={addGDeduction}
                        title={t("hi_name_add_garnishment_deduction", { name: currentLoginUserName })}
                        successTitle={t("garnishment_deduction_backoffice_profile")}
                        onClose={handleClose}
                        onSuccessClose={handleOnSuccessClose}
                        onDissmiss={handleClose}
                        deductionFormState={gDeductionFormState}
                        handleFormFieldChange={handleFormFieldChange}
                        handleSave={handleSave}
                        currentLoginUserName={currentLoginUserName}
                        index={editIndex}
                        talentName={talentName ? talentName.split(" ")[0] : ""}
                        parentField={parentField}
                        dedUpdateState={dedUpdateState}
                        handleClearError={onClearError}
                    />
                </Portal>}
        </SectionCard>
    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1.4;
        if (value === 1) return 1;
        if (value === 2) return 0.4;
        if (value === 3) return 1.5;
        if (value === 4) return 0.3;
        if (value === 5) return 0.5;


        return 1;
    }
}

export default GarnishmentDeductions;