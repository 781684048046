import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./open_job_orders_report_thunk";
import { IRCOpenJobOrders } from "../../../../interfaces/report_center";

export interface OpenJobOrdersReportListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCOpenJobOrders | null
}

const initialState: OpenJobOrdersReportListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const openJobOrdersReportListSlice = createSlice({
    name: 'openJobOrdersReportList',
    initialState: initialState,
    reducers: {
        clearOpenJobOrdersReportListError(state) {
            return { ...state, error: null };
        },
        clearOpenJobOrdersReportState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getOpenJobOrdersReportList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getOpenJobOrdersReportList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getOpenJobOrdersReportList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    },
});

export const { clearOpenJobOrdersReportListError, clearOpenJobOrdersReportState } = openJobOrdersReportListSlice.actions;
export const selectState = (state: OpenJobOrdersReportListState) => state;
export const selectLoading = (state: OpenJobOrdersReportListState) => state.loading;
export const selectError = (state: OpenJobOrdersReportListState) => state.error;



