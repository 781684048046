import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import { selectState, selectSingleState, selectSingleInterviewQuestionsInitialState } from "./interview_questions_reducer";

const select = (state: IRootState) => state.talent.details.interviews.inHouseInterviewQuestions;
export const selectInHouseInterviewsQuestionsState = createSelector(select, selectState);
export const selectSingleInHouseInterviewsQuestionsStateById = (id: string) => createSelector(
    select, 
    (state) => {
        if (id && state[id]) return selectSingleState(state, id);
        return selectSingleInterviewQuestionsInitialState();
    }
);
