import { combineReducers, configureStore, } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { usersListSlice } from './user_management/users_list/users_list_reducer';
import { userDetailsSlice } from './user_management/user_details/user_details_reducer';
import { manageUserSlice } from './user_management/manage_user/manage_user_reducer';
import { skillListSlice } from './system_configuration/skill_list/skill_list_reducer';
import {
    engagementActivityMethodListSlice
} from "./system_configuration/engagement_activity_methods/engagement_activity_method_list_reducer";
import { successProfileQuestionListSlice } from "./system_configuration/success_profile_questions/success_profile_questions_list_reducer";

import { engagementActivityTypeListSlice } from "./system_configuration/engagement_activity_types/engagement_activity_type_list_reducer";
import { salesCallJourneyMethodSlice } from './system_configuration/sales_call_journey_methods/sales_call_jouney_method_reducer'
import { serviceProfileListSlice } from './system_configuration/service_profile/service_profile_list_reducer';
import {
    marketingCampaignJourneyStepSlice
} from "./system_configuration/marketing_campaign_journey_steps/marketing_campaign_journey_steps_reducer";
import { businessSectorSlice } from "./system_configuration/business_sector/business_sector_reducer";
import { statusCodeSlice } from "./system_configuration/status_code/status_code_reducer";
import { contactRoleSlice } from "./system_configuration/contact_role/contact_role_reducer";
import { checkDeliverySlice } from "./system_configuration/check_delivery/check_delivery_reducer";
import { onboardingStepSlice } from "./system_configuration/onboarding_step/onboarding_step_reducer";
import { serviceCallSlice } from "./system_configuration/service_call/service_call_reducer";
import {
    trafficLightEngagementMetricSlice
} from "./system_configuration/traffic_light_engagement_metric/traffic_light_engagement_metric_reducer";
import {
    successProfileQuestionStepSlice
} from "./system_configuration/success_profile_question_steps/success_profile_question_steps_reducer";
import { companyStatusSlice } from "./system_configuration/company_status/company_status_reducer";
import { contactStatusSlice } from "./system_configuration/contact_status/contact_status_reducer";
import { talentStatusSlice } from "./system_configuration/talent_status/talent_status_reducer";
import { jobStatusSlice } from "./system_configuration/job_status/job_status_reducer";
import { contactMethodSlice } from "./system_configuration/contact_method/contact_method_reducer";
import { endAssignmentReasonSlice } from "./system_configuration/end_assignment_reasons/end_assignment_reasons_reducer";
import { jobOrderPlacementSlice } from "./system_configuration/job_order_placement/job_order_placement_reducer";
import {
    talentInterviewStatusSlice
} from "./system_configuration/talent_interview_status/talent_interview_status_reducer";
import {
    talentInterviewQuestionSlice
} from "./system_configuration/talent_interview_questions/talent_interview_questions_reducer";
import {
    passwordExpirationDaysSlice,
} from "./system_configuration/security_policy/security_policy_reducer";
import { jobOrderSourceSlice } from "./system_configuration/job_order_source/job_order_source_reducer";
import { workCompCodeSlice } from "./system_configuration/work_comp_code/work_comp_code_reducer";
import { payCycleSlice } from "./system_configuration/pay_cycle/pay_cycle_reducer";
import { payPeriodSlice } from "./system_configuration/pay_period/pay_period_reducer";
import { agencyDetailsSlice } from "./agency_setup/agency_setup_reducer";
import { agencyBranchDetailsSlice } from "./agency_setup/agency_branch/agency_branch_reducer";
import { assignmentTypeSlice } from "./system_configuration/assignment_type/assignment_type_reducer";
import { roleManagementReducers } from './role_management';
import { getAgencyOnboardingDataSlice } from './system_configuration/onboarding/onboarding_reducer';
import { textEmAllAuthTokenSlice } from './system_configuration/text_em_all/text_em_all_reducer';
import { otherDocsSlice } from './system_configuration/other_docs/other_docs_reducer';
import { teamManagementTeamsReducers } from './team_management';
import { toggleStatusSlice } from './user_management/toggle_status/toggle_status_reducer';
import { agencyProfilePictureSlice } from './agency_setup/agency_logo/agency_logo_reducer';
import { PayRollTaxConfigListSlice } from './payroll_tax_configuration/payroll_tax_configuration_reducer';
import { SalesTaxConfigListSlice } from './sales_tax_configuration/sales_tax_configuration_reducer';
import { WorkCompConfigListSlice } from './work_comp_configuration/work_comp_configuration_reducer';
import { adminCenterBackOfficeReducers } from './back_office';
import { agenciesListSlice } from './agency_management/agencies_list/agencies_list_reducer';
import { agenciesDetailsSlice } from './agency_management/agencies_details/agencies_details_reducer';
import { manageAgencySlice } from './agency_management/manage_agency/manage_agency_reducer';
import { agencyToggleStatusSlice } from './agency_management/agency_toggle_status/agency_toggle_status_reducer';
import { adminCenterInfoTextSlice } from './info_text/info_text_reducer';
import { rMInsuranceCompanyReducers } from './system_configuration/risk_management';
import { credentialStateSlice } from './integration_partners/master_tax/credentials/credentials_reducer';
import { integrationPartnerReducers } from './integration_partners';
import { WorkCompConfigDropdownSlice } from './work_comp_config_dropdown/work_comp_config_dropdown_reducer';
import { vacationConfigStateSlice } from './report_center/vacation_config_reducer';

export const adminCenterReducers = combineReducers({
    [usersListSlice.name]: usersListSlice.reducer,
    [userDetailsSlice.name]: userDetailsSlice.reducer,
    [toggleStatusSlice.name]: toggleStatusSlice.reducer,
    [manageUserSlice.name]: manageUserSlice.reducer,
    [skillListSlice.name]: skillListSlice.reducer,
    [engagementActivityMethodListSlice.name]: engagementActivityMethodListSlice.reducer,
    [successProfileQuestionListSlice.name]: successProfileQuestionListSlice.reducer,
    [engagementActivityTypeListSlice.name]: engagementActivityTypeListSlice.reducer,
    [salesCallJourneyMethodSlice.name]: salesCallJourneyMethodSlice.reducer,
    [serviceProfileListSlice.name]: serviceProfileListSlice.reducer,

    [marketingCampaignJourneyStepSlice.name]: marketingCampaignJourneyStepSlice.reducer,
    [businessSectorSlice.name]: businessSectorSlice.reducer,
    [statusCodeSlice.name]: statusCodeSlice.reducer,
    [contactRoleSlice.name]: contactRoleSlice.reducer,
    [checkDeliverySlice.name]: checkDeliverySlice.reducer,
    [onboardingStepSlice.name]: onboardingStepSlice.reducer,
    [serviceCallSlice.name]: serviceCallSlice.reducer,
    [trafficLightEngagementMetricSlice.name]: trafficLightEngagementMetricSlice.reducer,
    [successProfileQuestionStepSlice.name]: successProfileQuestionStepSlice.reducer,
    [companyStatusSlice.name]: companyStatusSlice.reducer,
    [contactStatusSlice.name]: contactStatusSlice.reducer,
    [talentStatusSlice.name]: talentStatusSlice.reducer,
    [jobStatusSlice.name]: jobStatusSlice.reducer,
    [contactMethodSlice.name]: contactMethodSlice.reducer,
    [endAssignmentReasonSlice.name]: endAssignmentReasonSlice.reducer,
    [jobOrderPlacementSlice.name]: jobOrderPlacementSlice.reducer,
    [talentInterviewStatusSlice.name]: talentInterviewStatusSlice.reducer,
    [talentInterviewQuestionSlice.name]: talentInterviewQuestionSlice.reducer,
    [passwordExpirationDaysSlice.name]: passwordExpirationDaysSlice.reducer,
    [jobOrderSourceSlice.name]: jobOrderSourceSlice.reducer,
    [workCompCodeSlice.name]: workCompCodeSlice.reducer,
    [payCycleSlice.name]: payCycleSlice.reducer,
    [payPeriodSlice.name]: payPeriodSlice.reducer,
    [agencyDetailsSlice.name]: agencyDetailsSlice.reducer,
    [agencyBranchDetailsSlice.name]: agencyBranchDetailsSlice.reducer,
    [assignmentTypeSlice.name]: assignmentTypeSlice.reducer,
    [getAgencyOnboardingDataSlice.name]: getAgencyOnboardingDataSlice.reducer,
    [textEmAllAuthTokenSlice.name]: textEmAllAuthTokenSlice.reducer,
    [otherDocsSlice.name]: otherDocsSlice.reducer,
    [agencyProfilePictureSlice.name]: agencyProfilePictureSlice.reducer,
    // [getAgencyOnboardingDataSlice.name]: getAgencyOnboardingDataSlice.reducer,
    // [textEmAllAuthTokenSlice.name]: textEmAllAuthTokenSlice.reducer,
    // [OtherDocsSlice.name]: OtherDocsSlice.reducer,
    [PayRollTaxConfigListSlice.name]: PayRollTaxConfigListSlice.reducer,
    [SalesTaxConfigListSlice.name]: SalesTaxConfigListSlice.reducer,
    [WorkCompConfigListSlice.name]: WorkCompConfigListSlice.reducer,
    [WorkCompConfigDropdownSlice.name]: WorkCompConfigDropdownSlice.reducer,
    [agenciesListSlice.name]: agenciesListSlice.reducer,
    [agenciesDetailsSlice.name]: agenciesDetailsSlice.reducer,
    [manageAgencySlice.name]: manageAgencySlice.reducer,
    [agencyToggleStatusSlice.name]: agencyToggleStatusSlice.reducer,
    [adminCenterInfoTextSlice.name]: adminCenterInfoTextSlice.reducer,
    "roleManagement": roleManagementReducers,
    "teamManagementTeams": teamManagementTeamsReducers,
    "adminCenterBackOffice": adminCenterBackOfficeReducers,
    "rMInsuranceCompany": rMInsuranceCompanyReducers,
    "integrationPartners": integrationPartnerReducers,
    [vacationConfigStateSlice.name]: vacationConfigStateSlice.reducer


});
//integration partners list
export * from "./integration_partners/index";
/// user management
/// users list
export * from './user_management/users_list/users_list_selector';
export * from './user_management/users_list/users_list_thunk';

/// user details
export * from './user_management/user_details/user_details_selector';
export * from './user_management/user_details/user_details_thunk';
/// manage user
export * from './user_management/manage_user/manage_user_selector';
export * from './user_management/manage_user/manage_user_thunk';
/// skill management
export * from './system_configuration/skill_list/skill_list_selector';
export * from './system_configuration/skill_list/skill_list_thunk';

// system configurtion
export * from './system_configuration/engagement_activity_methods/engagement_activity_method_list_thunk';
export * from './system_configuration/engagement_activity_methods/engagement_activity_method_list_selector';

export * from './system_configuration/success_profile_questions/success_profile_questions_list_selector';
export * from './system_configuration/success_profile_questions/success_profile_questions_list_thunk';

export * from './system_configuration/engagement_activity_types/engagement_activity_type_list_selector';
export * from './system_configuration/engagement_activity_types/engagement_activity_type_list_thunk';

export * from './system_configuration/sales_call_journey_methods/sales_call_journey_method_thunk';
export * from './system_configuration/sales_call_journey_methods/sales_call_journey_method_selector';

export * from './system_configuration/service_profile/service_profile_list_thunk';
export * from './system_configuration/service_profile/service_profile_list_selector';

export * from './system_configuration/marketing_campaign_journey_steps/marketing_campaign_journey_steps_thunk';
export * from './system_configuration/marketing_campaign_journey_steps/marketing_campaign_journey_steps_selector';

export * from './system_configuration/business_sector/business_sector_selector';
export * from './system_configuration/business_sector/business_sector_thunk';

export * from './system_configuration/status_code/status_code_thunk';
export * from './system_configuration/status_code/status_code_selector';

export * from './system_configuration/contact_role/contact_role_thunk';
export * from './system_configuration/contact_role/contact_role_selector';

export * from './system_configuration/check_delivery/check_delivery_thunk';
export * from './system_configuration/check_delivery/check_delivery_selector';

export * from './system_configuration/onboarding_step/onboarding_step_thunk';
export * from './system_configuration/onboarding_step/onboarding_step_selector';

export * from './system_configuration/service_call/service_call_thunk';
export * from './system_configuration/service_call/service_call_selector';

export * from './system_configuration/traffic_light_engagement_metric/traffic_light_engagement_metric_thunk';
export * from './system_configuration/traffic_light_engagement_metric/traffic_light_engagement_metric_selector';

export * from './system_configuration/success_profile_question_steps/success_profile_question_steps_thunk';
export * from './system_configuration/success_profile_question_steps/success_profile_question_steps_selector';

export * from './system_configuration/company_status/company_status_thunk';
export * from './system_configuration/company_status/company_status_selector';

export * from './system_configuration/contact_status/contact_status_thunk';
export * from './system_configuration/contact_status/contact_status_selector';

export * from './system_configuration/talent_status/talent_status_thunk';
export * from './system_configuration/talent_status/talent_status_selector';

export * from './system_configuration/job_status/job_status_thunk';
export * from './system_configuration/job_status/job_status_selector';

export * from './system_configuration/contact_method/contact_method_thunk';
export * from './system_configuration/contact_method/contact_method_selector';

export * from './system_configuration/end_assignment_reasons/end_assignment_reasons_thunk';
export * from './system_configuration/end_assignment_reasons/end_assignment_reasons_selector';

export * from './system_configuration/job_order_placement/job_order_placement_thunk';
export * from './system_configuration/job_order_placement/job_order_placement_selector';

export * from './system_configuration/talent_interview_status/talent_interview_status_thunk';
export * from './system_configuration/talent_interview_status/talent_interview_status_selector';

export * from './system_configuration/talent_interview_questions/talent_interview_questions_thunk';
export * from './system_configuration/talent_interview_questions/talent_interview_questions_selector';

export * from './system_configuration/security_policy/security_policy_thunk';
export * from './system_configuration/security_policy/security_policy_selector';

export * from './system_configuration/job_order_source/job_order_source_thunk';
export * from './system_configuration/job_order_source/job_order_source_selector';

export * from './system_configuration/work_comp_code/work_comp_code_thunk';
export * from './system_configuration/work_comp_code/work_comp_code_selector';

export * from './system_configuration/pay_cycle/pay_cycle_thunk';
export * from './system_configuration/pay_cycle/pay_cycle_selector';

export * from './system_configuration/pay_period/pay_period_thunk';
export * from './system_configuration/pay_period/pay_period_selector';

export * from './agency_setup/agency_setup_thunk';
export * from './agency_setup/agency_setup_selector';

export * from './agency_setup/agency_branch/agency_branch_thunk';
export * from './agency_setup/agency_branch/agency_branch_selector';

export * from "./system_configuration/assignment_type/assignment_type_thunk";
export * from "./system_configuration/assignment_type/assignment_type_selector";


export * from "./system_configuration/onboarding/onboarding_thunk";
export * from "./system_configuration/onboarding/onboarding_selector";

export * from "./system_configuration/text_em_all/text_em_all_thunk";
export * from "./system_configuration/text_em_all/text_em_all_selector";

export * from "./system_configuration/other_docs/other_docs_thunk";
export * from "./system_configuration/other_docs/other_docs_selector";

export * from "./user_management/toggle_status/toggle_status_thunk";
export * from "./user_management/toggle_status/toggle_status_selector"

export * from "./payroll_tax_configuration/payroll_tax_configuration_thunk"
export * from "./payroll_tax_configuration/payroll_tax_configuration_selector"

export * from "./sales_tax_configuration/sales_tax_configuration_thunk"
export * from "./sales_tax_configuration/sales_tax_configuration_selector"

export * from "./work_comp_configuration/work_comp_configuration_thunk"
export * from "./work_comp_configuration/work_comp_configuration_selector";

export * from "./agency_management/agencies_list/agencies_list_thunk";
export * from "./agency_management/agencies_list/agencies_list_selector";

export * from "./agency_management/agencies_details/agencies_details_thunk";
export * from "./agency_management/agencies_details/agencies_details_selector";

export * from "./agency_management/manage_agency/manage_agency_thunk";
export * from "./agency_management/manage_agency/manage_agency_selector";

export * from "./agency_management/agency_toggle_status/agency_toggle_status_thunk";
export * from "./agency_management/agency_toggle_status/agency_toggle_status_selector";

export * from "./info_text/info_text_thunk";
export * from "./info_text/info_text_selector";

export * from "./work_comp_config_dropdown/work_comp_config_dropdown_thunk";
export * from "./work_comp_config_dropdown/work_comp_config_dropdown_selector";


export * from "./user_management/toggle_status/toggle_status_thunk"
export * from "./user_management/toggle_status/toggle_status_selector"

export * from "./agency_setup/agency_logo/agency_logo_thunk";
export * from "./agency_setup/agency_logo/agency_logo_selector";

export * from './report_center/vacation_config_thunk';
export * from './report_center/vacation_config_selector';

