import { AppError, ITalent } from "../../../../interfaces";
import { CustomButton, FormInput, RegxPattern } from "../../../../utils";
import { useTranslation } from "react-i18next";
import ApiError from "../../../../components/api_error";
import { LoadingType, THEME } from "../../../../enums";
import "./create_login_form.scss";
import { LockIcon } from "../../../../icons";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { useEffect, useState } from "react";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import {
  createLoginForTalentThunkUser,
  getTalentById,
  selectProfileState,
  selectTalentDetailsByIdState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import {
  clearCreateLoginForTalentError,
} from "../../../../redux/talent/details/onboarding_and_docs/create_talent_login/create_talent_login_reducer";

interface Props {
  currentLoginUserName?: string;
  isFormVaid: boolean;
  loading: LoadingType;
  error?: AppError | null | undefined;
  onClearError: () => void;
  onSuccessClose: () => void;
  talentId: string;
}

const createLoginForTalent = [
  {
    field: "talentName",
    label: "talentName",
    secure: false,
    type: "text",
    required: true,
    placeholder: "talentName",
    pattern: "",
    keyboardtype: "",
  },
  {
    field: "email",
    label: "email",
    secure: false,
    required: true,
    type: "emailAddress",
    placeholder: "email",
    pattern: RegxPattern.email,
    keyboardtype: "",
  },
];

const CreateTalentAccountForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const talentState = useAppSelector((state) =>
    selectTalentDetailsByIdState(props.talentId)(state)
  );
  const [talentData, setTalentData] = useState<ITalent | null>();
  const profileState = useAppSelector((state) => selectProfileState(state));
  const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${
    profileState.profile?.last_name ?? ""
  }`;

  useEffect(() => {
    /// Get Talent Details
    if (props.talentId && props.talentId != null) {
      dispatch(getTalentById(props.talentId));
    }
    return () => {};
  }, []);
  useEffect(() => {
    if (talentState.loading === LoadingType.succeeded) {
      setTalentData(talentState.data);
    }
    return () => {};
  }, [talentState.loading]);
  const [formState, setFormState] = useState({
    talentId: "",
    talentName:
      talentState.data?.first_name + " " + talentState.data?.last_name ?? "",
    email: talentState.data?.email ?? "",
  });
  function getTalentDetails() {
    if (props.talentId && props.talentId != null) {
      dispatch(createLoginForTalentThunkUser(props.talentId));
      props.onClearError();
    }
  }

  function handleFieldChange(fieldId, value) {
    setFormState({ ...formState, [fieldId]: value });
  }

  function getTitle() {
    if (props.loading === LoadingType.succeeded)
      return `${t("login_creation")}`;
    return `${t("create_a_login_for_talent")}`;
  }

  function handleClearPopErrorError() {
    dispatch(clearCreateLoginForTalentError());
    props.onSuccessClose();
  }
  return (
    <div className="dshb-soa-f-container">
      <div className="dshb-soa-f-header">
        <FormDialogTilteHeader
          title={getTitle()}
          titleIcon={
            <LockIcon
              width={"100%"}
              height={"100%"}
              style={{ color: THEME.talentOptionTextColor }}
            />
          }
        />
      </div>
      <div className="dshb-soa-f-content">
        {getUI()}
        <div>
          {props.error && (
            <div className={"error-section"}>
              <ApiError
                message={props.error.message}
                onClose={props.onClearError}
              />
            </div>
          )}
        </div>
      </div>
      {getActions()}
    </div>
  );

  function getUI() {
    if (props.loading === LoadingType.succeeded) {
      return (
        <div className="dshb-soa-f-mark-success-holder">
          <DocSavedText>
            <span className="aknw-name">
              {t("congratulation") + " " + currentLoginUserName + "!"}
            </span>
            <span className="info-txt">{`${t(
              "a_new_login_has_been_created_for"
            )}`}</span>
            <span className="name">
              {talentData?.first_name +
                " " +
                talentData?.middle_name +
                " " +
                talentData?.last_name}
            </span>
          </DocSavedText>
        </div>
      );
    }

    return (
      <div>
        <div className="input-flx">
          <div>
            <FormInput
              label={t("talentName")}
              type={"text"}
              validations={formValidators}
              value={formState?.talentName}
              onChange={handleFieldChange}
              placeholder={""}
              id={"talentName"}
              disabled
            />
          </div>
          <div className="input-margin">
            <FormInput
              label={t("email")}
              validations={formValidators}
              type={"emailAddress"}
              value={formState?.email}
              onChange={handleFieldChange}
              placeholder={""}
              id={"email"}
              pattern={RegxPattern.email}
              disabled
            />
          </div>
        </div>
      </div>
    );
  }

  function getActions() {
    if (props.loading === LoadingType.succeeded) {
      return (
        <div className="dshb-soa-f-actions">
          <div className="btn-save">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("ok")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              // onClick={handleClearPopErrorError}
              onClick={props.onSuccessClose}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="dshb-soa-f-actions">
        <div className="btn-cancel">
          <CustomButton
            textStyle={{ textTransform: "capitalize" }}
            name={t("cancel")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            // onClick={props.onSuccessClose}
            onClick={handleClearPopErrorError}
          />
        </div>
        <div className="btn-save">
          <CustomButton
            loading={props.loading === LoadingType.pending}
            textStyle={{ textTransform: "capitalize" }}
            name={t("create")}
            backgroundColor={THEME.defaultHighLightColor}
            enable={true}
            onClick={getTalentDetails}
          />
        </div>
      </div>
    );
  }
  function formValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case createLoginForTalent[0].field: {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.username.test(value.text) === false)
          return t("validators.enterValidName");
        return null;
      }
      case createLoginForTalent[1].field: {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.email.test(value.text) === false)
          return t("validators.enterValidEmail");
        return null;
      }
      default: {
        return null;
      }
    }
  }
};

export default CreateTalentAccountForm;
