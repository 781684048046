import { useTranslation } from "react-i18next";
import { LoadingType, THEME } from "../../../../enums";
import { useState } from "react";
import ApiError from "../../../../components/api_error";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { PaperPlaneIcon } from "../../../../icons";
import { AppError } from "../../../../interfaces";
import { SendByEmail } from "../../../../interfaces/marketing_campaign";
import { RegxPattern, FormInput, CustomButton } from "../../../../utils";


interface EmailProps {
  DocumentId: string | undefined;
  loading: LoadingType;
  error: AppError | null | undefined;
  currentLoginUserName: string;
  onSubmit: (value) => void;
  onClearError: () => void;
  onSuccessClose: () => void;
}

const formOptions = {
  email: {
    field: "email_id",
    type: "email",
    label: "email",
    pattern: RegxPattern.email,
    secure: false,
    required: true,
    placeholder: "",
    keyboardtype: "",
  },
};

const SendByEmailForm: React.FC<EmailProps> = (props) => {

  const { t } = useTranslation();
  const [formState, setFormState] = useState<SendByEmail>({
    file_id: props.DocumentId ?? "",
    email_id: "",
  });

  function handleFieldChange(fieldId, value) {
    setFormState({ ...formState, [fieldId]: value });
  }

  function getTitle() {
    if (props.loading === LoadingType.succeeded)
      return `${t("sent_link_by_email")}`;
    return `${t("hi_lets_send_an_email_link", {
      name: props.currentLoginUserName,
    })}`;
  }

  return (
    <div className="dshb-soa-f-container">
      <div className="dshb-soa-f-header">
        <FormDialogTilteHeader
          title={getTitle()}
          subTitle={
            props.loading === LoadingType.succeeded
              ? undefined
              : t("to_whom_would_you_like_to_send_document_link")
          }
          titleIcon={
            <PaperPlaneIcon
              width={"100%"}
              height={"100%"}
              style={{ color: THEME.talentOptionTextColor }}
            />
          }
        />
      </div>
      <div className="dshb-soa-f-content">
        {getUI()}
        {props.error && (
          <div className={"error-section"}>
            <ApiError
              message={props.error.message}
              onClose={props.onClearError}
            />
          </div>
        )}
      </div>
      {getActions()}
    </div>
  )

  function getUI() {
    if (props.loading === LoadingType.succeeded) {
      return (
        <div className="dshb-soa-f-mark-success-holder">
          <DocSavedText>
            <span className="aknw-name">
              {t("well_done", { name: props.currentLoginUserName })}
            </span>
            <span className="info-txt">{`${t(
              "you_sent_an_document_link_to"
            )}:`}</span>
            <span className="email">{formState.email_id}</span>
          </DocSavedText>
        </div>
      );
    }

    return (
      <div className="dshb-soa-form">
        <div className="dshb-soa-f-column">
          <div
            key={formOptions.email.field}
            className={"dshb-soa-f-input-holder"}
          >
            <FormInput
              validations={formValidators}
              id={formOptions.email.field}
              onChange={handleFieldChange}
              secure={formOptions.email.secure}
              required={formOptions.email.required}
              placeholder={formOptions.email.placeholder}
              type={formOptions.email.type}
              value={formState[formOptions.email.field]}
              label={t(formOptions.email.label)}
              keyboardtype={formOptions.email?.keyboardtype}
            />
          </div>
        </div>
      </div>
    );
  }

  function formValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case formOptions.email.field: {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.email.test(value.text) === false)
          return t("validators.enterValidEmail");
        return null;
      }
      default: {
        return null;
      }
    }
  }

  function _isFormValid() {
    const { email_id } = formState;
    if (RegxPattern.email.test(email_id) === false) return false;
    return true;
  }

  function getActions() {
    if (props.loading === LoadingType.succeeded) {
      return (
        <div className="dshb-soa-f-actions">
          <div className="btn-save">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("ok")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={props.onSuccessClose}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="dshb-soa-f-actions">
        <div className="btn-save">
          <CustomButton
            loading={props.loading === LoadingType.pending}
            textStyle={{ textTransform: "capitalize" }}
            name={
              t("send")
            }
            enable={_isFormValid()}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={() =>
              props.onSubmit(formState)
            }
          />
        </div>
      </div>
    );
  }

}

export default SendByEmailForm