import { RouteComponentProps } from "react-router-dom";
import { ActionDialogHolder } from "../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper from "../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import { IUpdateReport } from "../../../interfaces/work_comp_audit_and_compliance_reporting";
import ReportForm from "../forms/report_form/report_form";
import { selectRiskMgmtInsuranceCompanies, selectRiskMgmtInsuranceCompanyState, selectWCCRDeleteDocState } from "../../../redux/risk_management";
import { useAppSelector } from "../../../redux/store";
import { RiskMgmtInsuranceCompany } from "../../../interfaces/risk_mgmt_insurance_company";

interface Props {
  visible: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (data : IUpdateReport, attachedDocs: Array<File>|null) => void;
  reportInfo?: IUpdateReport;
  state: {
    error?: AppError | null,
    loading: LoadingType,
    response: any,
  };
  history?: RouteComponentProps["history"];
}

const CreateReportPopup: React.FunctionComponent<Props> = (props) => {
  const { visible, onClose, onSubmit } = props;

  const insuranceCompanyState = useAppSelector(selectRiskMgmtInsuranceCompanyState);
  const insuranceCompanies: RiskMgmtInsuranceCompany[] = useAppSelector(selectRiskMgmtInsuranceCompanies);
  const wccrDeleteDocState = useAppSelector(selectWCCRDeleteDocState);

  const handleSubmit = (data : IUpdateReport, attachedDocs: Array<File>|null) => {
    onSubmit(data, attachedDocs);
  };

  return (
    <ActionDialogHolder
      visible={visible}
      wrapperWidth={"42%"}
      onClose={onClose}
      onDissmiss={onClose}
    >
      <DialogWrapper onClose={onClose}>
      <ReportForm
          title={props.title}
          onSubmit={handleSubmit}
          onClose={onClose}
          reportInfo={props?.reportInfo}
          state={props.state}
          history={props.history}
          insuranceCompanyState={insuranceCompanyState}
          insuranceCompanies={insuranceCompanies}
          wccrDeleteDocState={wccrDeleteDocState}
        />
      </DialogWrapper>
    </ActionDialogHolder>
  );
};

export default CreateReportPopup;