import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './back_office_direct_depost_reducer';
import { IRootState } from "../../../../store";

const select = (state: IRootState) => state.talent.details.backOfficePayrollSetUp.talentPRDirectDepositSetup;
export const selectTalentPRDDState = createSelector(select, reducer.selectState);
export const selectSingleTalentPRDDSStateById = (id: string | undefined | null) => createSelector(
    selectTalentPRDDState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleTalentPRSDDState(state, id);
        return reducer.selectInitialSingleTalentPRSDDState();
    }
);