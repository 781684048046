import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./paycheck_list_thunk";
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { PayCheck } from "../../../../interfaces/back_office_payroll";

export interface PayCheckState {
  error?: AppError | null;
  loading: LoadingType;
  response: PayCheck[] | null;
}

const initialState: PayCheckState = {
  error: null,
  loading: LoadingType.idle,
  response: null,
};

export const PayCheckSlice = createSlice({
  name: "PayCheck",
  initialState: initialState,
  reducers: {
    clearState(state) {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getPayCheckThunk.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getPayCheckThunk.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.getPayCheckThunk.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        response: action.payload,
      }))
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const PayCheckSliceActions = PayCheckSlice.actions;
export const selectState = (state: PayCheckState) => state;
export const selectAll = (state: PayCheckState) => state.response;
