
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { IRCPayrollJournal } from "../../../../interfaces/report_center_payroll_tax";
import * as thunk from "./payroll_journal_thunk";
import { createSlice } from "@reduxjs/toolkit";

export interface PayrollJournalListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IRCPayrollJournal | null,
}

const initialState: PayrollJournalListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const payrollJournalListSlice = createSlice({
    name: 'payrollJournalList',
    initialState: initialState,
    reducers: {
        clearPayrollJournalListError(state) {
            return { ...state, error: null };
        },
        clearPayrollJournalListState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPayrollJournalList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getPayrollJournalList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getPayrollJournalList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearPayrollJournalListError, clearPayrollJournalListState } = payrollJournalListSlice.actions;
export const selectState = (state: PayrollJournalListState) => state;


