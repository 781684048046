import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { THEME } from '../../../../../../enums';
import { PlusIcon, PlusWhiteIcon } from '../../../../../../icons';
import { selectSingleCompanyDepartmentsStructuresStateById } from '../../../../../../redux/store';
import { useAppSelector } from '../../../../../../redux/store';
import { CustomButton, getPlusBtnIcon } from '../../../../../../utils';
import * as MatIcons from '@material-ui/icons';
import { IconButton } from "@material-ui/core";
import './departments.scss';
import { useState } from 'react';
import { IDepartmentStructure } from '../../../../../../interfaces';

interface Props {
    onAddDepartment: () => void,
    onRemove: (value: { id: string, value: string }) => void,
    onDepartmentClick: (value: IDepartmentStructure) => void,
}

const Department: React.FunctionComponent<Props> = (props) => {
    const { params } = useRouteMatch();
    const companyDepartments = useAppSelector((state) => selectSingleCompanyDepartmentsStructuresStateById((params as { id: string, departmentId: string }).departmentId ?? (params as { id: string, departmentId: string }).id)(state));
    const { t } = useTranslation();

    return (
        <div className="cd-cs-dept-container">
            {/* <MultiOptionSection
                headerTitle={t('company_departments').toUpperCase()}
                actionName={t('add_department')}
                onClick={props.onAddDepartment}
                list={companyDepartments.data != null ? companyDepartments.data.map((doc, index) => ({ id: doc.id, value: doc.name })) : []}
                actions={(<></>)}
            // onRemove={props.onRemove}
            /> */}

            <div className="cd-cs-covv-dept-section">
                <div className="cd-cs-covv-dept-header">
                    <div className="cd-cs-covv-dept-header-title-txt">
                        <span>{t('company_departments').toUpperCase()}</span>
                    </div>
                    <div className="cd-cs-covv-dept-header-action">
                        <CustomButton
                            leftIcon={getPlusBtnIcon()}
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('add_department')}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={props.onAddDepartment}
                        />
                    </div>
                </div>
                <div className="cd-cs-covv-dept-content">
                    {(companyDepartments.data != null ? companyDepartments.data : []).map((doc, index) => {
                        return (
                            <DepartmentSection
                                key={index}
                                index={index}
                                doc={doc}
                                onClick={props.onDepartmentClick}
                            />

                        )
                    })}
                </div>
            </div>

        </div>
    )


}




function plusIcon() {
    return (
        <div className="btn-icon">
            <PlusIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
        </div>

    )
}

interface DepartmentSectionProps {
    index: number,
    doc: IDepartmentStructure,
    onClick: (value: IDepartmentStructure) => void
}

const DepartmentSection: React.FunctionComponent<DepartmentSectionProps> = (props: DepartmentSectionProps) => {
    const { index, doc, onClick } = props;
    const [expand, setExpand] = useState<boolean>();
    return (
        <div className="dept-section" style={{ borderTop: index === 0 ? 'none' : undefined }}>
            <div className="main-dept" >
                <div className="dept-add-icon">
                    {plusIcon()}
                </div>

                <button className="dept-custom-chip" onClick={() => onClick(doc)}>
                    <div className="dept-chip-title">
                        <span>{doc.department_name}</span>
                    </div>
                </button>
                <div className="dept-expnd-icon">
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setExpand(!expand)}
                    >
                        {expand ? <MatIcons.ExpandLess fontSize="small" color="inherit" /> : <MatIcons.ExpandMore fontSize="small" color="inherit" />}
                    </IconButton>
                </div>
            </div>

            {expand && childDeptNesting(doc.children)}

        </div>
    )


    function chipIcon(name) {
        return (
            <div className="dept-custom-chip">
                <div className="dept-chip-title">
                    <span>{name}</span>
                </div>
            </div>
        )
    }


    function childDeptNesting(list: IDepartmentStructure[]) {

        return (

            (list != null ? list : []).map((doc, index) => {
                return (
                    <div key={index} className="child-dept-section">
                        {<div className="child-dept-connector-line"></div>}
                        <div key={index} className="child-dept" >
                            <div className="dept-add-icon">
                                {plusIcon()}
                            </div>

                            <button className="dept-custom-chip" onClick={() => onClick(doc)}>
                                <div className="dept-chip-title">
                                    <span>{doc.department_name}</span>
                                </div>
                            </button>

                        </div>
                        <div className="inner-child-dept-section">
                            {doc.children && doc.children.length != 0 && childDeptNesting(doc.children)}
                        </div>
                    </div>

                )
            })



        )
    }
}



export default Department;