import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums"
import { AppError } from "../../../interfaces"
import * as thunk from './resume_file_thunk';


export interface SingleResumeFileState {
    response: string | null,
    error?: AppError | null,
    loading: LoadingType,
}

export interface resumeFileState {
    [key: string]: SingleResumeFileState
}


const initialState: resumeFileState = {}

export const resumeFileSlice = createSlice({
    name: 'resumeFile',
    initialState: initialState,
    reducers: {
        clearResumeFileState(state,action: PayloadAction <string>){
            return {...state,[action.payload]:{...state[action.payload],error:null,response:null,loading:LoadingType.idle}}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getResumeFileUrlForDownload.pending,
                (state, action) => ({
                    ...state,
                    [ action.meta.arg.id]: {
                        ...state[ action.meta.arg.id],
                        loading: LoadingType.pending,
                            response: null
                    }
                })
            )
            .addCase(
                thunk.getResumeFileUrlForDownload.rejected,
                (state, action) => ({
                    ...state,
                    [ action.meta.arg.id]: {
                        ...state[ action.meta.arg.id],
                        loading: LoadingType.failed,
                            error: action.payload,
                            response: null
                    }
                })
            )
            .addCase(
                thunk.getResumeFileUrlForDownload.fulfilled,
                (state, action) => ({
                    ...state,
                    [ action.meta.arg.id]: {
                        ...state[action.meta.arg.id],
                        loading: LoadingType.succeeded,
                            response: action.payload,
                            error: null
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});

export const resumeFileSliceActions = resumeFileSlice.actions;
export const selectresumeFileState = (state: resumeFileState) => state;
export const selectSingleResumeFileState = (state: resumeFileState, id: string): SingleResumeFileState => state[id];

export const selectInitialSingleResumeFileState = (): SingleResumeFileState => ({
    response: null,
    error: null,
    loading: LoadingType.idle
});