import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { IAgencies } from "../../../../interfaces/agency_management";

export const getAdminCenterAgencyManagementAgencyDetails = createAsyncThunk<
    IAgencies,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@admin_center/agency-management/agency_details/get',
    async (agencyId, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getAgencyById(agencyId);
            return res.data as IAgencies;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);