import * as MatIcons from "@material-ui/icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import { ROLE } from "../../enums";
import { getRoleUsersList, useAppDispatch, getCompetitorsList, getAccountManagers, getRecruiters, getCertificationsList, getCredentialsList, getEducationList, getRequiredEquipmentList, getSkillSetList } from "../../redux/store";
import { AppRoutes, getAssignmentsNavHeaderProps } from "../../routes";
import { GetCloseAction } from "../../utils";
import { AssignmentDashboard } from "./pages/universal_directory/dashboard/assignment_dashboard";
import UniversalDirectory from "./pages/universal_directory/universal_directory";

interface Props extends RouteComponentProps<any> { }

/// Company Details
const AssignmentsDetailsTabNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSkillSetList());
    dispatch(getCertificationsList());
    dispatch(getCredentialsList());
    dispatch(getEducationList());
    dispatch(getRequiredEquipmentList());
    dispatch(getRoleUsersList());
    dispatch(getCompetitorsList());
    return () => { }
  }, [])

  function handleNavigationChange(value: string) {
    history.push({
      pathname: value,
      state: location.state,
    });
  }

  return (
    <TabNavigation
      activeRoute={location.pathname}
      tabList={[
        {
          title: "dashboard",
          route: `${AppRoutes.assignmentsDetailsPage}/${params.id}/dashboard`,
        },
      ]}
      onChange={handleNavigationChange}
      actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.assignmentsPage)}/>}
    >
      <Switch>
        <Route
          exact
          path={`${AppRoutes.assignmentsDetailsPage}/:id/dashboard`}
          component={AssignmentDashboard}
        />
        <Route
          path={"/"}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: `${location.pathname}/dashboard`,
                state: { ...(location.state as any) },
              }}
            />
          )}
        />
      </Switch>
    </TabNavigation>
  );
};

/// Universal directory folder
const AssignmentsTabNav: React.FunctionComponent<Props> = (props: Props) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  return (
    <TabNavigation
      activeRoute={location.pathname}
      tabList={[
        {
          title: "universal_directory",
          route: AppRoutes.assignmentsUniversalDirectory,
        },
      ]}
      onChange={(value) => history.push(value)}
    >
      <Switch>
        <Route
          exact
          path={AppRoutes.assignmentsUniversalDirectory}
          component={UniversalDirectory}
        />
        <Route
          path={"/"}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: AppRoutes.assignmentsUniversalDirectory,
                state: { from: location },
              }}
            />
          )}
        />
      </Switch>
    </TabNavigation>
  );
};

export const AssignmentsNavigation: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  const headerOptions = getAssignmentsNavHeaderProps(location, match, t);

  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <Switch>
        <Route
          path={`${AppRoutes.assignmentsDetailsPage}/:id`}
          component={AssignmentsDetailsTabNav}
        />
        <Route path={"/"} component={AssignmentsTabNav} />
      </Switch>
    </PageNavHeader>
  );
};
