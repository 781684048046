import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, IBusinessSector, ISkills, ICertifications, ICredentials, IEducationRequirements, IEquipment } from "../../../../interfaces";
import * as thunk from "./skill_list_thunk";

export interface SkillListState extends EntityState<ISkills> {
    error?: AppError | null,
    loading: LoadingType,
    businessSetor: {
        error?: AppError | null,
        loading: LoadingType,
        dataList: IBusinessSector[]
    },
    skillSet: ISkills[],
    credentials: ICredentials[],
    certifications: ICertifications[],
    educationRequirements: IEducationRequirements[],
    equipments: IEquipment[],
    save: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
}

const skillListAdapter: EntityAdapter<ISkills> = createEntityAdapter<ISkills>({
    selectId: (doc) => doc.id ?? doc.skill_set,
})

const initialState: SkillListState = skillListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    businessSetor: {
        error: null,
        loading: LoadingType.idle,
        dataList: []
    },
    skillSet: [],
    credentials: [],
    certifications: [],
    educationRequirements: [],
    equipments: [],
    save: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
})


export const skillListSlice = createSlice({
    name: 'skillList',
    initialState: initialState,
    reducers: {
        clearLoginError(state) {
            return { ...state, error: null };
        },
        clearCreateState(state) {
            return { ...state, save: { ...initialState.save } };
        },
        clearRemoveState(state) {
            return { ...state, remove: { ...initialState.remove } };
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(thunk.getAdminCenterBusinessSector.pending, (state, action) => (
                {
                    ...state, businessSetor: {
                        ...state.businessSetor,
                        loading: LoadingType.pending, error: null
                    }
                }))
            .addCase(thunk.getAdminCenterBusinessSector.rejected, (state, action) => ({ ...state, businessSetor: { loading: LoadingType.failed, error: action.payload, dataList: [] } }))
            .addCase(thunk.getAdminCenterBusinessSector.fulfilled, (state, action) => ({ ...state, businessSetor: { loading: LoadingType.succeeded, error: null, dataList: action.payload } }))

            //Skill set
            .addCase(thunk.getAdminCenterSkills.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getAdminCenterSkills.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAdminCenterSkills.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, skillSet: action.payload }))

            .addCase(thunk.saveSkill.pending, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.saveSkill.rejected, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.saveSkill.fulfilled, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.deleteSkill.pending, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deleteSkill.rejected, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deleteSkill.fulfilled, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getAdminCenterCredentials.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getAdminCenterCredentials.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAdminCenterCredentials.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, credentials: action.payload }))

            .addCase(thunk.saveCredential.pending, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.saveCredential.rejected, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.saveCredential.fulfilled, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.deleteCredentials.pending, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deleteCredentials.rejected, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deleteCredentials.fulfilled, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getAdminCenterCertifications.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getAdminCenterCertifications.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAdminCenterCertifications.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, certifications: action.payload }))

            .addCase(thunk.saveCertification.pending, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.saveCertification.rejected, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.saveCertification.fulfilled, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.deleteCertification.pending, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deleteCertification.rejected, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deleteCertification.fulfilled, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getAdminCenterEducationRequirements.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getAdminCenterEducationRequirements.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAdminCenterEducationRequirements.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, educationRequirements: action.payload }))

            .addCase(thunk.saveEducationRequirement.pending, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.saveEducationRequirement.rejected, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.saveEducationRequirement.fulfilled, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.deleteEducationRequirement.pending, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deleteEducationRequirement.rejected, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deleteEducationRequirement.fulfilled, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getAdminCenterEquipment.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getAdminCenterEquipment.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAdminCenterEquipment.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, equipments: action.payload }))

            .addCase(thunk.saveEquipment.pending, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.saveEquipment.rejected, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.saveEquipment.fulfilled, (state, action) => ({ ...state, save: { ...state.save, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.deleteEquipment.pending, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.deleteEquipment.rejected, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.deleteEquipment.fulfilled, (state, action) => ({ ...state, remove: { ...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageSkillListActions = skillListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = skillListAdapter.getSelectors();
export const selectState = (state: SkillListState) => state;
export const selectLoading = (state: SkillListState) => state.loading;
export const selectError = (state: SkillListState) => state.error;
export const selectSkillSetState = (state: SkillListState) => state.skillSet;
export const selectBusinessState = (state: SkillListState) => state.businessSetor;
export const selectCertificationState = (state: SkillListState) => state.certifications;
export const selectCredentialState = (state: SkillListState) => state.credentials;
export const selectEducationState = (state: SkillListState) => state.educationRequirements;
export const selectEquipmentState = (state: SkillListState) => state.equipments;
export const selectSaveState = (state: SkillListState) => state.save;
export const selectRemoveState = (state: SkillListState) => state.remove;

