import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { SalesCall } from "../../../../interfaces/sales_call";
import { getContactSalesCallsList, getSalesCallFilteredList, getSalesCallList } from "./sales_call_list_thunk";


export interface SalesCallListState extends EntityState<SalesCall> {
    error?: AppError | null,
    loading: LoadingType,
   
}

const salesCallListAdapter: EntityAdapter<SalesCall> = createEntityAdapter<SalesCall>({
    selectId: (doc) => doc.id,
})

const initialState: SalesCallListState = salesCallListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
  
})


export const salesCallListSlice = createSlice({
    name: 'salesCallList',
    initialState: initialState,
    reducers: {
        clearSalesCallListError(state) {
            return { ...state, error: null };
        },
        clearSalesCallListState(state) {
            return salesCallListAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSalesCallList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(getSalesCallList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(getSalesCallList.fulfilled, (state, action) => salesCallListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addCase(getContactSalesCallsList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(getContactSalesCallsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(getContactSalesCallsList.fulfilled, (state, action) => salesCallListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addCase(getSalesCallFilteredList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(getSalesCallFilteredList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(getSalesCallFilteredList.fulfilled, (state, action) => salesCallListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearSalesCallListError, clearSalesCallListState } = salesCallListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = salesCallListAdapter.getSelectors();
export const selectState = (state: SalesCallListState) => state;
export const selectLoading = (state: SalesCallListState) => state.loading;
export const selectError = (state: SalesCallListState) => state.error;


