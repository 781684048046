import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, ITimeCardsBatch } from "../../../../../interfaces";
import * as thunk from './time_cards_batch_thunk'

export interface TimeCardsBatchState {
    error?: AppError | null;
    loading: LoadingType;
    response: ITimeCardsBatch[] | null;
}

const initialState: TimeCardsBatchState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}

export const timecardsBatchSlice = createSlice({
    name: "timecardsBatch",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getBatchDropdownThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getBatchDropdownThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getBatchDropdownThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))

            .addDefaultCase((state) => ({ ...state }));
    }
});
export const timecardsBatchSliceActions = timecardsBatchSlice.actions;
export const selectState = (state: TimeCardsBatchState) => state;