import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import MultiOptionSection from '../../../../components/multi_option_section/multi_option_section';
import { TALENT_SKILLS_TYPE } from '../../../../enums';
import { ISkillOption } from '../../../../interfaces';
import { selectJobDetailsByIdState, useAppSelector } from '../../../../redux/store';
import './job-skill-summary.scss';

interface Props {
    job_id: string,
    onAddOption: (type: string) => void,
    onRemoveOption: (type: string, value: {id: string, value: string}) => void,
    customStyle?: React.CSSProperties,
}

const JobSkillSummary: React.FunctionComponent<Props> = (props) => {
    const jobState = useAppSelector((state) => selectJobDetailsByIdState(props.job_id)(state));
    const { t } = useTranslation();
    return (
        <div className="cd-cs-covv-container" style={props.customStyle}> 
            <MultiOptionSection
                headerTitle={t('professional_skills').toUpperCase()}
                actionName={t('add_skills')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.professional_skills)}
                list={jobState.professional_skills.data  ?? []}
                onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.professional_skills, {id: doc.id, value: doc.value})}
            />
            <MultiOptionSection
                headerTitle={t('credentials').toUpperCase()}
                actionName={t('add_credentials')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.credentials)}
                list={jobState.credentials.data ?? []}
                onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.credentials, {id: doc.id, value: doc.value})}
            />
            <MultiOptionSection
                headerTitle={t('certifications').toUpperCase()}
                actionName={t('add_certifications')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.certifications)}
                list={jobState.certifications.data ?? []}
                onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.certifications, {id: doc.id, value: doc.value})}
            />
            <MultiOptionSection
                headerTitle={t('education').toUpperCase()}
                actionName={t('add_education')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.education)}
                list={jobState.education.data ?? []}
                onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.education, {id: doc.id, value: doc.value})}
            />
            <MultiOptionSection
                headerTitle={t('required_equipment').toUpperCase()}
                actionName={t('add_equipment')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.safety_equipment)}
                list={jobState.safety_equipment.data ?? []}
                onRemove={(doc)=> props.onRemoveOption(TALENT_SKILLS_TYPE.safety_equipment, {id: doc.id, value: doc.value})}
            />
        </div>
    )
}



export default JobSkillSummary;