import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { THEME, LoadingType } from "../../../../enums";
import { INFO_TEXT_CATEGORY } from "../../../../enums/info_text_category";
import { AgencySetup, BulbIcon } from "../../../../icons";
import { selectInfoTextState, getAdminCenterInfoText } from "../../../../redux/admin_center";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CustomButton } from "../../../../utils";
import { clearAdminCenterInfoTextError } from "../../../../redux/admin_center/info_text/info_text_reducer";
import './agency_setup_info_text.scss';


interface Props {
    visible: boolean;
    onClose: () => void;
    onDismiss: () => void;
}

const AgencySetUpInfoTextPopUp: React.FunctionComponent<Props> = (props) => {
    const { onClose, onDismiss, visible } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const infoTextState = useAppSelector((state) => selectInfoTextState(state));

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        dispatch(getAdminCenterInfoText(INFO_TEXT_CATEGORY.AGENCY_SETUP));
    }, [])

    const handleClearError = () => {
        dispatch(clearAdminCenterInfoTextError())
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'30%'}
            maxWrapperWidth={'60%'}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="ac-info-text-popup-container">
                    <div className="ac-info-text-popup-header">
                        <FormDialogTilteHeader
                            title={t("agency_setup_overview")}
                            titleIcon={<BulbIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="ac-info-text-popup-content">
                        <div className="info-text">
                            <p>Welcome to the KinISO Admin Center's Agency Set Up section. Here, Super Users can configure essential information specific to their staffing agency. This information will be used to personalize your agency's profile and enhance your KinISO experience.</p>
                            <span><b>Key Features:</b></span>
                            <span><b>Company Logo Upload:</b> Customize your agency's profile by uploading your company's logo. Ensure it's a high-quality image to make a lasting impression on clients and candidates.</span>
                            <span><b>Company Name:</b> Enter your staffing agency's official name. This will be displayed throughout the KinISO platform, so make sure it's accurate and professional.</span>
                            <span><b>Company Address:</b> Provide your agency's physical address. This helps in building trust with clients and candidates by showcasing your presence in the industry.</span>
                            <p><b>Company Website:</b> Share your agency's website URL. This allows users to access more information about your services and expertise.</p>
                            <span><b>Tips for a Great Profile:</b></span>
                            <ul>
                                <li>Use a high-resolution image for the logo.</li>
                                <li>Double-check the accuracy of your company name and address.</li>
                                <li>Ensure your website URL is correct and functional.</li>
                            </ul>
                            <span><b>Why is this important?</b></span>
                            <p>Personalizing your agency's profile with accurate and appealing information can significantly impact your success on KinISO. Clients and candidates are more likely to engage with agencies that present a profession.</p>
                            <span><b>Ready to Get Started?</b></span>
                            <p>Click on the relevant section to update your company's logo, name, address, and website. Make sure to save your changes before exiting.</p>
                            <p>Thank you for choosing KinISO to optimize your staffing agency's operations!</p>
                        </div>
                        {infoTextState.update?.error && (
                            <div className={"error-section"}>
                                <ApiError
                                    message={infoTextState.update?.error?.message}
                                    onClose={handleClearError}
                                />
                            </div>
                        )}
                    </div>
                    <div className="ac-info-text-popup-actions">
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('ok')}
                                loading={infoTextState.update?.loading === LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>

                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )

}

export default AgencySetUpInfoTextPopUp