import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectAll, selectEntities, selectState } from "./sales_call_list_reducer";

const select = (state: IRootState) => state.salesMarketing.myCallDeck.salesCallList;
export const selectSalesCallListState = createSelector(select, selectState);
export const selectSalesCallList = createSelector(
    select,
    selectAll
);
export const selectSalesCallListEntities = createSelector(
    select,
    selectEntities
);