import { useEffect, useRef, useState } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { CRIMINAL_RECORD, LoadingType, ROLE, THEME } from "../../../../enums";
import { ICreateTalentCrimeHistory, ITalentCrimeHistory } from "../../../../interfaces";
import { getRecruiters, selectRecruitersList, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { ApplicationReceivedIcon, ReadDocIcon } from "../../../../icons";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import DragAndDrop from "../../../../components/drag_and_drop/drag_and_drop";
import { CustomButton, getUserNameFromProfileDropdown } from "../../../../utils";
import "./add_crime_history.scss";


interface Props {
    visible: boolean;
    title: string;
    successTitle: string;
    crimeDataById?: ITalentCrimeHistory;
    onClose: () => void;
    onDissmiss?: () => void;
    onSuccessClose: () => void;
    onSubmit: (value: ICreateTalentCrimeHistory) => void;
    talent_id: string;
    selectedFiles: Array<File> | null;
    loading: LoadingType;
    onDocumentChange: (value: Array<File>) => void,
    onRemoveDocument: (index: number) => void,
}

const AddCrimeHistoryPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        crimeDataById,
        title,
        talent_id,
        onSubmit,
        loading,
        selectedFiles,
        onDocumentChange,
        onRemoveDocument
    } = props;
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const { t } = useTranslation();
    const recruitersList = useAppSelector((state) => selectRecruitersList(state));
    useEffect(() => {
        dispatch(getRecruiters({ role: ROLE.recruiter, branch_id: '' }));
    }, [])
    const getCrimeHistData = (value: ITalentCrimeHistory): ICreateTalentCrimeHistory => {
        return {
            talent_id: value.talent_id ?? "",
            offence_type: value.offence_type ?? "",
            recruiter_id: value.recruiter_id ?? "",
            file_paths: value.file_paths ?? {},
            file_names: value.file_names ?? []

        }
    }

    const initialForm: ICreateTalentCrimeHistory = {
        talent_id: talent_id,
        offence_type: "",
        recruiter_id: "",
        file_paths: {},
        file_names: []
    }
    const [formState, setFormState] = useState<ICreateTalentCrimeHistory>(
        crimeDataById ? getCrimeHistData(crimeDataById) : initialForm
    );

    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>();

    function handleFieldChange(fieldId: string, value: any | React.ChangeEvent<HTMLInputElement>) {
        setFormState({ ...formState, [fieldId]: value });
    }
    const enumOptions = [
        { label: 'Select', value: '' },
        ...Object.keys(CRIMINAL_RECORD).map((doc) => ({
            label: CRIMINAL_RECORD[doc],
            value: doc,
        })),
    ];
    function handleSubmit() {
        setCheckValidationOnSubmit(true);
        if (_isFormValid()) {
            if (crimeDataById && selectedFiles) {
                formState.file_names = [...crimeDataById.file_names, ...selectedFiles?.map((file) => file.name)];
            }
            else if (selectedFiles) {
                formState.file_names = selectedFiles?.map((file) => file.name);
            }
            onSubmit(formState)
            setCheckValidationOnSubmit(false);
        }
    }
    useEffect(() => {
        if (crimeDataById && selectedFiles) {
            formState.file_names = [...crimeDataById.file_names, ...selectedFiles?.map((file) => file.name)];
        }
        else if (selectedFiles) {
            formState.file_names = selectedFiles?.map((file) => file.name);
        }
    }, [selectedFiles, crimeDataById]);

    const onRemoveFiles = (index: number) => {
        if (selectedFiles && selectedFiles?.length > 1) {
            setFormState({ ...formState, file_names: formState.file_names.splice(index, 1) })
        } else {
            setFormState({ ...formState, file_names: [] });
        }
        onRemoveDocument(index);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"40%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="crime-f-container">
                    <div className="crime-f-header">
                        <FormDialogTilteHeader
                            title={title}
                            titleIcon={<ApplicationReceivedIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="crime-f-content">
                        <div className="cf-content-form-input">
                            <div className="form-item">
                                <CustomFormSelect
                                    name={'offence_type'}
                                    list={enumOptions}
                                    onChange={(value) => handleFieldChange('offence_type', value)}
                                    required={true}
                                    placeholder={t('select_option', { value: 'offence_type' })}
                                    value={formState.offence_type}
                                    label={t("type_criminal_record")}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-item">
                                <CustomFormSelect
                                    name={'recruiter_id'}
                                    list={recruitersList.map((doc) => ({
                                        label: getUserNameFromProfileDropdown(doc),
                                        value: doc.id,
                                    }))}
                                    onChange={(value) => handleFieldChange('recruiter_id', value)}
                                    required={false}
                                    placeholder={t('select_option', { value: 'recruiter_id' })}
                                    value={formState.recruiter_id}
                                    // customStyle={{ width: '-webkit-fill-available' }}
                                    label={t("recruiter")}

                                />
                            </div>

                        </div>
                        <div>
                            <DragAndDrop
                                label={t('documents')}
                                selectedFiles={selectedFiles}
                                accept={".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                onMultiChange={onDocumentChange}
                                onMultiRemove={onRemoveFiles}
                                formValidators={validateDocumentType}
                                multiple={true}
                                placeholder={"Select or Drag File(s)"}
                            />
                        </div>
                    </div>
                    <div className="crime-f-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={onClose}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                loading={loading === LoadingType.pending}
                                textStyle={{ textTransform: "capitalize" }}
                                name={crimeDataById ? t("update") : t("create")}
                                // enable={_isFormValid()}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>

        </ActionDialogHolder>
    )
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
    function validateDocumentType() {
        // if (props.selectedDocument && props.selectedDocument.type.startsWith('image/')) {
        //     return t('image_file_is_not_accepted');
        // }
        return null;
    }
    function _isFormValid() {
        const {
            offence_type,
        } = formState;

        if (
            offence_type === ""
        )
            return false;

        return true;
    }

    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "offence_type": {
                if (value.text === "") return t("validators.required");
                return null;
            }

            default: {
                return null;
            }
        }
    }

}

export default AddCrimeHistoryPopup;