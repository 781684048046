import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./employee_assignment_report_thunk";
import { IReportCenterEmpAssignmentReport } from "../../../../interfaces/report_center_talent";


export interface EmployeeAssignmentReportListState {
    error?: AppError | null,
    loading: LoadingType,
    response: IReportCenterEmpAssignmentReport[] | null
}

const initialState: EmployeeAssignmentReportListState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}


export const employeeAssignmentReportListSlice = createSlice({
    name: 'employeeAssignmentReportList',
    initialState: initialState,
    reducers: {
        clearEmployeeAssignmentReportListError(state) {
            return { ...state, error: null };
        },
        clearEmployeeAssignmentReportListState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getEmployeeAssignmentReportList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getEmployeeAssignmentReportList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getEmployeeAssignmentReportList.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearEmployeeAssignmentReportListError, clearEmployeeAssignmentReportListState } = employeeAssignmentReportListSlice.actions;
export const selectState = (state: EmployeeAssignmentReportListState) => state;
export const selectLoading = (state: EmployeeAssignmentReportListState) => state.loading;
export const selectError = (state: EmployeeAssignmentReportListState) => state.error;

