import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI,  } from "../../../../apis";
import { AppError, ISendOnlineApplication } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const sendOnlineApplicationThunk = createAsyncThunk<
string,
ISendOnlineApplication,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/recruiting/online-applications/post',
    async (credentials, thunkAPI) => {
        try {
            const res = await DashboardAPI.postOnlineApplications(credentials);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);