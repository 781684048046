import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import { selectState, selectSingleState, selectSingleTalentRequestSSNInitialState } from "./request_ssn_reducer";

const select = (state: IRootState) => state.talent.details.dashboard.talentSSNRequest;
export const selectTalentRequestSSNState = createSelector(select, selectState);
export const selectSingleTalentRequestSSNStateById = (id: string) => createSelector(
    select, 
    (state) => {
        if (id && state[id]) return selectSingleState(state, id);
        return selectSingleTalentRequestSSNInitialState();
    }
);