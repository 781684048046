import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../redux/store";
import { useRef } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { LoadingType } from "../../../../enums";
import DialogWrapper from "../../../../components/dialog_wrapper/dialog_wrapper";
import { salesDocumentSendByEmailActions } from "../../../../redux/sales_marketing/marketing_docs/marketing_doc_send_by_email/send_by_email_reducer";
import { AppError } from "../../../../interfaces";
import { ProfileState } from "../../../../redux/profile/profile_reducer";
import SendByEmailForm from "../../forms/send_by_email/send_by_email";
import { JobDocumentSendByEmailState } from "../../../../redux/job_order/details/document/document_send_by_email/send_by_email_reducer";


interface Props {
  visible: boolean;
  DocumentId: string | undefined;
  onClose: () => void;
  onDissmiss?: () => void;
  onSuccessClose: () => void;
  onSubmit: (value) => void;
  profileState: ProfileState;
  sendByemailState: JobDocumentSendByEmailState;
  loading: LoadingType;
  error: AppError | null | undefined;
}

const SendByEmailPopup: React.FunctionComponent<Props> = (props) => {
  const { visible, onClose, onDissmiss, onSuccessClose, profileState, sendByemailState } =
    props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const actionRef = useRef<ActionDialogHolderType>(null);

  function handleWrapperClose(action?: () => void) {
    if (sendByemailState.sendDocVidLinkToEmail.loading === LoadingType.succeeded) {
      closePopup(onSuccessClose);
    } else {
      if (action) closePopup(action);
    }
  }
  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={"40%"}
      onClose={onClose}
      onDissmiss={onDissmiss}
    >
      <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
        {getUI()}
      </DialogWrapper>
    </ActionDialogHolder>
  );
  function getUI() {
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
      }`;
    return (
      <div className="mark-tlnt-pop-holder">
        <SendByEmailForm
          DocumentId={props.DocumentId}
          loading={props.loading}
          error={props.error}
          currentLoginUserName={currentLoginUserName}
          onSubmit={props.onSubmit}
          onClearError={handleClearError}
          onSuccessClose={() => closePopup(props.onSuccessClose)}
        />
      </div>
    );
  }

  function handleClearError() {
    dispatch(salesDocumentSendByEmailActions.clearSendDocumentLinkToEmailError());
    dispatch(salesDocumentSendByEmailActions.clearsendVideoLinkToEmailError());
  }

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    handleClearError();
  }
};

export default SendByEmailPopup;
