import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI  } from "../../../../apis";
import { AppError, CompanyData} from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getCompanyBasicInfo = createAsyncThunk<
    CompanyData,
    {companyId: string, contact: boolean},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/company_info/company_basic_info/get',
    async (query, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getCompanyMetaData(query);
            if(typeof res.data === 'string') return [];
            return res.data as CompanyData;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);