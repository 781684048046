import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import ApiError from '../../../../components/api_error';
import DialogWrapper, { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import RenderInput from '../../../../components/render_input/render_input';
import { LoadingType, THEME } from '../../../../enums';
import { ContactsIcon } from '../../../../icons';
import { FormInputOption, IJobOrderDetail } from '../../../../interfaces';
import { jobOrderContactCreateActions } from '../../../../redux/job_order/details/contacts/job_order_contact_create/job_order_contact_reducer';
import { createJobOrderContact, getContactsDropdownThunk, postSnackbarMessage, selectContactsDropdownList, selectContactsDropdownListState, selectSingleJobOrderContactCreateStateById, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { CustomButton, SpinnerScreen } from '../../../../utils';
import './existing_contact.scss';
interface Props {
    visible: boolean,
    job: IJobOrderDetail,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

const ExistingContactPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        job,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const contactsListState = useAppSelector((state) => selectContactsDropdownListState(state));
    const contactsList = useAppSelector((state) => selectContactsDropdownList(state));
    const createState = useAppSelector((state) => selectSingleJobOrderContactCreateStateById(job.id)(state));
    const [formState, setFormState] = useState<{ job_id: string, job_title: string, contact_id: string }>({
        job_id: job.id ?? '',
        job_title: job.job_title,
        contact_id: '',
    })
    useEffect(() => {
        if (createState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(createState.response));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [createState.loading])

    useEffect(() => {
        if (job.company_id) {
            if (job.department_id && job.department_id != "") {
                dispatch(getContactsDropdownThunk({ company_id: job.company_id, department_id: job.department_id }));
            }
            else {
                dispatch(getContactsDropdownThunk({ company_id: job.company_id, department_id: undefined }));
            }
        }
    }, [])

    const formOptions: FormInputOption[] = [
        {
            field: "job_title",
            type: "text",
            label: 'job_title',
            secure: false,
            required: true,
            placeholder: '',
            keyboardtype: '',
            disabled: true,
        },
        {
            field: "contact_id",
            type: "selector",
            label: 'contact',
            secure: false,
            required: true,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: contactsList.map((doc) => ({ label: doc.name, value: doc.id })),
            multi: false,
        },
    ]

    function handleFieldChange(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setFormState({ ...formState, [parent]: { ...formState[parent], [fieldId]: value } });
        } else {
            setFormState({ ...formState, [fieldId]: value });
        }
    }

    function isFetchLoading() {
        return contactsListState.loading === LoadingType.pending;

    }

    function isAddLoading() {
        return createState.loading === LoadingType.pending;
    }

    function getError() {
        return createState.error;
    }

    function getOptionForm() {
        return (
            <div className="jbs-cnt-ext-f-container">
                <div className="jbs-cnt-ext-f-header">
                    <FormDialogTilteHeader
                        title={`${t('add_existing_contact')}`}
                        titleIcon={<ContactsIcon width={"100%"} height={"100%"} />}
                    />
                </div>
                <div className="jbs-cnt-ext-f-content">
                    <div className="jb-f-stp-one">
                        {formOptions.map((doc, index) => {
                            return (
                                <RenderInput
                                    key={index}
                                    doc={doc}
                                    index={index}
                                    formState={formState}
                                    handleFieldChange={handleFieldChange}
                                    formValidators={formValidators}
                                    className={"jb-f-input-holder"}
                                    menuPlacement={'top'}

                                />
                            )
                        })}
                    </div>
                    {getError() != null && <div className={"error-section"}>
                        <ApiError message={getError()?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="jbs-cnt-ext-f-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('cancel')}
                            enable={true}
                            backgroundColor={THEME.secondaryColor4}
                            onClick={() => {
                                if (getError()) handleClearError();
                                closePopup(onClose)
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={isAddLoading()}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('save')}
                            enable={_isValid()}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleAddOption}
                        />
                    </div>
                </div>
            </div>
        );
    }

    function _isValid() {
        const { job_title, contact_id, job_id } = formState;
        if (job_title === '' || contact_id === '') return false;
        return true;
    }

    function formValidators(value: { text: string, field: string, }) {
        switch (value.field) {
            case "job_title": {
                if (value.text == '') return t('validators.required');
                return null;
            };
            default: {
                return null;
            }
        }
    }


    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={430}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                {isFetchLoading() ? <div className="loading-spinner"><SpinnerScreen /></div> : getOptionForm()}
            </DialogWrapper>

        </ActionDialogHolder>
    );

    function handleAddOption() {
        dispatch(createJobOrderContact(formState));
    }

    function handleClearError() {
        dispatch(jobOrderContactCreateActions.clearJobOrderContactCreateError(job.id));
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default ExistingContactPopup;