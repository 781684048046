import React from 'react';
import { useTranslation } from 'react-i18next';
import RenderInput from '../../../components/render_input/render_input';
import { FormInputOption, IEducation } from '../../../interfaces';
import './education_form.scss';

interface Props {
    formState: IEducation,
    handleFieldChange: (id: string, value: any) => void,
}

const formOptions: (FormInputOption | FormInputOption[])[] = [
    [
        {
            field: "last_school_attended",
            type: "text",
            label: 'last_school_attended',
            secure: false,
            required: false,
            placeholder: '',
            keyboardtype: ''
        },
        {
            field: "degree",
            type: "text",
            label: 'degree',
            secure: false,
            required: false,
            placeholder: '',
            keyboardtype: ''
        },
    ],
    [
        {
            field: "year_completed",
            type: "date",
            label: 'year_completed',
            secure: false,
            required: false,
            placeholder: '',
            keyboardtype: '',
            views: ["year"],    
            format: "yyyy"
        },
        {
            field: "is_currently_in_progress",
            type: "checkbox",
            label: 'currently_in_progress',
            secure: false,
            required: false,
            placeholder: '',
            keyboardtype: ''
        },
    ],
    // [
    //     {
    //         field: "department_name",
    //         type: "text",
    //         label: 'department_name',
    //         secure: false,
    //         required: true,
    //         placeholder: '',
    //         keyboardtype: ''
    //     },
    //     {
    //         field: "is_currently_work_here",
    //         type: "checkbox",
    //         label: 'i_currently_work_here',
    //         secure: false,
    //         required: false,
    //         placeholder: '',
    //         keyboardtype: ''
    //     },
    // ],
    {
        field: "awards_achievement_and_honours",
        type: "text",
        label: 'list_awards_achievement_and_honours',
        secure: false,
        required: false,
        multiline: true,
        placeholder: '',
        keyboardtype: ''
    },

];

const EducationForm: React.FunctionComponent<Props> = (props) => {
    const { formState, handleFieldChange } = props;
    const { t } = useTranslation();

    return (
        <div className="oa-edk-form-container">
            {formOptions.map((doc, index) => {
                if (Array.isArray(doc)) {
                    return (
                        <div key={index+ 'row'} className="oa-edk-form-row">
                            {doc.map((subDoc, subIndex) => {
                                return (
                                    <RenderInput
                                        key={index + subIndex}
                                        doc={subDoc}
                                        index={subIndex}
                                        formState={formState}
                                        handleFieldChange={handleFieldChange}
                                        formValidators={formValidators}
                                        className={"oa-edk-f-input-holder"}
                                    />
                                );
                            })}
                        </div>
                    );
                }

                return (
                    <RenderInput
                        key={index}
                        doc={doc}
                        index={index}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        formValidators={formValidators}
                        className={"oa-edk-f-input-holder"}
                    />
                )
            })}
        </div>
    );

    function formValidators(value: { text: string, field: string, }) {
        switch (value.field) {
            case "":
            case "":
            case "": {
                if (value.text == '') return t('validators.required');
                return null;
            };
            case "": {
                if (value.text == '') return t('validators.required');
                // if (RegxPattern.email.test(value.text) === false) return t('validators.enterValidEmail');
                return null;
            };
            default: {
                return null;
            }
        }
    }

}

export default EducationForm;