import { createAsyncThunk } from "@reduxjs/toolkit";
import { JobOrderAPI } from "../../../apis";
import { AppError, IJobsList, } from "../../../interfaces";
import { catchRequestError } from "../../../utils";


export const getjobsDropdown = createAsyncThunk<
    Array<IJobsList>,
    {branch_id? :string, company_id?: string, department_id? :string, open_jobs?: boolean } | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@selectors/jobs/dropdown/get',
    async (query, thunkAPI) => {
        try {
            const res = await JobOrderAPI.getJobsDropdown(query);
            if(typeof res.data === 'string')  return [];
            return res.data as  Array<IJobsList>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);