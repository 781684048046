import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState,
} from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ServiceCallInfo } from "../../../../interfaces";
import { getServiceCallList } from "./service_call_list_thunk";

export interface ServiceCallListState extends EntityState<ServiceCallInfo> {
  error?: AppError | null;
  loading: LoadingType;
}

const serviceCallsListAdapter: EntityAdapter<ServiceCallInfo> = createEntityAdapter<ServiceCallInfo>({
    selectId: (doc) => doc.id,
})

const initialState: ServiceCallListState = serviceCallsListAdapter.getInitialState({
  error: null,
  loading: LoadingType.idle,
  serviceCalls: [],
});

export const serviceCallListSlice = createSlice({
  name: "serviceCallList",
  initialState: initialState,
  reducers: {
    clearServiceCallListError(state) {
      return { ...state, error: null };
    },
    clearServiceCallListState(state) {
      return serviceCallsListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(getServiceCallList.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null }))
        .addCase(getServiceCallList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
        .addCase(getServiceCallList.fulfilled, (state, action) => serviceCallsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
        .addDefaultCase(state => ({ ...state }));
  },
});

export const { clearServiceCallListError, clearServiceCallListState } =
  serviceCallListSlice.actions;
export const selectState = (state: ServiceCallListState) => state;
export const selectLoading = (state: ServiceCallListState) => state.loading;
export const selectError = (state: ServiceCallListState) => state.error;
export const { selectAll, selectEntities, selectIds, selectById } = serviceCallsListAdapter.getSelectors();
