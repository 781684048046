import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableFilterEmpty,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../enums";
import { ClaimStatusIcon, TalentActiveStatusIcon } from "../../../../icons";
import { IUnEmploymentClaim, IWorkCompClaim } from "../../../../interfaces";
import {
  getAllUnEmploymentClaimsList,
  getAllWorkCompAuditAndComplianceReportingList,
  getAllWorkCompClaimsList,
  selectUnEmploymentClaimsList,
  selectUnEmploymentClaimsListState,
  selectWCCRCreateState,
  selectWCCRUploadDocState,
  selectWorkCompAuditAndComplianceReportingList,
  selectWorkCompAuditAndComplianceReportingListState,
  selectWorkCompClaimsList,
  selectWorkCompClaimsListState,
  workCompAuditAndComplianceReportCreate,
  workCompAuditAndComplianceReportUpload,
} from "../../../../redux/risk_management";
import { unEmploymentClaimsListActions } from "../../../../redux/risk_management/universal_directory/unemployment_claims_list/unemployment_claims_list_reducer";
import { workCompClaimsListActions } from "../../../../redux/risk_management/universal_directory/work_comp_claims_list/work_comp_claims_list_reducer";
import { workCompCodesListActions } from "../../../../redux/selectors/work_comp_list/work_comp_list_reducer";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  CustomCheckBox,
  convertNumberToMoney,
  getPlusBtnIcon,
  getTableAccessIcon,
  moneyFormat,
} from "../../../../utils";
import { getDateString } from "../../../../variables";
import CreateWorkCompClaim from "../../popup/new_work_comp_claim";
import "./universal_directory.scss";
import SearchBar from "../../../../components/search_bar";
import {
  IUpdateReport,
  IWorkCompAuditAndComplianceReporting,
} from "../../../../interfaces/work_comp_audit_and_compliance_reporting";
import CreateReportPopup from "../../popup/create_report";
import { workCompAuditAndComplianceReportingListActions } from "../../../../redux/risk_management/universal_directory/work_comp_audit_and_compliance_reporting/work_comp_audit_and_compliance_reporting_reducer";
import { S3API } from "../../../../apis/s3_api";

interface Props extends RouteComponentProps<any> { }
const wcc_tableHeader = [
  { title: "access_claim", code: "access_claim" },
  { title: "employee_name", code: "employee_name" },
  { title: "claim_status", code: "claim_status" },
  { title: "potential_charge", code: "potential_charges" },
  { title: "cost_to_date", code: "cost_to_date" },
  { title: "claim_number", code: "claim_number" },
  //{ title: 'claim_date', code: 'claim_date' },
  { title: "reported_date", code: "reported_date" },
  { title: "date_closed", code: "date_closed" },
  { title: "company", code: "company_name" },
  //{ title: 'department', code: 'department_name' },
  { title: "next_steps", code: "next_steps" },
  // { title: 'branch', code: 'branch_name' },
];

const uc_tableHeader = [
  { title: "access_claim", code: "access_claim" },
  { title: "employee_name", code: "employee_name" },
  { title: "claim_status", code: "claim_status" },
  { title: "potential_charge", code: "potential_charges" },
  { title: "cost_to_date", code: "cost_to_date" },
  { title: "claim_number", code: "claim_number" },
  { title: "claim_date", code: "claim_date" },
  { title: "date_closed", code: "date_closed" },
  // { title: 'branch', code: 'branch_name' },
  { title: "next_steps", code: "next_steps" },
];

const wccr_tableHeader = [
  { title: "access_claim", code: "access_claim" },
  { title: "insurance_company", code: "insurance_company" },
  { title: "report_status", code: "report_status" },
  { title: "report_name", code: "report_name" },
  { title: "report_number", code: "report_number" },
  { title: "report_or_audit_period", code: "report_or_audit_period" },
  { title: "date_due", code: "date_due" },
  { title: "date_completed", code: "date_completed" },
  { title: "date_submitted", code: "date_submitted" },
];

const UniversalDirectoryPage: React.FunctionComponent<Props> = (props) => {

  const dispatch = useAppDispatch();
  const workCompClaimsState = useAppSelector((state) =>
    selectWorkCompClaimsListState(state)
  );
  const workCompClaimsList = useAppSelector((state) =>
    selectWorkCompClaimsList(state)
  );
  const unEmploymentClaimsState = useAppSelector((state) =>
    selectUnEmploymentClaimsListState(state)
  );
  const unEmploymentClaimsList = useAppSelector((state) =>
    selectUnEmploymentClaimsList(state)
  );

  const workCompAuditAndComplianceReportState = useAppSelector(
    selectWorkCompAuditAndComplianceReportingListState
  );
  const workCompAuditAndComplianceReportList: IWorkCompAuditAndComplianceReporting[] =
    useAppSelector(selectWorkCompAuditAndComplianceReportingList);

  const wccrCreateState = useAppSelector(selectWCCRCreateState);
  const wccrUploadDocState = useAppSelector(selectWCCRUploadDocState);

  const [workCompClaimsSearch, setWorkCompClaimsSearch] = useState("");
  const [wccStatus, setWCCStatus] = useState(false);
  const [unEmploymentClaimsSearch, setunEmploymentClaimsSearch] = useState("");
  const [ucStatus, setUCStatus] = useState(false);
  const [wcc_sortedField, wcc_setSortedField] = useState<string | null>("id");
  const [wcc_sortDirection, wcc_setSortDirection] = useState("asc");
  const [addClaim, setAddClaim] = useState<boolean>(false);
  const [addType, setAddType] = useState<string>("");
  const [uc_sortedField, uc_setSortedField] = useState<string | null>("id");
  const [uc_sortDirection, uc_setSortDirection] = useState("asc");

  const [wccrSearch, setWccrSearch] = useState("");
  const [wccrSortedField, setWccrSortedField] = useState<string | null>("id");
  const [wccrSortDirection, setWccrSortDirection] = useState("asc");
  const [wccrAttachedDocs, setWccrAttachedDocs] = useState<Array<File> | null>(null);

  const [addNewWCCR, setAddNewWCCR] = useState(false);
  const { history, match, location } = props;
  const { params } = match;
  const { t } = useTranslation();

  useEffect(() => {
    getWorkCompClaims();
    getUnEmploymentClaims();
    getAllWorkCompAuditAndComplianceReports();
    return () => { };
  }, []);

  useEffect(() => {
    if (workCompClaimsState.error != null && workCompClaimsList.length != 0) {
      dispatch(postSnackbarMessage(workCompClaimsState.error.message));
    }

    return () => { };
  }, [workCompClaimsState.error]);


  useEffect(() => {
    if (wccrCreateState.loading == LoadingType.succeeded) {
      if (wccrAttachedDocs) {
        dispatch(workCompAuditAndComplianceReportUpload({ id: wccrCreateState.response.object_id, attachedDocs: wccrAttachedDocs }));
      }
    }
    setAddNewWCCR(false);
    dispatch(workCompAuditAndComplianceReportingListActions.clearWCCRCreateState());
    getAllWorkCompAuditAndComplianceReports()
    return () => { };
  }, [wccrCreateState.loading]);

  useEffect(() => {
    if (wccrUploadDocState.loading == LoadingType.succeeded) {
      if (wccrAttachedDocs) {
        wccrAttachedDocs.forEach(doc => {
          S3API.uploadFile(wccrUploadDocState.response[doc.name].url, doc)
            .then(response => {

            }).catch(error => { });
        })
      }
    }
  }, [wccrUploadDocState.loading]);

  useEffect(() => {
    if (
      wccrUploadDocState.loading === LoadingType.succeeded
    ) {
      dispatch(postSnackbarMessage(wccrCreateState.response.status));
      dispatch(workCompAuditAndComplianceReportingListActions.clearWCCRUploadState());
    }
  }, [wccrUploadDocState.loading]);

  useEffect(() => {
    if (
      unEmploymentClaimsState.error != null &&
      unEmploymentClaimsList.length != 0
    ) {
      dispatch(postSnackbarMessage(unEmploymentClaimsState.error.message));
    }

    return () => { };
  }, [unEmploymentClaimsState.error]);

  useEffect(() => {
    if (
      workCompAuditAndComplianceReportState.error != null &&
      workCompAuditAndComplianceReportList.length != 0
    ) {
      dispatch(
        postSnackbarMessage(workCompAuditAndComplianceReportState.error.message)
      );
    }

    return () => { };
  }, [workCompAuditAndComplianceReportState.error]);

  const [stateTalentData, setStateTalentData] = useState<{
    talent_id: string;
    talent_ssn: string;
    employee_name: string;
  }>({ talent_id: "", talent_ssn: "", employee_name: "" });
  useEffect(() => {
    const state = location.state as any;
    if (state) {
      setStateTalentData({
        talent_id: state["talent_id"],
        talent_ssn: state["talent_ssn"],
        employee_name: state["employee_name"],
      });
      if (state["addClaim"]) {
        if (state["addType"] === "WORKCOMPCLAIM") {
          setAddClaim(true);
          setAddType("WORKCOMPCLAIM");
        } else {
          setAddClaim(true);
          setAddType("UNEMPLOYMENT");
        }
      }
    }
  }, [location]);

  function getWorkCompClaims() {
    dispatch(getAllWorkCompClaimsList());
  }

  function getUnEmploymentClaims() {
    dispatch(getAllUnEmploymentClaimsList());
  }

  function getAllWorkCompAuditAndComplianceReports() {
    dispatch(getAllWorkCompAuditAndComplianceReportingList());
  }

  //Work Comp Claims
  function getWCCFilteredList(): IWorkCompClaim[] {
    const list = wccStatus ? getOnlyWCCOpenStatus() : workCompClaimsList;
    let sortedList: IWorkCompClaim[] | undefined;

    if (wcc_sortedField != null) {
      sortedList = [...list].sort((a, b) => {
        const valueA =
          a[wcc_sortedField] != null
            ? typeof a[wcc_sortedField] != typeof 1
              ? a[wcc_sortedField].trim().toLowerCase()
              : a[wcc_sortedField]
            : "";
        const valueB =
          b[wcc_sortedField] != null
            ? typeof b[wcc_sortedField] != typeof 1
              ? b[wcc_sortedField].trim().toLowerCase()
              : b[wcc_sortedField]
            : "";
        if (wcc_sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return (sortedList ?? list).filter((doc) => {
      const str = workCompClaimsSearch.trim().toLowerCase();
      const nameFilter = doc.employee_name
        ? doc.employee_name.trim().toLowerCase().includes(str)
        : false;
      const companyName = doc.company_name
        ? doc.company_name.trim().toLowerCase().includes(str)
        : false;
      const departmentFilter = doc.department_name
        ? doc.department_name.trim().toLowerCase().includes(str)
        : false;
      const claimNumberFilter = doc.claim_number
        ? doc.claim_number.trim().toLowerCase().includes(str)
        : false;
      const claimStatusFilter = doc.claim_status
        ? doc.claim_status.trim().toLowerCase().includes(str)
        : false;
      const potentialChargeFilter = doc.potential_charges
        ? convertNumberToMoney(doc.potential_charges)
          .toString()
          .trim()
          .toLowerCase()
          .includes(str)
        : false;
      const costToDateFilter = doc.cost_to_date
        ? convertNumberToMoney(doc.cost_to_date)
          .toString()
          .trim()
          .toLowerCase()
          .includes(str)
        : false;
      const nextStepsFilter = doc.next_steps
        ? doc.next_steps.toString().trim().toLowerCase().includes(str)
        : false;
      return (
        nameFilter ||
        companyName ||
        departmentFilter ||
        claimNumberFilter ||
        claimStatusFilter ||
        potentialChargeFilter ||
        costToDateFilter ||
        nextStepsFilter
      );
    });
  }

  function getOnlyWCCOpenStatus() {
    return workCompClaimsList.filter((doc) => doc.claim_status === "OPEN");
  }
  function handleSortFieldChange(value: string) {
    if (wcc_sortedField && wcc_sortedField === value) {
      // wcc_setSortedField(null);
      wcc_setSortDirection(wcc_sortDirection === "asc" ? "desc" : "asc");
    } else {
      wcc_setSortedField(value);
      wcc_setSortDirection("asc");
    }
  }

  //Work Comp Claims
  const getWCCRFilteredList = (): IWorkCompAuditAndComplianceReporting[] => {
    let sortedList: IWorkCompAuditAndComplianceReporting[] | undefined;

    if (wccrSortedField != null) {
      sortedList = [...workCompAuditAndComplianceReportList].sort((a, b) => {
        const valueA =
          a[wccrSortedField] != null && a[wccrSortedField] !== undefined
            ? typeof a[wccrSortedField] == typeof "1"
              ? a[wccrSortedField].trim().toLowerCase()
              : a[wccrSortedField]
            : "";
        const valueB =
          b[wccrSortedField] != null
            ? typeof b[wccrSortedField] == typeof "1"
              ? b[wccrSortedField].trim().toLowerCase()
              : b[wccrSortedField]
            : "";
        if (wccrSortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return (sortedList ?? workCompAuditAndComplianceReportList).filter((doc) => {
      const str = wccrSearch.trim().toLowerCase();
      if (str == "")
        return true;
      const reportNameFilter = doc.report_name
        ? doc.report_name.trim().toLowerCase().includes(str)
        : false;
      const insuranceCompanyFilter = doc.insurance_company_name
        ? doc.insurance_company_name.trim().toLowerCase().includes(str)
        : false;
      const reportStatusFilter = doc.report_status
        ? doc.report_status.trim().toLowerCase().includes(str)
        : false;
      const reportNumberFilter = doc.report_number
        ? doc.report_number.trim().toLowerCase().includes(str)
        : false;
      const dateCompletedFilter = doc.date_completed
        ? getDateString(doc.date_completed).trim().toLowerCase().includes(str)
        : false;
      const dateDueFilter = doc.date_due
        ? getDateString(doc.date_due).trim().toLowerCase().includes(str)
        : false;
      const dateSubmittedFilter = doc.date_submitted
        ? getDateString(doc.date_submitted).trim().toLowerCase().includes(str)
        : false;
      const reportStartFilter = doc.report_or_audit_period_start
        ? getDateString(doc.report_or_audit_period_start).trim().toLowerCase().includes(str)
        : false;
      const reportEndFilter = doc.report_or_audit_period_end
        ? getDateString(doc.report_or_audit_period_end).trim().toLowerCase().includes(str)
        : false;
      return (
        reportNameFilter ||
        insuranceCompanyFilter ||
        reportStatusFilter ||
        reportNumberFilter ||
        dateCompletedFilter ||
        dateDueFilter ||
        dateSubmittedFilter ||
        reportStartFilter ||
        reportEndFilter
      );
    });
  };

  function getTalentStatusIcon(doc: IWorkCompClaim | IUnEmploymentClaim) {
    return (
      <div className="status-icon">
        <TalentActiveStatusIcon
          width={"100%"}
          height={"100%"}
          style={{
            color:
              doc.on_assignment === true
                ? THEME.buttonColor13
                : THEME.statusDisabledColor,
          }}
        />
      </div>
    );
  }
  function getStatus(status: string) {
    if (status === "OPEN")
      return (
        <span style={{ color: THEME.statusInActiveColor }}>{t("open")}</span>
      );
    else if (status === "CLOSED")
      return <span style={{ color: "#b1b1b1" }}>{t("closed")}</span>;
    else return <span>{""}</span>;
  }
  function getStatusField(doc: IWorkCompClaim | IUnEmploymentClaim) {
    return (
      <div className="risk-ud-table-name">
        <div className="status-icon">
          <ClaimStatusIcon
            width={"100%"}
            height={"100%"}
            style={{
              color:
                doc.claim_status === "OPEN"
                  ? THEME.statusInActiveColor
                  : THEME.statusDisabledColor,
            }}
          />
        </div>
        <div className="name-txt">
          <span>{getStatus(doc.claim_status)}</span>
        </div>
      </div>
    );
  }
  const getDateFromTimeStamp = (value: number) => {
    const fieldValue: any = value ? new Date(value * 1000) : "";
    return (
      <div>
        <Moment format="MM.DD.YYYY">{fieldValue}</Moment>
      </div>
    );
  };
  function handleAddWccSuccess() {
    setAddClaim(false);
    dispatch(workCompClaimsListActions.clearWorkCompClaimDocState());
    dispatch(workCompClaimsListActions.clearWorkCompClaimSaveState());
    dispatch(unEmploymentClaimsListActions.clearUnEmploymentClaimDocState());
    dispatch(unEmploymentClaimsListActions.clearUnEmploymentClaimSaveState());
    getWorkCompClaims();
    getUnEmploymentClaims();
  }
  function handleGotoAgencyDashboard() {
    handleAddWccSuccess();
    props.history.push(AppRoutes.dashboardPage);
  }

  function handleWCCSelect(value: IWorkCompClaim) {
    props.history.push({
      pathname: `${AppRoutes.workCompClaimDetailsPage}/${value.talent_id}`,
      state: {
        id: value.talent_id,
        name: `${value.employee_name ?? ""}`,
      },
    });
    return;
  }
  function handleUserSelect(value: IWorkCompClaim | IUnEmploymentClaim) {
    props.history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
      state: {
        id: value.talent_id,
        name: value.employee_name,
      },
    });
    return;
  }

  function handleInsuranceCompanySelect(
    value: IWorkCompAuditAndComplianceReporting
  ) {
    props.history.push({
      pathname: `${AppRoutes.workCompComplianceReportDetailsPage}/${value.id}`,
      state: {
        id: value.id,
        name: value.insurance_company_name,
        value: value,
      },
    });
    return;
  }

  function handleGoToWCCSelect(id: string, name: string, type: string) {
    handleAddWccSuccess();

    props.history.push({
      pathname: `${type === "WORKCOMPCLAIM"
        ? AppRoutes.workCompClaimDetailsPage
        : AppRoutes.unEmplomentDetailsPage
        }/${id}`,
      state: {
        id: id,
        name: name,
      },
    });
    return;
  }

  const handleAddNewWCCRSuccess = (
    data: IUpdateReport,
    attachedDocs: Array<File> | null
  ) => {
    setWccrAttachedDocs(attachedDocs);
    dispatch(workCompAuditAndComplianceReportingListActions.clearWCCRCreateState());
    dispatch(workCompAuditAndComplianceReportCreate(data));
    dispatch(workCompAuditAndComplianceReportingListActions.clearWCCRUploadState());
  };

  return (
    <div className="risk-management-ud-container">
      <div className="card-div wc-claim">
        <div className="rsk-mng-ud-table-header">
          <div className="header-left">
            <div className="claim-field-row">
              <div>
                <ClaimStatusIcon
                  width={"1.5vw"}
                  height={"1.5vw"}
                  style={{ color: THEME.defaultHighLightColor }}
                />
              </div>
              <div
                className="claim-field-text"
                style={{ marginLeft: "15px", fontWeight: 900, fontSize: "1vw" }}
              >
                {t("work_comp_claims")}
              </div>
            </div>
            <div>
              <TableHeaderOptions
                totalCount={getWCCFilteredList().length}
                search={workCompClaimsSearch}
                onSearchChange={(value) => setWorkCompClaimsSearch(value)}
                openStatus={wccStatus}
                onCheckedChange={() => setWCCStatus(!wccStatus)}
              />
            </div>
          </div>
          <div className="rsk-mng-ud-actions">
            <div className="rsk-mng-ud-add-user-btn">
              <div className="rsk-mng-ud-search-btn">
                <SearchBar
                  value={workCompClaimsSearch}
                  onChange={(value) => setWorkCompClaimsSearch(value)}
                  onSearch={() => { }}
                />
              </div>
              <div>
                <CustomButton
                  leftIcon={getPlusBtnIcon()}
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("add_work_comp_claim")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => {
                    setAddClaim(true);
                    setAddType("WORKCOMPCLAIM");
                    setStateTalentData({
                      talent_id: "",
                      talent_ssn: "",
                      employee_name: "",
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="rsk-mng-ud-container">
          <div className="rsk-mng-ud-table">{getWorkCompClaimsTableList()}</div>
        </div>
      </div>
      <div className="card-div unemp-claim">
        <div className="rsk-mng-ud-table-header">
          <div className="header-left">
            <div className="claim-field-row">
              <div>
                <ClaimStatusIcon
                  width={"1.5vw"}
                  height={"1.5vw"}
                  style={{ color: THEME.defaultHighLightColor }}
                />
              </div>
              <div
                className="claim-field-text"
                style={{ marginLeft: "15px", fontWeight: 900, fontSize: "1vw" }}
              >
                {t("unemployment_claims")}
              </div>
            </div>
            <TableHeaderOptions
              totalCount={getUCFilteredList().length}
              search={unEmploymentClaimsSearch}
              onSearchChange={(value) => setunEmploymentClaimsSearch(value)}
              openStatus={ucStatus}
              onCheckedChange={() => setUCStatus(!ucStatus)}
            />
          </div>
          <div className="rsk-mng-ud-actions">
            <div className="rsk-mng-ud-add-user-btn">
              <div className="rsk-mng-ud-search-btn">
                <SearchBar
                  value={unEmploymentClaimsSearch}
                  onChange={(value) => setunEmploymentClaimsSearch(value)}
                  onSearch={() => { }}
                />
              </div>
              <div>
                <CustomButton
                  leftIcon={getPlusBtnIcon()}
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("add_unemployment_claim")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => {
                    setAddClaim(true);
                    setAddType("UNEMPLOYMENT");
                    setStateTalentData({
                      talent_id: "",
                      talent_ssn: "",
                      employee_name: "",
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="rsk-mng-ud-container">
          <div className="rsk-mng-ud-table">
            {getUnemploymentClaimsTableList()}
          </div>
          {addClaim && (
            <Portal>
              <CreateWorkCompClaim
                type={addType}
                visible={addClaim}
                title={getPopupTitle(addType).title}
                successTitle={getPopupTitle(addType).successTitle}
                onClose={() => {
                  setAddClaim(false);
                  dispatch(
                    workCompCodesListActions.clearWorkCompCodesListState()
                  );
                }}
                onSuccessClose={handleAddWorkCompClaomSuccess}
                onGotoAgencyDashboard={handleGotoAgencyDashboard}
                onGotoCreated={handleGoToWCCSelect}
                stateTalentData={stateTalentData}
              />
            </Portal>
          )}
        </div>
      </div>
      <div className="card-div wc-audit-claim">
        <div className="rsk-mng-ud-table-header">
          <div className="header-left">
            <div className="claim-field-row">
              <div>
                <ClaimStatusIcon
                  width={"1.5vw"}
                  height={"1.5vw"}
                  style={{ color: THEME.defaultHighLightColor }}
                />
              </div>
              <div
                className="claim-field-text"
                style={{ marginLeft: "15px", fontWeight: 900, fontSize: "1vw" }}
              >
                {t("work_comp_audit_and_compliance_reporting")}
              </div>
            </div>
            <div className="rsk-mng-ud-table-header-options">
              <div className="total-count">
                <span>
                  {`${t("total_count")}: `}
                  <span className="total-count-number">
                    {getWCCRFilteredList().length}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="rsk-mng-ud-actions">
            <div className="rsk-mng-ud-add-user-btn">
              <div className="rsk-mng-ud-search-btn">
                <SearchBar
                  value={wccrSearch}
                  onChange={(value) => setWccrSearch(value)}
                  onSearch={() => { }}
                />
              </div>
              <div>
                <CustomButton
                  leftIcon={getPlusBtnIcon()}
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("add_report")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => {
                    setAddNewWCCR(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="rsk-mng-ud-container">
          <div className="rsk-mng-ud-table">{getWCCRTableList()}</div>
          {addNewWCCR && (
            <Portal>
              <CreateReportPopup
                visible={addNewWCCR}
                title={"New " + t("work_comp_audit_and_compliance_reporting")}
                onClose={() => {
                  setAddNewWCCR(false);
                }}
                onSubmit={handleAddNewWCCRSuccess}
                state={wccrCreateState}
              />
            </Portal>
          )}
        </div>
      </div>
    </div>
  );

  function getPopupTitle(type: string) {
    if (type === "WORKCOMPCLAIM")
      return {
        title: t("new_work_comp_claim"),
        successTitle: t("new_work_comp_claim"),
      };
    return {
      title: t("new_unemployment_claim"),
      successTitle: t("new_unemployment_claim"),
    };
  }

  /// Work Comp Claim table list
  function getWorkCompClaimsTableList() {
    if (workCompClaimsState.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (workCompClaimsState.error != null && workCompClaimsList.length === 0) {
      return (
        <TableErrorHandler
          error={workCompClaimsState.error}
          onRefresh={getWorkCompClaims}
        />
      );
    }
    if (workCompClaimsList.length === 0) {
      return (
        <TableEmpty
          title={t("no_data_found")}
          onClick={() => getWorkCompClaims()}
        />
      );
    }
    if (getWCCFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_data_found")} />;
    }
    return (
      <SortableTable
        headerList={wcc_tableHeader}
        sortedField={wcc_sortedField}
        onSortChange={handleSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={wcc_sortDirection}
      >
        {getWCCFilteredList().map((doc, index) => {
          return (
            <tr key={doc.id}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                {getTableAccessIcon(() => handleWCCSelect(doc))}
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(1) }}
                isButton={true}
                onClick={() => handleUserSelect(doc)}
                prefix={getTalentStatusIcon(doc)}
              >
                <span>{doc.employee_name}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(1) }}>
                <span>{getStatusField(doc)}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(1) }}>
                <span>
                  {doc.potential_charges
                    ? `${"$"}${moneyFormat(doc.potential_charges)}`
                    : ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(4) }}>
                <span>
                  {doc.cost_to_date
                    ? `${"$"}${moneyFormat(doc.cost_to_date)}`
                    : ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                <span>{doc.claim_number ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(6) }}>
                {doc.reported_date
                  ? getDateFromTimeStamp(doc.reported_date)
                  : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(7) }}>
                {doc.date_closed ? getDateFromTimeStamp(doc.date_closed) : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(8) }}>
                <span>{doc.company_name ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(9) }}>
                <span
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 300,
                  }}
                >
                  {doc.next_steps ?? ""}
                </span>
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 0.7;
    if (value === 1) return 1.2;
    if (value === 2) return 1;
    if (value === 3) return 1.2;
    if (value === 4) return 1;
    if (value === 5) return 1.2;
    if (value === 6) return 0.8;
    if (value === 7) return 0.8;
    if (value === 8) return 0.8;
    return 1;
  }

  ///Add Work Comp Claim action
  function handleAddWorkCompClaomSuccess() {
    setAddClaim(false);
    dispatch(workCompClaimsListActions.clearWorkCompClaimDocState());
    dispatch(workCompClaimsListActions.clearWorkCompClaimSaveState());
    dispatch(unEmploymentClaimsListActions.clearUnEmploymentClaimDocState());
    dispatch(unEmploymentClaimsListActions.clearUnEmploymentClaimSaveState());
    getWorkCompClaims();
  }

  //Unemployment Claims

  function getUnemploymentClaimsTableList() {
    if (unEmploymentClaimsState.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (
      unEmploymentClaimsState.error != null &&
      unEmploymentClaimsList.length === 0
    ) {
      return (
        <TableErrorHandler
          error={unEmploymentClaimsState.error}
          onRefresh={getUnEmploymentClaims}
        />
      );
    }
    if (unEmploymentClaimsList.length === 0) {
      return (
        <TableEmpty
          title={t("no_data_found")}
          onClick={() => getUnEmploymentClaims()}
        />
      );
    }
    if (getUCFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_data_found")} />;
    }
    return (
      <SortableTable
        headerList={uc_tableHeader}
        sortedField={uc_sortedField}
        onSortChange={handleUCSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={uc_sortDirection}
      >
        {getUCFilteredList().map((doc, index) => {
          return (
            <tr key={doc.id}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                {getTableAccessIcon(() => handleUnEmpClick(doc))}
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(1) }}
                isButton={true}
                onClick={() => handleUserSelect(doc)}
                prefix={getTalentStatusIcon(doc)}
              >
                <span>{doc.employee_name}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(2) }}>
                <span>{getStatusField(doc)}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(3) }}>
                <span>
                  {doc.potential_charges
                    ? `${"$"}${moneyFormat(doc.potential_charges)}`
                    : ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(4) }}>
                {doc.cost_to_date
                  ? `${"$"}${moneyFormat(doc.cost_to_date)}`
                  : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                <span>{doc.claim_number ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(6) }}>
                {doc.claim_date ? getDateString(doc.claim_date) : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(7) }}>
                {doc.date_closed ? getDateString(doc.date_closed) : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(8) }}>
                <span
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 300,
                  }}
                >
                  {doc.next_steps ?? ""}
                </span>
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function getReportPeriod(doc: IWorkCompAuditAndComplianceReporting) {
    return (
      (doc.report_or_audit_period_start
        ? getDateString(doc.report_or_audit_period_start)
        : "") +
      " - " +
      (doc.report_or_audit_period_end
        ? getDateString(doc.report_or_audit_period_end)
        : "")
    );
  }

  function getReportStatus(status: string) {
    if (status === "Due")
      return (
        <span style={{ color: THEME.statusInActiveColor }}>{t("due")}</span>
      );
    else if (status === "Complete & Submitted")
      return <span style={{ color: "#b1b1b1" }}>{t("completed")}</span>;
    else return <span>{""}</span>;
  }

  function getWCCRTableList() {
    if (workCompAuditAndComplianceReportState.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (
      workCompAuditAndComplianceReportState.error != null &&
      workCompAuditAndComplianceReportList.length === 0
    ) {
      return (
        <TableErrorHandler
          error={workCompAuditAndComplianceReportState.error}
          onRefresh={getAllWorkCompAuditAndComplianceReports}
        />
      );
    }
    if (workCompAuditAndComplianceReportList.length === 0) {
      return (
        <TableEmpty
          title={t("no_data_found")}
          onClick={() => getAllWorkCompAuditAndComplianceReports()}
        />
      );
    }
    if (getWCCRFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_data_found")} />;
    }
    return (
      <SortableTable
        headerList={wccr_tableHeader}
        sortedField={wccrSortedField}
        onSortChange={handleWCCRSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={wccrSortDirection}
      >
        {getWCCRFilteredList().map((doc, index) => {
          return (
            <tr key={doc.id}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                {getTableAccessIcon(() => handleInsuranceCompanySelect(doc))}
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(1) }}
                isButton={true}
                onClick={() => handleInsuranceCompanySelect(doc)}
              >
                <span>{doc.insurance_company_name}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(2) }}>
                {getReportStatus(doc.report_status)}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(3) }}>
                <span>{doc.report_name}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(4) }}>
                {doc.report_number}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                <span>{getReportPeriod(doc)}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(6) }}>
                {doc.date_due ? getDateString(doc.date_due) : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(7) }}>
                {doc.date_completed ? getDateString(doc.date_completed) : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(8) }}>
                {doc.date_submitted ? getDateString(doc.date_submitted) : ""}
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function handleWCCRSortFieldChange(value: string) {
    if (wccrSortedField && wccrSortedField === value) {
      setWccrSortDirection(wccrSortDirection === "asc" ? "desc" : "asc");
    } else {
      setWccrSortedField(value);
      setWccrSortDirection("asc");
    }
  }

  function handleUnEmpClick(doc: IUnEmploymentClaim) {
    props.history.push({
      pathname: `${AppRoutes.unEmplomentDetailsPage}/${doc.talent_id}`,
      state: {
        id: doc.talent_id,
        name: `${doc.employee_name ?? ""}`,
      },
    });
    return;
  }

  function getUCFilteredList(): IUnEmploymentClaim[] {
    const list = ucStatus ? getOnlyUCOpenStatus() : unEmploymentClaimsList;
    let sortedList: IUnEmploymentClaim[] | undefined;

    if (uc_sortedField != null) {
      sortedList = [...list].sort((a, b) => {
        const valueA =
          a[uc_sortedField] != null
            ? typeof a[uc_sortedField] != typeof 1
              ? a[uc_sortedField].trim().toLowerCase()
              : a[uc_sortedField]
            : "";
        const valueB =
          b[uc_sortedField] != null
            ? typeof b[uc_sortedField] != typeof 1
              ? b[uc_sortedField].trim().toLowerCase()
              : b[uc_sortedField]
            : "";
        if (uc_sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return (sortedList ?? list).filter((doc) => {
      const str = unEmploymentClaimsSearch.trim().toLowerCase();
      const nameFilter = doc.employee_name
        ? doc.employee_name.trim().toLowerCase().includes(str)
        : false;
      const claimNumberFilter = doc.claim_number
        ? doc.claim_number.toString().trim().toLowerCase().includes(str)
        : false;
      const claimStatusFilter = doc.claim_status
        ? doc.claim_status.trim().toLowerCase().includes(str)
        : false;
      const potentialChargeFilter = doc.potential_charges
        ? convertNumberToMoney(doc.potential_charges)
          .toString()
          .trim()
          .toLowerCase()
          .includes(str)
        : false;
      const costToDateFilter = doc.cost_to_date
        ? convertNumberToMoney(doc.cost_to_date)
          .toString()
          .trim()
          .toLowerCase()
          .includes(str)
        : false;
      const nextStepsFilter = doc.next_steps
        ? doc.next_steps.toString().trim().toLowerCase().includes(str)
        : false;
      const companyNameFilter = doc.company
        ? doc.company.toString().trim().toLowerCase().includes(str)
        : false;
      return (
        nameFilter ||
        claimNumberFilter ||
        claimStatusFilter ||
        potentialChargeFilter ||
        costToDateFilter ||
        nextStepsFilter ||
        companyNameFilter
      );
    });
  }

  function getOnlyUCOpenStatus() {
    return unEmploymentClaimsList.filter((doc) => doc.claim_status === "OPEN");
  }
  function handleUCSortFieldChange(value: string) {
    if (uc_sortedField && uc_sortedField === value) {
      uc_setSortDirection(uc_sortDirection === "asc" ? "desc" : "asc");
    } else {
      uc_setSortedField(value);
      uc_setSortDirection("asc");
    }
  }
};

interface TableHeaderOptionsProps {
  totalCount: number;
  search: string;
  onSearchChange: (value: string) => void;
  openStatus: boolean;
  onCheckedChange: () => void;
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
  const { t } = useTranslation();
  return (
    <div className="rsk-mng-ud-table-header-options">
      <div className="deparment-toggle">
        <CustomCheckBox
          name={"department"}
          title={"Show only Open Claims"}
          checked={props.openStatus}
          onClick={props.onCheckedChange}
        />
      </div>
      <div className="total-count">
        <span>
          {`${t("total_count")}: `}
          <span className="total-count-number">{props.totalCount}</span>
        </span>
      </div>
    </div>
  );
};

export default UniversalDirectoryPage;
