import pdf from "../assets/files/pdf.png";
import txt from "../assets/files/txt.png";
import tif from "../assets/files/tif.png";
import tiff from "../assets/files/tiff.png";
import jpg from "../assets/files/jpg.png";
import jpeg from "../assets/files/jpeg.png";
import png from "../assets/files/png.png";
import doc from "../assets/files/doc.png";
import docx from "../assets/files/docx.png";
import ppt from "../assets/files/ppt.png";
import pptx from "../assets/files/pptx.png";
import csv from "../assets/files/csv.png";
import xls from "../assets/files/xls.png";
import xlsx from "../assets/files/xlsx.png";
import xml from "../assets/files/xml.png";
import htm from "../assets/files/htm.png";
import html from "../assets/files/html.png";
import json from "../assets/files/json.png";
import odt from "../assets/files/odt.png"
import unknown from "../assets/files/unknown.png";

export const FILE_ICONS = {
    pdf: pdf,
    txt: txt,
    tif: tif,
    tiff: tiff,
    jpg: jpg,
    jpeg: jpeg,
    png: png,
    doc: doc,
    docx: docx,
    ppt: ppt,
    pptx: pptx,
    csv: csv,
    xls: xls,
    xlsx: xlsx,
    xml: xml,
    htm: htm,
    html: html,
    json: json,
    odt: odt,
    unknown: unknown,
}