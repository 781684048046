import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './talent_list_reducer';
import { selectSingleJobInterviewsToClientCompaniesInitialState, selectSingleState, selectState } from "./talent_list_reducer";

const select = (state: IRootState) => state.jobs.details.talent.talentList;
export const selectJobInterviewsToClientCompaniesState = createSelector(select, selectState);

export const selectSingleJobInterviewsToClientCompaniesStateById = (id: string) => createSelector(
    select, 
    (state) => {
        if (id && state[id]) return selectSingleState(state, id);
        return selectSingleJobInterviewsToClientCompaniesInitialState();
    }
);

export const selectSingleJobInterviewsToClientCompaniesList = (id: string) => createSelector(
    select, 
    (state) => {
        if (id && state[id]) {
            const data = selectSingleState(state, id).data;
            return data ? Object.keys(data).map((key) => data[key]) : [];
        }
        return [];
    }
);