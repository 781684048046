import { IMasterTaxCredntials, IMasterTaxSetup } from "../interfaces/master_tax";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const IntegrationAPI = {
    getMasterTaxAuth() {
        return interceptor.get(UrlBuilder.masterTaxAuth);
    },
    updateMasterTaxAuth(doc: IMasterTaxCredntials) {
        return interceptor.patch(UrlBuilder.masterTaxAuth, doc);
    },
    getMasterTaxConfig() {
        return interceptor.get(UrlBuilder.masterTaxConfig);
    },
    updateMasterTaxConfig(doc: IMasterTaxSetup) {
        return interceptor.patch(UrlBuilder.masterTaxConfig, doc);
    },
    getMasterExportCompanySetup() {
        return interceptor.get(UrlBuilder.masterTaxExportCompanySetup);
    }
};
