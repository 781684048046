
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDepartmentsDropdown, getReportDownloadURL, getRoleUsersList, selectAccountManagerRoleUsersList, selectDepartmentsDropdownList, selectRecruiterRoleUsersList, selectReportsDownloadState, useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import { LoadingType, THEME } from '../../../../../enums';
import TableEmpty, { TableErrorHandler, TableLoading } from '../../../../../components/table_empty/table_empty';
import SortableTable, { TableData } from '../../../../../components/sortable_table/sortable_table';
import { currencyConversion, CustomButton } from '../../../../../utils';
import TabOptionsHeader from '../../../../../components/tab_options_header/tab_options_header';
import CustomFormSelect, { CustomMultiFormSelect } from '../../../../../components/form_selector/form_select';
import { DownloadIcon } from '../../../../../icons';
import { IRCOpenJobOrders, IRCSingleOpenJobOrder } from '../../../../../interfaces/report_center';
import { getOpenJobOrdersReportList } from '../../../../../redux/report_center/job_orders/open_job_orders_report/open_job_orders_report_thunk';
import { selectOpenJobOrdersReportListState } from '../../../../../redux/report_center/job_orders/open_job_orders_report/open_job_orders_report_selector';
import { getDateString } from '../../../../../variables';
import { AppRoutes } from '../../../../../routes';
import { departmentsDropdownListActions } from '../../../../../redux/selectors/departments_list/departments_list_reducer';
import "./open_job_orders_report.scss";
import SearchBar from '../../../../../components/search_bar';
import UrlBuilder from '../../../../../apis/url_builder';
import { reportDownloadSliceActions } from '../../../../../redux/report_center/download_report/download_report_reducer';

interface Props extends RouteComponentProps<any> { }
const JobOrdersReport: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { location, history } = props;

    const tableHeader = [
        { title: "customer_name", code: "company_name" },
        { title: "department", code: "department_name" },
        { title: "order_date", code: "order_date" },
        { title: "hiring_manager", code: "hiring_manager" },
        { title: "job_title", code: "job_title" },
        { title: "start_date", code: "start_date" },
        { title: "end_date", code: "end_date" },
        { title: "pay_rate", code: "pay_rate" },
        { title: "bill_rate", code: "bill_rate" },
        { title: "required", code: "requested" },
        { title: "placed", code: "placed" },
        { title: "open", code: "open_orders" },
    ];

    const departmentsList = useAppSelector((state) => selectDepartmentsDropdownList(state));
    const accountManagersList = useAppSelector((state) => selectAccountManagerRoleUsersList(state));
    const recruitersList = useAppSelector((state) => selectRecruiterRoleUsersList(state));
    const openJobOrdersReportListState = useAppSelector((state) => selectOpenJobOrdersReportListState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));

    const [openJobOrdersReportList, setOpenJobOrdersReportList] = useState<IRCOpenJobOrders | null>(null);
    const [company, setCompany] = useState<string>("");
    const [companiesList, setCompaniesList] = useState<{ label: string, value: string }[]>([])
    const [department, setDepartment] = useState<string>("");
    const [accountManagers, setAccountManagers] = useState<string[]>([]);
    const [recruiters, setRecruiters] = useState<string[]>([]);
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");

    useEffect(() => {
        getOpenJobOrderReportList()
        dispatch(getRoleUsersList());
    }, []);

    useEffect(() => {
        if (company) {
            dispatch(getDepartmentsDropdown(company));
        }
        else {
            dispatch(
                departmentsDropdownListActions.clearDepartmentsDropdownListState()
            );
            setDepartment("");
        }
    }, [company]);

    useEffect(() => {
        if (openJobOrdersReportListState && openJobOrdersReportListState?.loading === LoadingType.succeeded) {
            setOpenJobOrdersReportList(openJobOrdersReportListState?.response);
            const updatedCompaniesList = openJobOrdersReportListState.response?.job_orders?.reduce<{ label: string; value: string }[]>((acc, company) => {
                if (!acc.some(item => item.value === company.company_id)) {
                    acc.push({ label: company.company_name, value: company.company_id });
                }
                return acc;
            }, [])!;
            setCompaniesList(updatedCompaniesList);
        }
    }, [openJobOrdersReportListState?.loading]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    function handleDownload() {
        dispatch(getReportDownloadURL(
            {
                baseUrl: UrlBuilder.openJobOrdersReport,
                company_id: company,
                department_id: department,
                download: true,
                downloadAR: { account_managers: accountManagers, recruiters: recruiters },
            }));
    }

    const getOpenJobOrderReportList = () => {
        dispatch(getOpenJobOrdersReportList())
    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };
    function handleCompanySelect(value: IRCSingleOpenJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            },
        });
        return;
    }
    function handleDepartmentSelect(value: IRCSingleOpenJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.department_id,
                departmentName: value.department_name,
            },
        });
        return;
    }
    function handleJobTitleSelect(value: IRCSingleOpenJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${value.id}`,
            state: {
                id: value.id,
                name: value.job_title,
            },
        });
        return;
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 0.5;
        if (value === 10) return 0.5;
        if (value === 11) return 0.5;
        return 1;
    }

    const showSpinner = () => openJobOrdersReportListState?.loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: IRCSingleOpenJobOrder[] | undefined = openJobOrdersReportList?.job_orders;
        let resultList: IRCSingleOpenJobOrder[] | undefined;

        if (openJobOrdersReportList && sortedField !== null) {
            resultList = [...openJobOrdersReportList?.job_orders].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        resultList = list?.filter(doc => {
            // Check for criteria
            const hasCompany = company && company !== "";
            const hasDepartment = department && department !== "";
            const hasAccountManagers = accountManagers.length > 0;
            const hasRecruiters = recruiters.length > 0;

            // Check company and department filters
            const companyMatch = !hasCompany || doc.company_id === company;
            const departmentMatch = !hasDepartment || doc.department_id === department;

            // Check account managers filter
            const accountManagersMatch = !hasAccountManagers || (
                doc.account_managers &&
                doc.account_managers.length > 0 &&
                doc.account_managers.some(manager => accountManagers.includes(manager))
            );

            // Check recruiters filter
            const recruitersMatch = !hasRecruiters || (
                doc.recruiters &&
                doc.recruiters.length > 0 &&
                doc.recruiters.some(recruiter => recruiters.includes(recruiter))
            );

            // Return true if all required conditions are met
            return companyMatch && departmentMatch && accountManagersMatch && recruitersMatch;
        });

        return (resultList ?? list)?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (openJobOrdersReportListState?.error != null && openJobOrdersReportList?.job_orders?.length === 0) {
            return <TableErrorHandler error={openJobOrdersReportListState?.error} onRefresh={getOpenJobOrderReportList} />;
        }

        if (openJobOrdersReportList?.job_orders?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No Jobs found"} onClick={getOpenJobOrderReportList} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <div className='ojhr-table'>
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList()?.map((doc) => {
                            return (
                                <tr key={doc.id}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}
                                        isButton={true}
                                        onClick={() => handleCompanySelect(doc)}
                                    >
                                        <span>{doc.company_name}</span>
                                    </TableData>
                                    <TableData
                                        customStyle={{ flex: getFlexNumber(1) }}
                                        isButton={true}
                                        onClick={() => handleDepartmentSelect(doc)}
                                    >
                                        <span>{doc.department_name !== "" ? doc.department_name : t("corporate")}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                        <span>{getDateString(doc.order_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                        <span>{doc.hiring_manager}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(4) }}
                                        isButton={true}
                                        onClick={() => handleJobTitleSelect(doc)}
                                    >
                                        <span>{doc.job_title}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                        <span>{getDateString(doc.start_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                        <span>{getDateString(doc.end_date)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                        <span>{currencyConversion(doc.pay_rate)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                        <span>{currencyConversion(doc.bill_rate)}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(9), color: THEME.statusInActiveColor }}>
                                        <span>{doc.requested}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(10), color: THEME.buttonColor17 }}>
                                        <span>{doc.placed}</span>
                                    </TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(11), color: THEME.statusInActiveColor }}>
                                        <span>{doc.open_orders}</span>
                                    </TableData>
                                </tr>
                            )
                        })}
                    </SortableTable>
                </div>
            );
        }
    };

    return (
        <div className="open-job-orders-reports-container">
            <div className="open-job-orders-reports-header">
                <div className="main-header">
                    <div className='cmp-dropdown'>
                        <CustomFormSelect
                            label={t("customer_name")}
                            name={"company_id"}
                            value={company}
                            placeholder={"All"}
                            list={companiesList?.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={(value) => {
                                setCompany(value);
                            }}
                        />
                    </div>
                    <div className='cmp-dropdown'>
                        <CustomFormSelect
                            label={t("department")}
                            name={"department_id"}
                            value={department}
                            placeholder={"All"}
                            list={departmentsList?.map((doc) => ({
                                label: doc.name,
                                value: doc.id,
                            }))}
                            onChange={(value) => {
                                setDepartment(value);
                            }}
                            disabled={company && company !== "" ? false : true}
                        />
                    </div>
                    <div className='cmp-dropdown'>
                        <CustomMultiFormSelect
                            label={t("account_manager")}
                            name={"account_manager"}
                            value={accountManagers}
                            placeholder={"All"}
                            list={accountManagersList?.map((doc) => ({
                                label: `${doc.first_name ?? ''} ${doc.last_name ?? ''}`,
                                value: doc.id,
                            }))}
                            onChange={(value) => {
                                setAccountManagers(value);
                            }}
                        />
                    </div>
                    <div className='cmp-dropdown'>
                        <CustomMultiFormSelect
                            label={t("recruiter")}
                            name={"recruiter"}
                            value={recruiters}
                            placeholder={"All"}
                            list={recruitersList.map((doc) => ({
                                label: `${doc.first_name ?? ''} ${doc.last_name ?? ''}`,
                                value: doc.id
                            }))}
                            onChange={(value) => {
                                setRecruiters(value);
                            }}
                        />
                    </div>
                </div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>

            <div className="table-container">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t("open_job_orders_report")}</span>
                        </div>
                    </div>
                    <div className="rc-scsr-btns">
                        <div className="total-count-action">
                            <span>
                                {`${t("total_count")}: `}
                                <span className="total-count-number">
                                    {getFilteredList()?.length}
                                </span>
                            </span>
                        </div>
                        <div className="rc-scsr-btn">
                            <CustomButton
                                leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                                loading={false}
                                name={t("download")}
                                enable={getFilteredList()?.length !== 0 && true}
                                backgroundColor={THEME.buttonColor16}
                                onClick={() => handleDownload()}
                            />
                        </div>
                        <div>
                            <SearchBar
                                value={search}
                                onChange={setSearch}
                                onSearch={() => { }}
                            />
                        </div>
                    </div>
                </div>
                {getTable()}
            </div>
            {openJobOrdersReportListState.loading === LoadingType.succeeded && <div>
                <div className="custom-tab">
                    <div className="tab-title">
                        <span>Total Job Orders:</span>
                    </div>
                    <div className='tab-requested-value'>
                        <span>
                            {openJobOrdersReportList && openJobOrdersReportList?.job_orders.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.requested
                            }, 0) : 0}
                        </span>
                        Required
                    </div>
                    <div className='tab-placed-value'>
                        <span>
                            {openJobOrdersReportList?.job_orders && openJobOrdersReportList?.job_orders.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.placed
                            }, 0) : 0}
                        </span>
                        Placed
                    </div>
                    <div className='tab-open-value'>
                        <span>
                            {openJobOrdersReportList?.job_orders && openJobOrdersReportList?.job_orders.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.open_orders
                            }, 0) : 0}
                        </span>
                        Open
                    </div>
                </div>
                <div className="call-counts">
                    <div className="total-count">
                        Number of Unique Open Positions
                    </div>
                    <div className="total-count">
                        {openJobOrdersReportListState?.response?.no_of_unique_open_positions}
                    </div>
                </div>
                <div className="call-counts">
                    <div className="total-count">
                        Number of Customers with Open Orders
                    </div>
                    <div className="total-count">
                        {openJobOrdersReportListState?.response?.no_of_customers_open_orders}
                    </div>
                </div>
            </div>}
        </div>
    );


}

export default JobOrdersReport;