import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import {
  selectAgencyOnboardingState,
  selectAgencyOnboardingFileURL,
  selecAgencyOnboardingtUpload,
  selecAgencyOnboardingDownload,
  selectUpdateState,
} from "./onboarding_reducer";

const select = (state: IRootState) => state.adminCenter.agencyOnboarding;
export const selectAgencyOnboardingListState = createSelector(
  select,
  selectAgencyOnboardingState
);

export const selectOnboardingUpdatestate = createSelector(
  select,
  selectUpdateState
);
export const selectAgencyOnboardingFileURLState = createSelector(select, selectAgencyOnboardingFileURL);
export const selectAgencyOnboardingFileUploadState = createSelector(select, selecAgencyOnboardingtUpload);
export const selectAgencyOnboardingFileDownloadState = createSelector(select, selecAgencyOnboardingDownload);
