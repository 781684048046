import { createSelector } from "@reduxjs/toolkit";
import { ROLE } from "../../../enums";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./role_users_list_reducer";

const select = (state: IRootState) => state.selectors.roleUsersList;
export const selectRoleUsersListState = createSelector(select, selectState);
export const selectRoleUsersList = createSelector(
    select,
    selectAll
);
export const selectRoleUsersListEntities = createSelector(
    select,
    selectEntities
);

export const selectAccountManagerRoleUsersList = createSelector(
    selectRoleUsersList,
    (docs) => docs.filter((doc,index) => doc.roles.filter((user, index)=> user.role === ROLE.accountManager).length != 0)
);

export const selectRecruiterRoleUsersList = createSelector(
    selectRoleUsersList,
    (docs) => docs.filter((doc,index) => doc.roles.filter((user, index)=> user.role === ROLE.recruiter).length != 0)
);

export const selectAccountManagerAndRecruiterRoleUsersList = createSelector(
    selectRoleUsersList,
    (docs) => docs.filter((doc,index) => doc.roles.filter((user, index)=> (user.role === ROLE.accountManager || user.role === ROLE.recruiter)).length != 0)
);