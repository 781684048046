import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ITrafficEngagement } from "../../../../interfaces";
import * as thunk from "./traffic_engagement_thunk";

export interface TrafficLightEngagementState extends EntityState<ITrafficEngagement> {
    error?: AppError | null,
    loading: LoadingType,
}

const TrafficLightEngagementAdapter: EntityAdapter<ITrafficEngagement> = createEntityAdapter<ITrafficEngagement>({
    selectId: (doc) => doc.id,
})

const initialState: TrafficLightEngagementState = TrafficLightEngagementAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const trafficLightEngagementSlice = createSlice({
    name: 'trafficLightEngagement',
    initialState: initialState,
    reducers: {
        clearTrafficLightEngagementError(state) {
            return { ...state, error: null };
        },
        clearTrafficLightEngagementState(state) {
            return TrafficLightEngagementAdapter.removeAll({ ...state, loading: LoadingType.idle, error: null });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getDashboardTrafficEngagement.pending,
                (state, action) => ({
                    ...state,
                    loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
                    error: null,
                })
            )
            .addCase(
                thunk.getDashboardTrafficEngagement.rejected,
                (state, action) => ({
                    ...state,
                    loading: LoadingType.failed,
                    error: action.payload,
                })
            )
            .addCase(
                thunk.getDashboardTrafficEngagement.fulfilled,
                (state, action) => TrafficLightEngagementAdapter.setAll({
                    ...state,
                    loading: LoadingType.succeeded,
                    error: null
                },
                    action.payload
                )
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearTrafficLightEngagementError, clearTrafficLightEngagementState } = trafficLightEngagementSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = TrafficLightEngagementAdapter.getSelectors();
export const selectState = (state: TrafficLightEngagementState) => state;
export const selectLoading = (state: TrafficLightEngagementState) => state.loading;
export const selectError = (state: TrafficLightEngagementState) => state.error;


