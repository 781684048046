import { useRef } from "react";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import { useTranslation } from "react-i18next";
import { CustomButton, FormInput, RegxPattern } from "../../../../utils";
import { THEME } from "../../../../enums";
import { TextEmAllSSO } from "../../../../interfaces/admin_center_textemall";
import { AppError } from "../../../../interfaces";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import ApiError from "../../../../components/api_error";
interface Props {
  visible: boolean;
  onClose: () => void;
  onDismiss: () => void;
  onSubmit: (credentials: TextEmAllSSO) => void;
  handleFieldChange: (fieldId: any, value: any) => void;
  isFormVaid: boolean;
  loading: boolean;
  error?: AppError | null | undefined;
  onClearError: () => void;
  formState: TextEmAllSSO;
  titleHeader: boolean;
}
const MPAuthenticationToken: React.FunctionComponent<Props> = (props) => {
  const {
    visible,
    onClose,
    onDismiss,
    handleFieldChange,
    onSubmit,
    isFormVaid,
    loading,
    error,
    formState,
    onClearError,
    titleHeader
  } = props;
  const { t } = useTranslation();
  const actionRef = useRef<ActionDialogHolderType>(null);

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={500}
      onClose={onClose}
      onDissmiss={onDismiss}
    >
      <DialogWrapper onClose={() => closePopup(onClose)}>
        <div className="text-em-auth-dialog">
          <div className="text-em-all-dialog-header">
            <FormDialogTilteHeader
              title={titleHeader ? t("generate_text_em_all_authorization_token") : t("generate_authorization_token")}
              titleIcon={undefined}
            />
          </div>
          <div className="text-em-all-dialog-content">
            <FormInput
              id={"UserName"}
              onChange={handleFieldChange}
              type={"text"}
              value={formState.UserName}
              label={t("username")}
              placeholder=""
              required={true}
              validations={validators}
            />
            <FormInput
              id={"Password"}
              onChange={handleFieldChange}
              type={"password"}
              value={formState.Password}
              label={t("password")}
              placeholder=""
              required={true}
              validations={validators}
            />
            {error && <div className="error-holder">
              <ApiError message={error.message} onClose={onClearError} />
            </div>}
          </div>
          <div className="text-em-all-dialog-footer">
            <div className="btn-cancel">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("cancel")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={onClose}
              />
            </div>
            <div className="btn-save">
              <CustomButton
                textStyle={{ textTransform: "capitalize" }}
                loading={loading}
                name={t("save")}
                backgroundColor={THEME.buttonColor1}
                enable={isFormVaid}
                onClick={() => (loading ? {} : onSubmit(formState))}
              />
            </div>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );
  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }
  function validators(value: { text: string; field: string }) {
    switch (value.field) {
      case "UserName": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.email.test(value.text) === false)
          return t("validators.enterValidEmail");
        return null;
      }

      case "Password": {
        if (value.text === "") return t("validators.required");
        return null;
      }

      default: {
        return null;
      }
    }
  }
};

export default MPAuthenticationToken;
