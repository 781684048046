import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { PayCheck, AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const voidPayCheckThunk = createAsyncThunk<
    string,
    { paycheck_id: string, recreate: boolean },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/paycheck/void-check/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.voidCheck(payload.paycheck_id, payload.recreate);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const reversePayCheckThunk = createAsyncThunk<
    string,
    { paycheck_id: string, recreate: boolean },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/paycheck/reverse-check/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.reverseCheck(payload.paycheck_id, payload.recreate);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });