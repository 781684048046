import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../apis/back_office_api";
import { ITimeCards, ITimecardsQueryParams, AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";



export const getPaycheckPDFURLThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/paycheck_directory/view_paycheck_url/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getPaycheckDownloadPDFURL(payload);
            return res.data.url ;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getInvoicePDFURLThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/billing_invoice/billing_invoice_report/view_invoice_url/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getInvoiceDownloadPDFURL(payload);
            return res.data.url ;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });