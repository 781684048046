import React, { PureComponent } from 'react';
import CustomText from './custom_text';
import { CircularProgress } from '@material-ui/core';

interface Props {
  size?: any,
  color?: any,
  status?: any,
  backgroundColor?: any,
  customStyle?: any
}

export function SpinnerScreen(props: Props) {
  const { size, color, status, backgroundColor, customStyle } = props;
  return (
    <div
      onClick={() => {/* do nothing */ }}
      style={{
        ...styles.loadScreen,
        ...customStyle
      }}
    >
      <CircularProgress style={{ color: color }} size={size ? size : 35} />
      {
        status ? <CustomText
          style={{
            marginTop: 10,
          }}
        >
          {status}
        </CustomText>
          : null
      }
    </div >
  )
}

const styles: { [key: string]: React.CSSProperties } = ({
  loadScreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    height: '100%',
  }
});

