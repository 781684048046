import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import {
    selectState,
    selectSingleJobServiceProfileState,
    selectInitialSingleJobServiceProfileState,
} from "./service_profile_info_reducer";

const select = (state: IRootState) => state.jobs.details.serviceProfile.serviceProfile;
export const selectJobServiceProfileState = createSelector(select, selectState);

export const selectJobServiceProfileByIdState = (id: string | undefined | null) => createSelector(
    selectJobServiceProfileState,
    (state) => {
        if (id && state[id]) return selectSingleJobServiceProfileState(state, id);
        return selectInitialSingleJobServiceProfileState();
    }
);

// export const selectJobServiceProfileDepartmentState = createSelector(select, selectDepartmentState);
