import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import { AppRoutes } from "../../routes/routes";
import { getAdminOnboardingNavHeaderProps } from "../../routes/route_helpers";
import AdminCenterOnBoardingWelcomeVideo from "./system_configuration/onboarding/pages/admin_center_onboarding_welcomevideo";
import AdminCenterOnBoardingCompany from "./system_configuration/onboarding/pages/admin_center_onboarding_company";
import AdminCenterOnBoardingPaperWork from "./system_configuration/onboarding/pages/admin_center_onboarding_paperwork";
import { GetCloseAction } from "../../utils";
import AdminCenterOnboardingHealthCare from "./system_configuration/onboarding/pages/admin_center_onboardng_healthcare";
import AdminCenterOnBoardingAndESCWOTC from "./system_configuration/onboarding/pages/admin_center_onboarding_esc_and_wotc";
export const OnBoardingTabNav = (props: RouteComponentProps<any>) => {

  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();

  return (
    <TabNavigation
      activeRoute={location.pathname}
      tabList={[
        {
          title: "welcome video",
          route: `${AppRoutes.systemConfigurationOnboarding}/welcome-video`,
        },
        {
          title: "terms_and_conditions",
          route: `${AppRoutes.systemConfigurationOnboarding}/terms-and-conditions`,
        },
        {
          title: "handbook",
          route: `${AppRoutes.systemConfigurationOnboarding}/hand-book`,
        },
        {
          title: "healthcare",
          route: `${AppRoutes.systemConfigurationOnboarding}/health-care`,
        },
        {
          title: "wotc-and-esc",
          route: `${AppRoutes.systemConfigurationOnboarding}/wotc-and-esc`,
        },
      ]}
      onChange={(value) =>
        history.push({ pathname: value, state: location.state })
      }
      actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.adminCenterSystemConfiguration)}/>}
    >
      <Switch>
        <Route
          exact
          path={`${AppRoutes.systemConfigurationOnboarding}/welcome-video`}
          component={AdminCenterOnBoardingWelcomeVideo}
        />
        <Route
          exact
          path={`${AppRoutes.systemConfigurationOnboarding}/terms-and-conditions`}
          component={AdminCenterOnBoardingCompany}
        />
        <Route
          exact
          path={`${AppRoutes.systemConfigurationOnboarding}/hand-book`}
          component={AdminCenterOnBoardingPaperWork}
        />
        <Route
          exact
          path={`${AppRoutes.systemConfigurationOnboarding}/health-care`}
          component={AdminCenterOnboardingHealthCare}
        />
        <Route
          exact
          path={`${AppRoutes.systemConfigurationOnboarding}/wotc-and-esc`}
          component={AdminCenterOnBoardingAndESCWOTC}
        />
        <Route
          path={"/"}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: `${location.pathname}/welcome-video`,
                state: { ...(location.state as any) },
              }}
            />
          )}
        />
      </Switch>
    </TabNavigation>
  );
};

export const AdminOnBoardingNavigation = (props: RouteComponentProps<any>) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  const headerOptions = getAdminOnboardingNavHeaderProps(location, match, t);

  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <Switch>
        <Route path={"/"} component={OnBoardingTabNav} />
      </Switch>
    </PageNavHeader>
  );
};
