import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./jobs_list_reducer";

const select = (state: IRootState) => state.selectors.jobsDropdown;
export const selectJobsDropdownListState = createSelector(select, selectState);
export const selectJobsDropdownList = createSelector(
    select,
    selectAll
);

export const selectJobsDropdownListEntities = createSelector(
    select,
    selectEntities
);