import { useTranslation } from "react-i18next";
import "./inbound_lead_info_page.scss";
import { IInBoundLeads } from "../../../../../../interfaces/inbound_leads";

interface Props {
    data: IInBoundLeads | null
}
const InboundLeadInfoPage: React.FunctionComponent<Props> = (props) => {
    const { data } = props;
    const { t } = useTranslation();
    return (
        <div className="ib-lead-info-container">
            <div className="business-goals-div">
                <div className="bg-header">
                    <span className="bg-h-title">{t("business_goals")}</span>
                </div>
                <div className="bg-content">
                    <div>
                        <ul className="bg-ul">
                            {data?.goal1 && <li>{data.goal1}</li>}
                            {data?.goal2 && <li>{data.goal2}</li>}
                            {data?.goal3 && <li>{data.goal3}</li>}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InboundLeadInfoPage;