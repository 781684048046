import { createEntityAdapter, createSlice, EntityAdapter, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import { AppError, ITalentInHouseInterview } from "../../../../../interfaces";
import * as thunk from "./talent_availability_thunk";

interface CRUDState {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
}

const initialAddState: CRUDState = {
    response: null,
    error: null,
    loading: LoadingType.idle,
}


export interface SingleTalentAvailableDatesState {
    data: Array<number>,
    error?: AppError | null,
    loading: LoadingType,
    add: CRUDState,
}


export interface TalentAvailableDatesState {
    [key: string]: SingleTalentAvailableDatesState,
}


const initialState: TalentAvailableDatesState = {}


export const talentAvailableDatesSlice = createSlice({
    name: 'talentAvailableDates',
    initialState: initialState,
    reducers: {
        clearTalentAvailableDatesError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    error: null,
                }
            };
        },
        clearSingleTalentAvailableDatesStateError(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: {
                        ...state[action.payload].add,
                        error: null,
                    },
                }
            }
        },
        clearSingleTalentAvailableDatesManageState(state, action: PayloadAction<string>) {
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    add: { ...initialAddState },
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getTalentAvailableDatesThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.pending,
                        error: null,
                        add: { ...initialAddState },
                    }
                })
            )
            .addCase(
                thunk.getTalentAvailableDatesThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        loading: LoadingType.failed,
                        error: action.payload,
                    }
                })
            )
            .addCase(
                thunk.getTalentAvailableDatesThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg]: {
                        ...state[action.meta.arg],
                        data: action.payload,
                        loading: LoadingType.succeeded,
                        error: null,
                    }
                })
            )
            /// Add new talent in house interview
            .addCase(
                thunk.addTalentAvailableDatesThunk.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        add: {
                            ...state[action.meta.arg.talent_id].add,
                            loading: LoadingType.pending,
                            error: null,
                        }
                    }
                })
            )
            .addCase(
                thunk.addTalentAvailableDatesThunk.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        add: {
                            ...state[action.meta.arg.talent_id].add,
                            loading: LoadingType.failed,
                            error: action.payload,
                        }
                    }

                })
            )
            .addCase(
                thunk.addTalentAvailableDatesThunk.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.talent_id]: {
                        ...state[action.meta.arg.talent_id],
                        add: {
                            ...state[action.meta.arg.talent_id].add,
                            loading: LoadingType.succeeded,
                            error: null,
                            response: action.payload,
                        }

                    }
                })
            )
            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentAvailableDatesActions = talentAvailableDatesSlice.actions;
export const selectState = (state: TalentAvailableDatesState) => state;
export const selectSingleState = (state: TalentAvailableDatesState, id: string) => state[id];

export const selectSingleTalentAvailableDatesInitialState = (): SingleTalentAvailableDatesState => {
    return {
        loading: LoadingType.pending,
        error: null,
        data: [],
        add: initialAddState,
    }
}


