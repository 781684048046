import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, Profile } from "../../../../interfaces";
import * as thunk from "./recruiters_list_thunk";

export interface RecruitersListState extends EntityState<Profile> {
    error?: AppError | null,
    loading: LoadingType,
}

const recruitersListAdapter: EntityAdapter<Profile> = createEntityAdapter<Profile>({
    selectId: (doc) => doc.id ?? doc.email,
})

const initialState: RecruitersListState = recruitersListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const recruitersListSlice = createSlice({
    name: 'recruitersList',
    initialState: initialState,
    reducers: {
        clearRecruitersListError(state){
            return {...state, error: null};
        },
        clearRecruitersListState(state){
            return recruitersListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getRecruiters.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getRecruiters.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getRecruiters.fulfilled, (state, action) => recruitersListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { clearRecruitersListError, clearRecruitersListState } = recruitersListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = recruitersListAdapter.getSelectors();
export const selectState = (state: RecruitersListState) => state;
export const selectLoading = (state: RecruitersListState) => state.loading;
export const selectError = (state: RecruitersListState) => state.error;


