import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../components/dialog_wrapper/dialog_wrapper';
import { OnboardingPaperWorkOptions } from '../../../enums/onboarding_paperwork';
import { QuestionMarkIcon, ReadDocIcon, TickGreenIcon } from '../../../icons';
import { ITalentOnBoardingData } from '../../../interfaces';
import { updateTalentOnboardingThunk, useAppDispatch } from '../../../redux/store';
import './employee_handbook.scss';
import EmployeeHandbookOptionMenu from './emp_options_menu/emp_options_menu';
import Handbook from './handbook/handbook';
import Signature from './signature/signature';


interface Props {
    visible: boolean,
    type: string,
    token: string,
    title: string,
    successTitle: string,
    state: ITalentOnBoardingData,
    termsAndConditions: any,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: (type: string, value?: any) => void,
}

const EmployeeHandbookPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        type,
        token,
        state,
        title,
        termsAndConditions,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const actionRef = useRef<ActionDialogHolderType>(null);


    const [options, setOptions] = useState({
        [OnboardingPaperWorkOptions.handbook_document]: {
            type: OnboardingPaperWorkOptions.handbook_document,
            title: t('employee_handbook'),
            icon: ReadDocIcon,
            visible: false,
            onClick: handleOptionClick,
        },
        [OnboardingPaperWorkOptions.terms_and_conditions]: {
            type: OnboardingPaperWorkOptions.terms_and_conditions,
            title: t('signature'),
            icon: state.handbook_document.talent_completed_review ? TickGreenIcon : QuestionMarkIcon,
            visible: false,
            onClick: handleOptionClick,
        },
    });

    function handleClose() {
        closePopup(onClose);
    }

    function handleOptionClick(type: string) {
        setOptions({ ...options, [type]: { ...options[type], visible: true } })
    }

    function handleOptionClose(type: string) {
        setOptions({ ...options, [type]: { ...options[type], visible: false } })
    }

    function handleOptionSuccessClose(type: string, value?: any) {
        dispatch(updateTalentOnboardingThunk({ ...state, [type]: value ?? { ...state[type], talent_completed_review: true } }));
        setOptions({ ...options, [type]: { ...options[type], visible: false } });
        // onSuccessClose(type);
    }
    const enableSave = state.handbook_document.talent_completed_review && state.terms_and_conditions.talent_read_tc && state.terms_and_conditions.talent_signature !== null;
    return (
        <>
            <ActionDialogHolder
                ref={actionRef}
                visible={visible}
                wrapperWidth={"70%"}
                onClose={onClose}
                onDissmiss={onDissmiss}
            >
                <DialogWrapper onClose={handleClose}>
                    <EmployeeHandbookOptionMenu
                        title={title}
                        enableSave={enableSave}
                        menuOptions={Object.keys(options).map(key => options[key])}
                        onCancel={handleClose}
                        onSave={handleClose}
                    />
                </DialogWrapper>
            </ActionDialogHolder>
            {(options.handbook_document.visible) && <Portal>
                <Handbook
                    visible={options.handbook_document.visible}
                    type={OnboardingPaperWorkOptions.handbook_document}
                    token={token}
                    title={title}
                    state={state}
                    onClose={() => handleOptionClose(options.handbook_document.type)}
                    onDissmiss={() => handleOptionClose(options.handbook_document.type)}
                    onSuccessClose={handleOptionSuccessClose}
                />
            </Portal>}
            {(options.terms_and_conditions.visible) && <Portal>
                <Signature
                    visible={options.terms_and_conditions.visible}
                    type={OnboardingPaperWorkOptions.terms_and_conditions}
                    token={token}
                    title={title}
                    state={state}
                    onClose={() => handleOptionClose(options.terms_and_conditions.type)}
                    onDissmiss={() => handleOptionClose(options.terms_and_conditions.type)}
                    termsAndConditions={termsAndConditions}
                    onSuccessClose={handleOptionSuccessClose}
                />
            </Portal>}
        </>


    );




    ///Handle clear error
    function handleClearError() {

    }

    /// handle submit
    function handleSubmit() {

    }


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}



export default EmployeeHandbookPopup;