import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { AppError, IIEImport } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { S3API } from "../../../../apis/s3_api";


export const postImportFileURLThunk = createAsyncThunk<
    { id: string, upload_url: string },
    { data: IIEImport, file: File | null, force_upload?: boolean },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/import_export/import/post",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.postImportFileUpload(payload.data, payload.force_upload);
            if (res.data && payload.file) {
                const url = res.data.upload_url;
                thunkAPI.dispatch(uploadImportFileThunk({ url: url, file: payload.file }))
            }
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const uploadImportFileThunk = createAsyncThunk<
    any,
    { url: string, file: File | null },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/import_export/import/upload_file/put",
    async (payload, thunkAPI) => {
        try {
            const res = await S3API.uploadWithPreSignedURL(payload.url, payload.file);;
            return res;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });



export const patchImportFileThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/import_export/import/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.patchImportFile(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });