import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums"
import { AppError, Assignment } from "../../../../../interfaces"
import * as thunk from './assignment_list_thunk';


export interface SingleJobsAssignmentState {
    data: Array<Assignment> | null,
    error?: AppError | null,
    loading: LoadingType
}


export interface JobsAssignmentState {
    [key: string]: SingleJobsAssignmentState,
}


const initialState: JobsAssignmentState = {}

function getLoadingState(state: any) {
    return {
        ...state,
        loading: LoadingType.pending,
        error: null,
    }
}

function getErrorState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.failed,
        error: payload,
    }
}

function getSuccessState(state: any, payload: any) {
    return {
        ...state,
        loading: LoadingType.succeeded,
        data: payload,
        error: null,
    }
}

export const jobsAssignmentSlice = createSlice({
    name: 'assignmentsList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                thunk.getJobsAssignmentsList.pending,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_order_id]: {
                        ...getLoadingState(state[action.meta.arg.job_order_id])
                    }
                })
            )
            .addCase(
                thunk.getJobsAssignmentsList.rejected,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_order_id]: {
                        ...getErrorState(state[action.meta.arg.job_order_id], action.payload),
                    }
                })
            )
            .addCase(
                thunk.getJobsAssignmentsList.fulfilled,
                (state, action) => ({
                    ...state,
                    [action.meta.arg.job_order_id]: {
                        ...getSuccessState(state[action.meta.arg.job_order_id], action.payload),
                    }
                })
            )

            .addDefaultCase(state => ({ ...state }));
    }
});
//companyDepartmentListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload)

export const jobsAssignmentSliceActions = jobsAssignmentSlice.actions;
export const selectJobsAssignmentsState = (state: JobsAssignmentState) => state;
export const selectSingleJobsAssignmentsState = (state: JobsAssignmentState, id: string): SingleJobsAssignmentState => state[id];

export const selectInitialSingleJobsAssignmentsState = (): SingleJobsAssignmentState => ({
    data: null,
    error: null,
    loading: LoadingType.idle,
});