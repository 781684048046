import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { selectState, selectAll, selectEntities } from "./skill_list_reducer";

const select = (state: IRootState) => state.selectors.skillSetList;
export const selectSkillSetListState = createSelector(select, selectState);
export const selectSkillSetList = createSelector(
    select,
    selectAll
);
export const selectSkillSetListEntities = createSelector(
    select,
    selectEntities
);