import { combineReducers } from "@reduxjs/toolkit";
import { interviewsToClientCompaniesSlice } from "./interviews_to_client_companies/interviews_to_client_companies_reducer";
import { inHouseInterviewQuestionsSlice } from "./interview_questions/interview_questions_reducer";
import { inHouseInterviewsSlice } from "./in_house_interviews/in_house_interviews_reducer";

export const talentInterviewsReducers = combineReducers({
    [inHouseInterviewsSlice.name]: inHouseInterviewsSlice.reducer,
    [interviewsToClientCompaniesSlice.name]: interviewsToClientCompaniesSlice.reducer,
    [inHouseInterviewQuestionsSlice.name]: inHouseInterviewQuestionsSlice.reducer,
});

export * from './in_house_interviews/in_house_interviews_thunk';
export * from './in_house_interviews/in_house_interviews_selector';

export * from './interviews_to_client_companies/interviews_to_client_companies_thunk';
export * from './interviews_to_client_companies/interviews_to_client_companies_selector';

export * from './interview_questions/interview_questions_thunk';
export * from './interview_questions/interview_questions_selector';