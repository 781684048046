import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../apis";
import { AppError } from "../../../interfaces";
import { IAdminRCVacationConfig } from "../../../interfaces/report_center";
import { catchRequestError } from "../../../utils";


export const getVacationConfigThunk = createAsyncThunk<
    IAdminRCVacationConfig,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>('@admin_center/report_center/vaction_report/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getVacationConfig();
            return res.data as IAdminRCVacationConfig;
        }
        catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


export const updateVacationConfigThunk = createAsyncThunk<
    string,
    IAdminRCVacationConfig,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>('@admin_center/report_center/vaction_report/patch',
    async (doc, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.patchVacationConfig(doc);
            return res.data;
        }
        catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    })