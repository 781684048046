import { useTranslation } from "react-i18next";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import { AppRoutes, getBackOfficeNavHeaderProps } from "../../routes";
import BackOfficeMainPage from "./back_office_page";
import WeeklyAccounitngTabNav from "./back_office_tabs_nav/bo_weekly_accounting_nav";
import TimeCardsTabNav from "./back_office_tabs_nav/bo_time_cards_nav";
import PayrollTabNav from "./back_office_tabs_nav/bo_payroll_nav";
import BillingInvoiceTabNav from "./back_office_tabs_nav/bo_billing_invoice_nav";
import {DeductionAuthorityNav, DeductionAuthorityDetailsNav} from "./back_office_tabs_nav/bo_deduction_authority_nav";
import ExportTabNav from "./back_office_tabs_nav/bo_export_nav";
import ImportTabNav from "./back_office_tabs_nav/bo_import_nav";
import AccountsReceivableTabNav from "./back_office_tabs_nav/bo_accounts_receivable_nav";

interface Props extends RouteComponentProps<any> {}

export const BackOfficeNavigation: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  const headerOptions = getBackOfficeNavHeaderProps(location, match, t);

  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <Switch>
        <Route
          path={AppRoutes.weeklyAccountingPage}
          component={WeeklyAccounitngTabNav}
        />
        <Route path={AppRoutes.timeCardsPage} component={TimeCardsTabNav} />
        <Route path={AppRoutes.payrollPage} component={PayrollTabNav} />
        <Route path={AppRoutes.billingInvoicesPage} component={BillingInvoiceTabNav} />
        <Route path={AppRoutes.boImportPage} component={ImportTabNav} />
        <Route path={AppRoutes.boExportPage} component={ExportTabNav} />
        <Route path={AppRoutes.deductionAuthoritiyPage} component={DeductionAuthorityNav} />
        <Route path={AppRoutes.accountsReceivablePage} component={AccountsReceivableTabNav} />
        <Route path={`${AppRoutes.deductionAuthoritiyDetailedPage}/:id`} component={DeductionAuthorityDetailsNav} />
        <Route component={BackOfficeMainPage} />
      </Switch>
    </PageNavHeader>
  );
};
