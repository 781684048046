import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ICompanyBackOfficeSetup } from "../../../../interfaces/company_back_office_setup";

export const getCompanyBackOfficeSetupThunk = createAsyncThunk<
    ICompanyBackOfficeSetup,
    { company_id?: string, department_id?: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/details/back_office_setup/get',
    async (query, thunkAPI) => {
        try {
            const res = await CompaniesAPI.getBackOfficeSetup(query);
            if (typeof res.data === 'string') return [];
            return res.data as ICompanyBackOfficeSetup;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const updateCompanyBackOfficeSetupThunk = createAsyncThunk<
    string,
    { data: ICompanyBackOfficeSetup, isNotCredit: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/details/back_office_setup/patch',
    async (payload, thunkAPI) => {
        try {
            const res = await CompaniesAPI.updateBackOfficeSetup(payload.data);
            if (payload.isNotCredit) {
                thunkAPI.dispatch(getCompanyBackOfficeSetupThunk({ company_id: payload.data.company_id, department_id: payload.data.department_id }));
            }
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);