import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../apis";
import { S3API } from "../../../../apis/s3_api";
import { AppError, ITalent, ICreateTalent, ICreateResponse, IProfilePictureRequestQuery, IProfilePictureUploadUrlResponse, IUploadUrlsResponse, IUploadUrlsQuery } from "../../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../../utils";
import { getTalentById } from "../../details";
import { getTalentList } from "../talent_list/talent_list_thunk";

/// Create user
export const createTalent = createAsyncThunk<
    ICreateResponse,
    { forceAdd: boolean, value: ICreateTalent },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/universal-directory/manage-talent/create',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.createTalent(payload)
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            thunkAPI.dispatch(getTalentList());
            return res.data as ICreateResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Update user
export const updateTalent = createAsyncThunk<
    ICreateResponse,
    ITalent,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/universal-directory/manage-talent/update',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.updateTalent(payload);
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            thunkAPI.dispatch(getTalentList());
            // thunkAPI.dispatch(getTalentById(payload.id));
            return res.data as ICreateResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


/// Files upload
export const getTalentFileUploadUrlsThunk = createAsyncThunk<
    IUploadUrlsResponse,
    IUploadUrlsQuery,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/universal-directory/manage-talent/upload-urls/get',
    async (query, thunkAPI) => {
        try {
            const res = await TalentAPI.getFileUploadUrls(query);
            if (typeof res.data === 'string') return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
            return res.data["data"] as IUploadUrlsResponse;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const uploadTalentFilesThunk = createAsyncThunk<
    any,
    { data: IUploadUrlsResponse, photo?: File | null, resume?: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/universal-directory/manage-talent/upload-files/post',
    async (payload, thunkAPI) => {
        try {
            if (payload.photo && payload.resume && payload.data?.profile_pic_upload_url && payload.data?.resume_upload_url) {
                const photoRes = await TalentAPI.uploadPhotoAndResume(payload.data?.profile_pic_upload_url?.url, payload.photo);
                const resumeRes = await TalentAPI.uploadPhotoAndResume(payload.data?.resume_upload_url?.url, payload.resume);
                return photoRes.data || resumeRes.data;
            }else if (payload.photo && payload.resume === null && payload.data?.profile_pic_upload_url) {
                const photoRes = await TalentAPI.uploadPhotoAndResume(payload.data?.profile_pic_upload_url?.url, payload.photo);
                return photoRes.data;
            }else if (payload.photo === null && payload.resume && payload.data?.resume_upload_url) {
                const resumeRes = await TalentAPI.uploadPhotoAndResume(payload.data?.resume_upload_url?.url, payload.resume);
                return resumeRes.data;
            }else {
                /// ignore
            }
            // return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);