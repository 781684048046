import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import { selectState, selectSingleState, selectSingleTalentCertificationInitialState } from "./certifications_other_docs_reducer";

const select = (state: IRootState) => state.talent.details.onBoardingAndDocs.talentCertificationOtherDocs;
export const selectTalentCertificationState = createSelector(select, selectState);
// export const selectSingleTalentCertificationStateById = (id: string) => createSelector(
//     select, 
//     (state) => {
//         if (id && state[id]) return selectSingleState(state, id);
//         return selectSingleTalentCertificationInitialState();
//     }
// );

export const selectSingleTalentCertificationStateById = (id: string | undefined | null) => createSelector(
    selectTalentCertificationState,
    (state) => {
        if (id && state[id]) return selectSingleState(state, id);
        return selectSingleTalentCertificationInitialState();
    }
);

export const selectSingleTalentCertificationList = (id: string) => createSelector(
    select, 
    (state) => {
        if (id && state[id]) {
            const data = selectSingleState(state, id).data;
            return data ? Object.keys(data).map((key) => data[key]) : [];
        }
        return [];
    }
);