import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssignmentsAPI  } from "../../../../../apis";
import { AppError, Assignment} from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";

export const getContactAssignmentsList = createAsyncThunk<
    Array<Assignment>,
    {contact_id: string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/details/assignments/assignment-list/get',
    async (query, thunkAPI) => {
        try {
            const res = await AssignmentsAPI.getAllAssignments({...query, company_id: null, department_id: null, talent_id: null, branch_id: null, job_order_id: null});
            if(typeof res.data === 'string') return [];
            return res.data as Assignment[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);