import { createAsyncThunk } from "@reduxjs/toolkit";
import { SalesMarketingAPI } from "../../../../apis/sales_marketing_api";
import { AppError, ICompetitor } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getCompetitorDetails = createAsyncThunk<
  ICompetitor,
  string,
  {
    rejectValue: AppError | null;
    rejectedMeta: AppError | null;
  }
>("@getCompetitorDetails", async (id, thunkAPI) => {
  try {
    const res = await SalesMarketingAPI.getCompetitor(id);
    return res.data as ICompetitor;
  } catch (error) {
    return catchRequestError(error, thunkAPI);
  }
});
