import { IUpdateEmpOnboardingDocsReviewStatus } from "../interfaces/employee_dashboard";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const EmployeeDashboardAPI = {
  getEmployeeDashboardDetails() {
    return interceptor.get(`${UrlBuilder.EmployeeDashboardDetails}`);
  },
  updateEmployeeDashboardDetails(data) {
    return interceptor.patch(`${UrlBuilder.EmployeeDashboardDetails}`, data);
  },
  getEmployeeDashboardOnboardingDocsReviewStatus() {
    return interceptor.get(
      `${UrlBuilder.employeeDashboardOnboardingDocsReviewStatus}`
    );
  },
  patchEmployeeDashboardOnboardingMyW4(data) {
    return interceptor.patch(
      `${UrlBuilder.employeeDashboardOnboardingUpdateMyW4}`,
      data
    );
  },
  patchEmployeeDashboardOnboardingMyDD(data) {
    return interceptor.patch(
      `${UrlBuilder.employeeDashboardOnboardingUpdateMyDD}`,
      data
    );
  },

  // e-docs
  getEmpOnboardingReviewStatus(talentId: string) {
    return interceptor.get(`${UrlBuilder.getOnboardingDocsReviewedStatus}?talent_id=${talentId}`)
  },

  updateEmpOnboardingReviewStatus(doc: IUpdateEmpOnboardingDocsReviewStatus) {
    return interceptor.patch(`${UrlBuilder.updateOnboardingDocsReviewedStatus}?talent_id=${doc.talent_id}`, doc)
  },

  postValidateEmpDDOnBoarding(data: IUpdateEmpOnboardingDocsReviewStatus) {
    return interceptor.post(`${UrlBuilder.validateEmpOBDD}`, data);
  },

  //payroll details
  getEDPayrollDetails() {
    return interceptor.get(`${UrlBuilder.employeeDashboardPayrollDetails}`);
  },

};
