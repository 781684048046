import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloSearcImportAPI } from "../../../apis";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";



export const refreshApolloContact = createAsyncThunk<
    string,
    { contact_id: string, need_direct_dial: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@contacts/contact_details/contact/refresh_contact/post',
    async (data, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.refreshContact(data.contact_id, data.need_direct_dial);
            // thunkAPI.dispatch(getTalentRecruiters({ talent_id: data.talent_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const refreshApolloCompany = createAsyncThunk<
    string,
    { company_id: string, need_direct_dial: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@companies/company_details/company/refresh_company/post',
    async (data, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.refreshCompany(data.company_id, data.need_direct_dial);
            // thunkAPI.dispatch(getTalentRecruiters({ talent_id: data.talent_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const refreshApolloTalent = createAsyncThunk<
    string,
    { talent_id: string, need_direct_dial: boolean },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/talent/refresh_talent/post',
    async (data, thunkAPI) => {
        try {
            const res = await ApolloSearcImportAPI.refreshTalent(data.talent_id, data.need_direct_dial);
            // thunkAPI.dispatch(getTalentRecruiters({ talent_id: data.talent_id }));
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);