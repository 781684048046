import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import InDevelopment from "../../others/in_development";
import { AppRoutes, getSalesNavHeaderProps } from "../../routes";
import { GetCloseAction } from "../../utils";
import { CompetitorDashboard } from "./pages/competitor_sheet/competitor_dashboard";
import CompetitorSheet from "./pages/competitor_sheet/competitor_sheet";
import MarketingActivity from "./pages/marketing_activity/marketing_activity";
import MarketingDocs from "./pages/marketing_docs/marketing_docs";
import MyCallDeck from "./pages/my_call_deck/my_call_deck";
import PlanMarketingCampaign from "./pages/plan_marketing_campaign/plan_marketing_campaign";
import SalesCallActivity from "./pages/sales_call_activity/sales_call_activity";
import ServiceCallActivity from "./pages/service_call_activity/service_call_activity";

interface Props extends RouteComponentProps<any> {}

const CompetitorDetailsTabNav = (props: RouteComponentProps<any>) => {

  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();
  return (
    <TabNavigation
      activeRoute={location.pathname}
      tabList={[
        {
          title: "dashboard",
          route: `${AppRoutes.salesAndMarketingCompetitorSheet}/${params.id}/dashboard`,
        },
      ]}
      onChange={(value) =>
        history.push({ pathname: value, state: location.state })
      }
      actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.salesAndMarketingCompetitorSheet)}/>}
    >
      <Switch>
        <Route
          exact
          path={`${AppRoutes.salesAndMarketingCompetitorSheet}/:id/dashboard`}
          component={CompetitorDashboard}
        />
        <Route
          path={"/"}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: AppRoutes.salesAndMarketingCompetitorSheet,
                state: { ...(location.state as any) },
              }}
            />
          )}
        />
      </Switch>
    </TabNavigation>
  );
};

/// Universal directory folder
const SalesAndMarketingTabNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  return (
    <TabNavigation
      activeRoute={location.pathname}
      tabList={[
        {
          title: "sales_call_activity",
          route: AppRoutes.salesAndMarketingSalesCallActivity,
        },
        {
          title: "service_call_activity",
          route: AppRoutes.salesAndMarketingServiceCallActivity,
        },
        {
          title: "marketing_activity",
          route: AppRoutes.salesAndMarketingMarketingActivity,
        },
        { title: "my_call_deck", route: AppRoutes.salesAndMarketingMyCallDeck },
        {
          title: "competitor_sheet",
          route: AppRoutes.salesAndMarketingCompetitorSheet,
        },
        {
          title: "marketing_docs",
          route: AppRoutes.salesAndMarketingMarketingDocs,
        },
      ]}
      onChange={(value) => history.push(value)}
    >
      <Switch>
        <Route
          exact
          path={AppRoutes.salesAndMarketingMyCallDeck}
          component={MyCallDeck}
        />
        <Route
          exact
          path={AppRoutes.salesAndMarketingCompetitorSheet}
          component={CompetitorSheet}
        />
        <Route
          exact
          path={AppRoutes.salesAndMarketingPlanMarketingCampaign}
          component={PlanMarketingCampaign}
        />
        <Route
          exact
          path={AppRoutes.salesAndMarketingSalesCallActivity}
          component={SalesCallActivity}
        />
        <Route
          exact
          path={AppRoutes.salesAndMarketingServiceCallActivity}
          component={ServiceCallActivity}
        />
        <Route
          exact
          path={AppRoutes.salesAndMarketingMarketingActivity}
          component={MarketingActivity}
        />
        <Route
          exact
          path={AppRoutes.salesAndMarketingMarketingDocs}
          component={MarketingDocs}
        />
        <Route
          path={"/"}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: AppRoutes.salesAndMarketingSalesCallActivity,
                state: { from: location },
              }}
            />
          )}
        />
      </Switch>
    </TabNavigation>
  );
};

export const SalesAndMarketingNavigation: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  const headerOptions = getSalesNavHeaderProps(location, match, t);

  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <Switch>
        <Route
          path={`${AppRoutes.salesAndMarketingCompetitorSheet}/:id`}
          component={CompetitorDetailsTabNav}
        />
        <Route path={"/"} component={SalesAndMarketingTabNav} />
      </Switch>
    </PageNavHeader>
  );
};
