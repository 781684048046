import { RouteComponentProps } from "react-router-dom";
import { CustomButton, FormInput, SpinnerScreen } from "../../../../../../utils";
import { IMasterTaxCredntials } from "../../../../../../interfaces/master_tax";
import { useEffect, useState } from "react";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { useTranslation } from "react-i18next";
import DragAndDrop from "../../../../../../components/drag_and_drop/drag_and_drop";
import "./credentials_page.scss";
import { LoadingType, THEME } from "../../../../../../enums";
import { getMasterTaxAuthThunk, selectCredentialState, updateMasterTaxAuthThunk } from "../../../../../../redux/admin_center";
import { S3API } from "../../../../../../apis/s3_api";
import { credentialsSliceActions } from "../../../../../../redux/admin_center/integration_partners/master_tax/credentials/credentials_reducer";

interface Props extends RouteComponentProps<any> { }

const MasterTaxCredentialsPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const credState = useAppSelector((state) => selectCredentialState(state));
    const [credData, setCredData] = useState<IMasterTaxCredntials | null>();
    const getCredData = () => {
        dispatch(getMasterTaxAuthThunk());
    }
    useEffect(() => {
        getCredData();
    }, []);

    const getFormData = (value?: IMasterTaxCredntials | null) => {
        if (value) {
            return {
                id: value.id,
                client_id: value.client_id,
                client_secret: value.client_secret,
                ssl_cert_file_name: value.ssl_cert_file_name,
                ssl_key_file_name: value.ssl_key_file_name,
                ssl_cert_path: value.ssl_cert_path,
                ssl_key_path: value.ssl_key_path
            }
        }
        return {
            id: "",
            client_id: "",
            client_secret: "",
            ssl_cert_file_name: "",
            ssl_key_file_name: "",
            ssl_cert_path: "",
            ssl_key_path: "",
        }
    }

    const [formState, setFormState] = useState<IMasterTaxCredntials>(getFormData(credData));
    useEffect(() => {
        if (credState.loading === LoadingType.succeeded) {
            setCredData(credState.data)
            setFormState(getFormData(credState.data))
        }
    }, [credState.data, credState.loading]);

    useEffect(() => {
        if (credState.postCreds.loading === LoadingType.succeeded && credState.postCreds.response) {
            const fileURLs = [
                {
                    url: credState.postCreds.response.ssl_cert_upload_url,
                    file: sslCertFile
                },
                {
                    url: credState.postCreds.response.ssl_key_upload_url,
                    file: sslKeyFile
                }];
            if (sslCertFile && sslKeyFile) {
                fileURLs.map((doc) => {
                    S3API.uploadWithPreSignedURL(doc.url, doc.file);
                });
            }

            dispatch(postSnackbarMessage("Credentials Added Successfully!" ?? null));
            dispatch(credentialsSliceActions.clearPostState());
            handleCancel();
            getCredData();
        }
    }, [credState.postCreds.loading, credState.postCreds.response])

    const [sslCertFile, setSSLCertFile] = useState<File | null>(null);
    const [sslKeyFile, setSSLKeyFile] = useState<File | null>(null);

    const handleFieldChange = (id: string, val: any) => {
        setFormState({ ...formState, [id]: val })
    };

    const handleSave = () => {
        dispatch(updateMasterTaxAuthThunk(
            {
                ...formState,
                ssl_cert_file_name: sslCertFile ? sslCertFile?.name : formState.ssl_cert_file_name,
                ssl_key_file_name: sslKeyFile ? sslKeyFile?.name : formState.ssl_key_file_name
            }));
    }
    const handleCancel = () => {
        setFormState(getFormData(credData));
        setSSLCertFile(null);
        setSSLKeyFile(null);

    }

    const _isFormValid = () => {
        const { client_id, client_secret, ssl_cert_path, ssl_key_path } = formState;
        if (client_id === "" || client_secret === "" ||
            (sslCertFile === null && ssl_cert_path === null) ||
            (sslKeyFile === null && ssl_key_path === null)) {
            return false
        }
        return true;
    }

    return (
        <div className="mtc-container">
            <div className="mtc-header">
                <span className="mtc-title">{t('credential_setup')}</span>
            </div>
            {credState.loading === LoadingType.pending ?
                <SpinnerScreen />
                :
                <div className="mtc-content">
                    <div className="input-row">
                        <div className="input-holder">
                            <FormInput
                                id={"client_id"}
                                onChange={(id, val) => handleFieldChange(id, val)}
                                type={"text"}
                                value={formState?.client_id ?? ""}
                                label={t("client_id")}
                                placeholder=""
                                required={true}
                            />
                        </div>
                        <div className="input-holder">
                            <FormInput
                                id={"client_secret"}
                                onChange={(id, val) => handleFieldChange(id, val)}
                                type={"text"}
                                value={formState?.client_secret ?? ""}
                                label={t("client_secret")}
                                placeholder=""
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="input-row">
                        <div className="input-holder">
                            <DragAndDrop
                                type={"small"}
                                label={t('ssl_cert_file')}
                                accept={".pem"}
                                multiple={false}
                                placeholder={"Select or Drag a File"}
                                selectedFile={sslCertFile}
                                onChange={setSSLCertFile}
                                onRemove={() => setSSLCertFile(null)}

                            />
                            {formState.ssl_cert_path && formState.ssl_cert_path !== "" &&
                                <span className="filename">Uploaded File: <b>{formState.ssl_cert_file_name}</b></span>
                            }
                        </div>
                        <div className="input-holder">
                            <DragAndDrop
                                type={"small"}
                                label={t('ssl_key_file')}
                                accept={".key"}
                                multiple={false}
                                placeholder={"Select or Drag a File"}
                                selectedFile={sslKeyFile}
                                onChange={setSSLKeyFile}
                                onRemove={() => setSSLKeyFile(null)}
                            />
                            {formState.ssl_key_path && formState.ssl_key_path !== "" &&
                                <span className="filename">Uploaded File: <b>{formState.ssl_key_file_name}</b></span>
                            }

                        </div>
                    </div>

                </div>
            }
            <div className="mtc-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("cancel")}
                        enable
                        backgroundColor={THEME.secondaryColor4}
                        onClick={handleCancel}
                    />
                </div>
                <div className="btn-save">
                    <CustomButton
                        loading={credState.postCreds.loading === LoadingType.pending}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("save")}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={handleSave}
                        enable={_isFormValid()}
                    />
                </div>
            </div>
        </div>
    );
}

export default MasterTaxCredentialsPage;