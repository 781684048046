import { combineReducers } from "@reduxjs/toolkit";
import { submitOnlineApplicationSlice } from "./submit_application/submit_application_reducer";
import { onlineApplicationVerifyTokenSlice } from "./verify_token/verify_token_reducer";

export const onlineApplicationReducers = combineReducers({
    [onlineApplicationVerifyTokenSlice.name]: onlineApplicationVerifyTokenSlice.reducer,
    [submitOnlineApplicationSlice.name]: submitOnlineApplicationSlice.reducer,
});

export * from './submit_application/submit_application_selector';
export * from './submit_application/submit_application_thunk';

export * from './verify_token/verify_token_selector';
export * from './verify_token/verify_token_thunk';