import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError } from "../../../interfaces";
import { IReportCenterUploadUrlResponse } from "../../../interfaces/report_center";
import * as thunk from "./download_report_thunk";



export interface ReportDownloadState {
        error?: AppError | null;
        loading: LoadingType;
        response: IReportCenterUploadUrlResponse | null;
}

const initialState: ReportDownloadState = {
        error: null,
        loading: LoadingType.idle,
        response: null,
}

export const reportDownloadSlice = createSlice({
    name: 'reportDownload',
    initialState: initialState,
    reducers: {
        clearPRErrorState(state) {
            return { ...state, error: null };
        },
        clearDownloadState(state) {
            return { ...state, ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getReportDownloadURL.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getReportDownloadURL.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getReportDownloadURL.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, error: null, response: action.payload, }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const reportDownloadSliceActions = reportDownloadSlice.actions;
export const selectState = (state: ReportDownloadState) => state;
export const selectLoading = (state: ReportDownloadState) => state.loading;
export const selectError = (state: ReportDownloadState) => state.error;




