import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import { InputActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';


const components = {
    DropdownIndicator: null,
};
const createOption = (label: string) => ({
    label,
    value: label,
});

export interface NewTextOption {
    readonly label: string;
    readonly value: string;
}
const CreatableTextSelectField = ({ label, formState, setFormState, fieldId, placeHolder, disabled, clearSetValue, onSubmit, filteredCounts }) => {

    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<readonly NewTextOption[]>([]);
    useEffect(() => {
        setValue(clearSetValue);
    }, [clearSetValue]);

    useEffect(() => {
        if(
            formState.person_titles.length ||
            formState.person_not_titles.length ||
            formState.person_locations.length ||
            formState.person_not_locations.length ||
            formState.organization_num_employees_ranges.length
            ){
            filteredCounts()
        }
    }, [formState]);

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setValue((prev) => {
                    const newValue = [...prev, createOption(inputValue)];
                    const selected_values = newValue.map((val) => val.value);
                    setFormState({ ...formState, [fieldId]: selected_values });
                    onSubmit({ ...formState, [fieldId]: selected_values });
                    return newValue;
                });
                setInputValue('');
                event.preventDefault();
        }
    };
    const handleRemoveOption = (optionToRemove) => {
        const updatedOptions = value.filter(
            (option) => option.value !== optionToRemove.value
        );
        setValue(updatedOptions);
        const selected_values = updatedOptions.map((val) => val.value);
        setFormState({ ...formState, [fieldId]: selected_values });

    };
    const handleOptionChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'clear') {
            // Clear all selected options when 'clear' action occurs
            setValue([]);
            setFormState({ ...formState, [fieldId]: [] });
        }
        else if (actionMeta.action === 'pop-value') {
            const updatedOptions = value.slice(0, -1);

            setValue(updatedOptions);
            const selected_values = updatedOptions.map((val) => val.value);
            setFormState({ ...formState, [fieldId]: selected_values });
        }
        else if (actionMeta.action === 'remove-value') {
            // Remove the selected option by its value
            const removedValue = actionMeta.removedValue;
            const updatedOptions = value.filter(
                (option) => option.value !== removedValue.value
            );
            setValue(updatedOptions);
            const selected_values = updatedOptions.map((val) => val.value);
            setFormState({ ...formState, [fieldId]: selected_values });
        }
        else {
            setValue(newValue);
        }
    };
    return (
        <CreatableSelect
            components={{
                DropdownIndicator: null,
                // MultiValueRemove: ({ innerProps, data }) => (
                //     <span
                //         {...innerProps}
                //         onClick={() => handleRemoveOption(data)}
                //     >
                //         &#215;
                //     </span>
                // ),
            }}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleOptionChange}
            onInputChange={setInputValue}
            onKeyDown={handleKeyDown}
            placeholder={placeHolder === "" ? `Enter ${label}` : placeHolder}
            value={value}
            className='creatable_text'
            isDisabled={disabled}
        />
    );
};

export default CreatableTextSelectField;
