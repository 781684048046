import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './assignment_list_reducers';

const select = (state: IRootState) => state.contacts.details.assignments.assignmentsList;
export const selectContactAssignmentsState = createSelector(select, reducer.selectContactAssignmentsState);
export const selectSingleContactAssignmentsStateById = (id: string | undefined | null) => createSelector(
    selectContactAssignmentsState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleContactAssignmentsState(state, id);
        return reducer.selectInitialSingleContactAssignmentsState();
    }
);