import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import MultiOptionSection from "../../../../components/multi_option_section/multi_option_section";
import { LoadingType, THEME } from "../../../../enums";
import { SalesmarketIcon } from "../../../../icons";
import { AppError, ISkillOption } from "../../../../interfaces";
import {
  getBusinessSectors,
  selectBusinessSectorList,
} from "../../../../redux/admin_center";
import { selectCompetitorDetailsState } from "../../../../redux/sales_marketing/competitor_sheet/competitor_details/competitor_details_selector";
import { getCompetitorDetails } from "../../../../redux/sales_marketing/competitor_sheet/competitor_details/competitor_details_thunk";
import { manageCompetitorActions } from "../../../../redux/sales_marketing/competitor_sheet/manage_competitors/manage_competitors_reducer";
import { selectManageCompetitorUpdateState } from "../../../../redux/sales_marketing/competitor_sheet/manage_competitors/manage_competitors_selector";
import { updateCompetitor } from "../../../../redux/sales_marketing/competitor_sheet/manage_competitors/manage_competitors_thunk";
import {
  getCompaniesList,
  getDepartmentsList,
  postSnackbarMessage,
  selectBusinessSectorListState, 
  selectCompaniesList,
  selectCompaniesListState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { CustomButton, FormInput } from "../../../../utils";
import "./competitor_summary.scss";
import { AppRoutes } from "../../../../routes";

interface AddPopupProps {
  clearError: Function;
  error: AppError | null | undefined;
  list: Array<{ label: string; value: string }>;
  onAdd: Function;
  onCancel: Function;
  onChange: Function;
  saving: boolean;
  selectedOptions: string[];
  title: string;
}

const AddPopup: FC<AddPopupProps> = (props: AddPopupProps) => {
  const {
    clearError,
    error,
    list,
    onAdd,
    onCancel,
    onChange,
    saving,
    selectedOptions,
    title,
  } = props;
  const actionRef = useRef<ActionDialogHolderType>(null);
  const { t } = useTranslation();
  return (
    <ActionDialogHolder
      ref={actionRef}
      visible
      wrapperWidth={430}
      onClose={() => onCancel()}
      onDissmiss={() => onCancel()}
    >
      <DialogWrapper onClose={() => onCancel()}>
        <div className="cmpny-op-f-container">
          <div className="cmpny-op-f-header">
            <FormDialogTilteHeader
              title={title}
              titleIcon={<SalesmarketIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <div className="cmpny-op-f-content">
            <CustomMultiFormSelect
              name={title}
              list={list}
              onChange={(value) => onChange(value)}
              required={true}
              placeholder={t("select")}
              value={selectedOptions}
              customStyle={{ width: "-webkit-fill-available" }}
            />
            {error && (
              <div className={"error-section"}>
                <ApiError message={error?.message} onClose={() => clearError} />
              </div>
            )}
          </div>
          <div className="cmpny-op-f-actions">
            <div className="btn-cancel">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("cancel")}
                enable={true}
                backgroundColor={THEME.secondaryColor4}
                onClick={() => onCancel()}
              />
            </div>
            <div className="btn-save">
              <CustomButton
                loading={saving}
                textStyle={{ textTransform: "capitalize" }}
                name={t("add")}
                enable={selectedOptions.length != 0}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => onAdd()}
              />
            </div>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );
};

interface RemovePopupProps {
  clearError: Function;
  error: AppError | null | undefined;
  onRemove: Function;
  onCancel: Function;
  saving: boolean;
  title: string;
}

const RemovePopup: FC<RemovePopupProps> = (props: RemovePopupProps) => {
  const { clearError, error, onCancel, onRemove, saving, title } = props;
  const actionRef = useRef<ActionDialogHolderType>(null);
  const { t } = useTranslation();

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible
      wrapperWidth={430}
      onClose={() => onCancel()}
      onDissmiss={() => onCancel()}
    >
      <div className="del-role-container">
        <div className="del-role-header">
          <div className="title-txt">
            <span>{`${t("attention")}!!`}</span>
          </div>
        </div>
        <div className="del-role-content">
          <div className="message-txt">
            <span>{title}</span>
          </div>
          {error && (
            <div className={"error-section"}>
              <ApiError message={error.message} onClose={() => clearError()} />
            </div>
          )}
        </div>
        <div className="del-role-action-holder">
          <div className="del-role-action">
            <div className="btn-no">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("cancel")}
                enable={true}
                backgroundColor={THEME.secondaryColor4}
                onClick={() => onCancel()}
              />
            </div>
            <div className="btn-yes">
              <CustomButton
                loading={saving}
                textStyle={{ textTransform: "capitalize" }}
                name={t("delete")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => onRemove()}
              />
            </div>
          </div>
        </div>
      </div>
    </ActionDialogHolder>
  );
};
interface Props {
  history: any;
}
export const CompetitorSummary: React.FunctionComponent<Props> = (props) => {
  const { history } = props
  const competitorDetailsState = useAppSelector((state) =>
    selectCompetitorDetailsState(state)
  );
  const [competitor, setCompetitor] = useState<any>({
    ...competitorDetailsState.competitorInfo,
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [addOption, setAddOption] = useState<{
    visible: boolean;
    optionType: string;
  } | null>(null);
  const [removeOption, setRemoveOption] = useState<{
    visible: boolean;
    optionType: string;
    value: ISkillOption;
  } | null>(null);
  const businessSectorListState = useAppSelector((state) =>
    selectBusinessSectorListState(state)
  );
  const businessSectors = useAppSelector((state) =>
    selectBusinessSectorList(state)
  );
  const companiesListState = useAppSelector((state) =>
    selectCompaniesListState(state)
  );
  const companies = useAppSelector((state) =>
    selectCompaniesList(state)
  ).filter((t) => t.type === "COMPANY");

  const updateState = useAppSelector((state) =>
    selectManageCompetitorUpdateState(state)
  );

  useEffect(() => {
    dispatch(getCompaniesList());
    dispatch(getDepartmentsList());
    dispatch(getBusinessSectors());
  }, []);

  useEffect(() => {
    if (updateState.loading == LoadingType.succeeded) {
      dispatch(postSnackbarMessage(updateState.response ?? null));
      dispatch(manageCompetitorActions.clearUpdateState());
      if (competitorDetailsState.competitorInfo?.id) {
        dispatch(
          getCompetitorDetails(competitorDetailsState.competitorInfo?.id)
        );
        cancelRemove();
      }
    }
  }, [updateState.loading]);

  const getAddSavingStatus = () => updateState.loading === LoadingType.pending;

  const getRemoveSavingStatus = () =>
    updateState.loading === LoadingType.pending;

  const getAddError = () => updateState.error;

  const getRemoveError = () => updateState.error;

  const getList = (optionType) => {
    switch (optionType) {
      case "sectors":
        return businessSectors.map((doc, index) => ({
          label: doc.business_sector ?? "",
          value: doc.id ?? "",
        }));
      case "companies":
        return companies.map((doc, index) => ({
          label: doc.name ?? "",
          value: doc.id ?? "",
        }));
      default:
        return [];
    }
  };

  const handleAddOption = (option: string) => {
    setAddOption({ visible: true, optionType: option });
    switch (option) {
      case "sectors":
        setSelectedOptions(competitor?.domain_expertise_ids ?? []);
        break;
      case "companies":
        setSelectedOptions(competitor?.competitor_customers_ids ?? []);
        break;
      default:
        return [];
    }
  };

  function handleCompanySelect(value: ISkillOption) {
    history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.id}`,
      state: {
        comapanyId: value.id,
        companyName: value.value,
      }
    });
    return;
  }

  const handleRemoveOption = (type: string, value: ISkillOption) => {
    setRemoveOption({ visible: true, optionType: type, value: value });
  };

  const cancelAdd = () => {
    setAddOption({ visible: false, optionType: "" });
    handleAddClearError();
    setSelectedOptions([]);
  };

  const cancelRemove = () => {
    setRemoveOption({
      visible: false,
      optionType: "",
      value: { id: "", value: "" },
    });
    handleRemoveClearError();
  };

  const handleAddClearError = () => {
    dispatch(manageCompetitorActions.clearUpdateState());
  };

  const handleRemoveClearError = () => { };

  const handleAdd = () => {
    switch (addOption?.optionType) {
      case "sectors": {
        const payload = {
          ...competitor,
          domain_expertise_ids: selectedOptions,
        };
        setCompetitor(payload);
        dispatch(updateCompetitor(payload));
        break;
      }
      case "companies": {
        const payload = {
          ...competitor,
          competitor_customers_ids: selectedOptions,
        };
        setCompetitor(payload);
        dispatch(updateCompetitor(payload));
        break;
      }
      default: {
        break;
      }
    }
    cancelAdd();
  };

  const handleRemove = () => {
    switch (removeOption?.optionType) {
      case "sectors": {
        const payload = {
          ...competitor,
          domain_expertise_ids: competitor.domain_expertise_ids.filter(
            (di) => di !== removeOption?.value.id
          ),
        };
        setCompetitor(payload);
        dispatch(updateCompetitor(payload));
        break;
      }
      case "companies": {
        const payload = {
          ...competitor,
          competitor_customers_ids: competitor.competitor_customers_ids.filter(
            (di) => di !== removeOption?.value.id
          ),
        };
        setCompetitor(payload);
        dispatch(updateCompetitor(payload));
        break;
      }
      default: {
        break;
      }
    }
    cancelRemove();
  };

  return (
    <div className={`cd-cs-covv-container competitor-summary`}>
      <div className="cd-cs-covv-section">
        <div className="cd-cs-covv-header">
          <div className="cd-cs-covv-header-title-txt">
            <span>{"COMPETITOR OVERVIEW"}</span>
          </div>
        </div>
        <div className="cd-cs-covv-content">
          <FormInput
            id={"overview"}
            onChange={(field: any, value: any) => {
              if (value !== null) {
                setCompetitor({ ...competitor, overview: value });
              }
            }}
            required={false}
            placeholder={""}
            type={"text"}
            multiline={true}
            value={competitor.overview}
            labelStyles={{ fontSize: "18px", marginTop: "25px" }}
            numberOfLines={3}
            fieldStyles={{ width: "25vw" }}
            disabled
          />
        </div>
      </div>
      <MultiOptionSection
        headerTitle={t("industry_expertise").toUpperCase()}
        actionName={t("industry_expertise")}
        onClick={() => handleAddOption("sectors")}
        list={businessSectors
          .filter(
            (i) =>
              competitor?.domain_expertise_ids.findIndex((p) => p === i.id) !==
              -1
          )
          .map((i) => ({
            value: i.business_sector,
            id: i.id,
          }))}
        onRemove={(value) => handleRemoveOption("sectors", value)}
        hideAddButton
        actions=""
      />
      <MultiOptionSection
        headerTitle={t("competitor_customers").toUpperCase()}
        actionName={t("competitor_customers")}
        onClick={() => handleAddOption("companies")}
        list={companies
          .filter(
            (i) =>
              competitor?.competitor_customers_ids.findIndex(
                (p) => p === i.id
              ) !== -1
          )
          .map((i) => ({
            value: i.name ?? "",
            id: i.id ?? "",
          }))}
        onRemove={(value) => handleRemoveOption("companies", value)}
        onSelect={(value) => handleCompanySelect(value)}
        hideAddButton
        actions=""
      />
      {addOption && addOption.visible && (
        <Portal>
          <AddPopup
            clearError={handleAddClearError}
            error={getAddError()}
            list={getList(addOption.optionType)}
            onAdd={handleAdd}
            onCancel={cancelAdd}
            onChange={(value) => setSelectedOptions(value)}
            saving={getAddSavingStatus()}
            selectedOptions={selectedOptions}
            title={`${t("add_option", { value: addOption.optionType })}`}
          />
        </Portal>
      )}
      {removeOption && removeOption.visible && (
        <Portal>
          <RemovePopup
            clearError={handleRemoveClearError}
            error={getRemoveError()}
            onCancel={cancelRemove}
            onRemove={handleRemove}
            saving={getRemoveSavingStatus()}
            title={t("are_you_sure_you_want_to_delete", {
              value: ` ${removeOption.value.value} `,
            })}
          />
        </Portal>
      )}
    </div>
  );
};
