
import { useEffect, useState } from 'react';
import './back_office_payroll_set_up.scss';
import { useTranslation } from 'react-i18next';
import { getTalentBOPayrollDirectDepositThunk, getTalentBOPayrollThunk, selectProfileState, selectSingleTalentPRSStateById, updateTalentBOPayrollThunk, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { RouteComponentProps } from 'react-router-dom';
import { LoadingType } from '../../../../enums';
import { SpinnerScreen, convertNumberToMoney } from '../../../../utils';
import { ITalentBackOfficeContributions, ITalentBackOfficeDeduction, ITalentBackOfficePayrollSetup, ITalentBackOfficeTalentAdditionalTax } from '../../../../interfaces/talent_back_office';
import Contributions from './contributions/contributions';
import Deductions from './deductions/deductions';
import TaxInformation from './tax_information/tax_information';
import TalentBODirectDeposit from './direct_deposit/direct_deposit';
import { talentPRSetupSliceActions } from '../../../../redux/talent/details/back_office_payroll_set_up/back_office_payroll/backoffice_payroll_setup_reducer';
import { talentPRDDsetupSliceActions } from '../../../../redux/talent/details/back_office_payroll_set_up/back_office_direct_deposit/back_office_direct_depost_reducer';
import GarnishmentDeductions from './garnishment_deduction/garnishment_deduction';


interface Props extends RouteComponentProps<any> { }
const PageLoading = () => {
    return (
        <div className="table-loading">
            <SpinnerScreen />
        </div>
    );
}

const BackOfficePayRollSetUp: React.FunctionComponent<Props> = (props) => {
    const { match, location, history } = props;
    const { params } = match;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const getTalentInitialForm = (value?: ITalentBackOfficePayrollSetup | null): ITalentBackOfficePayrollSetup => {
        if (value) {
            return {
                id: value.id,
                talent_id: value.talent_id,
                talent_additional_tax: value.talent_additional_tax,
                talent_deduction: value.talent_deduction,
                garnishment_deduction: value.garnishment_deduction,
                talent_contribution: value.talent_contribution,
                w4_parsed: value.w4_parsed
            }
        }
        return {
            id: "",
            talent_id: params.id,
            talent_additional_tax: [
                {
                    additional_tax_id: "",
                    additional_tax_value: "",
                    w4_data: {
                        modified_by: "",
                        created_by: "",
                        modified_date: 0,
                        filing_status: "",
                        multiple_jobs: false,
                        dependent_and_other_credits: 0,
                        other_income: 0,
                        deductions: 0,
                        extra_withholding: 0
                    },
                    has_lockin_letter: false,
                    resident: false,
                    geo_code: "",

                }
            ],
            talent_deduction: [
                {
                    deduction_id: "",
                    deduction_value: "",
                    deduction_amount: 0,
                    deduction_percentage: 0,
                    deduction_start_date: 0,
                    deduction_end_date: 0,
                    deduction_institution_id: "",
                    deduction_institution_value: "",
                    deduction_reference: 0,
                    deduction_pay_period_limit: 0,
                    deduction_monthly_limit: 0,
                    deduction_annual_limit: 0,
                    deduction_life_time_limit: 0,
                    deduction_expires: false,
                    is_active: false,
                    is_deduction_end: false,
                    note: "",
                }
            ],
            garnishment_deduction: [
                {
                    deduction_id: "",
                    deduction_value: "",
                    deduction_amount: 0,
                    deduction_percentage: 0,
                    deduction_start_date: 0,
                    deduction_end_date: 0,
                    deduction_institution_id: "",
                    deduction_institution_value: "",
                    deduction_reference: 0,
                    deduction_pay_period_limit: 0,
                    deduction_monthly_limit: 0,
                    deduction_annual_limit: 0,
                    deduction_life_time_limit: 0,
                    deduction_expires: false,
                    is_active: false,
                    is_deduction_end: false,
                    note: "",
                    case_number: ""
                }
            ],
            talent_contribution: [
                {
                    contribution_institute_id: "",
                    contribution_institute_value: "",
                    contribution_reference_no: 0,
                    contribution_pay_period_limit: 0,
                    contribution_monthly_limit: 0,
                    contribution_annual_limit: 0,
                    contribution_life_time_limit: 0,
                    contribution_start_date: 0,
                    contribution_end_date: 0,
                    contribution_amount: 0,
                    contribution_percentage: 0,
                    is_active: false,
                    frequency: "",
                    note: ""
                }
            ],
            w4_parsed: false,

        }
    }


    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`;

    const talentPayrollState = useAppSelector((state) => selectSingleTalentPRSStateById(params.id)(state));
    const updateTalentPayrollState = talentPayrollState.update;


    // const [findParent, setFindParent] = useState<{ finance_collections: boolean, billing_schedule_and_additional_items: boolean, invoice_format: boolean, credit_profile: boolean }>({ finance_collections: false, billing_schedule_and_additional_items: false, invoice_format: false, credit_profile: false })
    useEffect(() => {
        getTalentBOPayrollData();
        // handleUpdateClearState();
        getTalentBODDData();
        handleDDUpdateClearState();
    }, []);
    const getTalentBOPayrollData = () => {
        dispatch(getTalentBOPayrollThunk(params.id));
    }
    const getTalentBODDData = () => {
        dispatch(getTalentBOPayrollDirectDepositThunk(params.id));
    }
    const [formState, setFormState] = useState<ITalentBackOfficePayrollSetup>(getTalentInitialForm());


    useEffect(() => {
        if (talentPayrollState && talentPayrollState.loading === LoadingType.succeeded) {
            setFormState(getTalentInitialForm(talentPayrollState.data));
            handleUpdateClearState();
        }
    }, [talentPayrollState, talentPayrollState.loading]);

    function handleClearError() {
        dispatch(talentPRSetupSliceActions.clearTalentBOPRstateError(params.id));
    };
    function handleUpdateClearState() {
        dispatch(talentPRSetupSliceActions.initializeState(params.id));
    };
    function handleDDUpdateClearState() {
        dispatch(talentPRDDsetupSliceActions.initializeState(params.id));
    };
    function handleClearDDError() {
        dispatch(talentPRDDsetupSliceActions.clearTalentBOPRstateError(params.id));
    };
    const handleAddTaxInfo = (doc: ITalentBackOfficeTalentAdditionalTax, parent: string, indexToUpdate?: number, removeItem?: boolean) => {
        const updatedDoc = {
            ...doc,
            w4_data: {
                ...doc.w4_data,
                other_income: convertNumberToMoney(doc.w4_data.other_income, 'number') as number,
                deductions: convertNumberToMoney(doc.w4_data.deductions, 'number') as number,
                extra_withholding: convertNumberToMoney(doc.w4_data.extra_withholding, 'number') as number,
                dependent_and_other_credits: convertNumberToMoney(doc.w4_data.dependent_and_other_credits, 'number') as number,
            }
        }
        if (formState && formState[parent]) {
            if (removeItem && indexToUpdate !== undefined && indexToUpdate !== -1) {
                dispatch(updateTalentBOPayrollThunk({
                    data: {
                        ...formState,
                        [parent]: formState[parent].filter((_, index) => index !== indexToUpdate),
                        w4_parsed: formState.w4_parsed
                    },
                    nextStep: false, isRemove: true
                }));
            }
            else {
                if (indexToUpdate === undefined) {
                    dispatch(updateTalentBOPayrollThunk({
                        data: {
                            ...formState,
                            [parent]: formState[parent].concat(updatedDoc),
                            w4_parsed: formState.w4_parsed
                        }, nextStep: false
                    }));
                }
                else if (indexToUpdate !== -1) {
                    const updatedTaxInfo = [...formState[parent]];
                    updatedTaxInfo[indexToUpdate!] = {
                        ...updatedTaxInfo[indexToUpdate!],
                        ...updatedDoc,
                    };

                    dispatch(updateTalentBOPayrollThunk({
                        data: {
                            ...formState,
                            [parent]: updatedTaxInfo,
                            w4_parsed: formState.w4_parsed
                        }, nextStep: false
                    }));
                }
            }

        }
    };
    const handleAddDeduction = (doc: ITalentBackOfficeDeduction, parent: string, indexToUpdate?: number, removeItem?: boolean) => {
        const updatedDoc = {
            ...doc,
            deduction_amount: convertNumberToMoney(doc.deduction_amount, 'number') as number,
            deduction_percentage: convertNumberToMoney(doc.deduction_percentage, 'number') as number,
            deduction_pay_period_limit: convertNumberToMoney(doc.deduction_pay_period_limit, 'number') as number,
            deduction_monthly_limit: convertNumberToMoney(doc.deduction_monthly_limit, 'number') as number,
            deduction_annual_limit: convertNumberToMoney(doc.deduction_annual_limit, 'number') as number,
            deduction_life_time_limit: convertNumberToMoney(doc.deduction_life_time_limit, 'number') as number

        }
        if (formState && formState[parent]) {
            if (removeItem && indexToUpdate !== undefined && indexToUpdate !== -1) {
                dispatch(updateTalentBOPayrollThunk({
                    data: {
                        ...formState,
                        [parent]: formState[parent].filter((_, index) => index !== indexToUpdate),
                    },
                    nextStep: false, isRemove: true
                }));
            }
            else {
                if (indexToUpdate === undefined) {
                    dispatch(updateTalentBOPayrollThunk({
                        data: {
                            ...formState,
                            [parent]: formState[parent].concat(updatedDoc),
                        }, nextStep: false
                    }));
                }
                else if (indexToUpdate !== -1) {
                    const updatedTaxInfo = [...formState[parent]];
                    updatedTaxInfo[indexToUpdate!] = {
                        ...updatedTaxInfo[indexToUpdate!],
                        ...updatedDoc,
                    };

                    dispatch(updateTalentBOPayrollThunk({
                        data: {
                            ...formState,
                            [parent]: updatedTaxInfo,
                        }, nextStep: false
                    }));
                }
            }

        }
    };
    const handleAddGarnishmentDeduction = (
        doc: ITalentBackOfficeDeduction, 
        parent: string, 
        indexToUpdate?: number, 
        removeItem?: boolean
    ) => {    
        const updatedDoc = {
            ...doc,
            deduction_amount: convertNumberToMoney(doc.deduction_amount, 'number') as number,
            deduction_percentage: convertNumberToMoney(doc.deduction_percentage, 'number') as number,
            deduction_pay_period_limit: convertNumberToMoney(doc.deduction_pay_period_limit, 'number') as number,
            deduction_monthly_limit: convertNumberToMoney(doc.deduction_monthly_limit, 'number') as number,
            deduction_annual_limit: convertNumberToMoney(doc.deduction_annual_limit, 'number') as number,
            deduction_life_time_limit: convertNumberToMoney(doc.deduction_life_time_limit, 'number') as number
        };
    
        const currentParentArray = formState?.[parent] || [];
    
        if (removeItem && indexToUpdate !== undefined && indexToUpdate !== -1) {
            dispatch(updateTalentBOPayrollThunk({
                data: {
                    ...formState,
                    [parent]: currentParentArray.filter((_, index) => index !== indexToUpdate),
                },
                nextStep: false,
                isRemove: true
            }));
        } else {
            if (indexToUpdate === undefined) {
                dispatch(updateTalentBOPayrollThunk({
                    data: {
                        ...formState,
                        [parent]: [...currentParentArray, updatedDoc],
                    },
                    nextStep: false
                }));
            } else if (indexToUpdate !== -1) {
                const updatedTaxInfo = [...currentParentArray];
                updatedTaxInfo[indexToUpdate] = {
                    ...updatedTaxInfo[indexToUpdate],
                    ...updatedDoc,
                };
    
                dispatch(updateTalentBOPayrollThunk({
                    data: {
                        ...formState,
                        [parent]: updatedTaxInfo,
                    },
                    nextStep: false
                }));
            }
        }
    };
    
    const handleAddContribution = (doc: ITalentBackOfficeContributions, parent: string, indexToUpdate?: number, removeItem?: boolean) => {
        const updatedDoc = {
            ...doc,
            contribution_amount: convertNumberToMoney(doc.contribution_amount, 'number') as number,
            contribution_percentage: convertNumberToMoney(doc.contribution_percentage, 'number') as number,
            contribution_pay_period_limit: convertNumberToMoney(doc.contribution_pay_period_limit, 'number') as number,
            contribution_monthly_limit: convertNumberToMoney(doc.contribution_monthly_limit, 'number') as number,
            contribution_annual_limit: convertNumberToMoney(doc.contribution_annual_limit, 'number') as number,
            contribution_life_time_limit: convertNumberToMoney(doc.contribution_life_time_limit, 'number') as number

        }
        if (formState && formState[parent]) {
            if (removeItem && indexToUpdate !== undefined && indexToUpdate !== -1) {
                dispatch(updateTalentBOPayrollThunk({
                    data: {
                        ...formState,
                        [parent]: formState[parent].filter((_, index) => index !== indexToUpdate),
                    },
                    nextStep: false, isRemove: true
                }));
            }
            else {
                if (indexToUpdate === undefined) {
                    dispatch(updateTalentBOPayrollThunk({
                        data: {
                            ...formState,
                            [parent]: formState[parent].concat(updatedDoc),
                        }, nextStep: false
                    }));
                }
                else if (indexToUpdate !== -1) {
                    const updatedTaxInfo = [...formState[parent]];
                    updatedTaxInfo[indexToUpdate!] = {
                        ...updatedTaxInfo[indexToUpdate!],
                        ...updatedDoc,
                    };

                    dispatch(updateTalentBOPayrollThunk({
                        data: {
                            ...formState,
                            [parent]: updatedTaxInfo,
                        }, nextStep: false
                    }));
                }
            }

        }
    };

    return (
        <>
            {talentPayrollState.loading === LoadingType.pending ? <PageLoading /> :
                (
                    <div className="backoffice-payroll-container">
                        {/* <div className="backoffice-container-holder"> */}
                        <div className="backoffice-taxinfo-card backoffice-card">
                            <TaxInformation
                                currentLoginUserName={currentLoginUserName}
                                taxInfoUpdateState={updateTalentPayrollState}
                                formState={formState}
                                talentId={params.id}
                                talentName={(location.state as any).name}
                                handleUpdateClearState={handleUpdateClearState}
                                getTalentBOPayrollData={getTalentBOPayrollData}
                                handleAddTaxInfo={handleAddTaxInfo}
                                onClearError={handleClearError}
                            />
                        </div>
                        <div className="backoffice-ded-card backoffice-card">
                            <Deductions
                                currentLoginUserName={currentLoginUserName}
                                dedUpdateState={updateTalentPayrollState}
                                formState={formState}
                                talentId={params.id}
                                talentName={(location.state as any).name}
                                handleUpdateClearState={handleUpdateClearState}
                                getTalentBOPayrollData={getTalentBOPayrollData}
                                handleAddDeduction={handleAddDeduction}
                                onClearError={handleClearError}
                                history={history}

                            />
                        </div>
                        <div className="backoffice-ded-card backoffice-card">
                            <GarnishmentDeductions
                                currentLoginUserName={currentLoginUserName}
                                dedUpdateState={updateTalentPayrollState}
                                formState={formState}
                                talentId={params.id}
                                talentName={(location.state as any).name}
                                handleUpdateClearState={handleUpdateClearState}
                                getTalentBOPayrollData={getTalentBOPayrollData}
                                handleAddGarnishmentDeduction={handleAddGarnishmentDeduction}
                                onClearError={handleClearError}
                                history={history}

                            />
                        </div>
                        <div className="backoffice-dd-card backoffice-card">
                            <TalentBODirectDeposit
                                currentLoginUserName={currentLoginUserName}
                                talentId={params.id}
                                talentName={(location.state as any).name}
                                handleUpdateClearState={handleDDUpdateClearState}
                                onClearError={handleClearDDError}
                                history={history}
                            />
                        </div>
                        <div className="backoffice-cont-card backoffice-card">
                            <Contributions
                                currentLoginUserName={currentLoginUserName}
                                contributionUpdateState={updateTalentPayrollState}
                                formState={formState}
                                talentId={params.id}
                                talentName={(location.state as any).name}
                                handleUpdateClearState={handleUpdateClearState}
                                getTalentBOPayrollData={getTalentBOPayrollData}
                                handleAddContribution={handleAddContribution}
                                onClearError={handleClearError}
                                history={history}
                            />
                        </div>
                        {/* </div> */}
                    </div>
                )
            }
        </>
    )
}

export default BackOfficePayRollSetUp;