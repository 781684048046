import { RouteComponentProps } from "react-router-dom";
import {
  CustomButton,
  getPlusBtnIcon,
  paperPlaneBtnIcon,
} from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { Portal } from "react-portal";
import CreateTalentAccount from "../../popups/craete_login_talent/create_login_talent";
import "./on_boarding_and_docs.scss";
import {
  getTalentById,
  getTalentODRSThunk,
  selectSingleTalentODRSStateById,
  selectTalentDetailsByIdState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { clearCreateLoginForTalentState } from "../../../../redux/talent/details/onboarding_and_docs/create_talent_login/create_talent_login_reducer";
import { FormInputOption, ITalent } from "../../../../interfaces";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import { CloseIcon } from "../../../../icons";
import { forgotPassword } from "../../../../redux/forgot_password/forgot_password_thunk";
import { selectForgotPasswordState } from "../../../../redux/forgot_password/forgot_password_selector";
import RenderInput from "../../../../components/render_input/render_input";
import { RegxPattern } from "../../../../utils";
import OnboardingDocs from "./onboarding_docs/onboarding_docs";
import CertificationsAndOthers from "./certifications_and_others/certifications_and_others";
import HiringAndOnboardingData from "./hiring_and_onboarding_data/hiring_and_onboarding_data";
import { selectTalentODRSState } from "../../../../redux/talent/details/onboarding_and_docs/application_status/application_status_reducer";


interface Props extends RouteComponentProps<any> {
}

const OnBoardingAndDocs: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [popupLoginOpen, setLoginPopupOPen] = useState(false);
  const [showResetPage, setShowResetPage] = useState(false);
  const { history, location, match } = props;
  const { params } = match;

  const { data: talentData } = useAppSelector((state) =>
    selectTalentDetailsByIdState(params.id)(state)
  );
  useEffect(() => {
    dispatch(getTalentODRSThunk({ talent_id: params.id }));
  }, []);

  useEffect(() => {
    dispatch(getTalentById(params.id));
  }, [params.id]);

  function handleOnCreateLoginSuccess() {
    setLoginPopupOPen(false);
    dispatch(clearCreateLoginForTalentState());
  }

  return (
    <div className="tlnt-obd-container">
      
      <div className="tlnt-obd-docs-holder">
        <OnboardingDocs
          talentId={params.id}
          employeePortalAccess={talentData?.employee_portal_access ?? true}
          talentData={talentData}
          onCreateLogin={() => setLoginPopupOPen(true)}
          onResetPassword={() => setShowResetPage(true)}
        />
      </div>
      <div className="tlnt-obd-other-docs-holder">
        <div className="tlnt-obd-certs">
          <CertificationsAndOthers talent_id={params.id}/>
        </div>
        <div className="tlnt-obd-hrng">
          <HiringAndOnboardingData talent_id={params.id}/>
        </div>
      </div>

      {showResetPage && (
        <Portal>
          <ResetAction
            onClose={() => setShowResetPage(false)}
            onDismiss={() => setShowResetPage(false)}
            visible={showResetPage}
            talent={talentData}
          />
        </Portal>
      )}

      {popupLoginOpen && (
        <Portal>
          <CreateTalentAccount
            visible={popupLoginOpen}
            onClose={() => setLoginPopupOPen(false)}
            onDissmiss={() => setLoginPopupOPen(false)}
            onSuccessClose={handleOnCreateLoginSuccess}
            talentId={params.id}
          />
        </Portal>
      )}
      
    </div>
  );
};

const resetPassword: FormInputOption = {
  field: "email",
  label: "email",
  secure: false,
  required: false,
  type: "emailAddress",
  pattern: RegxPattern.email,
  disabled: true,
};

const ResetAction = ({ onClose, onDismiss, visible, talent }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const actionRef = useRef<ActionDialogHolderType>(null);

  const [successMessage, setSuccessMessage] = useState("");
  const [resetState, setResetState] = useState({
    email: talent?.email ?? "",
  });
  const [sentRequest, setSentRequest] = useState(false);

  const forgotPasswordState = useAppSelector((state) =>
    selectForgotPasswordState(state)
  );

  function handleFieldChange(fieldId, value) {
    setResetState({ ...resetState, [fieldId]: value });
  }

  function handleForgot() {
    dispatch(forgotPassword(resetState.email));
    setSentRequest(true);
  }

  useEffect(() => {
    if (forgotPasswordState.loading === LoadingType.succeeded && sentRequest) {
      setSuccessMessage(forgotPasswordState.response);
      setTimeout(() => setSuccessMessage(""), 5000);
      setSentRequest(false);
    }
  }, [forgotPasswordState.loading]);

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={450}
      onClose={onClose}
      onDissmiss={onDismiss}
    >
      <div className="reset-password-dialog-container">
        <div className="reset-password-dialog-header">
          <h4>{t("reset_password")}</h4>
          <CloseIcon onClick={() => closePopup(onClose)} />
        </div>
        <div className="reset-password-dialog-content">
          <RenderInput
            doc={resetPassword}
            formState={resetState}
            handleFieldChange={handleFieldChange}
            formValidators={formValidators}
          />
          <div className="email-message">
            <p style={{ color: "green", fontSize: "14px" }}>{successMessage}</p>
          </div>
        </div>
        <div className="reset-password-dialog-footer">
          <CustomButton
            textStyle={{ textTransform: "capitalize" }}
            name={t("reset")}
            enable={true}
            loading={forgotPasswordState.loading == LoadingType.pending}
            padding={12}
            fontSize={15}
            backgroundColor={THEME.buttonColor1}
            onClick={handleForgot}
          />
        </div>
      </div>
    </ActionDialogHolder>
  );

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }

  function formValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case resetPassword.field: {
        if (value.text == "") return t("validators.required");
        if (RegxPattern.email.test(value.text) === false)
          return t("validators.enterValidEmail");
        return null;
      }
      default: {
        return null;
      }
    }
  }
};

export default OnBoardingAndDocs;
