import { IRootState } from "../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import * as reducer from './assignment_list_reducer';

const select = (state: IRootState) => state.companies.details.assignments.assignmentsList;
export const selectCompanyAssignmentsState = createSelector(select, reducer.selectCompanyAssignmentsState);
export const selectSingleCompanyAssignmentsStateById = (id: string | undefined | null) => createSelector(
    selectCompanyAssignmentsState,
    (state) => {
        if (id && state[id]) return reducer.selectSingleCompanyAssignmentsState(state, id);
        return reducer.selectInitialSingleCompanyAssignmentsState();
    }
);