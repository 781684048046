import { RouteComponentProps } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../components/search_bar';
import SortableTable, { TableData } from '../../../../components/sortable_table/sortable_table';
import TableEmpty, { TableLoading, TableErrorHandler, TableFilterEmpty } from '../../../../components/table_empty/table_empty';
import { THEME, LoadingType } from '../../../../enums';
import { TalentActiveStatusIcon, ClaimStatusIcon } from '../../../../icons';
import { IWorkCompClaim, IUnEmploymentClaim, ITalent } from '../../../../interfaces';
import { useAppDispatch, useAppSelector, postSnackbarMessage, selectTalentWorkCompClaimsListState, selectTalentUnEmploymentClaimsListState, getTalentWorkCompClaimDetailsByTalentId, getTalentUnEmploymentClaimDetailsByTalentId, selectTalentUnEmploymentClaimsList, selectTalentWorkCompClaimsList, selectTalentDetailsByIdState } from '../../../../redux/store';
import { AppRoutes } from '../../../../routes';
import { CustomButton, getPlusBtnIcon, getTableAccessIcon, moneyFormat, CustomCheckBox, convertNumberToMoney } from '../../../../utils';
import { getDateString } from '../../../../variables';
import Moment from 'react-moment';
import './risk_management.scss';
interface Props extends RouteComponentProps<any> { }
const wcc_tableHeader = [
  { title: "access_claim", code: "access_claim" },
  { title: "employee_name", code: "employee_name" },
  { title: "claim_status", code: "claim_status" },
  { title: "potential_charge", code: "potential_charges" },
  { title: "cost_to_date", code: "cost_to_date" },
  { title: "claim_number", code: "claim_number" },
  { title: "reported_date", code: "reported_date" },
  { title: "date_closed", code: "date_closed" },
  { title: "company", code: "company_name" },
  { title: "next_steps", code: "next_steps" },
];

const uc_tableHeader = [
  { title: "access_claim", code: "access_claim" },
  { title: "employee_name", code: "employee_name" },
  { title: "claim_status", code: "claim_status" },
  { title: "potential_charge", code: "potential_charges" },
  { title: "cost_to_date", code: "cost_to_date" },
  { title: "claim_number", code: "claim_number" },
  { title: "claim_date", code: "claim_date" },
  { title: "date_closed", code: "date_closed" },
  { title: "next_steps", code: "next_steps" },
];
const TalentRiskManagement: React.FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { history, match, location } = props;
  const { params } = match;
  const { t } = useTranslation();

  const talentState = useAppSelector((state) => selectTalentDetailsByIdState(params.id)(state));

  const talentWorkCompClaimsState = useAppSelector((state) =>
    selectTalentWorkCompClaimsListState(state)
  );
  const talentWorkCompClaimsList = useAppSelector((state) =>
    selectTalentWorkCompClaimsList(state)
  );
  const talentUnEmploymentClaimsState = useAppSelector((state) =>
    selectTalentUnEmploymentClaimsListState(state)
  );
  const talentUnEmploymentClaimsList = useAppSelector((state) =>
    selectTalentUnEmploymentClaimsList(state)
  );
  const [workCompClaimsSearch, setWorkCompClaimsSearch] = useState("");
  const [wccStatus, setWCCStatus] = useState(false);
  const [unEmploymentClaimsSearch, setunEmploymentClaimsSearch] = useState("");
  const [ucStatus, setUCStatus] = useState(false);
  const [wcc_sortedField, wcc_setSortedField] = useState<string | null>("id");
  const [wcc_sortDirection, wcc_setSortDirection] = useState("asc");
  const [uc_sortedField, uc_setSortedField] = useState<string | null>("id");
  const [uc_sortDirection, uc_setSortDirection] = useState("asc");

  useEffect(() => {
    getWorkCompClaims();
    getUnEmploymentClaims();
    return () => { };
  }, []);

  useEffect(() => {
    if (talentWorkCompClaimsState?.error != null && talentWorkCompClaimsList?.length != 0) {
      dispatch(postSnackbarMessage(talentWorkCompClaimsState?.error?.message));
    }

    return () => { };
  }, [talentWorkCompClaimsState?.error]);

  useEffect(() => {
    if (
      talentUnEmploymentClaimsState?.error != null &&
      talentUnEmploymentClaimsList?.length != 0
    ) {
      dispatch(postSnackbarMessage(talentUnEmploymentClaimsState?.error?.message));
    }

    return () => { };
  }, [talentUnEmploymentClaimsState?.error]);

  function getWorkCompClaims() {
    dispatch(getTalentWorkCompClaimDetailsByTalentId(params.id));
  }

  function getUnEmploymentClaims() {
    dispatch(getTalentUnEmploymentClaimDetailsByTalentId(params.id));
  }

  //Work Comp Claims
  function getWCCFilteredList(): IWorkCompClaim[] {
    const list = wccStatus ? getOnlyWCCOpenStatus() : talentWorkCompClaimsList;
    let sortedList: IWorkCompClaim[] | undefined;

    if (wcc_sortedField != null) {
      sortedList = [...list].sort((a, b) => {
        const valueA =
          a[wcc_sortedField] != null
            ? typeof a[wcc_sortedField] != typeof 1
              ? a[wcc_sortedField].trim().toLowerCase()
              : a[wcc_sortedField]
            : "";
        const valueB =
          b[wcc_sortedField] != null
            ? typeof b[wcc_sortedField] != typeof 1
              ? b[wcc_sortedField].trim().toLowerCase()
              : b[wcc_sortedField]
            : "";
        if (wcc_sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return (sortedList ?? list)?.filter(doc => {
      const str = workCompClaimsSearch.trim().toLowerCase();
      return Object.values(doc).some(val =>
        val && val.toString().trim().toLowerCase().includes(str)
      );
    });
  }

  function getOnlyWCCOpenStatus() {
    return talentWorkCompClaimsList?.filter((doc) => doc.claim_status === "OPEN");
  }
  function handleSortFieldChange(value: string) {
    if (wcc_sortedField && wcc_sortedField === value) {
      // wcc_setSortedField(null);
      wcc_setSortDirection(wcc_sortDirection === "asc" ? "desc" : "asc");
    } else {
      wcc_setSortedField(value);
      wcc_setSortDirection("asc");
    }
  }

  function getTalentStatusIcon() {
    return (
      <div className="status-icon">
        <TalentActiveStatusIcon
          width={"100%"}
          height={"100%"}
          style={{
            color:
              talentState?.data?.currently_on_assignment === true
                ? THEME.buttonColor13
                : THEME.statusDisabledColor,
          }}
        />
      </div>
    );
  }
  function getStatus(status: string) {
    if (status === "OPEN")
      return (
        <span style={{ color: THEME.statusInActiveColor }}>{t("open")}</span>
      );
    else if (status === "CLOSED")
      return <span style={{ color: "#b1b1b1" }}>{t("closed")}</span>;
    else return <span>{""}</span>;
  }
  function getStatusField(doc: IWorkCompClaim | IUnEmploymentClaim) {
    return (
      <div className="risk-ud-table-name">
        <div className="status-icon">
          <ClaimStatusIcon
            width={"100%"}
            height={"100%"}
            style={{
              color:
                doc.claim_status === "OPEN"
                  ? THEME.statusInActiveColor
                  : THEME.statusDisabledColor,
            }}
          />
        </div>
        <div className="name-txt">
          <span>{getStatus(doc.claim_status)}</span>
        </div>
      </div>
    );
  }
  const getDateFromTimeStamp = (value: number) => {
    const fieldValue: any = value ? new Date(value * 1000) : "";
    return (
      <div>
        <Moment format="MM.DD.YYYY">{fieldValue}</Moment>
      </div>
    );
  };

  function handleWCCSelect(value: IWorkCompClaim) {
    props.history.push({
      pathname: `${AppRoutes.workCompClaimDetailsPage}/${value.talent_id}`,
      state: {
        id: value.talent_id,
        name: `${value.employee_name ?? ""}`,
      },
    });
    return;
  }
  function handleUserSelect(value: IWorkCompClaim | IUnEmploymentClaim) {
    props.history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
      state: {
        id: value.talent_id,
        name: value.employee_name,
      },
    });
    return;
  }

  function handleWCCAddSelect() {
    history.push({
      pathname: AppRoutes.riskManagementUniversalDirectory,
      state: {
        addClaim: true,
        addType: "WORKCOMPCLAIM",
        talent_id: params.id,
        talent_ssn: talentState?.data?.last_4_digits_of_ssn,
        employee_name: `${talentState.data?.first_name} ${talentState.data?.middle_name} ${talentState.data?.last_name}`
      },
    });
  }

  function handleAddUnEmpSelect() {
    history.push({
      pathname: AppRoutes.riskManagementUniversalDirectory,
      state: {
        addClaim: true,
        addType: "UNEMPLOYMENT",
        talent_id: params.id,
        talent_ssn: talentState?.data?.last_4_digits_of_ssn,
        employee_name: `${talentState.data?.first_name} ${talentState.data?.middle_name} ${talentState.data?.last_name}`
      },
    });
  }

  return (
    <div className="risk-management-tlnt-container">
      <div className="card-div wc-claim">
        <div className="rsk-mng-ud-table-header">
          <div className="header-left">
            <div className="claim-field-row">
              <div>
                <ClaimStatusIcon
                  width={"1.5vw"}
                  height={"1.5vw"}
                  style={{ color: THEME.defaultHighLightColor }}
                />
              </div>
              <div
                className="claim-field-text"
                style={{ marginLeft: "15px", fontWeight: 900, fontSize: "1vw" }}
              >
                {t("work_comp_claims")}
              </div>
            </div>
            <div>
              <TableHeaderOptions
                totalCount={getWCCFilteredList().length}
                search={workCompClaimsSearch}
                onSearchChange={(value) => setWorkCompClaimsSearch(value)}
                openStatus={wccStatus}
                onCheckedChange={() => setWCCStatus(!wccStatus)}
              />
            </div>
          </div>
          <div className="rsk-mng-ud-actions">
            <div className="rsk-mng-ud-add-user-btn">
              <div className="rsk-mng-ud-search-btn">
                <SearchBar
                  value={workCompClaimsSearch}
                  onChange={(value) => setWorkCompClaimsSearch(value)}
                  onSearch={() => { }}
                />
              </div>
              <div>
                <CustomButton
                  leftIcon={getPlusBtnIcon()}
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("add_work_comp_claim")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={handleWCCAddSelect}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="rsk-mng-ud-container">
          <div className="rsk-mng-ud-table">{getWorkCompClaimsTableList()}</div>
        </div>
      </div>
      <div className="card-div unemp-claim">
        <div className="rsk-mng-ud-table-header">
          <div className="header-left">
            <div className="claim-field-row">
              <div>
                <ClaimStatusIcon
                  width={"1.5vw"}
                  height={"1.5vw"}
                  style={{ color: THEME.defaultHighLightColor }}
                />
              </div>
              <div
                className="claim-field-text"
                style={{ marginLeft: "15px", fontWeight: 900, fontSize: "1vw" }}
              >
                {t("unemployment_claims")}
              </div>
            </div>
            <TableHeaderOptions
              totalCount={getUCFilteredList().length}
              search={unEmploymentClaimsSearch}
              onSearchChange={(value) => setunEmploymentClaimsSearch(value)}
              openStatus={ucStatus}
              onCheckedChange={() => setUCStatus(!ucStatus)}
            />
          </div>
          <div className="rsk-mng-ud-actions">
            <div className="rsk-mng-ud-add-user-btn">
              <div className="rsk-mng-ud-search-btn">
                <SearchBar
                  value={unEmploymentClaimsSearch}
                  onChange={(value) => setunEmploymentClaimsSearch(value)}
                  onSearch={() => { }}
                />
              </div>
              <div>
                <CustomButton
                  leftIcon={getPlusBtnIcon()}
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("add_unemployment_claim")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={handleAddUnEmpSelect}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="rsk-mng-ud-container">
          <div className="rsk-mng-ud-table">
            {getUnemploymentClaimsTableList()}
          </div>
        </div>
      </div>
    </div>
  );

  /// Work Comp Claim table list
  function getWorkCompClaimsTableList() {
    if (talentWorkCompClaimsState?.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (talentWorkCompClaimsState?.error != null && talentWorkCompClaimsList?.length === 0) {
      return (
        <TableErrorHandler
          error={talentWorkCompClaimsState?.error}
          onRefresh={getWorkCompClaims}
        />
      );
    }
    if (talentWorkCompClaimsList?.length === 0) {
      return (
        <TableEmpty
          title={t("no_data_found")}
          onClick={() => getWorkCompClaims()}
        />
      );
    }
    if (getWCCFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_data_found")} />;
    }
    return (
      <SortableTable
        headerList={wcc_tableHeader}
        sortedField={wcc_sortedField}
        onSortChange={handleSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={wcc_sortDirection}
      >
        {getWCCFilteredList().map((doc, index) => {
          return (
            <tr key={doc.id}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                {getTableAccessIcon(() => handleWCCSelect(doc))}
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(1) }}
                isButton={true}
                onClick={() => handleUserSelect(doc)}
                prefix={getTalentStatusIcon()}
              >
                <span>{doc.employee_name}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(1) }}>
                <span>{getStatusField(doc)}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(1) }}>
                <span>
                  {doc.potential_charges
                    ? `${"$"}${moneyFormat(doc.potential_charges)}`
                    : ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(4) }}>
                <span>
                  {doc.cost_to_date ? `${"$"}${moneyFormat(doc.cost_to_date)}` : ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                <span>{doc.claim_number ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(6) }}>
                {doc.reported_date
                  ? getDateFromTimeStamp(doc.reported_date)
                  : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(7) }}>
                {doc.date_closed ? getDateFromTimeStamp(doc.date_closed) : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(8) }}>
                <span>{doc.company_name ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(9) }}>
                <span
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 300,
                  }}
                >
                  {doc.next_steps ?? ""}
                </span>
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 1.3;
    if (value === 1) return 2;
    if (value === 2) return 2;
    if (value === 3) return 2;
    if (value === 4) return 1.4;
    if (value === 5) return 1.4;
    if (value === 8) return 2;
    if (value === 9) return 2;
    return 1;
  }

  //Unemployment Claims

  function getUnemploymentClaimsTableList() {
    if (talentUnEmploymentClaimsState?.loading === LoadingType.pending) {
      return <TableLoading />;
    }
    if (
      talentUnEmploymentClaimsState?.error != null &&
      talentUnEmploymentClaimsList?.length === 0
    ) {
      return (
        <TableErrorHandler
          error={talentUnEmploymentClaimsState?.error}
          onRefresh={getUnEmploymentClaims}
        />
      );
    }
    if (talentUnEmploymentClaimsList?.length === 0) {
      return (
        <TableEmpty
          title={t("no_data_found")}
          onClick={() => getUnEmploymentClaims()}
        />
      );
    }
    if (getUCFilteredList().length === 0) {
      return <TableFilterEmpty title={t("no_data_found")} />;
    }
    return (
      <SortableTable
        headerList={uc_tableHeader}
        sortedField={uc_sortedField}
        onSortChange={handleUCSortFieldChange}
        flexNumber={getFlexNumber}
        isAsc={uc_sortDirection}

      >
        {getUCFilteredList().map((doc, index) => {
          return (
            <tr key={doc.id}>
              <TableData customStyle={{ flex: getFlexNumber(0) }}>
                {getTableAccessIcon(() => handleUnEmpClick(doc))}
              </TableData>
              <TableData
                customStyle={{ flex: getFlexNumber(1) }}
                isButton={true}
                onClick={() => handleUserSelect(doc)}
                prefix={getTalentStatusIcon()}
              >
                <span>{doc.employee_name}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(2) }}>
                <span>{getStatusField(doc)}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(3) }}>
                <span>
                  {doc.potential_charges
                    ? `${"$"}${moneyFormat(doc.potential_charges)}`
                    : ""}
                </span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(4) }}>
                {doc.cost_to_date ? `${"$"}${moneyFormat(doc.cost_to_date)}` : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(5) }}>
                <span>{doc.claim_number ?? ""}</span>
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(6) }}>
                {doc.claim_date ? getDateString(doc.claim_date) : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(7) }}>
                {doc.date_closed ? getDateString(doc.date_closed) : ""}
              </TableData>
              <TableData customStyle={{ flex: getFlexNumber(8) }}>
                <span
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: 300,
                  }}
                >
                  {doc.next_steps ?? ""}
                </span>
              </TableData>
            </tr>
          );
        })}
      </SortableTable>
    );
  }

  function handleUnEmpClick(doc: IUnEmploymentClaim) {
    props.history.push({
      pathname: `${AppRoutes.unEmplomentDetailsPage}/${doc.talent_id}`,
      state: {
        id: doc.talent_id,
        name: `${doc.employee_name ?? ""}`,
      },
    });
    return;
  }

  function getUCFilteredList(): IUnEmploymentClaim[] {
    const list = ucStatus ? getOnlyUCOpenStatus() : talentUnEmploymentClaimsList;
    let sortedList: IUnEmploymentClaim[] | undefined;

    if (uc_sortedField != null) {
      sortedList = [...list].sort((a, b) => {

        const valueA =
          a[uc_sortedField] != null
            ? typeof a[uc_sortedField] != typeof 1
              ? a[uc_sortedField].trim().toLowerCase()
              : a[uc_sortedField]
            : "";
        const valueB =
          b[uc_sortedField] != null
            ? typeof b[uc_sortedField] != typeof 1
              ? b[uc_sortedField].trim().toLowerCase()
              : b[uc_sortedField]
            : "";
        if (uc_sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return (sortedList ?? list)?.filter(doc => {
      const str = unEmploymentClaimsSearch.trim().toLowerCase();
      return Object.values(doc).some(val =>
        val && val.toString().trim().toLowerCase().includes(str)
      );
    });
  }

  function getOnlyUCOpenStatus() {
    return talentUnEmploymentClaimsList?.filter((doc) => doc.claim_status === "OPEN");
  }
  function handleUCSortFieldChange(value: string) {
    if (uc_sortedField && uc_sortedField === value) {
      uc_setSortDirection(uc_sortDirection === "asc" ? "desc" : "asc");
    } else {
      uc_setSortedField(value);
      uc_setSortDirection("asc");
    }
  }
};

interface TableHeaderOptionsProps {
  totalCount: number;
  search: string;
  onSearchChange: (value: string) => void;
  openStatus: boolean;
  onCheckedChange: () => void;
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
  const { t } = useTranslation();
  return (
    <div className="rsk-mng-ud-table-header-options">
      <div className="deparment-toggle">
        <CustomCheckBox
          name={"department"}
          title={"Show only Open Claims"}
          checked={props.openStatus}
          onClick={props.onCheckedChange}
        />
      </div>
      <div className="total-count">
        <span>
          {`${t("total_count")}: `}
          <span className="total-count-number">{props.totalCount}</span>
        </span>
      </div>
    </div>
  );
}

export default TalentRiskManagement;