import { combineReducers } from "@reduxjs/toolkit";
import { jobOrdersHistoryReportListSlice } from "./job_orders_history_report/job_orders_history_report_reducer";
import { openJobOrdersReportListSlice } from "./open_job_orders_report/open_job_orders_report_reducer";

export const reportCenterJobOrdersReducers = combineReducers({
    [jobOrdersHistoryReportListSlice.name]: jobOrdersHistoryReportListSlice.reducer,
    [openJobOrdersReportListSlice.name]: openJobOrdersReportListSlice.reducer,
});

export * from "./job_orders_history_report/job_orders_history_report_selector";
export * from "./job_orders_history_report/job_orders_history_report_thunk";

export * from "./open_job_orders_report/open_job_orders_report_selector";
export * from "./open_job_orders_report/open_job_orders_report_thunk";
