const popupInfoContent = {
    backOfficeInfo:
        `<div className="info-text">
            <p>The Back Office section of KinISO plays a critical role in streamlining financial processes and ensuring accurate financial management. This section is specifically tailored to meet the unique needs of staffing agencies and assists in managing financial transactions.</p>
            <span><b>Here's a brief overview of what you can expect from the weekly accounting section in KinISO:</b></span>
            <span><b>Payroll Processing:</b> KinISO's Payroll section facilitates seamless payroll processing. Staffing agencies often deal with a high volume of temporary or contract workers, and this feature automates the calculation and distribution of wages, taking into account various factors such as hours worked, rates, and deductions.</span>
            <span><b>Invoicing and Billing:</b> This section simplifies the invoicing and billing process. Staffing agencies need to send invoices to clients for the services provided by their temporary staff. KiniSO allows users to generate and send invoices easily, keeping track of payments and outstanding balances.</span>
            <span><b>Expense Tracking:</b> Managing expenses is crucial for staffing agencies. KinISO's weekly accounting section helps agencies monitor and track expenses related to employee benefits, reimbursements, and taxes. This tracking ensures accurate financial reporting and cost control.</span>
            <span><b>Client Company and Employee Payments:</b> It allows for the efficient processing of payments from client companies and payments to employees. For clients, you can record incoming payments and reconcile them with invoices. For employees, the system automates direct deposit or check issuance, ensuring timely and accurate compensation.</span>
            <span><b>Tax Compliance:</b> Staffing agencies face complex tax regulations, especially when dealing with a diverse workforce. KinISO helps agencies stay compliant by calculating and withholding the appropriate taxes and generating tax forms, such as W-2s and 1099s, as needed.</span>
            <span><b>Data Security and Compliance:</b> KinISO prioritizes data security and compliance. It often includes features to protect sensitive financial information and ensures compliance with industry-specific regulations.</span>
            <p>In summary, KinISO's Back Office section simplifies and streamlines financial management for staffing agencies. It automates payroll, invoicing, expense tracking, and tax compliance processes, ultimately saving time, reducing errors, and enabling better financial control. By providing robust reporting it empowers staffing agencies to effectively manage their financial operations.</p>
        </div>`,
    weeklyAccountingInfo:
        `<div className="info-text">
            <p>The Weekly Accounting component of the KinISO software's Back Office is a crucial feature that facilitates the efficient and organized management of financial transactions and records on a weekly basis. One of the essential tasks within this component is transitioning from the previous accounting period to the new one. By clicking "Close Accounting Period" KinISO will "scrub" your Data to make sure there are no warning or errors which should be addressed. Here's an explanation of how this process works:</p>
            <span><b>1. Closing the Previous Weekly Accounting Period:</b></span>
            <p>At the end of each accounting week, users of KinISO must perform a series of steps to close out the previous weekly accounting period. This process involves several key actions:</p>
            <span><b>a. Verification and Reconciliation:</b> Before closing the previous week, users should carefully verify and reconcile all financial transactions, including payroll, invoices, expenses, and payments, for that specific week. This ensures that all records are accurate and complete.</span>
            <span><b>b. Review of Tax Withholdings:</b> Staffing agencies often deal with tax withholdings for their employees and contractors. During the closing process, users must review and confirm that all relevant taxes have been accurately withheld and reported.</span>
            <span><b>c. Payment Processing:</b> If there are any pending payments to employees or outstanding invoices from clients for the previous week, those transactions should be processed and recorded.</span>
            <span><b>d. Verification of Compliance:</b> Compliance with industry-specific regulations and tax laws is crucial. KinISO may include features that help users ensure they are compliant with all relevant regulations during the closing process.</span>
            <span><b>2. Transition to the New Weekly Accounting Period:</b></span> 
            <p>Once the previous weekly accounting period is reconciled and verified, users can proceed to open the new weekly accounting period for the upcoming week. Here's how this transition typically occurs:</p>
            <span><b>a. Initializing a New Period:</b> Users initiate the start of a new accounting week or period within KinISO. This action sets the stage for recording all financial transactions, payroll, and other accounting activities for the upcoming week.</span>
            <span><b>b. Setting Parameters:</b> Users can define parameters for the new week, such as week ending dare, pay rates, client billing rates, and any special instructions or adjustments required for the week.</span>
            <span><b>c. Entering Transactions:</b> As the new week progresses, users enter and record all relevant financial transactions, including employee hours, expenses, client invoices, and payments. These transactions are tracked within the new accounting period.</span>
            <span><b>d. Ongoing Reporting:</b> Throughout the week, users can generate real-time financial reports and analytics for the current accounting period to monitor the agency's financial health and performance.</span>
            <p>By following these steps, KinISO's Weekly Accounting component ensures a smooth transition from the previous accounting period to the new one. It helps staffing agencies maintain financial accuracy, compliance, and control while efficiently managing their financial operations on a weekly basis.</p>
        </div>
    `,
    timeCardsInfo:
        `<div className="info-text">
            <p>The Timecards component of KinISO's Back Office is a crucial module designed to efficiently manage and track employee work hours, billable hours to client companies, and various other transactions related to time and attendance. This component simplifies the process of recording, verifying, and reconciling timecard data submitted by employees. Here's an explanation of the key features and functions of the Timecards component:</p>
            <span><b>Employee Hour Entry:</b> Users enter employee work hours for a specified time period. This includes regular working hours, overtime, and any other relevant time entries. KinISO allows for flexibility in inputting various types of work hours to accommodate different pay structures and labor regulations.</span>
            <span><b>Client Billing:</b> Within the Timecards module, users can allocate billable hours worked by employees to specific client projects or companies. This feature is essential for staffing agencies, as it enables accurate billing to clients based on the actual hours worked on their projects.</span>
            <span><b>Transaction Management:</b> KinISO's Timecards component supports the addition of various transactions beyond regular work hours. Users can input data related to holiday pay, equipment deductions, bonuses, or any other compensation or deductions that need to be factored into an employee's pay.</span>
            <p><b>Verification and Validation:</b> To ensure accuracy, the Timecards module typically includes built-in validation rules. These rules can flag discrepancies, such as missing or inconsistent time entries, helping users identify and resolve issues before processing payroll or invoicing clients.</p>
            <p><b>Hour Balancing:</b> One of the critical features is the ability to balance hours paid with hours billed. This involves comparing the total hours recorded in KinISO against the timecards submitted by employees. Any discrepancies can be investigated and reconciled to ensure that both employees and clients are billed accurately.</p>
            <p><b>Approval Workflow:</b> Your Staffing Agency will likey include an approval workflow for timecards. Supervisors or managers can review and approve timecards to verify their accuracy before payroll processing or client billing occurs. This step helps maintain control and accountability over the timecard data.</p>
            <p><b>Reporting and Analytics:</b> Users can generate reports and analytics related to employee hours, client billing, and transaction details. These reports offer insights into labor costs, project profitability, and employee productivity.</p>
            <p><b>Compliance and Record keeping:</b> The Timecards component often includes features to help staffing agencies remain compliant with labor laws and regulations. For example, overtime rules both federal and state to state. It may also maintain historical records of timecards for auditing and compliance purposes.</p>
            <p>In summary, KinISO's Timecards component streamlines the process of tracking and managing employee work hours, client billing, and various time-related transactions. It ensures accuracy through validation and approval workflows while providing insights into labor costs and project profitability. KinISO simplifies the overall workforce management process for staffing agencies, making it an essential tool for efficient operations.</p>
        </div>`,
    payrollDashBoardInfoText:
        `<div className="info-text">
            <p>The Payroll component of KinISO's Back Office is a crucial module that simplifies and streamlines the payroll process for staffing agencies. It ensures accurate and timely compensation for employees, taking into account their work hours, rates, deductions, and other payroll-related factors. Here's a detailed explanation of the Payroll component:</p>
            <span><b>Timecards Integration:</b>The Payroll component seamlessly integrates with the Timecards section of KinISO. Once timecards have been completed and approved by the relevant personnel, this data serves as the foundation for payroll processing. Timecard information includes employee work hours, overtime, breaks, and any special pay rates.</span>
            <span><b>Batch Processing:</b> Users can create batches for each payroll run within the Payroll component. These batches allow for the systematic grouping of employees and their corresponding timecard data. Batches are essential for organizing and processing payroll in a structured manner. For example, you might have separate batches for weekly, bi-weekly, or monthly payroll runs.</span>
            <span><b>Error and Warning Handling:</b> KinISO's Payroll component incorporates a robust error and warning system to catch and address critical oversights before finalizing payroll. This feature helps prevent costly errors and ensures that employees are accurately compensated. Errors and warnings can relate to discrepancies in timecard data, tax withholdings, benefit deductions, and more.</span>
            <p><b>Paycheck Generation:</b> Once all necessary data has been input and reviewed, users can initiate the payroll processing. KinISO calculates the paychecks for each employee based on the data from the timecards and other relevant information. It factors in variables such as regular pay rates, overtime, bonuses, and deductions. Tax Calculations: The Payroll component automates tax calculations to ensure compliance with federal, state, and local tax regulations. It calculates and withholds the appropriate income taxes, Social Security, Medicare, and any other required deductions. This feature helps staffing agencies stay on top of their tax responsibilities.</p>
            <p><b>Direct Deposit or Check Issuance:</b> After calculating the net pay for each employee, KinISO offers options for distributing wages. Users can choose to set up direct deposit for employees, making the process faster and more convenient. Alternatively, the system can load paycards for distribution.</p>
            <p><b>Payroll Reporting:</b> KinISO's Payroll component provides comprehensive reporting capabilities. Users can generate payroll reports that detail individual employee earnings, deductions, and tax withholdings. These reports are essential for record-keeping, auditing, and compliance purposes.</p>
            <p><b>Compliance and Security:</b> The Payroll component prioritizes data security, safeguarding sensitive employee information, and financial data.</p>
            <p>In summary, KinISO's Payroll component simplifies the payroll process for staffing agencies by integrating seamlessly with the Timecards section. It allows users to create batches for each payroll run, meticulously handles errors and warnings, calculates paychecks accurately, automates tax calculations, and provides detailed reporting. By incorporating robust features and ensuring compliance, KinISO's Payroll component enhances efficiency and accuracy in managing payroll operations for staffing agencies.</p>
        </div>`,
    billingDashBoardInfoText:
        `<div className="info-text">
            <p>The Billing & Invoices component of KinISO's Back Office is a critical feature that enables users to efficiently manage the invoicing process for staffing agencies. This component streamlines the billing process after the Timecards section has been completed, ensuring accuracy and compliance. Here's an overview of the Billing & Invoices component in KinISO:</p>
            <span><b>Invoice Creation:</b> Once the Timecards section has been completed, Users can initiate the invoice creation process. KinISO allows Users to generate invoices based on the data entered in the Timecards, which typically include details such as hours worked, rates, and employee information.</span>
            <span><b>Batch Billing:</b> KinISO provides the capability to create batches for billing. This feature is particularly useful for staffing agencies that handle a high volume of transactions. Users can group invoices into batches based on various criteria, such as client, project, or billing cycle. Batch billing simplifies the management of multiple invoices simultaneously.</span>
            <span><b>Errors and Warnings:</b> To ensure accuracy and compliance, KinISO's Billing & Invoices component includes a robust system for error detection and warnings. This functionality helps identify any critical oversights or discrepancies in the invoicing process before finalizing invoices. Common errors that may be flagged include missing time entries, rate discrepancies, or incomplete client information.</span>
            <p><b>Data Validation:</b> The system performs data validation checks during the invoice creation process. This ensures that all required fields are filled out correctly and that the information aligns with the agency's billing policies and client agreements.</p>
            <p><b>Customization:</b> KinISO offers customization options for invoice templates. Users can personalize the format and appearance of their invoices to match their branding or client preferences. Customization include adding agency logos, specific payment terms, and contact information.</p>
            <p><b>Review and Approval:</b> Depending on the workflow setup, your staffing agency may incorporate a review and approval process for invoices. This feature allows designated users or managers to review invoices for accuracy and completeness before they are sent to clients.</p>
            <p><b>Automation:</b> KinISO automates many aspects of the billing process, such as calculating totals, taxes, and discounts. This automation reduces the risk of human error and ensures that invoices are consistent and compliant with applicable tax regulations.</p>
            <p><b>Client Communication:</b> Once invoices are finalized and approved, KinISO provides functionality for sending invoices directly to clients via email or other preferred communication channels. This expedites the billing cycle and helps agencies receive payments in a timely manner.</p>
            <p><b>Payment Tracking:</b> The KinISO system includes features for tracking payments received against invoices. This allows users to monitor outstanding balances and quickly identify overdue invoices.</p>
            <p><b>Reporting and Analytics:</b> KinISO's Billing & Invoices component often offers reporting and analytics tools, enabling users to gain insights into their agency's billing performance. This includes generating reports on revenue, invoice aging, and client payment history.</p>
            <p>In summary, KinISO's Billing & Invoices component simplifies and automates the invoicing process for staffing agencies. It ensures accuracy, compliance, and efficiency by allowing users to create batches for billing, conducting error and warning checks, and offering customization options. By streamlining invoice creation and management, KinISO helps staffing agencies maintain a healthy financial workflow and improve their cash flow management.</p>
        </div>`
};


export default popupInfoContent;