import { IPDFAutoFill } from "../../interfaces/pdf_auto_fill";

export const I9FormFields: { [key: string]: IPDFAutoFill } = {
    "3 A lawful permanent resident Enter USCIS or ANumber": { "key": "3 A lawful permanent resident Enter USCIS or ANumber", "mapTo": null },
    "Signature of Employee": { "key": "Signature of Employee", "mapTo": null },
    "Last Name Family Name from Section 1": { "key": "Last Name Family Name from Section 1", "mapTo": null },
    "First Name Given Name from Section 1": { "key": "First Name Given Name from Section 1", "mapTo": null },
    "Middle initial if any from Section 1": { "key": "Middle initial if any from Section 1", "mapTo": null },
    "First Name Given Name": { "key": "First Name Given Name", "mapTo": null },
    "Employee Middle Initial (if any)": { "key": "Employee Middle Initial (if any)", "mapTo": null },
    "Employee Other Last Names Used (if any)": { "key": "Employee Other Last Names Used (if any)", "mapTo": null },
    "Today's Date mmddyyy": { "key": "Today's Date mmddyyy", "mapTo": null },
    "Sig Date mmddyyyy 1": { "key": "Sig Date mmddyyyy 1", "mapTo": null },
    "Sig Date mmddyyyy 0": { "key": "Sig Date mmddyyyy 0", "mapTo": null },
    "Sig Date mmddyyyy 2": { "key": "Sig Date mmddyyyy 2", "mapTo": null },
    "Sig Date mmddyyyy 3": { "key": "Sig Date mmddyyyy 3", "mapTo": null },
    "Signature of Preparer or Translator 0": { "key": "Signature of Preparer or Translator 0", "mapTo": null },
    "Signature of Preparer or Translator 1": { "key": "Signature of Preparer or Translator 1", "mapTo": null },
    "Signature of Preparer or Translator 2": { "key": "Signature of Preparer or Translator 2", "mapTo": null },
    "Signature of Preparer or Translator 3": { "key": "Signature of Preparer or Translator 3", "mapTo": null },
    "Preparer or Translator Last Name (Family Name) 0": { "key": "Preparer or Translator Last Name (Family Name) 0", "mapTo": null },
    "Preparer or Translator Last Name (Family Name) 2": { "key": "Preparer or Translator Last Name (Family Name) 2", "mapTo": null },
    "Preparer or Translator Last Name (Family Name) 3": { "key": "Preparer or Translator Last Name (Family Name) 3", "mapTo": null },
    "Preparer or Translator Address (Street Number and Name) 0": { "key": "Preparer or Translator Address (Street Number and Name) 0", "mapTo": null },
    "Preparer or Translator Address (Street Number and Name) 1": { "key": "Preparer or Translator Address (Street Number and Name) 1", "mapTo": null },
    "Preparer or Translator Address (Street Number and Name) 2": { "key": "Preparer or Translator Address (Street Number and Name) 2", "mapTo": null },
    "Preparer or Translator Address (Street Number and Name) 3": { "key": "Preparer or Translator Address (Street Number and Name) 3", "mapTo": null },
    "Preparer or Translator First Name (Given Name) 0": { "key": "Preparer or Translator First Name (Given Name) 0", "mapTo": null },
    "Preparer or Translator Last Name (Family Name) 1": { "key": "Preparer or Translator Last Name (Family Name) 1", "mapTo": null },
    "Preparer or Translator First Name (Given Name) 2": { "key": "Preparer or Translator First Name (Given Name) 2", "mapTo": null },
    "Preparer or Translator First Name (Given Name) 3": { "key": "Preparer or Translator First Name (Given Name) 3", "mapTo": null },
    "Preparer or Translator City or Town 0": { "key": "Preparer or Translator City or Town 0", "mapTo": null },
    "Preparer or Translator City or Town 1": { "key": "Preparer or Translator City or Town 1", "mapTo": null },
    "Preparer or Translator City or Town 2": { "key": "Preparer or Translator City or Town 2", "mapTo": null },
    "Preparer or Translator City or Town 3": { "key": "Preparer or Translator City or Town 3", "mapTo": null },
    "Preparer State 0": { "key": "Preparer State 0", "mapTo": null },
    "Preparer State 1": { "key": "Preparer State 1", "mapTo": null },
    "Preparer State 2": { "key": "Preparer State 2", "mapTo": null },
    "Preparer State 3": { "key": "Preparer State 3", "mapTo": null },
    "Zip Code 0": { "key": "Zip Code 0", "mapTo": null },
    "Zip Code 1": { "key": "Zip Code 1", "mapTo": null },
    "Zip Code 2": { "key": "Zip Code 2", "mapTo": null },
    "Zip Code 3": { "key": "Zip Code 3", "mapTo": null },
    "CB_1": { "key": "CB_1", "mapTo": null },
    "CB_2": { "key": "CB_2", "mapTo": null },
    "CB_3": { "key": "CB_3", "mapTo": null },
    "CB_4": { "key": "CB_4", "mapTo": null },
    "Date of Rehire 0": { "key": "Date of Rehire 0", "mapTo": null },
    "Date of Rehire 1": { "key": "Date of Rehire 1", "mapTo": null },
    "Date of Rehire 2": { "key": "Date of Rehire 2", "mapTo": null },
    "Todays Date 0": { "key": "Todays Date 0", "mapTo": null },
    "Todays Date 1": { "key": "Todays Date 1", "mapTo": null },
    "Todays Date 2": { "key": "Todays Date 2", "mapTo": null },
    "Last Name 0": { "key": "Last Name 0", "mapTo": null },
    "Last Name 1": { "key": "Last Name 1", "mapTo": null },
    "Last Name 2": { "key": "Last Name 2", "mapTo": null },
    "First Name 0": { "key": "First Name 0", "mapTo": null },
    "First Name 1": { "key": "First Name 1", "mapTo": null },
    "First Name 2": { "key": "First Name 2", "mapTo": null },
    "Middle Initial 0": { "key": "Middle Initial 0", "mapTo": null },
    "Middle Initial 1": { "key": "Middle Initial 1", "mapTo": null },
    "Middle Initial 2": { "key": "Middle Initial 2", "mapTo": null },
    "PT Middle Initial 0": { "key": "PT Middle Initial 0", "mapTo": null },
    "PT Middle Initial 1": { "key": "PT Middle Initial 1", "mapTo": null },
    "PT Middle Initial 2": { "key": "PT Middle Initial 2", "mapTo": null },
    "PT Middle Initial 3": { "key": "PT Middle Initial 3", "mapTo": null },
    "Document Title 0": { "key": "Document Title 0", "mapTo": null },
    "Document Title 1": { "key": "Document Title 1", "mapTo": null },
    "Document Title 2": { "key": "Document Title 2", "mapTo": null },
    "Document Number 0": { "key": "Document Number 0", "mapTo": null },
    "Document Number 1": { "key": "Document Number 1", "mapTo": null },
    "Document Number 2": { "key": "Document Number 2", "mapTo": null },
    "Expiration Date 0": { "key": "Expiration Date 0", "mapTo": null },
    "Expiration Date 2": { "key": "Expiration Date 2", "mapTo": null },
    "Expiration Date 1": { "key": "Expiration Date 1", "mapTo": null },
    "Name of Emp or Auth Rep 0": { "key": "Name of Emp or Auth Rep 0", "mapTo": null },
    "Name of Emp or Auth Rep 1": { "key": "Name of Emp or Auth Rep 1", "mapTo": null },
    "Name of Emp or Auth Rep 2": { "key": "Name of Emp or Auth Rep 2", "mapTo": null },
    "Signature of Emp Rep 0": { "key": "Signature of Emp Rep 0", "mapTo": null },
    "Signature of Emp Rep 1": { "key": "Signature of Emp Rep 1", "mapTo": null },
    "Signature of Emp Rep 2": { "key": "Signature of Emp Rep 2", "mapTo": null },
    "CB_Alt_0": { "key": "CB_Alt_0", "mapTo": null },
    "CB_Alt_1": { "key": "CB_Alt_1", "mapTo": null },
    "CB_Alt_2": { "key": "CB_Alt_2", "mapTo": null },
    "Addtl Info 0": { "key": "Addtl Info 0", "mapTo": null },
    "Addtl Info 2": { "key": "Addtl Info 2", "mapTo": null },
    "Addtl Info 1": { "key": "Addtl Info 1", "mapTo": null },
    "CB_Alt": { "key": "CB_Alt", "mapTo": null },
    "Last Name Family Name from Section 1-2": { "key": "Last Name Family Name from Section 1-2", "mapTo": null },
    "First Name Given Name from Section 1-2": { "key": "First Name Given Name from Section 1-2", "mapTo": null },
    "Middle initial if any from Section 1-2": { "key": "Middle initial if any from Section 1-2", "mapTo": null },
    "State": { "key": "State", "mapTo": null },
    "ZIP Code": { "key": "ZIP Code", "mapTo": null },
    "Date of Birth mmddyyyy": { "key": "Date of Birth mmddyyyy", "mapTo": null },
    "US Social Security Number": { "key": "US Social Security Number", "mapTo": null },
    "Telephone Number": { "key": "Telephone Number", "mapTo": null },
    "Last Name (Family Name)": { "key": "Last Name (Family Name)", "mapTo": null },
    "Address Street Number and Name": { "key": "Address Street Number and Name", "mapTo": null },
    "Apt Number (if any)": { "key": "Apt Number (if any)", "mapTo": null },
    "City or Town": { "key": "City or Town", "mapTo": null },
    "Employees E-mail Address": { "key": "Employees E-mail Address", "mapTo": null },
    "Exp Date mmddyyyy": { "key": "Exp Date mmddyyyy", "mapTo": null },
    "USCIS ANumber": { "key": "USCIS ANumber", "mapTo": null },
    "Form I94 Admission Number": { "key": "Form I94 Admission Number", "mapTo": null },
    "Foreign Passport Number and Country of IssuanceRow1": { "key": "Foreign Passport Number and Country of IssuanceRow1", "mapTo": null },
    "Issuing Authority 1": { "key": "Issuing Authority 1", "mapTo": null },
    "Document Number 0 (if any)": { "key": "Document Number 0 (if any)", "mapTo": null },
    "Document Title 2 If any": { "key": "Document Title 2 If any", "mapTo": null },
    "Issuing Authority_2": { "key": "Issuing Authority_2", "mapTo": null },
    "Document Number If any_2": { "key": "Document Number If any_2", "mapTo": null },
    "Document Number if any_3": { "key": "Document Number if any_3", "mapTo": null },
    "List C Expiration Date 1": { "key": "List C Expiration Date 1", "mapTo": null },
    "List C Document Number 1": { "key": "List C Document Number 1", "mapTo": null },
    "List C Issuing Authority 1": { "key": "List C Issuing Authority 1", "mapTo": null },
    "List C Document Title 1": { "key": "List C Document Title 1", "mapTo": null },
    "FirstDayEmployed mmddyyyy": { "key": "FirstDayEmployed mmddyyyy", "mapTo": null },
    "S2 Todays Date mmddyyyy": { "key": "S2 Todays Date mmddyyyy", "mapTo": null },
    "Last Name First Name and Title of Employer or Authorized Representative": { "key": "Last Name First Name and Title of Employer or Authorized Representative", "mapTo": null },
    "Signature of Employer or AR": { "key": "Signature of Employer or AR", "mapTo": null },
    "Employers Business or Org Name": { "key": "Employers Business or Org Name", "mapTo": "name" },
    "Employers Business or Org Address": { "key": "Employers Business or Org Address", "mapTo": ["street_address", "city", "state", "zip_code"] },
    "Expiration Date if any": { "key": "Expiration Date if any", "mapTo": null },
    "List A": { "key": "List A", "mapTo": null },
    "List B Document 1 Title": { "key": "List B Document 1 Title", "mapTo": null },
    "List B Issuing Authority 1": { "key": "List B Issuing Authority 1", "mapTo": null },
    "List B Document Number 1": { "key": "List B Document Number 1", "mapTo": null },
    "List B Expiration Date 1": { "key": "List B Expiration Date 1", "mapTo": null },
    "Additional Information": { "key": "Additional Information", "mapTo": null }
}


// export const I9FormFields: { [key: string]: IPDFAutoFill } = {
//     "topmostSubform[0]": { key: "topmostSubform[0]", mapTo: null },
//     "topmostSubform[0].Page2[0]": { key: "topmostSubform[0].Page2[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].The_employee_s_first_day_of_employment__mm_dd_yyyy_[0]": { key: "topmostSubform[0].Page2[0].The_employee_s_first_day_of_employment__mm_dd_yyyy_[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].State[0]": { key: "topmostSubform[0].Page2[0].State[0]", mapTo: "state" },
//     "topmostSubform[0].Page2[0].textFieldEmployerName[0]": { key: "topmostSubform[0].Page2[0].textFieldEmployerName[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].textFieldEmployerTitle[0]": { key: "topmostSubform[0].Page2[0].textFieldEmployerTitle[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].Zip_Code_of_Employers_Business_or_Organization[0]": { key: "topmostSubform[0].Page2[0].Zip_Code_of_Employers_Business_or_Organization[0]", mapTo: "zip_code" },
//     "topmostSubform[0].Page2[0].Expiration_Date_if_any_mmddyyyy_6[0]": { key: "topmostSubform[0].Page2[0].Expiration_Date_if_any_mmddyyyy_6[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].Document_Number_6[0]": { key: "topmostSubform[0].Page2[0].Document_Number_6[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].Document_Title_4[0]": { key: "topmostSubform[0].Page2[0].Document_Title_4[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].Date_mmddyyyy[0]": { key: "topmostSubform[0].Page2[0].Date_mmddyyyy[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].Middle_Initial_2[0]": { key: "topmostSubform[0].Page2[0].Middle_Initial_2[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].First_Name_Given_Name_4[0]": { key: "topmostSubform[0].Page2[0].First_Name_Given_Name_4[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].Last_Name_Family_Name_4[0]": { key: "topmostSubform[0].Page2[0].Last_Name_Family_Name_4[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].City_or_Town_of_Employers_Business_or_Organization[0]": { key: "topmostSubform[0].Page2[0].City_or_Town_of_Employers_Business_or_Organization[0]", mapTo: "city" },
//     "topmostSubform[0].Page2[0].Employers_Business_or_Organization_Address_Street_Number_and_Name[0]": { key: "topmostSubform[0].Page2[0].Employers_Business_or_Organization_Address_Street_Number_and_Name[0]", mapTo: "street_address" },
//     "topmostSubform[0].Page2[0].Employers_Business_or_Organization_Name[0]": { key: "topmostSubform[0].Page2[0].Employers_Business_or_Organization_Name[0]", mapTo: "name" },
//     "topmostSubform[0].Page2[0].First_Name_of_Employer_or_Authorized_Representative[0]": { key: "topmostSubform[0].Page2[0].First_Name_of_Employer_or_Authorized_Representative[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].Last_Name_of_Employer_or_Authorized_Representative[0]": { key: "topmostSubform[0].Page2[0].Last_Name_of_Employer_or_Authorized_Representative[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].Additional_Information[0]": { key: "topmostSubform[0].Page2[0].Additional_Information[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Expiration_Date__if_any___mm_dd_yyyy__3[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Expiration_Date__if_any___mm_dd_yyyy__3[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Document_Number_3[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Document_Number_3[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Issuing_Authority_3[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Issuing_Authority_3[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Document_Title_3[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Document_Title_3[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Expiration_Date__if_any___mm_dd_yyyy__2[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Expiration_Date__if_any___mm_dd_yyyy__2[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Document_Number_2[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Document_Number_2[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Issuing_Authority_2[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Issuing_Authority_2[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Document_Title_2[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Document_Title_2[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_C_-_Expiration_Date__if_any___mm_dd_yyyy_[0]": { key: "topmostSubform[0].Page2[0].List_C_-_Expiration_Date__if_any___mm_dd_yyyy_[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_B_-_Expiration_Date__if_any___mm_dd_yyyy_[0]": { key: "topmostSubform[0].Page2[0].List_B_-_Expiration_Date__if_any___mm_dd_yyyy_[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Expiration_Date__if_any___mm_dd_yyyy__1[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Expiration_Date__if_any___mm_dd_yyyy__1[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_C_-_Document_Number[0]": { key: "topmostSubform[0].Page2[0].List_C_-_Document_Number[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_B_-_Document_Number[0]": { key: "topmostSubform[0].Page2[0].List_B_-_Document_Number[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Document_Number_1[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Document_Number_1[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_C_-_Issuing_Authority[0]": { key: "topmostSubform[0].Page2[0].List_C_-_Issuing_Authority[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_B_-_Issuing_Authority[0]": { key: "topmostSubform[0].Page2[0].List_B_-_Issuing_Authority[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Issuing_Authority_1[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Issuing_Authority_1[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_C_-_Document_Title[0]": { key: "topmostSubform[0].Page2[0].List_C_-_Document_Title[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_B_-_Document_Title[0]": { key: "topmostSubform[0].Page2[0].List_B_-_Document_Title[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].List_A_-_Document_Title_1[0]": { key: "topmostSubform[0].Page2[0].List_A_-_Document_Title_1[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].CitizenshipImmigration_Status[0]": { key: "topmostSubform[0].Page2[0].CitizenshipImmigration_Status[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].MI[0]": { key: "topmostSubform[0].Page2[0].MI[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].First_Name_Given_Name_3[0]": { key: "topmostSubform[0].Page2[0].First_Name_Given_Name_3[0]", mapTo: null },
//     "topmostSubform[0].Page2[0].Last_Name_Family_Name_3[0]": { key: "topmostSubform[0].Page2[0].Last_Name_Family_Name_3[0]", mapTo: null },
//     "topmostSubform[0].Page1[0]": { key: "topmostSubform[0].Page1[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].State[1]": { key: "topmostSubform[0].Page1[0].State[1]", mapTo: null },
//     "topmostSubform[0].Page1[0].State[0]": { key: "topmostSubform[0].Page1[0].State[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Zip_Code[0]": { key: "topmostSubform[0].Page1[0].Zip_Code[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].City_or_Town_2[0]": { key: "topmostSubform[0].Page1[0].City_or_Town_2[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Address_Street_Number_and_Name_2[0]": { key: "topmostSubform[0].Page1[0].Address_Street_Number_and_Name_2[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].First_Name_Given_Name_2[0]": { key: "topmostSubform[0].Page1[0].First_Name_Given_Name_2[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Last_Name_Family_Name_2[0]": { key: "topmostSubform[0].Page1[0].Last_Name_Family_Name_2[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].A_preparer_s__and_or_translator_s__assisted_the_employee_in_completing_Section_1[0]": { key: "topmostSubform[0].Page1[0].A_preparer_s__and_or_translator_s__assisted_the_employee_in_completing_Section_1[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].I_did_not_use_a_preparer_or_translator[0]": { key: "topmostSubform[0].Page1[0].I_did_not_use_a_preparer_or_translator[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Country_of_Issuance[0]": { key: "topmostSubform[0].Page1[0].Country_of_Issuance[0]", mapTo: null },
//     "topmostSubform[0].Page1[0]._3_Foreign_Passport_Number[0]": { key: "topmostSubform[0].Page1[0]._3_Foreign_Passport_Number[0]", mapTo: null },
//     "topmostSubform[0].Page1[0]._2_Form_I94_Admission_Number[0]": { key: "topmostSubform[0].Page1[0]._2_Form_I94_Admission_Number[0]", mapTo: null },
//     "topmostSubform[0].Page1[0]._1_Alien_Registration_NumberUSCIS_Number[0]": { key: "topmostSubform[0].Page1[0]._1_Alien_Registration_NumberUSCIS_Number[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].expiration_date__if_applicable__mm_dd_yyyy[0]": { key: "topmostSubform[0].Page1[0].expiration_date__if_applicable__mm_dd_yyyy[0]", mapTo: null },
//     "topmostSubform[0].Page1[0]._4\\": { key: "topmostSubform[0].Page1[0]._4\\", mapTo: null },
//     "topmostSubform[0].Page1[0]._4\\._An_alien_authorized_to_work_until__expiration_date__if_applicable__mmd_dd_yyyy__[0]": { key: "topmostSubform[0].Page1[0]._4\\._An_alien_authorized_to_work_until__expiration_date__if_applicable__mmd_dd_yyyy__[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Alien_Registration_NumberUSCIS_Number_1[0]": { key: "topmostSubform[0].Page1[0].Alien_Registration_NumberUSCIS_Number_1[0]", mapTo: null },
//     "topmostSubform[0].Page1[0]._3\\": { key: "topmostSubform[0].Page1[0]._3\\", mapTo: null },
//     "topmostSubform[0].Page1[0]._3\\._A_lawful_permanent_resident__Alien_Registration_Number_USCIS_Number__[0]": { key: "topmostSubform[0].Page1[0]._3\\._A_lawful_permanent_resident__Alien_Registration_Number_USCIS_Number__[0]", mapTo: null },
//     "topmostSubform[0].Page1[0]._2\\": { key: "topmostSubform[0].Page1[0]._2\\", mapTo: null },
//     "topmostSubform[0].Page1[0]._2\\._A_noncitizen_national_of_the_United_States__See_instructions_[0]": { key: "topmostSubform[0].Page1[0]._2\\._A_noncitizen_national_of_the_United_States__See_instructions_[0]", mapTo: null },
//     "topmostSubform[0].Page1[0]._1\\": { key: "topmostSubform[0].Page1[0]._1\\", mapTo: null },
//     "topmostSubform[0].Page1[0]._1\\._A_citizen_of_the_United_States[0]": { key: "topmostSubform[0].Page1[0]._1\\._A_citizen_of_the_United_States[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Employees_Telephone_Number[0]": { key: "topmostSubform[0].Page1[0].Employees_Telephone_Number[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Employees_Email_Address[0]": { key: "topmostSubform[0].Page1[0].Employees_Email_Address[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].U\\": { key: "topmostSubform[0].Page1[0].U\\", mapTo: null },
//     "topmostSubform[0].Page1[0].U\\.S\\": { key: "topmostSubform[0].Page1[0].U\\.S\\", mapTo: null },
//     "topmostSubform[0].Page1[0].U\\.S\\._Social_Security_Number__Last_4_numbers_[0]": { key: "topmostSubform[0].Page1[0].U\\.S\\._Social_Security_Number__Last_4_numbers_[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].U\\.S\\._Social_Security_Number__Next_2_numbers_[0]": { key: "topmostSubform[0].Page1[0].U\\.S\\._Social_Security_Number__Next_2_numbers_[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].U\\.S\\._Social_Security_Number__First_3_Numbers_[0]": { key: "topmostSubform[0].Page1[0].U\\.S\\._Social_Security_Number__First_3_Numbers_[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Date_of_Birth_mmddyyyy[0]": { key: "topmostSubform[0].Page1[0].Date_of_Birth_mmddyyyy[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].ZIP_Code[0]": { key: "topmostSubform[0].Page1[0].ZIP_Code[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].City_or_Town[0]": { key: "topmostSubform[0].Page1[0].City_or_Town[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Apt_Number[0]": { key: "topmostSubform[0].Page1[0].Apt_Number[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Address_Street_Number_and_Name[0]": { key: "topmostSubform[0].Page1[0].Address_Street_Number_and_Name[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Other_Last_Names_Used_if_any[0]": { key: "topmostSubform[0].Page1[0].Other_Last_Names_Used_if_any[0]", mapTo: null },
//     "topmostSubform[0].Page1[0].Middle_Initial[0]": { key: "topmostSubform[0].Page1[0].Middle_Initial[0]", mapTo: null },
//     "first Name Given Name from Section 1": { key: "first Name Given Name from Section 1", mapTo: null },
//     "last Name Family Name from Section 1": { key: "last Name Family Name from Section 1", mapTo: null },
//      "page1_emp_sign1": { key: "page1_emp_sign1", mapTo: null },
//     "page1_emp_sign_date1": { key: "page1_emp_sign_date1", mapTo: null },
//     "page1_employee_sign2": { key: "page1_employee_sign2", mapTo: null },
//     "page1_emp_sign_date2": { key: "page1_emp_sign_date2", mapTo: null },
//     "page2_emp_sign1": { key: "page1_emp_sign1", mapTo: null },
//     "page2_emp_sign_date1": { key: "page2_emp_sign_date1", mapTo: null },
//     "page2_emp_sign2": { key: "page2_emp_sign2", mapTo: null },
//     "page2_emp_sign_date2": { key: "page2_emp_sign_date2", mapTo: null },

// }