import {
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { LoadingType } from "../../../../enums";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import {
  SpinnerScreen,
} from "../../../../utils";

import gsap from "gsap";
import { IEmploymentHistory } from "../../../../interfaces";
import {
  getDateFromTimeStamp,
} from "../../../../variables";
import "./online_employment_history.scss";

import OnlineEmploymentHistorySection from "../../../../components/online_employment_history/online_employment_history_comp";
import {
  OnlineApplicationReceived,
} from "../../../../interfaces/online_application_received";
import {
  clearOnlineApplicationReceivedDetailsState,
  selectOnlineApplicationReceivedDetailsState,
} from "../../../../redux/dashboard/metrics/online_applications_received_details/online_applications_received_selector";
import { getOnlineApplicationReceivedDetails } from "../../../../redux/dashboard/metrics/online_applications_received_details/online_applications_received_thunk";
import { TableErrorHandler } from "../../../../components/table_empty/table_empty";

const PageLoading = (props) => {
  return (
    <div className="table-loading">
      <SpinnerScreen />
    </div>
  );
};

const OnlineEmploymentHistoryPage: FC<RouteComponentProps<any>> = (
  props: RouteComponentProps<any>
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { params } = props.match;
  const { loading, error, appInfo, id } = useAppSelector((state) =>
    selectOnlineApplicationReceivedDetailsState(state)
  );
  const [app, setApp] = useState<OnlineApplicationReceived>(
    appInfo ?? new OnlineApplicationReceived()
  );
  useEffect(() => {
    fetchAppDetails();
    return () => {
      dispatch(clearOnlineApplicationReceivedDetailsState());
    };
  }, []);
  useEffect(() => {
    const newApp = appInfo ?? new OnlineApplicationReceived();
    setApp({ ...newApp });
  }, [appInfo]);
  const fetchAppDetails = () => {
    if (params && params.id) {
      dispatch(getOnlineApplicationReceivedDetails(params.id));
    }
  };

  var employmentHistoryList = app?.work_history?.filter((item) => {
    return item.company_name != "";
  });


  const [newtimelinevalue, setnewValue] = useState(0);
  const [newprevious, setnewPrevious] = useState(0);

  const showloading = loading === LoadingType.pending;

  let scrl = useRef<HTMLUListElement | null>(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);
  //Slide click
  const slide = (shift) => {
    scrl.current!.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current!.scrollWidth - scrl.current!.scrollLeft) <=
      scrl.current!.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.5 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current!.scrollLeft);
    if (
      Math.floor(scrl.current!.scrollWidth - scrl.current!.scrollLeft) <=
      scrl.current!.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const getEmpData = (id) => () => {
    setnewPrevious(id);
    let activeElement;
    if (employmentHistoryList[id].company_name != "") {
      activeElement = employmentHistoryList?.find(
        (item) =>
          item.company_name === employmentHistoryList[id].company_name &&
          item.start_date === employmentHistoryList[id].start_date &&
          item.end_date === employmentHistoryList[id].end_date
      );
    }
    activeElement && setnewValue(id);
  };
  const getDateStringFormat = (value?: number) => {
    if (value == null) return "";
    const date = getDateFromTimeStamp(value);
    const year = date.getFullYear();
    return `${year}`;
  };
  // function handleAddCompanyToCallDeck(
  //   value: OnlineApplicationReceivedWorkHistory
  // ) {
  //   // ignore until API fix
  //   // setAddCompanyToCallDeckHistory({ visible: true, value: value });
  // }
  
  return (
    <>
      {showloading && <PageLoading />}
      {error != null && (
        <TableErrorHandler error={error} onRefresh={fetchAppDetails} />
      )}
      {loading === LoadingType.succeeded && error === null && (
        <>
          {app && employmentHistoryList?.length != 0 ? (
            <>
              <div className="tlnt-emp-history-ud-container">
                <div className="history-holder">
                  <div className="scrollable-list">
                    {scrollX !== 0 && (
                      <button
                        className="left-action"
                        onClick={() => slide(-50)}
                        onMouseEnter={(e) => anim(e)}
                        onMouseLeave={(e) => anim2(e)}
                      >
                        <div className="prev">
                          <ChevronLeft fontSize="small" color="inherit" />
                        </div>
                      </button>
                    )}
                    <style>
                      {` .online-timeline-ul::before {
                      width: ${scrl.current !== null
                          ? scrl.current.scrollWidth+ + "px" 
                          : 100 + "%"
                        } !important;
                    }
              `}
                    </style>
                    <ul
                      ref={scrl}
                      onScroll={scrollCheck}
                      className="online-timeline-ul"
                    >
                      {employmentHistoryList?.map((item, index) => (
                        <li
                          key={index}
                          onClick={getEmpData(index)}
                          data-index={index}
                          className={
                            newtimelinevalue === index ? "active_item" : ""
                          }
                        >
                          <div className="year_span">
                            {getDateStringFormat(item.start_date)}
                          </div>

                          <div className="company_span">
                            {item.company_name}
                          </div>
                        </li>
                      ))}
                    </ul>
                    {!scrolEnd && (
                      <button
                        className="right-action"
                        onClick={() => slide(+50)}
                        onMouseEnter={(e) => anim(e)}
                        onMouseLeave={(e) => anim2(e)}
                      >
                        <div className="next">
                          <ChevronRight fontSize="small" color="inherit" />
                        </div>
                      </button>
                    )}
                  </div>
                </div>
                <div className="details-container">
                  {employmentHistoryList && (
                    <OnlineEmploymentHistorySection
                      // talentId={params.id}
                      employmentHistory={
                        employmentHistoryList[newtimelinevalue].company_name !=
                          ""
                          ? employmentHistoryList[newtimelinevalue]
                          : null
                      }
                      onAddCompanyToCallDeck={() => { }}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="tlnt-emp-history-ud-container-empty">
              <div className="no-data-div">
                <span className="span">{t("no_emp_data_available")}</span>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default OnlineEmploymentHistoryPage;
