import { combineReducers } from "@reduxjs/toolkit";
// import { contactDetailsSlice } from "./contact_details/contact_details_reducer";
import { contactProfileSlice } from "./contact_profile/contact_profile_reducer";
import { contactsDetailsReducers } from "./details";
// import { manageContactSlice } from "./manage_contact/manage_contact_reducer";
import { contactsUniversalDirectoryReducers } from "./universal_directory";
import { apolloContactsListSlice } from "../apollo_search_import/contacts_search_import/contacts_search_import_reducer";

export const contactsReducers = combineReducers({
  'universalDirectory': contactsUniversalDirectoryReducers,
  'details': contactsDetailsReducers,
  // [contactDetailsSlice.name]: contactDetailsSlice.reducer,
  [contactProfileSlice.name]: contactProfileSlice.reducer,
});


export * from './details';
export * from './universal_directory';

// export * from './contact_details/contact_details_thunk';
// export * from './contact_details/contact_details_selector';

export * from './contact_profile/contact_profile_thunk';
export * from './contact_profile/contact_profile_selector';


