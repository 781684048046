import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../redux/store";
import {
  IContactApolloImport,
  ICompanyApolloImport,
  ICompaniesApolloSearchResults,
  IApolloCompaniesResults,
  IApolloImportResponse,
} from "../../../../interfaces/apollo_search_import";
import { useEffect, useState } from "react";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import { CustomButton, CustomCheckBox } from "../../../../utils";
import { TableFilterEmpty } from "../../../../components/table_empty/table_empty";
import {
  CallBlueIcon,
  CircleDownloadIcon,
  CompaniesIcon,
  EmailIcon,
  FacebookIcon,
  LinkedInIcon,
  TwitterIcon,
  UserProfileIcon,
  WebsiteIcon,
} from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import "./companies_results_table.scss";
import ImportPeoplePopup from "../../popups/import_popup/import_people_popup";
import { Portal } from "react-portal";
import { AppError } from "../../../../interfaces";
import ApolloPagination from "../apollo_table_pagination/apollo_table_pagination";
import { AppRoutes } from "../../../../routes";

interface Props {
  selectedContact?: ICompanyApolloImport | null;
  companiesResult: IApolloCompaniesResults | null;
  onChange: (value: ICompanyApolloImport | null) => void;
  title: string;
  importCompany: (doc: ICompanyApolloImport[], forceImport: boolean) => void;
  importLoading: LoadingType;
  successTitle: string;
  currentLoginUserName?: string;
  onClose: () => void;
  onGotoCreated: (id: string, name: string | undefined) => void;
  importResponse: IApolloImportResponse | undefined;
  source: string;
  error: AppError | null | undefined;
  onClearError: () => void;
  onClearImportState: () => void;
  handlePageChange: (pageNum: number) => void;
  currentPage: number;
  handleCompanySelect: (value: ICompaniesApolloSearchResults) => void;
  handleSelection: (itemMap: Map<any, ICompaniesApolloSearchResults>) => void;
  selectedItems: Map<any, ICompaniesApolloSearchResults>;
}

const ApolloCompaniesResults: React.FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    importCompany,
    onChange,
    importLoading,
    successTitle,
    currentLoginUserName,
    onClose,
    handlePageChange,
    currentPage,
    onClearImportState,
    handleCompanySelect,
    error,
    onClearError,
    onGotoCreated,
    importResponse,
    source,
    companiesResult,
    selectedItems,
    handleSelection,
  } = props;
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");
  const [importPopup, setImportPopup] = useState<boolean>(false);
  const companiesResultList = companiesResult?.results;

  useEffect(() => {
    // Find the script element by its widget_id attribute
    const scriptElement = document.querySelector(
      ".mp-side-menu"
    ) as HTMLElement;
    // Remove the script element if found
    if (scriptElement) {
      scriptElement.style.minWidth = "4vw !important";
    }
  }, []);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const SelectAllCheckBox = () => {
    return (
      <CustomCheckBox
        name={""}
        title={""}
        checked={selectedItems.size === companiesResultList?.length}
        onClick={handleMarkAllItem}
      />
    );
  };
  const tableHeader = [
    { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
    { title: "company_name", code: "name" },
    { title: "founded_year", code: "founded_year" },
    { title: "address", code: "raw_address" },
    { title: "industry", code: "industry" },
    {
      title: "organization_num_employees_ranges",
      code: "estimated_num_employees",
    },
    { title: "phone", code: "phone" },
  ];
  const getPhoneInfo = (phone: string | null | undefined) => {
    if (phone !== undefined && phone !== null && phone !== "") {
      return (
        <div
          onMouseOut={() => {
            setIsHovering(false);
          }}
          onMouseOver={() => {
            setIsHovering(true);
          }}
          className="icon-hover-div"
        >
          <div>
            <a
              href={`tel:${phone}`}
              className="phone-icon"
              target="_blank"
              rel="noreferrer"
            >
              <CallBlueIcon width={"100%"} height={"100%"} />
            </a>
          </div>
          {isHovering && (
            <div>
              <div className="parent-position">
                <div className="child-position">
                  <div className="phone-details">
                    <div className="phone">{phone}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
    return <span>{""}</span>;
  };
  const getSocialMediaInfo = (
    website: string | null | undefined,
    linkedin: string | null | undefined,
    facebook: string | null | undefined,
    twitter: string | null | undefined
  ) => {
    const socialMediaIcons: JSX.Element[] = [];
    if (website !== null && website !== undefined && website !== "") {
      socialMediaIcons.push(
        <a
          href={website}
          className="website-icon"
          target="_blank"
          rel="noreferrer"
        >
          <WebsiteIcon width={"100%"} height={"100%"} />
        </a>
      );
    }
    if (linkedin !== null && linkedin !== undefined && linkedin !== "") {
      socialMediaIcons.push(
        <a
          href={linkedin}
          className="website-icon"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon width={"100%"} height={"100%"} />
        </a>
      );
    }
    if (facebook !== null && facebook !== undefined && facebook !== "") {
      socialMediaIcons.push(
        <a
          href={facebook}
          className="website-icon"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon width={"100%"} height={"100%"} />
        </a>
      );
    }
    if (twitter !== null && twitter !== undefined && twitter !== "") {
      socialMediaIcons.push(
        <a
          href={twitter}
          className="website-icon"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon width={"100%"} height={"100%"} />
        </a>
      );
    }
    if (socialMediaIcons.length > 0) {
      return <div style={{ display: "flex" }}>{socialMediaIcons}</div>;
    }
    return <span>{""}</span>;
  };
  function getFilteredList(): ICompaniesApolloSearchResults[] | undefined {
    let sortedList: ICompaniesApolloSearchResults[] | undefined;
    if (sortedField != null && companiesResultList !== undefined) {
      sortedList = [...companiesResultList].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] !== undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }
    return (sortedList ?? companiesResultList)?.filter((doc) => {
      const str = search.trim().toLowerCase();
      const nameFilter = `${doc.name ?? ""}`.trim().toLowerCase().includes(str);
      // const cityFilter = doc.city ? doc.city.trim().toLowerCase().includes(str) : false;
      // const stateFilter = doc.state ? doc.state.trim().toLowerCase().includes(str) : false;
      // const zipcodeFilter = doc.zip_code ? doc.zip_code.trim().toLowerCase().includes(str) : false;
      return nameFilter;
    });
  }
  function handleSortFieldChange(value: string) {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }
  // const [selectedC, setSelectedItems] = useState<ICompaniesApolloSearchResults | undefined>();
  // const handleMarkCompany = (doc: ICompaniesApolloSearchResults) => {
  //     if (doc.id === selectedC?.id) {
  //         setSelectedItems(undefined);
  //     } else {
  //         setSelectedItems(doc);
  //     }
  // }

  const handleMarkCompany = (value: ICompaniesApolloSearchResults) => {
    const newMap = new Map(selectedItems);
    if (selectedItems.has(value.id)) {
      newMap.delete(value.id);
    } else {
      newMap.set(value.id, value);
    }
    handleSelection(newMap);
  };
  const handleMarkAllItem = () => {
    const newMap = new Map();
    if (selectedItems.size === companiesResultList?.length) {
      handleSelection(newMap);
    } else {
      companiesResultList?.forEach((contact) => {
        newMap.set(contact.id, contact);
      });
      handleSelection(newMap);
    }
  };
  const onSubmit = (isDirectDial: boolean, forceImport: boolean) => {
    importCompany(Array.from(selectedItems.values()), forceImport);
  };

  return (
    <div className="as-cnt-imp-f-container">
      <div className="as-cnt-imp-f-content">
        <div className="as-cnt-btn-row">
          {/* <span>{t("total_count")} : {formatNumber(companiesResult?.total_results)}</span> */}
          <span className="total-count">
            {t("total_results")} :
            <b>{formatNumber(companiesResult?.total_results)}</b>
          </span>
          <div className="creds-row">
            <div className="selectd-count-div">
              {selectedItems.size > 0 && (
                <span>
                  {t("selected_items", { name: source })} :&nbsp;
                  <b>{selectedItems.size}</b>
                </span>
              )}
            </div>
            <div>
              <CustomButton
                leftIcon={
                  <div className="btn-icon circle-download-svg">
                    <CircleDownloadIcon
                      width={"100%"}
                      height={"100%"}
                      style={{ fill: "#fff" }}
                    />
                  </div>
                }
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("import")}
                enable={selectedItems.size > 0 ? true : false}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => setImportPopup(true)}
              />
            </div>
          </div>
        </div>
        <div className="as-srch-table-holder">
          {getFilteredList()?.length !== 0 ? (
            <SortableTable
              headerList={tableHeader}
              sortedField={sortedField}
              onSortChange={handleSortFieldChange}
              flexNumber={getFlexNumber}
              isAsc={sortDirection}
            >
              {getFilteredList()?.map((doc, index) => {
                return (
                  <tr key={doc.id}>
                    <TableData
                      customStyle={{ flex: getFlexNumber(0) }}
                      onClick={() => handleRowClick(doc)}
                    >
                      <CustomCheckBox
                        name={""}
                        title={""}
                        checked={selectedItems.has(doc.id)}
                        onClick={() => handleMarkCompany(doc)}
                      />
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(1) }}>
                      <div className="company-div-row">
                        <div className="company-logo">
                          {doc?.logo_url !== null ? (
                            <img
                              src={doc?.logo_url}
                              width={"100%"}
                              height={"100%"}
                              alt="No Logo"
                            />
                          ) : (
                            <CompaniesIcon width={"100%"} height={"100%"} />
                          )}
                        </div>
                        <div className="company-row">
                          <div
                            onClick={() => handleCompanySelect(doc)}
                            className="company-name-span"
                          >
                            {doc.name}
                          </div>
                          <div className="icons-row">
                            {getSocialMediaInfo(
                              doc.website_url,
                              doc.linkedin_url,
                              doc.facebook_url,
                              doc.twitter_url
                            )}
                          </div>
                        </div>
                      </div>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                      {doc.founded_year}
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                      <div style={{ textTransform: "capitalize" }}>{`${
                        doc.organization_raw_address ?? doc.raw_address ?? ""
                      }`}</div>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(4) }}>
                      <div style={{ textTransform: "capitalize" }}>{`${
                        doc.industry ?? ""
                      }`}</div>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(5) }}>{`${
                      formatNumber(doc.estimated_num_employees) ?? ""
                    }`}</TableData>
                    <TableData customStyle={{ flex: getFlexNumber(6) }}>
                      {getPhoneInfo(doc.phone) ??
                        getPhoneInfo(doc.sanitized_phone)}
                    </TableData>
                  </tr>
                );
              })}
            </SortableTable>
          ) : (
            <div className="srch-talent-holder">
              <TableFilterEmpty title={t("no_talent_found")} />
            </div>
          )}
        </div>
        <div className="as-table-footer">
          <ApolloPagination
            totalPages={companiesResult?.page_count}
            onPageChange={handlePageChange}
            curPageNum={currentPage}
            totalCount={companiesResult?.total_results}
          />
        </div>
      </div>
      {importPopup && (
        <Portal>
          <ImportPeoplePopup
            visible={true}
            title={""}
            successTitle={successTitle}
            currentLoginUserName={currentLoginUserName}
            selectedNames={Array.from(
              selectedItems.values(),
              (entry) => entry.name
            )}
            onClose={() => {
              onClearImportState();
              setImportPopup(false);
              handleSelection(new Map());
              props.onChange(null);
            }}
            onGotoCreated={onGotoCreated}
            importResponse={importResponse}
            importLoading={importLoading}
            source={source}
            handleOnImport={onSubmit}
            onDissmiss={() => setImportPopup(false)}
            error={error}
            onClearError={onClearError}
            selectedCount={selectedItems.size}
          />
        </Portal>
      )}
    </div>
  );

  function formatNumber(n: number | undefined) {
    // format number 1000000 to 1,234,567
    return n
      ?.toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function handleRowClick(doc: ICompaniesApolloSearchResults) {
    if (props.selectedContact && props.selectedContact.id === doc.id) {
      onChange(null);
    } else {
      onChange({ id: doc.id, name: `${doc.name ?? ""}` });
    }
  }
  function getFlexNumber(value: number) {
    if (value === 0) return 0.1;
    if (value === 1) return 1.5;
    if (value === 2) return 0.5;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 0.5;
    if (value === 6) return 1;
    if (value === 7) return 0.5;
    if (value === 8) return 0.5;
    if (value === 9) return 1;

    return 1;
  }
};

export default ApolloCompaniesResults;
