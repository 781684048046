export enum AccountingWeekStatus {
  OPEN = "Open",
  CLOSED = "Closed",
  TO_BE_OPENED = "TO_BE_OPENED",
}
export enum TransactionStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}
export enum BatchStatus {
  OPEN = "OPEN",
  VERIFIED = "VERIFIED",
  CLOSED = "CLOSED",
}
export enum PayrollStatus {
  ACTIVE = "Active",
  POSTED = "Posted",
  DELETED = "Deleted",
}
export enum BillingStatus {
  OPEN = "Open",
  POSTED = "Posted",
  CLOSED = "Closed",
}
export enum TimeCardErrorStatus {
  OPEN = "OPEN",
  RESOLVED = "RESOLVED",
}
export enum WeeklyAErrorStatus {
  OPEN = "OPEN",
  RESOLVED = "RESOLVED",
}
export enum ImportExportFileStatus {
  PROCESSING = "Processing",
  COMPLETED = "Completed"
}
export enum MTExtractFileStatus {
  InProgress = "In Progress",
  COMPLETED = "Completed",
  FAILED = "Failed"
}
export enum MappingGroup {
  ESC_ELG_CHANGE = "Essential Staff Care ELGChange",
  ESC_PAYROLL_DEDUCTION = "Essential Staff Care Payroll Deduction",
  ESC_CENSUS = "Essential Staff Care Census",
  NEW_HIRE = "Texas New Hire Report",
  MASTER_TAX_WEEKLY_PAYROLL_TAX_SUMMARY = "Master Tax - Weekly Payroll Tax Summary",
  MASTER_TAX_RECONCILIATION_TAX_SUMMARY = "Master Tax - Reconciliation Tax Summary",
  TERMINATION = "Texas Termination Report"
}

export enum ImportMappingGroup {
  ESC_ELG_CHANGE = "Essential Staff Care ELGChange",
}
export enum ExportMappingGroup {
  ESC_PAYROLL_DEDUCTION = "Essential Staff Care Payroll Deduction",
  ESC_CENSUS = "Essential Staff Care Census",
  // CTI_PAYROLL = "CTI Payroll",
  NEW_HIRE = "Texas New Hire Report",
  MASTER_TAX_WEEKLY_PAYROLL_TAX_SUMMARY = "Master Tax - Weekly Payroll Tax Summary",
  MASTER_TAX_RECONCILIATION_TAX_SUMMARY = "Master Tax - Reconciliation Tax Summary",
  TERMINATION = "Texas Termination Report"

}

export enum ActionType {
  IMPORT = "Import",
  EXPORT = "Export"
}

export enum BankActionPurposeType {
  PAYMENTS = "Payments",
  RECEIVABLES = "Receivables"
}

export enum InvoicePaymentMethod {
  CHECK = "Check",
  ACH = "ACH",
  WIRE = "Wire",
}

export enum PayFrequency {
  WEEKLY = "Weekly",
  BIWEEKLY = "Bi-Weekly",
  TRIWEEKLY = "Tri-Weekly",
  MONTHLY = "Monthly"
}
