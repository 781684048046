import React from 'react';
import { useTranslation } from 'react-i18next';
import DragAndDrop from '../../../components/drag_and_drop/drag_and_drop';
import PhotoInput from '../../../components/photo_input/photo_input';
import RenderInput from '../../../components/render_input/render_input';
import { FormInputOption, IContactInformation } from '../../../interfaces';
import { enforceFormat, formatToPhone, FormInput, RegxPattern } from '../../../utils';
import './contact_information_form.scss';

interface Props {
    formState: IContactInformation,
    selectedPhoto: File | null,
    selectedResume: File | null,
    handleFieldChange: (id: string, value: any) => void,
    onPhotoChange: (value: File) => void,
    onResumeChange: (value: File) => void,
    onRemovePhoto: () => void,
    onRemoveResume: () => void,
    tokenEmail: string | undefined;
}

const ContactInformationForm: React.FunctionComponent<Props> = (props) => {
    const {
        formState,
        selectedPhoto,
        selectedResume,
        onPhotoChange,
        onResumeChange,
        onRemovePhoto,
        onRemoveResume,
        handleFieldChange,
        tokenEmail
    } = props;
    const { t } = useTranslation();

    const formOptions : (FormInputOption | FormInputOption[])[] = [
        [
            {
                field: "first_name",
                type: "text",
                label: 'first_name',
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "middle_name",
                type: "text",
                label: 'middle_name',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "last_name",
                type: "text",
                label: 'last_name',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
        ],
        /// 3rd row
        [
            {
                field: "phone",
                type: "tel",
                label: 'phone_number',
                // pattern: RegxPattern.phone,
                secure: false,
                required: true,
                placeholder: '(888) 888-8888',
                keyboardtype: '',
                maxLength: 16,
                onKeyUp: formatToPhone,
                onKeyDown: enforceFormat,
            },
            {
                field: "email",
                type: "text",
                label: 'email',
                pattern: RegxPattern.email,
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: '',
                disabled: tokenEmail !== null ? true : false,
            },
            {
                field: "last_4_digits_of_ssn",
                type: "text",
                label: 'last_4_digits_of_your_ssn',
                secure: false,
                required: false,
                placeholder: '',
                // pattern: '',
                keyboardtype: '',
                maxLength: 4,
            }
        ],
    
    ]
    return (
        <div className="oa-cnt-inf-form-container">
            <div className="oa-cnt-inf-form-row">
                <div
                    key={"photo"}
                    className={"oa-cnt-inf-f-input-holder"}
                    style={{ width: '-webkit-fill-available' }}
                >
                    <PhotoInput
                        selectedFile={selectedPhoto}
                        label={t('photo')}
                        onChange={onPhotoChange}
                        onRemove={onRemovePhoto}
                    />
                </div>
            </div>
            {formOptions.map((doc, index) => {
                if (Array.isArray(doc)) {

                    return (
                        <div key={index + 'row'} className="oa-cnt-inf-form-row">
                            {doc.map((subDoc, subIndex) => {
                                return (
                                    <RenderInput
                                        key={index + subIndex}
                                        doc={subDoc}
                                        index={subIndex}
                                        formState={formState}
                                        handleFieldChange={handleFieldChange}
                                        formValidators={formValidators}
                                        className={"oa-cnt-inf-f-input-holder"}
                                    />
                                );
                            })}
                        </div>
                    )
                }

                return (
                    <RenderInput
                        key={index}
                        doc={doc}
                        index={index}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        formValidators={formValidators}
                        className={"oa-cnt-inf-f-input-holder"}
                    />
                )
            })}
            <div className="oa-cnt-inf-form-row">
                <div
                    key={"resume"}
                    className={"oa-cnt-inf-f-input-holder"}
                    style={{ width: '-webkit-fill-available' }}
                >
                    <DragAndDrop
                        label={t('upload_resume_or_continue_creating_work_history')}
                        selectedFile={selectedResume}
                        accept={".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                        onChange={onResumeChange}
                        onRemove={onRemoveResume}
                        formValidators={validateResumeType}
                    />
                </div>
            </div>
        </div>
    );

    function validateResumeType() {
        if (selectedResume && selectedResume.type.startsWith('image/')) {
            return t('image_file_is_not_accepted');
        }
        return null;
    }

    function formValidators(value: { text: string, field: string, }) {
        switch (value.field) {
            case "first_name": {
                if (value.text === '') return t('validators.required');
                return null;
            }
            case "email": {
                if (value.text === '') return t('validators.required');
                if (RegxPattern.email.test(value.text) === false) return t('validators.enterValidEmail');
                return null;
            };
            case "phone": {
                if (!value.text) return null;
                if (RegxPattern.phone.test(value.text) === false) return t('validators.enterValidPhoneNumber');
                return null;
            };
            default: {
                return null;
            }
        }
    }
}

export default ContactInformationForm;