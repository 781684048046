import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError, IEReportsDirectory } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { BackOfficeAPI } from "../../../../apis/back_office_api";


export const getReportsDirectoryThunk = createAsyncThunk<
    IEReportsDirectory[],
    { week_ending_id: string, action_type: string },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/import_export/reports_directory_list/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getReportsDirectoryList(payload.week_ending_id, payload.action_type);
            return res.data as IEReportsDirectory[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });


export const uploadToMasterTax = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/import_export/reports_directory_list/upload_master_tax/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getExportPTSMasterTax(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });