import React from "react";
import { useTranslation } from "react-i18next";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import MenuOption, { MenuOptionDocInput } from "../../../../components/menu_option/menu_option";
import { THEME } from "../../../../enums";
import { OnboardingPaperWorkOptions } from "../../../../enums/onboarding_paperwork";
import { OnBoardingIcon } from "../../../../icons";
import { CustomButton } from "../../../../utils";
import "./emp_options_menu.scss";

interface Props {
    title: string,
    enableSave: boolean,
    menuOptions: Array<{
        type: OnboardingPaperWorkOptions,
        title: string,
        icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }>,
        visible: boolean,
        onClick: (type: string) => void,
    }>,
    onCancel: () => void,
    onSave: () => void,
}

function EmployeeHandbookOptionMenu(props: Props) {
    const { title, enableSave, menuOptions, onCancel, onSave } = props;
    const { t } = useTranslation();



    return (
        <div className="eop-menu-container">
            <div className="eop-menu-header">
                <FormDialogTilteHeader
                    title={title}
                    subTitle={t('please_read_employee_handbook')}
                    subTitleStyle={{ fontWeight: 'normal' }}
                    titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
                />
            </div>
            <div className="eop-menu-content">
                {menuOptions.map((doc, index) => {
                    return (
                        <MenuOption
                            key={index}
                            customStyle={{ width: "16.968vw", border: `1px solid ${THEME.defaultHighLightColor}50` }}
                            doc={{ title: doc.title, icon: doc.icon }}
                            t={t}
                            onClick={() => doc.onClick(doc.type)}
                        />
                    )
                })}

            </div>
            <div className="eop-menu-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("cancel")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={onCancel}
                    />
                </div>
                <div className="btn-new">
                    <CustomButton
                        loading={false}
                        textStyle={{
                            textTransform: "capitalize",
                        }}
                        name={t("confirm_complete")}
                        enable={enableSave}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={onSave}
                    />
                </div>
            </div>
        </div>
    );
}

export default EmployeeHandbookOptionMenu;