import { useTranslation } from 'react-i18next';
import * as MatIcons from '@material-ui/icons';
import './tab_options_header.scss';


interface Props {
    list?: Array<{ title: string }> | undefined,
    actions?: React.ReactNode,
    onClose: () => void,
}

const TabOptionsHeader = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div className="tab-header">
            <div className="tab-options-container">
                {props && props.list && props.list.map((doc, index) => {
                    return (
                        <div key={index} className="tab-option">
                            <span>{t(doc.title)}</span>
                        </div>
                    )
                })}
            </div>
            {props.actions ?? <a className="tab-close-action" onClick={props.onClose}>
                <div className="tab-close-icon">
                    <MatIcons.Close fontSize='small' color='inherit' />
                </div>
                <span>{t('close')}</span>
            </a>}
        </div>
    );
}

export default TabOptionsHeader;