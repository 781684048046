import { useTranslation } from "react-i18next";
import SectionCard from "../../../../../components/section_card/section_card";
import { useState } from "react";
import { LoadingType, THEME } from "../../../../../enums";
import { CustomButton, getPlusBtnIcon } from "../../../../../utils";
import './code_rate_options.scss';
import SearchBar from "../../../../../components/search_bar";
import CompanyBOWorkCompCodes from "./work_comp_codes/work_comp_codes";
import CompanyBORatesJobTitle from "./rates_job_title/rates_job_title";
import { AppError, ICompBOInvoiceDocsRatesJobTitle, ICompBOInvoiceDocsWCCodes, ICompanyBackOfficeInvoiceDocs } from "../../../../../interfaces";

interface Props {
    formState: ICompanyBackOfficeInvoiceDocs;
    currentLoginUserName: string;
    handleAddRateJob: (doc: ICompBOInvoiceDocsRatesJobTitle, index?: number, removeItem?: boolean) => void;
    handleAddWCCode: (doc?: { wc_code_id: string; }[], removeId?: string, removeItem?: boolean) => void;
    codeRateOptionsUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleClearError: () => void;
    companyId: string;
    handleUpdateClearState: () => void;
    getInvoiceDocsData: () => void;
}
const CodeRateOptions: React.FC<Props> = props => {
    const { formState, currentLoginUserName, handleAddRateJob, handleAddWCCode, codeRateOptionsUpdateState, handleClearError,
         companyId, handleUpdateClearState, getInvoiceDocsData } = props;

    const { t } = useTranslation();

    return (
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('code_rate_options')} >
            <div className="code-ro-container">
                <div className="code-ro-work-comp-codes-container">
                    <CompanyBOWorkCompCodes
                        formState={formState}
                        currentLoginUserName={currentLoginUserName}
                        handleAddWCCode={handleAddWCCode}
                        wcCodeUpdateState={codeRateOptionsUpdateState}
                        handleClearError={handleClearError}
                        companyId={companyId}
                        handleUpdateClearState={handleUpdateClearState}
                        getInvoiceDocsData={getInvoiceDocsData}
                    />
                </div>
                <div className="code-ro-rates-job-container">
                    <CompanyBORatesJobTitle
                        formState={formState}
                        currentLoginUserName={currentLoginUserName}
                        handleAddRateJob={handleAddRateJob}
                        codeRateOptionsUpdateState={codeRateOptionsUpdateState}
                        handleClearError={handleClearError}
                        companyId={companyId}
                        handleUpdateClearState={handleUpdateClearState}
                        getInvoiceDocsData={getInvoiceDocsData}
                    />
                </div>

            </div>

        </SectionCard>
    );
}

export default CodeRateOptions; 