import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import {
  selectSalesProfile,
  selectSaveState,
  selectServiceProfile,
} from "./contact_profile_reducer";

const select = (state: IRootState) => state.contacts.contactProfileData;

export const selectContactSalesProfile = createSelector(
  select,
  selectSalesProfile
);
export const selectContactSave_state = createSelector(select, selectSaveState);
export const selectContactServiceProfile = createSelector(
  select,
  selectServiceProfile
);

