import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../enums";
import { AppError, IWeekEndingDatesDropdown,  } from "../../../interfaces";
import * as thunk from "./week_ending_dates_thunk";

export interface WEDatesDDListState extends EntityState<IWeekEndingDatesDropdown> {
    error?: AppError | null,
    loading: LoadingType,
}

const weekEndingDDListAdapter: EntityAdapter<IWeekEndingDatesDropdown> = createEntityAdapter<IWeekEndingDatesDropdown>({
    selectId: (doc) => doc.id,
})

const initialState: WEDatesDDListState = weekEndingDDListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
})


export const weekEndingDatesDDSlice = createSlice({
    name: 'weekEndingDatesDropDown',
    initialState: initialState,
    reducers: {
        clearWEDatesDropdownListError(state){
            return {...state, error: null};
        },
        clearWEDatesDropdownListState(state){
            return weekEndingDDListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getWeekEndingDatesDropdownThunk.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getWeekEndingDatesDropdownThunk.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getWeekEndingDatesDropdownThunk.fulfilled, (state, action) => weekEndingDDListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const weekEndingDatesDDListActions = weekEndingDatesDDSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = weekEndingDDListAdapter.getSelectors();
export const selectState = (state: WEDatesDDListState) => state;
export const selectLoading = (state: WEDatesDDListState) => state.loading;
export const selectError = (state: WEDatesDDListState) => state.error;


