import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import { selectState, selectAll, selectEntities, selectUnEmploymentClaimSave, selectUnEmploymentClaimDocUpload,selectDetailsState } from "./unemployment_claims_list_reducer";

const select = (state: IRootState) => state.riskManagement.universalDirectory.unEmploymentClaimsList;
export const selectUnEmploymentClaimsListState = createSelector(select, selectState);
export const selectUnEmploymentClaimsList = createSelector(
    select,
    selectAll
);
export const selectUnEmploymentClaimsListEntities = createSelector(
    select,
    selectEntities
);
export const selectUnEmploymentClaimSaveResponse = createSelector(select,selectUnEmploymentClaimSave);
export const selectUnEmploymentClaimDocUploadState=createSelector(select,selectUnEmploymentClaimDocUpload);
export const selectUnEmploymentClaimTalentDetailsState=createSelector(select,selectDetailsState);