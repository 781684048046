import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../apis";
import { AppError,IEmploymentHistory } from "../../../interfaces";
import { catchRequestError } from "../../../utils";

export const getTalentEmpHistoryList = createAsyncThunk<
    Array<IEmploymentHistory>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent-details/talent/talent-employment-history/get',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalentEmploymentHistory(payload);
            if(typeof res.data === 'string') return [];
            return res.data as IEmploymentHistory[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);



