import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import {
  selectState,
  selectUpdateState,
} from "./manage_application_sent_reducer";

const select = (state: IRootState) =>
  state.dashboard.metrics.manageApplicationSent;
export const selectManageApplicationSentState = createSelector(
  select,
  selectState
);
export const selectManageApplicationSentUpdateState = createSelector(
  selectManageApplicationSentState,
  selectUpdateState
);
