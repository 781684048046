import { createAsyncThunk } from "@reduxjs/toolkit";
import { RiskManagementAPI  } from "../../../../apis";
import { AppError, IUnEmploymentClaim, ICreateUnEmploymentClaim, IUnEmploymentClaimTalentData} from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getAllUnEmploymentClaimsList = createAsyncThunk<
    Array<IUnEmploymentClaim>,
    string | undefined,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/all-un-employment-claims/get',
    async (id, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getUnEmploymentClaims(id);
            if(typeof res.data === 'string') return [];
            return res.data as IUnEmploymentClaim[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const addUnEmploymentClaim = createAsyncThunk<
    string,
    ICreateUnEmploymentClaim,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/un-employment-claims/post',
    async (data, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.postUnEmploymentClaims(data);
            thunkAPI.dispatch(getAllUnEmploymentClaimsList());
            if(typeof res.data === 'string') return {};
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateUnEmploymentClaim = createAsyncThunk<
    string,
    ICreateUnEmploymentClaim,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/un-employment-claims/patch',
    async (data, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.patchUnEmploymentClaims(data);
            //if (data.talent_id) thunkAPI.dispatch(getUnEmploymentClaimDetailsByTalentId(data.talent_id));
            thunkAPI.dispatch(getAllUnEmploymentClaimsList());
            if(typeof res.data === 'string') return {};
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getUnEmploymentClaimDocs = createAsyncThunk<
    Array<string>,
    {id:string,docs:any},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/un-employment-claims/getDocs',
    async (data, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getUnEmploymentClaimDocs(data.id,data.docs);
            if(typeof res.data === 'string') return [];
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const getUnEmploymentClaimDetailsByTalentId = createAsyncThunk<
    IUnEmploymentClaimTalentData,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@risk-management/universal-directory/un-employment-claims/talentId',
    async (id, thunkAPI) => {
        try {
            const res = await RiskManagementAPI.getUnEmploymentClaimsByTalentId(id);
            if (typeof res.data === 'string') return {};
            return res.data as IUnEmploymentClaimTalentData;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);