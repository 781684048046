import './application_card.scss';

interface Props {
    label: string,
    icon: React.ReactNode,
    onClick: () => void,
}
const ApplicationCard: React.FunctionComponent<Props> = (props) => {

    return (
        <button className="step" onClick={props.onClick}>
            <div className="st-icon">
                {props.icon}
            </div>
            <div className="step-label">
                <span>{props.label}</span>
            </div>
        </button>
    )
}

export default ApplicationCard;