import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ICreateResponse } from "../../../../interfaces";
import * as thunk from "./gross_profit_calculator_thunk";
import { ICreateGrossProfitCalculator, IGrossProfitCalculator } from "../../../../interfaces/gross_profit_calculator";

export interface GrossProfitCalculatorState {
    error?: AppError | null,
    loading: LoadingType,
    data: IGrossProfitCalculator | null,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateGrossProfitCalculator,
    },
}

const initialState: GrossProfitCalculatorState = {
    error: null,
    loading: LoadingType.idle,
    data: null,
    create: {
        error: null,
        loading: LoadingType.idle,
    },
}


export const grossProfitCalculatorSlice = createSlice({
    name: 'grossProfitCalculator',
    initialState: initialState,
    reducers: {
        clearCreateGrossProfitCalculatorState(state) {
            return { ...state, ...initialState};
        },
        clearCreateGrossProfitCalculatorError(state) {
            return { ...state, create: { ...state.create, error: null } };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getGrossProfitCalculator.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getGrossProfitCalculator.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getGrossProfitCalculator.fulfilled, (state, action) => ({ ...state, loading: LoadingType.succeeded, data: action.payload, }))
            .addCase(thunk.createGrossProfitCalculator.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createGrossProfitCalculator.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createGrossProfitCalculator.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageGrossProfitCalculatorActions = grossProfitCalculatorSlice.actions;
export const selectState = (state: GrossProfitCalculatorState) => state;
export const selectCreateState = (state: GrossProfitCalculatorState) => state.create;

