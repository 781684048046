import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import MultiOptionSection from '../../../../../components/multi_option_section/multi_option_section';
import { TALENT_SKILLS_TYPE } from '../../../../../enums';
import { ISkillOption } from '../../../../../interfaces';
import { selectTalentDetailsByIdState, useAppDispatch, useAppSelector } from '../../../../../redux/store';
import './skills_summery.scss';



interface Props {
    onAddOption: (type: string) => void,
    onRemoveOption: (type: string, value: ISkillOption) => void,
    customStyle?: React.CSSProperties,
}

const CompanySummery: React.FunctionComponent<Props> = (props) => {
    const { params } = useRouteMatch();
    const talentState = useAppSelector((state) => selectTalentDetailsByIdState((params as { id: string, departmentId: string }).departmentId ?? (params as { id: string, departmentId: string }).id)(state));
    const { t } = useTranslation();
    return (
        <div className="tlnt-ds-ss-container" style={props.customStyle}>
            <MultiOptionSection
                headerTitle={t('professional_skills').toUpperCase()}
                actionName={t('add_skills')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.professional_skills)}
                list={talentState.professional_skills.data ?? []}
                onRemove={(value) => props.onRemoveOption(TALENT_SKILLS_TYPE.professional_skills, value)}
                customStyle={{ borderTop: 'none' }}
            />
            <MultiOptionSection
                headerTitle={t('credentials').toUpperCase()}
                actionName={t('add_credentials')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.credentials)}
                list={talentState.credentials.data ?? []}
                onRemove={(value) => props.onRemoveOption(TALENT_SKILLS_TYPE.credentials, value)}
            />
            <MultiOptionSection
                headerTitle={t('certifications').toUpperCase()}
                actionName={t('add_certifications')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.certifications)}
                list={talentState.certifications.data ?? []}
                onRemove={(value) => props.onRemoveOption(TALENT_SKILLS_TYPE.certifications, value)}
            />
            <MultiOptionSection
                headerTitle={t('education').toUpperCase()}
                actionName={t('add_education')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.education)}
                list={talentState.education.data ?? []}
                onRemove={(value) => props.onRemoveOption(TALENT_SKILLS_TYPE.education, value)}
            />
            <MultiOptionSection
                headerTitle={t('required_equipment').toUpperCase()}
                actionName={t('add_equipment')}
                onClick={() => props.onAddOption(TALENT_SKILLS_TYPE.safety_equipment)}
                list={talentState.safety_equipment.data ?? []}
                onRemove={(value) => props.onRemoveOption(TALENT_SKILLS_TYPE.safety_equipment, value)}
            />
        </div>
    )






}

export default CompanySummery;