import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";
import { ReportCenterAPI } from "../../../../apis/report_center_api";
import { IRCCashReceipts } from "../../../../interfaces/report_center_finance_accounting";

export const getCashReceiptsList = createAsyncThunk<
    IRCCashReceipts,
    { start_date: number, end_date: number },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@report_center/cash_receipts/get",
    async (payload, thunkAPI) => {
        try {
            const res = await ReportCenterAPI.getCashReceipts(payload.start_date, payload.end_date);
            return res.data as IRCCashReceipts;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });