import { RouteComponentProps } from 'react-router-dom';
import './export_page.scss';
import { useTranslation } from 'react-i18next';
import { exportPageThunk, selectExportFileState, selectImportFileState, selectProfileState, useAppDispatch, useAppSelector } from '../../../../../redux/store';
import CustomFormSelect from '../../../../../components/form_selector/form_select';
import { ExportMappingGroup, LoadingType, THEME } from '../../../../../enums';
import { useState } from 'react';
import { Portal } from 'react-portal';
import { CustomButton } from '../../../../../utils';
import ExportFilePopup from '../../../popups/import_export_file/export_file_popup';
import { exportPageSliceActions } from '../../../../../redux/back_office/import_export/export_page/export_page_reducer';
import { AppRoutes } from '../../../../../routes';


interface Props extends RouteComponentProps<any> { }

const BOExportPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
        }`;
    const exportFileUploadState = useAppSelector((state) => selectExportFileState(state));

    const [selectedMapping, setSelectedMapping] = useState<ExportMappingGroup | null>(null);
    const [processFile, setProcessFile] = useState<boolean>(false);
    const handleProcessFile = () => {
        if (selectedMapping) {
            setProcessFile(true);
            dispatch(exportPageThunk({ param: selectedMapping }));
        }
    };
    const handleClose = () => {
        setProcessFile(false)
        setSelectedMapping(null);
        dispatch(exportPageSliceActions.clearState());
    }
    const handleGotoDirectory = () => {
        handleClose();
        props.history.push(AppRoutes.exportReportsDirectory);
    }
    return (
        <div className="export-page-container">
            <div className="export-page-header">
                <span>{t('lets_export_file')}</span>
            </div>
            <div className="export-page-content">
                <div className="input-div">
                    <div className="ip-input-row">
                        <div className="ip-input">
                            <CustomFormSelect
                                name={t("mapping_group")}
                                list={Object.keys(ExportMappingGroup).map(id => ({
                                    value: id,
                                    label: ExportMappingGroup[id as keyof typeof ExportMappingGroup]
                                }))}
                                onChange={(val) => setSelectedMapping(val)}
                                value={selectedMapping}
                                placeholder={t('Select')}
                                required={true}
                                label={t('which_feed_choose')}
                                customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className="export-page-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('cancel')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={handleClose}

                    />
                </div>

                <div className="btn-save">
                    <CustomButton
                        loading={exportFileUploadState.loading === LoadingType.pending}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('process_file')}
                        enable={selectedMapping ? true : false}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={handleProcessFile}
                    />
                </div>
            </div>
            {processFile &&
                (
                    <Portal>
                        <ExportFilePopup
                            visible={processFile}
                            onClose={handleClose}
                            title={t("export_file")}
                            mapping={selectedMapping ? ExportMappingGroup[selectedMapping] : null}
                            currentLoginUserName={currentLoginUserName}
                            error={exportFileUploadState.error}
                            loading={exportFileUploadState.loading}
                            onGotoDirectory={handleGotoDirectory}
                        />
                    </Portal>
                )
            }
        </div>
    );
}

export default BOExportPage;