import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, TalentStatus } from "../../../../interfaces";
import * as thunk from "./talent_status_thunk";

export interface TalentStatusState extends EntityState<TalentStatus> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const talentStatusAdapter: EntityAdapter<TalentStatus> = createEntityAdapter<TalentStatus>({
    selectId: (doc) => doc.id
})

const initialState: TalentStatusState = talentStatusAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const talentStatusSlice = createSlice({
    name: 'talentStatus',
    initialState: initialState,
    reducers: {
        clearTalentStatusListError(state){
            return {...state, error: null};
        },
        clearTalentStatusListState(state){
            return talentStatusAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearTalentStatusCreateState(state) {
            return { ...state, create : {...initialState.create }};
        },
        clearTalentStatusUpdateState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearTalentStatusDeleteState(state) {
            return { ...state, remove : {...initialState.remove} };
        },
        clearTalentStatusCreateStateError(state) {
            return { ...state, create : { ...initialState.create , error: null }};
        },
        clearTalentStatusUpdateStateError(state) {
            return { ...state, update : { ...initialState.update , error: null} };
        },
        clearTalentStatusDeleteStateError(state) {
            return { ...state, remove : { ...initialState.remove , error: null} };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTalentStatus.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getTalentStatus.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getTalentStatus.fulfilled, (state, action) => talentStatusAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createTalentStatus.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createTalentStatus.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createTalentStatus.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteTalentStatus.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteTalentStatus.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteTalentStatus.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateTalentStatus.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateTalentStatus.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateTalentStatus.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const talentStatusActions = talentStatusSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = talentStatusAdapter.getSelectors();
export const selectState = (state: TalentStatusState) => state;
export const selectLoading = (state: TalentStatusState) => state.loading;
export const selectError = (state: TalentStatusState) => state.error;


