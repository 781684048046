import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminCenterAPI } from "../../../../apis";
import { AppError, ContactRole } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getContactRoles = createAsyncThunk<
    Array<ContactRole>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
    >(
    '@admin_center/system_configuration/contact_role/get',
    async (_, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.getContactRoles();
            return res.data as ContactRole[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const createContactRole = createAsyncThunk<
    string,
    {'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/contact_role/post',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.createContactRole(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const deleteContactRole = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/contact_role/delete',
    async (contact_id, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.deleteContactRole(contact_id);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateContactRole = createAsyncThunk<
    string,
    {'id': string, 'value': string},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }>(
    '@admin_center/system_configuration/contact_role/update',
    async (data, thunkAPI) => {
        try {
            const res = await AdminCenterAPI.updateContactRole(data);
            return res.data as string;
        } catch(error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);