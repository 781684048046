import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../../apis";
import { AppError } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";


export const getTalentAvailableDatesThunk = createAsyncThunk<
    Array<number>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/dashboard/availability/available-dates/get',
    async (talentId, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalentAvailableDates(talentId);
            if(typeof res.data === 'string') return [];
            return res.data["dates_available"] as Array<number>;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const addTalentAvailableDatesThunk = createAsyncThunk<
    string,
    {talent_id: string, available_dates: Array<number>},
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/talent_details/dashboard/availability/available-dates/patch',
    async (payload, thunkAPI) => {
        try {
            const res = await TalentAPI.patchTalentAvailableDates(payload);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);