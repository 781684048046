import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../../enums";
import {AppError, JobDocument} from "../../../../../interfaces";
import * as thunk from './document_list_thunk';


export interface SingleJobDocumentState {
    error?: AppError | null,
    loading: LoadingType,
    data: Array<JobDocument> | null,
    videoUpload: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    documentUpload: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    documentUpdate: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    documentDelete: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}


export interface JobDocumentState {
    [key: string]: SingleJobDocumentState,
}


const initialState: JobDocumentState = {}


export const jobDocumentSlice = createSlice({
    name: 'jobDocument',
    initialState: initialState,
    reducers: {
        clearJobDocState(state) {
            return {...state, ...initialState};
        },
        clearJobDocUpdateState(state) {
            return { ...state, documentUpdate : { ...initialState.documentUpdate }};
        },
        clearJobDocUploadState(state) {
            return { ...state, documentUpload : { ...initialState.documentUpload }};
        },
        clearJobDocDeleteState(state) {
            return { ...state, documentDelete : { ...initialState.documentDelete }};
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(thunk.getJobDocuments.pending, (state, action) => ({...state, [action.meta.arg]: {...state[action.meta.arg], loading: LoadingType.pending}}))
        .addCase(thunk.getJobDocuments.rejected, (state, action) => ({...state, [action.meta.arg]: {...state[action.meta.arg], loading: LoadingType.failed, error: action.payload}}))
        .addCase(thunk.getJobDocuments.fulfilled, (state, action) => ({...state, [action.meta.arg]: {...state[action.meta.arg], loading: LoadingType.succeeded, data: action.payload, error: null}}))

        .addCase(thunk.createJobDocumentUploadVideo.pending, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], videoUpload: {...state[action.meta.arg.job_order_id].videoUpload, loading: LoadingType.pending, response: '', error: null}}}))
        .addCase(thunk.createJobDocumentUploadVideo.rejected, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], videoUpload: {...state[action.meta.arg.job_order_id].videoUpload, loading: LoadingType.failed, response: '', error: action.payload}}}))
        .addCase(thunk.createJobDocumentUploadVideo.fulfilled, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], videoUpload: {...state[action.meta.arg.job_order_id].videoUpload, loading: LoadingType.succeeded, response: action.payload, error: null}}}))

        .addCase(thunk.createJobDocumentUploadDocument.pending, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], documentUpload: {...state[action.meta.arg.job_order_id].documentUpload, loading: LoadingType.pending, response: '', error: null}}}))
        .addCase(thunk.createJobDocumentUploadDocument.rejected, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], documentUpload: {...state[action.meta.arg.job_order_id].documentUpload, loading: LoadingType.failed, response: '', error: action.payload}}}))
        .addCase(thunk.createJobDocumentUploadDocument.fulfilled, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], documentUpload: {...state[action.meta.arg.job_order_id].documentUpload, loading: LoadingType.succeeded, response: action.payload, error: null}}}))

        .addCase(thunk.updateJobDocumentUploadDocument.pending, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], documentUpdate: {...state[action.meta.arg.job_order_id].documentUpdate, loading: LoadingType.pending, response: '', error: null}}}))
        .addCase(thunk.updateJobDocumentUploadDocument.rejected, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], documentUpdate: {...state[action.meta.arg.job_order_id].documentUpdate, loading: LoadingType.failed, response: '', error: action.payload}}}))
        .addCase(thunk.updateJobDocumentUploadDocument.fulfilled, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], documentUpdate: {...state[action.meta.arg.job_order_id].documentUpdate, loading: LoadingType.succeeded, response: action.payload, error: null}}}))

        .addCase(thunk.deleteJobDocumentUploadDocument.pending, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], documentDelete: {...state[action.meta.arg.job_order_id].documentDelete, loading: LoadingType.pending, response: '', error: null}}}))
        .addCase(thunk.deleteJobDocumentUploadDocument.rejected, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], documentDelete: {...state[action.meta.arg.job_order_id].documentDelete, loading: LoadingType.failed, response: '', error: action.payload}}}))
        .addCase(thunk.deleteJobDocumentUploadDocument.fulfilled, (state, action) => ({...state, [action.meta.arg.job_order_id]: {...state[action.meta.arg.job_order_id], documentDelete: {...state[action.meta.arg.job_order_id].documentDelete, loading: LoadingType.succeeded, response: action.payload, error: null}}}))
            
        .addDefaultCase(state => ({ ...state }));
    }
});

export const jobsDocumentActions = jobDocumentSlice.actions;
export const selectJobDocumentState = (state: JobDocumentState) => state;
export const selectSingleJobDocumentState = (state: JobDocumentState, id: string): SingleJobDocumentState => state[id];

export const selectInitialSingleJobOrderContactCreateState = (): SingleJobDocumentState => ({
    error: null,
    loading: LoadingType.idle,
    data: null,
    videoUpload: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    documentUpload: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    documentUpdate: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    documentDelete: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
});