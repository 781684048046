import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton } from "../../../../utils";
import { DateInput } from "../../../../components/date_input/date_input";
import { convertDateToTimeStamp } from "../../../../variables";
import './terminate_talent.scss';
import ApiError from "../../../../components/api_error";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { selectProfileState, useAppSelector } from "../../../../redux/store";

interface Props {
    visible: boolean;
    state: any;
    headerTitle: string;
    talentName: string;
    onClose: () => void;
    onApprove: (selectedDate: number) => void;
    onClearError: () => void;
}

const TerminateTalentPopup = (props: Props) => {
    const { visible, headerTitle, state, talentName, onClose, onApprove, onClearError } = props
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [date, setDate] = useState(convertDateToTimeStamp(new Date()));
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    const handleDateChange = (id, value) => {
        setDate(value);
    };

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"20%"}
            onClose={onClose}>
            <div className="terminate-talent-container">
                {getUI()}
                {state.error && <div className="error-holder">
                    <ApiError message={state.error.message} onClose={onClearError} />
                </div>}
                <div className="terminate-talent-footer">
                    {getActions()}
                </div>
            </div>
        </ActionDialogHolder>
    )
    function getUI() {
        if (state && state.loading === LoadingType.succeeded) {
            return (
                <div className="saved-doc-holder">
                    <DocSavedText>
                        <div><span className="aknw-name">
                            {t("hi_username", {
                                userName: currentLoginUserName,
                            })}
                        </span></div>
                        <div>
                            <span className="sub-span">
                                {t('you_have_successfully_terminated', { talentName: talentName })}
                            </span>
                        </div>
                    </DocSavedText>
                </div>
            )
        }
        return (
            <>
                <div className="terminate-talent-header">
                    <span style={{ color: THEME.red }}>{t(headerTitle)}</span>
                </div>
                <div className="terminate-talent-content">
                    <DateInput
                        id={"termination_date"}
                        onChange={handleDateChange}
                        placeholder={"mm/dd/yyyy"}
                        value={date}
                        label={t("termination_date")}
                        customStyle={{ width: "-webkit-fill-available" }}
                    />
                </div>
            </>
        )
    }
    function getActions() {
        if (state.loading === LoadingType.succeeded) {
            return (
                <div className="btn-save">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('ok')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => closePopup(onClose)}
                    />
                </div>
            )
        }

        return (
            <>
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('no')}
                        enable={true}
                        onClick={() => closePopup(onClose)}
                    />
                </div>
                <div className="btn-save">
                    <CustomButton
                        loading={state.loading === LoadingType.pending}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('yes')}
                        enable={true}
                        onClick={() => onApprove(date)}
                    />
                </div>
            </>
        )
    }
}
export default TerminateTalentPopup;