import { useTranslation } from "react-i18next";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import InDevelopment from "../../others/in_development";
import { AppRoutes, getApolloSearchNavHeaderProps } from "../../routes";
import ApolloSearchImport from "./pages/apollo_search_page/apollo_search_import";
import ApolloCompanyDetailsPage from "./pages/companies_details_page/companies_details_page";


export const ApolloSearchImportNavigation = (props: RouteComponentProps<any>) => {
    const { history, location, match } = props;
    const { params } = match;

    const { t } = useTranslation();
    const headerOptions = getApolloSearchNavHeaderProps(location, match, t);
    return (
        <PageNavHeader data={headerOptions!} history={history} location={location}>
            <Switch>
                <Route exact path={`${AppRoutes.apolloCompanyDetailsPage}/:id/`} component={ApolloCompanyDetailsPage} />
                <Route exact path={`${AppRoutes.apolloSearchImport}/:source`} component={ApolloSearchImport} />
            </Switch>
        </PageNavHeader>
    );
}
